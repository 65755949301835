import React, { Component } from 'react';

class CustomerDashboard extends Component {

    constructor() {
        super();
        this.state = {
            dashBoardHeader: {}
            , customerDetails: {}
            , transactions: []
            , walletLedgers: []
            , totalItemsCount: 0
            , rightHeight: 50
        };
        this.loadDashboard = this.loadDashboard.bind(this);
        this.onCancel = this.onCancel.bind(this)
    };

    componentDidMount() {
        let customerID = ""
        if (this.props.calledFromInvoice) {
            customerID = this.props.cutomerID
        }
        else {
            customerID = this.props.match.params.key
        }
        this.loadDashboard(customerID)
    };

    loadDashboard(customerID) {

        this.props.getCustomerDashboardHeaders(customerID)
            .then((result) => {
                // console.log("Customer Dashboard Header - Data", JSON.stringify(result.searchResult));
                this.setState({ dashBoardHeader: result.searchResult, totalItemsCount: result.totalItemsCount })
            });

        this.props.getCustomerDetails(customerID)
            .then((customer) => {
                // console.log("Customer Details", JSON.stringify(customer));
                this.setState({ customerDetails: customer })
            });

        this.props.getCustomerTransactions(customerID)
            .then((result) => {
                // console.log("Customer Transaction Details - Data", JSON.stringify(result.searchResult));
                this.setState({ transactions: result.searchResult, totalItemsCount: result.totalItemsCount })
            });

        this.props.getWalletLedgersByCustomerID(customerID)
            .then((result) => {
                // console.log("Wallet Details - Data", JSON.stringify(result.searchResult));
                this.setState({ walletLedgers: result.searchResult, totalItemsCount: result.totalItemsCount })
            });
    };

    onCancel() {
        if (this.props.calledFromInvoice) {
            this.props.onClose()
        }
        else {
            this.props.history.push("/home/customer")
        }
    }


    render() {
        return <div className="content-wrapper">
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <font style={{ fontSize: "30px", marginBottom: "4px" }}>{this.state.customerDetails.FirstName + ' ' + this.state.customerDetails.LastName}</font>
                                {this.state.customerDetails.EmailID != "" && <React.Fragment><i className="fa fa-envelope" aria-hidden="true"></i><tex></tex>{this.state.customerDetails.EmailID}<text></text> </React.Fragment>}
                                {this.state.customerDetails.PhoneNo != "" && <React.Fragment><i className="fa fa-phone-square" aria-hidden="true"></i><text></text>{this.state.customerDetails.PhoneNo}</React.Fragment>}
                                <button className="btn btn-md btn-flat btn-default pull-right"
                                    onClick={this.onCancel} >Cancel</button>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-12">
                        <div className="info-box" style={{ marginTop: "10px", minHeight: "104px" }}>
                            <span className="info-box-icon bg-green" style={{minHeight: "104px"}}><i className="fa fa-money"></i></span>
                            <div className="info-box-content">
                                <span className="info-box-text" style={{ fontSize: "14px" }}>Total Spent </span>
                                <br />
                                <span className="info-box-number" style={{ fontSize: "20px" }}>{this.state.dashBoardHeader.total_spent}</span>
                            </div>
                        </div> 
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-12">
                        <div className="info-box" style={{ marginTop: "10px", minHeight: "104px" }}>
                            <span className="info-box-icon bg-aqua" style={{minHeight: "104px"}}><i className="fa fa-file-o"></i></span>
                            <div className="info-box-content">
                                <span className="info-box-text" style={{ fontSize: "14px" }}>Transactions</span>
                                <br />
                                <span className="info-box-number" style={{ fontSize: "20px" }}>{this.state.dashBoardHeader.total_transactions}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-12">
                        <div className="info-box" style={{ marginTop: "10px", minHeight: "104px" }}>
                            <span className="info-box-icon bg-blue" style={{minHeight: "104px"}}><i className="fa fa-cart-plus"></i></span>
                            <div className="info-box-content">
                                <span className="info-box-text" style={{ fontSize: "14px" }}>Membership</span>
                                <br />
                                <span className="info-box-number" style={{ fontSize: "20px" }}>{this.state.customerDetails.Membership ? this.state.customerDetails.Membership.Name : ""}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-xs-12">
                        <div className="info-box" style={{ marginTop: "10px", minHeight: "104px" }}>
                            <span className="info-box-icon bg-yellow" style={{minHeight: "104px"}}><i className="fa fa-money"></i></span>
                            <div className="info-box-content">
                                <span className="info-box-text" style={{ fontSize: "14px" }}>Wallet Amount </span><span className="info-box-number" style={{ fontSize: "20px" }}>{this.state.customerDetails.WalletBalance}</span>
                                <span className="info-box-text" style={{ fontSize: "14px" }}>Outstanding Amount</span><span className="info-box-number" style={{ fontSize: "20px" }}>{this.state.customerDetails.OutStandingAmount}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="box-body">
                        <div className="row">
                            <div className="col-md-7 col-sm-6 col-xs-12">
                                <div className="box">
                                    <div className="box-header with-border">
                                        <h3 className="box-title">Transaction-Details</h3>
                                    </div>
                                    <div className="box-body">
                                        <div className="table-responsive" style={{ height: this.state.rightHeight + "vh", overflowY: "scroll", overflowX: "hidden" }} className="scroll2">
                                            {this.state.transactions.length > 0 &&
                                                <table className="table no-margin">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Date</th>
                                                            <th>Product</th>
                                                            <th>Qty</th>
                                                            <th>Retail Price</th>
                                                            <th>Discount</th>
                                                            <th>Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ height: this.state.rightHeight + "vh", overflowY: "scroll", overflowX: "hidden" }} className="scroll2">
                                                        {this.state.transactions.map((transaction) => transaction.LineItems.map((lineItem) =>
                                                            <tr>
                                                                <td>{transaction.TransactionNo}</td>
                                                                <td>{(new Date(transaction.TransactionDate)).toString().substring(0, 24)}</td>
                                                                <td>{lineItem.Product.Name}</td>
                                                                <td>{lineItem.Qty}</td>
                                                                <td>{lineItem.RetailPrice}</td>
                                                                <td>{lineItem.DiscountAmount}</td>
                                                                <td>{lineItem.Amount}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>}
                                            {this.state.transactions.length === 0 && "No records found"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 col-sm-6 col-xs-12">
                                <div className="box">
                                    <div className="box-header with-border">
                                        <h3 className="box-title">Wallet-Ledger</h3>
                                    </div>
                                    <div className="box-body">
                                        <div className="table-responsive" style={{ height: this.state.rightHeight + "vh", overflowY: "scroll", overflowX: "hidden" }} className="scroll2">
                                            {this.state.walletLedgers.length > 0 && <table className="table no-margin">
                                                <thead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>Narration</th>
                                                        <th>Credit</th>
                                                        <th>Debit</th>
                                                        <th>Balance</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.walletLedgers.map((ledger) =>
                                                        <tr>
                                                            <td>{(new Date(ledger.TransactionDate)).toString().substring(0, 24)}</td>
                                                            <td>{ledger.CreditAmount > 0 ? "Credit for Transaction: " + ledger.TransactionNo : "Debit for Transaction: " + ledger.TransactionNo}</td>
                                                            <td>{ledger.CreditAmount}</td>
                                                            <td>{ledger.DebitAmount}</td>
                                                            <td>{ledger.WalletClosingBalance}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>}
                                            {this.state.walletLedgers.length === 0 && "No records found"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    };
};
export default CustomerDashboard;