import React from 'react'
import * as actionTypes from '../../actionTypes'
import Dropzone from 'react-dropzone'
import { SketchPicker } from 'react-color';


class AddEditProductCategory extends React.Component{
    constructor () {
        super()
        this.state={
            parent:'',
            parentID:'',
            parentError:'',
            name:'',
            nameError:'',
            sequence:0,
            color:'',
            image:null,
            key:'',
            type:'',
            imageLoading:false,
            saving: false
            // disableCategory:false
        }
        this.onSaveProductCategory = this.onSaveProductCategory.bind(this)
        this.onColorSelection= this.onColorSelection.bind(this)
        this.onImageRemove = this.onImageRemove.bind(this)
        this.onImageDrop = this.onImageDrop.bind(this)
        this.save= this.save.bind(this)
    }

    componentDidMount()
    {
        if(this.props.productCategory )
        {
            let productCategory = this.props.productCategory
            this.setState({parent: productCategory.Parent,
                parentID: productCategory.ParentID,
                name: productCategory.Name,
                sequence:productCategory.Sequence,
                color: productCategory.Color,
                image: productCategory.Image,
                key: productCategory.key,
                type:this.props.type})
        }
        else 
        {
            // disableCategory: this.props.disableCategory
            if(this.props.type=== actionTypes.PRODUCT_CATEGORY)
            {
                this.setState({parentID: "", parent: "", type:this.props.type})
            }else
            {
                this.setState({parentID: this.props.parentID, parent: this.props.parent, type:this.props.type })
            }
            // console.log('this.props.parentID,', this.props.parentID)
            // console.log(' this.props.parent',  this.props.parent)
        }
        //console.log('componentDidMount', this.props.type)
    }

    onColorSelection = (color) => {
        this.setState({ color: color.hex });
      };

      
    onImageDrop(acceptedFiles, rejectedFiles) {
        if(acceptedFiles.length > 0 )
        {   
            
            if((acceptedFiles[0].size/1024) > 100)
            {
                alert('File size should not be more than 100 KB')
                return
            }
            this.setState({imageLoading:true})
            this.props.uploadFileToServer(acceptedFiles[0], this.props.registration.registration.StorageFolder + '/ProductCategory' ).then((downloadURL)=>{
                //update product with images url
                let image = {Name:acceptedFiles[0].name, Size:acceptedFiles[0].size, Preview:downloadURL}
                this.setState({image:image})
                //console.log('File available at', downloadURL);
                this.setState({imageLoading:false})
            })
        }
        else{alert("No file selected")}
    }
    
    onImageRemove(){
        this.setState({image:null})
    }

    onSaveProductCategory ()
    {
        this.setState({saving:true})
        // console.log('this.state.type ',this.state.type )
        if(this.state.type === actionTypes.PRODUCT_CATEGORY)
        {
            if(this.state.name.trim() ==="")
            {
                this.setState({nameError: "Please provide name",saving:false})
                return
            }
            //check id name is unique 
            this.props.checkIfProductCategoryIsUnique( this.state.name.toLowerCase(), this.state.key, this.props.user.user.RegistrationID).then((isUnique)=>{
                if(!isUnique)
                {
                    this.setState({nameError: "Category already exits",saving:false})
                    return
                }
                this.save()
            })
        }
        if(this.state.type === actionTypes.PRODUCT_SUB_CATEGORY)
        {
            let nameError = ""
            let parentError =""
            if(this.state.name.trim() ==="")
            {
                nameError = "Please provide name"
            }
            if(this.state.parentID ==="")
            {
                parentError ="Please select category"
            }
            if(parentError !=="" || nameError !=="")
            {
                this.setState({nameError:nameError, parentError:parentError, saving:false})
                return 
            }
            this.props.checkIfProductSubCategoryIsUnique(this.state.name.toLowerCase(), this.state.parentID,  this.state.key, this.props.user.user.RegistrationID).then((isUnique)=>{
                if(!isUnique)
                {
                    this.setState({nameError: "Sub category already exits",saving:false})
                    return
                }
                this.save()
            })
        }

    
    }

    save(){
              //check id name is unique 
              let productCategory ={
                Parent:this.state.parent,
                ParentID:this.state.parentID,
                Name:this.state.name,
                Sequence:this.state.sequence,
                Color:this.state.color,
                Image:this.state.image,
                RegistrationID:this.props.user.user.RegistrationID,
                IsDeleted:false
            }
            this.props.saveProductCategory(this.state.key, productCategory).then((key)=>{
                this.props.getToastr("Category Saved Successfully")
                this.setState({saving:false})
                
                setTimeout(function() { //Start the timer
                    if(this.state.type === actionTypes.PRODUCT_CATEGORY)
                    {
                        console.log('PRODUCT_CATEGORY',this.state.type )
                        this.props.getProductCategories(this.props.user.user.RegistrationID)
                    }
                    else
                    {
                        console.log(this.state.type )
                        this.props.getProductSubCategories(this.props.user.user.RegistrationID)  
                    }
                    this.props.onSave(key,this.state.name )
                }.bind(this), 1000)
                // console.log('after timer')
            })
    }

    render(){
        return  <div>
            {this.state.type === actionTypes.PRODUCT_CATEGORY && <h3 style={{marginTop:"0px"}}>{this.state.key === "" ? "Add New Category" : "Edit Category" }</h3>}
            {this.state.type === actionTypes.PRODUCT_SUB_CATEGORY && <h3 style={{marginTop:"0px"}}>{this.state.key === "" ? "Add New Sub Category" : "Edit Sub Category" }</h3>}            
            <div className="box-body">         
                {this.state.type === actionTypes.PRODUCT_SUB_CATEGORY &&
                <div className={ this.state.parentError !=="" ? "form-group has-error": "form-group "} >   
                    <label>Category</label>
                    <select className="form-control" 
                    // disabled={this.state.disableCategory}
                    value={this.state.parentID} 
                    onChange= {(evt) => this.setState({parentID:evt.target.value, 
                        parent: evt.target.options[evt.target.selectedIndex].text})}>
                        <option value="">Select category</option>
                        {this.props.config.ProductCategories.map((productCategory) => 
                          <option value={productCategory.key}>{productCategory.Name}</option>
                        )}
                    </select>
                    {this.state.parentError !=="" &&  <span className="help-block">{this.state.parentError}</span> }
                </div>}                   
                <div className={ this.state.nameError !=="" ? "form-group has-error": "form-group "} >   
                    <label>{this.state.type === actionTypes.PRODUCT_CATEGORY ? "Category name" : "Sub Category name" }</label>
                    <input type = "text" className="form-control" 
                    onChange={(evt)=> {
                        this.setState({name:evt.target.value,nameError:"" })}}
                    value={this.state.name}
                    />
                    {this.state.nameError !=="" &&  <span className="help-block">{this.state.nameError}</span> }
                </div>
                <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label >Sequence</label>
                                <input type="number" className="form-control"  
                                onChange={(evt)=> this.setState({sequence:Number(evt.target.value)})}
                                value={this.state.sequence}
                            />
                            </div>
                            {!this.state.imageLoading && <div className="form-group">
                                <label>Image</label>  
                                    {!this.state.image &&
                                    <Dropzone onDrop={(files) => this.onImageDrop(files)} >
                                        <div>Try dropping some files here, or click to select files to upload.</div>
                                    </Dropzone>
                                    }
                                    {this.state.image && <div className="form-group">
                                        <ul className="mailbox-attachments clearfix">
                                                <li>
                                                    <span className="mailbox-attachment-icon has-img"><img src={this.state.image.Preview}   
                                                    alt="Attachment"/></span>
                                                    <div className="mailbox-attachment-info">
                                                        <span className="mailbox-attachment-name">{ this.state.image.Name.length > 20 ? this.state.image.Name.substr(0,20) + "..." : this.state.image.Name}</span>
                                                            <span className="mailbox-attachment-size">
                                                            {Math.round(this.state.image.Size/1024)} KB
                                                        <a  onClick={this.onImageRemove} 
                                                        className="btn btn-default btn-xs pull-right"><i className="fa fa-trash"></i></a>
                                                        </span>
                                                    </div>
                                                </li>
                                        </ul>
                                    </div>}
                                </div>     }
                                {this.state.imageLoading && <div>Uploading image</div>}
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label >Color</label>
                                {/* <input type="text" className="form-control"  
                                onChange={(evt)=> this.setState({color:evt.target.value})}
                                value={this.state.color}/> */}
                                <SketchPicker
                                    color={ this.state.color }
                                    onChangeComplete={ this.onColorSelection }
                                />
                            </div>
                        </div>
                </div>
                
               
            </div>
            <div className="box-footer">
                <button type="submit" className="btn btn-flat btn-md btn-default" onClick={this.props.onClose} >Cancel</button>
                <button type="submit" className="btn btn-flat btn-md btn-success pull-right" onClick={this.onSaveProductCategory} disabled={this.state.saving}>Save</button>
            </div>
    </div>

    }
}

export default AddEditProductCategory