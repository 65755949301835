import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CreatableSelect from 'react-select'
import { withRouter } from 'react-router-dom'
import { isNullOrUndefined } from 'util';
import TimePicker from '../TimePicker'

class AddEditOffer extends Component {

    constructor() {
        super()
        this.state = {
            key: '',
            name: '',
            description: '',
            priority: '',
            combineSelected: false,
            validityFrom: new Date().toISOString().split('T')[0],
            validityTo: new Date().toISOString().split('T')[0],
            specificDaySelected: '',
            specificDays: [],
            sundaySelected : false,
            mondaySelected : false,
            tuesdaySelected: false,
            wednesdaySelected: false,
            thursdaySelected:false,
            fridaySelected:false,
            saturdaySelected:false,
            specificTimeSelected: '',
            inTime: { HH: '', MM: '', AMPM: '' },
            outTime: { HH: '', MM: '', AMPM: '' },
            specificStoreSelected: false,
            allStoresSelected: false,
            associatedStores: [],
            stores: [],
            applicableCustomers: '',
            customerTags: [],
            offerType: '',
            offerTypeID: '',
            purchaseGroup: '',
            purchaseGroupID: '',
            purchaseQty: '',
            freeGroup: '',
            freeGroupID:'',
            freeQty: '',
            invoiceAmount: '',
            discount: '',
            discountValue: '',
            offerGroup: '',
            offerGroupID:'',
            productTags: [],

            nameError: '',
            priorityError: '',
            combineSelectedError: '',
            validityFromError: '',
            validityToError: '',
            specificDaySelectedError: '',
            specificTimeSelectedError: '',
            dayError: '',
            inTimeError: '',
            outTimeError: '',
            storeSelectionError: '',
            storeNameError: '',
            applicableCustomerError: '',
            typeError: '',
            op1PurchaseError: '',
            QtyError: '',
            freeError: '',
            invoiceAmountError: '',
            discountInError: '',
            valueError: '',
            offerGroupingError: '',
            saving: false,
            discountPercentageError:'',
            discountPercentage:0,
            spreadDiscount:false
        }
        this.onSaveOffer = this.onSaveOffer.bind(this)
        this.onProductTagChange = this.onProductTagChange.bind(this)
        this.handleOnChange = this.handleOnChange.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.onSpecificDaySelected = this.onSpecificDaySelected.bind(this)
    }

    componentDidMount() {

        var date = this.props.addDays(7);
        date = date.toISOString().split('T')[0]
        this.setState({validityTo:date});

        this.props.getStores(this.props.user.user.RegistrationID, 0, 10000, "active", "", "", "", "", "", "", ""
            , "", "", "", "").then((result) => {
                let stores = result.searchResult;
                const storeList = [];
                stores.map((store) => {
                    let obj = {
                        value: store.key, label: store.StoreName
                    }
                    storeList.push(obj);
                });
                this.setState({ stores: storeList })
            });

        this.props.getProductTags(this.props.user.user.RegistrationID).then((productTag) => {
            this.setState({ productTags: productTag })
        })

        const key = this.props.match.params.key
        if (key) {
            this.setState({ key: key })

            this.props.getOfferDetails(key).then((offer) => {
                this.setState({
                    key: key,
                    name: offer.Name,
                    description: offer.Description,
                    priority: offer.Priority ? offer.Priority.toString() : "",
                    combineSelected: offer.Combinable,
                    validityFrom: offer.ValidityFrom,
                    validityTo: offer.ValidityTo,
                    specificDaySelected: offer.SpecificDay,
                    specificDays: offer.SpecificDays,

                    specificTimeSelected: offer.SpecificTime,
                    inTime: offer.OfferStartTime ? offer.OfferStartTime :  { HH: '', MM: '', AMPM: '' },
                    outTime: offer.OfferEndTime ? offer.OfferEndTime :  { HH: '', MM: '', AMPM: '' },
                    specificStoreSelected: offer.SpecificStore,
                    allStoresSelected: offer.AllStores,
                    associatedStores: offer.Stores,
                    applicableCustomers: offer.CustomersTags,
                    offerTypeID: offer.OfferTypeID,
                    offerType: offer.OfferType,

                    purchaseGroup: offer.PurchaseGroup,
                    purchaseGroupID: offer.PurchaseGroupID,
                    purchaseQty: offer.PurchaseQty ? offer.PurchaseQty.toString() : "",
                    freeGroup: offer.FreeGroup,
                    freeGroupID: offer.FreeGroupID,
                    freeQty: offer.FreeQty ? offer.FreeQty.toString() : "",
                    invoiceAmount: offer.InvoiceAmount ? offer.InvoiceAmount.toString() : "",
                    discount: offer.Discount,
                    discountValue: offer.DiscountValue ? offer.DiscountValue.toString() : "",
                    offerGroup: offer.OfferGroup,
                    offerGroupID: offer.OfferGroupID,
                    discountPercentage:offer.hasOwnProperty('DiscountPercentage') ? offer.DiscountPercentage :0,
                    spreadDiscount: offer.hasOwnProperty('SpreadDiscount') ? offer.SpreadDiscount :false
                })
                if(offer.SpecificDays && offer.SpecificDays.length > 0)
                {
                    for (let index = 0; index < offer.SpecificDays.length; index++) {
                        const day = offer.SpecificDays[index];
                        if(day==="Sunday")
                        {
                            this.setState({sundaySelected:true});
                        }
                        if(day==="Monday")
                        {
                            this.setState({mondaySelected:true})
                        }
                        if(day==="Tuesday")
                        {
                            this.setState({tuesdaySelected:true})
                        }
                        if(day==="Wednesday")
                        {
                            this.setState({wednesdaySelected:true})
                        }
                        if(day==="Thursday")
                        {
                            this.setState({thursdaySelected:true})
                        }
                        if(day==="Friday")
                        {
                            this.setState({fridaySelected:true})
                        }
                        if(day==="Saturday")
                        {
                            this.setState({saturdaySelected:true})
                        }
                    }
                }                
            })
        }
    }

    onSaveOffer() {
        this.setState({ saving:true})
        let nameError = ""
        let priorityError = ""
        let validityFromError = ""
        let validityToError = ""
        let dayError = ""
        let inTimeError = ""
        let outTimeError = ""
        let storeSelectionError = ""
        let storeNameError = ""
        let applicableCustomerError = ""
        let typeError = ""
        let op1PurchaseError = ""
        let QtyError = ""
        let freeError = ""
        let invoiceAmountError = ""
        let discountInError = ""
        let valueError = ""
        let offerGroupingError = ""
        let discountPercentageError = ""
        

        if (this.state.name.trim() === "") {
            nameError = "Please provide offer name"
        }

        if (this.state.priority.trim() === "") {
            priorityError = "Please mention the priority"
        }

        if (this.state.validityFrom.trim() === "") {
            validityFromError = "Please provide validity start date"
        }

        if (this.state.validityTo.trim() === "") {
            validityToError = "Please provide validity end date"
        }

        if (this.state.validityFrom.trim() !== "" && this.state.validityTo.trim() !== "") {
            if (new Date(this.state.validityTo) < new Date(this.state.validityFrom)) {
                validityToError = "validity end date cannot be less than validity start date"
            }
        }

        if (this.state.specificDaySelected === "Yes") {
            if (this.state.sundaySelected  === false && this.state.mondaySelected  === false && this.state.tuesdaySelected  === false
                && this.state.wednesdaySelected === false && this.state.thursdaySelected  === false && this.state.fridaySelected  === false &&
                this.state.saturdaySelected === false) {
                dayError = "Please select specific day"
            }
        }

        if (this.state.specificTimeSelected === "Yes") {
            if (this.state.inTime.HH === '' || this.state.inTime.MM === '' || this.state.inTime.AMPM === '') {
                inTimeError = "Please specify In Time"
            }
            if (this.state.outTime.HH === '' || this.state.outTime.MM === '' || this.state.outTime.AMPM === '') {
                outTimeError = "Please specify Out Time"
            }
        }

        if (!this.state.allStoresSelected && !this.state.specificStoreSelected) {
            storeSelectionError = "Please select either All or Specific Store option."
        }

        if (this.state.specificStoreSelected) {
            if (this.state.associatedStores.length === 0) {
                storeNameError = "Please select store"
            }
        }

        // if (this.state.applicableCustomers.length === 0) {
        //     applicableCustomerError = "Please select applicable customers"
        // }

        if (this.state.offerType.trim() === "") {
            typeError = "Please select offer type"
        }

        if (this.state.offerType) {
            if (this.state.offerTypeID === "buyxgety") {
                if (this.state.purchaseGroup.trim() === "") {
                    op1PurchaseError = "Please provide purchase group"
                }
                if (this.state.purchaseQty.trim() === "") {
                    QtyError = "Please provide quantity"
                }
                if (this.state.freeGroup.trim() === "") {
                    freeError = "Please provide free product group"
                }
                if (this.state.freeQty.trim() === "") {
                    QtyError = "Please provide quantity"
                }
            }

            if (this.state.offerTypeID === "buyxqtygetydiscountpercentage") {
                if (this.state.purchaseGroup.trim() === "") {
                    op1PurchaseError = "Please provide purchase group"
                }
                if (this.state.purchaseQty.trim() === "") {
                    QtyError = "Please provide quantity"
                }
                if (this.state.discountPercentage.trim() === "") {
                    freeError = "Please provide discount percentage"
                }
            }
            if (this.state.offerTypeID === "invoicediscount") {
                if (this.state.invoiceAmount.trim() === "") {
                    invoiceAmountError = "Please provide invoice amount"
                }
                if (this.state.discount.trim() === "") {
                    discountInError = "Please provide discount type"
                }
                if (this.state.discountValue.trim() === "") {
                    valueError = "Please provide discount value"
                }
            }

            // if (this.state.offerTypeID === "invoicefreeitem") {
            //     if (this.state.invoiceAmount.trim() === "") {
            //         invoiceAmountError = "Please provide invoice amount"
            //     }
            //     if (this.state.freeGroup.trim() === "") {
            //         freeError = "Please provide free product group"
            //     }
            //     if (this.state.freeQty.trim() === "") {
            //         QtyError = "Please mention quantity"
            //     }
            // }

            // if (this.state.offerTypeID === "offergroupdiscountitem") {
            //     if (this.state.offerGroup.trim() === "") {
            //         offerGroupingError = "Please provide offer groups"
            //     }
            //     if (this.state.purchaseQty.trim() === "") {
            //         QtyError = "Please mention quantity"
            //     }
            //     if (this.state.discount.trim() === "") {
            //         discountInError = "Please provide discount in"
            //     }
            //     if (this.state.discountValue.trim() === "") {
            //         valueError = "Please provise discount value"
            //     }
            // }

            // if (this.state.offerTypeID === "offergroupdiscount") {
            //     if (this.state.offerGroup.trim() === "") {
            //         offerGroupingError = "Please provide offer groups"
            //     }
            //     if (this.state.purchaseQty.trim() === "") {
            //         QtyError = "Please provide quantity"
            //     }
            //     if (this.state.discount.trim() === "") {
            //         discountInError = "Please provide discount in"
            //     }
            //     if (this.state.discountValue.trim() === "") {
            //         valueError = "Please provise discount value"
            //     }
            // }

            // if (this.state.offerTypeID === "offergroupqtyfree") {
            //     if (this.state.offerGroup.trim() === "") {
            //         offerGroupingError = "Please provide offer groups"
            //     }
            //     if (this.state.purchaseQty.trim() === "") {
            //         QtyError = "Please provide quantity"
            //     }
            //     if (this.state.freeGroup.trim() === "") {
            //         freeError = "Please provide free product group"
            //     }
            //     if (this.state.freeQty.trim() === "") {
            //         QtyError = "Please mention quantity"
            //     }
            //     if (this.state.discount.trim() === "") {
            //         discountInError = "Please provide discount in"
            //     }
            //     if (this.state.discountValue.trim() === "") {
            //         valueError = "Please provise discount value"
            //     }
            // }

            // if (this.state.offerTypeID === "offergroupamtfree") {
            //     if (this.state.offerGroup.trim() === "") {
            //         offerGroupingError = "Please provide offer groups"
            //     }
            //     if (this.state.discountValue.trim() === "") {
            //         valueError = "Please provise offer group value"
            //     }
            //     if (this.state.freeGroup.trim() === "") {
            //         freeError = "Please provide free product group"
            //     }
            //     if (this.state.freeQty.trim() === "") {
            //         QtyError = "Please mention quantity"
            //     }
            // }
            if (this.state.discount.trim() === "perc") {
                if (this.state.value >= "100") {
                    valueError = "Percentage should be less than 100"
                }
            }
        }

        if (nameError !== "" || priorityError !== "" || dayError !== "" || inTimeError !== "" ||
            storeSelectionError !== "" || typeError !== "" ||
            op1PurchaseError !== "" || QtyError !== "" || freeError !== "" || invoiceAmountError !== "" ||
            discountInError !== "" || valueError !== "" || offerGroupingError !== "" || storeNameError !== "" ||
            validityFromError !== "" || validityToError !== "" || outTimeError !== "" || discountPercentageError!=="") {
            this.setState({
                nameError: nameError,
                priorityError: priorityError,
                validityFromError: validityFromError,
                validityToError: validityToError,
                dayError: dayError,
                inTimeError: inTimeError,
                outTimeError: outTimeError,
                storeSelectionError: storeSelectionError,
                storeNameError: storeNameError,
                // applicableCustomerError: applicableCustomerError,
                typeError: typeError,
                op1PurchaseError: op1PurchaseError,
                QtyError: QtyError,
                freeError: freeError,
                invoiceAmountError: invoiceAmountError,
                discountInError: discountInError,
                valueError: valueError,
                offerGroupingError: offerGroupingError,
                discountPercentageError:discountPercentageError,
                saving:false
            })
            return
        }

        let specificDays = []
        if (this.state.sundaySelected) 
        {
            specificDays.push("Sunday");
        }

        if (this.state.mondaySelected) 
        {
            specificDays.push("Monday");
        }

        if (this.state.tuesdaySelected) 
        {
            specificDays.push("Tuesday");
        }

        if (this.state.wednesdaySelected) 
        {
            specificDays.push("Wednesday");
        }

        if (this.state.thursdaySelected) 
        {
            specificDays.push("Thursday");
        }

        if (this.state.fridaySelected) 
        {
            specificDays.push("Friday");
        }

        if (this.state.saturdaySelected) 
        {
            specificDays.push("Saturday");
        }


        let offer = {
            RegistrationID: this.props.user.user.RegistrationID,
            Name: this.state.name,
            Description: this.state.description,
            Priority: this.state.priority ? Number(this.state.priority) : 0,
            Combinable: this.state.combineSelected,
            ValidityFrom: this.state.validityFrom,
            ValidityTo: this.state.validityTo,
            SpecificDay: this.state.specificDaySelected,
            SpecificDays: specificDays,
            SpecificTime: this.state.specificTimeSelected,
            OfferStartTime: this.state.inTime,
            OfferEndTime: this.state.outTime,
            SpecificStore: this.state.specificStoreSelected,
            AllStores: this.state.allStoresSelected,
            Stores: this.state.associatedStores,
            CustomersTags: this.state.applicableCustomers,
            PurchaseGroupID: this.state.purchaseGroupID,
            PurchaseGroup:this.state.purchaseGroup,
            PurchaseQty: this.state.purchaseQty ? Number(this.state.purchaseQty) : 0,
            FreeGroupID: this.state.freeGroupID,
            FreeGroup: this.state.freeGroup,
            FreeQty: this.state.freeQty ? Number(this.state.freeQty) : 0,
            InvoiceAmount: this.state.invoiceAmount ? Number(this.state.invoiceAmount) : 0,
            Discount: this.state.discount,
            DiscountValue: this.state.discountValue ? Number(this.state.discountValue) : 0,
            OfferGroupID: this.state.offerGroupID,
            OfferGroup: this.state.offerGroup,
            OfferType: this.state.offerType,
            OfferTypeID: this.state.offerTypeID,
            DiscountPercentage:this.state.discountPercentage,
            SpreadDiscount:this.state.spreadDiscount,
            IsDeleted : false,
        }
        this.props.saveOffer(offer, this.state.key).then(() => {
            this.props.getToastr("Offer saved successfully")
            setTimeout(function() { //Start the timer
                this.setState({ saving:false})
            }.bind(this), 1000)
            this.props.history.push('/home/catalogue/offer')
        });
    }

    onProductTagChange(e) {
        let productTag = {
            ProductTagID: e.target.value,
            Name: e.target.options[e.target.selectedIndex].text
        }
        this.setState({ productTags: productTag })
    }

    handleOnChange(storeList) {
        this.setState({ associatedStores: storeList, storeNameError: "" });
    }

    // onHandleChange(customerTagList) {
    //     this.setState({ applicableCustomers: customerTagList, applicableCustomerError: "" })
    // }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSaveOffer()
        }
    }

    onSpecificDaySelected = (e,day) => {
        let specificDays = this.state.specificDays;
        alert(e.checked + " " + day);
        if(e.checked)
        {
            specificDays.push(day);
        }
        this.setState({specificDays:specificDays});
    }

    render() {
        return <div className="content-wrapper">
            <section className="content-header">
                <h1>Offer</h1>
                <button type="submit" className="btn btn-flat btn-md btn-success pull-right"
                    onClick={this.onSaveOffer} style={{ marginTop: "-25px", marginLeft: "10px" }} disabled={this.state.saving}>Save</button>
                <Link to="/home/catalogue/offer" type="submit" className="btn btn-flat btn-md btn-default pull-right" style={{ marginTop: "-25px" }}>Cancel</Link>
            </section>
            <section className="content">
                <div className="row">
                    <div className="col-md-12">
                        <div className="box">
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className={this.state.nameError.trim() !== "" ? "form-group has-error" : "form-group "}>
                                            <label>Name</label>
                                            <input onKeyPress={this.handleKeyPress} type="text" className="form-control"
                                                onChange={(evt) => this.setState({ name: evt.target.value, nameError: "" })}
                                                value={this.state.name} />
                                            {this.state.nameError.trim() !== "" && <span className="help-block">{this.state.nameError}</span>}
                                        </div></div>
                                    <div className="col-md-6">
                                        <div className="form-group ">
                                            <label>Description</label>
                                            <input onKeyPress={this.handleKeyPress} type="text" className="form-control"
                                                onChange={(evt) => this.setState({ description: evt.target.value, descriptionError: "" })}
                                                value={this.state.description} />
                                        </div></div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className={this.state.priorityError !== "" ? "form-group has-error" : "form-group "}>
                                            <label>Priority</label>
                                            <input onKeyPress={this.handleKeyPress} type="number" className="form-control"
                                                onChange={(evt) => this.setState({ priority: evt.target.value, priorityError: "" })}
                                                value={this.state.priority} />
                                            {this.state.priorityError !== "" && <span className="help-block">{this.state.priorityError}</span>}
                                        </div></div>
                                    {/* <div className="col-md-3">
                                    <div className={this.state.combineSelectedError !== "" ? "form-group has-error" : "form-group "}>
                                        <label>Combinable</label>
                                        <select type="text" className="form-control"
                                            value={this.state.combineSelected}
                                            checked={this.state.combineSelected}
                                            onChange={(evt) => { this.setState({ combineSelected: evt.target.value, combineSelectedError: "" })}}>
                                                <option>Select</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                        {this.state.combineSelectedError !== "" && <span className="help-block">{this.state.combineSelectedError}</span>}                                        
                                    </div></div> */}
                                    <div className="col-md-3">
                                        <div className={this.state.validityFromError !== "" ? "form-group has-error" : "form-group "}>
                                            <label>Validity from</label>
                                            <input type="date" className="form-control" onKeyPress={this.handleKeyPress}
                                                onChange={(evt) => this.setState({ validityFrom: evt.target.value, validityFromError: "" })}
                                                value={this.state.validityFrom}
                                            />
                                            {this.state.validityFromError !== "" && <span className="help-block">{this.state.validityFromError}</span>}
                                        </div></div>
                                    <div className="col-md-3">
                                        <div className={this.state.validityToError !== "" ? "form-group has-error" : "form-group "}>
                                            <label>Validity to</label>
                                            <input type="date" className="form-control" onKeyPress={this.handleKeyPress}
                                                onChange={(evt) => this.setState({ validityTo: evt.target.value, validityToError: "" })}
                                                value={this.state.validityTo}
                                            />
                                            {this.state.validityToError !== "" && <span className="help-block">{this.state.validityToError}</span>}
                                        </div></div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className={this.state.specificDaySelectedError !== "" ? "form-group has-error" : "form-group "}>
                                            <label>Specific Day</label>
                                            <select type="text" className="form-control" value={this.state.specificDaySelected}
                                                onChange={(evt) => { this.setState({ specificDaySelected: evt.target.value, specificDaySelectedError: "" }) }}>
                                                <option>Select Day</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                            {this.state.specificDaySelectedError !== "" && <span className="help-block">{this.state.specificDaySelectedError}</span>}
                                        </div></div>
                                    <div className="col-md-3"></div>
                                    <div className="col-md-3">
                                        <div className={this.state.specificTimeSelectedError !== "" ? "form-group has-error" : "form-group "}>
                                            <label>Specific Time</label>
                                            <select type="text" className="form-control" 
                                                value={this.state.specificTimeSelected}
                                                onChange={(evt) => { this.setState({ specificTimeSelected: evt.target.value, specificTimeSelectedError: "", inTime:{ HH: '', MM: '', AMPM: '' }, outTime:{ HH: '', MM: '', AMPM: '' }}) }}>
                                                <option>Select Time</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                            {this.state.specificTimeSelectedError !== "" && <span className="help-block">{this.state.specificTimeSelectedError}</span>}
                                        </div></div>
                                    <div className="col-md-3"></div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        {this.state.specificDaySelected === "Yes" &&                                            
                                            <div className={this.state.dayError !== "" ? "form-group has-error" : "form-group "}>
                                                <div className="col-md-1">
                                                    <input type="checkbox" checked={this.state.sundaySelected}
                                                        value={this.state.sundaySelected}
                                                        onChange={() => { this.setState({ sundaySelected: !this.state.sundaySelected, dayError: "" }) }} />
                                                    <label><strong>Sun</strong></label>
                                                </div>
                                                <div className="col-md-1">
                                                    <input type="checkbox" checked={this.state.mondaySelected}
                                                        value={this.state.mondaySelected}
                                                        onChange={() => { this.setState({ mondaySelected: !this.state.mondaySelected, dayError: "" }) }} />
                                                    <label><strong>Mon</strong></label>
                                                </div>
                                                <div className="col-md-1">
                                                    <input type="checkbox" checked={this.state.tuesdaySelected}
                                                        value={this.state.tuesdaySelected}
                                                        onChange={() => { this.setState({ tuesdaySelected: !this.state.tuesdaySelected, dayError: "" }) }} />
                                                    <label><strong>Tue</strong></label>
                                                </div>
                                                <div className="col-md-1">
                                                    <input type="checkbox" checked={this.state.wednesdaySelected}
                                                        value={this.state.wednesdaySelected}
                                                        onChange={() => { this.setState({ wednesdaySelected: !this.state.wednesdaySelected, dayError: "" }) }} />
                                                    <label><strong>Wed</strong></label>
                                                </div>
                                                <div className="col-md-1">
                                                    <input type="checkbox" checked={this.state.thursdaySelected}
                                                        value={this.state.thursdaySelected}
                                                        onChange={() => { this.setState({ thursdaySelected: !this.state.thursdaySelected, dayError: "" }) }} />
                                                    <label><strong>Thu</strong></label>
                                                </div>
                                                <div className="col-md-1">
                                                    <input type="checkbox" checked={this.state.fridaySelected}
                                                        value={this.state.fridaySelected}
                                                        onChange={() => { this.setState({ fridaySelected: !this.state.fridaySelected, dayError: "" }) }} />
                                                    <label><strong>Fri</strong></label>
                                                </div>
                                                <div className="col-md-1">
                                                    <input type="checkbox" checked={this.state.saturdaySelected}
                                                        value={this.state.SaturdaySelected}
                                                        onChange={() => { this.setState({ saturdaySelected: !this.state.saturdaySelected, dayError: "" }) }} />
                                                    <label><strong>Sat</strong></label>
                                                </div>
                                                {this.state.dayError !== "" && <span className="help-block">{this.state.dayError}</span>}
                                            </div>}
                                    </div>
                                    {this.state.specificTimeSelected === "Yes" &&
                                        <div>
                                            <div className="col-md-3">
                                                <div className={this.state.inTimeError !="" ? "form-group has-error" : "form-group "} >
                                                    <label >From </label>
                                                    <TimePicker
                                                        time={this.state.inTime}
                                                        onChange={(time) => this.setState({ inTime: time, inTimeError: '' })} />
                                                    {this.state.inTimeError !="" && <span className="help-block">{this.state.inTimeError}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className={this.state.outTimeError!="" ? "form-group has-error" : "form-group "} >
                                                    <label >To</label>
                                                    <TimePicker
                                                        time={this.state.outTime}
                                                        onChange={(time) => this.setState({ outTime: time, outTimeError: '' })} />
                                                    {this.state.outTimeError!="" && <span className="help-block">{this.state.outTimeError}</span>}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Store Selection</label>
                                        </div></div>
                                    <div className="col-md-3">
                                        <div className={this.state.storeSelectionError ? "form-group has-error" : "form-group "} >
                                            <input type="radio" name="stores" value={this.state.allStoresSelected}
                                                checked={this.state.allStoresSelected} onKeyPress={this.handleKeyPress}
                                                onChange={() => { this.setState({ specificStoreSelected: false, allStoresSelected: true, associatedStores: [], storeSelectionError: "" }) }} />
                                            <label><strong>All Stores</strong></label>
                                            {this.state.storeSelectionError && <span className="help-block">{this.state.storeSelectionError}</span>}
                                        </div></div>
                                    <div className="col-md-3">
                                        <div className={this.state.storeSelectionError ? "form-group has-error" : "form-group "} >
                                            <input type="radio" name="stores" value={this.state.specificStoreSelected} checked={this.state.specificStoreSelected} onKeyPress={this.handleKeyPress}
                                                onChange={() => { this.setState({ specificStoreSelected: true, allStoresSelected: false, storeSelectionError: "" }) }} />
                                            <label><strong>Specific Stores</strong></label>
                                            {this.state.storeSelectionError && <span className="help-block">{this.state.storeSelectionError}</span>}
                                        </div></div>
                                    <div className="col-md-6"></div>
                                    <div className="col-md-6">
                                        {this.state.specificStoreSelected === true &&
                                            <div className={this.state.storeNameError !== "" ? "form-group has-error" : "form-group "}>
                                                <CreatableSelect
                                                    defaultValue={this.state.associatedStores}
                                                    options={this.state.stores}
                                                    onChange={this.handleOnChange}
                                                    isMulti
                                                />
                                                {this.state.storeNameError !== "" && <span className="help-block">{this.state.storeNameError}</span>}
                                            </div>}
                                    </div>
                                </div>
                                <div className="row">
                                    {/* <div className="col-md-6">
                                        <div className={this.state.applicableCustomerError !== "" ? "form-group has-error" : "form-group "}>
                                            <label >Applicable Customers</label>
                                            <CreatableSelect
                                                defaultValue={this.state.applicableCustomers}
                                                options={this.state.customerTags}
                                                onChange={this.onHandleChange}
                                                isMulti
                                            />
                                            {this.state.applicableCustomerError !== "" && <span className="help-block">{this.state.applicableCustomerError}</span>}
                                        </div>
                                    </div> */}
                                    <div className="col-md-6">
                                        <div className={this.state.typeError !== "" ? "form-group has-error" : "form-group "}>
                                            <label>Type</label>
                                            <select className="form-control" value={this.state.offerTypeID} onKeyPress={this.handleKeyPress}
                                                onChange={(evt) => this.setState({
                                                    offerTypeID: evt.target.value, offerType: evt.target.options[evt.target.selectedIndex].text, typeError: "", purchaseGroup: "", freeGroup: "", purchaseQty: "",
                                                    freeQty: "", invoiceAmount: "", discount: "", discountValue: "", offerGroup: ""
                                                })}>
                                                <option>Select</option>
                                                <option value="buyxgety">Buy X get Y free</option>
                                                <option value="buyxqtygetydiscountpercentage">Buy X get Y discount percentage</option>
                                                <option value="invoicediscount">Discount on invoice amount</option>
                                                {/* <option value="invoicefreeitem">Free item on invoice amount</option>
                                                <option value="offergroupdiscountitem">Discount on offer grouping qty(Discount on item)</option>
                                                <option value="offergroupdiscount">Discount on offer grouping amount</option>
                                                <option value="offergroupqtyfree">Free item on offer grouping qty</option>
                                                <option value="offergroupamtfree">Free item on offer grouping amount</option> */}
                                            </select>
                                            {this.state.typeError !== "" && <span className="help-block">{this.state.typeError}</span>}
                                        </div>
                                    </div>
                                    {this.state.offerTypeID === "buyxgety" && <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="checkbox" checked={this.state.spreadDiscount} onChange={() => this.setState({ spreadDiscount: !this.state.spreadDiscount })} />
                                            <label>Spread Free Amount Equally To All Offer Products</label>
                                        </div>
                                    </div>}
                                </div>
                                <div className="row">
                                    {(this.state.offerTypeID === "buyxgety" ||this.state.offerTypeID ===  "buyxqtygetydiscountpercentage") && <div>
                                        <div className="col-md-3">
                                            <div className={this.state.op1PurchaseError !== "" ? "form-group has-error" : "form-group "}>
                                                <label>Purchase Group</label>
                                                <select type="text" className="form-control" onKeyPress={this.handleKeyPress}
                                                    value={this.state.purchaseGroupID}
                                                    onChange={(evt) => this.setState({ purchaseGroupID: evt.target.value, purchaseGroup: evt.target.options[evt.target.selectedIndex].text, op1PurchaseError: "" })}>
                                                    <option value="">Select Product group</option>
                                                    {this.props.config.ProductTags.map((productTag) =>
                                                        <option value={productTag.key}>{productTag.Name}</option>
                                                    )}
                                                </select>
                                                {this.state.op1PurchaseError !== "" && <span className="help-block">{this.state.op1PurchaseError}</span>}
                                            </div></div>
                                        <div className="col-md-3">
                                            <div className={this.state.QtyError !== "" ? "form-group has-error" : "form-group "}>
                                                <label>Purchase Qty</label>
                                                <input onKeyPress={this.handleKeyPress} type="number" className="form-control"
                                                    onChange={(evt) => this.setState({ purchaseQty: evt.target.value, QtyError: "", typeError: "" })}
                                                    value={this.state.purchaseQty} />
                                                {this.state.QtyError !== "" && <span className="help-block">{this.state.QtyError}</span>}
                                            </div></div>
                                        {this.state.offerTypeID === "buyxgety" && <div className="col-md-3">
                                            <div className={this.state.freeError !== "" ? "form-group has-error" : "form-group "}>
                                                <label>Free Group</label>
                                                <select type="text" className="form-control" onKeyPress={this.handleKeyPress}
                                                    value={this.state.freeGroupID}
                                                    onChange={(evt) => this.setState({ freeGroupID: evt.target.value, freeGroup: evt.target.options[evt.target.selectedIndex].text, freeError: "" })}>
                                                    <option value="">Select Product group</option>
                                                    {this.props.config.ProductTags.map((productTag) =>
                                                        <option value={productTag.key}>{productTag.Name}</option>
                                                    )}
                                                </select>
                                                {this.state.freeError !== "" && <span className="help-block">{this.state.freeError}</span>}
                                            </div>
                                        </div>}
                                        {this.state.offerTypeID === "buyxgety" && <div className="col-md-3">
                                            <div className={this.state.QtyError !== "" ? "form-group has-error" : "form-group "}>
                                                <label>Free Qty</label>
                                                <input onKeyPress={this.handleKeyPress} type="number" className="form-control"
                                                    onChange={(evt) => this.setState({ freeQty: evt.target.value, QtyError: "", typeError: "" })}
                                                    value={this.state.freeQty} />
                                                {this.state.QtyError !== "" && <span className="help-block">{this.state.QtyError}</span>}
                                            </div>
                                        </div>}
                                        {this.state.offerTypeID === "buyxqtygetydiscountpercentage" && <div className="col-md-3">
                                            <div className={this.state.QtyError !== "" ? "form-group has-error" : "form-group "}>
                                                <label>Discount Percentage</label>
                                                <input onKeyPress={this.handleKeyPress} type="number" className="form-control"
                                                    onChange={(evt) => this.setState({ discountPercentage: evt.target.value, discountPercentageError: "", typeError: "" })}
                                                    value={this.state.discountPercentage} />
                                                {this.state.discountPercentageError !== "" && <span className="help-block">{this.state.discountPercentageError}</span>}
                                            </div>
                                        </div>}
                                    </div>}
                                    {this.state.offerTypeID === "invoicediscount" && <div>
                                        <div className="col-md-6">
                                            <div className={this.state.invoiceAmountError !== "" ? "form-group has-error" : "form-group "}>
                                                <label>Invoice Amount</label>
                                                <input onKeyPress={this.handleKeyPress} type="number" className="form-control"
                                                    onChange={(evt) => this.setState({ invoiceAmount: evt.target.value, invoiceAmountError: "" })}
                                                    value={this.state.invoiceAmount} />
                                                {this.state.invoiceAmountError !== "" && <span className="help-block">{this.state.invoiceAmountError}</span>}
                                            </div></div>
                                        <div className="col-md-3">
                                            <div className={this.state.discountInError !== "" ? "form-group has-error" : "form-group "}>
                                                <label>Discount Type</label>
                                                <select className="form-control" value={this.state.discount} onKeyPress={this.handleKeyPress}
                                                    onChange={(evt) => this.setState({ discount: evt.target.value, discountInError: "" })}>
                                                    <option>Select</option>
                                                    <option value="Percentage">Percentage</option>
                                                    <option value="Amount">Amount</option>
                                                </select>
                                                {this.state.discountInError !== "" && <span className="help-block">{this.state.discountInError}</span>}
                                            </div></div>
                                        <div className="col-md-3">
                                            <div className={this.state.valueError !== "" ? "form-group has-error" : "form-group "}>
                                                <label>Value</label>
                                                <input onKeyPress={this.handleKeyPress} type="number" className="form-control"
                                                    onChange={(evt) => this.setState({ discountValue: evt.target.value, valueError: "" })}
                                                    value={this.state.discountValue} />
                                                {this.state.valueError !== "" && <span className="help-block">{this.state.valueError}</span>}
                                            </div></div>
                                    </div>}
                                    {this.state.offerTypeID === "invoicefreeitem" && <div>
                                        <div className="col-md-6">
                                            <div className={this.state.invoiceAmountError !== "" ? "form-group has-error" : "form-group "}>
                                                <label>Invoice Amount</label>
                                                <input onKeyPress={this.handleKeyPress} type="text" className="form-control"
                                                    onChange={(evt) => this.setState({ invoiceAmount: evt.target.value, invoiceAmountError: "" })}
                                                    value={this.state.invoiceAmount} />
                                                {this.state.invoiceAmountError !== "" && <span className="help-block">{this.state.invoiceAmountError}</span>}
                                            </div></div>
                                        <div className="col-md-3">
                                            <div className={this.state.freeError !== "" ? "form-group has-error" : "form-group "}>
                                                <label>Free</label>
                                                <select type="text" className="form-control" onKeyPress={this.handleKeyPress}
                                                    value={this.state.freeGroupID}
                                                    onChange={(evt) => this.setState({ freeGroupID: evt.target.value, freeGroup: evt.target.options[evt.target.selectedIndex].text, freeError: "" })}>
                                                    <option value="">Select Product group</option>
                                                    {this.props.config.ProductTags.map((productTag) =>
                                                        <option value={productTag.key}>{productTag.Name}</option>
                                                    )}
                                                </select>
                                                {this.state.freeError !== "" && <span className="help-block">{this.state.freeError}</span>}
                                            </div></div>
                                        <div className="col-md-3">
                                            <div className={this.state.QtyError !== "" ? "form-group has-error" : "form-group "}>
                                                <label>Qty</label>
                                                <input onKeyPress={this.handleKeyPress} type="text" className="form-control"
                                                    onChange={(evt) => this.setState({ freeQty: evt.target.value, QtyError: "" })}
                                                    value={this.state.freeQty} />
                                                {this.state.QtyError !== "" && <span className="help-block">{this.state.QtyError}</span>}
                                            </div></div>
                                    </div>}
                                    {this.state.offerTypeID === "offergroupdiscountitem" && <div>
                                        <div className="col-md-3">
                                            <div className={this.state.offerGroupingError !== "" ? "form-group has-error" : "form-group "}>
                                                <label>Offer Grouping</label>
                                                <select type="text" className="form-control" onKeyPress={this.handleKeyPress}
                                                    value={this.state.offerGroupID}
                                                    onChange={(evt) => this.setState({ offerGroupID: evt.target.value, offerGroup: evt.target.options[evt.target.selectedIndex].text, offerGroupingError: "" })}>
                                                    <option value="">Select Product group</option>
                                                    {this.props.config.ProductTags.map((productTag) =>
                                                        <option value={productTag.key}>{productTag.Name}</option>
                                                    )}
                                                </select>
                                                {this.state.offerGroupingError !== "" && <span className="help-block">{this.state.offerGroupingError}</span>}
                                            </div></div>
                                        <div className="col-md-3">
                                            <div className={this.state.QtyError !== "" ? "form-group has-error" : "form-group "}>
                                                <label>Qty</label>
                                                <input onKeyPress={this.handleKeyPress} type="text" className="form-control"
                                                    onChange={(evt) => this.setState({ purchaseQty: evt.target.value, QtyError: "" })}
                                                    value={this.state.purchaseQty} />
                                                {this.state.QtyError !== "" && <span className="help-block">{this.state.QtyError}</span>}
                                            </div></div>
                                        <div className="col-md-3">
                                            <div className={this.state.discountInError !== "" ? "form-group has-error" : "form-group "}>
                                                <label>Discount Type</label>
                                                <select className="form-control" value={this.state.discount}
                                                    onChange={(evt) => this.setState({ discount: evt.target.value, discountInError: "" })}>
                                                    <option>Select</option>
                                                    <option value="Percentage">Percentage</option>
                                                    <option value="Amount">Amount</option>
                                                </select>
                                                {this.state.discountInError !== "" && <span className="help-block">{this.state.discountInError}</span>}
                                            </div></div>
                                        <div className="col-md-3">
                                            <div className={this.state.valueError !== "" ? "form-group has-error" : "form-group "}>
                                                <label>Value</label>
                                                <input onKeyPress={this.handleKeyPress} type="text" className="form-control"
                                                    onChange={(evt) => this.setState({ discountValue: evt.target.value, valueError: "" })}
                                                    value={this.state.discountValue} />
                                                {this.state.valueError !== "" && <span className="help-block">{this.state.valueError}</span>}
                                            </div></div>
                                    </div>}
                                    {this.state.offerTypeID === "offergroupdiscount" && <div>
                                        <div className="col-md-3">
                                            <div className={this.state.offerGroupingError !== "" ? "form-group has-error" : "form-group "}>
                                                <label>Offer Grouping</label>
                                                <select type="text" className="form-control"
                                                    value={this.state.offerGroupID}
                                                    onChange={(evt) => this.setState({ offerGroupID: evt.target.value,  offerGroup: evt.target.options[evt.target.selectedIndex].text, offerGroupingError: "" })}>
                                                    <option value="">Select Product group</option>
                                                    {this.props.config.ProductTags.map((productTag) =>
                                                        <option value={productTag.key}>{productTag.Name}</option>
                                                    )}
                                                </select>
                                                {this.state.offerGroupingError !== "" && <span className="help-block">{this.state.offerGroupingError}</span>}
                                            </div></div>
                                        <div className="col-md-3">
                                            <div className={this.state.QtyError !== "" ? "form-group has-error" : "form-group "}>
                                                <label>Qty</label>
                                                <input onKeyPress={this.handleKeyPress} type="text" className="form-control"
                                                    onChange={(evt) => this.setState({ purchaseQty: evt.target.value, QtyError: "" })}
                                                    value={this.state.purchaseQty} />
                                                {this.state.QtyError !== "" && <span className="help-block">{this.state.QtyError}</span>}
                                            </div></div>
                                        <div className="col-md-3">
                                            <div className={this.state.discountInError !== "" ? "form-group has-error" : "form-group "}>
                                                <label>Discount Type</label>
                                                <select className="form-control" value={this.state.discount}
                                                    onChange={(evt) => this.setState({ discount: evt.target.value, discountInError: "" })}>
                                                    <option>Select</option>
                                                    <option value="Percentage">Percentage</option>
                                                    <option value="Amount">Amount</option>
                                                </select>
                                                {this.state.discountInError !== "" && <span className="help-block">{this.state.discountInError}</span>}
                                            </div></div>
                                        <div className="col-md-3">
                                            <div className={this.state.valueError !== "" ? "form-group has-error" : "form-group "}>
                                                <label>Value</label>
                                                <input onKeyPress={this.handleKeyPress} type="text" className="form-control"
                                                    onChange={(evt) => this.setState({ discountValue: evt.target.value, valueError: "" })}
                                                    value={this.state.discountValue} />
                                                {this.state.valueError !== "" && <span className="help-block">{this.state.valueError}</span>}
                                            </div></div>
                                    </div>}
                                    {this.state.offerTypeID === "offergroupqtyfree" && <div>
                                        <div className="col-md-3">
                                            <div className={this.state.offerGroupingError !== "" ? "form-group has-error" : "form-group "}>
                                                <label>Offer Grouping</label>
                                                <select type="text" className="form-control" onKeyPress={this.handleKeyPress}
                                                    value={this.state.offerGroupID}
                                                    onChange={(evt) => this.setState({ offerGroupID: evt.target.value,  offerGroup: evt.target.options[evt.target.selectedIndex].text, offerGroupingError: "" })}>

                                                    {/* onChange={this.state.onProductTagChange}> */}
                                                    <option value="">Select Product group</option>
                                                    {this.props.config.ProductTags.map((productTag) =>
                                                        <option value={productTag.key}>{productTag.Name}</option>
                                                    )}
                                                </select>
                                                {this.state.offerGroupingError !== "" && <span className="help-block">{this.state.offerGroupingError}</span>}
                                            </div></div>
                                        <div className="col-md-3">
                                            <div className={this.state.QtyError !== "" ? "form-group has-error" : "form-group "}>
                                                <label>Qty</label>
                                                <input onKeyPress={this.handleKeyPress} type="text" className="form-control"
                                                    onChange={(evt) => this.setState({ purchaseQty: evt.target.value, QtyError: "" })}
                                                    value={this.state.purchaseQty} />
                                                {this.state.QtyError !== "" && <span className="help-block">{this.state.QtyError}</span>}
                                            </div></div>
                                        <div className="col-md-3">
                                            <div className={this.state.freeError !== "" ? "form-group has-error" : "form-group "}>
                                                <label>Free Offer Grouping</label>
                                                <select type="text" className="form-control" onKeyPress={this.handleKeyPress}
                                                    value={this.state.freeGroupID}
                                                    onChange={(evt) => this.setState({ freeGroupID: evt.target.value, freeGroup: evt.target.options[evt.target.selectedIndex].text, freeError: "" })}>                                                    
                                                    {/* onChange={(evt) => this.setState({ freeGroup: evt.target.value, freeError: "" })}> */}
                                                    <option value="">Select Product group</option>
                                                    {this.props.config.ProductTags.map((productTag) =>
                                                        <option value={productTag.key}>{productTag.Name}</option>
                                                    )}
                                                </select>
                                                {this.state.freeError !== "" && <span className="help-block">{this.state.freeError}</span>}
                                            </div></div>
                                        <div className="col-md-3">
                                            <div className={this.state.QtyError !== "" ? "form-group has-error" : "form-group "}>
                                                <label>Qty</label>
                                                <input onKeyPress={this.handleKeyPress} type="text" className="form-control"
                                                    onChange={(evt) => this.setState({ freeQty: evt.target.value, QtyError: "" })}
                                                    value={this.state.freeQty} />
                                                {this.state.QtyError !== "" && <span className="help-block">{this.state.QtyError}</span>}
                                            </div></div>
                                        <div>
                                            <div className="col-md-3">
                                                <div className={this.state.discountInError !== "" ? "form-group has-error" : "form-group "}>
                                                    <label>Discount Type</label>
                                                    <select className="form-control" value={this.state.discount} onKeyPress={this.handleKeyPress}
                                                        onChange={(evt) => this.setState({ discount: evt.target.value, discountInError: "" })}>
                                                        <option>Select</option>
                                                        <option value="Percentage">Percentage</option>
                                                        <option value="Amount">Amount</option>
                                                    </select>
                                                    {this.state.discountInError !== "" && <span className="help-block">{this.state.discountInError}</span>}

                                                </div></div>
                                            <div className="col-md-3">
                                                <div className={this.state.valueError !== "" ? "form-group has-error" : "form-group "}>
                                                    <label>Value</label>
                                                    <input onKeyPress={this.handleKeyPress} type="text" className="form-control"
                                                        onChange={(evt) => this.setState({ discountValue: evt.target.value, valueError: "" })}
                                                        value={this.state.discountValue} />
                                                    {this.state.valueError !== "" && <span className="help-block">{this.state.valueError}</span>}
                                                </div></div>
                                        </div>
                                    </div>}
                                    {this.state.offerTypeID === "offergroupamtfree" && <div>
                                        <div className="col-md-3">
                                            <div className={this.state.offerGroupingError !== "" ? "form-group has-error" : "form-group "}>
                                                <label>Offer Grouping</label>
                                                <select type="text" className="form-control" onKeyPress={this.handleKeyPress}
                                                    value={this.state.offerGroupID}
                                                    onChange={(evt) => this.setState({ offerGroupID: evt.target.value,  offerGroup: evt.target.options[evt.target.selectedIndex].text, offerGroupingError: "" })}>
                                                    
                                                    {/* onChange={(evt) => this.setState({ offerGroup: evt.target.value, offerGroupingError: "" })}> */}
                                                    <option value="">Select Product group</option>
                                                    {this.props.config.ProductTags.map((productTag) =>
                                                        <option value={productTag.key}>{productTag.Name}</option>
                                                    )}
                                                </select>
                                                {this.state.offerGroupingError !== "" && <span className="help-block">{this.state.offerGroupingError}</span>}
                                            </div></div>
                                        <div className="col-md-3">
                                            <div className={this.state.valueError !== "" ? "form-group has-error" : "form-group "}>
                                                <label>Amount</label>
                                                <input onKeyPress={this.handleKeyPress} type="text" className="form-control"
                                                    onChange={(evt) => this.setState({ discountValue: evt.target.value, valueError: "" })}
                                                    value={this.state.discountValue} />
                                                {this.state.valueError !== "" && <span className="help-block">{this.state.valueError}</span>}

                                            </div></div>
                                        <div className="col-md-3">
                                            <div className={this.state.freeError !== "" ? "form-group has-error" : "form-group "}>
                                                <label>Free Offer Grouping</label>
                                                <select type="text" className="form-control" onKeyPress={this.handleKeyPress}
                                                    value={this.state.freeGroupID}
                                                    onChange={(evt) => this.setState({ freeGroupID: evt.target.value, freeGroup: evt.target.options[evt.target.selectedIndex].text, freeError: "" })}>                                                    
                                                    {/* onChange={(evt) => this.setState({ freeGroup: evt.target.value, freeError: "" })}> */}
                                                    <option value="">Select Product group</option>
                                                    {this.props.config.ProductTags.map((productTag) =>
                                                        <option value={productTag.key}>{productTag.Name}</option>
                                                    )}
                                                </select>
                                                {this.state.freeError !== "" && <span className="help-block">{this.state.freeError}</span>}
                                            </div></div>
                                        <div className="col-md-3">
                                            <div className={this.state.QtyError !== "" ? "form-group has-error" : "form-group "}>
                                                <label>Qty</label>
                                                <input onKeyPress={this.handleKeyPress} type="text" className="form-control"
                                                    onChange={(evt) => this.setState({ freeQty: evt.target.value, QtyError: "" })}
                                                    value={this.state.freeQty} />
                                                {this.state.QtyError !== "" && <span className="help-block">{this.state.QtyError}</span>}
                                            </div></div>
                                    </div>}
                                </div>

                </div></div></div></div>
            </section>
            <button type="submit" className="btn btn-flat btn-md btn-success pull-right"
                onClick={this.onSaveOffer} style={{ marginTop: "-25px", marginLeft: "10px", marginRight: "15px" }} disabled={this.state.saving}>Save</button>
            <Link to="/home/catalogue/offer" type="submit" className="btn btn-flat btn-md btn-default pull-right" style={{ marginTop: "-25px" }}>Cancel</Link>

        </div>
    }
}

export default withRouter(AddEditOffer)