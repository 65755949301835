import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Print from '../Sales/Print'

class EditPackage extends Component {

    constructor() {
        super()

        this.state = {
            saving: false,
            employees: [],
            employee: null,
            key: '',
            packageServices: [],
            serviceDate: '',
            selectedService: {},
            employeeError: false,
            print: false,
            printChecked: false,
            printData: null,
        }
        this.onSavePackage = this.onSavePackage.bind(this);
    }

    componentDidMount() {
        const key = this.props.match.params.key;
        this.setState({ key: key });
        if (key) {
            this.props.getPackageDetails(key).then((data) => {

                let packageServices = [];
                for (let index = 0; index < data.PackageServices.length; index++) {
                    const service = data.PackageServices[index];
                    service.Employee = service.hasOwnProperty('Employee') ? service.Employee : { EmployeeID: '', EmployeeName: '' }
                    service.ServiceDate = service.hasOwnProperty('ServiceDate') ? service.ServiceDate : ''
                    service.Status = service.hasOwnProperty('Status') ? service.Status : "Pending"
                    service.Notes = service.hasOwnProperty('Notes') ? service.Notes : ''
                    service.errMessage = ""
                    packageServices.push(service);
                }

                let startDate = new Date(data.PackageStartDate.seconds * 1000).toString().substring(0, 24);
                startDate = new Date(startDate).toISOString().split('T')[0]
                let endDate = new Date(data.PackageEndDate.seconds * 1000).toString().substring(0, 24);
                endDate = new Date(endDate).toISOString().split('T')[0]

                this.setState({
                    packageCode: data.PackageCode,
                    packageStartDate: startDate,
                    packageEndDate: endDate,
                    customerName: data.CustomerName,
                    packageName: data.PackageName,
                    customerPhoneNo: data.CustomerPhoneNo,
                    packageCost: data.PackageCost,
                    customerEmailID: data.CustomerEmailID,
                    packageServices: packageServices,
                    packageCost: data.PackageCost,
                    transactionNo: data.TransactionNo,
                    packageValidityInDays: data.PackageValidityInDays,
                })
                this.props.getEmployees(this.props.user.user.RegistrationID, this.props.user.store.key,
                    0, 1000, "active", "", "", "", "", "",
                    "", "", "", "", "", ""
                ).then((employees) => {
                    this.setState({ employees: employees.searchResult })

                })
            })
        }
    }

    onSavePackage() {

        console.log(JSON.stringify(this.state.packageServices));
        this.setState({ saving: true })

        let completedCount = 0
        let status = 'Pending'

        for (let index = 0; index < this.state.packageServices.length; index++) {
            let packageService = this.state.packageServices[index]
            if (packageService.Status === 'Completed')
                completedCount += 1;
        }

        console.log(completedCount);
        if (completedCount > 0) {
            if (completedCount === this.state.packageServices.length) {
                status = 'Completed'
            }
            if (completedCount < this.state.packageServices.length) {
                status = 'Partial'
            }
        }
        let lineItemError = false
        let packageServices = this.state.packageServices
        for (let index = 0; index < packageServices.length; index++) {

            let packageService = packageServices[index]

            if ((packageService.ServiceDate.trim() === '' && packageService.Employee.EmployeeID.trim() != '') ||
                (packageService.ServiceDate.trim() != '' && packageService.Employee.EmployeeID.trim() === '')) {
                packageService.errMessage = "Edczcxzc"
                lineItemError = true
            }
        }

        if (lineItemError) {
            this.setState({ saving: false, packageServices: packageServices })
            return
        }

        let objPackage = {
            PackageServices: this.state.packageServices,
            PackageStatus: status,
        }

        const key = this.props.match.params.key
        if (key) {
            this.props.updatePackage(objPackage, key).then(() => {
                this.props.getToastr("Package updated Successfully")
                let packageTracker = {
                    PackageServices: this.state.packageServices,
                    PackageStatus: status,
                    PackageName: this.state.packageName,
                    PackageCost: this.state.packageCost,
                    TransactionNo: this.state.transactionNo,
                    CustomerName: this.state.customerName,
                    CustomerPhoneNo: this.state.customerPhoneNo,
                    CustomerEmailID: this.state.customerEmailID,
                    PackageStartDate: this.state.packageStartDate,
                    PackageEndDate: this.state.packageEndDate,
                    PackageValidityInDays: this.state.packageValidityInDays,
                }
                if (this.state.printChecked) {
                    let printConfig = {}
                    printConfig.PrintSize = "3 inch"
                    printConfig.PrintTemplate = this.props.registration.registration.hasOwnProperty("PackageTrackerTemplate") ? this.props.registration.registration.PackageTrackerTemplate : ""
                    let printData = {
                        packageTracker: packageTracker,
                        PrintConfig: printConfig
                    }
                    this.setState({ print: true, printData: printData })
                    console.log("printdata", JSON.stringify(printData))
                }

                setTimeout(function () {
                    this.setState({ saving: false })

                    this.props.history.push('/home/packages/packagelist')
                }.bind(this), 2000)
            })
            console.log(this.state.packageServices)
        }
    }

    render() {
        const styleEditor = {
            width: "100%",
            height: "70px",
            font_size: "14px",
            line_height: "18px",
            border: "1px solid #dddddd",
            padding: "10px"
        }
        return <div className="content-wrapper">
            <section className="content-header">
                <h1>Edit Package - {this.state.packageName + '(' + this.state.packageCode + ')'}</h1>
            </section>
            {!this.state.print && <section className="content">
                <div className="row">
                    <div className="box-body">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="box">
                                    <div className="box-header with-border">
                                        <h3 className="box-title">Package Details</h3>
                                    </div>
                                    <div className="box-body">
                                        <div className="row">
                                            <div className="col-md-2">
                                                <div className="form-group" >
                                                    <label >Start Date</label>
                                                    <span style={{ display: "block", fontWeight: "bold" }}>{this.state.packageStartDate}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="form-group" >
                                                    <label >End Date</label>
                                                    <span style={{ display: "block", fontWeight: "bold" }}>{this.state.packageEndDate}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="form-group">
                                                    <label >Package Cost</label>
                                                    <span style={{ display: "block", fontWeight: "bold" }}>{this.state.packageCost}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="form-group" >
                                                    <label >Customer Name</label>
                                                    <span style={{ display: "block", fontWeight: "bold" }}>{this.state.customerName}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="form-group" >
                                                    <label >Phone No</label>
                                                    <span style={{ display: "block", fontWeight: "bold" }}>{this.state.customerPhoneNo}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="form-group" >
                                                    <label >Email ID</label>
                                                    <span style={{ display: "block", fontWeight: "bold" }}>{this.state.customerEmailID}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="box">
                                    <div className="box-header with-border">
                                        <h3 className="box-title">Service-List</h3>
                                    </div>
                                    <div className="box-body">
                                        <div className="table-responsive scroll2" style={{

                                            height: "450px",
                                            overflowY: "scroll", overflowX: "hidden"
                                        }} >
                                            {this.state.packageServices.length > 0 && <table className="table no-margin">
                                                <tbody>
                                                    <tr>
                                                        <th>Service Name</th>
                                                        <th>Retail Price</th>
                                                        <th>Date</th>
                                                        <th>Employee Name</th>
                                                        <th>Notes</th>
                                                        <th>Status</th>
                                                    </tr>
                                                    {this.state.packageServices.map((service, index) =>
                                                        <tr>
                                                            <td >{service.Service}</td>
                                                            <td> <div>{service.RetailPrice}</div></td>
                                                            <td>
                                                                <div className={service.errMessage !== "" ? "form-group has-error" : "form-group "}>
                                                                    <input type="date" className="form-control"
                                                                        onChange={(evt) => {
                                                                            let packageServices = this.state.packageServices;
                                                                            let packageService = this.state.packageServices[index]
                                                                            packageService.errMessage = ''
                                                                            packageService.ServiceDate = evt.target.value
                                                                            if (packageService.Status === "Pending" && packageService.ServiceDate != '' && packageService.Employee.EmployeeID != '')
                                                                                packageService.Status = "Completed"

                                                                            packageServices[index] = packageService
                                                                            this.setState({ packageServices: packageServices })
                                                                        }}
                                                                        value={service.ServiceDate}
                                                                    />
                                                                    {service.errMessage !== "" && <span className="help-block">Please select Employee And date.</span>}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className={service.errMessage !== "" ? "form-group has-error" : "form-group "}>
                                                                    <select className="form-control"
                                                                        onKeyPress={this.handleKeyPress}
                                                                        onChange={(evt) => {
                                                                            let packageServices = this.state.packageServices;
                                                                            let packageService = this.state.packageServices[index]
                                                                            packageService.errMessage = ''
                                                                            packageService.Employee = { EmployeeID: '', EmployeeName: '' }
                                                                            this.state.employees.filter((e) => e.key === evt.target.value).map((f) => {
                                                                                f.EmployeeName = f.EmployeeFirstName + " " + f.EmployeeLastName
                                                                                packageService.Employee = { EmployeeID: f.key, EmployeeName: f.EmployeeName }
                                                                                if (packageService.Status === "Pending" && packageService.ServiceDate != '' && packageService.Employee.EmployeeID != '')
                                                                                    packageService.Status = "Completed"

                                                                                packageServices[index] = packageService
                                                                                this.setState({ packageServices: packageServices })
                                                                            })
                                                                            packageServices[index] = packageService
                                                                            this.setState({ packageServices: packageServices })
                                                                        }}
                                                                        value={service.Employee.EmployeeID}>
                                                                        <option value=''>Select Employee</option>
                                                                        {this.state.employees.map((e, index1) => <option key={index1} value={e.key}>{e.EmployeeFirstName + ' ' + e.EmployeeLastName}</option>)}
                                                                    </select>
                                                                    {service.errMessage !== '' && <span class="help-block">Please select Employee And date.</span>}
                                                                </div>
                                                            </td>
                                                            <td><textarea className="text" style={styleEditor}
                                                                onKeyPress={this.handleKeyPress}
                                                                onChange={(evt) => {
                                                                    let packageServices = this.state.packageServices;
                                                                    let packageService = this.state.packageServices[index]
                                                                    packageService.Notes = evt.target.value
                                                                    packageServices[index] = packageService
                                                                    this.setState({ packageServices: packageServices })
                                                                }}
                                                                value={service.Notes}>
                                                            </textarea></td>
                                                            <td>
                                                                {service.Status}</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>}
            {!this.state.print && <div className="col-md-12">
                <button type="submit" className="btn btn-flat btn-md btn-success pull-right"
                    onClick={this.onSavePackage} disabled={this.state.saving}
                >Save</button>
                <button className="btn btn-md btn-flat btn-default pull-right" style={{ marginRight: "10px" }}
                    onClick={() => this.props.history.push("/home/packages/packagelist")} >Cancel
                        </button>
                <div className="form-group pull-right" style={{ marginRight: "10PX" }}>
                    <input type="checkbox"
                        checked={this.state.printChecked}
                        onChange={() => this.setState({ printChecked: !this.state.printChecked })} />
                    <label>Print</label>
                </div>
            </div>}


            {this.state.print && <div>
                <Print  {...this.props}
                    printChecked={this.state.printChecked}
                    sendEmailChecked={false}
                    sendSMSChecked={false}
                    printData={this.state.printData}
                    onClose={() => {
                        this.setState({ print: false })
                        this.props.history.push('/home/packages/packagelist')
                    }
                    }
                />

            </div>}

        </div>
    }
}

export default withRouter(EditPackage)