import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Pagination from "react-js-pagination"
import Loader from '../Loader'
import Modal from 'react-responsive-modal'
import AuditTrail from '../AuditTrail'
import * as constants from '../../Constatnts'
import { isNullOrUndefined } from 'util'
import AddAttendance from './AddAttendance';

class AttendanceList extends Component {
    constructor() {
        super()
        this.state = {
            showModal: false,
            searchAttendanceToDate:new Date().toISOString().split('T')[0],
            searchAttendanceFromDate:new Date().toISOString().split('T')[0],
            attendanceList: [],
            attendance:null,
            activePage: 1,
            itemsCountPerPage: 20,
            totalItemsCount: 0,
            recordFrom: 0,
            paginationStatus: 'No records to display',
            loading: false,
            searchText:''
        }
        this.onClearSearch = this.onClearSearch.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.showPaginationStatus = this.showPaginationStatus.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.loadAttendance = this.loadAttendance.bind(this)
    }

    componentDidMount() {
        this.loadAttendance(this.state.recordFrom,this.state.itemsCountPerPage,this.state.searchText
            ,this.state.searchAttendanceFromDate,this.state.searchAttendanceToDate)
    }

    loadAttendance(from,size,searchText,searchAttendanceFromDate,searchAttendanceToDate)
    {
        this.setState({loading:true})
        this.props.getAttendance(from,size,this.props.user.user.RegistrationID,this.props.user.store.key,searchText,searchAttendanceFromDate,searchAttendanceToDate).then((result)=>{
            this.setState({attendanceList:result.searchResult,totalItemsCount: result.totalItemsCount, loading: false})
            this.showPaginationStatus()
        })        
    }

    onPostAttendanceSave(){
        this.setState({showModal:false, attendance: null});
        this.loadAttendance(this.state.recordFrom,this.state.itemsCountPerPage,this.state.searchText
            ,this.state.searchAttendanceFromDate,this.state.searchAttendanceToDate);
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }

    onClearSearch() {
        this.setState({recordFrom:0, searchText:'',
        searchAttendanceFromDate: new Date().toISOString().split('T')[0], 
        searchAttendanceToDate: new Date().toISOString().split('T')[0]})
        this.loadAttendance(0, this.state.itemsCountPerPage,''
        , new Date().toISOString().split('T')[0],new Date().toISOString().split('T')[0])
    }

    onSearchClick() {
        this.loadAttendance(this.state.recordFrom, this.state.itemsCountPerPage
            ,this.state.searchText, this.state.AttendanceFromDate,this.state.searchAttendanceToDate)
    }

    handlePageChange(pageNumber) {
        let recordFrom = ((pageNumber - 1) * this.state.itemsCountPerPage)
        this.setState({ activePage: pageNumber, recordFrom: recordFrom });
        this.loadAttendance(this.state.recordFrom, this.state.itemsCountPerPage
            ,this.state.searchText, this.state.AttendanceFromDate,this.state.searchAttendanceToDate)
    }

    showPaginationStatus() {
        let paginationStatus
        if ((this.state.recordFrom + 1) === this.state.totalItemsCount) {
            paginationStatus = "Showing " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else if ((this.state.recordFrom + this.state.itemsCountPerPage + 1) > this.state.totalItemsCount) {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + (this.state.recordFrom + this.state.itemsCountPerPage) + " of " + this.state.totalItemsCount

        }
        this.setState({ paginationStatus: paginationStatus })
    }


    render() {
        const btnColStyle = { width: "100px" }
        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6">
                                        <font style={{ fontSize: "30px" }}>Attendance</font>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="pull-right" style={{ marginTop: "6px" }}>
                                            <button className="btn btn-success btn-flat  btn-md" onClick={() => this.setState({ showModal: true, attendance:null })}>Create Attendance</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Search by employee name</label>
                                            <input type="text" className="form-control input-md"
                                                autoFocus={true}
                                                onKeyPress={this.handleKeyPress}
                                                value={this.state.searchText}
                                                onChange={(evt) => this.setState({ searchText: evt.target.value })} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Attendance From</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ searchAttendanceFromDate: evt.target.value })}
                                                value={this.state.searchAttendanceFromDate}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Attendance To</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ searchAttendanceToDate: evt.target.value })}
                                                value={this.state.searchAttendanceToDate}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginLeft: "5px" }}
                                            onClick={this.onSearchClick}
                                        >Search</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                            onClick={this.onClearSearch} >Clear</button>
                                    </div>
                                </div>
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading &&
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>Employee Name</th>
                                                <th>Date</th>
                                                <th>In Time</th>
                                                <th>Out Time</th>
                                                <th>Status</th>
                                                <th>Remarks</th>
                                                <th>Revision History</th>
                                                <th style={btnColStyle}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.attendanceList.map((attendance) =>
                                                <tr>
                                                    <td>{attendance.Employee.label}</td>
                                                    <td>{attendance.Date}</td>
                                                    {attendance.AttendanceType != "Leave" && attendance.AttendanceType != "Week-Off" && <td> {attendance.InTime.HH + ":" + attendance.InTime.MM + " " + attendance.InTime.AMPM}</td>}
                                                    {attendance.AttendanceType != "Leave" && attendance.AttendanceType != "Week-Off" && <td>{attendance.OutTime.HH + ":" + attendance.OutTime.MM + " " + attendance.OutTime.AMPM}</td>}
                                                    {attendance.AttendanceType === "Leave" || attendance.AttendanceType === "Week-Off" && <td></td>}
                                                    {attendance.AttendanceType === "Leave" || attendance.AttendanceType === "Week-Off" && <td></td>}
                                                    <td>{attendance.AttendanceType}</td>
                                                    <td>{attendance.Remarks}</td>
                                                    <td>
                                                    <div>Action :{attendance.Action}</div>
                                                    <div>Action By :{attendance.ActionBy}</div>
                                                    <div>Action Date :{(new Date(attendance.ActionOn)).toString().substring(0, 24)}</div>
                                                    <AuditTrail {...this.props} module={constants.MODULE_ATTENDANCE} parentKey={attendance.key} />
                                                </td>
                                                <td>
                                                    <div className="btn-group">
                                                        <div>
                                                            <button type="button" className="btn btn-md btn-flat btn-default"
                                                                onClick={() => this.setState({ showModal: true, attendance: attendance })}>
                                                                <i className="fa fa-edit"></i></button>
                                                        </div>
                                                    </div>
                                                </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>}
                                {/* {this.state.totalItemsCount === 0 && <div style={{ marginTop: "25px" }}>"No records found"</div>}  */}
                                {this.state.totalItemsCount > 0 && <div className="pull-left" style={{ marginTop: "25px" }}>
                                    {this.state.paginationStatus}
                                </div>}
                                {this.state.totalItemsCount > 0 && <div className="pull-right">
                                    <Pagination
                                        activePage={this.state.activePage} //this.state.activePage
                                        itemsCountPerPage={this.state.itemsCountPerPage}
                                        totalItemsCount={this.state.totalItemsCount}
                                        pageRangeDisplayed={2}
                                        onChange={this.handlePageChange}
                                    />
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <Modal open={this.state.showModal} onClose={() => this.setState({showModal:false})} center>
                    <div style={{width:"700px"}}>
                        <AddAttendance   {...this.props}
                            attendance={this.state.attendance}
                            onClose={() => this.setState({showModal:false})}
                            onSave={() => this.onPostAttendanceSave()} />
                    </div>
                </Modal>
            </section>
        </div>
    }
}

export default AttendanceList