import React, {Component} from 'react'
import * as actions from '../../actionTypes'

class AddEditProductTax extends Component{
    constructor () {
        super()
        this.state={
            saving: false,
            taxGroup:'',
            taxName:'',
            taxType:'',
            taxPercentage:0,
            taxComponents:[],
            key:'',
            action:'',
            taxGroupError:false,
            taxNames:[{ value: 'CGST', label: 'CGST' },
            { value: 'SGST', label: 'SGST' },
            { value: 'IGST', label: 'IGST' },
            { value: 'CESS', label: 'CESS' }
           ]
        }
        this.onAddTax = this.onAddTax.bind(this)
        this.onRemoveTax = this.onRemoveTax.bind(this)
        this.onSaveTax = this.onSaveTax.bind(this)
        this.onTaxNameChange = this.onTaxNameChange.bind(this)
    }

    componentDidMount(){  
        let taxGroup = ''
        let taxes = []
        let key=''
        if(this.props.action === actions.EDIT_PRODUCT_TAX)
        { 
            taxGroup=this.props.productTax.TaxGroup
            taxes=this.props.productTax.Taxes
            key = this.props.productTax.key
        }  
        this.setState({action:this.props.action, taxGroup:taxGroup, taxComponents:taxes, key:key})
     }

     onRemoveTax(index){
        let taxComponents = this.state.taxComponents
        taxComponents.splice(index,1 )
        this.setState({taxComponents:taxComponents})
    }

    onAddTax(){        
       
        if(this.state.taxName.trim()==="")
        {alert("Please provide tax name");return}
       if(this.state.taxPercentage < 0)
        {alert("Tax percentage should be greater than 0.");return}

        let taxComponent = {TaxName: this.state.taxName, 
                            TaxType: this.state.taxType,
                            TaxPercentage: this.state.taxPercentage}
        let taxComponents = this.state.taxComponents
        taxComponents.push(taxComponent)
        this.setState({taxComponents:taxComponents})    
    }

    onSaveTax(){
        this.setState({ saving:true})
            //console.log(this.state.taxComponents)
            if(this.state.taxComponents.length===0 )
            {
                alert('Please define tax components')
                return
            }
            let productTax = {TaxGroup: this.state.taxGroup, 
                       Taxes: this.state.taxComponents, 
                       RegistrationID: this.props.user.user.RegistrationID,
                        IsDeleted:false}
            this.props.saveProductTaxes(productTax, this.state.key).then(
                ()=>{
                    this.props.getToastr("Tax Saved Successfully")
                    setTimeout(function() { //Start the timer
                        this.setState({ saving:false})
                        this.props.onSave()
                    }.bind(this), 1000)
                })
    }
  
    onTaxNameChange(evt){
        this.setState({taxType: evt.target.value, 
            taxName: evt.target.value !== "Other" ?  evt.target.value : "" })
    }

    render(){
        const btnColStyle = {width:"120px"}
        const textStyle = {color:"white"}

        return <section className="content" >
            <section className="content-header">            
            {this.state.action === actions.ADD_NEW_TAX && <h1 style={{marginTop:"-25px"}}> Add New Tax</h1>}
            {this.state.action === actions.EDIT_PRODUCT_TAX && <h1 style={{marginTop:"-25px"}}> Edit Tax</h1>}
            </section>
            <div className="box-body">                            
                <div className={ this.state.brandError ? "form-group has-error": "form-group "} >
                    <label>Tax Group</label>
                    <input autoFocus type="text" className="form-control input-md" placeholder="enter tax group"
                        value={this.state.taxGroup}
                        onChange = {(evt)=> this.setState({taxGroup:evt.target.value})}/>   
                    {this.state.taxGroupError &&  <span class="help-block">Please provide tax group name</span> }        
                </div>               
            
                            {/* <div className="row"> */}
                                <table className = "table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th style={{width:"45%"}}>Tax Name</th>
                                            <th>Tax % </th>
                                            <th style={btnColStyle}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {this.state.taxType !== "Other" && <select className="form-control" 
                                                value={this.state.taxName} 
                                                onChange= {this.onTaxNameChange}>
                                                    <option value="">Select Tax Name</option>
                                                    <option value="CGST">CGST</option>
                                                    <option value="SGST">SGST</option>
                                                    <option value="IGST">IGST</option>
                                                    <option value="CESS">CESS</option>
                                                    <option value="Other">Other</option>
                                                </select>}
                                                {this.state.taxType === "Other" &&  <div className="input-group">
                                                    <input type="text" className="form-control" 
                                                    onChange={(evt)=> this.setState({taxName: evt.target.value})} 
                                                    value={this.state.taxName} />
                                                    <div className="input-group-btn">
                                                        <button type="button" class="btn btn-default btn-md" 
                                                        onClick={ () =>  this.setState({taxType:'',taxName:''})}>
                                                        <i class="glyphicon glyphicon-remove"></i> </button>
                                                    </div>
                                                </div>
                                                }
                                            </td>
                                            <td>
                                                <input type="number" className="form-control" 
                                                placeholder="enter tax percentage"
                                                onChange={(evt)=> this.setState({taxPercentage: evt.target.value})} 
                                                value={this.state.taxPercentage} />
                                            </td>
                                            <td>
                                                <button type="button" className="btn btn-default btn-md" onClick={this.onAddTax}>
                                                {/* <i className="glyphicon glyphicon-plus"></i> ADD TO LIST */}
                                                ADD TO LIST
                                                </button>  
                                            </td>
                                        </tr>                                    
                                        { this.state.taxComponents.map((taxComponent , index) =>       
                                        <tr>                                  
                                            <td>{taxComponent.TaxName}</td>
                                            <td>{taxComponent.TaxPercentage}</td>                              
                                            <td><div className="input-group-btn">
                                                <button type="button" className="btn btn-default btn-md" 
                                                onClick={() => this.onRemoveTax(index)}>
                                                <i className="glyphicon glyphicon-remove"></i>
                                                </button>
                                                </div>
                                            </td>
                                        </tr>)}                                                             
                                    </tbody> 
                                </table>          
                {/* </div> */}
            </div>
            <div className="box-footer">
                <button type="submit" className="btn btn-flat btn-lg btn-default" onClick={this.props.onClose} >Cancel</button>
                <button type="submit" className="btn btn-flat btn-lg btn-primary pull-right" onClick={this.onSaveTax} disabled={this.state.saving}>Save</button>
            </div>
        </section>
    }
}

export default AddEditProductTax