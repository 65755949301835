import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import * as constatnts from '../../Constatnts'
import { isNullOrUndefined } from 'util';
import Loader from '../Loader'


class CustomerReferenceDetail extends Component {
    constructor() {
        super()
        this.state = {
            month: '',
            monthYear:'',
            customers: [],
            exportCalled: false,
            searchText: '',
            dobFrom: new Date().toISOString().split('T')[0],
            dobTo: new Date().toISOString().split('T')[0],
            activePage: 1,
            itemsCountPerPage: 20,
            totalItemsCount: 0,
            recordFrom: 0,
            title: '',
            paginationStatus: 'No records to display',
            exportCalled: false,
            loading: true,
        }


        this.loadCustomerReferenceReport = this.loadCustomerReferenceReport.bind(this)
        this.onClearSearch = this.onClearSearch.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.onExportClick=this.onExportClick.bind(this)
    }

    componentDidMount() {
        let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        let month = new Date().getMonth()
        month = months[month];
        console.log(monthYear);
        let monthYear = month + " " + new Date().getFullYear()
        this.setState({ month: month, monthYear: monthYear});

        this.loadCustomerReferenceReport(this.state.dobFrom,
            this.state.dobTo,month)
    }

    loadCustomerReferenceReport(dobFrom, dobTo,month) {
        // let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

        // console.log("From", dobFrom);
        // console.log("To", dobTo);
        // let dobFrom1 = new Date(dobFrom).getDate()
        // let dobTo1 = new Date(dobTo).getDate()
        // let month = new Date(dobFrom).getMonth()
        // let month1 = months[month];
        // console.log(dobTo1 + "" + month1)


        this.setState({ loading: true })
        this.props.getCustomerReferenceReport(this.state.recordFrom, this.state.itemsCountPerPage, this.props.user.user.RegistrationID
            , this.props.user.store.key, month, dobFrom, dobTo)
            .then((result) => {
                let customers = result.searchResult;
                if(customers)
                {
                    let monthYear = this.state.monthYear;
                    monthYear = monthYear.split(" ")[1]

                    var oneDay = 24*60*60*1000; // hours*minutes*seconds*milliseconds
                    customers.map((customer) =>{
                        let currMonth = new Date().getMonth()+1
                        let dob = new Date(this.state.monthYear + "-" + customer.DOBNew.Month + "-" + customer.DOBNew.Day);
                        let currDate = new Date(new Date().getFullYear() + "-" + currMonth + "-" + new Date().getDate())
                        // let dueDays = Math.round(Math.abs((dob.getTime() - currDate.getTime())/(oneDay)));
                        let dueDays =(dob.getTime() - currDate.getTime())/(oneDay);
                        if(dueDays < 0)
                            dueDays = 0
                        customer.DueDays = dueDays;
                    })
                }
                this.setState({ customers: result.searchResult, totalItemsCount: result.totalItemsCount, loading: false })
            })
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }

    onExportClick() {
        this.setState({ exportCalled: true })
        this.props.getCustomerReferenceReport( 0, 10000,
            this.props.user.user.RegistrationID,
            this.props.user.store.key,
            this.state.month,
            this.state.dobFrom,
            this.state.dobTo,
            
            )
            .then((result) => {
                console.log(result.searchResult);
                if (isNullOrUndefined(result.searchResult) || result.searchResult.length === 0) {
                    this.props.getToastr("No Record Found")
                    return
                }
                let customers = []
                result.searchResult.map((data) => {
                    let monthYear = this.state.monthYear;
                    monthYear = monthYear.split(" ")[1]
                    var oneDay = 24*60*60*1000; // hours*minutes*seconds*milliseconds

                    let currMonth = new Date().getMonth()+1
                    let dob = new Date(this.state.monthYear + "-" + data.DOBNew.Month + "-" + data.DOBNew.Day);
                    let currDate = new Date(new Date().getFullYear() + "-" + currMonth + "-" + new Date().getDate())
                    let dueDays =(dob.getTime() - currDate.getTime())/(oneDay);
                    if(dueDays < 0)
                        dueDays = 0
                    
                    let customer = {
                        "Store Name ": this.props.user.store.StoreName,
                        "Customer Name":  data.FirstName + '' + data.LastName,
                        "Phone Number":  data.PhoneNo,
                        "Date Of Birth": data.DOBNew.Day + '-' + data.DOBNew.Month,
                        "Due In Days": dueDays,
                        "Referred By": data.ReferredByCustomer ? data.ReferredByCustomer.Name : "",
                        "Referred By Phone Number": data.ReferredByCustomer ? data.ReferredByCustomer.PhoneNo :  "",
                        "Relation": data.Relation ? data.Relation : ""
                    }
                    customers.push(customer);
                })
                const csvData = constatnts.objToCSV(customers);
                this.setState({  exportCalled: false })
                const csvBlob = new Blob([csvData], { type: "text/csv" });
                const url = window.URL.createObjectURL(csvBlob);
                const a = document.createElement("a");
                a.setAttribute("hidden", "");
                a.setAttribute("href", url);
                a.setAttribute("download", "CustomerReference.csv");
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
    }

    onClearSearch() {
        let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        let month = new Date().getMonth()
        month = months[month];
        let monthYear = month + " " + new Date().getFullYear()
        this.setState({ month: month, monthYear: monthYear});

        this.setState({
            recordFrom: 0, searchTransactionNo: '',
            dobFrom: new Date().toISOString().split('T')[0],
            dobTo: new Date().toISOString().split('T')[0],

        })
        this.loadCustomerReferenceReport(this.state.dobFrom, this.state.dobTo, month)
    }

    onSearchClick() {
        this.setState({ recordFrom: 0 })
        this.loadCustomerReferenceReport(
            this.state.dobFrom,
            this.state.dobTo,
            this.state.month
        )
    }

    showPaginationStatus() {
        console.log('this.state.recordFrom', this.state.recordFrom)
        let paginationStatus
        if ((this.state.recordFrom + 1) === this.state.totalItemsCount) {
            paginationStatus = "Showing " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else if ((this.state.recordFrom + this.state.itemsCountPerPage + 1) > this.state.totalItemsCount) {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + (this.state.recordFrom + this.state.itemsCountPerPage) + " of " + this.state.totalItemsCount

        }
        this.setState({ paginationStatus: paginationStatus })
    }

    render() {

        const btnColStyle = { width: "120px" }

        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6">
                                        <font style={{ fontSize: "30px" }}>Customer Reference Report</font>
                                    </div>
                                    <div className="col-md-6">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={() => this.props.history.push({ pathname: "/home/reports/allreport" })}
                                        ><i className="fa fa-arrow-left"></i> Back</button>
                                    </div>
                                </div>
                                <section className="content" >
                                    <div className="row">

                                        {/* <div className="col-md-2">
                                            <div className="form-group">
                                                <label>Date From</label>
                                                <input type="date" className="form-control"
                                                    onChange={(evt) => this.setState({ dobFrom: evt.target.value })}
                                                    value={this.state.dobFrom}
                                                    onKeyPress={this.handleKeyPress} />
                                            </div>
                                        </div>

                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label>Date To</label>
                                                <input type="date" className="form-control"
                                                    onChange={(evt) => this.setState({ dobTo: evt.target.value })}
                                                    value={this.state.dobTo}
                                                    onKeyPress={this.handleKeyPress} />
                                            </div>
                                        </div> */}

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Month</label>
                                                <select className="form-control"
                                                    onChange={(evt) => this.setState({ month: evt.target.value, monthYear:evt.target.options[evt.target.selectedIndex].text  })}
                                                    value={this.state.month}>
                                                    <option value="Dec">Dec 2018</option>
                                                    <option value="Jan">Jan 2019</option>
                                                    <option value="Feb">Feb 2019</option>
                                                    <option value="Mar">Mar 2019</option>
                                                </select>
                                            </div>
                                        </div>


                                        <div className="col-md-8">
                                            <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                                style={{ marginTop: "25px", marginLeft: "5px" }}
                                                onClick={this.onSearchClick}
                                            >Search</button>
                                            <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                                onClick={this.onClearSearch} >Clear</button>
                                            <button class="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px", marginRight: "5px" }} onClick={this.onExportClick} > Export <i class="glyphicon glyphicon-download-alt " ></i></button>

                                        </div>

                                    </div>
                                    {/* {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading && */}
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>Store Name</th>
                                                <th>Customer Name</th>

                                                <th>Phone Number</th>
                                                <th>Date of Birth</th>
                                                <th>Due In Days</th>
                                                <th>Referred By</th>
                                                <th>Referred By Phone Number</th>
                                                <th>Relation</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.customers.map((customer) =>
                                                <tr>
                                                    <td>{this.props.user.store.StoreName}</td>
                                                    <td>{customer.FirstName + ' ' + customer.LastName} </td>
                                                    <td>{customer.PhoneNo}</td>
                                                    <td>{customer.DOBNew && customer.DOBNew.Day + '-' + customer.DOBNew.Month}</td>
                                                    <td>{customer.DueDays}</td>
                                                    <td>{customer.ReferredByCustomer && customer.ReferredByCustomer.Name}</td>
                                                    <td>{customer.ReferredByCustomer && customer.ReferredByCustomer.PhoneNo}</td>
                                                    <td>{customer.Relation}</td>

                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    {/* }
                                {this.state.totalItemsCount > 0 && <div className="pull-left" style={{marginTop:"25px"}}>
                                {this.state.paginationStatus}
                                </div>}
                                <div className="pull-right">
                                    <Pagination
                                    activePage={this.state.activePage} //this.state.activePage
                                    itemsCountPerPage={this.state.itemsCountPerPage}
                                    totalItemsCount={this.state.totalItemsCount}
                                    pageRangeDisplayed={2}
                                    onChange={this.handlePageChange}
                                    />
                                </div> */}
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default withRouter(CustomerReferenceDetail)