
import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { Link } from 'react-router-dom'
import * as actionTypes from '../../actionTypes'
import AuditTrail from '../AuditTrail'
import * as constants from '../../Constatnts'
import Modal from 'react-responsive-modal'
import AddRegistrationSetting from '../../components/Settings/AddRegistrationSetting'

class Registration extends Component {

    constructor(props) {
        super(props);
        this.state = {
            firstLoad: false,
            businessName: '',
            businessType: '',
            contactNo: '',
            emailID: '',
            webSite: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
            showModal: false
        }
        this.onSaveGeneralDetails = this.onSaveGeneralDetails.bind(this)
    }

    componentDidMount() {
        if (!this.state.firstLoad) {
            this.setState({
                firstLoad: true,
                businessName: this.props.registration.registration.BusinessName,
                // businessType:this.props.registration.registration.BusinessType,
                contactNo: this.props.registration.registration.ContactNo,
                emailID: this.props.registration.registration.hasOwnProperty('EmailID') ? this.props.registration.registration.EmailID : '',
                webSite: this.props.registration.registration.hasOwnProperty('WebSite') ? this.props.registration.registration.WebSite : '',
                address1: this.props.registration.registration.hasOwnProperty('Address1') ? this.props.registration.registration.Address1 : '',
                address2: this.props.registration.registration.hasOwnProperty('Address2') ? this.props.registration.registration.Address2 : '',
                city: this.props.registration.registration.hasOwnProperty('City') ? this.props.registration.registration.City : '',
                state: this.props.registration.registration.hasOwnProperty('State') ? this.props.registration.registration.State : '',
                postalCode: this.props.registration.registration.hasOwnProperty('PostalCode') ? this.props.registration.registration.PostalCode : '',
                country: this.props.registration.registration.Country
            })
        }
    }
    onSaveGeneralDetails() {
        this.setState({ showModal: false })
        const generalDetails = {
            BusinessName: this.state.businessName,
            // BusinessType:this.state.businessType,
            ContactNo: this.state.contactNo,
            EmailID: this.state.emailID,
            WebSite: this.state.webSite,
            Address1: this.state.address1,
            Address2: this.state.address2,
            City: this.state.city,
            State: this.state.state,
            PostalCode: this.state.postalCode,
            Country: this.state.country
        }
        // console.log(generalDetails)
        // return
        this.props.updateRegistrationGeneralDetails(this.props.registration.registration.key, generalDetails).then(
            () => {
                this.props.getToastr("Registration saved successfully")
                this.props.getRegistration(this.props.registration.registration.key)
            })
    }

    render() {
        const modalWidth = { width: "300px" }

        return <div className="content-wrapper" >
            <section className="content-header">
                <h1>
                    Registration Details
                    </h1>
                <button type="submit"
                    style={{ marginTop: "-25px", marginLeft: "10px" }}
                    className="btn btn-default btn-md btn-flat pull-right"
                    onClick={() => this.setState({ showModal: true })}>Additional Setting</button>                    
            </section>

            <section className="content">
                <div className="row">
                    <div className="col-md-12">
                        <div className="box box-default">
                            <div className="box-header with-border">
                                <h3 className="box-title">General Details</h3>
                            </div>

                            <div className="box-body">
                                <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Business/Brand Name</label>
                                        <input type="text" name="BusinesName" className="form-control" disabled="true"
                                            value={this.state.businessName} />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Contact No</label>
                                        <input type="text" name="contactNo" className="form-control"
                                            placeholder="Please enter your contact no"
                                            value={this.state.contactNo}
                                            onChange={(evt) => this.setState({ contactNo: evt.target.value })}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Email ID</label>
                                        <input type="text" className="form-control"
                                            placeholder="Please enter your email Id"
                                            value={this.state.emailID}
                                            onChange={(evt) => this.setState({ emailID: evt.target.value })}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Website</label>
                                        <input type="text" name="webSite" className="form-control"
                                            placeholder="Please enter your Website"
                                            value={this.state.webSite}
                                            onChange={(evt) => this.setState({ webSite: evt.target.value })}
                                        />
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="box ">
                            <div className="box-header with-border">
                                <h3 className="box-title">Address</h3>
                            </div>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group" >
                                            <label >Line Address 1</label>
                                            <input type="text" className="form-control"
                                                onChange={(evt) => this.setState({ address1: evt.target.value })}
                                                value={this.state.address1}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Line Address 2</label>
                                            <input type="text" className="form-control"
                                                onChange={(evt) => this.setState({ address2: evt.target.value })}
                                                value={this.state.address2}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>City</label>
                                            <input type="text" className="form-control"
                                                onChange={(evt) => this.setState({ city: evt.target.value })}
                                                value={this.state.city}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group" >
                                            <label >State</label>
                                            <input type="text" className="form-control"
                                                onChange={(evt) => this.setState({ state: evt.target.value })}
                                                value={this.state.state}
                                            />
                                        </div>

                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group" >
                                            <label >Postal Code</label>
                                            <input type="text" className="form-control"
                                                onChange={(evt) => this.setState({ postalCode: evt.target.value })}
                                                value={this.state.postalCode}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group" >
                                            <label >Country</label>
                                            <input type="text" className="form-control"
                                                onChange={(evt) => this.setState({ country: evt.target.value })}
                                                value={this.state.country}
                                            />
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                        {/* <div className="box-footer">
                            <Link  to="/home"  className="btn btn-md btn-default btn-flat  pull-right">Cancel</Link>
                            <div className="pull-right">
                            <button className="btn btn-success btn-flat  btn-md" onClick={this.onSaveGeneralDetails}>Save</button>      
                            </div> */}

                        {this.props.registration.registration.key &&
                            <AuditTrail {...this.props} module={constants.MODULE_REGISTRATION} parentKey={this.props.registration.registration.key} />}

                        <button type="submit"
                            style={{ marginBottom: "10px", marginLeft: "10px" }}
                            className="btn btn-md btn-success btn-flat pull-right"
                            onClick={this.onSaveGeneralDetails}>Save
                                        </button>
                        <Link to="/home" className="btn btn-md btn-default btn-flat  pull-right">Cancel</Link>


                    </div>
                </div>
                <Modal open={this.state.showModal} onClose={() => this.setState({ showModal: false })} center>
                    <div style={modalWidth}>
                        <AddRegistrationSetting   {...this.props}
                            onClose={() => this.setState({ showModal: false })}
                            onSave={this.onSaveGeneralDetails}
                        />
                    </div>
                </Modal>
            </section>
        </div>

    }
}

export default Registration
