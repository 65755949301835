import firebase, { database } from '../firebase'
import * as actionTypes from '../actionTypes'

export function getCampaign(registrationID) {
    return (dispatch) => {
        return database.collection("Campaigns").where("RegistrationID", "==", registrationID).get().then((querySnapshot) => {
            const campaigns = []
            querySnapshot.forEach((doc) => {
                campaigns.push(
                    {
                        key: doc.id, ...doc.data()
                    }
                )
            })
            console.log("Get Campaign", campaigns);
            return campaigns
        }).catch((error) => { console.log(error) })
    }
}

export function saveCampaign(campaign, key) {
    return dispatch => {
        if (key !== "") {
            return database.collection("Campaigns").doc(key).update({
                ...campaign
            }).then(() => {
                return key
            }).catch(function (error) {
                console.error("Error updating customer membership: ", error);
            })
        }
        else {
            return database.collection("Campaigns").add({
                ...campaign
            })
                .then(function (docRef) {
                    return docRef.id
                })
                .catch(function (error) {
                    console.error("Error adding customer campaign: ", error);
                })
        }
    }
}


export function deleteCampaign(key) {
    return (dispatch) => {

        return database.collection("Campaigns").doc(key).update({
            IsDeleted: true
        }).then(() => { return true })
    }
}

export function getCampaignDetails(key) {
    return dispatch => {

        return database.collection("Campaigns").doc(key).get().then((doc) => {
            if (doc.exists) {
                console.log("Campaign found", doc.data());
                return doc.data()
            } else {
                console.log("No such campaign!");
            }
        })
    }
}


export function getCustomersForCampaign(searchParameters) {
    return (dispatch) => {

        console.log("CustomerList", JSON.stringify(searchParameters))
        let addMessage = firebase.functions().httpsCallable('getCustomers');
        return addMessage({ text: searchParameters }).then(function (result) {
            const customers = []
            let sanitizedMessage = result.data.text;
            sanitizedMessage.hits.hits.map((data, index) => {
                customers.push({ key: data._id, ...data._source })
            })
            return { totalItemsCount: sanitizedMessage.hits.total, searchResult: customers }
        });
    }
}


  export function getCustomersForTotalQty(searchParameters) {
    return (dispatch) => {
      console.log("CustomerList for total Qty",JSON.stringify(searchParameters))
      let addMessage = firebase.functions().httpsCallable('getSales');
      return addMessage({ text: searchParameters }).then(function (result) {
        const customers = []
        let sanitizedMessage = result.data.text;
        sanitizedMessage.hits.hits.map((data, index) => {
          customers.push({ key: data._id, ...data._source })
        })
        console.log("customers from Billqty",JSON.stringify(customers))
        return { totalItemsCount: sanitizedMessage.hits.total, searchResult: customers }

      });

    }
  }


export function getCustomersForTotalSpent(searchParameters) {
    return (dispatch) => {
        console.log("CustomerList for bill spent", JSON.stringify(searchParameters))
        let addMessage = firebase.functions().httpsCallable('getSales');
        return addMessage({ text: searchParameters }).then(function (result) {
            const customers = []
            let sanitizedMessage = result.data.text;
            sanitizedMessage.hits.hits.map((data, index) => {
                customers.push({ key: data._id, ...data._source })
            })
            console.log("customers from Billspent", JSON.stringify(customers))
            return { totalItemsCount: sanitizedMessage.hits.total, searchResult: customers }
        });
    }
}




export function getApplicableCampaigns() {
    return (dispatch, getState) => {
        return database.collection("Campaigns").where("IsDeleted", "==", false).
            get().then((querySnapshot) => {
                const campaigns = []
                querySnapshot.forEach((doc) => {
                    campaigns.push({
                        key: doc.id, ...doc.data()
                    })
                });

                let applicableCampaigns = [];
                var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                if (campaigns.length > 0) {
                    for (let index = 0; index < campaigns.length; index++) {
                        const campaign = campaigns[index];
                        if (campaign.Type === "One Time") {
                            if (new Date(campaign.StartDate).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)) {
                                applicableCampaigns.push(campaign)
                            }

                        }
                        if (campaign.Type === "Specific Day") {
                            if (new Date(campaign.StartDate).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0) && new Date().setHours(0, 0, 0, 0) <= new Date(campaign.EndDate).setHours(0, 0, 0, 0)) //start date 
                            {
                                if (campaign.OnDay != "") {

                                    if (campaign.OnDay.includes(days[new Date().getDay()])) {

                                        applicableCampaigns.push(campaign)
                                    }


                                }
                            }
                        }

                        if (campaign.Type === "Recurring") {

                            if (new Date(campaign.StartDate).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0) && new Date().setHours(0, 0, 0, 0) <= new Date(campaign.EndDate).setHours(0, 0, 0, 0)) //start date 
                            {
                                if (campaign.hasOwnProperty("LastRunDate")) {
                                    // var date = new Date(campaign.LastRunDate)
                                    var date = (new Date(campaign.LastRunDate.seconds * 1000)).toString().substring(0, 24)
                                    var date1 = new Date(date)
                                    var daysToAdd = campaign.RunsEvery
                                    console.log("daysToAdd", daysToAdd)
                                    date1.setDate(date1.getDate() + daysToAdd)
                                    date1 = date1.toString()
                                    console.log("final Date", date1)

                                    if (new Date(date1).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)) {
                                        applicableCampaigns.push(campaign)
                                    }

                                }

                                else {
                                    applicableCampaigns.push(campaign)
                                }

                            }
                        }
                    }
                }
                if (applicableCampaigns.length > 0) {

                    console.log(applicableCampaigns.length);

                    for (let index = 0; index < applicableCampaigns.length; index++) {
                        const element = applicableCampaigns[index];
                        database.collection("CampaignsToRun").add({
                            ...element
                        });

                    }
                }
                console.log(applicableCampaigns)
                return applicableCampaigns;
            }).catch((error) => { console.log(error) })
    }
}





