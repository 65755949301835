import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Pagination from "react-js-pagination"
import Loader from '../Loader'
import Modal from 'react-responsive-modal'
import Delivery from './Delivery'
import Confirm from 'react-confirm-bootstrap'
import { isNullOrUndefined } from 'util'
import * as constatnts from '../../Constatnts'

class DeliveryList extends Component {
    constructor() {
        super()
        this.state = {
            deliveryListDetail: [],
            selectedDelivery: null,
            searchCustomerDetail: '',
            searchTransactionNo: '',
            searchTransactionFromDate: new Date().toISOString().split('T')[0],
            searchTransactionToDate: new Date().toISOString().split('T')[0],
            cashRegisterList: [],
            cashRegister: {},
            searchCashRegister: '',
            searchEmployeeName: '',
            searchPendingReadyForPickupDeliveredAllRecords: 'pending',
            activePage: 1,
            itemsCountPerPage: 20,
            totalItemsCount: 0,
            recordFrom: 0,
            loading: false,
            showDelivery: false,
            showOutForDelivery: false,
            selectedEmployeeKey: '',
            selectedEmployee: '',
            sendSMSForOutForDelivery: false,
            SMSNo: '',
            employeeList: [],
            selectedSale: null
        }
        this.loadDeliveryDetailReport = this.loadDeliveryDetailReport.bind(this)
        this.onClearSearch = this.onClearSearch.bind(this)
        this.OnSearchPendingReadyForPickupDeliveredAllRecords = this.OnSearchPendingReadyForPickupDeliveredAllRecords.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.showPaginationStatus = this.showPaginationStatus.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.onDeliveryAction = this.onDeliveryAction.bind(this)
        this.onSaveOutForDelivery = this.onSaveOutForDelivery.bind(this)
        this.onExportClick = this.onExportClick.bind(this)
        // this.onEditClick = this.onEditClick.bind(this)
    }

    componentDidMount() {
        this.props.getCashRegisters(this.props.user.store.key).then((cashRegisters) => {
            this.setState({ cashRegisterList: cashRegisters })
        })
        this.props.getEmployees(this.props.user.user.RegistrationID, this.props.user.store.key,
            0, 1000, "active", "", "", "", "", "",
            "", "", "", "", "", ""
        ).then((employees) => {
            this.setState({ employeeList: employees.searchResult })
        })
        this.loadDeliveryDetailReport(this.state.recordFrom,
            this.state.searchPendingReadyForPickupDeliveredAllRecords,
            this.state.searchTransactionNo,
            this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate,
            this.state.searchCustomerDetail,
            this.state.searchCashRegister,
            this.state.searchEmployeeName
        )
    }

    loadDeliveryDetailReport(from, searchPendingReadyForPickupDeliveredAllRecords, searchTransactionNo, searchTransactionFromDate, searchTransactionToDate,
        searchCustomerDetail, searchCashRegister, searchEmployeeName) {
        this.setState({ loading: true })
        this.props.getDeliveryDetailReport(this.props.user.user.RegistrationID, from, this.state.itemsCountPerPage,
            searchPendingReadyForPickupDeliveredAllRecords, searchTransactionNo,
            searchTransactionFromDate, searchTransactionToDate,
            searchCustomerDetail, searchCashRegister, searchEmployeeName)
            .then((result) => {
                this.setState({ deliveryListDetail: result.searchResult, totalItemsCount: result.totalItemsCount, loading: false })
                this.showPaginationStatus()
            })
    }

    onClearSearch() {
        this.setState({
            recordFrom: 0,
            searchPendingReadyForPickupDeliveredAllRecords: 'pending',
            searchTransactionNo: '',
            searchTransactionToDate: new Date().toISOString().split('T')[0],
            searchTransactionFromDate: new Date().toISOString().split('T')[0],
            searchCustomerDetail: '', searchCashRegister: '', searchEmployeeName: ''
        })
        this.loadDeliveryDetailReport(0, "pending", "", this.state.searchTransactionFromDate, this.state.searchTransactionToDate, "", "", "")
    }

    OnSearchPendingReadyForPickupDeliveredAllRecords(option) {
        this.setState({ searchPendingReadyForPickupDeliveredAllRecords: option, recordFrom: 0 })
        this.loadDeliveryDetailReport(
            0,
            option,
            this.state.searchTransactionNo,
            this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate,
            this.state.searchCustomerDetail,
            this.state.searchCashRegister,
            this.state.searchEmployeeName
        )

    }

    onSearchClick() {
        this.setState({ searchFrom: 0 })
        this.loadDeliveryDetailReport(
            0,
            this.state.searchPendingReadyForPickupDeliveredAllRecords,
            this.state.searchTransactionNo,
            this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate,
            this.state.searchCustomerDetail,
            this.state.searchCashRegister,
            this.state.searchEmployeeName)
    }

    handlePageChange(pageNumber) {
        let recordFrom = ((pageNumber - 1) * this.state.itemsCountPerPage)
        this.setState({ activePage: pageNumber, recordFrom: recordFrom });
        this.loadDeliveryDetailReport(
            recordFrom,
            this.state.searchPendingReadyForPickupDeliveredAllRecords,
            this.state.searchTransactionNo,
            this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate,
            this.state.searchCustomerDetail,
            this.state.searchCashRegister,
            this.state.searchEmployeeName)
    }

    showPaginationStatus() {
        let paginationStatus
        if ((this.state.recordFrom + 1) === this.state.totalItemsCount) {
            paginationStatus = "Showing " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else if ((this.state.recordFrom + this.state.itemsCountPerPage + 1) > this.state.totalItemsCount) {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + (this.state.recordFrom + this.state.itemsCountPerPage) + " of " + this.state.totalItemsCount
        }
        this.setState({ paginationStatus: paginationStatus })
    }


    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }

    onExportClick() {
        this.setState({ exportCalled: true })
        this.props.getDeliveryDetailReport(this.props.user.user.RegistrationID, 0, 10000,
            "all", this.state.searchTransactionNo, this.state.searchTransactionFromDate, this.state.searchTransactionToDate,
            this.state.searchCustomerDetail, this.state.searchCashRegister, this.state.searchEmployeeName)
            .then((result) => {
                // console.log(result.searchResult);
                if (isNullOrUndefined(result.searchResult) || result.searchResult.length === 0) {
                    this.props.getToastr("No Record Found")
                    return
                }
                let deliveryLists = []
                result.searchResult.map((data) => {
                    let deliveryList = {
                        "Delivery Date": data.DeliveryDate,
                        // !isNullOrUndefined(data.Customer) ? data.Customer.FirstName + '' + data.Customer.LastName : "",
                        "Delivery Time" : data.DeliveryTime.HH + ':' + data.DeliveryTime.MM,
                        "Customer Name": data.DeliveryCustomer.FirstName,
                        "Customer PhoneNo.": !isNullOrUndefined(data.DeliveryCustomer.PhoneNo) ? data.DeliveryCustomer.PhoneNo : "",
                        "Customer EmailID": !isNullOrUndefined(data.DeliveryCustomer.EmailID) ? data.DeliveryCustomer.EmailID : "",
                        // "Delivery Address": !isNullOrUndefined(data.Gender) ? data.Gender : "",
                        "Transaction No": data.TransactionNo,
                        "Delivery Status" : data.DeliveryStatus,
                        "Employee Name" : !isNullOrUndefined(data.DeliveryOutForDeliveryEmployee) ? data.DeliveryOutForDeliveryEmployee : ""
                    }
                    deliveryLists.push(deliveryList);
                })
                const csvData = constatnts.objToCSV(deliveryLists);
                this.setState({ exportCalled: false })
                const csvBlob = new Blob([csvData], { type: "text/csv" });
                const url = window.URL.createObjectURL(csvBlob);
                const a = document.createElement("a");
                a.setAttribute("hidden", "");
                a.setAttribute("href", url);
                a.setAttribute("download", "deliveryList.csv");
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
    }

    onDeliveryAction(action, sale) {
        if (action === "outfordelivery") {
            //show popup to select employee an then update status    
            this.setState({
                selectedSale: sale,
                showOutForDelivery: true,
                SMSNo: sale.DeliveryCustomer.PhoneNo,
                sendSMSForOutForDelivery: sale.DeliveryCustomer.PhoneNo.trim() !== "" ? true : false
            })
        }

        if (action === "readyforpickup") {
            //show popup to select employee an then update status    
            this.setState({
                selectedSale: sale,
                showReadyForPickup: true,
                // SMSNo: sale.DeliveryCustomer.PhoneNo,
                // sendSMSForOutForDelivery: sale.DeliveryCustomer.PhoneNo.trim() !== "" ? true : false
            })
        }

        if (action === "delivered") {
            if (sale.key !== '') {
                // sale.DeliveryStatus = action
                this.props.markDelivered(sale).then(() => {
                    setTimeout(function () {
                    this.props.getToastr("Record saved successfully!!!")
                    this.setState({ searchFrom: 0 })
                    this.loadDeliveryDetailReport(
                        0,
                        this.state.searchPendingReadyForPickupDeliveredAllRecords,
                        this.state.searchTransactionNo,
                        this.state.searchTransactionFromDate,
                        this.state.searchTransactionToDate,
                        this.state.searchCustomerDetail,
                        this.state.searchCashRegister,
                        this.state.searchEmployeeName)
                    }.bind(this), 2000)
                })
            }

        }
    }

    onSaveOutForDelivery() {
        this.props.outForDelivery(this.state.selectedSale,
            this.state.selectedEmployee,
            this.state.selectedEmployeeKey,
            this.state.SMSNo).then(() => {
                setTimeout(function () {
                    this.props.getToastr("Record saved successfully!!!")
                    this.setState({ searchFrom: 0, showOutForDelivery: false });
                    this.loadDeliveryDetailReport(
                        0,
                        this.state.searchPendingReadyForPickupDeliveredAllRecords,
                        this.state.searchTransactionNo,
                        this.state.searchTransactionFromDate,
                        this.state.searchTransactionToDate,
                        this.state.searchCustomerDetail,
                        this.state.searchCashRegister,
                        this.state.searchEmployeeName)
                    }.bind(this), 2000)
                })
    }

    onSaveReadyForPickup() {
        this.props.readyForPickup(this.state.selectedSale,
            this.state.SMSNo).then(() => {
                setTimeout(function () {
                    this.props.getToastr("Record saved successfully!!!")
                    this.setState({ searchFrom: 0, showReadyForPickup: false });
                    this.loadDeliveryDetailReport(
                        0,
                        this.state.searchPendingReadyForPickupDeliveredAllRecords,
                        this.state.searchTransactionNo,
                        this.state.searchTransactionFromDate,
                        this.state.searchTransactionToDate,
                        this.state.searchCustomerDetail,
                        this.state.searchCashRegister,
                        this.state.searchEmployeeName);
                }.bind(this), 2000)
            })
    }

    render() {
        const btnColStyle = { width: "140px" }
        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6">
                                        <font style={{ fontSize: "30px" }}>Delivery List</font>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-12" style={{ marginBottom: "10px" }}>
                                        <div className="btn-group">
                                            <button type="button" className={this.state.searchPendingReadyForPickupDeliveredAllRecords === "pending" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.OnSearchPendingReadyForPickupDeliveredAllRecords("pending")}>Pending</button>
                                            <button type="button" className={this.state.searchPendingReadyForPickupDeliveredAllRecords === "outfordelivery" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.OnSearchPendingReadyForPickupDeliveredAllRecords("outfordelivery")}>Out For Delivery</button>
                                            <button type="button" className={this.state.searchPendingReadyForPickupDeliveredAllRecords === "readyforpickup" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.OnSearchPendingReadyForPickupDeliveredAllRecords("readyforpickup")}>Ready For Pickup</button>
                                            <button type="button" className={this.state.searchPendingReadyForPickupDeliveredAllRecords === "delivered" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.OnSearchPendingReadyForPickupDeliveredAllRecords("delivered")}>Delivered</button>
                                            <button type="button" className={this.state.searchPendingReadyForPickupDeliveredAllRecords === "all" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.OnSearchPendingReadyForPickupDeliveredAllRecords("all")}>All</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Search by customer name,email id, contact no</label>
                                            <input type="text"
                                                onKeyPress={this.handleKeyPress}
                                                autoFocus={true}
                                                className="form-control input-md"
                                                value={this.state.searchCustomerDetail}
                                                onChange={(evt) => this.setState({ searchCustomerDetail: evt.target.value })} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Employee Name</label>
                                            <input type="text" className="form-control input-md"
                                                onKeyPress={this.handleKeyPress}
                                                value={this.state.searchEmployeeName}
                                                onChange={(evt) => this.setState({ searchEmployeeName: evt.target.value })} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Transaction No</label>
                                            <input type="text" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionNo: evt.target.value })}
                                                value={this.state.searchTransactionNo}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Cash Register</label>
                                            <select type="text" className="form-control"
                                                onChange={(evt) => this.setState({ searchCashRegister: evt.target.value })}
                                                value={this.state.searchCashRegister}
                                                onKeyPress={this.handleKeyPress}>
                                                <option value="">All</option>
                                                {this.state.cashRegisterList.filter((e) => !e.IsDeleted).map((cashRegister) =>
                                                    <option value={cashRegister.key}>{cashRegister.CashRegisterCode}-{cashRegister.CashRegisterName}</option>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Transaction From</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionFromDate: evt.target.value })}
                                                value={this.state.searchTransactionFromDate}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Transaction To</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionToDate: evt.target.value })}
                                                value={this.state.searchTransactionToDate}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12" style={{marginBottom : "10px"}}>
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginLeft: "5px" }}
                                            onClick={this.onSearchClick}
                                        >Search</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                            onClick={this.onClearSearch} >Clear</button>
                                            <button class="btn btn-default btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginRight: "5px" }}
                                            onClick={this.onExportClick}
                                        >Export <i class="glyphicon glyphicon-download-alt " ></i></button>
                                    </div>
                                </div>
                                
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading &&
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th style={{ width: "10%" }}>Delivery Date</th>
                                                <th style={{ width: "20%" }}>Recepient Details</th>
                                                <th style={{ width: "20%" }}>Delivery Address</th>
                                                <th style={{ width: "30%" }}>Transaction Details</th>
                                                <th style={{ width: "10%" }}>Delivery Status</th>
                                                <th style={btnColStyle}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.deliveryListDetail.map((delivery) =>
                                                <tr>
                                                    <td>
                                                        {delivery.DeliveryDate}<br></br>
                                                        {delivery.DeliveryTime.HH}:{delivery.DeliveryTime.MM}
                                                    </td>
                                                    <td>
                                                        {delivery.DeliveryCustomer && <div>{delivery.DeliveryCustomer.FirstName}{delivery.DeliveryCustomer.LastName}</div>}
                                                        {delivery.DeliveryCustomer && <div>{delivery.DeliveryCustomer.PhoneNo}</div>}
                                                    </td>
                                                    <td>
                                                        {delivery.DeliveryCustomerAddress && <div>
                                                            {delivery.DeliveryCustomerAddress.AddressType}:
                                                                {delivery.DeliveryCustomerAddress.Address1}
                                                            {delivery.DeliveryCustomerAddress.Address2}{delivery.DeliveryCustomerAddress.City}
                                                            {delivery.DeliveryCustomerAddress.Country}{delivery.DeliveryCustomerAddress.PostalCode}
                                                            {delivery.DeliveryCustomerAddress.State}</div>
                                                        }
                                                    </td>
                                                    <td>
                                                        <div>{delivery.TransactionNo}</div>
                                                        <div>Note:{delivery.Note}</div>
                                                        <table className="table table-bordered table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th>Product</th>
                                                                    <th>Qty</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {delivery.LineItems.map(m => <tr>
                                                                    <td>{m.Product.Name}</td>
                                                                    <td>{m.Qty}</td>
                                                                </tr>)}
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                    {/* {this.state.searchPendingReadyForPickupDeliveredAllRecords === "all" &&  */}
                                                    <td>{delivery.DeliveryStatus}
                                                    {delivery.DeliveryOutForDeliveryEmployee && 
                                                        <div>Delivered By : {delivery.DeliveryOutForDeliveryEmployee}</div>}
                                                    </td>
                                                    <td>
                                                        {(delivery.DeliveryStatus.toLowerCase() !== "delivered" 
                                                         && delivery.DeliveryStatus.toLowerCase() !== "ready for pickup") && 
                                                        <div>
                                                            <button style={{ width: "100%" }} type="button" className="btn btn-md btn-default btn-flat"
                                                                onClick={() => this.setState({showDelivery: true,selectedDelivery: delivery})}>
                                                                <i className="fa fa-edit"></i>Edit</button>
                                                        </div>}
                                                        {delivery.DeliveryStatus.toLowerCase() === "pending" && <div>
                                                            <button style={{ width: "100%" }} type="button" className="btn btn-md btn-default btn-flat"
                                                                onClick={() => this.onDeliveryAction("outfordelivery", delivery)}>Out for Delivery</button>
                                                        </div>}
                                                        {delivery.DeliveryStatus.toLowerCase() === "pending" && <div>
                                                            <Confirm style={{ width: "100%" }} 
                                                                onConfirm={() => this.onDeliveryAction("readyforpickup", delivery)}
                                                                body="Are you sure you would like to mark this as Ready for Pickup?"
                                                                confirmText="Ready for Pickup"
                                                                title="Ready for Pickup">
                                                                <button style={{ width: "100%" }} className="btn btn-default btn-flat btn-md">Ready for Pickup</button>
                                                            </Confirm>
                                                            {/* <button style={{ width: "100%" }} type="button" className="btn btn-md btn-default btn-flat"
                                                                onClick={() => this.onDeliveryAction("readyforpickup", delivery)}>Ready for Pickup</button> */}
                                                        </div>}
                                                        {(delivery.DeliveryStatus.toLowerCase() === "pending"
                                                            || delivery.DeliveryStatus.toLowerCase() === "out for delivery"
                                                            || delivery.DeliveryStatus.toLowerCase() === "ready for pickup") && <div>
                                                            <Confirm style={{ width: "100%" }}
                                                                onConfirm={() => this.onDeliveryAction("delivered", delivery)}
                                                                body="Are you sure you would like to mark this as Delivered?"
                                                                confirmText="Delivered"
                                                                title="Delivered">
                                                                <button style={{ width: "100%" }} className="btn btn-default btn-flat btn-md">Delivered</button>
                                                            </Confirm>
                                                            {/* <button style={{ width: "100%" }} type="button" className="btn btn-md btn-default btn-flat"
                                                                    onClick={() => this.onDeliveryAction("delivered", delivery)}>Delivered</button> */}
                                                            </div>}
                                                    </td>
                                                </tr>)}
                                        </tbody>
                                    </table>}
                                    {/* {this.state.deliveryListDetail.length === 0 && "No records found"} */}
                                {this.state.totalItemsCount > 0 && <div className="pull-left" style={{ marginTop: "25px" }}>
                                    {this.state.paginationStatus}
                                </div>}
                                <div className="pull-right">
                                    <Pagination
                                        activePage={this.state.activePage} //this.state.activePage
                                        itemsCountPerPage={this.state.itemsCountPerPage}
                                        totalItemsCount={this.state.totalItemsCount}
                                        pageRangeDisplayed={2}
                                        onChange={this.handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.selectedDelivery && <Modal open={this.state.showDelivery}
                    onClose={() => this.setState({ showDelivery: false })} center>
                    <Delivery {...this.props}
                        deliveryCustomer={this.state.selectedDelivery.DeliveryCustomer}
                        deliveryCustomerAddress={this.state.selectedDelivery.DeliveryCustomerAddress}
                        deliveryDate={this.state.selectedDelivery.DeliveryDate}
                        deliveryTime={this.state.selectedDelivery.DeliveryTime}
                        deliveryNotes={this.state.selectedDelivery.Note}
                        onAddCustomer={(newCustomer) => { this.setState({ showAddCustomer: true, showInvoice: false, fromDelivery: true, showDelivery: false, newCustomer: newCustomer }) }}
                        onCustomerEdit={(deliveryCustomer) => { this.setState({ tmpDeliveryCustomer: deliveryCustomer, showAddCustomer: true, showInvoice: false, fromDelivery: true, showDelivery: false }) }}
                        onClose={() => this.setState({ showDelivery: false, fromDelivery: false })}
                        onSave={(deliveryCustomer, deliveryCustomerAddress, deliveryDate, deliveryTime, deliveryNotes, placeOfSupply) => {
                            this.setState({
                                deliveryCustomer: deliveryCustomer,
                                deliveryCustomerAddress: deliveryCustomerAddress,
                                deliveryDate: deliveryDate,
                                deliveryTime: deliveryTime,
                                deliveryNotes: deliveryNotes,
                                placeOfSupply: placeOfSupply,
                                showDelivery: false,
                                fromDelivery: false
                            })
                        }}
                    />
                </Modal>}
                <Modal open={this.state.showOutForDelivery}
                    onClose={() => this.setState({ showOutForDelivery: false })} center>
                    <div style={{ width: "400px" }}>
                        <h3 style={{ marginTop: "0px" }}>Out for delivery</h3>
                        <div className="box-body">
                            <div className="form-group">
                                <label >Employee</label>
                                <select className="form-control"
                                    onChange={(evt) => this.setState({
                                        selectedEmployeeKey: evt.target.value,
                                        selectedEmployee: evt.target.options[evt.target.selectedIndex].text
                                    })}
                                    value={this.state.selectedEmployeeKey}>
                                    <option value="">Select Employee</option>
                                    {this.state.employeeList.map((e, index) => <option key={index} value={e.key}>{e.EmployeeFirstName + ' ' + e.EmployeeLastName}</option>)}
                                </select>
                            </div>
                            
                            <div class="form-group">
                                <input type="checkbox"
                                    onChange={() => this.setState({ sendSMSForOutForDelivery: !this.state.sendSMSForOutForDelivery })}
                                    checked={this.state.sendSMSForOutForDelivery}
                                />
                                <label>Send SMS</label>
                                <input type="number"
                                    disabled={!this.state.sendSMSForOutForDelivery}
                                    onChange={(evt => this.setState({ SMSNo: evt.target.value }))}
                                    value={this.state.SMSNo}
                                    className="form-control" />
                            </div>
                        </div>
                        <div className="box-footer">
                            <button type="submit" className="btn btn-flat btn-md btn-default"
                                onClick={() => this.setState({ showOutForDelivery: false })} >Cancel</button>
                            <button type="submit" className="btn btn-flat btn-md btn-success pull-right"
                                onClick={() => this.onSaveOutForDelivery()}>OK</button>
                        </div>
                    </div>
                </Modal>
                <Modal open={this.state.showReadyForPickup}
                    onClose={() => this.setState({ showReadyForPickup: false })} center>
                    <div style={{ width: "400px" }}>
                        <h3 style={{ marginTop: "0px" }}>Ready For Pickup</h3>
                        <div className="box-body">
                            <div class="form-group">
                                <input type="checkbox"
                                    onChange={() => this.setState({ sendSMSForOutForDelivery: !this.state.sendSMSForOutForDelivery })}
                                    checked={this.state.sendSMSForOutForDelivery}
                                />
                                <label>Send SMS</label>
                                <input type="number"
                                    disabled={!this.state.sendSMSForOutForDelivery}
                                    onChange={(evt => this.setState({ SMSNo: evt.target.value }))}
                                    value={this.state.SMSNo}
                                    className="form-control" />
                            </div>
                        </div>
                        <div className="box-footer">
                            <button type="submit" className="btn btn-flat btn-md btn-default"
                                onClick={() => this.setState({ showReadyForPickup: false })} >Cancel</button>
                            <button type="submit" className="btn btn-flat btn-md btn-success pull-right"
                                onClick={() => this.onSaveReadyForPickup()}>OK</button>
                        </div>
                    </div>
                </Modal>
            </section>
        </div>
    }
}

export default withRouter(DeliveryList)