import React, { Component } from 'react';
import Confirm from 'react-confirm-bootstrap'
import Modal from 'react-responsive-modal'
import AddEditProductTax from './AddEditProductTax'
import * as actions from '../../actionTypes'
import Loader from '../Loader'
import AuditTrail from '../AuditTrail'
import * as constants from '../../Constatnts'
import Pagination from "react-js-pagination"

class ProductTaxList extends Component{
    constructor () {
        super()
        this.state = {
            isShowingModalAddTax:false,
            selectedProductTax:null,
            action:'',
            loading: true,
            searchActiveInactiveAllRecords: 'active',
            taxes:[]
        }
        this.loadProductTaxes = this.loadProductTaxes.bind(this)
        this.onPostTaxSave = this.onPostTaxSave.bind(this)
        this.onDeleteTaxes = this.onDeleteTaxes.bind(this)
    }

    componentDidMount() {
        this.props.getProductTaxes(this.props.user.user.RegistrationID).then(()=>
        {
            this.loadProductTaxes(this.state.searchActiveInactiveAllRecords)    
        })
    }
        
    loadProductTaxes(option)
    {
        let allTaxes = this.props.config.ProductTaxes
        let taxes= []
        if(option === "all")
        {
            taxes = allTaxes 
            
        }
        else if(option === "active")
        {
            taxes = allTaxes.filter((e)=> !e.IsDeleted)
        }
        else if(option ==="inactive")
        {
            taxes = allTaxes.filter((e)=> e.IsDeleted)
        }

        this.setState({ loading : false, taxes:taxes, searchActiveInactiveAllRecords:option })
    }

    onPostTaxSave()
    {
        this.setState({isShowingModalAddTax:false})
        this.loadPaymentTypes(this.state.searchActiveInactiveAllRecords)
    }

    onDeleteTaxes(tax){
        this.props.deleteProductTax(tax.key).then(() => {
            this.props.getToastr("Tax deleted successfully")
            this.loadPaymentTypes(this.state.searchActiveInactiveAllRecords)
        })
    }
    
    render() {    
        const btnColStyle = {width:"95px"}
        const revisionHistory = {width:"200px"}    
        const modalWidth = {width:"500px"}
        
        return <div className="content-wrapper" >
        <section className="content" >
            <div className="row">
                <div className="col-md-12">
                    <div className="box ">
                        <div className="box-header with-border">
                            <div className="row">                                
                                <div className="col-md-6">
                                    <font style={{fontSize:"30px"}}>Tax</font>
                                </div>
                                <div className="col-md-6">
                                    <div className="pull-right" style={{marginTop:"6px"}}>                                        
                                         <button type="button" className="btn btn-success btn-md btn-flat" 
                                         onClick={ () =>  this.setState({isShowingModalAddTax:true, 
                                            selectedProductTax:null, 
                                            action:actions.ADD_NEW_TAX})}>Add Tax</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box-body">
                        <div className="row">
                                    <div className="col-md-12" style={{ marginBottom: "10px" }}>
                                        <div className="btn-group">
                                            <button type="button" className={this.state.searchActiveInactiveAllRecords === "active" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.loadProductTaxes("active")}>Active</button>
                                            <button type="button" className={this.state.searchActiveInactiveAllRecords === "inactive" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.loadProductTaxes("inactive")}>In Active</button>
                                            <button type="button" className={this.state.searchActiveInactiveAllRecords === "all" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.loadProductTaxes("all")}>All</button>
                                        </div>
                                    </div>
                                </div>
                        {this.state.loading && <Loader show={this.state.loading} />}

                        {!this.state.loading &&
                            <table className = "table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Tax Group</th>      
                                        <th>Taxes</th>
                                        <th style={revisionHistory}>Revision History</th>
                                        <th style={btnColStyle}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.taxes.map((tax) =>                                 
                                    <tr>    
                                        <td>{tax.TaxGroup}</td>                                    
                                        <td>{tax.Taxes.map((tax)=> <div>{tax.TaxName}-{tax.TaxPercentage}%</div>)}</td>                                               
                                        <td>
                                        {tax.hasOwnProperty('Action') && <React.Fragment>
                                            <div>Action :{tax.Action}</div>
                                            <div>Action By :{tax.ActionBy}</div>                                                        
                                            <div>Action Date :{(new Date(tax.ActionOn.seconds*1000)).toString().substring(0,24)}</div>
                                            <AuditTrail {...this.props} module={constants.MODULE_PRODUCT_TAX} parentKey={tax.key}/>                                                                                                
                                            </React.Fragment>}
                                        </td>
                                        <td>
                                            <div className="btn-group">
                                            { tax.IsDeleted ? <span className="label label-danger pull-right">Inactive</span> : 
                                                <div>
                                                    <button type="button" className="btn btn-md btn-default btn-flat" 
                                                    onClick={() =>  this.setState({isShowingModalAddTax:true, 
                                                        selectedProductTax:tax, 
                                                        action:actions.EDIT_PRODUCT_TAX})
                                                }>
                                                    <i className="fa fa-edit"></i></button>
                                                    <Confirm
                                                        onConfirm={() => this.onDeleteTaxes(tax)}
                                                        body="Are you sure you would like to delete this tax?"
                                                        confirmText="Delete"
                                                        title="Tax Delete">
                                                        <button className="btn btn-default btn-flat"><i className="fa fa-trash-o"></i></button>
                                                    </Confirm>
                                                </div>    
                                            }
                                            </div>
                                        </td>
                                        </tr>
                                    )}                                                             
                                </tbody>
                            </table>}                            
                        </div>   
                    </div>
                </div>
                </div>
                <Modal open={this.state.isShowingModalAddTax} onClose={() => this.setState({isShowingModalAddTax:false})} center>
                    <div style={modalWidth}>
                    <AddEditProductTax   
                    {...this.props} 
                    action = {this.state.action}
                    productTax = {this.state.selectedProductTax}
                    onSave = {() => {this.onPostTaxSave()}}
                    onClose={() => this.setState({isShowingModalAddTax:false})}/>
                    </div>  
                </Modal>
            </section>
        </div>   
    }
}

export default ProductTaxList