import firebase, { database } from '../firebase'
import * as actionTypes from '../actionTypes'
import * as constants from '../Constatnts'

export function getPaymentTypes(registrationID) {
    return (dispatch) => {
            return database.collection("PaymentTypes").where("RegistrationID", "==", registrationID).orderBy("SequenceNo", "asc").get().then((querySnapshot) => {
                let paymentTypes = []
                querySnapshot.forEach((doc) => {
                    paymentTypes.push({ key: doc.id, ...doc.data() })
                })
                //return paymentTypes
                //console.log('paymentTypes', paymentTypes)
                dispatch({ type: actionTypes.PAYMENT_TYPES_GET, paymentTypes })
            })
        }
}

export function deletePaymentType(key) {
    return (dispatch, getState) => {
        return database.collection("PaymentTypes").doc(key).update({
            ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
            ActionBy: getState().user.user.Name,
            ActionByUID: getState().user.user.UID,
            ActionByEmailID: getState().user.user.EmailID,
            Action: constants.AUDIT_TRAIL_DELETE,
            IsDeleted:true
        }).then(() => { return true })
    }
}

export function savePaymentType(paymentType, key) {
    return (dispatch, getState) => {
        paymentType.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        paymentType.ActionBy = getState().user.user.Name
        paymentType.ActionByUID = getState().user.user.UID
        paymentType.ActionByEmailID = getState().user.user.EmailID

        //console.log('paymentType', paymentType)
        //console.log('key', key)

        if (key.trim() !== "") {
            paymentType.Action = constants.AUDIT_TRAIL_EDIT
            return database.collection("PaymentTypes").doc(key).update({
                ...paymentType
            }).then(() => {
                return key
            }).catch(function (error) {
                console.error("Error updating payment type: ", error);
            })
        }
        else {
            //console.log('new paymentType', paymentType)
            paymentType.Action = constants.AUDIT_TRAIL_NEW
            return database.collection("PaymentTypes").add({
                ...paymentType
            })
                .then(function (docRef) {
                    //console.log(docRef.id)
                    return docRef.id
                })
                .catch(function (error) {
                    console.error("Error adding payment type: ", error);
                })
        }

    }
}
