import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { auth } from '../firebase'

class ChangePassword extends Component {

    constructor() {
        super()
        this.state = {
            employee: {},
            employeePassword: '',
            employeeConfirmPassword: '',
            employeePasswordError: '',
            employeeConfirmPasswordError: ''
        }
        this.onCancelClick = this.onCancelClick.bind(this)
        this.onChangePassword = this.onChangePassword.bind(this)
    }

    componentDidMount() {

    }

    onChangePassword() {
        let employeePasswordError = ""
        let employeeConfirmPasswordError = ""

        if (this.state.employeePassword.trim() === '') { employeePasswordError = "Please provide password" }
        if (this.state.employeeConfirmPassword.trim() === '') { employeeConfirmPasswordError = "Please provide password" }
        if (this.state.employeePassword.length < 6) { employeePasswordError = "Password should be at least 6 characters in length" }
        if (this.state.employeePassword.trim() !==this.state.employeeConfirmPassword.trim()  ) { employeeConfirmPasswordError = "Password and confirm password do not match" }

        if (employeePasswordError.trim()!=="" || employeeConfirmPasswordError.trim()!=="") {
            this.setState({
                employeePasswordError: employeePasswordError,
                employeeConfirmPasswordError: employeeConfirmPasswordError
            })
            return;
        }
        //else update pass
        // alert('onChangePassword', this.state.employeePassword)
        var user = auth.currentUser;
        user.updatePassword(this.state.employeePassword).then(function() {
        // Update successful.
        
        }).catch(function(error) {
        // An error happened.
        });
        this.props.getToastr("Password Changed Successfully")
        this.props.onClose()
    }

    onCancelClick() {
        this.props.onClose()
    }

    render() {
        return <div>
            <h3 style={{ marginTop: "0px" }}>Change Password</h3>
            <div className="box-body">
                <div className="row">
                    {/* <div className="col-md-4">
                        <div className={this.state.employeeLoginError ? "form-group has-error" : "form-group "} >
                            <div className="form-group" >
                                <label>Login ID</label>
                                <input type="email" className="form-control" disabled
                                    onChange={(evt) => this.setState({ employeeLogin: evt.target.value, employeeLoginError: false })}
                                    value={this.state.employeeLogin}/>
                                {this.state.employeeLoginError && <span class="help-block">Please provide login credintials</span>}
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="col-md-4"> */}
                            <div className={this.state.employeePasswordError.trim()!=="" ? "form-group has-error" : "form-group "} >
                                <label>Password</label>
                                <input type="Password" className="form-control"
                                    onChange={(evt) => this.setState({ employeePassword: evt.target.value, employeePasswordError: "" })}
                                    value={this.state.employeePassword}/>
                                {this.state.employeePasswordError.trim()!=="" && <span className="help-block">{this.state.employeePasswordError}</span>}
                            </div>
                    </div>
                    <div className="row">
                            <div className={this.state.employeeConfirmPasswordError ? "form-group has-error" : "form-group "} >
                                <label >Confirm Password</label>
                                <input type="Password" className="form-control"
                                    onChange={(evt) => this.setState({ employeeConfirmPassword: evt.target.value, employeeConfirmPasswordError: "" })}
                                    value={this.state.employeeConfirmPassword}/>
                                {this.state.employeeConfirmPasswordError.trim()!=="" && <span className="help-block">{this.state.employeeConfirmPasswordError}</span>}
                            </div>
                    </div>
                {/* </div> */}
            </div>
            <div className="box-footer">
                <button onClick={() => this.onCancelClick()} className="btn btn-md btn-default btn-flat">Cancel</button>
                <button type="submit" className="btn btn-md btn-primary pull-right btn-flat" onClick={this.onChangePassword}>Save</button>
            </div>
        </div>
    }
}
export default withRouter(ChangePassword)