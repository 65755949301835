import React, { Component } from 'react'

class AddEditModifierGroup extends Component {

    constructor() {
        super()
        this.state = {
            saving: false,
            modifierGroups: [],
            modifierGroupName: '',
            modifierGroupError: '',
            modifierGroup:null,
            key: '',
            edit:false
        }
        this.onAddModifierGroup = this.onAddModifierGroup.bind(this)
        this.onRemoveModifierGroup = this.onRemoveModifierGroup.bind(this)
        this.onSaveModifierGroups = this.onSaveModifierGroups.bind(this)
        this.onSaveModifierGroup = this.onSaveModifierGroup.bind(this)
    }

    componentDidMount() {
        this.props.getModifierGroups(this.props.user.user.RegistrationID).then((modifierGroups) => {
            this.setState({modifierGroups : modifierGroups})
        })
    }    

    onAddModifierGroup() {
        let modifierGroupError = ''
        if (this.state.modifierGroupName.trim() === "") {
            modifierGroupError = 'Please enter group';
        }
        if (modifierGroupError != "") {
            this.setState({ modifierGroupError: modifierGroupError });
            return;
        }
        let modifierGroup = {
            Name: this.state.modifierGroupName, 
            IsDeleted: false,
            RegistrationID: this.props.user.user.RegistrationID
        }
        let modifierGroups = this.state.modifierGroups;

        let isFound = false;
        if (modifierGroups.length > 0) {
            for (let index = 0; index < modifierGroups.length; index++) {
                const group = modifierGroups[index];
                if (group.Name === modifierGroup.Name && group.IsDeleted === false) {
                    isFound = true;
                    break;
                }
            }
        }
        if (isFound) {
            alert('Modifier Group already exists')
        }
        else {
            modifierGroups.push(modifierGroup);
        }
        this.setState({ modifierGroups: modifierGroups, modifierGroupName: ''});
    }

    onRemoveModifierGroup(groupModifier, index) {
        console.log(groupModifier);
        if (groupModifier.hasOwnProperty('key')) {
            this.props.deleteModifierGroup(groupModifier.key).then(() => {
                this.props.getModifierGroups(this.props.user.user.RegistrationID).then((modifierGroups) => {
                    this.setState({ modifierGroups: modifierGroups })
                })
                this.props.getToastr("Modifier Group deleted successfully")
            })
        }
        else {
            let modifierGroups = this.state.modifierGroups
            modifierGroups.splice(index,1);
            this.setState({ modifierGroups: modifierGroups })
        }
    }
    
    onSaveModifierGroups() {
        this.setState({saving:true})        
        for (let index = 0; index < this.state.modifierGroups.length; index++) {
            let key = ''
            const groupModifier = this.state.modifierGroups[index];
            if(groupModifier.hasOwnProperty('key'))
            {
                key = groupModifier.key
            }
            this.props.saveModifierGroup(groupModifier, key)
        }
        
        this.setState({ saving:false, edit:false})
        
        this.props.getModifierGroups(this.props.user.user.RegistrationID).then((modifierGroups) => {
            this.setState({modifierGroups : modifierGroups})
        })        
    }

    onSaveModifierGroup(index){
        let group = this.state.modifierGroups[index]
        this.props.saveModifierGroup(group, group.key)
        this.setState({edit:false})
        this.props.getModifierGroups(this.props.user.user.RegistrationID).then((modifierGroups) => {
            this.setState({modifierGroups : modifierGroups})
        })
    }

    render() {
        const btnColStyle = { width: "120px" }
        return <div>
            <h3 style={{ marginTop: "0px" }}>Add Modifier Group</h3>
            <div className="box-body">
                <div className="row">
                    <div className="col-md-12">
                        <table>
                            <tr>
                                <td className={this.state.modifierGroupError != "" ? "form-group has-error" : "form-group "}>
                                    <input type="text" className="form-control" style={{width:"430px"}}
                                        placeholder="Enter Modifier Group"
                                        onChange={(evt) => this.setState({ modifierGroupName: evt.target.value, modifierGroupError: '' })}
                                        value={this.state.modifierGroupName} />
                                    {this.state.modifierGroupError !== "" && <span className="help-block">{this.state.modifierGroupError}</span>}
                                </td>
                                <td style={{ verticalAlign: "top"}}>
                                    <button type="button" className="btn btn-default btn-md"
                                        onClick={this.onAddModifierGroup}>
                                        Add </button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                {this.state.modifierGroups && this.state.modifierGroups.length > 0 &&
                    <table className="table table-bordered table-striped" style={{ marginTop: "10px" }}>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th style={btnColStyle}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.modifierGroups.filter((e) => !e.IsDeleted).map((group, index) =>
                                <tr>
                                    {!(this.state.edit === index)&& <td>
                                        {this.state.modifierGroups[index].Name}
                                    </td>}
                                     {(this.state.edit === index) && <td>
                                    <input type="text" className="form-control"                                        
                                        onChange={(evt) => {
                                            let modifierGroups = this.state.modifierGroups
                                            modifierGroups[index].Name = evt.target.value
                                            this.setState({ modifierGroups: modifierGroups, modifierGroupError: '' })
                                        }}                                        
                                        value={this.state.modifierGroups[index].Name}/>
                                    </td>}
                                    <td>
                                        <button type="button" className="btn btn-md btn-flat btn-default " 
                                            onClick={() => {this.setState({edit:index})}}><i className="fa fa-edit"></i></button>
                                        {!(this.state.edit === index)&& <button type="button" className="btn btn-default btn-md"
                                            onClick={()=> this.onRemoveModifierGroup(group,index)}>
                                            <i className="glyphicon glyphicon-remove"></i> </button>}
                                        {(this.state.edit === index) && <button className="btn btn-md btn-flat btn-default"
                                            onClick={() => this.onSaveModifierGroup(index)}><i className="fa fa-check-square"></i></button>}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>}
            </div>
            <div className="box-footer">
                <button type="submit" className="btn btn-flat btn-md btn-flat btn-default" onClick={this.props.onClose}>Cancel</button>
                <button type="submit" className="btn btn-flat btn-md btn-flat btn-success pull-right" onClick={this.onSaveModifierGroups} disabled={this.state.saving}>Save</button>
            </div>
        </div>
    }
}

export default AddEditModifierGroup