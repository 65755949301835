import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Loader from '../Loader'
import Pagination from 'react-js-pagination'
import { Link } from 'react-router-dom';


class ItemLedger extends Component {
    constructor() {
        super()
        this.state = {
            products: [],
            loading: true,
        }
        this.loadItemLedger = this.loadItemLedger.bind(this)
    }

    componentDidMount() {
        this.loadItemLedger();
    }

    loadItemLedger() {
        this.setState({ loading: true })
        this.props.getItemLedger(this.props.location.state.ProductSKU,
            this.props.location.state.LocationID)
            .then((result) => {
                this.setState({ products: result, loading: false })
            })
    }

    render() {
        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6">
                                        <font style={{ fontSize: "30px" }}>Item Ledger</font>
                                    </div>
                                    <div className="col-md-6">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={() => this.props.history.push({ pathname: "/home/reports/Instock" })}
                                        ><i className="fa fa-arrow-left"></i> Back</button>
                                    </div>
                                </div>
                            </div>

                            <div className="box-body">
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading &&
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                        
                                            <tr>
                                                <th>Date</th>
                                                <th>Location</th>
                                                <th>SKU</th>
                                                <th>Name</th>
                                                <th>Category</th>
                                                <th>Sub Category</th>
                                                <th>Brand</th>
                                                <th>Barcode</th>
                                               {/* <th>Retail Price</th> */}
                                                <th>Transaction Type</th>
                                                <th>Transaction No</th>
                                                <th>Transaction Action</th>
                                                <th>Transaction Qty</th>
                                                <th>In Stock Qty</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.products.map((item, index) =>
                                                <tr>
                                                    <td>{(new Date(item.ActionOn.seconds*1000)).toString().substring(0,24)}</td>
                                                    <td>{item.Location}</td>
                                                    <td>{item.ProductSKU}</td>
                                                    <td>{item.ProductName}</td>
                                                    <td>{item.Category}</td>
                                                    <td>{item.SubCategory}</td>
                                                    <td>{item.Brand}</td>
                                                    <td>{item.BarCode}</td>
                                                    {/* <td>{item.RetailPrice}</td> */}
                                                    <td>{item.TransactionType}</td>
                                                    <td>{item.TransactionNo}</td>
                                                    <td>{item.Action}</td>
                                                    <td>{item.TransactionQty}</td>
                                                    <td>{item.InStockQty}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default withRouter(ItemLedger)