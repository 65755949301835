import React, { Component } from 'react';
import * as actionTypes from '../../actionTypes'
import { withRouter } from 'react-router-dom'
import Dropzone from 'react-dropzone'
import { EditorState, convertToRaw, ContentState, convertFromHTML, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class PrintConfig extends Component {

    constructor() {
        super()
        this.state = {
            headerEditorState: EditorState.createEmpty(),
            footerEditorState: EditorState.createEmpty(),
            storeID: '',
            storeCode: '',
            storeName: '',
            printConfig: null,
            printDisplayName: '',
            moduleID: '',
            module: '',
            transactionTypeID: '',
            transactionType: '',
            printSize: '',
            headerText: '',
            headerImage: null,
            headerImageLoading: false,
            //headerImagePreview:'',
            footerText: '',
            footerImage: null,
            footerImageLoading: false,
            //footerImagePreview:'',
            storeCodeDisplay: false,
            storeCodeDisplayName: "Store Code",
            storeNameDisplay: false,
            storeNameDisplayName: 'Store Name',
            storePhoneNoDisplay: false,
            storePhoneNoDisplayName: 'Phone No',
            storeEmailIDDisplay: false,
            storeEmailIDDisplayName: 'Email ID',
            storeAddressLine1Display: false,
            storeAddressLine1DisplayName: 'Address Line 1',
            storeAddressLine2Display: false,
            storeAddressLine2DisplayName: 'Address Line 2',
            storeAddressCityDisplay: false,
            storeAddressCityDisplayName: 'City',
            storeAddressStateDisplay: false,
            storeAddressStateDisplayName: 'State',
            storeAddressPostalCodeDisplay: false,
            storeAddressPostalCodeDisplayName: 'Postal Code',
            storeAddressCountryDisplay: false,
            storeAddressCountryDisplayName: 'Country',
            storeCustomField1Display: false,
            storeCustomField2Display: false,
            storeCustomField3Display: false,
            storeCustomField4Display: false,
            storeCustomField5Display: false,
            storeCustomField6Display: false,
            storeCustomField1DisplayName: 'Custom Field 1',
            storeCustomField2DisplayName: 'Custom Field 2',
            storeCustomField3DisplayName: 'Custom Field 3',
            storeCustomField4DisplayName: 'Custom Field 4',
            storeCustomField5DisplayName: 'Custom Field 5',
            storeCustomField6DisplayName: 'Custom Field 6',
            customerCodeDisplay: false,
            customerCodeDisplayName: 'Customer Code',
            customerNameDisplay: false,
            customerNameDisplayName: 'Customer Name',
            customerPhoneNoDisplay: false,
            customerPhoneNoDisplayName: 'Phone No',
            customerEmailIDDisplay: false,
            customerEmailIDDisplayName: 'Email ID',
            customerBillingAddressDisplay : false,
            customerBillingAddressDisplayName : 'Billing Address',
            customerDeliveryAddressDisplay : false,
            customerDeliveryAddressDisplayName : 'Delivery Address',
            customerCustomField1Display: false,
            customerCustomField2Display: false,
            customerCustomField3Display: false,
            customerCustomField4Display: false,
            customerCustomField5Display: false,
            customerCustomField6Display: false,
            customerCustomField1DisplayName: 'Custom Field 1',
            customerCustomField2DisplayName: 'Custom Field 2',
            customerCustomField3DisplayName: 'Custom Field 3',
            customerCustomField4DisplayName: 'Custom Field 4',
            customerCustomField5DisplayName: 'Custom Field 5',
            customerCustomField6DisplayName: 'Custom Field 6',
            transactionNoDisplay: false,
            transactionNoDisplayName: 'No',
            transactionDateDisplay: false,
            transactionDateDisplayName: 'Date',
            transactionTotalQtyDisplay: false,
            transactionTotalQtyDisplayName: 'Total Qty',
            transactionDiscountDisplay: false,
            transactionDiscountDisplayName: 'Discount',
            transactionTaxSummaryDisplay: false,
            transactionTaxSummaryDisplayName: 'Tax Summary',
            transactionTotalDisplay: false,
            transactionTotalDisplayName: 'Total',
            transactionPaidDisplay: false,
            transactionPaidDisplayName: 'Paid',
            transactionBalanceDisplay: false,
            transactionBalanceDisplayName: 'Balance',
            transactionRoundOffDisplay: false,
            transactionRoundOffDisplayName: 'Round off',
            transactionPaymentModeDisplay : false,
            transactionPaymentModeDisplayName : 'Payment Mode',
            transactionNotesDisplay : false,
            transactionNotesDisplayName : 'Notes',
            transactionDetailsSrNoDisplay: false,
            transactionDetailsSrNoDisplayName: 'Sr. No.',
            transactionDetailsSKUDisplay: false,
            transactionDetailsSKUDisplayName: 'SKU',
            transactionDetailsItemNameDisplay: false,
            transactionDetailsItemNameDisplayName: 'Item Name',
            transactionDetailsItemDescriptionDisplay: false,
            transactionDetailsItemDescriptionDisplayName: 'Item Description',
            transactionDetailsBarcodeDisplay: false,
            transactionDetailsBarcodeDisplayName: 'Barcode',
            transactionDetailsHSNDisplay: false,
            transactionDetailsHSNDisplayName: 'HSN/SAC',
            transactionDetailsRetailPriceDisplay: false,
            transactionDetailsRetailPriceDisplayName: 'Retail Price',
            transactionDetailsSupplyPriceDisplay:false,
            transactionDetailsSupplyPriceDisplayName:'Supply Price',
            transactionDetailsSupplyPriceDisplayNameError:'',
            transactionDetailsMRPDisplay: false,
            transactionDetailsMRPDisplayName: 'MRP',
            transactionDetailsQtyDisplay: false,
            transactionDetailsQtyDisplayName: 'Qty',
            transactionDetailsSubTotalDisplay: false,
            transactionDetailsSubTotalDisplayName: 'Sub Total',
            transactionDetailsDiscountPercentageDisplay: false,
            transactionDetailsDiscountPercentageDisplayName: 'Dis %',
            transactionDetailsDiscountAmountDisplay: false,
            transactionDetailsDiscountAmountDisplayName: 'Dis Amt',
            transactionDetailsTaxDisplay: false,
            transactionDetailsTaxDisplayName: 'Tax',
            transactionDetailsTotalDisplay: false,
            transactionDetailsTotalDisplayName: 'Total',
            transactionDetailTaxAmountDisplay:false,
            transactionDetailTaxAmountDisplayName:'Tax Amount',
            transactionAmountInWordsfDisplay:'',
            transactionAmountInWordsfDisplayName:'Amount',
            transactionAmountInWordsfDisplayNameError:'',
            transactionDetailsEmployeeNameDisplay : false,
            transactionDetailsEmployeeNameDisplayName : 'Employee',
            transactionDetailsNotesDisplay : false,
            transactionDetailsNotesDisplayName : 'Notes',
            printDisplayNameError: '',
            Modules: [{ ModuleID: 'sales', Module: 'Sales' },{ModuleID: 'inventory', Module: 'Inventory'}],
            moduleError: '',
            transactionTypeError: '',
            printSizeOptions: [
                { PrintSizeOption: "A4" },
                { PrintSizeOption: "A5" },
                { PrintSizeOption: "3 inch" },
                { PrintSizeOption: "2 inch" }
            ],
            printSizeError: '',
            action: '',
            key: '',

            storeCodeDisplayNameError: '',
            storeNameDisplayNameError: '',
            storePhoneNoDisplayNameError: '',
            storeEmailIDDisplayNameError: '',
            storeAddressLine1DisplayNameError: '',
            storeAddressLine2DisplayNameError: '',
            storeAddressCityDisplayNameError: '',
            storeAddressStateDisplayNameError: '',
            storeAddressPostalCodeDisplayNameError: '',
            storeAddressCountryDisplayNameError: '',
            storeCustomField1DisplayNameError: '',
            storeCustomField2DisplayNameError: '',
            storeCustomField3DisplayNameError: '',
            storeCustomField4DisplayNameError: '',
            storeCustomField5DisplayNameError: '',
            storeCustomField6DisplayNameError: '',
            customerCodeDisplayNameError : '',
            customerNameDisplayNameError : '',
            customerPhoneNoDisplayNameError: '', 
            customerEmailIDDisplayNameError: '',
            customerCustomField1DisplayNameError: '', 
            customerCustomField2DisplayNameError: '',
            customerCustomField3DisplayNameError: '',
            customerCustomField4DisplayNameError: '',
            customerCustomField5DisplayNameError: '',
            customerCustomField6DisplayNameError: '',
            transactionNoDisplayNameError: '',
            transactionDateDisplayNameError: '',
            transactionTotalQtyDisplayNameError: '',
            transactionDiscountDisplayNameError: '',
            transactionTaxSummaryDisplayNameError: '',
            transactionTotalDisplayNameError: '',
            transactionPaidDisplayNameError: '',
            transactionBalanceDisplayNameError: '',
            transactionRoundOffDisplayNameError: '',
            transactionDetailsSrNoDisplayNameError: '',
            transactionDetailsSKUDisplayNameError: '',
            transactionDetailsItemNameDisplayNameError: '',
            transactionDetailsItemDescriptionDisplayNameError: '',
            transactionDetailsBarcodeDisplayNameError: '',
            transactionDetailsHSNDisplayNameError: '',
            transactionDetailsMRPDisplayNameError: '',
            transactionDetailsRetailPriceDisplayNameError: '',
            transactionDetailsQtyDisplayNameError: '',
            transactionDetailsSubTotalDisplayNameError: '',
            transactionDetailsDiscountPercentageDisplayNameError: '',
            transactionDetailsDiscountAmountDisplayNameError: '',
            transactionDetailsTaxDisplayNameError: '',
            transactionDetailTaxAmountDisplayNameError: '',
            transactionDetailsTotalDisplayNameError: '',
            customerBillingAddressDisplayError : '',
            customerDeliveryAddressDisplayError : '',
            transactionPaymentModeDisplayError : '',
            transactionNotesDisplayError : '',
            transactionDetailsEmployeeNameDisplayError : '',
            transactionDetailsNotesDisplayError : '',

            storeCustomField1Label: 'Custom Field 1',
            storeCustomField2Label: 'Custom Field 2',
            storeCustomField3Label: 'Custom Field 3',
            storeCustomField4Label: 'Custom Field 4',
            storeCustomField5Label: 'Custom Field 5',
            storeCustomField6Label: 'Custom Field 6',
            customerCustomField1Label: 'Custom Field 1',
            customerCustomField2Label: 'Custom Field 2',
            customerCustomFieldLabel: 'Custom Field 3',
            customerCustomField4Label: 'Custom Field 4',
            customerCustomField5Label: 'Custom Field 5',
            customerCustomField6Label: 'Custom Field 6'
        }
        this.onSavePrintConfig = this.onSavePrintConfig.bind(this)
        this.onCancelClick = this.onCancelClick.bind(this)
        this.onHeaderImageDrop = this.onHeaderImageDrop.bind(this)
        this.onHeaderImageRemove = this.onHeaderImageRemove.bind(this)
        this.onFooterImageDrop = this.onFooterImageDrop.bind(this)
        this.onFooterImageRemove = this.onFooterImageRemove.bind(this)
        this.onHeaderEditorStateChange = this.onHeaderEditorStateChange.bind(this)
        this.onFooterEditorStateChange = this.onFooterEditorStateChange.bind(this)
    }

    componentDidMount() {

        this.setState({
            storeID: this.props.location.state.storeID,
            storeName: this.props.location.state.storeName,
            storeCode: this.props.location.state.storeCode
        })
        if (this.props.location.state.printConfig !== null) {
            let printConfig = this.props.location.state.printConfig

            let hblocksFromHtml = htmlToDraft(printConfig.HeaderText);
            let hcontentState = ContentState.createFromBlockArray(hblocksFromHtml.contentBlocks, hblocksFromHtml.entityMap);
            let heditorState = EditorState.createWithContent(hcontentState);

            let fblocksFromHtml = htmlToDraft(printConfig.FooterText);
            let fcontentState = ContentState.createFromBlockArray(fblocksFromHtml.contentBlocks, fblocksFromHtml.entityMap);
            let feditorState = EditorState.createWithContent(fcontentState);

            //console.log(editorState)

            this.setState({
                headerEditorState: heditorState ? heditorState : EditorState.createEmpty(),
                footerEditorState: feditorState ? feditorState : EditorState.createEmpty(),
                key: printConfig.key,
                storeID: printConfig.StoreID,
                printDisplayName: printConfig.PrintDisplayName,
                moduleID: printConfig.ModuleID,
                module: printConfig.Module,
                transactionTypeID: printConfig.TransactionTypeID,
                transactionType: printConfig.TransactionType,
                printSize: printConfig.PrintSize,
                headerText: printConfig.HeaderText,
                headerImage: printConfig.hasOwnProperty('HeaderImage') ? printConfig.HeaderImage : null,
                footerText: printConfig.FooterText,
                footerImage: printConfig.hasOwnProperty('FooterImage') ? printConfig.FooterImage : null,
                storeCodeDisplay: printConfig.StoreCodeDisplay,
                storeCodeDisplayName: printConfig.StoreCodeDisplayName,
                storeNameDisplay: printConfig.StoreNameDisplay,
                storeNameDisplayName: printConfig.StoreNameDisplayName,
                storePhoneNoDisplay: printConfig.StorePhoneNoDisplay,
                storePhoneNoDisplayName: printConfig.StorePhoneNoDisplayName,
                storeEmailIDDisplay: printConfig.StoreEmailIDDisplay,
                storeEmailIDDisplayName: printConfig.StoreEmailIDDisplayName,
                storeAddressLine1Display: printConfig.StoreAddressLine1Display,
                storeAddressLine1DisplayName: printConfig.StoreAddressLine1DisplayName,
                storeAddressLine2Display: printConfig.StoreAddressLine2Display,
                storeAddressLine2DisplayName: printConfig.StoreAddressLine2DisplayName,
                storeAddressCityDisplay: printConfig.StoreAddressCityDisplay,
                storeAddressCityDisplayName: printConfig.StoreAddressCityDisplayName,
                storeAddressStateDisplay: printConfig.StoreAddressStateDisplay,
                storeAddressStateDisplayName: printConfig.StoreAddressStateDisplayName,
                storeAddressPostalCodeDisplay: printConfig.StoreAddressPostalCodeDisplay,
                storeAddressPostalCodeDisplayName: printConfig.StoreAddressPostalCodeDisplayName,
                storeAddressCountryDisplay: printConfig.StoreAddressCountryDisplay,
                storeAddressCountryDisplayName: printConfig.StoreAddressCountryDisplayName,
                storeCustomField1Display: printConfig.StoreCustomField1Display,
                storeCustomField2Display: printConfig.StoreCustomField2Display,
                storeCustomField3Display: printConfig.StoreCustomField3Display,
                storeCustomField4Display: printConfig.StoreCustomField4Display,
                storeCustomField5Display: printConfig.StoreCustomField5Display,
                storeCustomField6Display: printConfig.StoreCustomField6Display,
                storeCustomField1DisplayName: printConfig.StoreCustomField1DisplayName,
                storeCustomField2DisplayName: printConfig.StoreCustomField2DisplayName,
                storeCustomField3DisplayName: printConfig.StoreCustomField3DisplayName,
                storeCustomField4DisplayName: printConfig.StoreCustomField4DisplayName,
                storeCustomField5DisplayName: printConfig.StoreCustomField5DisplayName,
                storeCustomField6DisplayName: printConfig.StoreCustomField6DisplayName,
                customerCodeDisplay: printConfig.CustomerCodeDisplay,
                customerCodeDisplayName: printConfig.CustomerCodeDisplayName,
                customerNameDisplay: printConfig.CustomerNameDisplay,
                customerNameDisplayName: printConfig.CustomerNameDisplayName,
                customerPhoneNoDisplay: printConfig.CustomerPhoneNoDisplay,
                customerPhoneNoDisplayName: printConfig.CustomerPhoneNoDisplayName,
                customerEmailIDDisplay: printConfig.CustomerEmailIDDisplay,
                customerEmailIDDisplayName: printConfig.CustomerEmailIDDisplayName,
                customerBillingAddressDisplay: printConfig.hasOwnProperty('CustomerBillingAddressDisplay') ? printConfig.CustomerBillingAddressDisplay : '',
                customerBillingAddressDisplayName: printConfig.hasOwnProperty('CustomerBillingAddressDisplayName') ? printConfig.CustomerBillingAddressDisplayName: '',
                customerDeliveryAddressDisplay: printConfig.hasOwnProperty('CustomerDeliveryAddressDisplay')  ? printConfig.CustomerDeliveryAddressDisplay :'',
                customerDeliveryAddressDisplayName: printConfig.hasOwnProperty('CustomerDeliveryAddressDisplayName')?  printConfig.CustomerDeliveryAddressDisplayName:'',
                customerCustomField1Display: printConfig.CustomerCustomField1Display,
                customerCustomField2Display: printConfig.CustomerCustomField2Display,
                customerCustomField3Display: printConfig.CustomerCustomField3Display,
                customerCustomField4Display: printConfig.CustomerCustomField4Display,
                customerCustomField5Display: printConfig.CustomerCustomField5Display,
                customerCustomField6Display: printConfig.CustomerCustomField6Display,
                customerCustomField1DisplayName: printConfig.CustomerCustomField1DisplayName,
                customerCustomField2DisplayName: printConfig.CustomerCustomField2DisplayName,
                customerCustomField3DisplayName: printConfig.CustomerCustomField3DisplayName,
                customerCustomField4DisplayName: printConfig.CustomerCustomField4DisplayName,
                customerCustomField5DisplayName: printConfig.CustomerCustomField5DisplayName,
                customerCustomField6DisplayName: printConfig.CustomerCustomField6DisplayName,
                transactionNoDisplay: printConfig.TransactionNoDisplay,
                transactionNoDisplayName: printConfig.TransactionNoDisplayName,
                transactionDateDisplay: printConfig.TransactionDateDisplay,
                transactionDateDisplayName: printConfig.TransactionDateDisplayName,
                transactionTotalQtyDisplay: printConfig.TransactionTotalQtyDisplay,
                transactionTotalQtyDisplayName: printConfig.TransactionTotalQtyDisplayName,
                transactionDiscountDisplay: printConfig.TransactionDiscountDisplay,
                transactionDiscountDisplayName: printConfig.TransactionDiscountDisplayName,
                transactionTaxSummaryDisplay: printConfig.TransactionTaxSummaryDisplay,
                transactionTaxSummaryDisplayName: printConfig.TransactionTaxSummaryDisplayName,
                transactionTotalDisplay: printConfig.TransactionTotalDisplay,
                transactionTotalDisplayName: printConfig.TransactionTotalDisplayName,
                transactionPaidDisplay: printConfig.TransactionPaidDisplay,
                transactionPaidDisplayName: printConfig.TransactionPaidDisplayName,
                transactionBalanceDisplay: printConfig.TransactionBalanceDisplay,
                transactionBalanceDisplayName: printConfig.TransactionBalanceDisplayName,
                transactionRoundOffDisplay: printConfig.TransactionRoundOffDisplay,
                transactionRoundOffDisplayName: printConfig.TransactionRoundOffDisplayName,
                transactionPaymentModeDisplay: printConfig.hasOwnProperty('TransactionPaymentModeDisplay') ? printConfig.TransactionPaymentModeDisplay:'',
                transactionPaymentModeDisplayName:printConfig.hasOwnProperty('TransactionPaymentModeDisplayName')  ?printConfig.TransactionPaymentModeDisplayName:'',
                transactionNotesDisplay: printConfig.hasOwnProperty('TransactionNotesDisplay') ?printConfig.TransactionNotesDisplay:'',
                transactionNotesDisplayName: printConfig.hasOwnProperty('TransactionNotesDisplayName') ?printConfig.TransactionNotesDisplayName:'',
                transactionDetailsSrNoDisplay: printConfig.hasOwnProperty('TransactionDetailsSrNoDisplay') ? printConfig.TransactionDetailsSrNoDisplay : false,
                transactionDetailsSrNoDisplayName: printConfig.hasOwnProperty('TransactionDetailsSrNoDisplayName') ? printConfig.TransactionDetailsSrNoDisplayName : '',
                transactionDetailsSKUDisplay: printConfig.TransactionDetailsSKUDisplay,
                transactionDetailsSKUDisplayName: printConfig.TransactionDetailsSKUDisplayName,
                transactionDetailsItemNameDisplay: printConfig.TransactionDetailsItemNameDisplay,
                transactionDetailsItemNameDisplayName: printConfig.TransactionDetailsItemNameDisplayName,
                transactionDetailsItemDescriptionDisplay: printConfig.TransactionDetailsItemDescriptionDisplay,
                transactionDetailsItemDescriptionDisplayName: printConfig.TransactionDetailsItemDescriptionDisplayName,
                transactionDetailsBarcodeDisplay: printConfig.TransactionDetailsBarcodeDisplay,
                transactionDetailsBarcodeDisplayName: printConfig.TransactionDetailsBarcodeDisplayName,
                transactionDetailsHSNDisplay: printConfig.TransactionDetailsHSNDisplay,
                transactionDetailsHSNDisplayName: printConfig.TransactionDetailsHSNDisplayName,
                transactionDetailsRetailPriceDisplay: printConfig.TransactionDetailsRetailPriceDisplay,
                transactionDetailsRetailPriceDisplayName: printConfig.TransactionDetailsRetailPriceDisplayName,
                transactionDetailsMRPDisplay: printConfig.hasOwnProperty('TransactionDetailsMRPDisplay') ? printConfig.TransactionDetailsMRPDisplay : false,
                transactionDetailsMRPDisplayName: printConfig.hasOwnProperty('TransactionDetailsMRPDisplayName') ? printConfig.TransactionDetailsMRPDisplayName : '',
                tranasctionDetailMRPDisplayName: printConfig.TranasctionDetailMRPDisplayName,
                transactionDetailsQtyDisplay: printConfig.TransactionDetailsQtyDisplay,
                transactionDetailsQtyDisplayName: printConfig.TransactionDetailsQtyDisplayName,
                transactionDetailsSubTotalDisplay: printConfig.TransactionDetailsSubTotalDisplay,
                transactionDetailsSubTotalDisplayName: printConfig.TransactionDetailsSubTotalDisplayName,
                transactionDetailsDiscountPercentageDisplay: printConfig.TransactionDetailsDiscountPercentageDisplay,
                transactionDetailsDiscountPercentageDisplayName: printConfig.TransactionDetailsDiscountPercentageDisplayName,
                transactionDetailsDiscountAmountDisplay: printConfig.TransactionDetailsDiscountAmountDisplay,
                transactionDetailsDiscountAmountDisplayName: printConfig.TransactionDetailsDiscountAmountDisplayName,
                transactionDetailsTaxDisplay: printConfig.TransactionDetailsTaxDisplay,
                transactionDetailsTaxDisplayName: printConfig.TransactionDetailsTaxDisplayName,
                transactionDetailsTotalDisplay: printConfig.TransactionDetailsTotalDisplay,
                transactionDetailsTotalDisplayName:  printConfig.TransactionDetailsTotalDisplayName,
                transactionDetailTaxAmountDisplay :  printConfig.hasOwnProperty('TransactionDetailTaxAmountDisplay') ? printConfig.TransactionDetailTaxAmountDisplay : false,
                transactionDetailTaxAmountDisplayName: printConfig.hasOwnProperty('TransactionDetailTaxAmountDisplayName') ? printConfig.TransactionDetailTaxAmountDisplayName : '',
                transactionAmountInWordsfDisplay :  printConfig.hasOwnProperty('TransactionAmountInWordsfDisplay') ? printConfig.TransactionAmountInWordsfDisplay : false,
                transactionAmountInWordsfDisplayName: printConfig.hasOwnProperty('TransactionAmountInWordsfDisplayName') ? printConfig.TransactionAmountInWordsfDisplayName : '',
                transactionDetailsEmployeeNameDisplay: printConfig.hasOwnProperty('TransactionDetailsEmployeeNameDisplay')? printConfig.TransactionDetailsEmployeeNameDisplay:'',
                transactionDetailsEmployeeNameDisplayName: printConfig.hasOwnProperty('TransactionDetailsEmployeeNameDisplayName')? printConfig.TransactionDetailsEmployeeNameDisplayName:'',
                transactionDetailsNotesDisplay:printConfig.hasOwnProperty('TransactionDetailsNotesDisplay') ?printConfig.TransactionDetailsNotesDisplay:'',
                transactionDetailsNotesDisplayName: printConfig.hasOwnProperty('TransactionDetailsNotesDisplayName') ? printConfig.TransactionDetailsNotesDisplayName:'',
                transactionDetailsSupplyPriceDisplay:printConfig.hasOwnProperty('TransactionDetailsSupplyPriceDisplay') ?printConfig.TransactionDetailsSupplyPriceDisplay:'',
                transactionDetailsSupplyPriceDisplayName:printConfig.hasOwnProperty('TransactionDetailsSupplyPriceDisplayName') ?printConfig.TransactionDetailsSupplyPriceDisplayName:'',
            })
        }
        this.props.config.CustomFields.map((customField) => {
            if (customField.ModuleName === 'Store') {
                let customField1DisplayName = customField.CustomField1Name.trim() === '' ? 'Custom Field 1' : customField.CustomField1Name.trim()
                let customField2DisplayName = customField.CustomField2Name.trim() === '' ? 'Custom Field 2' : customField.CustomField2Name.trim()
                let customField3DisplayName = customField.CustomField3Name.trim() === '' ? 'Custom Field 3' : customField.CustomField3Name.trim()
                let customField4DisplayName = customField.CustomField4Name.trim() === '' ? 'Custom Field 4' : customField.CustomField4Name.trim()
                let customField5DisplayName = customField.CustomField5Name.trim() === '' ? 'Custom Field 5' : customField.CustomField5Name.trim()
                let customField6DisplayName = customField.CustomField6Name.trim() === '' ? 'Custom Field 6' : customField.CustomField6Name.trim()
                this.setState({
                    storeCustomField1DisplayName: customField1DisplayName,
                    storeCustomField2DisplayName: customField2DisplayName,
                    storeCustomField3DisplayName: customField3DisplayName,
                    storeCustomField4DisplayName: customField4DisplayName,
                    storeCustomField5DisplayName: customField5DisplayName,
                    storeCustomField6DisplayName: customField6DisplayName,
                    storeCustomField1Label: customField1DisplayName,
                    storeCustomField2Label: customField2DisplayName,
                    storeCustomField3Label: customField3DisplayName,
                    storeCustomField4Label: customField4DisplayName,
                    storeCustomField5Label: customField5DisplayName,
                    storeCustomField6Label: customField6DisplayName
                })
            }
            if (customField.ModuleName === 'Customer') {
                let customField1DisplayName = customField.CustomField1Name.trim() === '' ? 'Custom Field 1' : customField.CustomField1Name.trim()
                let customField2DisplayName = customField.CustomField2Name.trim() === '' ? 'Custom Field 2' : customField.CustomField2Name.trim()
                let customField3DisplayName = customField.CustomField3Name.trim() === '' ? 'Custom Field 3' : customField.CustomField3Name.trim()
                let customField4DisplayName = customField.CustomField4Name.trim() === '' ? 'Custom Field 4' : customField.CustomField4Name.trim()
                let customField5DisplayName = customField.CustomField5Name.trim() === '' ? 'Custom Field 5' : customField.CustomField5Name.trim()
                let customField6DisplayName = customField.CustomField6Name.trim() === '' ? 'Custom Field 6' : customField.CustomField6Name.trim()
                this.setState({
                    customerCustomField1DisplayName: customField1DisplayName,
                    customerCustomField2DisplayName: customField2DisplayName,
                    customerCustomField3DisplayName: customField3DisplayName,
                    customerCustomField4DisplayName: customField4DisplayName,
                    customerCustomField5DisplayName: customField5DisplayName,
                    customerCustomField6DisplayName: customField6DisplayName,
                    customerCustomField1Label: customField1DisplayName,
                    customerCustomField2Label: customField2DisplayName,
                    customerCustomField3Label: customField3DisplayName,
                    customerCustomField4Label: customField4DisplayName,
                    customerCustomField5Label: customField5DisplayName,
                    customerCustomField6Label: customField6DisplayName
                })
            }
        })
    }

    onSavePrintConfig() {

        let printDisplayNameError = ""
        let moduleError = ""
        let transactionTypeError = ""
        let printSizeError = ""

        let storeCodeDisplayNameError = ""
        let storeNameDisplayNameError = ""
        let storePhoneNoDisplayNameError = ""
        let storeEmailIDDisplayNameError = ""
        let storeAddressLine1DisplayNameError = ""
        let storeAddressLine2DisplayNameError = ""
        let storeAddressCityDisplayNameError = ""
        let storeAddressStateDisplayNameError = ""
        let storeAddressPostalCodeDisplayNameError = ""
        let storeAddressCountryDisplayNameError = ""
        let storeCustomField1DisplayNameError = ""
        let storeCustomField2DisplayNameError = ""
        let storeCustomField3DisplayNameError = ""
        let storeCustomField4DisplayNameError = ""
        let storeCustomField5DisplayNameError = ""
        let storeCustomField6DisplayNameError = ""

        let customerCodeDisplayNameError = ""
        let customerNameDisplayNameError = ""
        let customerPhoneNoDisplayNameError = "" 
        let customerEmailIDDisplayNameError = ""
        let customerCustomField1DisplayNameError = "" 
        let customerCustomField2DisplayNameError = ""
        let customerCustomField3DisplayNameError = ""
        let customerCustomField4DisplayNameError = ""
        let customerCustomField5DisplayNameError = ""
        let customerCustomField6DisplayNameError = ""
        let customerBillingAddressDisplayError = ""
        let customerDeliveryAddressDisplayError = ""

        let transactionNoDisplayNameError = ""
        let transactionDateDisplayNameError = ""
        let transactionTotalQtyDisplayNameError = ""
        let transactionDiscountDisplayNameError = ""
        let transactionTaxSummaryDisplayNameError = ""
        let transactionTotalDisplayNameError = ""
        let transactionPaidDisplayNameError = ""
        let transactionBalanceDisplayNameError = ""
        let transactionRoundOffDisplayNameError = ""
        let transactionPaymentModeDisplayError = ""
        let transactionNotesDisplayError = ""

        let transactionDetailsSrNoDisplayNameError = ""
        let transactionDetailsSKUDisplayNameError = ""
        let transactionDetailsItemNameDisplayNameError = ""
        let transactionDetailsItemDescriptionDisplayNameError = ""
        let transactionDetailsBarcodeDisplayNameError = ""
        let transactionDetailsHSNDisplayNameError = ""
        let transactionDetailsMRPDisplayNameError = ""
        let transactionDetailsRetailPriceDisplayNameError = ""
        let transactionDetailsQtyDisplayNameError = ""
        let transactionDetailsSubTotalDisplayNameError = ""
        let transactionDetailsDiscountPercentageDisplayNameError = ""
        let transactionDetailsDiscountAmountDisplayNameError = ""
        let transactionDetailsTaxDisplayNameError = ""
        let transactionDetailTaxAmountDisplayNameError = ""
        let transactionDetailsTotalDisplayNameError = ""
        let transactionDetailsEmployeeNameDisplayError = ""
        let transactionDetailsNotesDisplayError = ""
        let transactionDetailsSupplyPriceDisplayNameError = ""

        if (this.state.printDisplayName.trim() === "") {
            printDisplayNameError = "Please provide print name"
        }
        if (this.state.module.trim() === "") {
            moduleError = "Please select module"
        }
        if (this.state.transactionType === "") {
            transactionTypeError = "Please select transaction type"
        }
        if (this.state.printSize === "") {
            printSizeError = "Please select pint size"
        }

        if(this.state.storeCodeDisplay){
            if (this.state.storeCodeDisplayName.trim() === "") 
            { storeCodeDisplayNameError = "Please provide store code" }
        }

        if(this.state.storeNameDisplay){
            if (this.state.storeNameDisplayName.trim() === "") 
            { storeNameDisplayNameError = "Please provide store name" }
        }

        if(this.state.storePhoneNoDisplay){
            if (this.state.storePhoneNoDisplayName.trim() === "") {
                storePhoneNoDisplayNameError = "Please provide phone no"
            }
        }

        if(this.state.storeEmailIDDisplay){
            if (this.state.storeEmailIDDisplayName.trim() === "") {
                storeEmailIDDisplayNameError = "Please provide email id"
            }
        }

        if(this.state.storeAddressLine1Display){
            if (this.state.storeAddressLine1DisplayName.trim() === "") {
                storeAddressLine1DisplayNameError = "Please provide Address"
            }
        }

        if(this.state.storeAddressLine2Display){
            if (this.state.storeAddressLine2DisplayName.trim() === "") {
                storeAddressLine2DisplayNameError = "Please provide Address"
            }
        }

        if(this.state.storeAddressCityDisplay){
            if (this.state.storeAddressCityDisplayName.trim() === "") {
                storeAddressCityDisplayNameError = "Please provide city"
            }
        }

        if(this.state.storeAddressStateDisplay){
            if (this.state.storeAddressStateDisplayName.trim() === "") {
                storeAddressStateDisplayNameError = "Please provide state"
            }
        }

        if(this.state.storeAddressPostalCodeDisplay){
            if (this.state.storeAddressPostalCodeDisplayName.trim() === "") {
                storeAddressPostalCodeDisplayNameError = "Please provide postal code"
            }
        }

        if(this.state.storeAddressCountryDisplay){
            if (this.state.storeAddressCountryDisplayName.trim() === "") {
                storeAddressCountryDisplayNameError = "Please provide country"
            }
        }

        if(this.state.storeCustomField1Display){
            if (this.state.storeCustomField1DisplayName.trim() === "") {
                storeCustomField1DisplayNameError = "Please provide custom field"
            }
        }

        if(this.state.storeCustomField2Display){
            if (this.state.storeCustomField2DisplayName.trim() === "") {
                storeCustomField2DisplayNameError = "Please provide custom field"
            }
        }

        if(this.state.storeCustomField3Display){
            if (this.state.storeCustomField3DisplayName.trim() === "") {
                storeCustomField3DisplayNameError = "Please provide custom field"
            }
        }

        if(this.state.storeCustomField4Display){
            if (this.state.storeCustomField4DisplayName.trim() === "") {
                storeCustomField4DisplayNameError = "Please provide custom field"
            }
        }

        if(this.state.storeCustomField5Display){
            if (this.state.storeCustomField5DisplayName.trim() === "") {
                storeCustomField5DisplayNameError = "Please provide custom field"
            }
        }

        if(this.state.storeCustomField6Display){
            if (this.state.storeCustomField6DisplayName.trim() === "") {
                storeCustomField6DisplayNameError = "Please provide custom field"
            }
        }

        if(this.state.customerCodeDisplay){
            if (this.state.customerCodeDisplayName.trim() === "") {
                customerCodeDisplayNameError = "Please provide customer code"
            }
        }

        if(this.state.customerNameDisplay){
            if (this.state.customerNameDisplayName.trim() === "") {
                customerNameDisplayNameError = "Please provide customer name"
            }
        }

        if(this.state.customerPhoneNoDisplay){
            if (this.state.customerPhoneNoDisplayName.trim() === "") {
                customerPhoneNoDisplayNameError = "Please provide phone no"
            }
        }

        if(this.state.customerEmailIDDisplay){
            if (this.state.customerEmailIDDisplayName.trim() === "") {
                customerEmailIDDisplayNameError = "Please provide email id"
            }
        }

        if(this.customerBillingAddressDisplay){
            if(this.state.customerBillingAddressDisplayName.trim() === ""){
                customerBillingAddressDisplayError = "Please provide billing address"
            }
        }

        if(this.customerDeliveryAddressDisplay){
            if(this.state.customerDeliveryAddressDisplayName.trim() === ""){
                customerDeliveryAddressDisplayError = "Please provide delivery address"
            }
        }

        if(this.state.customerCustomField1Display){
            if (this.state.customerCustomField1DisplayName.trim() === "") {
                customerCustomField1DisplayNameError = "Please provide custom field"
            }
        }

        if(this.state.customerCustomField2Display){
            if (this.state.customerCustomField2DisplayName.trim() === "") {
                customerCustomField2DisplayNameError = "Please provide custom field"
            }
        }

        if(this.state.customerCustomField3Display){
            if (this.state.customerCustomField3DisplayName.trim() === "") {
                customerCustomField3DisplayNameError = "Please provide custom field"
            }
        }

        if(this.state.customerCustomField4Display){
            if (this.state.customerCustomField4DisplayName.trim() === "") {
                customerCustomField4DisplayNameError = "Please provide custom field"
            }
        }

        if(this.state.customerCustomField5Display){
            if (this.state.customerCustomField5DisplayName.trim() === "") {
                customerCustomField5DisplayNameError = "Please provide custom field"
            }
        }

        if(this.state.customerCustomField6Display){
            if (this.state.customerCustomField6DisplayName.trim() === "") {
                customerCustomField6DisplayNameError = "Please provide custom field"
            }
        }

        if(this.state.transactionNoDisplay){
            if (this.state.transactionNoDisplayName.trim() === "") {
                transactionNoDisplayNameError = "Please provide transaction no"
            }
        }

        if(this.state.transactionDateDisplay){
            if (this.state.transactionDateDisplayName.trim() === "") {
                transactionDateDisplayNameError = "Please provide transaction date"
            }
        }

        if(this.state.transactionTotalQtyDisplay){
            if (this.state.transactionTotalQtyDisplayName.trim() === "") {
                transactionTotalQtyDisplayNameError = "Please provide total qty"
            }
        }

        if(this.state.transactionDiscountDisplay){
            if (this.state.transactionDiscountDisplayName.trim() === "") {
                transactionDiscountDisplayNameError = "Please provide transaction discount"
            }
        }

        if(this.state.transactionTaxSummaryDisplay){
            if (this.state.transactionTaxSummaryDisplayName.trim() === "") {
                transactionTaxSummaryDisplayNameError = "Please provide tax summary"
            }
        }

        if(this.state.transactionTotalDisplay){
            if (this.state.transactionTotalDisplayName.trim() === "") {
                transactionTotalDisplayNameError = "Please provide transaction total"
            }
        }

        if(this.state.transactionPaidDisplay){
            if (this.state.transactionPaidDisplayName.trim() === "") {
                transactionPaidDisplayNameError = "Please provide transaction paid"
            }
        }

        if(this.state.transactionBalanceDisplay){
            if (this.state.transactionBalanceDisplayName.trim() === "") {
                transactionBalanceDisplayNameError = "Please provide transaction balance"
            }
        }

        if(this.state.transactionRoundOffDisplay){
            if (this.state.transactionRoundOffDisplayName.trim() === "") {
                transactionRoundOffDisplayNameError = "Please provide transaction round off"
            }
        }

        if(this.state.transactionPaymentModeDisplay){
            if (this.state.transactionPaymentModeDisplayName.trim() === "") {
                transactionPaymentModeDisplayError = "Please provide payment mode"
            }
        }
        
        if(this.state.transactionNotesDisplay){
            if (this.state.transactionNotesDisplayName.trim() === "") {
                transactionNotesDisplayError = "Please provide transaction notes"
            }
        }

        if(this.state.transactionDetailsSrNoDisplay){
            if (this.state.transactionDetailsSrNoDisplayName.trim() === "") {
                transactionDetailsSrNoDisplayNameError = "Please provide transaction detail sr. no."
            }
        }

        if(this.state.transactionDetailsSKUDisplay){
            if (this.state.transactionDetailsSKUDisplayName.trim() === "") {
                transactionDetailsSKUDisplayNameError = "Please provide transaction detail SKU"
            }
        }

        if(this.state.transactionDetailsItemNameDisplay){
            if (this.state.transactionDetailsItemNameDisplayName.trim() === "") {
                transactionDetailsItemNameDisplayNameError = "Please provide transaction detail Item Name"
            }
        }

        if(this.state.transactionDetailsItemDescriptionDisplay){
            if (this.state.transactionDetailsItemDescriptionDisplayName.trim() === "") {
                transactionDetailsItemDescriptionDisplayNameError = "Please provide transaction detail Item Description"
            }
        }

        if(this.state.transactionDetailsBarcodeDisplay){
            if (this.state.transactionDetailsBarcodeDisplayName.trim() === "") {
                transactionDetailsBarcodeDisplayNameError = "Please provide transaction detail Barcode"
            }
        }

        if(this.state.transactionDetailsHSNDisplay){
            if (this.state.transactionDetailsHSNDisplayName.trim() === "") {
                transactionDetailsHSNDisplayNameError = "Please provide transaction detail HSN"
            }
        }

        if(this.state.transactionDetailsMRPDisplay){
            if (this.state.transactionDetailsMRPDisplayName.trim() === "") {
                transactionDetailsMRPDisplayNameError = "Please provide transaction detail MRP"
            }
        }

        if(this.state.transactionDetailsRetailPriceDisplay){
            if (this.state.transactionDetailsRetailPriceDisplayName.trim() === "") {
                transactionDetailsRetailPriceDisplayNameError = "Please provide transaction detail Retail Price"
            }
        }

        if(this.state.transactionDetailsQtyDisplay){
            if (this.state.transactionDetailsQtyDisplayName.trim() === "") {
                transactionDetailsQtyDisplayNameError = "Please provide transaction detail Qty"
            }
        }

        if(this.state.transactionDetailsSubTotalDisplay){
            if (this.state.transactionDetailsSubTotalDisplayName.trim() === "") {
                transactionDetailsSubTotalDisplayNameError = "Please provide transaction detail Sub Total"
            }
        }

        if(this.state.transactionDetailsDiscountPercentageDisplay){
            if (this.state.transactionDetailsDiscountPercentageDisplayName.trim() === "") {
                transactionDetailsDiscountPercentageDisplayNameError = "Please provide transaction detail Discount Percentage"
            }
        }

        if(this.state.transactionDetailsDiscountAmountDisplay){
            if (this.state.transactionDetailsDiscountAmountDisplayName.trim() === "") {
                transactionDetailsDiscountAmountDisplayNameError = "Please provide transaction detail Discount Amount"
            }
        }

        if(this.state.transactionDetailsTaxDisplay){
            if (this.state.transactionDetailsTaxDisplayName.trim() === "") {
                transactionDetailsTaxDisplayNameError = "Please provide transaction detail Tax"
            }
        }

        if(this.state.transactionDetailTaxAmountDisplay){
            if (this.state.transactionDetailTaxAmountDisplayName.trim() === "") {
                transactionDetailTaxAmountDisplayNameError = "Please provide transaction detail Tax"
            }
        }

        if(this.state.transactionDetailsTotalDisplay){
            if (this.state.transactionDetailsTotalDisplayName.trim() === "") {
                transactionDetailsTotalDisplayNameError = "Please provide transaction detail SKU"
            }
        }

        if(this.state.transactionDetailsEmployeeNameDisplay){
            if (this.state.transactionDetailsEmployeeNameDisplayName.trim() === "") {
                transactionDetailsEmployeeNameDisplayError = "Please provide employee name"
            }
        }

        if(this.state.transactionDetailsNotesDisplay){
            if (this.state.transactionDetailsNotesDisplayName.trim() === "") {
                transactionDetailsNotesDisplayError = "Please provide transaction notes"
            }
        }

        if(this.state.transactionDetailsSupplyPriceDisplay){
            if (this.state.transactionDetailsSupplyPriceDisplayName.trim() === "") {
                transactionDetailsSupplyPriceDisplayNameError = "Please provide label name"
            }
        }
        


        if( printDisplayNameError !== "" || moduleError !== "" || 
            transactionTypeError !== "" || printSizeError !== "" ||
            storeCodeDisplayNameError !== "" || storeNameDisplayNameError !== "" ||
            storePhoneNoDisplayNameError !== "" || storeEmailIDDisplayNameError !== "" ||
            storeAddressLine1DisplayNameError !== "" || storeAddressLine2DisplayNameError !== "" ||
            storeAddressCityDisplayNameError !== "" || storeAddressStateDisplayNameError !== "" ||
            storeAddressPostalCodeDisplayNameError !== "" || storeAddressCountryDisplayNameError !== "" ||
            storeCustomField1DisplayNameError !== "" || storeCustomField2DisplayNameError !== "" ||
            storeCustomField3DisplayNameError !== "" || storeCustomField4DisplayNameError !== "" ||
            storeCustomField5DisplayNameError !== "" || storeCustomField6DisplayNameError !== "" ||
            customerCodeDisplayNameError !== "" || customerNameDisplayNameError !== "" ||
            customerPhoneNoDisplayNameError !== "" || customerEmailIDDisplayNameError !== "" || 
            customerBillingAddressDisplayError !== "" || customerDeliveryAddressDisplayError !== "" ||
            customerCustomField1DisplayNameError !== "" || customerCustomField2DisplayNameError !== "" ||
            customerCustomField3DisplayNameError !== "" || customerCustomField4DisplayNameError !== "" ||
            customerCustomField5DisplayNameError !== "" || customerCustomField6DisplayNameError !== "" ||
            transactionNoDisplayNameError !== "" || transactionDateDisplayNameError !== "" ||
            transactionTotalQtyDisplayNameError !== "" || transactionDiscountDisplayNameError !== "" ||
            transactionTaxSummaryDisplayNameError !== "" || transactionTotalDisplayNameError !== "" ||
            transactionPaidDisplayNameError !== "" || transactionBalanceDisplayNameError !== "" ||
            transactionPaymentModeDisplayError !== "" || transactionNotesDisplayError !== "" ||
            transactionRoundOffDisplayNameError !== "" || transactionDetailsSrNoDisplayNameError !== "" ||
            transactionDetailsSKUDisplayNameError !== "" || transactionDetailsItemNameDisplayNameError !== "" ||
            transactionDetailsItemDescriptionDisplayNameError !== "" || transactionDetailsBarcodeDisplayNameError !== "" ||
            transactionDetailsHSNDisplayNameError !== "" || transactionDetailsMRPDisplayNameError !== "" ||
            transactionDetailsRetailPriceDisplayNameError !== "" || transactionDetailsQtyDisplayNameError !== "" ||
            transactionDetailsSubTotalDisplayNameError !== "" || transactionDetailsDiscountPercentageDisplayNameError !== "" ||
            transactionDetailsDiscountAmountDisplayNameError !== "" || transactionDetailsTaxDisplayNameError !== "" ||
            transactionDetailTaxAmountDisplayNameError !== "" || transactionDetailsTotalDisplayNameError !== "" ||
            transactionDetailsEmployeeNameDisplayError !== "" || transactionDetailsNotesDisplayError !== "" || transactionDetailsSupplyPriceDisplayNameError!=="")
        { 
            
        this.setState({ printDisplayNameError: printDisplayNameError,
            moduleError: moduleError,
            transactionTypeError: transactionTypeError,
            printSizeError: printSizeError,
            storeCodeDisplayNameError : storeCodeDisplayNameError,
            storeNameDisplayNameError : storeNameDisplayNameError,
            storePhoneNoDisplayNameError : storePhoneNoDisplayNameError,
            storeEmailIDDisplayNameError : storeEmailIDDisplayNameError,
            storeAddressLine1DisplayNameError : storeAddressLine1DisplayNameError,
            storeAddressLine2DisplayNameError : storeAddressLine2DisplayNameError,
            storeAddressCityDisplayNameError : storeAddressCityDisplayNameError,
            storeAddressStateDisplayNameError : storeAddressStateDisplayNameError,
            storeAddressPostalCodeDisplayNameError : storeAddressPostalCodeDisplayNameError,
            storeAddressCountryDisplayNameError : storeAddressCountryDisplayNameError,
            storeCustomField1DisplayNameError : storeCustomField1DisplayNameError,
            storeCustomField2DisplayNameError : storeCustomField2DisplayNameError,
            storeCustomField3DisplayNameError : storeCustomField3DisplayNameError,
            storeCustomField4DisplayNameError : storeCustomField4DisplayNameError,
            storeCustomField5DisplayNameError : storeCustomField5DisplayNameError,
            storeCustomField6DisplayNameError : storeCustomField6DisplayNameError,

            customerCodeDisplayNameError : customerCodeDisplayNameError,
            customerNameDisplayNameError : customerNameDisplayNameError,
            customerPhoneNoDisplayNameError : customerPhoneNoDisplayNameError,
            customerEmailIDDisplayNameError : customerEmailIDDisplayNameError,
            customerBillingAddressDisplayError : customerBillingAddressDisplayError,
            customerDeliveryAddressDisplayError : customerDeliveryAddressDisplayError,
            customerCustomField1DisplayNameError : customerCustomField1DisplayNameError,
            customerCustomField2DisplayNameError : customerCustomField2DisplayNameError,
            customerCustomField3DisplayNameError : customerCustomField3DisplayNameError,
            customerCustomField4DisplayNameError : customerCustomField4DisplayNameError,
            customerCustomField5DisplayNameError : customerCustomField5DisplayNameError,
            customerCustomField6DisplayNameError : customerCustomField6DisplayNameError,

            transactionNoDisplayNameError : transactionNoDisplayNameError,
            transactionDateDisplayNameError : transactionDateDisplayNameError,
            transactionTotalQtyDisplayNameError : transactionTotalQtyDisplayNameError,
            transactionDiscountDisplayNameError : transactionDiscountDisplayNameError,
            transactionTaxSummaryDisplayNameError : transactionTaxSummaryDisplayNameError,
            transactionTotalDisplayNameError : transactionTotalDisplayNameError,
            transactionPaidDisplayNameError : transactionPaidDisplayNameError,
            transactionBalanceDisplayNameError : transactionBalanceDisplayNameError,
            transactionRoundOffDisplayNameError : transactionRoundOffDisplayNameError,
            transactionPaymentModeDisplayError : transactionPaymentModeDisplayError,
            transactionNotesDisplayError : transactionNotesDisplayError,

            transactionDetailsSrNoDisplayNameError : transactionDetailsSrNoDisplayNameError,
            transactionDetailsSKUDisplayNameError : transactionDetailsSKUDisplayNameError,
            transactionDetailsItemNameDisplayNameError : transactionDetailsItemNameDisplayNameError,
            transactionDetailsItemDescriptionDisplayNameError : transactionDetailsItemDescriptionDisplayNameError,
            transactionDetailsBarcodeDisplayNameError : transactionDetailsBarcodeDisplayNameError,
            transactionDetailsHSNDisplayNameError : transactionDetailsHSNDisplayNameError,
            transactionDetailsMRPDisplayNameError : transactionDetailsMRPDisplayNameError,
            transactionDetailsRetailPriceDisplayNameError : transactionDetailsRetailPriceDisplayNameError,
            transactionDetailsQtyDisplayNameError : transactionDetailsQtyDisplayNameError,
            transactionDetailsSubTotalDisplayNameError : transactionDetailsSubTotalDisplayNameError,
            transactionDetailsDiscountPercentageDisplayNameError : transactionDetailsDiscountPercentageDisplayNameError,
            transactionDetailsDiscountAmountDisplayNameError : transactionDetailsDiscountAmountDisplayNameError,
            transactionDetailsTaxDisplayNameError : transactionDetailsTaxDisplayNameError,
            transactionDetailTaxAmountDisplayNameError : transactionDetailTaxAmountDisplayNameError,
            transactionDetailsTotalDisplayNameError : transactionDetailsTotalDisplayNameError,
            transactionDetailsEmployeeNameDisplayError : transactionDetailsEmployeeNameDisplayError,
            transactionDetailsNotesDisplayError : transactionDetailsNotesDisplayError,
            transactionDetailsSupplyPriceDisplayNameError:transactionDetailsSupplyPriceDisplayNameError,
            saving : false})
            return }

        let printConfig = {
            // HeaderEditorState  : this.state.headerEditorState,
            // FooterEditorState: this.state.footerEditorState,
            RegistrationID: this.props.user.user.RegistrationID,
            StoreID: this.state.storeID,
            PrintDisplayName: this.state.printDisplayName,
            ModuleID: this.state.moduleID,
            Module: this.state.module,
            TransactionTypeID: this.state.transactionTypeID,
            TransactionType: this.state.transactionType,
            PrintSize: this.state.printSize,
            HeaderText: draftToHtml(convertToRaw(this.state.headerEditorState.getCurrentContent())),//this.state.headerText,
            HeaderImage: this.state.headerImage,
            FooterText: draftToHtml(convertToRaw(this.state.footerEditorState.getCurrentContent())), // this.state.footerText,
            FooterImage: this.state.footerImage,
            StoreCodeDisplay: this.state.storeCodeDisplay,
            StoreCodeDisplayName: this.state.storeCodeDisplayName,
            StoreNameDisplay: this.state.storeNameDisplay,
            StoreNameDisplayName: this.state.storeNameDisplayName,
            StorePhoneNoDisplay: this.state.storePhoneNoDisplay,
            StorePhoneNoDisplayName: this.state.storePhoneNoDisplayName,
            StoreEmailIDDisplay: this.state.storeEmailIDDisplay,
            StoreEmailIDDisplayName: this.state.storeEmailIDDisplayName,
            StoreAddressLine1Display: this.state.storeAddressLine1Display,
            StoreAddressLine1DisplayName: this.state.storeAddressLine1DisplayName,
            StoreAddressLine2Display: this.state.storeAddressLine2Display,
            StoreAddressLine2DisplayName: this.state.storeAddressLine2DisplayName,
            StoreAddressCityDisplay: this.state.storeAddressCityDisplay,
            StoreAddressCityDisplayName: this.state.storeAddressCityDisplayName,
            StoreAddressStateDisplay: this.state.storeAddressStateDisplay,
            StoreAddressStateDisplayName: this.state.storeAddressStateDisplayName,
            StoreAddressPostalCodeDisplay: this.state.storeAddressPostalCodeDisplay,
            StoreAddressPostalCodeDisplayName: this.state.storeAddressPostalCodeDisplayName,
            StoreAddressCountryDisplay: this.state.storeAddressCountryDisplay,
            StoreAddressCountryDisplayName: this.state.storeAddressCountryDisplayName,
            StoreCustomField1Display: this.state.storeCustomField1Display,
            StoreCustomField2Display: this.state.storeCustomField2Display,
            StoreCustomField3Display: this.state.storeCustomField3Display,
            StoreCustomField4Display: this.state.storeCustomField4Display,
            StoreCustomField5Display: this.state.storeCustomField5Display,
            StoreCustomField6Display: this.state.storeCustomField6Display,
            StoreCustomField1DisplayName: this.state.storeCustomField1DisplayName,
            StoreCustomField2DisplayName: this.state.storeCustomField2DisplayName,
            StoreCustomField3DisplayName: this.state.storeCustomField3DisplayName,
            StoreCustomField4DisplayName: this.state.storeCustomField4DisplayName,
            StoreCustomField5DisplayName: this.state.storeCustomField5DisplayName,
            StoreCustomField6DisplayName: this.state.storeCustomField6DisplayName,
            CustomerCodeDisplay: this.state.customerCodeDisplay,
            CustomerCodeDisplayName: this.state.customerCodeDisplayName,
            CustomerNameDisplay: this.state.customerNameDisplay,
            CustomerNameDisplayName: this.state.customerNameDisplayName,
            CustomerPhoneNoDisplay: this.state.customerPhoneNoDisplay,
            CustomerPhoneNoDisplayName: this.state.customerPhoneNoDisplayName,
            CustomerEmailIDDisplay: this.state.customerEmailIDDisplay,
            CustomerEmailIDDisplayName: this.state.customerEmailIDDisplayName,
            CustomerBillingAddressDisplay: this.state.customerBillingAddressDisplay,
            CustomerBillingAddressDisplayName: this.state.customerBillingAddressDisplayName,
            CustomerDeliveryAddressDisplay: this.state.customerDeliveryAddressDisplay,
            CustomerDeliveryAddressDisplayName: this.state.customerDeliveryAddressDisplayName,
            CustomerCustomField1Display: this.state.customerCustomField1Display,
            CustomerCustomField2Display: this.state.customerCustomField2Display,
            CustomerCustomField3Display: this.state.customerCustomField3Display,
            CustomerCustomField4Display: this.state.customerCustomField4Display,
            CustomerCustomField5Display: this.state.customerCustomField5Display,
            CustomerCustomField6Display: this.state.customerCustomField6Display,
            CustomerCustomField1DisplayName: this.state.customerCustomField1DisplayName,
            CustomerCustomField2DisplayName: this.state.customerCustomField2DisplayName,
            CustomerCustomField3DisplayName: this.state.customerCustomField3DisplayName,
            CustomerCustomField4DisplayName: this.state.customerCustomField4DisplayName,
            CustomerCustomField5DisplayName: this.state.customerCustomField5DisplayName,
            CustomerCustomField6DisplayName: this.state.customerCustomField6DisplayName,
            TransactionNoDisplay: this.state.transactionNoDisplay,
            TransactionNoDisplayName: this.state.transactionNoDisplayName,
            TransactionDateDisplay: this.state.transactionDateDisplay,
            TransactionDateDisplayName: this.state.transactionDateDisplayName,
            TransactionTotalQtyDisplay: this.state.transactionTotalQtyDisplay,
            TransactionTotalQtyDisplayName: this.state.transactionTotalQtyDisplayName,
            TransactionDiscountDisplay: this.state.transactionDiscountDisplay,
            TransactionDiscountDisplayName: this.state.transactionDiscountDisplayName,
            TransactionTaxSummaryDisplay: this.state.transactionTaxSummaryDisplay,
            TransactionTaxSummaryDisplayName: this.state.transactionTaxSummaryDisplayName,
            TransactionTotalDisplay: this.state.transactionTotalDisplay,
            TransactionTotalDisplayName: this.state.transactionTotalDisplayName,
            TransactionPaidDisplay: this.state.transactionPaidDisplay,
            TransactionPaidDisplayName: this.state.transactionPaidDisplayName,
            TransactionBalanceDisplay: this.state.transactionBalanceDisplay,
            TransactionBalanceDisplayName: this.state.transactionBalanceDisplayName,
            TransactionRoundOffDisplay: this.state.transactionRoundOffDisplay,
            TransactionRoundOffDisplayName: this.state.transactionRoundOffDisplayName,
            TransactionPaymentModeDisplay: this.state.transactionPaymentModeDisplay,
            TransactionPaymentModeDisplayName: this.state.transactionPaymentModeDisplayName,
            TransactionNotesDisplay: this.state.transactionNotesDisplay,
            TransactionNotesDisplayName: this.state.transactionNotesDisplayName,
            TransactionDetailsSrNoDisplay: this.state.transactionDetailsSrNoDisplay,
            TransactionDetailsSrNoDisplayName: this.state.transactionDetailsSrNoDisplayName,
            TransactionDetailsSKUDisplay: this.state.transactionDetailsSKUDisplay,
            TransactionDetailsSKUDisplayName: this.state.transactionDetailsSKUDisplayName,
            TransactionDetailsItemNameDisplay: this.state.transactionDetailsItemNameDisplay,
            TransactionDetailsItemNameDisplayName: this.state.transactionDetailsItemNameDisplayName,
            TransactionDetailsItemDescriptionDisplay: this.state.transactionDetailsItemDescriptionDisplay,
            TransactionDetailsItemDescriptionDisplayName: this.state.transactionDetailsItemDescriptionDisplayName,
            TransactionDetailsBarcodeDisplay: this.state.transactionDetailsBarcodeDisplay,
            TransactionDetailsBarcodeDisplayName: this.state.transactionDetailsBarcodeDisplayName,
            TransactionDetailsHSNDisplay: this.state.transactionDetailsHSNDisplay,
            TransactionDetailsHSNDisplayName: this.state.transactionDetailsHSNDisplayName,
            TransactionDetailsRetailPriceDisplay: this.state.transactionDetailsRetailPriceDisplay,
            TransactionDetailsRetailPriceDisplayName: this.state.transactionDetailsRetailPriceDisplayName,
            TransactionDetailsMRPDisplay: this.state.transactionDetailsMRPDisplay,
            TransactionDetailsMRPDisplayName: this.state.transactionDetailsMRPDisplayName,
            TransactionDetailsQtyDisplay: this.state.transactionDetailsQtyDisplay,
            TransactionDetailsQtyDisplayName: this.state.transactionDetailsQtyDisplayName,
            TransactionDetailsSubTotalDisplay: this.state.transactionDetailsSubTotalDisplay,
            TransactionDetailsSubTotalDisplayName: this.state.transactionDetailsSubTotalDisplayName,
            TransactionDetailsDiscountPercentageDisplay: this.state.transactionDetailsDiscountPercentageDisplay,
            TransactionDetailsDiscountPercentageDisplayName: this.state.transactionDetailsDiscountPercentageDisplayName,
            TransactionDetailsDiscountAmountDisplay: this.state.transactionDetailsDiscountAmountDisplay,
            TransactionDetailsDiscountAmountDisplayName: this.state.transactionDetailsDiscountAmountDisplayName,
            TransactionDetailsTaxDisplay: this.state.transactionDetailsTaxDisplay,
            TransactionDetailsTaxDisplayName: this.state.transactionDetailsTaxDisplayName,
            TransactionDetailsTotalDisplay: this.state.transactionDetailsTotalDisplay,
            TransactionDetailsTotalDisplayName: this.state.transactionDetailsTotalDisplayName,
            TransactionDetailTaxAmountDisplay: this.state.transactionDetailTaxAmountDisplay,
            TransactionDetailTaxAmountDisplayName: this.state.transactionDetailTaxAmountDisplayName,
            TransactionDetailsEmployeeNameDisplay: this.state.transactionDetailsEmployeeNameDisplay,
            TransactionDetailsEmployeeNameDisplayName: this.state.transactionDetailsEmployeeNameDisplayName,
            TransactionDetailsNotesDisplay: this.state.transactionDetailsNotesDisplay,
            TransactionDetailsNotesDisplayName: this.state.transactionDetailsNotesDisplayName,
            TransactionAmountInWordsfDisplay: this.state.transactionAmountInWordsfDisplay,
            TransactionAmountInWordsfDisplayName:this.state.transactionAmountInWordsfDisplayName,
            TransactionDetailsSupplyPriceDisplay: this.state.transactionDetailsSupplyPriceDisplay,
            TransactionDetailsSupplyPriceDisplayName: this.state.transactionDetailsSupplyPriceDisplayName
        }

        if(this.state.key)
        {
            this.props.savePrintConfig(printConfig, this.state.key).then((key) => {
                this.props.getToastr("Print Configuration saved successfully")
                this.props.history.push({
                    pathname: "/home/settings/stores/printconfig", state: {
                        storeID: this.state.storeID,
                        storeCode: this.state.storeCode, storeName: this.state.storeName
                    }
                })    
            })
        }
        else
        {
            this.props.getPrintTemplate(this.state.printSize+'_default').then((template) => {
                printConfig.PrintTemplate = template.PrintTemplate;
                //console.log('printConfig', printConfig)
                //console.log('this.state.key', this.state.key)
                //return 
                this.props.savePrintConfig(printConfig, this.state.key).then((key) => {
                    this.props.getToastr("Print Configuration saved successfully")
                    this.props.history.push({
                        pathname: "/home/settings/stores/printconfig", state: {
                            storeID: this.state.storeID,
                            storeCode: this.state.storeCode, storeName: this.state.storeName
                        }
                    })    
                })
            }) 
        }
               
    }

    onCancelClick() {
        this.props.history.push({
            pathname: "/home/settings/stores/printconfig", state: {
                storeID: this.state.storeID,
                storeCode: this.state.storeCode, storeName: this.state.storeName
            }
        })
    }

    onHeaderImageDrop(acceptedFiles, rejectedFiles) {


        if (acceptedFiles.length > 0) {

            if ((acceptedFiles[0].size / 1024) > 100) {
                alert('File size should not be more than 100 KB')
                return
            }
            this.setState({ headerImageLoading: true })
            this.props.uploadFileToServer(acceptedFiles[0], this.props.registration.registration.StorageFolder + '/PrintConfig').then((downloadURL) => {
                //update product with images url
                let image = { Name: acceptedFiles[0].name, Size: acceptedFiles[0].size, Preview: downloadURL }
                this.setState({ headerImage: image })
                this.setState({ headerImageLoading: false })
            })
        }
        else { alert("No file selected") }
        // if(acceptedFiles.length > 0 )
        // {   this.setState({headerImage:acceptedFiles[0], headerImagePreview:acceptedFiles[0].preview})
        //     //console.log(acceptedFiles[0].preview)
        // }
        // else{alert("No file selected")}
    }

    onHeaderImageRemove() {
        this.setState({ headerImage: null, headerImageLoading: false })
    }

    onFooterImageDrop(acceptedFiles, rejectedFiles) {
        if (acceptedFiles.length > 0) {

            if ((acceptedFiles[0].size / 1024) > 100) {
                alert('File size should not be more than 100 KB')
                return
            }
            this.setState({ footerImageLoading: true })
            this.props.uploadFileToServer(acceptedFiles[0], this.props.registration.registration.StorageFolder + '/PrintConfig').then((downloadURL) => {
                //update product with images url
                let image = { Name: acceptedFiles[0].name, Size: acceptedFiles[0].size, Preview: downloadURL }
                this.setState({ footerImage: image })
                this.setState({ footerImageLoading: false })
            })
        }
        else { alert("No file selected") }

        // if(acceptedFiles.length > 0 )
        // {   this.setState({footerImage:acceptedFiles[0], footerImagePreview:acceptedFiles[0].preview})
        // }
        // else{alert("No file selected")}
    }

    onFooterImageRemove() {
        this.setState({ footerImage: null, footerImagePreview: '' })
    }

    onHeaderEditorStateChange(editorState) {

        // console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())))
        // console.log('convertToRaw(this.state.headerEditorState.getCurrentContent())', convertToRaw(editorState.getCurrentContent()))
        // console.log('this.state.headerEditorState.getCurrentContent()', editorState.getCurrentContent())
        this.setState({
            headerEditorState: editorState
        })
    }

    onFooterEditorStateChange(editorState) {
        this.setState({
            footerEditorState: editorState
        })
    }

    render() {
        return <div className="content-wrapper">
            <section className="content-header">
                <h1>Print Config</h1>
                <button type="submit"
                    style={{ marginTop: "-25px", marginLeft: "10px" }}
                    className="btn btn-md btn-success pull-right btn-flat"
                    onClick={this.onSavePrintConfig}>Save</button>
                <button onClick={() => this.onCancelClick()}
                    style={{ marginTop: "-25px" }}
                    className="btn btn-md btn-default btn-flat pull-right">Cancel</button>
            </section>
            <section className="content" >
                <div className="box ">

                    <div className="box-body">
                        <div className="row">
                            <div className="col-md-3">
                                <div className={this.state.printDisplayNameError !== "" ? "form-group has-error" : "form-group "} >
                                    <label >Print Display Name</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ printDisplayName: evt.target.value, printDisplayNameError: "" })}
                                        value={this.state.printDisplayName}
                                    />
                                    {this.state.printDisplayNameError !== "" && <span class="help-block">{this.state.printDisplayNameError}</span>}
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className={this.state.moduleError !== "" ? "form-group has-error" : "form-group "} >
                                    <label>Module</label>
                                    <select className="form-control"
                                        value={this.state.moduleID}
                                        onChange={(evt) => {
                                            this.setState({
                                                moduleID: evt.target.value,
                                                module: evt.target.options[evt.target.selectedIndex].text, moduleError: ""
                                            })
                                        }}>
                                        <option value="">Select module</option>
                                        {this.state.Modules.map((m) =>
                                            <option value={m.ModuleID}>{m.Module}</option>
                                        )}
                                    </select>
                                    {this.state.moduleError !== "" && <span class="help-block">{this.state.moduleError}</span>}
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className={this.state.transactionTypeError !== "" ? "form-group has-error" : "form-group "} >
                                    <label>Transaction Type</label>
                                    <select className="form-control"
                                        value={this.state.transactionTypeID}
                                        onChange={(evt) => {
                                            this.setState({
                                                transactionTypeID: evt.target.value,
                                                transactionType: evt.target.options[evt.target.selectedIndex].text,
                                                printDisplayName: evt.target.options[evt.target.selectedIndex].text + " " + this.state.printSize,
                                                transactionTypeError: ""
                                            })
                                        }}>
                                        <option value="">Select transaction type</option>
                                        {this.props.config.TransactionTypes.filter((e) => !e.IsDeleted && e.Module === this.state.moduleID).map((t) =>
                                            <option value={t.key}>{t.TransactionType}</option>
                                        )}
                                    </select>
                                    {this.state.transactionTypeError !== "" && <span class="help-block">{this.state.transactionTypeError}</span>}
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className={this.state.printSizeError !== "" ? "form-group has-error" : "form-group "} >
                                    <label>Print Size</label>
                                    <select className="form-control"
                                        value={this.state.printSize}
                                        onChange={(evt) => {
                                            this.setState({
                                                printSize: evt.target.value,
                                                printDisplayName: this.state.transactionType + " " + evt.target.value,
                                                printSizeError: ""                                                
                                            })
                                            // this.props.getPrintTemplate(evt.target.value+'_default')
                                        }}>
                                        <option value="">Select print size</option>
                                        {this.state.printSizeOptions.map((p) =>
                                            <option value={p.PrintSizeOption}>{p.PrintSizeOption}</option>
                                        )}
                                    </select>
                                    {this.state.printSizeError !== "" && <span class="help-block">{this.state.printSizeError}</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box ">
                    <div className="box-header with-border">
                        <h3 className="box-title">Header</h3>
                    </div>
                    <div className="box-body">
                        <div className="row">
                            <div className="col-md-8">
                                <div>
                                    <Editor
                                        editorState={this.state.headerEditorState}
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="demo-editor"
                                        onEditorStateChange={this.onHeaderEditorStateChange}
                                    />
                                    {/* <textarea
                                                    disabled
                                                    value={draftToHtml(convertToRaw(this.state.headerEditorState.getCurrentContent()))}
                                                    /> */}
                                </div>
                                {/* <div className="form-group" >
                                                    <label >Header Text</label>
                                                    <textarea className="textarea" style={styleEditor}
                                                    onChange={(evt)=> this.setState({headerText:evt.target.value})}
                                                    value={this.state.headerText}
                                                    placeholder=""></textarea>
                                                </div> */}
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Header Image</label>
                                    {!this.state.headerImageLoading && <React.Fragment>

                                        {!this.state.headerImage &&
                                            <Dropzone onDrop={(files) => this.onHeaderImageDrop(files)} >
                                                <div>Try dropping some files here, or click to select files to upload.</div>
                                            </Dropzone>
                                        }
                                        {this.state.headerImage && <div className="form-group">
                                            <ul className="mailbox-attachments clearfix">
                                                <li>
                                                    <span className="mailbox-attachment-icon has-img"><img src={this.state.headerImage.Preview}
                                                        alt="Attachment" /></span>
                                                    <div className="mailbox-attachment-info">
                                                        <span className="mailbox-attachment-name">{this.state.headerImage.Name.length > 20 ? this.state.headerImage.Name.substr(0, 20) + "..." : this.state.headerImage.Name}</span>
                                                        <span className="mailbox-attachment-size">
                                                            {Math.round(this.state.headerImage.Size / 1024)} KB
                                                                                <a onClick={this.onHeaderImageRemove}
                                                                className="btn btn-default btn-xs pull-right"><i className="fa fa-trash"></i></a>
                                                        </span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>}
                                    </React.Fragment>}
                                    {this.state.headerImageLoading && <div>Uploading image</div>}
                                    {/* {!this.state.headerImagePreview &&
                                                        <Dropzone onDrop={(files) => this.onHeaderImageDrop(files)} >
                                                            <div>Try dropping some files here, or click to select files to upload.</div>
                                                        </Dropzone>
                                                        }
                                                        {this.state.headerImagePreview && <div className="form-group">
                                                            <ul className="mailbox-attachments clearfix">
                                                                    <li>
                                                                        <span className="mailbox-attachment-icon has-img"><img src={this.state.headerImagePreview}   
                                                                        alt="Attachment"/></span>
                                                                        <div className="mailbox-attachment-info">
                                                                            <span className="mailbox-attachment-name">{this.state.headerImage.name}</span>
                                                                                <span className="mailbox-attachment-size">
                                                                                {Math.round(this.state.headerImage.size/1024)} KB
                                                                            <a  onClick={this.onHeaderImageRemove} 
                                                                            className="btn btn-default btn-xs pull-right"><i className="fa fa-trash"></i></a>
                                                                            </span>
                                                                        </div>
                                                                    </li>
                                                            </ul>
                                                        </div>} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box ">
                    <div className="box-header with-border">
                        <h3 className="box-title">Footer</h3>
                    </div>
                    <div className="box-body">
                        <div className="row">
                            <div className="col-md-8">
                                <Editor
                                    editorState={this.state.footerEditorState}
                                    wrapperClassName="demo-wrapper"
                                    editorClassName="demo-editor"
                                    onEditorStateChange={this.onFooterEditorStateChange}
                                />
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label>Footer Image</label>
                                    {!this.state.footerImageLoading && <React.Fragment>
                                        {!this.state.footerImage &&
                                            <Dropzone onDrop={(files) => this.onFooterImageDrop(files)} >
                                                <div>Try dropping some files here, or click to select files to upload.</div>
                                            </Dropzone>
                                        }
                                        {this.state.footerImage && <div className="form-group">
                                            <ul className="mailbox-attachments clearfix">
                                                <li>
                                                    <span className="mailbox-attachment-icon has-img"><img src={this.state.footerImage.Preview}
                                                        alt="Attachment" /></span>
                                                    <div className="mailbox-attachment-info">
                                                        <span className="mailbox-attachment-name">{this.state.footerImage.Name.length > 20 ? this.state.footerImage.Name.substr(0, 20) + "..." : this.state.footerImage.Name}</span>
                                                        <span className="mailbox-attachment-size">
                                                            {Math.round(this.state.footerImage.Size / 1024)} KB
                                                                                    <a onClick={this.onFooterImageRemove}
                                                                className="btn btn-default btn-xs pull-right"><i className="fa fa-trash"></i></a>
                                                        </span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>}
                                    </React.Fragment>}
                                    {this.state.footerImageLoading && <div>Uploading image</div>}
                                    {/* {!this.state.footerImagePreview &&
                                                        <Dropzone onDrop={(files) => this.onFooterImageDrop(files)} >
                                                            <div>Try dropping some files here, or click to select files to upload.</div>
                                                        </Dropzone>
                                                        }
                                                        {this.state.footerImagePreview && <div className="form-group">
                                                            <ul className="mailbox-attachments clearfix">
                                                                    <li>
                                                                        <span className="mailbox-attachment-icon has-img"><img src={this.state.footerImagePreview}   
                                                                        alt="Attachment"/></span>
                                                                        <div className="mailbox-attachment-info">
                                                                            <span className="mailbox-attachment-name">{this.state.footerImage.name}</span>
                                                                                <span className="mailbox-attachment-size">
                                                                                {Math.round(this.footerImage.size/1024)} KB
                                                                            <a  onClick={this.onFooterImageRemove} 
                                                                            className="btn btn-default btn-xs pull-right"><i className="fa fa-trash"></i></a>
                                                                            </span>
                                                                        </div>
                                                                    </li>
                                                            </ul>
                                                        </div>} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box ">
                    <div className="box-header with-border">
                        <h3 className="box-title">Store information to be printed</h3>
                    </div>
                    <div className="box-body">
                        <table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th style={{ width: "10%" }}></th>
                                    <th style={{ width: "45%" }}>Field</th>
                                    <th style={{ width: "45%" }}>Display Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>                                
                                    <td>
                                        <input type="checkbox" checked={this.state.storeCodeDisplay}
                                            onChange={() => this.setState({ storeCodeDisplay: !this.state.storeCodeDisplay, storeCodeDisplayNameError:"" })}/>
                                    </td>
                                    <td>Store code</td>
                                    <td>
                                        <div className={this.state.storeCodeDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                            <input type="text" className="form-control"
                                                disabled={!this.state.storeCodeDisplay}
                                                onChange={(evt) => this.setState({ storeCodeDisplayName: evt.target.value, storeCodeDisplayNameError:"" })}
                                                value={this.state.storeCodeDisplayName} />                                    
                                            {this.state.storeCodeDisplayNameError !== "" && <span class="help-block">{this.state.storeCodeDisplayNameError}</span>}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.storeNameDisplay}
                                            onChange={() =>this.setState({ storeNameDisplay: !this.state.storeNameDisplay, storeNameDisplayNameError:"" })}/>
                                    </td>
                                    <td>Store Name</td>
                                    <td>
                                    <div className={this.state.storeNameDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.storeNameDisplay}
                                            onChange={(evt) => this.setState({ storeNameDisplayName: evt.target.value, storeNameDisplayNameError:""})}
                                            value={this.state.storeNameDisplayName} />
                                        {this.state.storeNameDisplayNameError !== "" && <span class="help-block">{this.state.storeNameDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.storePhoneNoDisplay}
                                        onChange={() =>this.setState({ storePhoneNoDisplay: !this.state.storePhoneNoDisplay, storePhoneNoDisplayNameError:""})}/>
                                    </td>
                                    <td>Phone No</td>
                                    <td>
                                    <div className={this.state.storePhoneNoDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.storePhoneNoDisplay}
                                            onChange={(evt) => this.setState({ storePhoneNoDisplayName: evt.target.value, storePhoneNoDisplayNameError:""})}
                                            value={this.state.storePhoneNoDisplayName} />
                                        {this.state.storePhoneNoDisplayNameError !== "" && <span class="help-block">{this.state.storePhoneNoDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.storeEmailIDDisplay}
                                        onChange={() =>this.setState({ storeEmailIDDisplay: !this.state.storeEmailIDDisplay, storeEmailIDDisplayNameError:""})}/>
                                    </td>
                                    <td>Email ID</td>
                                    <td>
                                    <div className={this.state.storeEmailIDDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.storeEmailIDDisplay}
                                            onChange={(evt) => this.setState({ storeEmailIDDisplayName: evt.target.value, storeEmailIDDisplayNameError:""})}
                                            value={this.state.storeEmailIDDisplayName} />
                                        {this.state.storeEmailIDDisplayNameError !== "" && <span class="help-block">{this.state.storeEmailIDDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.storeAddressLine1Display}
                                        onChange={() =>this.setState({ storeAddressLine1Display: !this.state.storeAddressLine1Display, storeAddressLine1DisplayNameError:""})}/>
                                    </td>
                                    <td>Address - Line Address 1</td>
                                    <td>
                                    <div className={this.state.storeAddressLine1DisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.storeAddressLine1Display}
                                            onChange={(evt) => this.setState({ storeAddressLine1DisplayName: evt.target.value, storeAddressLine1DisplayNameError:""})}
                                            value={this.state.storeAddressLine1DisplayName} />
                                        {this.state.storeAddressLine1DisplayNameError !== "" && <span class="help-block">{this.state.storeAddressLine1DisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.storeAddressLine2Display}
                                        onChange={() =>this.setState({ storeAddressLine2Display: !this.state.storeAddressLine2Display, storeAddressLine2DisplayNameError:""})}/>
                                    </td>
                                    <td>Address - Line Address 2</td>
                                    <td>
                                    <div className={this.state.storeAddressLine2DisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.storeAddressLine2Display}
                                            onChange={(evt) => this.setState({ storeAddressLine2DisplayName: evt.target.value, storeAddressLine2DisplayNameError:""})}
                                            value={this.state.storeAddressLine2DisplayName} />
                                        {this.state.storeAddressLine2DisplayNameError !== "" && <span class="help-block">{this.state.storeAddressLine2DisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.storeAddressCityDisplay}
                                        onChange={() =>this.setState({ storeAddressCityDisplay: !this.state.storeAddressCityDisplay, storeAddressCityDisplayNameError:""})}/>
                                    </td>
                                    <td>Address - City</td>
                                    <td>
                                    <div className={this.state.storeAddressCityDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.storeAddressCityDisplay}
                                            onChange={(evt) => this.setState({ storeAddressCityDisplayName: evt.target.value, storeAddressCityDisplayNameError:""})}
                                            value={this.state.storeAddressCityDisplayName} />
                                        {this.state.storeAddressCityDisplayNameError !== "" && <span class="help-block">{this.state.storeAddressCityDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.storeAddressStateDisplay}
                                        onChange={() =>this.setState({ storeAddressStateDisplay: !this.state.storeAddressStateDisplay, storeAddressStateDisplayNameError:""})}/>
                                    </td>
                                    <td>Address - State</td>
                                    <td>
                                    <div className={this.state.storeAddressStateDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.storeAddressStateDisplay}
                                            onChange={(evt) => this.setState({ storeAddressStateDisplayName: evt.target.value, storeAddressStateDisplayNameError:""})}
                                            value={this.state.storeAddressStateDisplayName} />
                                        {this.state.storeAddressStateDisplayNameError !== "" && <span class="help-block">{this.state.storeAddressStateDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.storeAddressPostalCodeDisplay}
                                        onChange={() =>this.setState({ storeAddressPostalCodeDisplay: !this.state.storeAddressPostalCodeDisplay, storeAddressPostalCodeDisplayNameError:""})}/>
                                    </td>
                                    <td>Address - Postal Code</td>
                                    <td>
                                    <div className={this.state.storeAddressPostalCodeDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.storeAddressPostalCodeDisplay}
                                            onChange={(evt) => this.setState({ storeAddressPostalCodeDisplayName: evt.target.value, storeAddressPostalCodeDisplayNameError:""})}
                                            value={this.state.storeAddressPostalCodeDisplayName} />
                                        {this.state.storeAddressPostalCodeDisplayNameError !== "" && <span class="help-block">{this.state.storeAddressPostalCodeDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.storeAddressCountryDisplay}
                                        onChange={() =>this.setState({ storeAddressCountryDisplay: !this.state.storeAddressCountryDisplay, storeAddressCountryDisplayNameError:""})}/>
                                    </td>
                                    <td>Address - Country</td>
                                    <td>
                                    <div className={this.state.storeAddressCountryDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.storeAddressCountryDisplay}
                                            onChange={(evt) => this.setState({ storeAddressCountryDisplayName: evt.target.value, storeAddressCountryDisplayNameError:""})}
                                            value={this.state.storeAddressCountryDisplayName} />
                                        {this.state.storeAddressCountryDisplayNameError !== "" && <span class="help-block">{this.state.storeAddressCountryDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.storeCustomField1Display}
                                        onChange={() =>this.setState({ storeCustomField1Display: !this.state.storeCustomField1Display, storeCustomField1DisplayNameError:""})}/>
                                    </td>
                                    <td>{this.state.storeCustomField1Label}</td>
                                    <td>
                                    <div className={this.state.storeCustomField1DisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.storeCustomField1Display}
                                            onChange={(evt) => this.setState({ storeCustomField1DisplayName: evt.target.value, storeCustomField1DisplayNameError:""})}
                                            value={this.state.storeCustomField1DisplayName} />
                                        {this.state.storeCustomField1DisplayNameError !== "" && <span class="help-block">{this.state.storeCustomField1DisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.storeCustomField2Display}
                                        onChange={() =>this.setState({ storeCustomField2Display: !this.state.storeCustomField2Display, storeCustomField2DisplayNameError:""})}/>
                                    </td>
                                    <td>{this.state.storeCustomField2Label}</td>
                                    <td>
                                    <div className={this.state.storeCustomField2DisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.storeCustomField2Display}
                                            onChange={(evt) => this.setState({ storeCustomField2DisplayName: evt.target.value, storeCustomField2DisplayNameError:""})}
                                            value={this.state.storeCustomField2DisplayName} />
                                        {this.state.storeCustomField2DisplayNameError !== "" && <span class="help-block">{this.state.storeCustomField2DisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox"  checked={this.state.storeCustomField3Display}
                                        onChange={() =>this.setState({ storeCustomField3Display: !this.state.storeCustomField3Display, storeCustomField3DisplayNameError:""})}/>
                                    </td>
                                    <td>{this.state.storeCustomField3Label}</td>
                                    <td>
                                    <div className={this.state.storeCustomField3DisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.storeCustomField3Display}
                                            onChange={(evt) => this.setState({ storeCustomField3DisplayName: evt.target.value, storeCustomField3DisplayNameError:""})}
                                            value={this.state.storeCustomField3DisplayName} />
                                        {this.state.storeCustomField3DisplayNameError !== "" && <span class="help-block">{this.state.storeCustomField3DisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" value={this.state.storeCustomField4Display}
                                        onChange={() =>this.setState({ storeCustomField4Display: !this.state.storeCustomField4Display, storeCustomField4DisplayNameError:""})}/>
                                    </td>
                                    <td>{this.state.storeCustomField4Label}</td>
                                    <td>
                                    <div className={this.state.storeCustomField4DisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.storeCustomField4Display}
                                            onChange={(evt) => this.setState({ storeCustomField4DisplayName: evt.target.value, storeCustomField4DisplayNameError:""})}
                                            value={this.state.storeCustomField4DisplayName} />
                                        {this.state.storeCustomField4DisplayNameError !== "" && <span class="help-block">{this.state.storeCustomField4DisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.storeCustomField5Display}
                                        onChange={() =>this.setState({ storeCustomField5Display: !this.state.storeCustomField5Display, storeCustomField5DisplayNameError:""})}/>
                                    </td>
                                    <td>{this.state.storeCustomField5Label}</td>
                                    <td>
                                    <div className={this.state.storeCustomField5DisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.storeCustomField5Display}
                                            onChange={(evt) => this.setState({ storeCustomField5DisplayName: evt.target.value, storeCustomField5DisplayNameError:""})}
                                            value={this.state.storeCustomField5DisplayName} />
                                        {this.state.storeCustomField5DisplayNameError !== "" && <span class="help-block">{this.state.storeCustomField5DisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.storeCustomField6Display}
                                        onChange={() =>this.setState({ storeCustomField6Display: !this.state.storeCustomField6Display, storeCustomField6DisplayNameError:""})}/>
                                    </td>
                                    <td>{this.state.storeCustomField6Label}</td>
                                    <td>
                                    <div className={this.state.storeCustomField6DisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.storeCustomField6Display}
                                            onChange={(evt) => this.setState({ storeCustomField6DisplayName: evt.target.value, storeCustomField6DisplayNameError:""})}
                                            value={this.state.storeCustomField6DisplayName} />
                                        {this.state.storeCustomField6DisplayNameError !== "" && <span class="help-block">{this.state.storeCustomField6DisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                {this.state.module.toLowerCase() ==="sales" && <div className="box ">
                    <div className="box-header with-border">
                        <h3 className="box-title">Customer information to be printed</h3>
                    </div>
                    <div className="box-body">
                        <table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th style={{ width: "10%" }}></th>
                                    <th style={{ width: "45%" }}>Field</th>
                                    <th style={{ width: "45%" }}>Display Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.customerCodeDisplay}
                                        onChange={() =>this.setState({ customerCodeDisplay: !this.state.customerCodeDisplay, customerCodeDisplayNameError:""})}/>
                                    </td>
                                    <td>Customer Code</td>
                                    <td>
                                    <div className={this.state.customerCodeDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.customerCodeDisplay}
                                            onChange={(evt) => this.setState({ customerCodeDisplayName: evt.target.value, customerCodeDisplayNameError:""})}
                                            value={this.state.customerCodeDisplayName} />
                                        {this.state.customerCodeDisplayNameError !== "" && <span class="help-block">{this.state.customerCodeDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.customerNameDisplay}
                                        onChange={() =>this.setState({ customerNameDisplay: !this.state.customerNameDisplay, customerNameDisplayNameError:""})}/>
                                    </td>
                                    <td>Customer Name</td>
                                    <td>
                                    <div className={this.state.customerNameDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.customerNameDisplay}
                                            onChange={(evt) => this.setState({ customerNameDisplayName: evt.target.value, customerNameDisplayNameError:""})}
                                            value={this.state.customerNameDisplayName} />
                                        {this.state.customerNameDisplayNameError !== "" && <span class="help-block">{this.state.customerNameDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.customerPhoneNoDisplay}
                                        onChange={() =>this.setState({ customerPhoneNoDisplay: !this.state.customerPhoneNoDisplay, customerPhoneNoDisplayNameError:""})}/>
                                    </td>
                                    <td>Phone No</td>
                                    <td>
                                    <div className={this.state.customerPhoneNoDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.customerPhoneNoDisplay}
                                            onChange={(evt) => this.setState({ customerPhoneNoDisplayName: evt.target.value, customerPhoneNoDisplayNameError:""})}
                                            value={this.state.customerPhoneNoDisplayName} />
                                        {this.state.customerPhoneNoDisplayNameError !== "" && <span class="help-block">{this.state.customerPhoneNoDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.customerEmailIDDisplay}
                                        onChange={() =>this.setState({ customerEmailIDDisplay: !this.state.customerEmailIDDisplay, customerEmailIDDisplayNameError:""})}/>
                                    </td>
                                    <td>Email ID</td>
                                    <td>
                                    <div className={this.state.customerEmailIDDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.customerEmailIDDisplay}
                                            onChange={(evt) => this.setState({ customerEmailIDDisplayName: evt.target.value, customerEmailIDDisplayNameError:""})}
                                            value={this.state.customerEmailIDDisplayName} />
                                        {this.state.customerEmailIDDisplayNameError !== "" && <span class="help-block">{this.state.customerEmailIDDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.customerBillingAddressDisplay}
                                        onChange={() =>this.setState({ customerBillingAddressDisplay: !this.state.customerBillingAddressDisplay, customerBillingAddressDisplayError:""})}/>
                                    </td>
                                    <td>Billing Address</td>
                                    <td>
                                    <div className={this.state.customerBillingAddressDisplayError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.customerBillingAddressDisplay}
                                            onChange={(evt) => this.setState({ customerBillingAddressDisplayName: evt.target.value, customerBillingAddressDisplayError:""})}
                                            value={this.state.customerBillingAddressDisplayName} />
                                        {this.state.customerBillingAddressDisplayError !== "" && <span class="help-block">{this.state.customerBillingAddressDisplayError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.customerDeliveryAddressDisplay}
                                        onChange={() =>this.setState({ customerDeliveryAddressDisplay: !this.state.customerDeliveryAddressDisplay, customerDeliveryAddressDisplayError:""})}/>
                                    </td>
                                    <td>Delivery Address</td>
                                    <td>
                                    <div className={this.state.customerDeliveryAddressDisplayError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.customerDeliveryAddressDisplay}
                                            onChange={(evt) => this.setState({ customerDeliveryAddressDisplayName: evt.target.value, customerDeliveryAddressDisplayError:""})}
                                            value={this.state.customerDeliveryAddressDisplayName} />
                                        {this.state.customerDeliveryAddressDisplayError !== "" && <span class="help-block">{this.state.customerDeliveryAddressDisplayError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>                                    
                                    <td>
                                        <input type="checkbox" checked={this.state.customerCustomField1Display}
                                        onChange={() =>this.setState({ customerCustomField1Display: !this.state.customerCustomField1Display, customerCustomField1DisplayNameError:""})}/>
                                    </td>
                                    <td>{this.state.customerCustomField1Label}</td>
                                    <td>
                                    <div className={this.state.customerCustomField1DisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.customerCustomField1Display}
                                            onChange={(evt) => this.setState({ customerCustomField1DisplayName: evt.target.value, customerCustomField1DisplayNameError:""})}
                                            value={this.state.customerCustomField1DisplayName} />
                                        {this.state.customerCustomField1DisplayNameError !== "" && <span class="help-block">{this.state.customerCustomField1DisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.customerCustomField2Display}
                                        onChange={() =>this.setState({ customerCustomField2Display: !this.state.customerCustomField2Display, customerCustomField2DisplayNameError:""})}/>
                                    </td>
                                    <td>{this.state.customerCustomField2Label}</td>
                                    <td>
                                    <div className={this.state.customerCustomField2DisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.customerCustomField2Display}
                                            onChange={(evt) => this.setState({ customerCustomField2DisplayName: evt.target.value, customerCustomField2DisplayNameError:""})}
                                            value={this.state.customerCustomField2DisplayName} />
                                        {this.state.customerCustomField2DisplayNameError !== "" && <span class="help-block">{this.state.customerCustomField2DisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.customerCustomField3Display}
                                        onChange={() =>this.setState({ customerCustomField3Display: !this.state.customerCustomField3Display, customerCustomField3DisplayNameError:""})}/>
                                    </td>
                                    <td>{this.state.customerCustomField3Label}</td>
                                    <td>
                                    <div className={this.state.customerCustomField3DisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.customerCustomField3Display}
                                            onChange={(evt) => this.setState({ customerCustomField3DisplayName: evt.target.value, customerCustomField3DisplayNameError:""})}
                                            value={this.state.customerCustomField3DisplayName} />
                                        {this.state.customerCustomField3DisplayNameError !== "" && <span class="help-block">{this.state.customerCustomField3DisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.customerCustomField4Display}
                                        onChange={() =>this.setState({ customerCustomField4Display: !this.state.customerCustomField4Display, customerCustomField4DisplayNameError:""})}/>
                                    </td>
                                    <td>{this.state.customerCustomField4Label}</td>
                                    <td>
                                    <div className={this.state.customerCustomField4DisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.customerCustomField4Display}
                                            onChange={(evt) => this.setState({ customerCustomField4DisplayName: evt.target.value, customerCustomField4DisplayNameError:""})}
                                            value={this.state.customerCustomField4DisplayName} />
                                        {this.state.customerCustomField4DisplayNameError !== "" && <span class="help-block">{this.state.customerCustomField4DisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.customerCustomField5Display}
                                        onChange={() =>this.setState({ customerCustomField5Display: !this.state.customerCustomField5Display, customerCustomField5DisplayNameError:""})}/>
                                    </td>
                                    <td>{this.state.customerCustomField5Label}</td>
                                    <td>
                                    <div className={this.state.customerCustomField5DisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.customerCustomField5Display}
                                            onChange={(evt) => this.setState({ customerCustomField5DisplayName: evt.target.value, customerCustomField5DisplayNameError:""})}
                                            value={this.state.customerCustomField5DisplayName} />
                                        {this.state.customerCustomField5DisplayNameError !== "" && <span class="help-block">{this.state.customerCustomField5DisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.customerCustomField6Display}
                                        onChange={() =>this.setState({ customerCustomField6Display: !this.state.customerCustomField6Display, customerCustomField6DisplayNameError:""})}/>
                                    </td>
                                    <td>{this.state.customerCustomField6Label}</td>
                                    <td>
                                    <div className={this.state.customerCustomField6DisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.customerCustomField6Display}
                                            onChange={(evt) => this.setState({ customerCustomField6DisplayName: evt.target.value, customerCustomField6DisplayNameError:""})}
                                            value={this.state.customerCustomField6DisplayName} />
                                        {this.state.customerCustomField6DisplayNameError !== "" && <span class="help-block">{this.state.customerCustomField6DisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>}
                <div className="box ">
                    <div className="box-header with-border">
                        <h3 className="box-title">Transaction</h3>
                    </div>
                    <div className="box-body">
                        <table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th style={{ width: "10%" }}></th>
                                    <th style={{ width: "45%" }}>Field</th>
                                    <th style={{ width: "45%" }}>Display Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.transactionNoDisplay}
                                        onChange={() =>this.setState({ transactionNoDisplay: !this.state.transactionNoDisplay, transactionNoDisplayNameError:""})}/>
                                    </td>
                                    <td>No</td>
                                    <td>
                                    <div className={this.state.transactionNoDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.transactionNoDisplay}
                                            onChange={(evt) => this.setState({ transactionNoDisplayName: evt.target.value, transactionNoDisplayNameError:""})}
                                            value={this.state.transactionNoDisplayName} />
                                        {this.state.transactionNoDisplayNameError !== "" && <span class="help-block">{this.state.transactionNoDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.transactionDateDisplay}
                                        onChange={() =>this.setState({ transactionDateDisplay: !this.state.transactionDateDisplay, transactionDateDisplayNameError:""})}/>
                                    </td>
                                    <td>Date</td>
                                    <td>
                                    <div className={this.state.transactionDateDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.transactionDateDisplay}
                                            onChange={(evt) => this.setState({ transactionDateDisplayName: evt.target.value, transactionDateDisplayNameError:""})}
                                            value={this.state.transactionDateDisplayName} />
                                        {this.state.transactionDateDisplayNameError !== "" && <span class="help-block">{this.state.transactionDateDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.transactionTotalQtyDisplay}
                                        onChange={() =>this.setState({ transactionTotalQtyDisplay: !this.state.transactionTotalQtyDisplay, transactionTotalQtyDisplayNameError:""})}/>
                                    </td>
                                    <td>Total Quantity</td>
                                    <td>
                                    <div className={this.state.transactionTotalQtyDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.transactionTotalQtyDisplay}
                                            onChange={(evt) => this.setState({ transactionTotalQtyDisplayName: evt.target.value, transactionTotalQtyDisplayNameError:""})}
                                            value={this.state.transactionTotalQtyDisplayName} />
                                        {this.state.transactionTotalQtyDisplayNameError !== "" && <span class="help-block">{this.state.transactionTotalQtyDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.transactionDiscountDisplay}
                                        onChange={() =>this.setState({ transactionDiscountDisplay: !this.state.transactionDiscountDisplay, transactionDiscountDisplayNameError:""})}/>
                                    </td>
                                    <td>Discount Amount</td>
                                    <td>
                                    <div className={this.state.transactionDiscountDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.transactionDiscountDisplay}
                                            onChange={(evt) => this.setState({ transactionDiscountDisplayName: evt.target.value, transactionDiscountDisplayNameError:""})}
                                            value={this.state.transactionDiscountDisplayName} />
                                        {this.state.transactionDiscountDisplayNameError !== "" && <span class="help-block">{this.state.transactionDiscountDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.transactionTaxSummaryDisplay}
                                        onChange={() =>this.setState({ transactionTaxSummaryDisplay: !this.state.transactionTaxSummaryDisplay, transactionTaxSummaryDisplayNameError:""})}/>
                                    </td>
                                    <td>Tax Summary</td>
                                    <td>
                                    <div className={this.state.transactionTaxSummaryDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.transactionTaxSummaryDisplay}
                                            onChange={(evt) => this.setState({ transactionTaxSummaryDisplayName: evt.target.value, transactionTaxSummaryDisplayNameError:""})}
                                            value={this.state.transactionTaxSummaryDisplayName} />
                                        {this.state.transactionTaxSummaryDisplayNameError !== "" && <span class="help-block">{this.state.transactionTaxSummaryDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.transactionTotalDisplay}
                                        onChange={() =>this.setState({ transactionTotalDisplay: !this.state.transactionTotalDisplay, transactionTotalDisplayNameError:""})}/>
                                    </td>
                                    <td>Total</td>
                                    <td>
                                    <div className={this.state.transactionTotalDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.transactionTotalDisplay}
                                            onChange={(evt) => this.setState({ transactionTotalDisplayName: evt.target.value, transactionTotalDisplayNameError:""})}
                                            value={this.state.transactionTotalDisplayName} />
                                        {this.state.transactionTotalDisplayNameError !== "" && <span class="help-block">{this.state.transactionTotalDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                {this.state.module.toLowerCase() ==="sales" && <React.Fragment> <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.transactionPaidDisplay}
                                        onChange={() =>this.setState({ transactionPaidDisplay: !this.state.transactionPaidDisplay, transactionPaidDisplayNameError:""})}/>
                                    </td>
                                    <td>Paid</td>
                                    <td>
                                    <div className={this.state.transactionPaidDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.transactionPaidDisplay}
                                            onChange={(evt) => this.setState({ transactionPaidDisplayName: evt.target.value, transactionPaidDisplayNameError:""})}
                                            value={this.state.transactionPaidDisplayName} />
                                        {this.state.transactionPaidDisplayNameError !== "" && <span class="help-block">{this.state.transactionPaidDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.transactionBalanceDisplay}
                                        onChange={() =>this.setState({ transactionBalanceDisplay: !this.state.transactionBalanceDisplay, transactionBalanceDisplayNameError:""})}/>
                                    </td>
                                    <td>Balance</td>
                                    <td>
                                    <div className={this.state.transactionBalanceDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.transactionBalanceDisplay}
                                            onChange={(evt) => this.setState({ transactionBalanceDisplayName: evt.target.value, transactionBalanceDisplayNameError:""})}
                                            value={this.state.transactionBalanceDisplayName} />
                                        {this.state.transactionBalanceDisplayNameError !== "" && <span class="help-block">{this.state.transactionBalanceDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.transactionRoundOffDisplay}
                                        onChange={() =>this.setState({ transactionRoundOffDisplay: !this.state.transactionRoundOffDisplay, transactionRoundOffDisplayNameError:""})}/>
                                    </td>
                                    <td>Round Off</td>
                                    <td>
                                    <div className={this.state.transactionRoundOffDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.transactionRoundOffDisplay}
                                            onChange={(evt) => this.setState({ transactionRoundOffDisplayName: evt.target.value, transactionRoundOffDisplayNameError:""})}
                                            value={this.state.transactionRoundOffDisplayName} />
                                        {this.state.transactionRoundOffDisplayNameError !== "" && <span class="help-block">{this.state.transactionRoundOffDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.transactionAmountInWordsfDisplay}
                                        onChange={() =>this.setState({ transactionAmountInWordsfDisplay: !this.state.transactionAmountInWordsfDisplay, transactionAmountInWordsfDisplayNameError:""})}/>
                                    </td>
                                    <td>Amount In Words</td>
                                    <td>
                                    <div className={this.state.transactionAmountInWordsfDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.transactionAmountInWordsfDisplay}
                                            onChange={(evt) => this.setState({ transactionAmountInWordsfDisplayName: evt.target.value, transactionAmountInWordsfDisplayNameError:""})}
                                            value={this.state.transactionAmountInWordsfDisplayName} />
                                        {this.state.transactionAmountInWordsfDisplayNameError !== "" && <span class="help-block">{this.state.transactionAmountInWordsfDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.transactionPaymentModeDisplay}
                                        onChange={() =>this.setState({ transactionPaymentModeDisplay: !this.state.transactionPaymentModeDisplay, transactionPaymentModeDisplayError:""})}/>
                                    </td>
                                    <td>Payment Mode</td>
                                    <td>
                                    <div className={this.state.transactionPaymentModeDisplayError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.transactionPaymentModeDisplay}
                                            onChange={(evt) => this.setState({ transactionPaymentModeDisplayName: evt.target.value, transactionPaymentModeDisplayError:""})}
                                            value={this.state.transactionPaymentModeDisplayName} />
                                        {this.state.transactionPaymentModeDisplayError !== "" && <span class="help-block">{this.state.transactionPaymentModeDisplayError}</span>}
                                    </div>
                                    </td>
                                </tr></React.Fragment>}
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.transactionNotesDisplay}
                                        onChange={() =>this.setState({ transactionNotesDisplay: !this.state.transactionNotesDisplay, transactionNotesDisplayError:""})}/>
                                    </td>
                                    <td>Notes</td>
                                    <td>
                                    <div className={this.state.transactionNotesDisplayError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.transactionNotesDisplay}
                                            onChange={(evt) => this.setState({ transactionNotesDisplayName: evt.target.value, transactionNotesDisplayError:""})}
                                            value={this.state.transactionNotesDisplayName} />
                                        {this.state.transactionNotesDisplayError !== "" && <span class="help-block">{this.state.transactionNotesDisplayError}</span>}
                                    </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="box ">
                    <div className="box-header with-border">
                        <h3 className="box-title">Transaction Details</h3>
                    </div>
                    <div className="box-body">
                        <table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th style={{ width: "10%" }}></th>
                                    <th style={{ width: "45%" }}>Field</th>
                                    <th style={{ width: "45%" }}>Display Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.transactionDetailsSrNoDisplay}
                                        onChange={() =>this.setState({ transactionDetailsSrNoDisplay: !this.state.transactionDetailsSrNoDisplay, transactionDetailsSrNoDisplayNameError:""})}/>
                                    </td>
                                    <td>Sr. No.</td>
                                    <td>
                                    <div className={this.state.transactionDetailsSrNoDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.transactionDetailsSrNoDisplay}
                                            onChange={(evt) => this.setState({ transactionDetailsSrNoDisplayName: evt.target.value, transactionDetailsSrNoDisplayNameError:""})}
                                            value={this.state.transactionDetailsSrNoDisplayName} />
                                        {this.state.transactionDetailsSrNoDisplayNameError !== "" && <span class="help-block">{this.state.transactionDetailsSrNoDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.transactionDetailsSKUDisplay}
                                        onChange={() =>this.setState({ transactionDetailsSKUDisplay: !this.state.transactionDetailsSKUDisplay, transactionDetailsSKUDisplayNameError:""})}/>
                                    </td>
                                    <td>SKU</td>
                                    <td>
                                    <div className={this.state.transactionDetailsSKUDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.transactionDetailsSKUDisplay}
                                            onChange={(evt) => this.setState({ transactionDetailsSKUDisplayName: evt.target.value, transactionDetailsSKUDisplayNameError:""})}
                                            value={this.state.transactionDetailsSKUDisplayName} />
                                        {this.state.transactionDetailsSKUDisplayNameError !== "" && <span class="help-block">{this.state.transactionDetailsSKUDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.transactionDetailsItemNameDisplay}
                                        onChange={() =>this.setState({ transactionDetailsItemNameDisplay: !this.state.transactionDetailsItemNameDisplay, transactionDetailsItemNameDisplayNameError:""})}/>
                                    </td>
                                    <td>Item Name</td>
                                    <td>
                                    <div className={this.state.transactionDetailsItemNameDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.transactionDetailsItemNameDisplay}
                                            onChange={(evt) => this.setState({ transactionDetailsItemNameDisplayName: evt.target.value, transactionDetailsItemNameDisplayNameError:""})}
                                            value={this.state.transactionDetailsItemNameDisplayName} />
                                        {this.state.transactionDetailsItemNameDisplayNameError !== "" && <span class="help-block">{this.state.transactionDetailsItemNameDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                {this.state.module.toLowerCase() ==="sales"  && <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.transactionDetailsEmployeeNameDisplay}
                                        onChange={() =>this.setState({ transactionDetailsEmployeeNameDisplay: !this.state.transactionDetailsEmployeeNameDisplay, transactionDetailsEmployeeNameDisplayError:""})}/>
                                    </td>
                                    <td>Employee</td>
                                    <td>
                                    <div className={this.state.transactionDetailsEmployeeNameDisplayError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.transactionDetailsEmployeeNameDisplay}
                                            onChange={(evt) => this.setState({ transactionDetailsEmployeeNameDisplayName: evt.target.value, transactionDetailsEmployeeNameDisplayError:""})}
                                            value={this.state.transactionDetailsEmployeeNameDisplayName} />
                                        {this.state.transactionDetailsEmployeeNameDisplayError !== "" && <span class="help-block">{this.state.transactionDetailsEmployeeNameDisplayError}</span>}
                                    </div>
                                    </td>
                                </tr>}
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.transactionDetailsNotesDisplay}
                                        onChange={() =>this.setState({ transactionDetailsNotesDisplay: !this.state.transactionDetailsNotesDisplay, transactionDetailsNotesDisplayError:""})}/>
                                    </td>
                                    <td>Notes</td>
                                    <td>
                                    <div className={this.state.transactionDetailsNotesDisplayError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.transactionDetailsNotesDisplay}
                                            onChange={(evt) => this.setState({ transactionDetailsNotesDisplayName: evt.target.value, transactionDetailsNotesDisplayError:""})}
                                            value={this.state.transactionDetailsNotesDisplayName} />
                                        {this.state.transactionDetailsNotesDisplayError !== "" && <span class="help-block">{this.state.transactionDetailsNotesDisplayError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.transactionDetailsItemDescriptionDisplay}
                                        onChange={() =>this.setState({ transactionDetailsItemDescriptionDisplay: !this.state.transactionDetailsItemDescriptionDisplay, transactionDetailsItemDescriptionDisplayNameError:""})}/>
                                    </td>
                                    <td>Item Description</td>
                                    <td>
                                    <div className={this.state.transactionDetailsItemDescriptionDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.transactionDetailsItemDescriptionDisplay}
                                            onChange={(evt) => this.setState({ transactionDetailsItemDescriptionDisplayName: evt.target.value, transactionDetailsItemDescriptionDisplayNameError:""})}
                                            value={this.state.transactionDetailsItemDescriptionDisplayName} />
                                        {this.state.transactionDetailsItemDescriptionDisplayNameError !== "" && <span class="help-block">{this.state.transactionDetailsItemDescriptionDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.transactionDetailsBarcodeDisplay}
                                        onChange={() =>this.setState({ transactionDetailsBarcodeDisplay: !this.state.transactionDetailsBarcodeDisplay, transactionDetailsBarcodeDisplayNameError:""})}/>
                                    </td>
                                    <td>Barcode</td>
                                    <td>
                                    <div className={this.state.transactionDetailsBarcodeDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.transactionDetailsBarcodeDisplay}
                                            onChange={(evt) => this.setState({ transactionDetailsBarcodeDisplayName: evt.target.value, transactionDetailsBarcodeDisplayNameError:""})}
                                            value={this.state.transactionDetailsBarcodeDisplayName} />
                                        {this.state.transactionDetailsBarcodeDisplayNameError !== "" && <span class="help-block">{this.state.transactionDetailsBarcodeDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.transactionDetailsHSNDisplay}
                                        onChange={() =>this.setState({ transactionDetailsHSNDisplay: !this.state.transactionDetailsHSNDisplay, transactionDetailsHSNDisplayNameError:""})}/>
                                    </td>
                                    <td>HSN/SAC</td>
                                    <td>
                                    <div className={this.state.transactionDetailsHSNDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.transactionDetailsHSNDisplay}
                                            onChange={(evt) => this.setState({ transactionDetailsHSNDisplayName: evt.target.value, transactionDetailsHSNDisplayNameError:""})}
                                            value={this.state.transactionDetailsHSNDisplayName} />
                                        {this.state.transactionDetailsHSNDisplayNameError !== "" && <span class="help-block">{this.state.transactionDetailsHSNDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.transactionDetailsMRPDisplay}
                                        onChange={() =>this.setState({ transactionDetailsMRPDisplay: !this.state.transactionDetailsMRPDisplay, transactionDetailsMRPDisplayNameError:""})}/>
                                    </td>
                                    <td>MRP</td>
                                    <td>
                                    <div className={this.state.transactionDetailsMRPDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.transactionDetailsMRPDisplay}
                                            onChange={(evt) => this.setState({ transactionDetailsMRPDisplayName: evt.target.value, transactionDetailsMRPDisplayNameError:""})}
                                            value={this.state.transactionDetailsMRPDisplayName} />
                                        {this.state.transactionDetailsMRPDisplayNameError !== "" && <span class="help-block">{this.state.transactionDetailsMRPDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                {this.state.module.toLowerCase() ==="sales"  &&<tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.transactionDetailsRetailPriceDisplay}
                                        onChange={() =>this.setState({ transactionDetailsRetailPriceDisplay: !this.state.transactionDetailsRetailPriceDisplay, transactionDetailsRetailPriceDisplayNameError:""})}/>
                                    </td>
                                    <td>Retail Price</td>
                                    <td>
                                    <div className={this.state.transactionDetailsRetailPriceDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.transactionDetailsRetailPriceDisplay}
                                            onChange={(evt) => this.setState({ transactionDetailsRetailPriceDisplayName: evt.target.value, transactionDetailsRetailPriceDisplayNameError:""})}
                                            value={this.state.transactionDetailsRetailPriceDisplayName} />
                                        {this.state.transactionDetailsRetailPriceDisplayNameError !== "" && <span class="help-block">{this.state.transactionDetailsRetailPriceDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>}
                                {this.state.module.toLowerCase() ==="inventory"  &&<tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.transactionDetailsSupplyPriceDisplay}
                                        onChange={() =>this.setState({ transactionDetailsSupplyPriceDisplay: !this.state.transactionDetailsSupplyPriceDisplay, transactionDetailsSupplyPriceDisplayError:""})}/>
                                    </td>
                                    <td>Supply Price</td>
                                    <td>
                                    <div className={this.state.transactionDetailsSupplyPriceDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.transactionDetailsSupplyPriceDisplay}
                                            onChange={(evt) => this.setState({ transactionDetailsSupplyPriceDisplayName: evt.target.value, transactionDetailsSupplyPriceDisplayError:""})}
                                            value={this.state.transactionDetailsRetailPriceDisplayName} />
                                        {this.state.transactionDetailsSupplyPriceDisplayNameError !== "" && <span class="help-block">{this.state.transactionDetailsSupplyPriceDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>}
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.transactionDetailsQtyDisplay}
                                        onChange={() =>this.setState({ transactionDetailsQtyDisplay: !this.state.transactionDetailsQtyDisplay, transactionDetailsQtyDisplayNameError:""})}/>
                                    </td>
                                    <td>Quantity</td>
                                    <td>
                                    <div className={this.state.transactionDetailsQtyDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.transactionDetailsQtyDisplay}
                                            onChange={(evt) => this.setState({ transactionDetailsQtyDisplayName: evt.target.value, transactionDetailsQtyDisplayNameError:""})}
                                            value={this.state.transactionDetailsQtyDisplayName} />
                                        {this.state.transactionDetailsQtyDisplayNameError !== "" && <span class="help-block">{this.state.transactionDetailsQtyDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.transactionDetailsSubTotalDisplay}
                                        onChange={() =>this.setState({ transactionDetailsSubTotalDisplay: !this.state.transactionDetailsSubTotalDisplay, transactionDetailsSubTotalDisplayNameError:""})}/>
                                    </td>
                                    <td>Sub Total</td>
                                    <td>
                                    <div className={this.state.transactionDetailsSubTotalDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.transactionDetailsSubTotalDisplay}
                                            onChange={(evt) => this.setState({ transactionDetailsSubTotalDisplayName: evt.target.value, transactionDetailsSubTotalDisplayNameError:""})}
                                            value={this.state.transactionDetailsSubTotalDisplayName} />
                                        {this.state.transactionDetailsSubTotalDisplayNameError !== "" && <span class="help-block">{this.state.transactionDetailsSubTotalDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.transactionDetailsDiscountPercentageDisplay}
                                        onChange={() =>this.setState({ transactionDetailsDiscountPercentageDisplay: !this.state.transactionDetailsDiscountPercentageDisplay, transactionDetailsDiscountPercentageDisplayNameError:""})}/>
                                    </td>
                                    <td>Discount %</td>
                                    <td>
                                    <div className={this.state.transactionDetailsDiscountPercentageDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.transactionDetailsDiscountPercentageDisplay}
                                            onChange={(evt) => this.setState({ transactionDetailsDiscountPercentageDisplayName: evt.target.value, transactionDetailsDiscountPercentageDisplayNameError:""})}
                                            value={this.state.transactionDetailsDiscountPercentageDisplayName} />
                                        {this.state.transactionDetailsDiscountPercentageDisplayNameError !== "" && <span class="help-block">{this.state.transactionDetailsDiscountPercentageDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.transactionDetailsDiscountAmountDisplay}
                                        onChange={() =>this.setState({ transactionDetailsDiscountAmountDisplay: !this.state.transactionDetailsDiscountAmountDisplay, transactionDetailsDiscountAmountDisplayNameError:""})}/>
                                    </td>
                                    <td>Discount Amount</td>
                                    <td>
                                    <div className={this.state.transactionDetailsDiscountAmountDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.transactionDetailsDiscountAmountDisplay}
                                            onChange={(evt) => this.setState({ transactionDetailsDiscountAmountDisplayName: evt.target.value, transactionDetailsDiscountAmountDisplayNameError:""})}
                                            value={this.state.transactionDetailsDiscountAmountDisplayName} />
                                        {this.state.transactionDetailsDiscountAmountDisplayNameError !== "" && <span class="help-block">{this.state.transactionDetailsDiscountAmountDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.transactionDetailsTaxDisplay}
                                        onChange={() =>this.setState({ transactionDetailsTaxDisplay: !this.state.transactionDetailsTaxDisplay, transactionDetailsTaxDisplayNameError:""})}/>
                                    </td>
                                    <td>Tax</td>
                                    <td>
                                    <div className={this.state.transactionDetailsTaxDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.transactionDetailsTaxDisplay}
                                            onChange={(evt) => this.setState({ transactionDetailsTaxDisplayName: evt.target.value, transactionDetailsTaxDisplayNameError:""})}
                                            value={this.state.transactionDetailsTaxDisplayName} />
                                        {this.state.transactionDetailsTaxDisplayNameError !== "" && <span class="help-block">{this.state.transactionDetailsTaxDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.transactionDetailTaxAmountDisplay}
                                        onChange={() =>this.setState({ transactionDetailTaxAmountDisplay: !this.state.transactionDetailTaxAmountDisplay, transactionDetailTaxAmountDisplayNameError:""})}/>
                                    </td>
                                    <td>Tax Amount</td>
                                    <td>
                                    <div className={this.state.transactionDetailTaxAmountDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.transactionDetailTaxAmountDisplay}
                                            onChange={(evt) => this.setState({ transactionDetailTaxAmountDisplayName: evt.target.value, transactionDetailTaxAmountDisplayNameError:""})}
                                            value={this.state.transactionDetailTaxAmountDisplayName} />
                                        {this.state.transactionDetailTaxAmountDisplayNameError !== "" && <span class="help-block">{this.state.transactionDetailTaxAmountDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" checked={this.state.transactionDetailsTotalDisplay}
                                        onChange={() =>this.setState({ transactionDetailsTotalDisplay: !this.state.transactionDetailsTotalDisplay, transactionDetailsTotalDisplayNameError:""})}/>
                                    </td>
                                    <td>Total</td>
                                    <td>
                                    <div className={this.state.transactionDetailsTotalDisplayNameError !== "" ? "form-group has-error" : "form-group "}>
                                        <input type="text" className="form-control"
                                            disabled={!this.state.transactionDetailsTotalDisplay}
                                            onChange={(evt) => this.setState({ transactionDetailsTotalDisplayName: evt.target.value, transactionDetailsTotalDisplayNameError:""})}
                                            value={this.state.transactionDetailsTotalDisplayName} />
                                        {this.state.transactionDetailsTotalDisplayNameError !== "" && <span class="help-block">{this.state.transactionDetailsTotalDisplayNameError}</span>}
                                    </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* <div className="box-footer"> */}
                <button type="submit"
                    style={{ marginBottom: "10px", marginLeft: "10px" }}
                    className="btn btn-md btn-success pull-right btn-flat"
                    onClick={this.onSavePrintConfig}>Save</button>
                <button onClick={() => this.onCancelClick()}
                    style={{ marginBottom: "10px" }}
                    className="btn btn-md btn-default btn-flat pull-right">Cancel</button>

                {/* </div>  */}
            </section>
        </div>
    }
}

export default withRouter(PrintConfig)