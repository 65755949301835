import firebase, {database} from '../firebase'
import * as actionTypes from '../actionTypes'
import * as constants from '../Constatnts'

export function addNewStore(store){
    return (dispatch, getState) => {
      store.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
      store.ActionBy = getState().user.user.Name
      store.ActionByUID = getState().user.user.UID
      store.ActionByEmailID = getState().user.user.EmailID

      store.Action = constants.AUDIT_TRAIL_NEW
        return database.collection("Stores").add({
          ...store
        })
        .then(function(docRef) {
            return docRef.id
        })
        .catch(function(error) {
            console.error("Error adding store: ", error);
        })
    }
}

export function getStores(registrationID, from, size, option,
    searchText, searchCity, searchState, searchPostalCode, searchCountry, searchCustomField1,
    searchCustomField2, searchCustomField3, searchCustomField4, searchCustomField5, searchCustomField6) {
        let searchParameters =   {
            "from": from,
            "size": size, 
            "query": {
              "bool": {
                "must": [
                  { "match": { "RegistrationID.keyword": registrationID}}
                ],
                "must_not": [
                ], 
                "should": [                
                ],
                "minimum_should_match": 1,
                "filter": {
                  "bool": {
                    "must": [
                       
                      ]
                  }
                }
              }
            }
          }
          if(option === "active")
          {
            searchParameters.query.bool.must_not.push( {"match": { "IsDeleted": "true" }})
          }
          if(option === "inactive")
          {
            searchParameters.query.bool.must_not.push( {"match": { "IsDeleted": "false" }})
          }
          searchParameters.query.bool.should.push({ "regexp": { "StoreCode": "@"+ searchText.trim().toLowerCase() +  ".*" }})
          searchParameters.query.bool.should.push({ "regexp": { "StoreName": "@"+ searchText.trim().toLowerCase() +  ".*" }})
          searchParameters.query.bool.should.push({ "regexp": { "EmailID": "@"+ searchText.trim().toLowerCase() +  ".*" }})
          searchParameters.query.bool.should.push({ "regexp": { "PhoneNo": "@"+ searchText.trim().toLowerCase() +  ".*" }})

          if(searchCity.trim()!=="")
          {
            searchParameters.query.bool.filter.bool.must.push({ "regexp": { "City": "@"+ searchCity.trim().toLowerCase() +  ".*" }})
          }
          if(searchState.trim()!=="")
          {
            searchParameters.query.bool.filter.bool.must.push({ "regexp": { "State": "@"+ searchState.trim().toLowerCase() +  ".*" }})
          }
          if(searchPostalCode.trim()!=="")
          {
            searchParameters.query.bool.filter.bool.must.push({ "regexp": { "PostalCode": "@"+ searchPostalCode.trim().toLowerCase() +  ".*" }})
          }
          if(searchCountry.trim()!=="")
          {
            searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Country": "@"+ searchCountry.trim().toLowerCase() +  ".*" }})
          }
          if(searchCustomField1.trim()!=="")
          {
            searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField1": "@"+ searchCustomField1.trim().toLowerCase() +  ".*" }})
          }
          if(searchCustomField2.trim()!=="")
          {
            searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField2": "@"+ searchCustomField2.trim().toLowerCase() +  ".*" }})
          }
          if(searchCustomField3.trim()!=="")
          {
            searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField3": "@"+ searchCustomField3.trim().toLowerCase() +  ".*" }})
          }
          if(searchCustomField4.trim()!=="")
          {
            searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField4": "@"+ searchCustomField4.trim().toLowerCase() +  ".*" }})
          }
          if(searchCustomField5.trim()!=="")
          {
            searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField5": "@"+ searchCustomField5.trim().toLowerCase() +  ".*" }})
          }
          if(searchCustomField6.trim()!=="")
          {
            searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField6": "@"+ searchCustomField6.trim().toLowerCase() +  ".*" }})
          }
    // console.log("Store Search Query", JSON.stringify(searchParameters))
          return (dispatch) => {
            let addMessage =  firebase.functions().httpsCallable('getStores');
            return addMessage({text: searchParameters}).then(function(result) {
                const stores = []
                let sanitizedMessage = result.data.text;
                    sanitizedMessage.hits.hits.map((data, index)=>{
                    stores.push({key: data._id, ...data._source})
                })
            return  {totalItemsCount: sanitizedMessage.hits.total, searchResult:stores}
            });
        }
}

export function getUserStores(registrationID, userID) {
  let user = { RegistrationID: registrationID }
  
  return (dispatch) => {

    let addMessage = firebase.functions().httpsCallable('getUserStores');

    return addMessage({ text: user }).then(function (result) {

      let userStores = result.data.text
      return database.collection("Stores").where("RegistrationID", "==", registrationID).where("IsDeleted", "==", false).get().then((querySnapshot) => {
        const stores = []
        querySnapshot.forEach((doc) => {
          for (let i = 0; i < userStores.length; i++) {
            if (userStores[i].id === doc.id || userStores[i].id === "***")
              stores.push(
                {
                  key: doc.id, ...doc.data()
                }
              )
          }
        })
        dispatch({type:actionTypes.SET_STORES, stores})
        return stores
      }).catch((error) => {
        console.log("Error getting store list: ", error)
      })
    });
  }
}

export function deleteStore(key){
    return (dispatch, getState) =>{
        return database.collection("Stores").doc(key).update({
          IsDeleted:true,
          ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
            ActionBy: getState().user.user.Name,
            ActionByUID: getState().user.user.UID,
            ActionByEmailID:getState().user.user.EmailID,

            Action: constants.AUDIT_TRAIL_DELETE
          }).then(()=> {return true})
    }
}

export function getStoreDetails(key){
    return (dispatch) => {
    return database.collection("Stores").doc(key).get().then((doc) => {
        if (doc.exists) {            
           return doc.data()
        } else {
            console.log("No such store found!");
        }
    })
    }
}

export function updateStore(store, key){
    return (dispatch, getState) => {
      store.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
      store.ActionBy = getState().user.user.Name
      store.ActionByUID = getState().user.user.UID
      store.ActionByEmailID = getState().user.user.EmailID

      store.Action = constants.AUDIT_TRAIL_EDIT
      // console.log('store',store)      
        return database.collection("Stores").doc(key).update({
          ...store
        })
    }
}

export function checkIfStoreCodeIsUnique(storeCode, storeID, registrationID){
  return (dispatch) => {
      let searchParameters =   {
          "from": 0,
          "size": 1, 
          "query": {
            "bool": {
              "must": [
                { "match": { "RegistrationID": registrationID}},
                { "match": { "IsDeleted": "false" }},
                { "match": { "StoreCode": storeCode }}
              ],
              "must_not": [
                  
              ] 
            }
          }
        }
        if(storeID !=="")
        {
          searchParameters.query.bool.must_not.push({ "match": { "_id": storeID }})
        }
        console.log('storeID',storeID)
       console.log('searchParameters',JSON.stringify(searchParameters))
       let addMessage =  firebase.functions().httpsCallable('getStores');
       return addMessage({text: searchParameters}).then(function(result) {
          let sanitizedMessage = result.data.text
          let isUnique = true 
          if(sanitizedMessage.hits.total > 0 ){
              isUnique = false
          }
          return  isUnique
      });
  }
}
