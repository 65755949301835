import React, { Component } from 'react';
import {Link } from 'react-router-dom';
import Confirm from 'react-confirm-bootstrap'
import { withRouter } from 'react-router-dom'
import Pagination from "react-js-pagination"
import Loader from '../Loader'
import ImportData from '../ImportData'
import Modal from 'react-responsive-modal'
import {HotKeys} from 'react-hotkeys';
import * as constants from '../../Constatnts'
import AuditTrail from '../AuditTrail'
import * as constatnts from '../../Constatnts'
import { isNullOrUndefined } from 'util';
import ImportLog from '../ImportLog'
import CustomerReference from './CustomerReference';

const keyMap = {
    new:'ctrl+n'
};

class CustomerList extends Component{
    constructor () {
        super()
        this.state={            
            customers:[],
            referredCustomer: {},
            showFilters:false,
            searchText:'',
            searchCity:'',
            searchState:'',
            searchPinCode:'',
            searchCountry:'',
            searchMembership:'',
            searchWalletFrom:'',
            searchWalletTo:'',
            searchCustomField1:'',
            searchCustomField2:'',
            searchCustomField3:'',
            searchCustomField4:'',
            searchCustomField5:'',
            searchCustomField6:'',
            searchNotes:'',
            activePage:1,
            itemsCountPerPage:20,
            totalItemsCount:0,
            recordFrom:0,
            paginationStatus:'No records to display',
            searchActiveInactiveAllRecords:'active',
            customField1DisplayName:'Custom Field 1',
            customField2DisplayName:'Custom Field 2',
            customField3DisplayName:'Custom Field 3',
            customField4DisplayName:'Custom Field 4',
            customField5DisplayName:'Custom Field 5',
            customField6DisplayName:'Custom Field 6',
            loading: true,
            showImport:false,
            showReferenceModal: false,
            exportCalled: false
        }
        this.onCustomerDelete = this.onCustomerDelete.bind(this)
        this.loadCustomers = this.loadCustomers.bind(this)
        this.showHideFilters = this.showHideFilters.bind(this)
        this.onClearSearch = this.onClearSearch.bind(this)
        this.onSearchActiveInactiveAllRecordsClicked = this.onSearchActiveInactiveAllRecordsClicked.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.showPaginationStatus= this.showPaginationStatus.bind(this)
        this.openNewCustomer = this.openNewCustomer.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.onExportClick = this.onExportClick.bind(this)
    }

    componentDidMount()
    {
        this.props.config.CustomFields.map((customField) => {
            if (customField.ModuleName === 'Customer')
            {
                let customField1DisplayName = customField.CustomField1Name.trim() === '' ? 'Custom Field 1' : customField.CustomField1Name.trim()
                let customField2DisplayName = customField.CustomField2Name.trim() === '' ? 'Custom Field 2' : customField.CustomField2Name.trim()
                let customField3DisplayName = customField.CustomField3Name.trim() === '' ? 'Custom Field 3' : customField.CustomField3Name.trim()
                let customField4DisplayName = customField.CustomField4Name.trim() === '' ? 'Custom Field 4' : customField.CustomField4Name.trim()
                let customField5DisplayName = customField.CustomField5Name.trim() === '' ? 'Custom Field 5' : customField.CustomField5Name.trim()
                let customField6DisplayName = customField.CustomField6Name.trim() === '' ? 'Custom Field 6' : customField.CustomField6Name.trim()
                this.setState({
                    customField1DisplayName:customField1DisplayName,
                    customField2DisplayName:customField2DisplayName,
                    customField3DisplayName:customField3DisplayName,
                    customField4DisplayName:customField4DisplayName,
                    customField5DisplayName:customField5DisplayName,
                    customField6DisplayName:customField6DisplayName
                })
            }
        })
        this.loadCustomers(
        this.state.recordFrom,
        this.state.searchActiveInactiveAllRecords,
        this.state.searchText,
        this.state.searchCity, 
        this.state.searchState, 
        this.state.searchPinCode, 
        this.state.searchCountry,
        this.state.searchMembership,            
        this.state.searchWalletFrom,
        this.state.searchWalletTo,
        this.state.searchCustomField1,
        this.state.searchCustomField2,
        this.state.searchCustomField3,
        this.state.searchCustomField4,
        this.state.searchCustomField5,
        this.state.searchCustomField6,
        this.state.searchNotes)
    }
    
    loadCustomers(from, option,searchText, searchCity, searchState, searchPinCode, searchCountry, searchMembership,
        searchWalletFrom, searchWalletTo, customField1, customField2,customField3,customField4,customField5,customField6, searchNotes ){
            this.setState({loading:true})
        this.props.getCustomers(this.props.user.user.RegistrationID,this.props.user.store.key, from, this.state.itemsCountPerPage, option,searchText, 
            searchCity, searchState, searchPinCode, searchCountry, searchMembership, searchWalletFrom, searchWalletTo,
            customField1, customField2,customField3,customField4,customField5,customField6, searchNotes )
        .then((result)=>{
            // customers = result.searchResult
            // totalItemsCount = result.totalItemsCount
            this.setState({customers:result.searchResult,totalItemsCount: result.totalItemsCount, loading: false})
            this.showPaginationStatus()
        })        
    }

    onCustomerDelete(key){
       this.props.deleteCustomer(key).then(()=> {
        this.props.getToastr("Customer deleted successfully")
        
        this.loadCustomers(
            this.state.recordFrom,
            this.state.searchActiveInactiveAllRecords,
            this.state.searchText,
            this.state.searchCity, 
            this.state.searchState, 
            this.state.searchPinCode, 
            this.state.searchCountry,
            this.state.searchMembership,
            this.state.searchWalletFrom,
            this.state.searchWalletTo,
            this.state.searchCustomField1,
            this.state.searchCustomField2,
            this.state.searchCustomField3,
            this.state.searchCustomField4,
            this.state.searchCustomField5,
            this.state.searchCustomField6,
            this.state.searchNotes)
       })
    }

    showHideFilters()
    {
        let showFilters = this.state.showFilters
        this.setState({showFilters: !showFilters})
    }

    onClearSearch()
    {
        this.setState({recordFrom:0, searchText:'',searchCity:'', 
        searchState:'', searchPinCode:'', searchCountry:'', 
        searchMembership:'', searchWalletfrom:'', searchWalletTo:'',
        searchCustomField1 :'',searchCustomField2 :'',searchCustomField3 :'',
        searchCustomField4 :'',searchCustomField6 :'',searchCustomField6 :'',
        searchNotes:'', showFilters:false,
        searchActiveInactiveAllRecords:"active"})
        this.loadCustomers(0, "active", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "")
    }

    onSearchActiveInactiveAllRecordsClicked(opiton)
    {
        this.setState({searchActiveInactiveAllRecords:opiton, recordFrom:0})
        this.loadCustomers(
            0,
            opiton,
            this.state.searchText,
            this.state.searchCity, 
            this.state.searchState, 
            this.state.searchPinCode, 
            this.state.searchCountry,
            this.state.searchMembership,
            this.state.searchWalletFrom,
            this.state.searchWalletTo,
            this.state.searchCustomField1,
            this.state.searchCustomField2,
            this.state.searchCustomField3,
            this.state.searchCustomField4,
            this.state.searchCustomField5,
            this.state.searchCustomField6,
            this.state.searchNotes)
    }

    onSearchClick(){
        this.setState({searchFrom:0})
        this.loadCustomers(
            0,
            this.state.searchActiveInactiveAllRecords,
            this.state.searchText,
            this.state.searchCity, 
            this.state.searchState, 
            this.state.searchPinCode, 
            this.state.searchCountry,
            this.state.searchMembership,
            this.state.searchWalletFrom,
            this.state.searchWalletTo,
            this.state.searchCustomField1,
            this.state.searchCustomField2,
            this.state.searchCustomField3,
            this.state.searchCustomField4,
            this.state.searchCustomField5,
            this.state.searchCustomField6,
            this.state.searchNotes)
    }

    handlePageChange(pageNumber) {
        //console.log(`active page is ${pageNumber}`);
       // searchFrom = this.state.searchFrom
       let recordFrom = ((pageNumber -1) * this.state.itemsCountPerPage)
        this.setState({activePage: pageNumber,recordFrom:recordFrom});
        this.loadCustomers(
            recordFrom,
            this.state.searchActiveInactiveAllRecords,
            this.state.searchText,
            this.state.searchCity, 
            this.state.searchState, 
            this.state.searchPinCode, 
            this.state.searchCountry,
            this.state.searchMembership,
            this.state.searchWalletFrom,
            this.state.searchWalletTo,
            this.state.searchCustomField1,
            this.state.searchCustomField2,
            this.state.searchCustomField3,
            this.state.searchCustomField4,
            this.state.searchCustomField5,
            this.state.searchCustomField6,
            this.state.searchNotes)
      }

      onExportClick() {
        this.setState({ exportCalled: true })
        this.props.getCustomers(this.props.user.user.RegistrationID, 
            this.props.user.store.key, 
            0, 
            10000, 
            this.state.searchActiveInactiveAllRecords, this.state.searchText, this.state.searchCity,
            this.state.searchState, this.state.searchPinCode, this.state.searchCountry,
            this.state.searchCustomField1,
            this.state.searchCustomField2,
            this.state.searchCustomField3,
            this.state.searchCustomField4,
            this.state.searchCustomField5,
            this.state.searchCustomField6)
            .then((result) => {
                // console.log(result.searchResult);
                if (isNullOrUndefined(result.searchResult) || result.searchResult.length === 0) {
                    this.props.getToastr("No Record Found")
                    return
                }
                let customers = []
                result.searchResult.map((data) => {
                    // console.log(JSON.stringify(data))
                    let customer = {
                        "Customer Code": data.CustomerCode,
                        "First Name": data.FirstName,
                        "Last Name": !isNullOrUndefined(data.LastName) ? data.LastName : "",
                        "Gender": !isNullOrUndefined(data.Gender) ? data.Gender : "",
                        "PhoneNo.": !isNullOrUndefined(data.PhoneNo) ? data.PhoneNo : "",
                        "EmailID": !isNullOrUndefined(data.EmailID) ? data.EmailID : "",
                        "Notes": !isNullOrUndefined(data.Note) ? data.Note : "",
                        "Address Type": "",
                        "Address Line1": "",
                        "Address Line2": "",
                        "City": "",
                        "State": "",
                        "Postal Code": "",
                        "Country": "",
                        "Membership": data.Membership ? (data.Membership.Name ? data.Membership.Name : "") : "",
                        "Opening Balance": !isNullOrUndefined(data.WalletOpeningBalance)? (data.WalletOpeningBalance):0,
                        "Outstanding Amount": !isNullOrUndefined(data.OutstandingAmount)?(data.OutstandingAmount):0,
                        "Wallet Balance": !isNullOrUndefined(data.WalletBalance)? (data.WalletBalance) : 0,
                        "Last Recharged Amount" : !isNullOrUndefined(data.WalletLastCreditAmount) ? data.WalletLastCreditAmount : 0,
                    }
                    data.Addresses.map((address) => {
                        if (address.AddressType==="Home") {
                            customer["Address Type"] = address.AddressType
                            customer["Address Line1"] = address.Address1
                            customer["Address Line2"] = address.Address2
                            customer.City = address.City
                            customer.State = address.State
                            customer["Postal Code"] = address.PostalCode
                            customer.Country = address.Country
                        }
                    })
                    if(!isNullOrUndefined(data.DOBNew))
                    {
                        let dob = data.DOBNew.Day + '-' + data.DOBNew.Month
                        if(data.DOBNew.Year !="")
                        {
                            dob = dob + '-' + data.DOBNew.Year
                        }
                        customer["Date of Birth"] = dob
                    }
                    if(!isNullOrUndefined(data.AnniversaryNew))
                    {
                        let aniversary = data.AnniversaryNew.Day + '-' + data.AnniversaryNew.Month
                        if(data.AnniversaryNew.Year !="")
                        {
                            aniversary = aniversary + '-' + data.AnniversaryNew.Year
                        }
                        customer["Aniversary"] = aniversary
                    }
                    customers.push(customer);
                })

                const csvData = constatnts.objToCSV(customers);
                this.setState({ loading: false, exportCalled: false })
                const csvBlob = new Blob([csvData], { type: "text/csv" });
                const url = window.URL.createObjectURL(csvBlob);
                const a = document.createElement("a");
                a.setAttribute("hidden", "");
                a.setAttribute("href", url);
                a.setAttribute("download", "customers.csv");
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
    }

      showPaginationStatus()
      {
          console.log('this.state.recordFrom', this.state.recordFrom)
            let paginationStatus 
            if((this.state.recordFrom +1)  === this.state.totalItemsCount)
            {
                paginationStatus= "Showing " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
            }
            else if((this.state.recordFrom + this.state.itemsCountPerPage + 1 )> this.state.totalItemsCount )
            {
                paginationStatus= "Showing " + (this.state.recordFrom +1) + " to  " + this.state.totalItemsCount + " of " + this.state.totalItemsCount 
            }
            else{
                paginationStatus= "Showing " + (this.state.recordFrom +1 )+ " to  " + (this.state.recordFrom + this.state.itemsCountPerPage ) + " of " + this.state.totalItemsCount 

            }
            this.setState({paginationStatus:paginationStatus})

      }

      openNewCustomer(){
        this.props.history.push({pathname:"/home/customer/newcustomer"})
      }

      handleKeyPress(e){
        if (e.key === 'Enter') {
            this.onSearchClick()
          }
      }
      
    render() {    
        const btnColStyle = {width:"140px"}
        const handlers = {
            new:this.openNewCustomer
        };

        const revisionHistory = { width: "200px" }
        return <HotKeys
        keyMap={keyMap}
        handlers={handlers}><div className="content-wrapper" >
        <section className="content" >
            {/* <form onSubmit={(e) => {this.onSearchClick(); alert('Form submit');e.preventDefault();}}> */}
            <div className="row">
                <div className="col-md-12">
                    <div className="box ">
                        <div className="box-header with-border">
                            <div className="row">
                                <div className="col-md-6">
                                    <font style={{fontSize:"30px"}}>Customers</font>
                                </div>
                                <div className="col-md-6">
                                    <div className="pull-right" style={{marginTop:"6px"}}>
                                        <button type="button" className="btn btn-default dropdown-toggle btn-flat  btn-md" data-toggle="dropdown">Action
                                            <span className="fa fa-caret-down"></span></button>
                                            <ul class="dropdown-menu" style={{right:"auto"}}>
                                                <li><a href="#" onClick={() => this.setState({ showImport: true })}>Import</a></li>
                                                <li class="divider"></li>
                                                <li><a href="#" onClick={() => { this.setState({ showImportLog: true }) }}>Import Log</a></li>
                                                <li class="divider"></li>
                                                {/* <li> <a onClick={this.onExportClick} > Export <i class="glyphicon glyphicon-download-alt "></i></a></li> */}
                                                {(this.props.user.user.Role==="Power User" ||  this.props.user.user.Role==="Store Owner" ) && 
                                                <React.Fragment> 
                                                    <li class="divider"></li>
                                                    <li> <a onClick={this.onExportClick} > Export <i class="glyphicon glyphicon-download-alt "></i></a></li>
                                                 </React.Fragment>}
                                            </ul>
                                            <text>  </text>
                                        <button  
                                        onClick={this.openNewCustomer} 
                                        className="btn btn-success btn-flat  btn-md" >Add Customer</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box-body">
                            <div className="row">
                                <div className="col-md-12" style={{marginBottom:"10px"}}>
                                    <div className="btn-group">
                                       <button type="button" className={this.state.searchActiveInactiveAllRecords === "active" ?  "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md" }
                                       onClick={()=> this.onSearchActiveInactiveAllRecordsClicked("active")}>Active</button>
                                       <button type="button" className={this.state.searchActiveInactiveAllRecords === "inactive" ?  "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md" }
                                       onClick={()=> this.onSearchActiveInactiveAllRecordsClicked("inactive")}>In Active</button>
                                       <button type="button" className={this.state.searchActiveInactiveAllRecords === "all" ?  "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md" }
                                       onClick={()=> this.onSearchActiveInactiveAllRecordsClicked("all")}>All</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="form-group">
                                        <label>Search by customer name, code, email id, contact no</label>
                                            <input type="text" 
                                            onKeyPress={this.handleKeyPress}
                                            autoFocus={true}
                                            className="form-control input-md"
                                            value={this.state.searchText}  
                                             onChange={(evt)=>this.setState({searchText:evt.target.value})}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <button type="button" className="btn btn-primary btn-flat btn-md pull-right" 
                                    style={{marginTop:"25px", marginLeft:"5px"}}
                                    onClick={this.onSearchClick}
                                    >Search</button>
                                    <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{marginTop:"25px"}} 
                                    onClick={this.onClearSearch} >Clear</button>
                                    <a type="button" className="btn btn-md pull-right" style={{marginTop:"25px"}} onClick={this.showHideFilters}>{this.state.showFilters ? "Less Filter" : "More Filters"}</a>
                                </div>
                            </div>
                            {this.state.showFilters && <div className="row">
                                <div className="col-md-9">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>City</label>
                                            <input type="text" className="form-control input-md" 
                                            onKeyPress={this.handleKeyPress}
                                            value={this.state.searchCity} 
                                            onChange={(evt)=>this.setState({searchCity:evt.target.value})}/>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>State</label>
                                            <input type="text" className="form-control input-md" 
                                            onKeyPress={this.handleKeyPress} 
                                            value={this.state.searchState} 
                                            onChange={(evt)=>this.setState({searchState:evt.target.value})}/>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Pincode</label>
                                            <input type="text" className="form-control input-md" 
                                            onKeyPress={this.handleKeyPress} 
                                            value={this.state.searchPinCode} 
                                            onChange={(evt)=>this.setState({searchPinCode:evt.target.value})}/>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Country</label>
                                            <input type="text" className="form-control input-md"  
                                            onKeyPress={this.handleKeyPress}
                                            value={this.state.searchCountry} 
                                            onChange={(evt)=>this.setState({searchCountry:evt.target.value})}/>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {this.state.showFilters && <div className="row">
                                <div className="col-md-9">
                                <div className="col-md-3" >
                                            <div className="form-group">
                                                <label>Membership</label>
                                                <input type="text" className="form-control input-md"
                                                    value={this.state.searchMembership}
                                                    onKeyPress={this.handleKeyPress}
                                                    onChange={(evt) => this.setState({ searchMembership: evt.target.value })} />
                                            </div>
                                        </div>
                                        <div className="col-md-3" >
                                            <div className="form-group">
                                                <label>Wallet Amount From</label>
                                                <input type="number" className="form-control input-md"
                                                    value={this.state.searchWalletFrom}
                                                    onKeyPress={this.handleKeyPress}
                                                    onChange={(evt) => this.setState({ searchWalletFrom: evt.target.value })} />                                                                       
                                            </div>
                                        </div>
                                        <div className="col-md-3" >
                                            <div className="form-group">           
                                            <label>Wallet Amount To</label>                                     
                                                <input type="number" className="form-control input-md"
                                                    value={this.state.searchWalletTo}
                                                    onKeyPress={this.handleKeyPress}
                                                    onChange={(evt) => this.setState({ searchWalletTo: evt.target.value })} />
                                            </div>
                                        </div>
                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>{this.state.customField1DisplayName}</label>
                                            <input type="text" className="form-control input-md" 
                                            value={this.state.searchCustomField1} 
                                            onKeyPress={this.handleKeyPress}
                                            onChange={(evt)=>this.setState({searchCustomField1:evt.target.value})}/>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>}
                            {this.state.showFilters && <div className="row">
                                <div className="col-md-9">
                                <div className="col-md-3">
                                        <div className="form-group">
                                            <label>{this.state.customField2DisplayName}</label>
                                            <input type="text" className="form-control input-md"  
                                            onKeyPress={this.handleKeyPress}
                                            value={this.state.searchCustomField2} 
                                            onChange={(evt)=>this.setState({searchCustomField2:evt.target.value})}/>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>{this.state.customField3DisplayName}</label>
                                            <input type="text" className="form-control input-md"  
                                            onKeyPress={this.handleKeyPress}
                                            value={this.state.searchCustomField3} 
                                            onChange={(evt)=>this.setState({searchCustomField3:evt.target.value})}/>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>{this.state.customField4DisplayName}</label>
                                            <input type="text" className="form-control input-md" 
                                            onKeyPress={this.handleKeyPress} 
                                            value={this.state.searchCustomField4} 
                                            onChange={(evt)=>this.setState({searchCustomField4:evt.target.value})}/>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>{this.state.customField5DisplayName}</label>
                                            <input type="text" className="form-control input-md" 
                                            onKeyPress={this.handleKeyPress}
                                            value={this.state.searchCustomField5} 
                                            onChange={(evt)=>this.setState({searchCustomField5:evt.target.value})}/>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {this.state.showFilters && <div className="row">
                                <div className="col-md-9">
                                <div className="col-md-3">
                                        <div className="form-group">
                                            <label>{this.state.customField6DisplayName}</label>
                                            <input type="text" className="form-control input-md"  
                                            onKeyPress={this.handleKeyPress}
                                            value={this.state.searchCustomField6} 
                                            onChange={(evt)=>this.setState({searchCustomField6:evt.target.value})}/>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Notes</label>
                                            <input type="text" className="form-control input-md"  
                                            onKeyPress={this.handleKeyPress}
                                            value={this.state.searchNotes} 
                                            onChange={(evt)=>this.setState({searchNotes:evt.target.value})}/>
                                        </div>
                                    </div>
                                        </div>                               
                                </div>}

                            {this.state.loading && <Loader show={this.state.loading} />}

                            {!this.state.loading &&
                                <table className = "table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Customer Details</th>
                                        <th>Contact Details</th>
                                        <th>Address</th>
                                        <th>Additional Details</th>
                                        <th>Notes</th>
                                        <th style={revisionHistory}>Revision History</th>
                                        <th style={btnColStyle}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.customers.map((customer) =>
                                    <tr>
                                        <td>{customer.FirstName + ' ' + customer.LastName}
                                        <div>{customer.CustomerCode}</div>
                                        <div>{customer.Gender}</div>
                                        </td>
                                        <td>{customer.PhoneNo}
                                            <div>{customer.EmailID}</div>
                                        </td>
                                        <td>
                                            {customer.Addresses && customer.Addresses.map((address)=> <div>
                                                <div><b>{address.AddressType}</b></div>
                                                <div>{address.Address1}</div>
                                                <div>{address.Address2}</div>
                                                <div>{address.City}</div>
                                                <div>{address.State}</div>
                                                <div>{address.PostalCode}</div>
                                                <div>{address.Country}</div>

                                            </div>
                                        )}
                                        </td>
                                        <td>
                                            {customer.CustomField1.trim()!== "" &&<div>{this.state.customField1DisplayName}:{customer.CustomField1}</div>}
                                            {customer.CustomField2.trim()!== "" &&<div>{this.state.customField2DisplayName}:{customer.CustomField2}</div>}
                                            {customer.CustomField3.trim()!== "" &&<div>{this.state.customField3DisplayName}:{customer.CustomField3}</div>}
                                            {customer.CustomField4.trim()!== "" &&<div>{this.state.customField4DisplayName}:{customer.CustomField4}</div>}
                                            {customer.CustomField5.trim()!== "" &&<div>{this.state.customField5DisplayName}:{customer.CustomField5}</div>}
                                            {customer.CustomField6.trim()!== "" && <div>{this.state.customField6DisplayName}:{customer.CustomField6}</div>}
                                            <div>Last Visited : {(new Date(customer.ActionOn)).toString().substring(0,24)}</div>
                                            <div>Last Recharged Amount : {customer.WalletLastCreditAmount}</div>
                                            <div>Wallet Balance Amount : {customer.WalletBalance}</div>
                                            {customer.Membership && customer.Membership.hasOwnProperty('MembershipID') && customer.Membership.MembershipID !=="" && 
                                            <div>Membership: {customer.Membership.Name} </div>
                                            }
                                            {customer.hasOwnProperty('SaleToPurchaseStore') && customer.SaleToPurchaseStore !== "" &&  <div>Mapped to Store : {customer.SaleToPurchaseStore}</div>}
                                       
                                        </td>
                                        <td>{customer.Note}</td>
                                        <td>
                                                        <div>Action :{customer.Action}</div>
                                                        <div>Action By :{customer.ActionBy}</div>                                                        
                                                        <div>Action Date :{(new Date(customer.ActionOn)).toString().substring(0,24)}</div>
                                                        <AuditTrail {...this.props} module={constants.MODULE_CUSTOMER} parentKey={customer.key}/>
                                                    </td>
                                        <td>
                                            <div class="btn-group">
                                            { customer.IsDeleted ? <span class="label label-danger pull-right">Inactive</span> : 
                                                <div>
                                                    <Link to={`/home/customer/edit/${customer.key}`}   type="button" class="btn btn-md btn-flat btn-default "><i class="fa fa-edit"></i></Link>  
                                                    <Confirm
                                                        onConfirm={() => this.onCustomerDelete(customer.key)}
                                                        body="Are you sure you would like to delete this customer?"
                                                        confirmText="Delete"
                                                        title="Customer Delete">
                                                        <button className="btn btn-default btn-flat"><i class="fa fa-trash-o"></i></button>
                                                    </Confirm>
                                                    <br></br>
                                                    <button type="button" className="btn btn-md btn-flat btn-default"
                                                                        onClick={() => this.setState({ showReferenceModal: true, referredCustomer: customer })}>
                                                                        <i className="fa fa-link"></i></button>
                                                    <Link to={`/home/customer/customerdashboard/${customer.key}`} type="button" class="btn btn-md btn-flat btn-default "><i class="fa fa-dashboard"></i></Link>                                          
                                                      
                                                </div>    
                                            }
                                            </div>
                                        </td>
                                     </tr>
                                    )}                                            
                                </tbody>
                                </table>}
                                {this.state.totalItemsCount > 0 && <div className="pull-left" style={{marginTop:"25px"}}>
                                {this.state.paginationStatus}
                                </div>}
                                <div className="pull-right">
                                    <Pagination
                                    activePage={this.state.activePage} //this.state.activePage
                                    itemsCountPerPage={this.state.itemsCountPerPage}
                                    totalItemsCount={this.state.totalItemsCount}
                                    pageRangeDisplayed={2}
                                    onChange={this.handlePageChange}
                                    />
                                </div>
                        </div>   
                    </div>
                </div>
                </div>
                {/* </form> */}
                </section>
                <Modal open={this.state.showImport} onClose={() => this.setState({ showImport: false })} center>
                    <ImportData  {...this.props} importType="customer" 
                    onClose={() => this.setState({ showImport: false })}
                    onImport={()=> {
                        this.loadCustomers(
                            this.state.recordFrom,
                            this.state.searchActiveInactiveAllRecords,
                            this.state.searchText,
                            this.state.searchCity, 
                            this.state.searchState, 
                            this.state.searchPinCode, 
                            this.state.searchCountry,
                            this.state.searchCustomField1,
                            this.state.searchCustomField2,
                            this.state.searchCustomField3,
                            this.state.searchCustomField4,
                            this.state.searchCustomField5,
                            this.state.searchCustomField6)    
                    }}
                    />
               </Modal>
               <Modal open={this.state.showImportLog} onClose={() => this.setState({ showImportLog: false })} center>
                    <ImportLog  {...this.props} importType="customer"
                        onClose={() => this.setState({ showImportLog: false })}
                    />
                </Modal>
                <Modal open={this.state.showReferenceModal} onClose={() => this.setState({ showReferenceModal: false })} center>
                        <CustomerReference  {...this.props}
                        referredCustomer={this.state.referredCustomer}
                        onClose={() => this.setState({ showReferenceModal: false })}
                        />
                </Modal>
                </div>   </HotKeys>
    }
}

export default withRouter(CustomerList)
