import React from 'react'
import AuditTrail from '../AuditTrail'
import * as constants from '../../Constatnts'

class CustomFields extends React.Component {
    constructor() {
        super()
        this.state = {
            moduleName: '',
            customField1Name: '',
            customField2Name: '',
            customField3Name: '',
            customField4Name: '',
            customField5Name: '',
            customField6Name: '',
            key: '',
            saving: false
        }
        this.onModuleNameChange = this.onModuleNameChange.bind(this)
        this.onSaveCustomFields = this.onSaveCustomFields.bind(this)
    }

    onModuleNameChange = (e) => {
        let moduleName = e.target.value
        this.setState({ moduleName: moduleName })
        if (moduleName.trim() !== "") {
            this.setState({
                customField1Name: '',
                customField2Name: '',
                customField3Name: '',
                customField4Name: '',
                customField5Name: '',
                customField6Name: '',
                key: ''
            })
            //get additional field names from db
            this.props.getCustomFieldNames(this.props.user.user.RegistrationID, moduleName).then((customFieldNames) => {
                console.log('before', customFieldNames)
                if (!customFieldNames) { return }
                console.log('after', customFieldNames)

                this.setState({
                    customField1Name: customFieldNames.CustomField1Name,
                    customField2Name: customFieldNames.CustomField2Name,
                    customField3Name: customFieldNames.CustomField3Name,
                    customField4Name: customFieldNames.CustomField4Name,
                    customField5Name: customFieldNames.CustomField5Name,
                    customField6Name: customFieldNames.CustomField6Name,
                    key: customFieldNames.key
                })
            })
        } else {
            this.setState({
                customField1Name: '',
                customField2Name: '',
                customField3Name: '',
                customField4Name: '',
                customField5Name: '',
                customField6Name: '',
                key: ''
            })
        }
    };

    onSaveCustomFields() {
        this.setState({ saving: true })
        //check if  code and  name is entered 
        if (this.state.moduleName.trim() === "") {
            alert('Please select module')
        }
        let customFields = {
            ModuleName: this.state.moduleName,
            CustomField1Name: this.state.customField1Name,
            CustomField2Name: this.state.customField2Name,
            CustomField3Name: this.state.customField3Name,
            CustomField4Name: this.state.customField4Name,
            CustomField5Name: this.state.customField5Name,
            CustomField6Name: this.state.customField6Name,
            RegistrationID: this.props.user.user.RegistrationID
        }

        this.props.saveCustomFieldNames(customFields, this.state.key).then(() => {
            this.props.getToastr("Custom Field Saved Successfully")
            this.setState({ saving: false })
            this.props.getAllCustomFieldNames(this.props.user.user.RegistrationID)
        })
    }

    render() {
        return <div className="content-wrapper">
            {/* <section className="content-header">
            <h1>Custom Fields</h1>
        </section>   */}
            <section className="content" >
                <div className="box ">
                    <div className="box-header with-border">
                        <div className="row">
                            <div className="col-md-12">
                                <font style={{ fontSize: "30px" }}>Custom Fields</font>
                            </div>
                        </div>
                    </div>
                    <div className="box-body">
                        <div className="form-group">
                            <label>Module</label>
                            <select className="form-control"
                                value={this.state.moduleName}
                                onChange={this.onModuleNameChange}
                            >
                                <option value="">Select module</option>
                                <option value="Customer">Customer</option>
                                <option value="Store">Store</option>
                                <option value="Employee">Employee</option>
                                <option value="Sales">Sales</option>
                                <option value="Vendor">Vendor</option> 
                            </select>
                        </div>
                        {this.state.moduleName.trim() !== "" && <div>
                            <table className="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Custom Field</th>
                                        <th>Display Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Custom Field 1</td>
                                        <td>
                                            <input type="text" className="form-control"
                                                onChange={(evt) => this.setState({ customField1Name: evt.target.value })}
                                                value={this.state.customField1Name} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Custom Field 2</td>
                                        <td>
                                            <input type="text" className="form-control"
                                                onChange={(evt) => this.setState({ customField2Name: evt.target.value })}
                                                value={this.state.customField2Name} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Custom Field 3</td>
                                        <td>
                                            <input type="text" className="form-control"
                                                onChange={(evt) => this.setState({ customField3Name: evt.target.value })}
                                                value={this.state.customField3Name} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Custom Field 4</td>
                                        <td>
                                            <input type="text" className="form-control"
                                                onChange={(evt) => this.setState({ customField4Name: evt.target.value })}
                                                value={this.state.customField4Name} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Custom Field 5</td>
                                        <td>
                                            <input type="text" className="form-control"
                                                onChange={(evt) => this.setState({ customField5Name: evt.target.value })}
                                                value={this.state.customField5Name} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Custom Field 6</td>
                                        <td>
                                            <input type="text" className="form-control"
                                                onChange={(evt) => this.setState({ customField6Name: evt.target.value })}
                                                value={this.state.customField6Name} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="box-footer">
                                {this.state.key &&
                                    <AuditTrail {...this.props} module={constants.MODULE_CUSTOM_FIELD} parentKey={this.state.key} />}

                                <button type="submit"
                                    className="btn btn-flat btn-md btn-flat btn-success pull-right"
                                    onClick={this.onSaveCustomFields} disabled={this.state.saving}>Save</button>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </section>
        </div>

    }
}

export default CustomFields