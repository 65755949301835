import React, { Component } from 'react'

var AsyncTypeahead = require('react-bootstrap-typeahead').AsyncTypeahead;

class PartySearch extends Component {
    constructor() {
        super()
        this.state = {
            isPartyTypeAheadLoading: false,
            partyTypeAheadSelected: [],
            partyTypeAheadOptions: []
        }
        this.onPartyTypeAheadSearch = this.onPartyTypeAheadSearch.bind(this)
        this.onPartySelected = this.onPartySelected.bind(this)
        this.onRemoveParty = this.onRemoveParty.bind(this)
    }

    onPartyTypeAheadSearch(query) {
        this.setState({ isPartyTypeAheadLoading: true });
        this.props.searchParty(this.props.user.user.RegistrationID, this.props.user.store.key, query)
            .then((result) => {
                this.setState({ partyTypeAheadOptions: result.searchResult })
                this.setState({ isPartyTypeAheadLoading: false });
            })
    }

    onRemoveParty() {
        this.props.onPartyChange(null)
    }

    onPartySelected(selected) {
        this.props.onPartyChange(selected[0])
    }

    render() {
        return <React.Fragment>
            {!this.props.party &&
                <div className={this.props.error ? "form-group has-error" : "form-group "}
                    style={{ marginBottom: "0px" }}>
                        <AsyncTypeahead
                            ref="asyncPartySearch"
                            allowNew={false}
                            isLoading={this.state.isPartyTypeAheadLoading}
                            multiple={false}
                            options={this.state.partyTypeAheadOptions}
                            labelKey="PartyFirstName"
                            minLength={3}
                            onSearch={this.onPartyTypeAheadSearch}
                            placeholder="Search party"
                            filterBy={['PartyFirstName', 'PartyLastName', 'PhoneNo', 'EmailID', 'PartyCode']}
                            dropup={false}
                            highlightOnlyResult={true}
                            selected={this.state.partyTypeAheadSelected}
                            onChange={(selected) => {
                                this.onPartySelected(selected)
                            }}
                            renderMenuItemChildren={(option, props) => (
                                <div>
                                    <div><b>{option.PartyFirstName} {option.PartyLastName}</b></div>
                                    <div>{option.PhoneNo}</div>
                                    <div>{option.EmailID}</div>
                                    <div>{option.PartyCode}</div>
                                </div>
                            )}
                        />
                  
                </div>}
            {this.props.party && <div className="input-group">
                <button type="button"
                    className="btn btn-default btn-block btn-flat btn-md" style={{ padding: "0px", height: "34px" }}>
                    <div style={{ fontSize: "15px", marginLeft: "5px" }} className="pull-left">{this.props.party.PartyFirstName} {this.props.party.PartyLastName}  {this.props.party.PartyCode !== "" ? " (" + this.props.party.PartyCode + ")" : ""}</div><br></br>
                    {this.props.party.PhoneNo !== "" && <div style={{ fontSize: "12px", marginLeft: "5px" }} className="pull-left"><i className="fa fa-phone"></i> {this.props.party.PhoneNo}</div>}
                    {this.props.party.EmailID !== "" && <div style={{ fontSize: "12px", marginRight: "5px" }} className="pull-right"><i className="fa fa-envelope"></i> {this.props.party.EmailID}</div>}
                </button>
                <div className="input-group-btn">
                    <button type="button" className="btn btn-default btn-md btn-flat"
                        onClick={() => this.onRemoveParty()}><i className="glyphicon glyphicon-remove" ></i> </button>
                </div>
            </div>}
        </React.Fragment>
    }
}

export default PartySearch