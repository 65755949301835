// import { auth, googleProvider, twitterProvider, facebookProvider } from '../firebase';
import * as actionTypes from '../actionTypes';
import firebase, {database} from '../firebase'

export function getUserACL()
{
    return (dispatch) => {
        let addMessage = firebase.functions().httpsCallable('getUserACL');
        return addMessage().then(function (result) {
        let acl = result.data.text;
        dispatch({type:actionTypes.SET_ACL, acl })
        });
    }
}

export function setAuthUser(auth)
{
    return dispatch => {
        dispatch({type:actionTypes.SET_AUTH, auth })
    }
}

export function signOutUser()
{
    return dispatch => {
        dispatch({type:actionTypes.SIGN_OUT})
    }
}

export function getUserDetails(uid)
{
    return (dispatch) => {
        
        return database.collection("User").where("UID","==", uid).get().then((querySnapshot) => {
            return querySnapshot.forEach((doc) => {
                const user =  doc.data()
                dispatch({type:actionTypes.SET_USER, user })
                // {id, EmailID , Name, RegistrationID, UID}=
           })
        }).catch((error) => {
            console.log(error)
        })
    }
}

export function getAccessibleStores(registrationID) {
    let user = { RegistrationID: registrationID }
  
    return (dispatch) => {
  
      let addMessage = firebase.functions().httpsCallable('getUserStores');
  
      return addMessage({ text: user }).then(function (result) {
  
        let userStores = result.data.text
        // console.log('getUserStores', userStores)
        return database.collection("Stores").where("RegistrationID", "==", registrationID).where("IsDeleted", "==", false).get().then((querySnapshot) => {
          const stores = []
          querySnapshot.forEach((doc) => {
            for (let i = 0; i < userStores.length; i++) {
              if (userStores[i].id === doc.id || userStores[i].id === "***")
                stores.push(
                  {
                    key: doc.id, ...doc.data()
                  }
                )
            }
          })
          dispatch({type:actionTypes.SET_STORES, stores})
          return stores
        }).catch((error) => {
          console.log("Error getting store list: ", error)
        })
      });
    }
  }

export function createUser(user) {
    return (dispatch, getState) => {
        user.Action = "New";
        user.ActionOn= firebase.firestore.FieldValue.serverTimestamp();
        user.ActionBy= getState().user.user.Name;
        user.ActionByEmailID= getState().user.user.EmailID;
        user.ActionByUID= getState().user.user.UID;
        
        console.log("createUser", JSON.stringify(user));
        let addMessage = firebase.functions().httpsCallable('createUser');
        return addMessage({ text: user }).then(function (result) {
            return result
        });
    }
}

export function setStore(store)
{
    return dispatch => {
        dispatch({type:actionTypes.SET_STORE, store})
    }
}


export function setCashRegister(cashRegister)
{
    return dispatch => {
        dispatch({type:actionTypes.SET_CASH_REGISTER, cashRegister})
    }
}

export function setCashRegisterClosing(cashRegisterClosing)
{
    return dispatch => {
        dispatch({type:actionTypes.SET_CASH_REGISTER_CLOSING, cashRegisterClosing})
    }
}