import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Link } from 'react-router-dom';

class EmployeeDashboard extends Component {

    constructor() {
        super();
        this.state = {
            employees: []
            , topEmployees: []
            , employeeProductTypes: []
            , stores: []
            , storeList: []
            , searchStore: ""
            , searchCashRegister: ""
            , searchTransactionType: ""
            , searchTransactionTypeName : 'All'
            , rightHeight:40
            , searchTransactionFromDate: new Date().toISOString().split('T')[0]
            , searchTransactionToDate: new Date().toISOString().split('T')[0],
        };

        this.loadEmployeeWiseSales = this.loadEmployeeWiseSales.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.onSearchClick = this.onSearchClick.bind(this);
        this.onClearSearch = this.onClearSearch.bind(this);
        this.getRandomColor = this.getRandomColor.bind(this);
        this.loadEmployeeDashboard = this.loadEmployeeDashboard.bind(this);
        this.loadTopPerformingEmployee = this.loadTopPerformingEmployee.bind(this);
        this.loadEmployeeProductTypeWise = this.loadEmployeeProductTypeWise.bind(this);
        this.onStoreChange = this.onStoreChange.bind(this)
    };

    componentDidMount() {
        this.setState({ storeID: this.props.user.store.key })
        let storeList = []
        storeList.push({ key: this.props.user.store.key })

        this.props.getUserStores(this.props.user.user.RegistrationID, "").then((stores) => {                
            this.setState({ stores: stores })
        });
    this.setState({ storeList: storeList, storeID: this.props.user.store.key });

        this.loadEmployeeDashboard(storeList, this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate,this.state.searchTransactionType);
    };

    
    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }

    getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    loadEmployeeWiseSales(storeList, searchTransactionFromDate, searchTransactionToDate,searchTransactionType) {
        this.props.getEmployeeDashboard(this.props.user.user.RegistrationID, storeList
            , searchTransactionFromDate, searchTransactionToDate,"10000",searchTransactionType)
            .then((result) => {
                //console.log("Employee-wise - Data", JSON.stringify(result.searchResult))
                let employees = {
                    labels: [],
                    datasets: [
                        {
                            label: 'Staff',
                            backgroundColor: [],
                            hoverBackgroundColor: [],
                            data: []
                        }
                    ]
                };
                result.searchResult.map((data, index) => {
                    employees.labels.push(data.name);
                    employees.datasets[0].data.push(data.total_sales);
                    let color = this.getRandomColor()
                    employees.datasets[0].backgroundColor.push(color);
                    employees.datasets[0].hoverBackgroundColor.push(color);
                });
                //console.log("Final Employee Data : ", employees);
                this.setState({ employees: employees });
            });
    };

    loadTopPerformingEmployee(storeList, searchTransactionFromDate, searchTransactionToDate,searchTransactionType) {
        this.props.getEmployeeDashboard(this.props.user.user.RegistrationID, storeList
            , searchTransactionFromDate, searchTransactionToDate,"3",searchTransactionType)
            .then((result) => {
                this.setState({ topEmployees: result.searchResult });
            });

    };

    loadEmployeeProductTypeWise(storeList, searchTransactionFromDate, searchTransactionToDate,searchTransactionType) {
        this.props.getEmployeeProductTypeWise(this.props.user.user.RegistrationID, storeList, searchTransactionFromDate, searchTransactionToDate,searchTransactionType)
            .then((result) => {
                this.setState({ employeeProductTypes: result.searchResult });
            });
    };

    loadEmployeeDashboard(storeList, searchTransactionFromDate, searchTransactionToDate,searchTransactionType) {
        this.loadEmployeeWiseSales(storeList, searchTransactionFromDate, searchTransactionToDate,searchTransactionType);
        this.loadTopPerformingEmployee(storeList, searchTransactionFromDate, searchTransactionToDate,searchTransactionType);
        this.loadEmployeeProductTypeWise(storeList, searchTransactionFromDate, searchTransactionToDate,searchTransactionType);
    };

    onSearchClick() {
        this.setState({ searchFrom: 0, loading: true})
        this.loadEmployeeDashboard(this.state.storeList,
            this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate,
            this.state.searchTransactionType);
    };

    onClearSearch() {
        let storeList = []
        storeList.push({ key: this.props.user.store.key});

        this.setState({storeList : storeList, 
            searchTransactionFromDate: new Date().toISOString().split('T')[0]
            , searchTransactionToDate: new Date().toISOString().split('T')[0]
            ,searchTransactionType : ''
        })
        this.loadEmployeeDashboard(storeList, new Date().toISOString().split('T')[0], new Date().toISOString().split('T')[0], '')
    };

    onStoreChange(e){
        let storeList = []
        console.log("key", e.target.value);
        if (e.target.value != "") {
            storeList.push({key: e.target.value})
        }
        else
        {
            for (let i = 0; i < this.state.stores.length; i++) {
                const store = this.state.stores[i];
                storeList.push({ key: store.key })
            }
        }
        console.log("storeList", JSON.stringify(storeList));
        this.setState({ storeList: storeList, storeID: e.target.value })
    }

    render() {
        return <div className="content-wrapper">
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6">
                                <font style={{ fontSize: "30px", marginBottom: "4px" }}>Employee Insight</font>
                            </div>
                            <div className="col-md-6">
                                <div className="input-group pull-right">
                                    <select type="text" className="form-control" style={{ width: "25%" }}
                                        value={this.state.storeID}
                                        onChange={this.onStoreChange}>
                                        <option value="">All Stores</option>
                                        {this.state.stores.filter((e) => !e.IsDeleted).map((store) =>
                                            <option value={store.key}>{store.StoreCode}-{store.StoreName}</option>
                                        )}
                                    </select>
                                    <select type="text" className="form-control" style={{ width: "23%" }}
                                        onChange={(evt) => this.setState({ searchTransactionType: evt.target.value,searchTransactionTypeName: evt.target.options[evt.target.selectedIndex].text })}
                                        value={this.state.searchTransactionType}>
                                        <option value="">All</option>
                                        {this.props.config.TransactionTypes.filter((e) => e.Module === "sales" && !e.IsDeleted).map((e) => <option value={e.key}>{e.TransactionType}</option>)}
                                    </select>
                                    <input type="date" className="form-control" style={{ width: "23%" }}
                                        onChange={(evt) => this.setState({ searchTransactionFromDate: evt.target.value })}
                                        value={this.state.searchTransactionFromDate} onKeyPress={this.handleKeyPress}/>
                                    <input type="date" className="form-control" style={{ width: "24%" }}
                                        onChange={(evt) => this.setState({ searchTransactionToDate: evt.target.value })}
                                        value={this.state.searchTransactionToDate} onKeyPress={this.handleKeyPress} /> 
                                    <button type="button" className="btn btn-primary btn-flat btn-md fa fa-search" style={{ width: "5%",padding: "9px" }}
                                        onClick={this.onSearchClick}></button>                                   
                                </div>
                            </div>                      
                        </div>
                    </div>
                    <div>
                        <div className="box-body">
                            <div className="row">
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <div className="box box-success">
                                        <div className="box-header with-border">
                                            <h3 className="box-title">Employee Performance</h3>
                                        </div>
                                        <div className="box-body">
                                            <div className="chart" style={{height:this.state.rightHeight + "vh"}}>
                                                <Doughnut
                                                    width={150}
                                                    height={70}
                                                    data={this.state.employees}
                                                    options={{
                                                        maintainAspectRatio: false
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6 col-xs-12">
                                    <div className="box box-success">
                                        <div className="box-header with-border">
                                            <h3 className="box-title">Top Peformer</h3>
                                        </div>
                                        <div className="box-body">
                                            {this.state.topEmployees.length > 0 && <div style={{height:this.state.rightHeight + "vh"}}>
                                                {this.state.topEmployees.map((employee) =>
                                                    <React.Fragment>
                                                            <span className="info-box-icon" style={{background:"transparent",lineHeight:'15px',marginTop:"70px",width:"170px" }}>
                                                                <i className="fa fa-trophy" style={{ color: this.getRandomColor()}}></i>
                                                                <br></br>
                                                                <span className="info-box-text">{employee.name}</span>
                                                                <br></br>
                                                                <span className="info-box-number"><i className="fa fa-inr"/>{employee.total_sales}</span>
                                                            </span>
                                                    </React.Fragment>
                                                )}
                                            </div>}
                                            {this.state.topEmployees.length === 0 && "No records found"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="box box-success">
                                        <div className="box-header with-border">
                                            <h3 className="box-title">Employee Wise</h3>
                                        </div>
                                        <div className="box-body">
                                            <div className="table-responsive">
                                                {this.state.employeeProductTypes.length > 0 && <table className="table no-margin">
                                                    <thead>
                                                        <tr>
                                                            <th>Employee</th>
                                                            <th>Bill Count</th>
                                                            <th>Services</th>
                                                            <th>Products</th>
                                                            <th>Wallet</th>
                                                            <th>Total</th>
                                                            <th>Gross Total</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.employeeProductTypes.map((empProd) =>
                                                            <tr>
                                                                <td>{empProd.name}</td>
                                                                <td>{empProd.total_transactions}</td>
                                                                <td><span><i className="fa fa-inr"/>{empProd.product_types.filter((e) => e.name ==="Services").map((type) => type.total_sales) > 0 ? empProd.product_types.filter((e) => e.name ==="Services").map((type) => type.total_sales) : 0}</span></td>
                                                                <td><span><i className="fa fa-inr"/>{empProd.product_types.filter((e) => e.name ==="Products").map((type) => type.total_sales)> 0 ? empProd.product_types.filter((e) => e.name ==="Products").map((type) => type.total_sales) : 0 }</span></td>
                                                                <td><span><i className="fa fa-inr"/>{empProd.product_types.filter((e) => e.name ==="Wallet Recharge").map((type) => type.total_sales) >0 ? empProd.product_types.filter((e) => e.name ==="Wallet Recharge").map((type) => type.total_sales) :0 }</span></td>
                                                                <td><span><i className="fa fa-inr"/>{empProd.total_sales}</span></td>
                                                                <td><span><i className="fa fa-inr"/>{empProd.total_gross_sales}</span></td>
                                                                <td><Link to={{ pathname: "/home/employeeworksheet", state: { key: empProd.employeeID, name:empProd.name, 
                                                                        searchTransactionFromDate: this.state.searchTransactionFromDate
                                                                        , searchTransactionToDate: this.state.searchTransactionToDate
                                                                        ,searchTransactionType: this.state.searchTransactionType, searchTransactionTypeName: this.state.searchTransactionTypeName } }}
                                                                        type="button" className="btn btn-md btn-default "><i className="fa fa-edit"></i></Link></td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>}
                                                {this.state.employeeProductTypes.length === 0 && "No records found"}
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    };
};
export default EmployeeDashboard;