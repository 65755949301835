import React, { Component } from 'react'
import Print from './Print'

class CashRegisterClosing extends Component {

    constructor() {
        super()
        this.state = {closingPaymentModes:[],
            closingRemarks:'',
            closingTotal:0,
            saving:false,
            print:false,
            printChecked:false,
            printData:null
        }
            this.onSave = this.onSave.bind(this)
            this.onCancel = this.onCancel.bind(this)
    }

   async componentDidMount(){

        //get cash register closing details
        if(!this.props.user.cashRegisterClosing)
        {
            return 
        }

        let paymentsResult = await this.props.getPaymenDetailstForCashRegisterClosing(this.props.user.user.RegistrationID, this.props.user.cashRegisterClosing.CashRegisterID,  new Date(this.props.user.cashRegisterClosing.OpeningTime.seconds*1000)) 
        // let d = new Date(this.props.user.cashRegisterClosing.OpeningTime.seconds*1000)
        // let openingTimeStr = d.getFullYear().toString()+"-"+((d.getMonth()+1).toString().length==2?(d.getMonth()+1).toString():"0"+(d.getMonth()+1).toString())+"-"+(d.getDate().toString().length==2?d.getDate().toString():"0"+d.getDate().toString())+" "+(d.getHours().toString().length==2?d.getHours().toString():"0"+d.getHours().toString())+":"+((parseInt(d.getMinutes()/5)*5).toString().length==2?(parseInt(d.getMinutes()/5)*5).toString():"0"+(parseInt(d.getMinutes()/5)*5).toString())+":00";
        // d = new Date()
        // let closingTimeStr = d.getFullYear().toString()+"-"+((d.getMonth()+1).toString().length==2?(d.getMonth()+1).toString():"0"+(d.getMonth()+1).toString())+"-"+(d.getDate().toString().length==2?d.getDate().toString():"0"+d.getDate().toString())+" "+(d.getHours().toString().length==2?d.getHours().toString():"0"+d.getHours().toString())+":"+((parseInt(d.getMinutes()/5)*5).toString().length==2?(parseInt(d.getMinutes()/5)*5).toString():"0"+(parseInt(d.getMinutes()/5)*5).toString())+":00";
        let cashExpense = await this.props.getCashFromCounterAmountForCashRegisterClosing(this.props.user.cashRegisterClosing.CashRegisterID,new Date(this.props.user.cashRegisterClosing.OpeningTime.seconds*1000))    
        let closingPaymentModes  = []
        let cashSales = 0
        let cashOpeningBalance= this.props.user.cashRegisterClosing.OpeningBalance
        let cashComponents = []
        let cashArr = paymentsResult.filter((e)=> e.PaymentMode.toLowerCase() ==="cash") 
        if(cashArr.length > 0 )
        {
            cashSales = cashArr[0].Amount
        }
        cashComponents.push({Name:"Opening Balance", Amount: cashOpeningBalance})
        cashComponents.push({Name:"Sales", Amount: cashSales})
        cashComponents.push({Name:"Expense", Amount: cashExpense})
        
        let cash = {PaymentMode: "Cash", 
        Amount:Number(cashOpeningBalance) + Number(cashSales) - Number(cashExpense) ,
        Components:cashComponents }

        closingPaymentModes.push(cash)
        paymentsResult.filter((e)=> (e.PaymentMode.toLowerCase() !=="cash" &&  e.PaymentMode.toLowerCase() !=="wallet")).map((m)=>{
            closingPaymentModes.push({PaymentMode: m.PaymentMode, 
            Amount: m.Amount,
            Components:[{Name:"Sales", Amount:m.Amount}] })
        })
        let closingTotal = 0 
        closingPaymentModes.map((m)=>{
            closingTotal = Number(m.Amount) + Number(closingTotal) 
        })
        // console.log('closingPaymentModes', closingPaymentModes)
        this.setState({closingPaymentModes:closingPaymentModes, closingTotal:closingTotal})
    }
    
    onSave()
    {
        this.setState({saving:true})
        let cashRegisterClosing = {ClosingRemarks:this.state.closingRemarks,
            ClosingTotal:this.state.closingTotal,
            ClosingPaymentModes: this.state.closingPaymentModes,
            ClosingTime: new Date(),
            ClosingByUserID:this.props.user.user.UID,
            ClosingByUserName:this.props.user.user.Name,
            Status:"Closed"
        }

        this.props.closeCashRegister(this.props.user.cashRegisterClosing.key, cashRegisterClosing).then(async()=>{
            let CashRegisterClosingPrint = await this.props.getCashRegisterClosingDetails(this.props.user.cashRegisterClosing.key)
            CashRegisterClosingPrint.ClosingTime = (new Date(CashRegisterClosingPrint.ClosingTime.seconds*1000)).toString().substring(0,24) 
            CashRegisterClosingPrint.OpeningTime = (new Date(CashRegisterClosingPrint.OpeningTime.seconds*1000)).toString().substring(0,24)
            this.props.getToastr("Cash register closed successfully")   
            this.props.setCashRegister(null)
            this.props.setCashRegisterClosing(null)

            if(this.state.printChecked)
            {
                let printConfig = {}
                printConfig.PrintSize ="3 inch" 
                printConfig.PrintTemplate  = this.props.registration.registration.hasOwnProperty("CashRegisterClosingTemplate") ? this.props.registration.registration.CashRegisterClosingTemplate :""
                let printData = {CashRegisterClosing:CashRegisterClosingPrint,PrintConfig:printConfig}
                this.setState({print:true,printData:printData })
            }
            else{
                this.props.history.push('/home/')
            }
        })
    }

    onCancel()
    {
        this.props.history.push('/home/')
    }

    render() {
        return <React.Fragment> 
            {this.props.user.cashRegisterClosing && <div className="content-wrapper">
                <section className="content-header">
                    <h1>
                        Cash Register Closing
                    </h1>            
                    <button type="submit" className="btn btn-md btn-success btn-flat pull-right"
                        style={{ marginTop: "-25px", marginLeft: "10px" }}
                        onClick={this.onSave}  disabled={this.state.saving}>Save
                    </button>
                    <button className="btn btn-md btn-flat btn-default pull-right" style={{ marginTop: "-25px" }}
                        onClick={this.onCancel} >Cancel
                    </button>
                    <span className="pull-right" style={{ marginTop: "-18px", marginRight: "10px" }}>
                    <input type="checkbox" checked={this.state.printChecked} onChange={() => this.setState({ printChecked: !this.state.printChecked })} />
                    <label >Print</label>
                    </span>
                    
                </section>
                <section className="content" >
                    <div className="row">
                        <div className="col-md-4">
                            <div className="box ">
                                <div className="box-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                        {this.state.closingPaymentModes.map((paymentMode)=> 
                                            <React.Fragment>
                                                <div style={{fontSize:"20px"}}>{paymentMode.PaymentMode}</div>
                                                {paymentMode.Components.map((c) => 
                                                    <div>{c.Name}   <span className="pull-right">{c.Amount}</span></div>  
                                                )}
                                             <div style={{fontWeight:"bold"}}>Total <span className="pull-right" style={{borderTop:"solid 1px"}}>{paymentMode.Amount}</span></div>  
                                             <br></br>
                                            </React.Fragment>
                                        )}
                                        <div className="pull-right" style={{fontSize:"20px",borderTop:"solid 1px"}}>{this.state.closingTotal}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="box ">
                                <div className="box-body">
                                    <div className="form-group">
                                        <label>Cash Resgiter</label>
                                        <input type="text" className="form-control"
                                            value={this.props.user.cashRegisterClosing.CashRegisterCode  + " - " + this.props.user.cashRegisterClosing.CashRegisterName}
                                            />
                                    </div>
                                    <div className="form-group">
                                        <label>Opening Time</label>
                                        <input type="text" className="form-control"
                                            value={(new Date(this.props.user.cashRegisterClosing.OpeningTime.seconds*1000)).toString().substring(0,24)}
                                            />
                                    </div>
                                    <div className="form-group">
                                        <label>Opening By</label>
                                        <input type="text" className="form-control"
                                            value={this.props.user.cashRegisterClosing.ActionBy}
                                            />
                                    </div>
                                    <div className="form-group">
                                        <label>Closing Remarks</label>
                                        <input type="text" className="form-control"
                                            onChange={(evt) => this.setState({ closingRemarks: evt.target.value })}
                                            value={this.state.closingRemarks}
                                            />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </section>
            </div> }
            {this.state.print && <div>
                <Print  {...this.props}   
                 printChecked = {this.state.printChecked}
                 sendEmailChecked={false}
                 sendSMSChecked = {false}
            printData = {this.state.printData}   
            onClose={()=> {
                this.setState({print:false})
                this.props.history.push('/home/')
                }
            }
            />
        
            </div>}
        </React.Fragment>
    }

}

export default CashRegisterClosing
