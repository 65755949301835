import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Confirm from 'react-confirm-bootstrap';
import * as actions from '../../actionTypes'
import Pagination from "react-js-pagination"
import Loader from '../Loader'
import AuditTrail from '../AuditTrail'
import * as constants from '../../Constatnts'

class Memberships extends Component {
    constructor() {
        super()
        this.state = {
            memberships: [],
            selectedMembership: '',
            searchActiveInactiveAllRecords: 'active',
            paginationStatus: 'No records to display',            
            activePage: 1,
            itemsCountPerPage: 20,
            totalItemsCount: 0,
            recordFrom: 0,
            searchText:'',
            loading: true
        }
        this.loadMemberships = this.loadMemberships.bind(this)
        this.onEditMemberships = this.onEditMemberships.bind(this)
        this.onDeleteMemberships = this.onDeleteMemberships.bind(this)           
        this.onClearSearch = this.onClearSearch.bind(this)
        this.onSearchActiveInactiveAllRecordsClicked = this.onSearchActiveInactiveAllRecordsClicked.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.showPaginationStatus = this.showPaginationStatus.bind(this)
    }
    componentDidMount() {
        console.log('Called');
        this.loadMemberships(this.state.recordFrom,
                this.state.searchActiveInactiveAllRecords,
                this.state.searchText)
    }

    loadMemberships(from,option,searchText) {
        this.setState({ loading: true })
        this.props.getMembership(this.props.user.user.RegistrationID, from, this.state.itemsCountPerPage, option, searchText).then((result) => {
            this.setState({ memberships: result.searchResult, totalItemsCount: result.totalItemsCount, loading: false })
            this.showPaginationStatus()
        })
    }

    onClearSearch()
    {
        this.setState({
            recordFrom:0, searchText: '', searchActiveInactiveAllRecords: 'active'
        })
        this.loadMemberships(0,"active", "")
    }

    onSearchActiveInactiveAllRecordsClicked(option)
    {
        this.setState({
            searchActiveInactiveAllRecords:option, recordFrom: 0
        })
        this.loadMemberships(0,option,this.state.searchText)
    }

    onSearchClick()
    {
        this.loadMemberships(this.state.recordFrom,
            this.state.searchActiveInactiveAllRecords,
            this.state.searchText)
    }

    handlePageChange(pageNumber) {
        let recordFrom = ((pageNumber - 1) * this.state.itemsCountPerPage)
        this.setState({ activePage: pageNumber, recordFrom: recordFrom });
        this.loadMemberships(recordFrom,
            this.state.searchActiveInactiveAllRecords,
            this.state.searchText,
            )
    }

    showPaginationStatus() {
        let paginationStatus
        if ((this.state.recordFrom + 1) === this.state.totalItemsCount) {
            paginationStatus = "Showing " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else if ((this.state.recordFrom + this.state.itemsCountPerPage + 1) > this.state.totalItemsCount) {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + (this.state.recordFrom + this.state.itemsCountPerPage) + " of " + this.state.totalItemsCount

        }
        this.setState({ paginationStatus: paginationStatus })
    }

    onEditMemberships(membership) {
        this.setState({ action: actions.EDIT_MEMBERSHIP, selectedMembership: membership.Memberships })
    }

    onDeleteMemberships(key) {
        this.props.deleteMembership(key).then(()=> { 
            setTimeout(function () { //Start the timer
                this.props.getToastr("Membership deleted successfully")
                this.loadMemberships(this.state.recordFrom,
                    this.state.searchActiveInactiveAllRecords,
                    this.state.searchText)
            }.bind(this), 3000)
        })
    }

    render() {
        const btnColStyle = { width: "120px" }

        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6">
                                        <font style={{ fontSize: "30px" }}>Membership</font>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="pull-right" style={{ marginTop: "6px" }}>
                                            <Link to="/home/memberships/newmembership" className="btn btn-success btn-flat  btn-md" >Add Membership</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-12" style={{ marginBottom: "10px" }}>
                                        <div className="btn-group">
                                            <button type="button" className={this.state.searchActiveInactiveAllRecords === "active" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.onSearchActiveInactiveAllRecordsClicked("active")}>Active</button>
                                            <button type="button" className={this.state.searchActiveInactiveAllRecords === "inactive" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.onSearchActiveInactiveAllRecordsClicked("inactive")}>In Active</button>
                                            <button type="button" className={this.state.searchActiveInactiveAllRecords === "all" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.onSearchActiveInactiveAllRecordsClicked("all")}>All</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="form-group">
                                            <label>Search by membership name</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchText}
                                                onChange={(evt) => this.setState({ searchText: evt.target.value })} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginLeft: "5px" }}
                                            onClick={this.onSearchClick}
                                        >Search</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                            onClick={this.onClearSearch} >Clear</button>
                                    </div>
                                </div>
                                {this.state.loading && <Loader show={this.state.loading} />}

                                {!this.state.loading &&
                                <table className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Description</th>
                                            <th>Revision History</th>
                                            <th style={btnColStyle}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.memberships.map((membership) =>
                                            <tr>
                                                <td>{membership.Name}</td>
                                                <td>{membership.Description}</td>
                                                <td>
                                                    <div>Action :{membership.Action}</div>
                                                    <div>Action By :{membership.ActionBy}</div>                                                        
                                                    <div>Action Date :{(new Date(membership.ActionOn)).toString().substring(0,24)}</div>
                                                    <AuditTrail {...this.props} module={constants.MODULE_MEMBERSHIP} parentKey={membership.key}/>
                                                </td>
                                                <td>
                                                    <div className="btn-group">
                                                        {membership.IsDeleted ? <span className="label label-danger pull-right">Inactive</span> :
                                                            <div>
                                                                <Link to={`/home/memberships/edit/${membership.key}`}
                                                                    type="button" className="btn btn-md btn-default "><i className="fa fa-edit"></i></Link>
                                                                <Confirm
                                                                    onConfirm={() => this.onDeleteMemberships(membership.key)}
                                                                    body="Are you sure you would like to delete this membership?"
                                                                    confirmText="Delete"
                                                                    title="Delete Membership">
                                                                    <button className="btn btn-default"><i className="fa fa-trash-o"></i></button>
                                                                </Confirm>
                                                            </div>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>}
                                {this.state.totalItemsCount > 0 && <div className="pull-left" style={{ marginTop: "25px" }}>
                                    {this.state.paginationStatus}
                                </div>}
                                <div className="pull-right">
                                    <Pagination
                                        activePage={this.state.activePage} //this.state.activePage
                                        itemsCountPerPage={this.state.itemsCountPerPage}
                                        totalItemsCount={this.state.totalItemsCount}
                                        pageRangeDisplayed={2}
                                        onChange={this.handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default withRouter(Memberships)