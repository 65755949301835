import firebase,{database} from '../firebase'
import * as constants from '../Constatnts'

export function addNewVendor(vendor){
    return (dispatch, getState) => {
      vendor.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
      vendor.ActionBy = getState().user.user.Name
      vendor.ActionByUID = getState().user.user.UID
      vendor.ActionByEmailID = getState().user.user.EmailID

      vendor.Action = constants.AUDIT_TRAIL_NEW
        return database.collection("Vendors").add({
          ...vendor
        })
        .then(function(docRef) {
            return docRef.id
        })
        .catch(function(error) {
            console.error("Error adding vendors: ", error);
        })
    }
}
// export function addNewVendor(vendor, key){
//   return dispatch =>{
//       if(key.trim()!== "")
//       {
//           return database.collection("Vendors").doc(key).update({
//               ...vendor
//             }).then(()=>{
//               return key
//             }).catch(function(error) {
//                 console.error("Error updating Vendors: ", error);
//             })
//       }
//       else
//       {
//           return database.collection("Vendors").add({
//               ...vendor
//             })
//             .then(function(docRef) {
//                 return docRef.id
//             })
//             .catch(function(error) {
//                 console.error("Error adding Vendors: ", error);
//             })
//       }
//   }
// }

// export function getVendors(registrationID, from, size, option , searchText,searchCity,searchState,searchCountry,searchPinCode,
//     searchCustomField1,searchCustomField2,searchCustomField3,searchCustomField4,searchCustomField5,searchCustomField6) {
//     let searchParameters =   {
//             "from": from,
//             "size": size, 
//             "query": {
//               "bool": {
//                 "must": [
//                   { "match": { "RegistrationID.keyword":registrationID}}
//                 ],
//                 "must_not": [
//                 ], 
//                 "should": [
                
//                 ],
//                 "minimum_should_match": 1,
//                 "filter": {
//                   "bool": {
//                     "must": [
                       
//                       ]
//                   }
//                 }
//               }
//             }
//           }
//           if(option === "active")
//           {
//             searchParameters.query.bool.must_not.push( {"match": { "IsDeleted": "true" }})
//           }
//           if(option === "inactive")
//           {
//             searchParameters.query.bool.must_not.push( {"match": { "IsDeleted": "false" }})
//           }
//           // searchParameters.query.bool.should.push({ "regexp": { "VendorFirstName": "@"+ searchText.trim().toLowerCase() +  ".*" }})
//           // searchParameters.query.bool.should.push({ "regexp": { "VendorLastName": "@"+ searchText.trim().toLowerCase() +  ".*" }})
//           // searchParameters.query.bool.should.push({ "regexp": { "EmailID": "@"+ searchText.trim().toLowerCase() +  ".*" }})
//           // searchParameters.query.bool.should.push({ "regexp": { "PhoneNo": "@"+ searchText.trim().toLowerCase() +  ".*" }})
//           // searchParameters.query.bool.should.push({ "regexp": { "VendorCode": "@"+ searchText.trim().toLowerCase() +  ".*" }})


//           if(searchCity.trim()!=="")
//           {
//             searchParameters.query.bool.filter.bool.must.push({ "regexp": { "City": "@"+ searchCity.trim().toLowerCase() +  ".*" }})
//           }
//           if(searchState.trim()!=="")
//           {
//             searchParameters.query.bool.filter.bool.must.push({ "regexp": { "State": "@"+ searchState.trim().toLowerCase() +  ".*" }})
//           }
//           if(searchPinCode.trim()!=="")
//           {
//             searchParameters.query.bool.filter.bool.must.push({ "regexp": { "PostalCode": "@"+ searchPinCode.trim().toLowerCase() +  ".*" }})
//           }
//           if(searchCountry.trim()!=="")
//           {
//             searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Country": "@"+ searchCountry.trim().toLowerCase() +  ".*" }})
//           }    
//           if (searchCustomField1.trim()!=="")
//           {
//             searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField1": "@"+ searchCustomField1.trim().toLowerCase() +  ".*" }})
//             }
//             if (searchCustomField2.trim()!=="")
//           {
//             searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField2": "@"+ searchCustomField2.trim().toLowerCase() +  ".*" }})
//             }
//             if (searchCustomField3.trim()!=="")
//           {
//             searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField3": "@"+ searchCustomField3.trim().toLowerCase() +  ".*" }})
//             }
//             if (searchCustomField4.trim()!=="")
//           {
//             searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField4": "@"+ searchCustomField4.trim().toLowerCase() +  ".*" }})
//             }
//             if (searchCustomField5.trim()!=="")
//           {
//             searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField5": "@"+ searchCustomField5.trim().toLowerCase() +  ".*" }})
//             }
//             if (searchCustomField6.trim()!=="")
//           {
//             searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField6": "@"+ searchCustomField6.trim().toLowerCase() +  ".*" }})
//             }

//             console.log("Query",JSON.stringify(searchParameters))
//     return (dispatch) => {
//                 // console.log("messageText",JSON.stringify(searchParameters)); 

//                 let addMessage =  firebase.functions().httpsCallable('getVendors');
        
//                 return addMessage({text: searchParameters}).then(function(result) {
//                     const vendors = []
//                     let sanitizedMessage = result.data.text;
//                     sanitizedMessage.hits.hits.map((data, index)=>{
//                     vendors.push({key: data._id, ...data._source})
//                 })
//                 console.log("vendors",vendors)
//                 return  {totalItemsCount: sanitizedMessage.hits.total, searchResult:vendors}
              
//         });
//     }
// }

// export function getVendors(registrationID) {
//     return (dispatch) => {
//         return database.collection("Vendors").where("RegistrationID","==",registrationID).get().then((querySnapshot) => {
        
//             const vendors = []
//             querySnapshot.forEach((doc) => {
//                 vendors.push(
//                   {
//                     key:doc.id,...doc.data()
//                   }
//                 )
//            })
//            return  {totalItemsCount: sanitizedMessage.hits.total, searchResult:vendors}
//         }).catch((error) => {
//             console.log("Error getting vendor list: ", error)
//         })
//     }
// }


export function getVendors(registrationID, from, size, option, searchText, searchCity, searchState, searchCountry, searchPinCode,
  searchCustomField1, searchCustomField2, searchCustomField3, searchCustomField4, searchCustomField5, searchCustomField6) {
  let searchParameters = {
    "from": from,
    "size": size,
    "query": {
      "bool": {
        "must": [
          { "term": { "RegistrationID": registrationID } }
        ],
        "must_not": [
        ],
        "should": [

        ],
        "minimum_should_match": 1,
        "filter": {
          "bool": {
            "must": [

            ]
          }
        }
      }
    }
  }
  if (option === "active") {
    searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "true" } })
  }
  if (option === "inactive") {
    searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "false" } })
  }
  searchParameters.query.bool.should.push({ "regexp": { "VendorDetails": "@"+ searchText.trim().toLowerCase() +  ".*" }})


  if (searchCity.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "City": "@" + searchCity.trim().toLowerCase() + ".*" } })
  }
  if (searchState.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "State": "@" + searchState.trim().toLowerCase() + ".*" } })
  }
  if (searchPinCode.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "PostalCode": "@" + searchPinCode.trim().toLowerCase() + ".*" } })
  }
  if (searchCountry.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Country": "@" + searchCountry.trim().toLowerCase() + ".*" } })
  }
  if (searchCustomField1.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField1": "@" + searchCustomField1.trim().toLowerCase() + ".*" } })
  }
  if (searchCustomField2.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField2": "@" + searchCustomField2.trim().toLowerCase() + ".*" } })
  }
  if (searchCustomField3.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField3": "@" + searchCustomField3.trim().toLowerCase() + ".*" } })
  }
  if (searchCustomField4.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField4": "@" + searchCustomField4.trim().toLowerCase() + ".*" } })
  }
  if (searchCustomField5.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField5": "@" + searchCustomField5.trim().toLowerCase() + ".*" } })
  }
  if (searchCustomField6.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField6": "@" + searchCustomField6.trim().toLowerCase() + ".*" } })
  }

  console.log("Query", JSON.stringify(searchParameters))
  return (dispatch, getState) => {
    // console.log("messageText",JSON.stringify(searchParameters)); 
    searchParameters.query.bool.must.push({
      "match": {
        "StoreID": getState().user.store.key
      }
    })
    let addMessage = firebase.functions().httpsCallable('getVendors');

    return addMessage({ text: searchParameters }).then(function (result) {
      const vendors = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        vendors.push({ key: data._id, ...data._source })
      })
      console.log("vendors", vendors)
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: vendors }

    });
  }
}

export function deleteVendor(key){
    return (dispatch, getState) => {
        return database.collection("Vendors").doc(key).update({
          IsDeleted:true,
          ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
          ActionBy: getState().user.user.Name,
          ActionByUID: getState().user.user.UID,
          ActionByEmailID:getState().user.user.EmailID,

          Action: constants.AUDIT_TRAIL_DELETE
      }).then(() => { return true })
    }
}

export function getVendorDetails(key){
    return (dispatch) => {
    return database.collection("Vendors").doc(key).get().then((doc) => {
        if (doc.exists) {
           return doc.data()
        } else {
            console.log("No such vendor found!");
        }
    })
    }
}

export function updateVendor(vendor, key){
    return (dispatch, getState) => {
      vendor.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
      vendor.ActionBy = getState().user.user.Name
      vendor.ActionByUID = getState().user.user.UID
      vendor.ActionByEmailID = getState().user.user.EmailID

      vendor.Action = constants.AUDIT_TRAIL_EDIT
        return database.collection("Vendors").doc(key).update({
          ...vendor
        })
    }
}


export function checkIfVendorCodeIsUnique(vendorCode, registrationID){
    return (dispatch) =>{
    let isUnique = true
    return database.collection("Vendors").where("RegistrationID" ,"==",registrationID).where("VendorCode","==",vendorCode).where("IsDeleted","==",false).limit(1).get().then((querySnapshot) => {
       querySnapshot.forEach((doc) => {
        isUnique = false
       })
      return isUnique
    })
  }
}

export function searchVendor(RegistrationID, StoreID, searchText) {
  return (dispatch) => {
    let searchParameters = {
      "from": 0,
      "size": 50,
      "query": {
        "bool": {
          "must": [
            { "match": { "RegistrationID": RegistrationID } },
            { "match": { "StoreID": StoreID } },
            { "match": { "IsDeleted": "false" } }
          ],
          "should": [

          ],"minimum_should_match": 1
        }
      }
    }
    searchParameters.query.bool.should.push({ "regexp": { "VendorDetails": "@" + searchText.trim().toLowerCase() + ".*" } })

    // console.log("searchVendor", JSON.stringify(searchParameters))
    let addMessage = firebase.functions().httpsCallable('getVendors');
    return addMessage({ text: searchParameters }).then(function (result) {
      const parties = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        parties.push({ key: data._id, ...data._source })
      })
      // console.log(JSON.stringify(parties))
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: parties }
    });
  }
}