import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as actionTypes from '../actionTypes'
import ChangePassword from './ChangePassword'
import Modal from 'react-responsive-modal'
import { auth } from '../firebase'

class Header extends Component {

  constructor() {
    super()
    this.state = {
      selectedMenu: '',
      selectedSubMenu: '',
      showChangePasswordModal: false
    }
    this.onMenuClicked = this.onMenuClicked.bind(this)
  }

  logout() {
    auth.signOut()
      .then(() => {
        this.props.signOutUser()
      });
  }

  onMenuClicked(menu) {
    if (this.state.selectedMenu === menu) {
      this.setState({ selectedMenu: '' })
    }
    else {
      this.setState({ selectedMenu: menu })
    }
  }

  render() {

    const isDisabled  = {
      pointerEvents: 'none'
      }

    const isEnabled = {
      cursor: 'pointer'
    }
    
    
    return (
      <div className="skin-blue">
        <header className="main-header">
          <Link to="/home" className="logo" onClick={() => this.setState({ selectedMenu: '', selectedSubMenu: '' })} style={{backgroundColor : this.props.config.ConnectionStatus.Status === 'disconnected' ? 'gray' :'#00a65a' }}>
            <span className="logo-mini"><img style={{ height: "45px", paddingBottom: "4px" }} src={require('../images/logo-mini.png')} /></span>
            <span className="logo-lg"><img style={{ height: "45px", paddingBottom: "4px" }} src={require('../images/logo-white.png')} /></span>
          </Link>
          <nav className="navbar navbar-static-top" role="navigation" style={{backgroundColor : this.props.config.ConnectionStatus.Status === 'disconnected' ? 'gray' :'#00a65a' }}>
            <a href="#" className="sidebar-toggle" data-toggle="push-menu" role="button">
              <span className="sr-only">Toggle navigation</span>
            </a>
            <div className="navbar-custom-menu">             
              <ul className="nav navbar-nav">
                {this.props.user.cashRegister && 
                <React.Fragment>
                  {/* <li className="dropdown user user-menu"  style={{ marginTop: "10px", marginRight:"10px" }}>
                    <Switch
                      uncheckedIcon={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            width:"100%",
                            fontSize: 12,
                            color: "white",
                            paddingRight: 2
                          }}
                        >
                          Off
                        </div>
                      }
                      checkedIcon={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            width:"100%",
                            fontSize: 12,
                            color: 'white',
                            paddingRight: 2
                          }}
                        >
                          On
                        </div>
                      }
                      onChange={() => {
                        this.props.saveConnectionStatus(this.state.offline, 'user');
                        this.setState({ offline: !this.state.offline })
                      }}
                      checked={!this.state.offline} />
                  </li> */}
                  <li className="dropdown user user-menu"  style={{ marginTop: "10px", marginRight:"10px" }}>
                    <span 
                      className={this.props.config.ConnectionStatus.Status === 'connected' ? 'badge bg-green' : 'badge'} 
                      style={{border:"1px solid white"}}>
                      {this.props.config.ConnectionStatus.Status === 'connected' ? 'Online' : 'Offline'}
                    </span>                 
                  </li>
                  <li className="dropdown user user-menu">
                    <a href="#"
                      style={{ padding: "6px 10px 0px 0px" }}
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      aria-expanded="false">
                      <span className="fa-stack fa-lg">
                        <i className="fa fa-circle-thin fa-stack-2x"></i>
                        <i className="fa fa-calculator fa-stack-1x"></i>
                      </span>
                      <span className="hidden-xs">{this.props.user.cashRegister.CashRegisterCode}-{this.props.user.cashRegister.CashRegisterName}</span>
                    </a>
                    {this.props.user.cashRegisterClosing && <ul className="dropdown-menu">
                      <li className="user-footer">
                        <div style={{ padding: "10px" }}>
                          <div><b>
                            <font style={{ fontSize: "20px" }}> Cash Register Closing </font> </b></div>
                          <div className="hidden-xs">Opening Balance: {this.props.user.cashRegisterClosing.OpeningBalance}</div>
                          <div className="hidden-xs">Opening Time: {(new Date(this.props.user.cashRegisterClosing.OpeningTime.seconds * 1000)).toString().substring(0, 24)}</div>
                          <div className="hidden-xs">Opening By: {this.props.user.cashRegisterClosing.OpeningByUserName}</div>
                        </div>
                        {this.props.config.ConnectionStatus.Status === 'connected' && <button className="btn btn-md btn-default btn-flat"
                          style={{ width: "100%" }}
                          onClick={() => {
                            this.props.history.push('/home/sales/cashregisterclosing/')
                          }}>
                          Close Cash Register
                        </button>}
                      </li>
                    </ul>}
                </li>
                </React.Fragment>}
                {this.props.user.store && <li className="dropdown user user-menu">
                  <a href="#"
                    style={{ padding: "6px 10px 0px 0px" }}
                    className="dropdown-toggle"
                    data-toggle="dropdown"
                    aria-expanded="false">
                    <span className="fa-stack fa-lg">
                      <i className="fa fa-circle-thin fa-stack-2x"></i>
                      <i className="fa fa-home fa-stack-1x"></i>
                    </span>
                    <span className="hidden-xs">{this.props.user.store.StoreCode}-{this.props.user.store.StoreName}</span>
                  </a>
                  <ul className="dropdown-menu">
                    <li className="user-footer">
                      <div style={{ padding: "10px" }}>
                        <div><b>
                          <font style={{ fontSize: "20px" }}> {this.props.user.store.StoreCode} - {this.props.user.store.StoreName}</font> </b></div>
                        <div className="hidden-xs">{this.props.user.store.Address1}</div>
                        <div className="hidden-xs">{this.props.user.store.Address2}</div>
                        <div className="hidden-xs">{this.props.user.store.City}</div>
                        <div className="hidden-xs">{this.props.user.store.State}</div>
                        <div className="hidden-xs">{this.props.user.store.Country}</div>
                      </div>
                      <div className="pull-right">
                      </div>
                    </li>
                  </ul>
                </li>}
                <li className="dropdown user user-menu">
                  {this.props.user.auth && <a href="#"
                    style={{ padding: "6px 10px 0px 0px" }}
                    className="dropdown-toggle"
                    data-toggle="dropdown">
                    <span className="fa-stack fa-lg">
                      <i className="fa fa-circle-thin fa-stack-2x"></i>
                      <i className="fa fa-user fa-stack-1x"></i>
                    </span>
                    {this.props.user.auth.displayName && <span className="hidden-xs">{this.props.user.auth.displayName}</span>}
                    {!this.props.user.auth.displayName && <span className="hidden-xs">{this.props.user.user.Name}</span>}
                  </a>}
                  <ul className="dropdown-menu">
                    {this.props.user.auth && <li className="user-header">
                      {this.props.user.auth.displayName && <img src={this.props.user.auth.photoURL} className="img-circle" alt="User Image" />}
                      {!this.props.user.auth.displayName && <img src={require('../images/DefaultUserImage.png')} className="img-circle" alt="User Image" />}

                      {this.props.user.auth.displayName && <p>
                        {this.props.user.auth.displayName}
                        <small>{this.props.user.auth.email}</small>
                      </p>}
                      {!this.props.user.auth.displayName && <p>
                        {this.props.user.user.Name}
                        <small>{this.props.user.user.EmailID}</small>
                      </p>}

                    </li>}
                    <li className="user-footer">
                      {this.props.config.ConnectionStatus.Status === 'connected' && <div className="pull-left">
                        <button className="btn btn-md btn-default btn-flat"
                          onClick={() => this.setState({ showChangePasswordModal: true })}>Change password</button>
                      </div>}
                      <div className="pull-right">
                        <Link to="/" onClick={() => this.logout()} className="btn btn-default btn-flat">
                          Sign out
                      </Link>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </nav>
        </header>
        <aside className="main-sidebar">
          {this.props.user.store && <section className="sidebar">
            <ul className="sidebar-menu" >
              <li className="header">Menu Options</li>
              <li className={this.state.selectedMenu === 'sales' ? 'active' : ''}>
                <a onClick={() => this.onMenuClicked('sales')}>
                  <i className="fa fa-files-o"></i>
                  <span>Sales</span>
                  <span className="pull-right-container">
                    <i style={this.state.selectedMenu === 'sales' ? { marginRight: "8px" } : {}} className={this.state.selectedMenu === 'sales' ? 'fa fa-angle-down pull-right' : 'fa fa-angle-left pull-right'}></i>
                  </span>
                </a>
                <ul className="treeview-menu" style={{ display: this.state.selectedMenu === 'sales' ? 'block' : 'none' }} >
                  {this.props.config.TransactionTypes.filter((e) => e.Module === "sales" && !e.IsDeleted).map((e, index) =>
                    <li key={index} onClick={() => this.setState({ selectedSubMenu: e.TransactionType })}
                      className={this.state.selectedSubMenu === e.TransactionType ? 'active' : ''} >
                      <Link to={{ pathname: "/home/sales/sales/", state: { transactionType: e, type: actionTypes.SALE_NEW } }}>
                        <i className="fa fa-file"></i><span>New {e.TransactionType}</span>
                      </Link>
                    </li>
                  )}
                  <li
                    onClick={() => this.setState({ selectedSubMenu: 'saleshistory' })}
                    className={this.state.selectedSubMenu === 'saleshistory' ? 'active' : ''}>
                    <Link to={{ pathname: "/home/sales/saleslist", state: { type: "list" } }}>
                      <i className="fa fa-file"></i><span>Sales History</span>
                    </Link>
                  </li>
                  {this.props.registration.registration.Delivery && <li>
                    <Link 
                      style={this.props.config.ConnectionStatus.Status === 'disconnected' ? isDisabled : isEnabled} to={{ pathname: "/home/sales/deliverylist", state: { type: "list" } }}>
                      <i className="fa fa-file"></i><span>Delivery List</span>
                    </Link>
                  </li>}
                  {this.props.user.store.hasOwnProperty("CashRegisterClosing") && <li
                    onClick={() => this.setState({ selectedSubMenu: 'cashregisterclosinglist' })}
                    className={this.state.selectedSubMenu === 'cashregisterclosinglist' ? 'active' : ''}
                    >
                    <Link 
                    style={this.props.config.ConnectionStatus.Status === 'disconnected' ? isDisabled : isEnabled}
                    to={{ pathname: "/home/sales/cashregisterclosinglist/", state: { type: "list" } }}>
                      <i className="fa fa-file"></i><span>Cash Register Closing</span>
                    </Link>
                  </li>}
                  {/* <li
                    onClick={() => this.setState({ selectedSubMenu: 'Packages' })}
                    className={this.state.selectedSubMenu === 'Packages' ? 'active' : ''} >
                    <Link 
                    style={this.props.config.ConnectionStatus.Status === 'disconnected' ? isDisabled : isEnabled}
                    to="/home/packages/packagelist">
                      <i className="fa fa-cubes"></i><span> Packages</span>
                    </Link>
                  </li> */}
                </ul>
              </li>
              <li className={this.state.selectedMenu === 'crm' ? 'active' : ''} 
              style={{ display: this.props.getVisibility("Customers", "read") }}>
                <a onClick={() => this.onMenuClicked('crm')}>
                  <i className="fa fa-users"></i>
                  <span>CRM</span>
                  <span className="pull-right-container">
                    <i style={this.state.selectedMenu === 'crm' ? { marginRight: "8px" } : {}} className={this.state.selectedMenu === 'crm' ? 'fa fa-angle-down pull-right' : 'fa fa-angle-left pull-right'}></i>
                  </span>
                </a>
                <ul className="treeview-menu" style={{ display: this.state.selectedMenu === 'crm' ? 'block' : 'none' }}>
                  <li style={{ display: this.props.getVisibility("Customers", "read") }}
                    onClick={() => this.setState({ selectedSubMenu: 'customer' })}
                    className={this.state.selectedSubMenu === 'customer' ? 'active' : ''} >
                    <Link 
                    style={this.props.config.ConnectionStatus.Status === 'disconnected' ? isDisabled : isEnabled}
                    to="/home/customer">
                      <i className="fa fa-user"></i><span>Customer</span>
                    </Link>
                  </li>
                  <li style={{ display: this.props.getVisibility("Campaigns", "read") }}>
                    <Link 
                    style={this.props.config.ConnectionStatus.Status === 'disconnected' ? isDisabled : isEnabled}
                    to="/home/campaign">
                      <i className="fa fa-comments"></i><span>Campaigns</span>
                    </Link>
                  </li>
                  <li style={{ display: this.props.getVisibility("Memberships", "read") }}>
                    <Link 
                    style={this.props.config.ConnectionStatus.Status === 'disconnected' ? isDisabled : isEnabled}
                    to="/home/membership">
                      <i className="fa fa-star"></i><span>Memberships</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li style={{ display: this.props.getVisibility("Inventory", "read") }}
                className={this.state.selectedMenu === 'inventory' ? 'active' : ''} >
                <a onClick={() => this.onMenuClicked('inventory')}>
                  <i className="fa fa-window-restore"></i>
                  <span>Inventory</span>
                  <span className="pull-right-container">
                    <i style={this.state.selectedMenu === 'crm' ? { marginRight: "8px" } : {}} className={this.state.selectedMenu === 'inventory' ? 'fa fa-angle-down pull-right' : 'fa fa-angle-left pull-right'}></i>
                  </span>
                </a>
                <ul className="treeview-menu" style={{ display: this.state.selectedMenu === 'inventory' ? 'block' : 'none' }}>
                  {this.props.config.TransactionTypes.filter((e) => e.Module === "inventory" && !e.IsDeleted && e.TransactionType !== "Transfer In").map((e, index) =>
                    <li key={index}
                      onClick={() => this.setState({ selectedSubMenu: e.TransactionType })}
                      className={this.state.selectedSubMenu === e.TransactionType ? 'active' : ''} >
                      <Link
                      style={this.props.config.ConnectionStatus.Status === 'disconnected' ? isDisabled : isEnabled}
                      to={{ pathname: "/home/inventory/inventory/", state: { transactionType: e, type: actionTypes.INVENTORY_NEW } }}>
                        <i className="fa fa-file"></i><span>New {e.TransactionType}</span>
                      </Link>
                    </li>
                  )}
                  <li
                    onClick={() => this.setState({ selectedSubMenu: 'inventorylist' })}
                    className={this.state.selectedSubMenu === 'inventorylist' ? 'active' : ''}>
                    <Link 
                    style={this.props.config.ConnectionStatus.Status === 'disconnected' ? isDisabled : isEnabled}
                    to={{ pathname: "/home/inventory/inventorylist", state: { type: "list" } }}>
                      <i className="fa fa-file"></i><span>Inventory List</span>
                    </Link>
                  </li>
                  <li
                    onClick={() => this.setState({ selectedSubMenu: 'intransit' })}
                    className={this.state.selectedSubMenu === 'intransit' ? 'active' : ''}>
                    <Link 
                    style={this.props.config.ConnectionStatus.Status === 'disconnected' ? isDisabled : isEnabled}
                    to={{ pathname: "/home/inventory/intransit", state: { type: "intransit" } }}>
                      <i className="fa fa-file"></i><span>In Transit</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                style={{ display: this.props.getVisibility("Expenses", "read") }}
                className={this.state.selectedMenu === 'Expense' ? 'active' : ''} >
                <a onClick={() => this.onMenuClicked('Expense')}>
                  <i className="fa fa-exchange"></i>
                  <span>Expense</span>
                  <span className="pull-right-container">
                    <i style={this.state.selectedMenu === 'Expense' ? { marginRight: "8px" } : {}} className={this.state.selectedMenu === 'crm' ? 'fa fa-angle-down pull-right' : 'fa fa-angle-left pull-right'}></i>
                  </span>
                </a>
                <ul className="treeview-menu" style={{ display: this.state.selectedMenu === 'Expense' ? 'block' : 'none' }}>
                  <li style={{ display: this.props.getVisibility("Expenses", "read") }}
                    onClick={() => this.setState({ selectedSubMenu: 'Expense List' })}
                    className={this.state.selectedSubMenu === 'Expense List' ? 'active' : ''} >
                    <Link 
                    style={this.props.config.ConnectionStatus.Status === 'disconnected' ? isDisabled : isEnabled}
                    to="/home/expenselist">
                      <i className="fa fa-list-alt"></i><span>Expense List</span>
                    </Link>
                  </li>
                  <li style={{ display: this.props.getVisibility("ExpenseCategories", "read") }}
                    onClick={() => this.setState({ selectedSubMenu: 'expence' })}
                    className={this.state.selectedSubMenu === 'expence' ? 'active' : ''} >
                    <Link 
                    style={this.props.config.ConnectionStatus.Status === 'disconnected' ? isDisabled : isEnabled}
                    to="/home/expensecategorieslist">
                      <i className="fa fa-briefcase"></i><span>Expense Category</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                style={{ display: this.props.getVisibility("Attendance", "read") }}
                className={this.state.selectedMenu === 'Attendance' ? 'active' : ''} >
                <a onClick={() => this.onMenuClicked('Attendance')}>
                  <i className="fa fa-calendar-check-o"></i>
                  <span>Attendance</span>
                  <span className="pull-right-container">
                    <i style={this.state.selectedMenu === 'Attendance' ? { marginRight: "8px" } : {}} className={this.state.selectedMenu === 'Attendance' ? 'fa fa-angle-down pull-right' : 'fa fa-angle-left pull-right'}></i>
                  </span>
                </a>
                <ul className="treeview-menu" style={{ display: this.state.selectedMenu === 'Attendance' ? 'block' : 'none' }}>
                  <li style={{ display: this.props.getVisibility("Attendance", "read") }}
                    onClick={() => this.setState({ selectedSubMenu: 'Attendance' })}
                    className={this.state.selectedSubMenu === 'Attendance' ? 'active' : ''} >
                    <Link 
                    style={this.props.config.ConnectionStatus.Status === 'disconnected' ? isDisabled : isEnabled}
                    to="/home/attendancelist">
                      <i className="fa fa-calendar-check-o"></i><span>Attendance</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li style={{ display: this.props.getVisibility("Products", "read") }}
                className={this.state.selectedMenu === 'catalogue' ? 'active' : ''} >
                <a onClick={() => this.onMenuClicked('catalogue')}>
                  <i className="fa fa-cubes"></i>
                  <span>Catalogue</span>
                  <span className="pull-right-container">
                    <i style={this.state.selectedMenu === 'catalogue' ? { marginRight: "8px" } : {}} className={this.state.selectedMenu === 'catalogue' ? 'fa fa-angle-down pull-right' : 'fa fa-angle-left pull-right'}></i>
                  </span>
                </a>
                <ul className="treeview-menu" style={{ display: this.state.selectedMenu === 'catalogue' ? 'block' : 'none' }}>
                  <li
                    style={{ display: this.props.getVisibility("Products", "read") }}
                    onClick={() => this.setState({ selectedSubMenu: 'product' })}
                    className={this.state.selectedSubMenu === 'product' ? 'active' : ''} >
                    <Link
                    style={this.props.config.ConnectionStatus.Status === 'disconnected' ? isDisabled : isEnabled}
                    to="/home/product">
                      <i className="fa fa-tag"></i><span>Product</span>
                    </Link>
                  </li>
                  <li
                    style={{ display: this.props.getVisibility("ProductCategories", "read") }}
                    onClick={() => this.setState({ selectedSubMenu: 'category' })}
                    className={this.state.selectedSubMenu === 'category' ? 'active' : ''} >
                    <Link
                    style={this.props.config.ConnectionStatus.Status === 'disconnected' ? isDisabled : isEnabled}
                    to={{ pathname: "/home/catalogue/category", state: { type: actionTypes.PRODUCT_CATEGORY } }}>
                      <i className="fa fa-briefcase"></i><span>Category</span>
                    </Link>
                  </li>
                  <li
                    style={{ display: this.props.getVisibility("ProductCategories", "read") }}
                    onClick={() => this.setState({ selectedSubMenu: 'subcategory' })}
                    className={this.state.selectedSubMenu === 'subcategory' ? 'active' : ''} >
                    <Link
                    style={this.props.config.ConnectionStatus.Status === 'disconnected' ? isDisabled : isEnabled}
                    to={{ pathname: "/home/catalogue/subcategory", state: { type: actionTypes.PRODUCT_SUB_CATEGORY } }}>
                      <i className="fa fa-briefcase"></i><span>Sub Category</span>
                    </Link>
                  </li>
                  <li
                    style={{ display: this.props.getVisibility("ProductBrands", "read") }}
                    onClick={() => this.setState({ selectedSubMenu: 'brand' })}
                    className={this.state.selectedSubMenu === 'brand' ? 'active' : ''} >
                    <Link 
                    style={this.props.config.ConnectionStatus.Status === 'disconnected' ? isDisabled : isEnabled}
                    to="/home/catalogue/brands">
                      <i className="fa fa-bookmark"></i><span>Brand</span>
                    </Link>
                  </li>
                  <li
                    style={{ display: this.props.getVisibility("ProductTaxes", "read") }}
                    onClick={() => this.setState({ selectedSubMenu: 'taxes' })}
                    className={this.state.selectedSubMenu === 'taxes' ? 'active' : ''} >
                    <Link 
                    style={this.props.config.ConnectionStatus.Status === 'disconnected' ? isDisabled : isEnabled}
                    to="/home/catalogue/taxes">
                      <i className="fa fa-calculator"></i><span>Taxes</span>
                    </Link>
                  </li>
                  <li
                    style={{ display: this.props.getVisibility("Modifiers", "read") }}
                    onClick={() => this.setState({ selectedSubMenu: 'modifiers' })}
                    className={this.state.selectedSubMenu === 'modifiers' ? 'active' : ''} >
                    <Link 
                    style={this.props.config.ConnectionStatus.Status === 'disconnected' ? isDisabled : isEnabled}
                    to="/home/catalogue/modifiers">
                      <i className="fa fa-plus-square"></i><span>Modifiers</span>
                    </Link>
                  </li>
                  <li
                    style={{ display: this.props.getVisibility("ProductTags", "read") }}
                    onClick={() => this.setState({ selectedSubMenu: 'tags' })}
                    className={this.state.selectedSubMenu === 'tags' ? 'active' : ''} >
                    <Link 
                    style={this.props.config.ConnectionStatus.Status === 'disconnected' ? isDisabled : isEnabled}
                    to="/home/catalogue/tags">
                      <i className="fa fa-calculator"></i><span>Product Group</span>
                    </Link>
                  </li>
                  <li
                    style={{ display: this.props.getVisibility("Offers", "read") }}
                    onClick={() => this.setState({ selectedSubMenu: 'offers' })}
                    className={this.state.selectedSubMenu === 'offers' ? 'active' : ''} >
                    <Link 
                    style={this.props.config.ConnectionStatus.Status === 'disconnected' ? isDisabled : isEnabled}
                    to="/home/catalogue/offer">
                      <i className="fa fa-pie-chart"></i><span>Offers</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                // style={{display: this.props.getVisibility("Registration","read")}}
                className={this.state.selectedMenu === 'setup' ? 'active' : ''} >
                <a onClick={() => this.onMenuClicked('setup')}>
                  <i className="fa fa-cog"></i>
                  <span>Setup</span>
                  <span className="pull-right-container">
                    <i style={this.state.selectedMenu === 'setup' ? { marginRight: "8px" } : {}} className={this.state.selectedMenu === 'setup' ? 'fa fa-angle-down pull-right' : 'fa fa-angle-left pull-right'}></i>
                  </span>
                </a>
                <ul className="treeview-menu" style={{ display: this.state.selectedMenu === 'setup' ? 'block' : 'none' }}>
                  <li
                    style={{ display: this.props.getVisibility("Registration", "read") }}
                    onClick={() => this.setState({ selectedSubMenu: 'registration' })}
                    className={this.state.selectedSubMenu === 'registration' ? 'active' : ''} >
                    <Link 
                    style={this.props.config.ConnectionStatus.Status === 'disconnected' ? isDisabled : isEnabled}
                    to="/home/settings/registration" >
                      <i className="fa fa-university"></i><span>Registration</span>
                    </Link>
                  </li>
                  {/* <li
                    onClick={() => this.setState({ selectedSubMenu: 'OnBoarding' })}
                    className={this.state.selectedSubMenu === 'OnBoarding' ? 'active' : ''} >
                    <Link to="/home/settings/onBoarding" >
                      <i className="fa fa-credit-card"></i><span>OnBoarding</span>
                    </Link>
                  </li> */}
                  <li
                    style={{ display: this.props.getVisibility("Stores", "read") }}
                    onClick={() => this.setState({ selectedSubMenu: 'stores' })}
                    className={this.state.selectedSubMenu === 'stores' ? 'active' : ''} >
                    <Link 
                    style={this.props.config.ConnectionStatus.Status === 'disconnected' ? isDisabled : isEnabled}
                    to="/home/settings/stores">
                      <i className="fa fa-building"></i><span>Stores</span>
                    </Link>
                  </li>
                  <li
                    style={{ display: this.props.getVisibility("Employees", "read") }}
                    onClick={() => this.setState({ selectedSubMenu: 'employees' })}
                    className={this.state.selectedSubMenu === 'employees' ? 'active' : ''} >
                    <Link 
                    style={this.props.config.ConnectionStatus.Status === 'disconnected' ? isDisabled : isEnabled}
                    to="/home/settings/employees/">
                      <i className="fa fa-user"></i><span>Employees</span>
                    </Link>
                  </li>
                  <li
                    style={{ display: this.props.getVisibility("Vendors", "read") }}
                    onClick={() => this.setState({ selectedSubMenu: 'vendors' })}
                    className={this.state.selectedSubMenu === 'vendors' ? 'active' : ''} >
                    <Link 
                    style={this.props.config.ConnectionStatus.Status === 'disconnected' ? isDisabled : isEnabled}
                    to="/home/settings/vendors/">
                      <i className="fa fa-user"></i><span>Vendors</span>
                    </Link>
                  </li>
                  <li
                    style={{ display: this.props.getVisibility("PaymentTypes", "read") }}
                    onClick={() => this.setState({ selectedSubMenu: 'paymenttypes' })}
                    className={this.state.selectedSubMenu === 'paymenttypes' ? 'active' : ''} >
                    <Link 
                    style={this.props.config.ConnectionStatus.Status === 'disconnected' ? isDisabled : isEnabled}
                    to="/home/settings/paymenttypes" >
                      <i className="fa fa-credit-card"></i><span>Payment Types</span>
                    </Link>
                  </li>
                  <li
                    style={{ display: this.props.getVisibility("TransactionTypes", "read") }}
                    onClick={() => this.setState({ selectedSubMenu: 'moduleconfiguration' })}
                    className={this.state.selectedSubMenu === 'moduleconfiguration' ? 'active' : ''} >
                    <Link 
                    style={this.props.config.ConnectionStatus.Status === 'disconnected' ? isDisabled : isEnabled}
                    to="/home/settings/moduleconfig">
                      <i className="fa fa-object-group"></i><span>Module Configuraiton</span>
                    </Link>
                  </li>
                  <li
                    style={{ display: this.props.getVisibility("CustomFields", "read") }}
                    onClick={() => this.setState({ selectedSubMenu: 'customfields' })}
                    className={this.state.selectedSubMenu === 'customfields' ? 'active' : ''} >
                    <Link 
                    style={this.props.config.ConnectionStatus.Status === 'disconnected' ? isDisabled : isEnabled}
                    to="/home/settings/customfields">
                      <i className="fa fa-bars"></i><span>Custom Fields</span>
                    </Link>
                  </li>
                </ul>
              </li>
              {this.props.user.user.Role !== "Store Cashier" &&
                <li>
                  <Link 
                    style={this.props.config.ConnectionStatus.Status === 'disconnected' ? isDisabled : isEnabled}
                    to="/home/reports/allreport" >
                    <i className="fa fa-file-o"></i><span>Reports</span>
                  </Link>
                </li>
              }
            </ul>
          </section>}
        </aside>
        <Modal open={this.state.showChangePasswordModal} onClose={() => this.setState({ showChangePasswordModal: false })} center>
          <div style={{ width: "400px" }}>
            <ChangePassword  {...this.props}
              onClose={() => this.setState({ showChangePasswordModal: false })}
            />
          </div>
        </Modal>
      </div>
    );
  }
}
export default Header