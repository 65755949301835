import {database} from '../firebase'
import * as actionTypes from '../actionTypes'

export function getAllCustomFieldNames(registrationID){
    return (dispatch) => {
     return database.collection("CustomFields").where("RegistrationID","==",registrationID).get().then((querySnapshot) => {
        let customFields =[]
        querySnapshot.forEach((doc) => {
            customFields.push({ key:doc.id,...doc.data()})
        })
        dispatch({type: actionTypes.CONFIG_GET_CUSTOM_FIELDS,customFields})   
     })
    }
}
