import firebase,{database} from '../firebase'
import * as constants from '../Constatnts'
import * as actionTypes from '../actionTypes'

export function addNewEmployee(employee){
    return (dispatch, getState) => {
      employee.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
      employee.ActionBy = getState().user.user.Name
      employee.ActionByUID = getState().user.user.UID
      employee.ActionByEmailID = getState().user.user.EmailID

      employee.Action = constants.AUDIT_TRAIL_NEW
        return database.collection("Employees").add({
          ...employee
        })
        .then(function(docRef) {
            return docRef.id
        })
        .catch(function(error) {
            console.error("Error adding employee: ", error);
        })
    }
}

export function getEmployees(registrationID, storeID, from, size, option, searchText, searchCity, searchState, searchCountry, searchPinCode,
  searchCustomField1, searchCustomField2, searchCustomField3, searchCustomField4, searchCustomField5, searchCustomField6) {
  let searchParameters = {
    "from": from,
    "size": size,
    "query": {
      "bool": {
        "must": [
          { "match": { "RegistrationID": registrationID } },
          { "match": { "StoreID": storeID } }
        ],
        "must_not": [
        ],
        "should": [

        ],
        "minimum_should_match": 1,
        "filter": {
          "bool": {
            "must": [

            ]
          }
        }
      }
    }
  }
  if (option === "active") {
    searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "true" } })
  }
  if (option === "inactive") {
    searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "false" } })
  }
  searchParameters.query.bool.should.push({ "regexp": { "EmployeeFirstName": "@" + searchText.trim().toLowerCase() + ".*" } })
  searchParameters.query.bool.should.push({ "regexp": { "EmployeeLastName": "@" + searchText.trim().toLowerCase() + ".*" } })
  searchParameters.query.bool.should.push({ "regexp": { "EmailID": "@" + searchText.trim().toLowerCase() + ".*" } })
  searchParameters.query.bool.should.push({ "regexp": { "PhoneNo": "@" + searchText.trim().toLowerCase() + ".*" } })
  searchParameters.query.bool.should.push({ "regexp": { "EmployeeCode": "@" + searchText.trim().toLowerCase() + ".*" } })


  if (searchCity.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "City": "@" + searchCity.trim().toLowerCase() + ".*" } })
  }
  if (searchState.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "State": "@" + searchState.trim().toLowerCase() + ".*" } })
  }
  if (searchPinCode.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "PostalCode": "@" + searchPinCode.trim().toLowerCase() + ".*" } })
  }
  if (searchCountry.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Country": "@" + searchCountry.trim().toLowerCase() + ".*" } })
  }
  if (searchCustomField1.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField1": "@" + searchCustomField1.trim().toLowerCase() + ".*" } })
  }
  if (searchCustomField2.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField2": "@" + searchCustomField2.trim().toLowerCase() + ".*" } })
  }
  if (searchCustomField3.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField3": "@" + searchCustomField3.trim().toLowerCase() + ".*" } })
  }
  if (searchCustomField4.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField4": "@" + searchCustomField4.trim().toLowerCase() + ".*" } })
  }
  if (searchCustomField5.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField5": "@" + searchCustomField5.trim().toLowerCase() + ".*" } })
  }
  if (searchCustomField6.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField6": "@" + searchCustomField6.trim().toLowerCase() + ".*" } })
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable('getEmployees');

    return addMessage({ text: searchParameters }).then(function (result) {
      const employees = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        employees.push({ key: data._id, ...data._source })
      })
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: employees }
    });
  }
}

export function getEmployeesForRedux(registrationID, storeID, from, size, option, searchText, searchCity, searchState, searchCountry, searchPinCode,
  searchCustomField1, searchCustomField2, searchCustomField3, searchCustomField4, searchCustomField5, searchCustomField6) {
  let searchParameters = {
    "from": from,
    "size": size,
    "query": {
      "bool": {
        "must": [
          { "match": { "RegistrationID": registrationID } },
          { "match": { "StoreID": storeID } }
        ],
        "must_not": [
        ],
        "should": [

        ],
        "minimum_should_match": 1,
        "filter": {
          "bool": {
            "must": [

            ]
          }
        }
      }
    }
  }
  if (option === "active") {
    searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "true" } })
  }
  if (option === "inactive") {
    searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "false" } })
  }
  searchParameters.query.bool.should.push({ "regexp": { "EmployeeFirstName": "@" + searchText.trim().toLowerCase() + ".*" } })
  searchParameters.query.bool.should.push({ "regexp": { "EmployeeLastName": "@" + searchText.trim().toLowerCase() + ".*" } })
  searchParameters.query.bool.should.push({ "regexp": { "EmailID": "@" + searchText.trim().toLowerCase() + ".*" } })
  searchParameters.query.bool.should.push({ "regexp": { "PhoneNo": "@" + searchText.trim().toLowerCase() + ".*" } })
  searchParameters.query.bool.should.push({ "regexp": { "EmployeeCode": "@" + searchText.trim().toLowerCase() + ".*" } })


  if (searchCity.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "City": "@" + searchCity.trim().toLowerCase() + ".*" } })
  }
  if (searchState.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "State": "@" + searchState.trim().toLowerCase() + ".*" } })
  }
  if (searchPinCode.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "PostalCode": "@" + searchPinCode.trim().toLowerCase() + ".*" } })
  }
  if (searchCountry.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Country": "@" + searchCountry.trim().toLowerCase() + ".*" } })
  }
  if (searchCustomField1.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField1": "@" + searchCustomField1.trim().toLowerCase() + ".*" } })
  }
  if (searchCustomField2.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField2": "@" + searchCustomField2.trim().toLowerCase() + ".*" } })
  }
  if (searchCustomField3.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField3": "@" + searchCustomField3.trim().toLowerCase() + ".*" } })
  }
  if (searchCustomField4.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField4": "@" + searchCustomField4.trim().toLowerCase() + ".*" } })
  }
  if (searchCustomField5.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField5": "@" + searchCustomField5.trim().toLowerCase() + ".*" } })
  }
  if (searchCustomField6.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField6": "@" + searchCustomField6.trim().toLowerCase() + ".*" } })
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable('getEmployees');

    return addMessage({ text: searchParameters }).then(function (result) {
      const employees = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        employees.push({ key: data._id, ...data._source })
      })
      dispatch({ type: actionTypes.GET_ACTIVE_EMPLOYEES, employees })
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: employees }
    });
  }
}


export function deleteEmployee(key){
    return (dispatch, getState) => {
        return database.collection("Employees").doc(key).update({
          IsDeleted:true,
          ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
          ActionBy: getState().user.user.Name,
          ActionByUID: getState().user.user.UID,
          ActionByEmailID:getState().user.user.EmailID,

          Action: constants.AUDIT_TRAIL_DELETE
        })
    }
}

export function getEmployeeDetails(key){
    return (dispatch) => {
    return database.collection("Employees").doc(key).get().then((doc) => {
        if (doc.exists) {
           return doc.data()
        } else {
            console.log("No such employee found!");
        }
    })
    }
}

export function updateEmployee(employee, key){
    return (dispatch, getState) => {
      employee.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
      employee.ActionBy = getState().user.user.Name
      employee.ActionByUID = getState().user.user.UID
      employee.ActionByEmailID = getState().user.user.EmailID

      employee.Action = constants.AUDIT_TRAIL_EDIT
        return database.collection("Employees").doc(key).update({
          ...employee
        })
    }
}

export function checkIfEmployeeCodeIsUnique(employeeCode, registrationID){
    return (dispatch) =>{
    let isUnique = true
    return database.collection("Employees").where("RegistrationID" ,"==",registrationID).where("EmployeeCode","==",employeeCode).where("IsDeleted","==",false).limit(1).get().then((querySnapshot) => {
       querySnapshot.forEach((doc) => {
        isUnique = false
       })
      return isUnique
    })
  }
}
