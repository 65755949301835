import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import Loader from '../Loader'
import * as constatnts from '../../Constatnts'
import { isNullOrUndefined } from 'util';
import * as actionTypes from '../../actionTypes'

class BillWiseDetailList extends Component {
    constructor() {
        super()
        this.state = {
            billWiseDetail: [],
            cashRegisterList: [],
            cashRegister: {},
            stores: [],
            storeList: [],
            searchText: '',
            searchTransactionNo: '',
            searchTransactiontype: '',
            searchTransactionFromDate: new Date().toISOString().split('T')[0],
            searchTransactionToDate: new Date().toISOString().split('T')[0],
            searchCustomerDetail: '',
            searchCashRegister: '',
            activePage: 1,
            itemsCountPerPage: 20,
            totalItemsCount: 0,
            recordFrom: 0,
            title: '',
            paginationStatus: 'No records to display',
            loading: true,
            exportBillWiseDetail: [],
            exportCalled: false,
            customField1DisplayName: 'Custom Field 1',
            customField2DisplayName: 'Custom Field 2',
            customField3DisplayName: 'Custom Field 3',
            customField4DisplayName: 'Custom Field 4',
            customField5DisplayName: 'Custom Field 5',
            customField6DisplayName: 'Custom Field 6',
            selectAll: false
        }
        this.onClearSearch = this.onClearSearch.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.showPaginationStatus = this.showPaginationStatus.bind(this)
        this.onExportClick = this.onExportClick.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.onConvertTransaction = this.onConvertTransaction.bind(this)
        this.checkIfrecordCanBeConverted = this.checkIfrecordCanBeConverted(this)
        this.onStoreChange = this.onStoreChange.bind(this)
    }

    async componentDidMount() {

        console.log("Connection Status", this.props.config.ConnectionStatus.Status)
        this.setState({ storeID: this.props.user.store.key })
        let storeList = []
        storeList.push({ key: this.props.user.store.key })

        this.setState({ stores: this.props.user.userStores, storeList: storeList, storeID: this.props.user.store.key });

        this.props.config.CustomFields.map((customField) => {
            if (customField.ModuleName === 'Customer') {
                let customField1DisplayName = customField.CustomField1Name.trim() === '' ? 'Custom Field 1' : customField.CustomField1Name.trim()
                let customField2DisplayName = customField.CustomField2Name.trim() === '' ? 'Custom Field 2' : customField.CustomField2Name.trim()
                let customField3DisplayName = customField.CustomField3Name.trim() === '' ? 'Custom Field 3' : customField.CustomField3Name.trim()
                let customField4DisplayName = customField.CustomField4Name.trim() === '' ? 'Custom Field 4' : customField.CustomField4Name.trim()
                let customField5DisplayName = customField.CustomField5Name.trim() === '' ? 'Custom Field 5' : customField.CustomField5Name.trim()
                let customField6DisplayName = customField.CustomField6Name.trim() === '' ? 'Custom Field 6' : customField.CustomField6Name.trim()
                this.setState({
                    customField1DisplayName: customField1DisplayName,
                    customField2DisplayName: customField2DisplayName,
                    customField3DisplayName: customField3DisplayName,
                    customField4DisplayName: customField4DisplayName,
                    customField5DisplayName: customField5DisplayName,
                    customField6DisplayName: customField6DisplayName
                })
            }
        })

        this.props.getCashRegisters(this.props.user.store.key).then((cashRegisters) => {
            // console.log("cashRegisters", cashRegisters);
            this.setState({ cashRegisterList: cashRegisters })
        })
        if (this.props.location.state.type === "report") {
            this.setState({ title: "Transaction Wise Detail Report" });
        }
        else if (this.props.location.state.type === "list") {
            this.setState({ title: "Sales History" });
        }

        if (this.props.config.ConnectionStatus.Status === 'connected') {
            this.loadBillWiseDetailReport(storeList,
                this.state.recordFrom,
                this.state.searchTransactionNo,
                this.state.searchTransactiontype,
                this.state.searchTransactionFromDate,
                this.state.searchTransactionToDate,
                this.state.searchCustomerDetail,
                this.state.searchCashRegister
            )
        }
        else {
            this.setState({ loading: true })
            let billWiseDetails = await this.props.getOfflineSales();
            console.log("Total Offline Sales", billWiseDetails.length);
            this.setState({ billWiseDetail: billWiseDetails, loading: false })
        }
    }

    loadBillWiseDetailReport(storeList, from, searchTransactionNo, searchTransactiontype, searchTransactionFromDate, searchTransactionToDate,
        searchCustomerDetail, searchCashRegister) {
        this.setState({ loading: true })
        this.props.getBillWiseDetailReport(this.props.user.user.RegistrationID, storeList, from, this.state.itemsCountPerPage,
            searchTransactionNo, searchTransactiontype, searchTransactionFromDate, searchTransactionToDate, searchCustomerDetail, searchCashRegister)
            .then((result) => {
                result.searchResult.map((l) => {
                    l.Selected = false
                })
                console.log(result.searchResult)
                this.setState({ billWiseDetail: result.searchResult, totalItemsCount: result.totalItemsCount, loading: false })
                this.showPaginationStatus()
            })
    }

    onClearSearch() {
        let storeList = []
        storeList.push({ key: this.props.user.store.key });

        this.setState({
            storeList: storeList,
            storeID: this.props.user.store.key,
            recordFrom: 0, searchTransactionNo: '',
            searchTransactiontype: '', searchTransactionToDate: new Date().toISOString().split('T')[0],
            searchTransactionFromDate: new Date().toISOString().split('T')[0],
            searchCustomerDetail: '', searchCashRegister: ''
        })
        this.loadBillWiseDetailReport(storeList, 0, "", "", this.state.searchTransactionFromDate, this.state.searchTransactionToDate, "", "")
    }

    onExportClick() {
        this.setState({ exportCalled: true })
        this.props.getBillWiseDetailReport(this.props.user.user.RegistrationID, this.state.storeList, 0, 10000,
            this.state.searchTransactionNo,
            this.state.searchTransactiontype,
            this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate,
            this.state.searchCustomerDetail,
            this.state.searchCashRegister)
            .then((result) => {
                // console.log(result.searchResult);
                if (isNullOrUndefined(result.searchResult) || result.searchResult.length === 0) {
                    this.props.getToastr("No Record Found")
                    return
                }
                let invoices = []
                result.searchResult.map((data) => {

                    // console.log(JSON.stringify(data))
                    let invoice = {
                        "Store Name ": data.Store.StoreName,
                        "Cash Register": data.CashRegister.CashRegisterName,
                        "Customer Code": !isNullOrUndefined(data.Customer) ? data.Customer.CustomerCode : "",
                        "Customer Name": !isNullOrUndefined(data.Customer) ? data.Customer.FirstName + '' + data.Customer.LastName : "",
                        "Customer Mobile No.": !isNullOrUndefined(data.Customer) ? data.Customer.PhoneNo : "",
                        "Transaction No": data.TransactionNo,
                        "Transaction Type": data.TransactionType.TransactionType,
                        "Transaction Date": (new Date(data.TransactionDate).toISOString().split('T')[0]),
                        "Qty": data.BillQty,
                        "Total Tax": data.BillTaxAmount,
                        "Discount": data.BillDiscount,
                        "Amount": data.BillAmount,
                        "Paid": data.BillPaidAmount,
                        "Balance": data.BalanceAmount,
                        "Cancelled": ""
                    }
                    invoice[this.state.customField1DisplayName] = ""
                    invoice[this.state.customField2DisplayName] = ""
                    invoice[this.state.customField3DisplayName] = ""
                    invoice[this.state.customField4DisplayName] = ""
                    invoice[this.state.customField5DisplayName] = ""
                    invoice[this.state.customField6DisplayName] = ""



                    for (let index = 0; index < this.props.config.PaymentTypes.length; index++) {
                        if (this.props.config.PaymentTypes[index].ShowInSales)
                            invoice[this.props.config.PaymentTypes[index].PaymentType] = ""
                    }

                    if (data.CancellationReason) {
                        invoice.Cancelled = "Yes"
                    }
                    if (data.Customer) {
                        invoice[this.state.customField1DisplayName] = data.Customer.CustomField1
                        invoice[this.state.customField2DisplayName] = data.Customer.CustomField2
                        invoice[this.state.customField3DisplayName] = data.Customer.CustomField3
                        invoice[this.state.customField4DisplayName] = data.Customer.CustomField4
                        invoice[this.state.customField5DisplayName] = data.Customer.CustomField5
                        invoice[this.state.customField6DisplayName] = data.Customer.CustomField6
                    }
                    data.Payments.map((payment) => {
                        invoice[payment.PaymentMode] = (payment.PaymentMode === "Cash" ? (payment.Amount - (invoice.BillChangeDue ? invoice.BillChangeDue : 0)) : (payment.Amount != undefined ? payment.Amount : 0))
                    })
                    invoices.push(invoice);
                })
                const csvData = constatnts.objToCSV(invoices);
                this.setState({ exportCalled: false })
                const csvBlob = new Blob([csvData], { type: "text/csv" });
                const url = window.URL.createObjectURL(csvBlob);
                const a = document.createElement("a");
                a.setAttribute("hidden", "");
                a.setAttribute("href", url);
                a.setAttribute("download", "Invoices.csv");
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
    }

    onSearchClick() {
        this.setState({ searchFrom: 0, loading: true })
        this.loadBillWiseDetailReport(
            this.state.storeList,
            0,
            this.state.searchTransactionNo,
            this.state.searchTransactiontype,
            this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate,
            this.state.searchCustomerDetail,
            this.state.searchCashRegister)
    }

    onStoreChange(e) {
        let storeList = []
        console.log("key", e.target.value);
        if (e.target.value != "") {
            storeList.push({ key: e.target.value })
        }
        else {
            for (let i = 0; i < this.state.stores.length; i++) {
                const store = this.state.stores[i];
                storeList.push({ key: store.key })
            }
        }
        console.log("storeList", JSON.stringify(storeList));
        this.setState({ storeList: storeList, storeID: e.target.value })
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }

    handlePageChange(pageNumber) {
        // console.log(`active page is ${pageNumber}`);
        let recordFrom = ((pageNumber - 1) * this.state.itemsCountPerPage)
        this.setState({ activePage: pageNumber, recordFrom: recordFrom });
        this.loadBillWiseDetailReport(
            this.state.storeList,
            recordFrom,
            this.state.searchTransactionNo,
            this.state.searchTransactiontype,
            this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate,
            this.state.searchCustomerDetail,
            this.state.searchCashRegister)
    }

    showPaginationStatus() {
        // console.log('this.state.recordFrom', this.state.recordFrom)
        let paginationStatus
        if ((this.state.recordFrom + 1) === this.state.totalItemsCount) {
            paginationStatus = "Showing " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else if ((this.state.recordFrom + this.state.itemsCountPerPage + 1) > this.state.totalItemsCount) {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + (this.state.recordFrom + this.state.itemsCountPerPage) + " of " + this.state.totalItemsCount

        }
        this.setState({ paginationStatus: paginationStatus })
    }

    onConvertTransaction(transactionType) {
        // console.log('onConvertTransaction',transactionType)
        //get selected records
        let selectedRecords = []
        let firstRecordTransactionType = null
        let firstRecordCustomer = null
        let firstRecord = false

        selectedRecords = this.state.billWiseDetail.filter((f) => f.Selected)
        if (selectedRecords.length === 0) {
            alert("Please select record(s).")
            return
        }
        for (let i = 0; i < selectedRecords.length; i++) {
            let record = selectedRecords[i]
            if (!firstRecord) {
                firstRecordTransactionType = record.TransactionType
                firstRecordCustomer = record.Customer
                firstRecord = true
                if (transactionType.TransactionType === firstRecordTransactionType.TransactionType) {
                    alert('Transaction can not be converted to same type. Please select different transaction types.')
                    return
                }
            }
            else {
                if (record.TransactionType.TransactionType !== firstRecordTransactionType.TransactionType) {
                    alert('Selected records should be of same transaction type')
                    return
                }
                if (!firstRecordCustomer && record.Customer) {
                    alert('Selected records should have same customer')
                    return
                }
                if (firstRecordCustomer && !record.Customer) {
                    alert('Selected records should have same customer')
                    return
                }
                // if(!billWiseDetail.ChildTransactionNo && !billWiseDetail.SalesReturnTransactionNo && !billWiseDetail.CancellationReason )
                if (firstRecordCustomer && record.Customer) {
                    if (firstRecordCustomer.key !== record.Customer.key) {
                        alert('Selected records should have same customer')
                        return
                    }

                }
            }
            if (record.hasOwnProperty('ChildTransactionNo')) {
                if (record.ChildTransactionNo.trim() !== "") {
                    alert('#' + record.TransactionNo + ' is already converted')
                    return
                }
            }
        }

        //all checksout well...so lets create a new sales that conatains all these records.
        let sale = {
            TransactionType: firstRecordTransactionType,
            Customer: firstRecordCustomer,
            RegistrationID: this.props.user.user.RegistrationID,
            CashRegister: null,
            Store: this.props.user.store,
            LineItems: [],
            BillQty: 0,
            BillSubTotal: 0,
            BillDiscount: 0,
            BillTaxAmount: 0,
            BillAmount: 0,
            RoundOff: 0,
            BillBalanceAmount: 0,
            BillPaidAmount: 0,
            BillChangeDue: 0,
            Payments: null,
            ParentTransactionNo: "",
            ParentTransactionKey: "",
            Employee: null,
            Notes: "",
            TaxSummaryList: [],
            PlaceOfSupply: "",
            BillingAddress: null,
            DeliveryCustomer: null,
            DeliveryCustomerAddress: null,
            DeliveryDate: "",
            DeliveryTime: "",
            deliveryNotes: "",
            BackEndUpdate: false
        }
        for (let i = 0; i < selectedRecords.length; i++) {
            let record = selectedRecords[i]
            record.LineItems.map((l) => {
                l.ParentTransactionNo = record.TransactionNo
                l.ParentTransactionKey = record.key
                l.ParentTransactionType = record.TransactionType.TransactionType
                l.Notes = record.TransactionNo
                sale.LineItems.push(l)
            })
        }
        if (firstRecordTransactionType.hasOwnProperty('UpdateStockChecked')) {
            if (firstRecordTransactionType.UpdateStockChecked) {
                transactionType.UpdateStockChecked = false//donot update stock again
            }
        }
        this.props.history.push({
            pathname: '/home/sales/newsales/',
            state: {
                type: actionTypes.SALE_NEW,
                key: "",
                transactionType: transactionType,
                sale: sale
            }
        })
    }

    checkIfrecordCanBeConverted(billWiseDetail) {
        if (billWiseDetail.ChildTransactionNo) {
            if (billWiseDetail.ChildTransactionNo.trim() !== "") {
                return false
            }
        }
        if (billWiseDetail.SalesReturnTransactionNo) {
            if (billWiseDetail.SalesReturnTransactionNo.trim() !== "") {
                return false
            }
        }
        if (billWiseDetail.CancellationReason) {
            if (billWiseDetail.CancellationReason !== "") {
                return false
            }
        }
        return true

    }

    render() {
        const btnColStyle = { width: "20px" }
        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6">
                                        <font style={{ fontSize: "30px" }}>{this.state.title}</font>
                                    </div>
                                    {this.state.title !== "Sales History" && <div className="col-md-6">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={() => this.props.history.push({ pathname: "/home/reports/allreport" })}
                                        ><i className="fa fa-arrow-left"></i> Back</button>
                                    </div>}
                                </div>
                            </div>
                            <div className="box-body">
                                {this.props.config.ConnectionStatus.Status === 'connected' && <React.Fragment> <div className="row">
                                    <div className="col-md-4">
                                        <label>Store Name</label>
                                        <select className="form-control"
                                            value={this.state.storeID}
                                            onChange={this.onStoreChange}>
                                            <option value="">All Stores</option>
                                            {this.state.stores.filter((e) => !e.IsDeleted).map((store) =>
                                                <option value={store.key}>{store.StoreCode}-{store.StoreName}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Transaction No</label>
                                            <input type="text" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionNo: evt.target.value })}
                                                value={this.state.searchTransactionNo}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Transaction Type</label>
                                            <select type="text" className="form-control"

                                                onChange={(evt) => this.setState({ searchTransactiontype: evt.target.value })}
                                                value={this.state.searchTransactiontype}
                                                onKeyPress={this.handleKeyPress}>
                                                <option value="">All</option>
                                                {this.props.config.TransactionTypes.filter((e) => e.Module === "sales" && !e.IsDeleted).map((e) => <option value={e.key}>{e.TransactionType}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                    <div className="row">
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label>Transaction From</label>
                                                <input type="date" className="form-control"
                                                    onChange={(evt) => this.setState({ searchTransactionFromDate: evt.target.value })}
                                                    value={this.state.searchTransactionFromDate}
                                                    onKeyPress={this.handleKeyPress} />
                                            </div>
                                        </div>

                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label>Transaction To</label>
                                                <input type="date" className="form-control"
                                                    onChange={(evt) => this.setState({ searchTransactionToDate: evt.target.value })}
                                                    value={this.state.searchTransactionToDate}
                                                    onKeyPress={this.handleKeyPress} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Customer Name,code,Phone Number,Email</label>
                                                <input type="text" className="form-control"
                                                    onChange={(evt) => this.setState({ searchCustomerDetail: evt.target.value })}
                                                    value={this.state.searchCustomerDetail}
                                                    onKeyPress={this.handleKeyPress} />
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Cash Register</label>
                                                <select type="text" className="form-control"
                                                    onChange={(evt) => this.setState({ searchCashRegister: evt.target.value })}
                                                    value={this.state.searchCashRegister}
                                                    onKeyPress={this.handleKeyPress}>
                                                    <option value="">All</option>
                                                    {this.state.cashRegisterList.map((cashRegister) =>
                                                        <option value={cashRegister.key}>{cashRegister.CashRegisterCode}-{cashRegister.CashRegisterName}</option>
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" >
                                        {this.state.title === "Sales History" && <div className="col-md-12">
                                            <button type="button" className="btn btn-default dropdown-toggle btn-flat  btn-md" data-toggle="dropdown">Convert
                                                <span className="fa fa-caret-down"></span></button>
                                            <ul className="dropdown-menu" style={{ right: "auto", marginLeft: "15px" }}>
                                                {this.props.config.TransactionTypes.filter((e) => e.Module === "sales" && !e.IsDeleted && e.TransactionType.toLowerCase().trim() !== "receive payment").map((e) => <li><a onClick={() => this.onConvertTransaction(e)}>{e.TransactionType}</a></li>)}
                                            </ul>
                                            <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                                style={{ marginLeft: "5px" }}
                                                onClick={this.onSearchClick} onKeyPress={this.handleKeyPress}
                                            >Search</button>
                                            <button type="button" className="btn btn-default btn-flat btn-md pull-right"
                                                onClick={this.onClearSearch}
                                            >Clear</button>
                                            <button className="btn btn-default btn-flat btn-md pull-right"
                                                style={{ marginRight: "5px" }}
                                                onClick={this.onExportClick}
                                            >Export <i className="glyphicon glyphicon-download-alt " ></i></button>
                                        </div>}
                                        {this.state.title === "Transaction Wise Detail Report" && <div className="col-md-12">
                                            <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                                style={{ marginLeft: "5px" }}
                                                onClick={this.onSearchClick} onKeyPress={this.handleKeyPress}
                                            >Search</button>
                                            <button type="button" className="btn btn-default btn-flat btn-md pull-right"
                                                onClick={this.onClearSearch}
                                            >Clear</button>
                                            <button className="btn btn-default btn-flat btn-md pull-right"
                                                style={{ marginRight: "5px" }}
                                                onClick={this.onExportClick}
                                            >Export <i className="glyphicon glyphicon-download-alt " ></i></button>
                                        </div>}
                                    </div><br></br></React.Fragment>}
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading &&
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                {this.state.title === "Sales History" && this.props.config.ConnectionStatus.Status === 'connected' &&
                                                    <th>
                                                        <input type="checkbox"
                                                            checked={this.state.selectAll}
                                                            onChange={() => {
                                                                let billWiseDetail = this.state.billWiseDetail
                                                                billWiseDetail.map((b) => {
                                                                    if (b.TransactionType.TransactionType.toLowerCase().trim() !== "receive payment"
                                                                        && !b.hasOwnProperty('CancellationReason')) {
                                                                        b.Selected = !this.state.selectAll
                                                                    }
                                                                })
                                                                this.setState({ selectAll: !this.state.selectAll, billWiseDetail: billWiseDetail })
                                                            }}
                                                        />
                                                    </th>}
                                                <th>Transaction No</th>
                                                <th>Transaction Type</th>
                                                <th>Transaction Date</th>
                                                <th>Store Name</th>
                                                <th>Cash Register</th>
                                                <th>Customer Detail</th>
                                                <th>Qty</th>
                                                <th>Total Tax</th>
                                                <th>Discount</th>
                                                <th>Amount</th>
                                                <th>Paid</th>
                                                <th>Balance</th>
                                                <th>Payment Type</th>
                                                {this.state.title === "Sales History" &&
                                                    <th style={btnColStyle} ></th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.billWiseDetail.map((billWiseDetail, index) =>
                                                <tr>
                                                    {this.state.title === "Sales History" && this.props.config.ConnectionStatus.Status === 'connected' &&
                                                        <td>
                                                            {/* {(this.checkIfrecordCanBeConverted(billWiseDetail)) &&  */}
                                                            {(billWiseDetail.TransactionType.TransactionType.toLowerCase().trim() !== "receive payment" && !billWiseDetail.hasOwnProperty('CancellationReason')) && <input type="checkbox"
                                                                checked={billWiseDetail.Selected}
                                                                // disabled={billWiseDetail.TransactionType.TransactionType.toLowerCase().trim()==="receive payment" ? true :false }
                                                                onChange={() => {
                                                                    let billWiseDetail = this.state.billWiseDetail
                                                                    billWiseDetail[index].Selected = !billWiseDetail[index].Selected
                                                                    this.setState({ billWiseDetail: billWiseDetail })
                                                                }}
                                                            />}
                                                        </td>}
                                                    <td>{billWiseDetail.TransactionNo}</td>
                                                    <td>
                                                        {billWiseDetail.TransactionType.TransactionType}
                                                        <div>
                                                            {billWiseDetail.CancellationReason && <span className="badge bg-red">Cancelled </span>}
                                                        </div>
                                                        <div>
                                                            {billWiseDetail.ParentTransactionKey && <span className="badge bg-lg bg-green">{billWiseDetail.ParentTransactionNo} </span>}
                                                        </div>
                                                        <div>
                                                            {billWiseDetail.SalesReturnTransactionNo && <span className="badge bg-orange">Sales return done via {billWiseDetail.SalesReturnTransactionNo}</span>}
                                                        </div>
                                                        <div>
                                                            {billWiseDetail.ChildTransactionNo && <span className="badge bg-orange">{billWiseDetail.ChildTransactionNo}</span>}
                                                        </div>
                                                    </td>
                                                    {this.props.config.ConnectionStatus.Status === 'connected' && <td>
                                                        {(new Date(billWiseDetail.TransactionDate).toISOString().split('T')[0])}
                                                    </td>}
                                                    {this.props.config.ConnectionStatus.Status === 'disconnected' && <td>
                                                            {(new Date(billWiseDetail.TransactionDate.seconds*1000)).toString().substring(0,24)}
                                                    </td>}
                                                    <td>{billWiseDetail.Store.StoreName}</td>
                                                    <td>
                                                        <div>{billWiseDetail.CashRegister.CashRegisterCode}</div>
                                                        <div>{billWiseDetail.CashRegister.CashRegisterName}</div>
                                                        <div>{billWiseDetail.CashRegister.StoreCode}</div>
                                                        <div>{billWiseDetail.CashRegister.StoreName}</div>
                                                    </td>
                                                    <td>
                                                        {billWiseDetail.Customer && <div>
                                                            <div>{billWiseDetail.Customer.FirstName + ' ' + billWiseDetail.Customer.LastName}</div>
                                                            <div>{billWiseDetail.Customer.EmailID}</div>
                                                            <div>{billWiseDetail.Customer.PhoneNo}</div>
                                                            {billWiseDetail.Customer.CustomField1.trim() !== "" && <div>{this.state.customField1DisplayName}:{billWiseDetail.Customer.CustomField1}</div>}
                                                            {billWiseDetail.Customer.CustomField2.trim() !== "" && <div>{this.state.customField2DisplayName}:{billWiseDetail.Customer.CustomField2}</div>}
                                                            {billWiseDetail.Customer.CustomField3.trim() !== "" && <div>{this.state.customField3DisplayName}:{billWiseDetail.Customer.CustomField3}</div>}
                                                            {billWiseDetail.Customer.CustomField4.trim() !== "" && <div>{this.state.customField4DisplayName}:{billWiseDetail.Customer.CustomField4}</div>}
                                                            {billWiseDetail.Customer.CustomField5.trim() !== "" && <div>{this.state.customField5DisplayName}:{billWiseDetail.Customer.CustomField5}</div>}
                                                            {billWiseDetail.Customer.CustomField6.trim() !== "" && <div>{this.state.customField6DisplayName}:{billWiseDetail.Customer.CustomField6}</div>}
                                                        </div>}

                                                    </td>
                                                    <td>{billWiseDetail.BillQty}</td>
                                                    <td>{billWiseDetail.BillTaxAmount}</td>
                                                    <td>{billWiseDetail.BillDiscount}</td>
                                                    <td>{billWiseDetail.BillAmount}</td>
                                                    <td>{billWiseDetail.BillPaidAmount}</td>
                                                    <td>{billWiseDetail.BalanceAmount}</td>
                                                    <td>
                                                        {billWiseDetail.Payments.map((payment) =>
                                                            <div>{payment.PaymentMode + ' ' + ':' + ' ' + (payment.PaymentMode === "Cash" ? (payment.Amount - billWiseDetail.BillChangeDue) : payment.Amount)}</div>
                                                        )}
                                                    </td>
                                                    {this.state.title === "Sales History" &&
                                                        <td>
                                                            <div>
                                                                <Link to={`/home/sales/viewsales/${billWiseDetail.key}`}
                                                                    type="button" className="btn btn-md btn-flat btn-default "><i className="fa fa-edit"></i></Link>
                                                            </div>
                                                        </td>
                                                    }
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>}
                                {this.props.config.ConnectionStatus.Status === 'connected' && this.state.totalItemsCount > 0 && <div className="pull-left" style={{ marginTop: "25px" }}>
                                    {this.state.paginationStatus}
                                </div>}
                                {this.props.config.ConnectionStatus.Status === 'connected' && <div className="pull-right">
                                    <Pagination
                                        activePage={this.state.activePage} //this.state.activePage
                                        itemsCountPerPage={this.state.itemsCountPerPage}
                                        totalItemsCount={this.state.totalItemsCount}
                                        pageRangeDisplayed={2}
                                        onChange={this.handlePageChange}
                                    />
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}
export default withRouter(BillWiseDetailList)