import React, { Component } from 'react';
import * as actionTypes from '../../actionTypes'
import { withRouter } from 'react-router-dom'
import Switch from "react-switch"

class AddEditStore extends Component{

    constructor(){
        super()
        this.state={
            saving: false,
            storeCode:'',
            storeName:'',
            emailID:'',
            phoneNo:'',
            address1:'',
            address2:'',
            city:'',
            state:'',
            postalCode:'',
            country:'',
            currency:'',
            fiscalYearMonth:'',
            language:'',
            timeZone:'',
            cashRegisterClosingSelected : false,
            customField1:'',
            customField2:'',
            customField3:'',
            customField4:'',
            customField5:'',
            customField6:'',
            storeNameError:'',
            storeCodeError:'',
            action:'',
            key:'',
            customField1DisplayName:'Custom Field 1',
            customField2DisplayName:'Custom Field 2',
            customField3DisplayName:'Custom Field 3',
            customField4DisplayName:'Custom Field 4',
            customField5DisplayName:'Custom Field 5',
            customField6DisplayName:'Custom Field 6'
        }
        this.onSaveStore= this.onSaveStore.bind(this)
    }
    
    componentDidMount(){
        if(this.props.location.state.action === actionTypes.STORE_NEW)
        {
          this.setState({action:this.props.location.state.action})
        }
        if(this.props.location.state.action === actionTypes.STORE_EDIT)
        {
            // console.log('this.props.location.state.action',this.props.location.state.action)
            // console.log(this.props.location.store)
            // return
          this.setState({action:this.props.location.state.action,
            key:this.props.location.state.storeID,
            storeCode:this.props.location.state.store.StoreCode,
            storeName:this.props.location.state.store.StoreName,
            emailID:this.props.location.state.store.EmailID,
            phoneNo: this.props.location.state.store.PhoneNo,
            address1: this.props.location.state.store.Address1,
            address2: this.props.location.state.store.Address2,
            city:this.props.location.state.store.City,
            state:this.props.location.state.store.State,
            postalCode:this.props.location.state.store.PostalCode,
            country: this.props.location.state.store.Country,
            currency: this.props.location.state.store.Currency,
            fiscalYearMonth: this.props.location.state.store.FiscalYearMonth,
            language:this.props.location.state.store.Language,
            timeZone: this.props.location.state.store.TimeZone,
            cashRegisterClosingSelected : this.props.user.store.hasOwnProperty('CashRegisterClosing') ? this.props.user.store.CashRegisterClosing : false,
            customField1: this.props.location.state.store.CustomField1,
            customField2: this.props.location.state.store.CustomField2,
            customField3: this.props.location.state.store.CustomField3,
            customField4: this.props.location.state.store.CustomField4,
            customField5: this.props.location.state.store.CustomField5,
            customField6: this.props.location.state.store.CustomField6 })
        }        
        this.props.config.CustomFields.map((customField) => {
            if (customField.ModuleName === 'Store')
            {
                let customField1DisplayName = customField.CustomField1Name.trim() === '' ? 'Custom Field 1' : customField.CustomField1Name.trim()
                let customField2DisplayName  = customField.CustomField2Name.trim() === '' ? 'Custom Field 2' : customField.CustomField2Name.trim()
                let customField3DisplayName = customField.CustomField3Name.trim() === '' ? 'Custom Field 3' : customField.CustomField3Name.trim()
                let customField4DisplayName = customField.CustomField4Name.trim() === '' ? 'Custom Field 4' : customField.CustomField4Name.trim()
                let customField5DisplayName = customField.CustomField5Name.trim() === '' ? 'Custom Field 5' : customField.CustomField5Name.trim()
                let customField6DisplayName = customField.CustomField6Name.trim() === '' ? 'Custom Field 6' : customField.CustomField6Name.trim()
                this.setState({
                    customField1DisplayName:customField1DisplayName,
                    customField2DisplayName:customField2DisplayName,
                    customField3DisplayName:customField3DisplayName,
                    customField4DisplayName:customField4DisplayName,
                    customField5DisplayName:customField5DisplayName,
                    customField6DisplayName:customField6DisplayName
                })
            }
        })
        console.log('key',this.props.location.state.storeID)
    }

    onSaveStore(){
        this.setState({ saving:true})
        let storeCodeError = ''
        let storeNameError =''
        if(this.state.storeCode.trim()===''){
            storeCodeError ='Please provide store code'
        }
        if(this.state.storeName.trim()==='')
        {
            storeNameError ='Please provide store name'
        }       
        if(storeCodeError !=='' || storeNameError!=='' )
        {
            this.setState({storeCodeError:storeCodeError, storeNameError:storeNameError, saving:false})
            return
        }
        this.props.checkIfStoreCodeIsUnique(this.state.storeCode, this.state.key, this.props.user.user.RegistrationID).then((isUnique)=>{
            if(!isUnique)
            {

                this.setState({ storeCodeError:'Store code already exists',saving:false})
                return
            }
            let store = {
                StoreCode:this.state.storeCode,
                StoreName:this.state.storeName,
                EmailID:this.state.emailID,
                PhoneNo:this.state.phoneNo,
                Address1:this.state.address1,
                Address2:this.state.address2, 
                City:this.state.city,
                State:this.state.state,
                PostalCode:this.state.postalCode,
                Country:this.state.country,
                Currency:this.state.currency,
                FiscalYearMonth:this.state.fiscalYearMonth,
                Language:this.state.language,
                TimeZone:this.state.timeZone,
                CashRegisterClosing : this.state.cashRegisterClosingSelected,
                CustomField1:this.state.customField1,
                CustomField2:this.state.customField2,
                CustomField3:this.state.customField3,
                CustomField4:this.state.customField4,
                CustomField5:this.state.customField5,
                CustomField6:this.state.customField6,
                RegistrationID: this.props.user.user.RegistrationID,
                IsDeleted:false
            }
            if(this.state.key === "")
            {
                this.props.addNewStore(store).then((key)=>{
                    this.props.getToastr("New Store created Successfully")
                    //redirect to store view page
                    setTimeout(function() { //Start the timer
                        this.setState({ saving:false})
                        this.props.history.push({pathname:"/home/settings/stores/viewstoredetails/",
                        state:{key:key} })
                    }.bind(this), 1000)
                    
                })
            }
            else
            {
                console.log('store', store)
                console.log('this.state.key',this.state.key)
                this.props.updateStore(store, this.state.key).then(()=>{
                    this.props.getToastr("Store Saved Successfully")
                    //redirect to store view page
                    setTimeout(function() { //Start the timer
                        this.props.history.push({pathname:"/home/settings/stores/viewstoredetails/",
                        state:{key:this.state.key} })
                    }.bind(this), 1000)
                    
                   
                })
            }
        })
    }   
    render() { 
    
        return <div className="content-wrapper">
                    <section className="content-header">
                        <h1>{this.state.action === actionTypes.STORE_EDIT ? "Edit Store" :"New Store" }</h1>
                        <button type="submit" className="btn btn-md btn-success btn-flat pull-right" 
                            style={{marginTop:"-25px",marginLeft:"10px"}} 
                            onClick={this.onSaveStore} disabled={this.state.saving}>Save
                        </button>
                        <button  className="btn btn-md btn-flat btn-default pull-right"  style={{marginTop:"-25px"}} 
                             onClick={()=> this.props.history.push("/home/settings/stores")} >Cancel
                        </button>
                    </section>  
                    <section className="content" >
                                <div className="box ">
                                    <div className="box-header with-border">
                                        <h3 className="box-title">General details</h3>
                                    </div>
                                    <div className="box-body">     
                                        <div className="row">
                                            <div className="col-md-3">
                                            <div className={ this.state.storeCodeError !=="" ? "form-group has-error": "form-group "} >
                                                    <label >Store Code</label>
                                                    <input type="text" className="form-control"  
                                                    onChange={(evt)=> this.setState({storeCode:evt.target.value, storeCodeError:''})}
                                                    value={this.state.storeCode}
                                                    />
                                                    {this.state.storeCodeError !=="" &&  <span class="help-block">{this.state.storeCodeError}</span> }
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className={ this.state.storeNameError !=="" ? "form-group has-error": "form-group "} >
                                                    <label>Store Name</label>
                                                    <input type="text" className="form-control"  
                                                    onChange={(evt)=> this.setState({storeName:evt.target.value, storeNameError:''})}
                                                    value={this.state.storeName}
                                                    />
                                                    {this.state.storeNameError!==""  &&  <span class="help-block">{this.state.storeNameError}</span> }

                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group" >
                                                        <label >Phone Number</label>
                                                        <input type="text" className="form-control"  
                                                        onChange={(evt)=> this.setState({phoneNo:evt.target.value})}
                                                        value={this.state.phoneNo}
                                                        />
                                                </div>
                                                
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group" >
                                                    <label>Email</label>
                                                    <input type="text" className="form-control"  
                                                    onChange={(evt)=> this.setState({emailID:evt.target.value})}
                                                    value={this.state.emailID}
                                                    />
                                                </div>
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                                <div className="box ">
                                    <div className="box-header with-border">
                                        <h3 className="box-title">Address</h3>
                                    </div>
                                    <div className="box-body">     
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group" >
                                                    <label >Line Address 1</label>
                                                    <input type="text" className="form-control"  
                                                    onChange={(evt)=> this.setState({address1:evt.target.value})}
                                                    value={this.state.address1}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Line Address 2</label>
                                                    <input type="text" className="form-control"  
                                                    onChange={(evt)=> this.setState({address2:evt.target.value})}
                                                    value={this.state.address2}
                                                    />
                                                </div>
                                            </div>
                                        </div>    
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>City</label>
                                                    <input type="text" className="form-control"  
                                                    onChange={(evt)=> this.setState({city:evt.target.value})}
                                                    value={this.state.city}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group" >
                                                        <label >State</label>
                                                        <input type="text" className="form-control"  
                                                        onChange={(evt)=> this.setState({state:evt.target.value})}
                                                        value={this.state.state}
                                                        />
                                                </div>
                                                
                                            </div>
                                            
                                        </div>  
                                        <div className="row">
                                        <div className="col-md-6">
                                                <div className="form-group" >
                                                    <label >Postal Code</label>
                                                    <input type="text" className="form-control"  
                                                    onChange={(evt)=> this.setState({postalCode:evt.target.value})}
                                                    value={this.state.postalCode}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group" >
                                                        <label >Country</label>
                                                        <input type="text" className="form-control"  
                                                        onChange={(evt)=> this.setState({country:evt.target.value})}
                                                        value={this.state.country}
                                                        />
                                                </div>
                                                
                                            </div>
                                          
                                        </div>  
                                    </div>
                                </div>
                                <div className="box ">
                                    <div className="box-header with-border">
                                        <h3 className="box-title">Settings</h3>
                                    </div>
                                    <div className="box-body">     
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="form-group" >
                                                    <label >Currency</label>
                                                    <input type="text" className="form-control"  
                                                    onChange={(evt)=> this.setState({currency:evt.target.value})}
                                                    value={this.state.currency}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Fiscal Year - Start Month</label>
                                                    <input type="text" className="form-control"  
                                                    onChange={(evt)=> this.setState({fiscalYearMonth:evt.target.value})}
                                                    value={this.state.fiscalYearMonth}
                                                    />
                                                </div>
                                            </div>
                                      
                                            <div className="col-md-3">
                                                <div className="form-group" >
                                                        <label >Timezone</label>
                                                        <input type="text" className="form-control"  
                                                        onChange={(evt)=> this.setState({timeZone:evt.target.value})}
                                                        value={this.state.timeZone}
                                                        />
                                                </div>
                                                
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group" >
                                                    <label >Language</label>
                                                    <input type="text" className="form-control"  
                                                    onChange={(evt)=> this.setState({language:evt.target.value})}
                                                    value={this.state.language}
                                                    />
                                                </div>
                                            </div>
                                        </div> 
                                        <div className="row">
                                            <div className="col-md-3" style={{ padding: "10px 0px 10px 0px", margin:"15px"}}><strong>Enable Cash Register Closing</strong></div>
                                            <div className="col-md-9">
                                                <Switch
                                                    checked={this.state.cashRegisterClosingSelected}
                                                    onChange={() => { this.setState({ cashRegisterClosingSelected: !this.state.cashRegisterClosingSelected }) }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="box ">
                                    <div className="box-header with-border">
                                        <h3 className="box-title">Additonal Information</h3>
                                    </div>
                                    <div className="box-body">     
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group" >
                                                    <label >{this.state.customField1DisplayName}</label>
                                                    <input type="text" className="form-control"  
                                                    onChange={(evt)=> this.setState({customField1:evt.target.value})}
                                                    value={this.state.customField1}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>{this.state.customField2DisplayName}</label>
                                                    <input type="text" className="form-control"  
                                                    onChange={(evt)=> this.setState({customField2:evt.target.value})}
                                                    value={this.state.customField2}
                                                    />
                                                </div>
                                            </div>
                                        </div>    
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group" >
                                                        <label >{this.state.customField3DisplayName}</label>
                                                        <input type="text" className="form-control"  
                                                        onChange={(evt)=> this.setState({customField3:evt.target.value})}
                                                        value={this.state.customField3}
                                                        />
                                                </div>
                                                
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group" >
                                                    <label >{this.state.customField4DisplayName}</label>
                                                    <input type="text" className="form-control"  
                                                    onChange={(evt)=> this.setState({customField4:evt.target.value})}
                                                    value={this.state.customField4}
                                                    />
                                                </div>
                                            </div>
                                        </div> 
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group" >
                                                        <label >{this.state.customField5DisplayName}</label>
                                                        <input type="text" className="form-control"  
                                                        onChange={(evt)=> this.setState({customField5:evt.target.value})}
                                                        value={this.state.customField5}
                                                        />
                                                </div>
                                                
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group" >
                                                    <label >{this.state.customField6DisplayName}</label>
                                                    <input type="text" className="form-control"  
                                                    onChange={(evt)=> this.setState({customField6:evt.target.value})}
                                                    value={this.state.customField6}
                                                    />
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                                <button type="submit" 
                                    style={{marginBottom:"10px",marginLeft:"10px"}} 
                                    className="btn btn-md btn-success btn-flat pull-right" 
                                    onClick={this.onSaveStore} disabled={this.state.saving}>Save
                                </button>
                                <button style={{marginBottom:"10px"}}   
                                    onClick={()=> this.props.history.push("/home/settings/stores")}
                                    className="btn btn-md btn-default btn-flat  pull-right">Cancel
                                </button>
                                {/* <div className="box-footer">
                                    <button  onClick={()=> this.props.history.push("/home/settings/stores")}  className="btn btn-lg btn-default btn-flat"  >Cancel</button>   
                                    <button type="submit" className="btn btn-lg btn-primary pull-right btn-flat" onClick={this.onSaveStore}>Save</button>
                                </div>  */}
                            </section>
                        </div>
    }
}

export default withRouter(AddEditStore)