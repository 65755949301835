import React, { Component } from 'react';
//import Modal from 'react-responsive-modal'
import * as actions from '../../../actionTypes'
import Modal from 'react-responsive-modal'
import EmployeeAccess from './EmployeeAccess'
import { isNullOrUndefined } from 'util'
//import Confirm from 'react-confirm-bootstrap'


class ViewEmployeeDetails extends Component{

    constructor(){
        super()
        this.state={
            employee:null,
            employeeID:'',
            customField1DisplayName:'Custom Field 1',
            customField2DisplayName:'Custom Field 2',
            customField3DisplayName:'Custom Field 3',
            customField4DisplayName:'Custom Field 4',
            customField5DisplayName:'Custom Field 5',
            customField6DisplayName:'Custom Field 6'
        }
    }
    
    componentDidMount(){
        
        this.props.getEmployeeDetails(this.props.location.state.key).then((employee)=> {
            this.setState({employee: employee, employeeID:this.props.location.state.key})
        })
        this.props.config.CustomFields.map((customField) => {
            if (customField.ModuleName === 'Employee')
            {
                let customField1DisplayName = customField.CustomField1Name.trim() === '' ? 'Custom Field 1' : customField.CustomField1Name.trim()
                let customField2DisplayName = customField.CustomField2Name.trim() === '' ? 'Custom Field 2' : customField.CustomField2Name.trim()
                let customField3DisplayName = customField.CustomField3Name.trim() === '' ? 'Custom Field 3' : customField.CustomField3Name.trim()
                let customField4DisplayName = customField.CustomField4Name.trim() === '' ? 'Custom Field 4' : customField.CustomField4Name.trim()
                let customField5DisplayName = customField.CustomField5Name.trim() === '' ? 'Custom Field 5' : customField.CustomField5Name.trim()
                let customField6DisplayName = customField.CustomField6Name.trim() === '' ? 'Custom Field 6' : customField.CustomField6Name.trim()
                this.setState({
                    customField1DisplayName:customField1DisplayName,
                    customField2DisplayName:customField2DisplayName,
                    customField3DisplayName:customField3DisplayName,
                    customField4DisplayName:customField4DisplayName,
                    customField5DisplayName:customField5DisplayName,
                    customField6DisplayName:customField6DisplayName
                })
            }
        })
    }

    render(){
        const modalWidth = { width: '700px' }

        return <div className="content-wrapper">
        <section className="content-header">
            <h1>View Employee Details</h1>            
            <button type="submit" className="btn btn-success pull-right btn-flat" style={{ marginTop: "-25px", marginLeft: "10px" }}
                onClick={()=> this.props.history.push({pathname: "/home/settings/employees/editemployee", 
                state:{action:actions.EMPLOYEE_EDIT, key:this.state.employeeID, 
                employee:this.state.employee} })}>Edit</button>
                
                {this.state.employee && isNullOrUndefined(this.state.employee.UID) && <button type="button"
                    className="btn btn-default btn-md pull-right btn-flat" style={{ marginTop: "-25px", marginLeft: "10px" }}
                    onClick={() => this.setState({ showModal: true})}>Assign Access</button>}

                <button type="cancel" className="btn btn-default pull-right btn-flat" style={{ marginTop: "-25px", marginLeft: "10px" }}
                onClick={()=> this.props.history.push({pathname: "/home/settings/employees"})}
                // state:{action:actions.EMPLOYEE_EDIT, key:this.state.employeeID, 
                // employee:this.state.employee
                >Back</button>
        </section>  
      {this.state.employee &&  <section className="content" >
                    <div className="box ">
                        <div className="box-header with-border">
                            <h3 className="box-title">General details</h3>
                            {/* {isNullOrUndefined(this.state.employee.UID) && <button type="submit" className="btn btn-default pull-right btn-flat"
                            onClick={()=>this.setState({
                                showModal:true })}
                        >Assign Access</button>}
                        {!isNullOrUndefined(this.state.employee.UID) && this.state.employee.UID != "" && 
                        <React.Fragment> <button type="submit" className="btn btn-default pull-right btn-flat"
                            onClick={()=>this.setState({
                                showModal:true })}
                        >Change Password</button> 
                        &nbsp;&nbsp;
                        <button type="submit" className="btn btn-default pull-right btn-flat"
                            onClick={()=>this.setState({
                                showModal:true })}
                        >Change Role</button> </React.Fragment>}                             */}

                        
                            {/* <div className="pull-right" style={{ marginTop: "6px" }}> */}
                                
                            {/* </div> */}
                        
                         </div>
                        <div className="box-body">     
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group" >
                                        <label >Employee Code</label>
                                        <br/>
                                        <span>{this.state.employee.EmployeeCode}</span>      
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>First Name</label>
                                        <br/>
                                        <span>{this.state.employee.EmployeeFirstName}</span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Last Name</label>
                                        <br/>
                                        <span>{this.state.employee.EmployeeLastName}</span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Gender</label>
                                        <br/>
                                        <span>{this.state.employee.Gender}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group" >
                                        <label >Phone Number</label>
                                        <br/>
                                        <span>{this.state.employee.PhoneNo}</span>
                                    </div>          
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group" >
                                        <label>Email</label>
                                        <br/>
                                        <span>{this.state.employee.EmailID}</span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group" >
                                        <label>Biometric ID</label>
                                        <br/>
                                        <span>{this.state.employee.BiometricID}</span>
                                    </div>
                                </div>
                            </div>  
                        </div>
                        <div className="box-header with-border">
                            <h3 className="box-title">Address</h3>
                        </div>
                        <div className="box-body">     
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group" >
                                        <label >Line Address 1</label>
                                        <br/>
                                        <span>{this.state.employee.Address1}</span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Line Address 2</label>
                                        <br/>
                                        <span>{this.state.employee.Address2}</span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>City</label>
                                        <br/>
                                        <span>{this.state.employee.City}</span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group" >
                                            <label >State</label>
                                            <br/>
                                            <span>{this.state.employee.State}</span>
                                    </div>
                                </div>
                            </div>  
                            <div className="row">
                            <div className="col-md-3">
                                    <div className="form-group" >
                                        <label >Postal Code</label>
                                        <br/>
                                        <span>{this.state.employee.PostalCode}</span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group" >
                                            <label >Country</label>
                                            <br/>
                                            <span>{this.state.employee.Country}</span>
                                    </div>
                                </div>
                            </div>  
                        </div>
                        <div className="box-header with-border">
                            <h3 className="box-title">Additonal Information</h3>
                        </div>
                        <div className="box-body">     
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group" >
                                        <label >{this.state.customField1DisplayName}</label>
                                        <br/>
                                        <span>{this.state.employee.CustomField1}</span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>{this.state.customField2DisplayName}</label>
                                        <br/>
                                        <span>{this.state.employee.CustomField2}</span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group" >
                                            <label >{this.state.customField3DisplayName}</label>
                                            <br/>
                                            <span>{this.state.employee.CustomField3}</span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group" >
                                        <label >{this.state.customField4DisplayName}</label>
                                        <br/>
                                        <span>{this.state.employee.CustomField4}</span>
                                    </div>
                                </div>
                            </div> 
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group" >
                                            <label >{this.state.customField5DisplayName}</label>
                                            <br/>
                                            <span>{this.state.employee.CustomField5}</span>
                                    </div>
                                    
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group" >
                                        <label >{this.state.customField6DisplayName}</label>
                                        <br/>
                                        <span>{this.state.employee.CustomField6}</span>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </section>}
                <Modal open={this.state.showModal} onClose={() => this.setState({ showModal: false })} center>
                    <div style={modalWidth}>
                        <EmployeeAccess  {...this.props}
                        employee={{...this.state.employee,employeeID:this.state.employeeID}}
                        />
                    </div>
                </Modal>
            </div>
    }
}

export default ViewEmployeeDetails