import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom'
import Pagination from "react-js-pagination"
import Loader from '../Loader'
import AuditTrail from '../AuditTrail'
import * as constants from '../../Constatnts'
import { isNullOrUndefined } from 'util';

class Packages extends Component {
    constructor() {
        super()
        this.state = {
            packages: [],
            referredCustomer: {},
            showFilters: false,
            searchText: '',
            packageName: '',
            transactionNo: '',
            activePage: 1,
            itemsCountPerPage: 20,
            totalItemsCount: 0,
            recordFrom: 0,
            paginationStatus: 'No records to display',
            searchActiveInactiveAllRecords: 'pending',
            loading: true
        }
        this.loadPackages = this.loadPackages.bind(this)
        this.onClearSearch = this.onClearSearch.bind(this)
        this.onSearchActiveInactiveAllRecordsClicked = this.onSearchActiveInactiveAllRecordsClicked.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.showPaginationStatus = this.showPaginationStatus.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.onExportClick = this.onExportClick.bind(this)
    }

    componentDidMount() {
        this.loadPackages(
            this.state.recordFrom,
            this.state.searchActiveInactiveAllRecords,
            this.state.packageName,
            this.state.transactionNo,
            this.state.searchText
        )
    }


    loadPackages(from, status, packageName, transactionNo, searchText) {
        this.setState({ loading: true })
        this.props.getPackages(this.props.user.user.RegistrationID, this.props.user.store.key, from
            , this.state.itemsCountPerPage, status, packageName, transactionNo, searchText)
            .then((result) => {
                this.setState({ packages: result.searchResult, totalItemsCount: result.totalItemsCount, loading: false })
                this.showPaginationStatus()
            })
    }

    onClearSearch() {
        this.setState({
            recordFrom: 0, packageName: '', transactionNo: '', searchText: '',
            searchActiveInactiveAllRecords: "pending"
        })
        this.loadPackages(0, "pending", "", "", "")
    }

    onSearchActiveInactiveAllRecordsClicked(option) {
        this.setState({ searchActiveInactiveAllRecords: option, recordFrom: 0 })
        this.loadPackages(
            0,
            option,
            this.state.packageName,
            this.state.transactionNo,
            this.state.searchText)
    }

    onSearchClick() {
        this.setState({ searchFrom: 0 })
        this.loadPackages(
            0,
            this.state.searchActiveInactiveAllRecords,
            this.state.packageName,
            this.state.transactionNo,
            this.state.searchText)
    }



    handlePageChange(pageNumber) {
        let recordFrom = ((pageNumber - 1) * this.state.itemsCountPerPage)
        this.setState({ activePage: pageNumber, recordFrom: recordFrom });
        this.loadPackages(
            recordFrom,
            this.state.searchActiveInactiveAllRecords,
            this.state.packageName,
            this.state.transactionNo,
            this.state.searchText)
    }

    onExportClick() {
        this.setState({ exportCalled: true })
        this.props.getPackages(this.props.user.user.RegistrationID, this.props.user.store.key, 0
            , 10000, this.state.searchActiveInactiveAllRecords, this.state.packageName, this.state.transactionNo, this.state.searchText)
            .then((result) => {
                console.log(result.searchResult);
                if (isNullOrUndefined(result.searchResult) || result.searchResult.length === 0) {
                    this.props.getToastr("No Record Found")
                    return
                }
                let packages = []
                result.searchResult.map((data) => {
                    let customer = {
                        "Transaction No": data.TransactionNo,
                        "Package Name": data.PackageName,
                        "Customer Code": data.CustomerCode,
                        "Customer Name": data.CustomerName,
                        "Customer Email ID": data.CustomerEmailID,
                        "Customer Phone No": data.CustomerPhoneNo,
                        "Purchase Date": new Date(data.CreatedOn).toISOString().split('T')[0],
                        "Valid Till": new Date(data.PackageEndDate).toISOString().split('T')[0],
                        "Status": data.PackageStatus
                    }
                    packages.push(customer);
                })

                const csvData = constants.objToCSV(packages);
                this.setState({ loading: false, exportCalled: false })
                const csvBlob = new Blob([csvData], { type: "text/csv" });
                const url = window.URL.createObjectURL(csvBlob);
                const a = document.createElement("a");
                a.setAttribute("hidden", "");
                a.setAttribute("href", url);
                a.setAttribute("download", "packages.csv");
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
    }

    showPaginationStatus() {
        let paginationStatus
        if ((this.state.recordFrom + 1) === this.state.totalItemsCount) {
            paginationStatus = "Showing " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else if ((this.state.recordFrom + this.state.itemsCountPerPage + 1) > this.state.totalItemsCount) {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + (this.state.recordFrom + this.state.itemsCountPerPage) + " of " + this.state.totalItemsCount

        }
        this.setState({ paginationStatus: paginationStatus })

    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }

    render() {
        const btnColStyle = { width: "140px" }
        const revisionHistory = { width: "200px" }
        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6">
                                        <font style={{ fontSize: "30px" }}>Package Tracker</font>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-12" style={{ marginBottom: "10px" }}>
                                        <div className="btn-group">



                                            <button type="button" className={this.state.searchActiveInactiveAllRecords === "pending" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.onSearchActiveInactiveAllRecordsClicked("pending")}>Pending</button>
                                            <button type="button" className={this.state.searchActiveInactiveAllRecords === "partial" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.onSearchActiveInactiveAllRecordsClicked("partial")}>Partial</button>
                                            <button type="button" className={this.state.searchActiveInactiveAllRecords === "completed" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.onSearchActiveInactiveAllRecordsClicked("completed")}>Completed</button>
                                            <button type="button" className={this.state.searchActiveInactiveAllRecords === "expired" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.onSearchActiveInactiveAllRecordsClicked("expired")}>Expired</button>
                                            <button type="button" className={this.state.searchActiveInactiveAllRecords === "cancelled" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.onSearchActiveInactiveAllRecordsClicked("cancelled")}>Cancelled</button>
                                            <button type="button" className={this.state.searchActiveInactiveAllRecords === "all" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.onSearchActiveInactiveAllRecordsClicked("")}>All</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Package Name</label>
                                            <input type="text" className="form-control input-md"
                                                onKeyPress={this.handleKeyPress}
                                                value={this.state.packageName}
                                                onChange={(evt) => this.setState({ packageName: evt.target.value })} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Transaction No</label>
                                            <input type="text" className="form-control input-md"
                                                onKeyPress={this.handleKeyPress}
                                                value={this.state.transactionNo}
                                                onChange={(evt) => this.setState({ transactionNo: evt.target.value })} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Customer Name, Email ID, PhoneNo No</label>
                                            <input type="text" className="form-control input-md"
                                                onKeyPress={this.handleKeyPress}
                                                value={this.state.searchText}
                                                onChange={(evt) => this.setState({ customerName: evt.target.value })} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginLeft: "5px" }}
                                            onClick={this.onSearchClick}
                                        >Search</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                            onClick={this.onClearSearch} >Clear</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px", marginRight: "5px" }}
                                            onClick={this.onExportClick} >Export</button>

                                    </div>
                                </div>
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading &&
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>Transaction No</th>
                                                <th>Package Name</th>
                                                <th>Customer Details</th>
                                                <th>Purchase Date</th>
                                                <th>Valid Till</th>
                                                <th>Status</th>
                                                <th style={revisionHistory}>Revision History</th>
                                                <th style={btnColStyle}></th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {this.state.packages.map((data) =>
                                                <tr>
                                                    <td>{data.TransactionNo}</td>
                                                    <td>{data.PackageName}</td>
                                                    <td>{data.CustomerName}
                                                        <div>{data.CustomerCode}</div>
                                                        <div>{data.CustomerEmailID}</div>
                                                        <div>{data.CustomerPhoneNo}</div>
                                                    </td>
                                                    <td>{new Date(data.CreatedOn).toISOString().split('T')[0]} </td>
                                                    <td>{new Date(data.PackageEndDate).toISOString().split('T')[0]}</td>
                                                    <td>{data.PackageStatus}</td>
                                                    <td>
                                                        <div>Action :{data.Action}</div>
                                                        <div>Action By :{data.ActionBy}</div>
                                                        <div>Action Date :{(new Date(data.ActionOn)).toString().substring(0, 24)}</div>
                                                        <AuditTrail {...this.props} module={constants.MODULE_PACKAGE} parentKey={data.key} />
                                                    </td>
                                                    <td>
                                                        <div class="btn-group">
                                                            {data.PackageStatus === 'Expired' ? <span class="label label-danger pull-right">Inactive</span> :
                                                                <div>
                                                                    <Link to={`/home/package/edit/${data.key}`} type="button" class="btn btn-md btn-flat btn-default "><i class="fa fa-edit"></i></Link>
                                                                </div>
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>}
                                {this.state.totalItemsCount > 0 && <div className="pull-left" style={{ marginTop: "25px" }}>
                                    {this.state.paginationStatus}
                                </div>}
                                <div className="pull-right">
                                    <Pagination
                                        activePage={this.state.activePage} //this.state.activePage
                                        itemsCountPerPage={this.state.itemsCountPerPage}
                                        totalItemsCount={this.state.totalItemsCount}
                                        pageRangeDisplayed={2}
                                        onChange={this.handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default withRouter(Packages)
