import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import CustomerDate from './CustomerDate'


class CustomerReference extends Component {

    constructor() {
        super()
        this.state = {
            DOBNew:{Day:'', Month:'', Year:''},
            AnniversaryNew:{Day:'', Month:'', Year:''},
            FirstName:'',
            LastName:'',
            Gender:'',
            EmailID:'',
            PhoneNo:'',
            Relation:'',
            FirstNameError:'',
            AnniversaryError:'',
            DOBError:'',
            referredCustomer: {}
        }
        this.onSaveCustomerReferences = this.onSaveCustomerReferences.bind(this)
    }

    componentDidMount() {
        console.log("Referred Props Customer: ", JSON.stringify(this.props.referredCustomer));
    }

    onSaveCustomerReferences(flag) {
        let firstNameError = ""
        let dobError= ""
        let anniversaryError = ""
        if(this.state.FirstName.trim()==="")
        {
            firstNameError = "Please provide Customer Name"
          
        }
       if(this.state.DOBNew.Day.toString().trim() !=="" && this.state.DOBNew.Month.toString().trim() ==="" )
       {
            dobError = "Please select Date of Birth month"
       }
       if(this.state.DOBNew.Day.toString().trim() ==="" && this.state.DOBNew.Month.toString().trim() !=="" )
       {
            dobError = "Please select Date of Birth day"
       }
       if(this.state.AnniversaryNew.Day.toString().trim() !=="" && this.state.AnniversaryNew.Month.toString().trim() ==="" )
       {
            anniversaryError = "Please select AnniversaryNew month"
       }
       if(this.state.AnniversaryNew.Day.toString().trim() ==="" && this.state.AnniversaryNew.Month.toString().trim() !=="" )
       {
        anniversaryError = "Please select AnniversaryNew day"
       }
       if(firstNameError !=="" || dobError!=="" || anniversaryError!=="")
       {
            this.setState({FirstNameError:firstNameError, 
                DOBError:dobError,
                AnniversaryError:anniversaryError,
                })
            return 
       }
        
       let name = this.props.referredCustomer.FirstName.trim() + ' '   + this.props.referredCustomer.LastName;
        let customer = {
            CustomerCode: '',
            FirstName: this.state.FirstName,
            LastName: this.state.LastName,
            EmailID: this.state.EmailID,
            Tags:['Receiver'],
            PhoneNo: this.state.PhoneNo,            
            DOBNew:this.state.DOBNew,
            AnniversaryNew:this.state.AnniversaryNew,
            Membership:{},
            WalletOpeningBalance:0,
            WalletBalance:0,
            OutstandingAmount:0,
            CustomerAcceptsMarketing: null,
            Note: '',
            Gender: this.state.Gender,
            Addresses: [],
            CustomField1: '',
            CustomField2: '',
            CustomField3: '',
            CustomField4: '',
            CustomField5: '',
            CustomField6: '',
            RegistrationID: this.props.user.user.RegistrationID,
            StoreID:this.props.user.store.key,
            IsDeleted: false,
            BackEndUpdate:false,
            ReferredByCustomer: {key: this.props.referredCustomer.key, 
                FirstName: this.props.referredCustomer.FirstName,
                LastName: this.props.referredCustomer.LastName, 
                Name: name.trim(),
                PhoneNo:  this.props.referredCustomer.PhoneNo,
                EmailID:  this.props.referredCustomer.EmailID} ,
            Relation: this.state.Relation
        }
       
        this.props.addCustomer(customer).then((key) => {
            let reference = {
                FirstName: this.state.FirstName,
                LastName: this.state.LastName,
                EmailID: this.state.EmailID,
                PhoneNo: this.state.PhoneNo,            
                DOBNew:this.state.DOBNew,
                AnniversaryNew:this.state.AnniversaryNew,
                Gender:this.state.Gender,
                Relation: this.state.Relation,
                RegistrationID: this.props.user.user.RegistrationID,
                StoreID:this.props.user.store.key
            }
            let referredCustomer=this.props.referredCustomer;
            let references = referredCustomer.References ? referredCustomer.References: []
            reference.CustomerKey = key
            references.push(reference)
            referredCustomer.References = references;
            referredCustomer.Tags=['Sender']
            this.props.updateCustomer(referredCustomer,referredCustomer.key);
            if(flag)
            {
                this.setState({
                    DOBNew:{Day:'', Month:'', Year:''},
                    AnniversaryNew:{Day:'', Month:'', Year:''},
                    FirstName:'',
                    LastName:'',
                    Gender:'',
                    EmailID:'',
                    PhoneNo:'',
                    Relation:'',
                    FirstNameError:'',
                    AnniversaryError:'',
                    DOBError:''
                })
            }
            else
            {
                this.props.onClose();
            }
            
            this.props.getToastr("Customer Saved Successfully");
        })
    }

    render() {
        return <div style={{width:"700px"}}>
            <h3 style={{ marginTop: "0px" }}>Add References</h3>
            <div className="box-body">
                   <div className="row">
                        <div className="col-md-6">
                            <div className={this.state.FirstNameError !== "" ? "form-group has-error" : "form-group "} >
                                <label >First Name</label>
                                <input type="text" className="form-control"
                                    onChange={(evt) =>{ this.setState({ FirstName: evt.target.value})}}
                                    value={this.state.FirstName}
                                />
                                {this.state.FirstNameError !== "" && <span class="help-block">{this.state.FirstNameError}</span>}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group ">
                                <label >Last Name</label>
                                <input type="text" className="form-control"
                                    onChange={(evt) =>{ this.setState({ LastName: evt.target.value})}}
                                    value={this.state.LastName}
                                />
                            </div>
                        </div>
                        </div>
                        
                   <div className="row">
                        <div className="col-md-6">
                            <div >
                                <label >Phone Number</label>
                                <input type="text" className="form-control"
                                    onChange={(evt) =>{ this.setState({ PhoneNo: evt.target.value})}}
                                    value={this.state.PhoneNo}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group ">
                                <label >Email</label>
                                <input type="text" className="form-control"
                                    onChange={(evt) =>{ this.setState({ EmailID: evt.target.value})}}
                                    value={this.state.EmailID}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className={ this.state.DOBError ? "form-group has-error": "form-group "} >
                                <label >Date Of Birth</label>
                                <CustomerDate 
                                    customerDate = {this.state.DOBNew}
                                    error={this.state.DOBError}
                                    onChange={(date)=> this.setState({DOBNew:date})}
                                    />
                                {this.state.DOBError!=="" && <span class="help-block">{this.state.DOBError}</span>} 
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div >
                                <label >Anniversary</label>
                                <CustomerDate 
                                    customerDate = {this.state.AnniversaryNew}
                                    error={this.state.dobError}
                                    onChange={(date)=> this.setState({AnniversaryNew:date})}
                                    />
                                    {this.state.AnniversaryError !=="" && <span class="help-block">{this.state.AnniversaryError}</span>} 
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group ">
                                <label >Gender</label>
                                <select className="form-control" value={this.state.Gender} onChange={(evt) => this.setState({ Gender: evt.target.value })}>
                                        <option >Select gender</option>
                                        <option >Female</option>
                                        <option >Male</option>
                                    </select>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group ">
                                <label >Relation</label>
                                <select className="form-control" value={this.state.Relation} onChange={(evt) => this.setState({ Relation: evt.target.value })}>
                                        <option >Select relation</option>
                                        <option >Mother</option>
                                        <option >Father</option>
                                        <option >Wife</option>
                                        <option >Husband</option>
                                        <option >Son</option>
                                        <option >Daughter</option>
                                        <option >Friend</option>
                                        <option >Other</option>
                                    </select>
                            </div>
                        </div>
                   </div>
      
            </div>
            <div className="box-footer">
                <button onClick={() => this.props.onClose()} className="btn btn-md btn-default btn-flat">Cancel</button>
                <button onClick={() => this.onSaveCustomerReferences(false)} type="submit" className="btn btn-md btn-success pull-right btn-flat">Save & Exit</button>
                <button onClick={() => this.onSaveCustomerReferences(true)} type="submit" className="btn btn-md btn-primary pull-right btn-flat" style={{marginRight:"10px"}}>Save & Continue</button>
            </div>
        </div>
    }
}
export default withRouter(CustomerReference)