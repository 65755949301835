import React, {Component} from 'react'

class CancelSales extends React.Component{
    constructor () {  
        super()
        this.state={
            cancellationReason:'',
            key:'',
            saving: false
        }
        this.onSave = this.onSave.bind(this)
    }
    
    componentDidMount()
    {
        //console.log('salesID',this.props.salesID)
        this.setState({key:this.props.salesID})
    }
    onSave(){
        if(this.state.cancellationReason.trim() ==='')
        {
            alert('Please provide reason for cancellation')
            return
        }
        this.setState({ saving:true})
        this.props.cancelSales(this.state.cancellationReason, this.state.key);
        this.props.getToastr("Transaction cancelled successfully");
        setTimeout(function () { //Start the timer
            this.setState({ saving: false })
            this.props.onSave()
        }.bind(this), 3000)
            
    }

    render(){
        return   <div style={{ width: "300px" }}>
        <h3 style={{marginTop:"0px"}}>Cancel Sales Invoice</h3>
        <div className="box-body">
            <textarea className="textarea" style={{ width: "100%", height: "200px"}}
            onChange={(evt)=> this.setState({cancellationReason:evt.target.value})}
            value={this.state.cancellationReason}
            ></textarea>
        </div>
        <div className="box-footer">
                <button type="submit" className="btn btn-md btn-flat btn-default" onClick={this.props.onClose} >Cancel</button>
                <button type="submit" 
                className="btn btn-flat btn-md btn-flat btn-success pull-right" 
                disabled={this.state.saving}
                onClick={this.onSave}>Save</button>
            </div>
    </div>

    }
}

export default CancelSales