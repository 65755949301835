import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Loader from '../Loader'
import ReactHtmlParser from 'react-html-parser';
import * as constants from '../../Constatnts'

class MonthlySalesReport extends Component {
    constructor() {
        super()
        this.state = {
            dailySales: [],
            stores: [],
            storeList: [],
            searchTransactionFromDate: new Date("2-Dec-2018").toISOString().split('T')[0],
            searchTransactionToDate: new Date().toISOString().split('T')[0],
            exportCalled: false,
            searchStoreName: '',
            loading: true,
            finalTable: null
        }
        this.onSearchClick = this.onSearchClick.bind(this)
        this.loadMonthlySalesData = this.loadMonthlySalesData.bind(this)
        this.loadPaymentModeData = this.loadPaymentModeData.bind(this)
        this.loadData = this.loadData.bind(this)
        this.onClearSearch = this.onClearSearch.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.onExportClick = this.onExportClick.bind(this)
        this.onStoreChange = this.onStoreChange.bind(this)
    }

    componentDidMount() {
        this.setState({ storeID: this.props.user.store.key })
        let storeList = []
        storeList.push({ key: this.props.user.store.key })

        this.props.getUserStores(this.props.user.user.RegistrationID, "").then((stores) => {                
            this.setState({ stores: stores })
        });
    this.setState({ storeList: storeList, storeID: this.props.user.store.key });
        this.loadData(this.state.searchTransactionToDate, this.state.searchTransactionFromDate, storeList) 
    }

    async loadData(searchTransactionToDate, searchTransactionFromDate, storeList) {
        let dailySales = await this.loadMonthlySalesData(searchTransactionToDate, searchTransactionFromDate,storeList);
        let payments = await this.loadPaymentModeData(searchTransactionToDate, searchTransactionFromDate,storeList);

        // For Creating Columns
        for (let index = 0; index < dailySales.length; index++) {
            let sale = dailySales[index];

            for (let index1 = 0; index1 < payments.length; index1++) {
                let payment = payments[index1];

                if (sale["Transaction Month"] === payment["Transaction Month"]) {
                    sale[payment.PaymentMode] = (payment.Payment_total_sales === undefined ? 0 : payment.Payment_total_sales)
                }
            }
        }

        var col = [];
        for (var i = 0; i < dailySales.length; i++) {
            for (var key in dailySales[i]) {
                if (col.indexOf(key) === -1) {
                    col.push(key);
                }
            }
        }

        var result = "<table class='table table-bordered'><thead><tr>";
        for (var i = 0; i < col.length; i++) {
            result += "<th>";
            result += col[i];
            result += "</th>";
        }
        result += "</tr></thead>";

        // ADD JSON DATA TO THE TABLE AS ROWS.
        result += "<tbody>";
        for (var i = 0; i < dailySales.length; i++) {
            result += "<tr>";
            for (var j = 0; j < col.length; j++) {
                result += "<td>";
                result += dailySales[i][col[j]] == undefined ? 0 : dailySales[i][col[j]];
                result += "</td>";
            }
            result += "</tr>";
        }

        // ADD Totals
        result +="<tr><td style='font-weight:bold'>Totals</td>"
        result +="<td style='font-weight:bold'>-</td>"
        for (var j = 2; j < col.length; j++) { 
            let total = 0
            for (var i = 0; i < dailySales.length; i++) {
                total += (dailySales[i][col[j]] === undefined ? 0 : Number(dailySales[i][col[j]]))
            }
            result += "<td style='font-weight:bold'>";
            result += total;
            result += "</td>";
        }
        result +="</tr>"
        result += "</tbody>";
        result += "</table>";
        this.setState({ finalTable: result,loading:false})
    }

    async onExportClick() {

        let dailySales = await this.loadMonthlySalesData(this.state.searchTransactionToDate, this.state.searchTransactionFromDate, this.state.storeList);
        let payments = await this.loadPaymentModeData(this.state.searchTransactionToDate, this.state.searchTransactionFromDate, this.state.storeList);

        // For Creating Columns
        for (let index = 0; index < dailySales.length; index++) {
            let sale = dailySales[index];

            for (let index1 = 0; index1 < payments.length; index1++) {
                let payment = payments[index1];

                if (sale["Transaction Month"] === payment["Transaction Month"]) {
                    sale[payment.PaymentMode] = (payment.Payment_total_sales === undefined ? 0 : payment.Payment_total_sales)
                }
            }
        }

        var col = [];
        for (var i = 0; i < dailySales.length; i++) {
            for (var key in dailySales[i]) {
                if (col.indexOf(key) === -1) {
                    col.push(key);
                }
            }
        }

        var finalSales= []
        for (var i = 0; i < dailySales.length; i++) {
            //result += "<tr>";
            var sale = dailySales[i];
            for (var j = 0; j < col.length; j++) {
                sale[col[j]] = sale[col[j]] === undefined ? 0 : sale[col[j]];
            }
            finalSales.push(sale);
        }

        const csvData = constants.objToCSV(finalSales);
        const csvBlob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(csvBlob);
        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", "MonthlySales.csv");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    async loadMonthlySalesData(searchTransactionToDate, searchTransactionFromDate, storeList) {
        let dailySales = [];
        return this.props.getDailySales(this.props.user.user.RegistrationID, storeList, searchTransactionToDate,
            searchTransactionFromDate, 'month')
            .then((result) => {
                dailySales = result.searchResult;
                return dailySales;
            })
    }

    async loadPaymentModeData(searchTransactionToDate, searchTransactionFromDate, storeList) {
        let payments = [];
        return this.props.getPaymentModeWiseDaily(this.props.user.user.RegistrationID, storeList, searchTransactionToDate,
            searchTransactionFromDate, 'month')
            .then((result) => {
                payments = result.searchResult;
                return payments;
            })
    }

    onClearSearch() {
        let storeList = []
        storeList.push({ key: this.props.user.store.key});

        this.setState({
            searchTransactionToDate: new Date().toISOString().split('T')[0],
            searchTransactionFromDate: new Date().toISOString().split('T')[0],
            storeList:storeList,
            storeID : this.props.user.store.key,
            loading: true
        })
        this.loadData(this.state.searchTransactionToDate, this.state.searchTransactionFromDate, storeList)
    }

    onSearchClick() {
        this.setState({
            loading: true
        })
        this.loadData(
            this.state.searchTransactionToDate,
            this.state.searchTransactionFromDate,
            this.state.storeList
        )
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }

    onStoreChange(e){
        let storeList = []

        console.log("key", e.target.value);
        if (e.target.value != "") {
            storeList.push({key: e.target.value})
        }
        else
        {
            for (let i = 0; i < this.state.stores.length; i++) {
                const store = this.state.stores[i];
                storeList.push({ key: store.key })
            }
        }
        console.log("storeList", JSON.stringify(storeList));
        this.setState({ storeList: storeList, storeID: e.target.value })
    }

    render() {
        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <font style={{ fontSize: "30px" }}>Monthly Sales</font>
                                    </div>
                                    <div className="col-md-6">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={() => this.props.history.push({ pathname: "/home/reports/allreport" })}
                                        ><i className="fa fa-arrow-left"></i> Back</button>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <label>Store Name</label>
                                        <select className="form-control"
                                            value={this.state.storeID}
                                            onChange={this.onStoreChange}>
                                            <option value="">All Stores</option>
                                            {this.state.stores.filter((e) => !e.IsDeleted).map((store) =>
                                                <option value={store.key}>{store.StoreCode}-{store.StoreName}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-md-8">
                                    <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginLeft: "5px" }}
                                            onClick={this.onSearchClick}
                                        >Search</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                            onClick={this.onClearSearch} >Clear</button>
                                        <button class="btn btn-default btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginRight: "5px" }}
                                            onClick={this.onExportClick}
                                        >Export <i class="glyphicon glyphicon-download-alt " ></i>
                                        </button>
                                    </div>
                                </div> 
                                <br></br>
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading &&
                                    ReactHtmlParser(this.state.finalTable)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default withRouter(MonthlySalesReport)