import React, {Component} from 'react'
import { Switch, Route} from 'react-router-dom';
import Login from './Login';
import Home from './Home'
import Header from './Header'
import Posts from './Post/Posts'
// import Dummy from './Dummy/Dummy'
// import DummyHeader from './Dummy/DummyHeader'
// import Dummy1 from './Dummy/Dummy1'
// import DummyPayment from './Dummy/DummyPayment'
import Post from './Post/Post'
import Register from './Register'
import Registration from './Settings/Registration'
import AuthenticatedComponent from './AuthenticatedComponent'
import {auth} from '../firebase'
import Product from './Catalogue/Product'
import ProductList from './Catalogue/ProductList'
import ModifiersList from './Catalogue/ModifiersList'
import Customer from './CRM/Customer'
import CustomerList from './CRM/CustomerList'
import Sales from './Sales/Sales'
import SalesIntermediate from './Sales/SalesIntermediate'
import CashRegisterClosing from './Sales/CashRegisterClosing'
import CashRegisterClosingList from './Sales/CashRegisterClosingList'
import ReceivePayment from './Sales/ReceivePayment'
import DeliveryList from './Sales/DeliveryList';

import InventoryIntermediate from './Inventory/InventoryIntermediate'
import Inventory from './Inventory/Inventory'
import InventoryList from './Inventory/InventoryList'
import ViewInventory from './Inventory/ViewInventory'

// import Payment from './Sales/Payment'
//import SalesList from './Sales/SalesList'
import ViewSales from './Sales/ViewSales'
import StoresList from './Settings/StoresList'
import AddEditStore from './Settings/AddEditStore'
import ViewStoreDetails from './Settings/ViewStoreDetails'
import EmployeeList from './Settings/Employee/EmployeeList'
import AddEditEmployee from './Settings/Employee/AddEditEmployee'
import ViewEmployeeDetails from './Settings/Employee/ViewEmployeeDetails'
import CustomFields from './Settings/CustomFields'
import PaymentTypes from './Settings/PaymentTypes'
import ProductBrandList from './Catalogue/ProductBrandList'
import ProductTaxList from './Catalogue/ProductTaxList'
import ProductCategoriesList from './Catalogue/ProductCategoriesList'
import PrintConfigList from './Settings/PrintConfigList'
import PrintConfig from './Settings/PrintConfig'
import AddEditTransactionType from './Settings/AddEditTransactionType'
import TransactionTypeList from './Settings/TransactionTypeList'
import Print from './Sales/Print'

import VendorList from './Settings/Vendor/VendorList'
import AddEditVendor from './Settings/Vendor/AddEditVendor'
import ExpenseCategoriesList from './Settings/ExpenseCategoriesList'
import ExpenseList from './Expenses/ExpenseList'
import Expense from './Expenses/Expense'
import Memberships from './CRM/Memberships'
import NewMembership from './CRM/NewMembership'
import CustomerReferenceDetail from './Reports/CustomerReferenceDetail'

// import SalesDashboard from './Reports/SalesDashboard'
// import BillWiseDetail from './Reports/BillWiseDetail'
// import ItemSoldReport from './Reports/ItemSoldReport'
// import InvoiceDetail from './Reports/InvoiceDetail'

import CustomerDashboard from './Reports/CustomerDashboard'
// import EmployeeItemDetail from './Reports/EmployeeItemReport'
import SalesDashboard from './Reports/SalesDashboard'
import BillWiseDetail from './Reports/BillWiseDetail'
import ItemSoldReport from './Reports/ItemSoldReport'
import InvoiceDetail from './Reports/InvoiceDetail'
import EmployeeItemDetail from './Reports/EmployeeItemReport'
import CategoryWiseDetail from './Reports/CategoryWiseDetail'
import BrandWiseDetail from './Reports/BrandWiseDetail'
import SubCategoryWiseDetail from './Reports/SubCategoryWiseDetail'
import ProductTypeWise from './Reports/ProductTypeWise'
import AllReport from './Reports/AllReport'
import Campaigns from './CRM/Campaigns'
import NewCampaign from './CRM/AddCampaign'
import EmployeeDashboard from './Reports/EmployeeDashboard'
import AttendanceList from './Attendance/AttendanceList'

import ExpenseListReport from './Reports/ExpenseListReport'
import ExpenseByExpenseCategoryReport from './Reports/ExpenseByExpenseCategoryReport'
import ExpenseByPartyReport from './Reports/ExpenseByParty'


import Instock from './Reports/InStockReport'
import ItemLedger from './Reports/ItemLedger'

import DailySalesReport from './Reports/DailySalesReport';
import MonthlySalesReport from './Reports/MonthlySalesReport'
import PaymentModeWise from './Reports/PaymentModeWise'

import EmployeeWorkSheet from './Reports/EmployeeWorkSheet'

import OnBoarding from './Settings/OnBoarding'

import PaymentModeTransactionTypeReport from './Reports/PaymentModeTransactionTypeReport';
import InvoiceDetail1 from './Reports/InvoiceDetail1';

import ProductTagList from './Catalogue/ProductTagList'
import NewProductTags from './Catalogue/AddEditProductTag'

import Offers from './Catalogue/OfferList'
import NewOffer from './Catalogue/AddEditOffer'

import AllInventoryReport from './Reports/AllInventoryReport'

import CustomerWalletLedgerReport from './Reports/CustomerWalletLedgerReport'
import CustomerOutstandingLedgerReport from './Reports/CustomerOutstandingLedgerReport'
import WalletLedger from './Reports/WalletLedger'
import OutStandingLedger from './Reports/OutStandingLedger'
import UniqueNoReport from './Reports/UniqueNoReport'
import UniqueNoLedger from './Reports/UniqueNoLedger'

import SalesTransactionView from './Public/SalesTransactionView'

import Packages from './Packages/Packages';
import EditPackage from './Packages/EditPackage';




class Main extends Component
{
    render()
    { 
        return<div>
                    {/* <Dummy {...this.props} /> */}
                    <Route exact path='/' render={({history})=>(<div><Login {...this.props} onHistory = {history}/></div>)} />
                    <Route exact path='/register' render={({history})=>(<div><Register {...this.props} onHistory = {history}/></div>)} />
                    <Route path='/salestransactionview/:key' render={(params)=>(<div><SalesTransactionView {...this.props} {...params}/></div>)} />
                         
                    <AuthenticatedComponent {...this.props}>
                         <Header {...this.props}/>
                         <Route exact path='/home'  render={({history})=>(<Home {...this.props} onHistory = {history}/>)}/>  
                         <Route exact path='/home/post'  render={({history})=>(<Posts {...this.props} onHistory = {history}/>)}/>  
                         <Route exact path='/home/post/newpost'  render={({history})=>(<Post {...this.props} onHistory = {history}/>)}/>   
                         <Route exact path='/home/settings/registration'  render={()=>(<Registration {...this.props} />)}/>   
                         <Route exact path='/home/product' render={()=>(<div><ProductList {...this.props}/></div>)} />
                         <Route exact path='/home/product/newproduct' render={()=>(<div><Product {...this.props}/></div>)} />
                         <Route exact path='/home/product/edit/:key' render={(params)=>(<div><Product {...this.props} {...params}/></div>)} />
                         <Route exact path='/home/catalogue/tags' render={(params)=>(<div><ProductTagList {...this.props} {...params}/></div>)} />
                         <Route exact path='/home/catalogue/addtags' render={(params)=>(<div><NewProductTags {...this.props} {...params}/></div>)} />
                         <Route exact path='/home/catalogue/tags/edit/:key' render={(params)=>(<div><NewProductTags {...this.props} {...params}/></div>)} />
                         <Route exact path='/home/catalogue/offer' render={(params)=>(<div><Offers {...this.props} {...params}/></div>)}/>
                         <Route exact path='/home/catalogue/newoffer' render={()=>(<div><NewOffer {...this.props}/></div>)}/>
                         <Route exact path='/home/catalogue/edit/:key' render={(params)=>(<div><NewOffer {...this.props} {...params}/></div>)} />
                         <Route exact path='/home/catalogue/modifiers' render={(params)=>(<div><ModifiersList {...this.props} {...params}/></div>)} />

                         <Route exact path='/home/customer/newcustomer' render={()=>(<div><Customer {...this.props}/></div>)} />
                         <Route exact path='/home/customer' render={()=>(<div><CustomerList {...this.props}/></div>)} />
                         <Route exact path='/home/customer/edit/:key' render={(params)=>(<div><Customer {...this.props} {...params}/></div>)} />
                         <Route exact path='/home/customer/customerdashboard/:key' render={(params)=>(<div><CustomerDashboard {...this.props} {...params}/></div>)} />
                         <Route exact path='/home/campaign' render={() =>(<div><Campaigns {...this.props}/></div>)}/>
                        <Route exact path='/home/campaign/newcampaign' render={()=>(<div><NewCampaign {...this.props}/></div>)}/>
                        <Route exact path='/home/campaign/edit/:key' render={(params)=>(<div><NewCampaign {...this.props} {...params}/></div>)} />

                         <Route  path='/home/sales/newsales' render={(params)=>(<div><Sales {...this.props} {...params}/></div>)} />
                         <Route  path='/home/sales/sales' render={(params)=>(<div><SalesIntermediate {...this.props} {...params}/></div>)} />
                         <Route  path='/home/sales/receivepayment' render={(params)=>(<div><ReceivePayment {...this.props} {...params}/></div>)} />
                         <Route exact path='/home/packages/packagelist' render={(params)=>(<div><Packages {...this.props} {...params}/></div>)} />
                        <Route exact path='/home/package/edit/:key' render={(params)=>(<div><EditPackage {...this.props} {...params}/></div>)} />

                         <Route  path='/home/inventory/inventory' render={(params)=>(<div><InventoryIntermediate {...this.props} {...params}/></div>)} />
                         <Route  path='/home/inventory/newinventory' render={(params)=>(<div><Inventory {...this.props} {...params}/></div>)} />
                         <Route exact path='/home/inventory/inventorylist' render={(params)=>(<div><InventoryList {...this.props} {...params}/></div>)} />
                         <Route exact path='/home/inventory/intransit' render={(params)=>(<div><InventoryList {...this.props} {...params}/></div>)} />
                         <Route exact path='/home/inventory/viewinventory/:key' render={(params)=>(<div><ViewInventory {...this.props} {...params}/></div>)} />
                         <Route  path='/home/inventory/editinventory' render={(params)=>(<div><Inventory {...this.props} {...params}/></div>)} />
                        
                         
                         {/* <Route exact path='/home/sales/newsales/payment' render={()=>(<div><Payment {...this.props}/></div>)} /> */}
                         {/* <Route exact path='/home/sales' render={()=>(<div><SalesList {...this.props}/></div>)} /> */}
                         <Route exact path='/home/sales/saleslist' render={(params)=>(<div><BillWiseDetail {...this.props} {...params}/></div>)} />
                         <Route exact path='/home/sales/viewsales/:key' render={(params)=>(<div><ViewSales {...this.props} {...params}/></div>)} />
                         <Route exact path='/home/sales/editsales/' render={(params)=>(<div><Sales {...this.props} {...params}/></div>)} />
                         <Route exact path='/home/sales/salesreturn/' render={(params)=>(<div><Sales {...this.props} {...params}/></div>)} />
                         <Route exact path='/home/sales/print/' render={(params)=>(<div><Print {...this.props} {...params}/></div>)} />
                         <Route  path='/home/sales/deliverylist' render={(params)=>(<div><DeliveryList {...this.props} {...params}/></div>)} />

                         <Route exact path='/home/sales/cashregisterclosing/' render={(params)=>(<div><CashRegisterClosing {...this.props} {...params}/></div>)} />
                         <Route exact path='/home/sales/cashregisterclosinglist/' render={(params)=>(<div><CashRegisterClosingList {...this.props} {...params}/></div>)} />

                         <Route exact path='/home/settings/stores/' render={(params)=>(<div><StoresList {...this.props} {...params}/></div>)} />
                         <Route exact path='/home/settings/stores/newstore/' render={(params)=>(<div><AddEditStore {...this.props} {...params}/></div>)} />
                         <Route exact path='/home/settings/stores/editstore/' render={(params)=>(<div><AddEditStore {...this.props} {...params}/></div>)} />
                         <Route exact path='/home/settings/stores/viewstoredetails/' render={(params)=>(<div><ViewStoreDetails {...this.props} {...params}/></div>)} />

                         <Route exact path='/home/settings/employees/' render={(params)=>(<div><EmployeeList {...this.props} {...params}/></div>)} />
                         <Route exact path='/home/settings/employees/newemployee' render={(params)=>(<div><AddEditEmployee {...this.props} {...params}/></div>)} />
                         <Route exact path='/home/settings/employees/editemployee' render={(params)=>(<div><AddEditEmployee {...this.props} {...params}/></div>)} />
                         <Route exact path='/home/settings/employees/viewemployeedetails' render={(params)=>(<div><ViewEmployeeDetails {...this.props} {...params}/></div>)} />

                         <Route exact path='/home/settings/customfields' render={(params)=>(<div><CustomFields {...this.props} {...params}/></div>)} />
                         <Route exact path='/home/settings/paymenttypes' render={(params)=>(<div><PaymentTypes {...this.props} {...params}/></div>)} />
                         
                         <Route exact path='/home/catalogue/brands' render={(params)=>(<div><ProductBrandList {...this.props} {...params}/></div>)} />
                         <Route exact path='/home/catalogue/taxes' render={(params)=>(<div><ProductTaxList {...this.props} {...params}/></div>)} />
                         <Route exact path='/home/catalogue/category' render={(params)=>(<div><ProductCategoriesList {...this.props} {...params}/></div>)} />
                         <Route exact path='/home/catalogue/subcategory' render={(params)=>(<div><ProductCategoriesList {...this.props} {...params}/></div>)} />
                         
                         <Route exact path='/home/settings/stores/printconfig' render={(params)=>(<div><PrintConfigList {...this.props} {...params}/></div>)} />
                         <Route exact path='/home/settings/stores/printconfig/newprintconfig' render={(params)=>(<div><PrintConfig {...this.props} {...params}/></div>)} />
                         <Route exact path='/home/settings/stores/printconfig/editprintconfig' render={(params)=>(<div><PrintConfig {...this.props} {...params}/></div>)} />

                         <Route exact path='/home/settings/moduleconfig' render={(params)=>(<div><TransactionTypeList {...this.props} {...params}/></div>)} />

                         <Route exact path='/home/settings/moduleconfig/new' render={(params)=>(<div><AddEditTransactionType  {...this.props} {...params}/></div>)} />
                         <Route exact path='/home/settings/moduleconfig/edit' render={(params)=>(<div><AddEditTransactionType {...this.props} {...params}/></div>)} />

                        <Route exact path='/home/settings/vendors/' render={(params)=>(<div><VendorList {...this.props} {...params}/></div>)}/>
                        <Route exact path='/home/settings/vendors/newvendor' render={(params)=>(<div><AddEditVendor {...this.props} {...params}/></div>)} />
                        <Route exact path='/home/settings/vendors/edit/' render={(params)=>(<div><AddEditVendor {...this.props} {...params}/></div>)} />
                        
                        <Route exact path='/home/expenselist' render={()=>(<div><ExpenseList {...this.props}/></div>)} /> 
                        <Route exact path='/home/expense' render={()=>(<div><Expense {...this.props}/></div>)} />
                        <Route exact path='/home/expensecategorieslist' render={()=>(<div><ExpenseCategoriesList {...this.props}/></div>)} />
                        {/* <Route exact path='/home/expensepayment/' render={()=>(<div><ExpensePayment {...this.props}/></div>)}/> */}

                        {/* <Route exact path='/home/expenselist' render={()=>(<div><ExpenseList {...this.props}/></div>)} /> 
                        <Route exact path='/home/expense' render={()=>(<div><Expense {...this.props}/></div>)} />  */}
                        
                        {/* <Route exact path='/home/reports/salesdashboard' render={(params)=>(<div><SalesDashboard {...this.props} {...params}/></div>)} />
                        <Route exact path='/home/reports/billwisedetail' render={(params)=>(<div><BillWiseDetail {...this.props} {...params}/></div>)} />
                        <Route exact path='/home/reports/invoicedetail' render={(params)=>(<div><InvoiceDetail {...this.props} {...params}/></div>)} />
                        <Route exact path='/home/reports/itemsolddetail' render={(params)=>(<div><ItemSoldReport {...this.props} {...params}/></div>)} />
                        <Route exact path='/home/reports/employeeitemdetai' render={(params)=>(<div><EmployeeItemDetail {...this.props} {...params}/></div>)} />
                        <Route exact path='/home/settings/expense/' render={(params)=>(<div><ExpenseCategoriesList {...this.props} {...params}/></div>)} /> */}
                        
                        <Route exact path='/home/reports/salesdashboard' render={(params)=>(<div><SalesDashboard {...this.props} {...params}/></div>)} />
                        <Route exact path='/home/reports/billwisedetail' render={(params)=>(<div><BillWiseDetail {...this.props} {...params}/></div>)} />
                        <Route exact path='/home/reports/invoicedetail' render={(params)=>(<div><InvoiceDetail {...this.props} {...params}/></div>)} />
                        <Route exact path='/home/reports/itemsolddetail' render={(params)=>(<div><ItemSoldReport {...this.props} {...params}/></div>)} />
                        <Route exact path='/home/reports/employeeitemdetai' render={(params)=>(<div><EmployeeItemDetail {...this.props} {...params}/></div>)} />
                        <Route exact path='/home/reports/categorywise' render={(params)=>(<div><CategoryWiseDetail {...this.props} {...params}/></div>)} />
                        <Route exact path='/home/reports/brandwise' render={(params)=>(<div><BrandWiseDetail {...this.props} {...params}/></div>)} />
                        <Route exact path='/home/reports/subcategorywise' render={(params)=>(<div><SubCategoryWiseDetail {...this.props} {...params}/></div>)} />
                        <Route exact path='/home/reports/producttypewise' render={(params)=>(<div><ProductTypeWise {...this.props} {...params}/></div>)} /> 
                        <Route exact path='/home/reports/allreport' render={(params)=>(<div><AllReport {...this.props} {...params}/></div>)} />
                        <Route exact path='/home/reports/employeedashboard' render={(params)=>(<div><EmployeeDashboard {...this.props} {...params}/></div>)} />
                        <Route exact path='/home/reports/customereferencedetail' render={(params)=>(<div><CustomerReferenceDetail {...this.props} {...params}/></div>)} />
                        
                        <Route exact path='/home/membership' render={() =>(<div><Memberships {...this.props}/></div>)}/>
                        <Route exact path='/home/memberships/newmembership' render={()=>(<div><NewMembership {...this.props}/></div>)}/>
                        <Route exact path='/home/memberships/edit/:key' render={(params)=>(<div><NewMembership {...this.props} {...params}/></div>)} />
                        <Route exact path='/home/attendancelist' render={()=>(<div><AttendanceList {...this.props}/></div>)} />
                  
                        <Route exact path= '/home/reports/expenseListreport' render={(params)=>(<div><ExpenseListReport {...this.props} {...params}/></div>)} />
                        <Route exact path= '/home/reports/expenseByExpenseCategoryreport' render={(params)=>(<div><ExpenseByExpenseCategoryReport {...this.props} {...params}/></div>)} />
                        <Route exact path= '/home/reports/expenseByPartyreport' render={(params)=>(<div><ExpenseByPartyReport {...this.props} {...params}/></div>)} />
                  
                        <Route exact path='/home/reports/Instock' render={(params)=>(<div><Instock {...this.props} {...params}/></div>)} />
                        <Route exact path='/home/reports/ItemLedger' render={(params)=>(<div><ItemLedger {...this.props} {...params}/></div>)} />
                        
                        <Route exact path='/home/reports/dailysales' render={(params)=>(<div><DailySalesReport {...this.props} {...params}/></div>)} />
                        <Route exact path='/home/reports/monthlysales' render={(params)=>(<div><MonthlySalesReport {...this.props} {...params}/></div>)} />
                        <Route exact path='/home/reports/paymentmodewise' render={(params)=>(<div><PaymentModeWise {...this.props} {...params}/></div>)} />

                        <Route exact path='/home/employeeworksheet' render={(params)=>(<div><EmployeeWorkSheet {...this.props} {...params}/></div>)} />
                        
                        <Route exact path='/home/settings/onBoarding' render={(params)=>(<div><OnBoarding {...this.props} {...params}/></div>)} />
                        
                        <Route exact path='/home/reports/paymentmodetransaction' render={(params)=>(<div><PaymentModeTransactionTypeReport {...this.props} {...params}/></div>)} />
                        <Route exact path='/home/reports/gstr1' render={(params)=>(<div><InvoiceDetail1 {...this.props} {...params}/></div>)} />

                        <Route exact path='/home/report/inventoryreports' render={(params)=>(<div><AllInventoryReport {...this.props} {...params}/></div>)} />

                        <Route exact path='/home/reports/customerwalletledger' render={(params)=>(<div><CustomerWalletLedgerReport {...this.props} {...params}/></div>)} />
                        <Route exact path='/home/reports/customeroutstandingledger' render={(params)=>(<div><CustomerOutstandingLedgerReport {...this.props} {...params}/></div>)} />
                        <Route exact path='/home/reports/walletledger' render={(params)=>(<div><WalletLedger {...this.props} {...params}/></div>)} />
                        <Route exact path='/home/reports/outstandingledger' render={(params)=>(<div><OutStandingLedger {...this.props} {...params}/></div>)} />
                        <Route exact path='/home/reports/uniqueno' render={(params)=>(<div><UniqueNoReport {...this.props} {...params}/></div>)} />
                        <Route exact path='/home/reports/uniquenoledger' render={(params)=>(<div><UniqueNoLedger {...this.props} {...params}/></div>)} />

                    </AuthenticatedComponent> 

                </div>      
    }
}

export default Main;
