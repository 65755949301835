import firebase, {database} from '../firebase'
// import * as actionTypes from '../actionTypes'
import * as constants from '../Constatnts'
import { isNullOrUndefined } from 'util';
import * as actionTypes from '../actionTypes'

export function addCustomer(customer){
    return (dispatch, getState) => {
      let newCustomerDoc = database.collection('Customers').doc()
      // console.log('New Customer ID', newCustomerDoc.id);
      customer.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
      customer.ActionBy = getState().user.user.Name
      customer.ActionByUID = getState().user.user.UID
      customer.ActionByEmailID = getState().user.user.EmailID
      customer.CreatedOn = firebase.firestore.FieldValue.serverTimestamp()
      customer.Action = constants.AUDIT_TRAIL_NEW

      newCustomerDoc.set(customer)
      return newCustomerDoc.id;
    }
}

export function getCustomers(RegistrationID, StoreID, from, size, option, searchText, searchCity,
  searchState, searchPinCode, searchCountry, searchMembership, searchWalletFrom, searchWalletTo,
  customField1, customField2, customField3, customField4, customField5, customField6) {
  return (dispatch) => {
    let searchParameters = {
      "from": from,
      "size": size,
      "query": {
        "bool": {
          "must": [
            { "match": { "RegistrationID": RegistrationID } }
            , {
              "match": {
                "StoreID": StoreID
              }
            }
          ],
          "must_not": [
          ],
          "should": [],
          "minimum_should_match": 1,
          "filter": {
            "bool": {
              "must": []
            }
          }
        }
      },
      "sort": [
        {
          "ActionOn": {
            "order": "desc"
          }
        }
      ]
    }
    if (option === "active") {
      searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "true" } })
    }
    if (option === "inactive") {
      searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "false" } })
    }
    searchParameters.query.bool.should.push({ "regexp": { "CustomerDetails": "@" + searchText + ".*" } })
    searchParameters.query.bool.should.push({ "match": { "CustomerDetails": { "query": searchText, "operator": "and" } } })
    searchParameters.query.bool.should.push({ "regexp": { "EmailID": "@" + searchText.trim().toLowerCase() + ".*" } })
    searchParameters.query.bool.should.push({ "regexp": { "PhoneNo": "@" + searchText.trim().toLowerCase() + ".*" } })
    if (searchCity.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Addresses.City": "@" + searchCity.trim().toLowerCase() + ".*" } })
    }
    if (searchState.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Addresses.State": "@" + searchState.trim().toLowerCase() + ".*" } })
    }
    if (searchPinCode.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Addresses.PostalCode": "@" + searchPinCode.trim().toLowerCase() + ".*" } })
    }
    if (searchCountry.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Addresses.Country": "@" + searchCountry.trim().toLowerCase() + ".*" } })
    }
    if (searchMembership.trim() !== ""){
      searchParameters.query.bool.filter.bool.must.push({"regexp": {"Membership.Name" : "@" + searchMembership.trim().toLowerCase() + ".*"} })
    }    
    if (searchWalletFrom !== "") {
      searchParameters.query.bool.filter.bool.must.push({"range": {"WalletBalance": {"gte": searchWalletFrom} }})
    }
    if (searchWalletTo !== "") {
      searchParameters.query.bool.filter.bool.must.push({"range": { "WalletBalance": {"lte": searchWalletTo} }})
    }    
    if (customField1.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField1": "@" + customField1.trim().toLowerCase() + ".*" } })
    }
    if (customField2.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField2": "@" + customField2.trim().toLowerCase() + ".*" } })
    }
    if (customField3.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField3": "@" + customField3.trim().toLowerCase() + ".*" } })
    }
    if (customField4.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField4": "@" + customField4.trim().toLowerCase() + ".*" } })
    }
    if (customField5.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField5": "@" + customField5.trim().toLowerCase() + ".*" } })
    }
    if (customField6.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CustomField6": "@" + customField6.trim().toLowerCase() + ".*" } })
    }
    console.log('searchParameters',searchParameters)
    let addMessage = firebase.functions().httpsCallable('getCustomers');
    return addMessage({ text: searchParameters }).then(function (result) {
      const customers = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        customers.push({ key: data._id, ...data._source })
      })
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: customers }
    });
  }
}

export function searchCustomer(RegistrationID, StoreID, searchText) {
  return (dispatch, getState) => {
    let searchParameters = {
      "from": 0,
      "size": 50,
      "query": {
        "bool": {
          "must": [
            { "match": { "RegistrationID": RegistrationID } },
            { "match": { "IsDeleted": "false" } }
          ],
          "should": [
            { "match": { "CustomerDetails": { "query": searchText, "operator": "and" } } },
            { "regexp": { "CustomerDetails": "@" + searchText + ".*" } }
          ], "minimum_should_match": 1
        }
      }
    }
    if (getState().registration.registration.hasOwnProperty('GlobalCustomer')) {
      if (!getState().registration.registration.GlobalCustomer) {
        searchParameters.query.bool.must.push({ "match": { "StoreID": StoreID } })
      }
    }
    // console.log("Search Customer", JSON.stringify(searchParameters))
    let addMessage = firebase.functions().httpsCallable('getCustomers');
    return addMessage({ text: searchParameters }).then(function (result) {
      const customers = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        customers.push({ key: data._id, ...data._source })
      })
      // console.log("Search Customer", JSON.stringify(customers))
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: customers }
    });
  }
}

export  function getCustomerDetails(key){
    return (dispatch) => {
      return database.collection('Customers').doc(key).get().then((doc) => {
        if (doc.exists) {
          console.log('Customer found', doc.data());
          return doc.data()
        } else {
          console.log('No such customer!');
        }
      })
    }
}

export function updateCustomer(customer, key){
    return (dispatch, getState) => {
      customer.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
      customer.ActionBy = getState().user.user.Name
      customer.ActionByUID = getState().user.user.UID
      customer.ActionByEmailID = getState().user.user.EmailID

      customer.Action = constants.AUDIT_TRAIL_EDIT
        return database.collection('Customers').doc(key).update({
          ...customer
        })
    }
}

export function deleteCustomer(key){
    return (dispatch, getState) => {
        return database.collection('Customers').doc(key).update({
          IsDeleted:true,
          ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
            ActionBy: getState().user.user.Name,
            ActionByUID: getState().user.user.UID,
            ActionByEmailID:getState().user.user.EmailID,

            Action: constants.AUDIT_TRAIL_DELETE
        }).then(() => { return true })
        
    }
}

export function getCustomerWalletBalance(key) {
  return (dispatch) => {
    return database.collection('Customers').doc(key).get().then((doc) => {
      if (doc.exists) {
        //console.log('Customer found', doc.data());
        let walletBalance = 0
        if (doc.data().WalletBalance) { walletBalance = doc.data().WalletBalance }
        return walletBalance
      } else {
        console.log('No such customer!');
      }
    })
  }
}

export function getCustomerDashboardHeaders(customerID) {
  let searchParameters = {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          {
            "match": {
              "Customer.key.keyword": customerID
            }
          }
        ],
        "must_not": [
          {
            "exists": {
              "field": "CancellationReason"
            }
          }
        ]
      }
    },
    "aggs": {
      "total_spent": {
        "sum": {
          "field": "BillAmount"
        }
      },
      "total_qty": {
        "sum": {
          "field": "BillQty"
        }
      },
      "total_transactions": {
        "cardinality": {
          "field": "_id"
        }
      }
    }
  };
  return (dispatch) => {
    // console.log("Get Customer Dashboard Header - Query", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable('getSales');
    return addMessage({ text: searchParameters }).then(function (result) {
      const header = {};
      let sanitizedMessage = result.data.text;
      // console.log("Customer Dashboard Header Data", JSON.stringify(sanitizedMessage));
      header.total_spent = sanitizedMessage.aggregations.total_spent.value;
      header.total_transactions = sanitizedMessage.aggregations.total_transactions.value;
      header.total_qty = sanitizedMessage.aggregations.total_qty.value;
      // console.log("Customer Dashboard Header Data", JSON.stringify(header));
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: header }
    });
  }
};

export function getWalletLedgersByCustomerID(customerID) {
  let searchParameters = { 
    "query": {
      "match": {
        "CustomerID.keyword": customerID
      }
    },"sort": [
      {
        "TransactionDate": {
          "order": "desc"
        }
      }
    ] 
  };
  return (dispatch) => {
    // console.log("Get Wallet Details - Query", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable('getWalletLedgers');
    return addMessage({ text: searchParameters }).then(function (result) {
      const walletLedgers = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        walletLedgers.push({ key: data._id, ...data._source });
      });
      // console.log("Customer Dashboard Header Data", JSON.stringify(walletLedgers));
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: walletLedgers }
    });
  }
};

export function getCustomerTransactions(customerID) {
  let searchParameters = { 
    "size": 10000,
    "query": {
      "bool": {
        "must": [
          {
            "match": {
              "Customer.key.keyword": customerID
            }
          }
        ]
      }
    },
    "sort": [
      {
        "TransactionDate": {
          "order": "desc"
        }
      }
    ]
  };
  return (dispatch) => {
    // console.log("Get Customer Transactions - Query", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable('getSales');
    return addMessage({ text: searchParameters }).then(function (result) {
      const transactions = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        transactions.push({ key: data._id, ...data._source });
      });
      // console.log("Customer Transaction Details", JSON.stringify(transactions));
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: transactions }
    });
  }
};

export function checkIfCustomerCodeIsUnique(customercode, customerID, registrationID) {
  return (dispatch) => {
    if(customercode.trim()==="")
    {
      return true 
    }
      let searchParameters = {
          "from": 0,
          "size": 1,
          "query": {
              "bool": {
                  "must": [
                      { "term": { "RegistrationID": registrationID } },
                      { "term": { "IsDeleted": "false" } },
                      { "term": { "CustomerCode": customercode.trim().toLowerCase() } }
                  ],
                  "must_not": [
                  ]
              }
          }
      }
      if (!isNullOrUndefined(customerID) && customerID !== "") {
          searchParameters.query.bool.must_not.push({ "match": { "_id": customerID } })
      }
      // console.log('customer searchParameters', JSON.stringify(searchParameters));
      let addMessage = firebase.functions().httpsCallable('getCustomers');
      return addMessage({ text: searchParameters }).then(function (result) {
          let sanitizedMessage = result.data.text
          let isUnique = true;
          if (sanitizedMessage.hits.total > 0) {
              isUnique = false
          }
          // console.log('isUnique', isUnique)
          return isUnique
      });
  }
}

export function checkIfCustomerPhoneNoIsUnique(phoneNo, customerID) {
  return (dispatch, getState) => {
    
    let searchParameters = {
      "from": 0,
      "size": 1,
      "query": {
        "bool": {
          "must": [
            { "term": { "IsDeleted": "false" } },
            { "term": { "PhoneNo": phoneNo.trim() } }
          ],
          "must_not": [
          ]
        }
      }
    }

    if(!getState().registration.registration.hasOwnProperty('GlobalCustomer') || getState().registration.registration.GlobalCustomer)
    {
      searchParameters.query.bool.must.push({ "term": { "RegistrationID": getState().user.user.RegistrationID } })
    }
    else
    {
      searchParameters.query.bool.must.push({ "match": { "StoreID": getState().user.store.key } })
    }

    if (!isNullOrUndefined(customerID) && customerID !== "") {
      searchParameters.query.bool.must_not.push({ "match": { "_id": customerID } })
    }

    // console.log('customer searchParameters', JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable('getCustomers');
    return addMessage({ text: searchParameters }).then(function (result) {
      let sanitizedMessage = result.data.text
      let isUnique = true;
      if (sanitizedMessage.hits.total > 0) {
        isUnique = false
      }
      return isUnique
    });
  }
}

export function checkIfCustomerStoreMappingExists(saleToPurchaseStoreID, customerID) {
  return (dispatch, getState) => {
    let searchParameters = {
      "from": 0,
      "size": 1,
      "query": {
        "bool": {
          "must": [
            { "term": { "IsDeleted": "false" } },
            { "term": { "SaleToPurchaseStoreID.keyword": saleToPurchaseStoreID.trim() } }
          ],
          "must_not": [
          ]
        }
      }
    }

    if(!getState().registration.registration.hasOwnProperty('GlobalCustomer') || getState().registration.registration.GlobalCustomer)
    {
      searchParameters.query.bool.must.push({ "term": { "RegistrationID": getState().user.user.RegistrationID } })
    }
    else
    {
      searchParameters.query.bool.must.push({ "match": { "StoreID": getState().user.store.key } })
    }

    if (!isNullOrUndefined(customerID) && customerID !== "") {
      searchParameters.query.bool.must_not.push({ "match": { "_id": customerID } })
    }
    console.log('customer searchParameters', JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable('getCustomers');
    return addMessage({ text: searchParameters }).then(function (result) {
      let sanitizedMessage = result.data.text
      let isExists  = false;
      if (sanitizedMessage.hits.total > 0) {
        isExists = true
      }
      return isExists
    });
  }
}

export function initialiseCustomers(registrationID) {
  return (dispatch) => {
      return database.collection("Customers").where("RegistrationID","==",registrationID).limit(1).get()
  }
}

export function pushCustomersToRedux(customers) {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_ACTIVE_CUSTOMERS, customers })
  }

}

export function pushCustomerToRedux(customer) {
  return (dispatch) => {
    dispatch({ type: actionTypes.PUSH_CUSTOMER_TO_REDUX, customer })
  }
}

export function getActiveCustomers(registrationID, scrollID) {
  let searchParameters = null
  if (scrollID == "") {
    searchParameters = {
      "from": 0,
      "size": 2000,
      "query": {
        "bool": {
          "must": [
            { "match": { "RegistrationID": registrationID } }
          ],
          "must_not": [
            { "match": { "IsDeleted": "true" } }
          ]
        }
      }
    }
  }
  else {
    searchParameters = {
      "scroll": "1m",
      "scroll_id": scrollID
    }
  }

  return (dispatch) => {
    let addMessage = ''
    if (scrollID === "") {
      addMessage = firebase.functions().httpsCallable('getCustomersWithScrollID');
    }
    else {
      addMessage = firebase.functions().httpsCallable('getCustomersForScrollID');
    }

    return addMessage({ text: searchParameters }).then(function (result) {
      const products = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        products.push({ key: data._id, ...data._source })
      })
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: products, scrollID: sanitizedMessage._scroll_id }
    });

  }
}