import React, { Component } from 'react'
import {Link } from 'react-router-dom'
import Confirm from 'react-confirm-bootstrap'
import { withRouter } from 'react-router-dom'


class PrintConfigList extends Component{
    constructor () {
        super()
        this.state={
            //printConfigs:[],
            storeID:'',
            storeName:'',
            storeCode:''
        }
        this.onPrintConfigDelete = this.onPrintConfigDelete.bind(this)
        this.loadPrintConfig= this.loadPrintConfig.bind(this)
    }

    componentDidMount()
    {
        this.setState({storeID: this.props.location.state.storeID,
            storeName: this.props.location.state.storeName,
            storeCode: this.props.location.state.storeCode
        })
        this.loadPrintConfig(this.props.location.state.storeID)
    }
    
    onPrintConfigDelete(key){
        this.props.deletePrintConfig(key).then(()=> {
            this.loadPrintConfig(this.state.storeID)
       })
    }
    
    loadPrintConfig(storeID){
        //let stores=[]
        this.props.getPrintConfigs(this.props.user.user.RegistrationID, storeID)
        
    }

    render() {    
        const btnColStyle = {width:"120px"}
        
        return <div className="content-wrapper" >
        <section className="content-header">
            <h1>Print Config - {this.state.storeCode} {this.state.storeName}</h1>
        </section>   
        <section className="content" >
            <div className="row">
                <div className="col-md-12">
                    <div className="box ">
                        <div className="box-header with-border">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="pull-right">
                                        <Link  to={{pathname:"/home/settings/stores/printconfig/newprintconfig",state:{storeID:this.state.storeID,storeCode: this.state.storeCode, storeName:this.state.storeName, printConfig:null}}} 
                                        className="btn btn-flat btn-success btn-md" > New Print Config</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box-body">
                                <table className = "table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Print Display Name</th>
                                        <th>Module</th>
                                        <th>Transaction Type</th>
                                        <th>Printer</th>
                                        <th style={btnColStyle}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.config.PrintConfigs.map((printConfig) =>
                                    <tr>
                                        <td>{printConfig.PrintDisplayName}</td>
                                        <td>{printConfig.Module}</td>
                                        <td>{printConfig.TransactionType}</td>
                                        <td>{printConfig.PrintSize}</td>
                                        <td>
                                            <div class="btn-group">
                                            { printConfig.IsDeleted ? <span class="label label-danger pull-right">Inactive</span> : 
                                                <div>
                                                    <Link to={{pathname:"/home/settings/stores/printconfig/editprintconfig", state:{storeID:this.state.storeID,
                                                    storeCode: this.state.storeCode, 
                                                    storeName:this.state.storeName, 
                                                    printConfig: printConfig}}}  
                                                    type="button" className="btn btn-md btn-flat btn-default "><i class="fa fa-edit"></i></Link>                                            
                                                    <Confirm
                                                        onConfirm={() => this.onPrintConfigDelete(printConfig.key)}
                                                        body="Are you sure you would like to delete this print config?"
                                                        confirmText="Yes"
                                                        title="Print Config Delete">
                                                        <button className="btn btn-md bnt-flat btn-default"><i class="fa fa-trash-o"></i></button>
                                                    </Confirm>
                                                </div>    
                                            }
                                            </div>
                                        </td>
                                     </tr>
                                    )}                                            
                                </tbody>
                                </table>
                        </div>   
                    </div>
                </div>
                </div>
                </section>
                </div>   
    }
}

export default withRouter(PrintConfigList)