import * as actionTypes from '../actionTypes'
import {database} from '../firebase'

export function savePrintConfig(printConfig, key){
    return dispatch =>{
        if(key.trim()!== "")
        {
            return database.collection("PrintConfigs").doc(key).update({
                ...printConfig
              }).then(()=>{
                return key
              }).catch(function(error) {
                  console.error("Error updating print config: ", error);
              })
        }
        else
        {
            return database.collection("PrintConfigs").add({
                ...printConfig
              })
              .then(function(docRef) {
                  return docRef.id
              })
              .catch(function(error) {
                  console.error("Error adding print config: ", error);
              })
        }
    }
}

export function deletePrintConfig(key)
{
    return (dispatch) => {
        return database.collection("PrintConfigs").doc(key).update({
            IsDeleted : true
        }).then(()=> {return true})
    }
}

export function getPrintConfigs(registrationID, storeID) {
    return (dispatch) => {
        return database.collection("PrintConfigs").where("RegistrationID","==", registrationID).where("StoreID","==",storeID).get().then((querySnapshot) => {
            const printConfigs = []
            querySnapshot.forEach((doc) => {
                printConfigs.push(
                  {
                    key:doc.id,
                    ...doc.data()
                  })
           })
           //console.log(productCategories)
           dispatch({type: actionTypes.PRINT_CONFIG_GET,printConfigs})
           //return printConfigs
        }).catch((error) => {
            console.log(error)
        })
    }
}


export function getPrintConfigByID(key) {
    return (dispatch) => {
        return database.collection("PrintConfigs").doc(key).get().then((doc) => {
            console.log(doc.data())
            return doc.data()
        });
    }
}

