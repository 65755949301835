import React, { Component } from 'react'
import Pagination from 'react-js-pagination'
import Loader from '../Loader'
import * as constants from '../../Constatnts'
import AuditTrail from '../AuditTrail'
import {Link } from 'react-router-dom';
import Confirm from 'react-confirm-bootstrap'

class CashRegisterClosingList extends Component {

    constructor() {
        super()
        this.state = {
            searchCashRegister:'',
            searchOpenClosed:'Open',
            cashRegisterClosingList:[],
            cashRegisterList:[]
        }
        this.onOpenClosedClicked = this.onOpenClosedClicked.bind(this)
        this.loadData= this.loadData.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.onClearSearch = this.onClearSearch.bind(this)
    }

    async componentDidMount()
    {
        let cashRegisterList = await this.props.getCashRegisters(this.props.user.store.key)
        this.setState({ cashRegisterList: cashRegisterList})
        this.loadData(this.state.searchOpenClosed,this.state.searchCashRegister )
    }
    
    onOpenClosedClicked(status){
        this.loadData(status,this.state.searchCashRegister )
    }

    onSearchClick()
    {
        this.loadData(this.state.searchOpenClosed,this.state.searchCashRegister )
    }

    onClearSearch(){
        this.loadData("Open","")
    }

    async loadData(status, cashRegister)
    {
        let cashRegisterClosingList =  await this.props.getCashRegisterClosingList(this.props.user.store.key, cashRegister, status)
        var sortedList = cashRegisterClosingList.sort((a, b) => {
            return new Date(a.OpeningTime.seconds*1000).getTime() - 
                new Date(b.OpeningTime.seconds*1000).getTime()
        }).reverse();
        this.setState({cashRegisterClosingList:sortedList, searchOpenClosed:status, searchCashRegister: cashRegister})
        // console.log('cashRegisterList',cashRegisterClosingList)
        // this.setState({cashRegisterClosingList:cashRegisterClosingList, searchOpenClosed:status, searchCashRegister: cashRegister})
       
    }

    render() {    
        const revisionHistory = { width: "200px" }
        return <div className="content-wrapper" ><section className="content" >
            <div className="row">
                <div className="col-md-12">
                    <div className="box ">
                        <div className="box-header with-border">
                            <div className="row">
                                <div className="col-md-6">
                                    <font style={{fontSize:"30px"}}>Cash Register Closing List</font>
                                </div>
                            </div>
                        </div>
                        <div className="box-body">
                            <div className="row">
                                <div className="col-md-12" style={{marginBottom:"10px"}}>
                                    <div className="btn-group">
                                       <button type="button" className={this.state.searchOpenClosed === "Open" ?  "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md" }
                                       onClick={()=> this.onOpenClosedClicked("Open")}>Open</button>
                                       <button type="button" className={this.state.searchOpenClosed === "Closed" ?  "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md" }
                                       onClick={()=> this.onOpenClosedClicked("Closed")}>Closed</button>
                                       {/* <button type="button" className={this.state.searchActiveInactiveAllRecords === "all" ?  "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md" }
                                       onClick={()=> this.onSearchActiveInactiveAllRecordsClicked("all")}>All</button> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="form-group">
                                        <label>Cash Register</label>
                                        <select type="text" className="form-control"
                                                onChange={(evt) => this.setState({ searchCashRegister: evt.target.value })}
                                                value={this.state.searchCashRegister}
                                                onKeyPress={this.handleKeyPress}>
                                                <option value="">All</option>
                                                {this.state.cashRegisterList.map((cashRegister) =>
                                                    <option value={cashRegister.key}>{cashRegister.CashRegisterCode}-{cashRegister.CashRegisterName}</option>
                                                )}
                                            </select>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <button type="button" className="btn btn-primary btn-flat btn-md pull-right" 
                                    style={{marginTop:"25px", marginLeft:"5px"}}
                                    onClick={this.onSearchClick}
                                    >Search</button>
                                    <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{marginTop:"25px"}} 
                                    onClick={this.onClearSearch} >Clear</button>
                                </div>
                            </div>
                           
                            {this.state.loading && <Loader show={this.state.loading} />}

                            {!this.state.loading &&
                                <table className = "table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Cash Register </th>
                                        <th>Opening Details</th>
                                        <th>Closing Details</th>
                                        <th style={revisionHistory}>Payment Details</th>
                                        <th >Status</th>
                                        <th style={revisionHistory}>Revision History</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.cashRegisterClosingList.map((closing) =>
                                    <tr>
                                        <td>{closing.CashRegisterCode + ' ' + closing.CashRegisterName}
                                        </td>
                                        <td>Opening Time: {(new Date(closing.OpeningTime.seconds*1000)).toString().substring(0,24)}
                                            <div>Opening Balance: {closing.OpeningBalance}</div>
                                            <div>Opening By: {closing.OpeningByUserName}</div>
                                        </td>
                                        <td>
                                           {closing.ClosingTime && <div>Closing Time: {(new Date(closing.ClosingTime.seconds*1000)).toString().substring(0,24)}</div>}
                                           {closing.ClosingRemarks && <div>Closing Remarks: {closing.ClosingRemarks}</div>}
                                           {closing.ClosingBy && <div>Closing By: {closing.ClosingByUserName}</div>}
                                        </td>
                                        <td>
                                            {closing.ClosingPaymentModes && <div>
                                            {closing.ClosingPaymentModes.map((paymentMode)=> 
                                                <React.Fragment>
                                                    <div style={{fontSize:"20px"}}>{paymentMode.PaymentMode}</div>
                                                    {paymentMode.Components.map((c) => 
                                                        <div>{c.Name}   <span className="pull-right">{c.Amount}</span></div>  
                                                    )}
                                                <div style={{fontWeight:"bold"}}>Total <span className="pull-right" style={{borderTop:"solid 1px"}}>{paymentMode.Amount}</span></div>  
                                                <br></br>
                                                </React.Fragment>
                                            )}
                                            </div>}
                                            {closing.ClosingTotal && <div className="pull-right" style={{fontSize:"20px",borderTop:"solid 1px"}}>{closing.ClosingTotal}</div>}
                                        </td>
                                        <td>
                                            {closing.Status}
                                        </td>
                                        <td>
                                                        <div>Action :{closing.Action}</div>
                                                        <div>Action By :{closing.ActionBy}</div>                                                        
                                                        <div>Action Date :{(new Date(closing.ActionOn.seconds*1000)).toString().substring(0,24)}</div>
                                                        {/* <AuditTrail {...this.props} module={constants.MODULE_CASH_REGISTER_CLOSING} parentKey={closing.key}/> */}
                                                    </td>
                                        
                                     </tr>
                                    )}                                            
                                </tbody>
                                </table>}
                                {/* {this.state.totalItemsCount > 0 && <div className="pull-left" style={{marginTop:"25px"}}>
                                {this.state.paginationStatus}
                                </div>}
                                <div className="pull-right">
                                    <Pagination
                                    activePage={this.state.activePage} //this.state.activePage
                                    itemsCountPerPage={this.state.itemsCountPerPage}
                                    totalItemsCount={this.state.totalItemsCount}
                                    pageRangeDisplayed={2}
                                    onChange={this.handlePageChange}
                                    />
                                </div> */}
                        </div>   
                    </div>
                </div>
                </div>
                {/* </form> */}
                </section></div>
    }

}
export default  CashRegisterClosingList

