import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import Loader from '../Loader'
// import * as constatnts from '../../Constatnts'
// import { isNullOrUndefined } from 'util';

class InventoryList extends Component {
    constructor() {
        super()
        this.state = {
            inventoryList: [],
            searchText: '',
            searchTransactionNo: '',
            searchTransactiontype: '',
            searchTransactionFromDate: new Date().toISOString().split('T')[0],
            searchTransactionToDate: new Date().toISOString().split('T')[0],
            searchVendorDetail:'',
            activePage: 1,
            itemsCountPerPage: 20,
            totalItemsCount: 0,
            recordFrom: 0,
            title: '',
            paginationStatus: 'No records to display',
            loading: true,
            exportInventoryList: [],
            title:"",
            searchText:""
            // exportCalled: false
        }
        this.onClearSearch = this.onClearSearch.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.showPaginationStatus = this.showPaginationStatus.bind(this)
        // this.onExportClick = this.onExportClick.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.loadList = this.loadList.bind(this)
    }

    componentDidMount() {
        if (this.props.location.state.type === "list") {
            this.setState({ title: "Inventory List" });
        }
        else if (this.props.location.state.type === "intransit") {
            this.setState({ title: "In Transit" });
        }
        
        this.loadList(this.state.recordFrom,
            this.state.searchTransactionNo,
            this.state.searchTransactiontype,
            this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate,
            this.state.searchVendorDetail
        )
    }

    loadList(from, 
        searchTransactionNo, 
        searchTransactiontype, 
        searchTransactionFromDate, 
        searchTransactionToDate,
        searchVendorDetail) {
        this.setState({ loading: true })
        this.props.getInventoryList(this.props.user.store.key, 
            from, this.state.itemsCountPerPage,
            searchTransactionNo, 
            searchTransactiontype,
            searchTransactionFromDate, 
            searchTransactionToDate,
            searchVendorDetail,
            this.props.location.state.type === "intransit" ? this.state.searchText:"" , 
            this.props.location.state.type === "intransit" ? "In Transit":"" )
            .then((result) => {
                this.setState({ inventoryList: result.searchResult, 
                    totalItemsCount: result.totalItemsCount, 
                    loading: false })
                this.showPaginationStatus()
            })
    }

    onClearSearch() {
        this.setState({
            recordFrom: 0, searchTransactionNo: '',
            searchTransactiontype: '', 
            searchTransactionToDate: new Date().toISOString().split('T')[0],
            searchTransactionFromDate: new Date().toISOString().split('T')[0],
            searchVendorDetail: ''
        })
        this.loadList(0, 
            "", 
            "", 
            this.state.searchTransactionFromDate, 
            this.state.searchTransactionToDate, "")
    }

    onSearchClick() {
        this.setState({ searchFrom: 0 })
        this.loadList(
            0,
            this.state.searchTransactionNo,
            this.state.searchTransactiontype,
            this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate,
            this.state.searchVendorDetail)
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }

    handlePageChange(pageNumber) {
        let recordFrom = ((pageNumber - 1) * this.state.itemsCountPerPage)
        this.setState({ activePage: pageNumber, recordFrom: recordFrom });
        this.loadList(
            recordFrom,
            this.state.searchTransactionNo,
            this.state.searchTransactiontype,
            this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate,
            this.state.searchVendorDetail)
    }

    showPaginationStatus() {
        let paginationStatus
        if ((this.state.recordFrom + 1) === this.state.totalItemsCount) {
            paginationStatus = "Showing " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else if ((this.state.recordFrom + this.state.itemsCountPerPage + 1) > this.state.totalItemsCount) {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + (this.state.recordFrom + this.state.itemsCountPerPage) + " of " + this.state.totalItemsCount

        }
        this.setState({ paginationStatus: paginationStatus })
    }

    render() {
        const btnColStyle = { width: "20px" }
        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6">
                                        <font style={{ fontSize: "30px" }}>{this.state.title}</font>
                                    </div>
                                    <div className="col-md-6">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={() => this.props.history.push({ pathname: "/home/reports/allreport" })}
                                        ><i className="fa fa-arrow-left"></i> Back</button>
                                         {/* <Link to={`/home/inventory/viewinventory/GNXR7M3hqlYacM2HDsri`}
                                                                    type="button" class="btn btn-md btn-flat btn-default "><i class="fa fa-edit"></i></Link> */}
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                            {this.props.location.state.type === "list" && <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Transaction No</label>
                                            <input type="text" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionNo: evt.target.value })}
                                                value={this.state.searchTransactionNo}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Transaction Type</label>
                                            <select type="text" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactiontype: evt.target.value })}
                                                value={this.state.searchTransactiontype}
                                                onKeyPress={this.handleKeyPress}>
                                                <option value="">All</option>
                                                {this.props.config.TransactionTypes.filter((e) => e.Module === "inventory" && !e.IsDeleted).map((e,index) => <option key={index} value={e.key}>{e.TransactionType}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>Transaction From</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionFromDate: evt.target.value })}
                                                value={this.state.searchTransactionFromDate}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>Transaction To</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionToDate: evt.target.value })}
                                                value={this.state.searchTransactionToDate}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Vendor Name, Code, Phone Number, Email</label>
                                            <input type="text" className="form-control"
                                                onChange={(evt) => this.setState({ searchVendorDetail: evt.target.value })}
                                                value={this.state.searchVendorDetail}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                </div>}
                                {this.props.location.state.type === "list" &&  <div className="row" >
                                    <div className="col-md-12" style={ {marginTop: "-25px"} }>
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginLeft: "5px" }}
                                            onClick={this.onSearchClick} onKeyPress={this.handleKeyPress}
                                        >Search</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" 
                                            style={{ marginTop: "25px" }}
                                            onClick={this.onClearSearch} 
                                        >Clear</button>
                                    </div>
                                </div>}
                                <br></br>
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading &&
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                {this.state.searchTransactiontype.trim()!=="" && <th></th>}
                                                <th>Transaction No</th>
                                                <th>Transaction Type</th>
                                                <th>Transaction Date</th>
                                                <th>Store Location</th>
                                                <th>Vendor/Store</th>
                                                <th>Qty</th>
                                                <th>Total Tax</th>
                                                <th>Discount</th>
                                                <th>Amount</th>
                                                <th>Status</th>
                                                <th style={btnColStyle} ></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.inventoryList.map((inventory,index) =>
                                                <tr key={index}>
                                                    <td>
                                                        {inventory.TransactionNo}
                                                    </td>
                                                    <td>
                                                    {inventory.TransactionType.TransactionType} 
                                                    <div>
                                                        {inventory.CancellationReason && <span className="badge bg-red">Cancelled </span>}
                                                    </div>
                                                    </td>
                                                    <td>{(new Date(inventory.TransactionDate).toISOString().split('T')[0])}</td>
                                                    <td>
                                                        {inventory.StoreLocation}
                                                    </td>
                                                    <td>
                                                        {inventory.Vendor && <React.Fragment>
                                                            <div>{inventory.Vendor.VendorFirstName} {inventory.Vendor.VendorLastName}</div>
                                                            <div>{inventory.Vendor.VendorCode}</div>
                                                        </React.Fragment>}
                                                        {(inventory.FromStore && inventory.FromStore.trim()!== "")&& <React.Fragment>
                                                            <div>{inventory.FromStore}</div>
                                                         </React.Fragment>}
                                                         {(inventory.ToStore && inventory.ToStore.trim()!== "")&& <React.Fragment>
                                                            <div>{inventory.ToStore}</div>
                                                         </React.Fragment>}
                                                    </td>
                                                    <td>{inventory.BillQty}</td>
                                                    <td>{inventory.BillTaxAmount}</td>
                                                    <td>{inventory.BillDiscount}</td>
                                                    <td>{inventory.BillAmount}</td>
                                                    <td>{inventory.Status}</td>
                                                    <td>
                                                        <div>
                                                            <Link to={`/home/inventory/viewinventory/${inventory.key}`}
                                                                type="button" className="btn btn-md btn-flat btn-default "><i className="fa fa-edit"></i></Link>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>}
                                {this.state.totalItemsCount > 0 && <div className="pull-left" style={{ marginTop: "25px" }}>
                                    {this.state.paginationStatus}
                                </div>}
                                <div className="pull-right">
                                    <Pagination
                                        activePage={this.state.activePage} //this.state.activePage
                                        itemsCountPerPage={this.state.itemsCountPerPage}
                                        totalItemsCount={this.state.totalItemsCount}
                                        pageRangeDisplayed={2}
                                        onChange={this.handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </section>
        </div>
    }
}
export default withRouter(InventoryList)