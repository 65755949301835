import React,{Component} from 'react'
import ReactMustache from 'react-mustache'
import ReactDOM from 'react-dom'
class Print extends Component{

    constructor(){
        super()
        this.state = {
            // sale:null,
            // printConfig:null,
            // template:'',
            // printData:null
            }
    }

    async componentDidMount()
    {
        //let sale = this.props.printData.Sale
        // this.props.printData.Sale.LineItems.map((lineItem, index) => {
        //     this.props.printData.Sale.LineItems[index].SrNo = index +1
        // })
        // this.setState({printData:this.props.printData})
        // console.log('sale', this.props.printData)
        // this.setState({updateState:true})
        let printHeight= 0 
       if(this.props.printData.PrintConfig.PrintSize ==="A4")
       {
        printHeight = 1000
       }
       else if(this.props.printData.PrintConfig.PrintSize ==="A5")
       {
        printHeight = 720
       }
       else{
        printHeight = 0
       }
       if(this.props.printData.hasOwnProperty('Sale'))
       {
        let rowCount =  this.props.printData.Sale.LineItems.length 
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.innerHTML = "var footer = document.getElementById('footer'); if(footer!==null){ var footerHeight = document.getElementById('footer').offsetHeight;var footerTop =document.getElementById('footer').offsetTop;var trheight = " + printHeight + " - (footerTop + footerHeight);document.getElementById('" +  rowCount +  "').style.height = trheight + 'px';  } ";
        this.instance.appendChild(s);
       }
        this.setState({updateState:true})
    }

    async componentDidUpdate()
    {
        // var footerTop = document.getElementById('printtable').offsetTop;
        // alert('footerTop', footerTop)
        // var tableheight = document.getElementById('printtable').offsetTop;
        // alert('tableheight', tableheight)
    //     var top = ReactDOM.findDOMNode("footer")
    //   .getBoundingClientRect().top
    //   alert('top', top)
    // console.log('this.props.printChecked', this.props.printChecked)
        if(this.props.printChecked)
        {
            window.print()
        }
        if(this.props.sendEmailChecked)
        {
            if(this.props.printData.Sale.EmailID.trim()==="")
            {
                return 
            }
            let HtmlBody = ReactDOM.findDOMNode(this).innerHTML
            if(this.props.printData.PrintConfig.PrintSize ==="3 inch" )
            {
                HtmlBody = "<div style='width:300px'>"+ HtmlBody+ "</div>"
            }
            let email={
                RegistrationID:this.props.printData.Sale.RegistrationID,
                StoreID:this.props.printData.Sale.Store.key,
                UserID:this.props.printData.Sale.ActionByUID,
                To:this.props.printData.Sale.EmailID,
                From: "noreply@posible.in",
                Subject:"Invoice #" + this.props.printData.Sale.TransactionNo,
                TextBody:"Invoice",
                HTMLBody:HtmlBody ,
            }
            this.props.sendEmail(email)
            this.props.getToastr("Email sent")

        }
        if(this.props.sendSMSChecked){
            let sms={
                RegistrationID:this.props.printData.Sale.RegistrationID,
                StoreID:this.props.printData.Sale.Store.key,
                UserID:this.props.printData.Sale.ActionByUID,
                Module:"sales",
                TransactionType: this.props.printData.Sale.TransactionType.TransactionType,
                TransactionData: this.props.printData.Sale,
                MobileNo: this.props.printData.Sale.SMSNo
            }
            this.props.sendSMS(sms)
        }
        this.props.onClose()
    }

    render(){
        return <React.Fragment >
            <div ref={el => (this.instance = el)} >
            {this.props.printData !== null && 
                <ReactMustache template={this.props.printData.PrintConfig.PrintTemplate}  data={this.props.printData}/>
            }
            </div>
        </React.Fragment>
    }
}

export default Print