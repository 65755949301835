import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as actionTypes from '../../actionTypes'

class AllReport extends Component {

    constructor() {
        super()
    }

    render() {

        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <font style={{ fontSize: "30px", marginBottom: "4px" }}>Reports</font>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-sm-6 col-xs-12" >
                                <div className="box box-success">
                                    <div className="box-header without-border">
                                        <h3 className="box-title">Sales Reports</h3>
                                    </div>
                                    <div className="box-body">
                                        <table className="table no-margin table-responsive">
                                            <tbody>
                                                <tr style={{ display: (this.props.getVisibility("Sales Dashboard", "read") === "inline" ? "table-row" : "none") }}>
                                                    <td style={{ lineHeight: "3" }}>
                                                        <Link to="/home/reports/salesdashboard" >
                                                            <span>Sales Dashboard</span>
                                                        </Link>
                                                    </td>
                                                </tr>
                                                 <tr style={{ display: (this.props.getVisibility("Daily Sales", "read") === "inline" ? "table-row" : "none") }}>
                                                    <td style={{ lineHeight: "3" }}>
                                                        <Link to="/home/reports/dailysales" >
                                                            <span>Daily Sales</span>
                                                        </Link>
                                                    </td>
                                                </tr>
                                                <tr style={{ display: (this.props.getVisibility("Monthly Sales", "read") === "inline" ? "table-row" : "none") }}>
                                                    <td style={{ lineHeight: "3" }}>
                                                        <Link to="/home/reports/monthlysales" >
                                                            <span>Monthly Sales</span>
                                                        </Link>
                                                    </td>
                                                </tr>
                                                <tr style={{ display: (this.props.getVisibility("Payment Mode Wise Report", "read") === "inline" ? "table-row" : "none") }}>
                                                    <td style={{ lineHeight: "3" }}>
                                                        <Link to="/home/reports/paymentmodewise" >
                                                            <span>Payment Mode Wise Report</span>
                                                        </Link>
                                                    </td>
                                                </tr>
                                                <tr style={{ display: (this.props.getVisibility("Payment Mode Transaction Type Wise Report", "read") === "inline" ? "table-row" : "none") }}>
                                                    <td style={{ lineHeight: "3" }}>
                                                        <Link to="/home/reports/paymentmodetransaction" >
                                                            <span>Payment Mode Transaction Type Wise Report</span>
                                                        </Link>
                                                    </td>
                                                </tr>
                                                <tr style={{ display: (this.props.getVisibility("Transaction Wise", "read") === "inline" ? "table-row" : "none") }}>
                                                    <td style={{ lineHeight: "3" }}>
                                                        <Link to={{ pathname: "/home/reports/billwisedetail", state: { type: "report" } }}>
                                                            <span>Transaction Wise</span>
                                                        </Link>
                                                    </td>
                                                </tr>
                                                <tr style={{ display: (this.props.getVisibility("Invoice Detail Report", "read") === "inline" ? "table-row" : "none") }}>
                                                    <td style={{ lineHeight: "3" }}>
                                                        <Link to="/home/reports/invoicedetail" >
                                                            <span>Invoice Detail Report</span>
                                                        </Link>
                                                    </td>
                                                </tr>
                                                <tr style={{ display: (this.props.getVisibility("GSTR 1 Report", "read") === "inline" ? "table-row" : "none") }}>
                                                    <td style={{ lineHeight: "3" }}>
                                                        <Link to="/home/reports/gstr1" >
                                                            <span>GSTR 1 Report</span>
                                                        </Link>
                                                    </td>
                                                </tr>
                                                <tr style={{ display: (this.props.getVisibility("Employee By Product", "read") === "inline" ? "table-row" : "none") }}>
                                                    <td style={{ lineHeight: "3" }}>
                                                        <Link to="/home/reports/employeeitemdetai" >
                                                            <span>Employee By Product</span>
                                                        </Link>
                                                    </td>
                                                </tr>
                                                <tr style={{ display: (this.props.getVisibility("Product Sold Report", "read") === "inline" ? "table-row" : "none") }}>
                                                    <td style={{ lineHeight: "3" }}>
                                                        <Link to="/home/reports/itemsolddetail" >
                                                            <span>Product Sold Report</span>
                                                        </Link>
                                                    </td>
                                                </tr>
                                                <tr style={{ display: (this.props.getVisibility("Product Type Wise Sold Report", "read") === "inline" ? "table-row" : "none") }}>
                                                    <td style={{ lineHeight: "3" }}>
                                                        <Link to="/home/reports/producttypewise" >
                                                            <span>Product Type Wise Sold Report</span>
                                                        </Link>
                                                    </td>
                                                </tr>
                                                <tr style={{ display: (this.props.getVisibility("Category Wise Sold Report", "read") === "inline" ? "table-row" : "none") }}>
                                                    <td style={{ lineHeight: "3" }}>
                                                        <Link to="/home/reports/categorywise" >
                                                            <span>Category Wise Sold Report</span>
                                                        </Link>
                                                    </td>
                                                </tr>
                                                <tr style={{ display: (this.props.getVisibility("Sub Category Wise Sold Report", "read") === "inline" ? "table-row" : "none") }}>
                                                    <td style={{ lineHeight: "3" }}>
                                                        <Link to="/home/reports/subcategorywise" >
                                                            <span>Sub Category Wise Sold Report</span>
                                                        </Link>
                                                    </td>
                                                </tr>
                                                <tr style={{ display: (this.props.getVisibility("Brand Wise Sold Report", "read") === "inline" ? "table-row" : "none") }}>
                                                    <td style={{ lineHeight: "3" }}>
                                                        <Link to="/home/reports/brandwise" >
                                                            <span>Brand Wise Sold Report</span>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12" >

                                <div className="box box-success">
                                    <div className="box-header without-border">
                                        <h3 className="box-title">Inventory Report</h3>
                                    </div>
                                    <div className="box-body">
                                        <table className="table no-margin table-responsive">
                                            <thead></thead>
                                            <tbody>
                                                <tr style={{ display: (this.props.getVisibility("In Stock", "read") === "inline" ? "table-row" : "none") }}>
                                                    <td style={{ lineHeight: "3" }}>
                                                        <Link to="/home/reports/Instock" >
                                                            <span>In Stock</span>
                                                        </Link>
                                                    </td>
                                                </tr>
                                                <tr style={{ display: (this.props.getVisibility("Unique No", "read") === "inline" ? "table-row" : "none") }}>
                                                    <td style={{ lineHeight: "3" }}>
                                                        <Link to="/home/reports/uniqueno" >
                                                            <span>Unique No</span>
                                                        </Link>
                                                    </td>
                                                </tr>
                                                {this.props.config.TransactionTypes.filter((e) => e.Module === "inventory" && !e.IsDeleted).map((e, index) =>
                                                    <tr key={index} onClick={() => this.setState({ selectedSubMenu: e.TransactionType })}>
                                                        <td style={{ lineHeight: "3" }}>
                                                            <Link to={{ pathname: "/home/report/inventoryreports", state: { transactionType: e } }}>
                                                                <span>{e.TransactionType}</span>
                                                            </Link>
                                                        </td>
                                                    </tr>)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-6 col-xs-12" >

                                <div className="box box-success">
                                    <div className="box-header without-border">
                                        <h3 className="box-title">Employee Reports</h3>
                                    </div>
                                    <div className="box-body">
                                        <table className="table no-margin table-responsive">
                                            <thead></thead>
                                            <tbody>
                                                <tr style={{ display: (this.props.getVisibility("Employee Insight", "read") === "inline" ? "table-row" : "none") }}>
                                                    <td style={{ lineHeight: "3" }}>
                                                        <Link to="/home/reports/employeedashboard" >
                                                            <span>Employee Insight</span>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="box box-success">
                                    <div className="box-header without-border">
                                        <h3 className="box-title">Customer Reports</h3>
                                    </div>
                                    <div className="box-body">
                                        <table className="table no-margin table-responsive">
                                            <thead></thead>
                                            <tbody>
                                                <tr style={{ display: (this.props.getVisibility("Customer Reference Report", "read") === "inline" ? "table-row" : "none") }}>
                                                    <td style={{ lineHeight: "3" }}>
                                                        <Link to="/home/reports/customereferencedetail" >
                                                            <span>Customer Reference Report</span>
                                                        </Link>
                                                    </td>
                                                </tr>
                                                <tr style={{ display: (this.props.getVisibility("Customer Wallet Ledger Report", "read") === "inline" ? "table-row" : "none") }}>
                                                    <td style={{ lineHeight: "3" }}>
                                                        <Link to="/home/reports/customerwalletledger" >
                                                            <span>Customer Wallet Ledger Report</span>
                                                        </Link>
                                                    </td>
                                                </tr>
                                                <tr style={{ display: (this.props.getVisibility("Customer OutStanding Ledger Report", "read") === "inline" ? "table-row" : "none") }}>
                                                    <td style={{ lineHeight: "3" }}>
                                                        <Link to="/home/reports/customeroutstandingledger" >
                                                            <span>Customer OutStanding Ledger Report</span>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="box box-success">
                                    <div className="box-header without-border">
                                        <h3 className="box-title">Expense Reports</h3>
                                    </div>
                                    <div className="box-body">
                                        <table className="table no-margin table-responsive">
                                            <thead></thead>
                                            <tbody>
                                                <tr style={{ display: (this.props.getVisibility("Expense List", "read") === "inline" ? "table-row" : "none") }}>
                                                    <td style={{ lineHeight: "3" }}>
                                                        <Link to="/home/reports/expenseListreport" >
                                                            <span>Expense List </span>
                                                        </Link>
                                                    </td>
                                                </tr>
                                                <tr style={{ display: (this.props.getVisibility("Expense By Expense Category", "read") === "inline" ? "table-row" : "none") }}>

                                                    <td style={{ lineHeight: "3" }}>
                                                        <Link to="/home/reports/expenseByExpenseCategoryreport" >
                                                            <span>Expense By Expense Category </span>
                                                        </Link>
                                                    </td>
                                                </tr>
                                                <tr style={{ display: (this.props.getVisibility("Expense By Expense Category", "read") === "inline" ? "table-row" : "none") }}>
                                                    <td style={{ lineHeight: "3" }}>
                                                        <Link to="/home/reports/expenseByPartyreport" >
                                                            <span>Expense By Party </span>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    };
};
export default AllReport;