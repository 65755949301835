import React, { Component } from 'react';
import Dropzone from 'react-dropzone'
import Loader from './Loader'
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ImportData extends Component {

    constructor() {
        super();
        this.state = {
            stores: [],
            selectedProductImportType: '1',
            selectedStoreID: '',
            selectedStoreName: '',
            importID: '',
            logs: [],
            loading: false,
            completed: false,
            importStatus: 'Uploading file...',
            showModal: true,
            importFile: null,
            emailID: '',
            rows: [],
            // customerImportTemplate:'https://firebasestorage.googleapis.com/v0/b/pos-expozaye.appspot.com/o/CustomerImport.csv?alt=media&token=235e61f0-a7ad-4447-af3d-82834a4d2c05',
            // productImportTemplate:'https://firebasestorage.googleapis.com/v0/b/pos-expozaye.appspot.com/o/ProductImport.csv?alt=media&token=6acd9ee9-75bd-44e1-9692-b124e7ba90c8',
            customerImportTemplate: 'https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/templates%2FCustomerImport.csv?alt=media&token=37568a30-1d0b-4312-b070-7fb4fa91f2d8',
            productImportTemplate: 'https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/templates%2FProductImport.csv?alt=media&token=4a20f49a-8bcb-4323-84d5-39e7be1603e2',
            // productImportTemplate: 'https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/templates%2FProductImport.csv?alt=media&token=432161e4-f196-49e7-aa0c-f2d41fc5df5e',
            // productPriceUpdateTemplate: 'https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/templates%2FProductPriceUpdate.csv?alt=media&token=9a7d05ab-26d1-45e1-8a58-f501c71e490a',
            productPriceUpdateTemplate: 'https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/templates%2FProductPriceUpdate.csv?alt=media&token=466a492a-cce0-4c69-b50a-0ab7c332ad11',
            customerColumns: [
                "CustomerCode", "FirstName", "LastName", "Gender", "PhoneNo", "EmailID", "Notes", "DOB"
                , "Anniversary", "AddressType", "AddressLine1", "AddressLine2", "City", "State", "PostalCode"
                , "Country", "CustomField1", "CustomField2", "CustomField3", "CustomField4", "CustomField5",
                "CustomField6", "Membership", "WalletOpeningBalance", "OutstandingAmount"],
            productColumns: [
                "SKU", "BarCode", "Brand", "Category", "SubCategory", "Name", "Description", "HSNSACCode", "ChargeTaxOnProduct"
                , "MRP", "SupplyPrice", "RetailPrice", "Markup", "DiscountPercentage","DiscountAmount"
                ,"TaxInclusive", "TaxGroup", "PriceBasedTax", "PriceRangeFrom1", "PriceRangeTo1"
                , "TaxGroup1", "PriceRangeFrom2", "PriceRangeTo2", "TaxGroup2","InventoryType", "MinQty", "MaxQty","ReorderQty"],
            productPriceUpdateColumns: ["ProductID", "SupplyPrice", "Markup", "RetailPrice", "MRP", "DiscountPercentage","DiscountAmount"],
            // productUpdateColumns: [
            //    "ProductID", "SKU", "BarCode", "Brand", "Category", "SubCategory", "Name", "Description", "HSNSACCode", "ChargeTaxOnProduct"
            //     , "MRP", "SupplyPrice", "RetailPrice", "Markup", "DiscountPercentage","DiscountAmount","TaxInclusive", "TaxGroup", "PriceBasedTax", "PriceRangeFrom1", "PriceRangeTo1"
            //     , "TaxGroup1", "PriceRangeFrom2", "PriceRangeTo2", "TaxGroup2"],
        }
        this.onCSVUpload = this.onCSVUpload.bind(this);
        this.onFileRemove = this.onFileRemove.bind(this)
        this.onUploadClick = this.onUploadClick.bind(this)
        this.fileReader = this.fileReader.bind(this)
        this.pollUploadStatus = this.pollUploadStatus.bind(this)
        this.showStatus = this.showStatus.bind(this)
    }

    componentDidMount() {
        this.props.getUserStores(this.props.user.user.RegistrationID, this.props.user.auth.uid).then((stores) => {
            this.setState({ stores: stores })
        })
        this.setState({ emailID: this.props.user.auth.email })
    }

    componentWillUnmount() {
        clearInterval(this.timer)
        this.timer = null
    }

    onCSVUpload() {
        this.setState({ loading: true })
    }

    onFileRemove() {
        this.setState({ importFile: null })
    }

    onUploadClick() {
        if (!this.state.importFile) {
            alert('Please select file to import')
            return
        }

        //read csv to chck no of rows
        //read csv to check column name
        //upload csv
        //create doc

        //console.log('pawan',e)
        let rows = this.state.rows
        //console.log('onUploadClick rows', rows)
        if (rows.length > 2001) {
            alert('No of rows more than 2000')
            return
        }
        let columns = rows[0].split(",")
        //console.log('columns', columns)
        let columnsToCheck = []
        if (this.props.importType === "customer") {
            columnsToCheck = this.state.customerColumns
        }
        else {
            if (this.state.selectedProductImportType === "1") {
                columnsToCheck = this.state.productColumns
            }
            else if (this.state.selectedProductImportType === "2") {
                columnsToCheck = this.state.productPriceUpdateColumns
            }
            else if (this.state.selectedProductImportType === "3") {
            
                columnsToCheck = this.state.productColumns
            }

        }
        //console.log('columnsToCheck', columnsToCheck)
        let error = ""
        for (var i = 0; i < columnsToCheck.length; i++) {
            let columnFound = false
            //console.log('columnsToCheck[i]',columnsToCheck[i])
            for (var j = 0; j < columns.length; j++) {
                if (columnsToCheck[i] === columns[j]) {
                    //console.log('colun found - columns[j]', columns[j])
                    columnFound = true
                    break;
                }
            }
            if (!columnFound) {
                error = error + columnsToCheck[i] + " missing."
                //console.log(error)
            }
        }
        if (error !== "") {
            alert("Invalid file format." + error)
            return
        }
        this.setState({ loading: true })
        let uploadPath = this.props.registration.registration.StorageFolder + this.props.importType === "customer" ? '/ImportCustomer/' : '/ImportProduct/' + this.props.user.user.RegistrationID + "_" + Math.floor((Math.random() * 100000) + 1)
        this.setState({ loading: true })
        this.props.uploadFileToServer(this.state.importFile, uploadPath).then((downloadURL) => {
            this.props.importCSV(uploadPath + '/' + this.state.importFile.name, this.props.importType,
                this.state.importFile.name,
                this.state.emailID, this.props.importType === "product" ? this.state.selectedProductImportType : "",
                this.state.selectedProductImportType ==="3" ? "" : this.state.selectedStoreID, this.state.selectedStoreName).then((importID) => {
                    this.setState({ importID: importID })
                    this.pollUploadStatus()
                })
        })
    }

    pollUploadStatus() {
        this.setState({ importStatus: 'Processing file..' })
        this.timer = setInterval(() => this.showStatus(), 30000);
    }

    showStatus() {

        // console.log('Polling Import Status...')
        this.props.getImportDetails(this.state.importID).then((data) => {
            if (data.Status.toLowerCase() === "completed") {
                this.setState({ loading: false, completed: true, downloadLink: data.DownloadLink, importStatus: "Import completed" })
                //get logs for download
                this.props.onImportCompleted()
                this.props.getImportLogs(this.state.importID).then((logs) => {
                    this.setState({ logs: logs })
                    clearInterval(this.timer)
                    this.timer = null
                })
            }
            else {
                this.setState({ importStatus: 'Processing file..' })
            }
        })
    }

    onFileSelect(acceptedFiles, rejectedFiles) {
        if (acceptedFiles.length > 0) {
            if ((acceptedFiles[0].size / 1024) > 6000) {
                alert('File size should not be more than 6 MB')
                return
            }
            let ext = acceptedFiles[0].name.substring(acceptedFiles[0].name.length - 3, acceptedFiles[0].name.length)
            if (ext !== "csv") {
                alert('Invalid file format')
                return
            }
            this.setState({ importFile: acceptedFiles[0] })
            const reader = new FileReader();
            reader.readAsText(this.state.importFile)
            reader.onload = this.fileReader
        }
        else { alert("No file selected") }
    }

    fileReader(e) {
        //console.log('fileReader')
        let rows = e.target.result.split("\r")
        //console.log('fileReader rows', rows)
        this.setState({ rows: rows })
    }

    render() {
        return <div style={{ width: "600px" }}>
            {this.props.importType === "customer" && <h3 style={{ marginTop: "0px" }}>Import Customer(s)</h3>}
            {this.props.importType === "product" && <h3 style={{ marginTop: "0px" }}>Import Product(s)</h3>}
            <div className="box-body">
                {(!this.state.loading && !this.state.completed) && <React.Fragment>
                    <div className="row">
                        <div class="callout callout-info">
                            <h4>Info</h4>
                            <p>- Maximum of 2,000 rows allowed per file upload.</p>
                            <p>- Click here to download import template. <a href={this.props.importType == "customer" ? this.state.customerImportTemplate : this.state.selectedProductImportType === "1" || this.state.selectedProductImportType === "3" ? this.state.productImportTemplate : this.state.productPriceUpdateTemplate}>Import Template</a></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            {!this.state.importFile && <Dropzone onDrop={(files) => this.onFileSelect(files)} >
                                <div>Drop csv file you would like to upload or click to select files to upload.</div>
                            </Dropzone>}
                            {this.state.importFile && <div className="form-group">
                                <ul className="mailbox-attachments clearfix">
                                    <li>
                                        <span className="mailbox-attachment-icon has-img"></span>
                                        <div className="mailbox-attachment-info">
                                            <span className="mailbox-attachment-name">{this.state.importFile.size.length > 20 ? this.state.importFile.size.substr(0, 20) + "..." : this.state.importFile.name}</span>
                                            <span className="mailbox-attachment-size">
                                                {Math.round(this.state.importFile.size / 1024)} KB
                                                        <a onClick={this.onFileRemove}
                                                    className="btn btn-default btn-xs pull-right"><i className="fa fa-trash"></i></a>
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>}
                        </div>
                        <div className="col-md-1">
                        </div>
                        {this.props.importType === "product" && <div className="col-md-7">
                            <div className="form-group">
                                <label >Import type:</label>
                                <select className="form-control"
                                    value={this.state.selectedProductImportType}
                                    onChange={e => {
                                        this.setState({ selectedProductImportType: e.target.value })
                                    }}>
                                    <option value="1">New product import</option>
                                    <option value="2">Update price for existing products</option>
                                    <option value="3">Update product details</option>
                                </select>
                            </div>

                             {((this.props.importType === "product" && this.state.selectedProductImportType !=="3") ||  this.props.importType === "customer" ) && <div className="form-group">
                                <label>Store:</label>
                                <select className="form-control"
                                    value={this.state.selectedStoreID}
                                    onChange={e => {
                                        this.setState({
                                            selectedStoreID: e.target.value,
                                            selectedStoreName: e.target.options[e.target.selectedIndex].text
                                        })
                                    }}>
                                    {this.props.user.user.Role.toLowerCase() === "power user" && <option value="">All store</option>}
                                    {this.state.stores.filter(e => !e.IsDeleted).map(store =>
                                        <option value={store.key}>{store.StoreCode} {store.StoreName}</option>
                                    )}
                                </select>
                            </div>}
                        </div>}
                    </div>
                </React.Fragment>
                }
                {(this.state.loading && !this.state.completed) && <React.Fragment>
                    <div><h3>{this.state.importStatus}</h3></div>
                    <div><Loader show={this.state.loading} /> </div>
                    <div>You can close this window. Import completion intimation will be emailed.</div>
                </React.Fragment>}
                {this.state.completed && <div><h3>Import completed</h3></div>}
                {(this.props.importType === "customer" && this.state.completed) &&
                    <ExcelFile element={<button className="btn btn-flat btn-md btn-flat btn-default">Download import processing file</button>}>
                        <ExcelSheet data={this.state.logs} name="Customers">
                            <ExcelColumn label="ExcelRowNo" value="ExcelRowNo" />
                            <ExcelColumn label="CustomerCode" value="CustomerCode" />
                            <ExcelColumn label="FirstName" value="FirstName" />
                            <ExcelColumn label="LastName" value="LastName" />
                            <ExcelColumn label="Gender" value="Gender" />
                            <ExcelColumn label="PhoneNo" value="PhoneNo" />
                            <ExcelColumn label="EmailID" value="EmailID" />
                            <ExcelColumn label="Notes" value="Notes" />
                            <ExcelColumn label="DOB" value="DOB" />
                            <ExcelColumn label="Anniversary" value="Anniversary" />
                            <ExcelColumn label="AddressType" value="Addresses[0].AddressType" />
                            <ExcelColumn label="AddressLine1" value="Addresses[0].AddressLine1" />
                            <ExcelColumn label="AddressLine2" value="Addresses[0].AddressLine2" />
                            <ExcelColumn label="City" value="Addresses[0].City" />
                            <ExcelColumn label="State" value="Addresses[0].State" />
                            <ExcelColumn label="PostalCode" value="Addresses[0].PostalCode" />
                            <ExcelColumn label="Country" value="Addresses[0].Country" />
                            <ExcelColumn label="CustomField1" value="CustomField1" />
                            <ExcelColumn label="CustomField2" value="CustomField2" />
                            <ExcelColumn label="CustomField3" value="CustomField3" />
                            <ExcelColumn label="CustomField4" value="CustomField4" />
                            <ExcelColumn label="CustomField5" value="CustomField5" />
                            <ExcelColumn label="CustomField6" value="CustomField6" />
                            <ExcelColumn label="Membership" value="Membership.Name" />
                            <ExcelColumn label="WalletOpeningBalance" value="WalletOpeningBalance" />
                            <ExcelColumn label="OutstandingAmount" value="OutstandingAmount" />
                            <ExcelColumn label="Status" value="Status" />
                            <ExcelColumn label="Error" value="Eror" />
                        </ExcelSheet>
                    </ExcelFile>}
                {(this.props.importType === "product" && (this.state.selectedProductImportType === "1" || this.state.selectedProductImportType === "3") && this.state.completed) &&
                    <ExcelFile element={<button className="btn btn-flat btn-md btn-flat btn-default">Download import processing file</button>}>
                        <ExcelSheet data={this.state.logs} name="ProductImport">
                            <ExcelColumn label="ExcelRowNo" value="ExcelRowNo" />
                            <ExcelColumn label="ProductType" value="ProductType" />
                            <ExcelColumn label="SKU" value="SKU" />
                            <ExcelColumn label="BarCode" value="BarCode" />
                            <ExcelColumn label="Brand" value="Brand" />
                            <ExcelColumn label="Category" value="Category" />
                            <ExcelColumn label="SubCategory" value="SubCategory" />
                            <ExcelColumn label="Name" value="Name" />
                            <ExcelColumn label="HSNSACCode" value="HSNSACCode" />
                            <ExcelColumn label="ChargeTaxOnProduct" value="ChargeTaxOnProduct" />
                            <ExcelColumn label="MRP" value="MRP" />
                            <ExcelColumn label="SupplyPrice" value="SupplyPrice" />
                            <ExcelColumn label="RetailPrice" value="RetailPrice" />
                            <ExcelColumn label="Markup" value="Markup" />
                            <ExcelColumn label="DiscountPercentage" value="DiscountPercentage" />
                            <ExcelColumn label="DiscountAmount" value="DiscountAmount" />
                            <ExcelColumn label="TaxInclusive" value="TaxInclusive" />
                            <ExcelColumn label="TaxGroup" value="TaxGroup" />
                            <ExcelColumn label="PriceBasedTax" value="PriceBasedTax" />
                            <ExcelColumn label="PriceRangeFrom1" value="PriceRangeFrom1" />
                            <ExcelColumn label="PriceRangeTo1" value="PriceRangeTo1" />
                            <ExcelColumn label="PriceRangeFrom2" value="PriceRangeFrom2" />
                            <ExcelColumn label="PriceRangeTo2" value="PriceRangeTo2" />
                            <ExcelColumn label="TaxGroup2" value="TaxGroup2" />
                            <ExcelColumn label="ProductType" value="ProductType" />
                            <ExcelColumn label="AllowDiscount" value="AllowDiscount" />
                            <ExcelColumn label="AllowPriceEdit" value="AllowPriceEdit" />
                            <ExcelColumn label="AllowTaxChange" value="AllowTaxChange" />
                            <ExcelColumn label="WalletTopUpAmount" value="WalletTopUpAmount" />
                            <ExcelColumn label="WalletTopUpSameAsRetailPrice" value="WalletTopUpSameAsRetailPrice" />
                            <ExcelColumn label="Status" value="Status" />
                            <ExcelColumn label="Error" value="Error" />
                        </ExcelSheet>
                    </ExcelFile>}
                    {(this.props.importType === "product" && this.state.selectedProductImportType === "2" && this.state.completed) &&
                    <ExcelFile element={<button className="btn btn-flat btn-md btn-flat btn-default">Download import processing file</button>}>
                        <ExcelSheet data={this.state.logs} name="ProductImport">
                            <ExcelColumn label="ExcelRowNo" value="ExcelRowNo" />
                            <ExcelColumn label="ProductID" value="ProductID" />
                            <ExcelColumn label="MRP" value="MRP" />
                            <ExcelColumn label="SupplyPrice" value="SupplyPrice" />
                            <ExcelColumn label="RetailPrice" value="RetailPrice" />
                            <ExcelColumn label="Markup" value="Markup" />
                            <ExcelColumn label="DiscountPercentage" value="DiscountPercentage" />
                            <ExcelColumn label="DiscountAmount" value="DiscountAmount" />
                            <ExcelColumn label="Status" value="Status" />
                            <ExcelColumn label="Error" value="Error" />
                        </ExcelSheet>
                    </ExcelFile>}
                    {(this.props.importType === "product" && this.state.selectedProductImportType === "3" && this.state.completed) &&
                    <ExcelFile element={<button className="btn btn-flat btn-md btn-flat btn-default">Download import processing file</button>}>
                        <ExcelSheet data={this.state.logs} name="ProductImport">
                            <ExcelColumn label="ExcelRowNo" value="ExcelRowNo" />
                            <ExcelColumn label="ProductID" value="ProductID" />
                            <ExcelColumn label="MRP" value="MRP" />
                            <ExcelColumn label="SupplyPrice" value="SupplyPrice" />
                            <ExcelColumn label="RetailPrice" value="RetailPrice" />
                            <ExcelColumn label="Markup" value="Markup" />
                            <ExcelColumn label="DiscountPercentage" value="DiscountPercentage" />
                            <ExcelColumn label="DiscountAmount" value="DiscountAmount" />
                            <ExcelColumn label="Status" value="Status" />
                            <ExcelColumn label="Error" value="Error" />
                        </ExcelSheet>
                    </ExcelFile>}
            </div>
            <div className="box-footer">
                {(!this.state.loading && !this.state.completed) && <button type="submit" className="btn btn-flat btn-md btn-success pull-right" onClick={this.onUploadClick} disabled={this.state.loading}>Upload</button>}
                <button type="submit" className="btn btn-flat btn-md btn-flat btn-default" onClick={this.props.onClose} >Cancel</button>
            </div>
        </div>

    }
}

export default ImportData