import React, { Component } from 'react'
import Loader from '../Loader'
var Typeahead = require('react-bootstrap-typeahead').Typeahead;

class SalesIntermediate extends Component {

    constructor(props) {
        super(props)
        this.state = {
            cashRegisters: [],
            cashRegisterOpeningBalance: 0,
            saving: false,
            showCashRegisterOpening: false,
            showCashRegisterSelection: false,
            cashRegisterClosing: null
        }
        this.redirectToTransactionPage = this.redirectToTransactionPage.bind(this)
        this.onCashRegisterSelection = this.onCashRegisterSelection.bind(this)
        this.onEnteringCashRegisterOpeningBalance = this.onEnteringCashRegisterOpeningBalance.bind(this)
    }

    componentDidMount() {
        if (this.props.user.cashRegister === null) {
            //show list of cash registers
            this.props.getCashRegisters(this.props.user.store.key).then((cashRegisters) => {
                let c = cashRegisters.filter((e) => !e.IsDeleted)
                if (c.length == 0) {
                    alert('No cash register exists. Pls define cash register first')
                    this.props.history.push('/home/')
                }
                else if (c.length === 1) {//auto select cash register 
                    this.onCashRegisterSelection(c[0])
                    // this.props.setCashRegister(c[0])
                }
                else {//give user option to select cash register 
                    this.setState({ cashRegisters: c, showCashRegisterSelection: true })
                }
            })
        } else {
            this.redirectToTransactionPage()
        }


    }

    async onCashRegisterSelection(selectedCashRegister) {
        //check if cash regiter closing enabled 
        let cashRegisterClosingEnbaled = false

        this.props.initialiseTransactionRunningNo(selectedCashRegister.key)
        this.props.initialiseKOTRunningNo(selectedCashRegister.key)
        this.setState({ loading: true, value: "Populating Products!!!" })

        if (this.props.registration.registration.hasOwnProperty('SyncCustomersLocally') && this.props.registration.registration.SyncCustomersLocally) {
            //this.setState({ value: "Populating Customers!!!" })
            let result1 = await this.props.getActiveCustomers(this.props.user.user.RegistrationID, '')
            let customers = result1.searchResult
            let scrollID1 = result1.scrollID
            for (let index = 1; index < (result1.totalItemsCount / 2000); index++) {
                result1 = this.props.getActiveCustomers(this.props.user.user.RegistrationID, scrollID1);
                if(result1 && result1.searchResult)
                {
                    result1.searchResult.forEach(element => {
                        customers.push(element)
                    });
                }
            }
            // console.log(customers.length);
            this.props.pushCustomersToRedux(customers)
        }
        
        let result = await this.props.getActiveProducts(this.props.user.user.RegistrationID, '')
        let products = result.searchResult
        let scrollID = result.scrollID
        for (let index = 1; index < (result.totalItemsCount / 2000); index++) {
            result = await this.props.getActiveProducts(this.props.user.user.RegistrationID, scrollID);
            result.searchResult.forEach(element => {
                products.push(element)
            });
        }
        // console.log(products.length);
        this.props.pushProductsToRedux(products);

        this.setState({ loading: false })

        if (this.props.user.store.hasOwnProperty("CashRegisterClosing")) {
            cashRegisterClosingEnbaled = this.props.user.store.CashRegisterClosing
        }
        if (cashRegisterClosingEnbaled) {
            //check if cash register is already opened, if not then ask for opening balance
            let cashRegisterClosing = await this.props.getCashRegisterOpeningDetails(selectedCashRegister.key)
            //    console.log('cashRegisterClosing',cashRegisterClosing)
            if (cashRegisterClosing) {//open cash register found
                this.props.setCashRegisterClosing(cashRegisterClosing)
                this.props.setCashRegister(selectedCashRegister)
                this.setState({ showCashRegisterOpening: false, showCashRegisterSelection: false, cashRegisterClosing: cashRegisterClosing })
                this.redirectToTransactionPage()
            }
            else {
                //show option to enter opening balance
                this.setState({
                    selectedCashRegister: selectedCashRegister,
                    showCashRegisterOpening: true,
                    showCashRegisterSelection: false,
                    cashRegisterClosing: null
                })
            }
        }
        else {
            this.props.setCashRegister(selectedCashRegister)
            this.setState({
                showCashRegisterOpening: false,
                showCashRegisterSelection: false
            })
            this.redirectToTransactionPage()
        }
    }

    async onEnteringCashRegisterOpeningBalance(openingBalance) {
        this.setState({ saving: true })
        let cashRegisterClosing = {
            CashRegisterID: this.state.selectedCashRegister.key,
            CashRegisterCode: this.state.selectedCashRegister.CashRegisterCode,
            CashRegisterName: this.state.selectedCashRegister.CashRegisterName,
            StoreID: this.props.user.store.key,
            StoreCode: this.props.user.store.StoreCode,
            StoreName: this.props.user.store.StoreName,
            RegistrationID: this.props.user.user.RegistrationID,
            OpeningBalance: openingBalance != 0 ? openingBalance : 0,
            OpeningTime: new Date(),
            OpeningByUserID: this.props.user.user.UID,
            OpeningByUserName: this.props.user.user.Name,
            Status: "Open"
        }
        let res = await this.props.openCashRegister(cashRegisterClosing)
        let cashRegisterClosingobj = await this.props.getCashRegisterOpeningDetails(res.CashRegisterID)
        if (!cashRegisterClosingobj) {
            alert('System is experiencing problem opening cash register. Pls try again or contact support')
            return
        }
        this.props.setCashRegister(this.state.selectedCashRegister)
        this.props.setCashRegisterClosing(cashRegisterClosingobj)
        this.setState({ showCashRegisterOpening: false, showCashRegisterSelection: false, cashRegisterClosing: cashRegisterClosingobj })
        this.redirectToTransactionPage()
    }

    redirectToTransactionPage() {
        if (this.props.location.state.transactionType.TransactionType.toLowerCase() === "receive payment") {
            this.props.history.push({
                pathname: "/home/sales/receivepayment/",
                state: { transactionType: this.props.location.state.transactionType, type: this.props.location.state.type }
            })
        }
        else {
            this.props.history.push({
                pathname: "/home/sales/newsales/",
                state: { transactionType: this.props.location.state.transactionType, type: this.props.location.state.type }
            })
        }
    }

    render() {
        return <div>
    
            {(!this.props.user.cashRegister && this.state.showCashRegisterSelection) && <div>
                <section className="content">
                    <div className="login-box">
                        <div className="form-group">
                            <label >Please select cash register</label>
                            <Typeahead
                                autoFocus={true}
                                allowNew={false}
                                multiple={false}
                                options={this.state.cashRegisters}
                                labelKey="CashRegisterName"
                                placeholder="Select cash register"
                                filterBy={['CashRegisterCode', 'CashRegisterName']}
                                bsSize={'large'}
                                dropup={false}
                                highlightOnlyResult={true}
                                //selected={this.state.selectedCashRegister}
                                onChange={(selected) => {
                                    this.setState({ selectedCashRegister: selected[0] })
                                }}
                                renderMenuItemChildren={(option, props) => (
                                    <div>
                                        <div><b>{option.CashRegisterCode} - {option.CashRegisterName} </b></div>
                                    </div>
                                )}
                            />
                        </div>
                        <button type="submit"
                            className="btn btn-md btn-success btn-flat pull-right"
                            onClick={() => this.onCashRegisterSelection(this.state.selectedCashRegister)}>OK
                                </button>
                    </div>
                </section>
            </div>}
            {this.state.showCashRegisterOpening && <React.Fragment>
                <section className="content">
                    <div className="login-box">
                        <div className="form-group">
                            <label >Please enter opening balance</label>
                            <input type="number" className="form-control"
                                onChange={(evt) => this.setState({ cashRegisterOpeningBalance: evt.target.value })}
                                value={this.state.cashRegisterOpeningBalance}
                            />
                        </div>
                        <button type="submit"
                            className="btn btn-md btn-success btn-flat pull-right"
                            disabled={this.state.saving}
                            onClick={() => this.onEnteringCashRegisterOpeningBalance(this.state.cashRegisterOpeningBalance)}>OK
                                </button>
                    </div>
                </section>
            </React.Fragment>}
            {this.state.loading && <div style={{marginTop:"200px"}}>
            <Loader
                value={this.state.value}
                show={this.state.loading} />
            </div>}
        </div>
    }
}

export default SalesIntermediate