import * as actionTypes from '../actionTypes';

const intialState= {
    auth:null,
    user:null,
    registration:null,
    store:null,
    cashRegister:null,
    acl: null,
    cashRegisterClosing:null,
    userStores:null
}
export default function(state=intialState, action) {
    switch (action.type) {
        case actionTypes.SIGN_OUT:
            return intialState
        case actionTypes.SET_AUTH:
            return {...state, auth:action.auth } 
        case actionTypes.SET_USER:
            return {...state, user:action.user} 
        case actionTypes.SET_REGISTRATION:
            return {...state,registration: action.registration }     
        case actionTypes.SET_STORE:
            return {...state, store: action.store }
        case actionTypes.SET_STORES:
            return { ...state, userStores: action.stores }  
        case actionTypes.SET_CASH_REGISTER:
            return {...state, cashRegister: action.cashRegister }  
        case actionTypes.SET_ACL:
            return { ...state, acl: action.acl }
        case actionTypes.SET_CASH_REGISTER_CLOSING:
            return {...state, cashRegisterClosing: action.cashRegisterClosing}
        default:
            return state;
    }
}
