import React from 'react'
import { withRouter } from 'react-router-dom'
import { database } from '../../firebase'
import CSVReader from 'react-csv-reader';


class OnBoarding extends React.Component {
    
    constructor() {
        super()
        this.state = {
            PaymentTypes: [{
                "IsDeleted": false,
                "IsSystemDefined": false,
                "PaymentType": "Cash",
                "SequenceNo": 1,
                "ShowInExpense": true,
                "ShowInSales": true
            },
            {
                "IsDeleted": false,
                "IsSystemDefined": false,
                "PaymentType": "Credit Card",
                "SequenceNo": 2,
                "ShowInExpense": true,
                "ShowInSales": true
            },
            {
                "IsDeleted": false,
                "IsSystemDefined": false,
                "PaymentType": "Debit Card",
                "SequenceNo": 3,
                "ShowInExpense": true,
                "ShowInSales": true
            },
            {
                "IsDeleted": false,
                "IsSystemDefined": false,
                "PaymentType": "Cheque",
                "SequenceNo": 4,
                "ShowInExpense": true,
                "ShowInSales": false
            },
            {
                "IsDeleted": false,
                "IsSystemDefined": false,
                "PaymentType": "Wallet",
                "SequenceNo": 5,
                "ShowInExpense": false,
                "ShowInSales": true
            },
            {
                "IsDeleted": false,
                "IsSystemDefined": false,
                "PaymentType": "Cash From Counter",
                "SequenceNo": 6,
                "ShowInExpense": true,
                "ShowInSales": false
            }
            ],
            ModuleConfigs: [
                {
                    "CalculateTaxChecked": true,
                    "CustomerMandatoryChecked": false,
                    "DiscountChecked": true,
                    "DiscountPercentage": 0,
                    "EmployeeManadatoryTransactionORLineItemLevel": "",
                    "EmployeeMandatoryChecked": false,
                    "IsDeleted": false,
                    "Module": "sales",
                    "NumberingFormat": [{ "Option": "text", "Separator": "/", "Value": "SI" },
                    { "Option": "storecode", "Separator": "/", "Value": "" },
                    { "Option": "cashregistercode", "Separator": "/", "Value": "" }],
                    "NumberingFormatDisplay": "SI/S01/CR1/<Running Number>",
                    "PaymentModeChecked": true,
                    "SalesImpactChecked": true,
                    "TransactionType": "Sales Invoice",
                    "UpdateStockChecked": true
                }, // Sales Invoice
                {
                    "CalculateTaxChecked": true,
                    "CustomerMandatoryChecked": true,
                    "DiscountChecked": true,
                    "DiscountPercentage": 0,
                    "EmployeeManadatoryTransactionORLineItemLevel": "",
                    "EmployeeMandatoryChecked": false,
                    "IsDeleted": false,
                    "Module": "sales",
                    "NumberingFormat": [{ "Option": "text", "Separator": "/", "Value": "SR" },
                    { "Option": "storecode", "Separator": "/", "Value": "" },
                    { "Option": "cashregistercode", "Separator": "/", "Value": "" }],
                    "NumberingFormatDisplay": "SR/S01/CR1/<Running Number>",
                    "PaymentModeChecked": true,
                    "SalesImpactChecked": true,
                    "TransactionType": "Sales Return",
                    "UpdateStockChecked": true
                }, // Sales Return
                {
                    "CalculateTaxChecked":false,"CustomerMandatoryChecked":false,"DiscountChecked":false
                    ,"DiscountPercentage":0,"EmployeeManadatoryTransactionORLineItemLevel":""
                    ,"EmployeeMandatoryChecked":false,"IncludePriceAndTax":false,"IsDeleted":false
                    ,"Module":"inventory",
                    "NumberingFormat":[{"Option":"text","Separator":"/","Value":"DM"},{"Option":"storecode","Separator":"/","Value":""}],"NumberingFormatDisplay":"DM/S01/<Running Number>","PaymentModeChecked":false
                    ,"SalesImpactChecked":false,"TransactionMovement":"store","TransactionType":"Damage","UpdateStockChecked":true,"UpdateStockInwardOrOutward":"outward"
                },// Damage
                {"CalculateTaxChecked":false,"CustomerMandatoryChecked":false,"DiscountChecked":false
                ,"DiscountPercentage":0,"EmployeeManadatoryTransactionORLineItemLevel":""
                ,"EmployeeMandatoryChecked":false,"IncludePriceAndTax":false,"IsDeleted":false
                ,"Module":"inventory"
                ,"NumberingFormat":[{"Option":"text","Separator":"/","Value":"OS"},{"Option":"storecode","Separator":"/","Value":""}],"NumberingFormatDisplay":"OS/S01/<Running Number>","PaymentModeChecked":false
                ,"SalesImpactChecked":false,"TransactionMovement":"store","TransactionType":"Opening Stock","UpdateStockChecked":true,"UpdateStockInwardOrOutward":"inward"
                },//Opening Stock
                {"CalculateTaxChecked":false,"CustomerMandatoryChecked":false,"DiscountChecked":false,"DiscountPercentage":0,"EmployeeManadatoryTransactionORLineItemLevel":"","EmployeeMandatoryChecked":false,"IncludePriceAndTax":true,"IsDeleted":false,"Module":"inventory","NumberingFormat":[{"Option":"text","Separator":"/","Value":"PE"},{"Option":"storecode","Separator":"/","Value":""}],"NumberingFormatDisplay":"PE/S01/<Running Number>","PaymentModeChecked":false,"SalesImpactChecked":false,"TransactionMovement":"vendortostore",
                "TransactionType":"Purchase Entry","UpdateStockChecked":true,"UpdateStockInwardOrOutward":"inward"
                },//Purchase Entry
                {"CalculateTaxChecked":false,"CustomerMandatoryChecked":false,"DiscountChecked":false,"DiscountPercentage":0,"EmployeeManadatoryTransactionORLineItemLevel":"","EmployeeMandatoryChecked":false,"IncludePriceAndTax":true,"IsDeleted":false,"Module":"inventory","NumberingFormat":[{"Option":"text","Separator":"/","Value":"PR"},{"Option":"storecode","Separator":"/","Value":""}],"NumberingFormatDisplay":"PR/S01/<Running Number>","PaymentModeChecked":false,"SalesImpactChecked":false,"TransactionMovement":"storetovendor","TransactionType":"Purchase Return","UpdateStockChecked":true,"UpdateStockInwardOrOutward":"outward"
                },//Purchase Return
                {"CalculateTaxChecked":false,"CustomerMandatoryChecked":false,"DiscountChecked":false,"DiscountPercentage":0,"EmployeeManadatoryTransactionORLineItemLevel":"","EmployeeMandatoryChecked":false,"IncludePriceAndTax":false,"IsDeleted":false,"Module":"inventory","NumberingFormat":[{"Option":"text","Separator":"/","Value":"SC"},{"Option":"storecode","Separator":"/","Value":""}],"NumberingFormatDisplay":"SC/S01/<Running Number>","PaymentModeChecked":false,"SalesImpactChecked":false,"TransactionMovement":"store","TransactionType":"Stock Check","UpdateStockChecked":false,"UpdateStockInwardOrOutward":""
                },//Stock Check
            ],
            Taxes: [
                {
                    "IsDeleted": false,
                    "TaxGroup": "GST 0%",
                    "Taxes": [{ "TaxName": "CGST", "TaxPercentage": "0", "TaxType": "CGST" },
                    { "TaxName": "SGST", "TaxPercentage": "0", "TaxType": "SGST" },
                    { "TaxName": "IGST", "TaxPercentage": "0", "TaxType": "IGST" }]
                },
                {
                    "IsDeleted": false,
                    "TaxGroup": "GST 18%",
                    "Taxes": [{ "TaxName": "CGST", "TaxPercentage": "9", "TaxType": "CGST" },
                    { "TaxName": "SGST", "TaxPercentage": "9", "TaxType": "SGST" },
                    { "TaxName": "IGST", "TaxPercentage": "18", "TaxType": "IGST" }]
                },
                {
                    "IsDeleted": false,
                    "TaxGroup": "GST 28%",
                    "Taxes": [{ "TaxName": "CGST", "TaxPercentage": "14", "TaxType": "CGST" },
                    { "TaxName": "SGST", "TaxPercentage": "14", "TaxType": "SGST" },
                    { "TaxName": "IGST", "TaxPercentage": "28", "TaxType": "IGST" }]
                },
                {
                    "IsDeleted": false,
                    "TaxGroup": "GST 12%",
                    "Taxes": [{ "TaxName": "CGST", "TaxPercentage": "6", "TaxType": "CGST" },
                    { "TaxName": "SGST", "TaxPercentage": "6", "TaxType": "SGST" },
                    { "TaxName": "IGST", "TaxPercentage": "12", "TaxType": "IGST" }]
                },
                {
                    "IsDeleted": false,
                    "TaxGroup": "GST 5%",
                    "Taxes": [{ "TaxName": "CGST", "TaxPercentage": "2.5", "TaxType": "CGST" },
                    { "TaxName": "SGST", "TaxPercentage": "2.5", "TaxType": "SGST" },
                    { "TaxName": "IGST", "TaxPercentage": "5", "TaxType": "IGST" }]
                }
            ],
            PrintConfigs: [{
                "CustomerCodeDisplay": true,
                "CustomerCodeDisplayName": "Customer Code",
                "CustomerCustomField1Display": false,
                "CustomerCustomField1DisplayName": "Custom Field 1",
                "CustomerCustomField2Display": false,
                "CustomerCustomField2DisplayName": "Custom Field 2",
                "CustomerCustomField3Display": false,
                "CustomerCustomField3DisplayName": "Custom Field 3",
                "CustomerCustomField4Display": false,
                "CustomerCustomField4DisplayName": "Custom Field 4",
                "CustomerCustomField5Display": false,
                "CustomerCustomField5DisplayName": "Custom Field 5",
                "CustomerCustomField6Display": false,
                "CustomerCustomField6DisplayName": "Custom Field 6",
                "CustomerEmailIDDisplay": true,
                "CustomerEmailIDDisplayName": "Email ID",
                "CustomerNameDisplay": true,
                "CustomerNameDisplayName": "Customer Name",
                "CustomerPhoneNoDisplay": true,
                "CustomerPhoneNoDisplayName": "Phone No",
                "FooterImage": null, "FooterText": "<p></p>\n", "HeaderImage": null,
                "HeaderText": "<p></p>\n",
                "Module": "Sales",
                "ModuleID": "sales",
                "PrintDisplayName": "SR 3 inch",
                "PrintSize": "3 inch",
                "PrintTemplate": "",
                "RegistrationID": "",
                "StoreAddressCityDisplay": true,
                "StoreAddressCityDisplayName": "City",
                "StoreAddressCountryDisplay": false,
                "StoreAddressCountryDisplayName": "Country",
                "StoreAddressLine1Display": true,
                "StoreAddressLine1DisplayName": "Address Line 1",
                "StoreAddressLine2Display": true,
                "StoreAddressLine2DisplayName": "Address Line 2",
                "StoreAddressPostalCodeDisplay": true,
                "StoreAddressPostalCodeDisplayName": "Postal Code",
                "StoreAddressStateDisplay": true,
                "StoreAddressStateDisplayName": "State",
                "StoreCodeDisplay": false,
                "StoreCodeDisplayName": "Store Code",
                "StoreCustomField1Display": false,
                "StoreCustomField1DisplayName": "Custom Field 1",
                "StoreCustomField2Display": false,
                "StoreCustomField2DisplayName": "Custom Field 2",
                "StoreCustomField3Display": false,
                "StoreCustomField3DisplayName": "Custom Field 3",
                "StoreCustomField4Display": false,
                "StoreCustomField4DisplayName": "Custom Field 4",
                "StoreCustomField5Display": false,
                "StoreCustomField5DisplayName": "Custom Field 5",
                "StoreCustomField6Display": false,
                "StoreCustomField6DisplayName": "Custom Field 6",
                "StoreEmailIDDisplay": true,
                "StoreEmailIDDisplayName": "Email ID",
                "StoreID": "",
                "StoreNameDisplay": true,
                "StoreNameDisplayName": "Store Name",
                "StorePhoneNoDisplay": true,
                "StorePhoneNoDisplayName": "Phone No",
                "TransactionBalanceDisplay": true,
                "TransactionBalanceDisplayName": "Balance",
                "TransactionDateDisplay": true,
                "TransactionDateDisplayName": "Date",
                "TransactionDetailsBarcodeDisplay": false,
                "TransactionDetailsBarcodeDisplayName": "Barcode",
                "TransactionDetailsDiscountAmountDisplay": false,
                "TransactionDetailsDiscountAmountDisplayName": "Dis Amt",
                "TransactionDetailsDiscountPercentageDisplay": false,
                "TransactionDetailsDiscountPercentageDisplayName": "Dis %",
                "TransactionDetailsHSNDisplay": false,
                "TransactionDetailsHSNDisplayName": "HSN/SAC",
                "TransactionDetailsItemDescriptionDisplay": false,
                "TransactionDetailsItemDescriptionDisplayName": "Item Description",
                "TransactionDetailsItemNameDisplay": true,
                "TransactionDetailsItemNameDisplayName": "Item Name",
                "TransactionDetailsMRPDisplay": false,
                "TransactionDetailsMRPDisplayName": "MRP",
                "TransactionDetailsQtyDisplay": true,
                "TransactionDetailsQtyDisplayName": "Qty",
                "TransactionDetailsRetailPriceDisplay": true,
                "TransactionDetailsRetailPriceDisplayName": "Retail Price",
                "TransactionDetailsSKUDisplay": true,
                "TransactionDetailsSKUDisplayName": "SKU",
                "TransactionDetailsSrNoDisplay": true,
                "TransactionDetailsSrNoDisplayName": "Sr. No.",
                "TransactionDetailsSubTotalDisplay": false,
                "TransactionDetailsSubTotalDisplayName": "Sub Total",
                "TransactionDetailsTaxDisplay": false,
                "TransactionDetailsTaxDisplayName": "Tax",
                "TransactionDetailsTotalDisplay": true,
                "TransactionDetailsTotalDisplayName": "Total",
                "TransactionDiscountDisplay": true,
                "TransactionDiscountDisplayName": "Discount",
                "TransactionNoDisplay": true,
                "TransactionNoDisplayName": "No",
                "TransactionPaidDisplay": true,
                "TransactionPaidDisplayName": "Paid",
                "TransactionRoundOffDisplay": true,
                "TransactionRoundOffDisplayName": "Round off",
                "TransactionTaxSummaryDisplay": true,
                "TransactionTaxSummaryDisplayName": "Tax Summary",
                "TransactionTotalDisplay": true,
                "TransactionTotalDisplayName": "Total",
                "TransactionTotalQtyDisplay": true,
                "TransactionTotalQtyDisplayName": "Total Qty.",
                "TransactionType": "Sales Return",
                "TransactionTypeID": ""
            },
            {
                "CustomerCodeDisplay": false,
                "CustomerCodeDisplayName": "Customer Code",
                "CustomerCustomField1Display": false,
                "CustomerCustomField1DisplayName": "Custom Field 1",
                "CustomerCustomField2Display": false,
                "CustomerCustomField2DisplayName": "Custom Field 2",
                "CustomerCustomField3Display": false,
                "CustomerCustomField3DisplayName": "Custom Field 3",
                "CustomerCustomField4Display": false,
                "CustomerCustomField4DisplayName": "Custom Field 4",
                "CustomerCustomField5Display": false,
                "CustomerCustomField5DisplayName": "Custom Field 5",
                "CustomerCustomField6Display": false,
                "CustomerCustomField6DisplayName": "Custom Field 6",
                "CustomerEmailIDDisplay": true,
                "CustomerEmailIDDisplayName": "Email ID",
                "CustomerNameDisplay": true,
                "CustomerNameDisplayName": "Customer Name",
                "CustomerPhoneNoDisplay": true,
                "CustomerPhoneNoDisplayName": "Phone No",
                "FooterImage": null, "FooterText": "<p></p>\n",
                "HeaderImage": null,
                "HeaderText": "<p style=\"text-align:center;\"><span style=\"font-size: 30px;\"><strong>Slaes Return</strong></span></p>\n",
                "Module": "Sales", "ModuleID": "sales", "PrintDisplayName": "SR A4",
                "PrintSize": "A4",
                "PrintTemplate": "",
                "StoreAddressCityDisplay": true,
                "StoreAddressCityDisplayName": "City",
                "StoreAddressCountryDisplay": false,
                "StoreAddressCountryDisplayName": "Country",
                "StoreAddressLine1Display": true,
                "StoreAddressLine1DisplayName": "Address Line 1",
                "StoreAddressLine2Display": true,
                "StoreAddressLine2DisplayName": "Address Line 2",
                "StoreAddressPostalCodeDisplay": true,
                "StoreAddressPostalCodeDisplayName": "Postal Code",
                "StoreAddressStateDisplay": true,
                "StoreAddressStateDisplayName": "State",
                "StoreCodeDisplay": false,
                "StoreCodeDisplayName": "Store Code",
                "StoreCustomField1Display": false,
                "StoreCustomField1DisplayName": "Custom Field 1",
                "StoreCustomField2Display": false,
                "StoreCustomField2DisplayName": "Custom Field 2",
                "StoreCustomField3Display": false,
                "StoreCustomField3DisplayName": "Custom Field 3",
                "StoreCustomField4Display": false,
                "StoreCustomField4DisplayName": "Custom Field 4",
                "StoreCustomField5Display": false,
                "StoreCustomField5DisplayName": "Custom Field 5",
                "StoreCustomField6Display": false,
                "StoreCustomField6DisplayName": "Custom Field 6",
                "StoreEmailIDDisplay": true,
                "StoreEmailIDDisplayName": "Email ID",
                "StoreID": "",
                "StoreNameDisplay": true,
                "StoreNameDisplayName": "Store Name",
                "StorePhoneNoDisplay": true,
                "StorePhoneNoDisplayName": "Phone No",
                "TransactionBalanceDisplay": true,
                "TransactionBalanceDisplayName": "Balance",
                "TransactionDateDisplay": true,
                "TransactionDateDisplayName": "Date",
                "TransactionDetailsBarcodeDisplay": true,
                "TransactionDetailsBarcodeDisplayName": "Barcode",
                "TransactionDetailsDiscountAmountDisplay": true,
                "TransactionDetailsDiscountAmountDisplayName": "Dis Amt",
                "TransactionDetailsDiscountPercentageDisplay": false,
                "TransactionDetailsDiscountPercentageDisplayName": "Dis %",
                "TransactionDetailsHSNDisplay": true,
                "TransactionDetailsHSNDisplayName": "HSN/SAC",
                "TransactionDetailsItemDescriptionDisplay": false,
                "TransactionDetailsItemDescriptionDisplayName": "Item Description",
                "TransactionDetailsItemNameDisplay": true,
                "TransactionDetailsItemNameDisplayName": "Item Name",
                "TransactionDetailsMRPDisplay": true,
                "TransactionDetailsMRPDisplayName": "MRP",
                "TransactionDetailsQtyDisplay": true,
                "TransactionDetailsQtyDisplayName": "Qty",
                "TransactionDetailsRetailPriceDisplay": true,
                "TransactionDetailsRetailPriceDisplayName": "Retail Price",
                "TransactionDetailsSKUDisplay": true,
                "TransactionDetailsSKUDisplayName": "SKU",
                "TransactionDetailsSrNoDisplay": true,
                "TransactionDetailsSrNoDisplayName": "Sr. No.",
                "TransactionDetailsSubTotalDisplay": true,
                "TransactionDetailsSubTotalDisplayName": "Sub Total",
                "TransactionDetailsTaxDisplay": true,
                "TransactionDetailsTaxDisplayName": "Tax",
                "TransactionDetailsTotalDisplay": true,
                "TransactionDetailsTotalDisplayName": "Total",
                "TransactionDiscountDisplay": true,
                "TransactionDiscountDisplayName": "Discount",
                "TransactionNoDisplay": true,
                "TransactionNoDisplayName": "No",
                "TransactionPaidDisplay": true,
                "TransactionPaidDisplayName": "Paid",
                "TransactionRoundOffDisplay": true,
                "TransactionRoundOffDisplayName": "Round off",
                "TransactionTaxSummaryDisplay": true,
                "TransactionTaxSummaryDisplayName": "Tax Summary",
                "TransactionTotalDisplay": true,
                "TransactionTotalDisplayName": "Total",
                "TransactionTotalQtyDisplay": true,
                "TransactionTotalQtyDisplayName": "Total Qty",
                "TransactionType": "Sales Return",
                "TransactionTypeID": ""
            },
            {
                "CustomerCodeDisplay": true,
                "CustomerCodeDisplayName": "Customer Code",
                "CustomerCustomField1Display": false,
                "CustomerCustomField1DisplayName": "Custom Field 1",
                "CustomerCustomField2Display": false,
                "CustomerCustomField2DisplayName": "Custom Field 2",
                "CustomerCustomField3Display": false,
                "CustomerCustomField3DisplayName": "Custom Field 3",
                "CustomerCustomField4Display": false,
                "CustomerCustomField4DisplayName": "Custom Field 4",
                "CustomerCustomField5Display": false,
                "CustomerCustomField5DisplayName": "Custom Field 5",
                "CustomerCustomField6Display": false,
                "CustomerCustomField6DisplayName": "Custom Field 6",
                "CustomerEmailIDDisplay": true,
                "CustomerEmailIDDisplayName": "Email ID",
                "CustomerNameDisplay": true,
                "CustomerNameDisplayName": "Customer Name",
                "CustomerPhoneNoDisplay": true,
                "CustomerPhoneNoDisplayName": "Phone No",
                "FooterImage": null, "FooterText": "<p></p>\n",
                "HeaderImage": null,
                "HeaderText": "<p></p>\n", "Module": "Sales",
                "ModuleID": "sales",
                "PrintDisplayName": "SI 3 inch",
                "PrintSize": "3 inch",
                "PrintTemplate": "",
                "StoreAddressCityDisplay": true,
                "StoreAddressCityDisplayName": "City",
                "StoreAddressCountryDisplay": false,
                "StoreAddressCountryDisplayName": "Country",
                "StoreAddressLine1Display": true,
                "StoreAddressLine1DisplayName": "Address Line 1",
                "StoreAddressLine2Display": true,
                "StoreAddressLine2DisplayName": "Address Line 2",
                "StoreAddressPostalCodeDisplay": true,
                "StoreAddressPostalCodeDisplayName": "Postal Code",
                "StoreAddressStateDisplay": true,
                "StoreAddressStateDisplayName": "State",
                "StoreCodeDisplay": false,
                "StoreCodeDisplayName": "Store Code",
                "StoreCustomField1Display": false,
                "StoreCustomField1DisplayName": "Custom Field 1",
                "StoreCustomField2Display": false,
                "StoreCustomField2DisplayName": "Custom Field 2",
                "StoreCustomField3Display": false,
                "StoreCustomField3DisplayName": "Custom Field 3",
                "StoreCustomField4Display": false,
                "StoreCustomField4DisplayName": "Custom Field 4",
                "StoreCustomField5Display": false,
                "StoreCustomField5DisplayName": "Custom Field 5",
                "StoreCustomField6Display": false,
                "StoreCustomField6DisplayName": "Custom Field 6",
                "StoreEmailIDDisplay": false,
                "StoreEmailIDDisplayName": "Email ID",
                "StoreID": "",
                "StoreNameDisplay": true,
                "StoreNameDisplayName": "Store Name",
                "StorePhoneNoDisplay": true,
                "StorePhoneNoDisplayName": "Phone No",
                "TransactionBalanceDisplay": true,
                "TransactionBalanceDisplayName": "Balance",
                "TransactionDateDisplay": true,
                "TransactionDateDisplayName": "Date",
                "TransactionDetailsBarcodeDisplay": true,
                "TransactionDetailsBarcodeDisplayName": "Barcode",
                "TransactionDetailsDiscountAmountDisplay": false,
                "TransactionDetailsDiscountAmountDisplayName": "Dis Amt",
                "TransactionDetailsDiscountPercentageDisplay": false,
                "TransactionDetailsDiscountPercentageDisplayName": "Dis %",
                "TransactionDetailsHSNDisplay": true,
                "TransactionDetailsHSNDisplayName": "HSN/SAC",
                "TransactionDetailsItemDescriptionDisplay": false,
                "TransactionDetailsItemDescriptionDisplayName": "Item Description",
                "TransactionDetailsItemNameDisplay": true,
                "TransactionDetailsItemNameDisplayName": "Item Name",
                "TransactionDetailsMRPDisplay": false,
                "TransactionDetailsMRPDisplayName": "MRP",
                "TransactionDetailsQtyDisplay": true,
                "TransactionDetailsQtyDisplayName": "Qty",
                "TransactionDetailsRetailPriceDisplay": true,
                "TransactionDetailsRetailPriceDisplayName": "Retail Price",
                "TransactionDetailsSKUDisplay": true,
                "TransactionDetailsSKUDisplayName": "SKU",
                "TransactionDetailsSrNoDisplay": true,
                "TransactionDetailsSrNoDisplayName": "Sr. No.",
                "TransactionDetailsSubTotalDisplay": false,
                "TransactionDetailsSubTotalDisplayName": "Sub Total",
                "TransactionDetailsTaxDisplay": false,
                "TransactionDetailsTaxDisplayName": "Tax",
                "TransactionDetailsTotalDisplay": true,
                "TransactionDetailsTotalDisplayName": "",
                "TransactionDiscountDisplay": true,
                "TransactionDiscountDisplayName": "Discount",
                "TransactionNoDisplay": true,
                "TransactionNoDisplayName": "Invoice No",
                "TransactionPaidDisplay": true,
                "TransactionPaidDisplayName": "Paid",
                "TransactionRoundOffDisplay": true,
                "TransactionRoundOffDisplayName": "Round off",
                "TransactionTaxSummaryDisplay": true,
                "TransactionTaxSummaryDisplayName": "Tax Summary",
                "TransactionTotalDisplay": true,
                "TransactionTotalDisplayName": "Total",
                "TransactionTotalQtyDisplay": true,
                "TransactionTotalQtyDisplayName": "Total Qty.",
                "TransactionType": "Sales Invoice",
                "TransactionTypeID": ""
            },
            {
                "CustomerCodeDisplay": true,
                "CustomerCodeDisplayName": "Customer Code",
                "CustomerCustomField1Display": false,
                "CustomerCustomField1DisplayName": "Custom Field 1",
                "CustomerCustomField2Display": false,
                "CustomerCustomField2DisplayName": "Custom Field 2",
                "CustomerCustomField3Display": false,
                "CustomerCustomField3DisplayName": "Custom Field 3",
                "CustomerCustomField4Display": false,
                "CustomerCustomField4DisplayName": "Custom Field 4",
                "CustomerCustomField5Display": false,
                "CustomerCustomField5DisplayName": "Custom Field 5",
                "CustomerCustomField6Display": false,
                "CustomerCustomField6DisplayName": "Custom Field 6",
                "CustomerEmailIDDisplay": true,
                "CustomerEmailIDDisplayName": "Email ID",
                "CustomerNameDisplay": true,
                "CustomerNameDisplayName": "Customer Name",
                "CustomerPhoneNoDisplay": true,
                "CustomerPhoneNoDisplayName": "Phone No",
                "FooterImage": null,
                "FooterText": "<p></p>\n",
                "HeaderImage": null,
                "HeaderText": "<p style=\"text-align:center;\"><span style=\"font-size: 30px;\"><strong>TAX INVOICE</strong></span></p>\n",
                "Module": "Sales",
                "ModuleID": "sales",
                "PrintDisplayName": "SI A4",
                "PrintSize": "A4",
                "PrintTemplate": "",
                "StoreAddressCityDisplay": true,
                "StoreAddressCityDisplayName": "City",
                "StoreAddressCountryDisplay": true,
                "StoreAddressCountryDisplayName": "Country",
                "StoreAddressLine1Display": true,
                "StoreAddressLine1DisplayName": "Address Line 1",
                "StoreAddressLine2Display": true,
                "StoreAddressLine2DisplayName": "Address Line 2",
                "StoreAddressPostalCodeDisplay": true,
                "StoreAddressPostalCodeDisplayName": "Postal Code",
                "StoreAddressStateDisplay": true,
                "StoreAddressStateDisplayName": "State",
                "StoreCodeDisplay": false,
                "StoreCodeDisplayName": "Store Code",
                "StoreCustomField1Display": false,
                "StoreCustomField1DisplayName": "Custom Field 1",
                "StoreCustomField2Display": false,
                "StoreCustomField2DisplayName": "Custom Field 2",
                "StoreCustomField3Display": false,
                "StoreCustomField3DisplayName": "Custom Field 3",
                "StoreCustomField4Display": false,
                "StoreCustomField4DisplayName": "Custom Field 4",
                "StoreCustomField5Display": false,
                "StoreCustomField5DisplayName": "Custom Field 5",
                "StoreCustomField6Display": false,
                "StoreCustomField6DisplayName": "Custom Field 6",
                "StoreEmailIDDisplay": true,
                "StoreEmailIDDisplayName": "Email ID",
                "StoreID": "",
                "StoreNameDisplay": true,
                "StoreNameDisplayName": "Store Name",
                "StorePhoneNoDisplay": true,
                "StorePhoneNoDisplayName": "Phone No",
                "TransactionBalanceDisplay": true,
                "TransactionBalanceDisplayName": "Balance",
                "TransactionDateDisplay": true,
                "TransactionDateDisplayName": "Date",
                "TransactionDetailsBarcodeDisplay": true,
                "TransactionDetailsBarcodeDisplayName": "Barcode",
                "TransactionDetailsDiscountAmountDisplay": true,
                "TransactionDetailsDiscountAmountDisplayName": "Dis Amt",
                "TransactionDetailsDiscountPercentageDisplay": false,
                "TransactionDetailsDiscountPercentageDisplayName": "Dis %",
                "TransactionDetailsHSNDisplay": true,
                "TransactionDetailsHSNDisplayName": "HSN/SAC",
                "TransactionDetailsItemDescriptionDisplay": false,
                "TransactionDetailsItemDescriptionDisplayName": "Item Description",
                "TransactionDetailsItemNameDisplay": true,
                "TransactionDetailsItemNameDisplayName": "Item Name",
                "TransactionDetailsMRPDisplay": true,
                "TransactionDetailsMRPDisplayName": "MRP",
                "TransactionDetailsQtyDisplay": true,
                "TransactionDetailsQtyDisplayName": "Qty",
                "TransactionDetailsRetailPriceDisplay": true,
                "TransactionDetailsRetailPriceDisplayName": "Retail Price",
                "TransactionDetailsSKUDisplay": true,
                "TransactionDetailsSKUDisplayName": "SKU",
                "TransactionDetailsSrNoDisplay": true,
                "TransactionDetailsSrNoDisplayName": "Sr. No.",
                "TransactionDetailsSubTotalDisplay": false,
                "TransactionDetailsSubTotalDisplayName": "Sub Total",
                "TransactionDetailsTaxDisplay": true,
                "TransactionDetailsTaxDisplayName": "Tax",
                "TransactionDetailsTotalDisplay": true,
                "TransactionDetailsTotalDisplayName": "Total",
                "TransactionDiscountDisplay": true,
                "TransactionDiscountDisplayName": "Discount",
                "TransactionNoDisplay": true,
                "TransactionNoDisplayName": "No",
                "TransactionPaidDisplay": true,
                "TransactionPaidDisplayName": "Paid",
                "TransactionRoundOffDisplay": true,
                "TransactionRoundOffDisplayName": "Round off",
                "TransactionTaxSummaryDisplay": true,
                "TransactionTaxSummaryDisplayName": "Tax Summary",
                "TransactionTotalDisplay": true,
                "TransactionTotalDisplayName": "Total",
                "TransactionTotalQtyDisplay": true,
                "TransactionTotalQtyDisplayName": "Total Qty.",
                "TransactionType": "Sales Invoice",
                "TransactionTypeID": ""
            }, {
                "CustomerCodeDisplay": true,
                "CustomerCodeDisplayName": "Customer Code",
                "CustomerCustomField1Display": false,
                "CustomerCustomField1DisplayName": "Custom Field 1",
                "CustomerCustomField2Display": false,
                "CustomerCustomField2DisplayName": "Custom Field 2",
                "CustomerCustomField3Display": false,
                "CustomerCustomField3DisplayName": "Custom Field 3",
                "CustomerCustomField4Display": false,
                "CustomerCustomField4DisplayName": "Custom Field 4",
                "CustomerCustomField5Display": false,
                "CustomerCustomField5DisplayName": "Custom Field 5",
                "CustomerCustomField6Display": false,
                "CustomerCustomField6DisplayName": "Custom Field 6",
                "CustomerEmailIDDisplay": true,
                "CustomerEmailIDDisplayName": "Email ID",
                "CustomerNameDisplay": true,
                "CustomerNameDisplayName": "Customer Name",
                "CustomerPhoneNoDisplay": true,
                "CustomerPhoneNoDisplayName": "Phone No",
                "FooterImage": null,
                "FooterText": "<p></p>\n",
                "HeaderImage": null,
                "HeaderText": "<p style=\"text-align:center;\"><span style=\"font-size: 30px;\"><strong>TAX INVOICE</strong></span></p>\n",
                "Module": "Sales",
                "ModuleID": "sales",
                "PrintDisplayName": "SI A5",
                "PrintSize": "A5",
                "PrintTemplate": "",
                "StoreAddressCityDisplay": true,
                "StoreAddressCityDisplayName": "City",
                "StoreAddressCountryDisplay": true,
                "StoreAddressCountryDisplayName": "Country",
                "StoreAddressLine1Display": true,
                "StoreAddressLine1DisplayName": "Address Line 1",
                "StoreAddressLine2Display": true,
                "StoreAddressLine2DisplayName": "Address Line 2",
                "StoreAddressPostalCodeDisplay": true,
                "StoreAddressPostalCodeDisplayName": "Postal Code",
                "StoreAddressStateDisplay": true,
                "StoreAddressStateDisplayName": "State",
                "StoreCodeDisplay": false,
                "StoreCodeDisplayName": "Store Code",
                "StoreCustomField1Display": false,
                "StoreCustomField1DisplayName": "Custom Field 1",
                "StoreCustomField2Display": false,
                "StoreCustomField2DisplayName": "Custom Field 2",
                "StoreCustomField3Display": false,
                "StoreCustomField3DisplayName": "Custom Field 3",
                "StoreCustomField4Display": false,
                "StoreCustomField4DisplayName": "Custom Field 4",
                "StoreCustomField5Display": false,
                "StoreCustomField5DisplayName": "Custom Field 5",
                "StoreCustomField6Display": false,
                "StoreCustomField6DisplayName": "Custom Field 6",
                "StoreEmailIDDisplay": true,
                "StoreEmailIDDisplayName": "Email ID",
                "StoreID": "",
                "StoreNameDisplay": true,
                "StoreNameDisplayName": "Store Name",
                "StorePhoneNoDisplay": true,
                "StorePhoneNoDisplayName": "Phone No",
                "TransactionBalanceDisplay": true,
                "TransactionBalanceDisplayName": "Balance",
                "TransactionDateDisplay": true,
                "TransactionDateDisplayName": "Date",
                "TransactionDetailsBarcodeDisplay": true,
                "TransactionDetailsBarcodeDisplayName": "Barcode",
                "TransactionDetailsDiscountAmountDisplay": true,
                "TransactionDetailsDiscountAmountDisplayName": "Dis Amt",
                "TransactionDetailsDiscountPercentageDisplay": false,
                "TransactionDetailsDiscountPercentageDisplayName": "Dis %",
                "TransactionDetailsHSNDisplay": true,
                "TransactionDetailsHSNDisplayName": "HSN/SAC",
                "TransactionDetailsItemDescriptionDisplay": false,
                "TransactionDetailsItemDescriptionDisplayName": "Item Description",
                "TransactionDetailsItemNameDisplay": true,
                "TransactionDetailsItemNameDisplayName": "Item Name",
                "TransactionDetailsMRPDisplay": true,
                "TransactionDetailsMRPDisplayName": "MRP",
                "TransactionDetailsQtyDisplay": true,
                "TransactionDetailsQtyDisplayName": "Qty",
                "TransactionDetailsRetailPriceDisplay": true,
                "TransactionDetailsRetailPriceDisplayName": "Retail Price",
                "TransactionDetailsSKUDisplay": true,
                "TransactionDetailsSKUDisplayName": "SKU",
                "TransactionDetailsSrNoDisplay": true,
                "TransactionDetailsSrNoDisplayName": "Sr. No.",
                "TransactionDetailsSubTotalDisplay": false,
                "TransactionDetailsSubTotalDisplayName": "Sub Total",
                "TransactionDetailsTaxDisplay": true,
                "TransactionDetailsTaxDisplayName": "Tax",
                "TransactionDetailsTotalDisplay": true,
                "TransactionDetailsTotalDisplayName": "Total",
                "TransactionDiscountDisplay": true,
                "TransactionDiscountDisplayName": "Discount",
                "TransactionNoDisplay": true,
                "TransactionNoDisplayName": "No",
                "TransactionPaidDisplay": true,
                "TransactionPaidDisplayName": "Paid",
                "TransactionRoundOffDisplay": true,
                "TransactionRoundOffDisplayName": "Round off",
                "TransactionTaxSummaryDisplay": true,
                "TransactionTaxSummaryDisplayName": "Tax Summary",
                "TransactionTotalDisplay": true,
                "TransactionTotalDisplayName": "Total",
                "TransactionTotalQtyDisplay": true,
                "TransactionTotalQtyDisplayName": "Total Qty.",
                "TransactionType": "Sales Invoice",
                "TransactionTypeID": ""
            }, {
                "CustomerCodeDisplay": true,
                "CustomerCodeDisplayName": "Customer Code",
                "CustomerCustomField1Display": false,
                "CustomerCustomField1DisplayName": "Custom Field 1",
                "CustomerCustomField2Display": false,
                "CustomerCustomField2DisplayName": "Custom Field 2",
                "CustomerCustomField3Display": false,
                "CustomerCustomField3DisplayName": "Custom Field 3",
                "CustomerCustomField4Display": false,
                "CustomerCustomField4DisplayName": "Custom Field 4",
                "CustomerCustomField5Display": false,
                "CustomerCustomField5DisplayName": "Custom Field 5",
                "CustomerCustomField6Display": false,
                "CustomerCustomField6DisplayName": "Custom Field 6",
                "CustomerEmailIDDisplay": true,
                "CustomerEmailIDDisplayName": "Email ID",
                "CustomerNameDisplay": true,
                "CustomerNameDisplayName": "Customer Name",
                "CustomerPhoneNoDisplay": true,
                "CustomerPhoneNoDisplayName": "Phone No",
                "FooterImage": null, "FooterText": "<p></p>\n", "HeaderImage": null,
                "HeaderText": "<p></p>\n",
                "Module": "Sales",
                "ModuleID": "sales",
                "PrintDisplayName": "SR A5",
                "PrintSize": "A5",
                "PrintTemplate": "",
                "RegistrationID": "",
                "StoreAddressCityDisplay": true,
                "StoreAddressCityDisplayName": "City",
                "StoreAddressCountryDisplay": false,
                "StoreAddressCountryDisplayName": "Country",
                "StoreAddressLine1Display": true,
                "StoreAddressLine1DisplayName": "Address Line 1",
                "StoreAddressLine2Display": true,
                "StoreAddressLine2DisplayName": "Address Line 2",
                "StoreAddressPostalCodeDisplay": true,
                "StoreAddressPostalCodeDisplayName": "Postal Code",
                "StoreAddressStateDisplay": true,
                "StoreAddressStateDisplayName": "State",
                "StoreCodeDisplay": false,
                "StoreCodeDisplayName": "Store Code",
                "StoreCustomField1Display": false,
                "StoreCustomField1DisplayName": "Custom Field 1",
                "StoreCustomField2Display": false,
                "StoreCustomField2DisplayName": "Custom Field 2",
                "StoreCustomField3Display": false,
                "StoreCustomField3DisplayName": "Custom Field 3",
                "StoreCustomField4Display": false,
                "StoreCustomField4DisplayName": "Custom Field 4",
                "StoreCustomField5Display": false,
                "StoreCustomField5DisplayName": "Custom Field 5",
                "StoreCustomField6Display": false,
                "StoreCustomField6DisplayName": "Custom Field 6",
                "StoreEmailIDDisplay": true,
                "StoreEmailIDDisplayName": "Email ID",
                "StoreID": "",
                "StoreNameDisplay": true,
                "StoreNameDisplayName": "Store Name",
                "StorePhoneNoDisplay": true,
                "StorePhoneNoDisplayName": "Phone No",
                "TransactionBalanceDisplay": true,
                "TransactionBalanceDisplayName": "Balance",
                "TransactionDateDisplay": true,
                "TransactionDateDisplayName": "Date",
                "TransactionDetailsBarcodeDisplay": false,
                "TransactionDetailsBarcodeDisplayName": "Barcode",
                "TransactionDetailsDiscountAmountDisplay": false,
                "TransactionDetailsDiscountAmountDisplayName": "Dis Amt",
                "TransactionDetailsDiscountPercentageDisplay": false,
                "TransactionDetailsDiscountPercentageDisplayName": "Dis %",
                "TransactionDetailsHSNDisplay": false,
                "TransactionDetailsHSNDisplayName": "HSN/SAC",
                "TransactionDetailsItemDescriptionDisplay": false,
                "TransactionDetailsItemDescriptionDisplayName": "Item Description",
                "TransactionDetailsItemNameDisplay": true,
                "TransactionDetailsItemNameDisplayName": "Item Name",
                "TransactionDetailsMRPDisplay": false,
                "TransactionDetailsMRPDisplayName": "MRP",
                "TransactionDetailsQtyDisplay": true,
                "TransactionDetailsQtyDisplayName": "Qty",
                "TransactionDetailsRetailPriceDisplay": true,
                "TransactionDetailsRetailPriceDisplayName": "Retail Price",
                "TransactionDetailsSKUDisplay": true,
                "TransactionDetailsSKUDisplayName": "SKU",
                "TransactionDetailsSrNoDisplay": true,
                "TransactionDetailsSrNoDisplayName": "Sr. No.",
                "TransactionDetailsSubTotalDisplay": false,
                "TransactionDetailsSubTotalDisplayName": "Sub Total",
                "TransactionDetailsTaxDisplay": false,
                "TransactionDetailsTaxDisplayName": "Tax",
                "TransactionDetailsTotalDisplay": true,
                "TransactionDetailsTotalDisplayName": "Total",
                "TransactionDiscountDisplay": true,
                "TransactionDiscountDisplayName": "Discount",
                "TransactionNoDisplay": true,
                "TransactionNoDisplayName": "No",
                "TransactionPaidDisplay": true,
                "TransactionPaidDisplayName": "Paid",
                "TransactionRoundOffDisplay": true,
                "TransactionRoundOffDisplayName": "Round off",
                "TransactionTaxSummaryDisplay": true,
                "TransactionTaxSummaryDisplayName": "Tax Summary",
                "TransactionTotalDisplay": true,
                "TransactionTotalDisplayName": "Total",
                "TransactionTotalQtyDisplay": true,
                "TransactionTotalQtyDisplayName": "Total Qty.",
                "TransactionType": "Sales Return",
                "TransactionTypeID": ""
            }],
            GlobalConfiguration: [
                {

                    "RoleName": "_Power User",
                    "Modules":
                        [
                            {
                                "name": "Campaigns",
                                "perms": { "read": true, "create": true, "update": true, "delete": true }
                            },
                            {
                                "name": "Products",
                                "perms": { "read": true, "create": true, "update": true, "delete": true }
                            },
                            {
                                "name": "ProductCategories",
                                "perms": { "read": true, "create": true, "update": true, "delete": true }
                            },
                            {
                                "name": "ProductBrands",
                                "perms": { "read": true, "create": true, "update": true, "delete": true }
                            },
                            {
                                "name": "ProductTaxes",
                                "perms": { "read": true, "create": true, "update": true, "delete": true }
                            },
                            {
                                "name": "Customers",
                                "perms": { "read": true, "create": true, "update": true, "delete": true }
                            },
                            {
                                "name": "Memberships",
                                "perms": { "read": true, "create": true, "update": true, "delete": true }
                            },
                            {
                                "name": "Sales",
                                "perms": { "read": true, "create": true, "update": true, "delete": true }
                            },
                            {
                                "name": "WalletLedgers",
                                "perms": { "read": true, "create": true, "update": true, "delete": true }
                            },
                            {
                                "name": "Registration",
                                "perms": { "read": true, "create": true, "update": true, "delete": true }
                            },
                            {
                                "name": "Stores",
                                "perms": { "read": true, "create": true, "update": true, "delete": true }
                            },
                            {
                                "name": "StoreLocations",
                                "perms": { "read": true, "create": true, "update": true, "delete": true }
                            },
                            {
                                "name": "CashRegisters",
                                "perms": { "read": true, "create": true, "update": true, "delete": true }
                            },
                            {
                                "name": "Employees",
                                "perms": { "read": true, "create": true, "update": true, "delete": true }
                            },
                            {
                                "name": "Vendors",
                                "perms": { "read": true, "create": true, "update": true, "delete": true }
                            },
                            {
                                "name": "PaymentTypes",
                                "perms": { "read": true, "create": true, "update": true, "delete": true }
                            },
                            {
                                "name": "TransactionTypes",
                                "perms": { "read": true, "create": true, "update": true, "delete": true }
                            },
                            {
                                "name": "User",
                                "perms": { "read": true, "create": true, "update": true, "delete": true }
                            },
                            {
                                "name": "TransactionRunningNo",
                                "perms": { "read": true, "create": true, "update": true, "delete": true }
                            },
                            {
                                "name": "PrintConfigs",
                                "perms": { "read": true, "create": true, "update": true, "delete": true }
                            },
                            {
                                "name": "Expenses",
                                "perms": { "read": true, "create": true, "update": true, "delete": true }
                            },
                            {
                                "name": "ExpenseCategories",
                                "perms": { "read": true, "create": true, "update": true, "delete": true }
                            },
                            {
                                "name": "Import",
                                "perms": { "read": true, "create": true, "update": true, "delete": true }
                            },
                            {
                                "name": "Post",
                                "perms": { "read": false, "create": true, "update": true, "delete": true }
                            },
                            {
                                "name": "OnBoarding",
                                "perms": { "read": false, "create": true, "update": true, "delete": true }
                            },
                            {
                                "name": "ACL",
                                "perms": { "read": false, "create": true, "update": true, "delete": true }
                            },
                            {
                                "name": "Roles",
                                "perms": { "read": false, "create": true, "update": true, "delete": true }
                            },
                            {
                                "name": "OTP",
                                "perms": { "read": false, "create": true, "update": true, "delete": true }
                            },
                            {
                                "name": "CustomFields",
                                "perms": { "read": false, "create": true, "update": true, "delete": true }
                            }]
                }]      ,
            Roles: [
                {
                    "RoleName": "_Power User",
                    "Modules": [{
                        "name": "***",
                        "perms": { "read": true, "create": true, "update": true, "delete": true }
                    }]
                },
                {
                    "RoleName": "_Store Owner",
                    "Modules": [
                        {
                            "name": "Products",
                            "perms": { "read": true, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "ProductCategories",
                            "perms": { "read": true, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "ProductBrands",
                            "perms": { "read": true, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "ProductTaxes",
                            "perms": { "read": true, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "Customers",
                            "perms": { "read": true, "create": true, "update": true, "delete": false }
                        },
                        {
                            "name": "Memberships",
                            "perms": { "read": true, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "Sales",
                            "perms": { "read": true, "create": true, "update": true, "delete": true }
                        },
                        {
                            "name": "WalletLedgers",
                            "perms": { "read": true, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "Registration",
                            "perms": { "read": true, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "Stores",
                            "perms": { "read": true, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "StoreLocations",
                            "perms": { "read": true, "create": true, "update": true, "delete": true }
                        },
                        {
                            "name": "CashRegisters",
                            "perms": { "read": true, "create": true, "update": true, "delete": true }
                        },
                        {
                            "name": "Employees",
                            "perms": { "read": true, "create": true, "update": true, "delete": true }
                        },
                        {
                            "name": "Vendors",
                            "perms": { "read": true, "create": true, "update": true, "delete": true }
                        },
                        {
                            "name": "PaymentTypes",
                            "perms": { "read": true, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "TransactionTypes",
                            "perms": { "read": true, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "CustomFields",
                            "perms": { "read": true, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "User",
                            "perms": { "read": true, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "TransactionRunningNo",
                            "perms": { "read": true, "create": true, "update": true, "delete": false }
                        },
                        {
                            "name": "PrintConfigs",
                            "perms": { "read": true, "create": true, "update": true, "delete": true }
                        },
                        {
                            "name": "Expenses",
                            "perms": { "read": true, "create": true, "update": true, "delete": true }
                        },
                        {
                            "name": "ExpenseCategories",
                            "perms": { "read": true, "create": true, "update": true, "delete": true }
                        },
                        {
                            "name": "Import",
                            "perms": { "read": true, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "Post",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "OnBoarding",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "ACL",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "Roles",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "OTP",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "Campaigns",
                            "perms": { "read": true, "create": true, "update": true, "delete": false }
                        },
                        {
                            "name": "SMS",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                    ]
                },
                {
                    "RoleName": "_Store Manager",
                    "Modules": [
                        {
                            "name": "Products",
                            "perms": { "read": true, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "ProductCategories",
                            "perms": { "read": true, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "ProductBrands",
                            "perms": { "read": true, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "ProductTaxes",
                            "perms": { "read": true, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "Customers",
                            "perms": { "read": true, "create": true, "update": true, "delete": false }
                        },
                        {
                            "name": "Memberships",
                            "perms": { "read": true, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "Sales",
                            "perms": { "read": true, "create": true, "update": true, "delete": true }
                        },
                        {
                            "name": "WalletLedgers",
                            "perms": { "read": true, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "Registration",
                            "perms": { "read": true, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "Stores",
                            "perms": { "read": true, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "StoreLocations",
                            "perms": { "read": true, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "CashRegisters",
                            "perms": { "read": true, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "Employees",
                            "perms": { "read": true, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "Vendors",
                            "perms": { "read": true, "create": true, "update": true, "delete": true }
                        },
                        {
                            "name": "PaymentTypes",
                            "perms": { "read": true, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "TransactionTypes",
                            "perms": { "read": true, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "CustomFields",
                            "perms": { "read": true, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "User",
                            "perms": { "read": true, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "TransactionRunningNo",
                            "perms": { "read": true, "create": true, "update": true, "delete": false }
                        },
                        {
                            "name": "PrintConfigs",
                            "perms": { "read": true, "create": true, "update": true, "delete": true }
                        },
                        {
                            "name": "Expenses",
                            "perms": { "read": true, "create": true, "update": true, "delete": true }
                        },
                        {
                            "name": "ExpenseCategories",
                            "perms": { "read": true, "create": true, "update": true, "delete": true }
                        },
                        {
                            "name": "Import",
                            "perms": { "read": true, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "Post",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "OnBoarding",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "ACL",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "Roles",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "OTP",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "Campaigns",
                            "perms": { "read": true, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "SMS",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                    ]
                },
                {
                    "RoleName": "_Store Cashier",
                    "Modules": [
                        {
                            "name": "Products",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "ProductCategories",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "ProductBrands",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "ProductTaxes",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "Customers",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "Memberships",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "Sales",
                            "perms": { "read": false, "create": true, "update": false, "delete": false }
                        },
                        {
                            "name": "WalletLedgers",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "Registration",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "Stores",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "StoreLocations",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "CashRegisters",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "Employees",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "Vendors",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "PaymentTypes",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "TransactionTypes",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "CustomFields",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "User",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "TransactionRunningNo",
                            "perms": { "read": false, "create": true, "update": true, "delete": true }
                        },
                        {
                            "name": "PrintConfigs",
                            "perms": { "read":false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "Expenses",
                            "perms": { "read": false, "create": true, "update": false, "delete": false }
                        },
                        {
                            "name": "ExpenseCategories",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "Import",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "Post",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "OnBoarding",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "ACL",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "Roles",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "OTP",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "Campaigns",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                        {
                            "name": "SMS",
                            "perms": { "read": false, "create": false, "update": false, "delete": false }
                        },
                    ]
                }
            ]
        }
        this.onSavePaymenTypes = this.onSavePaymenTypes.bind(this)
        this.onSaveModuleConfig = this.onSaveModuleConfig.bind(this)
        this.onSaveTax = this.onSaveTax.bind(this)
        this.onSavePrintConfigs = this.onSavePrintConfigs.bind(this)
        this.onCreateRoles = this.onCreateRoles.bind(this)
        this.onCreateGlobalConfiguration = this.onCreateGlobalConfiguration.bind(this)
        this.updateInvoices = this.updateInvoices.bind(this)
        this.updateProducts = this.updateProducts.bind(this)
        this.updateExpenses = this.updateExpenses.bind(this)
        this.onFileLoaded = this.onFileLoaded.bind(this);
    }

    onFileLoaded(collection) {
        let ctr = 0

        for (let i = 0; i < collection.length; i++) {
            const data = collection[i];
            if (i > 0) {
                if(data[0]!="")
                {
                    database.collection("Customers").where("RegistrationID", "==", "xhM8ciW27oswSZqbocmB").where("PhoneNo", "==", data[0]).get().then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            ctr = ctr + 1;
                            database.collection("Customers").doc(doc.id).update({
                                "Membership.MembershipID": data[2], "Membership.Name": data[1]
                            }).then(() => {
                                console.log("Records Updated: ", ctr)
                            })
                        })
                    })
                }
            }
        }
    }


    onSavePaymenTypes() {
        this.props.createMasters(this.state.PaymentTypes, "PaymentTypes");
    }

    onSaveModuleConfig() {
        this.props.createMasters(this.state.ModuleConfigs, "ModuleConfigs")
    }

    onSaveTax() {
        this.props.createMasters(this.state.Taxes, "Taxes")
    }
    
    onSavePrintConfigs() {
        this.props.createMasters(this.state.PrintConfigs, "PrintConfigs")
    }

    onCreateRoles() {
        let roles = this.state.Roles;
        for (let i = 0; i < roles.length; i++) {
            let role = roles[i];
            for (let j = 0; j < roles[i].Modules.length; j++) {
                console.log(role.RoleName + " " + roles[i].Modules[j].name + " " + roles[i].Modules[j].perms);
                this.props.createRoles(role.RoleName, roles[i].Modules[j].name, roles[i].Modules[j].perms);
            }
        }
    }

    onCreateGlobalConfiguration() {
        let roles = this.state.GlobalConfiguration;
        for (let i = 0; i < roles.length; i++) {
            let role = roles[i];
            for (let j = 0; j < roles[i].Modules.length; j++) {
                this.props.createGlobalConfigurationRoles(role.RoleName, roles[i].Modules[j].name, roles[i].Modules[j].perms);
            }
        }
    }

    updateInvoices()
    {
        this.props.updateInvoices();
    }
    
    updateProducts()
    {
        this.props.updateProducts();
    }
    
    updateExpenses()
    {
        this.props.updateExpenses();
    }
    

    render() {
        return <div className="container">
            <div className="content-wrapper">
                <div className="box-body">
                    <button type="submit"
                        style={{ marginBottom: "10px", marginLeft: "10px" }}
                        className="btn btn-md btn-success btn-flat pull-right"
                        onClick={this.updateInvoices}>Update Invoices
                    </button>
                {/* <CSVReader
                    cssClass="csv-input"
                    label="Select .csv file"
                    onFileLoaded={this.onFileLoaded}
                    inputId="ObiWan"
                /> */}

                    {/* <button type="submit"
                        style={{ marginBottom: "10px", marginLeft: "10px" }}
                        className="btn btn-md btn-success btn-flat pull-right"
                        onClick={this.onSavePaymenTypes}>Save Payment Type
                    </button>
                    <button type="submit"
                        style={{ marginBottom: "10px", marginLeft: "10px" }}
                        className="btn btn-md btn-success btn-flat pull-right"
                        onClick={this.onSaveModuleConfig}>Save Module Config
                    </button>
                    <button type="submit"
                        style={{ marginBottom: "10px", marginLeft: "10px" }}
                        className="btn btn-md btn-success btn-flat pull-right"
                        onClick={this.onSaveTax}>Save Taxes
                    </button>
                    <button type="submit"
                        style={{ marginBottom: "10px", marginLeft: "10px" }}
                        className="btn btn-md btn-success btn-flat pull-right"
                        onClick={this.onSavePrintConfigs}>Save Print Configs
                    </button>
                    <button type="submit"
                        style={{ marginBottom: "10px", marginLeft: "10px" }}
                        className="btn btn-md btn-success btn-flat pull-right"
                        onClick={this.onCreateRoles}>Create Roles
                    </button>
                    <button type="submit"
                        style={{ marginBottom: "10px", marginLeft: "10px" }}
                        className="btn btn-md btn-success btn-flat pull-right"
                        onClick={this.onCreateGlobalConfiguration}>Global Configuration - Powe User
                    </button> */}
                    {/*  */}
                    {/* <button type="submit"
                        style={{ marginBottom: "10px", marginLeft: "10px" }}
                        className="btn btn-md btn-success btn-flat pull-right"
                        onClick={this.updateProducts}>Update Products
                    </button> */}
                    {/* <button type="submit"
                        style={{ marginBottom: "10px", marginLeft: "10px" }}
                        className="btn btn-md btn-success btn-flat pull-right"
                        onClick={this.updateExpenses}>Update Expenses
                    </button> */}
                </div>
            </div>
        </div>
    }
}

export default withRouter(OnBoarding)