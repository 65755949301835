import React, { Component } from 'react';
import Loader from './Loader'
import { isNullOrUndefined } from 'util';
import * as constants from '../Constatnts'

class ImportLog extends Component {

    constructor() {
        super();
        this.state = {
            logList: [],
            loading: true
        }
    }

    componentDidMount() {
        this.props.getImportLogList(this.props.user.user.RegistrationID, this.props.importType).then((logList) => {
            this.setState({ logList: logList, loading: false })
        })
    }

    onExportClick(key) {
        // alert(key)
        this.setState({ exportCalled: true })
        this.props.getImportLogs(key)
            .then((result) => {

                let importLogs = []
                result.map((log) => {
                    if(this.props.importType.toLowerCase()==="product")
                    {
                        let importLog = {
                            SKU : log.SKU,
                            BarCode : log.BarCode,
                            Brand : log.Brand,
                            Category : log.Category,
                            SubCategory : log.SubCategory,
                            Name : log.Name,
                            Description : log.Description,
                            HSNSACCode : log.HSNSACCode,
                            ChargeTaxOnProduct : log.ChargeTaxOnProduct,
                            MRP : log.MRP,
                            SupplyPrice : log.SupplyPrice,
                            RetailPrice : log.RetailPrice,
                            Markup : log.Markup,
                            TaxInclusive : log.TaxInclusive,
                            TaxGroup : log.TaxGroup,
                            PriceBasedTax : log.PriceBasedTax,
                            PriceRangeFrom1 : log.PriceRangeFrom1,
                            PriceRangeTo1 : log.PriceRangeTo1,
                            TaxGroup1 : log.TaxGroup1,
                            PriceRangeFrom2 : log.PriceRangeFrom2,
                            PriceRangeTo2 : log.PriceRangeTo2,
                            TaxGroup2 : log.TaxGroup2,
                            ProductType : log.ProductType,
                            AllowDiscount : log.AllowDiscount,
                            AllowPriceEdit : log.AllowPriceEdit,
                            AllowTaxChange : log.AllowTaxChange,
                            WalletTopUpAmount : log.WalletTopUpAmount,
                            WalletTopUpSameAsRetailPrice : log.WalletTopUpSameAsRetailPrice,
                            Status:log.Status,
                            Error:log.Error,
                            ActionOn : (new Date(log.ActionOn.seconds * 1000)).toString().substring(0, 24),
                            ProductID:log.key

                        }
                        importLogs.push(importLog);
                    }
                    else
                    {
                        let importLog = {
                            CustomerCode : log.CustomerCode,
                            FirstName : log.FirstName,
                            LastName : log.LastName,
                            Gender : log.Gender,
                            PhoneNo : log.PhoneNo,
                            EmailID : log.EmailID,
                            Notes : log.Notes,
                            DOB : log.DOB,
                            Anniversary : log.Anniversary,
                            AddressType : log.AddressType,
                            AddressLine1 : log.AddressLine1,
                            AddressLine2 : log.AddressLine2,
                            City : log.City,
                            State : log.State,
                            PostalCode : log.PostalCode,
                            Country : log.Country,
                            CustomField1 : log.CustomField1,
                            CustomField2 : log.CustomField2,
                            CustomField3 : log.CustomField3,
                            CustomField4 : log.CustomField4,
                            CustomField5 : log.CustomField5,
                            CustomField6 : log.CustomField6,
                            Membership : log.Membership,
                            WalletOpeningBalance : log.WalletOpeningBalance,
                            OutstandingAmount : log.OutstandingAmount,
                            Status:log.Status,
                            Error:log.Error,
                            ActionOn : (new Date(log.ActionOn.seconds * 1000)).toString().substring(0, 24),
                            CustomerID: log.key
                        }
                        importLogs.push(importLog);
                    }
                })
                const csvData = constants.objToCSV(importLogs);
                this.setState({ loading: false, exportCalled: false })
                const csvBlob = new Blob([csvData], { type: "text/csv" });
                const url = window.URL.createObjectURL(csvBlob);
                const a = document.createElement("a");
                a.setAttribute("hidden", "");
                a.setAttribute("href", url);
                a.setAttribute("download", "ImportLogs.csv");
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
    }

    render() {
        return <div style={{ width: "700px" }}>
            <h3 style={{ marginTop: "0px" }}>Import Log</h3>
            <div className="box-body">
                {this.state.loading && <Loader />}
                {!this.state.loading && 
                    <div style={{ overflowY: "scroll",height: "60vh" }} className="scroll2">
                        <table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th style={{ width: "10%" }}>Date & time</th>
                                    <th style={{ width: "20%" }}>Action By</th>
                                    <th style={{ width: "10%" }}>Processing Start Time</th>
                                    <th style={{ width: "10%" }}>Processing End Time</th>
                                    <th style={{ width: "20%" }}>File</th>
                                    <th style={{ width: "20%" }}>Status</th>
                                    <th style={{ width: "10%" }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.logList.map((log) =>
                                    <tr>
                                        <td>{(new Date(log.ActionOn.seconds * 1000)).toString().substring(0, 24)}</td>
                                        <td><div>{log.ActionBy}</div>
                                            <div>{log.ActionByEmailID}</div>
                                        </td>
                                        <td>
                                            {log.ProcessingStartTime ? (new Date(log.ProcessingStartTime.seconds * 1000)).toString().substring(0, 24) : ""}
                                        </td>
                                        <td>
                                            {log.ProcessingEndTime ? (new Date(log.ProcessingEndTime.seconds * 1000)).toString().substring(0, 24) : ""}
                                        </td>
                                        <td>{log.FileName}</td>
                                        <td>{log.Status}</td>
                                        {/* {log.Status!="Completed" && <td></td>}   */}
                                        {/* {log.Status==="Completed" &&  */}
                                        <td><a onClick={() => this.onExportClick(log.key)}><i className="glyphicon glyphicon-download-alt"></i></a></td>
                                        {/* } */}
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                }
            </div>
        </div>
    }
}
export default ImportLog