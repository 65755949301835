import React, {Component} from 'react'
import { isNull } from 'util';
import { withRouter } from 'react-router-dom';
import firebase, { auth } from '../firebase'

class AuthenticatedComponent extends Component{

    componentDidMount() {
        //console.log('AuthComp.componentDidUpdate',this.props.user)
        // if(this.props.location.pathname==="/register")
        // {
        //     this.setState({showChildren:false})
        // }
        // else 
        let publicPath= false 
        console.log('this.props.location.pathname',this.props.location.pathname)
        if(this.props.location.pathname==="/register" )
        {
            publicPath= true
        }
        else if(this.props.location.pathname.includes('/salestransactionview/') )
        {
            publicPath= true
        }
        if (isNull(this.props.user.user) && !publicPath) {
            this.props.history.push('/');
            this.setState({showChildren:false})
        }
        // else
        // {
        //     this.setState({showChildren:true})
        // }
    }

    componentWillMount() {
        var connectedRef = firebase.database().ref(".info/connected");
        connectedRef.on("value", function (snap) {
          this.props.saveConnectionStatus(snap.val(),'system');
          if (snap.val() === true) {
            //this.setState({ offline: false, connectionStatus: 'online' })
            console.log("connected")
          } else {
            //this.setState({ offline: true, connectionStatus: 'offline' })
            console.log("not connected")
          }
        }.bind(this));
      }
      
    render ()
    {
        //console.log('Authenticatedcomponet', this.props.user)
        return !isNull(this.props.user.user) ? <div>{this.props.children}</div> : null;
        // return this.state.showChildren? <div>{this.props.children}</div> : null;
    }
}

export default withRouter(AuthenticatedComponent)