import React, { Component } from 'react';
import Confirm from 'react-confirm-bootstrap'
import Modal from 'react-responsive-modal'
import AddEditExpenseCategories from './AddEditExpenseCategories'
import AuditTrail from '../AuditTrail'
import * as constants from '../../Constatnts'

class ExpenseCategoriesList extends Component {
    constructor() {
        super()
        this.state = {
            isShowingModalAddExpenseCategory: false,
            expenseCategory: [],
            showModal: false,
            selectedExpenseCategory: null
        }
        this.onPostExpenseCategoriesSave = this.onPostExpenseCategoriesSave.bind(this)
        this.loadExpenseCategories = this.loadExpenseCategories.bind(this)
        this.deleteExpensecategories = this.deleteExpensecategories.bind(this)
    }

    componentDidMount() {
        this.loadExpenseCategories()
    }

    loadExpenseCategories() {
        this.props.getExpenseCategories(this.props.user.user.RegistrationID, this.props.user.store.key).then((expenseCategory) => {
            this.setState({ expenseCategory: expenseCategory })
        })
    }

    onPostExpenseCategoriesSave() {
        this.setState({ isShowingModalAddExpenseCategory: false })
        this.loadExpenseCategories()
    }

    deleteExpensecategories(Expense) {
        this.props.deleteExpenseCategories(Expense.key).then(() => {
            this.props.getToastr("Expense Category deleted successfully")
            this.loadExpenseCategories()
        })
    }

    render() {
        const btnColStyle = { width: "100px" }
        const modalWidth = { width: "500px" }

        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6">
                                        <font style={{ fontSize: "30px" }}>Expense Category</font>
                                    </div>
                                    <div className="col-md-6">

                                        <div className="pull-right">
                                            <button type="button" className="btn btn-success btn-flat  btn-md"
                                                onClick={() => this.setState({
                                                    isShowingModalAddExpenseCategory: true,
                                                    selectedExpenseCategory: null
                                                })}>Add New Expense Category</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                {this.state.expenseCategory && <table className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>Expense Category</th>
                                            <th>Revision History</th>
                                            <th style={btnColStyle}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.expenseCategory.map((expenseCategory) =>
                                            <tr>
                                                <td>{expenseCategory.ExpenseCategory}</td>
                                                <td>
                                                <div>Action :{expenseCategory.Action}</div>
                                                <div>Action By :{expenseCategory.ActionBy}</div>                                                        
                                                <div>Action Date :{(new Date(expenseCategory.ActionOn.seconds*1000)).toString().substring(0,24)}</div>
                                                <AuditTrail {...this.props} module={constants.MODULE_EXPENSE_CATEGORY} parentKey={expenseCategory.key}/>
                                            </td>
                                                <td>
                                                    <div className="btn-group">
                                                        {expenseCategory.IsDeleted ? <span className="label label-danger pull-right">Inactive</span> :
                                                            <div>
                                                                <button type="button" className="btn btn-md btn-flat btn-default"
                                                                    onClick={() => this.setState({
                                                                        isShowingModalAddExpenseCategory: true,
                                                                        selectedExpenseCategory: expenseCategory
                                                                    })}>
                                                                    <i className="fa fa-edit"></i></button>
                                                                <Confirm
                                                                    onConfirm={() => this.deleteExpensecategories(expenseCategory)}
                                                                    body="Are you sure you would like to delete this Expense Category?"
                                                                    confirmText="Delete"
                                                                    title="Expense Category Delete">
                                                                    <button className="btn btn-md btn-flat btn-default"><i className="fa fa-trash-o"></i></button>
                                                                </Confirm>
                                                            </div>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>}
                            </div>

                        </div>
                    </div>
                </div>
                <Modal open={this.state.isShowingModalAddExpenseCategory} onClose={() => this.setState({ isShowingModalAddExpenseCategory: false })} center>
                    <div style={modalWidth}>
                        <AddEditExpenseCategories
                            {...this.props}

                            ExpenseCategory={this.state.selectedExpenseCategory}
                            onSave={() => this.onPostExpenseCategoriesSave()}
                            onClose={() => this.setState({ isShowingModalAddExpenseCategory: false })} />
                    </div>
                </Modal>
            </section>
        </div>
    }
}

export default ExpenseCategoriesList