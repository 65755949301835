import React, { Component } from 'react';
import {Link } from 'react-router-dom';
import Confirm from 'react-confirm-bootstrap'
import { withRouter } from 'react-router-dom'
import Pagination from "react-js-pagination"
import * as actionTypes from '../../actionTypes'
import Loader from '../Loader'
import AuditTrail from '../AuditTrail'
import * as constants from '../../Constatnts'

class StoreList extends Component{    
    constructor () {
        super()
        this.state={
            stores:[],
            showFilters:false,
            searchText:'',
            searchCity:'',
            searchState:'',
            searchPostalCode:'',
            searchCountry:'',
            searchCustomField1:'',
            searchCustomField2:'',
            searchCustomField3:'',
            searchCustomField4:'',
            searchCustomField5:'',
            searchCustomField6:'',
            searchNotes:'',
            activePage:1,
            itemsCountPerPage:5,
            totalItemsCount:0,
            recordFrom:0,
            paginationStatus:'No records to display',
            searchActiveInactiveAllRecords:'active',
            customField1DisplayName:'Custom Field 1',
            customField2DisplayName:'Custom Field 2',
            customField3DisplayName:'Custom Field 3',
            customField4DisplayName:'Custom Field 4',
            customField5DisplayName:'Custom Field 5',
            customField6DisplayName:'Custom Field 6',
            loading: true
        }
            
        this.showHideFilters = this.showHideFilters.bind(this)
        this.onClearSearch = this.onClearSearch.bind(this)
        this.onSearchActiveInactiveAllRecordsClicked = this.onSearchActiveInactiveAllRecordsClicked.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.showPaginationStatus= this.showPaginationStatus.bind(this)
    }

    loadStores(from, option,searchText, searchCity, searchState, searchPostalCode, searchCountry, searchCustomField1,
        searchCustomField2, searchCustomField3, searchCustomField4, searchCustomField5, searchCustomField6){
        
        this.props.getStores(this.props.user.user.RegistrationID, from, this.state.itemsCountPerPage, option,
            searchText, searchCity, searchState, searchPostalCode, searchCountry, searchCustomField1,
            searchCustomField2, searchCustomField3, searchCustomField4, searchCustomField5, searchCustomField6)
        .then((result)=>{    
            this.props.getUserStores(this.props.user.user.RegistrationID, this.props.user.auth.uid).then((userStores) => {
                let stores = []
                for (let index = 0; index < result.searchResult.length; index++) {
                    const store = result.searchResult[index];

                    for (let i = 0; i < userStores.length; i++) {
                        if (userStores[i].key === store.key)
                          stores.push(
                            {
                              ...store
                            }
                          )
                      }
                }
            this.setState({stores:stores,totalItemsCount: stores.length,  loading: false})
            this.showPaginationStatus()
            })  
        })
    }
    
    componentDidMount()
      {
        this.props.config.CustomFields.map((customField) => {
            if (customField.ModuleName === 'Store')
            {
                let customField1DisplayName = customField.CustomField1Name.trim() === '' ? 'Custom Field 1' : customField.CustomField1Name.trim()
                let customField2DisplayName = customField.CustomField2Name.trim() === '' ? 'Custom Field 2' : customField.CustomField2Name.trim()
                let customField3DisplayName = customField.CustomField3Name.trim() === '' ? 'Custom Field 3' : customField.CustomField3Name.trim()
                let customField4DisplayName = customField.CustomField4Name.trim() === '' ? 'Custom Field 4' : customField.CustomField4Name.trim()
                let customField5DisplayName = customField.CustomField5Name.trim() === '' ? 'Custom Field 5' : customField.CustomField5Name.trim()
                let customField6DisplayName = customField.CustomField6Name.trim() === '' ? 'Custom Field 6' : customField.CustomField6Name.trim()
                this.setState({
                    customField1DisplayName:customField1DisplayName,
                    customField2DisplayName:customField2DisplayName,
                    customField3DisplayName:customField3DisplayName,
                    customField4DisplayName:customField4DisplayName,
                    customField5DisplayName:customField5DisplayName,
                    customField6DisplayName:customField6DisplayName
                })
            }
        })
        this.loadStores(this.state.recordFrom,this.state.searchActiveInactiveAllRecords, this.state.searchText, 
            this.state.searchCity, this.state.searchState, this.state.searchPostalCode, this.state.searchCountry, 
            this.state.searchCustomField1, this.state.searchCustomField2, this.state.searchCustomField3,
             this.state.searchCustomField4, this.state.searchCustomField5, this.state.searchCustomField6);            
      }

    onStoreDelete(key){
        this.props.deleteStore(key).then(()=> {
            this.props.getToastr("Store deleted successfully")
            this.loadStores(this.state.recordFrom,this.state.searchActiveInactiveAllRecords, this.state.searchText, 
                this.state.searchCity, this.state.searchState, this.state.searchPostalCode, this.state.searchCountry, 
                this.state.searchCustomField1, this.state.searchCustomField2, this.state.searchCustomField3,
                 this.state.searchCustomField4, this.state.searchCustomField5, this.state.searchCustomField6);
       })
    }

    showHideFilters()
    {
        let showFilters = this.state.showFilters
        this.setState({showFilters: !showFilters})
    }

    onClearSearch()
    {
        this.setState({recordFrom:0, searchActiveInactiveAllRecords:"active", searchText:'',searchCity:'', 
        searchState:'', searchPostalCode:'', searchCountry:'', searchCustomField1:'', searchCustomField2:'',
        searchCustomField3:'', searchCustomField4:'', searchCustomField5:'', searchCustomField6:''
        })
        this.loadStores(0,"active","","","","","","","","","","","")
    }

    onSearchActiveInactiveAllRecordsClicked(opiton)
    {
        this.setState({searchActiveInactiveAllRecords:opiton, recordFrom:0})
        this.loadStores(
            0,
            opiton,
            this.state.searchText,
            this.state.searchCity, 
            this.state.searchState, 
            this.state.searchPostalCode, 
            this.state.searchCountry,
            this.state.searchCustomField1,
            this.state.searchCustomField2,
            this.state.searchCustomField3,
            this.state.searchCustomField4,
            this.state.searchCustomField5,
            this.state.searchCustomField6
            )
    }

    onSearchClick(){
        this.setState({searchFrom:0})
        this.loadStores(
            0,
            this.state.searchActiveInactiveAllRecords,
            this.state.searchText,
            this.state.searchCity, 
            this.state.searchState, 
            this.state.searchPostalCode, 
            this.state.searchCountry,
            this.state.searchCustomField1,
            this.state.searchCustomField2,
            this.state.searchCustomField3,
            this.state.searchCustomField4,
            this.state.searchCustomField5,
            this.state.searchCustomField6
            )
    }

    handlePageChange(pageNumber) {
        //console.log(`active page is ${pageNumber}`);
       // searchFrom = this.state.searchFrom
       let recordFrom = ((pageNumber -1) * this.state.itemsCountPerPage)
        this.setState({activePage: pageNumber,recordFrom:recordFrom});
        this.loadStores(
            recordFrom,
            this.state.searchActiveInactiveAllRecords,
            this.state.searchText,
            this.state.searchCity, 
            this.state.searchState, 
            this.state.searchPostalCode, 
            this.state.searchCountry,
            this.state.searchCustomField1,
            this.state.searchCustomField2,
            this.state.searchCustomField3,
            this.state.searchCustomField4,
            this.state.searchCustomField5,
            this.state.searchCustomField6
            )
      }

      showPaginationStatus()
      {
          console.log('this.state.recordFrom', this.state.recordFrom)
            let paginationStatus 
            if((this.state.recordFrom +1)  === this.state.totalItemsCount)
            {
                paginationStatus= "Showing " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
            }
            else if((this.state.recordFrom + this.state.itemsCountPerPage + 1 )> this.state.totalItemsCount )
            {
                paginationStatus= "Showing " + (this.state.recordFrom +1) + " to  " + this.state.totalItemsCount + " of " + this.state.totalItemsCount 
            }
            else{
                paginationStatus= "Showing " + (this.state.recordFrom +1 )+ " to  " + (this.state.recordFrom + this.state.itemsCountPerPage ) + " of " + this.state.totalItemsCount 

            }
            this.setState({paginationStatus:paginationStatus})

      }

    render() {    
        const btnColStyle = {width:"100px"}
        
        return <div className="content-wrapper" >
        <section className="content" >
            <div className="row">
                <div className="col-md-12">
                    <div className="box ">
                        <div className="box-header with-border">
                            <div className="row">
                                <div className="col-md-6">
                                    <font style={{fontSize:"30px"}}>Stores</font>
                                </div>
                                <div className="col-md-6">
                                    <div className="pull-right" style={{marginTop:"6px"}}>
                                        {/* <button type="button" className="btn btn-default dropdown-toggle btn-flat  btn-md" data-toggle="dropdown">Action
                                            <span className="fa fa-caret-down"></span></button>
                                            <ul class="dropdown-menu">
                                                <li><a href="#">Import</a></li>
                                                <li class="divider"></li>
                                                <li><a href="#">Export</a></li>
                                            </ul> */}
                                            <text>  </text>
                                            <Link  to={{pathname:"/home/settings/stores/newstore",state:{action:actionTypes.STORE_NEW}}}
                                            className="btn btn-success btn-flat  btn-md" >Add Store</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box-body">
                            <div className="row">
                                <div className="col-md-12" style={{marginBottom:"10px"}}>
                                    <div className="btn-group">
                                       <button type="button" className={this.state.searchActiveInactiveAllRecords === "active" ?  "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md" }
                                       onClick={()=> this.onSearchActiveInactiveAllRecordsClicked("active")}>Active</button>
                                       <button type="button" className={this.state.searchActiveInactiveAllRecords === "inactive" ?  "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md" }
                                       onClick={()=> this.onSearchActiveInactiveAllRecordsClicked("inactive")}>In Active</button>
                                       <button type="button" className={this.state.searchActiveInactiveAllRecords === "all" ?  "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md" }
                                       onClick={()=> this.onSearchActiveInactiveAllRecordsClicked("all")}>All</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="form-group">
                                        <label>Search by Store name, code, email id, contact no</label>
                                            <input type="text" className="form-control input-md"
                                             value={this.state.searchText} 
                                             onChange={(evt)=>this.setState({searchText:evt.target.value})}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <button type="button" className="btn btn-primary btn-flat btn-md pull-right" 
                                    style={{marginTop:"25px", marginLeft:"5px"}}
                                    onClick={this.onSearchClick}
                                    >Search</button>
                                    <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{marginTop:"25px"}} 
                                    onClick={this.onClearSearch} >Clear</button>
                                    <a type="button" className="btn btn-md pull-right" style={{marginTop:"25px"}} onClick={this.showHideFilters}>{this.state.showFilters ? "Less Filter" : "More Filters"}</a>
                                </div>
                            </div>
                            {this.state.showFilters && <div className="row">
                                <div className="col-md-9">
                                    <div className="col-md-3" style={{paddingLeft:"0px"}}>
                                        <div className="form-group">
                                            <label>City</label>
                                            <input type="text" className="form-control input-md" 
                                            value={this.state.searchCity} 
                                            onChange={(evt)=>this.setState({searchCity:evt.target.value})}/>
                                        </div>
                                    </div>
                                    <div className="col-md-3" style1={{padding:"0px"}}>
                                        <div className="form-group">
                                            <label>State</label>
                                            <input type="text" className="form-control input-md"  
                                            value={this.state.searchState} 
                                            onChange={(evt)=>this.setState({searchState:evt.target.value})}/>
                                        </div>
                                    </div>
                                    <div className="col-md-3" style1={{padding:"0px"}}>
                                        <div className="form-group">
                                            <label>Postal Code</label>
                                            <input type="text" className="form-control input-md"  
                                            value={this.state.searchPostalCode} 
                                            onChange={(evt)=>this.setState({searchPostalCode:evt.target.value})}/>
                                        </div>
                                    </div>
                                    <div className="col-md-3" style1={{padding:"0px"}}>
                                        <div className="form-group">
                                            <label>Country</label>
                                            <input type="text" className="form-control input-md"  
                                            value={this.state.searchCountry} 
                                            onChange={(evt)=>this.setState({searchCountry:evt.target.value})}/>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {this.state.showFilters && <div className="row">
                                <div className="col-md-9">
                                    <div className="col-md-3" style={{paddingLeft:"0px"}}>
                                        <div className="form-group">
                                            <label>{this.state.customField1DisplayName}</label>
                                            <input type="text" className="form-control input-md" 
                                            value={this.state.searchCustomField1} 
                                            onChange={(evt)=>this.setState({searchCustomField1:evt.target.value})}/>
                                        </div>
                                    </div>
                                    <div className="col-md-3" style1={{padding:"0px"}}>
                                        <div className="form-group">
                                            <label>{this.state.customField2DisplayName}</label>
                                            <input type="text" className="form-control input-md"  
                                            value={this.state.searchCustomField2} 
                                            onChange={(evt)=>this.setState({searchCustomField2:evt.target.value})}/>
                                        </div>
                                    </div>
                                    <div className="col-md-3" style1={{padding:"0px"}}>
                                        <div className="form-group">
                                            <label>{this.state.customField3DisplayName}</label>
                                            <input type="text" className="form-control input-md"  
                                            value={this.state.searchCustomField3} 
                                            onChange={(evt)=>this.setState({searchCustomField3:evt.target.value})}/>
                                        </div>
                                    </div>
                                    <div className="col-md-3" style1={{padding:"0px"}}>
                                        <div className="form-group">
                                            <label>{this.state.customField4DisplayName}</label>
                                            <input type="text" className="form-control input-md"  
                                            value={this.state.searchCustomField4} 
                                            onChange={(evt)=>this.setState({searchCustomField4:evt.target.value})}/>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {this.state.showFilters && <div className="row">
                                <div className="col-md-9">
                                    <div className="col-md-3" style={{paddingLeft:"0px"}}>
                                        <div className="form-group">
                                            <label>{this.state.customField5DisplayName}</label>
                                            <input type="text" className="form-control input-md" 
                                            value={this.state.searchCustomField5} 
                                            onChange={(evt)=>this.setState({searchCustomField5:evt.target.value})}/>
                                        </div>
                                    </div>
                                    <div className="col-md-3" style1={{padding:"0px"}}>
                                        <div className="form-group">
                                            <label>{this.state.customField6DisplayName}</label>
                                            <input type="text" className="form-control input-md"  
                                            value={this.state.searchCustomField6} 
                                            onChange={(evt)=>this.setState({searchCustomField6:evt.target.value})}/>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {this.state.loading && <Loader show={this.state.loading} />}

                                {!this.state.loading &&
                                <table className = "table table-bordered table-striped">
                                <thead>
                                <tr>
                                        <th>Store Code</th>
                                         <th>Store Name</th>
                                         <th>Email ID</th>
                                         <th>Mobile No</th>
                                         <th>Address</th>
                                         <th>Custom Fields</th>
                                         <th>Revision History</th>
                                         <th style={btnColStyle}></th>
                                </tr>
                                </thead>
                                <tbody>
                                    {this.state.stores.map((store) =>
                                    <tr>
                                        <td>{store.StoreCode}</td>
                                         <td>{store.StoreName}</td>
                                         <td>{store.EmailID}</td>
                                         <td>{store.PhoneNo}</td>
                                         <td>
                                             <div>{store.Address1}</div>
                                             <div>{store.Address2}</div>
                                             <div>{store.City}</div>
                                             <div>{store.State}</div>
                                             <div>{store.PostalCode}</div>
                                             <div>{store.Country}</div>
                                         </td>                                                                             
                                            <td>
                                                {store.CustomField1.trim()!== "" &&<div>{this.state.customField1DisplayName}:{store.CustomField1}</div>}
                                                {store.CustomField2.trim()!== "" &&<div>{this.state.customField2DisplayName}:{store.CustomField2}</div>}
                                                {store.CustomField3.trim()!== "" &&<div>{this.state.customField3DisplayName}:{store.CustomField3}</div>}
                                                {store.CustomField4.trim()!== "" &&<div>{this.state.customField4DisplayName}:{store.CustomField4}</div>}
                                                {store.CustomField5.trim()!== "" &&<div>{this.state.customField5DisplayName}:{store.CustomField5}</div>}
                                                {store.CustomField6.trim()!== "" && <div>{this.state.customField6DisplayName}:{store.CustomField6}</div>}
                                            </td>
                                            <td>
                                                <div>Action :{store.Action}</div>
                                                <div>Action By :{store.ActionBy}</div>                                                        
                                                <div>Action Date :{(new Date(store.ActionOn).toISOString().split('T')[0])}</div>
                                                <AuditTrail {...this.props} module={constants.MODULE_STORE} parentKey={store.key}/>
                                            </td>
                                        <td>
                                            <div class="btn-group">
                                            { store.IsDeleted ? <span class="label label-danger pull-right">Inactive</span> : 
                                                <div>
                                                    <Link to={{pathname:"/home/settings/stores/viewstoredetails/", state:{key:store.key, action:actionTypes.STORE_EDIT}}}   
                                                    type="button" class="btn btn-md btn-flat btn-default "><i class="fa fa-edit"></i></Link>                                            
                                                    <Confirm
                                                        onConfirm={() => this.onStoreDelete(store.key)}
                                                        body="Are you sure you would like to delete this Store?"
                                                        confirmText="Yes"
                                                        title="Store Delete">
                                                        <button className="btn btn-default btn-flat"><i class="fa fa-trash-o"></i></button>
                                                    </Confirm>
                                                </div>    
                                            }
                                            </div>
                                        </td>
                                     </tr>
                                    )}                                            
                                </tbody>
                                </table>}
                                {this.state.totalItemsCount > 0 && <div className="pull-left" style={{marginTop:"25px"}}>
                                {this.state.paginationStatus}
                                </div>}
                                <div className="pull-right">
                                    <Pagination
                                    activePage={this.state.activePage} //this.state.activePage
                                    itemsCountPerPage={this.state.itemsCountPerPage}
                                    totalItemsCount={this.state.totalItemsCount}
                                    pageRangeDisplayed={2}
                                    onChange={this.handlePageChange}
                                    />
                                </div>
                        </div>   
                    </div>
                </div>
                </div>
                </section>
                </div>   
    }
}

export default withRouter(StoreList)