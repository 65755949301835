import React, { Component } from 'react'

var AsyncTypeahead = require('react-bootstrap-typeahead').AsyncTypeahead;

class CustomerSearch extends Component {

    constructor() {
        super()
        this.state = {
            isCustomerTypeAheadLoading: false,
            customerTypeAheadSelected: [],
            customerTypeAheadOptions: []
            // customer:null
        }
        this.onCustomerTypeAheadSearch = this.onCustomerTypeAheadSearch.bind(this)
        this.onCustomerSelected = this.onCustomerSelected.bind(this)
        this.getCustomerDetails = this.getCustomerDetails.bind(this)
        this.onRemoveCustomer = this.onRemoveCustomer.bind(this)
        this.roundUpTo2 = this.roundUpTo2.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
    }

    roundUpTo2(num) {
        return Math.round(Number(num) * 100) / 100
    }

    focus() {
        if (!this.props.customer) {
            this.refs.asyncCustomerSearch.getInstance().focus()
        }
    }

    async onCustomerTypeAheadSearch(query) {

        this.setState({ isCustomerTypeAheadLoading: true, query: query });

        if (this.props.config.ConnectionStatus.Status === 'connected') {
            this.props.searchCustomer(this.props.user.user.RegistrationID, this.props.user.store.key, query)
                .then((result) => {
                    this.setState({ customerTypeAheadOptions: result.searchResult })
                    this.setState({ isCustomerTypeAheadLoading: false });
                })
        }
        else {
            let searchedCustomers = [];
            if (this.props.registration.registration.hasOwnProperty('GlobalCustomer') && !this.props.registration.registration.GlobalCustomer) {
                searchedCustomers = await this.props.config.Customers.filter(p => p.StoreID === this.props.user.store.key && (p.CustomerCode.toLowerCase().includes(query.toLowerCase())
                    || p.FirstName.toLowerCase().includes(query.toLowerCase()) || p.LastName.toLowerCase().includes(query.toLowerCase())
                    || p.EmailID.toLowerCase().includes(query.toLowerCase()) || p.PhoneNo.includes(query)));

            }
            else {
                searchedCustomers = await this.props.config.Customers.filter(p => p.CustomerCode.toLowerCase().includes(query.toLowerCase())
                    || p.FirstName.toLowerCase().includes(query.toLowerCase()) || p.LastName.toLowerCase().includes(query.toLowerCase())
                    || p.EmailID.toLowerCase().includes(query.toLowerCase()) || p.PhoneNo.includes(query));
            }
            this.setState({ customerTypeAheadOptions: searchedCustomers })
            this.setState({ isCustomerTypeAheadLoading: false });
        }
    }

    onRemoveCustomer() {
        this.props.onCustomerChange(null)
    }

    getCustomerDetails(customer) {
        let selectedCustomer = {
            key: customer.key,
            CustomerCode: customer.CustomerCode,
            FirstName: customer.FirstName,
            LastName: customer.LastName,
            EmailID: customer.EmailID,
            PhoneNo: customer.PhoneNo,
            Membership: customer.Membership,
            Note: customer.hasOwnProperty('Note') ? customer.Note : "",
            CustomField1: customer.CustomField1,
            CustomField2: customer.CustomField2,
            CustomField3: customer.CustomField3,
            CustomField4: customer.CustomField4,
            CustomField5: customer.CustomField5,
            CustomField6: customer.CustomField6,
            WalletBalance: this.roundUpTo2(customer.WalletBalance),
            Addresses: customer.Addresses,
            SaleToPurchaseStoreID: customer.hasOwnProperty('SaleToPurchaseStoreID') ? customer.SaleToPurchaseStoreID : "",
            OpeningOutStandingAmount: customer.hasOwnProperty('OpeningOutStandingAmount') ? customer.OpeningOutStandingAmount : 0,
            OpeningOutStandingAmountCurrent: customer.hasOwnProperty('OpeningOutStandingAmountCurrent') ? customer.OpeningOutStandingAmountCurrent : 0,
            OpeningOutStandingAmountReceived: customer.hasOwnProperty('OpeningOutStandingAmountReceived') ? customer.OpeningOutStandingAmountReceived : 0,
            OutStandingAmount: customer.hasOwnProperty('OutStandingAmount') ? customer.OutStandingAmount : 0
        }
        return selectedCustomer
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            if (this.state.customerTypeAheadOptions.length === 0) {
                let query = this.state.query;
                let customer = {}
                customer.EmailID = ""
                customer.PhoneNo = ""
                customer.FirstName = ""
                if (query.includes("@")) {
                    customer.EmailID = query
                }
                if (Number(query)) {
                    customer.PhoneNo = query
                }
                if (!query.includes("@") && !Number(query)) {
                    customer.FirstName = query
                }
                this.props.onAddCustomer(customer);
            }
        }
    }

    onCustomerSelected(selected) {
        let customer = this.getCustomerDetails(selected[0])
        this.props.onCustomerChange(customer)
    }

    render() {
        return <React.Fragment>
            {!this.props.customer &&
                <div className={this.props.error ? "form-group has-error" : "form-group "} style={{ marginBottom: "0px" }}>
                    <div className="input-group">
                        <AsyncTypeahead
                            ref="asyncCustomerSearch"
                            allowNew={false}
                            isLoading={this.state.isCustomerTypeAheadLoading}
                            multiple={false}
                            options={this.state.customerTypeAheadOptions}
                            labelKey="FirstName"
                            minLength={3}
                            onSearch={this.onCustomerTypeAheadSearch}
                            onKeyDown={this.handleKeyPress}
                            placeholder="Search customer"
                            filterBy={['FirstName', 'LastName', 'PhoneNo', 'EmailID', 'CustomerCode']}
                            bsSize={'large'}
                            dropup={false}
                            highlightOnlyResult={true}
                            selected={this.state.customerTypeAheadSelected}
                            onChange={(selected) => {
                                this.onCustomerSelected(selected)
                            }}
                            renderMenuItemChildren={(option, props) => (
                                <div>
                                    <div><b>{option.FirstName} {option.LastName}</b></div>
                                    <div>{option.PhoneNo}</div>
                                    <div>{option.EmailID}</div>
                                    <div>{option.CustomerCode}</div>
                                </div>
                            )}
                        />
                        <div className="input-group-btn">
                            <button type="button" onClick={() => this.props.onAddCustomer(null)}
                                className="btn btn-default btn-lg btn-flat">
                                <i className="glyphicon glyphicon-plus"></i>
                            </button>
                        </div>
                    </div>
                </div>}
            {this.props.customer && <div className="input-group">
                <button type="button" onClick={() => this.props.onCustomerEdit()}
                    className="btn btn-default btn-block btn-flat btn-lg" style={{ padding: "0px", height: "46px" }}>
                    <div style={{ fontSize: "15px", marginLeft: "5px" }} className="pull-left">{this.props.customer.FirstName} {this.props.customer.LastName}  {this.props.customer.CustomerCode !== "" ? " (" + this.props.customer.CustomerCode + ")" : ""}</div><br></br>
                    {this.props.customer.PhoneNo !== "" && <div style={{ fontSize: "12px", marginLeft: "5px" }} className="pull-left"><i className="fa fa-phone"></i> {this.props.customer.PhoneNo}</div>}
                    {this.props.customer.EmailID !== "" && <div style={{ fontSize: "12px", marginRight: "5px" }} className="pull-right"><i className="fa fa-envelope"></i> {this.props.customer.EmailID}</div>}
                </button>
                <div className="input-group-btn">
                  {this.props.config.ConnectionStatus.Status === 'connected' && <button type="button" class="btn btn-md btn-flat btn-lg btn-default" onClick={this.props.onShowCustomerDashBoard}><i class="fa fa-dashboard"></i></button>}
                    <button type="button" className="btn btn-default btn-lg btn-flat"
                        onClick={() => this.onRemoveCustomer()}><i className="glyphicon glyphicon-remove" ></i> </button>
                </div>
            </div>}
            {(this.props.customer && this.props.customer.OutStandingAmount > 0) && <span className="label label-danger pull-right" style={{ margin: "5px", marginBottom: "0px", fontSize: "14px" }}>O/S: {this.props.customer.OutStandingAmount}</span>}
            {(this.props.customer && this.props.customer.WalletBalance > 0) && <span className="label label-success pull-right" style={{ margin: "5px", marginBottom: "0px", fontSize: "14px" }}>Wallet: {this.props.customer.WalletBalance}</span>}
            {(this.props.customer && this.props.customer.Membership && this.props.customer.Membership.hasOwnProperty('MembershipID')) && this.props.customer.Membership.MembershipID != "" &&
                <span className="label label-warning pull-right" style={{ margin: "5px", marginBottom: "0px", fontSize: "14px" }}>
                    {this.props.customer.Membership.Name}</span>}
        </React.Fragment>
    }
}

export default CustomerSearch