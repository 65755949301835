import React from 'react'

class AddEditExpenseCategories extends React.Component{
    constructor () {
        super()
        this.state={
            ExpenseCategory:'',
            ExpenseNameError:'',
            key:'',
            saving: false
        }
        this.onSaveExpensecategories = this.onSaveExpensecategories.bind(this)
    }

    componentDidMount()
    {
        console.log(JSON.stringify(this.props.ExpenseCategory))
        if(this.props.ExpenseCategory )
        {
            this.setState({ExpenseCategory: this.props.ExpenseCategory.ExpenseCategory, key: this.props.ExpenseCategory.key})
        }
    }

   
    onSaveExpensecategories ()
    {
        this.setState({ saving:true})
        let ExpenseNameError = ''
        if(this.state.ExpenseCategory.trim() === ""){
             ExpenseNameError = 'Please provide Expense Category'
             this.setState({ExpenseNameError: ExpenseNameError,saving:false})
             return 
        }
        this.props.checkIfExpenseCategoriesIsUnique(this.state.ExpenseCategory,this.props.user.user.RegistrationID,this.props.user.store.key).then((isUnique)=>{
            if(!isUnique){
                ExpenseNameError = 'Expense Category is already exists'
                this.setState({ExpenseNameError: ExpenseNameError,saving:false})
                return 
            }
            else
            {
                   let ExpenseCategory = {
                    ExpenseCategory: this.state.ExpenseCategory, 
                    RegistrationID: this.props.user.user.RegistrationID,
                    StoreID : this.props.user.store.key,
                    IsDeleted:false}
                if(this.state.key ==="")
                {
                    ExpenseCategory = {...ExpenseCategory}
                }
                this.props.saveExpenseCategories(ExpenseCategory,this.state.key).then(
                    (key)=> {
                        this.props.getToastr("Expense Category Saved Successfully")
                        this.setState({ saving:false})
                        this.props.onSave(key)}) 
            }
        })
        if(ExpenseNameError !== ""){
            this.setState({ExpenseNameError: ExpenseNameError})
            return 
        }
               
    }

    render(){
        return  <div>
            <h3 style={{marginTop:"0px"}}>Expense Category</h3>
            <div className="box-body">                            
                <div className={ this.state.ExpenseNameError !=="" ? "form-group has-error": "form-group "} >   
                    <label>Expense Category</label>
                    <input type = "text" autoFocus
                     className="form-control" 
                    onChange={(evt)=> {console.log('evt.target.value',evt.target.value)
                        this.setState({ExpenseCategory:evt.target.value,ExpenseNameError:"" })}}
                    value={this.state.ExpenseCategory}
                    />
                    {this.state.ExpenseNameError !=="" &&  <span class="help-block">{this.state.ExpenseNameError}</span> }
                </div>
            </div>
            <div className="box-footer">
                <button type="submit" className="btn btn-flat btn-md btn-default" onClick={()=> this.props.history.push('/home/expenselist')} >Cancel</button>
                <button type="submit" className="btn btn-flat btn-md btn-success pull-right" onClick={this.onSaveExpensecategories} disabled={this.state.saving}>Save</button>
            </div>
    </div>
    }
}

export default AddEditExpenseCategories