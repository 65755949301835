import React, { Component } from 'react';
import CustomerAddress from './CustomerAddress'
import Modal from 'react-responsive-modal'
import { withRouter } from 'react-router-dom'
import { HotKeys } from 'react-hotkeys';
import CustomerDate from './CustomerDate'

const keyMap = {
    save: 'ctrl+s',
    back: 'ctrl+b'
};

class Customer extends Component {

    constructor() {
        super()
        this.state = {
            key: '',
            stores: [],
            saving: false,
            saleToPurchaseStoreID: '',
            saleToPurchaseStore: '',
            storeID: '',
            customerCode: '',
            firstName: '',
            lastName: '',
            emailID: '',
            phoneNo: '',
            outStandingAmount:0,
            customerAcceptsMarketing: false,
            note: '',
            dobNew: { Day: '', Month: '', Year: '' },
            anniversaryNew: { Day: '', Month: '', Year: '' },
            membership: {},
            membershiplist: [],
            walletOpeningBalance: 0,
            openingOutStandingAmount: 0,
            gender: '',
            tag: '',
            firstNameError: false,
            emailORPhoneError: false,
            addresses: [],
            customField1: '',
            customField2: '',
            customField3: '',
            customField4: '',
            customField5: '',
            customField6: '',
            selectedAddress: null,
            selectedAddressIndex: -1,
            customField1DisplayName: 'Custom Field 1',
            customField2DisplayName: 'Custom Field 2',
            customField3DisplayName: 'Custom Field 3',
            customField4DisplayName: 'Custom Field 4',
            customField5DisplayName: 'Custom Field 5',
            customField6DisplayName: 'Custom Field 6',
            references: [],
            referredByCustomer: null,
            walletBalance: 0,
            phoneNoError: false
            // referredByName: '',

        }
        this.addressAddedCallBack = this.addressAddedCallBack.bind(this)
        this.onAddressRemove = this.onAddressRemove.bind(this)
        this.onSaveCustomer = this.onSaveCustomer.bind(this)
        this.onCancel = this.onCancel.bind(this)
        this.onMembershipChange = this.onMembershipChange.bind(this)
        this.loadMembership = this.loadMembership.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
    }

    async componentDidMount() {

        if (this.props.user.store.EnableSaleToPurchase)
            this.loadStores();

        this.loadMembership();

        //set display field names if its defined 
        this.props.config.CustomFields.map((customField) => {
            if (customField.ModuleName === 'Customer') {
                let customField1DisplayName = customField.CustomField1Name.trim() === '' ? 'Custom Field 1' : customField.CustomField1Name.trim()
                let customField2DisplayName = customField.CustomField2Name.trim() === '' ? 'Custom Field 2' : customField.CustomField2Name.trim()
                let customField3DisplayName = customField.CustomField3Name.trim() === '' ? 'Custom Field 3' : customField.CustomField3Name.trim()
                let customField4DisplayName = customField.CustomField4Name.trim() === '' ? 'Custom Field 4' : customField.CustomField4Name.trim()
                let customField5DisplayName = customField.CustomField5Name.trim() === '' ? 'Custom Field 5' : customField.CustomField5Name.trim()
                let customField6DisplayName = customField.CustomField6Name.trim() === '' ? 'Custom Field 6' : customField.CustomField6Name.trim()
                this.setState({
                    customField1DisplayName: customField1DisplayName,
                    customField2DisplayName: customField2DisplayName,
                    customField3DisplayName: customField3DisplayName,
                    customField4DisplayName: customField4DisplayName,
                    customField5DisplayName: customField5DisplayName,
                    customField6DisplayName: customField6DisplayName
                })
            }
        })
        let key = ""
        if (this.props.calledFromInvoice) {

            if (this.props.selectedCustomer) {
                key = this.props.selectedCustomer.key
            }
        }
        else {
            if (this.props.match.params.key) {
                key = this.props.match.params.key
            }
        }

        if (key.trim() === "") {
            if(this.props.newCustomer)
            {
                this.setState({ firstName: this.props.newCustomer.FirstName,emailID: this.props.newCustomer.EmailID,
                    phoneNo: this.props.newCustomer.PhoneNo,
                })
            }
            return
        }

        let customer = null;
        if(this.props.config.ConnectionStatus.Status === 'connected')
        {
            customer = await this.props.getCustomerDetails(key);
        }
        else
        {
            customer = this.props.config.Customers.filter(c => c.key === key)[0];
        }

        this.setState({
            key: key,
            storeID: this.props.user.store.key,
            customerCode: customer.CustomerCode,
            firstName: customer.FirstName,
            lastName: customer.LastName,
            emailID: customer.EmailID,
            phoneNo: customer.PhoneNo,
            dob: null,
            anniversary: null,
            dobNew: customer.hasOwnProperty('DOBNew') ? customer.DOBNew : { Day: '', Month: '', Year: '' },
            anniversaryNew: customer.hasOwnProperty('AnniversaryNew') ? customer.AnniversaryNew : { Day: '', Month: '', Year: '' },
            membership: customer.Membership,
            walletOpeningBalance: customer.WalletOpeningBalance,
            openingOutStandingAmount: customer.OpeningOutStandingAmount,
            customerAcceptsMarketing: customer.CustomerAcceptsMarketing,
            note: customer.hasOwnProperty('Note') ? customer.Note : "",
            gender: customer.Gender,
            tag: customer.Tag,
            outStandingAmount: customer.OutStandingAmount,
            addresses: customer.Addresses ? customer.Addresses : [],
            customField1: customer.CustomField1,
            customField2: customer.CustomField2,
            customField3: customer.CustomField3,
            customField4: customer.CustomField4,
            customField5: customer.CustomField5,
            customField6: customer.CustomField6,
            references: customer.References,
            referredByCustomer: customer.ReferredByCustomer,
            walletBalance: customer.hasOwnProperty('WalletBalance') ? customer.WalletBalance : 0,
            saleToPurchaseStoreID: customer.hasOwnProperty('SaleToPurchaseStoreID') ? customer.SaleToPurchaseStoreID : ''
        })

    }

    addressAddedCallBack = (address, index) => {
        if (index === -1) {
            //add addresss
            let addresses = this.state.addresses
            addresses.push(address)
            this.setState({ addresses: addresses })
        }
        else {
            let addresses = this.state.addresses
            addresses[index] = address
            this.setState({ addresses: addresses })
        }

    }

    onAddressSelected(index, address) {
        this.setState({ selectedAddressIndex: index, selectedAddress: address, isShowingModalCustomerAddress: true })

    }

    loadStores() {
        this.props.getStores(this.props.user.user.RegistrationID, 0, 10000, "active", "", "", "", "", "", "", ""
            , "", "", "", "").then((result) => {

                this.setState({ stores: result.searchResult })
            });
    }

    onAddressRemove(index) {
        let addresses = this.state.addresses
        addresses.splice(index, 1)
        this.setState({ addresses: addresses })
    }

    onCancel() {
        if (this.props.calledFromInvoice) {
            this.props.onClose()
        }
        else {
            this.props.history.push("/home/customer")
        }
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSaveCustomer()
        }
    }

    async onSaveCustomer() {
        this.setState({ saving: true })
        let firstNameError = ""
        let dobError = ""
        let anniversaryError = ""
        let emailORPhoneError = ""
        let phoneNoError = ""
        if (this.state.firstName.trim() === "") {
            firstNameError = "Please provide Customer Name"

        }
        if (this.state.dobNew.Day.toString().trim() !== "" && this.state.dobNew.Month.toString().trim() === "") {
            dobError = "Please select month"
        }
        if (this.state.dobNew.Day.toString().trim() === "" && this.state.dobNew.Month.toString().trim() !== "") {
            dobError = "Please select day"
        }
        if (this.state.anniversaryNew.Day.toString().trim() !== "" && this.state.anniversaryNew.Month.toString().trim() === "") {
            anniversaryError = "Please select month"
        }
        if (this.state.anniversaryNew.Day.toString().trim() === "" && this.state.anniversaryNew.Month.toString().trim() !== "") {
            anniversaryError = "Please select day"
        }
        if (this.state.phoneNo.trim() === "" && this.state.emailID.trim() === "") {
            emailORPhoneError = "Please enter either Phone Number or Email"
        }
        if (firstNameError !== "" || dobError !== "" || anniversaryError !== "" || emailORPhoneError !== "" || phoneNoError != "") {
            this.setState({
                firstNameError: firstNameError,
                dobError: dobError,
                phoneNoError: phoneNoError,
                anniversaryError: anniversaryError,
                emailORPhoneError: emailORPhoneError,
                phoneNoError: phoneNoError,
                saving: false
            })
            return
        }

        let key = this.state.key

        let mappingExists = false
        if(this.state.saleToPurchaseStoreID != "")
            mappingExists = await this.props.checkIfCustomerStoreMappingExists(this.state.saleToPurchaseStoreID, key)

        if(mappingExists)
        {
            this.setState({ saleToPurchaseStoreError: 'Store is already mapped to a customer', saving: false })
            return;
        }

        let isUnique = true
        if (this.state.phoneNo !== "" && this.props.config.ConnectionStatus.Status === 'connected') {
            isUnique = await this.props.checkIfCustomerPhoneNoIsUnique(this.state.phoneNo, key)
        }
        if (!isUnique) {
            this.setState({ phoneNoError: 'Phone Number already exists', saving: false })
            return
        }
        else {
            if (key !== "") {
                //update customer data
                let customer = {
                    CustomerCode: this.state.customerCode,
                    FirstName: this.state.firstName,
                    LastName: this.state.lastName,
                    EmailID: this.state.emailID,
                    PhoneNo: this.state.phoneNo,
                    DOB: null,
                    Anniversary: null,
                    DOBNew: this.state.dobNew,
                    AnniversaryNew: this.state.anniversaryNew,
                    Membership: this.state.membership,
                    CustomerAcceptsMarketing: this.state.customerAcceptsMarketing,
                    Note: this.state.note,
                    Gender: this.state.gender,
                    Addresses: this.state.addresses,
                    StoreID: this.state.storeID,
                    CustomField1: this.state.customField1,
                    CustomField2: this.state.customField2,
                    CustomField3: this.state.customField3,
                    CustomField4: this.state.customField4,
                    CustomField5: this.state.customField5,
                    CustomField6: this.state.customField6,
                    RegistrationID: this.props.user.user.RegistrationID,
                    StoreID: this.props.user.store.key,
                    IsDeleted: false,
                    BackEndUpdate: false,
                    // SaleToPurchaseStoreID : this.state.saleToPurchaseStoreID,
                    // SaleToPurchaseStore : this.state.saleToPurchaseStore
                }
                this.props.updateCustomer(customer, key)
                this.props.pushCustomerToRedux({ ...customer, key: key })
                this.props.getToastr("Customer Saved Successfully")
                setTimeout(function () { //Start the timer
                    this.setState({ saving: false })
                    if (this.props.calledFromInvoice) {
                        let selectedCustomer = {
                            key: key,
                            CustomerCode: customer.CustomerCode,
                            FirstName: customer.FirstName,
                            LastName: customer.LastName,
                            EmailID: customer.EmailID,
                            PhoneNo: customer.PhoneNo,
                            Membership: customer.Membership,
                            Note: customer.hasOwnProperty('Note') ? customer.Note : "",
                            CustomField1: customer.CustomField1,
                            CustomField2: customer.CustomField2,
                            CustomField3: customer.CustomField3,
                            CustomField4: customer.CustomField4,
                            CustomField5: customer.CustomField5,
                            CustomField6: customer.CustomField6,
                            WalletBalance: this.state.walletBalance,
                            Addresses: customer.Addresses,
                            OutStandingAmount: this.state.outStandingAmount
                        }
                        this.props.onSave({ ...selectedCustomer })
                    }
                    else {
                        this.props.history.push("/home/customer")
                    }
                }.bind(this), 2000)

            }
            else {
                let customer = {
                    CustomerCode: this.state.customerCode,
                    FirstName: this.state.firstName,
                    LastName: this.state.lastName,
                    EmailID: this.state.emailID,
                    PhoneNo: this.state.phoneNo,
                    Tag: this.state.tag,
                    DOB: null,
                    Anniversary: null,
                    DOBNew: this.state.dobNew,
                    AnniversaryNew: this.state.anniversaryNew,
                    Membership: this.state.membership,
                    WalletOpeningBalance: this.state.walletOpeningBalance ? Number(this.state.walletOpeningBalance) : 0,
                    WalletBalance: this.state.walletOpeningBalance ? Number(this.state.walletOpeningBalance) : 0,
                    OpeningOutStandingAmount: this.state.openingOutStandingAmount ? Number(this.state.openingOutStandingAmount) : 0,
                    OpeningOutStandingAmountCurrent: this.state.openingOutStandingAmount ? Number(this.state.openingOutStandingAmount) : 0,
                    OpeningOutStandingAmountReceived: 0,
                    OutStandingAmount:this.state.openingOutStandingAmount ? Number(this.state.openingOutStandingAmount) : 0,
                    OutStandingAmountReceived:0,
                    CustomerAcceptsMarketing: this.state.customerAcceptsMarketing,
                    Note: this.state.note,
                    Gender: this.state.gender,
                    Addresses: this.state.addresses,
                    StoreID: this.state.storeID,
                    CustomField1: this.state.customField1,
                    CustomField2: this.state.customField2,
                    CustomField3: this.state.customField3,
                    CustomField4: this.state.customField4,
                    CustomField5: this.state.customField5,
                    CustomField6: this.state.customField6,
                    RegistrationID: this.props.user.user.RegistrationID,
                    StoreID: this.props.user.store.key,
                    IsDeleted: false,
                    BackEndUpdate: false,
                    SaleToPurchaseStoreID : this.state.saleToPurchaseStoreID,
                    SaleToPurchaseStore : this.state.saleToPurchaseStore
                }

                key = await this.props.addCustomer(customer);
                this.props.pushCustomerToRedux({ ...customer, key: key })
                // console.log("Customer Key", key);
                this.props.getToastr("Customer Saved Successfully")
                setTimeout(function () { //Start the timer
                    if (this.props.calledFromInvoice) {

                        let cust = { ...customer, key: key }
                        this.props.onSave(cust)
                    }
                    else {
                        this.props.history.push("/home/customer")
                    }
                }.bind(this), 2000)
                
            }
        }
    }

    loadMembership() {

        this.setState({ membershiplist: this.props.config.Memberships })
        
        // this.props.getMembership(this.props.user.user.RegistrationID, 0, 10000, "active", "").then((memberships) => {
        //     this.setState({ membershiplist: memberships.searchResult })
        // })
    }

    onMembershipChange(e) {
        let membership = {
            MembershipID: e.target.value,
            Name: e.target.options[e.target.selectedIndex].text
        }
        this.setState({ membership: membership })
    }

    render() {
        const styleEditor = {
            width: "100%",
            height: "150px",
            font_size: "14px",
            line_height: "18px",
            border: "1px solid #dddddd",
            padding: "10px"
        }
        const modalWidth = {
            width: '500px'
        }
        const btnColStyle = { width: "120px" }

        const handlers = {
            save: this.onSaveCustomer,
            back: this.onCancel
        };

        return <HotKeys
            keyMap={keyMap}
            handlers={handlers}><div className="content-wrapper">
                <section className="content-header">
                    <h1>Customer</h1>
                    <button type="submit" className="btn btn-md btn-success btn-flat pull-right"
                        style={{ marginTop: "-25px", marginLeft: "10px" }}
                        onClick={this.onSaveCustomer} disabled={this.state.saving}>Save</button>
                    <button className="btn btn-md btn-flat btn-default pull-right" style={{ marginTop: "-25px" }}
                        onClick={this.onCancel} >Cancel</button>

                </section>
                <section className="content" >
                    <div className="box ">
                        <div className="box-header with-border">
                            <h3 className="box-title">General Details</h3>
                        </div>
                        <div className="box-body">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group " >
                                        <label >Customer Code</label>
                                        <input type="text" className="form-control"
                                            onKeyPress={this.handleKeyPress}
                                            autoFocus={true}
                                            onChange={(evt) => { this.setState({ customerCode: evt.target.value }) }}
                                            value={this.state.customerCode} />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className={this.state.firstNameError ? "form-group has-error" : "form-group "} >
                                        <label >First Name</label>
                                        <input type="text" className="form-control"
                                            onKeyPress={this.handleKeyPress}
                                            onChange={(evt) => { this.setState({ firstName: evt.target.value, firstNameError: '' }) }}
                                            value={this.state.firstName} />
                                        {this.state.firstNameError && <span className="help-block">{this.state.firstNameError}</span>}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Last Name</label>
                                        <input type="text" className="form-control"
                                            onKeyPress={this.handleKeyPress}
                                            onChange={(evt) => this.setState({ lastName: evt.target.value })}
                                            value={this.state.lastName}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group" >
                                        <label >Gender</label>
                                        <select className="form-control" value={this.state.gender} onChange={(evt) => this.setState({ gender: evt.target.value })}>
                                            <option >Select gender</option>
                                            <option >Female</option>
                                            <option >Male</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <div className={(this.state.emailORPhoneError || this.state.phoneNoError) ? "form-group has-error" : "form-group "}  >
                                        <label >Phone Number</label>
                                        <input type="text" className="form-control"
                                            onKeyPress={this.handleKeyPress}
                                            onChange={(evt) => this.setState({ phoneNo: evt.target.value, emailORPhoneError: false, phoneNoError: false })}
                                            value={this.state.phoneNo}
                                        />
                                        {(this.state.emailORPhoneError || this.state.phoneNoError) && <span className="help-block">{this.state.emailORPhoneError ? "Please provide either Phone Number or Email" : (this.state.phoneNoError ? "Phone No already exists" : "")} </span>}
                                    </div>

                                </div>
                                <div className="col-md-3">
                                    <div className={this.state.emailORPhoneError ? "form-group has-error" : "form-group "} >
                                        <label >Email</label>
                                        <input type="text" className="form-control"
                                            onKeyPress={this.handleKeyPress}
                                            onChange={(evt) => this.setState({ emailID: evt.target.value, emailORPhoneError: false })}
                                            value={this.state.emailID}
                                        />
                                        {this.state.emailORPhoneError && <span className="help-block">Please provide either Phone Number or Email</span>}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className={this.state.dobError ? "form-group has-error" : "form-group "} >
                                        <label >Date Of Birth</label>
                                        <CustomerDate
                                            customerDate={this.state.dobNew}
                                            error={this.state.dobError}
                                            onChange={(dobNew) => this.setState({ dobNew: dobNew, dobError: "" })}
                                        />
                                        {this.state.dobError !== "" && <span className="help-block">{this.state.dobError}</span>}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group" >
                                        <label >Anniversary Date</label>
                                        <CustomerDate
                                            customerDate={this.state.anniversaryNew}
                                            error={this.state.anniversaryError}
                                            onChange={(anniversaryNew) => this.setState({ anniversaryNew: anniversaryNew, anniversaryError: "" })}
                                        />
                                        {this.state.anniversaryError !== "" && <span className="help-block">{this.state.anniversaryError}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group" >
                                                <label >Membership</label>
                                                <select type="text" className="form-control"
                                                    value={this.state.membership ? this.state.membership.MembershipID : null}
                                                    onChange={this.onMembershipChange}
                                                    disabled={this.props.registration.registration.AutoUpgradeMembership}
                                                >
                                                    <option value="">Select Membership</option>
                                                    {this.state.membershiplist.map((membership) =>
                                                        <option value={membership.key}>{membership.Name}</option>
                                                    )}
                                                </select>


                                                {this.state.membershipError && <span className="help-block">Please provide membership</span>}
                                            </div>
                                        </div>
                                        {this.state.referredByCustomer && <div className="col-md-6">
                                            <div className="form-group" >
                                                <label >Referred By</label>
                                                <input type="text" className="form-control"
                                                    value={this.state.referredByCustomer.Name + ' ' + this.state.referredByCustomer.PhoneNo} disabled />
                                            </div>
                                        </div>}
                                        {/* <div className="col-md-6">
                                        <div className="form-group" >
                                            <label >Tags</label>
                                            <CreatableSelect
                                                isMulti={true}

                                            />
                                        </div>
                                    </div>   */}
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group" >
                                                <label >Wallet Opening Balance</label>
                                                <input type="text" className="form-control"
                                                    onKeyPress={this.handleKeyPress}
                                                    disabled={this.state.key !== "" ? true : false}
                                                    onChange={(evt) => this.setState({ walletOpeningBalance: evt.target.value, walletOpeningBalanceError: false })}
                                                    value={this.state.walletOpeningBalance}
                                                />
                                                {this.state.walletOpeningBalanceError && <span className="help-block">Please provide wallet Balance</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group" >
                                                <label >Outstanding Opening Amount</label>
                                                <input type="text" className="form-control"
                                                    onKeyPress={this.handleKeyPress}
                                                    disabled={this.state.key !== "" ? true : false}
                                                    onChange={(evt) => this.setState({ openingOutStandingAmount: evt.target.value })}
                                                    value={this.state.openingOutStandingAmount}
                                                />
                                               </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group" >
                                                <input type="checkbox" name="customerAcceptsMarketing" onChange={() => this.setState({ customerAcceptsMarketing: !this.state.customerAcceptsMarketing })} />
                                                <label for="customerAcceptsMarketing">Customer accepts marketing</label>
                                            </div>
                                        </div>
                                        {this.props.user.store.hasOwnProperty('EnableSaleToPurchase') && this.props.user.store.EnableSaleToPurchase && <div className="col-md-6">
                                            <div className={this.state.saleToPurchaseStoreError ? "form-group has-error" : "form-group "}  >
                                                <label >Store</label>
                                                <select type="text" className="form-control"
                                                    value={this.state.saleToPurchaseStoreID}
                                                    disabled={this.state.key !== "" ? true : false}
                                                    onChange={(evt) => this.setState({ saleToPurchaseStoreID: evt.target.value,saleToPurchaseStore: evt.target.options[evt.target.selectedIndex].text ,saleToPurchaseStoreError:'' })}>
                                                    <option value="">Select Store</option>
                                                    {this.state.stores.filter((e) => e.key !== this.props.user.store.key && !e.IsDeleted).map((store) =>
                                                        <option value={store.key}>{store.StoreName}</option>
                                                    )}
                                                </select>
                                                {this.state.saleToPurchaseStoreError && <span className="help-block">Store is already mapped to a customer</span>}
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group" >
                                        <label >Notes</label>
                                        <textarea className="text" style={styleEditor}
                                            onKeyPress={this.handleKeyPress}
                                            onChange={(evt) => this.setState({ note: evt.target.value })}
                                            value={this.state.note}></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box ">
                        <div className="box-header with-border">
                            <h3 className="box-title">Address</h3>
                            <button className="btn btn-primary pull-right btn-flat" onClick={() => this.setState({ isShowingModalCustomerAddress: true, selectedAddressIndex: -1, selectedAddress: null })}>Add Address</button>
                        </div>
                        <div className="box-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>Address Type</th>
                                                <th>Address</th>
                                                <th>Address</th>
                                                <th>City</th>
                                                <th>Postal Code</th>
                                                <th>State</th>
                                                <th>Country</th>
                                                <th style={btnColStyle}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.addresses.map((address, index) =>
                                                <tr>
                                                    <td>{address.AddressType}</td>
                                                    <td>{address.Address1}</td>
                                                    <td>{address.Address2}</td>
                                                    <td>{address.City}</td>
                                                    <td>{address.PostalCode}</td>
                                                    <td>{address.State}</td>
                                                    <td>{address.Country}</td>
                                                    <td>
                                                        <div className="btn-group">
                                                            <button type="button" className="btn btn-md btn-flat btn-default " onClick={() => this.onAddressSelected(index, address)} ><i className="fa fa-edit"></i></button>
                                                            <button type="button" className="btn btn-default btn-flat btn-md" onClick={() => this.onAddressRemove(index)}><i className="fa fa-trash-o"></i></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box ">
                        <div className="box-header with-border">
                            <h3 className="box-title">References</h3>
                        </div>
                        <div className="box-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>Gender</th>
                                                <th>Email ID</th>
                                                <th>Phone Number</th>
                                                <th>Date of Birth</th>
                                                <th>Anniversary</th>
                                                <th>Relation</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.references && this.state.references.map((customer, index) =>
                                                <tr>
                                                    <td>{customer.FirstName}</td>
                                                    <td>{customer.LastName}</td>
                                                    <td>{customer.Gender}</td>
                                                    <td>{customer.EmailID}</td>
                                                    <td>{customer.PhoneNo}</td>
                                                    <td>
                                                        {customer.DOBNew && <React.Fragment> {customer.DOBNew.Day + "-" + customer.DOBNew.Month + "-" + customer.DOBNew.Year === "--" ? "" : customer.DOBNew.Day + "-" + customer.DOBNew.Month + "-" + customer.DOBNew.Year} </React.Fragment>} </td>
                                                    <td>
                                                        {customer.AnniversaryNew && <React.Fragment> {customer.AnniversaryNew.Day + "-" + customer.AnniversaryNew.Month + "-" + customer.AnniversaryNew.Year === "--" ? "" : customer.AnniversaryNew.Day + "-" + customer.AnniversaryNew.Month + "-" + customer.AnniversaryNew.Year}</React.Fragment>}
                                                    </td>
                                                    <td>{customer.Relation}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box ">
                        <div className="box-header with-border">
                            <h3 className="box-title">Additonal Information</h3>
                        </div>
                        <div className="box-body">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group" >
                                        <label >{this.state.customField1DisplayName}</label>
                                        <input type="text" className="form-control"
                                            onChange={(evt) => this.setState({ customField1: evt.target.value, firstNameError: false })}
                                            value={this.state.customField1}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>{this.state.customField2DisplayName}</label>
                                        <input type="text" className="form-control"
                                            onChange={(evt) => this.setState({ customField2: evt.target.value })}
                                            value={this.state.customField2}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group" >
                                        <label >{this.state.customField3DisplayName}</label>
                                        <input type="text" className="form-control"
                                            onChange={(evt) => this.setState({ customField3: evt.target.value, firstNameError: false })}
                                            value={this.state.customField3}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>{this.state.customField4DisplayName}</label>
                                        <input type="text" className="form-control"
                                            onChange={(evt) => this.setState({ customField4: evt.target.value })}
                                            value={this.state.customField4}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group" >
                                        <label >{this.state.customField5DisplayName}</label>
                                        <input type="text" className="form-control"
                                            onChange={(evt) => this.setState({ customField5: evt.target.value, firstNameError: false })}
                                            value={this.state.customField5}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>{this.state.customField6DisplayName}</label>
                                        <input type="text" className="form-control"
                                            onChange={(evt) => this.setState({ customField6: evt.target.value })}
                                            value={this.state.customField6}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="box-footer">
                        <button onClick={this.onCancel} className="btn btn-md btn-flat btn-default"  >Cancel</button>
                        <button type="submit" className="btn btn-md btn-success btn-flat pull-right" onClick={this.onSaveCustomer}>Save</button>
                    </div> */}

                    </div>


                    <div >
                        <button type="submit" className="btn btn-md btn-success btn-flat pull-right"
                            style={{ marginBottom: "10px", marginLeft: "10px" }}
                            onClick={this.onSaveCustomer} disabled={this.state.saving}>Save</button>
                        <button className="btn btn-md btn-flat btn-default pull-right" style={{ marginBottom: "10px" }}
                            onClick={this.onCancel} >Cancel</button>
                    </div>


                </section>
                <Modal open={this.state.isShowingModalCustomerAddress} onClose={() => this.setState({ isShowingModalCustomerAddress: false })} center>
                    <div style={modalWidth}>
                        <CustomerAddress   {...this.props}
                            selectedAddressIndex={this.state.selectedAddressIndex}
                            selectedAddress={this.state.selectedAddress}
                            onAddressAdded={this.addressAddedCallBack}
                            onClose={() => this.setState({ isShowingModalCustomerAddress: false })} />
                    </div>
                </Modal>
            </div>
        </HotKeys>
    }
}

export default withRouter(Customer)