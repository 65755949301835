import React, { Component } from 'react';
import Dropzone from 'react-dropzone'
import CreatableSelect from 'react-select'
import Modal from 'react-responsive-modal'
import AddEditProductCategory from './AddEditProductCategory'
import AddEditProductBrand from './AddEditProductBrand'
import { withRouter } from 'react-router-dom'
import * as actionTypes from '../../actionTypes'
import AuditTrail from '../AuditTrail'
import * as constants from '../../Constatnts'
import {HotKeys} from 'react-hotkeys';

const keyMap = {
    save:'ctrl+s',
    back:'ctrl+b'
};

class Product extends Component {
    constructor() {
        super()
        this.state = {           
            sku: '',
            barCode: '',
            name: '',
            productTypeID: 0,
            productType: '',
            productTypeError: '',
            description: '',
            category: '',
            categoryID: '',
            subCategory: '',
            subCategoryID: '',
            brand: '',
            brandID: '',
            tags: '',
            images: [],
            compareAtPrice: '',
            supplyPrice: 0,
            markup: 0,
            retailPrice: 0,
            MRP: 0,
            allowDiscount: true,
            allowPriceEdit: true,
            allowTaxChange: true,
            chargeTaxOnPoduct: false,
            taxInclusive: false,
            priceBasedTax: false,
            walletTopUpSameAsRetailPrice: true,
            walletTopUpAmount: 0,
            walletTopUpError: '',
            taxID: '',
            taxGroup: '',
            taxPriceRange: null,
            initialTaxPriceRange: [{ FromPrice: 0, ToPrice: 0, TaxID: '', TaxGroup: '', FromPriceErrMsg: '', ToPriceErrMsg: '', TaxErrMsg: '' },
            { FromPrice: 0, ToPrice: 0, TaxID: '', TaxGroup: '', FromPriceErrMsg: '', ToPriceErrMsg: '', TaxErrMsg: '' }],
            HSNSACCode: '',
            categoryList: [],
            subCategoryList: [],
            brandList: [],
            tagList: [],
            isShowingModalAddCategory: false,
            firstLoad: false,
            isShowingModalAddBrand: false,
            isShowingModalAddSubCategory: false,
            nameError: false,
            categoryError: false,
            priceError: '',
            supplyPriceError: '',
            markupError: '',
            mrpError: '',
            skuError: false,
            categoryOrSubCategory: '',
            saving: false,
            editWalletTopUpAmount:false,
            accessibleToSelectedStores:false,
            storeSpecificPricing:false,
            storeSpecificPrices:[],
            storeRetailPrice:0,
            storeSupplyPrice:0,
            storeMarkUp:0,
            storeMRP:0,
            storePriceStore:'',
            storePriceStoreID:'',
            storePriceStoreError:'',
            stores:[],//stores accessible to logged in user,
            storeWalletTopUpAmount:0,
            storeWalletTopUpSameAsRetailPrice:true,
            storeEditWalletTopUpAmount:false,
            storeSpecificPricesError:'',
            discountAmount:0,
            discountPercentage:0,
            storeDiscountAmount:0,
            storeDiscountPercentage:0,
            minQty:0,
            maxQty:0,
            reorderQty:0,
            inventoryType:'FIFO',
            uniqueLabel:'',
            //Package Related
            services: [],
            service: '',
            serviceID: '',
            packageServices: [],
            serviceError: '',
            validityInDays:'',
            validityInDaysError:''
        }
        this.onDrop = this.onDrop.bind(this)
        this.onRemoveImage = this.onRemoveImage.bind(this)
        this.onCategoryChange = this.onCategoryChange.bind(this)
        this.onProductTypeChange = this.onProductTypeChange.bind(this)
        this.onSubCategoryChange = this.onSubCategoryChange.bind(this)
        this.onBrandChange = this.onBrandChange.bind(this)
        this.onSaveProduct = this.onSaveProduct.bind(this)
        this.onTaxChange = this.onTaxChange.bind(this)
        this.loadProductBrands = this.loadProductBrands.bind(this)
        this.onFromPriceChange = this.onFromPriceChange.bind(this)
        this.onToPriceChange = this.onToPriceChange.bind(this)
        this.onPriceBasedTaxChange = this.onPriceBasedTaxChange.bind(this)
        this.onCancel = this.onCancel.bind(this)
        this.onAddStoreSpecificPrice = this.onAddStoreSpecificPrice.bind(this)

        //Package Related
        this.onAddService = this.onAddService.bind(this)
        this.onRemoveService = this.onRemoveService.bind(this)
    }

    componentDidMount() {

        this.setState({ stores: this.props.user.userStores })

        // this.props.getUserStores(this.props.user.user.RegistrationID, this.props.user.auth.uid).then((stores) => {
        //         this.setState({ stores: stores })
        // })

        const key = this.props.match.params.key
        if (key) {//existing product
            this.props.getProductDetails(key).then((product) => {
            //    console.log('product',product)
                this.setState({
                    sku: product.SKU,
                    barCode: product.BarCode,
                    name: product.Name,
                    productID: product.ProductID,
                    productType: product.ProductType,
                    productTypeID: product.ProductTypeID,
                    description: product.Description,
                    category: product.Category,
                    categoryID: product.CategoryID,
                    subCategory: product.SubCategory,
                    subCategoryID: product.SubCategoryID,
                    brand: product.Brand,
                    brandID: product.BrandID,
                    tags: product.tags,
                    supplyPrice: product.SupplyPrice,
                    markup: product.Markup,
                    retailPrice: product.RetailPrice,
                    MRP: product.MRP,
                    allowDiscount: product.hasOwnProperty('AllowDiscount') ? product.AllowDiscount : true,
                    allowPriceEdit: product.hasOwnProperty('AllowPriceEdit') ? product.AllowPriceEdit : true,
                    allowTaxChange: product.hasOwnProperty('AllowTaxChange') ? product.AllowTaxChange : true,
                    priceBasedTax: product.PriceBasedTax,
                    walletTopUpSameAsRetailPrice: product.hasOwnProperty('WalletTopUpSameAsRetailPrice') ? product.WalletTopUpSameAsRetailPrice : true,
                    walletTopUpAmount: product.hasOwnProperty('WalletTopUpAmount') ? product.WalletTopUpAmount : 0,
                    chargeTaxOnPoduct: product.ChargeTaxOnProduct,
                    taxInclusive: product.TaxInclusive,
                    HSNSACCode: product.HSNSACCode,
                    taxID: product.TaxID,
                    taxGroup: product.TaxGroup,
                    taxPriceRange: product.TaxPriceRange,
                    images: product.Images,
                    editWalletTopUpAmount:product.hasOwnProperty('EditWalletTopUpAmount') ? product.EditWalletTopUpAmount :false,
                    accessibleToSelectedStores:product.hasOwnProperty('AccessibleToSelectedStores') ? product.AccessibleToSelectedStores :false,
                    storeSpecificPricing:product.hasOwnProperty('StoreSpecificPricing') ? product.StoreSpecificPricing :false,
                    storeSpecificPrices: product.hasOwnProperty('StoreSpecificPrices') ? product.StoreSpecificPrices :[],
                    discountAmount:product.hasOwnProperty('DiscountAmount') ? product.DiscountAmount : 0,
                    discountPercentage:product.hasOwnProperty('DiscountPercentage') ? product.DiscountPercentage : 0,
                    minQty: product.hasOwnProperty('MinQty')? product.MinQty : 0,
                    maxQty: product.hasOwnProperty('MaxQty')? product.MaxQty : 0,
                    reorderQty: product.hasOwnProperty('ReorderQty')? product.ReorderQty : 0,
                    inventoryType: product.hasOwnProperty('InventoryType') ? product.InventoryType: 'FIFO',
                    uniqueLabel: product.hasOwnProperty('UniqueLabel') ? product.UniqueLabel : '',
                    packageServices: product.hasOwnProperty('PackageServices') ? product.PackageServices : [],
                    validityInDays: product.hasOwnProperty('ValidityInDays') ? product.ValidityInDays.toString() : ''
                })
                if (this.state.productTypeID == 5) {
                    this.props.getProducts(this.props.user.user.RegistrationID, 0, 10000, "active", "", "", "", "", "", "", "").then((result) => {
                        let services = result.searchResult
                        this.setState({ services: services })
                    })      
                }
            })
        }
        else
        {
            if(this.props.user.user.Role.toLowerCase() !== "power user")
            this.setState({accessibleToSelectedStores:true})
        }
    }

    onDrop(acceptedFiles, rejectedFiles) {
        let error = false
        if (acceptedFiles.length + this.state.images.length > 1) {
            alert('You canot upload more than 1 file')
            error = true
        }
        //validate image extension and image size
        acceptedFiles.map((i) => {
            if (Math.round(i.size / 1024) > 100) {
                alert('File size can not be greater than 100 KB')
                error = true
            }
            let ext = i.name.substring(i.name.length - 3, i.name.length)
            if (ext === "jpg" || ext === "png" || ext === "svg") {

            }
            else {
                alert('Invalid file format')
                error = true
            }
        })
        if (error) {
            return
        }
        let imagesToBeUploaded = this.state.images
        acceptedFiles.map((i) => {
            let img = {
                ImageName: i.name,
                ImageSize: i.size,
                ImagePath: i.preview,
                UploadToServer: true,
                image: i
            }
            imagesToBeUploaded.push(img)
        })
        this.setState({ images: imagesToBeUploaded })
        //console.log(this.state.images)
    }

    onRemoveImage(index) {
        let array = [...this.state.images]
        array.splice(index, 1);
        this.setState({ images: array });
    }

    onCategoryChange = (e) => {
        let categoryID = e.target.value
        let category = e.target.options[e.target.selectedIndex].text
        if (categoryID !== "") { this.setState({ categoryError: false }) }
        this.setState({ category: category, categoryID: categoryID })
        //this.setState({subCategoryList: this.props.product.Categories[categoryIndex].SubCategories})
    };

    onProductTypeChange= (e) => {
        let productTypeID  = e.target.value
        let productType = e.target.options[e.target.selectedIndex].text
        if (productTypeID !== "") { this.setState({ productTypeError: "" }) }
        this.setState({
            productTypeID: Number(productTypeID),
            productType: productType
        })

        if(productTypeID === "5")
        {
            this.props.getProducts(this.props.user.user.RegistrationID, 0, 10000, "active", "", "", "", "", "", "", "").then((result) => {
                let services = result.searchResult
                this.setState({ services: services })
            })

            
        }
        else {
            this.setState({ services: [] })
        }
    };

    onSubCategoryChange = (e) => {
        let subCategoryID = e.target.value
        let subCategory = e.target.options[e.target.selectedIndex].text
        this.setState({ subCategory: subCategory, subCategoryID: subCategoryID })
    };

    onBrandChange(e) {
        let brandID = e.target.value
        let brand = e.target.options[e.target.selectedIndex].text
        this.setState({ brand: brand, brandID: brandID })
        //console.log('onBrandChange',e.target.value )
    }

    onTaxChange(e) {
        let taxID = e.target.value
        let tax = e.target.options[e.target.selectedIndex].text
        this.setState({ taxGroup: tax, taxID: taxID })
        //console.log('onTaxChange',e.target.value )
    }

    onCancel() {
        if (this.props.calledFromInvoice) {
            // console.log('Called from invoice ')
            this.props.onClose()
        }
        else {
            // console.log('Not called fomm invoice ')
            this.props.history.push("/home/product")
        }
    }

    async onSaveProduct() {
        let nameError = false
        let categoryError = false
        let priceError = ''
        let skuError = false
        let priceBasedTaxError = false
        let productTypeError = ""
        let walletTopUpError = ""
        let supplyPriceError = ""
        let markupError = ""
        let mrpError = ""
        let storeSpecificPricesError = ""
        let packageServicesError = ""
        let validityInDaysError = ""

        this.setState({ saving:true})
        //console.log('this.state.productTypeID', this.state.productTypeID)
        if (this.state.productTypeID === 0) {
            productTypeError = "Please select product type"
        }
        if (this.state.category === '') {
            nameError = true
        }
        if (this.state.retailPrice < 0) {
            priceError = 'Retail price cannot be negative'
        }
        if (!this.state.allowPriceEdit && this.state.retailPrice === 0) {
            priceError = 'Please provide retail price'
        }
        if (this.state.supplyPrice < 0) {
            supplyPriceError = 'Supply price cannot be negative'
        }
        if (this.state.MRP < 0) {
            mrpError = 'MRK cannot be negative'
        }
        if (this.state.markup < 0) {
            markupError = 'Markup cannot be negative'
        }
        if (this.state.category === '') {
            categoryError = true
        }
        if (this.state.sku === '') {
            skuError = true
        }

        if (this.state.productTypeID === 4 && !this.state.walletTopUpSameAsRetailPrice && this.state.walletTopUpAmount === 0) {
            walletTopUpError = "Please provide wallet recharge value"

        }
        if (this.state.priceBasedTax) {

            let taxPriceRange = this.state.taxPriceRange
            this.state.taxPriceRange.map((t, index) => {
                if (index > 0 && t.FromPrice <= 0) {
                    taxPriceRange[index].FromPriceErrMsg = "Value cannot be 0 or negative"
                    priceBasedTaxError = true
                }
                if (t.ToPrice <= 0) {
                    taxPriceRange[index].ToPriceErrMsg = "Value cannot be 0 or negative"
                    priceBasedTaxError = true
                }
                if (t.TaxID === "") {
                    taxPriceRange[index].TaxErrMsg = "Please select tax"
                    priceBasedTaxError = true
                }

            })
            this.setState({ taxPriceRange: taxPriceRange })
        }
        if(this.state.accessibleToSelectedStores)
        {
            if(this.state.storeSpecificPrices.length===0 )
            {
                storeSpecificPricesError= "Please specify store prices"
            }
        }
        if(this.state.productTypeID === 5)
        {
        

            if (this.state.validityInDays.trim() === "") {
                validityInDaysError = "Please provide validity in day's"
            }
    
            

            if(this.state.packageServices.length === 0)
            {
                packageServicesError = "Please select atleast one service."
                alert(packageServicesError);
            }
        }
        if (nameError || priceError !== "" || supplyPriceError !== "" || markupError !== ""
            || categoryError || skuError || priceBasedTaxError || productTypeError !== "" 
            || walletTopUpError !== "" ||storeSpecificPricesError!=="" || packageServicesError!=""
            || validityInDaysError!="") {
            this.setState({
                nameError: nameError, priceError: priceError, supplyPriceError: supplyPriceError,
                markupError: markupError,
                categoryError: categoryError, skuError: skuError,
                productTypeError: productTypeError, walletTopUpError,
                storeSpecificPricesError:storeSpecificPricesError,
                packageServicesError: packageServicesError,
                validityInDaysError:validityInDaysError,
                saving:false
            })
            return
        }
        
        let product = {
            RegistrationID: this.props.user.user.RegistrationID,
            Name: this.state.name,
            ProductTypeID: this.state.productTypeID,
            ProductType: this.state.productType,
            Description: this.state.description,
            SKU: this.state.sku,
            BarCode: this.state.barCode,
            Category: this.state.category,
            CategoryID: this.state.categoryID,
            SubCategoryID: this.state.subCategoryID,
            SubCategory: this.state.subCategory,
            //Tags:this.state.tags,
            BrandID: this.state.brandID,
            Brand: this.state.brand,
            SupplyPrice: this.state.supplyPrice,
            Markup: this.state.markup,
            RetailPrice: this.state.retailPrice,
            MRP: this.state.MRP,
            WalletTopUpSameAsRetailPrice: this.state.walletTopUpSameAsRetailPrice,
            WalletTopUpAmount: this.state.walletTopUpSameAsRetailPrice ? this.state.retailPrice : this.state.walletTopUpAmount,
            AllowDiscount: this.state.allowDiscount,
            AllowPriceEdit: this.state.allowPriceEdit,
            AllowTaxChange: this.state.allowTaxChange,
            PriceBasedTax: this.state.priceBasedTax,
            ChargeTaxOnProduct: this.state.chargeTaxOnPoduct,
            TaxInclusive: this.state.taxInclusive,
            HSNSACCode: this.state.HSNSACCode,
            TaxID: this.state.taxID,
            TaxGroup: this.state.taxGroup,
            PriceBasedTax: this.state.priceBasedTax,
            TaxPriceRange: this.state.priceBasedTax ? this.state.taxPriceRange : [],
            Images: this.state.images.filter((i) => !i.UploadToServer),
            IsDeleted: false,
            EditWalletTopUpAmount:this.state.editWalletTopUpAmount,
            AccessibleToSelectedStores:this.state.accessibleToSelectedStores,
            StoreSpecificPricing:this.state.storeSpecificPricing,
            StoreSpecificPrices: this.state.storeSpecificPrices ,
            DiscountAmount: this.state.discountAmount,
            DiscountPercentage: this.state.discountPercentage,
            MinQty: this.state.minQty,
            MaxQty: this.state.maxQty,
            ReorderQty: this.state.reorderQty,
            UniqueLabel:this.state.uniqueLabel,
            InventoryType: this.state.inventoryType,

            //Package
            PackageServices: this.state.packageServices,
            ValidityInDays: Number(this.state.validityInDays),
        }
        //console.log('product', JSON.stringify(product));

        let key = this.props.match.params.key
        if (key) {//update product details
            this.props.updateProduct(product, key)
            this.props.pushProductToRedux({ ...product, key: key })
            this.props.getToastr("Product Saved Successfully")
            this.state.images.filter((i) => i.UploadToServer).map((image, index) => {
                this.props.uploadFileToServer(image.image, this.props.registration.registration.StorageFolder + '/Product').then((downloadURL) => {
                    //update product with images url
                    this.props.updateProductImage(key, downloadURL, image.ImageName, image.ImageSize)
                    //console.log('File available at', downloadURL);
                })
            })
            setTimeout(function () { //Start the timer
                this.setState({ saving: false })

                this.props.history.push('/home/product')
            }.bind(this), 2000)
        }
        else {

            key = await this.props.addNewProduct(product)
            console.log("Product - key", key);
            this.props.pushProductToRedux({ ...product, key: key })
            this.props.getToastr("Product Saved Successfully")
            //Discuss with Pawan
            // this.state.images.filter((i) => i.UploadToServer).map((image, index) => {
            //     this.props.uploadFileToServer(image.image, this.props.registration.registration.StorageFolder + '/Product').then((downloadURL) => {
            //         //update product with images url
            //         this.props.updateProductImage(key, downloadURL, image.ImageName, image.ImageSize)
            //         //console.log('File available at', downloadURL);
            //     })
            // })
            setTimeout(function () { //Start the timer
                if (this.props.calledFromInvoice) {
                    //console.log('Called fomm invoice ')
                    product.ActionOn = null
                    this.props.onSave({ ...product, key: key })
                }
                else {
                    //console.log('Not called fomm invoice ')
                    this.props.history.push('/home/product')
                }

            }.bind(this), 2000)
        }
    }

    loadProductBrands(brandID) {
        this.props.getProductBrands(actionTypes, this.props.user.user.RegistrationID).then((serachResult) => {
            // console.log('loadProductBrands serachResult', serachResult)
            this.setState({ brandList: serachResult.searchResult, brandID: brandID })
        })
    }

    onFromPriceChange(index, value) {
        let taxPriceRange = this.state.taxPriceRange
        taxPriceRange[index].FromPrice = value
        taxPriceRange[index].FromPriceErrMsg = ""
        this.setState({ taxPriceRange: taxPriceRange })
    }

    onToPriceChange(index, value) {
        let taxPriceRange = this.state.taxPriceRange
        taxPriceRange[index].ToPrice = value
        taxPriceRange[index].ToPriceErrMsg = ""
        this.setState({ taxPriceRange: taxPriceRange })
    }

    onPriceBasedTaxChange(index, evt) {
        let taxPriceRange = this.state.taxPriceRange
        taxPriceRange[index].TaxID = evt.target.value
        taxPriceRange[index].TaxGroup = evt.target.options[evt.target.selectedIndex].text
        taxPriceRange[index].TaxErrMsg = ""
        this.setState({ taxPriceRange: taxPriceRange })
    }

    onAddStoreSpecificPrice(){
        
        if(this.state.storePriceStoreID.trim()==="")
        {
            this.setState({storePriceStoreError: "Please select store"})
            return 
        }
        //check if selected store is already added to list 
        if(this.state.storeSpecificPrices.filter(f=> f.StoreID === this.state.storePriceStoreID).length > 0 ){
            this.setState({storePriceStoreError: "Selected store already added"})
            return 
        }
        let storeSpecificPrice= {
            StoreID: this.state.storePriceStoreID,
            Store:this.state.storePriceStore,
            SupplyPrice:this.state.storeSupplyPrice,
            Markup: this.state.storeMarkUp,
            RetailPrice: this.state.storeRetailPrice,
            MRP: this.state.storeMRP,
            WalletTopUpSameAsRetailPrice:this.state.storeWalletTopUpSameAsRetailPrice,
            WalletTopUpAmount: this.state.storeWalletTopUpSameAsRetailPrice ? this.state.storeRetailPrice : this.state.storeWalletTopUpAmount,
            EditWalletTopUpAmount: this.state.storeEditWalletTopUpAmount,
            DiscountAmount: this.state.storeDiscountAmount,
            DiscountPercentage: this.state.storeDiscountPercentage
        }
        let storeSpecificPrices = this.state.storeSpecificPrices
        storeSpecificPrices.push(storeSpecificPrice)
        this.setState({storeSpecificPrices:storeSpecificPrices,
        storePriceStore:'',
        storePriceStoreID:'',
        storeSupplyPrice:0,
        storeMarkUp:0,
        storeRetailPrice:0,
        storeMRP:0,
        storeWalletTopUpAmount:0,
        storeWalletTopUpSameAsRetailPrice:true,
        storeEditWalletTopUpAmount:false,
        storeDiscountPercentage:0,
        storeDiscountAmount:0
        })
    }

    //Package related
    onAddService() {
        let serviceError = ""
       

        if(this.state.service.trim() === ""){
            serviceError = "Please select the service"
        }

        

        
        if(serviceError != "" 
        
        ){
            this.setState({
                serviceError : serviceError,
                
            })
            return
        }

        // let found = false;
        // for (let i = 0; i < this.state.packageServices.length; i++) {
        //     const packageService = this.state.packageServices[i];
        //     if(packageService.Service === this.state.service)
        //     {
        //         found = true;
        //         break;
        //     } 
        // }

        // if(found)
        // {
        //     alert('Service is already added');
        //     return
        // }

        let packageService = {
            Service: this.state.service,
            ServiceID: this.state.serviceID,
            
        }
        for (let index = 0; index < this.state.services.length; index++) {
            const service = this.state.services[index];
            if (service.key === packageService.ServiceID) {
                packageService.RetailPrice = service.RetailPrice;
            }
        }
        let packageServices = this.state.packageServices
        packageServices.push(packageService)
        this.setState({ packageServices: packageServices, serviceID: "" })
    }

    onRemoveService(index) {
        let packageServices = this.state.packageServices
        packageServices.splice(index, 1)
        this.setState({ packageServices: packageServices })
    }

    render() {
        const styleEditor = {
            width: "100%",
            height: "200px",
            font_size: "14px",
            line_height: "18px",
            border: "1px solid #dddddd",
            padding: "10px"
        }
        const imgTobeUploadedStyle = {
            width: '200px'
        }
        const modalWidth = {
            width: '500px'
        }
        const handlers = {
            save:this.onSaveProduct,
            back:this.onCancel
        };

        return <HotKeys
        keyMap={keyMap}
        handlers={handlers}> <div className="content-wrapper">
            <section className="content-header">
                <h1>Product</h1>               
                    
                <button type="submit" className="btn btn-md btn-success btn-flat pull-right"
                    style={{ marginTop: "-25px", marginLeft: "10px" }}
                    onClick={this.onSaveProduct}  disabled={this.state.saving}>Save
                </button>
                <button className="btn btn-md btn-flat btn-default pull-right" style={{ marginTop: "-25px" }}
                    onClick={this.onCancel} >Cancel
                </button>
            </section>
            <section className="content" >
                <div className="box ">
                    <div className="box-header with-border">
                        <h3 className="box-title">General details</h3>
                    </div>
                    <div className="box-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className={this.state.nameError ? "form-group has-error" : "form-group "} >
                                    <label>Name</label>
                                    <input type="text" className="form-control"
                                    autoFocus={true}
                                        onChange={(evt) => {
                                            this.setState({ name: evt.target.value, nameError: false })
                                        }}
                                        value={this.state.name}
                                        placeholder="" />
                                    {this.state.nameError && <span class="help-block">Please provide product name</span>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className={this.state.productTypeError !== "" ? "form-group has-error" : "form-group "} >
                                    <label>Type</label>
                                    <select type="text" className="form-control"
                                       onChange={this.onProductTypeChange}
                                        value={this.state.productTypeID}>
                                        <option value={0}>Select product type</option>
                                        <option value={1}>Goods for sale</option>
                                        <option value={2}>Goods not for sale</option>
                                        <option value={3}>Services</option>
                                        <option value={4}>Wallet Recharge</option>
                                        {/* <option value={5}>Package</option> */}
                                    </select>
                                    {this.state.productTypeError !== "" && <span class="help-block">{this.state.productTypeError}</span>}
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label >Description</label>
                            <textarea className="textarea" style={styleEditor}
                                onChange={(evt) => this.setState({ description: evt.target.value })}
                                value={this.state.description}
                                placeholder=""></textarea>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className={this.state.skuError ? "form-group has-error" : "form-group "} >
                                    <label >SKU</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ sku: evt.target.value, skuError: false })}
                                        value={this.state.sku}
                                        placeholder="" />
                                    {this.state.skuError && <span class="help-block">Please provide product SKU</span>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label >Barcode (ISBN, UPC, GTIN, etc.)</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ barCode: evt.target.value })}
                                        value={this.state.barCode}
                                        placeholder="" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className={this.state.categoryError ? "form-group has-error" : "form-group "} >
                                    <label>Category</label>
                                    <div className="input-group">
                                        <select className="form-control"
                                            value={this.state.categoryID}
                                            onChange={this.onCategoryChange}>
                                            <option value="">Select category</option>
                                            {this.props.config.ProductCategories.filter((e) => e.ParentID === "" && !e.IsDeleted).map((category) =>
                                                <option value={category.key}>{category.Name}</option>
                                            )}
                                        </select>
                                        {this.props.config.ConnectionStatus.Status === 'connected' && <div className="input-group-btn">
                                            <button type="button" class="btn btn-default btn-md"
                                                onClick={() => this.setState({
                                                    isShowingModalAddCategory: true,
                                                    categoryOrSubCategory: actionTypes.PRODUCT_CATEGORY
                                                })}>
                                                <i class="glyphicon glyphicon-plus"></i> </button>
                                        </div>}
                                    </div>
                                    {this.state.categoryError && <span class="help-block">Please select category</span>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label >Sub Category</label>
                                    <div className="input-group">
                                        <select className="form-control" value={this.state.subCategoryID} onChange={this.onSubCategoryChange}>
                                            <option value="">Select sub category</option>
                                            {this.props.config.ProductSubCategories.filter((e) => e.ParentID === this.state.categoryID && !e.IsDeleted).map((subCategory, index) =>
                                                <option value={subCategory.key}>{subCategory.Name}</option>
                                            )}
                                        </select>
                                        {this.props.config.ConnectionStatus.Status === 'connected' && <div className="input-group-btn">
                                            <button type="button"
                                                class="btn btn-default btn-md"
                                                disabled={this.state.categoryID === "" ? true : false}
                                                onClick={() => this.setState({
                                                    isShowingModalAddCategory: true,
                                                    categoryOrSubCategory: actionTypes.PRODUCT_SUB_CATEGORY
                                                })}>
                                                <i class="glyphicon glyphicon-plus"></i> </button>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                   
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Brand</label>
                                    <div className="input-group">
                                        <select className="form-control"
                                            value={this.state.brandID}
                                            onChange={this.onBrandChange}>
                                            <option value="">Select brand</option>
                                            {this.props.config.ProductBrands.filter((e) => !e.IsDeleted).map((brand, index) =>
                                                <option value={brand.key}>{brand.Brand}</option>
                                            )}
                                        </select>
                                        {this.props.config.ConnectionStatus.Status === 'connected' && <div className="input-group-btn">
                                            <button type="button" class="btn btn-default btn-md" onClick={() => this.setState({ isShowingModalAddBrand: true })}>
                                                <i class="glyphicon glyphicon-plus"></i> </button>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box ">
                    <div className="box-header with-border">
                        <h3 className="box-title">Price</h3>
                    </div>
                    <div className="box-body">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="form-group ">
                                    <input type="checkbox" 
                                    checked={this.state.accessibleToSelectedStores} 
                                    disabled={this.props.user.user.Role.toLowerCase() !== "power user"}
                                    onChange={() => this.setState({ accessibleToSelectedStores: !this.state.accessibleToSelectedStores })} />
                                    <label >Accessible to only selected stores</label>
                                </div>
                            </div>
                        </div>
                        { !this.state.accessibleToSelectedStores && <div className="row">
                            <div className="col-md-2">
                                <div className="form-group ">
                                    <input type="checkbox" 
                                    checked={this.state.storeSpecificPricing} 
                                    onChange={() => this.setState({ storeSpecificPricing: !this.state.storeSpecificPricing })} />
                                    <label >Store specific pricing</label>
                                </div>
                            </div>
                        </div>}
                        {(this.state.productTypeID !== 4 && !this.state.accessibleToSelectedStores) && <div className="row">
                            <div className="col-md-2">
                                <div className={this.state.supplyPriceError !== "" ? "form-group has-error" : "form-group "} >
                                    <label for="compareAtPrice">Supply Price</label>
                                    <input type="number" className="form-control"
                                        onChange={(evt) => this.setState({ supplyPrice: Number(evt.target.value) })}
                                        value={this.state.supplyPrice}
                                    />
                                    {this.state.supplyPriceError !== "" && <span class="help-block">{this.state.supplyPriceError}</span>}
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className={this.state.markupError !== "" ? "form-group has-error" : "form-group "} >
                                    <label >Markup</label>
                                    <input type="number" min="0" max="100" className="form-control"
                                        onChange={(evt) => this.setState({ markup: Number(evt.target.value) })}
                                        value={this.state.markup}
                                    />
                                    {this.state.markupError !== "" && <span class="help-block">{this.state.markupError}</span>}

                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className={this.state.priceError !== "" ? "form-group has-error" : "form-group "} >
                                    <label >Retail Price</label>
                                    <input type="number" className="form-control"
                                        onChange={(evt) => this.setState({ retailPrice: Number(evt.target.value), priceError: "" })}
                                        value={this.state.retailPrice}
                                    />
                                    {this.state.priceError !== "" && <span class="help-block">{this.state.priceError}</span>}
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group" >
                                    <label>Discount %</label>
                                    <input type="number" className="form-control"
                                        onChange={(evt) => {
                                            
                                            this.setState({ discountAmount: (this.state.retailPrice * Number(evt.target.value)/100),
                                                discountPercentage: Number(evt.target.value)})}
                                        }
                                        value={this.state.discountPercentage}
                                    />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group " >
                                    <label>Discount Amount</label>
                                    <input type="number" className="form-control"
                                        onChange={(evt) => {
                                            this.setState({ discountPercentage: Number((( Number(evt.target.value) * 100) / Number(this.state.retailPrice)).toFixed(3))
                                            ,discountAmount: Number(evt.target.value) })}
                                        }
                                        value={this.state.discountAmount}/>
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className={this.state.mrpError !== "" ? "form-group has-error" : "form-group "} >
                                    <label>MRP</label>
                                    <input type="number" className="form-control"
                                        onChange={(evt) => this.setState({ MRP: Number(evt.target.value) })}
                                        value={this.state.MRP}/>
                                    {this.state.mrpError !== "" && <span class="help-block">{this.state.mrpError}</span>}
                                </div>
                            </div>
                        </div>}
                        {(this.state.productTypeID === 4 && !this.state.accessibleToSelectedStores) && <div className="row">
                            <div className="col-md-3">
                                <div className={this.state.priceError !== "" ? "form-group has-error" : "form-group "} >
                                    <label >Retail Price</label>
                                    <input type="number" className="form-control"
                                        onChange={(evt) => this.setState({ retailPrice: Number(evt.target.value), priceError: "" })}
                                        value={this.state.retailPrice}
                                    />
                                    {this.state.priceError !== "" && <span class="help-block">{this.state.priceError}</span>}

                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <input type="checkbox" checked={this.state.walletTopUpSameAsRetailPrice} onChange={() => this.setState({ walletTopUpSameAsRetailPrice: !this.state.walletTopUpSameAsRetailPrice })} />
                                    <label >Wallet recharge amount same as retail price</label>
                                </div>
                            </div>
                            {!this.state.walletTopUpSameAsRetailPrice && <div className="col-md-3">
                                <div className={this.state.walletTopUpError !== "" ? "form-group has-error" : "form-group "} >
                                    <label >Wallet Recharge Amount</label>
                                    <input type="number" className="form-control"
                                        onChange={(evt) => this.setState({ walletTopUpAmount: Number(evt.target.value), walletTopUpError: "" })}
                                        value={this.state.walletTopUpAmount}
                                    />
                                    {this.state.walletTopUpError !== "" && <span class="help-block">{this.state.walletTopUpError}</span>}

                                </div>
                            </div>}
                            {!this.state.walletTopUpSameAsRetailPrice && <div className="col-md-3">
                                <div className="form-group ">
                                    <input type="checkbox" 
                                    checked={this.state.editWalletTopUpAmount} onChange={() => this.setState({ editWalletTopUpAmount: !this.state.editWalletTopUpAmount })} />
                                    <label >Allow user to specify recharge amount at time of billing</label>
                                  
                                </div>
                            </div>}
                        </div>}
                        {/* <div className="row">
                            <div className="col-md-12"> */}
                                 {( this.state.accessibleToSelectedStores || this.state.storeSpecificPricing) && <React.Fragment><table className = "table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Store</th>
                                        {this.state.productTypeID !== 4 && <th>Supply Price</th>}
                                        {this.state.productTypeID !== 4 && <th>Mark up</th>}
                                        <th>Retail Pirce</th>
                                        {this.state.productTypeID !== 4 && <th>Disc %</th>}
                                        {this.state.productTypeID !== 4 && <th>Disc Amt</th>}
                                        {this.state.productTypeID === 4 && <th>Wallet recharge amount same as retail price</th>}
                                        {this.state.productTypeID === 4 && <th>Wallet Recharge Amount</th>}
                                        {this.state.productTypeID === 4 && <th>Allow user to specify recharge amount at time of billing</th>}
                                        {this.state.productTypeID !== 4 && <th>MRP</th>}
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className={this.state.storeSpecificPricesError !== "" ? "form-group has-error" : "form-group "} >
                                                <select className="form-control"
                                                value={this.state.storePriceStoreID}
                                                onChange={ e  => {
                                                    this.setState({storePriceStoreID:e.target.value, 
                                                        storePriceStore:e.target.options[e.target.selectedIndex].text,
                                                        storePriceStoreError:'' }) 
                                                }}>
                                                <option value="">Select store</option>
                                                {this.state.stores.filter(e => !e.IsDeleted).map(store =>
                                                    <option value={store.key}>{store.StoreCode} {store.StoreName}</option>
                                                )}
                                                </select>
                                                {this.state.storeSpecificPricesError !== "" && <span class="help-block">{this.state.storeSpecificPricesError}</span>}
                                            </div>
                                        </td>
                                        {this.state.productTypeID !== 4 &&  <td>
                                                <input type="number" className="form-control"
                                                    onChange={(evt) => this.setState({ storeSupplyPrice: Number(evt.target.value) })}
                                                    value={this.state.storeSupplyPrice}
                                                />
                                                {this.state.storeSupplyPriceError !== "" && <span class="help-block">{this.state.storeSupplyPriceError}</span>}
                                        </td>}
                                        {this.state.productTypeID !== 4 && <td>
                                                <input type="number" className="form-control"
                                                    onChange={(evt) => this.setState({ storeMarkUp: Number(evt.target.value) })}
                                                    value={this.state.storeMarkUp}
                                                />
                                                {this.state.storeMarkUpError !== "" && <span class="help-block">{this.state.storeMarkUpError}</span>}
                                        </td>}
                                        <td>
                                                <input type="number" className="form-control"
                                                    onChange={(evt) => this.setState({ storeRetailPrice: Number(evt.target.value) })}
                                                    value={this.state.storeRetailPrice}
                                                />
                                                {this.state.storeRetailPriceError !== "" && <span class="help-block">{this.state.storeRetailPriceError}</span>}
                                        </td>
                                        {this.state.productTypeID !== 4 && <td>
                                                <input type="number" className="form-control"
                                                    onChange={(evt) => this.setState({ 
                                                         storeDiscountAmount :  Number(this.state.storeRetailPrice) * Number(Number(evt.target.value)  /100)
                                                        ,storeDiscountPercentage: Number(evt.target.value) })}
                                                    value={this.state.storeDiscountPercentage}
                                                />
                                        </td>}
                                        {this.state.productTypeID !== 4 && <td>
                                                <input type="number" className="form-control"
                                                    onChange={(evt) => this.setState({ 
                                                        storeDiscountPercentage :  Number(((Number(evt.target.value) * 100) / Number(this.state.storeRetailPrice)).toFixed(3)),
                                                        storeDiscountAmount: Number(evt.target.value) })}
                                                    value={this.state.storeDiscountAmount}
                                                />
                                        </td>}
                                        {this.state.productTypeID === 4 && <td>
                                            <input type="checkbox" 
                                            checked={this.state.storeWalletTopUpSameAsRetailPrice} 
                                            onChange={() => this.setState({ storeWalletTopUpSameAsRetailPrice: !this.state.storeWalletTopUpSameAsRetailPrice })} />
                                        </td>}
                                        {this.state.productTypeID === 4 && <td>
                                            {!this.state.storeWalletTopUpSameAsRetailPrice && <input type="number" className="form-control"
                                            onChange={(evt) => this.setState({ storeWalletTopUpAmount: Number(evt.target.value) })}
                                            value={this.state.storeWalletTopUpAmount}
                                            />}
                                        </td>}
                                        {this.state.productTypeID === 4 && <td>
                                            {!this.state.storeWalletTopUpSameAsRetailPrice && <input type="checkbox" 
                                            checked={this.state.storeEditWalletTopUpAmount} 
                                            onChange={() => this.setState({ storeEditWalletTopUpAmount: !this.state.storeEditWalletTopUpAmount })} 
                                            />}
                                        </td>}
                                        {this.state.productTypeID !== 4 &&  <td>
                                                <input type="number" className="form-control"
                                                    onChange={(evt) => this.setState({ storeMRP: Number(evt.target.value) })}
                                                    value={this.state.storeMRP}
                                                />
                                                {this.state.storeMRPError !== "" && <span class="help-block">{this.state.storeMRPError}</span>}
                                        </td>}
                                        <td>
                                            <button type="submit"
                                            className="btn btn-md btn-default btn-flat"
                                            onClick={this.onAddStoreSpecificPrice}>Add</button>
                                        </td>
                                    </tr>
                                    {this.state.storeSpecificPrices.map( (s,index) => <tr key={index}>
                                        <td>{s.Store}</td>
                                        {this.state.productTypeID !== 4 && <td>
                                            <input type="number" className="form-control"
                                                onChange={(evt) => {
                                                    let storePrices = this.state.storeSpecificPrices
                                                    storePrices[index].SupplyPrice =  Number(evt.target.value)
                                                    this.setState({storeSpecificPrices:storePrices})
                                                }}
                                                value={s.SupplyPrice}
                                            />
                                        </td>}
                                        {this.state.productTypeID !== 4 && <td>
                                            <input type="number" className="form-control"
                                                onChange={(evt) => {
                                                    let storePrices = this.state.storeSpecificPrices
                                                    storePrices[index].Markup =  Number(evt.target.value)
                                                    this.setState({storeSpecificPrices:storePrices})
                                                }}
                                                value={s.Markup}
                                            />
                                        </td>}
                                        {this.state.productTypeID !== 4 && <td>
                                            <input type="number" className="form-control"
                                                onChange={(evt) => {
                                                    let storePrices = this.state.storeSpecificPrices
                                                    storePrices[index].RetailPrice =  Number(evt.target.value)
                                                    this.setState({storeSpecificPrices:storePrices})
                                                }}
                                                value={s.RetailPrice}
                                            />
                                        </td>}
                                        {this.state.productTypeID !== 4 && <td>
                                            <input type="number" className="form-control"
                                                onChange={(evt) => {
                                                    let storePrices = this.state.storeSpecificPrices
                                                    storePrices[index].DiscountPercentage =  Number(evt.target.value)
                                                    storePrices[index].DiscountAmount = Number(storePrices[index].RetailPrice) *  Number(evt.target.value) /100
                                                    this.setState({storeSpecificPrices:storePrices})
                                                }}
                                                value={s.hasOwnProperty('DiscountPercentage') ?  s.DiscountPercentage:0}
                                            />
                                        </td>}
                                        {this.state.productTypeID !== 4 && <td>
                                            <input type="number" className="form-control"
                                                onChange={(evt) => {
                                                    let storePrices = this.state.storeSpecificPrices
                                                    storePrices[index].DiscountPercentage =  Number(((Number(evt.target.value) * 100) / Number(storePrices[index].RetailPrice)).toFixed(3))
                                                    storePrices[index].DiscountAmount =  Number(evt.target.value)
                                                    this.setState({storeSpecificPrices:storePrices})
                                                }}
                                                value={s.hasOwnProperty('DiscountAmount') ? s.DiscountAmount: 0}
                                            />
                                        </td>}
                                        {this.state.productTypeID === 4 && <td>{s.RetailPrice}</td>}
                                        {this.state.productTypeID === 4 && <td>{s.hasOwnProperty('WalletTopUpSameAsRetailPrice')? s.WalletTopUpSameAsRetailPrice ? "Yes": "No": "No"}</td>}
                                        {this.state.productTypeID === 4 && <td> {s.hasOwnProperty('WalletTopUpAmount')? s.WalletTopUpAmount :0}</td>}
                                        {this.state.productTypeID === 4 && <th>{s.hasOwnProperty('EditWalletTopUpAmount')? s.EditWalletTopUpAmount ? "Yes": "No": "No"}</th>}
                                        {this.state.productTypeID !== 4 && <td>
                                            <input type="number" className="form-control"
                                                onChange={(evt) => {
                                                    let storePrices = this.state.storeSpecificPrices
                                                    storePrices[index].MRP =  Number(evt.target.value)
                                                    this.setState({storeSpecificPrices:storePrices})
                                                }}
                                                value={s.MRP}
                                            />
                                        </td>}
                                        <td><button type="submit"
                                            className="btn btn-md btn-default btn-flat"
                                            onClick={()=> {
                                                let storePrices = this.state.storeSpecificPrices
                                                storePrices.splice(index, 1)
                                                this.setState({storeSpecificPrices:storePrices})
                                            }}>
                                            <i className="glyphicon glyphicon-remove"></i>
                                            </button>
                                        </td>
                                    </tr>)}
                                    </tbody>
                                </table>
                                <br></br>
                                </React.Fragment>
                                }

                            {/* </div>
                        </div> */}
                        <div className="row">
                            <div className="col-md-3">
                                <div className="form-group">
                                    <input type="checkbox" checked={this.state.allowPriceEdit} onChange={() => this.setState({ allowPriceEdit: !this.state.allowPriceEdit })} />
                                    <label >Allow price edit</label>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <input type="checkbox" checked={this.state.allowDiscount} onChange={() => this.setState({ allowDiscount: !this.state.allowDiscount })} />
                                    <label >Discountable</label>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <input type="checkbox" checked={this.state.allowTaxChange} onChange={() => this.setState({ allowTaxChange: !this.state.allowTaxChange })} />
                                    <label >Allow tax change</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <div className="form-group">
                                    <input type="checkbox" checked={this.state.chargeTaxOnPoduct} onChange={() => this.setState({ chargeTaxOnPoduct: !this.state.chargeTaxOnPoduct })} />
                                    <label >Charge taxes on this product</label>
                                </div>
                            </div>
                            {this.state.chargeTaxOnPoduct && <div className="col-md-3">
                                <div className="form-group">
                                    <input type="checkbox"
                                        checked={this.state.priceBasedTax}
                                        onChange={() => {
                                            this.setState({
                                                priceBasedTax: !this.state.priceBasedTax,
                                                taxPriceRange: this.state.taxPriceRange ? this.state.taxPriceRange : this.state.initialTaxPriceRange
                                            })
                                        }} />
                                    <label>Tax based on retail price</label>
                                </div>
                            </div>}
                            {this.state.chargeTaxOnPoduct && <div className="col-md-3">
                                <div className="form-group">
                                    <input type="checkbox"
                                        checked={this.state.taxInclusive}
                                        onChange={() => this.setState({ taxInclusive: !this.state.taxInclusive })} />
                                    <label >Tax inclusive</label>
                                </div>
                            </div>}
                            {this.state.chargeTaxOnPoduct && <div className="col-md-3">
                                <div className="form-group">
                                    <label>HSN/SAC Code</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ HSNSACCode: evt.target.value })}
                                        value={this.state.HSNSACCode}
                                        placeholder="HSN/SAC code" />
                                </div>
                            </div>}

                        </div>
                        {(this.state.chargeTaxOnPoduct && !this.state.priceBasedTax) && <div className="row">
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>Tax </label>
                                    <select className="form-control" value={this.state.taxID} onChange={this.onTaxChange}>
                                        <option value="">Select tax</option>
                                        {this.props.config.ProductTaxes.filter((e) => !e.IsDeleted).map((t) =>
                                            <option value={t.key}>{t.TaxGroup}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                        </div>}
                        {(this.state.chargeTaxOnPoduct && this.state.priceBasedTax) && <div>
                            {this.state.taxPriceRange.map((t, index) => <div className="row">
                                <div className="col-md-3">
                                    <div className={t.FromPriceErrMsg !== "" ? "form-group has-error" : "form-group "} >
                                        <label >Price - From</label>
                                        <input type="number" className="form-control"
                                            onChange={(evt) => this.onFromPriceChange(index, Number(evt.target.value))}
                                            value={t.FromPrice}
                                        />
                                        {t.FromPriceErrMsg !== "" && <span class="help-block">{t.FromPriceErrMsg}</span>}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className={t.ToPriceErrMsg !== "" ? "form-group has-error" : "form-group "} >
                                        <label>Price - To</label>
                                        <input type="number" className="form-control"
                                            onChange={(evt) => this.onToPriceChange(index, Number(evt.target.value))}
                                            value={t.ToPrice}
                                        />
                                        {t.ToPriceErrMsg !== "" && <span class="help-block">{t.ToPriceErrMsg}</span>}
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className={t.TaxErrMsg !== "" ? "form-group has-error" : "form-group "} >
                                        <label>Tax </label>
                                        <select className="form-control" value={t.TaxID} onChange={(evt) => this.onPriceBasedTaxChange(index, evt)}>
                                            <option value="">Select tax</option>
                                            {this.props.config.ProductTaxes.filter((e) => !e.IsDeleted).map((t) =>
                                                <option value={t.key}>{t.TaxGroup}</option>
                                            )}
                                        </select>
                                        {t.TaxErrMsg !== "" && <span class="help-block">{t.TaxErrMsg}</span>}
                                    </div>
                                </div>
                                {index === 1 && <div className="col-md-3">
                                    <div className="form-group">
                                        <div className="input-group-btn">
                                            <button type="button" class="btn btn-default btn-md" style={{ marginTop: "25px" }}
                                                onClick={() => {
                                                    let taxPriceRange = this.state.taxPriceRange
                                                    taxPriceRange.push({ FromPrice: 0, ToPrice: 0, TaxID: '', TaxGroup: '', FromPriceErrMsg: '', ToPriceErrMsg: '', TaxErrMsg: '' })
                                                    this.setState({ taxPriceRange: taxPriceRange })
                                                }}>
                                                <i class="glyphicon glyphicon-plus"></i> </button>
                                        </div>
                                    </div>
                                </div>}
                                {index > 1 && <div className="col-md-3">
                                    <div className="form-group">
                                        <div className="input-group-btn">
                                            <button type="button" class="btn btn-default btn-md" style={{ marginTop: "25px" }}
                                                onClick={() => {
                                                    let taxPriceRange = this.state.taxPriceRange
                                                    taxPriceRange.splice(index, 1)
                                                    this.setState({ taxPriceRange: taxPriceRange })
                                                }}>
                                                <i class="glyphicon glyphicon-remove"></i> </button>
                                        </div>
                                    </div>
                                </div>}
                            </div>)}
                        </div>}
                    </div>
                </div>
                {(this.state.productTypeID === 1 || this.state.productTypeID ===2) &&  <div className="box ">
                    <div className="box-header with-border">
                        <h3 className="box-title">Inventory</h3>
                    </div>
                    <div className="box-body">
                        <div className="row">
                            <div className="col-md-2">
                                <div className="form-group" >
                                    <label>Type</label>
                                    <select className="form-control"
                                            value={this.state.inventoryType}
                                            onChange={(evt)=> this.setState({inventoryType:evt.target.value})}>
                                            <option value="FIFO">FIFO</option>
                                            <option value="Unique No">Unique No</option>
                                    </select>
                                </div>
                            </div>
                            {this.state.inventoryType.toLowerCase() === "unique no" &&  <div className="col-md-2">
                                <div className="form-group" >
                                    <label>Unique Label</label>
                                    <input type="text" 
                                    className="form-control"
                                        onChange={(evt) => this.setState({uniqueLabel: evt.target.value})}
                                        value={this.state.uniqueLabel}
                                    />
                                </div>
                            </div>}
                        </div>
                        <div className="row">
                            <div className="col-md-2">
                                <div className="form-group" >
                                    <label >Min Qty</label>
                                    <input type="number" 
                                    className="form-control"
                                        onChange={(evt) => this.setState({ minQty: Number(evt.target.value) })}
                                        value={this.state.minQty}
                                    />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group" >
                                    <label >Max Qty</label>
                                    <input type="number" 
                                    className="form-control"
                                        onChange={(evt) => this.setState({ maxQty: Number(evt.target.value) })}
                                        value={this.state.maxQty}
                                    />
                                </div>
                            </div>
                            <div className="col-md-2">
                                <div className="form-group" >
                                    <label >Reorder Qty</label>
                                    <input type="number" 
                                    className="form-control"
                                        onChange={(evt) => this.setState({ reorderQty: Number(evt.target.value) })}
                                        value={this.state.reorderQty}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
                {(this.state.productTypeID === 5) &&  <div className="box ">
                    <div className="box-header with-border">
                        <h3 className="box-title">Package</h3>
                    </div>

                    <div className="box-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className={this.state.validityInDaysError !== "" ? "form-group has-error" : "form-group "}>
                                        <label>Validity</label>
                                        <input type="number" className="form-control" onKeyPress={this.handleKeyPress}
                                            style={{width:"200px"}}
                                            onChange={(evt) => this.setState({ validityInDays: evt.target.value, validityInDaysError: "" })}
                                            value={this.state.validityInDays}
                                        />In Day(s)
                                        {this.state.validityInDaysError !== "" && <span className="help-block">{this.state.validityInDaysError}</span>}
                                    </div>
                                </div>
                                

                            </div>
                            <table className="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th style={{ width: "45%" }}>Services</th>
                                        <th style={{ width: "120px" }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className={this.state.serviceError !== "" ? "form-group has-error" : "form-group "}>
                                                <select className="form-control" value={this.state.serviceID}
                                                    onChange={(evt) => this.setState({ service: evt.target.options[evt.target.selectedIndex].text, serviceID: evt.target.value, serviceError: '' })}>
                                                    <option value="">Select Service</option>
                                                    {this.state.services.filter((e) => e.ProductType === "Services").map((service) =>
                                                        <option value={service.key}>{service.Name}</option>
                                                    )}
                                                </select>
                                                {this.state.serviceError !== "" && <span className="help-block">{this.state.serviceError}</span>}
                                            </div>
                                        </td>
                                        <td>
                                            <button type="button" className="btn btn-default btn-md" onClick={this.onAddService}>
                                                ADD TO LIST </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th style={{ width: "45%" }}>Services</th>
                                        <th>Retail Price</th>
                                        <th style={{ width: "120px" }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.packageServices.map((packageService, index) =>
                                        <tr>
                                            <td>{packageService.Service}</td>
                                            <td>
                                                {packageService.RetailPrice}
                                            </td>
                                            <td><div className="input-group-btn">
                                                <button type="button" className="btn btn-default btn-md"
                                                    onClick={() => this.onRemoveService(index)}>
                                                    <i className="fa fa-trash-o"></i>
                                                </button>
                                            </div>
                                            </td>
                                        </tr>)}
                                </tbody>
                            </table>
                </div>
                </div>}
                <div className="box ">
                    <div className="box-header with-border">
                        <h3 className="box-title">Images</h3>
                    </div>
                    <div className="box-body">
                        <div className="row">
                            <div className="col-md-3">
                                <Dropzone onDrop={(files) => this.onDrop(files)} >
                                    <div>Try dropping some files here, or click to select files to upload.</div>
                                </Dropzone>
                                {/* {this.state.images.map((image, index) => {console.log(image)}) */}
                            </div>
                            <div className="col-md-9">
                                <ul className="mailbox-attachments clearfix">
                                    {this.state.images.map((image, index) =>
                                        <li>
                                            <span className="mailbox-attachment-icon has-img"><img src={image.ImagePath} alt="Attachment" /></span>
                                            <div className="mailbox-attachment-info">
                                                <span className="mailbox-attachment-name">{image.ImageName}</span>
                                                <span className="mailbox-attachment-size">
                                                    {Math.round(image.ImageSize / 1024)} KB
                                                        <a onClick={() => this.onRemoveImage(index)} className="btn btn-default btn-xs pull-right"><i className="fa fa-trash"></i></a>
                                                </span>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="box-footer"> */}
                
                {this.props.match.params.key &&
                <AuditTrail {...this.props} module={constants.MODULE_PRODUCT} parentKey={this.props.match.params.key}/>}

                <button type="submit"
                    style={{ marginBottom: "10px", marginLeft: "10px" }}
                    className="btn btn-md btn-success btn-flat pull-right"
                    onClick={this.onSaveProduct}  disabled={this.state.saving}>Save
                                </button>
                <button style={{ marginBottom: "10px" }}
                    onClick={this.onCancel}
                    className="btn btn-md btn-default btn-flat  pull-right">Cancel
                                </button>
                {/* </div>  */}
                <Modal open={this.state.isShowingModalAddCategory} onClose={() => this.setState({ isShowingModalAddCategory: false })} center>
                    <div style={modalWidth}>
                        <AddEditProductCategory
                            {...this.props}
                            type={this.state.categoryOrSubCategory}
                            productCategory={null}
                            parentID={this.state.categoryID}
                            parent={this.state.category}
                            onSave={(key, name) => {
                                if (this.state.categoryOrSubCategory === actionTypes.PRODUCT_CATEGORY) {
                                    //this.loadProductCategories() 
                                    this.setState({ categoryID: key, category: name, isShowingModalAddCategory: false })
                                }
                                else {
                                    //this.loadProductSubCategories()
                                    console.log('subCategoryID:', key)
                                    this.setState({ subCategoryID: key, subCategory: name, isShowingModalAddCategory: false })
                                }
                            }}
                            onClose={() => this.setState({ isShowingModalAddCategory: false })} />
                    </div>
                </Modal>
                <Modal open={this.state.isShowingModalAddBrand} onClose={() => this.setState({ isShowingModalAddBrand: false })} center>
                    <div style={modalWidth}>
                        {/* <br/> */}
                        <AddEditProductBrand   {...this.props}
                            onSave={(key) => {
                                //console.log('AddEditProductBrand onsave called ',key)
                                //this.loadProductBrands(key)
                                this.setState({ isShowingModalAddBrand: false })
                            }}
                            onClose={() => this.setState({ isShowingModalAddBrand: false })}
                        />
                    </div>

                </Modal>
            </section>
        </div>
        </HotKeys>
    }
}

export default withRouter(Product)
