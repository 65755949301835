import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';
import React, { Component } from 'react'
import { HotKeys } from 'react-hotkeys';
import Modal from 'react-responsive-modal'
import './Style.css'
import * as actionTypes from '../../actionTypes'
import { withRouter } from 'react-router-dom'
import Customer from '../CRM/Customer'
import Product from '../Catalogue/Product'
import Payment from './Payment'
import CustomerSearch from './CustomerSearch'
import CustomerAddress from './CustomerAddress'
import Delivery from './Delivery'
import CustomerDashBoard from '../Reports/CustomerDashboard'
import Switch from "react-switch"
import SalesModifiers from './SalesModifiers'
import UniqueNoSelection from '../Inventory/UniqueNoSelection'
import UniqueNoInput from '../Inventory/UniqueNoInput'
var AsyncTypeahead = require('react-bootstrap-typeahead').AsyncTypeahead;
var Typeahead = require('react-bootstrap-typeahead').Typeahead;

const keyMap = {
    productSearch: ['f1', 'f3'],
    productScan: 'f2',
    newProduct: 'f4',
    newCustomer: 'f5',
    searchCustomer: ['f6', 'f7'],
    paymentOption1: 'f9',
    paymentOption2: 'f10',
    paymentOption3: 'f11',
    discount: 'ctrl+d',
    hold: 'ctrl+h',
    lineItemQtyChange: 'ctrl+q',
    lineItemPriceChange: 'ctrl+p',
    lineItemDiscount: 'ctrl+d',
    lineItemTax: 'ctrl+t',
    lineItemEmployeeTagging: 'ctrl+e',
    lineItemDelete: 'ctrl+r',
    save: 'ctrl+s'
    // delete: ['del', { sequence: 'backspace', action: 'keyup' }],
    // expand: 'alt+up',
    // contract: 'alt+down',
    // konami: 'up up down down left right left right b a enter',
    // commandDown: { sequence: 'command', action: 'keydown' },
    // commandUp: { sequence: 'command', action: 'keyup' }
};

class Sales extends Component {

    constructor(props) {
        super(props)
        this.state = {
            editTransactionNo: "",
            editTransactionDate: null,
            sale: null,
            transactionType: null,
            // transactionNo:'',
            // transactionDate:null,
            type: '',
            key: '',
            parentTransactionKey: '',
            parentTransactionNo: '',
            selectedCustomer: null,
            selectedCategory: '',
            selectedCategoryID: '',
            selectedSubCategory: '',
            selectedSubCategoryID: '',
            // products:[],
            lineItems: [],
            billQty: 0,
            billSubTotal: 0,
            billDiscount: 0,
            billTaxAmount: 0,
            billAmount: 0,
            notes: '',
            tempNotes: '',
            showLineItemModal: false,
            selectedLineItem: null,
            selectedLineItemIndex: 0,
            showParkedSalesModal: false,
            showPayment: false,
            balanceAmount: 0,
            paidAmount: 0,
            changeDue: 0,
            // payments: [],
            cashRegisters: [],
            productsTypeAheadOptions: [],
            isProductTypeAheadLoading: false,
            productsTypeAheadSelected: [],
            customerTypeAheadOptions: [],
            isCustomerTypeAheadLoading: false,
            // customerTypeAheadSelected: [],
            showAddCustomer: false,
            showAddProduct: false,
            showInvoice: true,
            overallDiscountPercentage: 0,
            overallDiscountAmount: 0,
            overallDiscountClearExistingDiscount: false,
            employeeList: [],
            transactionLevelEmployeeSelected: null,
            //showLineItemEmployeeSelectionModal: false,
            lineItemEmployeeSelected: null,
            memberships: [],
            applicableMembership: null,
            selectedCashRegister: null,
            showCashRegisterSelection: false,
            negativeQty: true,
            customerError: '',
            employeeMandatoryAtTransactionError: '',
            employeeMandatoryAtLineItemError: '',
            showError: false,
            selectedItemEmployeeSelectionError: '',
            taxSummaryList: [],
            selectedCustomerAddress: null,
            rightHeight: 62,
            placeOfSupply: '',
            KOTType: 'dine in',
            deliveryCustomer: null,
            deliveryCustomerAddress: null,
            deliveryDate: '',
            deliveryTime: { HH: '', MM: '', AMPM: '' },
            deliveryNotes: '',
            showDelivery: false,
            fromDelivery: false,
            tmpDeliveryCustomer: null,
            showDiscountModal: false,
            showNotesModal: false,
            cashRegisterClosing: null,
            cashRegisterOpeningBalance: 0,
            roundOff: 0,
            showCustomerDashBoard: false,
            newCustomer: null,
            offers: [],
            offersApplied: [],
            offersToApply: [],
            offersPopupTabSelected: 'applied',
            applyMembershipDiscount: false,
            discountOptionSelected: "transaction",
            showDiscountMembership: false,
            showDiscountTransaction: false,
            showDiscountOffers: false,
            showDiscountOptions: false, showModifiers: false,
            toggleSearch: false,
            showUniqueNoSelection: false,
            selectedProduct: null,
            showUniqueNoInput: false,
            queryText: '',
            showTransactionTypes: false
        }
        this.onProductSelected = this.onProductSelected.bind(this)
        this.productSelected = this.productSelected.bind(this)

        this.calculateBillTotal = this.calculateBillTotal.bind(this)
        this.onRemoveLineItem = this.onRemoveLineItem.bind(this)
        this.onPaymentClick = this.onPaymentClick.bind(this)
        this.onDiscardClicked = this.onDiscardClicked.bind(this)
        this.onParkSaleClicked = this.onParkSaleClicked.bind(this)
        this.onLineItemQtyChange = this.onLineItemQtyChange.bind(this)
        this.onLineItemPriceChange = this.onLineItemPriceChange.bind(this)
        this.onLineItemDiscountChange = this.onLineItemDiscountChange.bind(this)
        this.onLineItemSelected = this.onLineItemSelected.bind(this)
        this.onChargeTaxOnProductChange = this.onChargeTaxOnProductChange.bind(this)
        this.onTaxIncluxiveChange = this.onTaxIncluxiveChange.bind(this)
        this.onLineItemDiscountAmountChange = this.onLineItemDiscountAmountChange.bind(this)
        this.onTaxGroupChange = this.onTaxGroupChange.bind(this)
        this.onLineItemNotesChange = this.onLineItemNotesChange.bind(this)
        this.onLineItemChangesDone = this.onLineItemChangesDone.bind(this)
        this.onRetriveParkedSales = this.onRetriveParkedSales.bind(this)
        this.onNotesOKClicked = this.onNotesOKClicked.bind(this)
        this.onProductTypeAheadSearch = this.onProductTypeAheadSearch.bind(this)
        this.onOverAllDiscount = this.onOverAllDiscount.bind(this)
        this.onCustomerSelected = this.onCustomerSelected.bind(this)
        this.onRemoveCustomer = this.onRemoveCustomer.bind(this)
        this.onCustomerChange = this.onCustomerChange.bind(this)
        this.onSave = this.onSave.bind(this)
        this.shortcutDiscount = this.shortcutDiscount.bind(this)
        this.shortcutHold = this.shortcutHold.bind(this)
        this.shortcutNewCustomer = this.shortcutNewCustomer.bind(this)
        this.shortcutNewProduct = this.shortcutNewProduct.bind(this)
        this.shortcutPaymentOption1 = this.shortcutPaymentOption1.bind(this)
        this.shortcutPaymentOption2 = this.shortcutPaymentOption2.bind(this)
        this.shortcutPaymentOption3 = this.shortcutPaymentOption3.bind(this)
        this.shortcutProductScan = this.shortcutProductScan.bind(this)
        this.shortcutProductSearch = this.shortcutProductSearch.bind(this)
        this.shortcutSearchCustomer = this.shortcutSearchCustomer.bind(this)
        this.onDiscountClicked = this.onDiscountClicked.bind(this)
        this.test = this.test.bind(this)
        this.roundUpTo2 = this.roundUpTo2.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.onSelectedCustomerAddressChange = this.onSelectedCustomerAddressChange.bind(this)
        this.onDeliveryClicked = this.onDeliveryClicked.bind(this)
        this.onCashRegisterSelection = this.onCashRegisterSelection.bind(this)
        this.onEnteringCashRegisterOpeningBalance = this.onEnteringCashRegisterOpeningBalance.bind(this)
        this.onLineItemWalletTopUpChange = this.onLineItemWalletTopUpChange.bind(this)
        this.applyOffer = this.applyOffer.bind(this)
        this.applySelectedOffer = this.applySelectedOffer.bind(this)
        this.removeSelectedOffer = this.removeSelectedOffer.bind(this)
        this.onApplyRemoveMembershipDiscount = this.onApplyRemoveMembershipDiscount.bind(this)
        this.onShowDiscountOptions = this.onShowDiscountOptions.bind(this)
        this.applyRemoveMembershipDiscountAtLineItem = this.applyRemoveMembershipDiscountAtLineItem.bind(this)
        this.onProductBarCodeSearch = this.onProductBarCodeSearch.bind(this)
        this.onTransactionTypeSelected = this.onTransactionTypeSelected.bind(this)
        this.applyInvoiceDiscountOffer = this.applyInvoiceDiscountOffer.bind(this)
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            if (this.state.showLineItemModal) {
                this.onLineItemChangesDone()
            }
            if (this.state.showDiscountModal) {
                // this.onOverAllDiscount()
                this.onOverAllDiscount(this.state.overallDiscountPercentage,
                    this.state.overallDiscountAmount,
                    this.state.overallDiscountClearExistingDiscount,
                    "",
                    "",
                    "")
            }
        }
    }

    componentDidMount() {

        ////console.log('this.props.location.state.type',this.props.location.state.type)
        //populate employees
        let key = ""
        // let transactionNo=""
        // let transactionDate=new Date()
        let callCalculateBillTotal = false
        let membershipList = []
        let employeeList = []
        let selectedCategory = ""
        let selectedCategoryID = ""
        let selectedSubCategory = ""
        let selectedSubCategoryID = ""
        let parentTransactionKey = ""
        let parentTransactionNo = ""
        let lineItems = []
        let applicableMembership = null
        let selectedCustomer = null
        let billQty = 0
        let billSubTotal = 0
        let billDiscount = 0
        let billTaxAmount = 0
        let billAmount = 0
        let roundOff = 0
        let notes = ""
        let balanceAmount = 0
        let paidAmount = 0
        let changeDue = 0
        let transactionType = null
        let sale = null
        let transactionLevelEmployeeSelected = null
        let taxSummaryList = []
        let deliveryCustomer = null
        let deliveryCustomerAddress = null
        let deliveryDate = null
        let deliveryTime = { HH: '', MM: '', AMPM: 'AM' }
        let deliveryNotes = ""
        let placeOfSupply = ""

        if (this.props.user.cashRegister === null) {
            //show list of cash registers
            this.props.getCashRegisters(this.props.user.store.key).then((cashRegisters) => {
                let c = cashRegisters.filter((e) => !e.IsDeleted)
                if (c.length == 0) {
                    alert('No cash register exists. Pls define cash register first')
                    this.props.history.push('/home/')
                }
                else if (c.length === 1) {//auto select cash register 
                    this.onCashRegisterSelection(c[0])
                    // this.props.setCashRegister(c[0])
                }
                else {//give user option to select cash register 
                    this.setState({ cashRegisters: c, showCashRegisterSelection: true })
                }
            })
        }

        this.setState({ offers: this.props.config.Offers })
        this.setState({ employeeList: this.props.config.Employees })
        this.setState({ memberships: this.props.config.Memberships })

        // this.props.getApplicableOffers(this.props.user.user.RegistrationID).then(offers=> {
        //     // console.log('sales - offers',offers)
        //     this.setState({offers:offers})
        // })

        // this.props.getEmployees(this.props.user.user.RegistrationID,this.props.user.store.key,
        //      0, 1000, "active" , "","","","","",
        //     "","","","","",""
        // ).then((employees) => {
        //     this.setState({ employeeList: employees.searchResult })})

        // this.props.getMembership(this.props.user.user.RegistrationID, 0, 10000, "active", "").then((result) => {
        //     membershipList = result.searchResult
        //     //console.log('didmount memberships', membershipList)
        //     this.setState({ memberships: membershipList })
        // })

        //check if selected category has items directly under it 
        let categories = this.props.config.ProductCategories.filter((e) => !e.IsDeleted)
        if (categories.length > 0) {
            selectedCategory = categories[0].Name
            selectedCategoryID = categories[0].key
            let categoryItems = this.props.config.Products.filter((e) => e.CategoryID === selectedCategoryID && e.SubCategoryID === "")
            if (categoryItems.length === 0) {
                let subCategories = this.props.config.ProductSubCategories.filter((e) => !e.IsDeleted && e.ParentID === selectedCategoryID)
                if (subCategories.length > 0) {
                    selectedSubCategoryID = subCategories[0].key
                    selectedSubCategory = subCategories[0].Name
                }
            }
        }

        if (this.props.location.state.type === actionTypes.SALE_NEW) {
            transactionType = this.props.location.state.transactionType
            if (transactionType.TransactionType.toLowerCase() === "sales return") {
                if (this.props.location.state.sale) {
                    sale = this.props.location.state.sale
                    selectedCustomer = sale.Customer
                    if (selectedCustomer) {
                        if (selectedCustomer.Membership) {
                            membershipList.filter((e) => e.key === selectedCustomer.Membership.MembershipID && !e.IsDeleted).map((e) => {
                                applicableMembership = e
                                ////console.log('applicableMembership', applicableMembership)
                            })
                        }
                    }
                    this.props.location.state.sale.LineItems.map((l) => {
                        let lineItem = l
                        lineItem.Qty = l.Qty * -1
                        lineItem.ProductConsumption = null
                        lineItem = this.onLineItemChange(-2, lineItem, sale.hasOwnProperty("PlaceOfSupply") ? sale.PlaceOfSupply : "")
                        lineItems.push(lineItem)
                    })
                    parentTransactionKey = this.props.location.state.key
                    parentTransactionNo = this.props.location.state.sale.TransactionNo
                    billQty = sale.BillQty * -1
                    billSubTotal = sale.BillSubTotal * -1
                    billDiscount = sale.BillDiscount * -1
                    billTaxAmount = sale.BillTaxAmount * -1
                    billAmount = sale.BillAmount * -1
                    notes = ""
                    balanceAmount = 0
                    paidAmount = 0
                    changeDue = 0
                }
            }
            else if (this.props.location.state.sale && transactionType.TransactionType.toLowerCase() !== "sales return") {
                sale = this.props.location.state.sale
                lineItems = sale.LineItems
                selectedCustomer = sale.Customer
                parentTransactionKey = ""
                parentTransactionNo = ""
                notes = ""
                balanceAmount = 0
                paidAmount = 0
                changeDue = 0
                callCalculateBillTotal = true
                // this.setState({lineItems:lineItems,selectedCustomer:selectedCustomer})

            }
        }
        if (this.props.location.state.type === actionTypes.SALE_EDIT) {//edit invoice 
            let sale = this.props.location.state.sale
            // console.log('sale.TransactionDate', sale.TransactionDate)
            this.setState({
                editTransactionNo: sale.TransactionNo,
                editTransactionDate: new Date(sale.TransactionDate.seconds * 1000)
            })

            ////console.log('component did mount sale', sale)
            if (sale.Customer) {
                if (sale.Customer.Membership) {
                    membershipList.filter((e) => e.key === sale.Customer.Membership.MembershipID && !e.IsDeleted).map((e) => {
                        applicableMembership = e
                    })
                }
            }
            key = this.props.location.state.key
            lineItems = sale.LineItems
            billQty = sale.BillQty
            billSubTotal = sale.BillSubTotal
            billDiscount = sale.BillDiscount
            billTaxAmount = sale.BillTaxAmount
            billAmount = sale.BillAmount
            notes = sale.Notes
            balanceAmount = 0
            paidAmount = 0
            changeDue = 0
            transactionType = sale.TransactionType
            selectedCustomer = sale.Customer
            transactionLevelEmployeeSelected = sale.Employee ? sale.Employee : null
            taxSummaryList = sale.TaxSummaryList ? sale.TaxSummaryList : []
            deliveryCustomer = sale.hasOwnProperty('DeliveryCustomer') ? sale.DeliveryCustomer : null
            deliveryCustomerAddress = sale.hasOwnProperty('DeliveryCustomerAddress') ? sale.DeliveryCustomerAddress : deliveryCustomerAddress
            deliveryDate = sale.hasOwnProperty('DeliveryDate') ? sale.DeliveryDate : deliveryDate
            deliveryTime = sale.hasOwnProperty('DeliveryTime') ? sale.DeliveryTime : deliveryTime
            deliveryNotes = sale.hasOwnProperty('DeliveryNotes') ? sale.DeliveryNotes : deliveryNotes
            placeOfSupply = sale.hasOwnProperty('PlaceOfSupply') ? sale.PlaceOfSupply : placeOfSupply
            roundOff = sale.hasOwnProperty('RoundOff') ? sale.RoundOff : 0

        }
        let rightHeight = this.state.rightHeight
        if (transactionType.EmployeeManadatoryTransactionORLineItemLevel === "transaction") {
            rightHeight = rightHeight - 10
        }

        this.setState({
            rightHeight: rightHeight,
            key: key,
            type: this.props.location.state.type,
            selectedCategory: selectedCategory,
            selectedCategoryID: selectedCategoryID,
            selectedSubCategory: selectedSubCategory,
            selectedSubCategoryID: selectedSubCategoryID,
            lineItems: lineItems,
            billQty: billQty,
            billSubTotal: billSubTotal,
            billDiscount: billDiscount,
            billTaxAmount: billTaxAmount,
            billAmount: billAmount,
            notes: notes,
            balanceAmount: balanceAmount,
            paidAmount: paidAmount,
            changeDue: changeDue,
            transactionType: transactionType,
            selectedCustomer: selectedCustomer,
            applicableMembership: applicableMembership,
            transactionLevelEmployeeSelected: transactionLevelEmployeeSelected,
            parentTransactionKey: parentTransactionKey,
            parentTransactionNo: parentTransactionNo,
            taxSummaryList: taxSummaryList,
            deliveryCustomer: deliveryCustomer,
            deliveryCustomerAddress: deliveryCustomerAddress,
            deliveryDate: deliveryDate,
            deliveryTime: deliveryTime,
            deliveryNotes: deliveryNotes,
            placeOfSupply: placeOfSupply,
            roundOff: roundOff
        })
        if (callCalculateBillTotal) {
            this.calculateBillTotal(lineItems)
        }

    }

    componentWillMount() {
        document.body.className = "skin-green fixed sidebar-mini sidebar-mini-expand-feature sidebar-collapse";
    }

    roundUpTo2(num) {
        return Math.round(Number(num) * 100) / 100
    }

    onProductTypeAheadSearch = async (query) => {

        this.setState({ isProductTypeAheadLoading: true });
        let searchedProducts = [];
        if (this.props.config.ConnectionStatus.Status === 'connected') {

            let result = await this.props.getProducts(this.props.user.user.RegistrationID, 0, 10000, "active", query, "", "", "",
                "", "", "");

            searchedProducts = result.searchResult;
        }
        else {
            searchedProducts = await this.props.config.Products.filter(p => p.SKU.toLowerCase().includes(query.toLowerCase())
                || p.Name.toLowerCase().includes(query.toLowerCase()) || p.BarCode.toLowerCase().includes(query.toLowerCase()));
        }

        let productSearchResult = []
        let rowCount = 0
        for (let i = 0; i < searchedProducts.length; i++) {

            if (rowCount > 10) {
                break
            }
            let e = searchedProducts[i]

            if (e.hasOwnProperty('AccessibleToSelectedStores')) {
                if (e.AccessibleToSelectedStores) {
                    let storePrice = e.StoreSpecificPrices.filter(f => f.StoreID === this.props.user.store.key)
                    if (storePrice.length > 0) {
                        e.RetailPrice = storePrice[0].RetailPrice
                        e.SupplyPrice = storePrice[0].SupplyPrice
                        e.Markup = storePrice[0].Markup
                        e.MRP = storePrice[0].MRP
                        e.DiscountAmount = storePrice[0].hasOwnProperty('DiscountAmount') ? storePrice[0].DiscountAmount : 0
                        e.DiscountPercentage = storePrice[0].hasOwnProperty('DiscountPercentage') ? storePrice[0].DiscountPercentage : 0
                        e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty('WalletTopUpSameAsRetailPrice') ? storePrice[0].WalletTopUpSameAsRetailPrice : false
                        e.WalletTopUpAmount = storePrice[0].hasOwnProperty('WalletTopUpAmount') ? storePrice[0].WalletTopUpAmount : 0
                        e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty('EditWalletTopUpAmount') ? storePrice[0].EditWalletTopUpAmount : false
                        productSearchResult.push(e)
                        rowCount = rowCount + 1
                    }
                    continue
                }
            }
            if (e.hasOwnProperty('StoreSpecificPricing')) {
                if (e.StoreSpecificPricing) {
                    let storePrice = e.StoreSpecificPrices.filter(f => f.StoreID === this.props.user.store.key)

                    if (storePrice.length > 0) {
                        e.RetailPrice = storePrice[0].RetailPrice
                        e.SupplyPrice = storePrice[0].SupplyPrice
                        e.Markup = storePrice[0].Markup
                        e.DiscountAmount = storePrice[0].hasOwnProperty('DiscountAmount') ? storePrice[0].DiscountAmount : 0
                        e.DiscountPercentage = storePrice[0].hasOwnProperty('DiscountPercentage') ? storePrice[0].DiscountPercentage : 0
                        e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty('WalletTopUpSameAsRetailPrice') ? storePrice[0].WalletTopUpSameAsRetailPrice : false
                        e.WalletTopUpAmount = storePrice[0].hasOwnProperty('WalletTopUpAmount') ? storePrice[0].WalletTopUpAmount : 0
                        e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty('EditWalletTopUpAmount') ? storePrice[0].EditWalletTopUpAmount : false
                        e.MRP = storePrice[0].MRP
                    }
                }
            }
            rowCount = rowCount + 1
            productSearchResult.push(e)
        }

        this.setState({ productsTypeAheadOptions: productSearchResult })
        this.setState({ isProductTypeAheadLoading: false });
    }

    onCustomerChange(customer) {
        if (customer) {
            this.onCustomerSelected(customer)
        }
        else {
            this.onRemoveCustomer()
        }
    }

    onRemoveCustomer() {
        //console.log('onRemoveCustomer', this.state.placeOfSupply)
        let rightHeight = this.state.rightHeight
        if (this.state.applicableMembership || this.state.placeOfSupply.trim() !== "") {//change line item price to normal
            rightHeight = rightHeight + 5
            let lineItems = []
            this.state.lineItems.map((l) => {
                let lineItem = l
                lineItem.RetailPrice = this.state.applicableMembership ? l.Product.RetailPrice : lineItem.RetailPrice
                lineItem.DiscountPer = 0
                lineItem.DiscountAmount = 0
                lineItem = this.onLineItemChange(-2, lineItem, "")
                lineItems.push(lineItem)
            })
            this.setState({ lineItems: lineItems })
            this.calculateBillTotal(lineItems)
        }
        if (this.state.selectedAddress) {
            rightHeight = rightHeight + 10
        }
        this.setState({
            selectedCustomer: null,
            applicableMembership: null,
            selectedCustomerAddress: null,
            rightHeight: rightHeight, placeOfSupply: "", customerError: ""
        })

        setTimeout(() => {
            this.shortcutSearchCustomer()
        }, 1000);
    }

    onCustomerSelected(selectedCustomer) {
        let applicableMembership = null
        let applyMembershipDiscount = false
        let rightHeight = this.state.rightHeight
        if (selectedCustomer.Membership) {
            this.state.memberships.filter((e) => e.key === selectedCustomer.Membership.MembershipID && !e.IsDeleted).map((e) => {
                applicableMembership = e
            })
            if (applicableMembership) {
                applyMembershipDiscount = true
                rightHeight = rightHeight - 5
                //recalculate item price based on customer mebership
                this.onApplyRemoveMembershipDiscount(applyMembershipDiscount, applicableMembership)
                setTimeout(() => {
                    this.shortcutProductSearch()
                }, 1000);
            }
        }
        if (selectedCustomer.Addresses.length >= 1) {
            rightHeight = rightHeight - 10
        }
        this.setState({
            customerError: "",
            rightHeight: rightHeight,
            placeOfSupply: "",
            selectedCustomer: selectedCustomer,
            applicableMembership: applicableMembership,
            applyMembershipDiscount: applyMembershipDiscount
        })
    }

    onSelectedCustomerAddressChange(placeOfSupply) {
        //console.log('onSelectedCustomerAddressChange')
        if (placeOfSupply.trim() === "") { return }
        let lineItems = []
        this.state.lineItems.map((l) => {
            let lineItem = l
            lineItem = this.onLineItemChange(-2, lineItem, placeOfSupply)
            lineItems.push(lineItem)
        })
        this.setState({ lineItems: lineItems })
        this.calculateBillTotal(lineItems)
    }

    onDiscardClicked() {
        this.setState({
            selectedCategory: '',
            selectedCategoryID: '',
            selectedSubCategory: '',
            selectedSubCategoryID: '',
            selectedCustomer: null,
            lineItems: [],
            billQty: 0,
            billSubTotal: 0,
            billDiscount: 0,
            billTaxAmount: 0,
            billAmount: 0,
            parentTransactionNo: '',
            parentTransactionKey: '',
            transactionLevelEmployeeSelected: null,
            notes: '',
            taxSummaryList: null,
            selectedCustomerAddress: null,
            placeOfSupply: ''
        })
    }

    onParkSaleClicked() {
        
        if (this.state.lineItems.length === 0) {
            this.setState({
                showError: true,
                noLineItemExistError: "No line item"
            })
            return
        }

        let sale = {
            TransactionType: this.state.transactionType,
            Customer: this.state.selectedCustomer,
            RegistrationID: this.props.user.user.RegistrationID,
            CashRegister: this.props.user.cashRegister,
            Store: this.props.user.store,
            LineItems: this.state.lineItems,
            BillQty: this.state.billQty,
            BillSubTotal: this.state.billSubTotal,
            BillDiscount: this.state.billDiscount,
            BillTaxAmount: this.state.billTaxAmount,
            BillAmount: this.state.billAmount,
            BillBalanceAmount: 0,
            BillPaidAmount: 0,
            BillChangeDue: 0,
            ParentTransactionNo: this.state.parentTransactionNo,
            ParentTransactionKey: this.state.parentTransactionKey,
            Employee: this.state.transactionLevelEmployeeSelected,
            Notes: this.state.notes,
            TaxSummaryList: this.state.taxSummaryList,
            BillingAddress: this.state.selectedCustomerAddress,
            PlaceOfSupply: this.state.placeOfSupply
        }
        let parkedSales = []
        parkedSales = this.props.sales.DraftSales
        parkedSales.push(sale)
        this.props.parkSales(parkedSales)
        this.onDiscardClicked()
    }

    onOverAllDiscount(overallDiscountPercentage,
        overallDiscountAmount,
        overallDiscountClearExistingDiscount,
        discountType,
        discountID,
        discountInfo
    ) {
        let discountPercentage = this.roundUpTo2(overallDiscountPercentage)
        let discountAmount = this.roundUpTo2(overallDiscountAmount)
        let overRide = overallDiscountClearExistingDiscount

        if (discountAmount > 0) {
            let billSubTotal = 0
            this.state.lineItems.map(m => {
                let allowDiscount = true
                if (m.Product.hasOwnProperty('AllowDiscount')) {
                    allowDiscount = m.Product.AllowDiscount
                }
                if (allowDiscount) {
                    billSubTotal = billSubTotal + Number(m.SubTotal)
                }
            })
            discountPercentage = Number(((discountAmount * 100) / Number(billSubTotal)).toFixed(3))
            // console.log('discountPercentage', discountPercentage)
        }
        let updatedLineItems = []
        this.state.lineItems.map((lineItem) => {
            // console.log('before- pawan', lineItem.DiscountPer)
            lineItem.DiscountPer = overRide ? Number(discountPercentage) : (Number(lineItem.DiscountPer) + Number(discountPercentage))
            // console.log('after', lineItem.DiscountPer)
            lineItem.DiscountAmount = 0
            lineItem = this.onLineItemChange(-2, lineItem, this.state.placeOfSupply, discountType,
                discountID,
                discountInfo, true)
            updatedLineItems.push(lineItem)
        })
        this.setState({
            lineItems: updatedLineItems, showDiscountOptions: false,
            overallDiscountAmount: 0, overallDiscountClearExistingDiscount: false, overallDiscountPercentage: 0
        })
        this.calculateBillTotal(updatedLineItems)
    }

    async onProductSelected(product) {
        if (product.hasOwnProperty('InventoryType')
            && product.InventoryType.toLowerCase() === "unique no") {
            this.setState({ showUniqueNoSelection: true, selectedProduct: product })
            return
        }
        this.productSelected(product, null)
    }

    async productSelected(product, uniqueNoList) {
        let lineItemIndex = -1
        let lineItem = {}
        if ((!this.state.transactionType.EmployeeMandatoryChecked && lineItemIndex === -1)
            || (this.state.transactionType.EmployeeMandatoryChecked &&
                this.state.transactionType.EmployeeManadatoryTransactionORLineItemLevel !== "lineitem" &&
                lineItemIndex === -1)) {
            this.state.lineItems.map((l, index) => {
                if (l.Product.key === product.key) {
                    //increase qty 
                    lineItem = l
                    lineItemIndex = index
                    // return
                }
            })
        }

        //check if product is already added
        //if lineitem level employee mandatory then 
        if (lineItemIndex === -1) {
            // console.log('product',product)
            lineItem.RetailPrice = product.RetailPrice
            lineItem.DiscountPer = product.hasOwnProperty('DiscountPercentage') ? product.DiscountPercentage : 0
            lineItem.DiscountAmount = 0
            if (uniqueNoList && uniqueNoList.length > 0) {
                lineItem.UniqueNoList = uniqueNoList
                lineItem.Qty = (this.state.transactionType.TransactionType.toLowerCase() === "sales return" && this.state.negativeQty) ? -1 * uniqueNoList.length : uniqueNoList.length
            }
            else {
                lineItem.Qty = (this.state.transactionType.TransactionType.toLowerCase() === "sales return" && this.state.negativeQty) ? -1 : 1
            }
            lineItem.SubTotal = 0
            lineItem.ChargeTaxOnProduct = product.ChargeTaxOnProduct
            lineItem.PriceBasedTax = product.ChargeTaxOnProduct ? product.PriceBasedTax : false
            lineItem.HSNSACCode = product.HSNSACCode
            lineItem.TaxInclusive = product.ChargeTaxOnProduct ? product.TaxInclusive : false
            lineItem.TaxGroup = product.TaxGroup
            lineItem.TaxID = product.TaxID
            lineItem.SubTotalWithDiscount = 0
            lineItem.TaxableAmount = 0
            lineItem.TotalTax = 0
            lineItem.Amount = 0
            lineItem.Product = product
            lineItem.Employee = { key: '' }
            lineItem.Notes = ''
            let overallMembershipDiscount = false
            if (this.state.applicableMembership) {
                if (this.state.applicableMembership.hasOwnProperty("DiscountApplied")) {
                    overallMembershipDiscount = this.state.applicableMembership.DiscountApplied
                }
                this.applyRemoveMembershipDiscountAtLineItem(lineItem,
                    this.state.applyMembershipDiscount,
                    overallMembershipDiscount, this.state.applicableMembership)
            }
        }
        else {
            // console.log('lineItem.UniqueNoList', lineItem.UniqueNoList)
            // console.log('uniqueNoList',uniqueNoList)
            if (uniqueNoList && uniqueNoList.length > 0) {
                // for (let index = 0; index < uniqueNoList.length; index++) {
                //     const element = uniqueNoList[index];
                //     lineItem.UniqueNoList.push(element)
                // }
                lineItem.UniqueNoList = uniqueNoList
                lineItem.Qty = (this.state.transactionType.TransactionType.toLowerCase() === "sales return" && this.state.negativeQty) ? -1 * lineItem.UniqueNoList.length : lineItem.UniqueNoList.length
            }
            else {
                lineItem.Qty = (this.state.transactionType.TransactionType.toLowerCase() === "sales return" && this.state.negativeQty) ? Number(lineItem.Qty) - 1 : Number(lineItem.Qty) + 1
            }
            // if(this.state.transactionType.TransactionType.toLowerCase()=== "sales return" && this.state.negativeQty)
            // {
            //     lineItem.Qty = Number(lineItem.Qty) - qty
            // }
            // else
            // {
            //     lineItem.Qty = Number(lineItem.Qty) + qty
            // } 
        }

        lineItem = this.onLineItemChange(lineItemIndex, lineItem, this.state.placeOfSupply)
        let lineItems = this.state.lineItems
        if (lineItemIndex >= 0) {
            lineItems.splice(lineItemIndex, 1);
            lineItems.unshift(lineItem)
        }
        else {
            lineItems.unshift(lineItem)
        }
        this.setState({ lineItems: lineItems })
        await this.calculateBillTotal(lineItems)

        if (this.state.transactionType.EmployeeMandatoryChecked &&
            this.state.transactionType.EmployeeManadatoryTransactionORLineItemLevel === "lineitem" &&
            lineItemIndex === -1) {
            this.onLineItemSelected(lineItem, lineItems.length - 1)
        }
        this.applyOffer()
    }

    async onRemoveLineItem(index) {
        let lineItems = this.state.lineItems
        lineItems.splice(index, 1)
        await this.calculateBillTotal(lineItems)
        await this.applyOffer()
        this.setState({
            selectedLineItem: null,
            selectedLineItemIndex: -1,
            selectedItemEmployeeSelectionError: ''
        })
    }

    ///line Item selected events -- START 
    onLineItemSelected(lineItem, index) {
        let selectedLineItem = { ...lineItem }
        this.setState({
            selectedLineItem: selectedLineItem,
            selectedLineItemIndex: index,
            selectedItemEmployeeSelectionError: '',
            showLineItemModal: true
        })
        //console.log('onLineItemSelected', lineItem)
    }

    onLineItemPriceChange(price) {
        let lineItem = this.state.selectedLineItem
        lineItem.RetailPrice = Number(price)
        lineItem = this.onLineItemChange(-2, lineItem, this.state.placeOfSupply)
        this.setState({ selectedLineItem: lineItem })
        //console.log('onLineItemPriceChange', this.state.selectedLineItem)
    }

    onLineItemQtyChange(qty) {
        let lineItem = this.state.selectedLineItem
        lineItem.Qty = Number(qty)
        lineItem = this.onLineItemChange(-2, lineItem, this.state.placeOfSupply)
        this.setState({ selectedLineItem: lineItem })
    }

    onLineItemDiscountChange(discountPer) {
        let lineItem = this.state.selectedLineItem
        lineItem.DiscountPer = Number(discountPer)
        lineItem.DiscountAmount = 0
        lineItem = this.onLineItemChange(-2, lineItem, this.state.placeOfSupply)
        this.setState({ selectedLineItem: lineItem })
    }

    onLineItemDiscountAmountChange(discountAmount) {
        let lineItem = this.state.selectedLineItem
        lineItem.DiscountPer = 0
        lineItem.DiscountAmount = Number(discountAmount)
        lineItem = this.onLineItemChange(-2, lineItem, this.state.placeOfSupply)
        this.setState({ selectedLineItem: lineItem })
    }

    onChargeTaxOnProductChange() {
        let lineItem = this.state.selectedLineItem
        lineItem.ChargeTaxOnProduct = !lineItem.ChargeTaxOnProduct
        if (!lineItem.ChargeTaxOnProduct) {
            lineItem.TaxInclusive = false
            lineItem.TaxGroup = ""
        }
        lineItem = this.onLineItemChange(-2, lineItem, this.state.placeOfSupply)
        this.setState({ selectedLineItem: lineItem })
    }

    onTaxIncluxiveChange() {
        let lineItem = this.state.selectedLineItem
        lineItem.TaxInclusive = !lineItem.TaxInclusive
        lineItem = this.onLineItemChange(-2, lineItem, this.state.placeOfSupply)
        this.setState({ selectedLineItem: lineItem })

    }

    onTaxGroupChange(taxID, taxGroup) {
        let lineItem = this.state.selectedLineItem
        lineItem.TaxID = taxID
        lineItem.TaxGroup = taxGroup
        lineItem = this.onLineItemChange(-2, lineItem, this.state.placeOfSupply)
        this.setState({ selectedLineItem: lineItem })
    }

    onLineItemNotesChange(notes) {
        let lineItem = this.state.selectedLineItem
        lineItem.Notes = notes
        //lineItem = {...this.state.selectedLineItem, Notes:notes}
        //console.log('noteschange', lineItem)
        this.setState({ selectedLineItem: lineItem })
        //this.setState({selectedLineItem: lineItem})
    }

    onLineItemWalletTopUpChange(amount) {
        // console.log(amount)
        let lineItem = this.state.selectedLineItem
        lineItem.Product.WalletTopUpAmount = Number(amount)
        this.setState({ selectedLineItem: lineItem })
    }

    async onLineItemChangesDone() {
        if (this.state.transactionType.EmployeeMandatoryChecked &&
            this.state.transactionType.EmployeeManadatoryTransactionORLineItemLevel === "lineitem") {
            if (this.state.selectedLineItem.Employee.key === "") {
                this.setState({ selectedItemEmployeeSelectionError: "Please select employee" })
                return
            }
        }
        let lineItems = this.state.lineItems
        lineItems[this.state.selectedLineItemIndex] = this.state.selectedLineItem
        this.setState({ lineItems: lineItems, showLineItemModal: false })
        await this.calculateBillTotal(lineItems)
        this.applyOffer()
    }

    async calculateBillTotal(lineItems) {
        let billQty = 0
        let billAmount = 0
        let billSubTotal = 0
        let billTaxAmount = 0
        let billDiscount = 0
        let taxSummaryList = []
        let roundOff = 0
        // console.log('calculateBillTotal this.state.lineItems', this.state.lineItems)
        //this.state.
        lineItems.map((lineItem, index) => {
            if (lineItem.TaxID != "") {
                let indexFound = -1
                taxSummaryList.map((t, index) => {
                    if (t.TaxID === lineItem.TaxID && t.HSNSACCode === lineItem.HSNSACCode) {
                        let sameTaxComponent = true
                        t.TaxComponentAmount.map((a) => {
                            if (lineItem.TaxComponentAmount.filter(f => f.TaxName === a.TaxName).length === 0) {
                                sameTaxComponent = false
                                return
                            }
                        })
                        if (sameTaxComponent) {
                            indexFound = index
                            return
                        }
                    }
                })
                //console.log(indexFound)
                if (indexFound < 0) {
                    let taxSummary = {
                        TaxID: lineItem.TaxID,
                        TaxGroup: lineItem.TaxGroup,
                        HSNSACCode: lineItem.HSNSACCode,
                        TaxableAmount: this.roundUpTo2(lineItem.TaxableAmount),
                        TotalTax: this.roundUpTo2(lineItem.TotalTax),
                        TaxComponentAmount: []
                    }
                    lineItem.TaxComponentAmount.map((a) => {
                        taxSummary.TaxComponentAmount.push({ ...a })
                    })
                    taxSummaryList.push(taxSummary)
                }
                else {
                    taxSummaryList[indexFound].TaxableAmount = this.roundUpTo2(Number(taxSummaryList[indexFound].TaxableAmount) + Number(lineItem.TaxableAmount))
                    taxSummaryList[indexFound].TotalTax = this.roundUpTo2(Number(taxSummaryList[indexFound].TotalTax) + Number(lineItem.TotalTax))
                    taxSummaryList[indexFound].TaxComponentAmount.map((t, index) => {
                        t.TaxAmount = this.roundUpTo2(Number(t.TaxAmount) + Number(lineItem.TaxComponentAmount[index].TaxAmount))
                    })
                }
            }
            //console.log('taxSummaryList', taxSummaryList)
            billQty = this.roundUpTo2(Number(billQty) + Number(lineItem.Qty))
            billSubTotal = this.roundUpTo2(Number(billSubTotal) + Number(lineItem.SubTotal))
            billAmount = this.roundUpTo2(Number(billAmount) + Number(lineItem.Amount))
            billTaxAmount = this.roundUpTo2(Number(billTaxAmount) + Number(lineItem.TotalTax))
            billDiscount = this.roundUpTo2(Number(billDiscount) + Number(lineItem.DiscountAmount))
        })
        roundOff = this.roundUpTo2(Math.round(billAmount) - billAmount)
        // console.log('roundOff', Math.round(roundOff))
        billAmount = Math.round(billAmount)
        this.setState({
            billQty: billQty,
            billSubTotal: billSubTotal,
            billAmount: billAmount,
            billTaxAmount: billTaxAmount,
            billDiscount: billDiscount,
            taxSummaryList: taxSummaryList,
            roundOff: roundOff
        })
    }

    async applyOffer()
    {
        console.log('**** applyOffer ****')
        // return      
        // let offers = this.state.offers

        let offersTmp = this.state.offers
        console.log("Temp Offers", JSON.stringify(offersTmp));
        let offers = []
        //check for time based offers
        for (let k = 0; k < offersTmp.length; k++) {
            let offer = offersTmp[k]
            if (offer.SpecificTime !== "" && offer.SpecificTime.toLowerCase() === "yes") {
                console.log('Time based offer:', offer.Name)
                var today = new Date()
                var currentTime = today.getHours() + "." + today.getMinutes()
                console.log('currentTime', currentTime)
                //console.log('currentHrs', currentHrs)
                //console.log('currentMins', currentMins)
                var startTime = (Number(offer.OfferStartTime.HH) + (offer.OfferStartTime.AMPM === "PM" ? 12 : 0)) + "." + offer.OfferStartTime.MM
                var endTime = (Number(offer.OfferEndTime.HH) + (offer.OfferEndTime.AMPM === "PM" ? 12 : 0)) + "." + offer.OfferEndTime.MM
                console.log('startTime', startTime)
                console.log('endTime', endTime)
                if (Number(startTime) <= Number(currentTime) && Number(currentTime) <= (endTime)) {
                    console.log('Time based offer applicable')
                    offers.push(offer)
                }
            }
            else {
                offers.push(offer)
            }
        }


        // console.log('offers',offers)
        if(offers.length===0)
        {
            return
        }
        if(this.state.applyMembershipDiscount &&  this.state.selectedCustomer && this.state.selectedCustomer.Membership && this.state.selectedCustomer.Membership.hasOwnProperty('MembershipID'))
        {
            return 
        }
        let offersApplied = this.state.offersApplied
        let beforeOffersToApply= this.state.offersToApply
        let offersToApply = []//this.state.offersToApply
        let showOffersToApply = false
        let lineItems = this.state.lineItems
        //1.first check if TRANSACTION levle offers that are alredy applied are still applicable if not then remove them
        let offersAppliedToBeRetained = []
        for(let i=0; i<offersApplied.length; i++){
            let offer = offersApplied[i]
            if(offer.OfferTypeID.toLowerCase()==="invoicediscount"){
                let invoiceAmount = Number(this.state.billAmount)
                if(invoiceAmount < offer.InvoiceAmount)
                {
                    await this.removeSelectedOffer(i)
                    continue
                }
                else{
                    //19th
                    await this.applyInvoiceDiscountOffer(offer)
                    offersAppliedToBeRetained.push(offer)
                }
                
            }
        }
        
        //2. now remove line item level offers
        for(let i = 0 ; i<lineItems.length;i++)
        {
            if(lineItems[i].hasOwnProperty('Discounts') && lineItems[i].Discounts.length> 0)
            {//if line item level then remove discout
                if(lineItems[i].Discounts[0].DiscountType.toLowerCase() ==="offer" && !lineItems[i].Discounts[0].TransactionLevelDiscount)
                {
                    lineItems[i].DiscountPer= 0 
                    lineItems[i].DiscountAmount = 0 
                    lineItems[i].Discounts=[]
                    lineItems[i] =  this.onLineItemChange(0, lineItems[i], this.state.placeOfSupply)
                    await this.calculateBillTotal(lineItems, this.state.receivedPaymentAmount)
                }
            }
        }
        // console.log('lineItems after removing line item offers', lineItems)

        //3. check which offers TRANSACTION level and LINE ITEM level are now applicable 
        for(let i=0; i<offers.length;i++)
        {
            let offer = offers[i]
            console.log('i',i)
            // console.log('offer.OfferTypeID',offer.OfferTypeID)
            if(offer.OfferTypeID.toLowerCase()==="invoicediscount"){
                let invoiceAmount = Number(this.state.billAmount)
                if(invoiceAmount>= offer.InvoiceAmount)
                {
                    //check if this offer is alread applied 
                    if(offersApplied.filter(f=> f.key === offer.key).length>0)
                    {
                        continue
                    }
                    else
                    {
                        if(offersToApply.filter(f=> f.key=== offer.key).length===0)
                        {
                            showOffersToApply= true 
                            offersToApply.push(offer)
                        }
                    }
                }
            }else  if(offer.OfferTypeID.toLowerCase()==="buyxgety"){
                //look at items that
                // console.log('buyxgety',offer)
                let offerApplied = false
                let offerProducts = []
                let purchaseGroupProductsFound = false
                let freeGroupProductsFound = false
                let productTags = this.props.config.ProductTags.filter(productTag=> productTag.key=== offer.PurchaseGroupID)
                if(productTags.length > 0 )
                {
                    // console.log('productTags found', productTags[0])
                    let productTag = productTags[0]
                    lineItems.map((m,index)=>{
                        let products = productTag.Products.filter(f=> f.ProductID=== m.Product.key)
                        if(products.length> 0 )
                        {
                            if(!m.hasOwnProperty('Discounts') || (m.hasOwnProperty('Discounts') && m.Discounts.length ===0))
                            {
                                purchaseGroupProductsFound = true
                                for(let j = 1 ; j<= m.Qty;j++){
                                    offerProducts.push({...m,Index:index, Counter:j,PurchaseGroup:true, FreeGroup:false,IncludedIn:0 }) //IncludedIn:1 = purchase group, IncludedIn:2 free group
                                }
                            }
                        }
                    })
                    // console.log('Purcahse group - offerProducts',offerProducts)
                    if(!purchaseGroupProductsFound)
                    {//if no purchase group products found then proceed further
                        continue
                    }
                    //get free product group 
                    let freeProductTags = this.props.config.ProductTags.filter(productTag=> productTag.key=== offer.FreeGroupID)
                    if(freeProductTags.length > 0 )
                    {
                        let freeProductTag = freeProductTags[0]
                        lineItems.map((m,index)=>{
                            let products = freeProductTag.Products.filter(f=> f.ProductID=== m.Product.key)
                            if(products.length> 0)
                            {
                                if(!m.hasOwnProperty('Discounts')|| (m.hasOwnProperty('Discounts') && m.Discounts.length ===0))
                                {
                                    freeGroupProductsFound = true
                                    if(offerProducts.filter(f=> f.Index === index ).length> 0 )
                                    {//same line item product is part of purchase group also include it as free group
                                        for(let j = 0 ; j<offerProducts.length;j++){
                                            if(offerProducts[j].Index === index )
                                            {
                                                offerProducts[j].FreeGroup = true 
                                            }
                                        }
                                    }
                                    else
                                    {//add to offerProducts as free grouup product
                                        for(let j = 1 ; j<= m.Qty;j++){
                                            offerProducts.push({...m,Index:index, Counter:j,PurchaseGroup:false, FreeGroup:true,IncludedIn:0 }) //IncludedIn:1 = purchase group, IncludedIn:2 free group
                                        }
                                    }
                                }
                            }
                        })
                        ///sort this data as per price
                        if(!freeGroupProductsFound)
                        {//if no purchase group products found then proceed further
                            continue
                        }
                        // console.log('offer',offer)
                        // console.log('freeGroupProductsFound',freeGroupProductsFound)
                        // console.log('freeGroupProductsFound  - offerProducts',offerProducts)
                        //check purchase group qty is met 
                        let purchaseGroupQty= 0 
                        // let purcahseCriteriaMeet = false
                        offerProducts.sort(function(a, b){return b.RetailPrice - a.RetailPrice})
                        // console.log('offerProducts',offerProducts)
                        let offerProductsProcessing = []
                        for(let s = 0 ; s<offerProducts.length;s++)
                        {
                            if(offerProducts[s].IncludedIn === 0 )
                            {
                                purchaseGroupQty = Number(purchaseGroupQty)+ 1
                                offerProducts[s].IncludedIn = 1
                                offerProductsProcessing.push(offerProducts[s])
                            }
                            // console.log('purchaseGroupQty',purchaseGroupQty)
                            if(offer.PurchaseQty <= purchaseGroupQty)
                            {
                                purchaseGroupQty=0 
                                // purcahseCriteriaMeet= true
                                // console.log('purcahseCriteriaMeet',true)
                                let freeGroupQty = 0
                                for(let j = 0 ; j<offerProducts.length;j++)
                                {
                                    if(offerProducts[offerProducts.length - (j+ 1)].IncludedIn === 0 )
                                    {
                                        
                                        freeGroupQty = freeGroupQty + 1
                                        offerProducts[offerProducts.length - (j+ 1)].IncludedIn = 2
                                        offerProductsProcessing.push(offerProducts[offerProducts.length - (j+ 1)])
                                        if( offer.FreeQty<= freeGroupQty)
                                        {
                                            //apply offer
                                            //if this is already part of offers that are applicable to be applied them keep it that way
                                            if(beforeOffersToApply.filter(f=>f.key === offer.key).length > 0)
                                            {
                                                console.log('break')
                                                offersToApply.push(offer)
                                                break
                                            }
                                            if(offer.hasOwnProperty('SpreadDiscount') && offer.SpreadDiscount)
                                            {
                                                //Apply Discount Percentage Equally -- Start
                                                let freeAmount = 0 
                                                let purchaseAmount = 0 
                                                let discountPer = 0
                                                let index = - 1
                                                for(let k= 0 ; k<offerProductsProcessing.length; k++)
                                                {
                                                    let offerPoduct = offerProductsProcessing[k]
                                                    if(offerPoduct.IncludedIn=== 2 && index !==offerPoduct.Index){
                                                        freeAmount = freeAmount + lineItems[offerPoduct.Index].SubTotalWithDiscount
                                                        index = offerPoduct.Index
                                                    }
                                                }
                                                index = - 1
                                                for(let k= 0 ; k<offerProductsProcessing.length; k++)
                                                {
                                                    let offerPoduct = offerProductsProcessing[k]
                                                    if(offerPoduct.IncludedIn=== 1 && index !==offerPoduct.Index){
                                                        purchaseAmount = purchaseAmount + lineItems[offerPoduct.Index].SubTotalWithDiscount
                                                        index = offerPoduct.Index
                                                    }
                                                }
                                                discountPer = this.roundUpTo2(freeAmount * 100 / (freeAmount + purchaseAmount))  
                                                console.log('freeAmount', freeAmount)
                                                console.log('purchaseAmount', purchaseAmount)
                                                console.log('discountPer',discountPer)
                                                index = - 1
                                                for(let k= 0 ; k<offerProductsProcessing.length; k++)
                                                {
                                                    let offerPoduct = offerProductsProcessing[k]
                                                    // console.log('offerPoduct.Index',offerPoduct)
                                                    if(index !==offerPoduct.Index)
                                                    {
                                                        // console.log('Applying offer discount percentage', offer.DiscountPercentage)
                                                        index = offerPoduct.Index
                                                        // console.log('index', index)
                                                        lineItems[index].DiscountPer= discountPer //percentage
                                                        lineItems[index].DiscountAmount=  0
                                                        lineItems[index] =  this.onLineItemChange(0, lineItems[index],this.state.placeOfSupply,"Offer",  offer.key,
                                                        offer.Name, false)
                                                        await this.calculateBillTotal(lineItems, this.state.receivedPaymentAmount)
                                                        offerApplied = true 
                                                    }
                                                }
                                                //Apply Discount Percentage Equally -- End
                                            }  
                                            else
                                            {
                                                //Free QTY -- Start
                                                let index = - 1
                                                for(let k= 0 ; k<offerProductsProcessing.length; k++)
                                                {
                                                    // console.log('k',k)
                                                    // console.log('index', index)
                                                    let offerPoduct = offerProductsProcessing[k]
                                                    if(index !== -1 && index !==offerPoduct.Index)
                                                    {
                                                        lineItems[index] = this.onLineItemChange(0, lineItems[index],this.state.placeOfSupply,"Offer",  offer.key,
                                                        offer.Name, false)
                                                        await this.calculateBillTotal(lineItems, this.state.receivedPaymentAmount)
                                                        offerApplied = true 
                                                        // console.log('lineItems[index]', lineItems[index])
                                                    }
                                                    if(offerPoduct.IncludedIn=== 2){
                                                        index = offerPoduct.Index
                                                        lineItems[offerPoduct.Index].DiscountPer= 0 
                                                        lineItems[offerPoduct.Index].DiscountAmount=  Number(lineItems[offerPoduct.Index].DiscountAmount)  + (lineItems[offerPoduct.Index].RetailPrice)
                                                        // lineItems[offerPoduct.Index].IncludedInOffer = true 
                                                        // lineItems[offerPoduct.Index].IncludedInOfferID = offer.key 
                                                        // console.log('part of free group')
                                                    }
                                                    else
                                                    {
                                                        index = offerPoduct.Index
                                                        // lineItems[offerPoduct.Index].IncludedInOffer = true 
                                                        // lineItems[offerPoduct.Index].IncludedInOfferID = offer.key 
                                                        // console.log('part ofprucase group')
                                                    }
                                                    // console.log('lineItems[offerPoduct.Index]', lineItems[offerPoduct.Index])
                                                }
                                                // console.log('index', index)
                                                if(index !== -1 )
                                                {
                                                    lineItems[index] = this.onLineItemChange(0, lineItems[index],this.state.placeOfSupply,"Offer",  offer.key,
                                                    offer.Name, false)
                                                    await this.calculateBillTotal(lineItems, this.state.receivedPaymentAmount)
                                                    offerApplied= true 
                                                    // console.log('lineItems[index]', lineItems[index])
                                                }
                                                //Free QTY -- END
                                            }                                          
                        
                                            freeGroupQty= 0 
                                            offerProductsProcessing= []
                                            // console.log('freeGroupQty', freeGroupQty)
                                            // console.log('offer.FreeQty', offer.FreeQty)
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                    }                    
                }
                if(offerApplied)
                {
                    if(offersAppliedToBeRetained.filter(f=>f.key === offer.key).length===0)
                    {
                        offersAppliedToBeRetained.push(offer)
                    }
                    
                }
            }
            else  if(offer.OfferTypeID.toLowerCase()==="buyxqtygetydiscountpercentage"){
                // console.log('offer name', offer.Name)
                let offerApplied = false
                let offerProducts = []
                let purchaseGroupProductsFound = false
                let productTags = this.props.config.ProductTags.filter(productTag=> productTag.key=== offer.PurchaseGroupID)
                if(productTags.length > 0 )
                {
                    // console.log('productTags.length', productTags.length)
                    let productTag = productTags[0]
                    lineItems.map((m,index)=>{
                        let products = productTag.Products.filter(f=> f.ProductID=== m.Product.key)
                        if(products.length> 0 )
                        {
                            // console.log('products found',m.Product)
                            if(!m.hasOwnProperty('Discounts') || (m.hasOwnProperty('Discounts') && m.Discounts.length ===0))
                            {
                                purchaseGroupProductsFound = true
                                for(let j = 1 ; j<= m.Qty;j++){
                                    offerProducts.push({...m,Index:index, Counter:j,PurchaseGroup:true, FreeGroup:false,IncludedIn:0 }) //IncludedIn:1 = purchase group, IncludedIn:2 free group
                                }
                            }
                        }
                    })
                    // console.log('Purcahse group - offerProducts',offerProducts)
                    if(!purchaseGroupProductsFound)
                    {//if no purchase group products found then proceed further
                        continue
                    }
                    // console.log('purchaseGroupProductsFound', purchaseGroupProductsFound)
                    //check purchase group qty is met 
                    let purchaseGroupQty= 0 
                    // let purcahseCriteriaMeet = false
                    offerProducts.sort(function(a, b){return b.RetailPrice - a.RetailPrice})
                    
                    // console.log('offerProducts',offerProducts)
                    let offerProductsProcessing = []
                    for(let s = 0 ; s<offerProducts.length;s++)
                    {
                        if(offerProducts[s].IncludedIn === 0 )
                        {
                            purchaseGroupQty = Number(purchaseGroupQty)+ 1
                            offerProducts[s].IncludedIn = 1
                            offerProductsProcessing.push(offerProducts[s])
                        }
                        // console.log('purchaseGroupQty',purchaseGroupQty)
                        if(offer.PurchaseQty <= purchaseGroupQty)
                        {
                            // console.log('Purchase qty criteria meet - offerProductsProcessing',offerProductsProcessing )
                            
                            //pawan
                            if(beforeOffersToApply.filter(f=>f.key === offer.key).length > 0)
                            {
                                console.log('break 1')
                                offersToApply.push(offer)
                                break
                            }
                            let index = - 1
                            for(let k= 0 ; k<offerProductsProcessing.length; k++)
                            {
                                let offerPoduct = offerProductsProcessing[k]
                                // console.log('offerPoduct.Index',offerPoduct)
                                if(index !==offerPoduct.Index)
                                {
                                    // console.log('Applying offer discount percentage', offer.DiscountPercentage)
                                    index = offerPoduct.Index
                                    // console.log('index', index)
                                    lineItems[index].DiscountPer= offer.DiscountPercentage //percentage
                                    lineItems[index].DiscountAmount=  0
                                    lineItems[index] =  this.onLineItemChange(0, lineItems[index],this.state.placeOfSupply,"Offer",  offer.key,
                                    offer.Name, false)
                                    await this.calculateBillTotal(lineItems, this.state.receivedPaymentAmount)
                                    offerApplied = true 
                                }
                            }
                            offerProductsProcessing=[]
                            purchaseGroupQty=0
                        }
                    }
                }
                if(offerApplied)
                {
                    if(offersAppliedToBeRetained.filter(f=>f.key === offer.key).length===0)
                    {
                        offersAppliedToBeRetained.push(offer)
                    }
                    
                }
            }
        }
        this.setState({lineItems:lineItems,offersApplied:offersAppliedToBeRetained})
        if(showOffersToApply)
        {
            this.setState({showDiscountOptions:true,
            showDiscountOffers:true,
            discountOptionSelected:"offer",
            offersPopupTabSelected:"applicable",offersToApply:offersToApply
            })
        }
        // this.setState({offersToApply:offersToApply})
    }

    async applySelectedOffer(index)
    {
        // return
        //this is transaciton offer 
        let lineItems = this.state.lineItems
        let offer = this.state.offersToApply[index]
        let discountPercentage = 0 
        let discountAmount = 0
        let offersApplied = this.state.offersApplied
        let offersToApply = this.state.offersToApply
        if(offer.OfferTypeID.toLowerCase()==="invoicediscount")
        {
            //check if offer is still valid
            let invoiceAmount = Number(this.state.billAmount)
            if(invoiceAmount < offer.InvoiceAmount)
            {
                // await this.removeSelectedOffer(index)
                // console.log('Offer no longer valid')
                offersToApply.splice(index, 1)
                this.setState({offersToApply:offersToApply})
                return
            }
            //check if any discountable item exists 
            let dicountableLineItemExists = false
            //console.log('lineItems',lineItems)
            for(let l=0;l<lineItems.length;l++)
            {
                let lineItem = lineItems[l]
                if(lineItem.Product.hasOwnProperty('AllowDiscount'))
                {
                    if(lineItem.Product.AllowDiscount)
                    {
                        dicountableLineItemExists = true
                        break
                    }
                }
                else
                {
                    dicountableLineItemExists = true 
                    break 
                }
            }
            //console.log('dicountableLineItemExists',dicountableLineItemExists)
            if(!dicountableLineItemExists)
            {
                console.log('Non discontanble items')
                return 
            }
            //added 19th May
            this.applyInvoiceDiscountOffer(offer)
        }
        else  if(offer.OfferTypeID.toLowerCase()==="buyxgety"){
            //look at items that
            // console.log('buyxgety',offer)
            let offerApplied = false
            let offerProducts = []
            let purchaseGroupProductsFound = false
            let freeGroupProductsFound = false
            let productTags = this.props.config.ProductTags.filter(productTag=> productTag.key=== offer.PurchaseGroupID)
            if(productTags.length > 0 )
            {
                // console.log('productTags found', productTags[0])
                let productTag = productTags[0]
                lineItems.map((m,index)=>{
                    let products = productTag.Products.filter(f=> f.ProductID=== m.Product.key)
                    if(products.length> 0 )
                    {
                        if(!m.hasOwnProperty('Discounts') || (m.hasOwnProperty('Discounts') && m.Discounts.length ===0))
                        {
                            purchaseGroupProductsFound = true
                            for(let j = 1 ; j<= m.Qty;j++){
                                offerProducts.push({...m,Index:index, Counter:j,PurchaseGroup:true, FreeGroup:false,IncludedIn:0 }) //IncludedIn:1 = purchase group, IncludedIn:2 free group
                            }
                        }
                    }
                })
                // console.log('Purcahse group - offerProducts',offerProducts)
                if(!purchaseGroupProductsFound)
                {//if no purchase group products found then proceed further
                    // console.log('Purchase group not found')
                    // await this.removeSelectedOffer(index)
                    offersToApply.splice(index, 1)
                    this.setState({offersToApply:offersToApply})
                    return
                }
                //get free product group 
                let freeProductTags = this.props.config.ProductTags.filter(productTag=> productTag.key=== offer.FreeGroupID)
                if(freeProductTags.length > 0 )
                {
                    let freeProductTag = freeProductTags[0]
                    lineItems.map((m,index)=>{
                        let products = freeProductTag.Products.filter(f=> f.ProductID=== m.Product.key)
                        if(products.length> 0)
                        {
                            if(!m.hasOwnProperty('Discounts')|| (m.hasOwnProperty('Discounts') && m.Discounts.length ===0))
                            {
                                freeGroupProductsFound = true
                                if(offerProducts.filter(f=> f.Index === index ).length> 0 )
                                {//same line item product is part of purchase group also include it as free group
                                    for(let j = 0 ; j<offerProducts.length;j++){
                                        if(offerProducts[j].Index === index )
                                        {
                                            offerProducts[j].FreeGroup = true 
                                        }
                                    }
                                }
                                else
                                {//add to offerProducts as free grouup product
                                    for(let j = 1 ; j<= m.Qty;j++){
                                        offerProducts.push({...m,Index:index, Counter:j,PurchaseGroup:false, FreeGroup:true,IncludedIn:0 }) //IncludedIn:1 = purchase group, IncludedIn:2 free group
                                    }
                                }
                            }
                        }
                    })
                    ///sort this data as per price
                    if(!freeGroupProductsFound)
                    {//if no purchase group products found then proceed further
                        // await this.removeSelectedOffer(index)
                        // console.log()
                        offersToApply.splice(index, 1)
                        this.setState({offersToApply:offersToApply})
                        return

                    }
                    // console.log('offer',offer)
                    // console.log('freeGroupProductsFound',freeGroupProductsFound)
                    // console.log('freeGroupProductsFound  - offerProducts',offerProducts)
                    //check purchase group qty is met 
                    let purchaseGroupQty= 0 
                    // let purcahseCriteriaMeet = false
                    offerProducts.sort(function(a, b){return b.RetailPrice - a.RetailPrice})
                    // console.log('offerProducts',offerProducts)
                    let offerProductsProcessing = []
                    for(let s = 0 ; s<offerProducts.length;s++)
                    {
                        if(offerProducts[s].IncludedIn === 0 )
                        {
                            purchaseGroupQty = Number(purchaseGroupQty)+ 1
                            offerProducts[s].IncludedIn = 1
                            offerProductsProcessing.push(offerProducts[s])
                        }
                        // console.log('purchaseGroupQty',purchaseGroupQty)
                        if(offer.PurchaseQty <= purchaseGroupQty)
                        {
                            purchaseGroupQty=0 
                            // purcahseCriteriaMeet= true
                            // console.log('purcahseCriteriaMeet',true)
                            let freeGroupQty = 0
                            for(let j = 0 ; j<offerProducts.length;j++)
                            {
                                if(offerProducts[offerProducts.length - (j+ 1)].IncludedIn === 0 )
                                {
                                    
                                    freeGroupQty = freeGroupQty + 1
                                    offerProducts[offerProducts.length - (j+ 1)].IncludedIn = 2
                                    offerProductsProcessing.push(offerProducts[offerProducts.length - (j+ 1)])
                                    if( offer.FreeQty<= freeGroupQty)
                                    {
                                        //apply offer
                                        //if this is already part of offers that are applicable to be applied them keep it that way
                                        // if(beforeOffersToApply.filter(f=>f.key === offer.key).length > 0)
                                        // {
                                        //     console.log('break')
                                        //     offersToApply.push(offer)
                                        //     break
                                        // }
                                        if(offer.hasOwnProperty('SpreadDiscount') && offer.SpreadDiscount)
                                        {
                                            //Apply Discount Percentage Equally -- Start
                                            let freeAmount = 0 
                                            let purchaseAmount = 0 
                                            let discountPer = 0
                                            let index = - 1
                                            for(let k= 0 ; k<offerProductsProcessing.length; k++)
                                            {
                                                let offerPoduct = offerProductsProcessing[k]
                                                if(offerPoduct.IncludedIn=== 2 && index !==offerPoduct.Index){
                                                    freeAmount = freeAmount + lineItems[offerPoduct.Index].SubTotalWithDiscount
                                                    index = offerPoduct.Index
                                                }
                                            }
                                            index = - 1
                                            for(let k= 0 ; k<offerProductsProcessing.length; k++)
                                            {
                                                let offerPoduct = offerProductsProcessing[k]
                                                if(offerPoduct.IncludedIn=== 1 && index !==offerPoduct.Index){
                                                    purchaseAmount = purchaseAmount + lineItems[offerPoduct.Index].SubTotalWithDiscount
                                                    index = offerPoduct.Index
                                                }
                                            }
                                            discountPer = this.roundUpTo2(freeAmount * 100 / (freeAmount + purchaseAmount))  
                                            console.log('freeAmount', freeAmount)
                                            console.log('purchaseAmount', purchaseAmount)
                                            console.log('discountPer',discountPer)
                                            index = - 1
                                            for(let k= 0 ; k<offerProductsProcessing.length; k++)
                                            {
                                                let offerPoduct = offerProductsProcessing[k]
                                                // console.log('offerPoduct.Index',offerPoduct)
                                                if(index !==offerPoduct.Index)
                                                {
                                                    // console.log('Applying offer discount percentage', offer.DiscountPercentage)
                                                    index = offerPoduct.Index
                                                    // console.log('index', index)
                                                    lineItems[index].DiscountPer= discountPer //percentage
                                                    lineItems[index].DiscountAmount=  0
                                                    lineItems[index] =  this.onLineItemChange(0, lineItems[index],this.state.placeOfSupply,"Offer",  offer.key,
                                                    offer.Name, false)
                                                    await this.calculateBillTotal(lineItems, this.state.receivedPaymentAmount)
                                                    offerApplied = true 
                                                }
                                            }
                                            //Apply Discount Percentage Equally -- End
                                        }  
                                        else
                                        {
                                            //Free QTY -- Start
                                            let index = - 1
                                            for(let k= 0 ; k<offerProductsProcessing.length; k++)
                                            {
                                                // console.log('k',k)
                                                // console.log('index', index)
                                                let offerPoduct = offerProductsProcessing[k]
                                                if(index !== -1 && index !==offerPoduct.Index)
                                                {
                                                    lineItems[index] = this.onLineItemChange(0, lineItems[index],this.state.placeOfSupply,"Offer",  offer.key,
                                                    offer.Name, false)
                                                    await this.calculateBillTotal(lineItems, this.state.receivedPaymentAmount)
                                                    offerApplied = true 
                                                    // console.log('lineItems[index]', lineItems[index])
                                                }
                                                if(offerPoduct.IncludedIn=== 2){
                                                    index = offerPoduct.Index
                                                    lineItems[offerPoduct.Index].DiscountPer= 0 
                                                    lineItems[offerPoduct.Index].DiscountAmount=  Number(lineItems[offerPoduct.Index].DiscountAmount)  + (lineItems[offerPoduct.Index].RetailPrice)
                                                    // lineItems[offerPoduct.Index].IncludedInOffer = true 
                                                    // lineItems[offerPoduct.Index].IncludedInOfferID = offer.key 
                                                    // console.log('part of free group')
                                                }
                                                else
                                                {
                                                    index = offerPoduct.Index
                                                    // lineItems[offerPoduct.Index].IncludedInOffer = true 
                                                    // lineItems[offerPoduct.Index].IncludedInOfferID = offer.key 
                                                    // console.log('part ofprucase group')
                                                }
                                                // console.log('lineItems[offerPoduct.Index]', lineItems[offerPoduct.Index])
                                            }
                                            // console.log('index', index)
                                            if(index !== -1 )
                                            {
                                                lineItems[index] = this.onLineItemChange(0, lineItems[index],this.state.placeOfSupply,"Offer",  offer.key,
                                                offer.Name, false)
                                                await this.calculateBillTotal(lineItems, this.state.receivedPaymentAmount)
                                                offerApplied= true 
                                                // console.log('lineItems[index]', lineItems[index])
                                            }
                                            //Free QTY -- END
                                        }                                          
                    
                                        freeGroupQty= 0 
                                        offerProductsProcessing= []
                                        // console.log('freeGroupQty', freeGroupQty)
                                        // console.log('offer.FreeQty', offer.FreeQty)
                                        break;
                                    }
                                    
                                }
                            }
                        }
                    }
                }                    
            }
            
        }
        else  if(offer.OfferTypeID.toLowerCase()==="buyxqtygetydiscountpercentage"){
            // console.log('offer name', offer.Name)
            let offerApplied = false
            let offerProducts = []
            let purchaseGroupProductsFound = false
            let productTags = this.props.config.ProductTags.filter(productTag=> productTag.key=== offer.PurchaseGroupID)
            if(productTags.length > 0 )
            {
                // console.log('productTags.length', productTags.length)
                let productTag = productTags[0]
                lineItems.map((m,index)=>{
                    let products = productTag.Products.filter(f=> f.ProductID=== m.Product.key)
                    if(products.length> 0 )
                    {
                        // console.log('products found',m.Product)
                        if(!m.hasOwnProperty('Discounts') || (m.hasOwnProperty('Discounts') && m.Discounts.length ===0))
                        {
                            purchaseGroupProductsFound = true
                            for(let j = 1 ; j<= m.Qty;j++){
                                offerProducts.push({...m,Index:index, Counter:j,PurchaseGroup:true, FreeGroup:false,IncludedIn:0 }) //IncludedIn:1 = purchase group, IncludedIn:2 free group
                            }
                        }
                    }
                })
                // console.log('Purcahse group - offerProducts',offerProducts)
                if(!purchaseGroupProductsFound)
                {//if no purchase group products found then proceed further
                    offersToApply.splice(index, 1)
                    this.setState({offersToApply:offersToApply})
                    return
                }
                // console.log('purchaseGroupProductsFound', purchaseGroupProductsFound)
                //check purchase group qty is met 
                let purchaseGroupQty= 0 
                // let purcahseCriteriaMeet = false
                offerProducts.sort(function(a, b){return b.RetailPrice - a.RetailPrice})
                
                // console.log('offerProducts',offerProducts)
                let offerProductsProcessing = []
                for(let s = 0 ; s<offerProducts.length;s++)
                {
                    if(offerProducts[s].IncludedIn === 0 )
                    {
                        purchaseGroupQty = Number(purchaseGroupQty)+ 1
                        offerProducts[s].IncludedIn = 1
                        offerProductsProcessing.push(offerProducts[s])
                    }
                    // console.log('purchaseGroupQty',purchaseGroupQty)
                    if(offer.PurchaseQty <= purchaseGroupQty)
                    {
                        // console.log('Purchase qty criteria meet - offerProductsProcessing',offerProductsProcessing )
                        
                        let index = - 1
                        for(let k= 0 ; k<offerProductsProcessing.length; k++)
                        {
                            let offerPoduct = offerProductsProcessing[k]
                            // console.log('offerPoduct.Index',offerPoduct)
                            if(index !==offerPoduct.Index)
                            {
                                // console.log('Applying offer discount percentage', offer.DiscountPercentage)
                                index = offerPoduct.Index
                                // console.log('index', index)
                                lineItems[index].DiscountPer= offer.DiscountPercentage //percentage
                                lineItems[index].DiscountAmount=  0
                                lineItems[index] =  this.onLineItemChange(0, lineItems[index],this.state.placeOfSupply,"Offer",  offer.key,
                                offer.Name, false)
                                await this.calculateBillTotal(lineItems, this.state.receivedPaymentAmount)
                                offerApplied = true 
                            }
                        }
                        offerProductsProcessing=[]
                        purchaseGroupQty=0
                    }
                }
            }
        }
        offersApplied.push(offer)
        offersToApply.splice(index, 1)
        this.setState({offersApplied:offersApplied, 
            offersToApply:offersToApply,
            showDiscountOptions:false})
   
    }

    async applyInvoiceDiscountOffer(offer)
    {
        let discountAmount = 0
        let discountPercentage = 0
        if(offer.Discount.toLowerCase()==="amount")
        {
            discountAmount= Number(offer.DiscountValue)
            // console.log('amount', discountAmount)
        }
        else
        {
            discountPercentage= Number(offer.DiscountValue)   
            // console.log('amount', discountPercentage)
        }
        this.onOverAllDiscount(discountPercentage,
            discountAmount, 
            true,
            "Offer",
            offer.key,
            offer.Name
            )
    }

    async removeSelectedOffer(index){

        let offer = this.state.offersApplied[index]
        let lineItems = this.state.lineItems
        let offerRemoved = false 
        for(let i= 0;i<lineItems.length; i++){
            let lineItem = lineItems[i]
            if(lineItem.hasOwnProperty('Discounts')){
                if(lineItem.Discounts.filter(f => f.DiscountID === offer.key).length > 0 ){
                    offerRemoved = true
                    // console.log('dicount applicable')
                    lineItem.DiscountPer = 0 
                    lineItem.DiscountAmount = 0 
                    lineItem.Discounts = []
                    lineItem=  this.onLineItemChange(0,lineItem, this.state.placeOfSupply,"","","")
                } 
            }
        }
        if(offerRemoved){
            this.calculateBillTotal(lineItems, this.state.receivedPaymentAmount)

            let offersToApply = this.state.offersToApply
            if(offersToApply.filter(f=> f.key === offer.key).length===0)
            {
                offersToApply.push(offer)
            }

            let offersApplied = this.state.offersApplied
            offersApplied.splice(index,1)

            this.setState({lineItems: lineItems, //updatedLineItems,
            offersToApply:offersToApply,offersApplied:offersApplied})
        }
    }

    onLineItemChange(index, lineItem, placeOfSupply,
        discountType,
        discountID,
        discountInfo,
        transactionLevelDiscount,
        transactionType) {
        //func:onLineItemChange
        let subTotal = this.roundUpTo2(Number(lineItem.RetailPrice) * Number(lineItem.Qty))
        let discountAmt = 0
        let discountPer = 0
        let allowDiscount = true
        if (lineItem.Product.hasOwnProperty('AllowDiscount')) {
            allowDiscount = lineItem.Product.AllowDiscount
        }
        if (allowDiscount) {

            if (Number(lineItem.DiscountAmount) > 0 && Number(lineItem.DiscountPer) === 0) {//calculate discount per based on discount amount
                //&& Number(lineItem.DiscountPer) === 0
                discountPer = Number(((Number(lineItem.DiscountAmount) * 100) / Number(lineItem.SubTotal)).toFixed(3))
                discountAmt = this.roundUpTo2(lineItem.DiscountAmount)
            }
            else {
                discountPer = Number(lineItem.DiscountPer)
                discountAmt = this.roundUpTo2((Number(subTotal) * Number(lineItem.DiscountPer)) / 100)
            }

            if (discountInfo) {
                if (discountInfo.trim() !== "") {
                    // if(!lineItem.hasOwnProperty('Discounts'))
                    // {
                    //     lineItem.Discounts = []
                    // }
                    lineItem.Discounts = []
                    lineItem.Discounts.push({
                        DiscountType: discountType,
                        DiscountID: discountID,
                        DiscountInfo: discountInfo,
                        DiscountAmount: discountAmt,
                        DiscountPercentage: discountPer,
                        TransactionLevelDiscount: transactionLevelDiscount
                    })
                }
            }
        }

        let subTotalWithDiscount = this.roundUpTo2(Number(subTotal) - Number(discountAmt))
        let chargeTaxOnProduct = lineItem.ChargeTaxOnProduct
        let priceBasedTax = lineItem.PriceBasedTax
        let taxInclusive = lineItem.TaxInclusive
        let taxableAmount = 0
        let totalTax = 0
        let amount = 0
        let taxComponentAmount = []
        let retailPriceWithoutTax = 0 //TBD

        let transactionTypeForCalculation = transactionType ? transactionType : this.state.transactionType
        if (transactionTypeForCalculation) {
            if (transactionTypeForCalculation.hasOwnProperty('CalculateTaxChecked')) {
                if (!transactionTypeForCalculation.CalculateTaxChecked) {
                    chargeTaxOnProduct = false
                }
                else if (transactionType) {
                    chargeTaxOnProduct = true
                }
            }
        }
        // if(this.state.transactionType)
        // {
        //     if(this.state.transactionType.hasOwnProperty('CalculateTaxChecked')){
        //         if(!this.state.transactionType.CalculateTaxChecked)
        //         {
        //             chargeTaxOnProduct= false
        //         }
        //     }
        // }

        if (chargeTaxOnProduct) {
            if (priceBasedTax) {
                let unitPriceAfterDiscount = this.roundUpTo2(Number(subTotalWithDiscount) / Number(lineItem.Qty))
                //console.log('unitPriceAfterDiscount',unitPriceAfterDiscount)
                //console.log('lineItem.Product.TaxPriceRange',lineItem.Product.TaxPriceRange)
                lineItem.Product.TaxPriceRange.map((t) => {
                    if (Number(unitPriceAfterDiscount) >= Number(t.FromPrice) && Number(unitPriceAfterDiscount) <= Number(t.ToPrice)) {
                        lineItem.TaxID = t.TaxID
                        lineItem.TaxGroup = t.TaxGroup
                        return
                    }
                })
            }
            let taxfound = false
            let customerFromSameState = true //TBD IMP IMP

            if (placeOfSupply !== "" && this.props.user.store.State !== "") {
                if (placeOfSupply.toLowerCase() !== this.props.user.store.State.toLowerCase()) {
                    customerFromSameState = false
                }
            }
            // console.log('placeOfSupply',placeOfSupply)
            // console.log('customerFromSameState', customerFromSameState)
            this.props.config.ProductTaxes.filter((t) => t.key === lineItem.TaxID.trim()).map((taxGroup, index) => {
                taxfound = true
                let taxAmount = 0
                let taxComponentTemp = []
                taxGroup.Taxes.map((t) => {
                    if (customerFromSameState && (t.TaxType === "CGST" || t.TaxType === "SGST")) {
                        taxComponentTemp.push({
                            TaxName: t.TaxName, TaxPercentage: Number(t.TaxPercentage),
                            TaxType: t.TaxType, TaxAmount: 0
                        })
                    }
                    else if (!customerFromSameState && t.TaxType === "IGST") {
                        taxComponentTemp.push({
                            TaxName: t.TaxName, TaxPercentage: Number(t.TaxPercentage),
                            TaxType: t.TaxType, TaxAmount: 0
                        })
                    }
                    else if (t.TaxType !== "CGST" && t.TaxType !== "IGST" && t.TaxType !== "SGST") {
                        taxComponentTemp.push({
                            TaxName: t.TaxName, TaxPercentage: Number(t.TaxPercentage),
                            TaxType: t.TaxType, TaxAmount: 0
                        })
                    }
                })

                if (taxInclusive) {//tax inclusive
                    let totalTaxRate = 0
                    taxComponentTemp.map((t) => {
                        totalTaxRate = this.roundUpTo2(Number(t.TaxPercentage) + Number(totalTaxRate))
                    })
                    totalTax = this.roundUpTo2(Number(subTotalWithDiscount) * Number(totalTaxRate) / (100 + Number(totalTaxRate)))
                    taxableAmount = this.roundUpTo2(Number(subTotalWithDiscount) - Number(totalTax))
                    amount = this.roundUpTo2(subTotalWithDiscount)
                    taxComponentTemp.map((t) => {
                        taxAmount = this.roundUpTo2(Number(totalTax) * ((Number(t.TaxPercentage) * 100) / Number(totalTaxRate)) / 100)
                        taxComponentAmount.push({
                            TaxName: t.TaxName, TaxPercentage: Number(t.TaxPercentage),
                            TaxType: t.TaxType, TaxAmount: taxAmount
                        })
                    })
                }
                else {
                    taxableAmount = subTotalWithDiscount
                    taxComponentTemp.map((t) => {
                        taxAmount = this.roundUpTo2((Number(taxableAmount) * Number(t.TaxPercentage)) / 100)
                        totalTax = this.roundUpTo2(Number(totalTax) + Number(taxAmount))
                        taxComponentAmount.push({
                            TaxName: t.TaxName, TaxPercentage: Number(t.TaxPercentage),
                            TaxType: t.TaxType, TaxAmount: taxAmount
                        })
                    })
                    //console.log('totalTax',totalTax)
                    amount = this.roundUpTo2(taxableAmount + totalTax)
                }
            })
            //tax is not found then
            if (!taxfound) {
                //alert("Tax not found for selected product. Please check item ")
                taxableAmount = 0
                taxComponentAmount = []
                totalTax = 0
                amount = subTotalWithDiscount
            }
        }
        else {
            taxableAmount = 0
            taxComponentAmount = []
            totalTax = 0
            amount = subTotalWithDiscount
        }

        // lineItem.Qty = qty
        // lineItem.RetailPrice = price
        lineItem.SubTotal = this.roundUpTo2(subTotal)
        lineItem.ChargeTaxOnProduct = chargeTaxOnProduct
        lineItem.TaxInclusive = taxInclusive
        lineItem.DiscountPer = this.roundUpTo2(discountPer)
        lineItem.DiscountAmount = this.roundUpTo2(discountAmt)
        lineItem.SubTotalWithDiscount = this.roundUpTo2(subTotalWithDiscount)
        lineItem.TaxableAmount = this.roundUpTo2(taxableAmount)
        lineItem.TaxComponentAmount = taxComponentAmount
        lineItem.TotalTax = this.roundUpTo2(totalTax)
        lineItem.Amount = this.roundUpTo2(amount)
        return lineItem
    }

    //line Item selected events -- END
    onRetriveParkedSales(draftSale, index) {
        //remove selected parked entry from redux store
        this.setState({
            selectedCustomer: draftSale.Customer,
            lineItems: draftSale.LineItems,
            billQty: draftSale.BillQty,
            billSubTotal: draftSale.BillSubTotal,
            billDiscount: draftSale.BillDiscount,
            billTaxAmount: draftSale.BillTaxAmount,
            billAmount: draftSale.BillAmount,
            parentTransactionNo: draftSale.ParentTransactionNo,
            parentTransactionKey: draftSale.ParentTransactionKey,
            notes: draftSale.Notes,
            taxSummaryList: draftSale.TaxSummaryList,
            selectedCustomerAddress: draftSale.BillingAddress,
            transactionLevelEmployeeSelected: draftSale.Employee,
            placeOfSupply: draftSale.PlaceOfSupply,
            showParkedSalesModal: false
        })
        let draftSales = this.props.sales.DraftSales
        draftSales.splice(index, 1)
        this.props.parkSales(draftSales)
    }

    onNotesOKClicked() {
        this.setState({ showNotesModal: false, notes: this.state.tempNotes })
    }

    onPaymentClick() {
        let customerError = ""
        let employeeMandatoryAtTransactionError = ""
        let employeeMandatoryAtLineItemError = ""
        let noLineItemExistError = ""

        if (this.state.transactionType.CustomerMandatoryChecked) {
            if (!this.state.selectedCustomer) {
                customerError = "Please select customer"
                // this.setState({customerError:customerError})
                // alert("Please select customer")
                // return
            }
        }
        //if employee is mandaotry then check if employee is selected 
        //console.log(this.state.transactionType.EmployeeManadatoryTransactionORLineItemLevel)
        if (this.state.transactionType.EmployeeMandatoryChecked) {
            if (this.state.transactionType.EmployeeManadatoryTransactionORLineItemLevel.toLowerCase() === "transaction") {
                if (!this.state.transactionLevelEmployeeSelected) {
                    employeeMandatoryAtTransactionError = "Please select employee"
                }
            }
            else {
                //console.log("lineitem employee erro-- else block")
                lineItems = []
                this.state.lineItems.map((lineItem) => {
                    //console.log("lineitem employee erro-- loop")
                    //console.log('lineItem.Employee.key', lineItem.Employee.key)
                    if (lineItem.Employee.key === "") {
                        employeeMandatoryAtLineItemError = "Employee not selected at line item level"
                        //console.log("lineitem employee erro")
                    }
                    //lineItems.push(lineItem)
                })
                //this.setState({lineItems:lineItems})
                /// console.log(this.state.lineItems)

            }
        }
        if (!this.state.selectedCustomer) {
            //check if any trnasction is of type wallet recharge 
            if (this.state.lineItems.filter((lineItem) => lineItem.Product.ProductTypeID === 4).length > 0) {
                customerError = "Please select customer"
            }
        }
        if (this.state.lineItems.length === 0) {
            noLineItemExistError = "No line item"
        }
        if (customerError !== "" || employeeMandatoryAtTransactionError !== ""
            || employeeMandatoryAtLineItemError !== "" || noLineItemExistError !== "") {
            this.setState({
                showError: true,
                customerError: customerError,
                employeeMandatoryAtTransactionError: employeeMandatoryAtTransactionError,
                employeeMandatoryAtLineItemError: employeeMandatoryAtLineItemError, noLineItemExistError: noLineItemExistError
            })
            return
        }

        let lineItems = this.state.lineItems
        let employee = {}
        //console.log('this.state.transactionLevelEmployeeSelected', this.state.transactionLevelEmployeeSelected)
        if (this.state.transactionLevelEmployeeSelected) {
            if (this.state.transactionLevelEmployeeSelected.hasOwnProperty("key")) {
                employee = {
                    key: this.state.transactionLevelEmployeeSelected.key,
                    EmployeeName: this.state.transactionLevelEmployeeSelected.EmployeeFirstName + ' ' + this.state.transactionLevelEmployeeSelected.EmployeeLastName,
                    EmployeeFirstName: this.state.transactionLevelEmployeeSelected.EmployeeFirstName,
                    EmployeeLastName: this.state.transactionLevelEmployeeSelected.EmployeeLastName,
                    EmployeeCode: this.state.transactionLevelEmployeeSelected.EmployeeCode
                }
                lineItems.map((l) => {
                    l.Employee = employee
                })
            }
        }
        let billingAddress = this.state.selectedCustomerAddress
        let sale = {
            TransactionType: this.state.transactionType,
            Customer: this.state.selectedCustomer,
            RegistrationID: this.props.user.user.RegistrationID,
            CashRegister: this.props.user.cashRegister,
            Store: this.props.user.store,
            LineItems: lineItems,
            BillQty: this.state.billQty,
            BillSubTotal: this.state.billSubTotal,
            BillDiscount: this.state.billDiscount,
            BillTaxAmount: this.state.billTaxAmount,
            BillAmount: this.state.billAmount,
            RoundOff: this.state.roundOff,
            Customer: this.state.selectedCustomer,
            BillBalanceAmount: 0,
            BillPaidAmount: 0,
            BillChangeDue: 0,
            Payments: null,
            ParentTransactionNo: this.state.parentTransactionNo,
            ParentTransactionKey: this.state.parentTransactionKey,
            Employee: employee,
            Notes: this.state.notes,
            TaxSummaryList: this.state.taxSummaryList,
            PlaceOfSupply: this.state.placeOfSupply,
            BillingAddress: billingAddress,
            DeliveryCustomer: this.state.deliveryCustomer,
            DeliveryCustomerAddress: this.state.deliveryCustomerAddress,
            DeliveryDate: this.state.deliveryDate,
            DeliveryTime: this.state.deliveryTime,
            DeliveryNotes: this.state.deliveryNotes,
            BackEndUpdate: false
            //key:this.state.key
        }
        if (this.props.registration.registration.KOT) {
            sale.KOTType = this.state.KOTType
        }

        if (this.props.location.state.type === actionTypes.SALE_NEW) {
            let transactionNo = ""
            this.state.transactionType.NumberingFormat.map((parameters) => {
                let para = ""
                if (parameters.Option === "text") {
                    para = parameters.Value
                }
                else if (parameters.Option == "storecode") {
                    para = this.props.user.store.StoreCode
                }
                else if (parameters.Option == "locationcode") {
                    para = this.props.user.cashRegister.LocationCode
                }
                else if (parameters.Option == "cashregistercode") {
                    para = this.props.user.cashRegister.CashRegisterCode
                }
                else if (parameters.Option == "calendardmonthyear") {
                    let d = new Date();
                    let a = d.getFullYear() + ""
                    para = d.getMonth() + a.substring(2, 4)
                    if (para.length < 3) {
                        para = "0" + para
                    }
                }
                else if (parameters.Option === "fiscalyear") {
                    let d = new Date();
                    para = d.getFullYear() + ""
                }
                else if (parameters.Option === "calendarmonth") {
                    let d = new Date();
                    let a = d.getMonth() + ""
                    if (a.length < 2) {
                        para = "0" + a
                    }
                }
                else if (parameters.Option === "calendaryear") {
                    let d = new Date();
                    para = d.getFullYear() + ""
                }
                transactionNo = transactionNo + para + parameters.Separator
            })
            sale.TransactionNo = transactionNo
            if (this.state.deliveryCustomer) {
                sale.DeliveryStatus = "Pending"
            }
        }
        else {
            sale.TransactionNo = this.state.editTransactionNo
            sale.TransactionDate = this.state.editTransactionDate
        }
        // console.log('sale', sale)
        //console.log('this.state.key' ,this.state.key)
        this.setState({ sale: sale, showPayment: true, showInvoice: false })
    }

    onDeliveryClicked() {
        let deliveryCustomer = null
        let deliveryCustomerAddress = null
        if (!this.state.deliveryCustomer && this.state.selectedCustomer) {
            deliveryCustomer = this.state.selectedCustomer
            if (this.state.selectedCustomerAddress) {
                deliveryCustomerAddress = this.state.selectedCustomerAddress
            }
        }
        else {
            deliveryCustomer = this.state.deliveryCustomer
            deliveryCustomerAddress = this.state.deliveryCustomerAddress
        }
        this.setState({
            showDelivery: true,
            deliveryCustomer: deliveryCustomer,
            deliveryCustomerAddress: deliveryCustomerAddress
        })
    }

    onSave() {

        this.props.history.push({ pathname: "/home/sales/sales/", state: { transactionType: this.state.transactionType, type: actionTypes.SALE_NEW } })
        return

    }

    onDiscountClicked() {
        if (this.state.lineItems.length === 0) {
            this.setState({
                showError: true,
                noLineItemExistError: "No line item"
            })
            return
        }
        this.setState({ showDiscountModal: true })
    }

    shortcutProductSearch() {
        //  this.refProductSearch.getInstance().focus() 
    }

    shortcutProductScan() {
    }

    shortcutNewProduct() {
        this.setState({ showAddProduct: true, showInvoice: false })
    }

    shortcutNewCustomer() {
        this.setState({ showAddCustomer: true, showInvoice: false })
    }

    shortcutSearchCustomer() {
        // this.refs.customerSearch.focus()
    }

    shortcutPaymentOption1() {
        this.onPaymentClick()
    }

    shortcutPaymentOption2() {
        this.onPaymentClick()
    }

    shortcutPaymentOption3() {
        this.onPaymentClick()
    }

    shortcutDiscount() {
        this.onDiscountClicked()

    }

    shortcutHold() {
        this.onParkSaleClicked()
    }

    test() {
        alert('save')
    }

    async onEnteringCashRegisterOpeningBalance(openingBalance) {
        let cashRegisterClosing = {
            CashRegisterID: this.state.selectedCashRegister.key,
            CashRegisterCode: this.state.selectedCashRegister.CashRegisterCode,
            CashRegisterName: this.state.selectedCashRegister.CashRegisterName,
            StoreID: this.props.user.store.key,
            StoreCode: this.props.user.store.StoreCode,
            StoreName: this.props.user.store.StoreName,
            RegistrationID: this.props.user.user.RegistrationID,
            OpeningBalance: openingBalance,
            OpeningTime: new Date(),
            OpeningByUserID: this.props.user.user.UID,
            OpeningByUserName: this.props.user.user.Name,
            Status: "Open"
        }
        let res = await this.props.openCashRegister(cashRegisterClosing)
        // console.log('cashRegisterClosingobj', cashRegisterClosingobj)
        let cashRegisterClosingobj = await this.props.getCashRegisterOpeningDetails(res.CashRegisterID)
        // console.log('cashRegisterClosing1', cashRegisterClosing1)

        if (!cashRegisterClosingobj) {
            alert('System is experiencing problem opning cash register. Pls try again or contact support')
            return
        }
        this.props.setCashRegister(this.state.selectedCashRegister)
        this.props.setCashRegisterClosing(cashRegisterClosingobj)
        this.setState({ showCashRegisterOpening: false, showCashRegisterSelection: false, cashRegisterClosing: cashRegisterClosingobj })
    }

    async onCashRegisterSelection(selectedCashRegister) {
        //check if cash regiter closing enabled 
        let cashRegisterClosingEnbaled = false
        if (this.props.user.store.hasOwnProperty("CashRegisterClosing")) {
            cashRegisterClosingEnbaled = this.props.user.store.CashRegisterClosing
        }
        if (cashRegisterClosingEnbaled) {
            //check if cash register is already opened, if not then ask for opening balance
            let cashRegisterClosing = await this.props.getCashRegisterOpeningDetails(selectedCashRegister.key)
            //    console.log('cashRegisterClosing',cashRegisterClosing)
            if (cashRegisterClosing) {//open cash register found
                this.props.setCashRegisterClosing(cashRegisterClosing)
                this.props.setCashRegister(selectedCashRegister)
                this.setState({ showCashRegisterOpening: false, showCashRegisterSelection: false, cashRegisterClosing: cashRegisterClosing })
            }
            else {
                //show option to enter opening balance
                this.setState({
                    selectedCashRegister: selectedCashRegister,
                    showCashRegisterOpening: true,
                    showCashRegisterSelection: false,
                    cashRegisterClosing: null
                })
            }
        }
        else {
            this.props.setCashRegister(selectedCashRegister)
            this.setState({
                showCashRegisterOpening: false,
                showCashRegisterSelection: false
            })
        }
    }

    async onApplyRemoveMembershipDiscount(applyMembershipDiscount, applicableMembership) {
        // console.log('applicableMembership',applicableMembership)
        let overallMembershipDiscount = false
        if (applicableMembership.hasOwnProperty("DiscountApplied")) {
            overallMembershipDiscount = applicableMembership.DiscountApplied
        }
        let lineItems = []
        this.state.lineItems.map((l) => {
            let lineItem = l
            // console.log('onApplyRemoveMembershipDiscount - lineItem',lineItem)
            this.applyRemoveMembershipDiscountAtLineItem(lineItem,
                applyMembershipDiscount,
                overallMembershipDiscount, applicableMembership)
            lineItems.push(lineItem)
        })

        await this.calculateBillTotal(lineItems)
        if (applyMembershipDiscount) {
            this.setState({
                lineItems: lineItems,
                applyMembershipDiscount: applyMembershipDiscount,
                showDiscountMembership: true,
                showDiscountTransaction: false,
                showDiscountOffers: false,
                discountOptionSelected: "membership"
            })
        }
        else {
            this.setState({
                lineItems: lineItems,
                applyMembershipDiscount: applyMembershipDiscount,
                showDiscountMembership: true,
                showDiscountTransaction: true,
                showDiscountOffers: true,
                discountOptionSelected: "membership"
            })
        }
    }

    applyRemoveMembershipDiscountAtLineItem(lineItem, applyMembershipDiscount, overallMembershipDiscount, applicableMembership) {
        let processLineItem = true
        if (lineItem.hasOwnProperty('Discounts')) {
            if (lineItem.Discounts.filter(f => f.DiscountType.toLowerCase() !== "membership").length > 0) {
                processLineItem = false
            }
        }
        if (overallMembershipDiscount && processLineItem) {
            lineItem.DiscountPer = Number(applyMembershipDiscount ? applicableMembership.Discount : 0)
            lineItem.DiscountAmount = 0
            if (applyMembershipDiscount) {
                lineItem = this.onLineItemChange(-2, lineItem, this.state.placeOfSupply, "Membership", applicableMembership.key, applicableMembership.Name, true)
            }
            else {
                lineItem.Discounts = []
                lineItem = this.onLineItemChange(-2, lineItem, this.state.placeOfSupply, "", "", "", true)
            }

        }
        else if (processLineItem) {
            if (applyMembershipDiscount) {
                applicableMembership.Products.filter((p) => p.ProductID === lineItem.Product.key).map((e) => {
                    // console.log('e', e)
                    let discountPercentage = e.hasOwnProperty('DiscountPercentage') ? Number(e.DiscountPercentage) : 0
                    let discountAmount = e.hasOwnProperty('DiscountAmount') ? Number(e.DiscountAmount) : 0
                    if (discountPercentage > 0) {
                        // console.log('discountPercentage', discountPercentage)
                        lineItem.DiscountPer = discountPercentage
                        lineItem.DiscountAmount = 0
                    }
                    else if (discountAmount > 0) {
                        // console.log('discountAmount', discountAmount)
                        lineItem.DiscountPer = Number(((Number(discountAmount) * 100) / Number(lineItem.RetailPrice)).toFixed(3))
                        lineItem.DiscountAmount = 0
                    }
                    else {
                        // console.log('e.RetailPrice', e.RetailPrice)
                        lineItem.RetailPrice = Number(e.RetailPrice)
                    }
                })
                // console.log('lineItem',lineItem)
                lineItem = this.onLineItemChange(-2, lineItem, this.state.placeOfSupply,
                    "Membership",
                    applicableMembership.key,
                    applicableMembership.Name,
                    true)
            }
            else {
                lineItem.RetailPrice = lineItem.Product.RetailPrice
                lineItem.DiscountPer = 0
                lineItem.DiscountAmount = 0
                lineItem.Discounts = []
                lineItem = this.onLineItemChange(-2, lineItem, this.state.placeOfSupply, "", "", "", true)
            }
        }
    }

    onProductBarCodeSearch(evt) {
        if (evt.key === 'Enter') {
            var productList = this.props.config.Products.filter((e) => e.SKU === this.state.queryText)
            let e = productList[0]
            if (e) {
                if (e.hasOwnProperty('AccessibleToSelectedStores')) {
                    if (e.AccessibleToSelectedStores) {
                        let storePrice = e.StoreSpecificPrices.filter(f => f.StoreID === this.props.user.store.key)
                        if (storePrice.length > 0) {
                            e.RetailPrice = storePrice[0].RetailPrice
                            e.SupplyPrice = storePrice[0].SupplyPrice
                            e.Markup = storePrice[0].Markup
                            e.MRP = storePrice[0].MRP
                            e.DiscountAmount = storePrice[0].hasOwnProperty('DiscountAmount') ? storePrice[0].DiscountAmount : 0
                            e.DiscountPercentage = storePrice[0].hasOwnProperty('DiscountPercentage') ? storePrice[0].DiscountPercentage : 0
                            e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty('WalletTopUpSameAsRetailPrice') ? storePrice[0].WalletTopUpSameAsRetailPrice : false
                            e.WalletTopUpAmount = storePrice[0].hasOwnProperty('WalletTopUpAmount') ? storePrice[0].WalletTopUpAmount : 0
                            e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty('EditWalletTopUpAmount') ? storePrice[0].EditWalletTopUpAmount : false
                        }
                    }
                }
                if (e.hasOwnProperty('StoreSpecificPricing')) {
                    if (e.StoreSpecificPricing) {
                        let storePrice = e.StoreSpecificPrices.filter(f => f.StoreID === this.props.user.store.key)

                        if (storePrice.length > 0) {
                            e.RetailPrice = storePrice[0].RetailPrice
                            e.SupplyPrice = storePrice[0].SupplyPrice
                            e.Markup = storePrice[0].Markup
                            e.DiscountAmount = storePrice[0].hasOwnProperty('DiscountAmount') ? storePrice[0].DiscountAmount : 0
                            e.DiscountPercentage = storePrice[0].hasOwnProperty('DiscountPercentage') ? storePrice[0].DiscountPercentage : 0
                            e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty('WalletTopUpSameAsRetailPrice') ? storePrice[0].WalletTopUpSameAsRetailPrice : false
                            e.WalletTopUpAmount = storePrice[0].hasOwnProperty('WalletTopUpAmount') ? storePrice[0].WalletTopUpAmount : 0
                            e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty('EditWalletTopUpAmount') ? storePrice[0].EditWalletTopUpAmount : false
                            e.MRP = storePrice[0].MRP
                        }
                    }
                }
                this.onProductSelected(e)
            }
            else {
                this.props.getInwardUniqueList(0, 100, this.props.user.store.key, "", this.state.queryText).then((result) => {

                    if (result && result.length > 0) {

                        let productID = result[0].ProductID
                        let uniqueList = []
                        //check if thi product is already added 
                        if (this.state.lineItems.filter(f => f.Product.key === productID).length > 0) {
                            let l = this.state.lineItems.filter(f => f.Product.key === productID)[0]
                            if (l.hasOwnProperty('UniqueNoList') && l.UniqueNoList.filter(f => f.UniqueNo1 === result[0].UniqueNo1
                                && f.UniqueNo2 === result[0].UniqueNo2
                                && f.UniqueNo3 === result[0].UniqueNo3
                                && f.UniqueNo3 === result[0].UniqueNo4).length > 0) {
                                alert('Unique no already added to transaction')
                                return
                            }
                            else {//
                                uniqueList = l.UniqueNoList
                            }
                        }
                        var productList = this.props.config.Products.filter((e) => e.key === productID)

                        var e = productList[0];

                        if (e.hasOwnProperty('AccessibleToSelectedStores')) {
                            if (e.AccessibleToSelectedStores) {
                                let storePrice = e.StoreSpecificPrices.filter(f => f.StoreID === this.props.user.store.key)
                                if (storePrice.length > 0) {
                                    e.RetailPrice = storePrice[0].RetailPrice
                                    e.SupplyPrice = storePrice[0].SupplyPrice
                                    e.Markup = storePrice[0].Markup
                                    e.MRP = storePrice[0].MRP
                                    e.DiscountAmount = storePrice[0].hasOwnProperty('DiscountAmount') ? storePrice[0].DiscountAmount : 0
                                    e.DiscountPercentage = storePrice[0].hasOwnProperty('DiscountPercentage') ? storePrice[0].DiscountPercentage : 0
                                    e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty('WalletTopUpSameAsRetailPrice') ? storePrice[0].WalletTopUpSameAsRetailPrice : false
                                    e.WalletTopUpAmount = storePrice[0].hasOwnProperty('WalletTopUpAmount') ? storePrice[0].WalletTopUpAmount : 0
                                    e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty('EditWalletTopUpAmount') ? storePrice[0].EditWalletTopUpAmount : false
                                }
                            }
                        }
                        if (e.hasOwnProperty('StoreSpecificPricing')) {
                            if (e.StoreSpecificPricing) {
                                let storePrice = e.StoreSpecificPrices.filter(f => f.StoreID === this.props.user.store.key)

                                if (storePrice.length > 0) {
                                    e.RetailPrice = storePrice[0].RetailPrice
                                    e.SupplyPrice = storePrice[0].SupplyPrice
                                    e.Markup = storePrice[0].Markup
                                    e.DiscountAmount = storePrice[0].hasOwnProperty('DiscountAmount') ? storePrice[0].DiscountAmount : 0
                                    e.DiscountPercentage = storePrice[0].hasOwnProperty('DiscountPercentage') ? storePrice[0].DiscountPercentage : 0
                                    e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty('WalletTopUpSameAsRetailPrice') ? storePrice[0].WalletTopUpSameAsRetailPrice : false
                                    e.WalletTopUpAmount = storePrice[0].hasOwnProperty('WalletTopUpAmount') ? storePrice[0].WalletTopUpAmount : 0
                                    e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty('EditWalletTopUpAmount') ? storePrice[0].EditWalletTopUpAmount : false
                                    e.MRP = storePrice[0].MRP
                                }
                            }
                        }

                        uniqueList.push(result[0])
                        // console.log('uniqueList',uniqueList)
                        this.productSelected(e, uniqueList)
                    }
                    else {
                        this.setState({ showAddProduct: true, showInvoice: false })
                    }
                })
            }
            this.setState({ queryText: '' });
        }
    }

    onShowDiscountOptions() {
        let showDiscountMembership = false
        let showDiscountTransaction = false
        let showDiscountOffers = false
        let discountOptionSelected = ""
        if (this.state.selectedCustomer &&
            this.state.selectedCustomer.Membership
            && this.state.selectedCustomer.Membership.hasOwnProperty('MembershipID')) {
            showDiscountMembership = true
            discountOptionSelected = "membership"
        }
        if (!this.state.applyMembershipDiscount && this.state.offersApplied.length === 0) {
            showDiscountTransaction = true
            discountOptionSelected = discountOptionSelected.trim() === "" ? "transaction" : discountOptionSelected
        }
        if (this.state.offers.length > 0 && !this.state.applyMembershipDiscount) {
            showDiscountOffers = true
            discountOptionSelected = discountOptionSelected.trim() === "" ? "offer" : discountOptionSelected
        }
        let offersPopupTabSelected = "applied"
        if (this.state.offersApplied.length > 0) {
            offersPopupTabSelected = "applied"
        }
        else if (this.state.offersToApply.length > 0) {
            offersPopupTabSelected = "applicable"
        } else if (this.state.offers.length > 0) {
            offersPopupTabSelected = "all"
        }
        this.setState({
            showDiscountOptions: true,
            discountOptionSelected: discountOptionSelected,
            offersPopupTabSelected: offersPopupTabSelected,
            showDiscountMembership: showDiscountMembership,
            showDiscountTransaction: showDiscountTransaction,
            showDiscountOffers: showDiscountOffers
        })
    }

    async onTransactionTypeSelected(selectedTransactionType) {
        //compute 
        let lineItems = []
        this.state.lineItems.map((l) => {
            let lineItem = this.onLineItemChange(-1, l,
                this.state.placeOfSupply, "", "", "", "", selectedTransactionType)
            lineItems.push(lineItem)
        })



        await this.calculateBillTotal(lineItems)
        console.log('selectedTransactionType', selectedTransactionType)
        this.setState({ lineItems: lineItems, transactionType: selectedTransactionType, showTransactionTypes: false })
        // console.log()
        // this.onLineItemChangesDone()

        // if(this.state.transactionType.TransactionType.toLowerCase() !== selectedTransactionType.TransactionType.toLowerCase())
        // {

        // await this.calculateBillTotal(lineItems)
        // this.setState({transactionType:selectedTransactionType,
        //     lineItems:lineItems,
        //     showTransactionTypes:false})
        // }
    }

    render() {

        const handlers = {
            productSearch: this.shortcutProductSearch,
            productScan: this.shortcutProductScan,
            newProduct: this.shortcutNewProduct,
            newCustomer: this.shortcutNewCustomer,
            searchCustomer: this.shortcutSearchCustomer,
            paymentOption1: this.shortcutPaymentOption1,
            paymentOption2: this.shortcutPaymentOption2,
            paymentOption3: this.shortcutPaymentOption3,
            discount: this.shortcutDiscount,
            hold: this.shortcutHold,
            save: this.test,
            // lineItemQtyChange:this.shortcutLineItemQtyChange,
            // lineItemPriceChange:this.shortcutLineItemPriceChange,
            // lineItemDiscount:'ctrl+d',
            // lineItemTax:'ctrl+t',
            // lineItemEmployeeTagging:'ctrl+e',
            // lineItemDelete:'ctrl+r'
        };

        return <div>
            <HotKeys
                keyMap={keyMap}
                handlers={handlers}>
                {this.state.showInvoice &&
                    <div>
                        {(!this.props.user.cashRegister && this.state.showCashRegisterSelection) && <div>
                            <section className="content">
                                <div className="login-box">
                                    <div className="form-group">
                                        <label >Please select cash register</label>
                                        <Typeahead
                                            autoFocus={true}
                                            allowNew={false}
                                            multiple={false}
                                            options={this.state.cashRegisters}
                                            labelKey="CashRegisterName"
                                            placeholder="Select cash register"
                                            filterBy={['CashRegisterCode', 'CashRegisterName']}
                                            bsSize={'large'}
                                            dropup={false}
                                            highlightOnlyResult={true}
                                            //selected={this.state.selectedCashRegister}
                                            onChange={(selected) => {
                                                this.setState({ selectedCashRegister: selected[0] })
                                            }}
                                            renderMenuItemChildren={(option, props) => (
                                                <div>
                                                    <div><b>{option.CashRegisterCode} - {option.CashRegisterName} </b></div>
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <button type="submit"
                                        className="btn btn-md btn-success btn-flat pull-right"
                                        onClick={() => this.onCashRegisterSelection(this.state.selectedCashRegister)}>OK
                                </button>
                                </div>
                            </section>
                        </div>}
                        {this.state.showCashRegisterOpening && <React.Fragment>
                            <section className="content">
                                <div className="login-box">
                                    <div className="form-group">
                                        <label >Please enter opening balance</label>
                                        <input type="number" className="form-control"
                                            onChange={(evt) => this.setState({ cashRegisterOpeningBalance: evt.target.value })}
                                            value={this.state.cashRegisterOpeningBalance}
                                        />
                                    </div>
                                    <button type="submit"
                                        className="btn btn-md btn-success btn-flat pull-right"
                                        onClick={() => this.onEnteringCashRegisterOpeningBalance(this.state.cashRegisterOpeningBalance)}>OK
                                </button>
                                </div>

                            </section>
                        </React.Fragment>}
                        {this.props.user.cashRegister && <div className="main-container">
                            <div className="content-wrapper">
                                <section className="content">
                                    <div className="row">
                                        <div className="col-md-8">
                                            <div className="product-tab">
                                                <div className="row" style={{ padding: "10px", paddingBottom: "0px" }}>
                                                    <div className="col-md-12">
                                                        <div className="input-group">
                                                            {!this.props.registration.registration.KOT && <div className="input-group-btn">
                                                                {this.state.type === actionTypes.SALE_NEW && <button type="button"
                                                                    onClick={() => this.setState({ showTransactionTypes: true })}
                                                                    className={this.state.transactionType.TransactionType.toLowerCase() === "sales return" ? "btn btn-warning btn-lg btn-flat" : "btn btn-success btn-lg btn-flat"} >
                                                                    New {this.state.transactionType.TransactionType}
                                                                </button>}
                                                                {this.state.type === actionTypes.SALE_EDIT && <button type="button"
                                                                    className="btn btn-info btn-lg btn-flat" >
                                                                    Edit {this.state.transactionType.TransactionType}
                                                                </button>}
                                                            </div>}
                                                            {this.props.registration.registration.KOT && <div className="input-group-btn">
                                                                <button type="button" style={{ width: "120px" }}
                                                                    onClick={() => this.setState({ KOTType: "Dine In" })}
                                                                    className={this.state.KOTType.toLowerCase() === "dine in" ? "btn btn-success btn-lg btn-flat" : "btn btn-default btn-lg btn-flat"}
                                                                >
                                                                    DINE IN
                                                    </button>
                                                                <button type="button" style={{ width: "120px" }}
                                                                    onClick={() => this.setState({ KOTType: "Delivery" })}
                                                                    className={this.state.KOTType.toLowerCase() === "delivery" ? "btn btn-success btn-lg btn-flat" : "btn btn-default btn-lg btn-flat"}
                                                                >
                                                                    DELIVERY
                                                    </button>
                                                                <button type="button"
                                                                    onClick={() => this.setState({ KOTType: "Takeout" })}
                                                                    className={this.state.KOTType.toLowerCase() === "takeout" ? "btn btn-success btn-lg btn-flat" : "btn btn-default btn-lg btn-flat"}
                                                                    style={{ width: "120px" }}>
                                                                    TAKEOUT
                                                    </button>

                                                            </div>
                                                            }

                                                            {!this.state.toggleSearch && <AsyncTypeahead
                                                                // ref={this.refProductSearch}
                                                                // ref={(ref) => this.refProductSearch = ref}
                                                                autoFocus={true}
                                                                allowNew={false}
                                                                isLoading={this.state.isProductTypeAheadLoading}
                                                                multiple={false}
                                                                options={this.state.productsTypeAheadOptions}
                                                                labelKey="Name"
                                                                minLength={3}
                                                                onSearch={this.onProductTypeAheadSearch}
                                                                placeholder="Start typing to search products"
                                                                filterBy={['Name', 'SKU', 'BarCode']}
                                                                bsSize={'large'}
                                                                autoFocus={true}
                                                                dropup={false}
                                                                highlightOnlyResult={true}
                                                                //onInputChange={(option) => console.log('option', option)}
                                                                selected={this.state.productsTypeAheadSelected}
                                                                onChange={(selected) => {
                                                                    this.onProductSelected(selected[0])
                                                                    this.setState({ productsTypeAheadSelected: [] })
                                                                }}
                                                                renderMenuItemChildren={(option, props) => (
                                                                    <div>
                                                                        <div><b>{option.Name}</b></div>
                                                                        <div>{option.SKU}</div>
                                                                        <div>{this.props.user.store.Currency}  {option.RetailPrice}</div>
                                                                    </div>
                                                                )}
                                                            />}

                                                            {this.state.toggleSearch && <input type="text"
                                                                onChange={(evt) => {
                                                                    this.setState({ queryText: evt.target.value })
                                                                }}
                                                                value={this.state.queryText}
                                                                onKeyPress={this.onProductBarCodeSearch}
                                                                placeholder="Start scanning to search products"
                                                                className="form-control" style={{ height: "46px", fontSize: "18px" }} />}
                                                            <div className="input-group-btn">
                                                                <button type="button"
                                                                    onClick={() => this.setState({ toggleSearch: !this.state.toggleSearch })}
                                                                    className="btn btn-default btn-lg btn-flat" style={{ height: "46px" }}>
                                                                    <i className="fa fa-exchange"></i>
                                                                </button>
                                                            </div>
                                                            {this.state.transactionType && <span className="input-group-addon"
                                                                style={{ backgroundColor: "#f4f4f4", display: this.state.transactionType.TransactionType.toLowerCase() === "sales return" ? "" : "none" }}
                                                                onClick={() => this.setState({ negativeQty: !this.state.negativeQty })}
                                                            >
                                                                <input type="checkbox"
                                                                    onChange={() => this.setState({ negativeQty: !this.state.negativeQty })}
                                                                    checked={this.state.negativeQty} />Negative Qty
                                                </span>}

                                                            <div className="input-group-btn">
                                                                <button type="button" onClick={() => this.setState({ showAddProduct: true, showInvoice: false })}
                                                                    className="btn btn-default btn-lg btn-flat">
                                                                    <i className="glyphicon glyphicon-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="col-md-2" style={{ padding: "5px" }}>
                                                            <div className="item-list-scroll scroll2">
                                                                {this.props.config.ProductCategories.filter((e) => !e.IsDeleted).map((productCategory, index) =>
                                                                    <a key={index}
                                                                        className={this.state.selectedCategoryID === productCategory.key ? "btn btn-flat btn-app category-selection-btn-selected" : "btn btn-flat btn-app category-selection-btn"}
                                                                        style={{ backgroundColor: productCategory.Color }}
                                                                        onClick={() => this.setState({ selectedCategoryID: productCategory.key, selectedCategory: productCategory.Name, selectedSubCategoryID: '', selectedSubCategory: '' })}>
                                                                        {!productCategory.Image && <div>
                                                                            <div className="row product-select-btn-text-full-product-name">
                                                                                {productCategory.Name}
                                                                            </div>

                                                                        </div>}
                                                                        {productCategory.Image && <div>
                                                                            <div className="row" >
                                                                                <img className="photo" src={productCategory.Image.Preview} />
                                                                            </div>
                                                                            <div className="row product-select-btn-text-name">
                                                                                {productCategory.Name}
                                                                            </div>
                                                                        </div>}
                                                                    </a>)}
                                                            </div>
                                                        </div>

                                                        <div className="col-md-2" style={{ padding: "5px" }}>
                                                            <div className="item-list-scroll scroll2">
                                                                {this.props.config.ProductSubCategories.filter((e) => e.ParentID === this.state.selectedCategoryID && !e.IsDeleted).map((productSubCategory, index) =>
                                                                    <a key={index} className={this.state.selectedSubCategoryID === productSubCategory.key ? "btn  btn-flat btn-app category-selection-btn-selected" : "btn  btn-flat btn-app category-selection-btn"}
                                                                        style={{ backgroundColor: productSubCategory.Color }}
                                                                        onClick={() => this.setState({ selectedSubCategoryID: productSubCategory.key, selectedSubCategory: productSubCategory.Name })}>
                                                                        {!productSubCategory.Image && <div>
                                                                            <div className="row product-select-btn-text-full-product-name">
                                                                                {productSubCategory.Name}
                                                                            </div>
                                                                        </div>}
                                                                        {productSubCategory.Image && <div>
                                                                            <div className="row" >
                                                                                <img className="photo" src={productSubCategory.Image.Preview} />
                                                                            </div>
                                                                            <div className="row product-select-btn-text-name">
                                                                                {productSubCategory.Name}
                                                                            </div>
                                                                        </div>}
                                                                    </a>)}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-8" style={{ padding: "5px" }}>

                                                            <div className="item-list-scroll scroll2">
                                                                {this.props.config.Products.filter(e => {
                                                                    // console.log(this.state.selectedCategoryID)
                                                                    // console.log(this.state.selectedSubCategoryID)
                                                                    if (e.CategoryID === this.state.selectedCategoryID && e.SubCategoryID === this.state.selectedSubCategoryID) {

                                                                        if (e.hasOwnProperty('AccessibleToSelectedStores')) {
                                                                            if (e.AccessibleToSelectedStores) {
                                                                                let storePrice = e.StoreSpecificPrices.filter(f => f.StoreID === this.props.user.store.key)
                                                                                if (storePrice.length > 0) {
                                                                                    e.RetailPrice = storePrice[0].RetailPrice
                                                                                    e.SupplyPrice = storePrice[0].SupplyPrice
                                                                                    e.Markup = storePrice[0].Markup
                                                                                    e.DiscountAmount = storePrice[0].hasOwnProperty('DiscountAmount') ? storePrice[0].DiscountAmount : 0
                                                                                    e.DiscountPercentage = storePrice[0].hasOwnProperty('DiscountPercentage') ? storePrice[0].DiscountPercentage : 0
                                                                                    e.MRP = storePrice[0].MRP
                                                                                    e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty('WalletTopUpSameAsRetailPrice') ? storePrice[0].WalletTopUpSameAsRetailPrice : false
                                                                                    e.WalletTopUpAmount = storePrice[0].hasOwnProperty('WalletTopUpAmount') ? storePrice[0].WalletTopUpAmount : 0
                                                                                    e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty('EditWalletTopUpAmount') ? storePrice[0].EditWalletTopUpAmount : false
                                                                                    return true
                                                                                }
                                                                                else {
                                                                                    return false
                                                                                }
                                                                            }
                                                                        }
                                                                        if (e.hasOwnProperty('StoreSpecificPricing')) {
                                                                            // console.log(e)
                                                                            if (e.StoreSpecificPricing) {
                                                                                let storePrice = e.StoreSpecificPrices.filter(f => f.StoreID === this.props.user.store.key)
                                                                                if (storePrice.length > 0) {
                                                                                    e.RetailPrice = storePrice[0].RetailPrice
                                                                                    e.SupplyPrice = storePrice[0].SupplyPrice
                                                                                    e.Markup = storePrice[0].Markup
                                                                                    e.DiscountAmount = storePrice[0].hasOwnProperty('DiscountAmount') ? storePrice[0].DiscountAmount : 0
                                                                                    e.DiscountPercentage = storePrice[0].hasOwnProperty('DiscountPercentage') ? storePrice[0].DiscountPercentage : 0
                                                                                    e.MRP = storePrice[0].MRP
                                                                                    e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty('WalletTopUpSameAsRetailPrice') ? storePrice[0].WalletTopUpSameAsRetailPrice : false
                                                                                    e.WalletTopUpAmount = storePrice[0].hasOwnProperty('WalletTopUpAmount') ? storePrice[0].WalletTopUpAmount : 0
                                                                                    e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty('EditWalletTopUpAmount') ? storePrice[0].EditWalletTopUpAmount : false
                                                                                }
                                                                            }
                                                                        }
                                                                        return true
                                                                    }
                                                                    return false
                                                                }).map((product, index) =>
                                                                    <a key={index} className="btn btn-app btn-flat product-selection-btn" onClick={() => this.onProductSelected(product)}>
                                                                        {product.Images.length === 0 && <div>
                                                                            <div className="row product-select-btn-text-full-product-name">
                                                                                {product.Name}
                                                                            </div>
                                                                            <div className="row product-select-btn-text-full-product-price">
                                                                                {this.props.user.store.Currency} {product.RetailPrice}/-
                                                        </div>
                                                                        </div>}
                                                                        {product.Images.length !== 0 && <div>
                                                                            <div className="row" >
                                                                                <img className="photo" src={product.Images[0].ImagePath} alt={product.Images[0].ImageName} />
                                                                            </div>
                                                                            <div className="row product-select-btn-text-name">
                                                                                {product.Name}
                                                                            </div>
                                                                            <div className="row product-select-btn-text-price"> {this.props.user.store.Currency} {product.RetailPrice}/-
                                                        </div>
                                                                        </div>}
                                                                    </a>)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="item-list">
                                                <div className="row" style={{ padding: "10px", paddingBottom: "0px" }}>
                                                    <div className="col-md-12">
                                                        <CustomerSearch
                                                            {...this.props}
                                                            customer={this.state.selectedCustomer}
                                                            error={this.state.customerError}
                                                            onCustomerChange={
                                                                this.onCustomerChange}
                                                            onAddCustomer={(newCustomer) => { this.setState({ showAddCustomer: true, showInvoice: false, newCustomer: newCustomer }) }}
                                                            onCustomerEdit={() => (this.props.config.ConnectionStatus.Status === 'connected' ? this.setState({ showAddCustomer: true, showInvoice: false }) : false)}
                                                            onShowCustomerDashBoard={() => {
                                                                this.setState({ showCustomerDashBoard: true, showInvoice: false })
                                                            }}
                                                        ></CustomerSearch>
                                                    </div>
                                                </div>
                                                {this.state.selectedCustomer && <div className="row" style={{ padding: "0px 10px 0px 10px" }}>
                                                    <div className="col-md-12" >
                                                        <CustomerAddress
                                                            {...this.props}
                                                            customer={this.state.selectedCustomer}
                                                            selectedAddress={this.state.selectedCustomerAddress}
                                                            onAddressChange={(address) => {
                                                                this.setState({ selectedCustomerAddress: address, placeOfSupply: address.State })
                                                                this.onSelectedCustomerAddressChange(address.State)
                                                            }}
                                                        />
                                                    </div>
                                                </div>}
                                                {this.state.transactionType && <div className="row" style={{ padding: "10px", paddingBottom: "0px" }} >
                                                    {this.state.transactionType.EmployeeManadatoryTransactionORLineItemLevel === "transaction" && <div className="col-md-12" >
                                                        {!this.state.transactionLevelEmployeeSelected && <div className={this.state.employeeMandatoryAtTransactionError ? "form-group has-error" : "form-group "} style={{ paddingTop: "0px" }}>
                                                            <Typeahead
                                                                allowNew={false}
                                                                multiple={false}
                                                                options={this.state.employeeList}
                                                                labelKey="EmployeeFirstName"
                                                                placeholder="Select employee"
                                                                filterBy={['EmployeeCode', 'EmployeeFirstName', 'EmployeeLastName']}
                                                                bsSize={'large'}
                                                                dropup={false}
                                                                highlightOnlyResult={true}
                                                                selected={this.state.transactionLevelEmployeeSelected}
                                                                onChange={(selected) => {
                                                                    //console.log('selected', selected)
                                                                    this.setState({ transactionLevelEmployeeSelected: selected[0] })
                                                                }}
                                                                renderMenuItemChildren={(option, props) => (
                                                                    <div>
                                                                        <div><b>{option.EmployeeFirstName} {option.EmployeeLastName}</b></div>
                                                                        <div>{option.EmployeeCode}</div>
                                                                        <div>{option.PhoneNo}</div>
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>}
                                                        {this.state.transactionLevelEmployeeSelected && <div className="input-group">
                                                            <button type="button"
                                                                className="btn btn-default btn-block btn-flat btn-lg">
                                                                {this.state.transactionLevelEmployeeSelected.EmployeeFirstName} {this.state.transactionLevelEmployeeSelected.EmployeeLastName} {this.state.transactionLevelEmployeeSelected.EmployeeCode !== "" ? " (" + this.state.transactionLevelEmployeeSelected.EmployeeCode + ")" : ""}
                                                            </button>
                                                            <div className="input-group-btn">
                                                                <button type="button" className="btn btn-default btn-lg btn-flat"
                                                                    onClick={() => this.setState({ transactionLevelEmployeeSelected: null })}><i className="glyphicon glyphicon-remove" ></i> </button>
                                                            </div>
                                                        </div>}
                                                    </div>}
                                                </div>}
                                                <div className="row" style={{ paddingLeft: "10px", paddingRight: "10px", paddingTop: "10px", paddingBottom: "0px" }}>
                                                    <div className="col-md-12">
                                                        {this.state.transactionType &&
                                                            <div style={{ height: this.state.rightHeight + "vh", overflowY: "scroll", overflowX: "hidden" }}
                                                                className="scroll2">
                                                                <ul className="products-list product-list-in-box " >
                                                                    {this.state.lineItems.map((lineItem, index) =>
                                                                        <li key={index} className="item" >
                                                                            <div className="row">
                                                                                <div className="col-md-10">
                                                                                    <div className="product-info" style={{ marginLeft: "10px" }} onClick={() => this.onLineItemSelected(lineItem, index)}>
                                                                                        <a className="product-title">{lineItem.Product.SKU} - {lineItem.Product.Name}</a>
                                                                                        {/* <span className="product-description">
                                                                                            Qty : {lineItem.Qty}  Total: {lineItem.Amount}
                                                                                        </span> */}
                                                                                        <div>MRP:{lineItem.Product.MRP}</div>
                                                                                        <div>
                                                                                            Price: {lineItem.RetailPrice} &nbsp; Qty: {lineItem.Qty} &nbsp; Dis: {lineItem.DiscountAmount}&nbsp; Tax: {lineItem.TotalTax} &nbsp; Total: {lineItem.Amount}
                                                                                        </div>
                                                                                        {lineItem.hasOwnProperty('Discounts') && <React.Fragment>{lineItem.Discounts.map((m, index) => <span key={index} className="product-description">
                                                                                            {m.DiscountType + ":"} {m.DiscountInfo}   {m.DiscountAmount > 0 ? ", Discount: " + m.DiscountAmount : ""}
                                                                                        </span>
                                                                                        )}</React.Fragment>}
                                                                                        {lineItem.Employee.key !== "" && <span className="product-description">
                                                                                            <i className="fa fa-user" />  {lineItem.Employee.EmployeeFirstName} {lineItem.Employee.EmployeeLastName}
                                                                                        </span>}
                                                                                        {(this.state.transactionType.EmployeeMandatoryChecked
                                                                                            && this.state.transactionType.EmployeeManadatoryTransactionORLineItemLevel === "lineitem"
                                                                                            && lineItem.Employee.key === "") && <span style={{ color: "red" }}>Please select employee</span>}
                                                                                        {lineItem.Notes.trim() !== "" && <span className="product-description">{lineItem.Notes}</span>}
                                                                                    </div>
                                                                                    {(lineItem.Product.hasOwnProperty('InventoryType')
                                                                                        && lineItem.Product.InventoryType.toLowerCase() === "unique no") &&
                                                                                        <button type="submit"
                                                                                            className="btn btn-md btn-default btn-flat"
                                                                                            onClick={() => this.setState({
                                                                                                showUniqueNoInput: true,
                                                                                                selectedLineItem: lineItem,
                                                                                                selectedLineItemIndex: index,
                                                                                                selectedProduct: lineItem.Product
                                                                                            })} >
                                                                                            {lineItem.Product.UniqueLabel.trim() !== "" ? lineItem.Product.UniqueLabel : "Unique No"}
                                                                                        </button>
                                                                                    }
                                                                                </div>
                                                                                <div className="col-md-2">
                                                                                    <a onClick={() => this.onRemoveLineItem(index)}><i className="fa fa-lg fa-trash-o pull-right" style={{ margin: "10px", paddingRight: "20px" }}></i></a>
                                                                                </div>
                                                                            </div>
                                                                        </li>)}
                                                                </ul>
                                                            </div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <footer className="main-footer pad" >
                                {this.state.transactionType && <div className="row">
                                    <div className="col-md-8">
                                        {this.state.key.trim() === '' && <button type="button" className="btn btn-flat btn-app"
                                            disabled={this.state.lineItems.length > 0 ? false : true}
                                            onClick={() => this.onParkSaleClicked()}>
                                            <i className="fa fa-clock-o"></i> Park Sale
  </button>}
                                        {this.state.key.trim() === '' && <button type="button" className="btn btn-flat btn-app"
                                            onClick={() => this.setState({ showParkedSalesModal: true })}>
                                            {this.state.transactionType && <span className="badge bg-green">{this.props.sales.DraftSales.filter(f => f.TransactionType.TransactionType === this.state.transactionType.TransactionType).length}</span>}
                                            <i className="fa fa-mail-forward"></i> Retrieve Sale
  </button>}
                                        {this.state.key.trim() === '' && <button type="button" className="btn btn-flat btn-app "
                                            disabled={this.state.lineItems.length > 0 ? false : true}
                                            onClick={() => this.onDiscardClicked()}>
                                            <i className="fa fa-trash-o"></i> Discard Sale
  </button>}


                                        {this.state.transactionType.DiscountChecked && <button type="button" className="btn btn-flat btn-app pull-right"
                                            disabled={this.state.lineItems.length > 0 ? false : true}
                                            onClick={() => {
                                                this.onShowDiscountOptions()
                                            }}>
                                            <i className="fa" >%</i> Discount
                        </button>}
                                        <button type="button" className="btn btn-flat btn-app pull-right"
                                            disabled={this.state.lineItems.length > 0 ? false : true}
                                            onClick={() => {
                                                // this.refs.txtNotes.focus();
                                                this.setState({ showNotesModal: true })
                                            }}>
                                            {this.state.notes !== "" && <span className="badge bg-green">Notes</span>}
                                            <i className="fa fa-sticky-note-o"></i> Notes
  </button>

                                        {this.props.registration.registration.Delivery && <button type="button" className="btn btn-flat btn-app pull-right"
                                            disabled={this.state.lineItems.length > 0 ? false : true}
                                            onClick={() => this.onDeliveryClicked()}>
                                            <i className="fa fa-truck" ></i> Delivery
                        </button>}

                                    </div>
                                    <div className="col-md-4">
                                        <button type="button"
                                            disabled={this.state.lineItems.length > 0 ? false : true}
                                            className={this.state.type === actionTypes.SALE_NEW ? this.state.transactionType.TransactionType.toLowerCase() !== "sales return" ? "btn btn-lg btn-flat btn-success btn-block pay-btn" : "btn btn-lg btn-flat btn-warning btn-block pay-btn" : this.state.type === actionTypes.SALE_EDIT ? "btn btn-lg btn-info btn-block pay-btn" : "btn btn-lg btn-warning btn-block pay-btn"}
                                            onClick={() => this.onPaymentClick()}>
                                            <div className="pull-left">
                                                <b>{this.state.transactionType.PaymentModeChecked ? "Pay" : "Save"}</b><small >{this.state.billQty} items</small> </div>
                                            <div className="pull-right">
                                                <b>{this.props.user.store.Currency} {this.state.billAmount}</b>
                                            </div>
                                        </button>
                                    </div>
                                </div>}
                            </footer>
                        </div>}
                        {this.props.config.PrintConfigs && <div style={{ display: "none" }}>
                            {this.props.config.PrintConfigs.map(m => <React.Fragment>
                                {m.HeaderImage && m.HeaderImage.Preview.trim() !== "" && <img src={m.HeaderImage.Preview}></img>}
                                {m.FooterImage && m.FooterImage.Preview.trim() !== "" && <img src={m.FooterImage.Preview}></img>}
                            </React.Fragment>)}
                        </div>}
                    </div>}
                <Modal open={this.state.showNotesModal} onClose={() => this.setState({ showNotesModal: false })} center>
                    <div style={{ width: "300px" }}>
                        <h3 style={{ marginTop: "0px" }}>Notes</h3>
                        <div className="box-body">
                            <textarea className="textarea" style={{ width: "100%", height: "200px" }}
                                onChange={(evt) => this.setState({ tempNotes: evt.target.value })}
                                value={this.state.tempNotes}
                            // ref="txtNotes"
                            ></textarea>
                        </div>
                        <div className="box-footer">
                            <button type="submit" className="btn btn-flat btn-md btn-default" onClick={() => this.setState({ showNotesModal: false })} >Cancel</button>
                            <button type="submit" className="btn btn-flat btn-md btn-success pull-right" onClick={() => this.onNotesOKClicked()}>OK</button>
                        </div>
                    </div>
                </Modal>
                <Modal open={this.state.showParkedSalesModal} onClose={() => this.setState({ showParkedSalesModal: false })} center>
                    <div style={{ width: "600px" }}>
                        <h3 style={{ marginTop: "0px" }}>Retrieve Sales</h3>
                        <div className="box-body">
                            {(this.props.sales.DraftSales && this.state.transactionType) && <table className="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th style={{ width: "60px" }}></th>
                                        <th>Transaction Time</th>
                                        <th>Customer</th>
                                        <th>Qty</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.sales.DraftSales.map((draftSale, index) => <React.Fragment>
                                        {draftSale.TransactionType.TransactionType === this.state.transactionType.TransactionType && <tr key={index}>

                                            <td>
                                                <button type="button" className="btn btn-flat btn-md btn-default "
                                                    onClick={() => this.onRetriveParkedSales(draftSale, index)}>
                                                    <i className="fa fa-mail-forward"></i>
                                                </button>
                                            </td>
                                            <td>
                                                {draftSale.TransactionDate}
                                            </td>
                                            <td>
                                                {draftSale.Customer ? draftSale.Customer.FirstName + " " + draftSale.Customer.LastName : ""}
                                            </td>
                                            <td>
                                                {draftSale.BillQty}
                                            </td>
                                            <td>
                                                {draftSale.BillAmount}
                                            </td>
                                        </tr>}
                                    </React.Fragment>
                                    )}
                                </tbody>
                            </table>}
                        </div>
                    </div>
                </Modal>
                <Modal open={this.state.showLineItemModal} onClose={() => this.setState({ showLineItemModal: false })} center>
                    {this.state.selectedLineItem && <div style={{ width: "700px" }}>
                        <h3 style={{ marginTop: "0px" }}>{this.state.selectedLineItem.Product.SKU} - {this.state.selectedLineItem.Product.Name}</h3>
                        <div className="box-body">
                            <div className="row">
                                <div className="col-md-2">
                                    <div className="form-group" >
                                        <label >Qty</label>
                                        <input type="number" className="form-control"
                                            onChange={(evt) => this.onLineItemQtyChange(evt.target.value)}
                                            value={this.state.selectedLineItem.Qty}
                                            onKeyPress={this.handleKeyPress}
                                            disabled={this.state.selectedLineItem.Product.hasOwnProperty('InventoryType') && this.state.selectedLineItem.Product.InventoryType.toLowerCase() === "unique no" ? true : false}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label >Price</label>
                                        <input type="number" className="form-control"
                                            onChange={(evt) => this.onLineItemPriceChange(evt.target.value)}
                                            value={this.state.selectedLineItem.RetailPrice}
                                            disabled={!this.state.selectedLineItem.Product.AllowPriceEdit}
                                            onKeyPress={this.handleKeyPress}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label >Disc %</label>
                                        <input type="number" className="form-control"
                                            onChange={(evt) => this.onLineItemDiscountChange(evt.target.value)}
                                            value={this.state.selectedLineItem.DiscountPer}
                                            disabled={!this.state.selectedLineItem.Product.AllowDiscount ||
                                                (this.state.selectedLineItem.hasOwnProperty('Discounts')
                                                    && this.state.selectedLineItem.Discounts.length > 0)}
                                            onKeyPress={this.handleKeyPress}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2">
                                    <div className="form-group">
                                        <label >Dis Amt</label>
                                        <input type="number" className="form-control"
                                            onChange={(evt) => this.onLineItemDiscountAmountChange(evt.target.value)}
                                            value={this.state.selectedLineItem.DiscountAmount}
                                            disabled={!this.state.selectedLineItem.Product.AllowDiscount ||
                                                (this.state.selectedLineItem.hasOwnProperty('Discounts')
                                                    && this.state.selectedLineItem.Discounts.length > 0)}
                                            onKeyPress={this.handleKeyPress}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label >Sub Total</label>
                                        <input type="number" className="form-control"
                                            disabled={true}
                                            value={this.state.selectedLineItem.SubTotalWithDiscount}
                                        />
                                    </div>
                                </div>
                            </div>
                            {(this.state.selectedLineItem.Product.hasOwnProperty('EditWalletTopUpAmount') ? this.state.selectedLineItem.Product.EditWalletTopUpAmount : false) && <div className="row">
                                <div className="col-md-3">
                                    <label > Wallet top up amount</label>
                                    <input type="number" className="form-control"
                                        onChange={(evt) => this.onLineItemWalletTopUpChange(evt.target.value)}
                                        value={this.state.selectedLineItem.Product.WalletTopUpAmount}
                                    />
                                </div>
                            </div>}
                            <div className="row">
                                <div className="col-md-6">
                                    {this.state.transactionType.CalculateTaxChecked && <div className="form-group">
                                        <input type="checkbox"
                                            onChange={() => this.onChargeTaxOnProductChange()}
                                            defaultChecked={this.state.selectedLineItem.ChargeTaxOnProduct}
                                            disabled={!this.state.selectedLineItem.Product.AllowTaxChange}
                                        />
                                        <label>Charge taxes on this product</label>
                                    </div>}
                                </div>
                                {this.state.selectedLineItem.ChargeTaxOnProduct && <div className="col-md-6">
                                    <div className="form-group">
                                        <input type="checkbox"
                                            onChange={() => this.onTaxIncluxiveChange()}
                                            defaultChecked={this.state.selectedLineItem.TaxInclusive}
                                            disabled={!this.state.selectedLineItem.Product.AllowTaxChange}
                                        />
                                        <label for="taxInclusive">Tax inclusive</label>
                                    </div>
                                </div>}
                            </div>
                            {this.state.selectedLineItem.ChargeTaxOnProduct && <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label >HSN/SAC Code</label>
                                        <input type="text" className="form-control"
                                            onKeyPress={this.handleKeyPress}
                                            onChange={(evt) => {
                                                let lineItem = this.state.selectedLineItem
                                                lineItem.HSNSACCode = evt.target.value
                                                this.setState({ selectedLineItem: lineItem })
                                            }}
                                            value={this.state.selectedLineItem.HSNSACCode}
                                            disabled={!this.state.selectedLineItem.Product.AllowTaxChange}
                                            placeholder="HSN/SAC code" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label for="tax">Tax </label>
                                        <select className="form-control" value={this.state.selectedLineItem.TaxID}
                                            onChange={(evt) => this.onTaxGroupChange(evt.target.value, evt.target.options[evt.target.selectedIndex].text)}
                                            disabled={!this.state.selectedLineItem.Product.AllowTaxChange}
                                        >
                                            <option value="">Select Tax</option>
                                            {this.props.config.ProductTaxes.map((t, index) =>
                                                <option value={t.key}>{t.TaxGroup}</option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                            </div>}
                            {this.state.selectedLineItem.ChargeTaxOnProduct && <div className="row">
                                {this.state.selectedLineItem.TaxComponentAmount.map((t) => <div className="col-md-3">
                                    <div className="form-group">
                                        <label >{t.TaxName} {t.TaxPercentage}%</label>
                                        <input type="text" className="form-control" value={t.TaxAmount} disabled={true}></input>
                                    </div>
                                </div>)}
                            </div>}
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label> Total Amount</label>
                                        <input type="text" className="form-control" disabled={true}
                                            value={this.state.selectedLineItem.Amount}></input>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className={this.state.selectedItemEmployeeSelectionError !== "" ? "form-group has-error" : "form-group "}>
                                        <label >Employee</label>
                                        <select className="form-control"

                                            onKeyPress={this.handleKeyPress}
                                            onChange={(evt) => {
                                                let selectedLineItem = this.state.selectedLineItem
                                                this.state.employeeList.filter((e) => e.key === evt.target.value).map((f) => {
                                                    f.EmployeeName = f.EmployeeFirstName + " " + f.EmployeeLastName
                                                    selectedLineItem.Employee = f
                                                    this.setState({ selectedLineItem: selectedLineItem, selectedItemEmployeeSelectionError: "" })
                                                    return
                                                })
                                            }}
                                            value={this.state.selectedLineItem.Employee.key}>
                                            <option value="">Select Employee</option>
                                            {this.state.employeeList.map((e, index) => <option key={index} value={e.key}>{e.EmployeeFirstName + ' ' + e.EmployeeLastName}</option>)}
                                        </select>
                                        {this.state.selectedItemEmployeeSelectionError !== "" && <span className="help-block">{this.state.selectedItemEmployeeSelectionError}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label >Notes</label>
                                        <input type="text" className="form-control"
                                            onChange={(evt) => this.onLineItemNotesChange(evt.target.value)}
                                            value={this.state.selectedLineItem.Notes}
                                            onKeyPress={this.handleKeyPress}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box-footer">
                            <button type="submit" className="btn btn-flat btn-md btn-default" onClick={() => this.setState({ showLineItemModal: false })} >Cancel</button>
                            <button type="submit" className="btn btn-flat btn-md btn-success pull-right" onClick={() => this.onLineItemChangesDone()}>OK</button>
                        </div>
                    </div>}
                </Modal>
                <Modal open={this.state.showError} onClose={() => this.setState({ showError: false })} center>
                    <div style={{ width: "300px" }}>
                        <h3 style={{ marginTop: "0px" }}>Error</h3>
                        <div className="box-body">
                            <ul>
                                {this.state.customerError !== "" && <li>{this.state.customerError}
                                </li>}
                                {this.state.employeeMandatoryAtLineItemError !== "" && <li>{this.state.employeeMandatoryAtLineItemError}
                                </li>}
                                {this.state.employeeMandatoryAtTransactionError !== "" && <li>{this.state.employeeMandatoryAtTransactionError}
                                </li>}
                                {this.state.noLineItemExistError !== "" && <li>{this.state.noLineItemExistError}
                                </li>}
                            </ul>
                        </div>
                        <div className="box-footer">
                            <button type="submit" className="btn btn-flat btn-md btn-danger pull-right"
                                onClick={() => this.setState({ showError: false })}>OK</button>
                        </div>
                    </div>
                </Modal>
                <Modal open={this.state.showDelivery}
                    onClose={() => this.setState({ showDelivery: false })} center>
                    <Delivery {...this.props}
                        deliveryCustomer={this.state.deliveryCustomer}
                        deliveryCustomerAddress={this.state.deliveryCustomerAddress}
                        deliveryDate={this.state.deliveryDate}
                        deliveryTime={this.state.deliveryTime}
                        deliveryNotes={this.state.deliveryNotes}
                        onAddCustomer={(newCustomer) => { this.setState({ showAddCustomer: true, showInvoice: false, fromDelivery: true, showDelivery: false, newCustomer: newCustomer }) }}
                        onCustomerEdit={(deliveryCustomer) => { this.setState({ tmpDeliveryCustomer: deliveryCustomer, showAddCustomer: true, showInvoice: false, fromDelivery: true, showDelivery: false }) }}
                        onClose={() => this.setState({ showDelivery: false, fromDelivery: false })}
                        onSave={(deliveryCustomer, deliveryCustomerAddress, deliveryDate, deliveryTime, deliveryNotes, placeOfSupply) => {
                            this.setState({
                                deliveryCustomer: deliveryCustomer,
                                deliveryCustomerAddress: deliveryCustomerAddress,
                                deliveryDate: deliveryDate,
                                deliveryTime: deliveryTime,
                                deliveryNotes: deliveryNotes,
                                placeOfSupply: placeOfSupply,
                                showDelivery: false,
                                fromDelivery: false
                            })
                        }} />
                </Modal>
                <Modal open={this.state.showDiscountOptions}
                    onClose={() => this.setState({ showDiscountOptions: false })} center>
                    <div style={{ width: "500px", height: "400px" }}>
                        <h3 style={{ marginTop: "0px" }}>Discount</h3>
                        <div className="box-body">
                            <div className="nav-tabs-custom" style={{ cursor: "move" }}>
                                <ul className="nav nav-tabs  ui-sortable-handle">
                                    {this.state.showDiscountMembership && <li className={this.state.discountOptionSelected === "membership" ? "active" : ""}><a onClick={() => this.setState({ discountOptionSelected: 'membership' })} data-toggle="tab">Membership</a></li>}
                                    {this.state.showDiscountTransaction && <li className={this.state.discountOptionSelected === "transaction" ? "active" : ""}><a onClick={() => this.setState({ discountOptionSelected: 'transaction' })} data-toggle="tab">Transaction</a></li>}
                                    {this.state.showDiscountOffers && <li className={this.state.discountOptionSelected === "offer" ? "active" : ""}><a onClick={() => this.setState({ discountOptionSelected: 'offer' })} data-toggle="tab">Offer</a></li>}
                                </ul>
                                <div className="tab-content no-padding">
                                    {(this.state.discountOptionSelected === "membership" && this.state.selectedCustomer && this.state.selectedCustomer.Membership && this.state.selectedCustomer.Membership.hasOwnProperty('MembershipID')) && <div className="box-body" style={{ height: "300px" }}>
                                        <div style={{ padding: "10px 0px 10px 0px" }}>Apply membership discount</div>
                                        <label>
                                            <Switch
                                                onChange={() => this.onApplyRemoveMembershipDiscount(!this.state.applyMembershipDiscount, this.state.applicableMembership)}
                                                checked={this.state.applyMembershipDiscount}
                                            />
                                        </label>
                                    </div>}
                                    {(this.state.discountOptionSelected === "transaction" && this.state.transactionType && this.state.transactionType.DiscountChecked) && <React.Fragment> <div className="box-body" style={{ height: "250px" }}>
                                        <div className="form-group">
                                            <label >Discount Percentage</label>
                                            <input type="number"
                                                className="form-control"
                                                onChange={(evt) => this.setState({ overallDiscountPercentage: evt.target.value })}
                                                onKeyPress={this.handleKeyPress}
                                                value={this.state.overallDiscountPercentage}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label >Discount Amount</label>
                                            <input type="number"
                                                onChange={(evt) => this.setState({ overallDiscountAmount: evt.target.value })}
                                                onKeyPress={this.handleKeyPress}
                                                value={this.state.overallDiscountAmount}
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input type="checkbox" checked={this.state.overallDiscountClearExistingDiscount}
                                                onChange={() => this.setState({ overallDiscountClearExistingDiscount: !this.state.overallDiscountClearExistingDiscount })} />
                                            <label>Clear existing discount</label>
                                        </div>

                                    </div>
                                        <div className="box-footer">
                                            <button type="submit" className="btn btn-flat btn-md btn-default"
                                                onClick={() => this.setState({ showDiscountOptions: false })} >Cancel</button>
                                            <button type="submit" className="btn btn-flat btn-md btn-success pull-right"
                                                onClick={() => this.onOverAllDiscount(this.state.overallDiscountPercentage,
                                                    this.state.overallDiscountAmount,
                                                    this.state.overallDiscountClearExistingDiscount,
                                                    "",
                                                    "",
                                                    "")}>OK</button>
                                        </div></React.Fragment>}
                                    {this.state.discountOptionSelected === "offer" && <div className="box-body" style={{ paddingBottom: "0px" }}>
                                        <div className="nav-tabs-custom" style={{ cursor: "move" }}>
                                            <ul className="nav nav-tabs  ui-sortable-handle">
                                                <li className={this.state.offersPopupTabSelected === "applied" ? "active" : ""}><a onClick={() => this.setState({ offersPopupTabSelected: 'applied' })} data-toggle="tab">Applied</a></li>
                                                <li className={this.state.offersPopupTabSelected === "applicable" ? "active" : ""}><a onClick={() => this.setState({ offersPopupTabSelected: 'applicable' })} data-toggle="tab">Applicable</a></li>
                                                <li className={this.state.offersPopupTabSelected === "all" ? "active" : ""}><a onClick={() => this.setState({ offersPopupTabSelected: 'all' })} data-toggle="tab">All</a></li>
                                            </ul>
                                            <div className="scroll2" style={{ height: "230px", padding: "10px", paddingBottom: "0px", overflowY: "scroll" }}>
                                                {this.state.offersPopupTabSelected === "applied" && <div style1={{ paddingTop: "10px" }}>
                                                    {this.state.offersApplied.map((m, index) => <div className="input-group">
                                                        <button key={index}
                                                                style={{ height: "64px" }}
                                                                type="submit"
                                                            className="btn btn-lg btn-block btn-default btn-flat"
                                                        >{m.Name} <br></br><div style={{ fontSize: "14px" }}>{m.Description}</div>
                                                        </button>
                                                        <div className="input-group-btn">
                                                            <button
                                                                style={{ height: "64px" }}
                                                                type="button" onClick={() => this.removeSelectedOffer(index)}
                                                                className="btn btn-default btn-lg btn-flat">
                                                                <i className="glyphicon glyphicon-remove"></i>
                                                            </button>
                                                        </div>
                                                    </div>)}
                                                </div>}
                                                {this.state.offersPopupTabSelected === "applicable" && <div style1={{ paddingTop: "10px" }}>
                                                    {this.state.offersToApply.map((m, index) => <button key={index}
                                                        type="submit"
                                                        className="btn btn-lg btn-block btn-default btn-flat"
                                                        onClick={() => this.applySelectedOffer(index)}
                                                    >{m.Name} <br></br><div style={{ fontSize: "14px" }}>{m.Description}</div></button>)}
                                                </div>}
                                                {this.state.offersPopupTabSelected === "all" && <div style1={{ paddingTop: "10px" }} >
                                                    {this.state.offers.map((m, index) => <button key={index}
                                                        type="submit"
                                                        className="btn btn-lg btn-block btn-default btn-flat"
                                                    >{m.Name} <br></br><div style={{ fontSize: "14px" }}>{m.Description}</div></button>)}
                                                </div>}
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>

                    </div>
                </Modal>
                <Modal open={this.state.showModifiers} onClose={() => this.setState({ showModifiers: false })} center>
                    <SalesModifiers
                        {...this.props}
                    />
                </Modal>
            </HotKeys>
            <Modal open={this.state.showUniqueNoInput}
                onClose={() => this.setState({ showUniqueNoInput: false })} center>
                <UniqueNoInput
                    {...this.props}
                    transactionID={this.state.key}
                    product={this.state.selectedProduct}
                    lineItem={this.state.selectedLineItem}
                    mode={"remove"}
                    onOK={(uniqueNoList) => {
                        this.productSelected(this.state.selectedProduct, uniqueNoList)
                        this.setState({ showUniqueNoInput: false })
                    }}
                    onClose={() => this.setState({ showUniqueNoInput: false })} />
            </Modal>
            <Modal open={this.state.showUniqueNoSelection}
                onClose={() => this.setState({ showUniqueNoSelection: false })} center>
                <UniqueNoSelection
                    {...this.props}
                    product={this.state.selectedProduct}
                    lineItem={this.state.selectedLineItem}
                    salesReturn={this.props.location.state.transactionType.TransactionType.toLowerCase() === "sales return" ? true : false}
                    onOK={(uniqueNoList) => {
                        let tmpUniqueNoList = []
                        if (this.state.selectedLineItem && this.state.selectedLineItem.hasOwnProperty('UniqueNoList')) {
                            tmpUniqueNoList = this.state.selectedLineItem.UniqueNoList
                        }
                        uniqueNoList.map(m => tmpUniqueNoList.push(m))
                        this.productSelected(this.state.selectedProduct, tmpUniqueNoList)
                        this.setState({ showUniqueNoSelection: false })
                    }}
                    onClose={() => this.setState({ showUniqueNoSelection: false })} />
            </Modal>
            {this.state.showPayment && <Payment  {...this.props}
                transactionID={this.state.key}
                onCancel={() => { this.setState({ showPayment: false, showInvoice: true }) }}
                onSave={() => { this.onSave() }}
                sale={this.state.sale} />
            }
            {this.state.showAddCustomer && <Customer {...this.props}
                calledFromInvoice={true}
                selectedCustomer={this.state.fromDelivery ? this.state.tmpDeliveryCustomer : this.state.selectedCustomer}
                newCustomer={this.state.newCustomer}
                onSave={(customer) => {
                    if (!this.state.fromDelivery) {
                        this.onCustomerSelected(customer)
                    }
                    else {
                        this.setState({ deliveryCustomer: customer })
                    }
                    this.setState({
                        showAddCustomer: false,
                        showInvoice: true,
                        showDelivery: this.state.fromDelivery
                    })
                }}
                onClose={() => this.setState({ showAddCustomer: false, showInvoice: true, showDelivery: this.state.fromDelivery })} />}
            {this.state.showCustomerDashBoard && <CustomerDashBoard {...this.props}
                calledFromInvoice={true}
                cutomerID={this.state.fromDelivery ? this.state.tmpDeliveryCustomer.key : this.state.selectedCustomer.key}
                onClose={() => this.setState({ showCustomerDashBoard: false, showInvoice: true, showDelivery: this.state.fromDelivery })} />}
            {this.state.showAddProduct && <Product {...this.props}
                calledFromInvoice={true}
                onSave={(product) => {
                    this.setState({ showAddProduct: false, showInvoice: true })
                    this.onProductSelected(product)
                    //update redux with new product
                }}
                onClose={() => this.setState({ showAddProduct: false, showInvoice: true })}
            />}
            <Modal open={this.state.showTransactionTypes}
                onClose={() => this.setState({ showTransactionTypes: false })} center>
                <div style={{ width: "300px" }}>
                    <h3 style={{ paddingTop: "0px" }}></h3>
                    <div className="box-body">
                        {this.props.config.TransactionTypes.filter(f => f.Module.toLowerCase() === "sales"
                            && f.TransactionType.toLowerCase() !== "receive payment" && !f.IsDeleted).map((m, index) => <button
                                style={{ marginBottom: "20px" }}
                                className="btn btn-lg btn-flat btn-default btn-block"
                                onClick={() => {
                                    this.onTransactionTypeSelected(m)
                                }}>
                                {m.TransactionType}
                            </button>)}
                    </div>
                </div>
            </Modal>
        </div>
    }
}

export default withRouter(Sales) 