import React, { Component } from 'react'
import Switch from "react-switch"
import { Link } from 'react-router-dom'

class AddRegistrationSettings extends Component{
    constructor(props) {
        super(props)
        this.state = {
            kotSelected : false,
            deliverySelected : false,
            globalCustomersSeleceted : false,
            productConsumptionSelected : false,
            sendInvoiceLinkSelected : false,
            sendInvoiceCancellationSmsSelected : false,
            cashRegisterClosingNotificationSelected : false
         }
         this.onSave = this.onSave.bind(this)
    }

    componentDidMount() {        
        this.setState({
            kotSelected: this.props.registration.registration.hasOwnProperty('KOT') ? this.props.registration.registration.KOT : false,
            deliverySelected: this.props.registration.registration.hasOwnProperty('Delivery') ? this.props.registration.registration.Delivery : false,
            globalCustomersSeleceted: this.props.registration.registration.hasOwnProperty('GlobalCustomer') ? this.props.registration.registration.GlobalCustomer : false,
            productConsumptionSelected: this.props.registration.registration.hasOwnProperty('ProductConsumption') ? this.props.registration.registration.ProductConsumption : false,
            sendInvoiceLinkSelected: this.props.registration.registration.hasOwnProperty('SendInvoiceLink') ? this.props.registration.registration.SendInvoiceLink : false,
            sendInvoiceCancellationSmsSelected: this.props.registration.registration.hasOwnProperty('SendInvoiceCancellationSMS') ? this.props.registration.registration.SendInvoiceCancellationSMS : false,
            cashRegisterClosingNotificationSelected: this.props.registration.registration.hasOwnProperty('CashRegisterClosingNotificationToStoreOwner') ? this.props.registration.registration.CashRegisterClosingNotificationToStoreOwner : false
        })
    }

    onSave(){        
        const additionalSetting = {
            KOT : this.state.kotSelected,
            Delivery : this.state.deliverySelected,
            GlobalCustomer : this.state.globalCustomersSeleceted,
            ProductConsumption : this.state.productConsumptionSelected,
            SendInvoiceLink : this.state.sendInvoiceLinkSelected,
            SendInvoiceCancellationSMS : this.state.sendInvoiceCancellationSmsSelected,
            CashRegisterClosingNotificationToStoreOwner : this.state.cashRegisterClosingNotificationSelected
        }
        this.props.updateRegistrationGeneralDetails(this.props.registration.registration.key, additionalSetting).then(
            () => {
                this.props.getToastr("Registration saved successfully")
                this.props.getRegistration(this.props.registration.registration.key)
                this.props.onClose()
            })
            
    }

    render(){
        return <div>
            <h3 style={{ marginTop: "0px" }}>Additional Setting</h3>            

            <div className="box-body" style={{margin:"10px"}}> 
                <div className="row">
                <div className="col-md-9" style={{ padding: "10px 0px 10px 0px" }}><strong>KOT</strong></div>
                    <div className="col-md-3">
                        <Switch
                            checked={this.state.kotSelected}
                            onChange={() => { this.setState({ kotSelected: !this.state.kotSelected }) }}
                        />
                    </div>
                </div>

                <div className='row'>
                <div className="col-md-9" style={{ padding: "10px 0px 10px 0px" }}><strong>Delivery</strong></div>
                    <div className="col-md-3">
                        <Switch
                            checked={this.state.deliverySelected}
                            onChange={() => { this.setState({ deliverySelected: !this.state.deliverySelected }) }}
                        />
                    </div>
                </div>

                <div className="row">
                <div className="col-md-9" style={{ padding: "10px 0px 10px 0px" }}><strong>Global Customers</strong></div>
                    <div className="col-md-3">
                        <Switch
                            checked={this.state.globalCustomersSeleceted}
                            onChange={() => { this.setState({ globalCustomersSeleceted: !this.state.globalCustomersSeleceted }) }}
                        />
                    </div>
                </div>                
                
                <div className="row">
                <div className="col-md-9" style={{ padding: "10px 0px 10px 0px" }}><strong>Product Consumption</strong></div>
                    <div className="col-md-3">
                        <Switch
                            checked={this.state.productConsumptionSelected}
                            onChange={() => { this.setState({ productConsumptionSelected: !this.state.productConsumptionSelected }) }}
                        />
                    </div>
                </div>
                
                <div className="row">
                <div className="col-md-9" style={{ padding: "10px 0px 10px 0px" }}><strong>Send Invoice Link</strong></div>
                    <div className="col-md-3">
                        <Switch
                            checked={this.state.sendInvoiceLinkSelected}
                            onChange={() => { this.setState({ sendInvoiceLinkSelected: !this.state.sendInvoiceLinkSelected }) }}
                        />
                    </div>
                </div>
                
                <div className="row">
                <div className="col-md-9" style={{ padding: "10px 0px 10px 0px" }}><strong>Send Invoice Cancellation SMS</strong></div>
                    <div className="col-md-3">
                        <Switch
                            checked={this.state.sendInvoiceCancellationSmsSelected}
                            onChange={() => { this.setState({ sendInvoiceCancellationSmsSelected: !this.state.sendInvoiceCancellationSmsSelected }) }}
                        />
                    </div>
                </div>
                
                <div className="row">
                <div className="col-md-9" style={{ padding: "10px 0px 10px 0px" }}><strong>Cash Register closing <br/>notification to store owner</strong></div>
                    <div className="col-md-3">
                        <Switch
                            checked={this.state.cashRegisterClosingNotificationSelected}
                            onChange={() => { this.setState({ cashRegisterClosingNotificationSelected: !this.state.cashRegisterClosingNotificationSelected }) }}
                        />
                    </div>
                </div>         
            </div>
            <div className="box-footer">
                <button type="submit" className="btn btn-flat btn-md btn-flat btn-default" onClick={this.props.onClose} >Cancel</button>
                <button type="submit" className="btn btn-flat btn-md btn-flat btn-success pull-right" onClick={this.onSave} disabled={this.state.saving}>Save</button>
            </div>
        </div>
    }
}

export default AddRegistrationSettings