import firebase, { database, functions } from '../firebase'
import * as actionTypes from '../actionTypes'
import * as constants from '../Constatnts'

export function addSales(sale) {
    return (dispatch, getState) => {
        let newSaleDoc = database.collection("Sales").doc();

        //console.log('New Sales ID',newSaleDoc.id);

        sale.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        sale.ActionBy = getState().user.user.Name
        sale.ActionByUID = getState().user.user.UID
        sale.ActionByEmailID = getState().user.user.EmailID
        sale.Action = constants.AUDIT_TRAIL_NEW

        newSaleDoc.set(sale)
        return newSaleDoc.id
    }
}

export function updateSales(sale, key) {
    return (dispatch, getState) => {
        sale.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        sale.ActionBy = getState().user.user.Name
        sale.ActionByUID = getState().user.user.UID
        sale.ActionByEmailID = getState().user.user.EmailID
        sale.Action = constants.AUDIT_TRAIL_EDIT
        sale.BackEndUpdate = false
        return database.collection("Sales").doc(key).update({
            ...sale
        })
    }
}

export function cancelSales(cancellationReason, key) {
    return (dispatch, getState) => {
        let actionOn = firebase.firestore.FieldValue.serverTimestamp()
        let actionBy = getState().user.user.Name
        let actionByUID = getState().user.user.UID
        let actionByEmailID = getState().user.user.EmailID
        let action = "CANCEL"
        return database.collection("Sales").doc(key).update({
            TransactionStatus: "Cancelled",
            CancellationReason: cancellationReason,
            ActionOn: actionOn,
            ActionBy: actionBy,
            ActionByUID: actionByUID,
            ActionByEmailID: actionByEmailID,
            Action: action,
            BackEndUpdate: false
        })
    }
}

export function getTransactionRunningNo(cashRegisterID, transactionTypeID) {
    return dispatch => {
        return database.collection("TransactionRunningNo").doc(cashRegisterID + transactionTypeID).get().then((doc) => {
            let runningNo = 1
            if (doc.exists) {
                runningNo = Number(doc.data().RunningNo) + 1
            }
            //console.log("getTransactionRunningNo", runningNo)
            return runningNo
        })
    }
}

export function updateTransactionRunningNo(cashRegisterID, transactionTypeID, runningNo) {
    return dispatch => {
        database.collection("TransactionRunningNo").doc(cashRegisterID + transactionTypeID).set({
            CashRegisterID: cashRegisterID, TransactionTypeID: transactionTypeID, RunningNo: runningNo
        })
        //console.log("updateTransactionRunningNo", runningNo)
    }
}

export function getKOTRunningNo(cashRegisterID) {
    return dispatch => {
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1; //January is 0!
        let yyyy = today.getFullYear();

        if (dd < 10) {
            dd = '0' + dd
        }

        if (mm < 10) {
            mm = '0' + mm
        }
        let key = cashRegisterID + dd + mm + yyyy
        return database.collection("KOTRunningNo").doc(key).get().then((doc) => {
            let runningNo = 0
            if (doc.exists) {
                runningNo = Number(doc.data().RunningNo) + 1
            }
            database.collection("KOTRunningNo").doc(key).set({ RunningNo: runningNo })
            return runningNo
        })
    }
}

export function getSaleDetails(key) {
    return (dispatch) => {
        return database.collection("Sales").doc(key).get().then((doc) => {
            if (doc.exists) {
                return doc.data()
                //dispatch({type: actionTypes.PARK_SALE,draftSales})
            } else {
                console.log("No such sale record!");
            }
        })
    }
}

export function parkSales(draftSales) {
    return dispatch => {
        dispatch({ type: actionTypes.PARK_SALE, draftSales })
    }
}

export function calculateLineItem(lineItem, Tax) {

    return (dispatch) => {
        let subTotal = lineItem.RetailPrice * lineItem.Qty
        let disocuntAmt = (subTotal * lineItem.DiscountPer) / 100
        let subTotalWithDiscount = subTotal - disocuntAmt
        let chargeTaxOnProduct = lineItem.Product.ChargeTaxOnProduct
        let taxInclusive
        let taxableAmount = 0
        let CGSTRate = 0
        let CGSTAmount = 0
        let SGSTRate = 0
        let SGSTAmount = 0
        let IGSTRate = 0
        let IGSTAmount = 0
        let CESSRate = 0
        let CESSAmount = 0
        let totalTax = 0
        let amount = 0
        let retailPriceWithoutTax = 0 //TBD
        //   console.log('chargeTaxOnProduct',chargeTaxOnProduct)
        if (chargeTaxOnProduct) {
            //tax 
            //console.log('this.props.product.Tax', this.props.product.Tax)
            Tax.map((t, index) => {
                if (t.TaxGroup.trim() === lineItem.Product.Tax.trim()) {
                    console.log('taxgroup', t)
                    CGSTRate = t.CGST
                    SGSTRate = t.SGST
                    IGSTRate = t.IGST
                    CESSRate = t.CESS
                }
            })

            taxInclusive = lineItem.Product.TaxInclusive
            if (taxInclusive) {//tax inclusive
                //console.log('tax inclusive')
                let totalTaxRate = CGSTRate + SGSTRate + CESSRate
                totalTax = subTotalWithDiscount * totalTaxRate / (100 + totalTaxRate)
                totalTax = +totalTax.toFixed(2);
                taxableAmount = subTotalWithDiscount - totalTax
                amount = subTotalWithDiscount

                CGSTAmount = taxableAmount * ((CGSTRate * 100) / totalTaxRate) / 100
                CGSTAmount = +CGSTAmount.toFixed(2)
                SGSTAmount = taxableAmount * ((SGSTRate * 100) / totalTaxRate) / 100
                SGSTAmount = +SGSTAmount.toFixed(2)
                CESSAmount = taxableAmount * ((CESSRate * 100) / totalTaxRate) / 100
                CESSAmount = +CESSAmount.toFixed(2)
                IGSTAmount = taxableAmount * ((IGSTRate * 100) / totalTaxRate) / 100
                IGSTAmount = +IGSTAmount.toFixed(2)
            }
            else {//tax exclusive
                //console.log('Tax exclusive')
                disocuntAmt = 0
                taxableAmount = subTotalWithDiscount
                // CGSTRate = product.CGSTRate
                CGSTAmount = (taxableAmount * CGSTRate) / 100
                CGSTAmount = +CGSTAmount.toFixed(2)
                //SGSTRate = product.SGSTRate
                SGSTAmount = (taxableAmount * SGSTRate) / 100
                SGSTAmount = +SGSTAmount.toFixed(2)
                //IGSTRate = product.IGSTRate
                IGSTAmount = (taxableAmount * IGSTRate) / 100
                IGSTAmount = +IGSTAmount.toFixed(2)
                //CESSRate = product.CESRate
                CESSAmount = (taxableAmount * CESSRate) / 100
                CESSAmount = +CESSAmount.toFixed(2)
                totalTax = CGSTAmount + SGSTAmount + CESSAmount
                amount = taxableAmount + totalTax
            }
        }
        else {
            amount = subTotal
        }

        // lineItem.Qty = qty
        // lineItem.RetailPrice = price
        lineItem.SubTotal = subTotal
        lineItem.ChargeTaxOnProduct = chargeTaxOnProduct
        lineItem.TaxInclusive = taxInclusive
        // lineItem.DiscountPer = discountPer 
        lineItem.DisocuntAmt = disocuntAmt
        lineItem.TaxableAmount = taxableAmount
        lineItem.CGSTRate = CGSTRate
        lineItem.CGSTAmount = CGSTAmount
        lineItem.SGSTRate = SGSTRate
        lineItem.SGSTAmount = SGSTAmount
        lineItem.IGSTRate = IGSTRate
        lineItem.IGSTAmount = IGSTAmount
        lineItem.CESSRate = CESSRate
        lineItem.CESSAmount = CESSAmount
        lineItem.TotalTax = totalTax
        lineItem.Amount = amount

        //console.log("calculateLinetItem" , lineItem)
        return lineItem
    }
}


export function generateOTP(registrationID, storeID, cashRegisterID, customerID) {
    return dispatch => {
        let otp = {
            RegistrationID: registrationID, StoreID: storeID,
            CashRegisterID: cashRegisterID, CustomerID: customerID
        }
        let addMessage = firebase.functions().httpsCallable('generateOTP');
        return addMessage({ text: otp }).then(function (result) {
            if (result.data.text) {
                return true
            }
            else {
                return false
            }
        });
    }
}

export function validateOTP(registrationID, storeID, cashRegisterID, customerID, OTP) {
    return (dispatch) => {
        let searchParameters = {
            RegistrationID: registrationID, StoreID: storeID,
            CashRegisterID: cashRegisterID, CustomerID: customerID, OTP: OTP
        }
        //console.log(searchParameters)
        let addMessage = firebase.functions().httpsCallable('validateOTP');
        return addMessage({ text: searchParameters }).then(function (result) {
            //console.log('result',result)
            if (result.data) {
                return true
            }
            else {
                return false
            }
        })
    }
}

export function getCashRegisterOpeningDetails(cashRegisterID) {
    return (dispatch) => {
        return database.collection("CashRegisterClosing").where("CashRegisterID", "==", cashRegisterID).where("Status", "==", "Open").get().then((querySnapshot) => {
            let obj
            querySnapshot.forEach((doc) => {
                obj = { key: doc.id, ...doc.data() }
            })
            return obj
        }).catch((error) => {
            return null
            console.log(error)
        })
    }
}


export function getCashRegisterClosingDetails(key) {
    return (dispatch) => {
        return database.collection("CashRegisterClosing").doc(key).get().then((doc) => {
            if (doc.exists) {
                return doc.data()
            } else {
                console.log("No such Cash Register Closing record!");
            }
        })
    }
}

export function openCashRegister(cashRegisterClosing) {
    return (dispatch, getState) => {
        cashRegisterClosing.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        cashRegisterClosing.ActionBy = getState().user.user.Name
        cashRegisterClosing.ActionByUID = getState().user.user.UID
        cashRegisterClosing.ActionByEmailID = getState().user.user.EmailID
        cashRegisterClosing.Action = constants.AUDIT_TRAIL_NEW
        return database.collection("CashRegisterClosing").add({
            ...cashRegisterClosing
        })
            .then(function (docRef) {
                return { key: docRef.id, ...cashRegisterClosing }
            })
            .catch(function (error) {
                return null
                console.error("Error saving sales : ", error);
            })
    }
}

export function closeCashRegister(key, cashRegisterClosing) {
    return (dispatch, getState) => {
        cashRegisterClosing.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        cashRegisterClosing.ActionBy = getState().user.user.Name
        cashRegisterClosing.ActionByUID = getState().user.user.UID
        cashRegisterClosing.ActionByEmailID = getState().user.user.EmailID
        cashRegisterClosing.Action = constants.AUDIT_TRAIL_EDIT
        return database.collection("CashRegisterClosing").doc(key).update({
            ...cashRegisterClosing
        })
    }
}

export function checkIfWalletDebitTransactionExists(storeID, transactionNo, debitAmount) {
    return (dispatch, getState) => {
        return database.collection("WalletLedgers").where("StoreID", "==", storeID).where("TransactionNo", "==", transactionNo).where("DebitAmount", "==", debitAmount).limit(1).get().then((querySnapshot) => {
            let exists = false
            querySnapshot.forEach((doc) => {
                exists = true
            })
            return exists
        }).catch((error) => {
            console.log(error)
            return false
        })
    }
}

export function getPaymenDetailstForCashRegisterClosing(registrationID, cashRegisterID, openingTime) {
    return (dispatch, getState) => {
        return database.collection("Sales").where("RegistrationID", "==", registrationID).where("CashRegister.key", "==", cashRegisterID).where("TransactionDate", "<=", new Date()).where("TransactionDate", ">=", openingTime).get().then((querySnapshot) => {
            let paymentModeAmount = []
            querySnapshot.forEach((doc) => {
                // console.log(doc.data())
                if (!doc.data().hasOwnProperty("CancellationReason")) {
                    doc.data().Payments.map((payment) => {
                        let index = -1
                        let amount = 0
                        if (payment.PaymentMode.toLowerCase() === "cash") {
                            amount = Number(payment.Amount) - Number(doc.data().BillChangeDue)
                        }
                        else {
                            amount = Number(payment.Amount)
                        }
                        for (var i = 0; i < paymentModeAmount.length; i++) {
                            if (paymentModeAmount[i].PaymentMode === payment.PaymentMode) {
                                index = i
                            }
                        }
                        if (index === -1) {
                            paymentModeAmount.push({
                                PaymentMode: payment.PaymentMode,
                                Amount: amount
                            })
                        }
                        else {
                            paymentModeAmount[index].Amount = Number(paymentModeAmount[index].Amount) + amount
                        }
                    })
                }

            })
            // console.log('paymentModeAmount',paymentModeAmount)
            return paymentModeAmount
        }).catch((error) => {
            console.log("Error getting invoice list: ", error)
        })
    }
}

export function getCashRegisterClosingList(storeID, cashRegisterID, status) {
    return (dispatch) => {
        let cashRegisterClosingRef = database.collection("CashRegisterClosing")
        let query = cashRegisterClosingRef.where("StoreID", "==", storeID).where("Status", "==", status)
        if (cashRegisterID.trim() !== "") {
            query = query.where("CashRegisterID", "==", cashRegisterID)
        }
        return query.get().then((querySnapshot) => {
            let list = []
            querySnapshot.forEach((doc) => {
                list.push({ key: doc.id, ...doc.data() })
            })
            // console.log('')
            return list
        }).catch((error) => {
            return null
            console.log(error)
        })
    }
}

export function getCustomerOutstanding(customerID) {
    return (dispatch, getState) => {
        let searchParameters = {
            "from": 0,
            "size" : 10000,
            "query": {
                "bool": {
                    "must": [
                        {
                            "term": {
                                "Customer.key.keyword": customerID
                            }
                        },
                        {
                            "term": {
                                "TransactionType.SalesImpactChecked": true
                            }
                        },
                        {
                            "range": {
                                "BalanceAmount": {
                                    "gt": 0
                                }
                            }
                        },
                        {
                            "match": {
                                "Store.key": getState().user.store.key
                            }
                        }
                    ],
                    "must_not": [
                        {
                            "exists": {
                                "field": "CancellationReason"
                            }
                        }
                    ]
                }
            },
            "sort": [
                {
                    "TransactionDate": {
                        "order": "desc"
                    }
                }
            ]
        }
        //   console.log('getCustomerOutstanding - Query', JSON.stringify(searchParameters));
        let addMessage = firebase.functions().httpsCallable('getSales');
        return addMessage({ text: searchParameters }).then(function (result) {
            const sales = []
            let sanitizedMessage = result.data.text;
            sanitizedMessage.hits.hits.map((data, index) => {
                let receivedPayment = data._source.hasOwnProperty('ReceivedPaymentAmount') ? Number(data._source.ReceivedPaymentAmount) : 0
                let outStandingAmount = Number(data._source.BalanceAmount) - Number(receivedPayment)
                if (outStandingAmount !== 0) {
                    sales.push({
                        TransactionKey: data._id,
                        TransactionNo: data._source.TransactionNo,
                        TransactionType: data._source.TransactionType.TransactionType,
                        TransactionDate: data._source.TransactionDate,
                        TransactionAmount: Number(data._source.BillAmount),
                        OutStandingAmount: outStandingAmount,
                        ReceiveAmount: 0,
                        Selected: false
                    })
                }

            })
            return sales
        });
    }
}

export function getOutstandingRRN(customerID) {
    return (dispatch) => {
        // console.log('customerID',customerID)

        return database.collection("RRN").where("CustomerID", "==", customerID).where("IsDeleted", "==", false).get().then((querySnapshot) => {
            let outstandingRRNs = []
            querySnapshot.forEach((doc) => {
                if (doc.data().BalanceAmount > 0) {
                    outstandingRRNs.push({ key: doc.id, ...doc.data() })
                }
            })
            // console.log('outstandingRRNs',outstandingRRNs)
            return outstandingRRNs
        }).catch((error) => {
            return null
            // console.log(error)
        })
    }
}

export function outForDelivery(sale, employee, employeeKey, smsNo) {
    return (dispatch) => {
        return database.collection("Sales").doc(sale.key).update({
            BackEndUpdate: true,
            DeliveryStatus: 'Out For Delivery',
            DeliveryOutForDeliveryEmployee: employee,
            DeliveryOutForDeliveryEmployeeKey: employeeKey,
            DeliveryOutForDeliveryActionOn: firebase.firestore.FieldValue.serverTimestamp(),
            DeliveryOutForDeliverySMSNo: smsNo
        }).then(() => {
            //send sms
            if (smsNo.trim() !== "") {
                let messageText = ""
                messageText = "Dear Customer, Your order " + sale.TransactionNo + " is out for delivery."
                let obj = {
                    RegistrationID: sale.RegistrationID, StoreID: sale.Store.key,
                    UserID: sale.ActionByUID, Module: 'sales',
                    CashRegisterID: sale.CashRegister.key, MobileNo: smsNo,
                    TransactionType: "Delivery", TransactionData: messageText
                }
                let addMessage = firebase.functions().httpsCallable('sendSMS');
                return addMessage({ text: obj }).then(function (result) {
                    if (result.data.text) {
                        return true
                    }
                    else {
                        return false
                    }
                })

            }
        })
    }
}

export function markDelivered(sale) {
    return (dispatch) => {
        return database.collection("Sales").doc(sale.key).update({
            BackEndUpdate: true,
            DeliveryStatus: 'Delivered',
            DeliveryDeliveredActionOn: firebase.firestore.FieldValue.serverTimestamp(),
        }).then(() => {
        })
    }
}

export function readyForPickup(sale, smsNo) {
    return (dispatch) => {
        return database.collection("Sales").doc(sale.key).update({
            BackEndUpdate: true,
            DeliveryStatus: 'Ready For Pickup',
            DeliveryReadyForPickupActionOn: firebase.firestore.FieldValue.serverTimestamp(),
            DeliveryReadyForPickupSMSNo: smsNo
        }).then(() => {
            //send sms
            if (smsNo.trim() !== "") {
                if (smsNo.trim() !== "") {
                    let messageText = ""
                    messageText = "Dear Customer, Your order " + sale.TransactionNo + " is ready for pickup."
                    let obj = {
                        RegistrationID: sale.RegistrationID, StoreID: sale.Store.key,
                        UserID: sale.ActionByUID, Module: 'sales',
                        CashRegisterID: sale.CashRegister.key, MobileNo: smsNo,
                        TransactionType: "Delivery", TransactionData: messageText
                    }
                    let addMessage = firebase.functions().httpsCallable('sendSMS');
                    return addMessage({ text: obj }).then(function (result) {
                        if (result.data.text) {
                            return true
                        }
                        else {
                            return false
                        }
                    })
                }
            }
        })
    }
}

export function getDeliveryDetailReport(registrationID, from, size, option, searchTransactionNo,
    searchTransactionFromDate, searchTransactionToDate,
    searchCustomerDetail, searchCashRegister, searchEmployeeName) {
    let searchParameters = {
        "from": from,
        "size": size,
        "query": {
            "bool": {
                "must": [
                    {
                        "term": {
                            "RegistrationID": registrationID
                        }
                    }
                ],
                "filter": {
                    "bool": {
                        "must": [

                        ],
                        "should": []
                    }
                }
            }
        },
        "sort": [
            {
                "TransactionDate": {
                    "order": "desc"
                }
            }
        ]
    }
    if (option.toLowerCase() === "pending") {
        searchParameters.query.bool.must.push({ "match_phrase": { "DeliveryStatus": "pending" } })
    }
    if (option.toLowerCase() === "all") {
        searchParameters.query.bool.must.push({ "exists": { "field": "DeliveryStatus" } })
    }
    if (option.toLowerCase() === "outfordelivery") {
        searchParameters.query.bool.must.push({ "match_phrase": { "DeliveryStatus": "Out For Delivery" } })
    }
    if (option.toLowerCase() === "readyforpickup") {
        searchParameters.query.bool.must.push({ "match_phrase": { "DeliveryStatus": "Ready For Pickup" } })
    }
    if (option.toLowerCase() === "delivered") {
        searchParameters.query.bool.must.push({ "match_phrase": { "DeliveryStatus": "delivered" } })
    }
    if (searchCustomerDetail.trim() !== "") {
        searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "DeliveryCustomer.FirstName": "@" + searchCustomerDetail.trim().toLowerCase() + ".*" } })
        searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "DeliveryCustomer.LastName": "@" + searchCustomerDetail.trim().toLowerCase() + ".*" } })
        searchParameters.query.bool.filter.bool.should.push({ "regexp": { "DeliveryCustomer.EmailID": "@" + searchCustomerDetail.trim().toLowerCase() + ".*" } })
        searchParameters.query.bool.filter.bool.should.push({ "regexp": { "DeliveryCustomer.PhoneNo": "@" + searchCustomerDetail.trim().toLowerCase() + ".*" } })
        searchParameters.query.bool.filter.bool.should.push({ "regexp": { "DeliveryCustomer.Code": "@" + searchCustomerDetail.trim().toLowerCase() + ".*" } })
    }
    if (searchTransactionNo.trim() !== "") {
        searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "TransactionNo": searchTransactionNo.trim().toLowerCase() } })
    }
    if (searchCashRegister.trim() !== "") {
        searchParameters.query.bool.filter.bool.must.push({ "match": { "CashRegister.key": searchCashRegister } })
    }
    if (searchEmployeeName.trim() !== "") {
        searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "DeliveryOutForDeliveryEmployee": searchEmployeeName.trim() } })
    }
    if (searchTransactionToDate.trim() !== "" && searchTransactionFromDate.trim() !== "") {
        searchParameters.query.bool.must.push({
            "range": {
                "DeliveryDate": {
                    "lte": searchTransactionToDate,
                    "gte": searchTransactionFromDate,
                    "format": "yyyy-MM-dd"
                }
            }
        })
    }

    return (dispatch, getState) => {
        searchParameters.query.bool.must.push({
            "match": {
                "Store.key": getState().user.store.key
            }
        })
        console.log("messageText", JSON.stringify(searchParameters));
        let addMessage = firebase.functions().httpsCallable('getSales');
        return addMessage({ text: searchParameters }).then(function (result) {
            const invoices = []
            let sanitizedMessage = result.data.text;
            sanitizedMessage.hits.hits.map((data, index) => {
                // console.log("data", JSON.stringify(data))
                invoices.push({ key: data._id, ...data._source })
            })
            console.log("Invoices", JSON.stringify(sanitizedMessage.hits.total))
            return { totalItemsCount: sanitizedMessage.hits.total, searchResult: invoices }
        });
    }
};

export function initialiseTransactionRunningNo(cashRegisterID) {
    return (dispatch) => {
        return database.collection("TransactionRunningNo").where("CashRegisterID", "==", cashRegisterID).get()
    }
};

export function initialiseKOTRunningNo(cashRegisterID) {

    return dispatch => {
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1; //January is 0!
        let yyyy = today.getFullYear();

        if (dd < 10) {
            dd = '0' + dd
        }

        if (mm < 10) {
            mm = '0' + mm
        }
        let key = cashRegisterID + dd + mm + yyyy
        database.collection("KOTRunningNo").doc(key).get().then((doc) => {
            let runningNo = 0
            if (!doc.exists) {
                database.collection("KOTRunningNo").doc(key).set({
                    CashRegisterID: cashRegisterID, RunningNo: runningNo
                })
            }
        })
    }
}

export function initialiseSales(registrationID) {
    return (dispatch) => {
        return database.collection("Sales").where("RegistrationID", "==", registrationID).limit(1).get()
    }
}

export function getOfflineSales() {
    return (dispatch, getState) => {
        return database.collection("Sales").where("RegistrationID", "==", getState().user.user.RegistrationID).where("Store.key", "==", getState().user.store.key).orderBy("TransactionDate", "desc").get().then((querySnapshot) => {
            let sales = [];
            querySnapshot.forEach((doc) => {
                //console.log("Sale", doc.data().TransactionDate);
                sales.push({ key: doc.id, ...doc.data() })
            })
            return sales;
        })
    }
}
