import firebase, {database} from '../firebase'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import * as actionTypes from '../actionTypes'


export function getToastr(message)
{
    return (dispatch) => {
        toastr.options = {
            positionClass : 'toast-bottom-right',
            hideDuration: 300,
            timeOut: 3000
            }
        toastr.success(message)
    }
}

export function getVisibility(module, access) {
    return (dispatch, getState) => {
        let visibility = true
        if (getState().user.acl) {
            if (access === "create")
                visibility = getState().user.acl.filter((e) => e.id === module).map((acl) => acl.perms.create)[0];
            else if (access === "read")
                visibility = getState().user.acl.filter((e) => e.id === module).map((acl) => acl.perms.read)[0];
            else if (access === "update")
                visibility = getState().user.acl.filter((e) => e.id === module).map((acl) => acl.perms.update)[0];
            else if (access === "delete")
                visibility = getState().user.acl.filter((e) => e.id === module).map((acl) => acl.perms.delete)[0];
        }
        return visibility ? "inline" : "none"
    }
}

export function importCSV(filePath, importType, fileName,emailID, productImportType, forStoreID, forStore){
    return (dispatch, getState) =>{
        return database.collection("Import").add({
            FileName:fileName,
            EmailID: emailID,
            ImportType:importType,
            ProductImportType:productImportType,
            ForStoreID:forStoreID,
            ForStore:forStore,
            FilePath:filePath,
            RegistrationID:getState().user.user.RegistrationID,
            StoreID: getState().user.store.key,
            RowsProcessed:0,
            Status:'Pending',
            ProcessingStartTime:null,
            ProcessingEndTime:null,
            Action:'NEW',
            ActionOn:firebase.firestore.FieldValue.serverTimestamp(),
            ActionBy:getState().user.user.Name,
            ActionByEmailID:getState().user.user.EmailID,
            ActionByUID:getState().user.user.UID
        })
        .then(function(docRef) {
            return docRef.id
            // console.log(' import',docRef.id)
        })
        .catch(function(error) {
            console.error("Error adding import: ", error);
        })
    }
}

export function getImportDetails(key){
    return (dispatch) => {
    return database.collection("ImportLogs").doc(key).get().then((doc) => {
        if (doc.exists) {
            // console.log("Import found", doc.data());
           return doc.data()
        } else {
            console.log("No such import!");
        }
    })
    }
}

export function sendEmail(email) {
    return (dispatch) => {
        return database.collection("Emails").add({...email}).then((docRef)=>{
            return docRef.id
            // console.log(' Emails',docRef.id)
        })
        .catch(function(error) {
            console.error("Error adding Email: ", error);
        })

    }
}

export function sendSMS(sms) {
    return (dispatch) => {
        let addMessage = firebase.functions().httpsCallable('sendSMS');
        return addMessage({ text: sms }).then(function (result) {
            if (result.data.text) {
                return true
            }
            else {
                return false
            }
        });
    }
}

export function getPrintTemplate(template) {
    // console.log(template);
    return (dispatch) => {
        return database.collection("PrintTemplates").doc(template).get().then((doc) => {
            if (doc.exists) {
                // console.log(doc.data().PrintTemplate);
                return doc.data()
            } else {
                console.log("No such template found!");
            }
        })
    }
}

export function getImportLogList(registrationID, importType) {
    return (dispatch) => {
        return database.collection("ImportLogs").where("RegistrationID", "==", registrationID).where("ImportType", "==", importType).orderBy("ActionOn", "desc").get().then((querySnapshot) => {
            let logs = []
            querySnapshot.forEach((doc) => {
                logs.push({ ...doc.data(), key: doc.id })
            })
            // console.log('logs',logs)
            return logs
        })
    }
}

export function getImportLogs(key) {
    //console.log('Key',key)
    return (dispatch) => {
        return database.collection("ImportLogs").doc(key).collection("Log").get().then((querySnapshot) => {
            let logs = []
            querySnapshot.forEach((doc) => {
                logs.push(doc.data())
            })
            // console.log('logs',logs)
            return logs
        })
    }
}

export function convertNumberToWords(amount) {
    return (dispatch) => {
        var words = new Array();
        words[0] = '';
        words[1] = 'One';
        words[2] = 'Two';
        words[3] = 'Three';
        words[4] = 'Four';
        words[5] = 'Five';
        words[6] = 'Six';
        words[7] = 'Seven';
        words[8] = 'Eight';
        words[9] = 'Nine';
        words[10] = 'Ten';
        words[11] = 'Eleven';
        words[12] = 'Twelve';
        words[13] = 'Thirteen';
        words[14] = 'Fourteen';
        words[15] = 'Fifteen';
        words[16] = 'Sixteen';
        words[17] = 'Seventeen';
        words[18] = 'Eighteen';
        words[19] = 'Nineteen';
        words[20] = 'Twenty';
        words[30] = 'Thirty';
        words[40] = 'Forty';
        words[50] = 'Fifty';
        words[60] = 'Sixty';
        words[70] = 'Seventy';
        words[80] = 'Eighty';
        words[90] = 'Ninety';
        amount = Math.abs(amount).toString();
        var atemp = amount.split(".");
        var number = atemp[0].split(",").join("");
        var n_length = number.length;
        var words_string = "";
        if (n_length <= 9) {
            var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
            var received_n_array = new Array();
            for (var i = 0; i < n_length; i++) {
                received_n_array[i] = number.substr(i, 1);
            }
            for (var i = 9 - n_length, j = 0; i < 9; i++ , j++) {
                n_array[i] = received_n_array[j];
            }
            for (var i = 0, j = 1; i < 9; i++ , j++) {
                if (i === 0 || i === 2 || i === 4 || i === 7) {
                    if (n_array[i] == 1) {
                        n_array[j] = 10 + parseInt(n_array[j]);
                        n_array[i] = 0;
                    }
                }
            }
            let value = "";
            for (var i = 0; i < 9; i++) {
                if (i === 0 || i === 2 || i === 4 || i === 7) {
                    value = n_array[i] * 10;
                } else {
                    value = n_array[i];
                }
                if (value !== 0) {
                    words_string += words[value] + " ";
                }
                if ((i === 1 && value !== 0) || (i === 0 && value !== 0 && n_array[i + 1] === 0)) {
                    words_string += "Crores ";
                }
                if ((i === 3 && value !== 0) || (i === 2 && value !== 0 && n_array[i + 1] === 0)) {
                    words_string += "Lakhs ";
                }
                if ((i === 5 && value !== 0) || (i === 4 && value !== 0 && n_array[i + 1] === 0)) {
                    words_string += "Thousand ";
                }
                if (i === 6 && value !== 0 && (n_array[i + 1] !== 0 && n_array[i + 2] !== 0)) {
                    words_string += "Hundred and ";
                } else if (i === 6 && value !== 0) {
                    words_string += "Hundred ";
                }
            }
            words_string = words_string.split("  ").join(" ");
        }
        return words_string + "Rupees Only";
    }
}

export function addDays(days) {
    return (dispatch) => {
        var date = new Date();
        date.setDate(date.getDate() + days);
        return date;
    }
}

export function disableNetwork() {
    return (dispatch) => {
        database.disableNetwork();
       console.log("Disable Network");
    }
}

export function enableNetwork() {
    return (dispatch) => {
        database.enableNetwork();
        console.log("Enable Network");
    }
}

export function saveConnectionStatus(status, action) {
    console.log(status);
    return (dispatch, getState) => {
        let newConnectionStatus = database.collection("ConnectionStatus").doc();
        let connectionStatus = {
            Status: status === true ? 'connected' : 'disconnected',
            Action : action,
            ActionOn : firebase.firestore.FieldValue.serverTimestamp(),
            ActionByEmailID: getState().user.user ? getState().user.user.EmailID : "",
            RegistrationID : getState().user.user ? getState().user.user.RegistrationID :""
        }
        if(connectionStatus.Status === 'connected')
        {
            database.enableNetwork();
        }
        else{
            database.disableNetwork();
        }
        newConnectionStatus.set(connectionStatus);
        dispatch({ type: actionTypes.GET_CONNECTION_STATUS, connectionStatus });
        return newConnectionStatus.id;
    }
};