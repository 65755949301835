import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import { withRouter } from 'react-router-dom';
import ImportMaster from "../ImportMaster";
import Confirm from 'react-confirm-bootstrap';

class NewProductTags extends Component {

    constructor() {
        super()
        this.state = {
            saving: false,
            name: '',
            description: '',
            nameError: '',
            optionError:'',
            key: '',
            showImport: false,
            records: []
        }
        this.onSaveTags = this.onSaveTags.bind(this);
        this.onDeleteItems = this.onDeleteItems.bind(this);
    }

    componentDidMount() {
        const key = this.props.match.params.key;
        this.setState({ key: key });
        console.log(key);
        if (key) {
            this.props.getProductTagDetails(key).then((tag) => {
                console.log(JSON.stringify(tag))
                this.setState({
                    name: tag.Name,
                    description: tag.Description,
                    records: tag.Products
                })
            })
        }
    }

    onSaveTags() {
        this.setState({ saving: true })
        if (this.state.name.trim() === "") {
            this.setState({ nameError: "Please provide Tag name", saving: false })
            return
        }
        let tag = {
            Name: this.state.name,
            Description: this.state.description,
            RegistrationID: this.props.user.user.RegistrationID,
            Products: this.state.records,
            IsDeleted: false,
        }
        const key = this.state.key ? this.state.key : "";

        this.props.saveProductTag(key, tag).then(() => {
            setTimeout(function () { //Start the timer
                this.props.getToastr("tag saved successfully")
                this.setState({ saving: false })
                .push('/home/catalogue/tags')
            }.bind(this), 3000)
        })

    }

    onDeleteItems() {
        this.setState({ records: [] })
    }

    render() {

        const styleEditor = {
            width: "100%",
            height: "150px",
            font_size: "14px",
            line_height: "18px",
            border: "1px solid #dddddd",
            padding: "10px"
        }
        const modalWidth = {
            width: '500px'
        }

        return <div className="content-wrapper">
            <section className="content-header">
                <h1>Product Group</h1>
            </section>
            <section className="content">
                <div className="box">
                    <div className="box-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className={this.state.nameError ? "form-group has-error" : "form-group "}>
                                    <label >Product Group Name</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ name: evt.target.value, nameError: "" })}
                                        value={this.state.name}
                                    />
                                    {this.state.nameError && <span class="help-block">Please provide Group name</span>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group" >
                                    <label >Description</label>
                                    <input className="text" className="form-control"
                                        onChange={(evt) => this.setState({ description: evt.target.value })}
                                        value={this.state.description}
                                    >
                                    </input>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group" >
                                    {this.state.records.length > 0 &&
                                        <label >Product(s) ({this.state.records.length})&nbsp;
                                    <Confirm
                                                onConfirm={() => this.onDeleteItems()}
                                                body="Are you sure you would like to remove uploaded product(s)?"
                                                confirmText="Yes"
                                                title="Item Delete">
                                                <button className="btn btn-default btn-flat"><i class="fa fa-trash-o"></i></button>
                                            </Confirm>
                                        </label>
                                    }
                                    {this.state.records.length > 0 && <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>SKU</th>
                                                <th>Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.records.map((product) =>
                                                <tr>
                                                    <td>{product.SKU}
                                                    </td>
                                                    <td>{product.Name}
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>}
                                    {this.state.records.length === 0 &&
                                        <button type="submit" className="btn btn-flat btn-md btn-default pull-left"
                                            onClick={() => this.setState({
                                                showImport: true
                                            })}>Upload Product(s)</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box-footer">
                        <Link to="/home/catalogue/tags" type="submit" className="btn btn-flat btn-md btn-default pull-left">Cancel</Link>
                        <button type="submit" className="btn btn-flat btn-md btn-success pull-right"
                            onClick={this.onSaveTags} disabled={this.state.saving}>Save</button>
                    </div>
                </div>
            </section>
            <Modal open={this.state.showImport} onClose={() => this.setState({ showImport: false })} center>
                <div style={modalWidth}>
                    <ImportMaster   {...this.props}
                        importType="producttags"
                        onClose={() => this.setState({ showImport: false })}
                        onImportCompletion={(records) => this.setState({ records: records, showImport: false })}
                    />
                </div>
            </Modal>
        </div>
    }
}

export default withRouter(NewProductTags)