import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Loader from '../Loader'
import Pagination from 'react-js-pagination'

class UniqueNoLedger extends Component{
    constructor(){
        super()
        this.state = {
            uniqueNoLedger : [],
            loading: true
        }
        this.loadUniqueNoLedger =  this.loadUniqueNoLedger.bind(this)
    }
    componentDidMount(){
        // console.log(this.props.location.state.Product.key)
        this.loadUniqueNoLedger()
    }

    loadUniqueNoLedger(){
        this.setState({ loading: true })
        this.props.getUniqueNoLedger(this.props.location.state.Product.key).then((result)=>{
            // console.log(JSON.stringify(result))
            this.setState({uniqueNoLedger:result, loading: false})
        })
    }

    render(){
        return <div className="content-wrapper">
            <section className="content">
                <div className="row">
                    <div className="col-md-12">
                        <div className="box">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6">
                                        <font style={{ fontSize: "30px" }}>Unique No Ledger</font>
                                    </div>
                                    <div className="col-md-6">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={() => this.props.history.push({ pathname: "/home/reports/uniqueno" })}
                                        ><i className="fa fa-arrow-left"></i> Back</button>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                            <div className="row">
                                    <div className="col-md-12">
                                        {this.state.loading && <Loader show={this.state.loading} />}
                                        {!this.state.loading &&
                                            <table className="table table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Store Name</th>
                                                        <th>Brand</th>
                                                        <th>Category</th>
                                                        <th>SubCategory</th>
                                                        <th>SKU</th>
                                                        <th>Barcode</th>
                                                        <th>Product Name</th>
                                                        <th>Supply Price</th>
                                                        <th>Unique No 1</th>
                                                        <th>Unique No 2</th>
                                                        <th>Unique No 3</th>
                                                        <th>Unique No 4</th>
                                                        <th>Inward Amount</th>
                                                        <th>Outward Amount</th>
                                                        <th>Transaction No</th>
                                                        <th>Transaction Type</th>
                                                        <th>Transaction Date</th>
                                                        <th>Movement</th>
                                                    </tr>
                                                </thead>
                                                <tbody> 
                                                {this.state.uniqueNoLedger.map((ledger) =>                                                
                                                    <tr>
                                                        <td>{this.props.user.store.StoreName}</td>
                                                        <td>{ledger.ProductBrand}</td>
                                                        <td>{ledger.ProductCategory}</td>
                                                        <td>{ledger.ProductSubCategory}</td>
                                                        <td>{ledger.ProductSKU}</td>
                                                        <td>{ledger.ProductBarCode}</td>
                                                        <td>{ledger.ProductName}</td>
                                                        <td>{ledger.SupplyPrice}</td>
                                                        <td>{ledger.UniqueNo1}</td>
                                                        <td>{ledger.UniqueNo2}</td>
                                                        <td>{ledger.UniqueNo3}</td>
                                                        <td>{ledger.UniqueNo4}</td> 
                                                        <td>{ledger.InWardAmount}</td>
                                                        <td>{ledger.OutWardAmount}</td>
                                                        <td>{ledger.TransactionNo}</td>
                                                        <td>{ledger.TransactionType}</td>
                                                        <td>{(new Date(ledger.ActionOn.seconds*1000)).toString().substring(0,24)}</td>
                                                        <td>{ledger.Movement}</td>                                                       
                                                    </tr>
                                                    )}
                                                </tbody>
                                            </table>}
                                        {this.state.totalItemsCount > 0 && <div className="pull-left" style={{ marginTop: "25px" }}>
                                            {this.state.paginationStatus}
                                        </div>}
                                        <div className="pull-right">
                                            <Pagination
                                                activePage={this.state.activePage} //this.state.activePage
                                                itemsCountPerPage={this.state.itemsCountPerPage}
                                                totalItemsCount={this.state.totalItemsCount}
                                                pageRangeDisplayed={2}
                                                onChange={this.handlePageChange}
                                            />
                                        </div>
                                    </div></div>
                            </div>
                        </div>
                    </div></div></section></div>
    }
}

export default withRouter(UniqueNoLedger)