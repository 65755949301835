import React, { Component } from 'react';
import * as actionTypes from '../../../actionTypes'
import { withRouter } from 'react-router-dom'
import CreatableSelect from 'react-select'
import { auth } from '../../../firebase'


class EmployeeAccess extends Component {

    constructor() {
        super()
        this.state = {
            employee: {},
            employeeLogin: '',
            associatedStores: [],
            employeePassword: '',
            employeeConfirmPassword: '',
            employeeRole: '',
            employeeAllStores: false,
            employeeSpecificStore: false,
            employeeLoginError: false,
            employeePasswordError: false,
            employeeConfirmPasswordError: false,
            employeeRoleError: false,
            employeeOptionError: false,
            employeeStoreError: false,
            action: '',
            key: '',
            stores: []
        }
        this.onCancelClick = this.onCancelClick.bind(this)
        this.handleOnChange = this.handleOnChange.bind(this)
        this.onSaveEmployeeAccess = this.onSaveEmployeeAccess.bind(this)
    }

    componentDidMount() {
        this.setState({employeeLogin:this.props.employee.EmailID});
        this.props.getStores(this.props.user.user.RegistrationID, 0, 10000, "active", "", "", "", "", "", "", ""
            , "", "", "", "").then((result) => {

                let stores = result.searchResult;

                const storeList = [];

                stores.map((store) => {
                    let obj = {
                        value: store.key, label: store.StoreName
                    }
                    storeList.push(obj);
                });
                this.setState({ stores: storeList })
            });
    }

    onSaveEmployeeAccess() {
        let employeeLoginError = false
        let employeePasswordError = false
        let employeeConfirmPasswordError = false
        let employeeRoleError = false
        let employeeStoreError = false
        let employeeOptionError = false

        if (this.state.employeeLogin.trim() === '') { employeeLoginError = true }
        if (this.state.employeePassword.trim() === '') { employeePasswordError = true }
        if (this.state.employeeConfirmPassword.trim() === '') { employeeConfirmPasswordError = true }
        if (this.state.employeeRole.trim() === '') { employeeRoleError = true }


        // if (this.state.employeeRole.trim() != "Power User") {
        //     if (this.state.employeeSpecificStore === false && this.state.employeeAllStores === false) {
        //         employeeOptionError = true;
        //     }
        // }

        if (this.state.employeeRole.trim() != "Power User") {
            if (this.state.associatedStores.length === 0) {
                employeeStoreError = true;
            }
        }

        if (employeeLoginError || employeePasswordError || employeeConfirmPasswordError || employeeRoleError || employeeStoreError) {
            this.setState({
                employeeLoginError: employeeLoginError,
                employeePasswordError: employeePasswordError,
                employeeConfirmPasswordError: employeeConfirmPasswordError,
                employeeRoleError: employeeRoleError,
                employeeStoreError: employeeStoreError
            })
            return;
        }

        auth.createUserWithEmailAndPassword(this.state.employeeLogin, this.state.employeePassword).then((result) => {
            const uid = result.user.uid;
            const employee = this.props.employee;
            const user = {};
            user.EmailID = this.state.employeeLogin;
            user.Name = employee.EmployeeFirstName.trim() + ' ' + employee.EmployeeLastName.trim();
            user.Name = user.Name.trim();
            user.RegistrationID = employee.RegistrationID;
            user.UID = uid;
            user.Role = this.state.employeeRole;
            user.Stores = this.state.associatedStores;

            this.props.createUser(user).then(() => {
                employee.UID = uid;
                this.props.updateEmployee(employee, employee.employeeID).then(() => {
                    this.setState({ showModal: false });
                    this.props.getToastr("Access granted successfully!!!!");
                });
            });
        }).catch(error => {
            this.props.getToastr("Error:" + error);
        });
    }

    onCancelClick() {
        if (this.state.action === actionTypes.EMPLOYEE_EDIT) {
            this.props.history.push({
                pathname: "/home/settings/employees/asignaccess",
                state: { key: this.state.key }
            })
        }
        else {
            this.props.history.push("/home/settings/employees")
        }
    }

    handleOnChange(storeList) {
        console.log(JSON.stringify(storeList));
        let objList = [];
        this.setState.associatedStores = [];
        storeList.map((store) => {
            let obj = {
                StoreKey: store.value, StoreName: store.label
            }
            objList.push(obj);
        });
        this.setState({ associatedStores: objList });
        console.log("Associated Stores", JSON.stringify(this.state.associatedStores));
    };

    render() {
        return <div>
            <h3 style={{ marginTop: "0px" }}>Assign Access</h3>
            <div className="box-body">
                <div className="row">
                    <div className="col-md-4">
                        <div className={this.state.employeeLoginError ? "form-group has-error" : "form-group "} >
                            <div className="form-group" >
                                <label>Login ID</label>
                                <input type="email" className="form-control"
                                    onChange={(evt) => this.setState({ employeeLogin: evt.target.value, employeeLoginError: false })}
                                    value={this.state.employeeLogin}
                                />
                                {this.state.employeeLoginError && <span class="help-block">Please provide login credintials</span>}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className={this.state.employeePasswordError ? "form-group has-error" : "form-group "} >
                            <div className="form-group">
                                <label>Password</label>
                                <input type="Password" className="form-control"
                                    onChange={(evt) => this.setState({ employeePassword: evt.target.value, employeePasswordError: false })}
                                    value={this.state.employeePassword}
                                />
                                {this.state.employeePasswordError && <span class="help-block">Please provide Password</span>}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className={this.state.employeeConfirmPasswordError ? "form-group has-error" : "form-group "} >
                            <div className="form-group" >
                                <label >Confirm Password</label>
                                <input type="Password" className="form-control"
                                    onChange={(evt) => this.setState({ employeeConfirmPassword: evt.target.value, employeeConfirmPasswordError: false })}
                                    value={this.state.employeeConfirmPassword}
                                />
                                {this.state.employeePassword !== this.state.employeeConfirmPassword && <span class="help-block">Password and Confirm Password should be same.</span>}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group" >
                            <div className={this.state.employeeRoleError ? "form-group has-error" : "form-group "} >
                                <label >Role</label>
                                <select type="text" className="form-control"
                                    onChange={(evt) => this.setState({ employeeRole: evt.target.value, employeeRoleError: false })}
                                    value={this.state.employeeRole}>
                                    <option>Select Role</option>
                                    <option>Power User</option>
                                    <option>Store Owner</option>
                                    <option>Store Manager</option>
                                    <option>Store Cashier</option>
                                </select>
                                {this.state.employeeRoleError && <span class="help-block">Please Enter Employee Role</span>}
                            </div>
                        </div>
                    </div>
                    {this.state.employeeRole !== "Power User" && this.state.employeeRole != "" &&
                        <div>
                            <div className="col-md-4">
                                <div className="form-group" >
                                    <label >Stores</label>
                                    <CreatableSelect
                                        options={this.state.stores}
                                        value={this.state.store}
                                        onChange={this.handleOnChange}
                                        isMulti={true}
                                    />
                                    {this.state.employeeStoreError && <span class="help-block">Please Select Store</span>}
                                </div>
                            </div>
                        </div>}
                </div>
            </div>
            <div className="box-footer">
                <button onClick={() => this.onCancelClick()} className="btn btn-lg btn-default btn-flat">Cancel</button>
                <button type="submit" className="btn btn-lg btn-primary pull-right btn-flat" onClick={this.onSaveEmployeeAccess}>Save</button>
            </div>
        </div>
    }
}
export default withRouter(EmployeeAccess)