import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

class SalesDashboard extends Component {

    constructor() {
        super();
        this.state = {
            dashBoardHeader: {}
            , expenseHeader: {}
            , categories: []
            , payments: []
            , productTypes: []
            , products: []
            , store: {}
            , storeList: []
            , cashregister: {}
            , cashregisterlist: []
            , employees: []
            , stores : []
            , storeList: []
            , searchStore: ""
            , searchCashRegister: ""
            , searchTransactionType: ""
            , searchTransactionFromDate: new Date().toISOString().split('T')[0]
            , searchTransactionToDate: new Date().toISOString().split('T')[0],
        };

        this.loadDashboardHeaders = this.loadDashboardHeaders.bind(this);
        this.loadExpenseHeaders = this.loadExpenseHeaders.bind(this);
        this.loadCategoryWiseSales = this.loadCategoryWiseSales.bind(this);
        this.loadProductWiseSales = this.loadProductWiseSales.bind(this);
        this.loadProductTypeData = this.loadProductTypeData.bind(this);
        this.loadEmployeeWiseSales = this.loadEmployeeWiseSales.bind(this);
        this.loadSalesDashboard = this.loadSalesDashboard.bind(this);
        this.getRandomColor = this.getRandomColor.bind(this);
        this.loadPaymentWiseDashboardData = this.loadPaymentWiseDashboardData.bind(this);
        this.onSearchClick = this.onSearchClick.bind(this)
        this.onClearSearch = this.onClearSearch.bind(this)
        this.loadStore = this.loadStore.bind(this)
        this.onStoreChange = this.onStoreChange.bind(this)
        this.loadCashregister = this.loadCashregister.bind(this)
        this.loadSalesDashboardData = this.loadSalesDashboardData.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.onStoreChange = this.onStoreChange.bind(this)
    };

    componentDidMount() {
        this.setState({ storeID: this.props.user.store.key })
        let storeList = []
        storeList.push({ key: this.props.user.store.key })

        this.props.getUserStores(this.props.user.user.RegistrationID, "").then((stores) => {                
            this.setState({ stores: stores })
        });
    this.setState({ storeList: storeList, storeID: this.props.user.store.key });

        this.loadSalesDashboard(storeList, this.state.searchCashRegister,
            this.state.searchTransactionType, this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate);

    };

    getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }

    loadDashboardHeaders(storeList, searchCashRegister, searchTransactionType,
        searchTransactionFromDate, searchTransactionToDate) {
        this.props.getDashboardHeaders(this.props.user.user.RegistrationID, storeList, searchCashRegister
            , searchTransactionType, searchTransactionFromDate, searchTransactionToDate)
            .then((result) => {
                this.setState({ dashBoardHeader: result.searchResult, totalItemsCount: result.totalItemsCount })
            });
    };

    loadExpenseHeaders(storeList, searchCashRegister, searchTransactionType,
        searchTransactionFromDate, searchTransactionToDate) {
        this.props.getExpensesforSalesDashboard(this.props.user.user.RegistrationID, storeList, searchCashRegister
            , searchTransactionFromDate, searchTransactionToDate)
            .then((result) => {
                this.setState({ expenseHeader: result.searchResult, totalItemsCount: result.totalItemsCount })
            });
    };

    loadCategoryWiseSales(storeList, searchCashRegister, searchTransactionType,
        searchTransactionFromDate, searchTransactionToDate) {
        this.props.getCategoryWiseSales(this.props.user.user.RegistrationID, storeList, searchCashRegister
            , searchTransactionType, searchTransactionFromDate, searchTransactionToDate)
            .then((result) => {
                let categories = result.searchResult.sort((a, b) => a.total_sales > b.total_sales)
                this.setState({ categories: categories, totalItemsCount: result.totalItemsCount })
            });
    };

    loadProductWiseSales(storeList, searchCashRegister, searchTransactionType,
        searchTransactionFromDate, searchTransactionToDate) {
        this.props.getProductWiseSales(this.props.user.user.RegistrationID, storeList, searchCashRegister
            , searchTransactionType, searchTransactionFromDate, searchTransactionToDate)
            .then((result) => {
                let products = result.searchResult.sort((a, b) => a.total_sales > b.total_sales)
                this.setState({ products: products, totalItemsCount: result.totalItemsCount })
            });
    };

    loadEmployeeWiseSales(storeList, searchCashRegister, searchTransactionType,
        searchTransactionFromDate, searchTransactionToDate) {
        this.props.getEmployeeWiseSales(this.props.user.user.RegistrationID, storeList, searchCashRegister
            , searchTransactionType, searchTransactionFromDate, searchTransactionToDate)
            .then((result) => {
                let employees = result.searchResult.sort((a, b) => a.total_sales > b.total_sales)
                this.setState({ employees: employees, totalItemsCount: result.totalItemsCount })
            });
    };

    loadSalesDashboardData(storeList, searchCashRegister, searchTransactionType,
        searchTransactionFromDate, searchTransactionToDate) {
        this.props.getSalesDashboard(this.props.user.user.RegistrationID, storeList, searchCashRegister
            , searchTransactionType, searchTransactionFromDate, searchTransactionToDate)
            .then((result) => {
                console.log("Get Sales Dashboard - Data", JSON.stringify(result.searchResult))

                let salesDashboardData = {
                    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                    datasets: [
                        {
                            label: 'Total Sales',
                            backgroundColor: '#00C0EF',
                            borderColor: '#00a65a',
                            hoverBorderColor: '#00a65a',
                            hoverBackgroundColor: '#00C0EF',
                            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                        }
                    ]
                };

                if (result.searchResult.buckets.January) {
                    salesDashboardData.datasets[0].data[0] = result.searchResult.buckets.January.total_sales.value;
                }
                if (result.searchResult.buckets.February) {
                    salesDashboardData.datasets[0].data[1] = result.searchResult.buckets.February.total_sales.value;
                }
                if (result.searchResult.buckets.March) {
                    salesDashboardData.datasets[0].data[2] = result.searchResult.buckets.March.total_sales.value;
                }
                if (result.searchResult.buckets.April) {
                    salesDashboardData.datasets[0].data[3] = result.searchResult.buckets.April.total_sales.value;
                }
                if (result.searchResult.buckets.May) {
                    salesDashboardData.datasets[0].data[4] = result.searchResult.buckets.May.total_sales.value;
                }
                if (result.searchResult.buckets.June) {
                    salesDashboardData.datasets[0].data[5] = result.searchResult.buckets.June.total_sales.value;
                }
                if (result.searchResult.buckets.July) {
                    salesDashboardData.datasets[0].data[6] = result.searchResult.buckets.July.total_sales.value;
                }
                if (result.searchResult.buckets.August) {
                    salesDashboardData.datasets[0].data[7] = result.searchResult.buckets.August.total_sales.value;
                }
                if (result.searchResult.buckets.September) {
                    salesDashboardData.datasets[0].data[8] = result.searchResult.buckets.September.total_sales.value;
                }
                if (result.searchResult.buckets.October) {
                    salesDashboardData.datasets[0].data[9] = result.searchResult.buckets.October.total_sales.value;
                }
                if (result.searchResult.buckets.November) {
                    salesDashboardData.datasets[0].data[10] = result.searchResult.buckets.November.total_sales.value;
                }
                if (result.searchResult.buckets.December) {
                    salesDashboardData.datasets[0].data[11] = result.searchResult.buckets.December.total_sales.value;
                }
                // console.log(salesDashboardData);
                this.setState({ salesDashboardData: salesDashboardData });
            });
    };

    loadPaymentWiseDashboardData(storeList, searchCashRegister, searchTransactionType,
        searchTransactionFromDate, searchTransactionToDate) {
        this.props.getPaymentModeTransactionTypeWiseSales(this.props.user.user.RegistrationID, storeList,
            searchTransactionType, searchTransactionFromDate, searchTransactionToDate,true)
            .then((result) => {
                let paymentData = []

                for (let index = 0; index < result.searchResult.length; index++) {

                    const payment = result.searchResult[index];

                    var obj = {
                        "Payment Mode": payment.name
                    }

                    this.props.config.TransactionTypes.filter((e) => e.PaymentModeChecked === true && e.SalesImpactChecked === true && !e.IsDeleted).map((e) => {

                        obj[e.TransactionType] = 0;
                        for (let index1 = 0; index1 < payment.transactionTypes.length; index1++) {

                            if (e.TransactionType === payment.transactionTypes[index1].name) {
                                obj[e.TransactionType] = payment.transactionTypes[index1].total_tran_amount
                            }
                        }
                    })
                    obj["Total"] = payment.total_mode_amount
                    paymentData.push(obj);
                }
                //console.log("Level 1 Data", JSON.stringify(paymentData));

                var col = [];
                for (var i = 0; i < paymentData.length; i++) {
                    for (var key in paymentData[i]) {
                        if (col.indexOf(key) === -1) {
                            col.push(key);
                        }
                    }
                }
                // console.log("columns", col)
                var result = "<table class='table table-bordered'><thead><tr>";
                for (var i = 0; i < col.length; i++) {
                    result += "<th>";
                    result += col[i];
                    result += "</th>";
                }
                result += "</tr></thead>";

                // ADD JSON DATA TO THE TABLE AS ROWS.
                result += "<tbody>";
                for (var i = 0; i < paymentData.length; i++) {
                    result += "<tr>";
                    for (var j = 0; j < col.length; j++) {
                        result += "<td>";
                        result += paymentData[i][col[j]] == undefined ? 0 : paymentData[i][col[j]];
                        result += "</td>";
                    }
                    result += "</tr>";
                }
                result += "</tbody>";
                result += "</table>";
                // console.log("table", result)

                this.setState({payments: result})
            });
    };

    loadProductTypeData(storeList, searchCashRegister, searchTransactionType,
        searchTransactionFromDate, searchTransactionToDate) {
        this.props.getProductTypeWiseSales(this.props.user.user.RegistrationID, storeList, searchCashRegister, searchTransactionType, searchTransactionFromDate, searchTransactionToDate).then((result) => {
            let productTypes = result.searchResult.sort((a, b) => a.total_sales > b.total_sales)
            this.setState({ productTypes: productTypes, totalItemsCount: result.totalItemsCount })
        });
    };

    loadSalesDashboard(storeList, searchCashRegister, searchTransactionType,
        searchTransactionFromDate, searchTransactionToDate) {

        this.loadDashboardHeaders(storeList, searchCashRegister, searchTransactionType,
            searchTransactionFromDate, searchTransactionToDate);

        this.loadExpenseHeaders(storeList, searchCashRegister, searchTransactionType,
            searchTransactionFromDate, searchTransactionToDate);

        this.loadCategoryWiseSales(storeList, searchCashRegister, searchTransactionType,
            searchTransactionFromDate, searchTransactionToDate);

        this.loadEmployeeWiseSales(storeList, searchCashRegister, searchTransactionType,
            searchTransactionFromDate, searchTransactionToDate);

        this.loadProductTypeData(storeList, searchCashRegister, searchTransactionType,
            searchTransactionFromDate, searchTransactionToDate);
      
        this.loadPaymentWiseDashboardData(storeList, searchCashRegister,
            searchTransactionType, searchTransactionFromDate,
            searchTransactionToDate);
    };

    onSearchClick() {
        this.setState({ searchFrom: 0, loading: true})
        this.loadSalesDashboard(this.state.storeList, this.state.searchCashRegister,
            this.state.searchTransactionType, this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate)
    };

    onClearSearch() {
        let storeList = []
        storeList.push({ key: this.props.user.store.key});

        this.setState({
            storeList : storeList, searchCashRegister: '', searchTransactionType: '',
            searchTransactionFromDate: new Date().toISOString().split('T')[0], searchTransactionToDate: new Date().toISOString().split('T')[0]
        })
        this.loadSalesDashboard(storeList, "", "", this.state.searchTransactionFromDate, this.state.searchTransactionToDate)
    };

    loadCashregister(storeID) {
        this.props.getCashRegisters(storeID).then((cashregisters) => {
            console.log("cashregister", cashregisters);
            this.setState({ cashregisterlist: cashregisters })
        })
    };

    loadStore() {
        this.props.getStores(this.props.user.user.RegistrationID, 0, 10000, 'active',
            '', '', '', '', '', '', '', '', '', '', '').then((data) => {
                console.log("stores", data.searchResult);
                this.setState({ storeList: data.searchResult })
            })
    };

    // onStoreChange(e) {
    //     let store = {
    //         key: e.target.value,
    //         StoreName: e.target.options[e.target.selectedIndex].text
    //     }
    //     this.loadCashregister(store.key);
    // }

    onStoreChange(e){
        let storeList = []
        console.log("key", e.target.value);
        if (e.target.value != "") {
            storeList.push({key: e.target.value})
        }
        else
        {
            for (let i = 0; i < this.state.stores.length; i++) {
                const store = this.state.stores[i];
                storeList.push({ key: store.key })
            }
        }
        console.log("storeList", JSON.stringify(storeList));
        this.setState({ storeList: storeList, storeID: e.target.value })
        this.loadCashregister(storeList);
    }

    render() {
        return <div className="content-wrapper">
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4">
                                <font style={{ fontSize: "30px", marginBottom: "4px" }}>Sales Dashboard</font>
                            </div>
                            <div className="col-md-8">
                                <div className="input-group pull-right">
                                    <select type="text" className="form-control" style={{ width: "30%" }}
                                        value={this.state.storeID}
                                        onChange={this.onStoreChange}>
                                        <option value="">All Stores</option>
                                        {this.state.stores.filter((e) => !e.IsDeleted).map((store) =>
                                            <option value={store.key}>{store.StoreCode}-{store.StoreName}</option>
                                        )}
                                    </select>
                                    <input type="date" className="form-control" style={{ width: "30%" }}
                                        onChange={(evt) => this.setState({ searchTransactionFromDate: evt.target.value })}
                                        value={this.state.searchTransactionFromDate} onKeyPress={this.handleKeyPress} />
                                    <input type="date" className="form-control" style={{ width: "30%" }}
                                        onChange={(evt) => this.setState({ searchTransactionToDate: evt.target.value })}
                                        value={this.state.searchTransactionToDate} onKeyPress={this.handleKeyPress} />
                                    <button type="button" className="btn btn-primary btn-flat btn-md fa fa-search" style={{ padding: "8px" }}
                                        onClick={this.onSearchClick}></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="col-md-3 col-sm-6 col-xs-12">
                            <div className="info-box" style={{ marginTop: "10px" }}>
                                <span className="info-box-icon bg-aqua"><i className="fa fa-file-o"></i></span>
                                <div className="info-box-content">
                                    <span className="info-box-text" style={{ fontSize: "13px" }}>Total<br /> Transactions / Qty</span>
                                    <span className="info-box-number">{this.state.dashBoardHeader.total_transactions + " / " + this.state.dashBoardHeader.total_qty}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12">
                            <div className="info-box" style={{ marginTop: "10px" }}>
                                <span className="info-box-icon bg-green"><i className="fa fa-money"></i></span>
                                <div className="info-box-content">
                                    <span className="info-box-text" style={{ fontSize: "13px" }}>Total<br /> Sales</span>
                                    <span className="info-box-number">{this.state.dashBoardHeader.total_sales}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12">
                            <div className="info-box" style={{ marginTop: "10px" }}>
                                <span className="info-box-icon bg-blue"><i className="fa fa-cart-plus"></i></span>

                                <div className="info-box-content">
                                    <span className="info-box-text" style={{ fontSize: "13px" }}>Total<br /> Expenses</span>
                                    <span className="info-box-number">{this.state.expenseHeader.total_expenses}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-xs-12">
                            <div className="info-box" style={{ marginTop: "10px" }}>
                                <span className="info-box-icon bg-yellow"><i className="fa fa-users"></i></span>
                                <div className="info-box-content">
                                    <span className="info-box-text" style={{ fontSize: "13px" }}>Total<br /> Customers</span>
                                    <span className="info-box-number">{this.state.dashBoardHeader.total_customers}</span>
                                </div>
                            </div>
                        </div>
                        <div className="box-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="box box-success">
                                        <div className="box-header with-border">
                                            <h3 className="box-title">Payment-Modes Transaction Type Wise</h3>
                                        </div>
                                        <div className="box-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    {ReactHtmlParser(this.state.payments)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <div className="box box-success">
                                        <div className="box-header with-border">
                                            <h3 className="box-title">Category-Wise</h3>
                                        </div>
                                        <div className="box-body">
                                            <div className="table-responsive">
                                                {this.state.categories.length > 0 && <table className="table no-margin">
                                                    <thead>
                                                        <tr>
                                                            <th>Category</th>
                                                            <th>Total Sales</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.categories.map((category) =>
                                                            <tr>
                                                                <td>{category.name}</td>
                                                                <td>{category.total_sales}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>}
                                                {this.state.categories.length === 0 && "No records found"}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <div className="box box-success">
                                        <div className="box-header with-border">
                                            <h3 className="box-title">Product Type-Wise</h3>
                                        </div>
                                        <div className="box-body">
                                            <div className="table-responsive">
                                                {this.state.productTypes.length > 0 && <table className="table no-margin">
                                                    <thead>
                                                        <tr>
                                                            <th>Product Type</th>
                                                            <th>Total Sales</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.productTypes.map((productType) =>
                                                            <tr>
                                                                <td>{productType.name}</td>
                                                                <td>{productType.total_sales}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>}
                                                {this.state.productTypes.length === 0 && "No records found"}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <div className="box box-success">
                                        <div className="box-header with-border">
                                            <h3 className="box-title">Employee-Wise</h3>
                                        </div>
                                        <div className="box-body">
                                            <div className="table-responsive">
                                                {this.state.employees.length > 0 && <table className="table no-margin">
                                                    <thead>
                                                        <tr>
                                                            <th>Employee</th>
                                                            <th>Total Sales</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.employees.map((employee) =>
                                                            <tr>
                                                                <td>{employee.name}</td>
                                                                <td>{employee.total_sales}</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>}
                                                {this.state.employees.length === 0 && "No records found"}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    };
};
export default SalesDashboard;