import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Confirm from 'react-confirm-bootstrap'
import { withRouter } from 'react-router-dom'
import * as constatnts from '../../Constatnts'
import { isNullOrUndefined } from 'util';
import Loader from '../Loader'

class SubCategoryWiseDetail extends Component {
    constructor() {
        super()
        this.state = {
            itemSold: [],
            searchStoreName: '',
            searchCategory: '',
            searchSubCategory: '',
            searchTransactionFromDate: new Date().toISOString().split('T')[0],
            searchTransactionToDate: new Date().toISOString().split('T')[0],
            exportCalled: false,
            loading: true,
        }
        this.onSearchClick = this.onSearchClick.bind(this)
        this.loadItemSoldReportData = this.loadItemSoldReportData.bind(this)
        this.onClearSearch = this.onClearSearch.bind(this)
        this.onExportClick = this.onExportClick.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
    }

    componentDidMount() {
        this.loadItemSoldReportData(this.state.searchStoreName, this.state.searchTransactionToDate,
            this.state.searchTransactionFromDate, this.state.searchCategory,
            this.state.searchSubCategory);
    }

    loadItemSoldReportData(searchStoreName, searchTransactionToDate, searchTransactionFromDate, searchCategory, searchSubCategory) {
        this.setState({ loading: true })
        this.props.getSubCategoryItemSoldReportData(this.props.user.user.RegistrationID, searchStoreName, searchTransactionToDate,
            searchTransactionFromDate, searchCategory, searchSubCategory)
            .then((result) => {
                console.log(JSON.stringify(result.searchResult))
                this.setState({ itemSold: result.searchResult, totalItemsCount: result.totalItemsCount, loading: false })
            })

    }

    onClearSearch() {
        this.setState({
            searchStoreName: '', searchTransactionToDate: new Date().toISOString().split('T')[0],
            searchTransactionFromDate: new Date().toISOString().split('T')[0], searchCategory: '', searchSubCategory: '',

        })
        this.loadItemSoldReportData("", this.state.searchTransactionToDate, this.state.searchTransactionFromDate, "", "")
    }

    onExportClick() {
        this.setState({ loading: false, exportCalled: true })
        this.props.getSubCategoryItemSoldReportData(this.props.user.user.RegistrationID,
            this.state.searchStoreName,
            this.state.searchTransactionToDate,
            this.state.searchTransactionFromDate,
            this.state.searchCategory,
            this.state.searchSubCategory,

        )
            .then((result) => {
                console.log(result.searchResult);
                if (isNullOrUndefined(result.searchResult) || result.searchResult.length === 0) {
                    this.props.getToastr("No Record Found")
                    return
                }
                let products = []
                result.searchResult.map((data) => {

                    console.log(JSON.stringify(data))
                    let product = {
                        "Category": data.category,
                        "Sub Category": data.sub_category,
                        "Qty": data.total_quantity,
                        "Discount": data.total_discount,
                        "Taxable Amount": data.total_taxable,
                        "Total Tax": data.total_taxes,
                        "Total Sales": data.total_sales

                    }
                    products.push(product);
                })
                const csvData = constatnts.objToCSV(products);
                this.setState({ loading: false, exportCalled: false })
                const csvBlob = new Blob([csvData], { type: "text/csv" });
                const url = window.URL.createObjectURL(csvBlob);
                const a = document.createElement("a");
                a.setAttribute("hidden", "");
                a.setAttribute("href", url);
                a.setAttribute("download", "subcategory.csv");
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
    }

    onSearchClick() {
        this.loadItemSoldReportData(
            this.state.searchStoreName,
            this.state.searchTransactionToDate,
            this.state.searchTransactionFromDate,
            this.state.searchCategory,
            this.state.searchSubCategory,
        )
    }
    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }
    render() {
        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6">
                                        <font style={{ fontSize: "30px" }}>Sub Category Wise Sold Report</font>
                                    </div>
                                    <div className="col-md-6">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={() => this.props.history.push({ pathname: "/home/reports/allreport" })}
                                        ><i className="fa fa-arrow-left"></i> Back</button>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>Store Name</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchStoreName}
                                                onChange={(evt) => this.setState({ searchStoreName: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Transaction From</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionFromDate: evt.target.value })}
                                                value={this.state.searchTransactionFromDate}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>


                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Transaction To</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionToDate: evt.target.value })}
                                                value={this.state.searchTransactionToDate}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Category</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchCategory}
                                                onChange={(evt) => this.setState({ searchCategory: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Sub Category</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchSubCategory}
                                                onChange={(evt) => this.setState({ searchSubCategory: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    </div>
                                    <div className="row" >
                                    <div className="col-md-12" style={ {marginTop: "-25px"} }>
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginLeft: "5px" }}
                                            onClick={this.onSearchClick}
                                        >Search</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                            onClick={this.onClearSearch} >Clear</button>
                                        <button class="btn btn-default btn-flat btn-md pull-right" 
                                        style={{ marginTop: "25px", marginRight: "5px" }} 
                                        onClick={this.onExportClick} 
                                        > Export <i class="glyphicon glyphicon-download-alt " ></i></button>
                                        {/* <a type="button" className="btn btn-md pull-right" style={{marginTop:"25px"}} onClick={this.showHideFilters}>{this.state.showFilters ? "Less Filter" : "More Filters"}</a> */}
                                    </div>
                                </div>
                                <br></br>
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading &&
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>Store Name</th>
                                                <th>Category</th>
                                                <th>Sub Category</th>
                                                <th>Qty</th>
                                                <th>Discount</th>
                                                <th>Taxable Amount</th>
                                                <th>Total Taxes</th>
                                                <th>Total Sales</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.itemSold.map((item, index) =>
                                                <tr>
                                                    <td>{item.store}</td>
                                                    <td>{item.category}</td>
                                                    <td>{item.sub_category}</td>
                                                    <th>{item.total_quantity}</th>
                                                    <th>{item.total_discount}</th>
                                                    <th>{item.total_taxable}</th>
                                                    <th>{item.total_taxes}</th>
                                                    <th>{item.total_sales}</th>


                                                </tr>
                                            )}
                                        </tbody>
                                    </table>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default withRouter(SubCategoryWiseDetail)