import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CreatableSelect from 'react-select'
import { withRouter } from 'react-router-dom'
import { isNullOrUndefined } from 'util';
import * as constatnts from '../../Constatnts'

class addCampaign extends Component {

    constructor() {
        super()
        this.state = {
            key: '',
            name: '',
            type: '',
            startDate: '',
            cycleOfDays: '',
            endDate: '',
            onDay: '',
            runsEvery: '',
            customerVisited: '',
            customerInDays: '',
            noOfTransaction: '',
            noOfTransactionDays: '',
            amountSpent: '',
            amountSpentDays: '',
            membership: null,
            customerGender: '',
            customerAgeFrom: '',
            customerAgeTo: '',
            walletBalanceFrom: '',
            walletBalanceTo: '',
            event: '',
            receivePaymentFrom: '',
            receivePaymentTo: '',
            placeholder: '',
            message: '',
            campaignStatus: "Processing",
            customerSelected: false,
            transactionSelected: false,
            amountSpentSelected: false,
            membershipSelected: false,
            customerGenderSelected: false,
            customerAgeSelected: false,
            walletBalanceSelected: false,
            eventSelected: false,
            receivePaymentSelected: false,
            membershipList: [],
            specificStoreSelected: false,
            specificCustomerSelected: false,
            allStoresSelected: false,
            allCustomersSelected: false,
            associatedStores: [],
            addedStores: [],
            nameError: '',
            typeError: '',
            startDateError: '',
            cycleOfDaysError: '',
            endDateError: '',
            onDayError: '',
            runsEveryError: '',
            customerVisitedError: '',
            customerInDaysError: '',
            noOfTransactionError: '',
            noOfTransactionDaysError: '',
            amountSpentError: '',
            amountSpentDaysError: '',
            membershipError: '',
            customerGenderError: '',
            customerAgeFromError: '',
            customerAgeToError: '',
            walletBalanceFromError: '',
            walletBalanceToError: '',
            eventError: '',
            receivePaymentFromError: '',
            receivePaymentToError: '',
            optionError: '',
            storeNameError: '',
            stores: [],
            placeholderError: '',
            messageError: '',
            customerOptionError: ''
        }

        this.onSaveCampaign = this.onSaveCampaign.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.onMembershipChange = this.onMembershipChange.bind(this);
        this.loadMembership = this.loadMembership.bind(this);
        this.handleOnTypeChange = this.handleOnTypeChange.bind(this);
        this.handleOnPlaceHolderChange = this.handleOnPlaceHolderChange.bind(this);
        this.onExportCustomers = this.onExportCustomers.bind(this)
        this.customersForTotalQty = this.customersForTotalQty.bind(this)
        this.customersForTotalSpent = this.customersForTotalSpent.bind(this)
    }
    

    componentDidMount() {
        this.loadMembership();
        this.props.getStores(this.props.user.user.RegistrationID, 0, 10000, "active", "", "", "", "", "", "", ""
            , "", "", "", "").then((result) => {

                let stores = result.searchResult;

                const storeList = [];

                stores.map((store) => {
                    let obj = {
                        value: store.key, label: store.StoreName
                    }
                    storeList.push(obj);
                });
                this.setState({ stores: storeList })
            });

        const key = this.props.match.params.key
        if (key) {
            this.setState({ key: key })
            this.props.getCampaignDetails(key).then((campaign) => {
                this.setState({
                    key: key,
                    name: campaign.Name,
                    campaignStaus: campaign.CampaignStatus,
                    type: campaign.Type,
                    startDate: campaign.StartDate,
                    endDate: campaign.EndDate,
                    onDay: campaign.OnDay,
                    runsEvery: campaign.RunsEvery,
                    cycleOfDays: campaign.CycleOfDays,
                    placeholder: campaign.Placeholder,
                    message: campaign.Message,
                    customerVisited: campaign.CustomerVisited,
                    customerInDays: campaign.CustomerInDays,
                    noOfTransaction: campaign.NoOfTransaction,
                    noOfTransactionDays: campaign.NoOfTransactionDays,
                    amountSpent: campaign.AmountSpent,
                    amountSpentDays: campaign.AmountSpentDays,
                    membership: campaign.Membership,
                    customerGender: campaign.CustomerGender,
                    customerAgeFrom: campaign.CustomerAgeFrom,
                    customerAgeTo: campaign.CustomerAgeTo,
                    walletBalanceFrom: campaign.WalletBalanceFrom,
                    walletBalanceTo: campaign.WalletBalanceTo,
                    event: campaign.Event,
                    receivePaymentFrom: campaign.ReceivePaymentFrom,
                    receivePaymentTo: campaign.ReceivePaymentTo,
                    storeName: campaign.StoreName,
                    allStoresSelected: campaign.AllStoresSelected,
                    associatedStores: !isNullOrUndefined(campaign.Stores) ? campaign.Stores : [],
                    customerSelected: campaign.CustomerSelected,
                    allCustomersSelected: campaign.AllCustomersSelected,
                    specificCustomerSelected: campaign.SpecificCustomerSelected,
                    transactionSelected: campaign.TransactionSelected,
                    amountSpentSelected: campaign.AmountSpentSelected,
                    membershipSelected: campaign.MembershipSelected,
                    customerGenderSelected: campaign.CustomerGenderSelected,
                    customerAgeSelected: campaign.CustomerAgeSelected,
                    walletBalanceSelected: campaign.WalletBalanceSelected,
                    eventSelected: campaign.EventSelected,
                    receivePaymentSelected: campaign.ReceivePaymentSelected,
                    specificStoreSelected: campaign.SpecificStoreSelected
                })
            })
        }
    };

    onSaveCampaign() {
        let nameError = ""
        let typeError = ""
        let startDateError = ""
        let endDateError = ""
        let runsEveryError = ""
        let onDayError = ""
        let cycleOfDaysError = ""
        let customerVisitedError = ""
        let customerInDaysError = ""
        let noOfTransactionError = ""
        let noOfTransactionDaysError = ""
        let amountSpentDaysError = ""
        let amountSpentError = ""
        let membershipError = ""
        let customerGenderError = ""
        let customerAgeFromError = ""
        let customerAgeToError = ""
        let walletBalanceFromError = ""
        let walletBalanceToError = ""
        let eventError = ""
        let receivePaymentFromError = ""
        let receivePaymentToError = ""
        let storeNameError = ""
        let placeholderError = ""
        let messageError = ""
        let customerOptionError = ""
        let optionError = ""

        if (this.state.name.trim() === "") {
            nameError = "Please provide Campaign name"
        }


        if (this.state.type.trim() === "") {
            typeError = "Please provide Campaign type"
        }

        if (this.state.type.trim() === "Recurring") {
            if (this.state.startDate.trim() === "") {
                startDateError = "Please provide Campaign Start Date"
            }

            if (this.state.endDate.trim() === "") {
                endDateError = "Please provide Campaign End Date"
            }

            if (this.state.runsEvery.trim() === "") {
                runsEveryError = "Please provide Run Every(in Days)"
            }

            if (this.state.cycleOfDays.trim() === "") {
                cycleOfDaysError = "Please specify Cycle(in Days)"
            }
        }

        if (this.state.type.trim() === "Specific Day") {
            if (this.state.startDate.trim() === "") {
                startDateError = "Please provide Campaign Start Date"
            }

            if (this.state.endDate.trim() === "") {
                endDateError = "Please provide Campaign End Date"
            }

            if (this.state.onDay.trim() === "") {
                onDayError = "Please provide On Day"
            }

            if (this.state.cycleOfDays.trim() === "") {
                cycleOfDaysError = "Please specicy Cycle(in Days)"
            }
        }


        if (this.state.type.trim() === "One Time") {
            if (this.state.startDate.trim() === "") {
                startDateError = "Please Select Date"
            }
        }

        if (!this.state.specificCustomerSelected && !this.state.allCustomersSelected) {
            customerOptionError = "Please select either All or Specific customer option."
        }



        if (this.state.customerSelected) {
            if (this.state.customerVisited.trim() === "") {
                customerVisitedError = "Please provide customer visited or not visited"
            }
            if (this.state.customerInDays === '') {
                customerInDaysError = "Please provide no of days"
            }
        }

        if (this.state.transactionSelected) {
            if (this.state.noOfTransaction === '') {
                noOfTransactionError = "Please provide No of transactions"
            }
            if (this.state.noOfTransactionDays === '') {
                noOfTransactionDaysError = "Please provide no of days"
            }
        }

        if (this.state.amountSpentSelected) {
            if (this.state.amountSpent === '') {
                amountSpentError = "Please provide Amount"
            }
            if (this.state.amountSpentDays === '') {
                amountSpentDaysError = "Please provide no of days"
            }
        }

        if (this.state.membershipSelected) {
            if (isNullOrUndefined(this.state.membership)) {
                membershipError = "Please provide membership"
            }
        }

        if (this.state.customerGenderSelected) {
            if (this.state.customerGender.trim() === "") {
                customerGenderError = "Please provide customer gender"
            }
        }

        if (this.state.customerAgeSelected) {
            if (this.state.customerAgeFrom === '') {
                customerAgeFromError = "Please provide value"
            }
            if (this.state.customerAgeTo === '') {
                customerAgeToError = "Please provide value"
            }
        }

        if (this.state.walletBalanceSelected) {
            if (this.state.walletBalanceFrom === '') {
                walletBalanceFromError = "Please provide value"
            }
            if (this.state.walletBalanceTo === '') {
                walletBalanceToError = "Please provide value"
            }
        }

        if (this.state.eventSelected) {
            if (this.state.event.trim() === "") {
                eventError = "Please provide event"
            }
        }

        if (this.state.receivePaymentSelected) {
            if (this.state.receivePaymentFrom === '') {
                receivePaymentFromError = "Please provide value"
            }
            if (this.state.receivePaymentTo === '') {
                receivePaymentToError = "Please provide value"
            }
        }

        if (this.state.specificStoreSelected) {
            if (this.state.associatedStores.length === 0) {
                storeNameError = "Please select store"
            }
        }

        if (this.state.placeholder.trim() === "") {
            placeholderError = "Please provide a placeholder name"
        }

        if (!this.state.allStoresSelected && !this.state.specificStoreSelected) {
            optionError = "Please select either All or Specific store option."
        }

        if (this.state.message.trim() === "") {
            messageError = "Please provide your message name"
        }

        this.setState({
            nameError: nameError,
            typeError: typeError,
            startDateError: startDateError,
            cycleOfDaysError: cycleOfDaysError,
            endDateError: endDateError,
            runsEveryError: runsEveryError,
            onDayError: onDayError,
            customerVisitedError: customerVisitedError,
            customerInDaysError: customerInDaysError,
            noOfTransactionError: noOfTransactionError,
            noOfTransactionDaysError: noOfTransactionDaysError,
            amountSpentError: amountSpentError,
            amountSpentDaysError: amountSpentDaysError,
            membershipError: membershipError,
            customerGenderError: customerGenderError,
            customerAgeFromError: customerAgeFromError,
            customerAgeToError: customerAgeToError,
            walletBalanceFromError: walletBalanceFromError,
            walletBalanceToError: walletBalanceToError,
            eventError: eventError,
            receivePaymentFromError: receivePaymentFromError,
            receivePaymentToError: receivePaymentToError,
            optionError: optionError,
            storeNameError: storeNameError,
            placeholderError: placeholderError,
            messageError: messageError,
            customerOptionError: customerOptionError
        });

        if (optionError !== "" || nameError !== "" || customerVisitedError !== "" ||
            customerInDaysError !== "" || noOfTransactionError !== "" ||
            noOfTransactionDaysError !== "" || amountSpentError !== "" ||
            amountSpentDaysError !== "" || membershipError !== "" ||
            customerGenderError !== "" || customerAgeFromError !== "" ||
            customerAgeToError !== "" || walletBalanceFromError !== "" ||
            walletBalanceToError !== "" || eventError !== "" ||
            receivePaymentFromError !== "" || receivePaymentToError !== "" ||
            storeNameError !== "" || placeholderError !== ""
            || messageError !== "" || typeError !== ""
            || startDateError !== "" || cycleOfDaysError !== ""
            || runsEveryError !== "" || onDayError !== ""
            || endDateError !== "" || customerOptionError !== ""
        ) { return }

        let campaign = {
            RegistrationID: this.props.user.user.RegistrationID,
            Name: this.state.name,
            Type: this.state.type,
            StartDate: this.state.startDate,
            CycleOfDays: Number(this.state.cycleOfDays),
            RunsEvery:Number(this.state.runsEvery) ,
            OnDay: this.state.onDay,
            EndDate: this.state.endDate,
            CampaignStatus: this.state.campaignStatus,
            CustomerSelected: this.state.customerSelected,
            AllCustomersSelected: this.state.allCustomersSelected,
            SpecificCustomerSelected: this.state.specificCustomerSelected,
            TransactionSelected: this.state.transactionSelected,
            AmountSpentSelected: this.state.amountSpentSelected,
            MembershipSelected: this.state.membershipSelected,
            CustomerGenderSelected: this.state.customerGenderSelected,
            CustomerAgeSelected: this.state.customerAgeSelected,
            WalletBalanceSelected: this.state.walletBalanceSelected,
            EventSelected: this.state.eventSelected,
            ReceivePaymentSelected: this.state.receivePaymentSelected,
            CustomerVisited: this.state.customerVisited,
            CustomerInDays: Number(this.state.customerInDays),
            NoOfTransaction: Number(this.state.noOfTransaction),
            NoOfTransactionDays: Number(this.state.noOfTransactionDays),
            AmountSpent: Number(this.state.amountSpent),
            AmountSpentDays: Number(this.state.amountSpentDays),
            Membership: this.state.membership,
            CustomerGender: this.state.customerGender,
            CustomerAgeFrom: Number(this.state.customerAgeFrom),
            CustomerAgeTo: Number(this.state.customerAgeTo),
            WalletBalanceFrom: Number(this.state.walletBalanceFrom),
            WalletBalanceTo: Number(this.state.walletBalanceTo),
            Event: this.state.event,
            ReceivePaymentFrom: Number(this.state.receivePaymentFrom),
            ReceivePaymentTo: Number(this.state.receivePaymentTo),
            Placeholder: this.state.placeholder,
            Message: this.state.message,
            SpecificStoreSelected: this.state.specificStoreSelected,
            AllStoresSelected: this.state.allStoresSelected,
            Stores: this.state.associatedStores,
            IsDeleted: false
        }
        console.log("Save Campaign", campaign)
        this.props.saveCampaign(campaign, this.state.key)
        this.props.history.push('/home/campaign')
    }

    onMembershipChange(e) {
        this.setState({ membershipError: "" });
        if (e.target.value != "") {
            let membership = {
                MembershipID: e.target.value,
                Name: e.target.options[e.target.selectedIndex].text
            }
            // console.log("On Change",membership)
            this.setState({ membership: membership })
        }
        else
            this.setState({ membership: null });

    }

    loadMembership() {
        this.props.getMembership(this.props.user.user.RegistrationID, 0, 10000, "active", "").then((memberships) => {
            this.setState({ membershipList: memberships.searchResult })
        });
    }


    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSaveCampaign()
        }
    }

    handleOnTypeChange = (e) => {
        let type = e.target.options[e.target.selectedIndex].text
        if (type !== "") {
            this.setState({ typeError: '', onDayError: '', runsEveryError: '', startDateError: '', endDateError: '', cycleOfDaysError: '', onDay: '', runsEvery: '', startDate: '', endDate: '', cycleOfDays: '' })
        }
        this.setState({ type: type, typeError: "" });
    }

    handleOnPlaceHolderChange = (e) => {
        let placeholder = e.target.options[e.target.selectedIndex].text
        if (placeholder !== "") {
            let message = this.state.message;
            message = message + ' <' + placeholder + '>';
            this.setState({ placeholderError: "", message: message })
        }
        this.setState({ placeholder: placeholder, typeError: "" });
    }

    handleOnChange(storeList, e) {
        // console.log(JSON.stringify(storeList));
        // let objList = [];
        // this.setState({ associatedStores: [] });
        // storeList.map((store) => {
        //     let obj = {
        //         StoreKey: store.value, StoreName: store.label
        //     }
        //     objList.push(obj);
        // });
        // console.log("Associated Stores", JSON.stringify(objList));
        this.setState({ associatedStores: storeList });
    }



    // async loadData() {
    //     console.log(this.state.TransactionDate)
    //     let totalQtyCustomers = await this.customersForTotalQty();
    //     let totalSpentCustomers = await this.customersForTotalSpent();
    //     let totalFilterCustomers = await this.onExportCustomers()
    //      for (let index = 0; index < totalQtyCustomers.length; index++) {
    //         let qty = totalQtyCustomers[index];
    //         for (let index1 = 0; index1 < totalSpentCustomers.length; index1++) {
    //             let spent = totalSpentCustomers[index1];
    //                 for (let index2 = 0; index2 < totalFilterCustomers.length; index2++) {
    //                     let filterCustomer = totalFilterCustomers[index2];

    //                     if (qty["Customer.Key"] === spent["Customer.key"] === filterCustomer["Customer.key"]) {
    //                         alert("success")




    //                     }
                        
    //                 }

               
    //         }
    //     }
    // }


    async onExportCustomers() {
        
        let searchParameters
        if (this.state.allCustomersSelected) {
            searchParameters = {
                "from": 0,
                "size": 10000,
                "query": {

                    "bool": {
                        "must": [
                            {
                                "term": {
                                    "RegistrationID": this.props.user.user.RegistrationID
                                }
                            },
                            {
                                "term": {
                                    "StoreID.keyword": this.props.user.store.key
                                }
                            },
                            {
                                "term": {
                                    "IsDeleted": false
                                }
                            }
                        ]
                    }
                }
            }

        }
        else {
            searchParameters = {
                "from": 0,
                "size": 10000,

                "query": {

                    "bool": {

                        "must": [

                            {
                                "term": {
                                    "IsDeleted": false
                                }
                            },
                            {
                                "term": {
                                    "RegistrationID": this.props.user.user.RegistrationID
                                }
                            },
                            {
                                "term": {
                                    "StoreID.keyword": this.props.user.store.key
                                }
                            }
                        ]
                    }
                }
            }
            if (this.state.membershipSelected === true && this.state.membership.Name != "") {
                searchParameters.query.bool.must.push({
                    "term": { "Membership.MembershipID.keyword": this.state.membership.MembershipID }
                })
            }
            if (this.state.customerGenderSelected === true && this.state.customerGender.trim() != "" && this.state.customerGender.trim() != "All") {
                searchParameters.query.bool.must.push({
                    "term": { "Gender.keyword": this.state.customerGender }
                })
            }

            if (this.state.walletBalanceSelected === true && this.state.walletBalanceTo.trim() != "" && this.state.walletBalanceFrom.trim() != "") {
                searchParameters.query.bool.must.push({
                    "range": { "WalletBalance": { "gte": this.state.walletBalanceFrom, "lte": this.state.walletBalanceTo } }
                })
            }
            if (this.state.receivePaymentSelected === true && this.state.receivePaymentFrom.trim() != "" && this.state.receivePaymentTo.trim() != "") {
                searchParameters.query.bool.must.push({
                    "range": { "OutStandingAmount": { "gte": this.state.receivePaymentFrom, "lte": this.state.receivePaymentTo } }
                })
            }
            if (this.state.customerSelected === true && this.state.customerVisited.trim() === "Not visited" && this.state.customerInDays.trim() != "") {
                alert("not visited")
                searchParameters.query.bool.must.push({
                    "range": { "LastVisitedOn": { "gte": "now-" + this.state.customerInDays + "d/d", "lte": "now/d", "format": "yyyy-MM-dd" } }
                })
                if (this.state.customerSelected === true && this.state.customerVisited.trim() === "Visited" && this.state.customerInDays.trim() != "") {
                    alert("Visited")
                    searchParameters.query.bool.must.push({

                        "range": { "LastVisitedOn": { "gte": "now-" + this.state.customerInDays + "d/d", "lte": "now/d", "format": "yyyy-MM-dd" } }
                    })
                }
            }
        }

        this.props.getCustomersForCampaign(searchParameters).then((result) => {
            console.log(JSON.stringify(result.searchResult));
            if (isNullOrUndefined(result.searchResult) || result.searchResult.length === 0) {
                this.props.getToastr("No Record Found")
                return
            }
            console.log("Search Parameter", JSON.stringify(searchParameters))
            let customers = []
            result.searchResult.map((data) => {
                let customer = {
                    "Customer Code": data.CustomerCode,
                    "First Name": data.FirstName,
                    "Last Name": !isNullOrUndefined(data.LastName) ? data.LastName : "",
                    "Gender": !isNullOrUndefined(data.Gender) ? data.Gender : "",
                    "PhoneNo.": !isNullOrUndefined(data.PhoneNo) ? data.PhoneNo : "",
                    "EmailID": !isNullOrUndefined(data.EmailID) ? data.EmailID : "",
                    "Membership": data.Membership ? (data.Membership.Name ? data.Membership.Name : "") : "",
                    "Opening Balance": !isNullOrUndefined(data.WalletOpeningBalance) ? (data.WalletOpeningBalance) : 0,
                    "Outstanding Amount": !isNullOrUndefined(data.OutstandingAmount) ? (data.OutstandingAmount) : 0,
                    "Wallet Balance": !isNullOrUndefined(data.WalletBalance) ? (data.WalletBalance) : 0,
                    "Last Recharged Amount": !isNullOrUndefined(data.WalletLastCreditAmount) ? data.WalletLastCreditAmount : 0,
                    // "Last Visited":!isNullOrUndefined(new Date(data.LastVisitedOn).toISOString().split('T')[0]) ? data.LastVisitedOn :""
                }
                customers.push(customer);
            })


            const csvData = constatnts.objToCSV(customers);
            this.setState({ loading: false, exportCalled: false })
            const csvBlob = new Blob([csvData], { type: "text/csv" });
            const url = window.URL.createObjectURL(csvBlob);
            const a = document.createElement("a");
            a.setAttribute("hidden", "");
            a.setAttribute("href", url);
            a.setAttribute("download", "customersforcampaign.csv");
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        })
    }


    async customersForTotalQty() {
        console.log("no of trasaction",this.state.noOfTransaction)
        console.log("no of days",this.state.noOfTransactionDays)
        console.log("no of transaction Selected",this.state.transactionSelected)
        let searchParameters = {
            "size": 10000,
            "query": {
              "bool": {
                "must": [
                  {
                    "term": {
                      "RegistrationID": this.props.user.user.RegistrationID
                    }
                  },
                  {
                    "term": {
                      "Store.key.keyword": this.props.user.store.key
                    }
                  }
                ],
                "must_not": [
                  {
                    "exists": {
                      "field": "CancellationReason"
                    }
                  }
                ]
              }
            },
            "aggs": {
              "group_by_customer": {
                "terms": {
                  "field": "Customer.key.keyword",
                  "size": 10000
                },
                "aggs": {
                  "total_transaction": {
                    "cardinality": {
                      "field": "_id"
                      
                    }
                  },
                  "sales_bucket_filter": {
                    "bucket_selector": {
                      "buckets_path": {
                        "totalTransaction": "total_transaction"
                      },
                      "script": "params.totalTransaction >" + this.state.noOfTransaction
                    }
                  }
                }
              }
            }
          }
          
          if (this.state.transactionSelected === true && this.state.noOfTransaction != "", this.state.noOfTransactionDays !="")  {
            searchParameters.query.bool.must.push({"range": {"TransactionDate": {"lte": "now/d","gte":"now-" + this.state.noOfTransactionDays + "d/d","format": "yyyy-MM-dd"} }})
        }
        
        
        let totalQtyCustomers = [];
        return this.props.getCustomersForTotalQty(searchParameters)
            .then((result) => {
                totalQtyCustomers = result.searchResult;
                return totalQtyCustomers;
            })
    }

    async customersForTotalSpent() {

        console.log("Amount Spent",this.state.this.state.amountSpent)
        console.log("amount Spent Days",this.state.amountSpentDays)
        console.log("amount Spent Selected",this.state.amountSpentSelected)

        let searchParameters = {
            "size": 10000,
            "query": {
              "bool": {
                "must": [
                  {
                    "term": {
                      "RegistrationID": this.props.user.user.RegistrationID
                  }
                  },
                  {
                    "term": {
                      "Store.key.keyword":  this.props.user.store.key
                    }
                  }
                ],
                "must_not": [
                  {
                    "exists": {
                      "field": "CancellationReason"
                    }
                  }
                ]
              }
            },
            "aggs": {
              "group_by_customer": {
                "terms": {
                  "field": "Customer.key.keyword",
                  "size": 10000

                },
                "aggs": {
                  "total_spent": {
                    "sum": {
                      "field": "BillAmount"
                    }
                  },
                  "sales_bucket_filter": {
                    "bucket_selector": {
                      "buckets_path": {
                        "totalSpent": "total_spent"
                      },
                      "script": "params.totalSpent >   " +  this.state.amountSpent
                    }
                  }
                }
              }
            }
           }

        if (this.state.amountSpentSelected === true && this.state.amountSpent != "", this.state.amountSpentDays !="")  {
            searchParameters.query.bool.must.push({"range": {"TransactionDate": {"lte": "now/d","gte":"now-" + this.state.amountSpentDays + "d/d","format": "yyyy-MM-dd"} }})
        }

        let totalSpentCustomers = [];
        return this.props.getCustomersForTotalSpent(searchParameters)
            .then((result) => {
                totalSpentCustomers = result.searchResult;
                return totalSpentCustomers;
            })
    }


    render() {
        const styleEditor = {
            width: "100%",
            height: "150px",
            font_size: "14px",
            line_height: "18px",
            border: "1px solid #dddddd",
            padding: "10px"
        }
        return <div className="content-wrapper">
            < section className="content-header" >
                <h1>Campaign</h1>
                <button type="submit" className="btn btn-flat btn-md btn-success pull-right"
                    onClick={this.onSaveCampaign} style={{ marginTop: "-25px", marginLeft: "10px" }}>Save</button>
                <Link to="/home/campaign" type="submit" className="btn btn-flat btn-md btn-default pull-right" style={{ marginTop: "-25px" }}>Cancel</Link>
            </section >
            <section className="content">
                <div className="row">
                    <div className="col-md-12">
                        <div className="box">
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className={this.state.nameError !== "" ? "form-group has-error" : "form-group "}>
                                            <label>Name</label>
                                            <input onKeyPress={this.handleKeyPress} type="text" className="form-control"
                                                onChange={(evt) => this.setState({
                                                    name: evt.target.value, nameError: "",
                                                })}
                                                value={this.state.name} />
                                            {this.state.nameError !== "" && <span className="help-block">{this.state.nameError}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className={this.state.typeError !== "" ? "form-group has-error" : "form-group "}>
                                            <label >Type</label>
                                            <select className="form-control" value={this.state.type}
                                                onChange={this.handleOnTypeChange}>
                                                <option value="">Select Campaign Type</option>
                                                <option value="Specific Day">Specific Day</option>
                                                <option value="One Time">One Time</option>
                                                <option value="Recurring">Recurring</option>
                                            </select>
                                            {this.state.typeError !== "" && <span className="help-block">{this.state.typeError}</span>}
                                        </div>
                                    </div>
                                </div>
                                {this.state.type !== "" && <div className="row">
                                    <div className="col-md-6">
                                        <div className={this.state.startDateError !== "" ? "form-group has-error" : "form-group "}>
                                            <label >{this.state.type === "One Time" ? "Select Date" : "Start Date"}</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ startDate: evt.target.value, startDateError: "" })}
                                                value={this.state.startDate}
                                            />
                                            {this.state.startDateError !== "" && <span className="help-block">{this.state.startDateError}</span>}
                                        </div>
                                    </div>
                                    {(this.state.type === "Recurring" || this.state.type === "Specific Day") && <div className="col-md-6">
                                        <div className={this.state.endDateError !== "" ? "form-group has-error" : "form-group "}>
                                            <label >End Date</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ endDate: evt.target.value, endDateError: "" })}
                                                value={this.state.endDate}
                                            />
                                            {this.state.endDateError !== "" && <span className="help-block">{this.state.endDateError}</span>}
                                        </div>
                                    </div>}
                                </div>}
                                {(this.state.type === "Recurring" || this.state.type === "Specific Day") && <div className="row">
                                    <div className="col-md-6">
                                        <div className={this.state.cycleOfDaysError !== "" ? "form-group has-error" : "form-group "}>
                                            <label >Cycle (in Days)</label>
                                            <input type="number" onKeyPress={this.handleKeyPress} className="form-control"
                                                onChange={(evt) => this.setState({ cycleOfDays: evt.target.value, cycleOfDaysError: "" })}
                                                value={this.state.cycleOfDays}
                                            />
                                            {this.state.cycleOfDaysError !== "" && <span className="help-block">{this.state.cycleOfDaysError}</span>}
                                        </div>
                                    </div>
                                    {this.state.type === "Recurring" && <div className="col-md-6">
                                        <div className={this.state.runsEveryError !== "" ? "form-group has-error" : "form-group "}>
                                            <label >Run Every (in Days)</label>
                                            <input type="number" onKeyPress={this.handleKeyPress} className="form-control"
                                                onChange={(evt) => this.setState({ runsEvery: evt.target.value, runsEveryError: "" })}
                                                value={this.state.runsEvery}
                                            />
                                            {this.state.runsEveryError !== "" && <span className="help-block">{this.state.runsEveryError}</span>}
                                        </div>
                                    </div>}
                                    {this.state.type === "Specific Day" && <div className="col-md-6">
                                        <div className={this.state.onDayError !== "" ? "form-group has-error" : "form-group "}>
                                            <label >On Day</label>
                                            <select className="form-control" value={this.state.onDay}
                                                onChange={(evt) => this.setState({ onDay: evt.target.value, onDayError: "" })}>
                                                <option >Select Day</option>
                                                <option >Monday</option>
                                                <option >Tuesday</option>
                                                <option >Wednesday</option>
                                                <option >Thursday</option>
                                                <option >Friday</option>
                                                <option >Saturday</option>
                                                <option >Sunday</option>
                                            </select>
                                            {this.state.onDayError !== "" && <span className="help-block">{this.state.onDayError}</span>}
                                        </div>
                                    </div>}
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="box">
                            <div className="box-header with-border">
                                <h3 className="box-title">Criteria</h3>
                            </div>
                            <div className="box-body">
                                <div className="row">

                                    <div className={this.state.customerOptionError !== "" ? "form-group col-md-4 has-error" : "form-group col-md-4"}>
                                        <input type="radio" name="customers" value={this.state.allCustomersSelected} checked={this.state.allCustomersSelected}
                                            onChange={() => {
                                                this.setState({
                                                    specificCustomerSelected: false, allCustomersSelected: true, customerOptionError: "",
                                                    customerSelected: "",
                                                    customerVisited: "",
                                                    customerInDays: "",
                                                    transactionSelected: "",
                                                    noOfTransaction: "",
                                                    noOfTransactionDays: "",
                                                    amountSpentSelected: "",
                                                    amountSpent: "",
                                                    amountSpentDays: "",
                                                    membershipSelected: "",
                                                    membership: "",
                                                    customerGenderSelected: "",
                                                    customerGender: "",
                                                    customerAgeSelected: "",
                                                    customerAgeFrom: "",
                                                    customerAgeTo: "",
                                                    walletBalanceSelected: "",
                                                    walletBalanceFrom: "",
                                                    walletBalanceTo: "",
                                                    eventSelected: "",
                                                    event: "",
                                                    receivePaymentSelected: "",
                                                    receivePaymentFrom: "",
                                                    receivePaymentTo: "",
                                                    nameError: "",
                                                    typeError: "",
                                                    startDateError: "",
                                                    endDateError: "",
                                                    runsEveryError: "",
                                                    onDayError: "",
                                                    cycleOfDaysError: "",
                                                    customerVisitedError: "",
                                                    customerInDaysError: "",
                                                    noOfTransactionError: "",
                                                    noOfTransactionDaysError: "",
                                                    amountSpentDaysError: "",
                                                    amountSpentError: "",
                                                    membershipError: "",
                                                    customerGenderError: "",
                                                    customerAgeFromError: "",
                                                    customerAgeToError: "",
                                                    walletBalanceFromError: "",
                                                    walletBalanceToError: "",
                                                    eventError: "",
                                                    receivePaymentFromError: "",
                                                    receivePaymentToError: "",
                                                    storeNameError: "",
                                                    placeholderError: "",
                                                    messageError: "",
                                                    optionError: "",
                                                })
                                            }} />
                                        <label><strong>All Customers</strong></label>
                                        {this.state.customerOptionError !== "" && <span className="help-block">{this.state.customerOptionError}</span>}
                                    </div>
                                    <div className={this.state.customerOptionError !== "" ? "form-group col-md-4 has-error" : "form-group col-md-4"}>
                                        <input type="radio" name="customers" value={this.state.specificCustomerSelected} checked={this.state.specificCustomerSelected}
                                            onChange={() => {
                                                this.setState({

                                                    specificCustomerSelected: true, allCustomersSelected: false, customerOptionError: '',
                                                    customerSelected: "",
                                                    customerVisited: "",
                                                    customerInDays: "",
                                                    transactionSelected: "",
                                                    noOfTransaction: "",
                                                    noOfTransactionDays: "",
                                                    amountSpentSelected: "",
                                                    amountSpent: "",
                                                    amountSpentDays: "",
                                                    membershipSelected: "",
                                                    membership: "",
                                                    customerGenderSelected: "",
                                                    customerGender: "",
                                                    customerAgeSelected: "",
                                                    customerAgeFrom: "",
                                                    customerAgeTo: "",
                                                    walletBalanceSelected: "",
                                                    walletBalanceFrom: "",
                                                    walletBalanceTo: "",
                                                    eventSelected: "",
                                                    event: "",
                                                    receivePaymentSelected: "",
                                                    receivePaymentFrom: "",
                                                    receivePaymentTo: "",
                                                    nameError: "",
                                                    typeError: "",
                                                    startDateError: "",
                                                    endDateError: "",
                                                    runsEveryError: "",
                                                    onDayError: "",
                                                    cycleOfDaysError: "",
                                                    customerVisitedError: "",
                                                    customerInDaysError: "",
                                                    noOfTransactionError: "",
                                                    noOfTransactionDaysError: "",
                                                    amountSpentDaysError: "",
                                                    amountSpentError: "",
                                                    membershipError: "",
                                                    customerGenderError: "",
                                                    customerAgeFromError: "",
                                                    customerAgeToError: "",
                                                    walletBalanceFromError: "",
                                                    walletBalanceToError: "",
                                                    eventError: "",
                                                    receivePaymentFromError: "",
                                                    receivePaymentToError: "",
                                                    storeNameError: "",
                                                    placeholderError: "",
                                                    messageError: "",
                                                    optionError: "",
                                                })
                                            }} />

                                        <label><strong>Specific Customers</strong></label>
                                        {this.state.customerOptionError !== "" && <span className="help-block">{this.state.customerOptionError}</span>}
                                    </div>
                                </div>
                                {this.state.specificCustomerSelected === true &&
                                    <div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <input type="checkbox" checked={this.state.customerSelected}
                                                    onChange={() => { this.setState({ customerSelected: !this.state.customerSelected, customerVisitedError: "", customerInDaysError: "" }) }} />
                                                <label><strong>Customer</strong></label>
                                            </div>
                                            <div className="col-md-4">
                                                <div className={this.state.customerVisitedError !== "" ? "form-group has-error" : "form-group "}>
                                                    <select className="form-control" value={this.state.customerVisited}
                                                        onChange={(evt) => this.setState({ customerVisited: evt.target.value, customerVisitedError: "" })}
                                                        disabled={!this.state.customerSelected}>
                                                        <option value="">select</option>
                                                        <option >Visited</option>
                                                        <option >Not visited</option>
                                                    </select>
                                                    {this.state.customerVisitedError !== "" && <span className="help-block">{this.state.customerVisitedError}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-1" style={{ textAlign: "center", marginTop: "4px", marginLeft: "10px" }}>
                                                <label placeholder="Day(s)">in</label>
                                            </div>
                                            <div className="col-md-2">
                                                <div className={this.state.customerInDaysError !== "" ? "form-group has-error" : "form-group "}>
                                                    <input type="number" className="form-control" value={this.state.customerInDays}
                                                        onChange={(evt) => this.setState({ customerInDays: evt.target.value, customerInDaysError: "" })}
                                                        disabled={!this.state.customerSelected}>
                                                    </input>
                                                    {this.state.customerInDaysError !== "" && <span className="help-block">{this.state.customerInDaysError}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-1" style={{ textAlign: "left", marginTop: "2px", marginLeft: "-20px" }}>
                                                <label placeholder="Day(s)">Day(s)</label>
                                            </div>
                                        </div>
                                        <br />

                                        <div className="row">
                                            <div className="col-md-4">
                                                <input type="checkbox" checked={this.state.transactionSelected}
                                                    onChange={() => { this.setState({ transactionSelected: !this.state.transactionSelected, noOfTransactionErrorCheck: "" }) }} />
                                                <label><strong>No of Transactions</strong></label>
                                            </div>
                                            <div className="col-md-4">
                                                <div className={this.state.noOfTransactionError !== "" ? "form-group has-error" : "form-group "}>
                                                    <input type="number" className="form-control" placeholder="No of Transaction"
                                                        onChange={(evt) => this.setState({ noOfTransaction: evt.target.value, noOfTransactionError: "" })}
                                                        value={this.state.noOfTransaction} disabled={!this.state.transactionSelected}
                                                    />
                                                    {this.state.noOfTransactionError !== "" && <span className="help-block">{this.state.noOfTransactionError}</span>}
                                                </div>
                                            </div>

                                            <div className="col-md-1" style={{ textAlign: "center", marginTop: "4px", marginLeft: "10px" }}>
                                                <label placeholder="Day(s)">in</label>
                                            </div>
                                            <div className="col-md-2">
                                                <div className={this.state.noOfTransactionDaysError !== "" ? "form-group has-error" : "form-group "}>
                                                    <input type="number" className="form-control" placeholder="Day(s)"
                                                        onChange={(evt) => this.setState({ noOfTransactionDays: evt.target.value, noOfTransactionDaysError: "" })}
                                                        value={this.state.noOfTransactionDays} disabled={!this.state.transactionSelected}
                                                    />
                                                    {this.state.noOfTransactionDaysError !== "" && <span className="help-block">{this.state.noOfTransactionDaysError}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-1" style={{ textAlign: "left", marginTop: "2px", marginLeft: "-20px" }}>
                                                <label>Day(s)</label>
                                            </div>
                                        </div>
                                        <br />

                                        <div className="row">
                                            <div className="col-md-4">
                                                <input type="checkbox" checked={this.state.amountSpentSelected}
                                                    onChange={() => { this.setState({ amountSpentSelected: !this.state.amountSpentSelected }) }} />
                                                <label><strong>Amount Spent</strong></label>
                                            </div>
                                            <div className="col-md-4">
                                                <div className={this.state.amountSpentError !== "" ? "form-group has-error" : "form-group "}>
                                                    <input type="number" onKeyPress={this.handleKeyPress} className="form-control" placeholder="Amount Spent"
                                                        onChange={(evt) => this.setState({ amountSpent: evt.target.value, amountSpentError: "", amountSpentDaysError: "" })}
                                                        value={this.state.amountSpent} disabled={!this.state.amountSpentSelected} />
                                                    {this.state.amountSpentError !== "" && <span className="help-block">{this.state.amountSpentError}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-1" style={{ textAlign: "center", marginTop: "4px", marginLeft: "10px" }}>
                                                <label placeholder="Day(s)">in</label>
                                            </div>
                                            <div className="col-md-2">
                                                <div className={this.state.amountSpentDaysError !== "" ? "form-group has-error" : "form-group "}>
                                                    <input type="text" onKeyPress={this.handleKeyPress} className="form-control" placeholder="Day(s)"
                                                        onChange={(evt) => this.setState({ amountSpentDays: evt.target.value, amountSpentDaysError: "", amountSpentError: "" })}
                                                        value={this.state.amountSpentDays} disabled={!this.state.amountSpentSelected}
                                                    />
                                                    {this.state.amountSpentDaysError !== "" && <span className="help-block">{this.state.amountSpentDaysError}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-1" style={{ textAlign: "left", marginTop: "2px", marginLeft: "-20px" }}>
                                                <label>Day(s)</label>
                                            </div>
                                        </div>
                                        <br />

                                        <div className="row">
                                            <div className="col-md-4">
                                                <input type="checkbox" checked={this.state.membershipSelected}
                                                    onChange={() => { this.setState({ membershipSelected: !this.state.membershipSelected, membershipError: "", nonMemberSelected: "", memberSelected: "" }) }} />
                                                <label><strong>Membership</strong></label>

                                            </div>
                                            {this.state.membershipSelected && <div className="col-md-8">
                                                <div className={this.state.membershipError !== "" ? "form-group has-error" : "form-group "}>
                                                    <select className="form-control" value={this.state.membership ? this.state.membership.MembershipID : null}
                                                        //onChange={(evt) => this.setState({ membership: evt.target.value, membershipError: "" })}
                                                        onChange={this.onMembershipChange}
                                                        disabled={!this.state.membershipSelected}>
                                                        <option value="">Non Membership Customers</option>
                                                        {this.state.membershipList.map((membership) =>
                                                            <option value={membership.key}>{membership.Name}</option>
                                                        )}
                                                    </select>
                                                    {this.state.membershipError !== "" && <span className="help-block">{this.state.membershipError}</span>}
                                                </div>
                                            </div>}
                                        </div>
                                        <br />

                                        <div className="row">
                                            <div className="col-md-4">
                                                <input type="checkbox" checked={this.state.customerGenderSelected}
                                                    onChange={() => { this.setState({ customerGenderSelected: !this.state.customerGenderSelected, customerGenderError: "" }) }} />
                                                <label><strong>Customer Gender</strong></label>
                                            </div>
                                            <div className="col-md-8">
                                                <div className={this.state.customerGenderError !== "" ? "form-group has-error" : "form-group "}>
                                                    <select className="form-control" value={this.state.customerGender}
                                                        onChange={(evt) => this.setState({ customerGender: evt.target.value, customerGenderError: "" })}
                                                        disabled={!this.state.customerGenderSelected}
                                                    >
                                                        <option>Select</option>
                                                        <option>All</option>
                                                        <option >Male</option>
                                                        <option >Female</option>
                                                    </select>
                                                    {this.state.customerGenderError !== "" && <span className="help-block">{this.state.customerGenderError}</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="row">
                                            <div className="col-md-4">
                                                <input type="checkbox" checked={this.state.walletBalanceSelected}
                                                    onChange={() => { this.setState({ walletBalanceSelected: !this.state.walletBalanceSelected, walletBalanceFromError: "", walletBalanceToError: "" }) }} />
                                                <label><strong>Wallet Balance</strong></label>
                                            </div>
                                            <div className="col-md-3">
                                                <div className={this.state.walletBalanceFromError !== "" ? "form-group has-error" : "form-group "}>
                                                    <input type="number" onKeyPress={this.handleKeyPress} className="form-control" placeholder="From"
                                                        onChange={(evt) => this.setState({ walletBalanceFrom: evt.target.value, walletBalanceFromError: "" })}
                                                        value={this.state.walletBalanceFrom} disabled={!this.state.walletBalanceSelected}
                                                    />
                                                    {this.state.walletBalanceFromError !== "" && <span className="help-block">{this.state.walletBalanceFromError}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-1" style={{ textAlign: "left", marginTop: "2px" }}>
                                                <label>-</label>
                                            </div>
                                            <div className="col-md-3">
                                                <div className={this.state.walletBalanceToError !== "" ? "form-group has-error" : "form-group "}>
                                                    <input type="number" onKeyPress={this.handleKeyPress} className="form-control" placeholder="To"
                                                        onChange={(evt) => this.setState({ walletBalanceTo: evt.target.value, walletBalanceToError: "" })}
                                                        value={this.state.walletBalanceTo} disabled={!this.state.walletBalanceSelected}
                                                    />
                                                    {this.state.walletBalanceToError !== "" && <span className="help-block">{this.state.walletBalanceToError}</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <br />

                                        <div className="row">
                                            <div className="col-md-4">
                                                <input type="checkbox" checked={this.state.eventSelected}
                                                    onChange={() => { this.setState({ eventSelected: !this.state.eventSelected, eventError: "" }) }} />
                                                <label><strong>Event</strong></label>
                                            </div>
                                            <div className="col-md-8">
                                                <div className={this.state.eventError !== "" ? "form-group has-error" : "form-group "}>
                                                    <select className="form-control" value={this.state.event}
                                                        onChange={(evt) => this.setState({ event: evt.target.value, eventError: "" })}
                                                        disabled={!this.state.eventSelected}>
                                                        <option value="">Select</option>
                                                        <option>Birthday</option>
                                                        <option>Anniversary</option>
                                                    </select>
                                                    {this.state.eventError !== "" && <span className="help-block">{this.state.eventError}</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <br />

                                        <div className="row">
                                            <div className="col-md-4">
                                                <input type="checkbox" checked={this.state.receivePaymentSelected}
                                                    onChange={() => { this.setState({ receivePaymentSelected: !this.state.receivePaymentSelected, receivePaymentFromError: "", receivePaymentToError: "" }) }} />
                                                <label><strong>Outstanding Amount</strong></label>
                                            </div>
                                            
                                            <div className="col-md-3">
                                                <div className={this.state.receivePaymentFromError !== "" ? "form-group has-error" : "form-group "}>
                                                    <input type="number" onKeyPress={this.handleKeyPress} className="form-control" placeholder="From"
                                                        onChange={(evt) => this.setState({ receivePaymentFrom: evt.target.value, receivePaymentFromError: "" })}
                                                        value={this.state.receivePaymentFrom} disabled={!this.state.receivePaymentSelected}
                                                    />
                                                    {this.state.receivePaymentFromError !== "" && <span className="help-block">{this.state.receivePaymentFromError}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-1" style={{ textAlign: "left", marginTop: "2px" }}>
                                                <label>-</label>
                                            </div>
                                            <div className="col-md-3">
                                                <div className={this.state.receivePaymentToError !== "" ? "form-group has-error" : "form-group "}>
                                                    <input type="number" onKeyPress={this.handleKeyPress} className="form-control" placeholder="To"
                                                        onChange={(evt) => this.setState({ receivePaymentTo: evt.target.value, receivePaymentToError: "" })}
                                                        value={this.state.receivePaymentTo} disabled={!this.state.receivePaymentSelected}
                                                    />
                                                    {this.state.receivePaymentToError !== "" && <span className="help-block">{this.state.receivePaymentToError}</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                    </div>}
                            </div>

                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="box">
                                    <div className="box-header with-border">
                                        <h3 className="box-title">Stores</h3>
                                    </div>
                                    <div className="box-body">
                                        <div className="row">
                                            <div className={this.state.optionError !== "" ? "form-group col-md-6 has-error" : "form-group col-md-6"}>
                                                <input type="radio" name="stores" value={this.state.allStoresSelected} checked={this.state.allStoresSelected}
                                                    onChange={() => { this.setState({ specificStoreSelected: false, allStoresSelected: true, associatedStores: [], optionError: '' }) }} />
                                                <label><strong>All Stores</strong></label>
                                                {this.state.optionError !== "" && <span className="help-block">{this.state.optionError}</span>}
                                            </div>
                                            <div className={this.state.optionError !== "" ? "form-group col-md-6 has-error" : "form-group col-md-6"}>
                                                <input type="radio" name="stores" value={this.state.specificStoreSelected} checked={this.state.specificStoreSelected}
                                                    onChange={() => { this.setState({ specificStoreSelected: true, allStoresSelected: false, optionError: '' }) }} />
                                                <label><strong>Specific Stores</strong></label>
                                                {this.state.optionError !== "" && <span className="help-block">{this.state.optionError}</span>}
                                            </div>
                                            <div className="col-md-12">
                                                {this.state.specificStoreSelected === true &&
                                                    <div className={this.state.storeNameError !== "" ? "form-group has-error" : "form-group "}>
                                                        <CreatableSelect
                                                            defaultValue={this.state.associatedStores}
                                                            options={this.state.stores}
                                                            onChange={this.handleOnChange}
                                                            isMulti
                                                        />
                                                        {this.state.storeNameError !== "" && <span className="help-block">{this.state.storeNameError}</span>}
                                                    </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="box">
                                    <div className="box-header with-border">
                                        <h3 className="box-title">Messages</h3>
                                    </div>
                                    <div className="box-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className={this.state.placeholderError !== "" ? "form-group has-error" : "form-group "}>
                                                    <label >Place holder</label>
                                                    <select className="form-control" value={this.state.placeholder}
                                                        onChange={this.handleOnPlaceHolderChange}>
                                                        <option value="">Select</option>
                                                        <option value="Customer Name">Customer Name</option>
                                                        <option value="Festival Name">Festival Name</option>
                                                    </select>
                                                    {this.state.placeholderError !== "" && <span className="help-block">{this.state.placeholderError}</span>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={this.state.messageError !== "" ? "form-group has-error" : "form-group "}>
                                                    <label >Message</label>
                                                    <textarea className="form-control"
                                                        onChange={(evt) => this.setState({ message: evt.target.value, messageError: "" })}
                                                        value={this.state.message}
                                                    ></textarea>
                                                    {this.state.messageError !== "" && <span className="help-block">{this.state.messageError}</span>}
                                                </div>
                                            </div><br />
                                            <p align="center">Notes: 1. Following text will be appended by default on all SMS.<br />
                                                To opt out call 1111111111<br />2. One SMS consists of 126 characters.<br />
                                                3. SMS having more than 126 characters is treated as 2 SMS.<br />
                                                4. Special characters count will be double in length ^{}\[]~|<br />
                                            </p>
                                        </div><br />
                                        
                                        <div className="col-md-">
                                            <button type="submit" style={{ marginLeft: "10px" }}
                                                className="btn btn-flat btn-md btn-success"
                                                onClick={this.onExportCustomers}>Export Customers</button>
                                        </div><br></br>
                                        <div className="col-md-4">
                                            <button type="submit" style={{ marginLeft: "10px" }}
                                                className="btn btn-flat btn-md btn-success"
                                                onClick={this.customersForTotalQty}>Transactions</button>
                                        </div><br></br>
                                        <div className="col-md-4">
                                            <button type="submit" style={{ marginLeft: "10px" }}
                                                className="btn btn-flat btn-md btn-success"
                                                onClick={this.customersForTotalSpent}>Spent</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button type="submit" className="btn btn-flat btn-md btn-success pull-right"
                    onClick={this.onSaveCampaign} style={{ marginLeft: "10px" }}>Save</button>
                <Link to="/home/campaign" type="submit" className="btn btn-flat btn-md btn-default pull-right">Cancel</Link>
            </section>
        </div >
    }
}

export default withRouter(addCampaign)