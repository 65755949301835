import React,{Component} from 'react'
import { withRouter } from 'react-router-dom'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import './Style.css'
import Print from './Print'

class Payment extends Component{

    constructor()
    {
      super()
      this.state = {
        sale:null,
        key:'',
        selectedPaymentMode:'',
        selectedPaymentModeID:'',
        balanceAmount:0,
        paidAmount:0,
        paymentModeAmount:0,
        paymentModeRemarks:'',
        changeDue:0,
        payments:[],
        firstCurrencyClick:true,
        printChecked:true,
        sendSMSChecked:false,
        sendEmailChecked:false,
        SMSNo:'',
        emailID:'',
        selectedPringConfigID:'',
        selectedPrintConfigPrintDisplayName:'',
        selectedPrintConfig:null,
        walletBalance:0,
        OTPVerificationRequired:false,
        OTP:'',
        walletAdded:false,
        saving:false,
        selectedPaymentOptionLoading:false,
        print:false,
        printKOT:false,
        printKOTData:null,
        paymentModes:[],
        outstandingRRNs:[],
        selectedRRNID:''
      }
      this.onPaymentModeSelected= this.onPaymentModeSelected.bind(this)
      this.onAddPaymentAmount = this.onAddPaymentAmount.bind(this)
      this.onSaveSalesTransaction =this.onSaveSalesTransaction.bind(this)
      this.onCurrencyClick = this.onCurrencyClick.bind(this)
      this.addPaymentAmount = this.addPaymentAmount.bind(this)
      this.paymentModeSelected = this.paymentModeSelected.bind(this)
      this.roundUpTo2 = this.roundUpTo2.bind(this)
      this.printFormatting = this.printFormatting.bind(this)
    }

    componentDidMount(){
        //firt payment mode to be made selected 
        let selectedPaymentMode=""
        let selectedPaymentModeID=""
        let selectedPringConfigID=""
        let paymentModes = this.props.config.PaymentTypes.filter((e)=> e.ShowInSales && !e.IsDeleted)
        let printChecked= false
        let sendSMSChecked = false
        let SMSNo = ""
        let sendEmailChecked = false
        let emailID=""
        let selectedPrintConfigPrintDisplayName = ""

        if(this.props.sale.TransactionType.hasOwnProperty('PaymentTypes'))
        {
            let transactionTypePaymentModes = []
            paymentModes.map(m=>{
                if(this.props.sale.TransactionType.PaymentTypes.filter( f=> f.PaymentTypeID === m.key).length > 0)
                {
                    transactionTypePaymentModes.push(m)
                } 
            })
            paymentModes = transactionTypePaymentModes
        }
        
        if(paymentModes.length >  0 )
        {
            if(paymentModes[0].PaymentType.toLowerCase()!=="wallet" && paymentModes[0].PaymentType.toLowerCase()!=="return redemption note")
            {
                selectedPaymentModeID = paymentModes[0].key
                selectedPaymentMode = paymentModes[0].PaymentType
            }       
        }
        //console.log('paymentModes', paymentModes)
        //firt print confign to be made selected 
        let printConfig = this.props.config.PrintConfigs.filter((e)=> !e.IsDeleted 
                        && e.TransactionTypeID === this.props.sale.TransactionType.key)
        if(printConfig.length > 0 )
        {
            selectedPringConfigID = printConfig[0].key
            selectedPrintConfigPrintDisplayName =printConfig[0].PrintDisplayName
            printChecked= true 
        }
        else
        {
            printChecked= false
        }
        //if(this.props.location.state.sale.Customer)
        if(this.props.sale.Customer)        
        {
            if(this.props.sale.Customer.PhoneNo !=="")
            {   sendSMSChecked = true 
                SMSNo = this.props.sale.Customer.PhoneNo
            }
            if(this.props.sale.Customer.EmailID !=="")
            {   sendEmailChecked = true 
                emailID = this.props.sale.Customer.EmailID
            }   
        }
       
        this.setState({sale:this.props.sale, 
            balanceAmount: this.props.sale.BillAmount,
            paymentModeAmount: this.props.sale.BillAmount, 
            key:this.props.transactionID,
            selectedPaymentMode:selectedPaymentMode,
            selectedPaymentModeID:selectedPaymentModeID,
            selectedPringConfigID: selectedPringConfigID,
            selectedPrintConfigPrintDisplayName: selectedPrintConfigPrintDisplayName,
            printChecked:printChecked,
            sendSMSChecked:sendSMSChecked,
            SMSNo:SMSNo,
            sendEmailChecked:sendEmailChecked,
            emailID:emailID,
            paymentModes:paymentModes
            })
    }

    roundUpTo2(num){
        return Math.round(Number(num) * 100) / 100
    }

   async onPaymentModeSelected(paymentMode){
        let walletBalance= 0 
        let OTPVerificationRequired = false 
        
        this.setState({selectedPaymentOptionLoading:true,  outstandingRRNs:[], selectedRRNID:''})
        
        if(paymentMode.PaymentType.toLowerCase()==="wallet")
        {
           
            if(this.state.sale.Customer)
            {
                this.props.getCustomerWalletBalance(this.state.sale.Customer.key).then((w)=>{
                    walletBalance = this.roundUpTo2(w) 
                    if(walletBalance<=0 )
                    {
                        alert("No wallet balance")
                        this.setState({selectedPaymentOptionLoading:false})
                        return 
                    }
                    if(paymentMode.OTPVerification)
                    {
                        OTPVerificationRequired = paymentMode.OTPVerification
                        //this.props.generateOTP()
                        //gerate OPT
                        this.props.generateOTP(this.props.user.user.RegistrationID, this.props.user.store.key,
                            this.props.user.cashRegister.key, this.state.sale.Customer.key).then((otpGenerated)=>{
                                if(!otpGenerated)
                                {
                                    alert("Unable to generate OTP")
                                    this.setState({selectedPaymentOptionLoading:false})
                                    return
                                }
                                else
                                {
                                    this.setState({selectedPaymentOptionLoading:false})
                                    this.paymentModeSelected( paymentMode.key, 
                                        paymentMode.PaymentType,
                                        this.state.balanceAmount,
                                        true,walletBalance,OTPVerificationRequired)    
                                }
                            })
                    }
                    else
                    {
                        this.setState({selectedPaymentOptionLoading:false})
                        this.paymentModeSelected( paymentMode.key, 
                            paymentMode.PaymentType,
                            this.state.balanceAmount,
                            true,walletBalance,OTPVerificationRequired)   
                    }
                })
            }
            else{
                alert("Please select customer")
                this.setState({selectedPaymentOptionLoading:false})
                return 
            }
        }
        else{
            this.setState({selectedPaymentOptionLoading:false})
            if(paymentMode.PaymentType.toLowerCase()==="return redemption note" && this.state.balanceAmount < 0 )
            {
            // alert('return redemtion to be generated')
            // this.setState({selectedPaymentOptionLoading:false})
                if(!this.state.sale.Customer)
                {
                    this.setState({selectedPaymentOptionLoading:false})
                    alert('Please select customer')
                    return
                }
            }
            else if(paymentMode.PaymentType.toLowerCase()==="return redemption note" && this.state.balanceAmount > 0 )
            {
                // alert('return redemtion to be used')
                // this.setState({selectedPaymentOptionLoading:false})
                if(!this.state.sale.Customer)
                {
                    this.setState({selectedPaymentOptionLoading:false})
                    alert('Please select customer')
                    return
                }
                //get outsanding rrn
                let outstandingRRNs = await this.props.getOutstandingRRN(this.state.sale.Customer.key)
                if(outstandingRRNs.length===0)
                {
                    this.setState({selectedPaymentOptionLoading:false})
                    alert('No outstanding RRN found')
                    return
                }
                this.setState({outstandingRRNs:outstandingRRNs})
            }
            this.paymentModeSelected( paymentMode.key, 
                paymentMode.PaymentType,
                this.state.balanceAmount,
                true,walletBalance,OTPVerificationRequired)   
        }
    }

    paymentModeSelected(selectedPaymentModeID, 
        selectedPaymentMode, 
        paymentModeAmount ,firstCurrencyClick, 
        walletBalance, OTPVerificationRequired)
        {
            this.setState({selectedPaymentModeID: selectedPaymentModeID, 
                selectedPaymentMode:selectedPaymentMode, 
                paymentModeAmount:paymentModeAmount ,firstCurrencyClick:firstCurrencyClick, 
                walletBalance:walletBalance, OTPVerificationRequired: OTPVerificationRequired, selectedPaymentOptionLoading:false})
        }

    onAddPaymentAmount(){
        //payment amount should be greater than zero 
        // console.log('this.state.payments',this.state.payments)
        if(this.state.selectedPaymentMode.toLowerCase()==="")
        {
            alert('Please select payment mode')
            return 
        }
        if(this.state.paymentModeAmount ===0 )
        {
            alert('Please provide amount')
            return 
        }
        if(this.state.selectedPaymentMode.toLowerCase() === "cash" 
        || this.state.selectedPaymentMode.toLowerCase() === "wallet" 
        || (this.state.selectedPaymentMode.toLowerCase() === "return redemption note"  && this.state.paymentModeAmount< 0))
        {
            let arr = this.state.payments.filter((p)=> p.PaymentMode.toLowerCase() === this.state.selectedPaymentMode.toLowerCase() )
            if(arr.length> 0)
            {
                alert('Payment mode already added')
                return
            }
        }
        if(this.state.selectedPaymentMode.toLowerCase() === "return redemption note" && this.state.paymentModeAmount> 0)
        {
            //ceheck if selected rrn exists
            if(this.state.selectedRRNID.trim()===""){
                alert('Please select RRN')
                return
            }

            //user can not add the sam ereturn redemption note twice 
            if( this.state.payments.filter((p)=>
            p.PaymentMode.toLowerCase() === this.state.selectedPaymentMode.toLowerCase() 
            && p.hasOwnProperty('RRNID')
            && p.RRNID === this.state.selectedRRNID ).length> 0){
                alert('Selected RRN already added')
                return
            }
            
            //check amount selected should be valaible in selected RRN
            if(this.state.paymentModeAmount> Number( this.state.outstandingRRNs.filter(f=> f.key === this.state.selectedRRNID)[0].BalanceAmount) )
            {
                alert("Insuficient RRN balance")
                return
            }
        }

        this.setState({selectedPaymentOptionLoading:true})
        
        if(this.state.paymentModeAmount <= 0   && this.state.sale.TransactionType.TransactionType.toLowerCase() !== "sales return")
        {
            alert('Please provide payment amount')
            this.setState({selectedPaymentOptionLoading:false})
            return
        }
        if(this.state.selectedPaymentMode.toLowerCase() === "wallet")
        {
            if(this.state.paymentModeAmount > this.state.walletBalance)
            {
                alert("Insuficient wallet balance")
                this.setState({selectedPaymentOptionLoading:false})
                return
            }
            else if(this.state.OTPVerificationRequired)
            {
                if(this.state.OTP.trim() ==="")
                {
                    alert("Please provide OTP")
                    this.setState({selectedPaymentOptionLoading:false})
                    return 
                }
                else{
                    //validate OTP
                    this.props.validateOTP(this.props.user.user.RegistrationID, this.props.user.store.key,
                        this.props.user.cashRegister.key, this.state.sale.Customer.key, this.state.OTP).then((otpVerified)=>{
                            if(!otpVerified)
                            {
                                alert('Invalid OTP')
                                this.setState({selectedPaymentOptionLoading:false})
                                return
                            }
                           
                            this.addPaymentAmount()
                            this.setState({walletAdded:true,selectedPaymentOptionLoading:false})
                           
                        })
                }
            }
            else {
                this.setState({walletAdded:true,selectedPaymentOptionLoading:false})
                this.addPaymentAmount()
            }
        }
        else
        {
            this.setState({selectedPaymentOptionLoading:false})
            this.addPaymentAmount()
        }
        //console.log('payments',payments)
    }

    addPaymentAmount(){
        let changeDue= 0 
        let balanceAmount = 0
        let paidAmount = 0 
        let payments= [] 
        // console.log('this.state.paymentModeAmount', this.state.paymentModeAmount)
        if(this.state.selectedPaymentMode.toLowerCase() === "cash")
        {//amount more than bill amount is allowed 
            if(this.state.paymentModeAmount > this.state.balanceAmount)
            {
                changeDue = Number(this.state.paymentModeAmount)  - Number(this.state.balanceAmount)
                balanceAmount = 0
                paidAmount = Number(this.state.sale.BillAmount)
            }
            else
            {
                changeDue = 0 
                balanceAmount = Number(this.state.balanceAmount) - Number(this.state.paymentModeAmount)
                paidAmount = Number(this.state.paidAmount) + Number(this.state.paymentModeAmount)
            }
            this.setState({changeDue:changeDue,
                balanceAmount: balanceAmount,
                paidAmount: paidAmount,
                paymentModeAmount: balanceAmount
            })
            payments = this.state.payments
            payments.push({PaymentMode: this.state.selectedPaymentMode , Amount: this.state.paymentModeAmount, PaymentModeRemarks:this.state.paymentModeRemarks})
            this.setState({payments: payments, paymentModeRemarks:'', outstandingRRNs:[], selectedRRNID:''})
        }
        else
        {
            
            if(this.state.paymentModeAmount > 0 && this.state.paymentModeAmount > this.state.balanceAmount)
            {
                alert('Amount can not be more than balance amount')
                return 
            }
            
            
            payments = this.state.payments
            let RRNID = ""
            let RRNTransactionNo=""
            if(this.state.selectedPaymentMode.toLowerCase() === "return redemption note"  && this.state.paymentModeAmount> 0)
            {
                RRNTransactionNo = this.state.outstandingRRNs.filter(f=> f.key === this.state.selectedRRNID)[0].TransactionNo
                RRNID= this.state.outstandingRRNs.filter(f=> f.key === this.state.selectedRRNID)[0].key
            }
            payments.push({PaymentMode: this.state.selectedPaymentMode , 
                Amount: this.state.paymentModeAmount, 
                PaymentModeRemarks:this.state.paymentModeRemarks,
                RRNID: RRNID,
                RRNTransactionNo:RRNTransactionNo
                })

            changeDue = 0 
            balanceAmount = Number(this.state.balanceAmount) - Number(this.state.paymentModeAmount)
            paidAmount = Number(this.state.paidAmount) + Number(this.state.paymentModeAmount)
            let selectedPaymentModeID= ''
            let selectedPaymentMode = ''
            
            // let paymentModes = this.props.config.PaymentTypes.filter((e)=> !e.IsDeleted)
            let paymentModes = this.state.paymentModes
            if(paymentModes.length >  0 )
            {
                selectedPaymentModeID = paymentModes[0].key
                selectedPaymentMode = paymentModes[0].PaymentType
            }
            this.setState({payments: payments, 
            selectedPaymentModeID:selectedPaymentModeID, 
            selectedPaymentMode:selectedPaymentMode,
            walletBalance:0,changeDue:changeDue,
            balanceAmount: balanceAmount,
            paidAmount: paidAmount,
            paymentModeAmount: balanceAmount, 
            OTPVerificationRequired:false,
            paymentModeRemarks:'',
            selectedRRNID:'',
            outstandingRRNs:[]})
        }
        // console.log('this.state.paymentModeAmount', this.state.paymentModeAmount)
        if(balanceAmount === 0 )
        {
            this.btnSaveSalesTransaction.focus()
            // console.log('btnSaveSalesTransaction')
        }
    }
    
    onRemovePayment(index){
        
        let payments = this.state.payments
        if(payments[index].PaymentMode.toLowerCase() ==="wallet"){
            this.setState({walletAdded:false})
        }
        payments.splice(index, 1)
        let balanceAmount =0
        let paidAmount = 0
        let paymentModeAmount=0
        let changeDue = 0

        payments.map((p) =>{
            paidAmount = Number(paidAmount)+  Number(p.Amount) 
        }) 
        balanceAmount = Number(this.state.sale.BillAmount) - Number(paidAmount)
        paymentModeAmount = Number(balanceAmount)
        this.setState({payments:payments, 
                changeDue:changeDue,
                balanceAmount: balanceAmount,
                paidAmount: paidAmount,
                paymentModeAmount: paymentModeAmount
        })
    }

    async onSaveSalesTransaction()
    {
        //save invoice 
        this.setState({ saving: true })
        let sale = this.state.sale
        sale.BalanceAmount = Number(this.state.balanceAmount)
        sale.BillPaidAmount = Number(this.state.paidAmount)
        sale.BillChangeDue = Number(this.state.changeDue)
        sale.Payments = this.state.payments
        sale.PrintChecked = this.state.printChecked
        sale.PrintConfigID = this.state.selectedPringConfigID
        // sale.PrintDisplayName
        sale.SendSMSChecked = this.state.sendSMSChecked
        sale.SMSNo = this.state.SMSNo
        sale.SendEmailChecked = this.state.sendEmailChecked
        sale.EmailID = this.state.emailID
        sale.TransactionStatus = "Completed"

        if (sale.TransactionType.TransactionType.toLowerCase() === "receive payment") {
            if (sale.BalanceAmount > 0) {
                alert('Balance amount not allowed.')
                this.setState({ saving: false })
                return
            }
        }
        else {
            if (sale.TransactionType.hasOwnProperty('BalanceAmount')) {
                if (sale.TransactionType.BalanceAmount === "partial") {
                    if (sale.BillPaidAmount === 0) {
                        alert('Full balance not allowed.')
                        this.setState({ saving: false })
                        return
                    }
                }
                else if (sale.TransactionType.BalanceAmount === "no") {
                    if (sale.BalanceAmount > 0) {
                        alert('Balance not allowed.')
                        this.setState({ saving: false })
                        return
                    }
                }
            }
        }

        sale.Payments.filter(f => f.PaymentMode.toLowerCase() === "wallet").map(m => sale.WalletBalance = Number(sale.Customer.WalletBalance) - Number(m.Amount))

        if (this.state.key.trim() === '') {//new sale  
            
            // this.props.disableNetwork();
            // console.log("Network Disabled!!!!");

            let nextRunningNo = await this.props.getTransactionRunningNo(sale.CashRegister.key, sale.TransactionType.key)

            let printKOT = (this.props.registration.registration.hasOwnProperty("KOT")  && this.props.registration.registration.KOT) ? this.props.registration.registration.KOT : false
            
            let KOTNo = 0
            sale.TransactionDate = new Date()
            sale.TransactionNo = sale.TransactionNo + nextRunningNo
            if (printKOT) {
                KOTNo = await this.props.getKOTRunningNo(sale.CashRegister.key)
                console.log("KOTNo", KOTNo)
                sale.KOTNo = KOTNo
            }

            let key = await this.props.addSales(sale);
            //update sales running no

            await this.props.updateTransactionRunningNo(sale.CashRegister.key, sale.TransactionType.key, nextRunningNo)

            // this.props.enableNetwork();
            // console.log("Network Enabled!!!!");

            toastr.options = {
                positionClass: 'toast-bottom-full-width',
                hideDuration: 300,
                timeOut: 3000
            }
            toastr.success("Transaction #" + sale.TransactionNo + " created successfully")
            if (this.state.printChecked || this.state.sendSMSChecked || this.state.sendEmailChecked) {
                let salePrint = { ...sale }
                salePrint.key = key
                this.printFormatting(salePrint)
                let printData = { Sale: salePrint, PrintConfig: this.props.config.PrintConfigs.filter((e) => e.key === this.state.selectedPringConfigID)[0] }
                let printKOTConfig = {}
                let printKOTData = {}
                if (printKOT) {

                    printKOTConfig.PrintSize = "3 inch"
                    printKOTConfig.PrintTemplate = this.props.registration.registration.hasOwnProperty("KOTTemplate") ? this.props.registration.registration.KOTTemplate : ""
                    printKOTData = { Sale: salePrint, PrintConfig: printKOTConfig }
                }
                if (this.state.selectedPringConfigID === "") {
                    this.setState({ printChecked: false, sendEmailChecked: false })
                }
                this.setState({
                    print: true,
                    sale: sale,
                    printData: printData,
                    printKOT: printKOT,
                    printKOTData: printKOTData
                })//TBDpawan
                return
            }
            else {
                this.props.onSave()
                this.setState({ saving: false })
            }
        }
        else {//edit sale

            //alert('Update Called')
            this.props.updateSales(sale, this.state.key)
            toastr.options = {
                positionClass: 'toast-bottom-full-width',
                hideDuration: 300,
                timeOut: 3000
            }
            toastr.success("Invoice#" + sale.TransactionNo + " updated successfully")
            if (this.state.printChecked || this.state.sendSMSChecked || this.state.sendEmailChecked) {
                // console.log('sale edit',sale)
                this.printFormatting(sale)
                let printData = { Sale: sale, PrintConfig: this.props.config.PrintConfigs.filter((e) => e.key === this.state.selectedPringConfigID)[0] }
                this.setState({ print: true, sale: sale, printData: printData })//TBDpawan
                return
            }
            else {
                this.props.onSave()
                this.setState({ saving: false })
            }
        }
    }

    printFormatting(sale){
        
        // sale1.TransactionDate =  (new Date(sale.TransactionDate.seconds*1000)).toString().substring(0,24)
        sale.TransactionDate = sale.TransactionDate.toString().substring(0,24)  
        let billTaxableAmount = 0 
        let costPriceTotal = 0 
        let mrpTotal = 0
        sale.LineItems.map((lineItem, index) => {
            sale.LineItems[index].SrNo = index +1
            sale.LineItems[index].BasePrice = this.roundUpTo2(Number(sale.LineItems[index].TaxableAmount) / Number(sale.LineItems[index].Qty))
            if(lineItem.TaxComponentAmount && lineItem.TaxComponentAmount.length > 0 && lineItem.TaxInclusive)
            {
                let taxPercentage = 0
                let taxAmount = 0
                lineItem.TaxComponentAmount.map(m=>{
                    taxPercentage = taxPercentage + Number(m.TaxPercentage)
                })
                taxAmount = this.roundUpTo2(Number(lineItem.RetailPrice) * Number(taxPercentage) / (100 + Number(taxPercentage)))
                sale.LineItems[index].CostPrice = Number(lineItem.RetailPrice) - Number(taxAmount)
            }
            else
            {
                sale.LineItems[index].CostPrice = lineItem.RetailPrice
            }
            sale.LineItems[index].CostPriceTotal = this.roundUpTo2(Number(sale.LineItems[index].CostPrice) * Number(sale.LineItems[index].Qty))
            costPriceTotal = costPriceTotal + Number(sale.LineItems[index].CostPriceTotal)

            sale.LineItems[index].MRP = Number(lineItem.Product.MRP === 0 ? lineItem.RetailPrice :  lineItem.Product.MRP);

            sale.LineItems[index].MRPTotal = this.roundUpTo2(Number(sale.LineItems[index].MRP) * Number(sale.LineItems[index].Qty));
            mrpTotal = mrpTotal + Number(sale.LineItems[index].MRPTotal)

            billTaxableAmount = this.roundUpTo2(Number(billTaxableAmount) + Number(sale.LineItems[index].TaxableAmount))
        })
        sale.BillCostPriceTotal = costPriceTotal
        sale.BillMRPTotal = mrpTotal
        sale.MRPSaving = this.roundUpTo2(this.roundUpTo2(Number(sale.BillMRPTotal) - this.roundUpTo2(Number(sale.BillAmount))));

        sale.BillTaxableAmount = this.roundUpTo2(Number(billTaxableAmount))
       


        sale.AmountInWords= this.props.convertNumberToWords(sale.BillAmount)  
        // console.log('sale1.AmountInWords', sale1.AmountInWords)
        sale.LineItems.map((lineItem, index) => {
            sale.LineItems[index].SrNo = index +1
        })
        let printTaxComponents= []
        let printTaxComponentsWithRate= []
        sale.LineItems.map((lineItem, index) => {
            if(lineItem.TaxID !=="")
            {
                lineItem.TaxComponentAmount.map((t)=>{
                    let indexFound = -1 
                    printTaxComponents.map((pt,ptIndex)=>{
                        if(t.TaxType===pt.TaxType)
                        {
                            indexFound = ptIndex
                            return
                        }
                    })
                    if(indexFound<0)
                    {
                        printTaxComponents.push({ TaxType:t.TaxType,
                            TaxAmount:this.roundUpTo2(Number(t.TaxAmount))
                           })
                    }
                    else
                    {
                        printTaxComponents[indexFound].TaxAmount =this.roundUpTo2( Number(printTaxComponents[indexFound].TaxAmount )+ Number(t.TaxAmount))
                    }


                    let indexFoundTaxRate = -1 
                    printTaxComponentsWithRate.map((pt,ptIndex)=>{
                        if(t.TaxType===pt.TaxType && t.TaxPercentage ===pt.TaxPercentage )
                        {
                            indexFoundTaxRate = ptIndex
                            return
                        }
                    })
                    if(indexFoundTaxRate<0)
                    {
                        printTaxComponentsWithRate.push({ TaxType:t.TaxType,TaxPercentage:t.TaxPercentage,
                            TaxAmount:this.roundUpTo2(Number(t.TaxAmount)),  TaxableAmount: Number(lineItem.TaxableAmount)
                           })
                    }
                    else
                    {
                        printTaxComponentsWithRate[indexFoundTaxRate].TaxAmount =this.roundUpTo2( Number(printTaxComponentsWithRate[indexFoundTaxRate].TaxAmount )+ Number(t.TaxAmount))
                        printTaxComponentsWithRate[indexFoundTaxRate].TaxableAmount =this.roundUpTo2( Number(printTaxComponentsWithRate[indexFoundTaxRate].TaxableAmount )+  Number(lineItem.TaxableAmount))
                    }
                })
           }
        })
        sale.PrintTaxComponents = printTaxComponents
        sale.PrintTaxComponentsWithRate= printTaxComponentsWithRate
        sale.LineItems.map((lineItem, index)=>{
            for(var i=0; i<printTaxComponents.length; i++ )
            {
                if(lineItem.TaxComponentAmount.length<(i+1))
                {
                    lineItem.TaxComponentAmount.push({TaxName:'', TaxAmount:0, TaxPercentage:'',TaxType:''})
                }
            }
        })
        //wallet balance
        // sale.Payments.filter(f=> f.PaymentMode.toLowerCase()==="wallet").map(m=> sale.WalletBalance = Number(sale.Customer.WalletBalance )- Number(m.Amount))
        console.log('print sale', sale)
    }
    
    onCurrencyClick(currencyAmt){
        let paymentModeAmount =  this.state.firstCurrencyClick ? Number(currencyAmt) : Number(this.state.paymentModeAmount) + Number(currencyAmt )
        this.setState({paymentModeAmount:paymentModeAmount, firstCurrencyClick : this.state.firstCurrencyClick ? !this.state.firstCurrencyClick : this.state.firstCurrencyClick})
    }

    render()
    {
        const currencyBtn = {margin: "0px", 
        width: "80px", 
        height: "80px" ,
        fontWeight: "bold",
        color: "gray", 
        //background: "white"
        }

        const payStyleSize = {fontSize:"35px"}
        
        return<div className="content-wrapper">
        {!this.state.print && <React.Fragment>
            {this.state.sale && <section className="content-header">
                <h1 style={{marginLeft:"10px"}}>{ this.state.sale.TransactionType.PaymentModeChecked ? "Payment options - " + this.state.sale.TransactionType.TransactionType : "Save - " + this.state.sale.TransactionType.TransactionType }</h1>
                <button  className="btn btn-lg btn-flat btn-default pull-right"  
                    style={{marginTop:"-35px"}} 
                    disabled= {this.state.saving}
                    onClick={this.props.onCancel} >Back
                </button>
            </section>}
            {this.state.sale && <section className="content">
                <div className="row" >
                    <div className="col-md-6">
                        <div className="payment-container-left">
                        {this.state.sale.TransactionType.PaymentModeChecked && <div className="col-md-5 scroll2" style={{overflowX: "scroll",overflow: "scroll",height:"80vh"}}>
                                {/* .filter((e)=> e.ShowInSales && !e.IsDeleted) */}
                                {this.state.paymentModes.map((paymentMode )=>
                                    <button 
                                    style={{marginBottom:"20px"}}
                                    className={this.state.selectedPaymentMode ===paymentMode.PaymentType  ?  "btn btn-lg btn-flat btn-info btn-block" :  "btn btn-flat btn-lg btn-default btn-block" }
                                    onClick={(evt) => this.onPaymentModeSelected(paymentMode)}
                                    disabled = {(paymentMode.PaymentType.toLowerCase() === "wallet" && this.state.walletAdded) || this.state.selectedPaymentOptionLoading ? true : false}
                                    >
                                    {paymentMode.PaymentType}
                                    </button>
                                )}
                        </div>}
                        {this.state.sale.TransactionType.PaymentModeChecked && <div className="col-md-7">
                                <div className="row">
                                        <div className="input-group " >
                                            <input type="number" className="form-control input-lg" 
                                                value={this.state.paymentModeAmount}
                                                onChange={(evt)=> this.setState({paymentModeAmount: evt.target.value}) }
                                                disabled={this.state.selectedPaymentOptionLoading}
                                                ref={(ref) => this.txtPaymentModeAmount = ref}
                                                onKeyPress={(ev) => {
                                                    //console.log(`Pressed keyCode ${ev.key}`);
                                                    if (ev.key === 'Enter') {
                                                      // Do code here
                                                      this.onAddPaymentAmount()
                                                    }
                                                  }}
                                                />
                                            <span className="input-group-btn">
                                                <button type="button" 
                                                onClick={()=> this.onAddPaymentAmount() }
                                                disabled={this.state.selectedPaymentOptionLoading}
                                                className="btn btn-lg btn-info btn-flat"> Add Payment  <i className="fa fa-arrow-right"></i>
                                                </button>
                                            </span>
                                        </div>
                                </div>
                                <div className="row">
                                        {this.state.selectedPaymentMode.toLowerCase() === 'cash' &&  <div className="row">
                                        <div style={{marginTop:"5px",marginLeft: "15px"}}>
                                            <button 
                                            onClick={()=> {let amt = 2000; this.onCurrencyClick(amt)}} 
                                            disabled={this.state.selectedPaymentOptionLoading}
                                            className="btn btn-flat btn-default" style={currencyBtn}>
                                                2000 {this.props.user.store.Currency}
                                            </button>
                                            <button  
                                            onClick={()=> {let amt = 500; this.onCurrencyClick(amt)}} 
                                            disabled={this.state.selectedPaymentOptionLoading}
                                            className="btn btn-flat btn-default" style={currencyBtn}>
                                                500 {this.props.user.store.Currency}
                                            </button>
                                            <button 
                                            onClick={()=> {let amt = 200; this.onCurrencyClick(amt)}} 
                                            disabled={this.state.selectedPaymentOptionLoading}
                                            className="btn btn-flat btn-default" style={currencyBtn}>
                                                200 {this.props.user.store.Currency}
                                            </button>
                                            <button  
                                            onClick={()=> {let amt = 100; this.onCurrencyClick(amt)}} 
                                            disabled={this.state.selectedPaymentOptionLoading}
                                            className="btn btn-flat btn-default" style={currencyBtn}>
                                                100 {this.props.user.store.Currency}
                                            </button>
                                            <button 
                                            onClick={()=> {let amt = 50; this.onCurrencyClick(amt)}} 
                                            disabled={this.state.selectedPaymentOptionLoading}
                                            className="btn btn-flat btn-default" style={currencyBtn}>
                                                50 {this.props.user.store.Currency}
                                            </button>
                                            <button  
                                            onClick={()=> {let amt = 20; this.onCurrencyClick(amt)}} 
                                            disabled={this.state.selectedPaymentOptionLoading}
                                            className="btn btn-flat btn-default" style={currencyBtn}>
                                                20 {this.props.user.store.Currency}
                                            </button>
                                            <button  
                                            onClick={()=> {let amt = 10; this.onCurrencyClick(amt)}} 
                                            disabled={this.state.selectedPaymentOptionLoading}
                                            className="btn btn-flat btn-default" style={currencyBtn}>
                                                10 {this.props.user.store.Currency}
                                            </button>
                                            <button  
                                            onClick={()=> {let amt = 5; this.onCurrencyClick(amt)}} 
                                            disabled={this.state.selectedPaymentOptionLoading}
                                            className="btn btn-flat btn-default" style={currencyBtn}>
                                                5 {this.props.user.store.Currency}
                                            </button>
                                            <button 
                                            onClick={()=> { this.setState({paymentModeAmount:this.state.balanceAmount,firstCurrencyClick:false })}} 
                                            disabled={this.state.selectedPaymentOptionLoading}
                                            className="btn btn-flat btn-default" style={currencyBtn}>
                                                Exact
                                            </button>
                                        </div>
                                    </div>}
                                </div>
                                {this.state.outstandingRRNs.length > 0 && <div className="row" style={{marginTop:"15px"}}>
                                    <div className="form-group">
                                                <label>RRN</label>
                                                <select className="form-control input-lg"
                                                    value={this.state.selectedRRNID}
                                                    onChange={(evt)=> this.setState({selectedRRNID:evt.target.value})}>
                                                    <option value="">Select RRN</option>
                                                    {this.state.outstandingRRNs.map((rrn) =>
                                                        <option value={rrn.key}>{rrn.TransactionNo} ({this.props.user.store.Currency} {rrn.BalanceAmount})</option>
                                                    )}
                                                </select>
                                    </div>
                                </div>}
                                {this.state.walletBalance > 0 && <div className="row" style={{marginTop:"15px"}}>
                                    <div className="form-group">
                                                <label>Wallet Blance</label>
                                                <input type="text" name="logoText" className="form-control input-lg" 
                                                disabled={true}
                                                value={this.state.walletBalance}
                                                onChange={(evt)=> this.setState({walletBalance:evt.target.value})} />
                                    </div>
                                </div>}
                                {this.state.OTPVerificationRequired  && <div className="row" >
                                    <div className="form-group">
                                                <label>OTP</label>
                                                <input type="text" name="logoText" className="form-control input-lg" 
                                                value={this.state.OTP}
                                                onChange={(evt)=> this.setState({OTP:evt.target.value})} />
                                    </div>
                                </div>}
                                <div className="row">
                                    <div style={{marginTop:"5px"}}>
                                        {this.state.selectedPaymentMode.toLowerCase() !== 'cash' && <div className="form-group">
                                                <input type="text" className="form-control input-lg"  
                                                onChange={(evt)=> this.setState({paymentModeRemarks:evt.target.value})}
                                                value={this.state.paymentModeRemarks}
                                                placeholder = "Remarks"/>
                                                </div>}
                                    </div>
                                </div>
                        </div>}
                        </div>
                    </div>
                    <div className="col-md-6">
                    {this.state.sale &&  <div className="payment-container-right">
                                        {/* SHOW SELECTED PAYMENT OPTION */}
                                            {/* <div className="row"> 
                                                    <div className="col-md-12" > */}
                                                    <div style={{minHeight:"58vh"}}>
                                                        <div className="col-md-12" style={payStyleSize}>
                                                            <label>{this.state.sale.TransactionType.PaymentModeChecked ?  "Pay" : "Total" }: </label>
                                                            <label className="pull-right">{this.state.sale.BillAmount}</label>
                                                        </div>
                                                        {this.state.sale.TransactionType.PaymentModeChecked && <div>
                                                            {this.state.payments.map((payment, index ) =>  
                                                            <div className="col-md-12" style={{fontSize:"18px"}} > 
                                                                    <button className="btn btn-flat btn-lg btn-default" style={{marginRight:"10px",marginBottom:"10px"}} 
                                                                    onClick={() => this.onRemovePayment(index)}><i className="fa fa-trash-o"></i></button>
                                                                    <label> {payment.PaymentMode}: </label>
                                                                    {payment.PaymentModeRemarks.trim() !=="" && <label> ({payment.PaymentModeRemarks})</label>}
                                                                    <label className="pull-right" style={{marginTop:"12px"}}>{payment.Amount} </label>
                                                                {/* <button onClick={() => this.onRemovePayment(index)}>Remove</button> */}
                                                            </div>
                                                            )}
                                                        </div>}
                                                        {this.state.sale.TransactionType.PaymentModeChecked && <div className="col-md-12" style={{fontSize:"18px"}}> 
                                                                <label>Paid Amount:</label>
                                                                <label className="pull-right">{this.state.paidAmount}</label>
                                                        </div>}
                                                        {this.state.sale.TransactionType.PaymentModeChecked && <div className="col-md-12" style={{fontSize:"18px"}}> 
                                                                <label>Balance Amount:</label>
                                                                <label className="pull-right">{this.state.balanceAmount}</label>
                                                        </div>}
                                                        {this.state.changeDue > 0 && <div className="col-md-12" style={{fontSize:"18px"}}> 
                                                                <label>Change Due:</label>
                                                                <label className="pull-right">{this.state.changeDue}</label>
                                                        </div>}
                                                    </div>
                                            
                                                <div className="col-md-12"> 
                                                    <div className="col-md-4" style={{padding:"0px"}}>
                                                        <div className="form-group">
                                                            <input type="checkbox"
                                                              disabled={this.state.selectedPringConfigID ===""}
                                                              onChange={() =>this.setState({printChecked:!this.state.printChecked})}
                                                              checked = {this.state.printChecked}/>
                                                            <label>Print</label>
                                                            <select className="form-control input-lg" 
                                                                disabled={!this.state.printChecked || this.state.selectedPringConfigID ===""}
                                                                value={this.state.selectedPringConfigID} 
                                                                onChange= {(evt) => this.setState({selectedPringConfigID:evt.target.value,
                                                                    selectedPrintConfigPrintDisplayName: evt.target.options[evt.target.selectedIndex].text })}>
                                                                    {this.props.config.PrintConfigs.filter((e)=> !e.IsDeleted 
                                                                    && e.TransactionTypeID === this.state.sale.TransactionType.key ).map((t)=> 
                                                                    <option value={t.key}>{t.PrintDisplayName}</option>
                                                        )}
                                                    </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4" style={{padding:"0px"}}>
                                                        <div className="form-group">
                                                            <input type="checkbox"   
                                                            onChange={() =>this.setState({sendSMSChecked:!this.state.sendSMSChecked})}
                                                            checked = {this.state.sendSMSChecked && this.props.config.ConnectionStatus.Status === 'connected'}
                                                            disabled = {this.props.config.ConnectionStatus.Status === 'disconnected'}
                                                            />
                                                            <label>Send SMS</label>
                                                            <input type="number"  
                                                            disabled={!this.state.sendSMSChecked} 
                                                            onChange={(evt=> this.setState({SMSNo:evt.target.value}))}
                                                            value = {this.state.SMSNo}
                                                            className="form-control input-lg"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4" style={{padding:"0px"}}>
                                                        <div className="form-group">
                                                            <input type="checkbox"
                                                            onChange={() =>this.setState({sendEmailChecked:!this.state.sendEmailChecked})}
                                                            checked = {this.state.sendEmailChecked && this.props.config.ConnectionStatus.Status === 'connected'}
                                                            disabled = {this.props.config.ConnectionStatus.Status === 'disconnected'}
                                                            />
                                                            <label >Send Email</label>
                                                            <input type="text" 
                                                            disabled={!this.state.sendEmailChecked} 
                                                            onChange={(evt=> this.setState({emailID:evt.target.value}))}
                                                            value = {this.state.emailID}
                                                            className="form-control input-lg"/>
                                                        </div>
                                                    </div>
                                                </div> 
                                                {/* <div className="row">  */}
                                                    {this.state.sale && <div className="col-md-12">
                                                        <button className="btn btn-lg btn-flat btn-success btn-block" 
                                                        ref={(ref) => this.btnSaveSalesTransaction = ref}
                                                        onClick={()=> this.onSaveSalesTransaction()} 
                                                    
                                                        // disabled ={(this.state.sale.TransactionType.PaymentModeChecked   && this.state.paidAmount === 0 && this.state.sale.TransactionType.TransactionType.toLowerCase() !=="sales return" )
                                                        //  || (this.state.paidAmount === 0 && this.state.sale.BillAmount !==0 && this.state.sale.TransactionType.TransactionType.toLowerCase() ==="sales return" )
                                                        //  || this.state.saving ? true : false}

                                                        disabled = {this.state.saving}
                                                         >{ this.state.saving ? "Saving Data...": "Done"}</button>
                                                    </div>  }
                                                {/* </div>   */}
                
                        </div>}
                    </div>
                </div>
            </section>}
            </React.Fragment>}
            {this.state.print && <div>
                <Print  {...this.props}   
                printChecked = {this.state.printChecked}
                sendEmailChecked={this.state.sendEmailChecked}
                sendSMSChecked = {this.state.sendSMSChecked}
                printData = {this.state.printData}   
                onClose={()=> {
                    // this.setState({print:false})
                    //this.props.onSave()
                    if(this.props.registration.registration.KOT){
                        //KOT printing to be done
                        if(this.state.printKOT)
                        {
                            this.setState({printChecked:true,
                                sendEmailChecked:false, 
                                sendSMSChecked: false,
                                printData: this.state.printKOTData,printKOT:false })
                        }
                        else{
                            //KOT printing done
                            // console.log("KOT printing done")
                            this.setState({print:false})
                            this.props.onSave()
                        }
                    }
                    else{
                        //non KOT printing      
                        this.setState({print:false})
                        this.props.onSave()
                    }
                }
            }
            />
            </div>}

            {/* {this.state.printKOT && <div>
                <Print  {...this.props}   
                printChecked = {this.state.printChecked}
                sendEmailChecked={false}
                sendSMSChecked = {false}
            printData = {this.state.printKOTData}   
            onClose={()=> {this.setState({printKOT:false})
            this.props.onSave()
                }
            }
            />
            </div>} */}
        </div>
    }
}
export default withRouter(Payment)