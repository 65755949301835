import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import * as constatnts from '../../Constatnts'
import { isNullOrUndefined } from 'util';
import Loader from '../Loader'
import Pagination from 'react-js-pagination'
import { Link } from 'react-router-dom';

class UniqueNoReport extends Component {
    constructor() {
        super()
        this.state = {
            uniqueNos: [],
            searchBrand: '',
            searchCategory: '',
            searchSubCategory: '',
            searchProductName: '',
            searchSKU: '',
            searchUniqueNo1: '',
            searchUniqueNo2: '',
            searchUniqueNo3: '',
            searchUniqueNo4: '',
            activePage: 1,
            itemsCountPerPage: 20,
            totalItemsCount: 0,
            recordFrom: 0,
            paginationStatus: 'No records to display',
            loading: true,
        }
        this.loadUniqueNo = this.loadUniqueNo.bind(this)
        this.showHideFilters = this.showHideFilters.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.onClearSearch = this.onClearSearch.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.showPaginationStatus = this.showPaginationStatus.bind(this)
        this.onExportClick = this.onExportClick.bind(this)
    }

    componentDidMount() {
        this.loadUniqueNo(this.state.recordFrom, this.state.searchBrand, this.state.searchCategory, this.state.searchSubCategory,
            this.state.searchProductName, this.state.searchSKU, this.state.searchUniqueNo1, this.state.searchUniqueNo2,
            this.state.searchUniqueNo3, this.state.searchUniqueNo4)
    }

    loadUniqueNo(from, searchBrand, searchCategory, searchSubCategory, searchProductName, searchSKU,
        searchUniqueNo1, searchUniqueNo2, searchUniqueNo3, searchUniqueNo4) {
        this.setState({ loading: true })
        this.props.getUniqueNos(this.props.user.user.RegistrationID, this.props.user.store.key, from, this.state.itemsCountPerPage, searchBrand,
            searchCategory, searchSubCategory, searchProductName, searchSKU, searchUniqueNo1, searchUniqueNo2, searchUniqueNo3, searchUniqueNo4)
            .then((result) => {
                // console.log(JSON.stringify(result.searchResult))
                this.setState({ uniqueNos: result.searchResult, totalItemsCount: result.totalItemsCount, loading: false })
                this.showPaginationStatus()
            })
    }

    showHideFilters() {
        let showFilters = this.state.showFilters
        this.setState({ showFilters: !showFilters })
    }

    onSearchClick() {
        this.setState({ searchFrom: 0, loading: true })
        this.loadUniqueNo(0,
            this.state.searchBrand,
            this.state.searchCategory,
            this.state.searchSubCategory,
            this.state.searchProductName,
            this.state.searchSKU,
            this.state.searchUniqueNo1,
            this.state.searchUniqueNo2,
            this.state.searchUniqueNo3,
            this.state.searchUniqueNo4)
    }

    onClearSearch() {
        this.setState({
            recordFrom: 0, searchBrand: '',
            searchCategory: '',
            searchSubCategory: '',
            searchProductName: '',
            searchSKU: '',
            searchUniqueNo1: '',
            searchUniqueNo2: '',
            searchUniqueNo3: '',
            searchUniqueNo4: ''
        })
        this.loadUniqueNo(0, "", "", "", "", "", "", "", "", "")
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }

    handlePageChange(pageNumber) {
        let recordFrom = ((pageNumber - 1) * this.state.itemsCountPerPage)
        this.setState({ activePage: pageNumber, recordFrom: recordFrom });
        this.loadUniqueNo(this.state.recordFrom, this.state.searchBrand,
            this.state.searchCategory,
            this.state.searchSubCategory,
            this.state.searchProductName,
            this.state.searchSKU,
            this.state.searchUniqueNo1,
            this.state.searchUniqueNo2,
            this.state.searchUniqueNo3,
            this.state.searchUniqueNo4)
    }

    onExportClick() {
        this.setState({ exportCalled: true })
        this.props.getUniqueNos(this.props.user.user.RegistrationID, this.props.user.store.key, 0, 10000, this.state.searchBrand,
            this.state.searchCategory, this.state.searchSubCategory, this.state.searchProductName, this.state.searchSKU, this.state.searchUniqueNo1, 
            this.state.searchUniqueNo2, this.state.searchUniqueNo3, this.state.searchUniqueNo4)
            .then((result) => {
                // console.log(result.searchResult);
                if (isNullOrUndefined(result.searchResult) || result.searchResult.length === 0) {
                    this.props.getToastr("No Record Found")
                    return
                }
                let uniqueNos = []
                result.searchResult.map((data) => {
                    let uniqueNo = {
                        "Store Name" : this.props.user.store.StoreName,
                        "Brand" : data.ProductBrand,
                        "Category" : data.ProductCategory,
                        "Sub Category" : data.ProductSubCategory,
                        "SKU" : data.ProductSKU,
                        "Barcode" : data.ProductBarCode,
                        "Name" : data.ProductName,
                        "Unique No 1" : data.UniqueNo1,
                        "Unique No 2" : data.UniqueNo2,
                        "Unique No 3" : data.UniqueNo3,
                        "Unique No 4" : data.UniqueNo4,
                        "Supply Price" : !isNullOrUndefined(data.InWardAmount) ? data.InWardAmount : "",
                        "Retail Price" : !isNullOrUndefined(data.OutWardAmount) ? data.OutWardAmount : "",
                        "Transaction No" : data.TransactionNo,
                        "Transaction Type" : data.TransactionType
                        }
                        uniqueNos.push(uniqueNo);
                })
                const csvData = constatnts.objToCSV(uniqueNos);
                this.setState({ exportCalled: false })
                const csvBlob = new Blob([csvData], { type: "text/csv" });
                const url = window.URL.createObjectURL(csvBlob);
                const a = document.createElement("a");
                a.setAttribute("hidden", "");
                a.setAttribute("href", url);
                a.setAttribute("download", "Unique No.csv");
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
    }


    showPaginationStatus() {
        console.log('this.state.recordFrom', this.state.recordFrom)
        let paginationStatus
        if ((this.state.recordFrom + 1) === this.state.totalItemsCount) {
            paginationStatus = "Showing " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else if ((this.state.recordFrom + this.state.itemsCountPerPage + 1) > this.state.totalItemsCount) {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + (this.state.recordFrom + this.state.itemsCountPerPage) + " of " + this.state.totalItemsCount
        }
        this.setState({ paginationStatus: paginationStatus })
    }


    render() {
        const btnColStyle = { width: "15px" }
        return <div className="content-wrapper">
            <section className="content">
                <div className="row">
                    <div className="col-md-12">
                        <div className="box">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6">
                                        <font style={{ fontSize: "30px" }}>Unique No Report</font>
                                    </div>
                                    <div className="col-md-6">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={() => this.props.history.push({ pathname: "/home/reports/allreport" })}
                                        ><i className="fa fa-arrow-left"></i> Back</button>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="row">

                                <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Unique No 1</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchUniqueNo1}
                                                onChange={(evt) => this.setState({ searchUniqueNo1: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Unique No 2</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchUniqueNo2}
                                                onChange={(evt) => this.setState({ searchUniqueNo2: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Unique No 3</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchUniqueNo3}
                                                onChange={(evt) => this.setState({ searchUniqueNo3: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Unique No 4</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchUniqueNo4}
                                                onChange={(evt) => this.setState({ searchUniqueNo4: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Product Name</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchProductName}
                                                onChange={(evt) => this.setState({ searchProductName: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>SKU</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchSKU}
                                                onChange={(evt) => this.setState({ searchSKU: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Category</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchCategory}
                                                onChange={(evt) => this.setState({ searchCategory: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Sub Category</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchSubCategory}
                                                onChange={(evt) => this.setState({ searchSubCategory: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>


                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Brand</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchBrand}
                                                onChange={(evt) => this.setState({ searchBrand: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    
                                
                                    
                                    
                                    <div className="col-md-9" >
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginLeft: "5px" }}
                                            onClick={this.onSearchClick}
                                        >Search</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                            onClick={this.onClearSearch} >Clear</button>
                                        <button class="btn btn-default btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginRight: "5px" }}
                                            onClick={this.onExportClick}
                                        > Export <i class="glyphicon glyphicon-download-alt " ></i></button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        {this.state.loading && <Loader show={this.state.loading} />}
                                        {!this.state.loading &&
                                            <table className="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Store Name</th>
                                                    <th>Brand</th>
                                                    <th>Category</th>
                                                    <th>Subcategory</th>
                                                    <th>SKU</th>
                                                    <th>Barcode</th>
                                                    <th>Product Name</th>
                                                    <th>Unique No 1</th>
                                                    <th>Unique No 2</th>
                                                    <th>Unique No 3</th>
                                                    <th>Unique No 4</th>
                                                    <th>Inward Amount</th>
                                                    <th>Outward Amount</th>
                                                    <th>Transaction No</th>
                                                    <th>Transaction Type</th>
                                                    <th style={btnColStyle}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.uniqueNos.map((uniqueNo) => 
                                                <tr>
                                                    <td>{this.props.user.store.StoreName}</td>
                                                    <td>{uniqueNo.ProductBrand}</td>
                                                    <td>{uniqueNo.ProductCategory}</td>
                                                    <td>{uniqueNo.ProductSubCategory}</td>
                                                    <td>{uniqueNo.ProductSKU}</td>
                                                    <td>{uniqueNo.ProductBarCode}</td>
                                                    <td>{uniqueNo.ProductName}</td>
                                                    <td>{uniqueNo.UniqueNo1}</td>
                                                    <td>{uniqueNo.UniqueNo2}</td>
                                                    <td>{uniqueNo.UniqueNo3}</td>
                                                    <td>{uniqueNo.UniqueNo4}</td>
                                                    <td>{uniqueNo.InWardAmount}</td>
                                                    <td>{uniqueNo.OutWardAmount}</td>
                                                    <td>{uniqueNo.TransactionNo}</td>
                                                    <td>{uniqueNo.TransactionType}</td>
                                                    <td><Link to={{pathname:"/home/reports/uniquenoledger", state:{Product : uniqueNo}}} 
                                                    type="button" className="btn btn-md btn-default btn-flat">
                                                        <i className="fa fa-info"></i></Link></td>
                                                </tr>)}
                                            </tbody>
                                        </table>}
                                        {this.state.totalItemsCount > 0 && <div className="pull-left" style={{ marginTop: "25px" }}>
                                            {this.state.paginationStatus}
                                        </div>}
                                        <div className="pull-right">
                                            <Pagination
                                                activePage={this.state.activePage} //this.state.activePage
                                                itemsCountPerPage={this.state.itemsCountPerPage}
                                                totalItemsCount={this.state.totalItemsCount}
                                                pageRangeDisplayed={2}
                                                onChange={this.handlePageChange}
                                            />
                                        </div>
                                    </div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default withRouter(UniqueNoReport)