import React from 'react'
import * as actions from '../../actionTypes'

class AddEditStoreLocation extends React.Component {
    constructor() {
        super()
        this.state = {
            locationCode: '',
            locationName: '',
            locationCodeError: false,
            locationNameError: false,
            storeID: '',
            action: '',
            key: '',
            saving: false
        }
        this.onSaveStoreLocation = this.onSaveStoreLocation.bind(this)
    }

    componentDidMount() {
        this.setState({
            storeID: this.props.storeID,
            action: this.props.action
        })
        console.log('storeID', this.props.storeID)
        if (this.props.storeLocation) {
            this.setState({
                key: this.props.storeLocation.key,
                locationCode: this.props.storeLocation.LocationCode,
                locationName: this.props.storeLocation.LocationName
            })
        }
    }

    onSaveStoreLocation() {
        this.setState({ saving: true })
        //check if location code and location name is entered 
        let locationCodeError = false
        let locationNameError = false
        if (this.state.locationCode.trim() === "") { locationCodeError = true }
        if (this.state.locationName.trim() === "") { locationNameError = true }
        this.setState({
            locationCodeError: locationCodeError,
            locationNameError: locationNameError, saving: false
        })
        if (locationCodeError || locationNameError) { return }
        //check location code is unique
        let location = {
            LocationCode: this.state.locationCode,
            LocationName: this.state.locationName,
            StoreID: this.state.storeID,
            RegistrationID: this.props.user.user.RegistrationID,
            IsDelete: false
        }

        if (this.state.action === actions.STORELOCATION_NEW) {
            this.props.addStoreLocation(location).then(() => {
                this.props.getToastr("Store Location Saved Successfully")
                this.setState({ saving: false })
                this.props.onSave()
            })
        }
        if (this.state.action === actions.STORELOCATION_EDIT) {
            this.props.updateStoreLocation(location, this.state.key).then(() => {
                this.props.getToastr("Store Location Saved Successfully")
                this.setState({ saving: false })
                this.props.onSave()
            })
        }
    }

    render() {
        return <section className="content" >
            <section className="content-header">
                <h1> Add New Location</h1>
            </section>
            <div className="box-body">
                <div className={this.state.locationCodeError ? "form-group has-error" : "form-group "} >
                    <label>Location Code</label>
                    <input type="text" className="form-control"
                        onChange={(evt) => this.setState({ locationCode: evt.target.value, locationCodeError: "" })}
                        value={this.state.locationCode} />
                    {this.state.locationCodeError && <span class="help-block">Please provide location code</span>}
                </div>
                <div className={this.state.locationNameError ? "form-group has-error" : "form-group "} >
                    <label>Location Name</label>
                    <input type="text" className="form-control"
                        onChange={(evt) => this.setState({ locationName: evt.target.value, locationNameError: "" })}
                        value={this.state.locationName} />
                    {this.state.locationNameError && <span class="help-block">Please provide location name</span>}
                </div>
            </div>
            <div className="box-footer">
                <button type="submit" className="btn btn-md btn-flat btn-default" onClick={this.props.onClose} >Cancel</button>
                <button type="submit" className="btn btn-flat btn-md btn-flat btn-success pull-right" onClick={this.onSaveStoreLocation} disabled={this.state.saving}>Save</button>
            </div>
        </section>
    }
}

export default AddEditStoreLocation