import React, {Component} from 'react'

class UniqueNoInput extends Component{
    constructor () {
        super()
        this.state={
           qty:1,
           uniqueNoList:[],
           error:'',
           processing:false    
        }
       this.generateUniqueList = this.generateUniqueList.bind(this)
       this.onUniqueNo1TextChange = this.onUniqueNo1TextChange.bind(this)
       this.onUniqueNo2TextChange = this.onUniqueNo2TextChange.bind(this)
       this.onUniqueNo3TextChange = this.onUniqueNo3TextChange.bind(this)
       this.onUniqueNo4TextChange = this.onUniqueNo4TextChange.bind(this)
       this.onRemoveLineItem = this.onRemoveLineItem.bind(this)
       this.onOk = this.onOk.bind(this)
    }

    async componentDidMount(){ 
        let uniqueNoList= []
        let qty = 1
        // console.log('this.props.mode',this.props.mode)
        if(this.props.lineItem)
        {
            qty = Number(this.props.lineItem.Qty)
            uniqueNoList = this.props.lineItem.UniqueNoList
            if(this.props.mode==="remove")
            {
                uniqueNoList.map(m=>m.AllowEdit= false)
            }
            else if(this.props.mode==="add" ){
                uniqueNoList.map(m=>m.AllowEdit= true)
            }
            else if(this.props.mode==="view"){
                uniqueNoList.map(m=>m.AllowEdit= false)
            }
            else if(this.props.mode==="edit")
            {
                //check if unique nos are already used 
                //if used then lock them 
                for(let i=0;i<uniqueNoList.length;i++)
                {
                    let uniqueNo = null
                    uniqueNo = await this.props.getUniqueNoIDBasedOnTransaction(this.props.user.store.key, 
                    this.props.product.key,
                    uniqueNoList[i].UniqueNo1,uniqueNoList[i].UniqueNo2,uniqueNoList[i].UniqueNo3,uniqueNoList[i].UniqueNo4)  
                    // console.log('this.props.transactionID.trim()',this.props.transactionID.trim())
                    // console.log('uniqueNo',uniqueNo)
                    if(uniqueNo)
                    {
                        // alert('Unique no already used. Cannot delete line item.')
                        // return 
                        if(uniqueNo.TransactionID === this.props.transactionID)
                        {
                            uniqueNoList[i].key= uniqueNo.key 
                            uniqueNoList[i].AllowEdit= true
                        }
                        else
                        {
                            uniqueNoList[i].AllowEdit= false
                        }
                        
                    }
                    else
                    {
                        uniqueNoList[i].key= ""
                        uniqueNoList[i].AllowEdit= true
                    }
                }
            }
        }  
        this.setState({ qty: qty, uniqueNoList:this.generateUniqueList(qty, uniqueNoList)})
     }

     onUniqueNo1TextChange(value, index){
        let uniqueNoList = this.state.uniqueNoList
        uniqueNoList[index].UniqueNo1 = value
        uniqueNoList[index].Error = ""
        this.setState({uniqueNoList:uniqueNoList})
     }

     onUniqueNo2TextChange(value, index){
        let uniqueNoList = this.state.uniqueNoList
        uniqueNoList[index].UniqueNo2 = value
        uniqueNoList[index].Error = ""
        this.setState({uniqueNoList:uniqueNoList})
     }

     onUniqueNo3TextChange(value, index){
        let uniqueNoList = this.state.uniqueNoList
        uniqueNoList[index].UniqueNo3 = value
        uniqueNoList[index].Error = ""
        this.setState({uniqueNoList:uniqueNoList})
     }

     onUniqueNo4TextChange(value, index){
        let uniqueNoList = this.state.uniqueNoList
        uniqueNoList[index].UniqueNo4 = value
        uniqueNoList[index].Error = ""
        this.setState({uniqueNoList:uniqueNoList})
     }

     onRemoveLineItem(index)
     {
        let uniqueNoList = this.state.uniqueNoList
        uniqueNoList.splice(index,1)
        this.setState({uniqueNoList:uniqueNoList, qty:uniqueNoList.length})
     }

     async onOk()
     {
        this.setState({processing:true})
        let uniqueNoList = this.state.uniqueNoList
        let error = ""
        let blankUniqueNo = false
        let duplicateUniqueNo = false
        let duplicateDBUniqueNo = false

        if(uniqueNoList.length===0)
        {
            error = "Please provide " + this.props.product.hasOwnProperty('UniqueLabel') &&  this.props.product.UniqueLabel.trim()!=="" ? this.props.product.UniqueLabel.trim() : "Unique No"
        }
        uniqueNoList.map((m)=>{
            if(m.UniqueNo1.trim()==="" &&
            m.UniqueNo2.trim()==="" && 
            m.UniqueNo3.trim()==="" &&
            m.UniqueNo4.trim()==="")
            {
                blankUniqueNo = true 
                m.Error = "Please provide value" 
            }
        })
        //check duplicate no in the same row 
        uniqueNoList.map((m,index)=>{
            if(m.UniqueNo1.trim() !==""  && 
            (m.UniqueNo1.trim()=== m.UniqueNo2.trim()
            || m.UniqueNo1.trim()=== m.UniqueNo3.trim()
            || m.UniqueNo1.trim()=== m.UniqueNo4.trim()))
            {
                duplicateUniqueNo= true 
                m.Error =  this.props.product.hasOwnProperty('UniqueLabel') &&  this.props.product.UniqueLabel.trim()!=="" ? "Duplicate " + this.props.product.UniqueLabel.trim()+  " - " + m.UniqueNo1.trim()  : "Duplicate Unique No - " + m.UniqueNo1.trim()
            }
            if(m.UniqueNo2.trim() !==""  && 
            (m.UniqueNo2.trim()=== m.UniqueNo1.trim()
            || m.UniqueNo2.trim()=== m.UniqueNo3.trim()
            || m.UniqueNo2.trim()=== m.UniqueNo4.trim()))
            {
                duplicateUniqueNo= true 
                m.Error =  this.props.product.hasOwnProperty('UniqueLabel') &&  this.props.product.UniqueLabel.trim()!=="" ? "Duplicate " + this.props.product.UniqueLabel.trim() +  " - " + m.UniqueNo2.trim() : "Duplicate Unique No - " + m.UniqueNo2.trim()
            }
            if(m.UniqueNo3.trim() !==""  && 
            (m.UniqueNo3.trim()=== m.UniqueNo1.trim()
            || m.UniqueNo3.trim()=== m.UniqueNo2.trim()
            || m.UniqueNo3.trim()=== m.UniqueNo4.trim()))
            {
                duplicateUniqueNo= true 
                m.Error =  this.props.product.hasOwnProperty('UniqueLabel') &&  this.props.product.UniqueLabel.trim()!=="" ? "Duplicate " + this.props.product.UniqueLabel.trim() +  " - " + m.UniqueNo3.trim() : "Duplicate Unique No - " + m.UniqueNo3.trim()
            }
            if(m.UniqueNo4.trim() !==""  && 
            (m.UniqueNo4.trim()=== m.UniqueNo1.trim()
            || m.UniqueNo4.trim()=== m.UniqueNo2.trim()
            || m.UniqueNo4.trim()=== m.UniqueNo3.trim()))
            {
                duplicateUniqueNo= true 
                m.Error =  this.props.product.hasOwnProperty('UniqueLabel') &&  this.props.product.UniqueLabel.trim()!=="" ? "Duplicate " + this.props.product.UniqueLabel.trim() +  " - " + m.UniqueNo4.trim() : "Duplicate Unique No - " + m.UniqueNo4.trim()
            }
            uniqueNoList.map((n,nindex)=>{
                if(index!==nindex)
                {//
                    if(m.UniqueNo1.trim() !==""  && 
                    (m.UniqueNo1.trim()=== n.UniqueNo1.trim()
                    || m.UniqueNo1.trim()=== n.UniqueNo2.trim()
                    || m.UniqueNo1.trim()=== n.UniqueNo3.trim()
                    || m.UniqueNo1.trim()=== n.UniqueNo4.trim()))
                    {
                        duplicateUniqueNo= true 
                        m.Error =  this.props.product.hasOwnProperty('UniqueLabel') &&  this.props.product.UniqueLabel.trim()!=="" ? "Duplicate " + this.props.product.UniqueLabel.trim() +  " - " + m.UniqueNo1.trim() : "Duplicate Unique No - " + m.UniqueNo1.trim()
                    }
                    if(m.UniqueNo2.trim() !==""  && 
                    (m.UniqueNo2.trim()=== n.UniqueNo1.trim()
                    || m.UniqueNo2.trim()=== n.UniqueNo2.trim()
                    || m.UniqueNo2.trim()=== n.UniqueNo3.trim()
                    || m.UniqueNo2.trim()=== n.UniqueNo4.trim()))
                    {
                        duplicateUniqueNo= true 
                        m.Error =  this.props.product.hasOwnProperty('UniqueLabel') &&  this.props.product.UniqueLabel.trim()!=="" ? "Duplicate " + this.props.product.UniqueLabel.trim() +  " - " + m.UniqueNo2.trim() : "Duplicate Unique No - " + m.UniqueNo2.trim()
                    }
                    if(m.UniqueNo3.trim() !==""  && 
                    (m.UniqueNo3.trim()=== n.UniqueNo1.trim()
                    || m.UniqueNo3.trim()=== n.UniqueNo2.trim()
                    || m.UniqueNo3.trim()=== n.UniqueNo3.trim()
                    || m.UniqueNo3.trim()=== n.UniqueNo4.trim()))
                    {
                        duplicateUniqueNo= true 
                        m.Error =  this.props.product.hasOwnProperty('UniqueLabel') &&  this.props.product.UniqueLabel.trim()!=="" ? "Duplicate " + this.props.product.UniqueLabel.trim() +  " - " + m.UniqueNo3.trim() : "Duplicate Unique No - " + m.UniqueNo3.trim()
                    }
                    if(m.UniqueNo4.trim() !==""  && 
                    (m.UniqueNo4.trim()=== n.UniqueNo1.trim()
                    || m.UniqueNo4.trim()=== n.UniqueNo2.trim()
                    || m.UniqueNo4.trim()=== n.UniqueNo3.trim()
                    || m.UniqueNo4.trim()=== n.UniqueNo4.trim()))
                    {
                        duplicateUniqueNo= true 
                        m.Error = this.props.product.hasOwnProperty('UniqueLabel') &&  this.props.product.UniqueLabel.trim()!=="" ? "Duplicate " + this.props.product.UniqueLabel.trim() +  " - " + m.UniqueNo4.trim() : "Duplicate Unique No - " + m.UniqueNo4.trim()
                    }
                }
            })
        })
        // uniqueNoList.map(async (m,index)=>{
        for(let i=0;i<uniqueNoList.length;i++)
        {
            let m=uniqueNoList[i]
            if(m.UniqueNo1.trim()!=="" && m.AllowEdit)
            {
                let uniqueNo = await this.props.checkIfUniqueNoIsUnique(this.props.user.store.key, 
                this.props.product.key,
                m.UniqueNo1)
                // console.log('m',m)
                if(uniqueNo && uniqueNo.key !== m.key )
                {
                    duplicateDBUniqueNo= true 
                    m.Error = m.UniqueNo1 +  " already exists."
                }

            }
            if(m.UniqueNo2.trim()!=="" && m.AllowEdit)
            {
                let uniqueNo = await this.props.checkIfUniqueNoIsUnique(this.props.user.store.key, 
                    this.props.product.key,
                    m.UniqueNo2)
                    if(uniqueNo && uniqueNo.key !== m.key )
                    {
                        duplicateDBUniqueNo= true 
                        m.Error = m.UniqueNo2 +  " already exists."
                    }
            }
            if(m.UniqueNo3.trim()!=="" && m.AllowEdit)
            {
                let uniqueNo = await this.props.checkIfUniqueNoIsUnique(this.props.user.store.key, 
                    this.props.product.key,
                    m.UniqueNo3)
                    if(uniqueNo && uniqueNo.key !== m.key )
                    { 
                        duplicateDBUniqueNo= true 
                        m.Error = m.UniqueNo3 +  " already exists."
                    }
            }
            if(m.UniqueNo4.trim()!=="" && m.AllowEdit)
            {
                let uniqueNo = await this.props.checkIfUniqueNoIsUnique(this.props.user.store.key, 
                    this.props.product.key,
                    m.UniqueNo4)
                    if(uniqueNo && uniqueNo.key !== m.key )
                    {
                        duplicateDBUniqueNo= true 
                        m.Error = m.UniqueNo4 +  " already exists."
                    }
            }
        }
        // console.log('duplicateDBUniqueNo',duplicateDBUniqueNo)
        if(error.trim()!=="" || blankUniqueNo || duplicateUniqueNo || duplicateDBUniqueNo)
        {
            this.setState({error:error,uniqueNoList:uniqueNoList,processing:false })
            return 
        }
        uniqueNoList.map(m=>{
            m.Error =""
        })
        this.props.onOK(uniqueNoList)
        this.setState({processing:false})
     }

     generateUniqueList(qty, uniqueNoList)
     {
         let newUniqueNoList = []
        for(let i=0; i<qty;i++)
        {
            if(uniqueNoList.length>i)
            {
                uniqueNoList[i].Error=''
                newUniqueNoList.push(uniqueNoList[i])
            }
            else
            {
                newUniqueNoList.push({
                    UniqueNo1:'',
                    UniqueNo2:'',
                    UniqueNo3:'',
                    UniqueNo4:'',
                    AllowEdit:true,
                    Error:'',
                    key:''
                })
            }
        }
        return newUniqueNoList
    }

    render(){
        return <React.Fragment>
         <div style={{ width: "700px" }}>
                    <h3 style={{ marginTop: "0px" }}>{this.props.product.hasOwnProperty('UniqueLabel') &&  this.props.product.UniqueLabel.trim()!=="" ? this.props.product.UniqueLabel.trim() : "Unique No"}</h3>
                    <div className="box-body">
                        {(this.props.mode==="add" || this.props.mode==="edit") && <div className="row">
                            <div className="col-md-4">
                            <table>
                                <tbody>
                                <tr>
                                    <td style={{padding:"5px"}}>
                                        Qty
                                    </td>
                                    <td style={{padding:"5px"}}>
                                        <input autoFocus type="number" 
                                        className="form-control input-md"
                                        value={this.state.qty}
                                        onChange = {(evt)=> this.setState({qty:Number(evt.target.value)})}/>  
                                    </td>
                                    <td style={{padding:"5px"}}>
                                        <button type="submit" className="btn btn-flat btn-md btn-default" 
                                        onClick={()=>{
                                            let nonEditIndex = 0
                                            this.state.uniqueNoList.map((m,index)=>{
                                                if(m.hasOwnProperty('AllowEdit') && !m.AllowEdit){
                                                    nonEditIndex= nonEditIndex
                                                }
                                            })
                                            if((nonEditIndex+1)>Number(this.state.qty))
                                            {
                                                alert('Can not remove unique no(s)')
                                                return
                                            }
                                            let uniqueNoList = this.generateUniqueList(Number(this.state.qty), this.state.uniqueNoList)
                                            this.setState({uniqueNoList:uniqueNoList})
                                            }}>OK</button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            </div>
                        </div>}
                        <table className = "table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{this.props.product.hasOwnProperty('UniqueLabel') &&  this.props.product.UniqueLabel.trim()!=="" ? this.props.product.UniqueLabel.trim() + " 1": "Unique No 1"}</th>
                                    <th>{this.props.product.hasOwnProperty('UniqueLabel') &&  this.props.product.UniqueLabel.trim()!=="" ? this.props.product.UniqueLabel.trim() + " 2": "Unique No 2"}</th>
                                    <th>{this.props.product.hasOwnProperty('UniqueLabel') &&  this.props.product.UniqueLabel.trim()!=="" ? this.props.product.UniqueLabel.trim() + " 3": "Unique No 3"}</th>
                                    <th>{this.props.product.hasOwnProperty('UniqueLabel') &&  this.props.product.UniqueLabel.trim()!=="" ? this.props.product.UniqueLabel.trim() + " 4": "Unique No 4"}</th>
                                    <th style={{width:"50px"}}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.uniqueNoList.map((m,index) =><tr key={index}>
                                    <td>{index +1 }</td>
                                    <td>
                                    <div className={m.Error !== "" ? "form-group has-error" : "form-group "} > 
                                        <input type="text" 
                                        className="form-control input-md"
                                        disabled={(m.hasOwnProperty('AllowEdit') && m.AllowEdit)? false : true}
                                        value={m.UniqueNo1}
                                        onChange = {(evt)=> {this.onUniqueNo1TextChange(evt.target.value, index)}}/>   
                                        {m.Error !== "" && <span class="help-block">{m.Error}</span>}
                                    </div>
                                    </td>
                                    <td> <input type="text" 
                                        className="form-control input-md"
                                        disabled={(m.hasOwnProperty('AllowEdit') && m.AllowEdit)? false : true}
                                        value={m.UniqueNo2}
                                        onChange = {(evt)=> {this.onUniqueNo2TextChange(evt.target.value, index)}}/>   
                                    </td>
                                    <td> <input type="text" 
                                        className="form-control input-md"
                                        disabled={(m.hasOwnProperty('AllowEdit') && m.AllowEdit)? false : true}
                                        value={m.UniqueNo3}
                                        onChange = {(evt)=> {this.onUniqueNo3TextChange(evt.target.value, index)}}/>   
                                    </td>
                                    <td> 
                                        <input type="text" 
                                        className="form-control input-md"
                                        disabled={(m.hasOwnProperty('AllowEdit') && m.AllowEdit)? false : true}
                                        value={m.UniqueNo4}
                                        onChange = {(evt)=> {this.onUniqueNo4TextChange(evt.target.value, index)}}/>   
                                    </td>
                                    <td>
                                        {this.props.mode !=="view" && <div className="input-group-btn">
                                            <button type="button" className="btn btn-default btn-md" 
                                            onClick={() => this.onRemoveLineItem(index)}>
                                            <i className="glyphicon glyphicon-remove"></i>
                                            </button>
                                        </div>}
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>        
                    </div>
                    <div className="box-footer">
                        <button type="submit" className="btn btn-flat btn-md btn-default" 
                        onClick={this.props.onClose}>Cancel</button>
                        <button type="submit" className="btn btn-flat btn-md btn-success pull-right" 
                        disabled={this.state.processing}
                        onClick={this.onOk}>OK</button>
                    </div>
                </div>
                </React.Fragment>
    }
}

export default UniqueNoInput