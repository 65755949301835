import {storageRef} from '../firebase'
import * as actionTypes from '../actionTypes'


export function uploadFileToServer(fileTobeUploaded, storageFolder, action)
{
    return (dispatch) => {
        const fileNameAndPath = storageFolder + '/' + fileTobeUploaded.name
        return storageRef.child(fileNameAndPath).put(fileTobeUploaded).then(function(snapshot) {
            console.log('uploadFileToServer - Uploaded a blob or file!', snapshot);
            console.log('ref - fullPath path', snapshot.ref.fullPath)
            return snapshot.ref.getDownloadURL()
        });
    }
}


// export function uploadFileToServer1(fileTobeUploaded, storageFolder, action)
// {
//     return (dispatch) => {
//         const fileNameAndPath = storageFolder + '/' + fileTobeUploaded.name
//         return storageRef.child(fileNameAndPath).put(fileTobeUploaded).then(function(snapshot) {
//             console.log('uploadFileToServer - Uploaded a blob or file!');
//             return snapshot.ref.getDownloadURL()
//         });
//     }
// }


// export function uploadFileToServer(fileTobeUploaded, storageFolder, action)
// {
//     return (dispatch) => {
//         const fileNameAndPath = storageFolder + '/' + fileTobeUploaded.name
//         return storageRef.child(fileNameAndPath).put(fileTobeUploaded).then(function(snapshot) {
//             console.log('uploadFileToServer - Uploaded a blob or file!');
//             return snapshot.ref.getDownloadURL().then(function(downloadURL) {
//                 console.log('File available at', downloadURL);
//                 dispatch({type: action,downloadURL})
//                 });
//         });
//     }
// }