import React, { Component } from 'react'
import CreatableSelect from 'react-select'
import TimePicker from '../TimePicker'
import { isNullOrUndefined } from 'util';

class AddAttendance extends Component {

    constructor() {
        super()
        this.state = {
            key:'',
            employees: [],
            employee: null,
            inTime: {HH:'', MM:'', AMPM:''},
            outTime: {HH:'', MM:'', AMPM:''},
            inTimeDate: null,
            outTimeDate: null,
            attendanceType: '',
            remarks: '',
            attendanceDate: new Date().toISOString().split('T')[0],
            saving: false,
            employeeError: false,
            attendanceDateError: false,
            attendanceTypeError: false,
            inTimeError: false,
            outTimeError:false
        }
        this.onCreateAttendance = this.onCreateAttendance.bind(this);
        this.loadEmployees = this.loadEmployees.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this)
    }

    componentDidMount() {
        this.loadEmployees();

        if(this.props.attendance)
        {
            this.setState(
                {
                    key: this.props.attendance.key,
                    employee:this.props.attendance.Employee,
                    attendanceDate: this.props.attendance.Date,
                    attendanceType:this.props.attendance.AttendanceType,
                    inTime:this.props.attendance.InTime,
                    outTime:this.props.attendance.OutTime,
                    remarks:this.props.attendance.Remarks
                }
            )
        }
    }

    loadEmployees() {
        this.props.getEmployees(this.props.user.user.RegistrationID, this.props.user.store.key, 0, 10000, 'active', '',
            '', '', '', '', '', '', '', '', '', '').then((result) => {
                let employees = [];
                result.searchResult.map((emp) => {
                    let obj = {
                        value: emp.key
                    }
                    let name = (emp.EmployeeFirstName.trim() + ' ' + (emp.EmployeeLastName ? emp.EmployeeLastName.trim() : ""))
                    name = name.trim()
                    obj.label = name;
                    employees.push(obj);
                });
                this.setState({ employees: employees })
            })
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onCreateAttendance()
        }
    }

    handleOnChange(employee) {
        this.setState({ employee: employee });
    };

    onCreateAttendance() {

        this.setState({ saving: true })

        let employeeError = false
        let attendanceDateError = false
        let attendanceTypeError = false
        let inTimeError = false
        let outTimeError = false

        if (isNullOrUndefined(this.state.employee)) { employeeError = true }
        if (this.state.attendanceType.trim() === '') { attendanceTypeError = true }
        if (this.state.attendanceDate.trim() === '') { attendanceDateError = true }

        if(this.state.attendanceType.trim() != '' && this.state.attendanceType.trim() != "Leave" && this.state.attendanceType.trim() != "Week-Off")
        {
            if(this.state.inTime.HH.trim()==='' || this.state.inTime.MM==='' || this.state.inTime.AMPM==='')
            {
                inTimeError = true
            }
        }

        if (employeeError || attendanceDateError || attendanceTypeError || inTimeError || outTimeError) {
            this.setState({
                employeeError: employeeError,
                attendanceDateError: attendanceDateError,
                attendanceTypeError: attendanceTypeError,
                inTimeError: inTimeError,
                outTimeError:outTimeError,
                saving: false
            })
            return;
        }

        let attendance = {
            RegistrationID: this.props.user.user.RegistrationID,
            StoreID: this.props.user.store.key,
            Employee : this.state.employee,
            Date: this.state.attendanceDate,
            AttendanceType: this.state.attendanceType,
            InTime: this.state.inTime,
            OutTime: this.state.outTime,
            Remarks: this.state.remarks
        }

        this.props.saveAttendance(attendance,this.state.key).then((key) => {
            this.props.getToastr("Attendance Saved Successfully");

            setTimeout(function () { 
                this.setState({saving:false})
                this.props.onSave()
            }.bind(this), 2000)

        })
    }

    render() {
        return <div>
            <h3 style={{ marginTop: "0px" }}>Add Attendance</h3>
            <div className="box-body">
                <div className="row">
                    <div className="col-md-4">
                        <div className={this.state.employeeError ? "form-group has-error" : "form-group "} >
                            <div className="form-group" >
                                <label>Employee</label>
                                <CreatableSelect
                                    options={this.state.employees}
                                    value={this.state.employee}
                                    onChange={this.handleOnChange}
                                    isMulti={false}
                                    onKeyPress={this.handleKeyPress}
                                />
                                {this.state.employeeError && <span class="help-block">Please select employee</span>}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className={this.state.attendanceDateError ? "form-group has-error" : "form-group "} >
                            <div className="form-group">
                                <label>Date</label>
                                <input type="date" className="form-control"
                                    onChange={(evt) => this.setState({ attendanceDate: evt.target.value, attendanceDateError: '' })}
                                    value={this.state.attendanceDate}
                                    onKeyPress={this.handleKeyPress} />
                                {this.state.attendanceDateError && <span class="help-block">Please select Date</span>}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className={this.state.attendanceTypeError ? "form-group has-error" : "form-group "} >
                            <div className="form-group" >
                                <label >Status</label>
                                <select className="form-control"
                                    onChange={(evt) => this.setState({ attendanceType: evt.target.value, attendanceTypeError: false, inTime:{HH:'', MM:'', AMPM:''}, outTime:{HH:'', MM:'', AMPM:''} })}
                                    value={this.state.attendanceType}>
                                    <option>Select</option>
                                    <option>Present</option>
                                    <option>Half Day</option>
                                    <option>Leave</option>
                                    <option>Week-Off</option>
                                </select>
                                {this.state.attendanceTypeError && <span class="help-block">Please select Status</span>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                {this.state.attendanceType != "Leave" && <React.Fragment>
                    <div className="col-md-4">
                        <div className={this.state.inTimeError ? "form-group has-error" : "form-group "} >
                            <label >In Time</label>
                            <TimePicker
                                time={this.state.inTime}
                                onChange={(time) => this.setState({ inTime: time, inTimeError:'' })} />
                            {this.state.inTimeError && <span class="help-block">Please select In Time</span>}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className={this.state.outTimeError ? "form-group has-error" : "form-group "} >
                            <label >Out Time</label>
                            <TimePicker
                                time={this.state.outTime}
                                onChange={(time) => this.setState({ outTime: time, outTimeError:'' })} />
                            {this.state.outTimeError && <span class="help-block">Please select Out Time</span>}
                        </div>
                    </div></React.Fragment>}
                    <div className="col-md-4">
                        <div className="form-group">
                            <label >Remarks</label>
                            <input type="text" className="form-control" value={this.state.remarks}
                                onChange={(evt) => this.setState({ remarks: evt.target.value })} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="box-footer">
                <button onClick={() => this.props.onClose()} className="btn btn-md btn-default btn-flat">Cancel</button>
                <button type="submit" className="btn btn-md btn-success pull-right btn-flat" onClick={this.onCreateAttendance} disabled={this.state.saving}>Save</button>
            </div>
        </div>
    }
}
export default AddAttendance