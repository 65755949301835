import React, { Component } from 'react';
import * as actionTypes from '../../../actionTypes'
import { withRouter } from 'react-router-dom'

class AddEditVendor extends Component {

    constructor() {
        super()
        this.state = {
            vendorCode: '',
            vendorFirstName: '',
            vendorLastName: '',
            gender: '',
            emailID: '',
            phoneNo: '',
            storeID: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
            customField1: '',
            customField2: '',
            customField3: '',
            customField4: '',
            customField5: '',
            customField6: '',
            vendorCodeError: false,
            vendorFirstNameError: false,
            VendorCodeError: '',
            action: '',
            key: '',
            customField1DisplayName: 'Custom Field 1',
            customField2DisplayName: 'Custom Field 2',
            customField3DisplayName: 'Custom Field 3',
            customField4DisplayName: 'Custom Field 4',
            customField5DisplayName: 'Custom Field 5',
            customField6DisplayName: 'Custom Field 6',
            saving: false
        }
        this.onSaveVendor = this.onSaveVendor.bind(this)
        this.onCancelClick = this.onCancelClick.bind(this)
    }

    componentDidMount() {
        this.props.config.CustomFields.map((customField) => {
            if (customField.ModuleName === 'Vendor') {
                let customField1DisplayName = customField.CustomField1Name.trim() === '' ? 'Custom Field 1' : customField.CustomField1Name.trim()
                let customField2DisplayName = customField.CustomField2Name.trim() === '' ? 'Custom Field 2' : customField.CustomField2Name.trim()
                let customField3DisplayName = customField.CustomField3Name.trim() === '' ? 'Custom Field 3' : customField.CustomField3Name.trim()
                let customField4DisplayName = customField.CustomField4Name.trim() === '' ? 'Custom Field 4' : customField.CustomField4Name.trim()
                let customField5DisplayName = customField.CustomField5Name.trim() === '' ? 'Custom Field 5' : customField.CustomField5Name.trim()
                let customField6DisplayName = customField.CustomField6Name.trim() === '' ? 'Custom Field 6' : customField.CustomField6Name.trim()
                this.setState({
                    customField1DisplayName: customField1DisplayName,
                    customField2DisplayName: customField2DisplayName,
                    customField3DisplayName: customField3DisplayName,
                    customField4DisplayName: customField4DisplayName,
                    customField5DisplayName: customField5DisplayName,
                    customField6DisplayName: customField6DisplayName
                })
            }
        })
        if (this.props.location.state.action === actionTypes.VENDOR_NEW) {
            this.setState({ action: this.props.location.state.action })
        }
        if (this.props.location.state.action === actionTypes.VENDOR_EDIT) {

            let vendor = this.props.location.state.vendor
            this.setState({
                action: this.props.location.state.action,
                key: this.props.location.state.vendor.key,
                vendorCode: vendor.VendorCode,
                vendorFirstName: vendor.VendorFirstName,
                vendorLastName: vendor.VendorLastName,                
                emailID: vendor.EmailID,
                phoneNo: vendor.PhoneNo,
                storeID: vendor.StoreID,
                address1: vendor.Address1,
                address2: vendor.Address2,
                city: vendor.City,
                state: vendor.State,
                postalCode: vendor.PostalCode,
                country: vendor.Country,
                customField1: vendor.CustomField1,
                customField2: vendor.CustomField2,
                customField3: vendor.CustomField3,
                customField4: vendor.CustomField4,
                customField5: vendor.CustomField5,
                customField6: vendor.CustomField6
            })
        }        
    }

    onSaveVendor() {

        let vendorCodeError = false
        let vendorFirstNameError = false
        this.setState({ saving: true })

        if (this.state.vendorCode.trim() === '') { vendorCodeError = true }
        if (this.state.vendorFirstName.trim() === '') { vendorFirstNameError = true }

        if (vendorCodeError || vendorFirstNameError) {
            this.setState({ vendorCodeError: vendorCodeError, vendorFirstNameError: vendorFirstNameError, saving: false })
            return
        }

        // let VendorCodeError = ''
        // if(this.state.vendor.trim()=== ""){
        //      VendorCodeError = 'Please provide Expense Category'
        //      this.setState({VendorCodeError: VendorCodeError})
        //      return 
        // }
        // this.props.checkIfVendorIsUnique(this.state.vendor,this.props.user.user.RegistrationID).then((isUnique)=>{
        //     if(!isUnique){
        //         VendorCodeError = 'Expense Category is already exists'
        //         this.setState({VendorCodeError: VendorCodeError})
        //         return 
        //     }
        //     else
        //     {
        //         let vendor = {vendor: this.state.vendor, 
        //             RegistrationID: this.props.user.user.RegistrationID,
        //             IsDeleted:false}
        //         if(this.state.key ==="")
        //         {
        //             vendor = {...vendor}
        //         }
        //         this.props.addNewVendor(vendor,this.state.key).then(
        //             (key)=> this.props.onSaveVendor(key)) 
        //     }
        // })
        // if(VendorCodeError !== ""){
        //     this.setState({VendorCodeError: VendorCodeError})
        //     return 
        // }

        let vendor = {
            VendorCode: this.state.vendorCode,
            VendorFirstName: this.state.vendorFirstName,
            VendorLastName: this.state.vendorLastName,
            Gender: this.state.gender,
            EmailID: this.state.emailID,
            PhoneNo: this.state.phoneNo,
            Address1: this.state.address1,
            Address2: this.state.address2,
            City: this.state.city,
            State: this.state.state,
            PostalCode: this.state.postalCode,
            Country: this.state.country,
            CustomField1: this.state.customField1,
            CustomField2: this.state.customField2,
            CustomField3: this.state.customField3,
            CustomField4: this.state.customField4,
            CustomField5: this.state.customField5,
            CustomField6: this.state.customField6,
            StoreID:this.props.user.store.key,
            RegistrationID: this.props.user.user.RegistrationID,
            IsDeleted: false
        }
        // console.log(vendor)
        if (this.state.action === actionTypes.VENDOR_NEW) {
            this.props.addNewVendor(vendor).then((key) => {                
                //redirect to store view page
                setTimeout(function () { //Start the timer
                    this.setState({ saving: false })
                    this.props.getToastr("Vendor saved successfully");
                    this.props.history.push({
                        pathname: "/home/settings/vendors",
                        state: { key: key }
                    })
                }.bind(this), 2000)
            })
        }
        if (this.state.action === actionTypes.VENDOR_EDIT) {
            this.props.updateVendor(vendor, this.state.key).then(() => {

                setTimeout(function () {
                    this.props.getToastr("Vendor saved successfully")
                    this.setState({ saving: false })
                    this.props.history.push({
                        pathname: "/home/settings/vendors",
                        state: { key: this.state.key }
                    })
                }.bind(this), 2000)
            })
        }
    }

    onCancelClick() {
        if (this.state.action === actionTypes.VENDOR_EDIT) {
            this.props.history.push({
                pathname: "/home/settings/vendors",
                state: { key: this.state.key }
            })
        }
        else {
            this.props.history.push("/home/settings/vendors")
        }
    }

    render() {
        return <div className="content-wrapper">
            <section className="content-header">
                <h1>{this.state.action === actionTypes.VENDOR_EDIT ? "Edit Vendor" : "New Vendor"}
                </h1>
                <button type="submit"
                    style={{ marginTop: "-25px", marginLeft: "10px" }}
                    className="btn btn-md btn-success btn-flat pull-right"
                    onClick={this.onSaveVendor} disabled={this.state.saving}>Save
                    </button>
                <button style={{ marginTop: "-25px" }}
                    onClick={this.onCancelClick}
                    className="btn btn-md btn-default btn-flat  pull-right">Cancel
                    </button>
            </section>
            <section className="content" >
                <div className="box ">
                    <div className="box-header with-border">
                        <h3 className="box-title">General details</h3>
                    </div>
                    <div className="box-body">
                        <div className="row">
                            <div className="col-md-3">
                                <div className={this.state.vendorCodeError ? "form-group has-error" : "form-group "} >
                                    <div className="form-group" >
                                        <label>Vendor Code</label>
                                        <input type="text" className="form-control"
                                            onChange={(evt) => this.setState({ vendorCode: evt.target.value, vendorCodeError: false })}
                                            value={this.state.vendorCode}
                                        />
                                        {this.state.vendorCodeError && <span class="help-block">Please provide vendor code</span>}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="box-body">     
                                             <div className="row">
                                            <div className="col-md-3">
                                            <div className={ this.state.vendorCodeError!=="" ? "form-group has-error": "form-group "} >
                                                <div className="form-group" >
                                                    <label>Vendor Code</label>
                                                    <input type="text" className="form-control"  
                                                    onChange={(evt)=> this.setState({vendorCode:evt.target.value, vendorCodeError:false})}
                                                    value={this.state.vendorCode}
                                                    
                                                    />
                                                    {this.state.vendorCodeError!=="" &&  <span class="help-block">{this.state.VendorCodeError}</span> }
                                                </div>
                                            </div>
                                            </div> */}
                            <div className="col-md-3">
                                <div className={this.state.vendorFirstNameError ? "form-group has-error" : "form-group "} >
                                    <div className="form-group">
                                        <label>First Name</label>
                                        <input type="text" className="form-control"
                                            onChange={(evt) => this.setState({ vendorFirstName: evt.target.value })}
                                            value={this.state.vendorFirstName}
                                        />
                                        {this.state.vendorFirstNameError && <span class="help-block">Please provide vendor first name</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group" >
                                    <label >Last Name</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ vendorLastName: evt.target.value })}
                                        value={this.state.vendorLastName}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3">
                                {/* <div className="form-group" >
                                    <label >Gender</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ gender: evt.target.value })}
                                        value={this.state.gender}
                                    />
                                </div> */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <div className="form-group" >
                                    <label >Mobile No</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ phoneNo: evt.target.value })}
                                        value={this.state.phoneNo}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group" >
                                    <label>Email</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ emailID: evt.target.value })}
                                        value={this.state.emailID}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="box ">
                    <div className="box-header with-border">
                        <h3 className="box-title">Address</h3>
                    </div>
                    <div className="box-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group" >
                                    <label >Line Address 1</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ address1: evt.target.value })}
                                        value={this.state.address1}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Line Address 2</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ address2: evt.target.value })}
                                        value={this.state.address2}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>City</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ city: evt.target.value })}
                                        value={this.state.city}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group" >
                                    <label >State</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ state: evt.target.value })}
                                        value={this.state.state}
                                    />
                                </div>

                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group" >
                                    <label >Postal Code</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ postalCode: evt.target.value })}
                                        value={this.state.postalCode}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group" >
                                    <label >Country</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ country: evt.target.value })}
                                        value={this.state.country}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box ">
                    <div className="box-header with-border">
                        <h3 className="box-title">Additonal Information</h3>
                    </div>
                    <div className="box-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group" >
                                    <label >{this.state.customField1DisplayName}</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ customField1: evt.target.value })}
                                        value={this.state.customField1}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>{this.state.customField2DisplayName}</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ customField2: evt.target.value })}
                                        value={this.state.customField2}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group" >
                                    <label >{this.state.customField3DisplayName}</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ customField3: evt.target.value })}
                                        value={this.state.customField3}
                                    />
                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="form-group" >
                                    <label >{this.state.customField4DisplayName}</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ customField4: evt.target.value })}
                                        value={this.state.customField4}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group" >
                                    <label >{this.state.customField5DisplayName}</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ customField5: evt.target.value })}
                                        value={this.state.customField5}
                                    />
                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="form-group" >
                                    <label >{this.state.customField6DisplayName}</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ customField6: evt.target.value })}
                                        value={this.state.customField6}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button type="submit"
                    style={{ marginBottom: "10px", marginLeft: "10px" }}
                    className="btn btn-md btn-success btn-flat pull-right"
                    onClick={this.onSaveVendor} disabled={this.state.saving}>Save
                    </button>
                <button style={{ marginBottom: "10px" }}
                    onClick={this.onCancelClick}
                    className="btn btn-md btn-default btn-flat  pull-right">Cancel
                    </button>
            </section>
        </div>
    }
}

export default withRouter(AddEditVendor)