import React, { Component } from 'react'
var AsyncTypeahead = require('react-bootstrap-typeahead').AsyncTypeahead;


class AddEditModifier extends Component {
    constructor() {
        super()
        this.state = {
            isProductTypeAheadLoading: false,
            saving: false,
            modifierGroup: '',
            modifierGroupID:'',
            modifier: '',
            price: '',
            allProducts: false,
            specificProducts: false,
            associatedProducts: [],
            productsTypeAheadSelected: [],
            modifierGroupError: '',
            modifierError: '',
            priceError: '',
            optionError: '',
            associatedProductsError:'',
            key:''
        }
        this.onSaveModifier = this.onSaveModifier.bind(this)
        this.onProductTypeAheadSearch = this.onProductTypeAheadSearch.bind(this)
        this.onProductSelected = this.onProductSelected.bind(this)

    }

    onProductTypeAheadSearch = (query) => {
        this.setState({ isProductTypeAheadLoading: true });
        this.props.getProducts(this.props.user.user.RegistrationID, 0, 100, 'active', query, "", "", "",
            "", "", "")
            .then((result) => {
                this.setState({ productsTypeAheadOptions: result.searchResult })
                this.setState({ isProductTypeAheadLoading: false });
            })
    }

    onProductSelected(product) {
        
        let selectedProduct = {
            BarCode: product.BarCode,
            Brand:product.Brand,
            BrandID: product.BrandID,
            Category: product.Category,
            CategoryID: product.CategoryID,
            Description: product.Description,
            MRP:product.MRP,
            Name:product.Name,
            ProductType: product.ProductType,
            RetailPrice: product.RetailPrice,
            SKU:product.SKU,
            SubCategory:product.SubCategory,
            SubCategoryID: product.SubCategoryID,
            SupplyPrice: product.SupplyPrice,
            ProductID: product.key
        }
        let associatedProducts = this.state.associatedProducts;
        associatedProducts.push(selectedProduct);
        this.setState({associatedProducts: associatedProducts});
    }

    componentDidMount() {
        if(this.props.modifier)
        {
            this.setState(
                {
                    key: this.props.modifier.key,
                    modifier: this.props.modifier.ModifierName,
                    associatedProducts: this.props.modifier.AssociatedProducts,
                    price: this.props.modifier.ModifierPrice.toString(),
                    modifierGroup: this.props.modifier.ModifierGroup,
                    modifierGroupID: this.props.modifier.ModifierGroupID,
                    allProducts: this.props.modifier.AllProducts,
                    specificProducts: this.props.modifier.SpecificProducts
                }
            )
        }
    }

    onSaveModifier() {
        this.setState({saving:true});

        let modifierError = ''
        let modifierGroupError = ''
        let priceError = ''
        let optionError = ''
        let associatedProductsError = ''

        if (this.state.modifierGroup.trim() === "") {
            modifierGroupError = 'Please select group';
        }
        if (this.state.modifier.trim() === "") {
            modifierError = 'Please enter modifier';
        }
        if (this.state.price.trim() === "") {
            priceError = 'Please enter price';
        }
        if (this.state.allProducts === false && this.state.specificProducts === false) {
            optionError = 'Please select either All or Specific option';
        }
        if(this.state.specificProducts)
        {
            if(this.state.associatedProducts.length === 0)
            {
                associatedProductsError ='Please select atleast one product';
            }
        }

        if(modifierGroupError !=="" || modifierError !=="" 
        || optionError !=="" || associatedProductsError !==""
        || priceError !=="")
        {
            this.setState({modifierGroupError:modifierGroupError,modifierError:modifierError,optionError:optionError,
                associatedProductsError:associatedProductsError,priceError:priceError, saving:false})
            return;
        }

        let modifier = {
            ModifierGroup: this.state.modifierGroup
            , ModifierGroupID: this.state.modifierGroupID
            , ModifierName: this.state.modifier
            , ModifierPrice: Number(this.state.price)
            , IsDeleted: false
            , RegistrationID: this.props.user.user.RegistrationID
            , AllProducts: this.state.allProducts
            , SpecificProducts: this.state.specificProducts
            , AssociatedProducts: this.state.associatedProducts
        }
        this.props.saveModifiers(modifier, this.state.key).then(
            (key) => {
                this.props.getToastr("Modifier Saved Successfully")
                this.setState({ saving: false })
                setTimeout(function () {
                    this.props.onSave();
                }.bind(this), 1000)
            })
    }

    render() {
        return <div>
            <h3 style={{ marginTop: "0px" }}>Add Modifier</h3>
            <div className="box-body">
                <div className="row">
                    <div className="col-md-6">
                        <div className={this.state.modifierGroupError != "" ? "form-group has-error" : "form-group "}>
                            <label>Group</label>
                            <select className="form-control"
                                value={this.state.modifierGroupID}
                                onChange={(evt) => this.setState({ modifierGroup: evt.target.options[evt.target.selectedIndex].text,modifierGroupID: evt.target.value,  modifierGroupError: '' })}>
                                <option value="">Select Group</option>
                                <option value="Group 1">Group 1</option>
                                <option value="Group 2">Group 2</option>
                                <option value="Group 3">Group 3</option>
                                <option value="Group 4">Group 4</option>
                                {/* {this.props.config.ProductBrands.filter((e) => !e.IsDeleted).map((brand, index) =>
                                    <option value={brand.key}>{brand.Brand}</option>
                                )} */}
                            </select>
                            {this.state.modifierGroupError !== "" && <span class="help-block">{this.state.modifierGroupError}</span>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className={this.state.modifierError != "" ? "form-group has-error" : "form-group "}>
                            <label>Modifier</label>
                            <input type="text" className="form-control"
                                placeholder="Enter Modifier"
                                onChange={(evt) => this.setState({ modifier: evt.target.value, modifierError : '' })}
                                value={this.state.modifier} />
                            {this.state.modifierError !== "" && <span class="help-block">{this.state.modifierError}</span>}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className={this.state.priceError != "" ? "form-group col-md-6 has-error" : "form-group col-md-6 "}>
                        <label>Price</label>
                        <input type="text" className="form-control"
                            placeholder="Enter Price"
                            onChange={(evt) => this.setState({ price: evt.target.value, priceError:'' })}
                            value={this.state.price} />
                        {this.state.priceError !== "" && <span class="help-block">{this.state.priceError}</span>}
                    </div>
                    <div className={this.state.optionError !== "" ? "form-group col-md-3 has-error" : "form-group col-md-3"}>
                        <input type="radio" style={{marginTop:"30px"}} name="products" value={this.state.allProducts} checked={this.state.allProducts}
                            onChange={() => { this.setState({ specificProducts: false, allProducts: true, associatedProducts: [], optionError: '', associatedProductsError:'' }) }} />
                        <label><strong> All Products</strong></label>
                        {this.state.optionError !== "" && <span className="help-block">{this.state.optionError}</span>}
                    </div>
                    <div className={this.state.optionError !== "" ? "form-group col-md-3 has-error" : "form-group col-md-3"}>
                        <input type="radio" style={{marginTop:"30px"}} name="products" value={this.state.specificProducts} checked={this.state.specificProducts}
                            onChange={() => { this.setState({ specificProducts: true, allProducts: false, associatedProducts: [], optionError: '', associatedProductsError:'' }) }} />
                        <label><strong> Specific Products</strong></label>
                        {this.state.optionError !== "" && <span className="help-block">{this.state.optionError}</span>}
                    </div>
                </div>
                {this.state.specificProducts && <div className="row">
                    <div className={this.state.associatedProductsError !== "" ? "form-group col-md-12 has-error" : "form-group col-md-12"}>
                        <label>Search Products</label>
                        <AsyncTypeahead
                            // ref={this.refProductSearch}
                            //ref={(ref) => this.refProductSearch = ref}
                            autoFocus={true}
                            allowNew={false}
                            isLoading={this.state.isProductTypeAheadLoading}
                            multiple={false}
                            options={this.state.productsTypeAheadOptions}
                            labelKey="Name"
                            minLength={3}
                            onSearch={this.onProductTypeAheadSearch}
                            placeholder="Start typing or scanning to search products"
                            filterBy={['Name', 'SKU', 'BarCode']}
                            bsSize={'medium'}
                            autoFocus={true}
                            dropup={false}
                            highlightOnlyResult={true}
                            selected={this.state.productsTypeAheadSelected}
                            onChange={(selected) => {
                                this.onProductSelected(selected[0])
                                this.setState({ productsTypeAheadSelected: [] })
                            }}
                            renderMenuItemChildren={(option, props) => (
                                <div>
                                    <div><b>{option.Name}</b></div>
                                    <div>{option.SKU}</div>
                                    <div>{this.props.user.store.Currency}  {option.RetailPrice}</div>
                                </div>
                            )}
                        />
                        {this.state.associatedProductsError !== "" && <span className="help-block">{this.state.associatedProductsError}</span>}
                    </div>
                </div>
                }
                {this.state.associatedProducts  && this.state.associatedProducts.length > 0 && 
                <table className="table table-bordered table-striped" style={{marginTop:"10px"}}>
                <thead>
                    <tr>
                        <th>SKU</th>
                        <th>Name</th>
                        <th>Retail Price</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.associatedProducts.map((product,index) =>
                        <tr>
                            <td>{product.SKU}
                            </td>
                            <td>{product.Name}
                            </td>
                            <td>{product.RetailPrice}</td>
                            <td>
                                <button type="button" class="btn btn-default btn-md" 
                                    onClick={() => {
                                        let associatedProducts = this.state.associatedProducts
                                        associatedProducts.splice(index, 1)
                                        this.setState({ associatedProducts: associatedProducts })
                                    }}>
                                    <i class="glyphicon glyphicon-remove"></i> </button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>}
            </div>
            <div className="box-footer">
                <button type="submit" className="btn btn-flat btn-md btn-flat btn-default" onClick={this.props.onClose} >Cancel</button>
                <button type="submit" className="btn btn-flat btn-md btn-flat btn-success pull-right" onClick={this.onSaveModifier} disabled={this.state.saving}>Save</button>
            </div>
        </div>
    }
}

export default AddEditModifier