import firebase, { database } from '../firebase'
import * as actionTypes from '../actionTypes'
import * as constants from '../Constatnts'

export function getModifiers(registrationID, from, size, option, searchText) {
    return (dispatch) => {
        let searchParameters = {
            "from": from,
            "size": size,
            "query": {
                "bool": {
                    "must": [
                        { "match": { "RegistrationID": registrationID } }
                    ], "must_not": [
                    ]
                }
            },
            "sort": [
                { "ActionOn": { "order": "desc" } }
            ]
        }
        if (option === "active") {
            searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "true" } })
        }
        if (option === "inactive") {
            searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "false" } })
        }
        searchParameters.query.bool.must.push({ "regexp": { "ModifierName": "@" + searchText.trim().toLowerCase() + ".*" } })
        //console.log("Modifiers Search",searchParameters);
        let addMessage = firebase.functions().httpsCallable('getModifiers');
        return addMessage({ text: searchParameters }).then(function (result) {
            const modifiers = []
            let sanitizedMessage = result.data.text;
            sanitizedMessage.hits.hits.map((data, index) => {
                modifiers.push({ key: data._id, ...data._source })
            })
            return { totalItemsCount: sanitizedMessage.hits.total, searchResult: modifiers };
        });
    }
}

export function saveModifiers(modifier, key) {
    return (dispatch, getState) => {
        modifier.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        modifier.ActionBy = getState().user.user.Name
        modifier.ActionByUID = getState().user.user.UID
        modifier.ActionByEmailID = getState().user.user.EmailID

        if (key.trim() !== "") {
            modifier.Action = constants.AUDIT_TRAIL_EDIT
            return database.collection("Modifiers").doc(key).update({
                ...modifier
            }).then(() => {
                return key
            }).catch(function (error) {
                console.error("Error updating modifier: ", error);
            })
        }
        else {
            modifier.Action = constants.AUDIT_TRAIL_NEW

            return database.collection("Modifiers").add({
                ...modifier
            })
                .then(function (docRef) {
                    return docRef.id
                })
                .catch(function (error) {
                    console.error("Error adding modifier: ", error);
                })
        }
    }
}

export function deleteModifiers(key) {
    return (dispatch, getState) => {
        return database.collection("Modifiers").doc(key).update({
            IsDeleted: true,
            ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
            ActionBy: getState().user.user.Name,
            ActionByUID: getState().user.user.UID,
            ActionByEmailID: getState().user.user.EmailID,

            Action: constants.AUDIT_TRAIL_DELETE
        }).then(() => { return true })
    }
}


export function getModifierGroups(registrationID)
{
    return (dispatch) => {
        return database.collection("ModifierGroups").where("RegistrationID","==",registrationID).get().then((querySnapshot) => {        
            const modifierGroup = []
            querySnapshot.forEach((doc) => {
                modifierGroup.push(
                  {
                    key:doc.id,...doc.data()
                  }
                )
           })
        //    console.log("Get Offers", offers);
            return modifierGroup
            // dispatch({ type: actionTypes.GET_OFFER, offers })

        }).catch((error) => {console.log(error)})
    }
}

export function saveModifierGroup(modifierGroup, key) {
    return (dispatch, getState) => {        
        modifierGroup.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        modifierGroup.ActionBy = getState().user.user.Name
        modifierGroup.ActionByUID = getState().user.user.UID
        modifierGroup.ActionByEmailID = getState().user.user.EmailID
        console.log(modifierGroup)
        if (key.trim() !== "") {
            modifierGroup.Action = constants.AUDIT_TRAIL_EDIT
            return database.collection("ModifierGroups").doc(key).update({
                ...modifierGroup
            }).then(() => {
                return key
            }).catch(function (error) {
                console.error("Error updating modifier group: ", error);
            })
        }
        else {
            modifierGroup.Action = constants.AUDIT_TRAIL_NEW
            return database.collection("ModifierGroups").add({
                ...modifierGroup
            }).then(function (docRef) {
                return docRef.id
            }).catch(function (error) {
                console.error("Error adding modifier group: ", error);
            })
        }
    }
}

export function deleteModifierGroup(key){
    return (dispatch, getState) => {
        return database.collection("ModifierGroups").doc(key).update({
            IsDeleted: true,
            ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
            ActionBy: getState().user.user.Name,
            ActionByUID: getState().user.user.UID,
            ActionByEmailID: getState().user.user.EmailID,

            Action: constants.AUDIT_TRAIL_DELETE
        }).then(() => { return true })
    }
}