import firebase, { database } from '../firebase'
import * as actionTypes from '../actionTypes'
import * as constants from '../Constatnts'
import { isNullOrUndefined } from 'util';

export function getExpenseRunningNo(storeID) {
    return dispatch => {
        return database.collection("TransactionRunningNo").doc("expense" + storeID).get().then((doc) => {
            let runningNo = 1
            if (doc.exists) {
                runningNo = Number(doc.data().RunningNo) + 1
            }
            return database.collection("TransactionRunningNo").doc("expense" + storeID).set({
                StoreID: storeID, RunningNo: runningNo
            }).then(() => {
                return runningNo
            })
        })
    }
}

export function getExpenses(RegistrationID, StoreID, from, size, option, searchParty, searchExpenseDateFrom, searchExpenseDateTo, searchExpenseCategory,
    searchExpenseNumber, searchReferenceNumber) {
    let searchParameters = {
        "from": from,
        "size": size,
        "query": {
            "bool": {
                "must": [
                    { "term": { "RegistrationID.keyword": RegistrationID } },
                    { "term": { "StoreID.keyword": StoreID } }
                ], 
                "should": [
                
                ],
                "minimum_should_match": 1,
                "filter": {
                    "bool": {
                        "must": [

                        ]
                    }
                }
            }
        },
        "sort": [
            {
                "ActionOn": {
                    "order": "desc"
                }
            }
        ]
    }
    if(searchExpenseDateTo !=="" && searchExpenseDateFrom!=="" )
    {
        searchParameters.query.bool.must.push({
            "range": {
                "ExpenseDate": {
                    "lte": searchExpenseDateTo,
                    "gte": searchExpenseDateFrom,
                    "format": "yyyy-MM-dd"
                }
            }
        })
    }
    if (option != "") {
        searchParameters.query.bool.filter.bool.must.push({ "match": { "Status": option.trim().toLowerCase() } })
    }
    if (searchParty.trim() !== "") {
        searchParameters.query.bool.should.push({ "regexp": { "Party.PartyFirstName": "@" + searchParty.trim().toLowerCase() + ".*" } })
        searchParameters.query.bool.should.push({ "regexp": { "Party.PartyLastName": "@" + searchParty.trim().toLowerCase() + ".*" } })
    }
    else {
        searchParameters.query.bool.should.push({ "regexp": { "Party.PartyFirstName": "@.*" } })
    }
    if (searchExpenseCategory.trim() !== "") {
        searchParameters.query.bool.filter.bool.must.push({ "regexp": { "ExpenseCategory": "@" + searchExpenseCategory.trim().toLowerCase() + ".*" } })
    }
    if (searchExpenseNumber.trim() !== "") {
        searchParameters.query.bool.filter.bool.must.push({ "regexp": { "ExpenseNo": "@" + searchExpenseNumber.trim().toLowerCase() + ".*" } })
    }
    if (searchReferenceNumber.trim() !== "") {
        searchParameters.query.bool.filter.bool.must.push({ "regexp": { "RefNo": "@" + searchReferenceNumber.trim().toLowerCase() + ".*" } })
    }
    console.log("getExpenses", JSON.stringify(searchParameters))
    return (dispatch) => {
        let addMessage = firebase.functions().httpsCallable('getExpenses');
        return addMessage({ text: searchParameters }).then(function (result) {
            const expenses = []
            let sanitizedMessage = result.data.text;
            sanitizedMessage.hits.hits.map((data, index) => {
                expenses.push({ key: data._id, ...data._source })
            })
            return { totalItemsCount: sanitizedMessage.hits.total, searchResult: expenses }
        });
    }
}

// export function getActiveExpenses(RegistrationID){
//     let searchParameters =   {
//         "from": 0,
//         "size": 10000, 
//         "query": {
//           "bool": {
//             "must": [
//               { "match": { "RegistrationID": RegistrationID}}
//             ],
//             "must_not": [
//                 {"match": { "IsDeleted": "true" }}
//             ]
//           }
//         }
//       }

//     return (dispatch) => {
//         let addMessage =  firebase.functions().httpsCallable('getExpenses');
//         return addMessage({text: searchParameters}).then(function(result) {
//             const expenses = []
//             let sanitizedMessage = result.data.text;
//                 sanitizedMessage.hits.hits.map((data, index)=>{
//                 expenses.push({key: data._id, ...data._source})
//             })
//             console.log('expenses',expenses)
//             dispatch({type: actionTypes.GET_ACTIVE_PRODUCTS,products})

//         });
//     }
// }

// export function addExpenses(Expense){
//     return (dispatch, getState) =>{
//         Expense.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
//         Expense.ActionBy = getState().user.user.Name
//         Expense.ActionByUID = getState().user.user.UID
//         Expense.ActionByEmailID = getState().user.user.EmailID

//         Expense.Action = constants.AUDIT_TRAIL_NEW
//         return database.collection('Expenses').add({
//           ...Expense
//         })
//         .then(function(docRef) {
//             return docRef.id
//         })
//         .catch(function(error) {
//             console.error('Error adding Expenses: ', error);
//         })
//     }
// }

export function saveExpenses(Expense, key) {
    return (dispatch, getState) => {
        Expense.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        Expense.ActionBy = getState().user.user.Name
        Expense.ActionByUID = getState().user.user.UID
        Expense.ActionByEmailID = getState().user.user.EmailID
        console.log('key', key)
        if (!isNullOrUndefined(key) && key.trim() !== "") {
            Expense.Action = constants.AUDIT_TRAIL_EDIT
            return database.collection("Expenses").doc(key).update({
                ...Expense
            }).then(() => {
                return key
            }).catch(function (error) {
                console.error("Error updating expenses: ", error);
            })
        }
        else {
            Expense.Action = constants.AUDIT_TRAIL_NEW
            Expense.CreatedOn=firebase.firestore.FieldValue.serverTimestamp()
            return database.collection("Expenses").add({
                ...Expense
            })
                .then(function (docRef) {
                    return docRef.id
                })
                .catch(function (error) {
                    console.error("Error adding expenses: ", error);
                })
        }
    }
}

// export function getExpenses(registrationID) {
//     return (dispatch) => {
//         return database.collection("Expenses").where("RegistrationID","==", registrationID).get().then((querySnapshot) => {
//             const Expenses = []
//             querySnapshot.forEach((doc) => {
//                 Expenses.push(
//                   {
//                     key:doc.id,
//                     ...doc.data()
//                   })
//            })
//            console.log(Expenses)
//            return Expenses

//             }).catch((error) => {
//             console.log(error)
//         })
//     }
// }

export function getExpenseDetails(key) {
    return (dispatch) => {
        return database.collection("Expenses").doc(key).get().then((doc) => {
            if (doc.exists) {
                console.log("Expense found", doc.data());
                return doc.data()
            } else {
                console.log("No such Expense!");
            }
        })
    }
}

export function searchParty(RegistrationID, StoreID, searchText) {
    return (dispatch) => {
        let searchParameters = {
            "from": 0,
            "size": 50,
            "query": {
                "bool": {
                    "must": [
                        { "match": { "RegistrationID": RegistrationID } },
                        { "match": { "StoreID": StoreID } },
                        { "match": { "IsDeleted": "false" } }
                    ],
                    "should": [
                        { "regexp": { "PartyDetails": "@" + searchText.trim().toLowerCase() + ".*" } }
                    ]
                }
            }
        }
        // console.log(JSON.stringify(searchParameters))
        let addMessage = firebase.functions().httpsCallable('getParties');
        return addMessage({ text: searchParameters }).then(function (result) {
            const parties = []
            let sanitizedMessage = result.data.text;
            sanitizedMessage.hits.hits.map((data, index) => {
                parties.push({ key: data._id, ...data._source })
            })
            // console.log(JSON.stringify(parties))
            return { totalItemsCount: sanitizedMessage.hits.total, searchResult: parties }
        });
    }
}

export function cancelExpense(cancellationReason, key) {
    return (dispatch, getState) => {
        let actionOn = firebase.firestore.FieldValue.serverTimestamp()
        let actionBy = getState().user.user.Name
        let actionByUID = getState().user.user.UID
        let actionByEmailID = getState().user.user.EmailID
        let action = "CANCEL"
        return database.collection("Expenses").doc(key).update({
            Status: "Cancelled",
            CancellationReason: cancellationReason,
            ActionOn: actionOn,
            ActionBy: actionBy,
            ActionByUID: actionByUID,
            ActionByEmailID: actionByEmailID,
            Action: action
        })
    }
}

// export function getCashFromCounterAmountForCashRegisterClosing(
//     cashRegisterID, 
//     fromDate, 
//     toDate)
// {
//     return (dispatch) => {
//         let searchParameters = {
//             "size": 0,
//             "query": {
//                 "bool": {
//                 "must": [
                    
//                     {
//                     "match": {
//                         "CashRegisterID": cashRegisterID
//                     }
//                     },
//                     {
//                     "range": {
//                         "ExpenseDateTime": {
//                         "lte": fromDate,
//                         "gte": toDate
//                         }
//                     }
//                     },
//                     {
//                     "match": {
//                         "PaymentType": "Cash From Counter"
//                     }
//                     }
//                 ],
//                 "must_not": [
//                     {
//                     "term": {
//                         "Status.keyword": "Cancelled"
//                     }
//                     }
//                 ]
//                 }
//             },
//             "aggs": {
//                 "total_expenses": {
//                 "sum": {
//                     "field": "Amount"
//                 }
//                 }
//             }
//         }
// console.log('searchParameters', JSON.stringify(searchParameters))
//         let addMessage = firebase.functions().httpsCallable('getExpenses');
//         return addMessage({ text: searchParameters}).then(function (result) {
//             let sanitizedMessage = result.data.text;
//             console.log('sanitizedMessage', sanitizedMessage)
//             return sanitizedMessage.aggregations.total_expenses.value 
//         });
//     }
// }


export function getCashFromCounterAmountForCashRegisterClosing(
    cashRegisterID, 
    fromDate)
{
    return (dispatch, getState) =>{
    return database.collection("Expenses").where("CashRegisterID","==",cashRegisterID).where("Status","==", "Paid").where("ExpenseDateTime","<=",new Date()).where("ExpenseDateTime",">=",fromDate).get().then((querySnapshot) => {
        let amount = 0
        querySnapshot.forEach((doc) => {
            amount= amount +  Number(doc.data().Amount) 
            console.log('amount',doc.data().Amount)
        })
        // console.log('paymentModeAmount',paymentModeAmount)
        return amount
    }).catch((error) => {
        console.log("Error getting getCashFromCounterAmountForCashRegisterClosing : ", error)
    })
    }
}