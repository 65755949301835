import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import * as constatnts from '../../Constatnts'
import { isNullOrUndefined } from 'util';
import Loader from '../Loader'


class AllInventoryReport extends Component {
    constructor() {
        super()
        this.state = {
            inventoryLists: [],
            searchTransactionNo: '',
            searchTransactiontype: '',
            searchTransactionFromDate: new Date().toISOString().split('T')[0],
            searchTransactionToDate: new Date().toISOString().split('T')[0],
            searchVendorDetail: '',
            searchProductDetail: '',
            activePage: 1,
            itemsCountPerPage: 20,
            totalItemsCount: 0,
            recordFrom: 0,
            title: '',
            paginationStatus: 'No records to display',
            exportCalled: false,
            loading: true,
        }

        this.onClearSearch = this.onClearSearch.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.showPaginationStatus = this.showPaginationStatus.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.onExportClick = this.onExportClick.bind(this)
    }

    componentDidMount() {

        this.loadInventoryDetailReport(this.state.recordFrom,
            this.state.searchTransactionNo,
            this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate,
            this.state.searchVendorDetail,
            this.state.searchProductDetail
        )
    }

    loadInventoryDetailReport(from, searchTransactionNo, searchTransactionFromDate
        , searchTransactionToDate, searchVendorDetail, searchProductDetail) {

        this.setState({ loading: true })
        this.props.getAllInvetoryDetailReport(from, this.state.itemsCountPerPage,
            searchTransactionNo, this.props.location.state.transactionType.key,
            searchTransactionFromDate, searchTransactionToDate,
            searchVendorDetail, searchProductDetail)
            .then((result) => {
                this.setState({ inventoryLists: result.searchResult, totalItemsCount: result.totalItemsCount, loading: false })
                this.showPaginationStatus()
            })
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }

    onClearSearch() {
        this.setState({
            recordFrom: 0, searchTransactionNo: '',
            searchTransactiontype: '',
            searchTransactionFromDate: new Date().toISOString().split('T')[0],
            searchTransactionToDate: new Date().toISOString().split('T')[0],
            searchVendorDetail: '', searchProductDetail: ''
        })
        this.loadInventoryDetailReport(0, "", new Date().toISOString().split('T')[0], new Date().toISOString().split('T')[0], "", "")
    }

    onExportClick() {
        this.setState({ exportCalled: true })
        this.props.getAllInvetoryDetailReport(this.state.recordFrom, this.state.itemsCountPerPage,
            this.state.searchTransactionNo, this.props.location.state.transactionType.key,
            this.state.searchTransactionFromDate, this.state.searchTransactionToDate,
            this.state.searchVendorDetail, this.state.searchProductDetail)
            .then((result) => {
                console.log(result.searchResult);
                if (isNullOrUndefined(result.searchResult) || result.searchResult.length === 0) {
                    this.props.getToastr("No Record Found")
                    return
                }
                let inventoryLists = []
                result.searchResult.map((data) => {
                    let inventory = {
                        "Store Name ": data.StoreName,
                        "Store Location": data.StoreLocation,
                        "Transaction No": data.TransactionNo,
                        "Transaction Type": data.TransactionType,
                        "Transaction Date": (new Date(data.TransactionDate).toISOString().split('T')[0]),
                        "Type": data.ProductType,
                        "Item Code": data.SKU,
                        "Item Name": data.ProductName,
                        "Cancelled": "",
                    }

                    if(this.props.location.state.transactionType.TransactionMovement === "vendortostore" ||
                    this.props.location.state.transactionType.TransactionMovement === "storetovendor")
                    {
                        inventory["Vendor Code"] = data.VendorCode ? data.VendorCode : ""
                        inventory["Vendor Name"] = data.VendorName ? data.VendorName: ""
                        inventory["Vendor Phone No."] = data.VendorPhoneNo ? data.VendorPhoneNo: ""
                    }

                    if(this.props.location.state.transactionType.TransactionType === 'Transfer In')
                    {
                        inventory["From Store"] = data.FromStore ? data.FromStore :""
                    }

                    if(this.props.location.state.transactionType.TransactionType === 'Transfer Out')
                    {
                        inventory["To Store"] = data.ToStore ? data.ToStore : ""
                    }

                    if(this.props.location.state.transactionType.IncludePriceAndTax)
                    {
                        inventory["Supply Price"] = data.SupplyPrice
                        inventory.Discount = data.DiscountAmount
                        inventory["Taxble Amount"] = data.TaxableAmount
                        inventory["Tax Inclusive"] = data.TaxInclusive
                        inventory["Tax Summary"] = ""
                    }

                    if (data.CancellationReason) {
                        inventory.Cancelled = "Yes"
                    }

                    if (this.props.location.state.transactionType.IncludePriceAndTax && data.TaxComponentAmount) {
                        data.TaxComponentAmount.map((tax) => {
                            if (inventory["Tax Summary"] === "") {
                                inventory["Tax Summary"] = tax.TaxName + '(' + tax.TaxPercentage + '%) : ' + tax.TaxAmount
                            }
                            else {
                                inventory["Tax Summary"] = inventory["Tax Summary"] + "," + tax.TaxName + '(' + tax.TaxPercentage + '%) : ' + tax.TaxAmount
                            }
                        })
                    }
                    inventoryLists.push(inventory);
                })
                const csvData = constatnts.objToCSV(inventoryLists);
                this.setState({ loading: false, exportCalled: false })
                const csvBlob = new Blob([csvData], { type: "text/csv" });
                const url = window.URL.createObjectURL(csvBlob);
                const a = document.createElement("a");
                a.setAttribute("hidden", "");
                a.setAttribute("href", url);
                a.setAttribute("download", this.props.location.state.transactionType.TransactionType + "_Details.csv");
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
    }


    onSearchClick() {
        this.setState({ searchFrom: 0 })
        this.loadInventoryDetailReport(
            0,
            this.state.searchTransactionNo,
            this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate,
            this.state.searchVendorDetail,
            this.state.searchProductDetail)
    }

    handlePageChange(pageNumber) {

        let recordFrom = ((pageNumber - 1) * this.state.itemsCountPerPage)
        this.setState({ activePage: pageNumber, recordFrom: recordFrom });
        this.loadInventoryDetailReport(
            recordFrom,
            this.state.searchTransactionNo,
            this.state.searchTransactionFromDate, this.state.searchTransactionToDate,
            this.state.searchVendorDetail,
            this.state.searchProductDetail)
    }

    showPaginationStatus() {
        let paginationStatus
        if ((this.state.recordFrom + 1) === this.state.totalItemsCount) {
            paginationStatus = "Showing " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else if ((this.state.recordFrom + this.state.itemsCountPerPage + 1) > this.state.totalItemsCount) {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + (this.state.recordFrom + this.state.itemsCountPerPage) + " of " + this.state.totalItemsCount

        }
        this.setState({ paginationStatus: paginationStatus })
    }

    render() {
        const btnColStyle = { width: "120px" }

        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6">
                                        <font style={{ fontSize: "30px" }}>{this.props.location.state.transactionType.TransactionType}</font>
                                    </div>
                                    <div className="col-md-6">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={() => this.props.history.push({ pathname: "/home/reports/allreport" })}
                                        ><i className="fa fa-arrow-left"></i> Back</button>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Transaction No</label>
                                            <input type="text" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionNo: evt.target.value })}
                                                value={this.state.searchTransactionNo}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>Transaction From</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionFromDate: evt.target.value })}
                                                value={this.state.searchTransactionFromDate}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <label>Transaction To</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ searchTransactionToDate: evt.target.value })}
                                                value={this.state.searchTransactionToDate}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Vendor Name,Code,Phone Number,Email</label>
                                            <input type="text" className="form-control"
                                                onChange={(evt) => this.setState({ searchVendorDetail: evt.target.value })}
                                                value={this.state.searchVendorDetail}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Product Name,Code</label>
                                            <input type="text" className="form-control"
                                                onChange={(evt) => this.setState({ searchProductDetail: evt.target.value })}
                                                value={this.state.searchProductDetail}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row" >
                                    <div className="col-md-12" style={{ marginTop: "-25px" }}>
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginLeft: "5px" }}
                                            onClick={this.onSearchClick}
                                        >Search</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                            onClick={this.onClearSearch} >Clear</button>
                                        <button class="btn btn-default btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginRight: "5px" }}
                                            onClick={this.onExportClick}
                                        > Export <i class="glyphicon glyphicon-download-alt " ></i></button>
                                    </div>
                                </div><br></br>
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading &&
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>Transaction No</th>
                                                <th>Transaction Type</th>
                                                <th>Transaction Date</th>
                                                {(this.props.location.state.transactionType.TransactionMovement === "vendortostore" ||
                                                this.props.location.state.transactionType.TransactionMovement === "storetovendor" ||
                                                this.props.location.state.transactionType.TransactionMovement ==="storetostore")
                                                 && 
                                                <th>Vendor/Store Detail</th>}
                                                <th>Type</th>
                                                <th>Item Code</th>
                                                <th>Item Name</th>
                                                {this.props.location.state.transactionType.IncludePriceAndTax && <th>Supply Price</th>}
                                                <th>Qty</th>
                                                {this.props.location.state.transactionType.IncludePriceAndTax && <th>Discount</th>}
                                                {this.props.location.state.transactionType.IncludePriceAndTax && <th>Taxable Amount</th>}
                                                {this.props.location.state.transactionType.IncludePriceAndTax && <th>Tax Inclusive</th>}
                                                {this.props.location.state.transactionType.IncludePriceAndTax && <th>Tax Summary</th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.inventoryLists.map((inventory) =>
                                                <tr>
                                                    <td>{inventory.TransactionNo}</td>
                                                    <td>{inventory.TransactionType}
                                                        <div>
                                                            {inventory.CancellationReason && <span className="badge bg-red">Cancelled </span>}
                                                        </div>
                                                    </td>
                                                    <td>{(new Date(inventory.TransactionDate).toISOString().split('T')[0])}</td>
                                                    {(this.props.location.state.transactionType.TransactionMovement === "vendortostore" ||
                                                        this.props.location.state.transactionType.TransactionMovement === "storetovendor" ||
                                                        this.props.location.state.transactionType.TransactionMovement === "storetostore")
                                                        && <td>
                                                            <div>{inventory.VendorName}</div>
                                                            <div>{inventory.VendorEmailID}</div>
                                                            <div>{inventory.VendorPhoneNo}</div>
                                                            {inventory.TransactionType === 'Transfer In' && <div>{inventory.FromStore}</div>}
                                                            {inventory.TransactionType === 'Transfer Out' && <div>{inventory.ToStore}</div>}
                                                        </td>}
                                                    <td>{inventory.ProductType}</td>
                                                    <td>{inventory.SKU}</td>
                                                    <td>{inventory.ProductName}</td>
                                                    {this.props.location.state.transactionType.IncludePriceAndTax && <td>{inventory.SupplyPrice}</td>}
                                                    <td>{inventory.Qty}</td>
                                                    {this.props.location.state.transactionType.IncludePriceAndTax && <td>{inventory.DiscountAmount}</td>}
                                                    {this.props.location.state.transactionType.IncludePriceAndTax && <td>{inventory.TaxableAmount}</td>}
                                                    {this.props.location.state.transactionType.IncludePriceAndTax && <td>{inventory.TaxInclusive === true ? "Yes" : "No"}</td>}
                                                    {this.props.location.state.transactionType.IncludePriceAndTax && <td>
                                                        {inventory.TaxComponentAmount && inventory.TaxComponentAmount.map((tax) =>
                                                            <div>
                                                                <div>{tax.TaxName + '(' + tax.TaxPercentage + '%) : ' + tax.TaxAmount}</div>
                                                            </div>
                                                        )}</td>}
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>}
                                {this.state.totalItemsCount > 0 && <div className="pull-left" style={{ marginTop: "25px" }}>
                                    {this.state.paginationStatus}
                                </div>}
                                <div className="pull-right">
                                    <Pagination
                                        activePage={this.state.activePage} //this.state.activePage
                                        itemsCountPerPage={this.state.itemsCountPerPage}
                                        totalItemsCount={this.state.totalItemsCount}
                                        pageRangeDisplayed={2}
                                        onChange={this.handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default withRouter(AllInventoryReport)