import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { auth } from '../firebase'
import { withRouter } from 'react-router'
import Loader from './Loader'
import Modal from 'react-responsive-modal'


class Register extends Component {

  constructor() {
    super()
    this.state = {
      businessName: '',
      buinessUniqueName: 'yourbusinessname',
      fullName: '',
      contactNo: '',
      emailID: '',
      password: '',
      confirmPassword: '',
      loading: false,
      showModal: false,
      businessNameError: '',
      fullNameError: '',
      contactNoError: '',
      emailIDError: '',
      passwordError: '',
      confirmPasswordError: ''
    }
    this.onRegister = this.onRegister.bind(this)
    this.createRegistration = this.createRegistration.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  componentDidMount() {
    console.log('register called')
    if (this.props.user.auth) {
      this.setState({ fullName: this.props.user.auth.displayName, emailID: this.props.user.auth.email })
    }
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.onRegister()
    }
  }

  onRegister() {
    this.setState({ loading: true });

    //event.preventDefault

    let businessNameError=""
    let fullNameError =""
    let contactNoError=""
    let emailIDError=""
    let passwordError=""
    let confirmPasswordError= ""

    if (this.state.businessName.trim() === '') 
    { businessNameError = 'Please provide business/brand name'}
    if (this.state.fullName.trim() === '') 
    {fullNameError='Please provide your full name'}
    if (this.state.contactNo.trim() === '')
    { contactNoError='Please provide your contact no'}
    if (this.state.emailID.trim() === '') 
    { emailIDError= 'Please provide email id' }
    if (!this.props.user.auth && this.state.password.trim() === '') 
    { passwordError = 'Please enter passord' }
    if (!this.props.user.auth && this.state.confirmPassword.trim() === '') 
    { confirmPasswordError = 'Please enter confirm passowrd' }
    if (!this.props.user.auth && this.state.password.trim() !== this.state.confirmPassword.trim()) 
    { confirmPasswordError = 'Passowrd and confirm passowrd do not match' }
if(businessNameError !=="" ||
fullNameError !=="" || contactNoError!=="" || emailIDError !=="" || passwordError!=="" || confirmPasswordError !=="")
{
  this.setState({businessNameError:businessNameError,
    fullNameError:fullNameError,
    contactNoError:contactNoError,
    emailIDError:emailIDError,
    passwordError:passwordError,
    confirmPasswordError:confirmPasswordError,
    loading:false
  })
  return
}
    // if(this.state.password.trim().length()<6) {alert('Password should be atleast six characters in lenght')}
    //check if valid email id is provided..find regular expression for that
    //check if businessname is available
    this.props.checkBusinessNameIsUnique(this.state.buinessUniqueName).then(() => {
      if (!this.props.registration.businessNameIsUnique) {
        alert('Business name already registered with us. Please try a different business name')
        this.setState({ businessName: '', buinessUniqueName: 'yourbusinessname', loading:false })
        return
      }

      if (this.props.user.auth) {
        this.createRegistration()
      }
      else {//create user with emailid and password
        auth.createUserWithEmailAndPassword(this.state.emailID, this.state.password).then((result) => {
          const auth = result.user;
          //auth.sendEmailVerification();
          this.createRegistration(auth)
        }).catch(error => {
          alert(error)
          this.setState({ loading: false });
        })
      }
    })
  }

  createRegistration(auth) {
    const registration = {
      BusinessName: this.state.businessName,
      ContactNo: this.state.contactNo,
      Country: 'india',
      StorageFolder: this.state.buinessUniqueName,
      UniqueName: this.state.buinessUniqueName,
      UID: auth.uid,
      EmailID: this.state.emailID,
      Name: this.state.fullName,
    }
    this.props.createRegistration(registration).then(() => {
      this.setState({ loading: false });
      this.props.getToastr("Registration created successfully")
      this.props.setAuthUser(auth)
      this.props.onHistory.push('/')
    })
  }

  render() {
    const modalWidth = { width: "700px" }

    return <div className="register-box">
      <div className="register-box-body">
        <div className="login-logo">
          <img src={require('../images/logo.png')} alt="logo"/>
        </div>
        {this.state.loading && <Loader show={this.state.loading} />}


        {!this.state.loading && <React.Fragment>
          <p className="login-box-msg">Register a new membership</p>
          <div className={ this.state.businessNameError!=="" ? "form-group has-error": "form-group "} >
                                
            <input type="text" className="form-control" onKeyPress={this.handleKeyPress} placeholder="Business/Brand name"
              value={this.state.businessName}
              onChange={(evt) => {
                this.setState({
                  businessName: evt.target.value.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''),
                  buinessUniqueName: evt.target.value.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()
                })
              }}></input>
            <span className="glyphicon glyphicon-th form-control-feedback"></span>
            {this.state.businessNameError!=="" && <span class="help-block">{this.state.businessNameError}</span>}
          </div>
          <div className={ this.state.fullNameError!=="" ? "form-group has-error": "form-group "} >
            <input type="text" className="form-control" onKeyPress={this.handleKeyPress} placeholder="Full Name"
              disabled={(this.props.user.auth)}
              value={this.state.fullName}
              onChange={evt => this.setState({ fullName: evt.target.value })}
            ></input>
            <span className="glyphicon glyphicon-user form-control-feedback"></span>
            {this.state.fullNameError!=="" && <span class="help-block">{this.state.fullNameError}</span>}
          </div>
          <div className={ this.state.contactNoError!=="" ? "form-group has-error": "form-group "} >
            <input type="text" className="form-control" onKeyPress={this.handleKeyPress} placeholder="Contact No"
              value={this.state.contactNo}
              onChange={evt => this.setState({ contactNo: evt.target.value })}
            ></input>
            <span className="glyphicon glyphicon-earphone form-control-feedback"></span>
            {this.state.contactNoError!=="" && <span class="help-block">{this.state.contactNoError}</span>}
          </div>
          <div className={ this.state.emailIDError!=="" ? "form-group has-error": "form-group "} >
            <input type="email" className="form-control" onKeyPress={this.handleKeyPress} placeholder="Email"
              disabled={(this.props.user.auth)}
              value={this.state.emailID}
              onChange={evt => this.setState({ emailID: evt.target.value })}
            ></input>
            <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
            {this.state.emailIDError!=="" && <span class="help-block">{this.state.emailIDError}</span>}
          </div>
          {!this.props.user.auth &&
          <div className={ this.state.passwordError!=="" ? "form-group has-error": "form-group "} >
              <input type="password" className="form-control" onKeyPress={this.handleKeyPress} placeholder="Password"
                value={this.state.password}
                onChange={evt => this.setState({ password: evt.target.value })}
              />
              <span className="glyphicon glyphicon-lock form-control-feedback"></span>
              {this.state.passwordError!=="" && <span class="help-block">{this.state.passwordError}</span>}
            </div>
          }
          {!this.props.user.auth &&
          <div className={ this.state.confirmPasswordError!=="" ? "form-group has-error": "form-group "} >
              <input type="password" className="form-control" onKeyPress={this.handleKeyPress} placeholder="Confirm Password"
                value={this.state.confirmPassword}
                onChange={evt => this.setState({ confirmPassword: evt.target.value })}
              ></input>
              <span className="glyphicon glyphicon-log-in form-control-feedback"></span>
              {this.state.confirmPasswordError!=="" && <span class="help-block">{this.state.confirmPasswordError}</span>}
            </div>
          }
          <div className="row">
            <div className="col-xs-12">
                <label>
                  By registering with POSible.in, you agree to the following <a onClick={() => this.setState({ showModal: true })}>terms and conditions</a>
                </label>
            </div>
            
          </div>
          <div className="row">
            <div className="col-xs-4 pull-right" style={{marginRight:"1px"}}>
                <button disabled={this.state.loading}
                className="btn btn-success btn-block btn-flat" onClick={this.onRegister} >Register</button>
              </div>
          </div>
          </React.Fragment>}
        <Link to="/" className="text-center">I already have a membership</Link>
      </div>
      <Modal open={this.state.showModal} onClose={() => this.setState({ showModal: false })} center>
        <div style={modalWidth}>
          <div className="col-sm-12">
            <p>
              THESE TERMS AND CONDITIONS ("TERMS OF USE") IS AN ELECTRONIC RECORD IN THE FORM OF AN ELECTRONIC CONTRACT FORMED UNDER INFORMATION TECHNOLOGY ACT, 2000 AND RULES MADE THEREUNDER AND THE AMENDED PROVISIONS PERTAINING TO ELECTRONIC DOCUMENTS / RECORDS IN VARIOUS STATUTES AS AMENDED BY THE INFORMATION TECHNOLOGY ACT, 2000. THESE TERMS OF USE DOES NOT REQUIRE ANY PHYSICAL, ELECTRONIC OR DIGITAL SIGNATURE.
                                <br />
              <br />
              THESE TERMS OF USE IS A LEGALLY BINDING DOCUMENT BETWEEN USER AND COMPANY (BOTH TERMS DEFINED BELOW). THESE TERMS OF USE WILL BE EFFECTIVE UPON YOUR ACCEPTANCE OF THE SAME (DIRECTLY OR INDIRECTLY IN ELECTRONIC FORM OR BY MEANS OF AN ELECTRONIC RECORD) AND WILL GOVERN THE RELATIONSHIP BETWEEN USER AND COMPANY FOR THE USE OF THE WEBSITE (DEFINED BELOW). UPON ACCEPTANCE USER ACCEPT AND AGREE TO BE BOUND BY THESE TERMS AND CONDITIONS. COMPANY GRANTS YOU A LIMITED, NON-EXCLUSIVE, NON-TRANSFERABLE, REVOCABLE LICENCE TO USE THE SERVICES (SUBJECT TO YOUR ACCOUNT TYPE) IN ACCORDANCE WITH THESE TERMS AND CONDITIONS. ALL RIGHTS NOT EXPRESSLY GRANTED TO USERS IN THESE TERMS ARE RESERVED BY COMPANY.
                                <br />
              <br />
              THIS DOCUMENT IS PUBLISHED AND SHALL BE CONSTRUED IN ACCORDANCE WITH THE PROVISIONS OF RULE 3 (1) OF THE INFORMATION TECHNOLOGY (INTERMEDIARIES GUIDELINES) RULES, 2011 UNDER INFORMATION TECHNOLOGY ACT, 2000 THAT REQUIRE PUBLISHING THE RULES AND REGULATIONS, PRIVACY POLICY, COOKIE POLICY FOR ACCESS OR USAGE OF THE WEBSITE.
                            </p>
            <h2>1 - TERMS</h2>
            <p>
              <b>1.1 </b>
              These terms and conditions ("<b>Terms and Conditions</b>") and all applicable Policies (as defined below) which are herein incorporated by way of reference govern your rights and obligations as an User (defined below) of the Platform(s) (defined below) while accessing and using the Platform(s) and/or availing the services on the Platform, as may be rendered by Techmandala Solutions LLP, a company incorporated under the provisions of the (Indian) Companies Act, 1956, having its registered office at having its registered office at A-101, Madhur Apt., Near Dream Land Hotel, Deewan Housing, Vasai(E), Maharashtra, India - 401208 ("<b>Company</b>") through the POSible (hereinafter collectively referred to as the "<b>Platform</b>") to User from time to time. Any reference to "you" or "User" or "User’s" in these Terms and Conditions, means you the User of the Platform, browsing and/or taking the services including free trial, offered by the Company on the Platform ("<b>Products</b>").<br />
              <br />
              <b>1.2</b>
              As a User, your access to and use of the Platform are conditional upon your acceptance of and continuous compliance with these Terms and Conditions at all times. By accessing, browsing and/or using the Platform and/or availing any of the services including free trial, you agree to be bound by these Terms and Conditions and all rules, guidelines, policies, terms and conditions applicable to the Platform of the Company ("<b>Policies</b>"), as may be modified by Company from time to time, which shall be deemed to be incorporated in these Terms and Conditions by reference, unconditionally and at all times. The Company shall have the sole right to change, modify, add or remove these Terms and Conditions or any of its Policies or and add new features relating to the Platform, at any time without any prior written notice or intimation. It is hereby clarified that each User shall be responsible for reviewing the Terms and Conditions and Policies on the Platform periodically and keep themselves updated.
                            </p>
            <h2>2 - USER ELIGIBILITY </h2>
            <p>
              The services on the Platform are only available to persons who are competent to enter into legally binding contracts under the Indian Contract Act, 1872 (<b>Contract Act</b>). Any person who shall be considered as incompetent to contract as per the provisions of the Contract Act including minors, un-discharged insolvents etc. are not eligible to use the Platform (<b>User</b>). Any person using the services on the Platform on behalf of a proprietor/ partnership/ firm/ company, represents and warrants that it is duly authorized by the User to accept the Terms and Conditions and the Policies and that such person has the authority to bind the User to these Terms and Conditions and the Policies.
                            </p>
            <h2>3 - USER ACCOUNT </h2>
            <p>
              You may access and use the Website and the Services either as a registered user or as a guest user. However, not all sections of the Website and Services will be accessible to guest users.<br />
              <br />
              In order to access and use the Services, Users will need to:<br />
              <br />
              ("<b>Account Information</b>)<br />
              All Users agree to provide true, accurate, current and complete Account Information, and to maintain and promptly update their Account Information in order to ensure that it remains true, accurate, current and complete.
                            </p>
            <h2 style={{textTransform: "uppercase"}}>4 - Non-compliance with these terms </h2>
            <p>
              If a User does not comply with these Terms, Company may, at its discretion and with or without notice, cancel or suspend that User's account and refuse to provide Services to that User.
                            </p>
            <h2>5 - CANCELLATION OF ACCOUNTS </h2>
            <p>
              Company reserves the right to cancel or suspend any User's account at any time in its sole discretion, and all Users are entitled to cancel their account with Company at any time. All cancellations will result in the deactivation or deletion of the User's account.<br />
              <br />
              An email or phone request to Company to cancel a User account is not considered cancellation. Users may only cancel their account through the Website. If a User elects to cancel its account, cancellation will not become effective unless and until no money is owing on that User's account. If you cancel the Services before the end of your current paid up month, your cancellation will take effect immediately and you will not be charged again.<br />
              <br />
              ALL of your data and content will be deleted from our systems immediately upon cancellation of your account. This content cannot be recovered once your account is cancelled. Company is not liable for any loss or damage following, or as a result of, cancellation of your account, and it is your responsibility to ensure that any content or data which you require is backed-up or replicated before cancellation.
                            </p>
            <h2>6 - ACCOUNT DOWNGRADES </h2>
            <p>
              Users may downgrade their accounts at any time by following the steps on the Website. The downgrade will become effective immediately. No refunds will be paid in respect of any unused portion of any higher account offering.<br />
              <br />
              Downgrading your Services may cause the loss of content, or features of capacity of your account. If you choose to downgrade your account, Company does not accept any liability for the resulting loss of data, content, features or capacity.
                            </p>
            <h2>7 - PAYMENTS </h2>
            <p>
              All Services are charged in advance on a monthly basis and are non-refundable, including for partial months of Services, Service upgrades or downgrades or unused Services in an open account. Company will not be held liable in any way for any lack of refunds in connection with the Services. A valid credit card number is required for paying all User accounts.<br />
              <br />
              If a User is carrying out a free trial of the Services (as offered on the Website), the free trial will begin on the day that the User's account is opened and ends 30 days later.<br />
              <br />
              For financing services all payment will be processed in 4 working days from date of transaction upon submission of sufficient documents i.e. invoice, proof of delivery etc. as requested by Company from time to time.
                            </p>
            <h2>8 - FEES </h2>
            <p>
              The fees charged for the Services ("<b>Fees</b>") are subject at all times to change by Company giving 30 (thirty) days’ notice, which will be posted on the Website. Company shall not liable to Users or any third party for any modification to the Fees.
                            </p>
            <h2>9 - TAXES </h2>
            <p>
              All Fees are exclusive of all taxes, and the User indemnifies and holds Company harmless against any claims by any tax authority for any underpayment of any sales, use, goods and services, value added or other tax or levy, and any penalties and/or interest.
                            </p>
            <h2>10 - RESPONSIBILITY </h2>
            <p>
              Each User will be responsible for all activity that results from its use of the Services. Company disclaims any and all liability in relation to each Users' use of the Services, and will not be held accountable if a User suffers any loss or damage as a result of its use of the Services, including (but not limited to) any loss or damage resulting from:<br />
              <br />
              <b>a)</b> any downgrade of the Services that Users may opt for through the Website; and<br />
              <br />
              <b>b)</b> the cancellation of any User's account.
                            </p>
            <h2>11 - SERVICES ARE PROVIDED 'AS IS' </h2>
            <p>
              The Website and Services are provided on an "as is" and "as available" basis, and your use of them is at your sole risk. Company does not warrant that: (a) the Services will meet your specific requirements; (b) the Services will be uninterrupted, timely, secure, or error-free; (c) the results that may be obtained from the use of the Services will be accurate or reliable; (d) the quality of any products, services, information, or other material purchased or obtained by you through the Services will meet your expectations; or (e) any errors in the Services will be corrected.<br />
              <br />
              You acknowledge that Company may use third party suppliers to provide necessary hardware, software, networking, connectivity, storage and related technology required to provide the Services. The acts and omissions of those third party suppliers may be outside of Company's control, and Company does not accept any liability for any loss or damage suffered as a result of any act or omission of any third party supplier.<br />
              <br />
              On behalf of itself and such third party suppliers, Company excludes any other warranties that may be implied or otherwise apply under statute or otherwise under applicable law, to the maximum extent permitted by law.
                            </p>
            <h2>12 - LIABILITY </h2>
            <p>
              Company shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if Company has been advised of the possibility of such damages), resulting from: (a) the use or the inability to use the Service; (b) the cost of procurement of substitute goods and services resulting from any goods, data, information or services purchased or obtained or messages received or transactions entered into through or from the Services; (c) unauthorised access to or alteration of your transmissions or data; (d) statements or conduct of any third party regarding the Services; or (e) any other matter relating to the Services.<br />
              <br />
              Company's maximum aggregate liability under or in connection with this Agreement shall be limited to the amount of Fees paid to Company by the relevant User per month.
                            </p>
            <h2>13 - INDEMNITY </h2>
            <p>
              Each User indemnifies, and will keep indemnified, Company against all forms of liability, actions, proceedings, demands, costs, charges and expenses which Company may incur or be subject to or suffer as a result of the relevant User's use of the Services.
                            </p>
            <h2>14 - USER’S RESPONSIBILITY </h2>
            <p>
              <b>i)</b> No illegal use: You agree and undertake that you will not use the Website or Services for any illegal or unauthorised purpose, or upload any information that is in breach of any confidentiality obligation, copyright, trademark or other intellectual property or proprietary rights of any person, entity or organization or any applicable laws. You agree and undertake that you will not attempt to gain unauthorized access to any portion or feature of the Website, any other systems or networks connected to the Website, to any Company’s server, or to any of the Services offered on or through the Website, by hacking, password mining or any other illegitimate means;<br />
              <br />
              <b>ii)</b> No malicious use: Users may only access the Website and Services through the interface provided by Company, and shall not use the Website or Services for any malicious means, or abuse, harass, threaten, intimidate or impersonate any other User of the Website or Services.<br />
              <br />
              <b>iii)</b> When you use the Website and/or the Services You specifically undertake not to host, display, upload, modify, publish, transmit, update or share any information or Content that:<br />
              <br />
              •	impersonate any person or entity, or falsely state or otherwise misrepresent Your affiliation with a person or entity;<br />
              •	threatens the unity, integrity, defense, security or sovereignty of India, friendly relations with foreign states or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting of any other nation;<br />
              •	infringes any patent, trademark, copyright or other proprietary rights;<br />
              •	contain software viruses or any other computer code, files or programs designed to interrupt destroy or limit the functionality of any computer resource;<br />
              •	deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;<br />
              •	engage in any activity that interferes with or disrupts access to the Website or the Services (or the servers and networks which are connected to the Website);<br />
              •	probe, scan or test the vulnerability of the Website or any network connected to the Website, nor breach the security or authentication measures on the Website or any network connected to the Website. You may not reverse look-up, trace or seek to trace any information on any other user, of or visitor to, the Website, or any other User of Company, including any company Account not owned by You, to its source, or exploit the Website or Service or information made available or offered by or through the Website, in any way whether or not the purpose is to reveal any information, including but not limited to personal identification information, other than Your own information, as provided for by the Website;<br />
              •	disrupt or interfere with the security of, or otherwise cause harm to, the Website, systems resources, accounts, passwords, servers or networks connected to or accessible through the Websites or any affiliated or linked sites;<br />
              •	use the Website or Content for any purpose that is unlawful or prohibited by the Agreement, or to solicit the performance of any illegal activity or other activity which infringes the rights of Company or other third parties;<br />
              •	falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded;<br />
              •	violate any code of conduct or other guidelines, which may be applicable for or to any particular Service;<br />
              •	violate any applicable laws or regulations for the time being in force within or outside India;<br />
              •	violate the terms of this Terms and Conditions.<br />
              •	You shall solely be responsible for maintaining the necessary computer / mobile equipment, internet connections and other software and technologies that may be required to access, use and transact on the Website.<br />
              <br />
              You may need to install updates that we or any third party introduce from time to time to use the services, Products, Website including downloads and required functionality, such as bug fixes, patches, enhanced functions, missing plug-ins and new versions (collectively, "Updates"). Your use of the Products or Website you have installed requires that you have agreed to receive such automatically requested Updates.<br />
              <br />
              You represent and warrant that <b>(i)</b> You are not located in a country that is subject to a India Government embargo, or that has been designated by the India Government as a "terrorist supporting" country; and <b>(ii)</b> You are not listed on any India Government list of prohibited or restricted parties.
                            </p>
            <h2>15 - INTELLECTUAL PROPERTY </h2>
            <p>
              You agree and accept that that Company owns all of the intellectual property rights existing in the Website and Services and You shall not raise any claims in relation to the same whatsoever the case may be.
                            </p>
            <h2>16 - USE OF LOGO </h2>
            <p>
              You agree and undertake that you will not  publish or use Company's brand, branding or logos without prior written consent.
                            </p>
            <h2>17 - TECHNICAL SUPPORT AND MALFUNCTIONS </h2>
            <p>
              Company will try to promptly address (during normal business hours) all technical issues that arise on the Website or in connection with the Services. However, Company will not be liable for any loss suffered as a result of any partial or total breakdown of the Website or any technical malfunctions resulting in an inability to use the Services (either in whole or in part).
                            </p>
            <h2>18 - SECURITY </h2>
            <p>
              Users are responsible for maintaining the security of their account and password on the Website. Company will not be liable for any loss or damage that may result from any failure to keep User names and passwords secure. Company makes efforts to keep content uploaded by Users secure, but will not be liable for any loss or damage that may result from any breach of security, or any unauthorised access or use of that content.
                            </p>
            <h2>19 - GOVERNING LAW AND JURISDICTION </h2>
            <p>
              These Terms shall be governed by India law, and all Users submit to the exclusive jurisdiction of the Indian courts for any matter or dispute arising in relation to these Terms.
                            </p>
            <h2>20 - GENERAL PROVISIONS </h2>
            <p>
              <b>a)</b>
              Notice: All notices with respect to these Terms and Conditions from Company will be served to You by email or by general notification on the Website. Any notice provided to Company pursuant to these Terms and Conditions should be sent to Grievance Officer at New Delhi.
                                <br />
              <br />
              <b>b)</b>
              Assignment: You cannot assign or otherwise transfer these Terms and Conditions, or any rights granted hereunder or any obligations, to any third party and any such assignment or transfer or purported assignment or transfer shall be void ab initio. Company's rights and/or obligations under these Terms and Conditions are freely assignable or otherwise transferable by Company to any third parties without the requirement of seeking Your prior consent. Company may inform You of such assignment or transfer in accordance with the notice requirements under these Terms and Conditions. Company shall have right to transfer Your Account and Account Information to a third party who purchases Company's business as conducted under the Website.
                                <br />
              <br />
              <b>c)</b>
              Severability: If, for any reason, a court of competent jurisdiction finds any provision of the these Terms and Conditions, or portion thereof, to be unenforceable, that provision shall be enforced to the maximum extent permissible so as to give effect to the intent of the parties as reflected by that provision, and the remainder of the Agreement shall continue in full force and effect. Company may amend in a reasonable manner such provision to make it enforceable and such amendment will be given effect in accordance with the amendment terms of these Terms and Conditions.
                                <br />
              <br />
              <b>d)</b>
              Waiver: Any failure or delay by a party to enforce or exercise any provision of these Terms and Conditions, or any related right, shall not constitute a waiver by such party of that provision or right. The exercise of one or more of a party's rights hereunder shall not be a waiver of, or preclude the exercise of, any rights or remedies available to such party under these Terms of Use or in law or at equity. Any waiver by a party shall only be made in writing and executed by a duly authorized officer of such party.
                                <br />
              <br />
              <b>e)</b>
              Principal to Principal Relationship: You and Company are independent contractors, and nothing in these Terms of Use will be construed to create a partnership, joint venture, association of persons, agency (disclosed or undisclosed), franchise, sales representative, or employment relationship between You and Company.
                                <br />
              <br />
              <b>f)</b>
              Force Majeure: If performance of any service or obligation under these Terms and Conditions or other agreement by Company are, prevented, restricted, delayed or interfered with by reason of labor disputes, strikes, acts of God, floods, lightning, severe weather, shortages of materials, rationing, utility or communication failures, earthquakes, war, revolution, acts of terrorism, civil commotion, acts of public enemies, blockade, embargo or any law, order, proclamation, regulation, ordinance, demand or requirement having legal effect of any government or any judicial authority or representative of any such government, or any other act whatsoever, whether similar or dissimilar to those referred to in this clause, which are beyond the reasonable control of Company (each, a "Force Majeure Event"), then Company shall be excused from such performance to the extent of and during the period of such Force Majeure Event. Company shall exercise all reasonable commercial efforts to continue to perform its obligations hereunder.
                                <br />
              <br />
              <b>g)</b>
              Grievance Officer: In compliance with Information Technology Act, 2000 and the rules made thereunder, the Grievance Officer of Company for the purpose of this Agreement shall be Mr. Lalit Singh with email address: <span style={{color: "blue"}}>support@posible.in</span>. Company may change the aforesaid details from time to time under intimation to you.
                            </p>
            <h2><u>PRIVACY POLICY</u></h2>
            <p>
              This document is published in accordance with the provisions of Rule 4(1) of the Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011 which requires the publishing of a privacy policy for handling of or dealing in personal information including sensitive personal data or information.
                                <br />
              <br />
              This Platform is owned and operated by Techmandala Solutions LLP, a company incorporated under the provisions of the (Indian) Companies Act, 1956, having its registered office at A-101, Madhur Apt., Near Dream Land Hotel, Deewan Housing, Vasai(E), Maharashtra, India - 401208 (hereinafter referred to as "<b>Company</b>" "<b>we</b>" "<b>us</b>" or "<b>our</b>").
                                <br />
              <br />
              Any reference to "you" or "your" in this privacy policy ("<b>Privacy Policy</b>"), means you as a user of our Posible application, mobile site i.e. [.] and/or the website i.e. [.] (hereinafter collectively referred to as the "<b>Platform</b>") as an end-user/customer accessing the Platform ("<b>User</b>") for carrying out online search for Products and to buy the Products listed on the Platform. Products shall mean "products manufactured and/or distributed by the Company's partner merchants through the Platform" and the afore-mentioned services of searching and buying the Products provided by the Company on the Platform, along with other ancillary services as may be included and modified by the Company from time to time, shall together be referred to as "<b>Services</b>".
                                <br />
              <br />
              As a User, your access to and use of the Platform is conditional upon your acceptance of and continued compliance with this Privacy Policy. By using the Platform, you agree to be bound by this Privacy Policy and all rules, guidelines, policies, terms and conditions applicable to a Service being provided by the Company ("<b>Policies</b>"), as may be modified by the Company from time to time, unconditionally and at all times and further agree and confirm that you consent to providing the Company information about yourself for the purpose set out below and you understand that such collection, storage and handling is lawful, necessary and permissible under applicable laws.
                                <br />
              <br />
              Each capitalized term not defined but used in this Privacy Policy shall have the same meaning as ascribed to such term in the Terms and Conditions (defined below).
                            </p>
            <h2>1 - Requirement for collection of information </h2>
            <p>
              The information collected is relevant and necessary to understand you or your online browsing search pattern, including products, services and locations searched. The information is collected with an intention to provide you with a customized experience, provide updated information, related transaction information, provide help regarding usage of the Platform or any of our [other advertiser-focused websites] or new services and special offers we think you might be interested in, carry out follow ups, to assist with customer service or technical support issues, troubleshoot problems, measure gauge for surveys on your interest in the Products listed, enforce the Terms and Conditions and other related policies or to prevent fraud and unlawful use. It may be noted that the Company has strict guidelines with respect to unauthorized access to information provided by the User (as enumerated below).
                            </p>
            <h2>2 - Collection of Information</h2>
            <p>
              Our Privacy Policy contains provisions regarding collection, storage and handling of personal and non-personal information that you share on the Platform. These could include mandatory or voluntary disclosures that you make while and for using the Platform. Personal Information is data that can be used to uniquely identify or contact a single person ("<b>Personal Information</b>"). Personal Information for the purposes of this Privacy Policy shall include, but not be limited to, your name, address, nationality, telephone number, date of birth, gender, e-mail address, images, videos, financial information such as banking details etc. provided by you whilst using the Platform and/ or availing the Services.
                                <br />
              <br />
              In addition to the above, we also collect information in relation to the transactions undertaken by you as a User such as your account history, all billing information, payment history, transactional history, correspondents etc.
                                <br />
              <br />
              [If you submit a comment or feedback on any business featured on the Platform and/or any of the Services offered on the Platform, we may (but are not obliged to) publish your comment online or offline to promote our business, products and services. We will collect your name which will be displayed next to your comment, and e-mail address, which will not be published, but which we may use to contact you in relation to your comment. From time to time, we may ask you for your opinion on our Services. When we do surveys wherein we may use cookies or other on-device storage and may combine the information collected by those cookies or other on-device storage with your answers. We will make sure that the survey results do not identify you, or anyone else who fills in our surveys.]
                                <br />
              <br />
              By accessing, browsing and continuing to use/browse the Platform or by clicking on "Register" or "Submit" icons or other relevant icons/ buttons on the Platform, you accept the terms of this Privacy Policy, and the fact that we store and process your Personal Information in accordance with this Privacy Policy. In order to manage your orders, you may opt to register with us by creating a user account through the Platform. The account allows to manage your orders, receive special offers, provides ease in making future transactions, managing personal settings.
                                <br />
              <br />
              What we know about you we get mostly from you. But we may also have to find out more about you from other sources in order to verify the information submitted by you, and to make sure that what we know about you is correct and complete. This kind of verification is primarily done in order to address various security concerns, and to ensure that you comply with the provisions of applicable laws, and the terms and conditions available on the Platform ("<b>Terms and Conditions</b>") and other terms and conditions as may be prescribed by us from time to time. We do not collect personally identifiable information (including Personal Information) about you, expect when you specifically provide such information on a voluntary basis.
                            </p>
            <h2>3 - Usage of Information Collected</h2>
            <p>
              The Company is sensitive towards your information including your Personal Information and non-personal information (email, business details, demographic location and address, age, name, phone number etc.) provided at the time of registering with the Company or otherwise while availing the Services on the Platform.
                                <br />
              <br />
              We use information about you to help us provide you with a more personalized service which is tailored to suit your needs.
                                <br />
              <br />
              Your Personal Information may be used by us to:
                                <br />
              <b>i)</b> to complete and administer the orders placed by you through the Platform;<br />
              <b>ii)</b> administer the account created by you, if any, through the Platform;<br />
              <b>iii)</b> monitor, develop and improve the Platform, Services and your experience;<br />
              <b>iv)</b> assist in and administer the provision of Services to you;<br />
              <b>v)</b> process and deal with any complaints or enquiries made by or about you;<br />
              <b>vi)</b> detection and prevention of any fraudulent or illegal activities in accordance with applicable laws;<br />
              <b>vii)</b> investigate any suspected breach of the Terms and Conditions or the breach of other terms and conditions otherwise relating to you and to monitor compliance including by way of checking postings or submissions to the Platform.<br />
              <br />
              We may also use the information provided by you to contact you for any communication in relation to<br />
              <b>i)</b> the Products ordered;<br />
              <b>ii)</b> any incomplete transactions;<br />
              <b>iii)</b> providing reviews in relation to the Services or the Products ordered;<br />
              <b>vii)</b> any assistance required in relation to the Services or any transaction undertaken by you through the Platform.<br />
              <br />
              Where and only where requested/agreed by you, we will:<br />
              <b>i)</b> send you and keep you updated with information by email, telephone (including SMS) or mail about existing and new services and special offers from us;<br />
              <b>ii)</b> send you information by email, telephone or mail about related products or services of selected third parties that may be of interest to you; and<br />
              <b>iii)</b> use the information for marketing and for market research purposes, including internal demographic studies, to provide, optimize and personalize our services and to send you newsletters and information about the products and services.
                                <br />
              <br />
              The information is readily available on the Platform and only viewable by you by logging on to the Platform. You, at all times have the option not to provide any personal or other information about you without agreeing to register with the Company and otherwise withdraw your consent by communicating the same to us in the manner in the Terms and Conditions and the Policies. Upon withdrawal the Company will have no obligations towards you nor will it be liable for the Services and products provided till the time you had consented to retain the information with the Company. Please note that if you "do not agree" to, or "opt out" from, providing Personal Information or other information requested by us, you may not be able to access certain areas of the Platform and/or avail certain Services. However, it may be noted that the Company reserves the right to send service related and your specific and preferred transaction related communication without offering you the facility to opt-out. You can update your Account Details (defined below), Personal Information and other information at any time. At your request, the Company can also cancel your registration and remove/block your Personal Information from its database/ Platform. However, this information may remain in our archive even after deletion or termination of your account as required by law and the handling of the same is in accordance with the provisions of the applicable law and as intimated by the government agency from time to time.
                            </p>
            <h2>4 - Use of information for marketing</h2>
            <p>
              In line with any permission given by you to us, we may send you<br />
              <b>i)</b> direct marketing communications about similar services/Products;<br />
              <b>ii)</b> regular newsletters;<br />
              <b>iii)</b> customized offers of our own, or selected third party services and disclose your information to other organizations who may send direct marketing communications to you. You can change your marketing preferences if you are registered with us. In addition, you can unsubscribe from email communications via unsubscribe links provided in communications sent to you.
                                <br />
              <br />
              We may also request you to participate in market research activities undertaken by the Company, any information provided by you in this regard, will only be used by the Company with your consent.
                            </p>
            <h2>5 - Information Sharing</h2>
            <p>
              For any payments on the Platform, the Company uses online payment gateway operated by third party and the information that you provide at the time of making a purchase through the Platform is held and stored with third party payment gateway operator authorised at multiple points. The payment gateway operator may also have access to your online purchase history/details that you make through the Platform. These payment gateways are digitally encrypted, thereby providing the highest possible degree of privacy and care available, currently. Whilst we are unable to guarantee 100% (one hundred percent) security, this makes it hard for a hacker to decrypt your details. You are strongly recommended not to send full credit or debit card details in unencrypted electronic communications with us. Also, you are responsible for protecting against unauthorised access to your password and to your computer. We will not be liable for the disclosure and dissemination of Personal Information to third parties which you as prudent Users put up on the Platform and available to the other Users of the Platform.
                                <br />
              <br />
              Kindly note that we do not store extremely sensitive information like your credit card information on our database.
                                <br />
              <br />
              The Company, handles all information in accordance with the provisions of the applicable law as made applicable from time to time and only for the duration lawfully required, thereunder. The Company may disclose aggregated information, including to aggregators, about the use of the Platform without Personal Information. The Company implements standard measures to protect against unauthorized access to and unlawful interception of Personal Information. However, the Company feels prudent to inform you that no internet access can fully eliminate security risks and enforcement of privacy laws are at a developing stage in some parts of the world.
                            </p>
            <h2>6 - Storage of Information</h2>
            <p>
              The Company does not rent, sell or share your personal or sensitive information and does not disclose any of your Personal Information to third parties unless you have granted the Company the permission to do so. However, we may pass your Personal Information to other third parties in the following scenarios:<br />
              <b>i)</b> To help us process questions you may have about a Product listed on the Platform, service, registration or other inquiry you may have;<br />
              <b>ii)</b> To confirm or correct what we know about you;<br />
              <b>iii)</b> We may pass your information including Personal Information to our partner merchant for fulfillment of the orders placed by you;<br />
              <b>iv)</b> We may pass your Personal Information to our employees, contractors, suppliers and/or agents to administer the Services provided to you by us now or in the future;<br />
              <b>v)</b> To help us or law enforcement agencies prevent fraud, money laundering, terrorism and other crimes. Also, we may disclose your Personal Information to the police, regulatory bodies or legal advisers in connection with any alleged criminal offence or suspected breach of this Privacy Policy and or the breach of other terms and conditions otherwise relating to you or otherwise where required by law and we will co-operate with any law enforcement authorities or court order requesting or directing us to disclose the identity of or locate anyone breaching this Privacy Policy or otherwise for the prevention or detection of crime or the apprehension or prosecution of offenders.<br />
              <b>vi)</b> If you call our customer care call centre, we may pass your Personal Information to our call centre to allow them to deal with your call more efficiently;<br />
              <b>vii)</b> Assisting an agency in undertaking information for us;<br />
              <b>viii)</b> If another organization is engaged by us to perform tasks on our behalf;<br />
              <b>ix)</b> Compliance with an audit on our business;<br />
              <b>x)</b> If we sell all or any part of our business or merge with another company;<br />
              <b>xi)</b> If it is necessary for legal proceedings and when we believe in good faith that any applicable law, regulation, government, regulatory or other authority, agency or officer requires it or to protect the safety or security of users of the Platform;<br />
              <b>xii)</b> If we use third party processors to administer and process your Personal Information for the purposes notified in this Privacy Policy, e.g. for hosting activities related to the use of the Platform or Services; and<br />
              <b>xiii)</b> To obtain advice from third parties such as auditors or legal advisors.<br />
              <br />
              In addition to the above, disclosures may also be made if it was clearly stated at the time of registration and, where required, if you have given permission for this. Generally, we will disclose only the information we consider reasonably necessary to disclose. Also, some general statistical information about the Platform user base, traffic volumes and related matters may be passed on to reputable third parties but these details will not include information personally identifying you.
                                <br />
              <br />
              Further it may be noted that we may share your information with the associates of the Company. An associates for the purposes of this Privacy Policy shall mean a company in which the Company directly or indirectly, owns or controls, or is owned or controlled by, or is under common ownership or control with the Company; wherein ‘Own' means the beneficial ownership of or the ability to direct the voting of more than 20% (twenty percent) of the total share capital or rights to distributions on account of equity of the Company and ‘Control' shall include the right to appoint majority of the directors or to control the management or policy decisions exercisable by a person or persons acting individually or in concert, directly or indirectly, including by virtue of their shareholding or management rights or shareholders agreements or voting agreements or in any other manner.
                            </p>
            <h2>7 - Third Party Sites</h2>
            <p>
              Please note that this policy does not cover any third party websites to which we provide links in the Platform, nor does it cover advertisers. They may have their own privacy policies and/or terms and conditions of use. The Company provides third-party advertisements and only links to third party sites through the Platform ("<b>Linked Site</b>"), but does not provide any Personal Information to these third-party sites or advertisers.
                                <br />
              <br />
              The Company clarifies that the Linked Sites are operated by third parties and are not under control of or affiliation or association of the Company unless specifically expressed by the Company. The Company is not responsible for any form of transmission, material received from any Linked Site. We are not liable and do not represent anything regarding the privacy practices and policies adopted by the Linked Sites and their owners/ operators. Also, we are not in a position to control or guarantee the accuracy, integrity, or quality of the information, data, text, software, music, sound, photographs, graphics, videos, messages or other relevant materials available on the Linked Sites.
                                <br />
              <br />
              Providing the Linked Sites on the Platform does not imply or convey any endorsement by the Company in any manner or of the contents of the Linked Site. You are aware that the information you provide on the Linked Sites shall be governed by policies of the Linked Sites, which you review. The Company does not have power or authority to prevent misuse of your information through the Linked Sites or advertisers.
                                <br />
              <br />
              Linked Sites and advertisers may use technology to send the advertisements directly to your browser because of which the Linked Site or advertisers automatically receive your internet protocol address. They may use cookies, JavaScript, web beacons and other technologies to measure the effectiveness of their advertisements and to personalize advertising content. The Company has no access or control over cookies or other features of the Linked Sites or advertisers and are not governed by this Privacy Policy.
                            </p>
            <h2>8 - Security Practices</h2>
            <p>
              The Company strives to ensure the security, integrity protection and privacy of your Personal Information against unauthorized access or unauthorized alteration, disclosure or destruction. We follow stringent security techniques and requirements for handling sensitive and personal information. These techniques and requirements are fully compliant with the guidelines set forth under law. Our servers are accessible only to authorized personnel. Your information logged under this Privacy Policy is shared with respective personnel only on need to know basis and/ or to provide the services you have requested. All representatives handling information under these provisions are under contractual confidentiality obligation with the Company. While, we take every step to safeguard the confidentiality of your personal information, by using the Platform, you agree and confirm that the Company is not liable for any disclosure due to errors in transmission or unauthorized acts of third parties or Linked Sites.
                                <br />
              <br />
              Notwithstanding the foregoing, you must keep account details, including your username and password ("<b>Account Details</b>") confidential at all times, and should not reveal the same to any third parties. You must take appropriate security measures to prevent unauthorized disclosure of your Account Details (including logging out from your account at the end of each session), and you must notify us immediately if your Account Details becomes known to any unauthorized user. You are solely responsible for maintaining the confidentiality of your Account Details, and all activities that occur under your account(s). Any breach of these terms and conditions by anyone to whom you disclose your account details, including your username and password will be treated as if the breach had been committed by you, and will not relieve you of your obligations under the Terms and Conditions.
                                <br />
              <br />
              Notwithstanding anything contained herein, the Company shall not be liable for any loss, damage or misuse of your Personal Information due to a force majeure event. A force majeure event for the purposes of this Privacy Policy shall mean any event that is beyond reasonable control of the Company and shall include, without limitation, sabotage, fire, flood, explosion, acts of God, civil commotion, strikes or industrial action of any kind, riots, insurrection, war, acts of government, computer hacking, unauthorised access to computer data and storage device, computer viruses breach of security and encryption or any other cause beyond the control of the Company.
                            </p>
            <h2>9 - Acceptance to terms</h2>
            <p>
              By accessing and browsing the Platform and/or registering with the Company and using the Services you are deemed to have agreed to the terms of this Privacy Policy. This Privacy Policy should be at all times read along with the Terms and Conditions. The Policy applies to all information, including Personal Information and Account Details.
                                <br />
              <br />
              By using the Services, you agree and acknowledge that your Personal Information may be transferred across national boundaries and stored and processed in any the country around the world. You acknowledge that in the process of providing the Services through the Platform, some activities relating to handling of information collected under this Privacy Policy may be done by the Company's trusted third party vendors or agents, web hosting providers, communication services, and web analytic providers.
                            </p>
            <h2>10 - Amendments to Privacy Policy</h2>
            <p>
              We reserve the right, in our sole discretion, to change, modify, add or delete portions of this Privacy Policy at any time without notice, and it is your responsibility to review this Privacy Policy from time to time, in order to keep track and take note of the changes. We will post the latest version on the Platform. Your use of the Platform following any amendment of this Privacy Policy will signify and constitute your consent to and acceptance of such revised this Privacy Policy. You are expected to keep visiting the Platform and reading the Terms and Conditions and the Policies at regular intervals to keep yourself updated. We will not file or store a copy of these terms for each interaction on transaction by you via the Platform. We therefore recommend that you save a copy of this Privacy Policy for future reference, and ensure that such copies can be reliably verified as being effective (i.e. published on the Platform) on a particular date.
                            </p>
            <h2>11 - Contact details</h2>
            <p>
              Thank you for reading our Privacy Policy and thank you for choosing our Platform. If you have any questions about anything contained in this Privacy Policy, you can email us with any questions, queries or complaints at support@posible.in.
                            </p>
            <h2>12 - Disputes</h2>
            <p>
              Any disputes over inter alia collection, storage and handling of Personal Information will be governed by this Privacy Policy, Terms and Conditions and by the laws of India and courts of New Delhi shall have exclusive jurisdiction. For any communication under this Privacy Policy please contact the Company on support@posible.in.
                            </p>
            <h2><u>COOKIE POLICY</u></h2>
            <p>
              This Platform is owned and operated by Techmandala Solutions LLP, a company incorporated under the provisions of the (Indian) Companies Act, 1956, having its registered office at having its registered office at A-101, Madhur Apt., Near Dream Land Hotel, Deewan Housing, Vasai(E), Maharashtra, India - 401208 (hereinafter referred to as "<b>Company</b>"/ "<b>Portal</b>"/ "<b>we</b>"/ "<b>us</b>"/ "<b>our</b>").
                                <br />
              <br />
              Any reference to "you" or "your" in this cookie policy ("<b>Cookie Policy</b>"), means you as a user of POSible application, mobile site i.e. [www.posible.in] and/or the website i.e. [www.posible.in] (hereinafter collectively referred to as the "<b>Platform</b>") browsing and/or purchasing Products listed on the Platform ("<b>User</b>"); Products shall mean “electrical appliances and mobile devices manufactured by third party and listed on the Platform” ("<b>Products</b>").
                                <br />
              <br />
              As a User, your access to and use of the Platform is conditional upon your acceptance of and continued compliance with this Cookie Policy. By using the Platform, you agree to be bound by this Cookie Policy and all rules, guidelines, policies, terms and conditions applicable to the services being provided by the Company on the Platform ("<b>Policies</b>"), as may be modified by the Company from time to time.
                            </p>
            <h2>1 - Scope of Applicability</h2>
            <p>
              <b>1.1)</b> The Portal may utilize (i) a tracking pixel (such as, a smart pixel(s) or managed pixel(s)) ("<b>Pixel</b>") placed on certain page(s) of the Platform to collect relevant information from the Platform and to set a cookie on the browser of User(s) (the "<b>Cookie</b>"), (ii) browser stream data of User(s) to the Platform or (iii) other non-identifiable / non-personal information of the User (collectively, hereinafter referred to as the "<b>Targeting Data</b>"). Such Targeting Data may include, among other things (only non-identifiable information of User is collected by Platform)), IP address, date and time of visit to Platform and merchandise viewed or placed in User cart (if applicable). The Targeting Data is used to provide targeted relevant advertisements for the User(s), while the User(s) are on the Platform network and / or partner web platform (at discretion of the Company) and may be combined or matched to third party data to identify potential customers of the Company and display a targeted advertisement to such potential customers. The Company agrees that it shall not remove, modify or move the Pixel.
                                <br />
              <b>1.2)</b> By using the Platform, our servers (hosted by a third party service provider) may collect information indirectly and automatically about your activities on the Platform by way of Cookies. You hereby agree that we may combine your visitor session information or other information collected through Cookies and other technical methods with personally identifiable information in order to understand and improve your online experiences and to determine what category/nature of products you prefer or are likely to be of interest to you. This information is not linked to your personal information and is maintained distinctly.
                                <br />
              <b>1.3)</b> You may also get cookies from our advertisers. We do not control these cookies, and once you have clicked on the advertisement and left the Platform, our Cookies Policy no longer applies. The advertisers or third party sites may use cookies, java script, web beacons and other technologies to measure the effectiveness of their advertisements and to personalize advertising content. The Company has no access or control over cookies or other features of such third party sites or advertisers and are not governed by this Cookies Policy. You acknowledge that the Company is not responsible for collecting Cookies through third-party advertisements and third party sites, neither is it liable for misuse of the information you provide to such advertisements or sites.
                                <br />
              <b>1.4)</b> It is clarified that we store information only in the manner contained herein and in accordance with the provisions of applicable law. You can change your web browsers settings to accept or not to accept Cookies.
                                <br />
              <b>1.5)</b> The Company could use log file including internet protocol addresses, browser type, internet service provider, referring/exit pages, platform type, date/time stamp, and number of clicks to analyse trends, administer the Platform, track user movement and gather broad demographic information for aggregate use. These are not linked to information identifiable personally.
                                <br />
              <b>1.6)</b> The Company agrees that the Targeting Data shall only be disclosed by the Company for (i) the Platform’s performance of the services (if found appropriate by the Platform); and (ii) any other business purposes of the Platform; provided that for this subsection (ii), the Targeting Data is used and disclosed by the Platform on an aggregated-basis only (if considered appropriate by the Platform).
                            </p>
            <h2>2 - Opt-Out Option</h2>
            <p>
              <b>2.1)</b> You always have the right to opt-out for prohibiting the Platform from placing Cookie at your access device by emailing us at gaurav@posible.in. Please write "Request For Cookie Opt-Out" in the subject line of your email and include a copy of your identity card to help us prevent unauthorized individual(s) write such email(s) to us.
                                <br />
              <b>2.2)</b> If the foregoing process is followed, we will carry out the foregoing action. Additionally, should you have any clarifications regarding this Cookie Policy, then please feel free to write to us at gaurav@posible.in.
                            </p>
            <h2>3 - Amendment / Modification</h2>
            <p>
              The Company reserves the right to modify this Cookie Policy at any time with or without any further notice(s) by uploading the revised Cookie Policy on the Platform and it is the duty of the User to keep itself aware of the revisions to the Cookie Policy of the Platform.
                            </p>
            <h2>4 - Limitation Of Liability</h2>
            <p>
              THE COMPANY WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND, INCLUDING WITHOUT LIMITATION DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES, ARISING OUT OF OR IN CONNECTION WITH THE COOKIE POLICY, THE PLATFORM, THE SERVICES, THE TRANSACTION PROCESSING SERVICE, THE INABILITY TO USE THE SERVICES OR THE TRANSACTION PROCESSING SERVICE, OR THOSE RESULTING FROM ANY SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH THE SERVICES BEYOND THE VALUE OF THE LAST TRANSACTION CARRIED OUT BY THE USER THROUGH THE PLATFORM.
                            </p>
            <h2>5 - Miscellaneous</h2>
            <p>
              <b>5.1)</b> The User and the Company acknowledge and accept that electronic format shall be deemed an acceptable means of communication for the purposes of this Cookie Policy.
                                <br />
              <b>5.2)</b> This Cookie Policy constitutes the complete and entire agreement between User and the Company on the subject of ‘Cookie norms of the Platform’ and shall supersede any and all other prior understandings, commitments, representations or agreements, whether written or oral, between the User and the Company.
                                <br />
              <b>5.3)</b> If any provision of this Cookie Policy shall be found by any court or administrative body of competent jurisdiction to be invalid or unenforceable, such invalidity or enforceability shall no effect the other provisions of this Cookie Policy, which shall remain in full force an effect.
                                <br />
              <b>5.4)</b> In no event will any delay, failure or omission (in whole or in part) in enforcing, exercising or pursuing any right, power, privilege, claim or remedy conferred by or arising under this Cookie Policy or by law, be deemed to be or construed as a waiver of that or any other right, so as to bar the enforcement of that, or any other right, power privilege, claim or remedy, in any other instance at any time or times subsequently.
                            </p>
          </div>

        </div>
      </Modal>
    </div>
  }
}

export default withRouter(Register)