import firebase, { database } from '../firebase'

export function createMasters(data, key) {
    return (dispatch) => {
        return database.collection("OnBoarding").doc(key).set({
            data
        }).then(() => {
            return
        }).catch(function (error) {
            console.error("Error updating Payment Types: ", error);
        })
    }
};

function roundUpTo2(num) {
    return Math.round(Number(num) * 100) / 100
};

export function updateInvoices(){
    return (dispatch) => {
        return database.collection("Sales").where("TransactionDate",">=",new Date("25-Apr-2019")).get().then((querySnapshot) => {
            let ctr = 0;
            querySnapshot.forEach((doc) => {
                    ctr = ctr + 1
                    database.collection("Invoices").doc(doc.id).update({
                        "BackEndUpdate": true, "ManualRun" : true
                    })
                    console.log("Records Updated: " + doc.id + " Record Count: " + ctr);
            })
        }).catch((error) => {
            console.log("Error getting store list: ", error)
        })
    }
}

export function updateProducts() {
    let ctr = 0;
    return (dispatch) => {
        return database.collection("Products").where("RegistrationID", "==", "aevbWxVLO6740OK12umP").get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                database.collection("Products").doc(doc.id).update({
                    "IsDeleted": true
                }).then((key) => {
                    ctr = ctr + 1
                    console.log("Records Updated: ", ctr)
                })
            })
        }).catch((error) => {
            console.log("Error getting products list: ", error)
        })
    }
};

export function updateExpenses() {
    let ctr = 0;
    return (dispatch) => {
        return database.collection("Expenses").where("RegistrationID", "==", "jZ9hW7vItZ8q3v6pmicJ").get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                database.collection("Expenses").doc(doc.id).update({
                    "ExpenseDateTime": new Date(doc.data().ExpenseDate)
                }).then((key) => {
                    ctr = ctr + 1
                    console.log("Records Updated: ", ctr)
                })
            })
        }).catch((error) => {
            console.log("Error getting expense list: ", error)
        })
    }
};

export function createRoles(role, module, perms) {
    return (dispatch) => {
        return database.collection("Roles").doc(role).collection("aclModules").doc(module).set({
            perms
        }).then(() => {
            return
        }).catch(function (error) {
            console.error("Error updating Roles: ", error);
        })
    }
};

export function createGlobalConfigurationRoles(role, module, perms) {
    return (dispatch) => {
        return database.collection("GlobalConfigurations").doc(role).collection("aclModules").doc(module).set({
            perms
        }).then(() => {
            return
        }).catch(function (error) {
            console.error("Error updating Global Configuration roles ", error);
        })
    }
};