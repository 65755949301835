import React, { Component } from 'react'

class AddEditProductBrand extends Component {
    constructor() {
        super()
        this.state = {
            brand: '',
            key: '',
            brandError: '',
            saving: false
        }
        this.onSaveBrand = this.onSaveBrand.bind(this)
    }

    componentDidMount() {

        let brand = ''
        let key = ''
        if (this.props.productBrand) {
            brand = this.props.productBrand.Brand
            key = this.props.productBrand.key
        }
        this.setState({ brand: brand, key: key })
    }

    onSaveBrand() {
        this.setState({ saving:true})
        if (this.state.brand.trim() === "") {
            this.setState({ brandError: 'Please provide brand name', saving:false})
            return
        }
        this.props.checkIfProductBrandIsUnique(this.state.brand.toLowerCase(), this.state.key, this.props.user.user.RegistrationID).then((isUnique) => {
            // console.log('isUnique', isUnique)
            if (!isUnique) {

                this.setState({ brandError: 'Brand name already exists', saving:false})
                return

            }

            else {
                
                let productBrand = {
                    Brand: this.state.brand,
                    RegistrationID: this.props.user.user.RegistrationID, IsDeleted: false
                }
                this.props.saveProductBrand(productBrand, this.state.key).then(
                    (key) => {
                        
                        this.props.getToastr("Brand saved successfully")                        

                        setTimeout(function () { //Start the timer
                            this.setState({saving:false})
                            this.props.onSave(key)
                        }.bind(this), 2000)

                    })
            }

        })
    }

    render() {
        return <div>
            {this.state.key === "" && <h3 style={{ marginTop: "0px" }}>Add New Brand</h3>}
            {this.state.key !== "" && <h3 style={{ marginTop: "0px" }}>Edit Brand</h3>}
            <div className="box-body">
                <div className={this.state.brandError ? "form-group has-error" : "form-group "} >
                    <label>Brand</label>
                    <input type="text" className="form-control"
                        onChange={(evt) => {
                            this.setState({ brand: evt.target.value, brandError: '' })
                        }}
                        value={this.state.brand} />
                    {this.state.brandError!=="" && <span class="help-block">{this.state.brandError}</span>}
                </div>
            </div>
            <div className="box-footer">
                <button type="submit" className="btn btn-flat btn-md btn-flat btn-default" onClick={this.props.onClose} >Cancel</button>
                <button type="submit" className="btn btn-flat btn-md btn-flat btn-success pull-right" onClick={this.onSaveBrand} disabled={this.state.saving}>Save</button>
            </div>
        </div>
    }
}

export default AddEditProductBrand