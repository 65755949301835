import * as actionTypes from '../actionTypes'
import firebase, { database } from '../firebase'
import * as constants from '../Constatnts'

export function saveProductCategory(key, productCategory) {
    return (dispatch, getState) => {
        productCategory.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        productCategory.ActionBy = getState().user.user.Name
        productCategory.ActionByUID = getState().user.user.UID
        productCategory.ActionByEmailID = getState().user.user.EmailID

        if (key.trim() !== "") {
            productCategory.Action = constants.AUDIT_TRAIL_EDIT
            return database.collection("ProductCategories").doc(key).update({
                ...productCategory
            }).then(() => {
                return key
            }).catch(function (error) {
                console.error("Error updating product category: ", error);
            })
        }
        else {
            productCategory.Action = constants.AUDIT_TRAIL_NEW
            return database.collection("ProductCategories").add({
                ...productCategory
            })
                .then(function (docRef) {
                    return docRef.id
                })
                .catch(function (error) {
                    console.error("Error adding product category: ", error);
                })
        }
    }
}

export function deleteProductCategory(key) {
    return (dispatch, getState) => {
        return database.collection("ProductCategories").doc(key).update({
            IsDeleted: true,
            ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
            ActionBy: getState().user.user.Name,
            ActionByUID: getState().user.user.UID,
            ActionByEmailID: getState().user.user.EmailID,

            Action: constants.AUDIT_TRAIL_DELETE
        }).then(() => { return true })
    }
}

export function getProductCategories(registrationID) {
    return (dispatch) => {
        let searchParameters = {
            "from": 0,
            "size": 10000,
            "query": {
                "bool": {
                    "must": [
                        { "match": { "RegistrationID": registrationID } },
                        { "match": { "ParentID.keyword": "" } }
                    ]
                }
            },
            "sort": [
                {
                    "Sequence": { "order": "asc" },
                    "Name.keyword": { "order": "asc" }
                }
            ]
        }
        // console.log('searchParameters', searchParameters)
        let addMessage = firebase.functions().httpsCallable('getProductCategories');
        return addMessage({ text: searchParameters }).then(function (result) {
            const productCategories = []
            let sanitizedMessage = result.data.text;
            sanitizedMessage.hits.hits.map((data) => {
                productCategories.push({ key: data._id, ...data._source })
            })
            // console.log('productCategories',productCategories)
            dispatch({ type: actionTypes.PRODUCT_CATEGORY_GET, productCategories })
        });
    }
}

export function searchProductCategories(registrationID, from, size, option, searchText) {
    return (dispatch) => {
        let searchParameters = {
            "from": from,
            "size": size,
            "query": {
                "bool": {
                    "must": [
                        { "match": { "RegistrationID": registrationID } },
                        {"term":{"ParentID.keyword":""}}
                    ],
                    "must_not": [
                    ],
                }
            },
            "sort": [
                { "Name.keyword": { "order": "asc" } }
            ]
        }
        if (option === "active") {
            searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "true" } })
        }
        if (option === "inactive") {
            searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "false" } })
        }
        if(searchText.trim() != "")
        {
            searchParameters.query.bool.must.push({ "regexp": { "Name": "@" + searchText.trim().toLowerCase() + ".*" } })
        }
        // console.log('Product Categories - searchParameters', JSON.stringify(searchParameters));
        let addMessage = firebase.functions().httpsCallable('getProductCategories');
        return addMessage({ text: searchParameters }).then(function (result) {
            const productCategories = []
            let sanitizedMessage = result.data.text;
            sanitizedMessage.hits.hits.map((data) => {
                productCategories.push({ key: data._id, ...data._source })
            })
            return  {totalItemsCount: sanitizedMessage.hits.total, searchResult:productCategories}
        });
    }
}


export function getProductSubCategories(registrationID) {
    return (dispatch) => {
        let searchParameters = {
            "from": 0,
            "size": 10000,
            "query": {
                "bool": {
                    "must": [
                        { "match": { "RegistrationID": registrationID } }
                    ],
                    "must_not": [
                        { "match": { "ParentID.keyword": "" } }
                    ]
                }
            },
            "sort": [
                {
                    "Parent.keyword": { "order": "asc" },
                    "Sequence": { "order": "asc" },
                    "Name.keyword": { "order": "asc" }
                }
            ]
        }

        let addMessage = firebase.functions().httpsCallable('getProductCategories');
        return addMessage({ text: searchParameters }).then(function (result) {
            const productSubCategories = []
            let sanitizedMessage = result.data.text;
            sanitizedMessage.hits.hits.map((data) => {
                productSubCategories.push({ key: data._id, ...data._source })
            })
            dispatch({ type: actionTypes.PRODUCT_SUB_CATEGORY_GET, productSubCategories })
        });
    }
}

export function searchProductSubCategories(registrationID, from, size, option, searchText) {
    return (dispatch) => {
        let searchParameters = {
            "from": from,
            "size": size,
            "query": {
                "bool": {
                    "must": [
                        { "match": { "RegistrationID": registrationID } }
                    ], "must_not": [
                        { "match": { "ParentID.keyword": "" } }
                    ],
                }
            },
            "sort": [
                { "Name.keyword": { "order": "asc" } }
            ]
        }
        if (option === "active") {
            searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "true" } })
        }
        if (option === "inactive") {
            searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "false" } })
        }
        if(searchText.trim() != "")
        {
            searchParameters.query.bool.must.push({ "regexp": { "Name": "@" + searchText.trim().toLowerCase() + ".*" } })
        }
        // console.log('searchParameters', searchParameters)
        let addMessage = firebase.functions().httpsCallable('getProductCategories');
        return addMessage({ text: searchParameters }).then(function (result) {
            const productSubCategories = []
            let sanitizedMessage = result.data.text;
            sanitizedMessage.hits.hits.map((data) => {
                productSubCategories.push({ key: data._id, ...data._source })
            })
            return  {totalItemsCount: sanitizedMessage.hits.total, searchResult:productSubCategories}
        });
    }
}

export function checkIfProductCategoryIsUnique(category, categoryID, registrationID) {
    return (dispatch) => {
        let searchParameters = {
            "from": 0,
            "size": 1,
            "query": {
                "bool": {
                    "must": [
                        { "match": { "RegistrationID": registrationID } },
                        { "match": { "IsDeleted": "false" } },
                        { "match": { "Name": category } },
                        { "match": { "ParentID.keyword": "" } },

                    ],
                    "must_not": [

                    ]
                }
            }
        }
        if (categoryID !== "") {
            searchParameters.query.bool.must_not.push({ "match": { "_id": categoryID } })
        }
        // console.log('searchParameters', JSON.stringify(searchParameters))
        let addMessage = firebase.functions().httpsCallable('getProductCategories');
        return addMessage({ text: searchParameters }).then(function (result) {
            let sanitizedMessage = result.data.text
            let isUnique = true
            if (sanitizedMessage.hits.total > 0) {
                isUnique = false
            }
            return isUnique
        });
    }
}

export function checkIfProductSubCategoryIsUnique(subCategory, categoryID, subCategoryID, registrationID) {
    return (dispatch) => {
        let searchParameters = {
            "from": 0,
            "size": 1,
            "query": {
                "bool": {
                    "must": [
                        { "match": { "RegistrationID": registrationID } },
                        { "match": { "IsDeleted": "false" } },
                        { "match": { "ParentID": categoryID } },
                        { "match": { "Name": subCategory } }
                    ],
                    "must_not": [

                    ]
                }
            }
        }
        if (subCategoryID !== "") {
            searchParameters.query.bool.must_not.push({ "match": { "_id": subCategoryID } })
        }
        //console.log('searchParameters',searchParameters)
        let addMessage = firebase.functions().httpsCallable('getProductCategories');
        return addMessage({ text: searchParameters }).then(function (result) {
            let sanitizedMessage = result.data.text
            let isUnique = true
            if (sanitizedMessage.hits.total > 0) {
                isUnique = false
            }
            return isUnique
        });
    }
}


export function pushProductCategoriesToRedux(productCategory) {
    return (dispatch) => {
        dispatch({ type: actionTypes.PUSH_PRODUCT_CATEGORIES_TO_REDUX, productCategory })
    }
}

export function pushProductSubCategoriesToRedux(productSubCategory) {
    return (dispatch) => {
        dispatch({ type: actionTypes.PUSH_PRODUCT_SUB_CATEGORIES_TO_REDUX, productSubCategory })
    }
}



// export function getProductCategories(registrationID) {
//     return (dispatch) => {
//         return database.collection("ProductCategories").where("RegistrationID","==", registrationID).where("ParentID","==", "").get().then((querySnapshot) => {
//             const productCategories = []
//             querySnapshot.forEach((doc) => {
//                 productCategories.push(
//                   {
//                     key:doc.id,
//                     ...doc.data()
//                   })
//            })
//            //console.log(productCategories)
//            dispatch({type: actionTypes.PRODUCT_CATEGORY_GET,productCategories})
//         }).catch((error) => {
//             console.log(error)
//         })
//     }
// }

// export function getProductSubCategories(registrationID) {
//     return (dispatch) => {
//         return database.collection("ProductCategories").where("RegistrationID","==", registrationID).get().then((querySnapshot) => {
//             let productSubCategories = []
//             querySnapshot.forEach((doc) => {
//                 if(doc.data().ParentID !== "")
//                 {
//                     console.log(doc.data())
//                     productSubCategories.push(
//                         {
//                           key:doc.id,
//                           ...doc.data()
//                         })
//                 }        
//            })
//            //console.log(productSubCategories)
//            dispatch({type: actionTypes.PRODUCT_SUB_CATEGORY_GET,productSubCategories})
//         }).catch((error) => {
//             console.log(error)
//         })
//     }
// }

// export function checkIfProductCategoryIsUnique(category, registrationID)
// {
//     return (dispatch) => {
//         let productCategoryIsUnique = true
//             return database.collection("ProductCategories").where("RegistrationID","==", registrationID).where("Category","==",category).where("IsDeleted","==",false).limit(1).get().then((querySnapshot) => {
//                 querySnapshot.forEach((doc) => {
//                     productCategoryIsUnique = false
//                     console.log('insise loop')
//             })
//             //console.log('productCategoryIsUnique', productCategoryIsUnique)
//             return productCategoryIsUnique
//         }).catch((error) => {
//         console.log(error)
//         })
//     }
// }