import * as actionTypes from '../actionTypes';

const intialState = {
    CustomFields: [],
    TransactionTypes: [],
    ProductTaxes: [],
    TransactionTypes: [],
    ProductCategories: [],
    ProductSubCategories: [],
    ProductBrands: [],
    Products: [],
    Customers: [],
    PaymentTypes: [],
    PrintConfigs: [],
    ProductTags: [],
    Offers: [],
    Employees: [],
    Memberships: [],
    ConnectionStatus: {}
}

export default function (state = intialState, action) {
    switch (action.type) {
        case actionTypes.CONFIG_GET_CUSTOM_FIELDS:
            return { ...state, CustomFields: action.customFields }
        case actionTypes.GET_TAXES:
            return { ...state, ProductTaxes: action.productTaxes }
        case actionTypes.PRODUCT_CATEGORY_GET:
            return { ...state, ProductCategories: action.productCategories }
        case actionTypes.PRODUCT_SUB_CATEGORY_GET:
            return { ...state, ProductSubCategories: action.productSubCategories }
        case actionTypes.PRODUCT_BRANDS_GET:
            return { ...state, ProductBrands: action.productBrands }
        case actionTypes.TRANSACTION_TYPES_GET:
            return { ...state, TransactionTypes: action.transactionTypes }
        case actionTypes.GET_ACTIVE_PRODUCTS:
            return { ...state, Products: action.products }
        case actionTypes.PAYMENT_TYPES_GET:
            return { ...state, PaymentTypes: action.paymentTypes }
        case actionTypes.PRINT_CONFIG_GET:
            return { ...state, PrintConfigs: action.printConfigs }
        case actionTypes.PUSH_PRODUCT_TO_REDUX:
            let products = state.Products
            const index = state.Products.map(e => e.key).indexOf(action.product.key);
            if (index >= 0) {
                products[index] = action.product
            }
            else {
                products.push(action.product)
            }
            return { ...state, Products: products }
        case actionTypes.PUSH_CUSTOMER_TO_REDUX:
            let customers = state.Customers
            const index1 = state.Customers.map(e => e.key).indexOf(action.customer.key);
            if (index1 >= 0) {
                customers[index1] = action.customer
            }
            else {
                customers.push(action.customer)
            }
            return { ...state, Customers: customers }
        case actionTypes.PUSH_PRODUCT_CATEGORIES_TO_REDUX:
            let productCategories = state.ProductCategories
            productCategories.push(action.productCategory)
            return { ...state, ProductCategories: productCategories }
        case actionTypes.PUSH_PRODUCT_SUB_CATEGORIES_TO_REDUX:
            let productSubCategories = state.ProductSubCategories
            productSubCategories.push(action.productSubCategory)
            return { ...state, ProductSubCategories: productSubCategories }
        case actionTypes.PUSH_PRODUCT_BRAND_TO_REDUX:
            let ProductBrands = state.ProductBrands
            ProductBrands.push(action.productBrand)
            return { ...state, ProductBrands: ProductBrands }
        case actionTypes.PRODUCT_TAGS_GET:
            return { ...state, ProductTags: action.productTags }
        case actionTypes.GET_ACTIVE_OFFERS:
            return { ...state, Offers: action.offers }
        case actionTypes.GET_ACTIVE_EMPLOYEES:
            return { ...state, Employees: action.employees }
        case actionTypes.GET_ACTIVE_MEMBERSHIPS:
            return { ...state, Memberships: action.memberships }
        case actionTypes.GET_ACTIVE_CUSTOMERS:
            return { ...state, Customers: action.customers }
        case actionTypes.GET_CONNECTION_STATUS:
            return { ...state, ConnectionStatus: action.connectionStatus }
        default:
            return state;
    }
}