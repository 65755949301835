import React, { Component } from 'react';
import * as actionTypes from '../../../actionTypes'
import { withRouter } from 'react-router-dom'

class AddEditEmployee extends Component {
    constructor() {
        super()
        this.state = {
            employeeCode: '',
            employeeFirstName: '',
            employeeLastName: '',
            gender: '',
            emailID: '',
            phoneNo: '',
            storeID: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
            biometricID: '',
            customField1: '',
            customField2: '',
            customField3: '',
            customField4: '',
            customField5: '',
            customField6: '',
            employeeCodeError: false,
            employeeFirstNameError: false,
            action: '',
            key: '',
            customField1DisplayName: 'Custom Field 1',
            customField2DisplayName: 'Custom Field 2',
            customField3DisplayName: 'Custom Field 3',
            customField4DisplayName: 'Custom Field 4',
            customField5DisplayName: 'Custom Field 5',
            customField6DisplayName: 'Custom Field 6'
        }
        this.onSaveEmployee = this.onSaveEmployee.bind(this)
        this.onCancelClick = this.onCancelClick.bind(this)
    }

    componentDidMount() {
        if (this.props.location.state.action === actionTypes.EMPLOYEE_NEW) {
            this.setState({ action: this.props.location.state.action })
        }
        if (this.props.location.state.action === actionTypes.EMPLOYEE_EDIT) {
            //console.log('this.props.location.state.key', this.props.location.state.key)
            let employee = this.props.location.state.employee
            this.setState({
                action: this.props.location.state.action,
                key: this.props.location.state.key,
                employeeCode: employee.hasOwnProperty("EmployeeCode") ? employee.EmployeeCode:"",
                employeeFirstName: employee.hasOwnProperty("EmployeeFirstName") ?employee.EmployeeFirstName:"",
                employeeLastName: employee.hasOwnProperty("EmployeeLastName") ?employee.EmployeeLastName:"",
                gender: employee.hasOwnProperty("Gender") ? employee.Gender:"",
                emailID: employee.hasOwnProperty("EmailID") ? employee.EmailID:"",
                phoneNo: employee.hasOwnProperty("PhoneNo") ?employee.PhoneNo:"",
                storeID: employee.hasOwnProperty("storeID") ?employee.storeID:"",
                address1: employee.hasOwnProperty("Address1") ?employee.Address1:"",
                address2: employee.hasOwnProperty("Address2") ?employee.Address2:"",
                city: employee.hasOwnProperty("City") ?employee.City:"",
                state: employee.hasOwnProperty("State") ?employee.State:"",
                postalCode: employee.hasOwnProperty("PostalCode") ?employee.PostalCode:"",
                country: employee.hasOwnProperty(" Country") ?employee.Country:"",
                biometricID: employee.hasOwnProperty("BiometricID") ? employee.BiometricID :"",
                customField1: employee.hasOwnProperty("CustomField1") ? employee.CustomField1 :"",
                customField2: employee.hasOwnProperty("CustomField2") ?employee.CustomField2:"",
                customField3: employee.hasOwnProperty("CustomField3") ?employee.CustomField3:"",
                customField4: employee.hasOwnProperty("CustomField4") ?employee.CustomField4:"",
                customField5: employee.hasOwnProperty("CustomField5") ?employee.CustomField5:"",
                customField6: employee.hasOwnProperty("CustomField6") ? employee.CustomField6:""
            })
        }
        this.props.config.CustomFields.map((customField) => {
            if (customField.ModuleName === 'Employee') {
                let customField1DisplayName = customField.CustomField1Name.trim() === '' ? 'Custom Field 1' : customField.CustomField1Name.trim()
                let customField2DisplayName = customField.CustomField2Name.trim() === '' ? 'Custom Field 2' : customField.CustomField2Name.trim()
                let customField3DisplayName = customField.CustomField3Name.trim() === '' ? 'Custom Field 3' : customField.CustomField3Name.trim()
                let customField4DisplayName = customField.CustomField4Name.trim() === '' ? 'Custom Field 4' : customField.CustomField4Name.trim()
                let customField5DisplayName = customField.CustomField5Name.trim() === '' ? 'Custom Field 5' : customField.CustomField5Name.trim()
                let customField6DisplayName = customField.CustomField6Name.trim() === '' ? 'Custom Field 6' : customField.CustomField6Name.trim()
                this.setState({
                    customField1DisplayName: customField1DisplayName,
                    customField2DisplayName: customField2DisplayName,
                    customField3DisplayName: customField3DisplayName,
                    customField4DisplayName: customField4DisplayName,
                    customField5DisplayName: customField5DisplayName,
                    customField6DisplayName: customField6DisplayName
                })
            }
        })
    }

    onSaveEmployee() {
        let employeeCodeError = false
        let employeeFirstNameError = false

        if (this.state.employeeCode.trim() === '') { employeeCodeError = true }
        if (this.state.employeeFirstName.trim() === '') { employeeFirstNameError = true }
        if (employeeCodeError || employeeFirstNameError) {
            this.setState({ employeeCodeError: employeeCodeError, employeeFirstNameError: employeeFirstNameError })
            return
        }

        //checK if store code is unique
        //check if store name is unique
        let employee = {
            EmployeeCode: this.state.employeeCode,
            EmployeeFirstName: this.state.employeeFirstName,
            EmployeeLastName: this.state.employeeLastName,
            Gender: this.state.gender,
            EmailID: this.state.emailID,
            PhoneNo: this.state.phoneNo,
            BiometricID: this.state.biometricID,
            StoreID: this.props.user.store.key,
            Address1: this.state.address1,
            Address2: this.state.address2,
            City: this.state.city,
            State: this.state.state,
            PostalCode: this.state.postalCode,
            Country: this.state.country,
            CustomField1: this.state.customField1,
            CustomField2: this.state.customField2,
            CustomField3: this.state.customField3,
            CustomField4: this.state.customField4,
            CustomField5: this.state.customField5,
            CustomField6: this.state.customField6,
            RegistrationID: this.props.user.user.RegistrationID,
            IsDeleted: false
        }
        console.log(employee)
        if (this.state.action === actionTypes.EMPLOYEE_NEW) {
            this.props.addNewEmployee(employee).then((key) => {
                this.props.getToastr("Employee Saved Successfully")
                //redirect to store view page
                this.props.history.push({
                    pathname: "/home/settings/employees/viewemployeedetails/",
                    state: { key: key }
                })
            })
        }
        if (this.state.action === actionTypes.EMPLOYEE_EDIT) {
            console.log(JSON.stringify("employee Update", employee.key))
            this.props.updateEmployee(employee, this.state.key).then(() => {
                this.props.getToastr("Employee Saved Successfully")
                //redirect to store view page
                this.props.history.push({
                    pathname: "/home/settings/employees/viewemployeedetails/",
                    state: { key: this.state.key }
                })
            })
        }
    }

    onCancelClick() {
        if (this.state.action === actionTypes.EMPLOYEE_EDIT) {
            this.props.history.push({
                pathname: "/home/settings/employees/viewemployeedetails/",
                state: { key: this.state.key }
            })
        }
        else {
            this.props.history.push("/home/settings/employees")
        }
    }

    render() {

        return <div className="content-wrapper">
            <section className="content-header">
                <h1>{this.state.action === actionTypes.EMPLOYEE_EDIT ? "Edit Employee" : "New Employee"}</h1>
                <button type="submit"
                    style={{ marginTop: "-25px", marginLeft: "10px" }}
                    className="btn btn-md btn-success btn-flat pull-right"
                    onClick={this.onSaveEmployee}>Save</button>

                <button style={{ marginTop: "-25px", marginLeft: "10px" }}
                    onClick={this.onCancelClick}
                    className="btn btn-md btn-default btn-flat pull-right">Cancel</button>
            </section>
            <section className="content" >
                <div className="box ">
                    <div className="box-header with-border">
                        <h3 className="box-title">General details</h3>
                    </div>
                    <div className="box-body">
                        <div className="row">
                            <div className="col-md-3">
                                <div className={this.state.employeeCodeError ? "form-group has-error" : "form-group "} >
                                    <div className="form-group" >
                                        <label>Employee Code</label>
                                        <input type="text" className="form-control"
                                            onChange={(evt) => { this.setState({ employeeCode: evt.target.value, employeeCodeError: false }) }}
                                            value={this.state.employeeCode}
                                        />
                                        {this.state.employeeCodeError && <span class="help-block">Please provide employee code</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className={this.state.employeeFirstNameError ? "form-group has-error" : "form-group "} >
                                    <div className="form-group">
                                        <label>First Name</label>
                                        <input type="text" className="form-control"
                                            onChange={(evt) => { this.setState({ employeeFirstName: evt.target.value, employeeFirstNameError: false }) }}
                                            value={this.state.employeeFirstName}
                                        />
                                        {this.state.employeeFirstNameError && <span class="help-block">Please provide employee first name</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group" >
                                    <label >Last Name</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ employeeLastName: evt.target.value })}
                                        value={this.state.employeeLastName}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group" >
                                    <label >Gender</label>
                                    <select className="form-control" value={this.state.gender} onChange={(evt) => this.setState({ gender: evt.target.value })}>
                                        <option >Select gender</option>
                                        <option >Female</option>
                                        <option >Male</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <div className="form-group" >
                                    <label >Phone Number</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ phoneNo: evt.target.value })}
                                        value={this.state.phoneNo}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group" >
                                    <label>Email</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ emailID: evt.target.value })}
                                        value={this.state.emailID}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group" >
                                    <label>Biometric ID</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ biometricID: evt.target.value })}
                                        value={this.state.biometricID}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box ">
                    <div className="box-header with-border">
                        <h3 className="box-title">Address</h3>
                    </div>
                    <div className="box-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group" >
                                    <label >Line Address 1</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ address1: evt.target.value })}
                                        value={this.state.address1}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Line Address 2</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ address2: evt.target.value })}
                                        value={this.state.address2}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>City</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ city: evt.target.value })}
                                        value={this.state.city}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group" >
                                    <label >State</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ state: evt.target.value })}
                                        value={this.state.state}
                                    />
                                </div>

                            </div>

                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group" >
                                    <label >Postal Code</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ postalCode: evt.target.value })}
                                        value={this.state.postalCode}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group" >
                                    <label >Country</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ country: evt.target.value })}
                                        value={this.state.country}
                                    />
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <div className="box ">
                    <div className="box-header with-border">
                        <h3 className="box-title">Additonal Information</h3>
                    </div>
                    <div className="box-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group" >
                                    <label >{this.state.customField1DisplayName}</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ customField1: evt.target.value })}
                                        value={this.state.customField1}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>{this.state.customField2DisplayName}</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ customField2: evt.target.value })}
                                        value={this.state.customField2}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group" >
                                    <label >{this.state.customField3DisplayName}</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ customField3: evt.target.value })}
                                        value={this.state.customField3}
                                    />
                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="form-group" >
                                    <label >{this.state.customField4DisplayName}</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ customField4: evt.target.value })}
                                        value={this.state.customField4}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group" >
                                    <label >{this.state.customField5DisplayName}</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ customField5: evt.target.value })}
                                        value={this.state.customField5}
                                    />
                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="form-group" >
                                    <label >{this.state.customField6DisplayName}</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ customField6: evt.target.value })}
                                        value={this.state.customField6}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button type="submit"
                    style={{ marginBottom: "10px", marginLeft: "10px" }}
                    className="btn btn-md btn-success btn-flat pull-right"
                    onClick={this.onSaveEmployee}>Save
                                </button>
                <button style={{ marginBottom: "10px" }}
                    onClick={this.onCancelClick}
                    className="btn btn-md btn-default btn-flat  pull-right">Cancel
                                </button>
            </section>
        </div>
    }
}

export default withRouter(AddEditEmployee)