import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-responsive-modal';
import { withRouter } from 'react-router-dom';
import ImportMaster from "../ImportMaster";
import Confirm from 'react-confirm-bootstrap';

class newMembership extends Component {

    constructor() {
        super()
        this.state = {
            saving: false,
            name: '',
            discountApplied: false,
            discountNotApplied: false,
            appliedOnDiscountLevel:false,
            appliedOnRetailLevel:false,
            discount: '',
            description: '',
            nameError: '',
            optionError:'',
            discountOptionError:'',
            key: '',
            showImport: false,
            records: []
        }
        this.onSaveMemberships = this.onSaveMemberships.bind(this);
        this.onDeleteItems = this.onDeleteItems.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this)
    }

    componentDidMount() {
        const key = this.props.match.params.key;
        this.setState({ key: key });
        if (key) {
            this.props.getMembershipDetails(key).then((membership) => {
                this.setState({
                    name: membership.Name,
                    discount :membership.hasOwnProperty('Discount') ? membership.Discount : '',
                    discountApplied :membership.hasOwnProperty('DiscountApplied') ? membership.DiscountApplied : false,
                    discountNotApplied :membership.hasOwnProperty('DiscountNotApplied') ? membership.DiscountNotApplied : false,
                    description: membership.Description,
                    records: membership.Products,
                    appliedOnDiscountLevel:membership.hasOwnProperty('AppliedOnDiscountLevel') ? membership.AppliedOnDiscountLevel:'',
                    appliedOnRetailLevel:membership.hasOwnProperty('AppliedOnRetailLevel') ? membership.AppliedOnRetailLevel:'',
                })
            })
        }
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSaveMemberships()
        }
    }

    onSaveMemberships() {
        this.setState({ saving: true })
        let nameError = "", optionError="", discountOptionError=""
        if (this.state.name.trim() === "") {
            nameError = "Please provide Membership name"
        }
        if(!this.state.discountApplied && !this.state.discountNotApplied)
        {
            optionError = "Please select either of the options."
        }
        if(!this.state.discountApplied && !this.state.appliedOnDiscountLevel && !this.state.appliedOnRetailLevel)
        {
            discountOptionError = "Please select either of the options."
        }

        if (nameError != "" || optionError != "" || discountOptionError!="") {
            this.setState({ nameError: nameError, optionError: optionError, discountOptionError: discountOptionError, saving: false })
            return;
        }
        
        let membership = {
            Name: this.state.name,
            DiscountApplied: this.state.discountApplied,
            AppliedOnDiscountLevel: this.state.appliedOnDiscountLevel,
            AppliedOnRetailLevel : this.state.appliedOnRetailLevel,
            DiscountNotApplied: this.state.discountNotApplied,
            Discount: Number(this.state.discount),
            Description: this.state.description,
            RegistrationID: this.props.user.user.RegistrationID,
            Products: this.state.records,
            IsDeleted: false,
        }
        const key = this.state.key ? this.state.key : "";

        this.props.saveMembership(key, membership).then(() => {
            setTimeout(function () { //Start the timer
                this.props.getToastr("Membership saved successfully")
                this.setState({ saving: false })
                this.props.history.push('/home/membership')
            }.bind(this), 3000)
        })

    }

    onDeleteItems() {
        this.setState({ records: [] })
    }

    render() {
        const modalWidth = {
            width: '500px'
        }

        const styleEditor = {
            width: "80px", 
            height: "30px",
            font_size: "14px",
            line_height: "18px",
            border: "1px solid #dddddd",
            padding: "10px"
        }

        return <div className="content-wrapper">
            <section className="content-header">
                <h1>Membership</h1>
            </section>
            <section className="content">
                <div className="box">
                    <div className="box-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className={this.state.nameError!="" ? "form-group has-error" : "form-group "}>
                                    <label >Membership Name</label>
                                    <input type="text" className="form-control"
                                        onChange={(evt) => this.setState({ name: evt.target.value, nameError: "" })}
                                        value={this.state.name}
                                        onKeyPress={this.handleKeyPress}
                                    />
                                    {this.state.nameError !="" && <span class="help-block">{this.state.nameError}</span>}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group" >
                                    <label >Description</label>
                                    <input type="text" className="form-control" 
                                        onChange={(evt) => this.setState({ description: evt.target.value })}
                                        value={this.state.description}
                                        onKeyPress={this.handleKeyPress}
                                    >
                                    </input>
                                </div>
                            </div>

                            <div className={this.state.optionError !== "" ? "form-group col-md-6 has-error" : "form-group col-md-6"}>

                                <label >Membership Discount </label><br></br>

                                <input type="radio" name="stores" value={this.state.discountApplied} checked={this.state.discountApplied}
                                    onChange={() => { this.setState({ discountApplied: true, discountNotApplied: false,optionError:'',appliedOnDiscountLevel:false,appliedOnRetailLevel:false, records: [],discount:'' }) }} />
                                <label><strong>&nbsp;&nbsp;Overall</strong></label>&nbsp;&nbsp;&nbsp;

                                <input type="radio" name="stores" value={this.state.discountNotApplied} checked={this.state.discountNotApplied}
                                    onChange={() => { this.setState({ discountNotApplied: true, discountApplied: false,optionError:'',records: [],discount:'' }) }} />
                                <label><strong>&nbsp;&nbsp;Product Specific</strong></label>
                                {this.state.optionError !== "" && <span className="help-block">{this.state.optionError}</span>}
                            </div>

                            {this.state.discountApplied && <div className="col-md-6">
                                <div className={this.state.discountError ? "form-group has-error" : "form-group "}>
                                    <label >Overall Discount %</label>
                                    <input style={styleEditor} type="number" className="form-control" onChange={(evt) => this.setState({ discount: evt.target.value,discountError:"" })}
                                        value={this.state.discount}
                                    />
                                </div>
                            </div>}

                            {this.state.discountNotApplied && <div className={this.state.discountOptionError !== "" ? "form-group col-md-6 has-error" : "form-group col-md-6"}>
                             <label>Product Specific</label><br></br>

                             <input type="radio" name="discountlevel" value={this.state.appliedOnDiscountLevel} checked={this.state.appliedOnDiscountLevel}
                                    onChange={() => { this.setState({ appliedOnDiscountLevel: true, appliedOnRetailLevel: false,discountOptionError:'', records: []}) }} />
                                <label><strong>&nbsp;&nbsp;Discount Percentage/Amount</strong></label>&nbsp;&nbsp;&nbsp;

                            <input type="radio" name="discountlevel" value={this.state.appliedOnRetailLevel} checked={this.state.appliedOnRetailLevel}
                                    onChange={() => { this.setState({ appliedOnRetailLevel: true, appliedOnDiscountLevel: false,discountOptionError:'',records: [] }) }} />
                                <label><strong>&nbsp;&nbsp;Retail Price</strong></label>
                                {this.state.discountOptionError !== "" && <span className="help-block">{this.state.discountOptionError}</span>}

                            </div>}

                             <div className="col-md-12">
                                <div className="form-group" >
                                    {this.state.records.length > 0 &&
                                        <label >Product(s) ({this.state.records.length})&nbsp;
                                            <Confirm
                                                onConfirm={() => this.onDeleteItems()}
                                                body="Are you sure you would like to remove uploaded product(s)?"
                                                confirmText="Yes"
                                                title="Item Delete">
                                                <button className="btn btn-default btn-flat"><i class="fa fa-trash-o"></i></button>
                                            </Confirm>
                                        </label>
                                    }
                                    {this.state.records.length > 0 && <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>SKU</th>
                                                <th>Name</th>
                                                {this.state.appliedOnDiscountLevel &&<th>Discount %</th>}
                                                {this.state.appliedOnDiscountLevel &&<th>Discount Amt</th>}
                                                {this.state.appliedOnRetailLevel &&<th>Retail Price</th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.records.map((product) =>
                                                <tr>
                                                    <td>{product.SKU}</td>
                                                    <td>{product.Name}</td>
                                                    {this.state.appliedOnDiscountLevel &&<td>{product.DiscountPercentage}</td>}
                                                    {this.state.appliedOnDiscountLevel &&<td>{product.DiscountAmount}</td>}
                                                    {this.state.appliedOnRetailLevel && <td>{product.RetailPrice}</td>}
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>}
                                    {(this.state.records.length === 0 && (this.state.appliedOnDiscountLevel || this.state.appliedOnRetailLevel)) &&
                                        <button type="submit" className="btn btn-flat btn-md btn-default pull-left"
                                            onClick={() => this.setState({
                                                showImport: true
                                            })}>Upload Product(s)</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box-footer">
                        <Link to="/home/membership" type="submit" className="btn btn-flat btn-md btn-default pull-left">Cancel</Link>
                        <button type="submit" className="btn btn-flat btn-md btn-success pull-right"
                            onClick={this.onSaveMemberships} disabled={this.state.saving}>Save</button>
                    </div>
                </div>
            </section>
            <Modal open={this.state.showImport} onClose={() => this.setState({ showImport: false })} center>
                <div style={modalWidth}>
                    <ImportMaster   {...this.props}
                        importType= {this.state.appliedOnDiscountLevel ? "membershipDiscount" : "membership"} 
                        onClose={() => this.setState({ showImport: false })}
                        onImportCompletion={(records) => this.setState({ records: records, showImport: false })}
                    />
                </div>
            </Modal>
        </div>
    }
}

export default withRouter(newMembership)