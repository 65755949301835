import React, { Component } from 'react'
import Loader from '../Loader'

class UniqueNoSelection extends Component {

    constructor() {
        super()
        this.state = {
            uniqueNoList: [],
            loading: true,
            searchText: '',
            selectAll: false
        }
        this.loadList = this.loadList.bind(this)
        this.onClearSearch = this.onClearSearch.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
    }

    componentDidMount() {
        this.loadList(0)
    }

    loadList(recordFrom) {
        if (this.props.salesReturn) {
            this.props.getUniqueListForSalesReturn(recordFrom, 1000, this.props.user.store.key,
                this.props.product.key,
                this.state.searchText).then((result) => {
                    let newResult = []
                    result.map(m => {
                        if (this.props.lineItem && this.props.lineItem.hasOwnProperty('UniqueNoList')) {
                            if (this.props.lineItem.UniqueNoList.filter(f => f.key === m.key).length === 0) {
                                m.Selected = false
                                newResult.push(m)
                            }
                        }
                        else {
                            m.Selected = false
                            newResult.push(m)
                        }

                    })
                    this.setState({
                        uniqueNoList: newResult,
                        loading: false
                    })
                })
        }
        else {
            
            this.props.getInwardUniqueList(recordFrom, 1000, this.props.user.store.key,
                this.props.product.key,
                this.state.searchText).then((result) => {
                    let newResult = []
                    result.map(m => {
                        if (this.props.lineItem && this.props.lineItem.hasOwnProperty('UniqueNoList')) {
                            if (this.props.lineItem.UniqueNoList.filter(f => f.key === m.key).length === 0) {
                                m.Selected = false
                                newResult.push(m)
                            }
                        }
                        else {
                            m.Selected = false
                            newResult.push(m)
                        }
                    })
                    this.setState({
                        uniqueNoList: newResult,
                        loading: false
                    })
                })
        }
    }

    onClearSearch() {
        this.setState({ recordFrom: 0, searchText: '' })
        this.loadList(0)
    }

    onSearchClick() {
        this.setState({ searchFrom: 0 })
        this.loadList(0)
    }

    render() {
        return <div style={{ width: "700px" }}>
            <h3 style={{ marginTop: "0px" }}>{this.props.product.Name}</h3>
            <div className="box-body">
                <div className="row">
                    <div className="col-md-8">
                        <table>
                            <tr>
                                <td style={{ padding: "5px", width: "100px" }}>
                                    {this.props.product.hasOwnProperty('UniqueLabel')
                                        && this.props.product.UniqueLabel.trim() !== "" ? "Search " + this.props.product.UniqueLabel.trim() : "Search Unique No 1"}
                                </td>
                                <td style={{ padding: "5px", width: "300px" }}>
                                    <input autoFocus type="text"
                                        className="form-control input-md"
                                        value={this.state.searchText}
                                        onChange={(evt) => this.setState({ searchText: evt.target.value })} />
                                </td>
                                <td style={{ padding: "5px" }}>
                                    <button type="submit" className="btn btn-flat btn-md btn-default"
                                        onClick={this.onSearchClick}>Search</button>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                {this.state.loading && <Loader show={this.state.loading} />}
                {!this.state.loading && <table className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>
                                <input type="checkbox"
                                    checked={this.state.selectAll}
                                    onChange={() => {
                                        let uniqueNoList = this.state.uniqueNoList
                                        uniqueNoList.map((b) => {
                                            b.Selected = !this.state.selectAll
                                        })
                                        this.setState({ selectAll: !this.state.selectAll, uniqueNoList: uniqueNoList })
                                    }}
                                />
                            </th>
                            <th>{this.props.product.hasOwnProperty('UniqueLabel') && this.props.product.UniqueLabel.trim() !== "" ? this.props.product.UniqueLabel.trim() + " 1" : "Unique No 1"}</th>
                            <th>{this.props.product.hasOwnProperty('UniqueLabel') && this.props.product.UniqueLabel.trim() !== "" ? this.props.product.UniqueLabel.trim() + " 2" : "Unique No 2"}</th>
                            <th>{this.props.product.hasOwnProperty('UniqueLabel') && this.props.product.UniqueLabel.trim() !== "" ? this.props.product.UniqueLabel.trim() + " 3" : "Unique No 3"}</th>
                            <th>{this.props.product.hasOwnProperty('UniqueLabel') && this.props.product.UniqueLabel.trim() !== "" ? this.props.product.UniqueLabel.trim() + " 4" : "Unique No 4"}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.uniqueNoList.map((m, index) => <tr key={index}>
                            <td>
                                <input type="checkbox"
                                    checked={m.Selected}
                                    onChange={() => {
                                        let uniqueNoList = this.state.uniqueNoList
                                        uniqueNoList[index].Selected = !uniqueNoList[index].Selected
                                        this.setState({ uniqueNoList: uniqueNoList })
                                    }}
                                />
                            </td>
                            <td>
                                {m.UniqueNo1}
                            </td>
                            <td>
                                {m.UniqueNo2}
                            </td>
                            <td>
                                {m.UniqueNo3}
                            </td>
                            <td>
                                {m.UniqueNo4}
                            </td>
                        </tr>)}
                    </tbody>
                </table>}
                {/* {this.state.totalItemsCount > 0 && <div className="pull-left" style={{ marginTop: "25px" }}>
                                    {this.state.paginationStatus}
                    </div>}
                    <div className="pull-right">
                        <Pagination
                            activePage={this.state.activePage} //this.state.activePage
                            itemsCountPerPage={this.state.itemsCountPerPage}
                            totalItemsCount={this.state.totalItemsCount}
                            pageRangeDisplayed={2}
                            onChange={this.handlePageChange}
                        />
                    </div>         */}
            </div>
            <div className="box-footer">
                <button type="submit" className="btn btn-flat btn-md btn-default"
                    onClick={this.props.onClose}>Cancel</button>
                <button type="submit" className="btn btn-flat btn-md btn-success pull-right"
                    onClick={() => {
                        let selectedUniqueNos = this.state.uniqueNoList.filter(f => f.Selected)
                        this.props.onOK(selectedUniqueNos)
                    }}>OK</button>
            </div>
        </div>
    }
}

export default UniqueNoSelection