import React, { Component } from 'react';
import {Link } from 'react-router-dom';

class CustomerAddress extends Component{

    constructor()
    {
        super()
        this.state = {
            addressType:'',
            address1:'',
            address2:'',
            city:'',
            state:'',
            postalCode:'',
            country:'',
            newAddress:true
        }
        this.onSaveAddress  = this.onSaveAddress.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
    }

    handleKeyPress(e){
        if (e.key === 'Enter') {
            this.onSaveAddress()
          }
    }

    componentDidMount(){
        console.log('this.props.selectedAddress',this.props.selectedAddress)
        console.log('this.props.selectedAddressIndex',this.props.selectedAddressIndex)
        
        if(this.props.selectedAddress)
        {
            console.log('addess exists')
            this.setState({
                addressType:this.props.selectedAddress.AddressType,
                address1:this.props.selectedAddress.Address1,
                address2:this.props.selectedAddress.Address2,
                city:this.props.selectedAddress.City,
                state:this.props.selectedAddress.State,
                postalCode:this.props.selectedAddress.PostalCode,
                country:this.props.selectedAddress.Country,
                newAddress:false
            })
        }
        else{
            this.setState({
                addressType:"Home"})
            }
    }

    onSaveAddress()
    {
        if(this.state.address1.trim()==="" && 
        this.state.address2.trim()==="" &&
        this.state.city.trim()==="" && 
        this.state.state.trim()==="" &&
        this.state.postalCode.trim()==="" &&
        this.state.country.trim()==="")
        {
            this.setState({addressError:"Please provide address"})
            return
        }
            let address = {
            AddressType:this.state.addressType,
            Address1: this.state.address1,
            Address2:this.state.address2,
            City:this.state.city,
            State:this.state.state,
            PostalCode:this.state.postalCode,
            Country:this.state.country
        }
        this.props.onAddressAdded(address, this.props.selectedAddressIndex)
        this.props.onClose()
    }
    render (){
        return <React.Fragment>
        {this.state.newAddress && <h3 style={{ marginTop: "0px" }}>Add New Address</h3>}
        {!this.state.newAddress && <h3 style={{ marginTop: "0px" }}>Edit Address</h3>}
            <div className="box-body">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group" >
                                <label >Address Type</label>
                                <select className="form-control" 
                                onKeyPress={this.handleKeyPress}
                                value={this.state.addressType} 
                                onChange= {(evt) => this.setState({addressType:evt.target.value, addressError:""})}>
                                    <option >Home</option>
                                    <option >Work</option>
                                    <option >Other</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className={this.state.addressError ? "form-group has-error" : "form-group "} >

                                <label >Address</label>
                                <input type="text" 
                                onKeyPress={this.handleKeyPress}
                                 autoFocus={true}
                                className="form-control"  
                                onChange={(evt)=> this.setState({address1:evt.target.value, addressError:""})}
                                value={this.state.address1}
                                />
                            {this.state.addressError!=="" && <span class="help-block">{this.state.addressError}</span>}
           
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group" >
                                <label >Address</label>
                                <input type="text" className="form-control"  
                                onKeyPress={this.handleKeyPress}
                                onChange={(evt)=> this.setState({address2:evt.target.value, addressError:""})}
                                value={this.state.address2}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group" >
                                <label >City</label>
                                <input type="text" className="form-control" 
                                onKeyPress={this.handleKeyPress} 
                                onChange={(evt)=> this.setState({city:evt.target.value, addressError:""})}
                                value={this.state.city}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group" >
                                <label >Postal Code</label>
                                <input type="text" className="form-control"  
                                onKeyPress={this.handleKeyPress}
                                onChange={(evt)=> this.setState({postalCode:evt.target.value, addressError:""})}
                                value={this.state.postalCode}
                                />
                            </div>
                        </div>
                    </div>    
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group" >
                                <label >State</label>
                                <input type="text" className="form-control"  
                                onKeyPress={this.handleKeyPress}
                                onChange={(evt)=> this.setState({state:evt.target.value, addressError:""})}
                                value={this.state.state}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group" >
                                <label >Country</label>
                                <input type="text" className="form-control"  
                                onKeyPress={this.handleKeyPress}
                                onChange={(evt)=> this.setState({country:evt.target.value, addressError:""})}
                                value={this.state.country}
                                />
                            </div>
                        </div>
                    </div>
            </div>
            <div className="box-footer">
                        <button type="submit" 
                        className="btn btn-flat btn-default" 
                        onClick={this.props.onClose} >Cancel</button>
                        <button type="submit" 
                        className="btn btn-success btn-flat pull-right"
                        onClick={this.onSaveAddress}>Save</button>
            </div>
        </React.Fragment>
    }
}

export default CustomerAddress