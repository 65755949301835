import React,{Component} from 'react'

class SalesModifiers extends Component{

    constructor(props)
    {
      super(props)
      this.state = {
        selectedModifierGroupID:"",
        modifierGroups:[],
        modifiers:[],
        selectedModifiers:[],
        qty:0,
        amount:0
      }
      this.removeSelectedModifier= this.removeSelectedModifier.bind(this)
      this.addSelectedModifier = this.addSelectedModifier.bind(this)
      this.calculateTotal =this.calculateTotal.bind(this)
    }

    async componentDidMount()
    {
        let modifierGroups = await this.props.getModifierGroups(this.props.user.user.RegistrationID)
        console.log('modifierGroups',modifierGroups)
    //     modifiers.map(m=>{
    //         if(modifierGroups.filter(f=> f.ModifierGroupID === m.ModifierGroupID && !m.IsDeleted).length===0)
    //         {
    //             modifierGroups.push({ModifierGroupID:m.ModifierGroupID,
    //                 ModifierGroup:m.ModifierGroup
    //             })
    //         }
    //     })   
    //    this.setState({modifiers:modifiers, 
    //     modifierGroups:modifierGroups})
    }

    addSelectedModifier(selectedModifier){
        let selectedModifiers = this.state.selectedModifiers
        selectedModifiers.push(selectedModifier)
        this.calculateTotal(selectedModifiers)
    }

    removeSelectedModifier(index){
        let selectedModifiers = this.state.selectedModifiers
        selectedModifiers.splice(index,1)
        this.calculateTotal(selectedModifiers)
    }

    calculateTotal(selectedModifiers){
        let qty = 0
        let amount = 0 
        selectedModifiers.map(m=>{
            qty = qty+1
            amount = amount + Number(m.ModifierPrice)
        })
        this.setState({qty:qty,
        amount:amount,
        selectedModifiers:selectedModifiers
        })
    }

    render(){  
        return <div style={{width:"800px", height:"400px"}}> 
        <h3 style={{ marginTop: "0px" }}>Modifier(s)</h3>
        <div className="box-body">
            <div className="row">
                <div className="col-md-3" style1={{ padding: "5px" }}>
                    {this.state.modifierGroups.map((m,index)=><button key={index}
                                            type="submit" 
                                            className= {m.ModifierGroupID === this.state.selectedModifierGroupID ?   "btn btn-lg btn-block btn-success btn-flat"  : "btn btn-lg btn-block btn-default btn-flat" }
                                            onClick={()=> this.setState({selectedModifierGroupID:m.ModifierGroupID})}
                                            >{m.ModifierGroup}</button>)}
                </div>
                <div className="col-md-3" style1={{ padding: "5px" }}>
                {this.state.modifiers && this.state.modifiers.filter(f=> f.ModifierGroupID === this.state.selectedModifierGroupID).map((m,index)=><button key={index}
                                            type="submit" 
                                            className="btn btn-lg btn-block btn-default btn-flat" 
                                            onClick={()=> this.addSelectedModifier(m)}
                                            >{m.ModifierName}</button>)}
                </div>
                <div className="col-md-6" style1={{ padding: "5px" }}>
                {this.state.selectedModifiers && this.state.selectedModifiers.map((m,index)=><div className="input-group">
                                            <button key={index}
                                                type="submit" 
                                                className="btn btn-lg btn-block btn-default btn-flat" 
                                                >{m.ModifierName}
                                            </button>
                                            <div className="input-group-btn">
                                                <button 
                                                type="button" onClick={() => this.removeSelectedModifier(index)}
                                                    className="btn btn-default btn-lg btn-flat">
                                                    <i className="glyphicon glyphicon-remove"></i>
                                                </button> 
                                            </div>
                                            </div>)}
                </div>
            </div>
        </div>
        <div className="box-footer">
            <button type="submit" className="btn btn-flat btn-md btn-flat btn-default" onClick={this.props.onClose} >Cancel</button>
            <button type="submit" className="btn btn-flat btn-md btn-flat btn-success pull-right" onClick={()=> this.props.onSave(this.state.consumptionDetails)} disabled={this.state.saving}>Save</button>
        </div>
    </div>
    }
}
export default SalesModifiers