import React, { Component } from 'react';

class CustomerDate extends Component {
    constructor(props) {
        super(props)
        this.state = {Day:'',
                    Month:'',
                    Year:''}
        this.onDayChange= this.onDayChange.bind(this)
        this.onMonthChange = this.onMonthChange.bind(this)
        this.onYearChange = this.onYearChange.bind(this)   
    }

    componentDidMount()
    {
        // console.log('this.props.customerDate', this.props.customerDate)
        if(!this.props.customerDate)
        {
            return
        }
        this.setState({Day: this.props.customerDate.Day,
        Month:this.props.customerDate.Month,
        Year:this.props.customerDate.Year})
    }
    onDayChange(Day)
    {
        this.setState({Day:Day})
        let date = {Day:Day, Month:this.state.Month, Year:this.state.Year}
        this.props.onChange(date)
    }

    onMonthChange(Month)
    {
        this.setState({Month:Month})
        let date = {Day:this.state.Day, Month:Month, Year:this.state.Year}
        this.props.onChange(date)
    }

    onYearChange(Year)
    {
        this.setState({Year:Year})
        let date = {Day:this.state.Day, Month:this.state.Month, Year:Year}
        this.props.onChange(date)
    }

    render(){
        return <div className="row">
            <div className="col-md-4" style={{paddingRight:"0px"}}>
                <input type="number"
                 onChange={(evt) => this.onDayChange(evt.target.value)}
                 value={this.props.customerDate.Day} 
                className="form-control"></input>
            </div>
            <div style={{padding:"0px"}} className="col-md-4" >
                <select 
                className="form-control" 
                onChange={(evt) => this.onMonthChange(evt.target.value)}
                value={this.props.customerDate.Month} >
                    <option ></option>
                    <option >Jan</option>
                    <option >Feb</option>
                    <option >Mar</option>
                    <option >Apr</option>
                    <option >May</option>
                    <option >Jun</option>
                    <option >Jul</option>
                    <option >Aug</option>
                    <option >Sep</option>
                    <option >Oct</option>
                    <option >Nov</option>
                    <option >Dec</option>
            </select></div>
            <div className="col-md-4" style={{paddingLeft:"0px"}}>
                <input type="number" 
                className="form-control" 
                onChange={(evt) => this.onYearChange(evt.target.value)}
                value={this.props.customerDate.Year} >
                </input>
            </div>
        </div>

    }
}

export default CustomerDate
