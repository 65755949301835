import { database } from '../firebase'


export function getAuditTrails(registrationID, collectionName,key) {
    return (dispatch) => {
        // console.log(registrationID)
        // console.log(collectionName)
        // console.log(key)

        return database.collection(collectionName).where("RegistrationID", "==", registrationID).where("DocKey", "==", key).orderBy("ActionOn","desc").get().then((querySnapshot) => {
            let auditTrails= []
            querySnapshot.forEach((doc) => {
                auditTrails.push( {...doc.data() })
            })
            return auditTrails
        })
        
    }
}

