import React,{Component} from 'react'
var AsyncTypeahead = require('react-bootstrap-typeahead').AsyncTypeahead;

class ProductConsumption extends Component{

    constructor(props)
    {
      super(props)
      this.state = {
        isProductTypeAheadLoading:false,
        productsTypeAheadOptions:[],
        consumptionDetails:[]

      }
      this.onProuctTypeAheadSearch = this.onProuctTypeAheadSearch.bind(this)
      this.onProductSelected = this.onProductSelected.bind(this)
      this.onRemoveProduct = this.onRemoveProduct.bind(this)
      this.onQtyChange= this.onQtyChange.bind(this)
      
    }

    componentDidMount()
    {
       // console.log(this.props.lineItem)
        //this.setState({consumptionDetails: this.props.lineItem.hasOwnProperty('ConsumptionDetails') ? this.props.lineItem.ConsumptionDetails: []})
        if(this.props.lineItem.ConsumptionDetails)
        {
            this.setState({consumptionDetails:this.props.lineItem.ConsumptionDetails})
        }

    }

    onProuctTypeAheadSearch = (query) => {

        this.setState({ isProductTypeAheadLoading: true });
        this.props.getProducts(this.props.user.user.RegistrationID, 0, 10, "active", query, "", "", "",
            "", "", "")
            .then((result) => {
                this.setState({ productsTypeAheadOptions: result.searchResult,isProductTypeAheadLoading: false  })
            })
    }

    onProductSelected(product) {
        //console.log('product', product)
        let lineItem = {ProductID: product.key,
        Name: product.Name,
        SKU:product.SKU,
        Qty:0
        }
        //console.log('lineItem', lineItem)
        let consumptionDetails = this.state.consumptionDetails
        consumptionDetails.push(lineItem)
        this.setState({consumptionDetails:consumptionDetails})
    }

    onRemoveProduct(index)
    {
        let consumptionDetails = this.state.consumptionDetails
        consumptionDetails.splice(index, 1)
        this.setState({consumptionDetails:consumptionDetails})
    }

    onQtyChange(qty, index){
        let consumptionDetails = this.state.consumptionDetails
        consumptionDetails[index].Qty = qty
        this.setState({consumptionDetails:consumptionDetails})
    }

    render(){
        
        return <div style={{width:"600px"}}> 
        <h3 style={{ marginTop: "0px" }}>Product Consumption</h3>
        <div className="box-body">
            <div className="form-group" >
                <label><b>{this.props.lineItem.Product.SKU} - {this.props.lineItem.Product.Name}</b></label>
            </div>
            <div className="form-group" >
                <label>Select product</label>
                <AsyncTypeahead
                    autoFocus={true}
                    allowNew={false}
                    isLoading={this.state.isProductTypeAheadLoading}
                    multiple={false}
                    options={this.state.productsTypeAheadOptions}
                    labelKey="Name"
                    minLength={3}
                    onSearch={this.onProuctTypeAheadSearch}
                    placeholder="Start typing to search products"
                    filterBy={['Name', 'SKU']}
                    // bsSize={'large'}
                    autoFocus={true}
                    dropup={false}
                    highlightOnlyResult={true}
                    selected={this.state.productsTypeAheadSelected}
                    onChange={(selected) => {
                        this.onProductSelected(selected[0])
                        this.setState({ productsTypeAheadSelected: [] })
                    }}
                    renderMenuItemChildren={(option, props) => (
                        <div>
                            <div><b>{option.Name}</b></div>
                            <div>{option.SKU}</div>
                        </div>
                    )}
                />
            </div>
            
            <table className = "table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th style={{width:"60%"}}>Select Product</th>
                            <th>Enter Qty </th>
                            <th style={{width:"60px"}}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.consumptionDetails.map((m,index)=>  <tr>
                            <td>
                                <div>{m.SKU} - {m.Name}</div>
                            </td>
                            <td>
                                <input type="number"
                                    className="form-control"
                                    onChange={(evt) => this.onQtyChange(evt.target.value,index)}
                                    value={m.Qty}
                                />
                            </td>
                            <td>
                                <div className="input-group-btn">
                                <button type="button" className="btn btn-default btn-md" 
                                onClick={() => this.onRemoveProduct(index)}>
                                <i className="glyphicon glyphicon-remove"></i>
                                </button>
                                </div>
                            </td>
                        </tr>)}
                    </tbody>
                </table>
        </div>
        <div className="box-footer">
            <button type="submit" className="btn btn-flat btn-md btn-flat btn-default" onClick={this.props.onClose} >Cancel</button>
            <button type="submit" className="btn btn-flat btn-md btn-flat btn-success pull-right" onClick={()=> this.props.onSave(this.state.consumptionDetails)} disabled={this.state.saving}>Save</button>
        </div>
    </div>
    }
}
export default ProductConsumption