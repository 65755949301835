import firebase, { database } from '../firebase'
import * as actionTypes from '../actionTypes'
import * as constants from '../Constatnts'

export function addNewProduct(prod) {
  return (dispatch, getState) => {
    
    let newProductDoc = database.collection("Products").doc();

    prod.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
    prod.ActionBy = getState().user.user.Name
    prod.ActionByUID = getState().user.user.UID
    prod.ActionByEmailID = getState().user.user.EmailID
    prod.Action = constants.AUDIT_TRAIL_NEW

    newProductDoc.set(prod);
    return newProductDoc.id;

  }
}

export function updateProductImage(productKey, imagePath, imageName, imageSize) {
  return (dispatch) => {
    return database.collection("Products").doc(productKey).update({
      Images: firebase.firestore.FieldValue.arrayUnion({ ImagePath: imagePath, ImageName: imageName, ImageSize: imageSize })
    })
  }
}

export function getProducts(RegistrationID, from, size, option, searchText, searchCategory, searchSubCategory, searchBrand,
  searchTags, searchDescription, searchHSNSAC) {
  let searchParameters = {
    "from": from,
    "size": size,
    "query": {
      "bool": {
        "must": [
          { "match": { "RegistrationID": RegistrationID } }
        ],
        "must_not": [
        ],
        "should": [

        ],
        "minimum_should_match": 1,
        "filter": {
          "bool": {
            "must": [

            ]
          }
        }
      }
    },
    "sort": [
      {
        "Name": {
          "order": "asc"
        }
      }
    ]
  }
  if (option === "active") {
    searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "true" } })
  }
  if (option === "inactive") {
    searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "false" } })
  }
  searchParameters.query.bool.should.push({ "regexp": { "Name": "@" + searchText.trim().toLowerCase() + ".*" } })
  searchParameters.query.bool.should.push({ "regexp": { "SKU": "@" + searchText.trim().toLowerCase() + ".*" } })
  searchParameters.query.bool.should.push({ "regexp": { "BarCode": "@" + searchText.trim().toLowerCase() + ".*" } })

  if (searchCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Category": "@" + searchCategory.trim().toLowerCase() + ".*" } })
  }
  if (searchSubCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "SubCategory": "@" + searchSubCategory.trim().toLowerCase() + ".*" } })
  }
  if (searchBrand.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Brand": "@" + searchBrand.trim().toLowerCase() + ".*" } })
  }
  if (searchTags.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Tags": "@" + searchTags.trim().toLowerCase() + ".*" } })
  }
  if (searchBrand.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Description": "@" + searchDescription.trim().toLowerCase() + ".*" } })
  }
  if (searchHSNSAC.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "HSNSACCode": "@" + searchHSNSAC.trim().toLowerCase() + ".*" } })
  }
  return (dispatch , getState) => {

      let addMessage = firebase.functions().httpsCallable('getProducts');
      // console.log(JSON.stringify(searchParameters))

      return addMessage({ text: searchParameters }).then(function (result) {
        const products = []
        let sanitizedMessage = result.data.text;
        sanitizedMessage.hits.hits.map((data, index) => {
          products.push({ key: data._id, ...data._source })
        })
        return { totalItemsCount: sanitizedMessage.hits.total, searchResult: products }
      });
    }
}

export function getProductsForInventorySearch(RegistrationID, searchText, excludeUniqueNo) {
  let searchParameters = {
    "from": 0,
    "size": 1000,
    "query": {
      "bool": {
        "must": [
          { "match": { "RegistrationID": RegistrationID } },
          { "match": { "IsDeleted": "false" } },
        ],
        "should": [
          {
            "term": {
              "ProductType": "goods for sale"
            }
          },
          {
            "term": {
              "ProductType": "goods not for sale"
            }
          }
        ], "minimum_should_match": 1,
        "filter": {
          "bool": {
            "must": [

            ]
          }
        }
      }
    },
    "sort": [
      {
        "Name": {
          "order": "asc"
        }
      }
    ]
  }
  searchParameters.query.bool.filter.bool.must.push({ "match": { "ProductDetails": searchText.trim() } })
  // console.log(JSON.stringify(searchParameters));
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable('getProducts');
    return addMessage({ text: searchParameters }).then(function (result) {
      const products = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        if (data._source.hasOwnProperty('InventoryType')) {
          if (data._source.InventoryType === "Unique No" && excludeUniqueNo) {
            ///DONT PUSH
          }
          else {
            products.push({ key: data._id, ...data._source })
          }
        }
        else {
          products.push({ key: data._id, ...data._source })
        }

      })
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: products }
    });
  }
}

export function deleteProduct(key) {
  return (dispatch, getState) => {
    return database.collection("Products").doc(key).update({
      IsDeleted: true,
      ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
      ActionBy: getState().user.user.Name,
      ActionByUID: getState().user.user.UID,
      ActionByEmailID: getState().user.user.EmailID,

      Action: constants.AUDIT_TRAIL_DELETE
    }).then(() => { return true })
  }
}

export function getProductDetails(key) {
  return (dispatch) => {
    return database.collection("Products").doc(key).get().then((doc) => {
      if (doc.exists) {
        // console.log("Product found", doc.data());
        return doc.data()
      } else {
        console.log("No such product!");
      }
    })
  }
}

export function updateProduct(product, key) {
  return (dispatch, getState) => {
    let ActionOn = firebase.firestore.FieldValue.serverTimestamp()
    let ActionBy = getState().user.user.Name
    let ActionByUID = getState().user.user.UID
    let ActionByEmailID = getState().user.user.EmailID
    let Action = constants.AUDIT_TRAIL_EDIT
    return database.collection("Products").doc(key).update({
      ...product,
      ActionOn: ActionOn,
      ActionBy: ActionBy,
      ActionByUID: ActionByUID,
      ActionByEmailID: ActionByEmailID,
      Action: Action
    })
  }
}

export function pushProductToRedux(product) {
  return (dispatch) => {
    dispatch({ type: actionTypes.PUSH_PRODUCT_TO_REDUX, product })
  }
}

export function getProductsByBarCode(RegistrationID, from, size, searchText) {
  let searchParameters = {
    "from": from,
    "size": size,
    "query": {
      "bool": {
        "must": [
          { "match": { "RegistrationID": RegistrationID } },
          { "match": { "IsDeleted": "false" } },
        ],
        "should": [], "minimum_should_match": 1
      }
    },
    "sort": [
      {
        "ActionOn": {
          "order": "desc"
        }
      }
    ]
  }
  searchParameters.query.bool.should.push({ "match_phrase": { "SKU": searchText.trim() } })
  searchParameters.query.bool.should.push({ "match_phrase": { "BarCode": searchText.trim() } })
  // console.log("getProductsByBarCode", JSON.stringify(searchParameters))
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable('getProducts');
    return addMessage({ text: searchParameters }).then(function (result) {
      const products = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        products.push({ key: data._id, ...data._source })
      })
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: products }
    });
  }
}

export function pushProductsToRedux(products) {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_ACTIVE_PRODUCTS, products })
  }
}

export function getActiveProducts(RegistrationID, scrollID) {
  let searchParameters = null
  if (scrollID == "") {
    searchParameters = {
      "from": 0,
      "size": 2000,
      "query": {
        "bool": {
          "must": [
            { "match": { "RegistrationID": RegistrationID } }
          ],
          "must_not": [
            { "match": { "IsDeleted": "true" } }
          ]
        }
      },
      "sort": [
        {
          "Name": {
            "order": "asc"
          }
        }
      ]
    }
  }
  else {
    searchParameters = {
      "scroll": "1m",
      "scroll_id": scrollID
    }
  }

  return (dispatch) => {
    let addMessage = ''
    if (scrollID === "") {
      addMessage = firebase.functions().httpsCallable('getProductsWithScrollID');
    }
    else {
      addMessage = firebase.functions().httpsCallable('getProductsForScrollID');
    }

    return addMessage({ text: searchParameters }).then(function (result) {
      const products = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        products.push({ key: data._id, ...data._source })
      })
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: products, scrollID: sanitizedMessage._scroll_id }
    });
    
  }
}

// export function getActiveProducts(registrationID) {
//   return (dispatch,getState) => {
//     return database.collection("Products").where("RegistrationID", "==", registrationID).where("IsDeleted", "==", false).get().then((querySnapshot) => {
//       let products = []
//       querySnapshot.forEach((doc) => {

//         let product = doc.data();
//         product.ActionOn = null
//         if(product.hasOwnProperty('AccessibleToSelectedStores') && product.AccessibleToSelectedStores)
//         {
//           if (product.StoreSpecificPrices.filter(f => f.StoreID === getState().user.store.key).length > 0)
//             products.push({ key: doc.id, ...product })
//         }
//         else
//         {
//           products.push({ key: doc.id, ...product })
//         }
//       });
//       dispatch({ type: actionTypes.GET_ACTIVE_PRODUCTS, products })
//       return products;
//     });
//   }
// };