import React, { Component } from 'react'
import * as actionTypes from '../../actionTypes'
import Modal from 'react-responsive-modal'
import VendorSearch from './VendorSearch'
import Product from '../Catalogue/Product'
import Print from '../Sales/Print'
import ImportMaster from "../ImportMaster"
import UniqueNoInput from './UniqueNoInput'
import UniqueNoSelection from './UniqueNoSelection'
import Loader from '../Loader'

var AsyncTypeahead = require('react-bootstrap-typeahead').AsyncTypeahead;

class Inventory extends Component {

    constructor() {
        super()
        this.state = {
            key: '',
            transactionNo: '',
            transactionDate: new Date().toISOString().split('T')[0],
            transactionType: null,
            notes: '',
            lineItems: [],
            billQty: 0,
            billSubTotal: 0,
            billAmount: 0,
            billTaxAmount: 0,
            billDiscount: 0,
            taxSummaryList: [],
            showLineItemModal: false,
            selectedLineItem: null,
            selectedLineItemIndex: 0,
            placeOfSupply: '',
            refNo: '',
            selectedVendor: null,
            productsTypeAheadOptions: [],
            isProductTypeAheadLoading: false,
            productsTypeAheadSelected: [],
            showAddProduct: false,
            showInventory: true,
            saving: false,
            vendorError: '',
            noLineItemExistError: '',
            storeLocationError: '',
            parentTransactionNo: '',
            parentTransactionKey: '',
            editTransactionNo: '',
            editTransactionDate: null,
            showInventory: true,
            showAddProduct: false,
            storeLocations: [],
            storeLocationID: '',
            storeLocation: '',
            transactionDateError: '',
            toStoreError: '',
            toStoreID: '',
            toStore: '',
            toStoreList: [],
            print: false,
            printChecked: false,
            sendEmailChecked: false,
            sendSMSChecked: false,
            printData: null,
            selectedPringConfigID: '',
            selectedPrintConfigPrintDisplayName: '',
            showImport: false,
            showUniqueNoInput:false,
            showUniqueNoSelection:false,
            selectedProduct:null,
            toggleSearch: false
        }
        this.onProductSelected = this.onProductSelected.bind(this)
        this.onProductTypeAheadSearch = this.onProductTypeAheadSearch.bind(this)
        this.onLineItemChange = this.onLineItemChange.bind(this)
        this.roundUpTo2 = this.roundUpTo2.bind(this)
        this.onLineItemPriceChange = this.onLineItemPriceChange.bind(this)
        this.onLineItemPriceChange = this.onLineItemPriceChange.bind(this)
        this.onRemoveLineItem = this.onRemoveLineItem.bind(this)
        this.onLineItemChangesDone = this.onLineItemChangesDone.bind(this)
        this.onSelectedLineItemQtyChange = this.onSelectedLineItemQtyChange.bind(this)
        this.onSelectedLineItemPriceChange = this.onSelectedLineItemPriceChange.bind(this)
        this.onSelectedLineItemDiscountChange = this.onSelectedLineItemDiscountChange.bind(this)
        this.onSelectedLineItemDiscountAmountChange = this.onSelectedLineItemDiscountAmountChange.bind(this)
        this.onSelectedLineItemChargeTaxOnProductChange = this.onSelectedLineItemChargeTaxOnProductChange.bind(this)
        this.onSelectedLineItemTaxIncluxiveChange = this.onSelectedLineItemTaxIncluxiveChange.bind(this)
        this.onSelectedLineItemNotesChange = this.onSelectedLineItemNotesChange.bind(this)
        this.onLineItemSelected = this.onLineItemSelected.bind(this)
        this.onSelectedLineItemTaxGroupChange = this.onSelectedLineItemTaxGroupChange.bind(this)
        this.onSave = this.onSave.bind(this)
        this.onCancel = this.onCancel.bind(this)
        this.getTransactioNo = this.getTransactioNo.bind(this)
        this.printFormatting = this.printFormatting.bind(this)
        this.onImportCompletion = this.onImportCompletion.bind(this)
        this.productSelected = this.productSelected.bind(this)
        this.onProductBarCodeSearch = this.onProductBarCodeSearch.bind(this)
    }

   async componentDidMount() {

        let selectedPringConfigID = ""
        let printConfig = this.props.config.PrintConfigs.filter((e) => !e.IsDeleted
            && e.TransactionTypeID === this.props.location.state.transactionType.key)
        if (printConfig.length > 0) {
            selectedPringConfigID = printConfig[0].key
            // selectedPrintConfigPrintDisplayName =printConfig[0].PrintDisplayName
        }
        await this.props.getStoreLocations(this.props.user.store.key).then((storeLocations) => {
            this.setState({ storeLocations: storeLocations, storeLocationID: storeLocations[0].key, storeLocation:storeLocations[0].LocationCode + ' ' + storeLocations[0].LocationName })
        })
        this.props.getStores(this.props.user.user.RegistrationID, 0, 10000, "active", "", "", "", "", "", "", "", "", "", "", "").then((result) => {
            let storeList = result.searchResult.filter((s) => s.key !== this.props.user.store.key)
            this.setState({ toStoreList: storeList })
        })
        if (this.props.location.state.type === actionTypes.INVENTORY_NEW) {
            // console.log('this.props.location.state.transactionType',this.props.location.state.transactionType)
            if (this.props.location.state.transactionType.TransactionType.toLowerCase() === "purchase return") {
                // console.log('this.props.location.state.inventory', this.props.location.state.inventory)
                if (this.props.location.state.inventory) {
                    let inventory = this.props.location.state.inventory
                    this.setState({
                        lineItems: inventory.LineItems,
                        billQty: inventory.BillQty,
                        billSubTotal: inventory.BillSubTotal,
                        billAmount: inventory.BillAmount,
                        billTaxAmount: inventory.BillTaxAmount,
                        billDiscount: inventory.BillDiscount,
                        taxSummaryList: inventory.TaxSummaryList,
                        placeOfSupply: inventory.PlaceOfSupply,
                        selectedVendor: inventory.Vendor
                    })
                }
            }
            // console.log('this.props.location.state.transactionType',this.props.location.state.transactionType)
            this.setState({
                key: "",
                transactionType: this.props.location.state.transactionType,
                inventory: null, selectedPringConfigID: selectedPringConfigID,
                transactionDate: new Date().toISOString().split('T')[0]
            })
        }
        else if (this.props.location.state.type === actionTypes.INVENTORY_EDIT) {
            let inventory = this.props.location.state.inventory

            this.setState({loading:true, 
                key: this.props.location.state.key,
                transactionType: inventory.TransactionType,
                notes: inventory.Notes,
                billQty: inventory.BillQty,
                billSubTotal: inventory.BillSubTotal,
                billAmount: inventory.BillAmount,
                billTaxAmount: inventory.BillTaxAmount,
                billDiscount: inventory.BillDiscount,
                taxSummaryList: inventory.TaxSummaryList,
                placeOfSupply: inventory.PlaceOfSupply,
                transactionNo: inventory.TransactionNo,
                refNo: inventory.hasOwnProperty("RefNo") ? inventory.RefNo : "",
                selectedVendor: inventory.Vendor,
                storeLocationID: inventory.hasOwnProperty("StoreLocationID") ? inventory.StoreLocationID : "",
                storeLocation: inventory.hasOwnProperty("StoreLocation") ? inventory.StoreLocation : "",
                toStoreID: inventory.hasOwnProperty("ToStoreID") ? inventory.ToStoreID : "",
                toStore: inventory.hasOwnProperty("ToStore") ? inventory.ToStore : ""
            });

            for (let index = 0; index < inventory.LineItems.length; index++) {
                const lineItem = inventory.LineItems[index];
                let inStockQty = await this.props.getProductInStockQty(this.props.user.store.key,this.state.storeLocationID,lineItem.Product.key);
                inventory.LineItems[index].InStockQty = inStockQty;
            }
            
            this.setState({
                lineItems: inventory.LineItems,
                loading:false
            })
        }
    }

    roundUpTo2(num) {
        return Math.round(Number(num) * 100) / 100
    }
    onProductBarCodeSearch(e) {
        
        if (e.key === 'Enter') {
        // this.setState({ isProductTypeAheadLoading: true });
        this.props.getProductsByBarCode(this.props.user.user.RegistrationID, 0, 100, this.state.queryText)
            .then((result) => {
                console.log("onProductBarCodeSearch", JSON.stringify(result.searchResult));

                let e = result.searchResult[0]

                if (e) {
                    if (e.hasOwnProperty('AccessibleToSelectedStores')) {
                        if (e.AccessibleToSelectedStores) {
                            let storePrice = e.StoreSpecificPrices.filter(f => f.StoreID === this.props.user.store.key)
                            if (storePrice.length > 0) {
                                e.RetailPrice = storePrice[0].RetailPrice
                                e.SupplyPrice = storePrice[0].SupplyPrice
                                e.Markup = storePrice[0].Markup
                                e.MRP = storePrice[0].MRP
                                e.DiscountAmount = storePrice[0].hasOwnProperty('DiscountAmount') ? storePrice[0].DiscountAmount : 0
                                e.DiscountPercentage = storePrice[0].hasOwnProperty('DiscountPercentage') ? storePrice[0].DiscountPercentage : 0
                                e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty('WalletTopUpSameAsRetailPrice') ? storePrice[0].WalletTopUpSameAsRetailPrice : false
                                e.WalletTopUpAmount = storePrice[0].hasOwnProperty('WalletTopUpAmount') ? storePrice[0].WalletTopUpAmount : 0
                                e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty('EditWalletTopUpAmount') ? storePrice[0].EditWalletTopUpAmount : false
                            }
                        }
                    }
                    if (e.hasOwnProperty('StoreSpecificPricing')) {
                        if (e.StoreSpecificPricing) {
                            let storePrice = e.StoreSpecificPrices.filter(f => f.StoreID === this.props.user.store.key)

                            if (storePrice.length > 0) {
                                e.RetailPrice = storePrice[0].RetailPrice
                                e.SupplyPrice = storePrice[0].SupplyPrice
                                e.Markup = storePrice[0].Markup
                                e.DiscountAmount = storePrice[0].hasOwnProperty('DiscountAmount') ? storePrice[0].DiscountAmount : 0
                                e.DiscountPercentage = storePrice[0].hasOwnProperty('DiscountPercentage') ? storePrice[0].DiscountPercentage : 0
                                e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty('WalletTopUpSameAsRetailPrice') ? storePrice[0].WalletTopUpSameAsRetailPrice : false
                                e.WalletTopUpAmount = storePrice[0].hasOwnProperty('WalletTopUpAmount') ? storePrice[0].WalletTopUpAmount : 0
                                e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty('EditWalletTopUpAmount') ? storePrice[0].EditWalletTopUpAmount : false
                                e.MRP = storePrice[0].MRP
                            }
                        }
                    }

                    this.setState({ queryText: '' });

                    this.onProductSelected(e)
                }
                else{
                    this.setState({ showAddProduct: true, showInventory: false })
                }
            })
        }
    }
    
    onProductTypeAheadSearch = (query) => {

        let excludeUniqueNo = this.state.transactionType.TransactionType.toLowerCase() ==="stock update" ? true : false

        this.setState({ isProductTypeAheadLoading: true });
        this.props.getProductsForInventorySearch(this.props.user.user.RegistrationID, query, excludeUniqueNo)
            .then((result) => {

                let productSearchResult = []
                let rowCount = 0
                for (let i = 0; i < result.searchResult.length; i++) {
                    if (rowCount > 10) {
                        break
                    }
                    let e = result.searchResult[i]

                    if (e.hasOwnProperty('AccessibleToSelectedStores')) {
                        if (e.AccessibleToSelectedStores) {
                            let storePrice = e.StoreSpecificPrices.filter(f => f.StoreID === this.props.user.store.key)
                            if (storePrice.length > 0) {
                                e.RetailPrice = storePrice[0].RetailPrice
                                e.SupplyPrice = storePrice[0].SupplyPrice
                                e.Markup = storePrice[0].Markup
                                e.MRP = storePrice[0].MRP
                                e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty('WalletTopUpSameAsRetailPrice') ? storePrice[0].WalletTopUpSameAsRetailPrice : false
                                e.WalletTopUpAmount = storePrice[0].hasOwnProperty('WalletTopUpAmount') ? storePrice[0].WalletTopUpAmount : 0
                                e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty('EditWalletTopUpAmount') ? storePrice[0].EditWalletTopUpAmount : false
                                productSearchResult.push(e)
                                rowCount = rowCount + 1
                            }
                            continue
                        }
                    }
                    if (e.hasOwnProperty('StoreSpecificPricing')) {
                        if (e.StoreSpecificPricing) {
                            let storePrice = e.StoreSpecificPrices.filter(f => f.StoreID === this.props.user.store.key)

                            if (storePrice.length > 0) {
                                e.RetailPrice = storePrice[0].RetailPrice
                                e.SupplyPrice = storePrice[0].SupplyPrice
                                e.Markup = storePrice[0].Markup
                                e.WalletTopUpSameAsRetailPrice = storePrice[0].hasOwnProperty('WalletTopUpSameAsRetailPrice') ? storePrice[0].WalletTopUpSameAsRetailPrice : false
                                e.WalletTopUpAmount = storePrice[0].hasOwnProperty('WalletTopUpAmount') ? storePrice[0].WalletTopUpAmount : 0
                                e.EditWalletTopUpAmount = storePrice[0].hasOwnProperty('EditWalletTopUpAmount') ? storePrice[0].EditWalletTopUpAmount : false
                                e.MRP = storePrice[0].MRP
                            }
                        }
                    }
                    rowCount = rowCount + 1
                    productSearchResult.push(e)
                }
                this.setState({ productsTypeAheadOptions: productSearchResult })
                this.setState({ isProductTypeAheadLoading: false });
            })
    }

    async onProductSelected(product) {
        if(this.state.transactionType.UpdateStockChecked 
            && this.state.transactionType.UpdateStockInwardOrOutward.toLowerCase() ==="outward"
            && product.hasOwnProperty('InventoryType') 
            && product.InventoryType.toLowerCase() === "unique no")
            {//give option to select unique id 
                this.setState({showUniqueNoSelection:true, selectedProduct:product})
                return
            }

            if(this.state.transactionType.UpdateStockChecked 
                && this.state.transactionType.UpdateStockInwardOrOutward.toLowerCase() ==="inward"
                && product.hasOwnProperty('InventoryType') 
                && product.InventoryType.toLowerCase() === "unique no")
                {
                    //check if product already elected 
                    let lineItem = null
                    let lineItemIndex = -1
                    this.state.lineItems.map((l, index) => {
                        if (l.Product.key === product.key) {
                            //increase qty 
                            lineItem = l
                            lineItemIndex = index
                            // return
                        }
                    })
                    this.setState({showUniqueNoInput:true, selectedProduct:product, selectedLineItem:lineItem})
                    return
            }

            this.productSelected(product, null)
    }

    async productSelected(product, uniqueNoList){
        let lineItemIndex = -1
        let lineItem = {}
        // let showUniqueNoInput = false
        let selectedLineItemIndex= -1
        let selectedLineItem = null
        // //check if product is already added
        this.state.lineItems.map((l, index) => {
            if (l.Product.key === product.key) {
                //increase qty 
                lineItem = l
                lineItemIndex = index
                return
            }
        })
        if (lineItemIndex === -1) {
            lineItem.Qty =1
            lineItem.SupplyPrice = product.SupplyPrice
            lineItem.SubTotal = 0
            lineItem.ChargeTaxOnProduct = product.ChargeTaxOnProduct
            lineItem.PriceBasedTax = product.ChargeTaxOnProduct ? product.PriceBasedTax : false
            lineItem.HSNSACCode = product.HSNSACCode
            lineItem.TaxInclusive = product.ChargeTaxOnProduct ? product.TaxInclusive : false
            lineItem.TaxGroup = product.TaxGroup
            lineItem.TaxID = product.TaxID
            lineItem.DiscountPer = 0
            lineItem.DiscountAmount = 0
            lineItem.SubTotalWithDiscount = 0
            lineItem.TaxableAmount = 0
            lineItem.TotalTax = 0
            lineItem.Amount = 0
            lineItem.Product = product
            lineItem.Notes = ''
            if(uniqueNoList && uniqueNoList.length> 0)
            {
                lineItem.UniqueNoList = uniqueNoList
                lineItem.Qty = uniqueNoList.length
            }
            
        }
        else {
           
            if(uniqueNoList && uniqueNoList.length> 0)
            {
                lineItem.Qty =Number(uniqueNoList.length)
                lineItem.UniqueNoList = uniqueNoList
            }
            else
            {
                lineItem.Qty = Number(lineItem.Qty) + 1
            }
        }
        let inStockQty = await this.props.getProductInStockQty(this.props.user.store.key,this.state.storeLocationID,product.key);
        lineItem.InStockQty = inStockQty
        lineItem = this.onLineItemChange(lineItem, this.state.placeOfSupply)
        //lineItem.Error=""
        let lineItems = this.state.lineItems
        if (lineItemIndex >= 0) {
            lineItems[lineItemIndex] = lineItem
        }
        else {
            lineItems.push(lineItem)
        }

        this.setState({ lineItems: lineItems,
            selectedLineItem:selectedLineItem,
            selectedLineItemIndex:selectedLineItemIndex,
            noLineItemExistError: '' })
        this.calculateBillTotal(lineItems)
    }

    onLineItemChange(lineItem, placeOfSupply) {
        let includePriceAndTax = false
        if (this.state.transactionType.hasOwnProperty('IncludePriceAndTax')) {
            includePriceAndTax = this.state.transactionType.IncludePriceAndTax
        }
        if (!includePriceAndTax) {
            lineItem.SubTotal = 0
            lineItem.ChargeTaxOnProduct = false
            lineItem.TaxInclusive = false
            lineItem.DiscountPer = 0
            lineItem.DiscountAmount = 0
            lineItem.SubTotalWithDiscount = 0
            lineItem.TaxableAmount = 0
            lineItem.TaxComponentAmount = []
            lineItem.TotalTax = 0
            lineItem.Amount = 0
            return lineItem
        }

        let subTotal = this.roundUpTo2(Number(lineItem.SupplyPrice) * Number(lineItem.Qty))
        let discountAmt
        let discountPer
        if (Number(lineItem.DiscountAmount) > 0 && Number(lineItem.DiscountPer) === 0) {//calculate discount per based on discount amount
            discountPer = Number(((Number(lineItem.DiscountAmount) * 100) / Number(lineItem.SubTotal)).toFixed(3))
            discountAmt = this.roundUpTo2(lineItem.DiscountAmount)
        }
        else {
            discountPer = Number(lineItem.DiscountPer)
            discountAmt = this.roundUpTo2((Number(subTotal) * Number(lineItem.DiscountPer)) / 100)
        }
        let subTotalWithDiscount = this.roundUpTo2(Number(subTotal) - Number(discountAmt))
        let chargeTaxOnProduct = lineItem.ChargeTaxOnProduct
        let priceBasedTax = lineItem.PriceBasedTax
        let taxInclusive = lineItem.TaxInclusive
        let taxableAmount = 0
        let totalTax = 0
        let amount = 0
        let taxComponentAmount = []
        let supplyPriceWithoutTax = 0 //TBD

        if (chargeTaxOnProduct) {
            if (priceBasedTax) {
                let unitPriceAfterDiscount = this.roundUpTo2(Number(subTotalWithDiscount) / Number(lineItem.Qty))
                //console.log('unitPriceAfterDiscount',unitPriceAfterDiscount)
                //console.log('lineItem.Product.TaxPriceRange',lineItem.Product.TaxPriceRange)
                lineItem.Product.TaxPriceRange.map((t) => {
                    if (Number(unitPriceAfterDiscount) >= Number(t.FromPrice) && Number(unitPriceAfterDiscount) <= Number(t.ToPrice)) {
                        lineItem.TaxID = t.TaxID
                        lineItem.TaxGroup = t.TaxGroup
                        return
                    }
                })
            }
            let taxfound = false
            let customerFromSameState = true //TBD IMP IMP

            if (placeOfSupply !== "" && this.props.user.store.State !== "") {
                if (placeOfSupply.toLowerCase() !== this.props.user.store.State.toLowerCase()) {
                    customerFromSameState = false
                }
            }
            this.props.config.ProductTaxes.filter((t) => t.key === lineItem.TaxID.trim()).map((taxGroup, index) => {
                taxfound = true
                let taxAmount = 0
                let taxComponentTemp = []
                taxGroup.Taxes.map((t) => {
                    if (customerFromSameState && (t.TaxType === "CGST" || t.TaxType === "SGST")) {
                        taxComponentTemp.push({
                            TaxName: t.TaxName, TaxPercentage: Number(t.TaxPercentage),
                            TaxType: t.TaxType, TaxAmount: 0
                        })
                    }
                    else if (!customerFromSameState && t.TaxType === "IGST") {
                        taxComponentTemp.push({
                            TaxName: t.TaxName, TaxPercentage: Number(t.TaxPercentage),
                            TaxType: t.TaxType, TaxAmount: 0
                        })
                    }
                    else if (t.TaxType !== "CGST" && t.TaxType !== "IGST" && t.TaxType !== "SGST") {
                        taxComponentTemp.push({
                            TaxName: t.TaxName, TaxPercentage: Number(t.TaxPercentage),
                            TaxType: t.TaxType, TaxAmount: 0
                        })
                    }
                })

                if (taxInclusive) {//tax inclusive
                    let totalTaxRate = 0
                    taxComponentTemp.map((t) => {
                        totalTaxRate = this.roundUpTo2(Number(t.TaxPercentage) + Number(totalTaxRate))
                    })
                    totalTax = this.roundUpTo2(Number(subTotalWithDiscount) * Number(totalTaxRate) / (100 + Number(totalTaxRate)))
                    taxableAmount = this.roundUpTo2(Number(subTotalWithDiscount) - Number(totalTax))
                    amount = this.roundUpTo2(subTotalWithDiscount)
                    taxComponentTemp.map((t) => {
                        taxAmount = this.roundUpTo2(Number(totalTax) * ((Number(t.TaxPercentage) * 100) / Number(totalTaxRate)) / 100)
                        taxComponentAmount.push({
                            TaxName: t.TaxName, TaxPercentage: Number(t.TaxPercentage),
                            TaxType: t.TaxType, TaxAmount: taxAmount
                        })
                    })
                }
                else {
                    taxableAmount = subTotalWithDiscount
                    taxComponentTemp.map((t) => {
                        taxAmount = this.roundUpTo2((Number(taxableAmount) * Number(t.TaxPercentage)) / 100)
                        totalTax = this.roundUpTo2(Number(totalTax) + Number(taxAmount))
                        taxComponentAmount.push({
                            TaxName: t.TaxName, TaxPercentage: Number(t.TaxPercentage),
                            TaxType: t.TaxType, TaxAmount: taxAmount
                        })
                    })
                    amount = this.roundUpTo2(taxableAmount + totalTax)
                }
            })
            //tax is not found then
            if (!taxfound) {
                //alert("Tax not found for selected product. Please check item ")
                taxableAmount = 0
                taxComponentAmount = []
                totalTax = 0
                amount = subTotalWithDiscount
            }
        }
        else {
            taxableAmount = 0
            taxComponentAmount = []
            totalTax = 0
            amount = subTotalWithDiscount
        }
        // lineItem.Qty = qty
        // lineItem.RetailPrice = price
        lineItem.SubTotal = this.roundUpTo2(subTotal)
        lineItem.ChargeTaxOnProduct = chargeTaxOnProduct
        lineItem.TaxInclusive = taxInclusive
        lineItem.DiscountPer = this.roundUpTo2(discountPer)
        lineItem.DiscountAmount = this.roundUpTo2(discountAmt)
        lineItem.SubTotalWithDiscount = this.roundUpTo2(subTotalWithDiscount)
        lineItem.TaxableAmount = this.roundUpTo2(taxableAmount)
        lineItem.TaxComponentAmount = taxComponentAmount
        lineItem.TotalTax = this.roundUpTo2(totalTax)
        lineItem.Amount = this.roundUpTo2(amount)
        return lineItem
    }

    async onRemoveLineItem(index) {
        //if it is edit and has unique no then check if the unique nos have not be untilized in some other transaciton
        if(this.state.lineItems[index].hasOwnProperty('UniqueNoList') 
        && this.state.lineItems[index].UniqueNoList.length> 0 
        && this.state.key.trim()!=="" )
        {
            let uniqueNoList = this.state.lineItems[index].UniqueNoList
            for(let i=0;i<uniqueNoList.length;i++)
            {
                let uniqueNo = null
                uniqueNo = await this.props.getUniqueNoIDBasedOnTransaction(this.props.user.store.key, 
                this.state.lineItems[index].Product.key,
                uniqueNoList[i].UniqueNo1,uniqueNoList[i].UniqueNo2,uniqueNoList[i].UniqueNo3,uniqueNoList[i].UniqueNo4)   
                // console.log('uniqueNoID',uniqueNo)
                if(uniqueNo && uniqueNo.TransactionID !== this.state.key.trim())
                {
                    alert('Unique no already used. Cannot delete line item.')
                    return 
                }
            }
        }
        let lineItems = this.state.lineItems
        lineItems.splice(index, 1)
        this.calculateBillTotal(lineItems)
        this.setState({selectedLineItem: null, 
            selectedLineItemIndex: -1})
    }

    onLineItemPriceChange(index, price) {
        let lineItems = this.state.lineItems
        let lineItem = this.state.lineItems[index]
        lineItem.SupplyPrice = Number(price)
        lineItem = this.onLineItemChange(lineItem, this.state.placeOfSupply)
        lineItems[index] = lineItem
        this.setState({ lineItems: lineItems })
        this.calculateBillTotal(lineItems)
    }

    onLineItemQtyChange(index, qty) {
        let lineItems = this.state.lineItems
        let lineItem = this.state.lineItems[index]
        lineItem.Qty = Number(qty)
        lineItem = this.onLineItemChange(lineItem, this.state.placeOfSupply)
        lineItems[index] = lineItem
        this.setState({ lineItems: lineItems })
        this.calculateBillTotal(lineItems)
    }

    calculateBillTotal(lineItems) {
        let billQty = 0
        let billAmount = 0
        let billSubTotal = 0
        let billTaxAmount = 0
        let billDiscount = 0
        let taxSummaryList = []

        let includePriceAndTax = false
        if (this.state.transactionType.hasOwnProperty('IncludePriceAndTax')) {
            includePriceAndTax = this.state.transactionType.IncludePriceAndTax
        }
        if (!includePriceAndTax) {
            lineItems.map((lineItem) => {
                billQty = this.roundUpTo2(Number(billQty) + Number(lineItem.Qty))
            })
            this.setState({
                billQty: billQty,
                billSubTotal: billSubTotal,
                billAmount: billAmount,
                billTaxAmount: billTaxAmount,
                billDiscount: billDiscount,
                taxSummaryList: taxSummaryList
            })
            return
        }

        for (let index = 0; index < lineItems.length; index++) {
            const lineItem = lineItems[index];
            if (lineItem.TaxID != "") {
                let indexFound = -1
                taxSummaryList.map((t, index) => {
                    if (t.TaxID === lineItem.TaxID && t.HSNSACCode === lineItem.HSNSACCode) {
                        let sameTaxComponent = true
                        t.TaxComponentAmount.map((a) => {
                            if (lineItem.TaxComponentAmount.filter(f => f.TaxName === a.TaxName).length === 0) {
                                sameTaxComponent = false
                                return
                            }
                        })
                        if (sameTaxComponent) {
                            indexFound = index
                            return
                        }
                    }
                })
                if (indexFound < 0) {
                    let taxSummary = {
                        TaxID: lineItem.TaxID,
                        TaxGroup: lineItem.TaxGroup,
                        HSNSACCode: lineItem.HSNSACCode,
                        TaxableAmount: this.roundUpTo2(lineItem.TaxableAmount),
                        TotalTax: this.roundUpTo2(lineItem.TotalTax),
                        TaxComponentAmount: []
                    }
                    lineItem.TaxComponentAmount.map((a) => {
                        taxSummary.TaxComponentAmount.push({ ...a })
                    })
                    taxSummaryList.push(taxSummary)
                }
                else {
                    taxSummaryList[indexFound].TaxableAmount = this.roundUpTo2(Number(taxSummaryList[indexFound].TaxableAmount) + Number(lineItem.TaxableAmount))
                    taxSummaryList[indexFound].TotalTax = this.roundUpTo2(Number(taxSummaryList[indexFound].TotalTax) + Number(lineItem.TotalTax))
                    taxSummaryList[indexFound].TaxComponentAmount.map((t, index) => {
                        t.TaxAmount = this.roundUpTo2(Number(t.TaxAmount) + Number(lineItem.TaxComponentAmount[index].TaxAmount))
                    })
                }
            }
            billQty = this.roundUpTo2(Number(billQty) + Number(lineItem.Qty))
            billSubTotal = this.roundUpTo2(Number(billSubTotal) + Number(lineItem.SubTotal))
            billAmount = this.roundUpTo2(Number(billAmount) + Number(lineItem.Amount))
            billTaxAmount = this.roundUpTo2(Number(billTaxAmount) + Number(lineItem.TotalTax))
            billDiscount = this.roundUpTo2(Number(billDiscount) + Number(lineItem.DiscountAmount))
        }

        this.setState({
            billQty: billQty,
            billSubTotal: billSubTotal,
            billAmount: billAmount,
            billTaxAmount: billTaxAmount,
            billDiscount: billDiscount,
            taxSummaryList: taxSummaryList
        })
    }

    onLineItemChangesDone() {

        let lineItems = this.state.lineItems
        lineItems[this.state.selectedLineItemIndex] = this.state.selectedLineItem
        this.setState({ lineItems: lineItems, showLineItemModal: false })
        this.calculateBillTotal(lineItems)
    }

    onLineItemSelected(lineItem, index) {
        let selectedLineItem = { ...lineItem }
        this.setState({
            selectedLineItem: selectedLineItem,
            selectedLineItemIndex: index,
            selectedItemEmployeeSelectionError: '',
            showLineItemModal: true
        })
        //console.log('onLineItemSelected', lineItem)
    }

    onSelectedLineItemQtyChange(qty) {
        let lineItem = this.state.selectedLineItem
        lineItem.Qty = Number(qty)
        lineItem = this.onLineItemChange(lineItem, this.state.placeOfSupply)
        this.setState({ selectedLineItem: lineItem })
    }

    onSelectedLineItemPriceChange(price) {
        let lineItem = this.state.selectedLineItem
        lineItem.SupplyPrice = Number(price)
        lineItem = this.onLineItemChange(lineItem, this.state.placeOfSupply)
        this.setState({ selectedLineItem: lineItem })
    }

    onSelectedLineItemDiscountChange(discountPer) {
        let lineItem = this.state.selectedLineItem
        lineItem.DiscountPer = Number(discountPer)
        lineItem.DiscountAmount = 0
        lineItem = this.onLineItemChange(lineItem, this.state.placeOfSupply)
        this.setState({ selectedLineItem: lineItem })
    }

    onSelectedLineItemDiscountAmountChange(discountAmount) {
        let lineItem = this.state.selectedLineItem
        lineItem.DiscountPer = 0
        lineItem.DiscountAmount = Number(discountAmount)
        lineItem = this.onLineItemChange(lineItem, this.state.placeOfSupply)
        this.setState({ selectedLineItem: lineItem })
    }

    onSelectedLineItemChargeTaxOnProductChange() {
        let lineItem = this.state.selectedLineItem
        lineItem.ChargeTaxOnProduct = !lineItem.ChargeTaxOnProduct
        if (!lineItem.ChargeTaxOnProduct) {
            lineItem.TaxInclusive = false
            lineItem.TaxGroup = ""
        }
        lineItem = this.onLineItemChange(lineItem, this.state.placeOfSupply)
        this.setState({ selectedLineItem: lineItem })
    }

    onSelectedLineItemTaxIncluxiveChange() {
        let lineItem = this.state.selectedLineItem
        lineItem.TaxInclusive = !lineItem.TaxInclusive
        lineItem = this.onLineItemChange(lineItem, this.state.placeOfSupply)
        this.setState({ selectedLineItem: lineItem })
    }

    onSelectedLineItemTaxGroupChange(taxID, taxGroup) {
        let lineItem = this.state.selectedLineItem
        lineItem.TaxID = taxID
        lineItem.TaxGroup = taxGroup
        lineItem = this.onLineItemChange(lineItem, this.state.placeOfSupply)
        this.setState({ selectedLineItem: lineItem })
    }

    onSelectedLineItemNotesChange(notes) {
        let lineItem = this.state.selectedLineItem
        lineItem.Notes = notes
        this.setState({ selectedLineItem: lineItem })
    }

    async onSave() {
        this.setState({ saving: true })
        let vendorError = ""
        let storeLocationError = ""
        let noLineItemExistError = ""
        let transactionDateError = ""
        let toStoreError = ""
        if (this.state.transactionDate.trim() === "") {
            transactionDateError = "Please select transaction date"
        }
        if (this.state.transactionType.TransactionMovement === "vendortostore" ||
            this.state.transactionType.TransactionMovement === "storetovendor") {
            if (!this.state.selectedVendor) {
                vendorError = "Please select vendor"
            }
            if (this.state.storeLocationID.trim() === "") {
                storeLocationError = "Please select store location"
            }
        }
        if (this.state.transactionType.TransactionMovement === "store") {
            if (this.state.storeLocationID.trim() === "") {
                storeLocationError = "Please select store location"
            }
        }
        if (this.state.transactionType.TransactionMovement === "storetostore") {
            if (this.state.storeLocationID.trim() === "") {
                storeLocationError = "Please select store location"
            }
            if (this.state.toStoreID.trim() === "") {
                toStoreError = "Please select to store"
            }
        }
        if (this.state.lineItems.length === 0) {
            noLineItemExistError = "Please select items"
        }

        if (vendorError !== "" || noLineItemExistError !== "" || storeLocationError !== "" || toStoreError !== "") {
            this.setState({
                showError: true,
                vendorError: vendorError,
                noLineItemExistError: noLineItemExistError,
                storeLocationError: storeLocationError,
                toStoreError: toStoreError,
                saving: false
            })
            return
        }

        let inventory = {
            TransactionType: this.state.transactionType,
            Vendor: this.state.selectedVendor,
            RegistrationID: this.props.user.user.RegistrationID,
            Store: this.props.user.store,
            LineItems: this.state.lineItems,
            BillQty: this.state.billQty,
            BillSubTotal: this.state.billSubTotal,
            BillDiscount: this.state.billDiscount,
            BillTaxAmount: this.state.billTaxAmount,
            BillAmount: this.state.billAmount,
            ParentTransactionNo: this.state.parentTransactionNo,
            ParentTransactionKey: this.state.parentTransactionKey,
            Notes: this.state.notes,
            TaxSummaryList: this.state.taxSummaryList,
            PlaceOfSupply: this.state.placeOfSupply,
            StoreLocation: this.state.storeLocation,
            StoreLocationID: this.state.storeLocationID,
            RefNo: this.state.refNo,
            BackEndUpdate: false,
            Status: "Completed",
            ToStoreID: this.state.toStoreID,
            ToStore: this.state.toStore,
            ChildTransactionNo: '',
            ChildTransactionKey: ''
        }
        // console.log('inventory',inventory)
        if (this.props.location.state.type === actionTypes.INVENTORY_NEW) {
            let transactionNo = this.getTransactioNo(this.state.transactionType)
            let nextRunningNo = await this.props.getInventoryRunningNo(inventory.Store.key, inventory.TransactionType.key)
            transactionNo = transactionNo + nextRunningNo
            inventory.TransactionNo = transactionNo
            inventory.TransactionDate = new Date(this.state.transactionDate)
            // console.log('inventory', inventory)
            this.props.addInventory(inventory).then((key) => {
                this.props.getToastr("Inventory #" + transactionNo + " saved successfully")
                if (this.state.printChecked) {
                    let inventoryPrint = { ...inventory }
                    let printData = { Inventory: inventoryPrint, PrintConfig: this.props.config.PrintConfigs.filter((e) => e.key === this.state.selectedPringConfigID)[0] }
                    this.setState({
                        print: true,
                        showInventory: false,
                        inventoryPrint: inventoryPrint,
                        printData: printData
                    })
                    return
                }
                setTimeout(function () { //Start the timer
                    this.setState({ saving: false })
                    // this.props.history.push({ pathname: "/home/sales/sales/", state: { transactionType: this.state.transactionType, type: actionTypes.SALE_NEW } }) 
                    this.props.history.push({ pathname: '/home/inventory/inventorylist/', state: { type: "list" } })
                }.bind(this), 2000)
            })
        }
        else {
            inventory.TransactionNo = this.state.transactionNo
            inventory.TransactionDate = new Date(this.state.transactionDate)
            this.props.updateInventory(inventory, this.props.location.state.key).then((key) => {
                this.props.getToastr("Inventory #" + inventory.TransactionNo + " updated successfully")
                setTimeout(function () { //Start the timer
                    this.setState({ saving: false })
                    //redirect to list page
                    this.props.history.push({ pathname: '/home/inventory/inventorylist/', state: { type: "list" } })
                }.bind(this), 2000)
            })
        }
    }

    printFormatting(inventory) {

        inventory.TransactionDate = inventory.TransactionDate.toString().substring(0, 24)
        inventory.AmountInWords = this.props.convertNumberToWords(inventory.BillAmount)
        inventory.LineItems.map((lineItem, index) => {
            inventory.LineItems[index].SrNo = index + 1
        })
        let printTaxComponents = []
        inventory.LineItems.map((lineItem, index) => {
            if (lineItem.TaxID != "") {
                lineItem.TaxComponentAmount.map((t) => {
                    let indexFound = -1
                    printTaxComponents.map((pt, ptIndex) => {
                        if (t.TaxType === pt.TaxType) {
                            indexFound = ptIndex
                            return
                        }
                    })
                    if (indexFound < 0) {
                        printTaxComponents.push({
                            TaxType: t.TaxType,
                            TaxAmount: this.roundUpTo2(Number(t.TaxAmount))
                        })
                    }
                    else {
                        printTaxComponents[indexFound].TaxAmount = this.roundUpTo2(Number(printTaxComponents[indexFound].TaxAmount) + Number(t.TaxAmount))
                    }
                })

            }
        })
        inventory.PrintTaxComponents = printTaxComponents
        inventory.LineItems.map((lineItem, index) => {
            for (var i = 0; i < printTaxComponents.length; i++) {
                if (lineItem.TaxComponentAmount.length < (i + 1)) {
                    lineItem.TaxComponentAmount.push({ TaxName: '', TaxAmount: 0, TaxPercentage: '', TaxType: '' })
                }
            }
        })
    }

    getTransactioNo(transactionType) {
        let transactionNo = ""
        transactionType.NumberingFormat.map((parameters) => {
            let para = ""
            if (parameters.Option === "text") {
                para = parameters.Value
            }
            else if (parameters.Option === "storecode") {
                para = this.props.user.store.StoreCode
            }
            // else if (parameters.Option == "locationcode") {
            //     para = this.props.user.cashRegister.LocationCode
            // }
            else if (parameters.Option === "calendardmonthyear") {
                let d = new Date();
                let a = d.getFullYear() + ""
                para = d.getMonth() + a.substring(2, 4)
                if (para.length < 3) {
                    para = "0" + para
                }
            }
            else if (parameters.Option === "fiscalyear") {
                let d = new Date();
                para = d.getFullYear() + ""
            }
            else if (parameters.Option === "calendarmonth") {
                let d = new Date();
                let a = d.getMonth() + ""
                if (a.length < 2) {
                    para = "0" + a
                }
            }
            else if (parameters.Option === "calendaryear") {
                let d = new Date();
                para = d.getFullYear() + ""
            }
            transactionNo = transactionNo + para + parameters.Separator
        })
        return transactionNo
    }

    onCancel() {
        // this.props.history.push('/home/inventory/inventorylist')
        // console.log('onCancel')
        this.props.history.push({ pathname: '/home/inventory/inventorylist/', state: { type: "list" } })
    }

    async onImportCompletion(importedRcords) {
        this.setState({ loading: true, showImport: false })
        for (let index = 0; index < importedRcords.length; index++) {
            const lineItem = importedRcords[index];
            let inStockQty = await this.props.getProductInStockQty(this.props.user.store.key,this.state.storeLocationID,lineItem.Product.key);
            importedRcords[index].InStockQty = inStockQty;
        }

        if (this.state.transactionType.IncludePriceAndTax) {
            importedRcords.map((lineItem, index) => {
                importedRcords[index] = this.onLineItemChange(lineItem, "")
            })
            this.setState({ showImport: false, lineItems: importedRcords })
            this.calculateBillTotal(importedRcords)
        }
        else {
            this.setState({ showImport: false, lineItems: importedRcords })
            this.calculateBillTotal(importedRcords)
        }
        this.setState({ loading: false })
    }

    render() {
        // const btnColStyle = {width:"60px"}
        const styleEditor = {
            width: "100%",
            height: "150px",
            font_size: "14px",
            line_height: "18px",
            border: "1px solid #dddddd",
            padding: "10px"
        }

        const modalWidth = {
            width: '500px'
        }

        return <React.Fragment>
            {this.state.showInventory && <div>
                <div className="content-wrapper">
                    <section className="content-header">
                        {this.state.transactionType && <h1>
                            {this.state.transactionType.TransactionType} - {this.props.location.state.type === actionTypes.INVENTORY_NEW ? "New" : "Edit"}
                        </h1>}
                        <button type="submit" className="btn btn-md btn-success btn-flat pull-right"
                            style={{ marginTop: "-25px", marginLeft: "10px" }}
                            onClick={this.onSave} disabled={this.state.saving}>Save
                </button>
                        <button className="btn btn-md btn-flat btn-default pull-right" style={{ marginTop: "-25px" }}
                            onClick={this.onCancel} >Cancel
                </button>
                        {this.state.selectedPringConfigID !== "" && <span className="pull-right" style={{ marginTop: "-25px", marginRight: "10px" }}>
                            <select className="form-control input-md"
                                value={this.state.selectedPringConfigID}
                                onChange={(evt) => this.setState({
                                    selectedPringConfigID: evt.target.value,
                                    selectedPrintConfigPrintDisplayName: evt.target.options[evt.target.selectedIndex].text
                                })}>
                                {this.props.config.PrintConfigs.filter((e) => !e.IsDeleted
                                    && e.TransactionTypeID === this.props.location.state.transactionType.key).map((t, index) =>
                                        <option key={index} value={t.key}>{t.PrintDisplayName}</option>
                                    )}
                            </select>
                        </span>}
                        {this.state.selectedPringConfigID !== "" && <span className="pull-right" style={{ marginTop: "-18px", marginRight: "10px" }}>
                            <input type="checkbox" checked={this.state.printChecked} onChange={() => this.setState({ printChecked: !this.state.printChecked })} />
                            <label >Print</label>
                        </span>}
                    </section>
                    <section className="content" >
                        <div className="box ">
                            {this.state.transactionType && <div className="box-body">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className={this.state.transactionDateError !== "" ? "form-group has-error" : "form-group "} >
                                            <label>Date</label>
                                            <input type="date" className="form-control"
                                                onChange={(evt) => this.setState({ transactionDate: evt.target.value, transactionDateError: '' })}
                                                value={this.state.transactionDate}
                                            // onKeyPress={this.handleKeyPress} 
                                            />
                                            {this.state.transactionDateError !== "" && <span className="help-block">{this.state.transactionDateError}</span>}
                                        </div>
                                    </div>
                                    {(this.state.transactionType.TransactionMovement == "vendortostore" || this.state.transactionType.TransactionMovement == "storetovendor") && <div className="col-md-3">
                                        <div className={this.state.vendorError !== "" ? "form-group has-error" : "form-group "} >

                                            <label>Vendor</label>
                                            <VendorSearch {...this.props}
                                                vendor={this.state.selectedVendor}
                                                error={this.state.partyError}
                                                onChange={(vendor) => this.setState({ selectedVendor: vendor, vendorError: '' })}
                                            />
                                            {this.state.vendorError !== "" && <span className="help-block">{this.state.vendorError}</span>}

                                        </div>
                                    </div>}
                                    {(this.state.transactionType.TransactionMovement == "vendortostore" || this.state.transactionType.TransactionMovement == "storetovendor" || this.state.transactionType.TransactionMovement == "store" || this.state.transactionType.TransactionMovement == "storetostore") && <div className="col-md-3">
                                        <div className={this.state.storeLocationError !== "" ? "form-group has-error" : "form-group "} >

                                            <label>Store Location</label>
                                            <select className="form-control"
                                                value={this.state.storeLocationID}
                                                onChange={(e) => {
                                                    this.setState({
                                                        storeLocationID: e.target.value,
                                                        storeLocation: e.target.options[e.target.selectedIndex].text,
                                                        storeLocationError: ''
                                                    })
                                                }}>
                                                <option value="">Select Store Location</option>
                                                {this.state.storeLocations.filter((e) => !e.IsDeleted).map((storeLocation) =>
                                                    <option  key={storeLocation.key} value={storeLocation.key}>{storeLocation.LocationCode}  {storeLocation.LocationName}</option>
                                                )}
                                            </select>
                                            {this.state.storeLocationError !== "" && <span className="help-block">{this.state.storeLocationError}</span>}

                                        </div>
                                    </div>}
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Ref No</label>
                                            <input type="text" className="form-control"
                                                onChange={(evt) => this.setState({ refNo: evt.target.value })}
                                                value={this.state.refNo}
                                            />
                                        </div>

                                    </div>
                                    {(this.state.transactionType.TransactionMovement == "storetostore") && <div className="col-md-3">
                                        <div className={this.state.toStoreError !== "" ? "form-group has-error" : "form-group "} >

                                            <label>To Store</label>
                                            <select className="form-control"
                                                value={this.state.toStoreID}
                                                onChange={(e) => {
                                                    this.setState({
                                                        toStoreID: e.target.value,
                                                        toStore: e.target.options[e.target.selectedIndex].text,
                                                        toStoreError: ''
                                                    })
                                                }}>
                                                <option value="">Select Store </option>
                                                {this.state.toStoreList.map((store) =>
                                                    <option value={store.key}>{store.StoreCode}  {store.StoreName}</option>
                                                )}
                                            </select>
                                            {this.state.toStoreError !== "" && <span className="help-block">{this.state.toStoreError}</span>}

                                        </div>
                                    </div>}
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className={this.state.noLineItemExistError !== "" ? "form-group has-error" : "form-group "} >

                                                    <div className="input-group" style={{ width: "100%" }}>
                                                    {!this.state.toggleSearch && <AsyncTypeahead
                                                            //  ref={(ref) => this.typeaheadProductSearch = ref}
                                                            autoFocus={true}
                                                            allowNew={false}
                                                            isLoading={this.state.isProductTypeAheadLoading}
                                                            multiple={false}
                                                            options={this.state.productsTypeAheadOptions}
                                                            labelKey="Name"
                                                            minLength={3}
                                                            onSearch={this.onProductTypeAheadSearch}
                                                            placeholder="Start typing or scanning to search products"
                                                            filterBy={['Name', 'SKU','BarCode']}
                                                            autoFocus={true}
                                                            dropup={false}
                                                            highlightOnlyResult={true}
                                                            selected={this.state.productsTypeAheadSelected}
                                                            onChange={(selected) => {
                                                                this.onProductSelected(selected[0])
                                                                this.setState({ productsTypeAheadSelected: [] })
                                                            }}
                                                            renderMenuItemChildren={(option, props) => (
                                                                <div>
                                                                    <div><b>{option.Name}</b></div>
                                                                    <div>{option.SKU}</div>
                                                                </div>
                                                            )}
                                                        />}
                                                        {this.state.toggleSearch && <input type="text" 
                                                        onChange={(evt) => {
                                                            this.setState({ queryText: evt.target.value })
                                                        }}
                                                        value={this.state.queryText}
                                                        onKeyPress={this.onProductBarCodeSearch}
                                                        placeholder="Start scanning to search products"
                                                            className="form-control" style={{ height: "33px", fontSize: "14px" }} />}
                                                        <div className="input-group-btn">
                                                            <button type="button"
                                                                onClick={() => this.setState({ toggleSearch: !this.state.toggleSearch })}
                                                                className="btn btn-default btn-lg btn-flat" style={{ height: "33px",padding:"5px 16px" }}>
                                                                <i className="fa fa-exchange"></i>
                                                            </button>
                                                        </div>
                                                        <div className="input-group-btn">
                                                            <button type="button" onClick={() => this.setState({ showAddProduct: true, showInventory: false })}
                                                                className="btn btn-default btn-flat">
                                                                <i className="glyphicon glyphicon-plus"></i>
                                                            </button>
                                                            <button type="button" onClick={() => {

                                                                this.setState({ showImport: true })
                                                            }}
                                                                className="btn btn-default btn-flat">
                                                                <i className="glyphicon glyphicon-upload"></i>Import Product
                                                    </button>
                                                        </div>
                                                    </div>
                                                    {this.state.noLineItemExistError !== "" && <span className="help-block">{this.state.noLineItemExistError}</span>}

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">

                                            <div className="col-md-12" style={{ marginTop: "10px" }}>
                                                {this.state.transactionType && <table className="table table-bordered table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: "10px" }}>#</th>
                                                            <th>Product</th>
                                                            <th style={{ width: "150px" }}>MRP</th>
                                                            {this.state.transactionType.IncludePriceAndTax && <th style={{ width: "150px" }}>Supply Price</th>}
                                                            {(this.state.transactionType.TransactionType === 'Transfer In' || (this.props.location.state.inventory && this.props.location.state.inventory.hasOwnProperty('SaleToPurchase'))) && <th style={{ width: "100px" }}>Dispatched Qty</th>}
                                                            <th style={{ width: "100px" }}>Qty</th>
                                                            <th style={{ width: "100px" }}>In Stock Qty</th>
                                                            {this.state.transactionType.IncludePriceAndTax && <th style={{ width: "100px" }}>Sub Total</th>}
                                                            {this.state.transactionType.IncludePriceAndTax && <th style={{ width: "100px" }}>Discount</th>}
                                                            {this.state.transactionType.IncludePriceAndTax && <th style={{ width: "100px" }}>Tax</th>}
                                                            {this.state.transactionType.IncludePriceAndTax && <th style={{ width: "100px" }}>Tax Amount</th>}
                                                            {this.state.transactionType.IncludePriceAndTax && <th style={{ width: "100px" }}>Total</th>}
                                                            {this.state.transactionType.TransactionType !== 'Transfer In' && <th style={{ width: "40px" }}></th>}
                                                        </tr>
                                                    </thead>
                                                   
                                                    {!this.state.loading  && <tbody>

                                                        {this.state.lineItems.map((lineItem, index) => <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td><a onClick={() => { this.onLineItemSelected(lineItem, index) }}>
                                                                {lineItem.Product.Name}
                                                                <div className="sales-sub-text">SKU - {lineItem.Product.SKU}</div>
                                                                {lineItem.Product.BarCode !== "" && <div className="sales-sub-text">Barcode - {lineItem.Product.BarCode} </div>}
                                                                {lineItem.HSNSACCode !== "" && <div className="sales-sub-text">HSNSACCode - {lineItem.HSNSACCode} </div>}
                                                                {lineItem.Notes !== "" && <div className="sales-sub-text">Notes - {lineItem.Notes} </div>}
                                                            </a>
                                                            </td>
                                                            <td>{lineItem.Product.MRP}</td>
                                                            {this.state.transactionType.IncludePriceAndTax && <td>
                                                                <input type="number"
                                                                    className="form-control"
                                                                    onChange={(evt) => this.onLineItemPriceChange(index, evt.target.value)}
                                                                    // onKeyPress={this.handleKeyPress}
                                                                    value={lineItem.SupplyPrice}
                                                                />
                                                            </td>}
                                                            {(this.state.transactionType.TransactionType === 'Transfer In' || (this.props.location.state.inventory && this.props.location.state.inventory.hasOwnProperty('SaleToPurchase'))) && <td style={{ width: "100px" }}>{lineItem.DispatchedQty}</td>}
                                                            <td>
                                                                {/* non unique no products*/}
                                                                {(!lineItem.Product.hasOwnProperty('InventoryType') || (lineItem.Product.hasOwnProperty('InventoryType') 
                                                                && lineItem.Product.InventoryType.toLowerCase() !== "unique no")) && <input type="number"
                                                                    className="form-control"
                                                                    onChange={(evt) => this.onLineItemQtyChange(index, evt.target.value)}
                                                                    // onKeyPress={this.handleKeyPress}
                                                                    value={lineItem.Qty}
                                                                />}
                                                                {(this.state.transactionType.UpdateStockChecked 
                                                                &&  lineItem.Product.hasOwnProperty('InventoryType') 
                                                                && lineItem.Product.InventoryType.toLowerCase() === "unique no") && <React.Fragment>
                                                                    <div>{lineItem.Qty}</div>
                                                                    <div> <button type="submit"
                                                                        style={{width:"100%"}}
                                                                        className="btn btn-md btn-default btn-flat pull-right"
                                                                        onClick={()=> this.setState({showUniqueNoInput:true, selectedLineItem: lineItem, 
                                                                        selectedLineItemIndex:index, 
                                                                        selectedProduct:lineItem.Product})} > 
                                                                        {lineItem.Product.UniqueLabel.trim()!==""? lineItem.Product.UniqueLabel : "Unique No"}
                                                                        </button>
                                                                    </div>
                                                                    </React.Fragment>
                                                                }
                                                            </td>
                                                            <td>
                                                                {lineItem.InStockQty}
                                                            </td>
                                                            {this.state.transactionType.IncludePriceAndTax && <td>{lineItem.SubTotal}</td>}
                                                            {this.state.transactionType.IncludePriceAndTax && <td>
                                                                {lineItem.DiscountAmount}
                                                                <div className="sales-sub-text">{lineItem.DiscountPer} %</div>
                                                            </td>}
                                                            {this.state.transactionType.IncludePriceAndTax && <td>
                                                                {lineItem.TaxGroup}
                                                                {lineItem.TaxComponentAmount.map((t) =>
                                                                    <div className="sales-sub-text">{t.TaxName} {t.TaxPercentage}%:{t.TaxAmount}</div>)}
                                                            </td>}
                                                            {this.state.transactionType.IncludePriceAndTax && <td>{lineItem.TotalTax}</td>}
                                                            {this.state.transactionType.IncludePriceAndTax && <td>{lineItem.Amount}</td>}
                                                            {this.state.transactionType.TransactionType !== 'Transfer In' && <td>
                                                                <a onClick={() => this.onRemoveLineItem(index)}><i className="fa fa-lg fa-trash-o pull-right" style={{ margin: "10px" }}></i></a>

                                                            </td>}

                                                        </tr>)}
                                                        <tr>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            {this.state.transactionType.IncludePriceAndTax && <td></td>}
                                                            {(this.state.transactionType.TransactionType === 'Transfer In' || (this.props.location.state.inventory && this.props.location.state.inventory.hasOwnProperty('SaleToPurchase'))) && <td></td>}
                                                            
                                                            <td><b>{this.state.billQty}</b></td>
                                                            <td></td>
                                                            {this.state.transactionType.IncludePriceAndTax && <td><b>{this.state.billSubTotal}</b></td>}
                                                            {this.state.transactionType.IncludePriceAndTax && <td>
                                                                <b>{this.state.billDiscount}</b>
                                                            </td>}
                                                            {this.state.transactionType.IncludePriceAndTax && <td>
                                                            </td>}
                                                            {this.state.transactionType.IncludePriceAndTax && <td><b>{this.state.billTaxAmount}</b></td>}
                                                            {this.state.transactionType.IncludePriceAndTax && <td><b>{this.state.billAmount}</b></td>}
                                                            <td>
                                                            </td>
                                                        </tr>
                                                    </tbody>}
                                                   
                                                </table>}
                                                {this.state.loading && <Loader show={this.state.loading} />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">

                                    {this.state.transactionType && <div className="col-md-6">
                                        {this.state.transactionType.IncludePriceAndTax && <div className="form-group">
                                            <label >Tax Summary</label>
                                            {this.state.taxSummaryList && <table className="table table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>HSN/SAC</th>
                                                        <th>Taxable Amount</th>
                                                        <th>Tax</th>
                                                        <th>Tax Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.taxSummaryList.map((t) => <tr>
                                                        <td>{t.HSNSACCode}</td>
                                                        <td>{t.TaxableAmount}</td>
                                                        <td>{t.TaxGroup}</td>
                                                        <td>{t.TotalTax}</td>
                                                    </tr>)}
                                                </tbody>
                                            </table>}
                                        </div>}
                                    </div>}
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >Notes</label>
                                            <textarea className="textarea" style={styleEditor}
                                                onChange={(evt) => this.setState({ notes: evt.target.value })}
                                                value={this.state.notes}
                                            ></textarea>
                                        </div>

                                    </div>
                                </div>

                            </div>}
                        </div>

                        <button type="submit"
                            style={{ marginBottom: "10px", marginLeft: "10px" }}
                            className="btn btn-md btn-success btn-flat pull-right"
                            onClick={this.onSave} disabled={this.state.saving}>Save
                                    </button>
                        <button style={{ marginBottom: "10px" }}
                            onClick={this.onCancel}
                            className="btn btn-md btn-default btn-flat  pull-right">Cancel
                        </button>
                        {this.state.selectedPringConfigID !== "" && <span className="pull-right"
                            style={{ marginBottom: "10px", marginRight: "10px" }}
                        >
                            <select className="form-control input-md"
                                value={this.state.selectedPringConfigID}
                                onChange={(evt) => this.setState({
                                    selectedPringConfigID: evt.target.value,
                                    selectedPrintConfigPrintDisplayName: evt.target.options[evt.target.selectedIndex].text
                                })}>
                                {this.props.config.PrintConfigs.filter((e) => !e.IsDeleted
                                    && e.TransactionTypeID === this.props.location.state.transactionType.key).map((t, index) =>
                                        <option key={index} value={t.key}>{t.PrintDisplayName}</option>
                                    )}
                            </select>
                        </span>}
                        {this.state.selectedPringConfigID !== "" && <span className="pull-right"
                            style={{ marginTop: "5px", marginRight: "10px" }}
                        >
                            <input type="checkbox" checked={this.state.printChecked} onChange={() => this.setState({ printChecked: !this.state.printChecked })} />
                            <label >Print</label>
                        </span>}
                    </section>
                    <Modal open={this.state.showLineItemModal} onClose={() => this.setState({ showLineItemModal: false })} center>
                        {this.state.selectedLineItem && <div style={{ width: "700px" }}>
                            <h3 style={{ marginTop: "0px" }}>{this.state.selectedLineItem.Product.SKU} - {this.state.selectedLineItem.Product.Name}</h3>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="form-group" >
                                            <label >Qty</label>
                                            <input type="number" className="form-control"
                                                onChange={(evt) => this.onSelectedLineItemQtyChange(evt.target.value)}
                                                value={this.state.selectedLineItem.Qty}
                                                onKeyPress={this.handleKeyPress}
                                                disabled={this.state.selectedLineItem.Product.hasOwnProperty('InventoryType') && this.state.selectedLineItem.Product.InventoryType.toLowerCase()==="unique no" ? true: false }
                                            />
                                        </div>
                                    </div>
                                    {this.state.transactionType.IncludePriceAndTax && <React.Fragment>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Price</label>
                                                <input type="number" className="form-control"
                                                    onChange={(evt) => this.onSelectedLineItemPriceChange(evt.target.value)}
                                                    value={this.state.selectedLineItem.SupplyPrice}
                                                    disabled={!this.state.selectedLineItem.Product.AllowPriceEdit}
                                                    onKeyPress={this.handleKeyPress}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label >Disc %</label>
                                                <input type="number" className="form-control"
                                                    onChange={(evt) => this.onSelectedLineItemDiscountChange(evt.target.value)}
                                                    value={this.state.selectedLineItem.DiscountPer}
                                                    disabled={!this.state.selectedLineItem.Product.AllowDiscount}
                                                    onKeyPress={this.handleKeyPress}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label >Dis Amt</label>
                                                <input type="number" className="form-control"
                                                    onChange={(evt) => this.onSelectedLineItemDiscountAmountChange(evt.target.value)}
                                                    value={this.state.selectedLineItem.DiscountAmount}
                                                    disabled={!this.state.selectedLineItem.Product.AllowDiscount}
                                                    onKeyPress={this.handleKeyPress}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label >Sub Total</label>
                                                <input type="number" className="form-control"
                                                    disabled={true}
                                                    value={this.state.selectedLineItem.SubTotalWithDiscount}
                                                />
                                            </div>
                                        </div> </React.Fragment>}
                                </div>
                                {this.state.transactionType.IncludePriceAndTax && <React.Fragment>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="checkbox"
                                                    onChange={() => this.onSelectedLineItemChargeTaxOnProductChange()}
                                                    defaultChecked={this.state.selectedLineItem.ChargeTaxOnProduct}
                                                    disabled={!this.state.selectedLineItem.Product.AllowTaxChange}
                                                />
                                                <label>Charge taxes on this product</label>
                                            </div>
                                        </div>
                                        {this.state.selectedLineItem.ChargeTaxOnProduct && <div className="col-md-6">
                                            <div className="form-group">
                                                <input type="checkbox"
                                                    onChange={() => this.onSelectedLineItemTaxIncluxiveChange()}
                                                    defaultChecked={this.state.selectedLineItem.TaxInclusive}
                                                    disabled={!this.state.selectedLineItem.Product.AllowTaxChange}
                                                />
                                                <label for="taxInclusive">Tax inclusive</label>
                                            </div>
                                        </div>}
                                    </div>
                                    {this.state.selectedLineItem.ChargeTaxOnProduct && <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >HSN/SAC Code</label>
                                                <input type="text" className="form-control"
                                                    onKeyPress={this.handleKeyPress}
                                                    onChange={(evt) => {
                                                        let lineItem = this.state.selectedLineItem
                                                        lineItem.HSNSACCode = evt.target.value
                                                        this.setState({ selectedLineItem: lineItem })
                                                    }}
                                                    value={this.state.selectedLineItem.HSNSACCode}
                                                    disabled={!this.state.selectedLineItem.Product.AllowTaxChange}
                                                    placeholder="HSN/SAC code" />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="tax">Tax </label>
                                                <select className="form-control" value={this.state.selectedLineItem.TaxID}
                                                    onChange={(evt) => this.onSelectedLineItemTaxGroupChange(evt.target.value, evt.target.options[evt.target.selectedIndex].text)}
                                                    disabled={!this.state.selectedLineItem.Product.AllowTaxChange}
                                                >
                                                    <option value="">Select Tax</option>
                                                    {this.props.config.ProductTaxes.map((t, index) =>
                                                        <option value={t.key}>{t.TaxGroup}</option>
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                    </div>}
                                    {this.state.selectedLineItem.ChargeTaxOnProduct && <div className="row">
                                        {this.state.selectedLineItem.TaxComponentAmount.map((t) => <div className="col-md-3">
                                            <div className="form-group">
                                                <label >{t.TaxName} {t.TaxPercentage}%</label>
                                                <input type="text" className="form-control" value={t.TaxAmount} disabled={true}></input>
                                            </div>
                                        </div>)}
                                    </div>}
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label> Total Amount</label>
                                                <input type="text" className="form-control" disabled={true}
                                                    value={this.state.selectedLineItem.Amount}></input>
                                            </div>
                                        </div>
                                        <div className="col-md-6">

                                        </div>
                                    </div>
                                </React.Fragment>}
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label >Notes</label>
                                            <input type="text" className="form-control"
                                                onChange={(evt) => this.onSelectedLineItemNotesChange(evt.target.value)}
                                                value={this.state.selectedLineItem.Notes}
                                                onKeyPress={this.handleKeyPress}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-footer">
                                <button type="submit" className="btn btn-flat btn-md btn-default" onClick={() => this.setState({ showLineItemModal: false })} >Cancel</button>
                                <button type="submit" className="btn btn-flat btn-md btn-success pull-right" onClick={() => this.onLineItemChangesDone()}>OK</button>
                            </div>
                        </div>}
                    </Modal>
                </div>
            </div>}
            <Modal open={this.state.showUniqueNoInput} 
            onClose={() => this.setState({ showUniqueNoInput: false })} center>
                <UniqueNoInput 
                {...this.props}
                transactionID={this.state.key}
                product={this.state.selectedProduct}
                lineItem={this.state.selectedLineItem}
                mode={this.state.transactionType 
                    && this.state.transactionType.hasOwnProperty('UpdateStockInwardOrOutward') 
                    && this.state.transactionType.UpdateStockInwardOrOutward.toLowerCase() ==="inward"
                    && this.state.key.trim()==="" ? "add"
                        :(this.state.transactionType 
                        && this.state.transactionType.hasOwnProperty('UpdateStockInwardOrOutward') 
                        && this.state.transactionType.UpdateStockInwardOrOutward.toLowerCase() ==="inward"
                        && this.state.key.trim()!=="" ) ? "edit" 
                            : "remove"}
                onOK={(uniqueNoList)=>{
                    this.productSelected(this.state.selectedProduct, uniqueNoList)
                    this.setState({ showUniqueNoInput: false })
                }}
                onClose={() => this.setState({ showUniqueNoInput: false })}/>
            </Modal>
            <Modal open={this.state.showUniqueNoSelection} 
            onClose={() => this.setState({ showUniqueNoSelection: false })} center>
                <UniqueNoSelection 
                {...this.props}
                product={this.state.selectedProduct}
                lineItem={this.state.selectedLineItem}
                onOK={(uniqueNoList)=>{
                    // let tmpUniqueNoList =[]
                    // if(this.selectedLineItem && this.selectedLineItem.hasOwnProperty('UniqueNoList'))
                    // {
                    //     tmpUniqueNoList = [...this.selectedLineItem.UniqueNoList]
                    // }
                    // tmpUniqueNoList=[...uniqueNoList]
                    // this.productSelected(this.state.selectedProduct, tmpUniqueNoList)
                    // this.setState({ showUniqueNoSelection: false })
                    let tmpUniqueNoList =[]
                    if(this.state.selectedLineItem && this.state.selectedLineItem.hasOwnProperty('UniqueNoList'))
                    {
                        tmpUniqueNoList = this.state.selectedLineItem.UniqueNoList
                    }
                    uniqueNoList.map(m=> tmpUniqueNoList.push(m))
                    this.productSelected(this.state.selectedProduct, tmpUniqueNoList)
                    this.setState({ showUniqueNoSelection: false })
                }}
                onClose={() => this.setState({ showUniqueNoSelection: false })}/>
            </Modal>
            {this.state.transactionType && <Modal open={this.state.showImport} onClose={() => this.setState({ showImport: false })} center>
                <div style={modalWidth}>
                    <ImportMaster {...this.props}
                        importType={this.state.transactionType.IncludePriceAndTax ? "inventoryPrice" : "inventoryQty"}
                        inventoryInWardOrOutward ={ this.state.transactionType.TransactionType.toLowerCase()  ==="stock update" ? "" : this.state.transactionType.UpdateStockInwardOrOutward.toLowerCase()}
                        onClose={() => this.setState({ showImport: false })}
                        onImportCompletion={(records) =>
                            this.onImportCompletion(records)} />
                </div>
            </Modal>}
            {this.state.showAddProduct && <Product {...this.props}
                calledFromInvoice={true}
                onSave={(product) => {
                    this.setState({ showAddProduct: false, showInventory: true })
                    this.onProductSelected(product)
                }}
                onClose={() => this.setState({ showAddProduct: false, showInventory: true })}
            />}
            {this.state.print && <div>
                <Print  {...this.props}
                    printChecked={this.state.printChecked}
                    sendEmailChecked={this.state.sendEmailChecked}
                    sendSMSChecked={this.state.sendSMSChecked}
                    printData={this.state.printData}
                    onClose={() => {
                        this.setState({ print: false, showInventory: true })
                        this.props.history.push('/home/inventory/inventorylist')
                        }
                    }
                />
            </div>}
        </React.Fragment>
    }

}

export default Inventory
