import React, { Component } from 'react';
import Confirm from 'react-confirm-bootstrap'
import Loader from '../Loader'
import AuditTrail from '../AuditTrail'
import * as constants from '../../Constatnts'

class  TransactionTypeList extends Component{
    constructor () {
        super()
        this.state = {
            transactionTypes:[],
            searchActiveInactiveAllRecords: 'active',
            loading: true,
            transactions:[]
        }
        this.loadTransactionTypes = this.loadTransactionTypes.bind(this)
        this.onTransactionTypeDelete = this.onTransactionTypeDelete.bind(this)        
    }

    componentDidMount(){
        this.props.getTransactionTypes(this.props.user.user.RegistrationID).then(()=>
        {
            this.loadTransactionTypes(this.state.searchActiveInactiveAllRecords)    
        })
    }

    loadTransactionTypes(option)
    {
        let alltransactions = this.props.config.TransactionTypes
        let transactions= []
        if(option === "all")
        {
            transactions = alltransactions 
            
        }
        else if(option === "active")
        {
            transactions = alltransactions.filter((e)=> !e.IsDeleted)
        }
        else if(option ==="inactive")
        {
            transactions = alltransactions.filter((e)=> e.IsDeleted)
        }

        this.setState({ loading : false, transactions:transactions, searchActiveInactiveAllRecords:option })
    }
    
    onTransactionTypeDelete(key){
        this.props.deleteTransactionType(key).then(()=>{
            this.props.getToastr("Transaction Type deleted successfully")
            this.loadTransactionTypes(this.state.searchActiveInactiveAllRecords)
        })
    }

    render() {    
        const btnColStyle = {width:"100px"}
        
        return <div className="content-wrapper" >  
        <section className="content">
            <div className="row">
                <div className="col-md-12">
                    <div className="box ">
                        <div className="box-header with-border">
                            <div className="row">                                
                                <div className="col-md-6">
                                    <font style={{fontSize:"30px"}}>Module Configuration</font>
                                </div>
                                <div className="col-md-6">
                                    <div className="pull-right" style={{marginTop:"6px"}}>                                                                                   
                                        <button type="button" 
                                        className="btn btn-success btn-flat btn-md" 
                                        onClick={ () =>  this.props.history.push({pathname:"/home/settings/moduleconfig/new",state:{transactionType:null}})}>Add Transaction Type</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box-body">
                        <div className="row">
                                    <div className="col-md-12" style={{ marginBottom: "10px" }}>
                                        <div className="btn-group">
                                            <button type="button" className={this.state.searchActiveInactiveAllRecords === "active" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.loadTransactionTypes("active")}>Active</button>
                                            <button type="button" className={this.state.searchActiveInactiveAllRecords === "inactive" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.loadTransactionTypes("inactive")}>In Active</button>
                                            <button type="button" className={this.state.searchActiveInactiveAllRecords === "all" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.loadTransactionTypes("all")}>All</button>
                                        </div>
                                    </div>
                                </div>
                        {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading &&
                                <table className="table table-bordered table-striped">
                                <thead>
                                    <tr>                                        
                                        <th>Module</th>
                                        <th>Transaction Type</th>
                                        <th>Numbering Format</th>
                                        <th>Options</th>
                                        <th>Revision History</th>
                                        <th style={btnColStyle}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.transactions.map((transactionType,index) =>
                                    <tr>
                                        <td>{transactionType.Module}</td>
                                        <td>
                                        {transactionType.TransactionType} 
                                        </td>
                                        <td>
                                            {transactionType.NumberingFormatDisplay}
                                        </td>
                                        <td>
                                            <div>Show in sales report: {transactionType.SalesImpactChecked ? "yes" :"no"}</div>
                                            <div>Allow payment modes: {transactionType.PaymentModeChecked ? "yes" :"no"}</div>
                                            <div>Calculate taxes: {transactionType.CalculateTaxChecked ? "yes" :"no"}</div>
                                            <div>Customer selection manadatory: {transactionType.CustomerMandatoryChecked ? "yes" :"no"}</div>
                                            <div>Employee tagging mandatory: {transactionType.EmployeeMandatoryChecked ? "yes" :"no"}</div>
                                            <div>Discount allowed: {transactionType.DiscountChecked ? "yes" :"no"}</div>
                                        </td>
                                        <td>
                                        {transactionType.hasOwnProperty('Action') && <React.Fragment>
                                            <div>Action :{transactionType.Action}</div>
                                            <div>Action By :{transactionType.ActionBy}</div>                                                        
                                            <div>Action Date :{(new Date(transactionType.ActionOn.seconds*1000)).toString().substring(0,24)}</div>
                                            <AuditTrail {...this.props} module={constants.MODULE_PRODUCT_TAX} parentKey={transactionType.key}/>                                                                                                
                                            </React.Fragment>}
                                        </td>
                                        <td>
                                            <div className="btn-group">
                                            { transactionType.IsDeleted ? <span className="label label-danger pull-right">Inactive</span> : 
                                                <div>
                                                    <button type="button" className="btn btn-md btn-default btn-flat" 
                                                    onClick={() => this.props.history.push({pathname:"/home/settings/moduleconfig/edit",state:{transactionType:transactionType}}) }>
                                                    <i className="fa fa-edit"></i></button>
                                                    <Confirm
                                                        onConfirm={() => this.onTransactionTypeDelete(transactionType.key)}
                                                        body="Are you sure you would like to delete this transaction type?"
                                                        confirmText="Delete"
                                                        title="Transaction Type Delete">
                                                        <button className="btn btn-default btn-flat"><i className="fa fa-trash-o"></i></button>
                                                    </Confirm>
                                                </div>    
                                            }
                                            </div>
                                        </td>
                                     </tr>
                                    )}                                            
                                </tbody>
                            </table>}
                        </div>   
                    </div>
                </div>
            </div>
        </section>
    </div>   
    }
}

export default TransactionTypeList