import React from 'react'
import * as actions from '../../actionTypes'

class AddEditCashRegister extends React.Component{
    constructor () {
        super()
        this.state={
            saving: false,
            cashRegisterCode:'',
            cashRegisterName:'',
            storeLocationID:'',
            storeID:'',
            action:'',
            key:'',
            storeLocations:[],
            cashRegisterCodeError:false,
            cashRegisterNameError:false,
            locationError:false,
            storeLocation:''
        }
        this.onSaveCashRegister = this.onSaveCashRegister.bind(this)
    }

    componentDidMount()
    {
        this.setState({storeID:this.props.storeID, 
            action: this.props.action, storeLocations:this.props.storeLocations})
            if(this.props.cashRegister)
            {
                this.setState({key: this.props.cashRegister.key, 
                    cashRegisterCode : this.props.cashRegister.CashRegisterCode,
                    cashRegisterName: this.props.cashRegister.CashRegisterName,
                    storeLocationID: this.props.cashRegister.StoreLocationID
                })
            }
    }

    onSaveCashRegister()
    {
        this.setState({ saving:true})
        //check if  code and  name is entered 
        let cashRegisterCodeError = false
        let cashRegisterNameError = false 
        let locationError = false
        if(this.state.cashRegisterCode.trim()==="")
        {cashRegisterCodeError= true}
        if(this.state.cashRegisterName.trim()==="")
        {cashRegisterNameError= true}
        if(this.state.storeLocationID.trim()==="")
        {locationError= true}
        this.setState({ cashRegisterCodeError:cashRegisterCodeError,
            cashRegisterNameError:cashRegisterNameError})
        if(cashRegisterCodeError || cashRegisterNameError)
        {return}
        //check code is unique
        let cashRegister = {CashRegisterCode: this.state.cashRegisterCode,
        CashRegisterName:this.state.cashRegisterName,
        StoreLocationID: this.state.storeLocationID,
        StoreLocation: this.state.storeLocation,
        StoreID: this.state.storeID,
        RegistrationID: this.props.user.user.RegistrationID,
        IsDeleted: false}
        
        if(this.state.action === actions.CASH_REGISTER_NEW)
        {
            this.props.addCashRegister(cashRegister).then(()=> {
                this.props.getToastr("Cash Register Saved Successfully")
                this.setState({ saving:false})
                this.props.onSave()
            })
        }
        if(this.state.action === actions.CASH_REGISTER_EDIT)
        {
            this.props.updateCashRegister(cashRegister,this.state.key).then(()=> {
                this.props.getToastr("Cash Register Saved Successfully")
                this.setState({ saving:false})
                this.props.onSave()})
        }
    }

    render(){
        return <section className="content" >
        <section className="content-header">
               {this.state.action === actions.CASH_REGISTER_NEW &&  <h1> Add New Cash Register</h1>}
               {this.state.action === actions.CASH_REGISTER_EDIT &&  <h1> Edit Cash Register</h1>}
        </section> 
            <div className="box-body">                            
            <div className={this.state.cashRegisterCodeError ? "form-group has-error" : "form-group "} >
                    <label>Cash Register Code</label>
                    <input type = "text" className="form-control" 
                    onChange={(evt)=> this.setState({cashRegisterCode:evt.target.value,cashRegisterCodeError:"" })}
                    value={this.state.cashRegisterCode}/>
                    {this.state.cashRegisterCodeError &&  <span class="help-block">Please provide code</span> }
                </div>
                <div className={this.state.cashRegisterNameError ? "form-group has-error" : "form-group "} >
                    <label>Cash Register Name</label>
                    <input type = "text" className="form-control" 
                    onChange={(evt)=> this.setState({cashRegisterName:evt.target.value, cashRegisterNameError:""})}
                    value={this.state.cashRegisterName}/>
                    {this.state.cashRegisterNameError &&  <span class="help-block">Please provide name</span> }

                </div>
                <div className={this.state.locationError ? "form-group has-error" : "form-group "} >
                    <label>Location </label>
                    <select className="form-control"
                        value={this.state.storeLocationID} 
                        onChange= {(evt)=> {this.setState({storeLocationID: evt.target.value,storeLocation:evt.target.options[evt.target.selectedIndex].text, locationError:""})}}>
                            <option value={-1}>Select Location</option>
                            {this.state.storeLocations.map((storeLocation)=> 
                            <option value={storeLocation.key}>{storeLocation.LocationCode} - {storeLocation.LocationName}</option>
                            )}
                    </select>
                    {this.state.locationError &&  <span class="help-block">Please select location</span> }
                </div>
                
            </div>
            <div className="box-footer">
                <button type="submit" className="btn btn-md btn-flat btn-default" onClick={this.props.onClose} >Cancel</button>
                <button type="submit" className="btn btn-flat btn-md btn-flat btn-success pull-right" onClick={this.onSaveCashRegister} disabled={this.state.saving}>Save</button>
            </div>
    </section>
    }
}

export default AddEditCashRegister