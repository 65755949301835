import React, { Component } from 'react';
import {Link } from 'react-router-dom';
import Confirm from 'react-confirm-bootstrap'
import Pagination from "react-js-pagination"
import Loader from '../Loader'
import ImportData from '../ImportData'
import ImportLog from '../ImportLog'
import Modal from 'react-responsive-modal'
import {HotKeys} from 'react-hotkeys';
import AuditTrail from '../AuditTrail'
import * as constants from '../../Constatnts'
import { isNullOrUndefined } from 'util'

const keyMap = {
    new:'ctrl+n'
};

class ProductList extends Component{
    constructor () {
        super()
        this.state={
            exportCalled: false,
            showFilters:false,
            searchText:'',
            searchCategory:'',
            searchSubCategory:'',
            searchBrand:'',
            searchTags:'',
            searchDescription:'',
            searchHSNSAC:'',
            activePage:1,
            itemsCountPerPage:20,
            totalItemsCount:0,
            recordFrom:0,
            paginationStatus:'No records to display',
            searchActiveInactiveAllRecords:'active',
            loading: true,
            products:[],
            showImportLog:false,
            showImport:false
        }
        this.onProductDelete = this.onProductDelete.bind(this)
        this.loadProducts= this.loadProducts.bind(this)
        this.showHideFilters = this.showHideFilters.bind(this)
        this.onClearSearch = this.onClearSearch.bind(this)
        this.onSearchActiveInactiveAllRecordsClicked = this.onSearchActiveInactiveAllRecordsClicked.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.showPaginationStatus= this.showPaginationStatus.bind(this)
        this.openNew = this.openNew.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.onExportClick = this.onExportClick.bind(this)
    }

    onExportClick() {
        this.setState({ exportCalled: true })
        this.props.getProducts(this.props.user.user.RegistrationID, 
            0, 10000,
            this.state.searchActiveInactiveAllRecords,
            this.state.searchText,
            this.state.searchCategory,
            this.state.searchSubCategory,
            this.state.searchBrand,
            this.state.searchTags,
            this.state.searchDescription,
            this.state.searchHSNSAC)
            .then((result) => {
                // console.log(result.searchResult);
                if (isNullOrUndefined(result.searchResult) || result.searchResult.length === 0) {
                    this.props.getToastr("No Record Found")
                    return
                }
                let products = []
                result.searchResult.map((data) => {
                    // console.log(JSON.stringify(data))
                    let product = {
                        "ProductID":data.key,
                        "SKU": data.SKU,
                        "Barcode": !isNullOrUndefined(data.BarCode) ? data.BarCode : "",
                        "Brand": !isNullOrUndefined(data.Brand) ? (data.Brand) : "",
                        "Cateogry": data.Category,
                        "SubCateogry": !isNullOrUndefined(data.SubCategory) ? data.SubCategory : "",
                        "Name": data.Name,
                        "Description": !isNullOrUndefined(data.Description) ? data.Description : "",
                        "HSNSACCode": !isNullOrUndefined(data.HSNSACCode) ? (data.HSNSACCode) : "",
                        "ChargeTaxOnProduct": !isNullOrUndefined(data.ChargeTaxOnProduct) ? (data.ChargeTaxOnProduct) : "",
                        "Type": data.ProductType,
                        "SupplyPrice": !isNullOrUndefined(data.SupplyPrice) ? (data.SupplyPrice) : "",
                        "Markup": !isNullOrUndefined(data.Markup) ? (data.Markup) : "",
                        "RetailPrice": !isNullOrUndefined(data.RetailPrice) ? (data.RetailPrice) : "",
                        "MRP": !isNullOrUndefined(data.MRP) ? (data.MRP) : "",
                        "TaxInclusive": !isNullOrUndefined(data.TaxInclusive) ? (data.TaxInclusive) : "",
                        "TaxGroup": !isNullOrUndefined(data.TaxGroup) ? (data.TaxGroup) : ""
                    }
                    products.push(product);
                })

                const csvData = constants.objToCSV(products);
                this.setState({ loading: false, exportCalled: false })
                const csvBlob = new Blob([csvData], { type: "text/csv" });
                const url = window.URL.createObjectURL(csvBlob);
                const a = document.createElement("a");
                a.setAttribute("hidden", "");
                a.setAttribute("href", url);
                a.setAttribute("download", "products.csv");
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
    }
    componentDidMount()
    {
        this.loadProducts(this.state.recordFrom,
            this.state.searchActiveInactiveAllRecords,
            this.state.searchText,
            this.state.searchCategory,
            this.state.searchSubCategory,
            this.state.searchBrand,this.state.searchTags,this.state.searchDescription, this.state.searchHSNSAC)
    }
    
    handleKeyPress(e){
        if (e.key === 'Enter') {
            this.onSearchClick()
          }
      }
      
      openNew(){
        this.props.history.push({pathname:"/home/product/newproduct"})
      }

    onProductDelete(key){
        this.props.deleteProduct(key).then(()=> {
            this.props.getToastr("Product deleted successfully")
            this.setState({loading:true})
            setTimeout(function () { //Start the timer
                this.loadProducts(this.state.recordFrom,
                    this.state.searchActiveInactiveAllRecords,
                    this.state.searchText,
                    this.state.searchCategory,
                    this.state.searchSubCategory,
                    this.state.searchBrand,this.state.searchTags,this.state.searchDescription, this.state.searchHSNSAC)
            }.bind(this), 2000)
           
       })
    }
    
    loadProducts(from, option , searchText, searchCategory,searchSubCategory,searchBrand, 
        searchTags, searchDescription, searchHSNSAC){
    //     let searchParameters =   {
    //         "from": from,
    //         "size": this.state.itemsCountPerPage, 
    //         "query": {
    //           "bool": {
    //             "must": [
    //               { "match": { "RegistrationID": this.props.user.user.RegistrationID}}
    //             ],
    //             "must_not": [
    //             ], 
    //             "should": [
                
    //             ],
    //             "minimum_should_match": 1,
    //             "filter": {
    //               "bool": {
    //                 "must": [
                       
    //                   ]
    //               }
    //             }
    //           }
    //         }
    //       }
    //       if(option === "active")
    //       {
    //         searchParameters.query.bool.must_not.push( {"match": { "IsDeleted": "true" }})
    //       }
    //       if(option === "inactive")
    //       {
    //         searchParameters.query.bool.must_not.push( {"match": { "IsDeleted": "false" }})
    //       }
    //       searchParameters.query.bool.should.push({ "regexp": { "Name": "@"+ searchText.trim() +  ".*" }})
    //       searchParameters.query.bool.should.push({ "regexp": { "SKU": "@"+ searchText.trim() +  ".*" }})
    //       searchParameters.query.bool.should.push({ "regexp": { "BarCode": "@"+ searchText.trim() +  ".*" }})
       
    //       if(searchCategory.trim()!=="")
    //       {
    //         searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Category": "@"+ searchCategory +  ".*" }})
    //       }
    //       if(searchSubCategory.trim()!=="")
    //       {
    //         searchParameters.query.bool.filter.bool.must.push({ "regexp": { "SubCategory": "@"+ searchSubCategory +  ".*" }})
    //       }
    //       if(searchBrand.trim()!=="")
    //       {
    //         searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Brans": "@"+ searchBrand +  ".*" }})
    //       }
    //       if(searchTags.trim()!=="")
    //       {
    //         searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Tags": "@"+ searchTags +  ".*" }})
    //       }
    //       if(searchBrand.trim()!=="")
    //       {
    //         searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Description": "@"+ searchDescription +  ".*" }})
    //       }
    //       if(searchHSNSAC.trim()!=="")
    //       {
    //         searchParameters.query.bool.filter.bool.must.push({ "regexp": { "HSNSACCode": "@"+ searchHSNSAC +  ".*" }})
    //       }

          //////console.log(searchParameters)
          this.setState({loading:true})
        this.props.getProducts(this.props.user.user.RegistrationID, from, this.state.itemsCountPerPage, option , searchText, searchCategory,searchSubCategory,searchBrand, 
            searchTags, searchDescription, searchHSNSAC)
        .then((result)=>{
            // customers = result.searchResult
            // totalItemsCount = result.totalItemsCount
            this.setState({products:result.searchResult, totalItemsCount: result.totalItemsCount, loading: false })
           //console.log(result.searchResult)
            this.showPaginationStatus()
        })
    }

    showHideFilters()
    {
        let showFilters = this.state.showFilters
        this.setState({showFilters: !showFilters})
    }

    onClearSearch()
    {
        this.setState({recordFrom:0, searchText:'',searchCategory:'', 
        searchSubCategory:'', searchBrand:'', searchTags:'', searchDescription:'',searchHSNSAC:'',
        showFilters:false,
        searchActiveInactiveAllRecords:'active'})
        this.loadProducts(0,"active","","","","","","","")
    }

    onSearchActiveInactiveAllRecordsClicked(option)
    {
        this.setState({searchActiveInactiveAllRecords:option, recordFrom:0})
        this.loadProducts(0,
            option, 
            this.state.searchText, 
            this.state.searchCategory, 
            this.state.searchSubCategory, 
            this.state.searchBrand, 
            this.state.searchTags, 
            this.state.searchDescription, 
            this.state.searchHSNSAC)
    }

    onSearchClick(){
        this.setState({searchFrom:0})
        this.loadProducts(0,
            this.state.searchActiveInactiveAllRecords, 
            this.state.searchText, 
            this.state.searchCategory, 
            this.state.searchSubCategory, 
            this.state.searchBrand, 
            this.state.searchTags, 
            this.state.searchDescription, 
            this.state.searchHSNSAC)
    

    }

    handlePageChange(pageNumber) {
        ////console.log(`active page is ${pageNumber}`);
       // searchFrom = this.state.searchFrom
       let recordFrom = ((pageNumber -1) * this.state.itemsCountPerPage)
        this.setState({activePage: pageNumber,recordFrom:recordFrom});
        this.loadProducts(recordFrom,
            this.state.searchActiveInactiveAllRecords, 
            this.state.searchText, 
            this.state.searchCategory, 
            this.state.searchSubCategory, 
            this.state.searchBrand, 
            this.state.searchTags, 
            this.state.searchDescription, 
            this.state.searchHSNSAC)
      }

      showPaginationStatus()
      {
          //console.log('this.state.recordFrom', this.state.recordFrom)
            let paginationStatus 
            if((this.state.recordFrom +1)  === this.state.totalItemsCount)
            {
                paginationStatus= "Showing " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
            }
            else if((this.state.recordFrom + this.state.itemsCountPerPage + 1 )> this.state.totalItemsCount )
            {
                paginationStatus= "Showing " + (this.state.recordFrom +1) + " to  " + this.state.totalItemsCount + " of " + this.state.totalItemsCount 
            }
            else{
                paginationStatus= "Showing " + (this.state.recordFrom +1 )+ " to  " + (this.state.recordFrom + this.state.itemsCountPerPage ) + " of " + this.state.totalItemsCount 

            }
            this.setState({paginationStatus:paginationStatus})

      }

     

    render() {    
        const imgStyle = {width:"50px"}
        const imgColStyle = {width:"60px"}
        const btnColStyle = {width:"100px"}
         const handlers = {
            new:this.openNew
        };

        const revisionHistory = { width: "200px" }
        return <HotKeys
        keyMap={keyMap}
        handlers={handlers}><div className="content-wrapper" >
        <section className="content" >
            <div className="row">
                <div className="col-md-12">
                    <div className="box ">
                        <div className="box-header with-border">
                            <div className="row">
                                <div className="col-md-6">
                                    <font style={{fontSize:"30px"}}>Products</font>
                                </div>
                                <div className="col-md-6">
                                    <div className="pull-right" style={{marginTop:"6px"}}>
                                        <button type="button" className="btn btn-default dropdown-toggle btn-flat  btn-md" data-toggle="dropdown">Action
                                            <span className="fa fa-caret-down"></span></button>
                                            <ul class="dropdown-menu" style={{right:"auto"}}> 
                                                <li><a href="#" onClick={()=> {this.setState({showImport:true})}}>Import</a></li>
                                                <li class="divider"></li>
                                                <li><a href="#" onClick={()=> {this.setState({showImportLog:true})}}>Import Log</a></li>
                                                <li class="divider"></li>                                                
                                                <li> <a onClick={this.onExportClick} > Export <i className="glyphicon glyphicon-download-alt " ></i></a></li>
                                            </ul>
                                            <text>  </text>
                                            
                                        <button  onClick={this.openNew}
                                        className="btn btn-success btn-flat  btn-md" >Add Product</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box-body">
                            <div className="row">
                                <div className="col-md-12" style={{marginBottom:"10px"}}>
                                    <div className="btn-group">
                                        <button type="button" className={this.state.searchActiveInactiveAllRecords === "active" ?  "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md" }
                                        onClick={()=> this.onSearchActiveInactiveAllRecordsClicked("active")}>Active</button>
                                        <button type="button" className={this.state.searchActiveInactiveAllRecords === "inactive" ?  "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md" }
                                        onClick={()=> this.onSearchActiveInactiveAllRecordsClicked("inactive")}>In Active</button>
                                        <button type="button" className={this.state.searchActiveInactiveAllRecords === "all" ?  "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md" }
                                        onClick={()=> this.onSearchActiveInactiveAllRecordsClicked("all")}>All</button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="form-group">
                                        <label>Search by product name, SKU, barcode</label>
                                            <input type="text" className="form-control input-md"
                                            autoFocus={true}
                                            onKeyPress={this.handleKeyPress}
                                            value={this.state.searchText} 
                                            onChange={(evt)=>this.setState({searchText:evt.target.value})}/>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <button type="button" className="btn btn-primary btn-flat btn-md pull-right" 
                                    style={{marginTop:"25px", marginLeft:"5px"}}
                                    onClick={this.onSearchClick}
                                    >Search</button>
                                    <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{marginTop:"25px"}} 
                                    onClick={this.onClearSearch} >Clear</button>
                                    <a type="button" className="btn btn-md pull-right" style={{marginTop:"25px"}} onClick={this.showHideFilters}>{this.state.showFilters ? "Less Filter" : "More Filters"}</a>
                                </div>
                            </div>
                            {this.state.showFilters && <div className="row">
                                <div className="col-md-9">
                                    <div className="col-md-3" style={{paddingLeft:"0px"}}>
                                        <div className="form-group">
                                            <label>Category</label>
                                            <input type="text" className="form-control input-md" 
                                            onKeyPress={this.handleKeyPress}
                                            value={this.state.searchCategory} 
                                            onChange={(evt)=>this.setState({searchCategory:evt.target.value})}/>
                                        </div>
                                    </div>
                                    <div className="col-md-3" style1={{padding:"0px"}}>
                                        <div className="form-group">
                                            <label>Sub Category</label>
                                            <input type="text" className="form-control input-md"  
                                            onKeyPress={this.handleKeyPress}
                                            value={this.state.searchSubCategory} 
                                            onChange={(evt)=>this.setState({searchSubCategory:evt.target.value})}/>
                                        </div>
                                    </div>
                                    <div className="col-md-3" style1={{padding:"0px"}}>
                                        <div className="form-group">
                                            <label>Brand</label>
                                            <input type="text" className="form-control input-md"  
                                            onKeyPress={this.handleKeyPress}
                                            value={this.state.searchBrand} 
                                            onChange={(evt)=>this.setState({searchBrand:evt.target.value})}/>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {this.state.showFilters && <div className="row">
                                <div className="col-md-9">
                                    <div className="col-md-3" style={{paddingLeft:"0px"}}>
                                        <div className="form-group">
                                            <label>Description</label>
                                            <input type="text" className="form-control input-md" 
                                            onKeyPress={this.handleKeyPress}
                                            value={this.state.searchDescription} 
                                            onChange={(evt)=>this.setState({searchDescription:evt.target.value})}/>
                                        </div>
                                    </div>
                                    <div className="col-md-3" style1={{padding:"0px"}}>
                                        <div className="form-group">
                                            <label>HSN/SAC</label>
                                            <input type="text" className="form-control input-md"  
                                            onKeyPress={this.handleKeyPress}
                                            value={this.state.searchHSNSAC} 
                                            onChange={(evt)=>this.setState({searchHSNSAC:evt.target.value})}/>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {this.state.loading && <Loader show={this.state.loading} />}
                            {!this.state.loading &&
                                <table className = "table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th style={imgColStyle}></th>
                                        <th>Type</th>
                                        <th>SKU</th>
                                        <th>Name</th>
                                        <th>Category</th>
                                        <th>Sub Category</th>
                                        <th>Brand</th>
                                        <th>Retail Price</th>
                                        <th style={revisionHistory}>Revision History</th>
                                        <th style={btnColStyle}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.products.map((product) =>
                                    <tr>
                                        <td class="product-img">
                                        {product.Images && <img  style={imgStyle} src={product.Images[0] ? product.Images[0].ImagePath : require('../../images/product.png')}/>}
                                        </td>
                                        <td>{product.ProductType}</td>
                                        <td>{product.SKU}</td>
                                        <td>{product.Name}</td>
                                        <td>{product.Category}</td>
                                        <td>{product.SubCategory}</td>
                                        <td>{product.Brand}</td>
                                        <td className="pull-right">{product.RetailPrice}</td>
                                        <td>
                                                        <div>Action :{product.Action}</div>
                                                        <div>Action By :{product.ActionBy}</div>                                                        
                                                        <div>Action Date :{(new Date(product.ActionOn)).toString().substring(0,24)}</div>
                                                        <AuditTrail {...this.props} module={constants.MODULE_PRODUCT} parentKey={product.key}/>
                                                    </td>
                                        <td>
                                            <div class="btn-group">
                                            { product.IsDeleted ? <span class="label label-danger pull-right">Inactive</span> : 
                                                <div>
                                                    <Link to={`/home/product/edit/${product.key}`}   type="button" class="btn btn-md btn-default btn-flat"><i class="fa fa-edit"></i></Link>                                            
                                                    <Confirm
                                                        onConfirm={() => this.onProductDelete(product.key)}
                                                        body="Are you sure you would like to delete this product?"
                                                        confirmText="Yes"
                                                        title="Product Delete">
                                                        <button className="btn btn-default btn-flat"><i class="fa fa-trash-o"></i></button>
                                                    </Confirm>
                                                </div>    
                                            }
                                            {/* <Link to={`/home/product/edit/${product.key}`}   type="button" class="btn btn-md btn-default "><i class="fa fa-edit"></i></Link>
                                                <button type="button" class="btn btn-default btn-md" onClick={() => this.onProductDelete(product.key)}><i class="fa fa-trash-o"></i></button> */}
                                            </div>
                                        </td>
                                     </tr>
                                    )}                                            
                                </tbody>
                                </table>}
                                {this.state.totalItemsCount > 0 && <div className="pull-left" style={{marginTop:"25px"}}>
                                {this.state.paginationStatus}
                                </div>}
                                <div className="pull-right">
                                    <Pagination
                                    activePage={this.state.activePage} //this.state.activePage
                                    itemsCountPerPage={this.state.itemsCountPerPage}
                                    totalItemsCount={this.state.totalItemsCount}
                                    pageRangeDisplayed={2}
                                    onChange={this.handlePageChange}
                                    />
                                </div>
                        </div>   
                    </div>
                </div>
                </div>
                </section>
               <Modal open={this.state.showImport} onClose={() => this.setState({ showImport: false })} center>
                    <ImportData  {...this.props} importType="product" 
                    onClose={() => {
                        this.loadProducts(this.state.recordFrom,
                            this.state.searchActiveInactiveAllRecords,
                            this.state.searchText,
                            this.state.searchCategory,
                            this.state.searchSubCategory,
                            this.state.searchBrand,this.state.searchTags,this.state.searchDescription, this.state.searchHSNSAC)                
                            this.setState({ showImport: false })
                    }}
                    onImportCompleted={()=>{
                        this.loadProducts(this.state.recordFrom,
                            this.state.searchActiveInactiveAllRecords,
                            this.state.searchText,
                            this.state.searchCategory,
                            this.state.searchSubCategory,
                            this.state.searchBrand,this.state.searchTags,this.state.searchDescription, this.state.searchHSNSAC)                
                            this.setState({ showImport: false })
                    }}

                    />
               </Modal>
               <Modal open={this.state.showImportLog} onClose={() => this.setState({ showImportLog: false })} center>
                    <ImportLog  {...this.props} importType="product" 
                    onClose={() => this.setState({ showImportLog: false })}
                    />
               </Modal>
                </div>   
                </HotKeys>
    }
}

export default ProductList