import React, { Component } from 'react';
import Confirm from 'react-confirm-bootstrap'
import Modal from 'react-responsive-modal'
import AddEditProductCategory from './AddEditProductCategory'
import * as actionTypes from '../../actionTypes'
import Loader from '../Loader'
import { withRouter } from 'react-router-dom'
import AuditTrail from '../AuditTrail'
import * as constants from '../../Constatnts'

class ProductCategoriesList extends Component {
    constructor() {
        super()
        this.state = {
            productCategories:[],
            productSubCategories:[],
            showModal: false,
            selectedProductCategory: null,
            type: '',
            searchText: '',
            searchNotes: '', 
            itemsCountPerPage: 20,          
            recordFrom: 0,
            searchActiveInactiveAllRecords: 'active',
            loading: true
        }
        this.loadProductCategories = this.loadProductCategories.bind(this)
        this.onDeleteProductCategory = this.onDeleteProductCategory.bind(this)
        this.onPostProductCategorySave = this.onPostProductCategorySave.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.onClearSearch = this.onClearSearch.bind(this)
    }


    componentDidMount() {
        //console.log('this.props.location.state.type',this.props.location.state.type)
        this.setState({ type: this.props.location.state.type, loading: false })
        this.loadProductCategories(this.props.location.state.type, this.state.recordFrom,
            this.state.searchActiveInactiveAllRecords, this.state.searchText)
    }

    loadProductCategories(type, from, option, searchText) {
        if (type === actionTypes.PRODUCT_CATEGORY) {            
            this.props.searchProductCategories(this.props.user.user.RegistrationID, from, this.state.itemsCountPerPage, option, searchText).then((result)=>{
                this.setState({productCategories:result.searchResult,totalItemsCount: result.totalItemsCount, loading: false})
            });
        }
        else {
            this.props.searchProductSubCategories(this.props.user.user.RegistrationID, from, this.state.itemsCountPerPage, option, searchText).then((result)=>{
                this.setState({productSubCategories:result.searchResult,totalItemsCount: result.totalItemsCount, loading: false})
            });
        }
    }

    onPostProductCategorySave() {
        this.setState({ showModal: false })
        this.loadProductCategories(this.props.location.state.type, this.state.recordFrom,
            this.state.searchActiveInactiveAllRecords, this.state.searchText)
    }

    onDeleteProductCategory(key) {
        this.props.deleteProductCategory(key).then(() => {
            this.props.getToastr("Product Category deleted successfully")
            this.loadProductCategories(this.state.type, this.state.recordFrom,
                this.state.searchActiveInactiveAllRecords, this.state.searchText)
        })
    }

    onSearchClick() {
        this.setState({ searchFrom: 0 })
        this.loadProductCategories(this.state.type, 0, this.state.searchActiveInactiveAllRecords,
            this.state.searchText)
    }

    onClearSearch() {
        this.setState({
            recordFrom: 0, searchText: '',
            searchActiveInactiveAllRecords: "active"
        })
        this.loadProductCategories(this.state.type,0, "active", "")
    }

    onSearchActiveInactiveAllRecordsClicked(option) {
        this.setState({ searchActiveInactiveAllRecords: option, recordFrom: 0 })
        this.loadProductCategories(this.state.type, 0, option, this.state.searchText)
    }

    render() {
        const btnColStyle = { width: "95px" }
        const revisionHistory = { width: "200px" }
        const modalWidth = { width: "500px" }

        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6">
                                        <font style={{ fontSize: "30px" }}>{this.state.type === actionTypes.PRODUCT_CATEGORY ? "Category" : "Sub Category"}</font>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="pull-right" style={{ marginTop: "6px" }}>
                                            <button type="button" className="btn btn-success btn-flat btn-md"
                                                onClick={() => this.setState({
                                                    showModal: true,
                                                    selectedProductCategory: null
                                                })}>
                                                {this.state.type === actionTypes.PRODUCT_CATEGORY ? "Add Category" : "Add Sub Category"}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.state.type === actionTypes.PRODUCT_CATEGORY && <div className="box-body">
                            <div className="row">
                                    <div className="col-md-12" style={{ marginBottom: "10px" }}>
                                        <div className="btn-group">
                                            <button type="button" className={this.state.searchActiveInactiveAllRecords === "active" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.onSearchActiveInactiveAllRecordsClicked("active")}>Active</button>
                                            <button type="button" className={this.state.searchActiveInactiveAllRecords === "inactive" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.onSearchActiveInactiveAllRecordsClicked("inactive")}>In Active</button>
                                            <button type="button" className={this.state.searchActiveInactiveAllRecords === "all" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.onSearchActiveInactiveAllRecordsClicked("all")}>All</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-9">
                                        <div className="form-group">
                                            <label>Search by Category Name</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchText}
                                                onChange={(evt) => this.setState({ searchText: evt.target.value })} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginLeft: "5px" }}
                                            onClick={this.onSearchClick}
                                        >Search</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                            onClick={this.onClearSearch} >Clear</button>
                                    </div>
                                </div>
                                {this.state.loading && <Loader show={this.state.loading} />}

                                {!this.state.loading &&
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>Category</th>
                                                <th>Sequence</th>
                                                <th>Color</th>
                                                <th>Image</th>
                                                <th style={revisionHistory}>Revision History</th>
                                                <th style={btnColStyle}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.productCategories.map((category) => <tr>
                                                <td>{category.Name}</td>
                                                <td>{category.Sequence}</td>
                                                <td>{category.Color} {category.Color !== "" && <div style={{ width: "30px", height: "30px", backgroundColor: category.Color }}>   </div>}</td>
                                                <td>
                                                    {category.Image && <img style={{ width: "50px" }} src={category.Image.Preview} />}
                                                </td>
                                                <td>
                                                    <div>Action :{category.Action}</div>
                                                    <div>Action By :{category.ActionBy}</div>
                                                    <div>Action Date :{(new Date(category.ActionOn)).toString().substring(0,24)}</div>
                                                    <AuditTrail {...this.props} module={constants.MODULE_PRODUCT_CATEGORIES} parentKey={category.key}/>
                                                </td>
                                                <td>
                                                    <div className="btn-group">
                                                        {category.IsDeleted ? <span className="label label-danger pull-right ">Inactive</span> :
                                                            <div>
                                                                <button type="button" className="btn btn-md btn-default btn-flat"
                                                                    onClick={() => this.setState({
                                                                        showModal: true,
                                                                        selectedProductCategory: category
                                                                    })}>
                                                                    <i className="fa fa-edit"></i></button>
                                                                <Confirm
                                                                    onConfirm={() => this.onDeleteProductCategory(category.key)}
                                                                    body="Are you sure you would like to delete this record?"
                                                                    confirmText="Delete"
                                                                    title="Category Delete">
                                                                    <button className="btn btn-default btn-flat"><i className="fa fa-trash-o"></i></button>
                                                                </Confirm>
                                                            </div>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                            )}
                                        </tbody>
                                    </table>}
                            </div>}

                            {this.state.type === actionTypes.PRODUCT_SUB_CATEGORY && <div className="box-body">
                            <div className="row">
                                    <div className="col-md-12" style={{ marginBottom: "10px" }}>
                                        <div className="btn-group">
                                            <button type="button" className={this.state.searchActiveInactiveAllRecords === "active" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.onSearchActiveInactiveAllRecordsClicked("active")}>Active</button>
                                            <button type="button" className={this.state.searchActiveInactiveAllRecords === "inactive" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.onSearchActiveInactiveAllRecordsClicked("inactive")}>In Active</button>
                                            <button type="button" className={this.state.searchActiveInactiveAllRecords === "all" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.onSearchActiveInactiveAllRecordsClicked("all")}>All</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-9">
                                        <div className="form-group">
                                            <label>Search by Sub-Category Name</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchText}
                                                onChange={(evt) => this.setState({ searchText: evt.target.value })} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginLeft: "5px" }}
                                            onClick={this.onSearchClick}
                                        >Search</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                            onClick={this.onClearSearch} >Clear</button>
                                    </div>
                                </div>
                                {this.state.loading && <Loader show={this.state.loading} />}

                                {!this.state.loading &&
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>Category</th>
                                                <th>Sub Category</th>
                                                <th>Sequence</th>
                                                <th>Color</th>
                                                <th>Image</th>
                                                <th style={revisionHistory}>Revision History</th>
                                                <th style={btnColStyle}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.productSubCategories.map((category) =>
                                                <tr>
                                                    <td>{category.Parent}</td>
                                                    <td>{category.Name}</td>
                                                    <td>{category.Sequence}</td>
                                                    <td>{category.Color}  {category.Color !== "" && <div style={{ width: "30px", height: "30px", backgroundColor: category.Color }}>   </div>}</td>
                                                    <td>
                                                        {category.Image && <img style={{ width: "50px" }} src={category.Image.Preview} />}
                                                    </td>
                                                    <td>
                                                        <div>Action :{category.Action}</div>
                                                        <div>Action By :{category.ActionBy}</div>                                                        
                                                        <div>Action Date :{(new Date(category.ActionOn)).toString().substring(0,24)}</div>
                                                        <AuditTrail {...this.props} module={constants.MODULE_PRODUCT_CATEGORIES} parentKey={category.key}/>
                                                    </td>
                                                    <td>
                                                        <div className="btn-group">
                                                            {category.IsDeleted ? <span className="label label-danger pull-right ">Inactive</span> :
                                                                <div>
                                                                    <button type="button" className="btn btn-md btn-default btn-flat"
                                                                        onClick={() => this.setState({
                                                                            showModal: true,
                                                                            selectedProductCategory: category
                                                                        })}>
                                                                        <i className="fa fa-edit"></i></button>
                                                                    <Confirm
                                                                        onConfirm={() => this.onDeleteProductCategory(category)}
                                                                        body="Are you sure you would like to delete this record?"
                                                                        confirmText="Delete"
                                                                        title="Sub Category Delete">
                                                                        <button className="btn btn-default btn-flat"><i className="fa fa-trash-o"></i></button>
                                                                    </Confirm>
                                                                </div>
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>}
                            </div>}

                        </div>
                    </div>
                </div>
                <Modal open={this.state.showModal} onClose={() => this.setState({ showModal: false })} center>
                    <div style={modalWidth}>
                        <AddEditProductCategory
                            {...this.props}
                            productCategory={this.state.selectedProductCategory}
                            parentID=""
                            parent=""
                            // disableCategory = {false}
                            type={this.state.type}
                            onSave={() => this.onPostProductCategorySave()}
                            onClose={() => this.setState({ showModal: false })} />
                    </div>
                </Modal>
            </section>
        </div>
    }
}

export default withRouter(ProductCategoriesList)