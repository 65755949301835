import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Confirm from 'react-confirm-bootstrap';
import * as actions from '../../actionTypes'
import Loader from '../Loader'
import AuditTrail from '../AuditTrail'
import * as constants from '../../Constatnts'

class Offers extends Component {
    constructor() {
        super();
        this.state = {
            offers: [],
            searchActiveInactiveAllRecords: 'active',
            loading: true
        }

        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.loadOffers = this.loadOffers.bind(this)
        this.onEditOffer = this.onEditOffer.bind(this)
        this.onDeleteOffer = this.onDeleteOffer.bind(this)
    }

    componentDidMount() {
        this.loadOffers(this.state.searchActiveInactiveAllRecords)
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSave()
        }
    }

    loadOffers(option) {
        this.props.getOffers(this.props.user.user.RegistrationID).then((alloffers) => {
            let offers = []
            if (option === "all") {
                offers = alloffers
            }
            else if (option === "active") {
                offers = alloffers.filter((e) => !e.IsDeleted)
            }
            else if (option === "inactive") {
                offers = alloffers.filter((e) => e.IsDeleted)
            }
            this.setState({ offers: offers, searchActiveInactiveAllRecords: option, loading: false})
        })
    }

    onEditOffer(offer) {
        this.setState({ action: actions.EDIT_OFFER })
    }

    onDeleteOffer(key) {
        this.props.deleteOffer(key).then(() => {
            this.loadOffers(this.state.searchActiveInactiveAllRecords)
            this.props.getToastr("Offer deleted successfully")
        })
    }

    render() {
        const btnColStyle = { width: "120px" }
        return <div className="content-wrapper" >
            <section className="content">
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6">
                                        <font style={{ fontSize: "30px" }}>Offers</font>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="pull-right" style={{ marginTop: "6px" }}>
                                            <Link to="/home/catalogue/newoffer" className="btn btn-success btn-flat btn-md" >New Offer</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-12" style={{ marginBottom: "10px" }}>
                                        <div className="btn-group">
                                            <button type="button" className={this.state.searchActiveInactiveAllRecords === "active" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.loadOffers("active")}>Active</button>
                                            <button type="button" className={this.state.searchActiveInactiveAllRecords === "inactive" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.loadOffers("inactive")}>In Active</button>
                                            <button type="button" className={this.state.searchActiveInactiveAllRecords === "all" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.loadOffers("all")}>All</button>
                                        </div>
                                    </div>
                                </div>
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading &&
                                <table className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>Offer Name</th>
                                            <th>Offer Details</th>
                                            <th>Description</th>
                                            <th>Revision History</th>
                                            <th style={btnColStyle}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.offers.map((offer) =>
                                            <tr>
                                                <td>{offer.Name}
                                                    {offer.Description !== "" && <div>{offer.Description}</div>}
                                                </td>
                                                <td>Validity From: {offer.ValidityFrom} Validity To : {offer.ValidityTo}
                                                    {offer.SpecificDay === "Yes" && <div>{"Specific Days: " + offer.SpecificDays}</div>}
                                                    {offer.SpecificTime === "Yes" && <div>Specific Time : {offer.OfferStartTime.HH + ":" + offer.OfferStartTime.MM + " " + offer.OfferStartTime.AMPM} - {offer.OfferEndTime.HH + ":" + offer.OfferEndTime.MM + " " + offer.OfferEndTime.AMPM}</div>}
                                                    {(offer.AllStores || offer.Stores) && <td>
                                                        {offer.Stores && offer.Stores.map((store) =>
                                                            <div>Stores : {store.label}</div>
                                                        )}
                                                        {offer.AllStores && <div>Stores: All Stores</div>}
                                                    </td>}                                     
                                                </td>
                                                <td>
                                                    <div>Offer Type : {offer.OfferType}</div>
                                                    {offer.PurchaseGroup !== "" && <div>Purchase Group :{offer.PurchaseGroup}</div>}
                                                    {offer.PurchaseQty !== 0 && <div>Purchase Qty : {offer.PurchaseQty}</div>}
                                                    {offer.FreeGroup !== "" && <div>Free Group : {offer.FreeGroup}</div>}
                                                    {offer.FreeQty !== 0 && <div>Free Qty : {offer.FreeQty}</div>}
                                                    {offer.InvoiceAmount !== 0 && <div>Invoice Amount : {offer.InvoiceAmount}</div>}
                                                    {offer.Discount !== "" && <div>Discount Type : {offer.Discount}</div>}
                                                    {offer.DiscountValue !== 0 && <div>Discount Value : {offer.DiscountValue}</div>}
                                                    {offer.OfferGroup !== "" && <div>Offer Group : {offer.OfferGroup}</div>}
                                                </td>
                                                <td>
                                                    {offer.hasOwnProperty('Action') && <React.Fragment>
                                                        <div>Action :{offer.Action}</div>
                                                        <div>Action By :{offer.ActionBy}</div>
                                                        <div>Action Date :  {offer.ActionOn ? (new Date(offer.ActionOn.seconds*1000)).toString().substring(0,24): ""}</div>
                                                        <AuditTrail {...this.props} module={constants.MODULE_OFFER} parentKey={offer.key} />
                                                    </React.Fragment>}
                                                </td>
                                                <td>
                                                    <div className="btn-group">
                                                        {offer.IsDeleted ? <span className="label label-danger pull-right">Inactive</span> :
                                                            <div>
                                                                <Link to={`/home/catalogue/edit/${offer.key}`}
                                                                    type="button" className="btn btn-md btn-default btn-flat"><i className="fa fa-edit"></i></Link>
                                                                <Confirm
                                                                    onConfirm={() => this.onDeleteOffer(offer.key)}
                                                                    body="Are you sure you would like to delete this offer?"
                                                                    confirmText="Delete"
                                                                    title="Delete Offer">
                                                                    <button className="btn btn-md btn-default btn-flat"><i className="fa fa-trash-o"></i></button>
                                                                </Confirm>
                                                            </div>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default withRouter(Offers)