export const AUDIT_TRAIL_NEW = 'NEW'
export const AUDIT_TRAIL_EDIT = 'EDIT'
export const AUDIT_TRAIL_DELETE = 'DELETE'

export const MODULE_PRODUCT_BRANDS ='MODULE_PRODUCT_BRANDS'
export const MODULE_PRODUCT_CATEGORIES ='MODULE_PRODUCT_CATEGORIES'
export const MODULE_PRODUCT = 'MODULE_PRODUCT'
export const MODULE_PRODUCT_TAX = 'MODULE_PRODUCT_TAX'

export const MODULE_CUSTOMER = 'MODULE_CUSTOMER'
export const MODULE_MEMBERSHIP = 'MODULE_MEMBERSHIP'

export const MODULE_EXPENSE = 'MODULE_EXPENSE'
export const MODULE_EXPENSE_CATEGORY = 'MODULE_EXPENSE_CATEGORY'

export const MODULE_REGISTRATION = 'MODULE_REGISTRATION'
export const MODULE_EMPLOYEE = 'MODULE_EMPLOYEE'
export const MODULE_VENDOR = 'MODULE_VENDOR'
export const MODULE_PAYMENT_TYPE = 'MODULE_PAYMENT_TYPE'
export const MODULE_TRANSACTION_TYPE = 'MODULE_TRANSACTION_TYPE'
export const MODULE_CUSTOM_FIELD = 'MODULE_CUSTOM_FIELD'

export const MODULE_STORE = 'MODULE_STORE'
export const MODULE_STORE_LOCATION = 'MODULE_STORE_LOCATION'
export const MODULE_CASH_REGISTER = 'MODULE_CASH_REGISTER'
export const MODULE_CASH_REGISTER_CLOSING= "MODULE_CASH_REGISTER_CLOSING"
export const MODULE_PRINT_CONFIG = 'MODULE_PRINT_CONFIG'

export const  MODULE_SALES = 'MODULE_SALES'
export const MODULE_ATTENDANCE = 'MODULE_ATTENDANCE'

export const MODULE_INVENTORY = 'MODULE_INVENTORY'
export const MODULE_PACKAGE = 'MODULE_PACKAGE'

export  const objToCSV = obj => {
    const csvRows = [];
    const headers = Object.keys(obj[0]);
    csvRows.push(headers.join(","));
  
    for (const row of obj) {
      const values = headers.map(header => {
        const escaped = ("" + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(","));
    }
  
    return csvRows.join("\n");
  };

  export const PRODUCT_TAG = 'PRODUCT_TAG'
  export const MODULE_TAG = 'MODULE_TAG'
  export const MODULE_OFFER = 'MODULE_OFFER'
  export const MODULE_PRODUCT_MODIFIER = 'MODULE_PRODUCT_MODIFIER'