import React, {Component} from 'react'

class AddEditTransactiontype extends Component{
    constructor () {
        super()
        this.state={
            saving: false,
            key:'',
            module:'sales',
            moduleError:'',
            transactionType:'',
            numberingFormat:[{Option:"text",Value:"", Separator:"", OptionError:"",ValueError:""},
            {Option:"storecode",Value:"", Separator:"", OptionError:"",ValueError:""},
            {Option:"cashregistercode",Value:"", Separator:"",OptionError:"",ValueError:""}
            ],
            salesImpactChecked:false,
            paymentModeChecked:false,
            calculateTaxChecked:false,
            customerMandatoryChecked:false,
            employeeMandatoryChecked:false,
            employeeManadatoryTransactionORLineItemLevel: "",
            paymentModeAllOrSpecific:"",
            discountChecked:false,
            discountPercentage:0,
            key:'',
            isSystemDefined:false,
            numberingOptions:[
                {value:"", text:"Select"},
                {value:"text", text:"Text"},
                {value:"storecode", text:"Store Code"},
                {value:"locationcode", text:"Location Code"},
                {value:"cashregistercode", text:"Cash Register Code"},
                {value:"fiscalyear", text:"Fiscal Year (YYYY)"},
                {value:"calendardmonthyear", text:"Calendar Month Year (MMYY)"},
                {value:"calendarmonth", text:"Calendar Month (MM)"},
                {value:"calendaryear", text:"Calendar Year (YYYY)"}
            ],
            numberingFormatDisplay:'',
            selectedTransactionType:null,
            updateStockChecked:false,
            updateStockInwardOrOutward:'',
            includePriceAndTax:false ,
            transactionMovement:'',
            includePriceAndTax:false ,
            balanceAmount:"partial"                              
        }
        this.onOptionChanged = this.onOptionChanged.bind(this)
        this.onSeparatorChange = this.onSeparatorChange.bind(this)
        this.onRemoveText = this.onRemoveText.bind(this)
        this.displayNumberFormat = this.displayNumberFormat.bind(this)
        this.onSaveTransactionType = this.onSaveTransactionType.bind(this)       
    }

    componentDidMount(){
        // console.log('this.props.location.state.transactionType',this.props.location.state.transactionType)
        if(this.props.location.state.transactionType !==null)
        {
            let transactionType = this.props.location.state.transactionType 
            let numberingFormatTemp = []
            transactionType.NumberingFormat.map((n,index)=>{
                numberingFormatTemp.push({Option:n.Option,Value:n.Value, Separator:n.Separator, OptionError:"",ValueError:""})
            })
            this.setState({selectedTransactionType:transactionType,
            key:transactionType.key,
            module:transactionType.Module,
            transactionType: transactionType.TransactionType,
            numberingFormat: numberingFormatTemp,
            numberingFormatDisplay:transactionType.NumberingFormatDisplay,
            salesImpactChecked:transactionType.SalesImpactChecked,
            paymentModeChecked:transactionType.PaymentModeChecked,
            calculateTaxChecked:transactionType.CalculateTaxChecked,
            customerMandatoryChecked:transactionType.CustomerMandatoryChecked,
            employeeMandatoryChecked:transactionType.EmployeeMandatoryChecked,
            employeeManadatoryTransactionORLineItemLevel: transactionType.EmployeeManadatoryTransactionORLineItemLevel,
            updateStockChecked:transactionType.hasOwnProperty('UpdateStockChecked') ? transactionType.UpdateStockChecked : false,
            updateStockInwardOrOutward:transactionType.hasOwnProperty('UpdateStockInwardOrOutward') ? transactionType.UpdateStockInwardOrOutward:false,
            transactionMovement: transactionType.hasOwnProperty('TransactionMovement') ? transactionType.TransactionMovement : '',
            includePriceAndTax: transactionType.hasOwnProperty('IncludePriceAndTax')  ? transactionType.IncludePriceAndTax :false,
            discountChecked:transactionType.DiscountChecked,
            isSystemDefined: transactionType.hasOwnProperty('IsSystemDefined') ?  transactionType.IsSystemDefined : false,
            balanceAmount: transactionType.hasOwnProperty('BalanceAmount') ?  transactionType.BalanceAmount : "partial"
        })
        }
    }

    onOptionChanged(index, value)
    {
        let numberingFormat = this.state.numberingFormat
        numberingFormat[index].Option = value
        this.setState({numberingFormat:numberingFormat})
        this.displayNumberFormat()
    }

    onSeparatorChange(index, value){
        let numberingFormat = this.state.numberingFormat
        numberingFormat[index].Separator = value
        this.setState({numberingFormat:numberingFormat})
        this.displayNumberFormat()
    }

    onRemoveText(index)
    {
        let numberingFormat = this.state.numberingFormat
        numberingFormat[index].Option = ""
        numberingFormat[index].Value = ""
        this.setState({numberingFormat:numberingFormat})
        this.displayNumberFormat()
    }

    onTextChange(index, value)
    {
        let numberingFormat = this.state.numberingFormat
        numberingFormat[index].Value = value
        numberingFormat[index].ValueError = ""
        this.setState({numberingFormat:numberingFormat})
        this.displayNumberFormat()
    }

    onSaveTransactionType(){
        this.setState({ saving:true})
        let transactionTypeError = ""
        let moduleNameError =""
        let numberingFormatError = false
        let numberingFormat = this.state.numberingFormat

        if(this.state.transactionType.trim() ==="")
        {
            transactionTypeError = "Please provide transaction type"
        }
        if(this.state.module.trim()==="")
        {
            moduleNameError = "Please select module"
        }
        this.state.numberingFormat.map((para, index) =>{
            let optionError = ""
            let valueError =""
            
            if(para.Option === "")
            {
                numberingFormatError = true
                optionError="Please select option"
            }
            if(para.Option === "text" && para.Value ==="")
            {
                numberingFormatError = true
                valueError="Please provide value"
            }
            numberingFormat[index].OptionError = optionError
            numberingFormat[index].ValueError = valueError
        })
        this.setState({numberingFormat:numberingFormat})

        if(transactionTypeError !== "" || moduleNameError !== "" || numberingFormatError)
        {
            this.setState({transactionTypeError: transactionTypeError, 
                moduleNameError:moduleNameError})
                // console.log('Error')
                return
        }

        let numberingFormatTemp = []
        this.state.numberingFormat.map((n,index)=>{
            numberingFormatTemp.push({Option:n.Option,Value:n.Value, Separator:n.Separator})
        })
        let transactionType  = {Module:this.state.module,
        TransactionType:this.state.transactionType,
        NumberingFormat:numberingFormatTemp,
        NumberingFormatDisplay:this.state.numberingFormatDisplay,
        SalesImpactChecked:this.state.salesImpactChecked,
        PaymentModeChecked:this.state.paymentModeChecked,
        CalculateTaxChecked:this.state.calculateTaxChecked,
        CustomerMandatoryChecked:this.state.customerMandatoryChecked,
        EmployeeMandatoryChecked:this.state.employeeMandatoryChecked,
        EmployeeManadatoryTransactionORLineItemLevel: this.state.employeeManadatoryTransactionORLineItemLevel,
        DiscountChecked:this.state.discountChecked,
        DiscountPercentage:this.state.discountPercentage,
        UpdateStockChecked:this.state.updateStockChecked ,
        UpdateStockInwardOrOutward: this.state.updateStockInwardOrOutward,
        IncludePriceAndTax: this.state.includePriceAndTax,
        TransactionMovement:this.state.transactionMovement,
        IsDeleted:false,
        BalanceAmount: this.state.balanceAmount,
        RegistrationID: this.props.user.user.RegistrationID}
        // console.log('saveTransactionType')
        this.props.saveTransactionType(transactionType, this.state.key).then(()=>{
            this.props.getToastr("Transaction type Saved Successfully")
            this.setState({ saving:false})            
            this.props.history.push("/home/settings/moduleconfig/")
        })
      
    }
    displayNumberFormat()
    {
        let numberingFormatDisplay =""
        this.state.numberingFormat.map((n,index)=>{
            let para = ""
            if(n.Option === "text")
            {
                para=n.Value
            }
            if(n.Option == "storecode")
            {
                para = "S01"
            }
            if(n.Option == "locationcode")
            {
                para = "L01"
            }
            if(n.Option == "cashregistercode")
            {
                para = "CR1"
            }
            if(n.Option == "calendardmonthyear")
            {
                let d = new Date();
                let a  = d.getFullYear() + ""
                para = d.getMonth()  + a.substring(2,4)
                if(para.length < 3)
                {
                    para = "0" + para 
                }
            }
            if(n.Option ==="fiscalyear")
            {
                let d = new Date();
                para  = d.getFullYear() + ""
            }
            if(n.Option ==="calendarmonth")
            {
                let d = new Date();
                let a   = d.getMonth() + ""
                if(a.length <2)
                {
                    para = "0" + a 
                }
            }
            if(n.Option ==="calendaryear")
            {
                let d = new Date();
                para  = d.getFullYear() + ""
            }
            numberingFormatDisplay = numberingFormatDisplay  + para + n.Separator 
        })
        numberingFormatDisplay= numberingFormatDisplay + "<Running Number>"
        this.setState({numberingFormatDisplay:numberingFormatDisplay})
    }

    render(){
        const checkBoxStyle = {marginRight:"10px"}

        return <div className="content-wrapper">
        <section className="content" >
                <div className="box ">
                    <div className="box-header with-border">
                        {this.state.key === "" && <font style={{fontSize:"30px"}}>Add New Transaction Type</font>}
                        {this.state.key !== "" && <font style={{fontSize:"30px"}}>Edit Transaction Type</font>}
                    </div>
                    <div className="box-body">
                        <div className="row">
                            <div className="col-md-6">
                                <div className={ this.state.moduleError !=="" ? "form-group has-error": "form-group "} >
                                    <label >Module</label>
                                    <select className="form-control" 
                                        disabled = {this.state.key !== ""}
                                        onChange={(evt)=> {
                                           
                                            let numberingOptions= []
                                            let numberingFormat =[]
                                            if(evt.target.value ==="sales")
                                            {
                                                numberingOptions=[
                                                    {value:"", text:"Select"},
                                                    {value:"text", text:"Text"},
                                                    {value:"storecode", text:"Store Code"},
                                                    {value:"locationcode", text:"Location Code"},
                                                    {value:"cashregistercode", text:"Cash Register Code"},
                                                    {value:"fiscalyear", text:"Fiscal Year (YYYY)"},
                                                    {value:"calendardmonthyear", text:"Calendar Month Year (MMYY)"},
                                                    {value:"calendarmonth", text:"Calendar Month (MM)"},
                                                    {value:"calendaryear", text:"Calendar Year (YYYY)"}
                                                ]
                                                numberingFormat=[{Option:"text",Value:"", Separator:"", OptionError:"",ValueError:""},
                                                {Option:"storecode",Value:"", Separator:"", OptionError:"",ValueError:""},
                                                {Option:"cashregistercode",Value:"", Separator:"",OptionError:"",ValueError:""}
                                                ]
                                            }
                                            else
                                            {
                                                numberingOptions=[
                                                    {value:"", text:"Select"},
                                                    {value:"text", text:"Text"},
                                                    {value:"storecode", text:"Store Code"},
                                                    {value:"locationcode", text:"Location Code"},
                                                    {value:"fiscalyear", text:"Fiscal Year (YYYY)"},
                                                    {value:"calendardmonthyear", text:"Calendar Month Year (MMYY)"},
                                                    {value:"calendarmonth", text:"Calendar Month (MM)"},
                                                    {value:"calendaryear", text:"Calendar Year (YYYY)"}
                                                ]
                                                numberingFormat=[{Option:"text",Value:"", Separator:"", OptionError:"",ValueError:""},
                                                {Option:"storecode",Value:"", Separator:"", OptionError:"",ValueError:""}
                                                ]
                                            }
                                            this.setState({module:evt.target.value, 
                                                moduleError:'',
                                                numberingOptions:numberingOptions,
                                                numberingFormat:numberingFormat})
                                        }
                                        }
                                        value={this.state.module}>
                                        <option value="sales">Sales</option>
                                        <option value="inventory">Inventory</option>
                                    </select>
                                    {this.state.moduleError !=="" &&  <span class="help-block">{this.state.moduleError}</span> }
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className={ this.state.transactionTypeError ? "form-group has-error": "form-group "} >
                                    <label >Transaction Type</label>
                                    <input type = "text" className="form-control" 
                                    disabled={this.state.isSystemDefined}
                                    onChange={(evt)=> { 
                                        this.setState({transactionType:evt.target.value, transactionTypeError:''})}}
                                    value={this.state.transactionType}/>
                                    {this.state.transactionTypeError !=="" &&  <span class="help-block">{this.state.transactionTypeError}</span> }
                                </div>
                            </div>
                        </div>    
                        <div className="row">
                           {this.state.module ==="sales" &&  <div className="col-md-6">
                                <div style={{borderBottom:"1px solid #f4f4f4",padding:"5px 0px",marginBottom:"20px",fontSize:"18px"}} >
                                    <b>Options</b>
                                </div>
                                <div className="form-group">
                                        <input type="checkbox"  style={checkBoxStyle}
                                        checked={this.state.salesImpactChecked}
                                        onChange={()=>this.setState({salesImpactChecked: !this.state.salesImpactChecked}) }/>
                                        <label >Show in sales report</label>
                                </div>
                                <div className="form-group">
                                    <input type="checkbox"  style={checkBoxStyle}
                                    checked={this.state.paymentModeChecked}
                                    onChange={()=>this.setState({paymentModeChecked: !this.state.paymentModeChecked,paymentModeAllOrSpecific: !this.state.paymentModeChecked ?"all" : ""}) }/>
                                    <label>Allow payment modes</label>
                                    {this.state.paymentModeChecked && <div style={{marginLeft:"20px",marginTop:"-15px"}}>
                                        <br></br>
                                        <select className="form-control" 
                                            value={this.state.balanceAmount}
                                            onChange={(evt)=> {this.setState({balanceAmount: evt.target.value})}}>
                                            <option value="partial">Partial Balance Allowed</option>
                                            <option value="full">Full Balance Allowed</option>
                                            <option value="no">Balance Not Allowed</option>
                                        </select>
                                    </div>}
                                </div>
                                <div className="form-group">
                                            <input type="checkbox"  style={checkBoxStyle}
                                            checked={this.state.updateStockChecked}
                                            onChange={()=>this.setState({updateStockChecked: !this.state.updateStockChecked}) }/>
                                            <label >Update stock</label>
                                    </div>
                                {/* {this.state.paymentModeChecked && <div className="radio" style={{marginLeft:"20px",marginTop:"-15px"}}>
                                        <label>
                                        <input type="radio" name="paymentModeChecked"  
                                        onChange={()=> this.setState({paymentModeAllOrSpecific:"all"}) } 
                                        checked={this.state.paymentModeAllOrSpecific ==="all" }
                                        value="all" 
                                        />
                                        All payment modes
                                        </label>
                                </div>}
                                {this.state.paymentModeChecked && <div className="radio" style={{marginLeft:"20px"}}>
                                        <label>
                                        <input type="radio" name="paymentModeChecked" 
                                        checked={this.state.paymentModeAllOrSpecific ==="specific"}                                        
                                        onChange={()=> this.setState({paymentModeAllOrSpecific:"specific"}) }
                                        value="specific"
                                        ></input>
                                        Specific payment modes
                                        </label>
                                </div>}
                                {this.state.paymentModeAllOrSpecific ==="specific" && <div className="radio" style={{marginLeft:"40px"}}>
                                    {this.props.config.PaymentTypes.filter((e)=> e.ShowInSales && !e.IsDeleted).map((paymentMode )=>
                                    <div><input type="checkbox"/>{paymentMode.PaymentType}</div>
                                    )}
                                </div>} */}
                                <div className="form-group">
                                    <input type="checkbox"  style={checkBoxStyle}
                                    checked={this.state.calculateTaxChecked}
                                    onChange={()=>this.setState({calculateTaxChecked: !this.state.calculateTaxChecked}) }/>
                                    <label>Calculate taxes</label>
                                </div>
                                <div className="form-group">
                                    <input type="checkbox"  style={checkBoxStyle}
                                    checked={this.state.customerMandatoryChecked}
                                    onChange={()=>this.setState({customerMandatoryChecked: !this.state.customerMandatoryChecked}) }/>
                                    <label>Customer selection manadatory</label>
                                </div>
                                <div className="form-group">
                                    <input type="checkbox"  style={checkBoxStyle}
                                    checked={this.state.employeeMandatoryChecked}
                                    onChange={()=>this.setState({employeeMandatoryChecked: !this.state.employeeMandatoryChecked, employeeManadatoryTransactionORLineItemLevel: !this.state.employeeMandatoryChecked ? "transaction" : "" }) }/>
                                    <label>Employee tagging mandatory</label>
                                </div>
                                 {this.state.employeeMandatoryChecked && <div className="radio" style={{marginLeft:"20px",marginTop:"-15px"}}>
                                        <label>
                                        <input type="radio" name="employeeMandatoryChecked"  
                                        onChange={()=> this.setState({employeeManadatoryTransactionORLineItemLevel:"transaction"}) } 
                                        checked={this.state.employeeManadatoryTransactionORLineItemLevel ==="transaction" }
                                        value="transaction" 
                                        />
                                        Transaction Level
                                        </label>
                                </div>}
                                {this.state.employeeMandatoryChecked && <div className="radio" style={{marginLeft:"20px"}}>
                                        <label>
                                        <input type="radio" name="employeeMandatoryChecked" 
                                        checked={this.state.employeeManadatoryTransactionORLineItemLevel ==="lineitem"}                                        
                                        onChange={()=> this.setState({employeeManadatoryTransactionORLineItemLevel:"lineitem"}) }
                                        value="lineitem"
                                        ></input>
                                        Transaction Line Item Level
                                        </label>
                                </div>}
                                <div className="form-group">
                                    <input type="checkbox"  style={checkBoxStyle}
                                    checked={this.state.discountChecked}
                                    onChange={()=>this.setState({discountChecked: !this.state.discountChecked}) }/>
                                    <label>Discount allowed</label>
                                </div>
                                {/* <div className="form-group">
                                    <input type="checkbox"  style={checkBoxStyle}
                                    onChange={()=>this.setState({chargeTaxOnPoduct: !this.state.chargeTaxOnPoduct}) }/>
                                    <label>Update inventory</label>
                                </div>  */}
                            </div>}
                            {this.state.module==="inventory" && <div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                            <input type="checkbox"  style={checkBoxStyle}
                                            checked={this.state.updateStockChecked}
                                            onChange={()=>this.setState({updateStockChecked: !this.state.updateStockChecked}) }/>
                                            <label >Update stock</label>
                                    </div>
                                    {this.state.updateStockChecked && <div className="radio" style={{marginLeft:"20px",marginTop:"-15px"}}>
                                        <label>
                                        <input type="radio" name="updateStockChecked"  
                                        onChange={()=> this.setState({updateStockInwardOrOutward:"inward"}) } 
                                        checked={this.state.updateStockInwardOrOutward ==="inward" }
                                        value="inward" 
                                        />
                                        Inward
                                        </label>
                                </div>}
                                {this.state.updateStockChecked && <div className="radio" style={{marginLeft:"20px",marginTop:"-15px"}}>
                                        <label>
                                        <input type="radio" name="updateStockChecked"  
                                        onChange={()=> this.setState({updateStockInwardOrOutward:"outward"}) } 
                                        checked={this.state.updateStockInwardOrOutward ==="outward" }
                                        value="outward" 
                                        />
                                        outward
                                        </label>
                                </div>}
                                    <div className="form-group">
                                        <input type="checkbox"  style={checkBoxStyle}
                                        checked={this.state.includePriceAndTax}
                                        onChange={()=>this.setState({includePriceAndTax: !this.state.includePriceAndTax}) }/>
                                        <label >Include supply price and taxes</label>
                                    </div>
                                    <div className="form-group">
                                    <label >Transaction Movement</label>
                                        <select className="form-control"
                                            value={this.state.transactionMovement} 
                                            onChange= {(evt)=> {this.setState({transactionMovement: evt.target.value, transactionMovementError:""})}}>
                                                <option value="">Select option</option>
                                                <option value="vendortostore">Vendor to Store</option>
                                                <option value="storetovendor">Store to Vendor</option>
                                                <option value="storetostore">Store to Store</option>
                                                <option value="store">Store</option>
                                        </select>
                                    </div>
                                </div>
                            </div>}
                            <div className="col-md-6">
                                <div style={{borderBottom:"1px solid #f4f4f4",padding:"5px 0px",marginBottom:"20px",fontSize:"18px"}}  >
                                    <b>Numbering</b>
                                </div>
                                <div className="row">
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label>Parameter</label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Separator</label>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                        </div>
                                </div>
                                {this.state.numberingFormat.map((m, index)=> <div className="row">
                                        <div className="col-md-5">
                                        {m.Option !== "text" && 
                                            <div className={ m.OptionError !=="" ? "form-group has-error": "form-group "} >    
                                                <select className="form-control" 
                                                    onChange={(evt)=> this.onOptionChanged(index, evt.target.value )}
                                                    value={m.Option}>
                                                    {this.state.numberingOptions.map((m)=> <option value={m.value}>{m.text}</option>) }
                                                </select>
                                                {m.OptionError!=="" &&  <span class="help-block">{m.OptionError}</span> }
                                            </div>
                                            }
                                            {m.Option === "text" &&  
                                             <div className={ m.ValueError !=="" ? "form-group has-error": "form-group "} > 
                                                <div className="input-group">
                                                    <input type="text" className="form-control" 
                                                    onChange={(evt)=> this.onTextChange(index, evt.target.value)} 
                                                    value={m.Value} />
                                                    <div className="input-group-btn">
                                                        <button type="button" class="btn btn-default btn-md" 
                                                        onClick={ () => this.onRemoveText(index)  }>
                                                        <i class="glyphicon glyphicon-remove"></i> </button>
                                                    </div>
                                                </div>
                                                {m.ValueError!=="" &&  <span class="help-block">{m.ValueError}</span> }
                                            </div>
                                            }
                                        </div>
                                        <div className="col-md-4">
                                            
                                                <input type="text"  value={m.Separator} className="form-control" 
                                                onChange={(evt)=>this.onSeparatorChange(index, evt.target.value) }/>
            
                                        </div>
                                        {index === 2 && <div className="col-md-2">
                                               
                                                    <div className="input-group-btn">
                                                            <button type="button" class="btn btn-default btn-md" 
                                                                onClick={ () =>  {
                                                                let numberingFormat = this.state.numberingFormat
                                                                numberingFormat.push({Option:"",Value:"",Separator:"",OptionError:"",ValueError:""})
                                                                this.setState({numberingFormat:numberingFormat})
                                                            }}>
                                                            <i class="glyphicon glyphicon-plus"></i> </button>
                                                    </div>
                                          
                                            </div>}
                                            {index > 2 && <div className="col-md-2">
                                              
                                                    <div className="input-group-btn">
                                                            <button type="button" class="btn btn-default btn-md" 
                                                            onClick={ () =>  {
                                                                let numberingFormat = this.state.numberingFormat
                                                                numberingFormat.splice(index, 1)
                                                                this.setState({numberingFormat:numberingFormat})
                                                            }}>
                                                            <i class="glyphicon glyphicon-remove"></i> </button>
                                                    </div>
                                           
                                            </div>}
                                </div>)}
                                <div>
                                        <font style={{fontSize:"20px", marginTop:"30px"}}><b>{this.state.numberingFormatDisplay}</b></font>
                                </div>
                            </div>
                        </div>                   
                    </div>
                    <div className="box-footer">
                        <button type="submit" className="btn btn-flat btn-md btn-success pull-right" style={{marginLeft:"10px"}} onClick={this.onSaveTransactionType} disabled={this.state.saving}>Save</button>
                        <button type="submit" className="btn btn-flat btn-md btn-default  pull-right" onClick={()=>  this.props.history.push("/home/settings/moduleconfig/")} >Cancel</button>
                      
                    </div>
            </div>
           
        </section>
    </div>
    }
}

export default AddEditTransactiontype