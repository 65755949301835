import React, { Component } from 'react';
import Modal from 'react-responsive-modal'
import { withRouter } from 'react-router-dom'
import AddEditExpenseCategories from '../Settings/AddEditExpenseCategories'
import PartySearch from './PartySearch'

class Expense extends Component {

    constructor() {
        super()
        this.state = {
            saving: false,
            hideTax: true,
            key: null,
            expenseNo: '',
            isPartyTypeAheadLoading: false,
            partyTypeAheadSelected: [],
            // party: null,
            selectedParty: null,
            expenseCategory: '',
            expenseCategoryID: '',
            expenseCategorieslist: [],
            expenseDate: new Date().toISOString().split('T')[0],
            refNo: '',
            note: '',
            amount: null,
            hsn: '',
            taxInclusive: true,
            taxID: '',
            taxGroup: '',
            taxAmount: null,
            netAmount: null,
            paymentTypeID: '',
            paymentType: '',
            cashRegister: '',
            cashRegisterID:'',
            paymentRemark: '',
            status: 'Pending',
           
            isShowingModalAddExpenseCategory: false,
            isShowingModalAddPaymentMode: false,
            expense: {},
            cashRegisterList: [],
            partyError: '',
            expenseDateErrorMessage:'',
            expenseCategoryError:'',
            expenseDateError:'',
            amountError:'',
            cashRegisterError:''
        }
        this.onPartySelected = this.onPartySelected.bind(this)
        this.loadExpenseCategories = this.loadExpenseCategories.bind(this)
        this.onSaveExpense = this.onSaveExpense.bind(this)
        this.onTaxChange = this.onTaxChange.bind(this)
        this.onPaymentChange = this.onPaymentChange.bind(this)
        this.roundUpTo2 = this.roundUpTo2.bind(this)
    }

    componentDidMount() {

        this.loadExpenseCategories();

        this.props.getCashRegisters(this.props.user.store.key).then((cashRegisters) => {
            this.setState({ cashRegisterList: cashRegisters })
        })


        if (this.props.expense) {
            this.setState({
                key: this.props.expense.key,
                expenseNo: this.props.expense.ExpenseNo,
                selectedParty: this.props.expense.Party,
                expenseCategory: this.props.expense.ExpenseCategory,
                expenseCategoryID: this.props.expense.ExpenseCategoryID,
                expenseDate: this.props.expense.ExpenseDate,
                refNo: this.props.expense.RefNo,
                note: this.props.expense.Note,
                amount: this.props.expense.Amount,
                hsn: this.props.expense.Hsn,
                taxInclusive: this.props.expense.TaxInclusive,
                taxGroup: this.props.expense.TaxGroup,
                taxAmount: this.props.expense.TaxAmount,
                taxID: this.props.expense.TaxID,
                netAmount: this.props.expense.NetAmount,
                paymentTypeID: this.props.expense.PaymentTypeID,
                paymentType: this.props.expense.PaymentType,
                cashRegister: this.props.expense.CashRegister,
                cashRegisterID: this.props.expense.CashRegisterID,

                paymentRemark: this.props.expense.PaymentRemark,
                status: this.props.expense.Status,
            })
        }
        // console.log("Key", this.state.key)
    }

    roundUpTo2(num) {
        return Math.round(Number(num) * 100) / 100
    }

    onTaxChange(e) {
        let taxID = e.target.value
        let tax = e.target.options[e.target.selectedIndex].text
        this.setState({ taxGroup: tax, taxID: taxID })
        //console.log('onTaxChange',e.target.value )
    }

    async onSaveExpense() {
        this.setState({ saving: true })
        let partyError = ""
        let expenseCategoryError = ""
        let expenseDateError = ""
        let refNoError = ""
        let amountError = ""
        let cashRegisterError = ""

        if (!this.state.selectedParty) {
            partyError = 'Please select party'
        }
        if (this.state.expenseCategory.trim() === "") {
            expenseCategoryError = 'Please select expense category'
        }
        if (this.state.expenseDate.trim() === "") {
            expenseDateError = 'Please select date'
        }
        if (this.state.amount <= 0) {
            amountError = 'Please enter amount'
        }
        
        if (this.state.paymentType != "" && this.state.paymentType.toLowerCase() === "cash from counter") {
            if (this.state.cashRegister.trim() === "") {
                cashRegisterError = 'Please select cash register'
            }
        }
        if(this.state.expenseDate.trim() !=="" 
        && (this.state.paymentType != "" && this.state.paymentType.toLowerCase() === "cash from counter") && (this.props.user.store.CashRegisterClosing && this.state.expenseDate !== new Date().toISOString().split('T')[0]))
        {
            expenseDateError = "In case of payment mode 'Cash from Counter', expense Date cannot be more than current date"
        }

        if (partyError !== "" || expenseCategoryError !== "" ||
            expenseDateError !== "" || refNoError !== "" ||
            amountError !== ""  || cashRegisterError !== "") {
            this.setState({
                partyError: partyError,
                expenseCategoryError: expenseCategoryError,
                expenseDateError: expenseDateError,
                amountError: amountError,
                cashRegisterError: cashRegisterError,
                saving: false
            })
            return
        }
        let runningNo
        let expenseNo = ""
        let expenseDate = new Date()
        if(this.state.paymentType.toLowerCase() !== "cash from counter")
        {
            expenseDate = new Date(this.state.expenseDate)
        }
        if (this.state.key === null) {
            runningNo = await this.props.getExpenseRunningNo(this.props.user.store.key)
            expenseNo = "EX/" + this.props.user.store.StoreCode + "/" + runningNo
        }
        else {
            expenseNo = this.state.expenseNo
        }
        let Expense = {
            ExpenseNo: expenseNo,
            Party: this.state.selectedParty,
            ExpenseCategory: this.state.expenseCategoryIndex === -1 ? '' : this.state.expenseCategory,
            ExpenseCategoryID: this.state.expenseCategoryID,
            ExpenseDate: this.state.expenseDate,
            ExpenseDateTime: expenseDate,
            RefNo: this.state.refNo,
            Note: this.state.note,
            Amount: this.state.amount,
            Hsn: this.state.hsn,
            TaxInclusive: this.state.taxInclusive,
            TaxID: this.state.taxID,
            TaxGroup: this.state.taxGroup,
            TaxAmount: this.state.taxAmount,
            NetAmount: this.state.netAmount,
            PaymentTypeID: this.state.paymentTypeID,
            PaymentType: this.state.paymentType,
            PaymentRemark: this.state.paymentRemark,
            CashRegister: this.state.cashRegister,
            CashRegisterID: this.state.cashRegisterID,
            Status: this.state.status,
            StoreID: this.props.user.store.key,
            RegistrationID: this.props.user.user.RegistrationID,
        }
        // console.log('expense', Expense)
        this.props.saveExpenses(Expense, this.state.key).then((key) => {


            this.props.getToastr("Expense Saved Successfully")

            setTimeout(function () { 
                this.setState({saving:false})
                this.props.onSave()
            }.bind(this), 2000)

            // console.log('addExpense called')
            // console.log('Expense saved:', key)
        })
    }

    onPaymentChange = (e) => {
        let paymentType = e.target.options[e.target.selectedIndex].text
        if (paymentType !== "") {
            this.setState({
                status: "Paid"
            })
        }
        this.setState({
            paymentTypeID: e.target.value,
            paymentType: paymentType,
        });
    }

    onPartySelected(party) {
        let selectedParty = null
        if(party)
        {
            selectedParty = {
                key: party.key,
                PartyCode: party.PartyCode,
                PartyFirstName: party.PartyFirstName,
                PartyLastName: party.PartyLastName,
                PartyType:party.PartyType,
                EmailID: party.EmailID,

                PhoneNo: party.PhoneNo
            }
            let name = (selectedParty.PartyFirstName.trim() + ' ' + selectedParty.PartyLastName.trim()).trim()
            selectedParty.PartyName = name
        }
        this.setState({ selectedParty: selectedParty, partyError: "" })
    }

    loadExpenseCategories() {
        this.props.getExpenseCategories(this.props.user.user.RegistrationID, 
            this.props.user.store.key).then((expenseCategories) => {
            this.setState({ expenseCategorieslist: expenseCategories })
        })
    }

    render() {
        const styleEditor = {
            width: "100%",
            height: "75px",
            font_size: "14px",
            line_height: "10px",
            border: "1px solid #dddddd",
            padding: "10px"
        }
        const modalWidth = { width: '700px' }
        return <div>
            <h3 style={{ marginTop: "0px" }}>Add Expense</h3>
            <div className="box-body" style={{ padding: "0px" }}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="col-md-12">
                            <div className={this.state.partyError.trim()!=="" ? "form-group has-error" : "form-group "}>
                                <label>Party</label>
                                <PartySearch {...this.props}
                                    party={this.state.selectedParty}
                                    error={this.state.partyError}
                                    onPartyChange={(party) => this.onPartySelected(party)}
                                />
                                {this.state.partyError.trim()!=="" && <span class="help-block">{this.state.partyError }</span>}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className={this.state.expenseCategoryError.trim()!=="" ? "form-group has-error" : "form-group "} >
                                <label>Expense Cateogry</label>
                                <div className="input-group">
                                    <select className="form-control"
                                        value={this.state.expenseCategoryID}
                                        onChange={(e) => this.setState({ expenseCategoryID: e.target.value, expenseCategory: e.target.options[e.target.selectedIndex].text, expenseCategoryError: '' })}>
                                        <option value="">Select Expense category</option>
                                        {this.state.expenseCategorieslist.filter((e) =>!e.IsDeleted).map((expenseCategory, index) =>
                                            <option value={expenseCategory.key}>{expenseCategory.ExpenseCategory}</option>
                                        )}
                                    </select>
                                    <div className="input-group-btn">
                                        <button type="button" class="btn btn-default btn-md" onClick={() => this.setState({ isShowingModalAddExpenseCategory: true })}>
                                            <i class="glyphicon glyphicon-plus"></i> </button>
                                    </div>
                                </div>
                                {this.state.expenseCategoryError.trim()!=="" && <span class="help-block">{this.state.expenseCategoryError}</span>}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className={this.state.expenseDateError.trim()!=="" ? "form-group has-error" : "form-group "} >
                                <label >Date</label>
                                <input type="date" className="form-control"
                                    onChange={(evt) => this.setState({ expenseDate: evt.target.value, expenseDateError: "", expenseDateErrorMessage:'' })}
                                    value={this.state.expenseDate}
                                    placeholder="" />
                                {this.state.expenseDateError.trim()!=="" && <span class="help-block">{this.state.expenseDateError}</span>}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group " >
                                <label >Reference No.</label>
                                <input className="form-control" value={this.state.refNo}
                                    onChange={(evt) => this.setState({ refNo: evt.target.value, refNoError: "" })}
                                    value={this.state.refNo} />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group" >
                                <label >Notes</label>
                                <textarea className="textarea" style={styleEditor}
                                    onChange={(evt) => this.setState({ note: evt.target.value })}
                                    value={this.state.note}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="col-md-12">
                            <div className={this.state.amountError.trim()!=="" ? "form-group has-error" : "form-group "} >
                                <label >Amount</label>
                                <input type="number" className="form-control"
                                    onChange={(evt) => this.setState({ amount: Number(evt.target.value), amountError: "" })}
                                    value={this.state.amount}
                                />
                                {this.state.amountError.trim()!=="" && <span class="help-block">{this.state.amountError}</span>}
                            </div>
                        </div>
                        {!this.state.hideTax && <React.Fragment> <div className="col-md-12">
                            <div className="form-group" >
                                <label >HSN/SAC code</label>
                                <input className="form-control" value={this.state.hsn} onChange={(evt) => this.setState({ hsn: evt.target.value })} />
                            </div>
                        </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <input type="checkbox"
                                        checked={this.state.taxInclusive}
                                        onChange={() => this.setState({ taxInclusive: !this.state.taxInclusive })} />
                                    <label >Tax Inclusive</label>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Tax Group</label>
                                    <select className="form-control" value={this.state.taxID} onChange={this.onTaxChange}>
                                        <option value="">Select tax</option>
                                        {this.props.config.ProductTaxes.filter((e) => !e.IsDeleted).map((t) =>
                                            <option value={t.key}>{t.TaxGroup}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group" >
                                    <label >Tax Amount</label>
                                    <input className="form-control" value={this.state.taxAmount}
                                        onChange={(evt) => this.setState({ taxAmount: evt.target.value })}
                                        disabled />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group" >
                                    <label >Net Amount</label>
                                    <input className="form-control" value={this.state.netAmount}
                                        onChange={(evt) => this.setState({ netAmount: evt.target.value })}
                                        disabled />
                                </div>
                            </div> </React.Fragment>}
                        <div className="col-md-12">
                            <div className= "form-group">
                                <label>Payment Type</label>
                                <select className="form-control"
                                    onChange={this.onPaymentChange}
                                    value={this.state.paymentTypeID} >
                                    <option value="">Select Payment Type</option>
                                    {this.props.config.PaymentTypes.filter((e) => e.ShowInExpense && !e.IsDeleted).map((e) => <option value={e.key}>{e.PaymentType}</option>)}
                                </select>
                            </div>
                        </div>
                        {this.state.paymentType.toLowerCase() === "cash from counter" && <div className="col-md-12">
                            <div className={this.state.cashRegisterError.trim()!=="" ? "form-group has-error" : "form-group "} >
                                <label >Cash Register</label>
                                <select className="form-control"
                                    onChange={(evt) => this.setState({ cashRegisterID: evt.target.value,cashRegister: evt.target.options[evt.target.selectedIndex].text, cashRegisterError: "" })}>
                                    <option value="">Select Cash Register</option>
                                    {this.state.cashRegisterList.map((cashRegister) =>
                                        <option value={cashRegister.key}>{cashRegister.CashRegisterName}</option>)}
                                </select>
                                {this.state.cashRegisterError.trim()!=="" && <span class="help-block">{this.state.cashRegisterError}</span>}
                            </div>
                        </div>}
                        {this.state.paymentTypeID.trim() !== "" && <div className="col-md-12">
                            <div className="form-group" >
                                <label >Payment Remarks</label>
                                <input type="text" className="form-control input-md"
                                    onChange={(evt) => this.setState({ paymentRemark: evt.target.value })}
                                    value={this.state.paymentRemark} />
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
            <div className="box-footer">
                <button onClick={() => this.props.onClose()} type="submit" className="btn btn-flat btn-md btn-default pull-left">Cancel</button>
                <button type="submit" className="btn btn-md btn-success btn-flat pull-right" onClick={this.onSaveExpense} disabled={this.state.saving}>Save</button>
            </div>
            <Modal open={this.state.isShowingModalAddExpenseCategory} onClose={() => this.setState({ isShowingModalAddExpenseCategory: false })} center>
                <div style={modalWidth}>
                    <AddEditExpenseCategories {...this.props}
                        onSave={(key) => {
                            // console.log('Expense Category onsave called ', key)
                            this.loadExpenseCategories(key)
                            this.setState({ isShowingModalAddExpenseCategory: false })
                        }}
                        onClose={() => this.setState({ isShowingModalAddExpenseCategory: false })} />
                </div>
            </Modal>
        </div>
    }
}
export default withRouter(Expense)