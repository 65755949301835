import firebase, {database} from '../firebase'
import * as actionTypes from '../actionTypes'
import * as constants from '../Constatnts'

export function getCustomFieldNames(registrationID, moduleName){
    return (dispatch) => {
    return database.collection("CustomFields").where("RegistrationID","==",registrationID).where("ModuleName","==",moduleName).limit(1).get().then((querySnapshot) => {
        let customFields = null
        querySnapshot.forEach((doc) => {
            customFields = { key:doc.id,...doc.data()}
        })
         return customFields
     })
    }
}

export function saveCustomFieldNames(customFields, key){
    return (dispatch, getState) => {
        customFields.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        customFields.ActionBy = getState().user.user.Name
        customFields.ActionByUID = getState().user.user.UID
        customFields.ActionByEmailID = getState().user.user.EmailID

        console.log('saveCustomFieldNames', key)
        if(key.trim()!== "")
        {
            customFields.Action = constants.AUDIT_TRAIL_EDIT
            return database.collection("CustomFields").doc(key).update({
                ...customFields
              })
        }
        else
        {
            customFields.Action = constants.AUDIT_TRAIL_NEW
            return database.collection("CustomFields").add({
                ...customFields
              })
              .then(function(docRef) {
                  return docRef.id
              })
              .catch(function(error) {
                  console.error("Error adding custom fields: ", error);
              })
        }
        
    }
}
