import firebase, { database, functions } from '../firebase'
import * as actionTypes from '../actionTypes'
import * as constants from '../Constatnts'
import { truncateSync } from 'fs';

export function addInventory(inventory) {
  return (dispatch, getState) => {
    inventory.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
    inventory.ActionBy = getState().user.user.Name
    inventory.ActionByUID = getState().user.user.UID
    inventory.ActionByEmailID = getState().user.user.EmailID
    inventory.Action = constants.AUDIT_TRAIL_NEW
    return database.collection("Inventory").add({
      ...inventory
    })
      .then(function (docRef) {
        return docRef.id
      })
      .catch(function (error) {
        console.error("Error saving Inventory : ", error);
      })
  }
}

export function updateInventory(inventory, key) {
  return (dispatch, getState) => {
    inventory.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
    inventory.ActionBy = getState().user.user.Name
    inventory.ActionByUID = getState().user.user.UID
    inventory.ActionByEmailID = getState().user.user.EmailID
    inventory.Action = constants.AUDIT_TRAIL_EDIT
    return database.collection("Inventory").doc(key).update({
      ...inventory
    })
  }
}

export function cancelInventory(cancellationReason, key) {
  return (dispatch, getState) => {
    let actionOn = firebase.firestore.FieldValue.serverTimestamp()
    let actionBy = getState().user.user.Name
    let actionByUID = getState().user.user.UID
    let actionByEmailID = getState().user.user.EmailID
    let action = "CANCEL"
    return database.collection("Inventory").doc(key).update({
      Status: "Cancelled",
      CancellationReason: cancellationReason,
      ActionOn: actionOn,
      ActionBy: actionBy,
      ActionByUID: actionByUID,
      ActionByEmailID: actionByEmailID,
      Action: action
    })
  }
}

export function getInventoryRunningNo(storeID, transactionTypeID) {
  return dispatch => {
    return database.collection("TransactionRunningNo").doc("inventory" + storeID + transactionTypeID).get().then((doc) => {
      let runningNo = 1
      if (doc.exists) {
        runningNo = Number(doc.data().RunningNo) + 1
      }
      return database.collection("TransactionRunningNo").doc("inventory" + storeID + transactionTypeID).set({
        StoreID: storeID, TransactionTypeID: transactionTypeID, RunningNo: runningNo
      }).then(() => {
        return runningNo
      })
    })
  }
}

export function getInventoryDetails(key) {
  return (dispatch) => {
    return database.collection("Inventory").doc(key).get().then((doc) => {
      if (doc.exists) {
        return doc.data()
      } else {
        console.log("No such inventory record!");
      }
    })
  }
}

export function getInventoryList(storeID, from, size, searchTransactionNo, searchTransactionType,
  searchTransactionFromDate, searchTransactionToDate,
  searchVendorDetail, searchText, status) {
  let searchParameters = {
    "from": from,
    "size": size,
    "query": {
      "bool": {
        "must": [
          {
            "match": {
              "Store.key": storeID
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": searchTransactionToDate,
                "gte": searchTransactionFromDate,
                "format": "yyyy-MM-dd"
              }
            }
          }
        ],
        "must_not": [],
        "filter": {
          "bool": {
            "must": [

            ],
            "should": [],
          }
        }
      }
    },
    "sort": [
      {
        "ActionOn": {
          "order": "desc"
        },
        "TransactionDate": {
          "order": "desc"
        }
      }
    ]
  }
  if (status.trim() !== "") {
    searchParameters.query.bool.must.push({ "match": { "Status": status.trim().toLowerCase() } })
    searchParameters.query.bool.must.push({
      "range": {
        "TransactionDate": {
          "lte": searchTransactionToDate,
          "gte": searchTransactionFromDate,
          "format": "yyyy-MM-dd"
        }
      }
    });
  }
  else {
    searchParameters.query.bool.must_not.push({ "match": { "Status": "In Transit" } })

  }
  if (searchVendorDetail.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({ "regexp": { "Vendor.VendorFirstName": "@" + searchVendorDetail.trim().toLowerCase() + ".*" } })
    searchParameters.query.bool.filter.bool.should.push({ "regexp": { "Vendor.VendorLastName": "@" + searchVendorDetail.trim().toLowerCase() + ".*" } })
    searchParameters.query.bool.filter.bool.should.push({ "regexp": { "Vendor.EmailID": "@" + searchVendorDetail.trim().toLowerCase() + ".*" } })
    searchParameters.query.bool.filter.bool.should.push({ "regexp": { "Vendor.PhoneNo": "@" + searchVendorDetail.trim().toLowerCase() + ".*" } })
    searchParameters.query.bool.filter.bool.should.push({ "regexp": { "Vendor.VendorCode": "@" + searchVendorDetail.trim().toLowerCase() + ".*" } })
  }
  if (searchTransactionNo.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "TransactionNo": searchTransactionNo } })
  }

  if (searchTransactionType.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "TransactionType.key": searchTransactionType.trim().toLowerCase() } })
  }
  return (dispatch) => {
    // console.log('searchParameters', JSON.stringify(searchParameters))
    let addMessage = firebase.functions().httpsCallable('getInventory');
    return addMessage({ text: searchParameters }).then(function (result) {
      const inventory = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        inventory.push({ key: data._id, ...data._source })
      })
      // console.log('inventory', inventory)
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: inventory }
    });
  }
};

export function getProductInStockQty(storeID, locationID, productId) {
  // console.log("StoreID", "==", storeID + " " + "LocationID", "==", locationID + " " + "ProductID", "==", productId)
  return dispatch => {
    let inStockQty = 0
    return database.collection("InventoryInStock").where("StoreID", "==", storeID).where("LocationID", "==", locationID).where("ProductID", "==", productId).limit(1).get().then(async (querySnapshot) => {
      querySnapshot.forEach(async (doc) => {
        inStockQty = doc.data().InStockQty
      })
      return inStockQty
    })
  }
}

export function getInwardUniqueList(from, size, storeID, productID, searchText) {
  return (dispatch) => {
    let collection = database.collection("UniqueNos");
    let query = collection.where("StoreID", "==", storeID).where("Movement","==","inward").where("IsDeleted", "==", false)
    
    if(productID.trim() !== "")
    {
      query = query.where("ProductID", "==", productID);
    }

    return query.get().then((querySnapshot) => {
      let uniqueNos = []
      querySnapshot.forEach((doc) => {
let uniqueNo;        
        if (searchText.trim() !== "")
        {
          if (doc.data().UniqueNo1.toLowerCase().includes(searchText.toLowerCase())
            || doc.data().UniqueNo2.toLowerCase().includes(searchText.toLowerCase())
            || doc.data().UniqueNo3.toLowerCase().includes(searchText.toLowerCase())
            || doc.data().UniqueNo4.toLowerCase().includes(searchText.toLowerCase())) {

              uniqueNo = doc.data();
              uniqueNo.key = doc.id;
              uniqueNo.ActionOn = null;   
              uniqueNos.push(uniqueNo)
          }
        }
        else
        {
          uniqueNo = doc.data();
          uniqueNo.ActionOn = null;
          uniqueNo.key = doc.id;
          uniqueNos.push(uniqueNo)
        }
      })
      return uniqueNos
    })
  }
};

export function getUniqueListForSalesReturn(from, size, storeID, productID, searchText) {
  // let searchParameters = {
  //   "from": from,
  //   "size": size,
  //   "query": {
  //     "bool": {
  //       "must": [
  //         {
  //           "match": {
  //             "StoreID": storeID
  //           }
  //         },
  //         {
  //           "match": {
  //             "Movement": "outward"
  //           }
  //         },
  //         {
  //           "match": {
  //             "TransactionTypeModule": "sales"
  //           }
  //         },
  //         {
  //           "match": {
  //             "IsDeleted": false
  //           }
  //         }
  //       ],
  //       "filter": {
  //         "bool": {
  //           "should": [],
  //           "minimum_should_match": 1
  //         }
  //       }
  //     }
  //   },
  //   "sort": [
  //     {
  //       "ActionOn": {
  //         "order": "desc"
  //       }
  //     }
  //   ]
  // }

  // if (uniqueNo.trim() !== "") {
  //   searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "UniqueNo1": uniqueNo.trim().toLowerCase() } })
  //   searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "UniqueNo2": uniqueNo.trim().toLowerCase() } })
  //   searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "UniqueNo3": uniqueNo.trim().toLowerCase() } })
  //   searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "UniqueNo4": uniqueNo.trim().toLowerCase() } })
  // }
  // if (productID !== "") {
  //   searchParameters.query.bool.must.push({ "match": { "ProductID": productID } });
  // }

  // return (dispatch) => {
  //   console.log('searchParameters', JSON.stringify(searchParameters))
  //   let addMessage = firebase.functions().httpsCallable('getUniqueNos');
  //   return addMessage({ text: searchParameters }).then(function (result) {
  //     const inventory = []
  //     let sanitizedMessage = result.data.text;
  //     sanitizedMessage.hits.hits.map((data, index) => {
  //       inventory.push({ key: data._id, ...data._source })
  //     })
  //     // console.log('inventory', inventory)
  //     return { totalItemsCount: sanitizedMessage.hits.total, searchResult: inventory }
  //   });
  // }

  return (dispatch) => {
    let collection = database.collection("UniqueNos");
    let query = collection.where("StoreID", "==", storeID).where("TransactionTypeModule","==","sales").where("Movement","==","outward").where("IsDeleted", "==", false)
    
    if(productID.trim() !== "")
    {
      query = query.where("ProductID", "==", productID);
    }

    return query.get().then((querySnapshot) => {
      let uniqueNos = []
      querySnapshot.forEach((doc) => {
        
        if (searchText.trim() !== "")
        {
          if (doc.data().UniqueNo1.toLowerCase().includes(searchText.toLowerCase())
            || doc.data().UniqueNo2.toLowerCase().includes(searchText.toLowerCase())
            || doc.data().UniqueNo3.toLowerCase().includes(searchText.toLowerCase())
            || doc.data().UniqueNo4.toLowerCase().includes(searchText.toLowerCase())) {

              uniqueNos.push(doc.data())
          }
        }
          else{
          uniqueNos.push(doc.data())
          }
      })
      return uniqueNos
    })
  }
};

export function getUniqueNoIDBasedOnTransaction(storeID, productID,
  uniqueNo1, uniqueNo2, uniqueNo3, uniqueNo4) {

  let searchParameters = {
    "from": 0,
    "size": 1,
    "query": {
      "bool": {
        "must": [
          {
            "match": {
              "StoreID": storeID
            }
          },
          {
            "match": {
              "ProductID": productID
            }
          },
          {
            "match": {
              "UniqueNo1.keyword": uniqueNo1
            }
          },
          {
            "match": {
              "UniqueNo2.keyword": uniqueNo2
            }
          },
          {
            "match": {
              "UniqueNo3.keyword": uniqueNo3
            }
          },
          {
            "match": {
              "UniqueNo4.keyword": uniqueNo4
            }
          }
        ]
      }
    }
  }

  return (dispatch) => {
    // console.log('searchParameters', JSON.stringify(searchParameters))
    let addMessage = firebase.functions().httpsCallable('getUniqueNos');
    return addMessage({ text: searchParameters }).then(function (result) {
      let uniqueNo = null
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data) => {
        uniqueNo = { key: data._id, ...data._source }
        // console.log('uniqueNo',uniqueNo)
      })
      return uniqueNo
    });
  }
}

export function checkIfUniqueNoIsUnique(storeID, productID,
  uniqueNo) {

  let searchParameters = {
    "from": 0,
    "size": 1,
    "query": {
      "bool": {
        "must": [
          {
            "match": {
              "StoreID": storeID
            }
          },
          {
            "match": {
              "ProductID": productID
            }
          }
        ],
        "filter": {
          "bool": {
            "should": [],
          }
        }
      }
    }
  }

  return (dispatch) => {
    if (uniqueNo.trim() !== "") {
      searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "UniqueNo1": uniqueNo.trim().toLowerCase() } })
      searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "UniqueNo2": uniqueNo.trim().toLowerCase() } })
      searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "UniqueNo3": uniqueNo.trim().toLowerCase() } })
      searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "UniqueNo4": uniqueNo.trim().toLowerCase() } })
    }
    // console.log('searchParameters', JSON.stringify(searchParameters))
    let addMessage = firebase.functions().httpsCallable('getUniqueNos');
    return addMessage({ text: searchParameters }).then(function (result) {
      let uniqueNo = null
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data) => {
        uniqueNo = { key: data._id, ...data._source }
        // console.log('uniqueNo',uniqueNo)
      })
      return uniqueNo
    });
  }
};

export function getUniqueNoList(storeID) {
  return (dispatch) => {
    return database.collection("UniqueNos").where("StoreID", "==", storeID).where("IsDeleted", "==", false).get().then((querySnapshot) => {
      // let uniqueNos = []
      // querySnapshot.forEach((doc) => {
      //   uniqueNos.push(doc.data())
      // })
      // return uniqueNos
    })
  }
};