import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import Modal from '../Modal'
import Confirm from 'react-confirm-bootstrap'
class Posts extends Component {

    constructor()
    {
        super()
        this.state=
        {
            isLoading:true
        }
        this.onDeactivateConfirm = this.onDeactivateConfirm.bind(this)
        this.onPublishConfirm = this.onPublishConfirm.bind(this)
        
    }
    
    componentDidMount()
    {this.props.getPosts(this.props.user.user.RegistrationID).then(
        (() => this.setState({isLoading: false}) ))
    
    }
    onDeactivateConfirm(id)
    {
        this.props.deactivatePost(id).then(()=> {
            this.props.getPosts(this.props.user.user.RegistrationID)
        })
    }
    onPublishConfirm(id)
    {
        this.props.publishPost(id).then(()=> {
            this.props.getPosts(this.props.user.user.RegistrationID)
        })
    }
    render() {
        const textAlign = {
            textAlign:'Left'
        };
   
    return (
    <div className="content-wrapper" >
        <section className="content-header">
            <h1>
            Posts
            </h1>
        </section>  
        <section className="content" style={textAlign}>
            <div className="row">
                <div className="col-md-12">
                    <div className="box ">
                        <div className="box-header with-border">
                            <h3 className="box-title">List</h3>
                            <div className="pull-right">
                                <Link  to="/home/post/newpost" className="btn btn-primary" >Create New Post</Link>
                            </div>
                        </div>
                        <div className="box-body">
                            {this.state.isLoading &&  <div class="overlay">
                                <i class="fa fa-refresh fa-spin"></i>
                            </div>}
                            <ul class="products-list product-list-in-box">
                                {this.props.post.posts.map((post, key) => 
                                    <li class="item">
                                        <div class="product-img">
                                            <img src={post.PostSourceUrl} alt={post.PostDescription}/>
                                        </div>
                                        <div class="product-info">
                                            <a href="javascript:void(0)" class="product-title">{post.PostDescription}
                                            { post.Status === 0 && <span class="label label-info pull-right">Draft</span>}
                                            { post.Status === 1 && <span class="label label-success pull-right">Published</span>}
                                            { post.Status === 2 && <span class="label label-danger pull-right">Inactive</span>}
                                            </a>
                                            <span class="product-description">
                                                    {post.PostRedirectionURL}
                                            </span>
                                            <div class="pull-right">
                                            { (post.Status === 0 || post.Status == 2 ) && <Confirm
                                                onConfirm={() => this.onPublishConfirm(post.key)}
                                                body="Are you sure you would like to publish this post?"
                                                confirmText="Yes"
                                                title="Publish Post">
                                                <button className="btn btn-default">Publish</button>
                                            </Confirm>}
                                            { post.Status === 1  &&  <Confirm
                                                onConfirm={() => this.onDeactivateConfirm(post.key)}
                                                body="Are you sure you want to deactivate this post?"
                                                confirmText="Yes"
                                                title="Deactivate Post">
                                                <button className="btn btn-default">Deactivate</button>
                                            </Confirm>}
                                            </div>
                                        </div>
                                    </li>
                            )}
                            </ul>
                        </div>   
                    </div>
                </div>
                  
            </div>
            <Modal />
        </section>
    </div>
    );
    }
}

export default Posts


{/* <button className="btn btn-default" data-toggle="modal" data-target="#modal-default">Deactivate</button> */}