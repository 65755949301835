import React, { Component } from 'react';
import Confirm from 'react-confirm-bootstrap'
import Modal from 'react-responsive-modal'
import AddEditProductBrand from './AddEditProductBrand'
import Loader from '../Loader'
import Pagination from "react-js-pagination"
import AuditTrail from '../AuditTrail'
import * as constants from '../../Constatnts'

class ProductBrandList extends Component {
    constructor() {
        super()
        this.state = {
            productBrands: [],
            showModal: false,
            selectedProductBrand: null,
            searchText: '',
            searchNotes: '',
            activePage: 1,
            itemsCountPerPage: 20,
            totalItemsCount: 0,
            recordFrom: 0,
            paginationStatus: '',
            searchActiveInactiveAllRecords: 'active',
            loading: true
        }
        this.loadProductBrands = this.loadProductBrands.bind(this)
        this.onSave = this.onSave.bind(this)
        this.onBrandDelete = this.onBrandDelete.bind(this)
        this.onSearchActiveInactiveAllRecordsClicked = this.onSearchActiveInactiveAllRecordsClicked.bind(this)
        this.onClearSearch = this.onClearSearch.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
    }

    componentDidMount() {
        this.loadProductBrands(this.state.recordFrom,
            this.state.searchActiveInactiveAllRecords,
            this.state.searchText)
    }

    loadProductBrands(from, option, searchText) {
        this.props.searchProductBrands(this.props.user.user.RegistrationID, from, this.state.itemsCountPerPage, option, searchText)
            .then((searchResult) => {
                console.log(JSON.stringify(searchResult))
                this.setState({ productBrands: searchResult.searchResult, totalItemsCount: searchResult.totalItemsCount, loading: false })
                this.showPaginationStatus()
            })
    }

    onSearchClick() {
        this.setState({ searchFrom: 0 })
        this.loadProductBrands(0,
            this.state.searchActiveInactiveAllRecords,
            this.state.searchText)
    }

    onBrandDelete(key) {
        this.props.deleteProductBrand(key).then(() => {
            this.props.getToastr("Brand deleted successfully")

            this.loadProductBrands(
                this.state.recordFrom,
                this.state.searchActiveInactiveAllRecords,
                this.state.searchText,
            )
        })
    }

    onClearSearch() {
        this.setState({
            recordFrom: 0, searchText: '',
            searchActiveInactiveAllRecords: "active"})
        this.loadProductBrands(0, "active", "", "")
    }


    onSave() {
        this.setState({ showModal: false })
        this.loadProductBrands(
            this.state.recordFrom,
            this.state.searchActiveInactiveAllRecords,
            this.state.searchText,
        )
    }


    onSearchActiveInactiveAllRecordsClicked(opiton) {
        this.setState({ searchActiveInactiveAllRecords: opiton, recordFrom: 0 })
        this.loadProductBrands(
            0,
            opiton,
            this.state.searchText
        )
    }

    showPaginationStatus() {
        console.log('this.state.recordFrom', this.state.recordFrom)
        let paginationStatus
        if ((this.state.recordFrom + 1) === this.state.totalItemsCount) {
            paginationStatus = "Showing " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else if ((this.state.recordFrom + this.state.itemsCountPerPage + 1) > this.state.totalItemsCount) {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + (this.state.recordFrom + this.state.itemsCountPerPage) + " of " + this.state.totalItemsCount

        }
        this.setState({ paginationStatus: paginationStatus })

    }

    render() {
        const btnColStyle = { width: "95px" }
        const revisionHistory = { width: "200px" }
        const modalWidth = { width: "500px" }

        return <div className="content-wrapper" >
            <section className="content">
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6">
                                        <font style={{ fontSize: "30px" }}>Brands</font>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="pull-right" style={{ marginTop: "6px" }}>
                                            <button type="button"
                                                className="btn btn-success btn-md btn-flat"
                                                onClick={() => this.setState({ showModal: true, selectedProductBrand: null })}>Add Brand</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-12" style={{ marginBottom: "10px" }}>
                                        <div className="btn-group">
                                            <button type="button" className={this.state.searchActiveInactiveAllRecords === "active" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.onSearchActiveInactiveAllRecordsClicked("active")}>Active</button>
                                            <button type="button" className={this.state.searchActiveInactiveAllRecords === "inactive" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.onSearchActiveInactiveAllRecordsClicked("inactive")}>In Active</button>
                                            <button type="button" className={this.state.searchActiveInactiveAllRecords === "all" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.onSearchActiveInactiveAllRecordsClicked("all")}>All</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-9">
                                        <div className="form-group">
                                            <label>Search by Brand Name</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchText}
                                                onChange={(evt) => this.setState({ searchText: evt.target.value })} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginLeft: "5px" }}
                                            onClick={this.onSearchActiveInactiveAllRecordsClicked}
                                        >Search</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                            onClick={this.onClearSearch} >Clear</button>
                                    </div>
                                </div>
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading &&
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>Brand</th>
                                                <th style={revisionHistory}>Revision History</th>
                                                <th style={btnColStyle}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.productBrands.map((productBrand, index) =>
                                                <tr>
                                                    <td>{productBrand.Brand}</td>
                                                    <td>
                                                        <div>Action :{productBrand.Action}</div>
                                                        <div>Action By :{productBrand.ActionBy}</div>                                                        
                                                        <div>Action Date :{(new Date(productBrand.ActionOn)).toString().substring(0,24)}</div>
                                                        <AuditTrail {...this.props} module={constants.MODULE_PRODUCT_BRANDS} parentKey={productBrand.key}/>
                                                    </td>
                                                    <td>
                                                        <div className="btn-group">
                                                            {productBrand.IsDeleted ? <span className="label label-danger pull-right">Inactive</span> :
                                                                <div>
                                                                    <button type="button" className="btn btn-md btn-flat btn-default"
                                                                        onClick={() => this.setState({ showModal: true, selectedProductBrand: productBrand })}>
                                                                        <i className="fa fa-edit"></i></button>
                                                                    <Confirm
                                                                        onConfirm={() => this.onBrandDelete(productBrand.key)}
                                                                        body="Are you sure you would like to delete this brand?"
                                                                        confirmText="Delete"
                                                                        title="Brand Delete">
                                                                        <button className="btn btn-default btn-flat btn-md"><i className="fa fa-trash-o"></i></button>
                                                                    </Confirm>
                                                                </div>
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                }
                                {this.state.totalItemsCount > 0 && <div className="pull-left" style={{ marginTop: "25px" }}>
                                    {this.state.paginationStatus}
                                </div>}
                                <div className="pull-right">
                                    <Pagination
                                        activePage={this.state.activePage} //this.state.activePage
                                        itemsCountPerPage={this.state.itemsCountPerPage}
                                        totalItemsCount={this.state.totalItemsCount}
                                        pageRangeDisplayed={2}
                                        onChange={this.handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal open={this.state.showModal} onClose={() => this.setState({ showModal: false })} center>
                    <div style={modalWidth}>
                        <AddEditProductBrand   {...this.props}
                            productBrand={this.state.selectedProductBrand}
                            onClose={() => this.setState({ showModal: false })}
                            onSave={this.onSave}
                        />
                    </div>
                </Modal>
            </section>
        </div>
    }
}

export default ProductBrandList