import React, { Component } from 'react';
import Loader from './Loader'
var Typeahead = require('react-bootstrap-typeahead').Typeahead;

class Home extends Component {

    constructor() {
        super()
        this.state = {
            selectedStore: null,
            stores: []
        }
        this.onStoreSelection = this.onStoreSelection.bind(this)
    }

    componentDidMount() {
        //load config
        this.props.getAccessibleStores(this.props.user.user.RegistrationID).then((stores) => {
            // console.log('stores',stores)
            if (stores.length === 1) {
                this.onStoreSelection(stores[0])
            }
            else {
                this.setState({ stores: stores })
            }
        })
    }

    async onStoreSelection(store) {

        this.setState({ loading: true })

        
        this.setState({ value: "Populating Store Details!!!" })
        await this.props.setStore(store)
        await this.props.getUserACL()

        //this.setState({ value: "Populating Custom Fields!!!" })
        this.props.getAllCustomFieldNames(this.props.user.user.RegistrationID)
        //this.setState({ value: "Populating Product Categories!!!" })
        this.props.getProductCategories(this.props.user.user.RegistrationID)
        //this.setState({ value: "Populating Product Subcategories!!!" })
        this.props.getProductSubCategories(this.props.user.user.RegistrationID)
        //this.setState({ value: "Populating Product Brands!!!" })
        this.props.getProductBrands(this.props.user.user.RegistrationID)
        //this.setState({ value: "Populating Product Taxes!!!" })
        this.props.getProductTaxes(this.props.user.user.RegistrationID)

        this.props.getPaymentTypes(this.props.user.user.RegistrationID)
        await this.props.getTransactionTypes(this.props.user.user.RegistrationID)
        this.props.getProductTags(this.props.user.user.RegistrationID)
        await this.props.getPrintConfigs(this.props.user.user.RegistrationID, store.key)

        this.props.getApplicableOffers(this.props.user.user.RegistrationID)
        this.props.getEmployeesForRedux(this.props.user.user.RegistrationID, store.key, 0, 1000, "active", "", "", "", "", "",
            "", "", "", "", "", "");
        this.props.getMemberships(this.props.user.user.RegistrationID, 0, 10000, "active", "");
        this.props.initialiseSales(this.props.user.user.RegistrationID)
        this.props.initialiseCustomers(this.props.user.user.RegistrationID)
        this.props.getUniqueNoList(store.key);
        this.setState({ loading: false });
    }
    

    render() {
        const style = {
            background: "#ecf0f5"
        }
        return <div className="content-wrapper" style={style}>
            {this.props.user.store && <div>
                <section className="content-header">
                    <h1>
                        Welcome to POSible!
                            <small></small>
                    </h1>
                </section>
                <section className="content">
                </section>
            </div>}
            {this.state.loading && <Loader
                value={this.state.value}
                show={this.state.loading} />}
            {!this.props.user.store && this.state.stores && this.state.stores.length > 0 && <React.Fragment>
                <section className="content">
                    <div className="login-box">
                        <div className="form-group">
                            <label >Please select store</label>
                            <Typeahead
                                autoFocus={true}
                                allowNew={false}
                                multiple={false}
                                options={this.state.stores}
                                labelKey="StoreName"
                                placeholder="Select store"
                                filterBy={['StoreCode', 'StoreName', 'Address1', 'Address2', 'City', 'State', 'Country']}
                                bsSize={'large'}
                                dropup={false}
                                highlightOnlyResult={true}
                                onChange={(selected) => {
                                    this.setState({ selectedStore: selected[0] })
                                }}
                                renderMenuItemChildren={(option, props) => (
                                    <div>
                                        <div><b>{option.StoreCode} - {option.StoreName} </b></div>
                                        <div>{option.Address1}</div>
                                        <div>{option.Address2}</div>
                                        <div>{option.City}</div>
                                        <div>{option.State}</div>
                                        <div>{option.Country}</div>
                                    </div>
                                )}
                            />
                        </div>
                        <button type="submit"
                            className="btn btn-md btn-success btn-flat pull-right"
                            onClick={() => this.onStoreSelection(this.state.selectedStore)}>OK
                                </button>
                    </div>
                </section>
            </React.Fragment>}
        </div>
    }
}

export default Home