import React, { Component } from 'react'

class ExpenseCancel extends Component {
    constructor() {
        super()
        this.state = {
            cancelRemarks: '',
            cancelRemarksError: '',
            saving: false
        }
        this.onSaveCancelRemarks = this.onSaveCancelRemarks.bind(this)
    }

    componentDidMount() {      
    }

    onSaveCancelRemarks() {
        this.setState({ saving: true })
        this.props.cancelExpense(this.state.cancelRemarks, this.props.expense.key).then(
            (key) => {
                this.props.getToastr("Cancelled successfully")
            });
            this.props.onClose()
    }

    render() {
        return <div>
            <h3 style={{ marginTop: "0px" }}>Cancellation Remarks</h3>
            <div className="box-body">
                <div 
                className={this.state.cancelRemarksError ? "form-group has-error" : "form-group "}
                 >                    
                <textarea className="textarea" style={{ width: "100%", height: "200px"}}
                        onChange={(evt) => {
                            this.setState({ cancelRemarks: evt.target.value, cancelRemarksError: '' })
                        }}
                        value={this.state.cancelRemarks}></textarea>
                    {this.state.cancelRemarksError!=="" && <span class="help-block">{this.state.cancelRemarksError}</span>}
                </div>
            </div>
            <div className="box-footer">
                <button type="submit" className="btn btn-flat btn-md btn-flat btn-default" onClick={this.props.onClose} >Cancel</button>
                <button type="submit" className="btn btn-flat btn-md btn-flat btn-success pull-right" onClick={this.onSaveCancelRemarks} disabled={this.state.saving}>Save</button>
            </div>
        </div>
    }
}

export default ExpenseCancel