import firebase, {database} from '../firebase'
import * as actionTypes from '../actionTypes'
import * as constants from '../Constatnts'

export function getTransactionTypes(registrationID){
    return (dispatch) => {
    return database.collection("TransactionTypes").where("RegistrationID","==",registrationID).orderBy("TransactionType").get().then((querySnapshot) => {
        let transactionTypes = []
        querySnapshot.forEach((doc) => {
            transactionTypes.push( { key:doc.id,...doc.data()}) 
        })
        dispatch({type: actionTypes.TRANSACTION_TYPES_GET,transactionTypes})
     })
    }
}

export function deleteTransactionType(key){
    return (dispatch, getState) =>{
        return database.collection("TransactionTypes").doc(key).update({
          IsDeleted:true,
          ActionOn: firebase.firestore.FieldValue.serverTimestamp(),
            ActionBy: getState().user.user.Name,
            ActionByUID: getState().user.user.UID,
            ActionByEmailID: getState().user.user.EmailID,

            Action: constants.AUDIT_TRAIL_DELETE
        }).then(() => { return true })
    }
}

export function saveTransactionType(transactionType, key){
    return (dispatch, getState) => {
        transactionType.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        transactionType.ActionBy = getState().user.user.Name
        transactionType.ActionByUID = getState().user.user.UID
        transactionType.ActionByEmailID = getState().user.user.EmailID

        if(key.trim()!== "")
        {
            transactionType.Action = constants.AUDIT_TRAIL_EDIT
            return database.collection("TransactionTypes").doc(key).update({
                ...transactionType
              }).then(()=>{
                return key
              }).catch(function(error) {
                  console.error("Error updating transaction types: ", error);
              })
        }
        else
        {
            transactionType.Action = constants.AUDIT_TRAIL_NEW
            return database.collection("TransactionTypes").add({
                ...transactionType
              })
              .then(function(docRef) {
                  return docRef.id
              })
              .catch(function(error) {
                  console.error("Error adding transaction types: ", error);
              })
        }
        
    }
}
