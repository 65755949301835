import React,{Component} from 'react'
import Modal from 'react-responsive-modal'
import {Link } from 'react-router-dom'
import CancelTransaction from '../CancelTransaction'
import * as actionTypes from '../../actionTypes';
import AuditTrail from '../AuditTrail'
import * as constants from '../../Constatnts'
import Print from '../Sales/Print'
import UniqueNoInput from './UniqueNoInput'

class ViewInventory extends Component{

    constructor()
    {
      super()
      this.state = {
        inventory:null,
        key:'',
        showCancel:false,
        returnTransactionType:null,
        print:false,
        showEditButton:false,
        showCancelButton:false,
        showReturnButton:false,
        includePriceAndTax:false,
        print:false,
        printChecked:false,
        sendEmailChecked:false,
        sendSMSChecked :false,
        printData :null   ,
        selectedPringConfigID:'',
        selectedPrintConfigPrintDisplayName:'',
        showUniqueNoInput:false,
        selectedLineItem:null,
        selectedProduct:null,
        processing:false
      }
      this.load = this.load.bind(this)
      this.onCancel = this.onCancel.bind(this)
      this.printFormatting = this.printFormatting.bind(this)
      this.roundUpTo2 = this.roundUpTo2.bind(this)
      this.onCancelButtonClicked = this.onCancelButtonClicked.bind(this)
      this.onPurchaseReturnClicked= this.onPurchaseReturnClicked.bind(this)
    }

    componentDidMount(){
        this.load()
    }
    
    roundUpTo2(num){
        return Math.round(Number(num) * 100) / 100
    }
    
    printFormatting(inventory){
        
        inventory.TransactionDate = (new Date(inventory.TransactionDate.seconds*1000)).toString().substring(0,24)  
        inventory.AmountInWords= this.props.convertNumberToWords(inventory.BillAmount)  
        inventory.LineItems.map((lineItem, index) => {
            inventory.LineItems[index].SrNo = index +1
        })
        let printTaxComponents= []
        inventory.LineItems.map((lineItem, index) => {
            if(lineItem.TaxID !="")
            {
                lineItem.TaxComponentAmount.map((t)=>{
                    let indexFound = -1 
                    printTaxComponents.map((pt,ptIndex)=>{
                        if(t.TaxType===pt.TaxType)
                        {
                            indexFound = ptIndex
                            return
                        }
                    })
                    if(indexFound<0)
                    {
                        printTaxComponents.push({ TaxType:t.TaxType,
                            TaxAmount:this.roundUpTo2(Number(t.TaxAmount))
                           })
                    }
                    else
                    {
                        printTaxComponents[indexFound].TaxAmount =this.roundUpTo2( Number(printTaxComponents[indexFound].TaxAmount )+ Number(t.TaxAmount))
                    }
                })
            }
        })
        inventory.PrintTaxComponents = printTaxComponents
        inventory.LineItems.map((lineItem, index)=>{
            for(var i=0; i<printTaxComponents.length; i++ )
            {
                if(lineItem.TaxComponentAmount.length<(i+1))
                {
                    lineItem.TaxComponentAmount.push({TaxName:'', TaxAmount:0, TaxPercentage:'',TaxType:''})
                }
            }
        })
    }

    load()
    {
        const key = this.props.match.params.key
        if(key)
        {   
            let showCancelButton= false
            let showEditButton= false
            let showReturnButton = false
            let includePriceAndTax = false
            let selectedPringConfigID = ""
            let printConfig = null
            let printData = null
            this.props.getInventoryDetails(key).then((inventory)=> {
                // console.log('inventory', inventory)
                printConfig = this.props.config.PrintConfigs.filter((e)=> !e.IsDeleted 
                && e.TransactionTypeID === inventory.TransactionType.key)
                if(printConfig.length > 0 )
                {
                    selectedPringConfigID = printConfig[0].key
                    // selectedPrintConfigPrintDisplayName =printConfig[0].PrintDisplayName
                    let inventoryPrint = {...inventory}
                    this.printFormatting(inventoryPrint)
                    printData = {Inventory:inventoryPrint,PrintConfig:this.props.config.PrintConfigs.filter((e)=> e.key === selectedPringConfigID)[0]}
                    // console.log('printData', printData)
                }
                
                if(inventory.TransactionType.hasOwnProperty('IncludePriceAndTax'))
                {
                    includePriceAndTax = inventory.TransactionType.IncludePriceAndTax
                }
                let childTransaction = false
                if(inventory.hasOwnProperty('ChildTransactionNo'))
                {
                    if(inventory.ChildTransactionNo !=="")
                    {
                        childTransaction=true
                    }
                }
            if(inventory.TransactionType.TransactionType.toLowerCase() !=="opening stock" 
            && inventory.TransactionType.TransactionType.toLowerCase()  !=="stock update"
            && !inventory.hasOwnProperty('CancellationReason') && !childTransaction
            // && (!inventory.hasOwnProperty('ChildTransactionNo') && inventory.ChildTransactionNo ==="") 
            )
            {
                showEditButton=true
            }
            if(inventory.TransactionType.TransactionType.toLowerCase() !=="opening stock" 
            && inventory.TransactionType.TransactionType.toLowerCase()  !=="stock update"
            && !inventory.hasOwnProperty('CancellationReason') && !childTransaction
            // && (inventory.hasOwnProperty('ChildTransactionNo') && inventory.ChildTransactionNo ==="")
            ) 
            {
                showCancelButton=true
            }
            if(inventory.TransactionType.TransactionType .toLowerCase() !=="opening stock" 
            && inventory.TransactionType.TransactionType.toLowerCase()  !=="stock update"
            && inventory.TransactionType.TransactionType.toLowerCase()!=="purchase return" 
            && !inventory.hasOwnProperty('CancellationReason') 
            && inventory.TransactionType.UpdateStockChecked
            && inventory.TransactionType.TransactionMovement ==="vendortostore" 
            && !childTransaction
            // && (inventory.hasOwnProperty('ChildTransactionNo') && inventory.ChildTransactionNo ==="")
            ) 
            {
                showReturnButton=true
            }
            let returnTransactionType = null
            this.props.config.TransactionTypes.filter((e)=> !e.ISDeleted && e.TransactionType.toLowerCase() === "purchase return").map((t)=>{
                returnTransactionType = t
            })
            this.setState({inventory:inventory, 
                key:key, 
                returnTransactionType:returnTransactionType,
                showCancelButton:showCancelButton,
                showEditButton:showEditButton,
                showReturnButton:showReturnButton,
                includePriceAndTax:includePriceAndTax,
                selectedPringConfigID:selectedPringConfigID,
                printConfig:printConfig,
                printData:printData
             })
         })
        }
    }

    async onCancelButtonClicked(){
       this.setState({processing:true})
        for(let i=0;i< this.state.inventory.LineItems.length; i++ ){
            let m = this.state.inventory.LineItems[i]
            // console.log(m)
            if(m.hasOwnProperty('UniqueNoList') && m.UniqueNoList.length > 0)
            {
                let uniqueNoList = m.UniqueNoList
                for(let i=0;i<uniqueNoList.length;i++)
                {
                    let uniqueNo = null
                    uniqueNo = await this.props.getUniqueNoIDBasedOnTransaction(this.props.user.store.key, 
                    m.Product.key,
                    uniqueNoList[i].UniqueNo1,uniqueNoList[i].UniqueNo2,uniqueNoList[i].UniqueNo3,uniqueNoList[i].UniqueNo4)   
                    // console.log('uniqueNo',uniqueNo)
                    if(uniqueNo && uniqueNo.TransactionID !== this.state.key.trim())
                    {
                        alert('Unique no already used. Cannot cancel record.')
                        this.setState({processing:false})
                        return 
                    }
                }
            }
        }
        this.setState({showCancel:true,processing:false})
    }

    onCancel(cancellationReason)
    {
        this.props.cancelInventory(cancellationReason, this.state.key).then(()=>
            {
                this.props.getToastr("Transaction cancelled successfully")                        
                        setTimeout(function () { //Start the timer
                        this.load()
                        this.setState({showCancel:false})
                        }.bind(this), 3000)
            })
    }

    async onPurchaseReturnClicked(){
        this.setState({processing:true})
        let inventory = this.state.inventory
        for(let i=0;i< inventory.LineItems.length; i++ ){
            let m = inventory.LineItems[i]
            // console.log(m)
            if(m.hasOwnProperty('UniqueNoList') && m.UniqueNoList.length > 0)
            {
                let uniqueNoList = m.UniqueNoList
                for(let i=0;i<uniqueNoList.length;i++)
                {
                    let uniqueNo = null
                    uniqueNo = await this.props.getUniqueNoIDBasedOnTransaction(this.props.user.store.key, 
                    m.Product.key,
                    uniqueNoList[i].UniqueNo1,uniqueNoList[i].UniqueNo2,uniqueNoList[i].UniqueNo3,uniqueNoList[i].UniqueNo4)   
                    console.log('uniqueNo',uniqueNo)
                    if(uniqueNo && uniqueNo.TransactionID !== this.state.key.trim())
                    {
                        alert('Unique no already used. Cannot create purchase return.')
                        this.setState({processing:false})
                        return 
                    }
                    else if(!uniqueNo){
                        alert('Unique no not found. Please contact support@posible.in')
                        this.setState({processing:false})
                        return
                    }
                    uniqueNoList[i].key = uniqueNo.key
                }
                inventory.LineItems[i].UniqueNoList = uniqueNoList
            }
        }
        console.log('inventory',inventory)
        this.setState({processing:false})
        this.props.history.push({pathname:'/home/inventory/inventory/',
                    state:{type:actionTypes.INVENTORY_NEW, 
                        key: this.state.key, 
                        transactionType:this.state.returnTransactionType, 
                        inventory:inventory}  })
    }

    render()
    {
        return <div className="content-wrapper">
         {(this.state.inventory && !this.state.print) && <div>
        <section className="invoice">
            <div className="row">
                <div className="col-xs-12">
                <h3 className="page-header">
                    {this.state.inventory.TransactionType.TransactionType} - #{this.state.inventory.TransactionNo}
                </h3>
                </div>
            </div>
            <div className="row invoice-info">
                <div className="col-sm-3 invoice-col">
                    <p className="lead" style={{marginBottom:"5px"}}>Transaction Details:</p>
                    <address>
                        <div>No: {this.state.inventory.TransactionNo}</div>
                        <div>Date: {(new Date(this.state.inventory.TransactionDate.seconds*1000)).toString().substring(0,15)}</div>
                        <div>Store Code: {this.state.inventory.Store.StoreCode}</div>
                        <div>Store Name: {this.state.inventory.Store.StoreName}</div>
                        {this.state.inventory.StoreLocation  && <div>Store Location : {this.state.inventory.StoreLocation}</div>}
                        {this.state.inventory.ParentTransactionKey  && <span className="badge bg-lg bg-green">{this.state.inventory.ParentTransactionNo} </span>}
                        {this.state.inventory.CancellationReason  && <span className="badge bg-lg bg-red">Cancellation Reason:{this.state.inventory.CancellationReason}</span>}
                        {this.state.inventory.PurchaseReturnTransactionNo && <span className="badge bg-lg bg-orange">Purchase return done via {this.state.inventory.PurchaseReturnTransactionNo}  </span>}
                    </address>
                </div>
                <div className="col-sm-3 invoice-col">
                {this.state.inventory.Vendor && <React.Fragment>
                    <p className="lead" style={{marginBottom:"5px"}}>Vendor:</p>
                    {this.state.inventory.Vendor && <address>
                    {this.state.inventory.Vendor.VendorCode !=="" &&  <div>Code: {this.state.inventory.Vendor.VendorCode}</div>}
                    <div>Name: {this.state.inventory.Vendor.VendorFirstName} {this.state.inventory.Vendor.VendorLastName} </div>
                    {this.state.inventory.Vendor.PhoneNo !=="" && <div>Phone No: {this.state.inventory.Vendor.PhoneNo}</div>}
                    {this.state.inventory.Vendor.EmailID !=="" && <div>Email ID: {this.state.inventory.Vendor.EmailID}</div>}
                    </address>}
                    </React.Fragment>}
                    {(this.state.inventory.TransactionType.TransactionMovement === "storetostore" && this.state.inventory.TransactionType.UpdateStockInwardOrOutward ==="outward") && <React.Fragment>
                    <p className="lead" style={{marginBottom:"5px"}}>To Store:</p>
                    {this.state.inventory.ToStore}
                    </React.Fragment>}
                    {((this.state.inventory.TransactionType.TransactionMovement === "storetostore" || (this.state.inventory.TransactionType.TransactionMovement === "store" && this.state.inventory.TransactionType.TransactionType.toLowerCase() === "transfer in") ) && this.state.inventory.TransactionType.UpdateStockInwardOrOutward ==="inward") && <React.Fragment>
                    <p className="lead" style={{marginBottom:"5px"}}>From Store:</p>
                    {this.state.inventory.FromStore}
                    </React.Fragment>}
                </div>
                <div className="col-sm-3 invoice-col">
                    
                </div>
                <div className="col-sm-3 invoice-col">
                    <p className="lead" style={{marginBottom:"5px"}}>Audit Trail:</p>
                    <address>
                                <div>Action: {this.state.inventory.Action}</div>
                                <div>Action By: {this.state.inventory.ActionBy}</div>                                                        
                                <div>Action Date: {(new Date(this.state.inventory.ActionOn.seconds*1000)).toString().substring(0,24)}</div>
                                <AuditTrail {...this.props} module={constants.MODULE_INVENTORY} parentKey={this.state.key}/>
                    </address>
                </div>
                
            </div>
            <div className="row">
                <div className="col-xs-12 table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Product</th>
                                <th>MRP</th>
                                {this.state.includePriceAndTax && <th>Supply Price</th>}
                                <th>Qty</th>
                                <th>In Stock Qty</th>
                                {this.state.includePriceAndTax && <th>Sub total</th>}
                                {this.state.includePriceAndTax && <th>Discount</th>}
                                {this.state.includePriceAndTax && <th>Tax</th>}
                                {this.state.includePriceAndTax && <th>Tax Amount</th>}
                                {this.state.includePriceAndTax && <th>Total</th>}
                            </tr>
                        </thead>
                        <tbody>
                           {this.state.inventory.LineItems.map((lineItem, index )=>
                           <tr key={index}>
                                <td>{index+ 1}</td>
                                <td>{lineItem.Product.Name}
                                <div className="sales-sub-text">SKU - {lineItem.Product.SKU}</div>
                                {lineItem.Product.BarCode !== ""  && <div className="sales-sub-text">Barcode - {lineItem.Product.BarCode} </div> }
                                {lineItem.HSNSACCode !== ""  && <div className="sales-sub-text">HSNSACCode - {lineItem.HSNSACCode} </div> }
                                {lineItem.Notes !== ""  && <div className="sales-sub-text">Notes - {lineItem.Notes} </div> }
                                </td>
                                <td>{lineItem.Product.MRP}</td>
                                {this.state.includePriceAndTax && <td>{lineItem.SupplyPrice}</td>}
                                <td>{lineItem.Qty}
                                {(lineItem.hasOwnProperty('UniqueNoList') 
                                && lineItem.UniqueNoList.length > 0) && <div> 
                                        <button type="submit"
                                        style={{width:"100%"}}
                                        className="btn btn-md btn-default btn-flat pull-right"
                                        onClick={()=> this.setState({showUniqueNoInput:true, selectedLineItem: lineItem, 
                                        selectedLineItemIndex:index, 
                                        selectedProduct:lineItem.Product})} > 
                                        {lineItem.Product.UniqueLabel.trim()!==""? lineItem.Product.UniqueLabel : "Unique No"}
                                        </button>
                                    </div>}
                                </td>
                                <td>{lineItem.InStockQty}</td>
                                {this.state.includePriceAndTax && <td>{lineItem.SubTotal}</td>}
                                {this.state.includePriceAndTax && <td>
                                    {lineItem.DiscountAmount}
                                    <div className="sales-sub-text">{lineItem.DiscountPer} %</div>
                                </td>}
                                {this.state.includePriceAndTax && <td>{lineItem.TaxGroup}
                                {lineItem.TaxComponentAmount.map((t)=> 
                                <div className="sales-sub-text">{t.TaxName} {t.TaxPercentage}%:{t.TaxAmount}</div>)}
                                </td>}
                                {this.state.includePriceAndTax && <td>{lineItem.TotalTax}
                               
                                </td>}
                                {this.state.includePriceAndTax &&  <td>{lineItem.Amount}</td>}
                            </tr>
                        )} 
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            {this.state.includePriceAndTax &&<td></td>}
                            <td><b>{this.state.inventory.BillQty}</b></td>
                            {this.state.includePriceAndTax && <td><b>{this.state.inventory.BillSubTotal}</b></td>}
                            {this.state.includePriceAndTax && <td><b>{this.state.inventory.BillDiscount}</b></td>}
                            {this.state.includePriceAndTax && <td></td>}
                            {this.state.includePriceAndTax && <td><b>{this.state.inventory.BillTaxAmount}</b></td>}
                            {this.state.includePriceAndTax && <td><b>{this.state.inventory.BillAmount}</b></td>}
                        </tr>
                        </tbody>
                        </table>
                    </div>
            </div>
            <div className="row">
                 {this.state.includePriceAndTax && <div className="col-xs-6">
                    <p className="lead" style={{marginBottom:"5px"}}>Tax Summary:</p>
                    {this.state.inventory.TaxSummaryList && <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>HSN/SAC</th>
                                            <th>Taxable Amount</th>
                                            <th>Tax</th>
                                            <th>Tax Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.inventory.TaxSummaryList.map((t,index)=><tr key={index}>
                                            <td>{t.HSNSACCode}</td>
                                            <td>{t.TaxableAmount}</td>
                                            <td>{t.TaxGroup}</td>
                                            <td>{t.TotalTax}</td>
                                        </tr>)}
                                    </tbody>
                                </table>}

                </div>}
                <div className="col-xs-6">
                    <p className="lead" style={{marginBottom:"5px"}}>Notes:</p>
                    <address>
                        {this.state.inventory.Notes}
                    </address>
                </div>
        </div>
        <div className="row">
            <div className="col-md-4">
                {this.state.selectedPringConfigID !=="" && <div className="input-group"> <div className="input-group-btn"><button style={{ marginBottom: "10px",marginRight: "5px"}}
                    onClick={()=>{
                        this.setState({print:true,printChecked:true})
                    }}
                    className="btn btn-md btn-default btn-flat">Print
                </button></div>
                 {/* <span 
                    style={{ marginBottom: "10px", marginRight: "10px" }}> */}
                    <select className="form-control input-md" 
                        value={this.state.selectedPringConfigID} 
                        onChange= {(evt) => this.setState({selectedPringConfigID:evt.target.value,
                            selectedPrintConfigPrintDisplayName: evt.target.options[evt.target.selectedIndex].text })}>
                            {this.props.config.PrintConfigs.filter((e)=> !e.IsDeleted 
                            && e.TransactionTypeID === this.state.inventory.TransactionType.key ).map((t, index)=> 
                            <option key={index} value={t.key}>{t.PrintDisplayName}</option>
                            )}
                    </select>
                </div>}
                </div>
                <div className="col-md-8">
                <Link to={{ pathname: "/home/inventory/inventorylist", state: { type: "list" } }}
                disabled={this.state.processing}
                className="btn btn-flat btn-default pull-right"><i className="fa fa-arrow-left"></i> Back</Link>   
                
                {this.state.showEditButton  && <Link to={
                    {pathname:'/home/inventory/editinventory/', 
                    state:{type:actionTypes.INVENTORY_EDIT, key:this.state.key, transactionType:this.state.inventory.TransactionType, inventory:this.state.inventory}}}   
                    type="button" style={{marginRight: "5px"}} 
                    disabled={this.state.processing}
                    className="btn btn-info btn-flat pull-right">
                    <i className="fa fa-file-o"></i> 
                    Edit {this.state.inventory.TransactionType.TransactionType}
                </Link>}
                {this.state.showReturnButton && <button type="button" 
                className="btn btn-flat btn-danger pull-right" 
                style={{marginRight: "5px"}} 
                disabled={this.state.processing}
                onClick={this.onPurchaseReturnClicked}>
                    <i className="fa fa-minus-circle"></i> Purchase Return 
                </button>}
                {this.state.showCancelButton && <button type="button" 
                className="btn btn-flat btn-danger pull-right" 
                style={{marginRight: "5px"}} 
                disabled={this.state.processing}
                onClick={this.onCancelButtonClicked}>
                    <i className="fa fa-minus-circle"></i> Cancel {this.state.inventory.TransactionType.TransactionType}
                </button>}
                
            </div>
        </div>
            </section>
        <Modal open={this.state.showCancel} onClose={() => this.setState({showCancel:false})} center>
                <CancelTransaction   
                {...this.props} 
                transactionName= {this.state.inventory.TransactionType.TransactionType}
                onSave={(cancellationReason) => {
                    this.onCancel(cancellationReason)}}
                onClose={() => this.setState({showCancel:false})}
                /> 
            
        </Modal>
        </div>}
        <Modal open={this.state.showUniqueNoInput} 
            onClose={() => this.setState({ showUniqueNoInput: false })} center>
                <UniqueNoInput 
                {...this.props}
                transactionID={this.state.key}
                product={this.state.selectedProduct}
                lineItem={this.state.selectedLineItem}
                mode={"view"}
                onOK={()=>{
                    this.setState({ showUniqueNoInput: false })
                }}
                onClose={() => this.setState({ showUniqueNoInput: false })}/>
            </Modal>
        {this.state.print && <div>
                <Print  {...this.props}   
                 printChecked = {this.state.printChecked}
                 sendEmailChecked={this.state.sendEmailChecked}
                 sendSMSChecked = {this.state.sendSMSChecked}
            printData = {this.state.printData}   
            onClose={()=> {this.setState({print:false})
            this.load()
                }
            }
            />
        </div>}
    </div>
   
    }
}

export default ViewInventory