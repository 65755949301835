import * as firebase from 'firebase';
// Initialize Firebase
var config = {
  apiKey: "AIzaSyDU8LquL8LEHACl6zOZPCl6qhvRgKpZ0-M",
  authDomain: "posible-app-prod.firebaseapp.com",
  databaseURL: "https://posible-app-prod.firebaseio.com",
  projectId: "posible-app-prod",
  storageBucket: "posible-app-prod.appspot.com",
  // messagingSenderId: "1057547901573"
  //expozaye-pos
  // apiKey: "AIzaSyATrtyy0gIJqNcJPrLPpy5gMl2RFvnc8kI",
  // authDomain: "pos-expozaye.firebaseapp.com",
  // databaseURL: "https://pos-expozaye.firebaseio.com",
  // projectId: "pos-expozaye",
  // storageBucket: "pos-expozaye.appspot.com",
  // messagingSenderId: "252910197754"

  // apiKey: "AIzaSyAzOS1qexwQussaKBpWx-Hiph0RROjl7fk",
  // authDomain: "photowall-e754d.firebaseapp.com",
  // databaseURL: "https://photowall-e754d.firebaseio.com",
  // projectId: "photowall-e754d",
  // storageBucket: "photowall-e754d.appspot.com",
  // messagingSenderId: "704411315591"
};
firebase.initializeApp(config);

export const database = firebase.firestore()
export const functions = firebase.functions();

// database.settings({
//   timestampsInSnapshots: true
// });

// var connectedRef = firebase.database().ref(".info/connected");
// connectedRef.on("value", function (snap) {
//   if (snap.val() === true) {
//     alert("connected");
//   } else {
//     alert("not connected");
//   }
// });

database.enablePersistence();

export const storageRef = firebase.storage().ref();
export const auth = firebase.auth();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const twitterProvider = new firebase.auth.TwitterAuthProvider();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();

export default firebase