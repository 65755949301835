import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Loader from '../Loader'
import Pagination from 'react-js-pagination'
import * as constatnts from '../../Constatnts'
import { isNullOrUndefined } from 'util'
import { Link } from 'react-router-dom';

class WalletLedger extends Component {
    constructor() {
        super()
        this.state = {
            walletLedgers: [],
            searchTransactionNo : '',
            searchTransactionFromDate : '',
            searchTransactionToDate : '',
            activePage:1,
            itemsCountPerPage:20,
            totalItemsCount:0,
            recordFrom:0,
            paginationStatus:'No records to display'
        }
        this.loadWalletLedger = this.loadWalletLedger.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.onClearSearch = this.onClearSearch.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.onExportClick = this.onExportClick.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.showPaginationStatus = this.showPaginationStatus.bind(this)
    }

    componentDidMount() {
        this.loadWalletLedger(this.state.recordFrom,
            this.state.searchTransactionNo,
            this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate);
    }

    loadWalletLedger(from, searchTransactionNo, searchTransactionFromDate, searchTransactionToDate) {
        this.setState({ loading: true })
        this.props.getWalletLedgersByCustomer(this.props.location.state.Customer.key, from, this.state.itemsCountPerPage,
                    searchTransactionNo, searchTransactionFromDate, searchTransactionToDate)
            .then((result) => {
                this.setState({ walletLedgers: result.searchResult, totalItemsCount: result.totalItemsCount, loading: false })
                this.showPaginationStatus()
            });            
    }

    onSearchClick() {
        this.loadWalletLedger(
            this.state.recordFrom,
            this.state.searchTransactionNo,
            this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate
        )
    }

    onClearSearch() {
        this.setState({ recordFrom: 0, searchTransactionNo: '', searchTransactionFromDate: '', searchTransactionToDate: '' })
        this.loadWalletLedger(0, "", "", "")
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }

    onExportClick() {
        this.setState({ exportCalled: true })
        this.props.getWalletLedgersByCustomer(this.props.location.state.Customer.key, 0, 10000,
            this.state.searchTransactionNo, this.state.searchTransactionFromDate, this.state.searchTransactionToDate)
            .then((result) => {
                // console.log(result.searchResult);
                if (isNullOrUndefined(result.searchResult) || result.searchResult.length === 0) {
                    this.props.getToastr("No Record Found")
                    return
                }
                let customerWallets = []
                result.searchResult.map((ledger) => {
                    let customerWallet = {
                        "Transaction Date" : (new Date(ledger.TransactionDate)).toString().substring(0, 24),
                        "Narration" : ledger.CreditAmount > 0 ? "Credit for Transaction: " + ledger.TransactionNo : "Debit for Transaction: " + ledger.TransactionNo,
                        "Credit Amount" : ledger.CreditAmount,
                        "Debit Amount" : ledger.DebitAmount,
                        "Wallet Closing Balance" : ledger.WalletClosingBalance
                    }
                    customerWallets.push(customerWallet);
                })
                const csvData = constatnts.objToCSV(customerWallets);
                this.setState({ exportCalled: false })
                const csvBlob = new Blob([csvData], { type: "text/csv" });
                const url = window.URL.createObjectURL(csvBlob);
                const a = document.createElement("a");
                a.setAttribute("hidden", "");
                a.setAttribute("href", url);
                a.setAttribute("download", "CustomerWalletLedger.csv");
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
    }

    handlePageChange(pageNumber) {
        let recordFrom = ((pageNumber - 1) * this.state.itemsCountPerPage)
        this.setState({ activePage: pageNumber, recordFrom: recordFrom });
        this.loadWalletLedger(
            this.state.recordFrom,
            this.state.searchTransactionNo,
            this.state.searchTransactionFromDate,
            this.state.searchTransactionToDate
        )
    }

    showPaginationStatus() {
        console.log('this.state.recordFrom', this.state.recordFrom)
        let paginationStatus
        if ((this.state.recordFrom + 1) === this.state.totalItemsCount) {
            paginationStatus = "Showing " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else if ((this.state.recordFrom + this.state.itemsCountPerPage + 1) > this.state.totalItemsCount) {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + (this.state.recordFrom + this.state.itemsCountPerPage) + " of " + this.state.totalItemsCount
        }
        this.setState({ paginationStatus: paginationStatus })
    }

    render() {
        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6">
                                        <font style={{ fontSize: "30px" }}>Wallet Ledger</font>
                                    </div>
                                    <div className="col-md-6">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={() => this.props.history.push({ pathname: "/home/reports/customerwalletledger" })}
                                        ><i className="fa fa-arrow-left"></i> Back</button>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="col-md-3">
                                            <div className="form-group"><br />
                                                <label>Transaction No</label>
                                                <input type="text"
                                                    onKeyPress={this.handleKeyPress}
                                                    autoFocus={true}
                                                    className="form-control input-md"
                                                    value={this.state.searchTransactionNo}
                                                    onChange={(evt) => this.setState({ searchTransactionNo: evt.target.value })} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group"><br />
                                                <label>From Date</label>
                                                <input type="date" className="form-control"
                                                    onChange={(evt) => this.setState({ searchTransactionFromDate: evt.target.value })}
                                                    value={this.state.searchTransactionFromDate}
                                                    onKeyPress={this.handleKeyPress} />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group"><br />
                                                <label>To Date</label>
                                                <input type="date" className="form-control"
                                                    onChange={(evt) => this.setState({ searchTransactionToDate: evt.target.value })}
                                                    value={this.state.searchTransactionToDate}
                                                    onKeyPress={this.handleKeyPress} />
                                            </div>
                                        </div>
                                        <div className="col-md-3"><br />
                                            <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                                style={{ marginTop: "25px", marginLeft: "5px" }}
                                                onClick={this.onSearchClick}
                                            >Search</button>
                                            <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                                onClick={this.onClearSearch} >Clear</button>
                                            <button class="btn btn-default btn-flat btn-md pull-right"
                                                style={{ marginTop: "25px", marginRight: "5px" }}
                                                onClick={this.onExportClick}
                                            >Export <i class="glyphicon glyphicon-download-alt " ></i></button>
                                        </div>
                                    </div>
                                </div>
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading &&
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Narration</th>
                                                <th>Credit</th>
                                                <th>Debit</th>
                                                <th>Balance</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.walletLedgers.map((ledger) =>
                                                <tr>
                                                    <td>{(new Date(ledger.TransactionDate)).toString().substring(0, 24)}</td>
                                                    <td>{ledger.CreditAmount > 0 ? "Credit for Transaction: " + ledger.TransactionNo : "Debit for Transaction: " + ledger.TransactionNo}</td>
                                                    <td>{ledger.CreditAmount}</td>
                                                    <td>{ledger.DebitAmount}</td>
                                                    <td>{ledger.WalletClosingBalance}</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>}
                                {this.state.totalItemsCount > 0 && <div className="pull-left" style={{ marginTop: "25px" }}>
                                    {this.state.paginationStatus}
                                </div>}
                                <div className="pull-right">
                                    <Pagination
                                        activePage={this.state.activePage} //this.state.activePage
                                        itemsCountPerPage={this.state.itemsCountPerPage}
                                        totalItemsCount={this.state.totalItemsCount}
                                        pageRangeDisplayed={2}
                                        onChange={this.handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }

}

export default withRouter(WalletLedger)