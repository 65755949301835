import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import Pagination from "react-js-pagination"
import * as constatnts from '../../Constatnts'
import { isNullOrUndefined } from 'util';
import Loader from '../Loader'



class ExpenseByParty extends Component {

    constructor() {
        super()
        this.state = {
            Expenses: [], 
            searchExpenseDateFrom: new Date().toISOString().split('T')[0],
            searchExpenseDateTo: new Date().toISOString().split('T')[0],
            searchParty: '', 
            loading: true,
            
        }
        this.onSearchClick = this.onSearchClick.bind(this)
        this.loadExpenses = this.loadExpenses.bind(this)
        this.onClearSearch = this.onClearSearch.bind(this)
        this.onExportClick = this.onExportClick.bind(this)

    }

    componentDidMount() {
        
        this.loadExpenses(
            this.state.searchExpenseDateFrom,
            this.state.searchExpenseDateTo,
            this.state.searchParty,
            )
    }

    loadExpenses( searchExpenseDateFrom, searchExpenseDateTo
        , searchParty) {
            this.setState({ loading: true })
        this.props.getExpenseByParty(this.props.user.user.RegistrationID, this.props.user.store.key,
             searchExpenseDateFrom, searchExpenseDateTo, searchParty,
            ).then((result) => {
                console.log(result.searchResult);
                this.setState({ Expenses: result.searchResult, totalItemsCount: result.totalItemsCount ,loading:false})
                

            })

    }


    onExportClick() {
        this.setState({ loading: false, exportCalled: true })
        this.props.getExpenseByParty(this.props.user.user.RegistrationID,
            this.props.user.store.key,
            this.state.searchExpenseDateFrom, 
            this.state.searchExpenseDateTo, 
            this.state.searchParty)
            .then((result) => {
                console.log(result.searchResult);
                if (isNullOrUndefined(result.searchResult) || result.searchResult.length === 0) {
                    this.props.getToastr("No Record Found")
                    return
                }
                let expenses = []
                result.searchResult.map((data) => {

                    console.log(JSON.stringify(data))
                    let expense = {
                        "Expense Category.": data.key,
                        "Paid Amount": data.PaidAmount,
                        "Pending Amount" : data.PendingAmount
                    }
                    expenses.push(expense);
                })
                const csvData = constatnts.objToCSV(expenses);
                this.setState({ loading: false, exportCalled: false })
                const csvBlob = new Blob([csvData], { type: "text/csv" });
                const url = window.URL.createObjectURL(csvBlob);
                const a = document.createElement("a");
                a.setAttribute("hidden", "");
                a.setAttribute("href", url);
                a.setAttribute("download", "ExpensePartyListReport.csv");
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
    }

    onClearSearch() {
        this.setState({
             searchExpenseDateFrom: new Date().toISOString().split('T')[0], searchExpenseDateTo: new Date().toISOString().split('T')[0]
            , searchParty: '', 
        })
        this.loadExpenses( this.state.searchExpenseDateFrom, this.state.searchExpenseDateTo
            , "")
    }


    onSearchClick() {
        this.loadExpenses(   
            this.state.searchExpenseDateFrom,
            this.state.searchExpenseDateTo,
            this.state.searchParty,
        )
    }

    

    render() {
       
        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border" style={{paddingTop:"10px",paddingBottom:"10px",paddingRight:"0px"}}>
                                <div className="col-md-6"  style={{padding:"0px"}}>
                                    <font style={{ fontSize: "30px" }}>Expense By Party</font>
                                </div>
                                <div className="col-md-6">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={() => this.props.history.push({ pathname: "/home/reports/allreport" })}
                                        ><i className="fa fa-arrow-left"></i> Back</button>
                                    </div>
                            </div>
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Expense Date from</label>
                                            <input type="date" className="form-control input-md"
                                                
                                                value={this.state.searchExpenseDateFrom}
                                                onChange={(evt) => this.setState({ searchExpenseDateFrom: evt.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Expense Date To</label>
                                            <input type="date" className="form-control input-md"
                                                
                                                value={this.state.searchExpenseDateTo}
                                                onChange={(evt) => this.setState({ searchExpenseDateTo: evt.target.value })}
                                            />
                                        </div>
                                    </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Party</label>
                                                <input type="text" className="form-control input-md"
                                                    
                                                    value={this.state.searchParty}
                                                    onChange={(evt) => this.setState({ searchParty: evt.target.value })}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginLeft: "5px" }}
                                            onClick={this.onSearchClick}
                                        >Search</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                            onClick={this.onClearSearch} >Clear</button>
                                            <button class="btn btn-default btn-flat btn-md pull-right" 
                                        style={{ marginTop: "25px", marginRight: "5px" }} 
                                        onClick={this.onExportClick} 
                                        > Export <i class="glyphicon glyphicon-download-alt " ></i></button>
                                    </div>
                                    
                                </div><br></br>
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading &&
                                <table className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                           
                                            <th>Party</th>

                                            
                                            <th> Paid Amount</th>

                                            <th>Due Amount</th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.Expenses && this.state.Expenses.map((Expense) =>
                                            <tr>
                                                
                                                <td>{Expense.key}</td>
                                                
                                                <td>{Expense.PaidAmount}</td> 

                                                <td>{Expense.PendingAmount}</td>


                                               
                                            </tr>
                                        )}
                                    </tbody>

                                </table>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default withRouter(ExpenseByParty)