import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Confirm from 'react-confirm-bootstrap';
import * as actions from '../../actionTypes'

class Campaigns extends Component {
    constructor() {
        super();
        this.state = {
            campaigns: [],
            customerVisited: '',
            customerInDays: '',
            noOfTransactionFrom: '',
            noOfTransactionTo: '',
            purchaseAmountFrom: '',
            purchaseAmountTo: '',
            membership: {},
            customerGender: '',
            customerAgeFrom: '',
            customerAgeTo: '',
            walletBalanceFrom: '',
            walletBalanceTo: '',
            event: '',
            receivePaymentFrom: '',
            receivePaymentTo: '',

            placeholder: '',
            message: ''
        }

        this.loadCampaigns = this.loadCampaigns.bind(this)
        this.onEditCampaigns = this.onEditCampaigns.bind(this)
        this.onDeleteCampaigns = this.onDeleteCampaigns.bind(this)
    }

    componentDidMount() {
        this.loadCampaigns()
    }

    loadCampaigns() {
        this.props.getCampaign(this.props.user.user.RegistrationID).then((campaigns) => {
            console.log("Load Campaign", campaigns);
            this.setState({ campaigns: campaigns })
        })
    }

    onEditCampaigns(campaign) {
        this.setState({ action: actions.EDIT_MEMBERSHIP })
        console.log(campaign)
    }

    onDeleteCampaigns(key) {
        console.log(key)
        this.props.deleteCampaign(key).then(() => {
            this.loadCampaigns()
        })
    }

    render() {
        const btnColStyle = { width: "120px" }
        return <div className="content-wrapper" >
            <section className="content-header">
                <h1>Campaigns</h1>
            </section>
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">

                                    <div className="col-md-12">
                                        <div className="pull-right">
                                            <Link to="/home/campaign/newcampaign" className="btn btn-success btn-flat btn-md" >Add Campaign</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                <table className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Type</th>
                                            <th>Options</th>
                                            <th>Stores</th>
                                            <th>Message</th>
                                            <th>Status</th>
                                            <th style={btnColStyle}>  </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.campaigns.map((campaign) =>
                                            <tr>
                                                <td>{campaign.Name}</td>
                                                <td>{campaign.Type}</td>
                                                <td>
                                                    {campaign.Type === "Recurring" &&
                                                        <div>Start Date: {campaign.StartDate} to End Data: {campaign.EndDate}
                                                        <br />
                                                        Cycle(in Days) : {campaign.CycleOfDays} 
                                                        <br />
                                                        Run Every(in Days) : {campaign.RunsEvery}
                                                    </div> }
                                                    {campaign.Type === "Specific Day" &&
                                                        <div>Start Date: {campaign.StartDate} to End Data: {campaign.EndDate}
                                                        <br />
                                                        Cycle(in Days) : {campaign.CycleOfDays} 
                                                        <br />
                                                        On Day : {campaign.OnDay}
                                                    </div> }
                                                    {campaign.Type === "One Time" &&
                                                        <div>Select Date: {campaign.StartDate}
                                                    </div> }
                                                    {campaign.CustomerSelected &&
                                                        <div>Customer : {campaign.CustomerVisited} in {campaign.CustomerInDays} Days </div>}

                                                    {campaign.TransactionSelected &&
                                                        <div>No of Transaction :{campaign.NoOfTransaction} in {campaign.NoOfTransactionDays} Days</div>
                                                    }
                                                    {campaign.PurchaseAmountSelected &&
                                                        <div>Purchase Amount : From {campaign.PurchaseAmountFrom} to {campaign.PurchaseAmountTo}</div>
                                                    }
                                                    {campaign.MembershipSelected &&
                                                        <div>Membership : {campaign.Membership.Name}</div>
                                                    }
                                                    {campaign.CustomerGenderSelected &&
                                                        <div>Gender : {campaign.CustomerGender}</div>
                                                    }
                                                    {campaign.WalletBalanceSelected &&
                                                        <div>Wallet Balance : From {campaign.WalletBalanceFrom} to {campaign.WalletBalanceTo}</div>
                                                    }
                                                    {campaign.EventSelected &&
                                                        <div>Event : {campaign.Event}</div>
                                                    }
                                                    {campaign.ReceivePaymentSelected &&
                                                        <div>Outstanding Amount : From {campaign.ReceivePaymentFrom} to {campaign.ReceivePaymentTo}</div>
                                                    }
                                                </td>
                                                {(campaign.AllStoresSelected || campaign.Stores)  && <td>
                                                {campaign.Stores && campaign.Stores.map((store) => 
                                                    <div>Stores : {store.label}</div>
                                                        )}
                                                        {campaign.AllStoresSelected && <div>Stores: All Stores</div>}
                                                </td>}
                                                <td>
                                                    {campaign.Placeholder}<br />
                                                    {campaign.Message}
                                                </td>
                                                <td>{campaign.CampaignStatus}</td>
                                                <td>
                                                    <div className="btn-group">
                                                        {campaign.IsDeleted ? <span className="label label-danger pull-right">Inactive</span> :
                                                            <div>
                                                                <Link to={`/home/campaign/edit/${campaign.key}`}
                                                                    type="button" className="btn btn-md btn-default btn-flat"><i className="fa fa-edit"></i></Link>
                                                                <Confirm
                                                                    onConfirm={() => this.onDeleteCampaigns(campaign.key)}
                                                                    body="Are you sure you would like to delete this membership?"
                                                                    confirmText="Delete"
                                                                    title="Delete Membership">
                                                                    <button className="btn btn-md btn-default btn-flat"><i className="fa fa-trash-o"></i></button>
                                                                </Confirm>
                                                            </div>
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default withRouter(Campaigns)