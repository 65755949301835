import React,{Component} from 'react'
import Modal from 'react-responsive-modal'
import {Link } from 'react-router-dom'
import CancelSales from './CancelSales'
import * as actionTypes from '../../actionTypes';
import AuditTrail from '../AuditTrail'
import * as constants from '../../Constatnts'
import Print from './Print'
import ProductConsumption from './ProductConsumption'
import UniqueNoInput from '../Inventory/UniqueNoInput'

class ViewSales extends Component{

    constructor()
    {
      super()
       this.state = {
       sale:null,
       salePrint:null,
       key:'',
       showCancelSales:false,
       salesReturnTransactionType:null,
       print:false,
       selectedPringConfigID:'',
       printData:null,
       selectedLineItem:null,
       selectedLineItemIndex:0,
       emailID:'',
       emailError:'',
       showEmail:false,
       printChecked:false,
       sendEmailChecked:false,
       editAllowed: false,
       returnAllowed:false,
       cancelAllowed:false
      }
      this.load = this.load.bind(this)
      this.sendEmail = this.sendEmail.bind(this)
      this.printFormatting = this.printFormatting.bind(this)
      this.roundUpTo2 = this.roundUpTo2.bind(this)
    }

    componentDidMount(){
        this.load()
    }

    sendEmail(){
        if(this.state.emailID.trim()==="")
        {
            this.setState({emailError:"Please specify email id"})
            return
        }
        let sale = this.state.salePrint
        sale.EmailID = this.state.emailID
        let printData = {Sale:sale,PrintConfig:this.props.config.PrintConfigs.filter((e)=> e.key === this.state.selectedPringConfigID)[0]}
        this.setState({print:true, printChecked:false, sendEmailChecked:true ,printData:printData,showEmail:false})//TBDpawan
    }
                  
     load()
    {
        
        const key = this.props.match.params.key
        if(key)
        {   
            
            let selectedPringConfigID = ''
            this.props.getSaleDetails(key).then(async(sale)=> {
            //check if wallte recharge
            // console.log('sale',sale)
            let returnAllowed = true 
            let editAllowed = true
            let cancelAllowed = true 

            console.log(JSON.stringify(sale));
            let printConfig = this.props.config.PrintConfigs.filter((e)=> !e.IsDeleted 
            && e.TransactionTypeID === sale.TransactionType.key)
            if(printConfig.length > 0 )
            {
                selectedPringConfigID = printConfig[0].key
            }

            let walletRechargeItem = sale.LineItems.filter((e)=> e.Product.ProductType ==="Wallet Recharge")
            if(walletRechargeItem.length> 0 )
            {
                editAllowed= false
                returnAllowed= false
            }

            let packageItem = sale.LineItems.filter((e)=> e.Product.ProductType ==="Package")
            if(packageItem.length> 0 )
            {
                //check if package is status pending
                let result = await this.props.getPackages(this.props.user.user.RegistrationID, 
                    sale.Store.key, 0, 100, "", "", sale.TransactionNo, "")

                if(result)
                {
                    if(result.searchResult.filter(f=> f.PackageStatus.toLowerCase() !=="pending" || f.PackageStatus.toLowerCase() !=="cancelled").length > 0)
                    {
                        cancelAllowed=false
                    }
                }  
                editAllowed= false
                returnAllowed= false
            }

            let walletRedeem = sale.Payments.filter((e)=> e.PaymentMode ==="Wallet")
            if(walletRedeem.length> 0 )
            {
                editAllowed= false
                returnAllowed= false
            }

            if(sale.CancellationReason )
            {
                editAllowed= false
                returnAllowed= false
                cancelAllowed= false
                // console.log('CancellationReason',cancelAllowed)
            }

            if(sale.TransactionType.TransactionType.toLowerCase() ==="sales return" )
            {
                returnAllowed= false
            }

            if(!sale.TransactionType.SalesImpactChecked )
            {
                returnAllowed= false
            }

            if(sale.hasOwnProperty('SalesReturnTransactionNo'))
            {
                if(sale.SalesReturnTransactionNo.trim()!=="")
                {
                    returnAllowed= false
                    cancelAllowed= false
                    // console.log('SalesReturnTransactionNo',cancelAllowed)
                }
            }

            if(sale.hasOwnProperty('ChildTransactionNo'))
            {
                if(sale.ChildTransactionNo.trim()!=="")
                {
                    returnAllowed= false
                    editAllowed= false
                    cancelAllowed= false
                    // console.log('ChildTransactionNo',cancelAllowed)
                }
            }
            
            if(sale.TransactionType.TransactionType.toLowerCase() === "receive payment")
            {
                returnAllowed= false
                editAllowed= false
            }

            if(sale.hasOwnProperty('ReceivedPaymentAmount') && sale.ReceivedPaymentAmount> 0 )
            {
                editAllowed= false
                cancelAllowed= false
            }   
            // console.log('this.props.getVisibility("Sales","update")',this.props.getVisibility("Sales","update"))
            if(this.props.getVisibility("Sales","update")==="none")
            {
                editAllowed= false
            }
            // style={{display: this.props.getVisibility("Sales","update")}}
            let salesReturnTransactionType = null
            this.props.config.TransactionTypes.filter((e)=> !e.ISDeleted && e.TransactionType.toLowerCase() === "sales return").map((t)=>{
                salesReturnTransactionType = t
            })
            let salePrint = {...sale}
            this.printFormatting(salePrint)
            this.setState({sale:sale, key:key, 
                salesReturnTransactionType:salesReturnTransactionType,
                selectedPringConfigID:selectedPringConfigID,
                salePrint:salePrint,
                editAllowed:editAllowed,
                returnAllowed:returnAllowed,
                cancelAllowed:cancelAllowed
            })
         })
        }
    }

    printFormatting(sale){
        
        // sale1.TransactionDate =  (new Date(sale.TransactionDate.seconds*1000)).toString().substring(0,24)
        sale.TransactionDate = sale.TransactionDate.toString().substring(0,24)  
       
       
        let billTaxableAmount = 0 
        let costPriceTotal = 0 
        let mrpTotal = 0
        sale.LineItems.map((lineItem, index) => {
            sale.LineItems[index].SrNo = index +1
            sale.LineItems[index].BasePrice = this.roundUpTo2(Number(sale.LineItems[index].TaxableAmount) / Number(sale.LineItems[index].Qty))
            if(lineItem.TaxComponentAmount && lineItem.TaxComponentAmount.length > 0 && lineItem.TaxInclusive)
            {
                let taxPercentage = 0
                let taxAmount = 0
                lineItem.TaxComponentAmount.map(m=>{
                    taxPercentage = taxPercentage + Number(m.TaxPercentage)
                })
                taxAmount = this.roundUpTo2(Number(lineItem.RetailPrice) * Number(taxPercentage) / (100 + Number(taxPercentage)))
                sale.LineItems[index].CostPrice = Number(lineItem.RetailPrice) - Number(taxAmount)
            }
            else
            {
                sale.LineItems[index].CostPrice = lineItem.RetailPrice
            }
            sale.LineItems[index].CostPriceTotal = this.roundUpTo2(Number(sale.LineItems[index].CostPrice) * Number(sale.LineItems[index].Qty))
            costPriceTotal = costPriceTotal + Number(sale.LineItems[index].CostPriceTotal)

            sale.LineItems[index].MRP = Number(lineItem.Product.MRP === 0 ? lineItem.RetailPrice :  lineItem.Product.MRP);

            sale.LineItems[index].MRPTotal = this.roundUpTo2(Number(sale.LineItems[index].MRP) * Number(sale.LineItems[index].Qty));
            mrpTotal = mrpTotal + Number(sale.LineItems[index].MRPTotal)

            billTaxableAmount = this.roundUpTo2(Number(billTaxableAmount) + Number(sale.LineItems[index].TaxableAmount))
        })
        sale.BillCostPriceTotal = costPriceTotal
        sale.BillMRPTotal = mrpTotal
        sale.MRPSaving = this.roundUpTo2(this.roundUpTo2(Number(sale.BillMRPTotal) - this.roundUpTo2(Number(sale.BillAmount))));

        sale.BillTaxableAmount = this.roundUpTo2(Number(billTaxableAmount))
       


        sale.AmountInWords= this.props.convertNumberToWords(sale.BillAmount)  
        // console.log('sale1.AmountInWords', sale1.AmountInWords)
        sale.LineItems.map((lineItem, index) => {
            sale.LineItems[index].SrNo = index +1
        })
        let printTaxComponents= []
        let printTaxComponentsWithRate= []
        sale.LineItems.map((lineItem, index) => {
            if(lineItem.TaxID !=="")
            {
                lineItem.TaxComponentAmount.map((t)=>{
                    let indexFound = -1 
                    printTaxComponents.map((pt,ptIndex)=>{
                        if(t.TaxType===pt.TaxType)
                        {
                            indexFound = ptIndex
                            return
                        }
                    })
                    if(indexFound<0)
                    {
                        printTaxComponents.push({ TaxType:t.TaxType,
                            TaxAmount:this.roundUpTo2(Number(t.TaxAmount))
                           })
                    }
                    else
                    {
                        printTaxComponents[indexFound].TaxAmount =this.roundUpTo2( Number(printTaxComponents[indexFound].TaxAmount )+ Number(t.TaxAmount))
                    }


                    let indexFoundTaxRate = -1 
                    printTaxComponentsWithRate.map((pt,ptIndex)=>{
                        if(t.TaxType===pt.TaxType && t.TaxPercentage ===pt.TaxPercentage )
                        {
                            indexFoundTaxRate = ptIndex
                            return
                        }
                    })
                    if(indexFoundTaxRate<0)
                    {
                        printTaxComponentsWithRate.push({ TaxType:t.TaxType,TaxPercentage:t.TaxPercentage,
                            TaxAmount:this.roundUpTo2(Number(t.TaxAmount)),  TaxableAmount: Number(lineItem.TaxableAmount)
                           })
                    }
                    else
                    {
                        printTaxComponentsWithRate[indexFoundTaxRate].TaxAmount =this.roundUpTo2( Number(printTaxComponentsWithRate[indexFoundTaxRate].TaxAmount )+ Number(t.TaxAmount))
                        printTaxComponentsWithRate[indexFoundTaxRate].TaxableAmount =this.roundUpTo2( Number(printTaxComponentsWithRate[indexFoundTaxRate].TaxableAmount )+  Number(lineItem.TaxableAmount))
                    }
                })
           }
        })
        sale.PrintTaxComponents = printTaxComponents
        sale.PrintTaxComponentsWithRate= printTaxComponentsWithRate
        sale.LineItems.map((lineItem, index)=>{
            for(var i=0; i<printTaxComponents.length; i++ )
            {
                if(lineItem.TaxComponentAmount.length<(i+1))
                {
                    lineItem.TaxComponentAmount.push({TaxName:'', TaxAmount:0, TaxPercentage:'',TaxType:''})
                }
            }
        })
        //wallet balance
        // sale.Payments.filter(f=> f.PaymentMode.toLowerCase()==="wallet").map(m=> sale.WalletBalance = Number(sale.Customer.WalletBalance )- Number(m.Amount))
        console.log('print sale', sale)
    }

    roundUpTo2(num){
        return Math.round(Number(num) * 100) / 100
    }
    
    render()
    {
        
        return <div className="content-wrapper">
         {(this.state.sale && !this.state.print) && <div>
        <section className="invoice">
            <div className="row">
                <div className="col-xs-12">
                <h3 className="page-header">
                    {this.state.sale.TransactionType.TransactionType} - #{this.state.sale.TransactionNo}
                </h3>
                </div>
            </div>
            <div className="row invoice-info">
                <div className="col-sm-3 invoice-col">
                    <p className="lead" style={{marginBottom:"5px"}}>Transaction Details:</p>
                    <address>
                        <div>No: {this.state.sale.TransactionNo}</div>
                        <div>Date: {this.state.sale.TransactionDate.seconds ? (new Date(this.state.sale.TransactionDate.seconds*1000)).toString().substring(0,24): new Date(this.state.sale.TransactionDate)}</div>
                        <div>Store Code: {this.state.sale.Store.StoreCode}</div>
                        <div>Store Name: {this.state.sale.Store.StoreName}</div>
                        <div>CR Code: {this.state.sale.CashRegister.CashRegisterCode}</div>
                        <div>CR Name: {this.state.sale.CashRegister.CashRegisterName}</div>
                        {this.state.sale.ParentTransactionKey  && <span className="badge bg-lg bg-green">Sales return for {this.state.sale.ParentTransactionNo} </span>}
                        {this.state.sale.CancellationReason  && <span className="badge bg-lg bg-red">Cancellation Reason:{this.state.sale.CancellationReason}</span>}
                        {this.state.sale.SalesReturnTransactionNo && <span className="badge bg-lg bg-orange">Sales return done via {this.state.sale.SalesReturnTransactionNo}  </span>}
                    </address>
                </div>
                <div className="col-sm-3 invoice-col">
                    <p className="lead" style={{marginBottom:"5px"}}>Customer:</p>
                    {this.state.sale.Customer && <address>
                    {this.state.sale.Customer.CustomerCode !=="" &&  <div>Code: {this.state.sale.Customer.CustomerCode}</div>}
                    <div>Name: {this.state.sale.Customer.FirstName} {this.state.sale.Customer.LastName} </div>
                    {this.state.sale.Customer.PhoneNo !=="" && <div>Phone No: {this.state.sale.Customer.PhoneNo}</div>}
                    {this.state.sale.Customer.EmailID !=="" && <div>Email ID: {this.state.sale.Customer.EmailID}</div>}
                    </address>}
                </div>
                <div className="col-sm-3 invoice-col">
                    <p className="lead" style={{marginBottom:"5px"}}>Notes:</p>
                    <address>
                        {this.state.sale.Notes}
                    </address>
                </div>
                {this.props.config.ConnectionStatus.Status === 'connected' && <div className="col-sm-3 invoice-col">
                    <p className="lead" style={{marginBottom:"5px"}}>Audit Trail:</p>
                    <address>
                                <div>Action: {this.state.sale.Action}</div>
                                <div>Action By: {this.state.sale.ActionBy}</div>                                                        
                                <div>Action Date: {this.state.sale.ActionOn.seconds ? (new Date(this.state.sale.ActionOn.seconds*1000)).toString().substring(0,24): ""}</div>
                                <AuditTrail {...this.props} module={constants.MODULE_SALES} parentKey={this.state.key}/>
                    </address>
                </div>}
            </div>
            <div className="row">
                <div className="col-xs-12 table-responsive">
                   {this.state.sale.TransactionType.TransactionType.toLowerCase()!=="receive payment" &&  <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Product</th>
                                <th>MRP</th>
                                <th>Price</th>
                                <th>Qty</th>
                                <th>Sub total</th>
                                <th>Discount</th>
                                <th>Tax</th>
                                <th>Tax Amount</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                           {this.state.sale.LineItems.map((lineItem, index )=>
                           <tr key={index}>
                                <td>{index+ 1}</td>
                                <td>{lineItem.Product.Name}
                                <div className="sales-sub-text">SKU - {lineItem.Product.SKU}</div>
                                {lineItem.Product.BarCode !== ""  && <div className="sales-sub-text">Barcode - {lineItem.Product.BarCode} </div> }
                                {lineItem.HSNSACCode !== ""  && <div className="sales-sub-text">HSNSACCode - {lineItem.HSNSACCode} </div> }
                                {lineItem.Notes !== ""  && <div className="sales-sub-text">Notes - {lineItem.Notes} </div> }
                                {lineItem.Employee.key !== ""  && <div className="sales-sub-text"> <i className="fa fa-user"/> {lineItem.Employee.EmployeeFirstName} {lineItem.Employee.EmployeeLastName}</div> }
                                {( ( this.props.registration.registration.hasOwnProperty('ProductConsumption') && this.props.registration.registration.ProductConsumption) &&   this.state.sale.TransactionType.TransactionType.toLowerCase() !=="sales return"  )&& <button  
                                onClick={()=> this.setState({showPoductConsumption:true, selectedLineItem:lineItem, selectedLineItemIndex:index})} 
                                className={lineItem.ConsumptionDetails ?  "btn btn-flat btn-success" :  "btn btn-flat btn-default"}>Product Consumption</button> }
                                </td>
                                <td>{lineItem.Product.MRP}</td>
                                <td>{lineItem.RetailPrice}</td>
                                <td>
                                    {lineItem.Qty}
                                    {(lineItem.hasOwnProperty('UniqueNoList') 
                                    && lineItem.UniqueNoList.length > 0) && <div> 
                                        <button type="submit"
                                        style={{width:"100%"}}
                                        className="btn btn-md btn-default btn-flat"
                                        onClick={()=> this.setState({showUniqueNoInput:true, 
                                            selectedLineItem: lineItem, 
                                            selectedLineItemIndex:index, 
                                            selectedProduct:lineItem.Product})} > 
                                            {lineItem.Product.UniqueLabel.trim()!==""? lineItem.Product.UniqueLabel : "Unique No"}
                                        </button>
                                    </div>}
                                </td>
                                <td>{lineItem.SubTotal}</td>
                                <td>
                                    {lineItem.DiscountAmount}
                                    <div className="sales-sub-text">{lineItem.DiscountPer} %</div>
                                </td>
                                <td>{lineItem.TaxGroup}</td>
                                <td>{lineItem.TotalTax}
                                {lineItem.TaxComponentAmount.map((t, index)=> 
                                <div key={index} className="sales-sub-text">{t.TaxName} {t.TaxPercentage}%:{t.TaxAmount}</div>)}
                                </td>
                                <td>{lineItem.Amount}</td>
                            </tr>
                        )} 
                        </tbody>
                        </table>}
                        {this.state.sale.TransactionType.TransactionType.toLowerCase()==="receive payment" &&  <table className="table table-striped">
                        <thead>
                            <tr>
                                <th style={{width:"10px"}}>#</th>
                                {/* <th >Transaction Date</th> */}
                                <th >Transaction No</th>
                                <th >Transaction Type</th>
                                <th >Transaction Amount</th>
                                <th >Balance Amount</th>
                                <th >Receive Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                           {this.state.sale.OutStandingPayments.map((lineItem, index )=>
                            <tr key={index}>
                                <td>{index + 1}</td>
                                {/* <td>{ lineItem.TransactionNo.trim()!=="" ? (new Date(lineItem.TransactionDate.seconds*1000)).toString().substring(0,24): ""}</td> */}
                                <td>{lineItem.TransactionNo}</td>
                                <td>{lineItem.TransactionType}</td>
                                <td>{lineItem.TransactionAmount}</td>
                                <td>{lineItem.OutStandingAmount}</td>
                                <td>{lineItem.ReceiveAmount}</td>
                         </tr>
                        )} 
                         <tr>
                            <td></td>
                            {/* <td></td> */}
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td><b>{this.state.sale.BillAmount}</b></td>
                        </tr>
                        </tbody>
                        </table>}
                    </div>
            </div>
            <div className="row">
                <div className="col-xs-3">
                <p className="lead" style={{marginBottom:"5px"}}>Payment Methods:</p>
                <div class="table-responsive">
                        <table class="table">
                            {this.state.sale.Payments.map((payment, index) => <tr key={index}>
                                <th style={{width:"50%"}}>{payment.PaymentMode}:</th>
                                <td>{payment.Amount}</td>
                            </tr>)}
                        </table>
                    </div>
                </div>
                <div className="col-xs-5">
                    {this.state.sale.TransactionType.TransactionType.toLowerCase()!=="receive payment" && <React.Fragment>
                    <p className="lead" style={{marginBottom:"5px"}}>Tax Summary:</p>
                    {this.state.sale.TaxSummaryList && <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>HSN/SAC</th>
                                            <th>Taxable Amount</th>
                                            <th>Tax</th>
                                            <th>Tax Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.sale.TaxSummaryList.map((t,index)=><tr key={index}>
                                            <td>{t.HSNSACCode}</td>
                                            <td>{t.TaxableAmount}</td>
                                            <td>{t.TaxGroup}</td>
                                            <td>{t.TotalTax}</td>
                                        </tr>)}
                                    </tbody>
                                </table>}
                            </React.Fragment>}

                </div>
                <div class="col-xs-4">
                {this.state.sale.TransactionType.TransactionType.toLowerCase()!=="receive payment" && <React.Fragment>
                <p class="lead" style={{marginBottom:"5px"}}>Totals</p>
                <div class="table-responsive">
                    <table class="table">
                    <tr>
                        <th style={{width:"50%"}}>Qty:</th>
                        <td>{this.state.sale.BillQty}</td>
                    </tr>
                    <tr>
                        <th>Subtotal:</th>
                        <td>{this.state.sale.BillSubTotal}</td>
                    </tr>
                    <tr>
                        <th>Discount</th>
                        <td>{this.state.sale.BillDiscount}</td>
                    </tr>
                    <tr>
                        <th>Tax:</th>
                        <td>{this.state.sale.BillTaxAmount}</td>
                    </tr>
                    {this.state.sale.RoundOff && <tr>
                        <th>Round Off:</th>
                        <td>{this.state.sale.RoundOff}</td>
                    </tr>}
                    <tr>
                        <th>Total:</th>
                        <td>{this.state.sale.BillAmount}</td>
                    </tr>
                    <tr>
                        <th>Paid Amount:</th>
                        <td>{this.state.sale.BillPaidAmount}</td>
                    </tr>
                    {this.state.sale.hasOwnProperty('ReceivedPaymentAmount') && <tr>
                        <th>Received Amount:</th>
                        <td>{this.state.sale.ReceivedPaymentAmount}</td>
                    </tr>}
                    {this.state.sale.hasOwnProperty('ReceivedPaymentAmount') && <React.Fragment>
                    {this.state.sale.ReceivedPaymentTransactions.map((m,index)=><tr key={index}>
                        <td><i>{m.ReceivePaymentNo}</i></td>
                        <td><i>{m.ReceiveAmount}</i></td>
                    </tr>)}</React.Fragment>}
                    <tr>
                        <th>Balance Amount:</th>
                        <td>{this.state.sale.hasOwnProperty('ReceivedPaymentAmount') ?  this.state.sale.BalanceAmount -  this.state.sale.ReceivedPaymentAmount:  this.state.sale.BalanceAmount}</td>
                    </tr>
                   { this.state.sale.BillChangeDue !== 0 && <tr>
                        <th>Change Due:</th>
                        <td>{this.state.sale.BillChangeDue}</td>
                    </tr>}
                    </table>
                </div>
                </React.Fragment>}
                </div>
        </div>
        <div className="row">
            <div className="col-md-4">
            {this.state.selectedPringConfigID !=="" &&  <div className="input-group">
                    <div className="input-group-btn">
                        <button type="button" 
                        disabled = {this.props.config.ConnectionStatus.Status === 'connected'}
                        onClick={()=> {
                            let emailID = ""
                            if(this.state.sale.hasOwnProperty("EmailID"))
                            {
                                if(this.state.sale.EmailID.trim()!=="")
                                {
                                    emailID=this.state.sale.EmailID.trim()
                                }
                                else if(this.state.sale.Customer)
                                {
                                   emailID=this.state.sale.Customer.EmailID.trim()
                                }
                            }
                            this.setState({emailID:emailID, 
                                showEmail:true,
                                emailError:''})
                          }
                        }
                        className="btn btn-flat btn-default" 
                        style={{marginRight: "5px"}}><i className="fa fa-envelope "></i> Email</button>
                        <button type="button" 
                        onClick={()=> {
                          
                            let printData = {Sale:this.state.salePrint,PrintConfig:this.props.config.PrintConfigs.filter((e)=> e.key === this.state.selectedPringConfigID)[0]}
                            this.setState({print:true, printChecked:true, sendEmailChecked:false, printData:printData})//TBDpawan
                        }
                        }
                        className="btn btn-flat btn-default" 
                        style={{marginRight: "5px"}}><i className="fa fa-print"></i> Print</button>
                    </div>
                    <select className="form-control input-md" 
                            value={this.state.selectedPringConfigID} 
                            onChange= {(evt) => this.setState({selectedPringConfigID:evt.target.value,
                                selectedPrintConfigPrintDisplayName: evt.target.options[evt.target.selectedIndex].text })}>
                                
                                {this.props.config.PrintConfigs.filter((e)=> !e.IsDeleted 
                                && e.TransactionTypeID === this.state.sale.TransactionType.key ).map((t, index)=> 
                                <option key={index} value={t.key}>{t.PrintDisplayName}</option>
                                )}
                        </select>
                </div>}
        

                {/* <button type="button" className="btn btn-flat btn-default" style={{marginRight: "5px"}}><i className="fa  fa-envelope"></i> Email</button>   
                
                <button type="button" className="btn btn-flat btn-default" style={{marginRight: "5px"}}><i className="fa fa-mobile-phone"></i> SMS</button>    */}
                </div>
                <div className="col-md-8">
                <Link to={{ pathname: "/home/sales/saleslist", state: { type: "list" } }}
                className="btn btn-flat btn-default pull-right"><i className="fa fa-arrow-left"></i> Back</Link>   
                
                {this.state.editAllowed  && <Link to={
                    {pathname:'/home/sales/editsales/', 
                    state:{type:actionTypes.SALE_EDIT, key:this.state.key, transactionType:this.state.sale.TransactionType, sale:this.state.sale}}}   
                    type="button" style={{marginRight: "5px"}} className="btn btn-info btn-flat pull-right">
                    <i className="fa fa-file-o"></i> 
                    Edit {this.state.sale.TransactionType.TransactionType}
                </Link>}

                {this.state.returnAllowed && <Link to={
                    {pathname:'/home/sales/salesreturn/',
                    state:{type:actionTypes.SALE_NEW, key: this.state.key, transactionType:this.state.salesReturnTransactionType, sale:this.state.sale}  } } 
                    type="button" className="btn btn-warning btn-flat pull-right" style={{marginRight: "5px"}}>
                    <i className="fa  fa-mail-forward"></i>
                    Sales Return
                </Link>}
                {this.state.cancelAllowed && <button type="button" className="btn btn-flat btn-danger pull-right" style={{marginRight: "5px"}} onClick={()=> { this.setState({showCancelSales:true}) } }>
                    <i className="fa fa-minus-circle"></i> Cancel {this.state.sale.TransactionType.TransactionType}
                </button>}
            </div>
        </div>
            </section>
        <Modal open={this.state.showCancelSales} onClose={() => this.setState({showCancelSales:false})} center>
                <CancelSales   
                {...this.props} 
                salesID={this.state.key} 
                onSave={() => {
                    this.load()
                    this.setState({showCancelSales:false})
                }}
                onClose={() => this.setState({showCancelSales:false})}
                /> 
            
        </Modal>
        <Modal open={this.state.showPoductConsumption} onClose={() => this.setState({showPoductConsumption:false})} center>
                <ProductConsumption   
                {...this.props} 
                lineItem={this.state.selectedLineItem} 
                onSave={(consumptionDetails) => {
                    //this.load()
                    //save 
                    let sale = this.state.sale
                    sale.LineItems[this.state.selectedLineItemIndex].ConsumptionDetails= consumptionDetails
                    this.props.updateSales(sale, this.state.key).then(()=> {
                        this.setState({sale:sale,showPoductConsumption:false})
                        this.props.getToastr("Invoice#" + sale.TransactionNo + " updated successfully")      
                    })
                }}
                onClose={() => this.setState({showPoductConsumption:false})}
                /> 
            
        </Modal>
        <Modal open={this.state.showEmail} onClose={() => this.setState({showEmail:false})} center>
            <div style={{ width: "300px" }}>
                <h3 style={{marginTop:"0px"}}>Email</h3>
                <div className="box-body">
                    <div className={this.state.emailError ? "form-group has-error" : "form-group "} >
                        <label>Email Id</label>
                        <input type="text" 
                        autoFocus
                        className="form-control"
                            onChange={(evt) => {
                                this.setState({ emailID: evt.target.value, emailError: '' })
                            }}
                            value={this.state.emailID} />
                        {this.state.emailError!=="" && <span class="help-block">{this.state.emailError}</span>}
                    </div>
                </div>
                <div className="box-footer">
                    <button type="submit" className="btn btn-md btn-flat btn-default" onClick={()=> this.setState({showEmail:false})} >Cancel</button>
                    <button type="submit" 
                    className="btn btn-flat btn-md btn-flat btn-success pull-right" 
                    // disabled={this.state.saving}
                    onClick={this.sendEmail}>Send</button>
                </div>
            </div>
        </Modal>
        <Modal open={this.state.showUniqueNoInput} 
            onClose={() => this.setState({ showUniqueNoInput: false })} center>
                <UniqueNoInput 
                {...this.props}
                transactionID={this.state.key}
                product={this.state.selectedProduct}
                lineItem={this.state.selectedLineItem}
                mode={"view"}
                onOK={()=>{
                    this.setState({ showUniqueNoInput: false })
                }}
                onClose={() => this.setState({ showUniqueNoInput: false })}/>
        </Modal>
        </div>}
        {this.state.print && <div>
                <Print  {...this.props}   
                 printChecked = {this.state.printChecked}
                 sendEmailChecked={this.state.sendEmailChecked}
                 sendSMSChecked = {false}
            printData = {this.state.printData}   
            onClose={()=> {this.setState({print:false})
            this.load()
                }
            }
            />
        
            </div>}
    </div>
   
    }
}

export default ViewSales