import Main from './Main'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {setAuthUser, getUserDetails, signOutUser, createUser, setStore, setCashRegister,getUserACL, setCashRegisterClosing,getAccessibleStores} from '../actions/userAction'
import {getPosts, savePost, deactivatePost,publishPost, getNextSequenceNo} from '../actions/PostAction'
import {checkBusinessNameIsUnique,createRegistration, getRegistration,setLogoPath,setFavIconPath,updateRegistrationSettings , updateRegistrationGeneralDetails} from '../actions/RegistrationAction'
import {uploadFileToServer } from '../actions/fileUploadAction'
import {pushProductToRedux, getActiveProducts, addNewProduct,updateProductImage, getProducts, deleteProduct, getProductDetails, updateProduct, getProductsForInventorySearch,getProductsByBarCode,pushProductsToRedux} from '../actions/ProductAction'
import {checkIfCustomerCodeIsUnique, searchCustomer, getCustomerWalletBalance, addCustomer, getCustomers, getCustomerDetails, updateCustomer, deleteCustomer, getCustomerDashboardHeaders,getWalletLedgersByCustomerID,getCustomerTransactions, checkIfCustomerPhoneNoIsUnique,checkIfCustomerStoreMappingExists,initialiseCustomers,getActiveCustomers,pushCustomersToRedux,pushCustomerToRedux} from '../actions/CustomerAction'
import {generateOTP, validateOTP,addSales, updateSales,cancelSales, parkSales,calculateLineItem,getSaleDetails, getTransactionRunningNo, getKOTRunningNo, updateTransactionRunningNo, getCashRegisterOpeningDetails, openCashRegister, getPaymenDetailstForCashRegisterClosing, closeCashRegister, getCashRegisterClosingList, getCashRegisterClosingDetails, getCustomerOutstanding, getOutstandingRRN, outForDelivery,markDelivered,readyForPickup,getDeliveryDetailReport,initialiseTransactionRunningNo,initialiseKOTRunningNo,initialiseSales,getOfflineSales} from '../actions/SalesAction'
import {addNewStore, getUserStores , getStores, deleteStore, getStoreDetails, updateStore, checkIfStoreCodeIsUnique} from '../actions/StoresAction'
import {addStoreLocation, getStoreLocations, deleteStoreLocations, getStoreLocationDetails, updateStoreLocation} from '../actions/StoreLocationsAction'
import {addCashRegister,getCashRegisters, deleteCashRegister, getCashRegisterDetails,updateCashRegister } from '../actions/CashRegisterAction'
import {addNewEmployee, getEmployees,getEmployeesForRedux, deleteEmployee, getEmployeeDetails, updateEmployee, checkIfEmployeeCodeIsUnique} from '../actions/EmployeesAction'
import {getCustomFieldNames, saveCustomFieldNames} from '../actions/CustomFieldsAction'
import {getAllCustomFieldNames} from '../actions/ConfigAction'
import {getPaymentTypes, savePaymentType, deletePaymentType} from '../actions/PaymentTypes'
import {  searchProductBrands, getProductBrands, deleteProductBrand, saveProductBrand, checkIfProductBrandIsUnique} from '../actions/ProductBrandsAction'
import {getProductTaxes, saveProductTaxes, deleteProductTax} from '../actions/ProductTaxesAction'
import {pushProductCategoriesToRedux,pushProductSubCategoriesToRedux,  saveProductCategory,deleteProductCategory, getProductCategories, getProductSubCategories, checkIfProductCategoryIsUnique, checkIfProductSubCategoryIsUnique, searchProductCategories, searchProductSubCategories} from '../actions/ProductCategoryAction'
import {getTransactionTypes, deleteTransactionType, saveTransactionType} from '../actions/TransctionTypeAction'
import {savePrintConfig, deletePrintConfig, getPrintConfigs,getPrintConfigByID} from '../actions/PrintConfigAction'
import {getMembership, saveMembership, deleteMembership, getMembershipDetails, getMemberships} from '../actions/MembershipAction'
import {saveExpenseCategories,deleteExpenseCategories, getExpenseCategories, getActiveExpenseCategories, checkIfExpenseCategoriesIsUnique} from '../actions/ExpenseCategoryAction'
import {getEmployeeDashboard, getEmployeeProductTypeWise, getBillWiseDetailReport,getInvoiceDetailReport, getEmployeeItemDetailReport,getProductTypeSoldReportData,getItemBrandSoldReportData,getItemCategorySoldReportData,getSubCategoryItemSoldReportData,getDashboardHeaders,getProductWiseSales,getCategoryWiseSales,getEmployeeWiseSales,getItemSoldReportData,getSalesTransaction,getPaymentTypeWiseSales,getSalesDashboard,getCustomerReferenceReport,getProductTypeWiseSales,getPaymentWiseTransaction,getWalletWiseTransaction,getExpensesforSalesDashboard, getExpenseList,getExpenseCategoryList,getExpenseByParty, getItemInStockData,getItemLedger, getDailySales,getPaymentModeWiseDaily,getPaymentModeWiseData, getEmployeeWorksheet,getPaymentModeTransactionTypeWiseSales,getAllInvetoryDetailReport,getCustomerWalletLedger,getCustomerOutStandingLedger, getOutStandingLedgerByCustomerID, getWalletLedgersByCustomer, getUniqueNos, getUniqueNoLedger} from '../actions/ReportAction'
import {saveExpenses, getExpenses,getExpenseDetails, searchParty, cancelExpense, getExpenseRunningNo, getCashFromCounterAmountForCashRegisterClosing} from '../actions/ExpenseAction'
import {searchVendor, addNewVendor, getVendors, deleteVendor, getVendorDetails, updateVendor, checkIfVendorCodeIsUnique} from '../actions/VendorAction'
import {convertNumberToWords, getPrintTemplate, sendSMS,sendEmail, getToastr, importCSV, getImportLogs, getImportDetails, getImportLogList,getVisibility,addDays,disableNetwork,enableNetwork,saveConnectionStatus} from '../actions/CommonAction'
import {getAuditTrails} from '../actions/AuditTrailAction'
import {withRouter} from 'react-router'
import {getCampaign, saveCampaign, deleteCampaign, getCampaignDetails} from '../actions/CampaignAction'
import {getAttendance,saveAttendance} from '../actions/AttendanceAction'
import {addInventory, updateInventory, cancelInventory, getInventoryRunningNo, getInventoryDetails,getInventoryList, getProductInStockQty,getInwardUniqueList, getUniqueNoIDBasedOnTransaction, checkIfUniqueNoIsUnique,getUniqueListForSalesReturn,getUniqueNoList} from '../actions/InventoryAction'
import {createMasters,createGlobalConfigurationRoles,createRoles,updateInvoices,updateProducts,updateExpenses} from '../actions/OnBoardingAction'
import{searchProductTags, saveProductTag,getProductTags,deleteProductTag,getProductTagDetails} from '../actions/ProductTagAction'
import {getOffers, saveOffer, deleteOffer, getOfferDetails,getApplicableOffers} from '../actions/OfferAction'
import {getModifiers, saveModifiers, deleteModifiers, deleteModifierGroup, saveModifierGroup, getModifierGroups} from '../actions/ModifierAction'
import {getPackages,getPackageDetails,updatePackage} from '../actions/PackageAction'


function mapStateToProps(state) {
    return {
       user:state.user,
       post:state.post,
       registration:state.registration,
       fileUpload:state.fileUpload,
       product: state.product,
       sales: state.sales,
       config: state.config
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        uploadFileToServer,
        convertNumberToWords, getPrintTemplate, sendSMS, sendEmail, getToastr,importCSV, getImportLogs, getImportDetails, getAuditTrails, getImportLogList,getVisibility,addDays,disableNetwork,enableNetwork,saveConnectionStatus,
        checkBusinessNameIsUnique ,createRegistration,getRegistration,setLogoPath,setFavIconPath,updateRegistrationSettings,updateRegistrationGeneralDetails,
        setAuthUser,getUserDetails, signOutUser, createUser,setStore,setCashRegister,getUserACL,setCashRegisterClosing,getAccessibleStores,
        getPosts,savePost,deactivatePost , publishPost, getNextSequenceNo,
        pushProductToRedux, getActiveProducts, addNewProduct,updateProductImage, getProducts,deleteProduct,getProductDetails,updateProduct,getProductsForInventorySearch,getProductsByBarCode,pushProductsToRedux,
        checkIfCustomerCodeIsUnique, searchCustomer, getCustomerWalletBalance, addCustomer, getCustomers, getCustomerDetails, updateCustomer, deleteCustomer, getCustomerDashboardHeaders,getWalletLedgersByCustomerID,getCustomerTransactions,checkIfCustomerPhoneNoIsUnique,checkIfCustomerStoreMappingExists,initialiseCustomers,getActiveCustomers,pushCustomersToRedux,pushCustomerToRedux,
        generateOTP, validateOTP,addSales,updateSales, cancelSales,parkSales, calculateLineItem, getSaleDetails, getTransactionRunningNo,getKOTRunningNo, updateTransactionRunningNo, getCashRegisterOpeningDetails, openCashRegister, getPaymenDetailstForCashRegisterClosing, closeCashRegister, getCashRegisterClosingList, getCashRegisterClosingDetails, getCustomerOutstanding, getOutstandingRRN, outForDelivery,markDelivered,readyForPickup,getDeliveryDetailReport,initialiseTransactionRunningNo,initialiseKOTRunningNo,initialiseSales,getOfflineSales
        ,addNewStore, getUserStores, getStores, deleteStore, getStoreDetails, updateStore, checkIfStoreCodeIsUnique
        ,addStoreLocation, getStoreLocations, deleteStoreLocations, getStoreLocationDetails, updateStoreLocation
        ,addCashRegister,getCashRegisters, deleteCashRegister, getCashRegisterDetails,updateCashRegister
        ,addNewEmployee, getEmployees, deleteEmployee, getEmployeeDetails, updateEmployee, checkIfEmployeeCodeIsUnique,getEmployeesForRedux
        ,getCustomFieldNames, saveCustomFieldNames
        ,getAllCustomFieldNames
        ,getPaymentTypes, savePaymentType, deletePaymentType
        , searchProductBrands, getProductBrands, deleteProductBrand, saveProductBrand, checkIfProductBrandIsUnique
        ,getProductTaxes, saveProductTaxes, deleteProductTax
        ,pushProductCategoriesToRedux, pushProductSubCategoriesToRedux,saveProductCategory,deleteProductCategory, getProductCategories, getProductSubCategories, checkIfProductCategoryIsUnique, checkIfProductSubCategoryIsUnique, searchProductCategories, searchProductSubCategories
        ,getTransactionTypes, deleteTransactionType, saveTransactionType
        ,savePrintConfig, deletePrintConfig, getPrintConfigs, getPrintConfigByID
        ,getMembership, saveMembership, deleteMembership, getMembershipDetails, getMemberships
        ,saveExpenseCategories,deleteExpenseCategories, getExpenseCategories, getActiveExpenseCategories, checkIfExpenseCategoriesIsUnique
        ,getEmployeeDashboard, getEmployeeProductTypeWise,getBillWiseDetailReport,getInvoiceDetailReport,getProductTypeSoldReportData,getItemBrandSoldReportData,getItemCategorySoldReportData,getSubCategoryItemSoldReportData,getEmployeeItemDetailReport,getDashboardHeaders,getProductWiseSales,getCategoryWiseSales,getEmployeeWiseSales,getItemSoldReportData,getSalesTransaction,getPaymentTypeWiseSales,getSalesDashboard,getCustomerReferenceReport,getProductTypeWiseSales,getPaymentWiseTransaction,getWalletWiseTransaction,getExpensesforSalesDashboard, getExpenseList,getExpenseCategoryList,getExpenseByParty, getItemInStockData,getItemLedger, getDailySales,getPaymentModeWiseDaily,getPaymentModeWiseData, getEmployeeWorksheet, getPaymentModeTransactionTypeWiseSales, getAllInvetoryDetailReport
        ,getCustomerWalletLedger,getCustomerOutStandingLedger, getOutStandingLedgerByCustomerID, getWalletLedgersByCustomer, getUniqueNos, getUniqueNoLedger
        ,getExpenses,getExpenseDetails, searchParty,cancelExpense, getExpenseRunningNo, saveExpenses, getCashFromCounterAmountForCashRegisterClosing
        ,searchVendor, addNewVendor, getVendors, deleteVendor, getVendorDetails, updateVendor, checkIfVendorCodeIsUnique
        ,getCampaign, saveCampaign, deleteCampaign, getCampaignDetails
        ,getAttendance,saveAttendance
        ,addInventory, updateInventory, cancelInventory, getInventoryRunningNo, getInventoryDetails,getInventoryList,getProductInStockQty,getInwardUniqueList,getUniqueNoIDBasedOnTransaction,checkIfUniqueNoIsUnique,getUniqueListForSalesReturn,getUniqueNoList
        ,createMasters,createGlobalConfigurationRoles,createRoles,updateInvoices,updateProducts,updateExpenses,
        searchProductTags, saveProductTag,getProductTags,deleteProductTag,getProductTagDetails
        ,getOffers, saveOffer, deleteOffer, getOfferDetails,getApplicableOffers
        ,getModifiers, saveModifiers, deleteModifiers,deleteModifierGroup, saveModifierGroup, getModifierGroups
        ,getPackages,getPackageDetails,updatePackage
    
    }, dispatch)
}

const App = withRouter(connect(mapStateToProps, mapDispatchToProps)(Main))

export default App
