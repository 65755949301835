import React, { Component } from 'react';
import {auth, googleProvider, facebookProvider} from '../firebase'
import {Link} from 'react-router-dom'

class Login extends Component {
    
    constructor(props)
    {
        super(props)
            this.state={
                loginid:'',
                password:'',
                showStoreSelection:false,
                stores:[]
            }
            this.login = this.login.bind(this)
            this.googleLogin = this.googleLogin.bind(this);
           this.onSignIn = this.onSignIn.bind(this)
           this.handleKeyPress = this.handleKeyPress.bind(this)
    }

    handleKeyPress(e){
        if (e.key === 'Enter') {
            this.onSignIn()
          }
      }
      
    googleLogin() {
        auth.signInWithPopup(googleProvider) 
          .then((result) => {
            this.login(result)
        })
      }
      onSignIn = ()=> {
        //console.log(this.state.loginid)
        //validate user input
        auth.signInWithEmailAndPassword(this.state.loginid, this.state.password)
        .then((result) => {
            this.login(result)
        })
        .catch(error => {
            alert(error)
        this.setState({
            loginid:'',
                password:''
            })
        })
    }

      login(result)
      {
        const user = result.user;
        this.props.setAuthUser(user)
        this.props.getUserDetails(this.props.user.auth.uid).then(()=>{ 
                if(this.props.user.user)
                {
                    //this.props.getProducts(this.props.user.user.RegistrationID)   
                    //get registration details

                    //console.log("RegistrationID", this.props.user.user.RegistrationID);
                    this.props.getRegistration(this.props.user.user.RegistrationID).then(()=>{
                        this.props.onHistory.push('/home')
                    })
                }
                else
                {
                    //user registration not found 
                    this.props.onHistory.push('/register')
                    //alert('redirect to registration page')
                }
            })
        //Get registration details
        //this.props.getUserDetails(this.props.user.uid)
        //If registration not found then redirect to new registration page

        //If registration found then edirect to home page
      }


    render() {
        return (
            <div className="login-page" style={{background: "#ecf0f5"}}>
                <div className="login-box">
                    
                    {!this.state.showStoreSelection && <div className="login-box-body">
                            <div className="login-logo">
                                <img style={{ width: "210px" }} src={require('../images/logo.png')}/>
                            </div>
                            <p className="login-box-msg">Sign in to start your session</p>

                            <div className="form-group has-feedback">
                                <input type="email" className="form-control" placeholder="Email"  
                                autoFocus={true}
                                value={this.state.loginid} 
                                onKeyPress={this.handleKeyPress}
                                onChange={evt => this.setState({loginid:evt.target.value})}/>
                                <span className="glyphicon glyphicon-envelope form-control-feedback"></span>
                            </div>
                            <div className="form-group has-feedback">
                                <input type="password" className="form-control" placeholder="Password"
                                value={this.state.password}
                                onKeyPress={this.handleKeyPress}
                                onChange={evt => this.setState({password:evt.target.value})}/>
                                <span className="glyphicon glyphicon-lock form-control-feedback"></span>
                            </div>
                            <div className="row">
                                <div className="col-xs-8">
                                
                                </div>
                                <div className="col-xs-4">
                                <button type="submit" className="btn btn-success btn-block btn-flat" onClick={this.onSignIn} >Sign In</button>
                                </div>
                            </div>
                            <Link to="/register" className="text-center">Register a new membership</Link>
                        </div>}
                        {this.state.showStoreSelection && <div>Store selection</div>}
                </div>
        </div>


        );
    }
}

export default Login