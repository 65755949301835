import React, { Component } from 'react'
import CustomerSearch from './CustomerSearch'
import Customer from '../CRM/Customer'
import Payment from './Payment'
import CustomerDashBoard from '../Reports/CustomerDashboard'

class ReceivePayment extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showAddCustomer: false,
            show: true,
            customerError: '',
            lineItemError: '',
            selectedCustomer: null,
            outStandingPayments: [],
            selectAll: false,
            totalOutStandingAmount: 0,
            totalReceiveAmount: 0,
            sale: null,
            notes: '',
            showCustomerDashBoard: false,
        }
        this.onCustomerChange = this.onCustomerChange.bind(this)
        this.calculateTotal = this.calculateTotal.bind(this)
        this.onSave = this.onSave.bind(this)
    }

    async onCustomerChange(customer) {
        let outStandingPayments = []
        if (customer) {
            outStandingPayments = await this.props.getCustomerOutstanding(customer.key)
            if (customer.hasOwnProperty('OpeningOutStandingAmountCurrent') && customer.OpeningOutStandingAmountCurrent > 0) {
                console.log('OpeningOutStandingAmountCurrent', customer.OpeningOutStandingAmountCurrent)
                outStandingPayments = [{
                    TransactionKey: "",
                    TransactionNo: "",
                    TransactionType: "Customer Opening Outstanding Amount",
                    TransactionDate: '',
                    TransactionAmount: 0,
                    OutStandingAmount: Number(customer.OpeningOutStandingAmountCurrent),
                    ReceiveAmount: 0,
                    Selected: false
                }, ...outStandingPayments]
            }
        }
        this.setState({ outStandingPayments: outStandingPayments, selectedCustomer: customer, totalOutStandingAmount: 0, totalReceiveAmount: 0 })
    }

    calculateTotal() {
        let totalOutStandingAmount = 0
        let totalReceiveAmount = 0
        this.state.outStandingPayments.map(m => {
            totalOutStandingAmount = Number(totalOutStandingAmount) + Number(m.OutStandingAmount)
            if (m.Selected) {
                totalReceiveAmount = totalReceiveAmount + Number(m.ReceiveAmount)
            }
        })
        this.setState({ totalOutStandingAmount: totalOutStandingAmount, totalReceiveAmount: totalReceiveAmount })
    }

    onSave() {
        let customerError = ""
        let lineItemError = ""
        if (!this.state.selectedCustomer) {
            customerError = "Please select customer"
        }
        else if (this.state.totalReceiveAmount <= 0) {
            lineItemError = "Please speicfy amount to be received"
        }
        if (customerError.trim() !== "" || lineItemError.trim() !== "") {
            this.setState({ customerError: customerError, lineItemError: lineItemError })
            return
        }
        let lineItems = this.state.outStandingPayments.filter(f => f.Selected)
        let transactionNo = ""
        this.props.location.state.transactionType.NumberingFormat.map((parameters) => {
            let para = ""
            if (parameters.Option === "text") {
                para = parameters.Value
            }
            else if (parameters.Option == "storecode") {
                para = this.props.user.store.StoreCode
            }
            else if (parameters.Option == "locationcode") {
                para = this.props.user.cashRegister.LocationCode
            }
            else if (parameters.Option == "cashregistercode") {
                para = this.props.user.cashRegister.CashRegisterCode
            }
            else if (parameters.Option == "calendardmonthyear") {
                let d = new Date();
                let a = d.getFullYear() + ""
                para = d.getMonth() + a.substring(2, 4)
                if (para.length < 3) {
                    para = "0" + para
                }
            }
            else if (parameters.Option === "fiscalyear") {
                let d = new Date();
                para = d.getFullYear() + ""
            }
            else if (parameters.Option === "calendarmonth") {
                let d = new Date();
                let a = d.getMonth() + ""
                if (a.length < 2) {
                    para = "0" + a
                }
            }
            else if (parameters.Option === "calendaryear") {
                let d = new Date();
                para = d.getFullYear() + ""
            }
            transactionNo = transactionNo + para + parameters.Separator
        })

        let sale = {
            TransactionNo: transactionNo,
            TransactionType: this.props.location.state.transactionType,
            Customer: this.state.selectedCustomer,
            RegistrationID: this.props.user.user.RegistrationID,
            CashRegister: this.props.user.cashRegister,
            Store: this.props.user.store,
            LineItems: [],
            OutStandingPayments: lineItems,
            BillQty: 0,
            BillSubTotal: 0,
            BillDiscount: 0,
            BillTaxAmount: 0,
            BillAmount: this.state.totalReceiveAmount,
            RoundOff: 0,
            BillBalanceAmount: 0,
            BillPaidAmount: 0,
            BillChangeDue: 0,
            Payments: null,
            ParentTransactionNo: '',
            ParentTransactionKey: '',
            Employee: null,
            Notes: this.state.notes,
            TaxSummaryList: null,
            PlaceOfSupply: '',
            BillingAddress: null,
            DeliveryCustomer: null,
            DeliveryCustomerAddress: null,
            DeliveryDate: null,
            DeliveryTime: null,
            DeliveryNotes: '',
            BackEndUpdate: false
        }
        this.setState({ sale: sale, show: false, showPayment: true })
    }

    render() {
        // const btnColStyle = {width:"60px"}
        const styleEditor = {
            width: "100%",
            height: "150px",
            font_size: "14px",
            line_height: "18px",
            border: "1px solid #dddddd",
            padding: "10px"
        }

        const modalWidth = {
            width: '500px'
        }

        return <div>
            {this.state.show && <div className="content-wrapper">
                <section className="content-header">
                    <h1>Receive Payment</h1>
                    <button type="submit" className="btn btn-md btn-success btn-lg btn-flat pull-right"
                        style={{ marginTop: "-36px", marginLeft: "10px" }}
                        onClick={this.onSave} disabled={this.state.saving}>Save
                </button>
                    <button className="btn btn-md btn-flat btn-default btn-lg pull-right" style={{ marginTop: "-36px" }}
                        onClick={() => this.setState({
                            customerError: '',
                            lineItemError: '',
                            selectedCustomer: null,
                            outStandingPayments: [],
                            selectAll: false,
                            totalOutStandingAmount: 0,
                            totalReceiveAmount: 0,
                            sale: null,
                            notes: ''
                        })} >Cancel
                </button>
                </section>
                <section className="content" >
                    <div className="box ">
                        <div className="box-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <CustomerSearch
                                        {...this.props}
                                        customer={this.state.selectedCustomer}
                                        error={this.state.customerError}
                                        onCustomerChange={
                                            this.onCustomerChange}
                                        onAddCustomer={() => { this.setState({ showAddCustomer: true, show: false }) }}
                                        onCustomerEdit={() => { this.setState({ showAddCustomer: true, show: false }) }}
                                        onShowCustomerDashBoard={() => {
                                            this.setState({ showCustomerDashBoard: true, show: false })
                                        }}
                                    ></CustomerSearch>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12" style={{ marginTop: "10px" }}>
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <input type="checkbox"
                                                        checked={this.state.selectAll}
                                                        onChange={() => {
                                                            let outStandingPayments = this.state.outStandingPayments
                                                            outStandingPayments.map((b) => {
                                                                b.Selected = !this.state.selectAll
                                                                    b.ReceiveAmount = !this.state.selectAll ? b.OutStandingAmount : 0
                                                            })
                                                            this.setState({ selectAll: !this.state.selectAll, outStandingPayments: outStandingPayments })
                                                            this.calculateTotal()
                                                        }}
                                                    />
                                                </th>
                                                <th style={{ width: "10px" }}>#</th>
                                                {/* <th >Transaction Date</th> */}
                                                <th >Transaction No</th>
                                                <th >Transaction Type</th>
                                                <th >Transaction Amount</th>
                                                <th >Balance Amount</th>
                                                <th >Receive Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.outStandingPayments.map((lineItem, index) => <tr>
                                                <td>
                                                    <input type="checkbox"
                                                        checked={lineItem.Selected}
                                                        onChange={() => {
                                                            let outStandingPayments = this.state.outStandingPayments
                                                            outStandingPayments[index].Selected = !outStandingPayments[index].Selected
                                                            outStandingPayments[index].ReceiveAmount = outStandingPayments[index].Selected ? outStandingPayments[index].OutStandingAmount : 0
                                                            this.setState({ outStandingPayments: outStandingPayments })
                                                            this.calculateTotal()
                                                        }}
                                                    />
                                                </td>
                                                <td>{index + 1}</td>
                                                {/* <td>{lineItem.TransactionDate}</td> */}
                                                <td>{lineItem.TransactionNo}</td>
                                                <td>{lineItem.TransactionType}</td>
                                                <td>{lineItem.TransactionAmount}</td>
                                                <td>{lineItem.OutStandingAmount}</td>
                                                <td ><input type="number"
                                                    disabled={!lineItem.Selected}
                                                    value={lineItem.ReceiveAmount}
                                                    onChange={evt => {
                                                        let receiveAmount = Number(evt.target.value)
                                                        if (receiveAmount <= lineItem.OutStandingAmount && receiveAmount > 0) {
                                                            let outStandingPayments = this.state.outStandingPayments
                                                            outStandingPayments[index].ReceiveAmount = receiveAmount
                                                            this.setState({ outStandingPayments: outStandingPayments })
                                                            this.calculateTotal()
                                                        }
                                                    }}
                                                ></input></td>
                                                {/* <td>{lineItem.BalanceAmount}</td> */}
                                            </tr>)}
                                            <tr>
                                                <td></td>
                                                {/* <td></td> */}
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td><b>{this.state.totalOutStandingAmount}</b></td>
                                                <td><b>{this.state.totalReceiveAmount}</b></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Notes</label>
                                        <textarea className="textarea" style={styleEditor}
                                            onChange={(evt) => this.setState({ notes: evt.target.value })}
                                            value={this.state.notes}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>}
            {this.state.showAddCustomer && <Customer {...this.props}
                calledFromInvoice={true}
                selectedCustomer={this.state.selectedCustomer}
                onSave={(customer) => {
                    this.onCustomerChange(customer)
                    this.setState({
                        showAddCustomer: false,
                        show: true
                    })
                }}
                onClose={() => this.setState({ showAddCustomer: false, show: true })} />}
            {this.state.showCustomerDashBoard && <CustomerDashBoard {...this.props}
                calledFromInvoice={true}
                cutomerID={this.state.fromDelivery ? this.state.tmpDeliveryCustomer.key : this.state.selectedCustomer.key}
                onClose={() => this.setState({ showCustomerDashBoard: false, show: true})} />}
            {this.state.showPayment && <Payment  {...this.props}
                transactionID=""
                onCancel={() => { this.setState({ showPayment: false, show: true }) }}
                onSave={() => {
                    this.setState({
                        showAddCustomer: false,
                        showPayment: false,
                        show: true,
                        customerError: '',
                        lineItemError: '',
                        selectedCustomer: null,
                        outStandingPayments: [],
                        selectAll: false,
                        totalOutStandingAmount: 0,
                        totalReceiveAmount: 0,
                        sale: null,
                        notes: ''
                    })
                }}
                sale={this.state.sale}
            />

            }
        </div>

    }
}

export default ReceivePayment