import React, { Component } from 'react';
import Modal from 'react-responsive-modal'
import AddEditStoreLocation from './AddEditStoreLocation'
import AddEditCashRegister from './AddEditCashRegister'
import * as actions from '../../actionTypes'
import Confirm from 'react-confirm-bootstrap'
import {Link } from 'react-router-dom'
import AuditTrail from '../AuditTrail'
import * as constants from '../../Constatnts'

class ViewStoreDetails extends Component{

    constructor(){
        super()
        this.state={
            storeID:'',
            storeDetails:null,
            isShowAddEditLocation:false,
            addEditStoreLocationAction:'',
            selectedStoreLocation:null,
            storeLocations:[],
            cashRegisters:[],
            isShowAddEditCashRegister:false,
            selectedCashRegister:null,
            addEditCashRegisterAction:'',
            customField1DisplayName:'Custom Field 1',
            customField2DisplayName:'Custom Field 2',
            customField3DisplayName:'Custom Field 3',
            customField4DisplayName:'Custom Field 4',
            customField5DisplayName:'Custom Field 5',
            customField6DisplayName:'Custom Field 6',
                       
        }
        this.onStoreLocationSave = this.onStoreLocationSave.bind(this)
        this.onStoreLocationDelete = this.onStoreLocationDelete.bind(this)
        this.loadStoreLocations = this.loadStoreLocations.bind(this)
        this.loadCashRegisters= this.loadCashRegisters.bind(this)
        this.ShowAddEditCashRegister= this.ShowAddEditCashRegister.bind(this)
        this.onCashRegisterSave = this.onCashRegisterSave.bind(this)
        this.onCashRegisterDelete = this.onCashRegisterDelete.bind(this)
    }
    
    componentDidMount(){

                
        this.props.config.CustomFields.map((customField) => {
            if (customField.ModuleName === 'Store')
            {
                let customField1DisplayName = customField.CustomField1Name.trim() === '' ? 'Custom Field 1' : customField.CustomField1Name.trim()
                let customField2DisplayName = customField.CustomField2Name.trim() === '' ? 'Custom Field 2' : customField.CustomField2Name.trim()
                let customField3DisplayName = customField.CustomField3Name.trim() === '' ? 'Custom Field 3' : customField.CustomField3Name.trim()
                let customField4DisplayName = customField.CustomField4Name.trim() === '' ? 'Custom Field 4' : customField.CustomField4Name.trim()
                let customField5DisplayName = customField.CustomField5Name.trim() === '' ? 'Custom Field 5' : customField.CustomField5Name.trim()
                let customField6DisplayName = customField.CustomField6Name.trim() === '' ? 'Custom Field 6' : customField.CustomField6Name.trim()
                this.setState({
                    customField1DisplayName:customField1DisplayName,
                    customField2DisplayName:customField2DisplayName,
                    customField3DisplayName:customField3DisplayName,
                    customField4DisplayName:customField4DisplayName,
                    customField5DisplayName:customField5DisplayName,
                    customField6DisplayName:customField6DisplayName
                })
            }
        })
        this.props.getStoreDetails(this.props.location.state.key).then((store)=> {
            this.setState({storeDetails: store, storeID:this.props.location.state.key})
            console.log('key',this.props.location.state.key)
            this.loadStoreLocations()
            this.loadCashRegisters()
        })
    }

    showAddEditStoreLocationsModal(action, storeLocation)
    {
        this.setState({isShowAddEditLocation:true, addEditStoreLocationAction: action, selectedStoreLocation:storeLocation})
    }

    onStoreLocationSave()
    {
        //get store location
        this.setState({isShowAddEditLocation:false})
        this.loadStoreLocations()
       
    }

    loadStoreLocations()
    {
        this.props.getStoreLocations(this.state.storeID).then((storeLocations)=>{
            this.setState({storeLocations:storeLocations,  loading: false})
        })
    }

    onStoreLocationDelete(key){
        this.props.deleteStoreLocations(key).then(()=> {
            this.loadStoreLocations()
        })
    }

    loadCashRegisters()
    {
        this.props.getCashRegisters(this.state.storeID).then((cashRegisters)=>{
            this.setState({cashRegisters:cashRegisters,  loading: false})
        })
    }

    ShowAddEditCashRegister(cashRegister, action)
    {
        this.setState({isShowAddEditCashRegister: true, selectedCashRegister: cashRegister, addEditCashRegisterAction:action})
    }

    onCashRegisterSave(){
        this.setState({isShowAddEditCashRegister:false})
        this.loadCashRegisters()
    }

    onCashRegisterDelete(key){
        this.props.deleteCashRegister(key).then(()=> {
            this.loadCashRegisters()
        })
    }

    render(){
        const btnColStyle = {width:"120px"}

        return <div className="content-wrapper">
        <section className="content-header">
            <h1>View Store Details</h1>
        </section>  
      {this.state.storeDetails &&  <section className="content" >
                    <div className="box ">
                        <div className="box-header with-border">
                            <h3 className="box-title">Store details</h3>
                            <button type="submit" className="btn btn-primary pull-right btn-flat" 
                            onClick={()=> this.props.history.push({pathname: "/home/settings/stores/editstore/", state:{action:actions.STORE_EDIT, storeID:this.state.storeID, store:this.state.storeDetails} })}
                         
                            >Edit Store Details
                            </button>
                            <Link to={{pathname:"/home/settings/stores/printconfig",state:{storeID:this.state.storeID, storeCode: this.state.storeDetails.StoreCode, storeName: this.state.storeDetails.StoreName}}} 
                            className="btn btn-default pull-right btn-flat" 
                            style={{marginRight:"5px"}}>Print Config</Link>
                            <button type="submit" className="btn btn-default pull-right btn-flat" style={{marginRight:"5px"}} onClick={()=> this.showAddEditStoreLocationsModal(actions.STORELOCATION_NEW, null)}>Add Location</button>
                            <button type="submit" className="btn btn-default pull-right btn-flat"  style={{marginRight:"5px"}} onClick={()=> this.ShowAddEditCashRegister(null,actions.CASH_REGISTER_NEW)}>Add Cash Register</button>
                        </div>
                        <div className="box-body">     
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group" >
                                        <label >Store Code</label>
                                        <br/>
                                        <span>{this.state.storeDetails.StoreCode}</span>      
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Store Name</label>
                                        <br/>
                                        <span>{this.state.storeDetails.StoreName}</span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group" >
                                        <label >Phone Number</label>
                                        <br/>
                                        <span>{this.state.storeDetails.PhoneNo}</span>
                                    </div>          
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group" >
                                        <label>Email</label>
                                        <br/>
                                        <span>{this.state.storeDetails.EmailID}</span>
                                    </div>
                                </div>
                            </div>  
                        </div>
                        <div className="box-header with-border">
                            <h3 className="box-title">Address</h3>
                        </div>
                        <div className="box-body">     
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group" >
                                        <label >Line Address 1</label>
                                        <br/>
                                        <span>{this.state.storeDetails.Address1}</span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Line Address 2</label>
                                        <br/>
                                        <span>{this.state.storeDetails.Address2}</span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>City</label>
                                        <br/>
                                        <span>{this.state.storeDetails.City}</span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group" >
                                            <label >State</label>
                                            <br/>
                                            <span>{this.state.storeDetails.State}</span>
                                    </div>
                                </div>
                                
                            </div>  
                            <div className="row">
                            <div className="col-md-3">
                                    <div className="form-group" >
                                        <label >Postal Code</label>
                                        <br/>
                                        <span>{this.state.storeDetails.PostalCode}</span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group" >
                                            <label >Country</label>
                                            <br/>
                                            <span>{this.state.storeDetails.Country}</span>
                                    </div>
                                </div>
                            </div>  
                        </div>
                        <div className="box-header with-border">
                            <h3 className="box-title">Settings</h3>
                        </div>
                        <div className="box-body">     
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group" >
                                        <label >Currency</label>
                                        <br/>
                                        <span>{this.state.storeDetails.Currency}</span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Fiscal Year - Start Month</label>
                                        <br/>
                                        <span>{this.state.storeDetails.FiscalYearMonth}</span>
                                    </div>
                                </div>
                          
                                <div className="col-md-3">
                                    <div className="form-group" >
                                            <label >Timezone</label>
                                            <br/>
                                            <span>{this.state.storeDetails.TimeZone}</span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group" >
                                        <label >Language</label>
                                        <br/>
                                        <span>{this.state.storeDetails.Language}</span>
                                    </div>
                                </div>
                            </div> 
                        </div>
                        <div className="box-header with-border">
                            <h3 className="box-title">Additonal Information</h3>
                        </div>
                        <div className="box-body">     
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group" >
                                        <label >{this.state.customField1DisplayName}</label>
                                        <br/>
                                        <span>{this.state.storeDetails.CustomField1Name}</span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>{this.state.customField2DisplayName}</label>
                                        <br/>
                                        <span>{this.state.storeDetails.CustomField2Name}</span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group" >
                                            <label >{this.state.customField3DisplayName}</label>
                                            <br/>
                                            <span>{this.state.storeDetails.CustomField3Name}</span>
                                    </div>
                                    
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group" >
                                        <label >{this.state.customField4DisplayName}</label>
                                        <br/>
                                        <span>{this.state.storeDetails.CustomField4Name}</span>
                                    </div>
                                </div>
                            </div> 
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="form-group" >
                                            <label >{this.state.customField5DisplayName}</label>
                                            <br/>
                                            <span>{this.state.storeDetails.CustomField5Name}</span>
                                    </div>
                                    
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group" >
                                        <label >{this.state.customField6DisplayName}</label>
                                        <br/>
                                        <span>{this.state.storeDetails.CustomField6Name}</span>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div className="box ">
                        <div className="box-header with-border">
                            <h3 className="box-title">Location</h3>
                            <button type="submit" className="btn btn-primary pull-right btn-flat" onClick={()=> this.showAddEditStoreLocationsModal(actions.STORELOCATION_NEW, null)}>Add Location</button>
                        </div>
                        <div className="box-body">     
                            {this.state.storeLocations.length > 0 && 
                            
                            <table className = "table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Location Code</th>
                                        <th>Location Name</th>
                                        <th>Revision History</th>
                                        <th style={btnColStyle}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.storeLocations.map((storeLocation) =>
                                    <tr>
                                        <td>{storeLocation.LocationCode}</td>
                                        <td>{storeLocation.LocationName}</td>
                                        <td>
                                        {storeLocation.hasOwnProperty('Action') && <React.Fragment>
                                            <div>Action :{storeLocation.Action}</div>
                                            <div>Action By :{storeLocation.ActionBy}</div>                                                        
                                            <div>Action Date :{(new Date(storeLocation.ActionOn.seconds*1000)).toString().substring(0,24)}</div>
                                            <AuditTrail {...this.props} module={constants.MODULE_PRODUCT_TAX} parentKey={storeLocation.key}/>                                                                                                
                                            </React.Fragment>}
                                        </td>
                                        <td>
                                            <div className="btn-group">
                                            { storeLocation.IsDeleted ? <span class="label label-danger pull-right">Inactive</span> : 
                                                <div>
                                                    <button onClick={()=> this.showAddEditStoreLocationsModal(actions.STORELOCATION_EDIT, storeLocation)}
                                                    type="button" class="btn btn-md btn-default "><i class="fa fa-edit"></i></button>                                            
                                                    <Confirm
                                                        onConfirm={() => this.onStoreLocationDelete(storeLocation.key)}
                                                        body="Are you sure you would like to delete this store location?"
                                                        confirmText="Yes"
                                                        title="Store Location Delete">
                                                        <button className="btn btn-default"><i class="fa fa-trash-o"></i></button>
                                                    </Confirm>
                                                </div>    
                                            }
                                            </div>
                                        </td>
                                     </tr>
                                    )}                                            
                                </tbody>
                                </table>}
                                {/* {this.state.storeLocations.length === 0 && <div>No locations defined</div> } */}
                        </div>
                    </div>
                    <div className="box ">
                        <div className="box-header with-border">
                            <h3 className="box-title">Cash Register</h3>
                            <button type="submit" className="btn btn-primary pull-right btn-flat"  onClick={()=> this.ShowAddEditCashRegister(null,actions.CASH_REGISTER_NEW)}>Add Cash Register</button>
                        </div>
                        <div className="box-body">     
                        {this.state.cashRegisters.length > 0 && <table className = "table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Cash Register Code</th>
                                        <th>Cash Register Name</th>
                                        <th>Revision History</th>
                                        <th style={btnColStyle}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.cashRegisters.map((cashRegister) =>
                                    <tr>
                                        <td>{cashRegister.CashRegisterCode}</td>
                                        <td>{cashRegister.CashRegisterName}</td>
                                        <td>
                                        {cashRegister.hasOwnProperty('Action') && <React.Fragment>
                                            <div>Action :{cashRegister.Action}</div>
                                            <div>Action By :{cashRegister.ActionBy}</div>                                                        
                                            <div>Action Date :{(new Date(cashRegister.ActionOn.seconds*1000)).toString().substring(0,24)}</div>
                                            <AuditTrail {...this.props} module={constants.MODULE_PRODUCT_TAX} parentKey={cashRegister.key}/>                                                                                                
                                            </React.Fragment>}
                                        </td>
                                        <td>
                                            <div className="btn-group">
                                            { cashRegister.IsDeleted ? <span class="label label-danger pull-right">Inactive</span> : 
                                                <div>
                                                    <button onClick={()=> this.ShowAddEditCashRegister( cashRegister, actions.CASH_REGISTER_EDIT)}
                                                    type="button" class="btn btn-md btn-default "><i class="fa fa-edit"></i></button>                                            
                                                    <Confirm
                                                        onConfirm={() => this.onCashRegisterDelete(cashRegister.key)}
                                                        body="Are you sure you would like to delete this cash register?"
                                                        confirmText="Yes"
                                                        title="Cash Register Delete">
                                                        <button className="btn btn-default"><i class="fa fa-trash-o"></i></button>
                                                    </Confirm>
                                                </div>    
                                            }
                                            </div>
                                        </td>
                                     </tr>
                                    )}                                            
                                </tbody>
                                </table>}
                        </div>
                    </div>
                </section>}
                <Modal open={this.state.isShowAddEditLocation} onClose={() => this.setState({isShowAddEditLocation:false})} center>
                        <div style={{width:"400px"}}>
                            <AddEditStoreLocation   {...this.props} 
                            storeID={this.state.storeID}  
                            action={this.state.addEditStoreLocationAction} 
                            storeLocation = {this.state.selectedStoreLocation}
                            onSave={()=> this.onStoreLocationSave()}
                            onClose={() => this.setState({isShowAddEditLocation:false})}/>
                        </div>  
                </Modal>    
                <Modal open={this.state.isShowAddEditCashRegister} onClose={() => this.setState({isShowAddEditCashRegister:false})} center>
                        <div style={{width:"400px"}}>
                            <AddEditCashRegister   {...this.props} 
                            storeID={this.state.storeID}  
                            action={this.state.addEditCashRegisterAction} 
                            cashRegister = {this.state.selectedCashRegister}
                            storeLocations = {this.state.storeLocations}
                            onSave={()=> this.onCashRegisterSave()}
                            onClose={() => this.setState({isShowAddEditCashRegister:false})}/>
                        </div>  
                </Modal>  
            </div>
    }
}

export default ViewStoreDetails