import React, { Component } from 'react';
import Confirm from 'react-confirm-bootstrap'
import { withRouter } from 'react-router-dom'
import Modal from 'react-responsive-modal'
import Loader from '../Loader'
import AuditTrail from '../AuditTrail'
import * as constants from '../../Constatnts'

class PaymentTypes extends Component{
    constructor () {
        super()
        this.state={
            showModal:false,
            tempPaymentType:'',
            tempSequenceNo:'',
            tempPaymentTypeID:'',
            tempIsSystemDefined:false,
            tempPaymentTypeError :'',
            tempSalesExpenseError:'',
            tempSequenceNoError:'',
            searchActiveInactiveAllRecords: 'active',
            payments:[],
            saving:false
        }
        this.onPaymentTypeDelete = this.onPaymentTypeDelete.bind(this)
        this.loadPaymentTypes= this.loadPaymentTypes.bind(this)
        this.onSave = this.onSave.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
    }
    
    componentDidMount() {
        this.loadPaymentTypes(this.state.searchActiveInactiveAllRecords)
        }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSave()
        }
    }

    loadPaymentTypes(option) {
        this.props.getPaymentTypes(this.props.user.user.RegistrationID).then(() => {
            let allPayments = this.props.config.PaymentTypes
            let payments = []
            if (option === "all") {
                payments = allPayments

            }
            else if (option === "active") {
                payments = allPayments.filter((e) => !e.IsDeleted)
            }
            else if (option === "inactive") {
                payments = allPayments.filter((e) => e.IsDeleted)
            }

            this.setState({ loading: false, payments: payments, searchActiveInactiveAllRecords: option })
        })
    }

        
    onPaymentTypeDelete(key){
        this.props.deletePaymentType(key).then(()=> {
            this.props.getToastr("Payment Type deleted successfully")
            this.loadPaymentTypes(this.state.searchActiveInactiveAllRecords)
       })
    }
    
    
    onSave()
    {
        this.setState({saving:true})
        let tempPaymentTypeError = "" 
        let tempSequenceNoError= ""
        let tempSalesExpenseError = ""
        if(this.state.tempPaymentType.trim() ==="")
        {
            tempPaymentTypeError= 'Please provide payment type' 
        }
        if(this.state.tempSequenceNo.toString().trim() ==="")
        {
            tempSequenceNoError = 'Please provide sequence no'
        }
        if(!this.state.tempSales && !this.state.tempExpense)
        {
            tempSalesExpenseError="Please select at least sales or expense"
        }
        if(this.props.config.PaymentTypes.filter((e)=> !e.IsDeleted &&  e.key !==  (this.state.action ==='edit' ? this.state.tempPaymentTypeID : e.key) && e.PaymentType.trim().toLowerCase()=== this.state.tempPaymentType.trim().toLowerCase() ).length >0)
        {
            tempPaymentTypeError= 'Payment type already exists' 
        }

        if(tempPaymentTypeError !=="" || tempSequenceNoError !=="" || tempSalesExpenseError!=="")
        {
            this.setState({saving:false,
            tempPaymentTypeError:tempPaymentTypeError,
            tempSequenceNoError:tempSequenceNoError, tempSalesExpenseError: tempSalesExpenseError})
            return
        }

            let showInSales = this.state.tempSales ? this.state.tempSales  : false
            let showInExpense = this.state.tempExpense ? this.state.tempExpense  : false
        let paymentType = {
            RegistrationID: this.props.user.user.RegistrationID,
            PaymentType: this.state.tempPaymentType,
            SequenceNo: this.state.tempSequenceNo,
            IsSystemDefined: this.state.tempIsSystemDefined,
            ShowInSales:showInSales,
            ShowInExpense: showInExpense,
            IsDeleted: false 
        }
        if(this.state.tempPaymentType.toLowerCase()==="wallet")
        {
            paymentType.OTPVerification = this.state.tempOTPVerification
        }
        this.props.savePaymentType(paymentType, this.state.tempPaymentTypeID).then(()=>{
            this.loadPaymentTypes(this.state.searchActiveInactiveAllRecords)
            this.props.getToastr("Payment type saved successfully")               
            this.setState({showModal:false, tempPaymentTypeID:'',
                tempPaymentType:'', 
                tempIsSystemDefined:false, 
                tempSales:false, 
                tempExpense:false,
                tempOTPVerification:false,
                tempSequenceNo:0,
                saving:false})
        })
    }    

    render() {    
        const btnColStyle = {width:"120px"}
        
        return <div className="content-wrapper" >
        <section className="content" >
            <div className="row">
                <div className="col-md-12">
                    <div className="box ">
                        <div className="box-header with-border">
                            <div className="row">
                                <div className="col-md-6">
                                    <font style={{fontSize:"30px"}}>Payment Types</font>
                                </div>
                                <div className="col-md-6">
                                    <div className="pull-right" style={{marginTop:"6px"}}>
                                        <button  
                                        onClick={()=> {
                                            this.setState({showModal:false, 
                                            tempPaymentTypeError :'',
                                            tempSalesExpenseError:'',
                                            tempSequenceNoError:'',
                                            tempPaymentTypeID:'',
                                            tempSequenceNo:0,
                                            tempPaymentType:'', 
                                            tempIsSystemDefined:false, 
                                            tempSales:false, 
                                            tempExpense:false,
                                            tempOTPVerification:false,
                                            action:'new', showModal:true})
                                 
                                        }}
                                        className="btn btn-success btn-md btn-flat" 
                                        >Add New Payement Type</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box-body">
                        <div className="row">
                                    <div className="col-md-12" style={{ marginBottom: "10px" }}>
                                        <div className="btn-group">
                                            <button type="button" className={this.state.searchActiveInactiveAllRecords === "active" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.loadPaymentTypes("active")}>Active</button>
                                            <button type="button" className={this.state.searchActiveInactiveAllRecords === "inactive" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.loadPaymentTypes("inactive")}>In Active</button>
                                            <button type="button" className={this.state.searchActiveInactiveAllRecords === "all" ? "btn btn-primary btn-flat btn-md " : "btn btn-default btn-flat btn-md"}
                                                onClick={() => this.loadPaymentTypes("all")}>All</button>
                                        </div>
                                    </div>
                                </div>
                                {this.state.loading && <Loader show={this.state.loading} />}
                        {!this.state.loading && <React.Fragment>
                        {this.state.payments &&                      
                        <table className = "table table-bordered table-striped">
                                <thead>
                                    <tr>
                                        <th>Payment Type</th>
                                        <th>Sequence</th>
                                        <th>Revision History</th>
                                        <th></th>
                                        <th style={btnColStyle}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.payments.map((paymentType) =>
                                    <tr>
                                        <td>{paymentType.PaymentType}</td>
                                        <td>{paymentType.SequenceNo}</td>
                                        <td>
                                            <div>Show in sales: {paymentType.ShowInSales ? "Yes" : "No"}</div>
                                            <div>Show in expense: {paymentType.ShowInExpense ? "Yes" : "No"}</div>
                                            {paymentType.hasOwnProperty('OTPVerification') && <div> OTP Verification: {paymentType.OTPVerification ? "Yes":"No"}</div>}
                                        </td>
                                        <td>
                                        {paymentType.hasOwnProperty('Action') && <React.Fragment>
                                            <div>Action :{paymentType.Action}</div>
                                            <div>Action By :{paymentType.ActionBy}</div>                                                        
                                            <div>Action Date :{(new Date(paymentType.ActionOn.seconds*1000)).toString().substring(0,24)}</div>
                                            <AuditTrail {...this.props} module={constants.MODULE_PRODUCT_TAX} parentKey={paymentType.key}/>                                                                                                
                                            </React.Fragment>}
                                        </td>
                                        <td>
                                            <div class="btn-group">
                                            { paymentType.IsDeleted ? <span class="label label-danger pull-right">Inactive</span> : 
                                                <div>
                                                    <button onClick={()=> {this.setState({action:'edit', 
                                                    showModal:true,
                                                    tempPaymentTypeID:paymentType.key , 
                                                    tempPaymentType: paymentType.PaymentType,
                                                    tempSequenceNo: paymentType.SequenceNo,
                                                    tempIsSystemDefined: paymentType.IsSystemDefined,
                                                    tempSales: paymentType.ShowInSales,
                                                    tempExpense:paymentType.ShowInExpenses,
                                                    tempOTPVerification: paymentType.hasOwnProperty('OTPVerification') ? paymentType.OTPVerification: false
                                                    });
                                                    //console.log('paymentType',paymentType)
                                                }}
                                                    type="button" class="btn btn-md btn-flat btn-default "><i class="fa fa-edit"></i></button>                                            
                                                    <Confirm
                                                        onConfirm={() => this.onPaymentTypeDelete(paymentType.key)}
                                                        body="Are you sure you would like to delete this payment type?"
                                                        confirmText="Yes"
                                                        title="Payment Type Delete">
                                                        <button className="btn btn-flat btn-default"><i class="fa fa-trash-o"></i></button>
                                                    </Confirm>
                                                </div>    
                                            }
                                            </div>
                                        </td>
                                     </tr>
                                    )}                                            
                                </tbody>
                            </table>}
                            </React.Fragment>}
                        </div>   
                    </div>
                </div>
                </div>
            </section>
                <Modal open={this.state.showModal} onClose={() => this.setState({showModal:false})} center>
                        <div style={{width:"500px"}}>
                        
                                        {this.state.action === 'new' && <h3 style={{ marginTop: "0px" }}>Add New Payment Type</h3>}
                                        {this.state.action === 'edit'&& <h3 style={{ marginTop: "0px" }}>Edit Payment Type</h3>}
                                <div className="box-body">
                                        <div className={ this.state.tempPaymentTypeError !=="" ? "form-group has-error": "form-group "} >
                                            <label>Payment Type</label>
                                            <input type = "text" className="form-control"  
                                             onKeyPress={this.handleKeyPress} 
                                            onChange={(evt) => this.setState({tempPaymentType:evt.target.value, tempPaymentTypeError:""}) }
                                            value={this.state.tempPaymentType}
                                            disabled = {this.state.tempIsSystemDefined}
                                            autoFocus
                                            />
                                            {this.state.tempPaymentTypeError !=="" &&  <span class="help-block">{this.state.tempPaymentTypeError}</span> }
                                        </div>                            
                                        <div className={ this.state.tempSequenceNoError !=="" ? "form-group has-error": "form-group "} >
                                            <label>Sequence No</label>
                                            <input type = "number" className="form-control" 
                                             onKeyPress={this.handleKeyPress} 
                                             onChange={(evt) => this.setState({tempSequenceNo:Number(evt.target.value), tempSequenceNoError:""}) }
                                             value={this.state.tempSequenceNo}
                                            />
                                            {this.state.tempSequenceNoError !=="" &&  <span class="help-block">{this.state.tempSequenceNoError}</span> }
                                        </div>
                                        <div className={ this.state.tempSalesExpenseError !=="" ? "form-group has-error": "form-group "} >
                                                <input type="checkbox" 
                                                checked={this.state.tempSales}
                                                onChange={()=>this.setState({tempSales: !this.state.tempSales, tempSalesExpenseError:""}) }/>
                                                <label >Show in sales</label>
                                            {this.state.tempSalesExpenseError !=="" &&  <span class="help-block">{this.state.tempSalesExpenseError}</span> }
                                        </div>
                                        <div className={ this.state.tempSalesExpenseError !=="" ? "form-group has-error": "form-group "} >
                                                <input type="checkbox" 
                                                
                                                checked={this.state.tempExpense}
                                                onChange={()=>this.setState({tempExpense: !this.state.tempExpense, tempSalesExpenseError:""}) }/>
                                                <label >Show in expense</label>
                                                {this.state.tempSalesExpenseError !=="" &&  <span class="help-block">{this.state.tempSalesExpenseError}</span> }
                                        </div>
                                        {this.state.tempPaymentType.toLowerCase()==="wallet" && <div className="form-group ">
                                                <input type="checkbox" 
                                                checked={this.state.tempOTPVerification}
                                                onChange={()=>this.setState({tempOTPVerification: !this.state.tempOTPVerification}) }/>
                                                <label >OTP verification required</label>
                                        </div>}
                                </div>
                                <div className="box-footer">
                                    <button type="submit" className="btn btn-flat btn-md btn-default" onClick={() => this.setState({showModal:false})} >Cancel</button>
                                    <button type="submit" className="btn btn-flat btn-md btn-success pull-right" onClick={()=> this.onSave()}>OK</button>
                                </div>
                        </div>  
                </Modal>      
        </div>   
    }
}

export default withRouter(PaymentTypes)