import firebase, {database} from '../firebase'
import * as actionTypes from '../actionTypes'
import * as constants from '../Constatnts'

export function checkBusinessNameIsUnique(businessUniqueName){
    return (dispatch) => {
        // console.log('checkBusinessNameIsUnique', businessUniqueName)
        let businessNameIsUnique = true
        return database.collection("Registration").where("UniqueName","==", businessUniqueName).get().then((querySnapshot) => {
             querySnapshot.forEach((doc) => {
                businessNameIsUnique = false
           })
        //    console.log('dispatch',businessNameIsUnique)
           dispatch({type:actionTypes.CHECK_BUSINESS_NAME_IS_UNIQUE, businessNameIsUnique })
        }).catch((error) => {
            console.log(error)
        })
    }
}

export function createRegistration(reg) {
    return dispatch => {
        // console.log(JSON.stringify(reg));
        let addMessage = firebase.functions().httpsCallable('signupProcess');
        return addMessage({ text: reg }).then(function (result) {
            dispatch({ type: actionTypes.GET_REGISTRATION, result })
        });
    }
}


export function getRegistration(registrationID){
    return (dispatch) => {
        let registration = {}
        return database.collection("Registration").doc(registrationID).get().then((doc) => {
               registration = {key:doc.id,
               ...doc.data()}     

            //console.log("Registration - Obj", registration);
           dispatch({type:actionTypes.GET_REGISTRATION, registration })
        }).catch((error) => {
            console.log(error)
        })
    }
}

export function setLogoPath(registrationID, logoPath)
{
    return (dispatch) => {
        return database.collection("Registration").doc(registrationID).update({LogoPath:logoPath})
    }
}


export function setFavIconPath(registrationID, favIconPath)
{
    return (dispatch) => {
        return database.collection("Registration").doc(registrationID).update({FavIconPath:favIconPath})
    }
}

export function updateRegistrationSettings(registrationID, settings)
{
    return (dispatch, getState) => {
        settings.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        settings.ActionBy = getState().user.user.Name
        settings.ActionByUID = getState().user.user.UID
        settings.ActionByEmailID = getState().user.user.EmailID

        settings.Action = constants.AUDIT_TRAIL_EDIT
        return database.collection("Registration").doc(registrationID).update(settings)
    }
}

export function updateRegistrationGeneralDetails(registrationID, generalDetails)
{
    // console.log(registrationID)
    // console.log(generalDetails)
    return (dispatch, getState) => {
        generalDetails.ActionOn = firebase.firestore.FieldValue.serverTimestamp()
        generalDetails.ActionBy = getState().user.user.Name
        generalDetails.ActionByUID = getState().user.user.UID
        generalDetails.ActionByEmailID = getState().user.user.EmailID

        generalDetails.Action = constants.AUDIT_TRAIL_EDIT
        return database.collection("Registration").doc(registrationID).update(generalDetails)
    }
    
}