import firebase, { database, functions } from '../firebase'

export function getBillWiseDetailReport(registrationID, searchStores, from, size, searchTransactionNo, searchTransactionType,
  searchTransactionFromDate, searchTransactionToDate,
  searchCustomerDetail, searchCashRegister) {
  let searchParameters = {
    "from": from,
    "size": size,
    "query": {
      "bool": {
        "must": [
          {
            "term": {
              "RegistrationID": registrationID
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": searchTransactionToDate,
                "gte": searchTransactionFromDate,
                "format": "yyyy-MM-dd"
              }
            }
          }
        ],
        "should": [], "minimum_should_match": 1,
        "filter": {
          "bool": {
            "must": [

            ],
            "should": []
          }
        }
      }
    },
    "sort": [
      {
        "TransactionDate": {
          "order": "desc"
        }
      }
    ]
  }
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      "term": {
        "Store.key.keyword": store.key
      }
    })
  }
  if (searchCustomerDetail.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({ "regexp": { "Customer.FirstName": "@" + searchCustomerDetail.trim().toLowerCase() + ".*" } })
    searchParameters.query.bool.filter.bool.should.push({ "regexp": { "Customer.LastName": "@" + searchCustomerDetail.trim().toLowerCase() + ".*" } })
    searchParameters.query.bool.filter.bool.should.push({ "regexp": { "Customer.EmailID": "@" + searchCustomerDetail.trim().toLowerCase() + ".*" } })
    searchParameters.query.bool.filter.bool.should.push({ "regexp": { "Customer.PhoneNo": "@" + searchCustomerDetail.trim().toLowerCase() + ".*" } })
    searchParameters.query.bool.filter.bool.should.push({ "regexp": { "Customer.Code": "@" + searchCustomerDetail.trim().toLowerCase() + ".*" } })
  }
  if (searchTransactionNo.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "TransactionNo": searchTransactionNo } })
  }
  if (searchTransactionType.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "TransactionType.key": searchTransactionType.trim().toLowerCase() } })
  }
  if (searchCashRegister.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CashRegister.key": "@" + searchCashRegister.trim().toLowerCase() + ".*" } })
  }
  console.log("Parameters", JSON.stringify(searchParameters))
  return (dispatch, getState) => {
    let addMessage = firebase.functions().httpsCallable('getSales');
    return addMessage({ text: searchParameters }).then(function (result) {
      const invoices = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        invoices.push({ key: data._id, ...data._source })
      })
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: invoices }
    });
  }
};

export function getInvoiceDetailReport(registrationID, searchStores, from, size, searchTransactionNo, searchTransactionType,
  searchTransactionFromDate, searchTransactionToDate,
  searchCustomerDetail, searchProductDetail
) {
  let searchParameters = {
    "from": from,
    "size": size,
    "query": {
      "bool": {
        "must": [
          {
            "term": {
              "RegistrationID": registrationID
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": searchTransactionToDate,
                "gte": searchTransactionFromDate,
                "format": "yyyy-MM-dd"
              }
            }
          }
        ],
        "should": [], "minimum_should_match": 1,
        "filter": {
          "bool": {
            "should": [

            ]
          }
        }
      }
    },
    "sort": [
      {
        "TransactionDate": {
          "order": "desc"
        }
      }
    ]
  }
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      "term": {
        "StoreID.keyword": store.key
      }
    })
  }
  if (searchCustomerDetail.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({ "regexp": { "CustomerDetails": "@" + searchCustomerDetail.trim().toLowerCase() + ".*" } })
  }
  if (searchProductDetail.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({ "regexp": { "ProductDetails": "@" + searchProductDetail.trim().toLowerCase() + ".*" } })

  }
  if (searchTransactionNo.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "TransactionNo": searchTransactionNo } })
  }
  if (searchTransactionType.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({ "regexp": { "TransactionTypeID": searchTransactionType.trim().toLowerCase() } })
  }
  return (dispatch, getState) => {
    let addMessage = firebase.functions().httpsCallable('getDenomSales');

    return addMessage({ text: searchParameters }).then(function (result) {
      const invoices = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        invoices.push({ ...data._source, key: data._id })
      })
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: invoices }
    });
  }
}

export function getEmployeeItemDetailReport(registrationID, from, size, searchTransactionNo, searchTransactionType,
  searchTransactionFromDate, searchTransactionToDate,
  searchCustomerDetail, searchProductDetail, searchEmployeeName
) {
  let searchParameters = {
    "from": from,
    "size": size,
    "query": {
      "bool": {
        "must": [
          {
            "exists": {
              "field": "EmployeeName"
            }
          },
          {
            "term": {
              "RegistrationID": registrationID
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": searchTransactionToDate,
                "gte": searchTransactionFromDate,
                "format": "yyyy-MM-dd"
              }
            }
          }
        ],
        "must_not": [
          {
            "exists": {
              "field": "CancellationReason"
            }
          }
        ],
        "filter": {
          "bool": {
            "should": [
            ]
          }
        }
      }
    }
  }
  if (searchCustomerDetail.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({ "regexp": { "CustomerDetails": "@" + searchCustomerDetail.trim().toLowerCase() + ".*" } })
  }

  if (searchProductDetail.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({ "regexp": { "ProductDetails": "@" + searchProductDetail.trim().toLowerCase() + ".*" } })

  }
  if (searchTransactionNo.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "TransactionNo": searchTransactionNo } })
  }
  if (searchTransactionType.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({ "match": { "TransactionTypeID": searchTransactionType } })
  }

  if (searchEmployeeName.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({ "regexp": { "EmployeeName": "@" + searchEmployeeName.trim().toLowerCase() + ".*" } })
  }
  if (searchTransactionNo.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "TransactionNo": searchTransactionNo } })
  }

  return (dispatch, getState) => {
    searchParameters.query.bool.must.push({
      "match": {
        "StoreID": getState().user.store.key
      }
    })

    let addMessage = firebase.functions().httpsCallable('getDenomSales');

    return addMessage({ text: searchParameters }).then(function (result) {
      const invoices = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        invoices.push({ ...data._source, key: data._id })
      })
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: invoices }
    });
  }
}

export function getItemSoldReportData(registrationID, searchStoreName, searchTransactionToDate, searchTransactionFromDate, searchCategory, searchSubCategory,
  searchProductSKU, searchProductName, searchBrand) {

  let searchParameters =
  {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          {
            "term": {
              "RegistrationID": registrationID
            }
          },
          {
            "term": {
              "SalesImpacted": true
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": searchTransactionToDate,
                "gte": searchTransactionFromDate,
                "format": "yyyy-MM-dd"
              }
            }
          }
        ],
        "must_not": [
          { "exists": { "field": "CancellationReason" } }
        ], "should": [], "minimum_should_match": 1,
        "filter": {
          "bool": {
            "must": []
          }
        }
      }
    },
    "aggs": {
      "my_buckets": {
        "composite": {
          "size": 10000,
          "sources": [
            {
              "store": {
                "terms": {
                  "field": "StoreName.keyword"
                }
              }
            },
            {
              "brand": {
                "terms": {
                  "field": "Brand.keyword",
                  "missing_bucket": true
                }
              }
            },
            {
              "category": {
                "terms": {
                  "field": "Category.keyword"
                }
              }
            },
            {
              "sub_category": {
                "terms": {
                  "field": "SubCategory.keyword",
                  "missing_bucket": true
                }
              }
            },
            {
              "product_type": {
                "terms": {
                  "field": "ProductType.keyword"
                }
              }
            },
            {
              "sku": {
                "terms": {
                  "field": "SKU.keyword"
                }
              }
            },
            {
              "product": {
                "terms": {
                  "field": "ProductName.keyword"
                }
              }
            }
            //,
            // {
            //   "price": {
            //     "terms": {
            //       "field": "RetailPrice"
            //     }
            //   }
            // }
          ]
        },
        "aggs": {
          "total_qty": {
            "sum": {
              "field": "Qty"
            }
          },
          "total_discount": {
            "sum": {
              "field": "DiscountAmount"
            }
          },
          "total_taxable": {
            "sum": {
              "field": "TaxableAmount"
            }
          },
          "total_taxes": {
            "sum": {
              "field": "TotalTax"
            }
          },
          "total_sales": {
            "sum": {
              "field": "Amount"
            }
          }
        }
      }
    }
  }
  for (let i = 0; i < searchStoreName.length; i++) {
    const store = searchStoreName[i];

    searchParameters.query.bool.should.push({
      "term": {
        "StoreID.keyword": store.key
      }
    })
  }

  if (searchCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "Category": searchCategory.trim().toLowerCase() } })
  }
  if (searchSubCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "SubCategory": searchSubCategory.trim().toLowerCase() } })
  }
  if (searchProductSKU.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "SKU": searchProductSKU.trim().toLowerCase() } })
  }
  if (searchProductName.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "ProductName": searchProductName } })
  }
  if (searchBrand.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "Brand": searchBrand.trim().toLowerCase() } })
  }
  return (dispatch, getState) => {
    // searchParameters.query.bool.must.push({
    //   "match": {
    //     "StoreID": getState().user.store.key
    //   }
    // })
    //console.log("Parameters", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable('getDenomSales');
    return addMessage({ text: searchParameters }).then(function (result) {

      const itemSold = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.my_buckets.buckets.map((data, index) => {
        let item = {
          store: data.key.store,
          brand: data.key.brand,
          category: data.key.category,
          sub_category: data.key.sub_category,
          product_type: data.key.product_type,
          sku: data.key.sku,
          product: data.key.product,
          //price: data.key.price,
          total_quantity: data.total_qty.value,
          total_sales: data.total_sales.value,
          total_discount: data.total_discount.value,
          total_taxes: data.total_taxes.value,
          total_taxable: data.total_taxable.value
        }
        itemSold.push(item);
      })

      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: itemSold }
    });
  }
};

export function getProductTypeSoldReportData(registrationID, searchStoreName, searchTransactionToDate, searchTransactionFromDate, searchProductType) {
  let searchParameters =
  {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          {
            "term": {
              "RegistrationID": registrationID
            }
          },
          {
            "term": {
              "SalesImpacted": true
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": searchTransactionToDate,
                "gte": searchTransactionFromDate,
                "format": "yyyy-MM-dd"
              }
            }
          }
        ],
        "must_not": [
          { "exists": { "field": "CancellationReason" } }
        ],
        "filter": {
          "bool": {
            "must": []
          }
        }
      }
    },
    "aggs": {
      "my_buckets": {
        "composite": {
          "size": 10000,
          "sources": [
            {
              "store": {
                "terms": {
                  "field": "StoreName.keyword"
                }
              }
            },
            {
              "product_type": {
                "terms": {
                  "field": "ProductType.keyword"
                }
              }
            }
          ]
        },
        "aggs": {
          "total_qty": {
            "sum": {
              "field": "Qty"
            }
          },
          "total_discount": {
            "sum": {
              "field": "DiscountAmount"
            }
          },
          "total_taxable": {
            "sum": {
              "field": "TaxableAmount"
            }
          },
          "total_taxes": {
            "sum": {
              "field": "TotalTax"
            }
          },
          "total_sales": {
            "sum": {
              "field": "Amount"
            }
          }
        }
      }
    }
  }

  if (searchStoreName.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "StoreName": "@" + searchStoreName.trim().toLowerCase() + ".*" } })
  }

  if (searchProductType.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "ProductType": "@" + searchProductType.trim().toLowerCase() + ".*" } })
  }
  return (dispatch, getState) => {
    searchParameters.query.bool.must.push({
      "match": {
        "StoreID": getState().user.store.key
      }
    })
    let addMessage = firebase.functions().httpsCallable('getDenomSales');
    return addMessage({ text: searchParameters }).then(function (result) {

      const productTypes = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.my_buckets.buckets.map((data, index) => {
        let productType = {
          store: data.key.store,
          product_type: data.key.product_type,
          total_quantity: data.total_qty.value,
          total_sales: data.total_sales.value,
          total_discount: data.total_discount.value,
          total_taxes: data.total_taxes.value,
          total_taxable: data.total_taxable.value
        }

        productTypes.push(productType);
      })

      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: productTypes }
    });
  }
};

export function getItemBrandSoldReportData(registrationID, searchStoreName, searchTransactionToDate, searchTransactionFromDate,
  searchBrand) {
  let searchParameters =
  {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          {
            "term": {
              "RegistrationID": registrationID
            }
          },
          {
            "term": {
              "SalesImpacted": true
            }
          },
          {
            "exists": {
              "field": "Brand"
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": searchTransactionToDate,
                "gte": searchTransactionFromDate,
                "format": "yyyy-MM-dd"
              }
            }
          }
        ], "must_not": [
          { "exists": { "field": "CancellationReason" } }
        ],
        "filter": {
          "bool": {
            "must": []
          }
        }
      }
    },
    "aggs": {
      "my_buckets": {
        "composite": {
          "size": 10000,
          "sources": [
            {
              "store": {
                "terms": {
                  "field": "StoreName.keyword"
                }
              }
            },
            {
              "brand": {
                "terms": {
                  "field": "Brand.keyword"
                }
              }
            }
          ]
        },
        "aggs": {
          "total_qty": {
            "sum": {
              "field": "Qty"
            }
          },
          "total_discount": {
            "sum": {
              "field": "DiscountAmount"
            }
          },
          "total_taxable": {
            "sum": {
              "field": "TaxableAmount"
            }
          },
          "total_taxes": {
            "sum": {
              "field": "TotalTax"
            }
          },
          "total_sales": {
            "sum": {
              "field": "Amount"
            }
          }
        }
      }
    }
  }

  if (searchStoreName.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "StoreName": "@" + searchStoreName.trim().toLowerCase() + ".*" } })
  }
  if (searchBrand.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Brand": "@" + searchBrand.trim().toLowerCase() + ".*" } })
  }
  return (dispatch, getState) => {
    searchParameters.query.bool.must.push({
      "match": {
        "StoreID": getState().user.store.key
      }
    })
    let addMessage = firebase.functions().httpsCallable('getDenomSales');
    return addMessage({ text: searchParameters }).then(function (result) {

      const brandSold = []
      let sanitizedMessage = result.data.text;

      sanitizedMessage.aggregations.my_buckets.buckets.map((data, index) => {
        let brand = {
          store: data.key.store,
          brand: data.key.brand,
          total_quantity: data.total_qty.value,
          total_sales: data.total_sales.value,
          total_discount: data.total_discount.value,
          total_taxes: data.total_taxes.value,
          total_taxable: data.total_taxable.value
        }
        brandSold.push(brand);
      })
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: brandSold }
    });
  }
};

export function getItemCategorySoldReportData(registrationID, searchStoreName, searchTransactionToDate, searchTransactionFromDate, searchCategory) {
  let searchParameters =
  {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          {
            "term": {
              "RegistrationID": registrationID
            }
          },
          {
            "term": {
              "SalesImpacted": true
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": searchTransactionToDate,
                "gte": searchTransactionFromDate,
                "format": "yyyy-MM-dd"
              }
            }
          }
        ], "must_not": [
          { "exists": { "field": "CancellationReason" } }
        ],
        "filter": {
          "bool": {
            "must": []
          }
        }
      }
    },
    "aggs": {
      "my_buckets": {
        "composite": {
          "size": 10000,
          "sources": [
            {
              "store": {
                "terms": {
                  "field": "StoreName.keyword"
                }
              }
            },
            {
              "category": {
                "terms": {
                  "field": "Category.keyword"
                }
              }
            },
          ]
        },
        "aggs": {
          "total_qty": {
            "sum": {
              "field": "Qty"
            }
          },
          "total_discount": {
            "sum": {
              "field": "DiscountAmount"
            }
          },
          "total_taxable": {
            "sum": {
              "field": "TaxableAmount"
            }
          },
          "total_taxes": {
            "sum": {
              "field": "TotalTax"
            }
          },
          "total_sales": {
            "sum": {
              "field": "Amount"
            }
          }
        }
      }
    }
  }

  if (searchStoreName.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "StoreName": "@" + searchStoreName.trim().toLowerCase() + ".*" } })
  }

  if (searchCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Category": "@" + searchCategory.trim().toLowerCase() + ".*" } })
  }
  return (dispatch, getState) => {
    searchParameters.query.bool.must.push({
      "match": {
        "StoreID": getState().user.store.key
      }
    })
    let addMessage = firebase.functions().httpsCallable('getDenomSales');
    return addMessage({ text: searchParameters }).then(function (result) {

      const productCategories = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.my_buckets.buckets.map((data, index) => {
        let productCategory = {
          store: data.key.store,
          category: data.key.category,
          total_quantity: data.total_qty.value,
          total_sales: data.total_sales.value,
          total_discount: data.total_discount.value,
          total_taxes: data.total_taxes.value,
          total_taxable: data.total_taxable.value
        }
        productCategories.push(productCategory);
      })

      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: productCategories }
    });
  }
};

export function getSubCategoryItemSoldReportData(registrationID, searchStoreName, searchTransactionToDate, searchTransactionFromDate, searchCategory, searchSubCategory) {
  let searchParameters =
  {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          {
            "term": {
              "RegistrationID": registrationID
            }
          },
          {
            "term": {
              "SalesImpacted": true
            }
          },
          {
            "exists": {
              "field": "SubCategory"
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": searchTransactionToDate,
                "gte": searchTransactionFromDate,
                "format": "yyyy-MM-dd"
              }
            }
          }
        ],
        "filter": {
          "bool": {
            "must": []
          }
        }
      }
    },
    "aggs": {
      "my_buckets": {
        "composite": {
          "size": 10000,
          "sources": [
            {
              "store": {
                "terms": {
                  "field": "StoreName.keyword"
                }
              }
            },
            {
              "category": {
                "terms": {
                  "field": "Category.keyword"
                }
              }
            },
            {
              "sub_category": {
                "terms": {
                  "field": "SubCategory.keyword"
                }
              }
            }
          ]
        },
        "aggs": {
          "total_qty": {
            "sum": {
              "field": "Qty"
            }
          },
          "total_discount": {
            "sum": {
              "field": "DiscountAmount"
            }
          },
          "total_taxable": {
            "sum": {
              "field": "TaxableAmount"
            }
          },
          "total_taxes": {
            "sum": {
              "field": "TotalTax"
            }
          },
          "total_sales": {
            "sum": {
              "field": "Amount"
            }
          }
        }
      }
    }
  }

  if (searchStoreName.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "StoreName": "@" + searchStoreName.trim().toLowerCase() + ".*" } })
  }
  if (searchCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Category": "@" + searchCategory.trim().toLowerCase() + ".*" } })
  }
  if (searchSubCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "SubCategory": "@" + searchSubCategory.trim().toLowerCase() + ".*" } })
  }
  return (dispatch, getState) => {
    searchParameters.query.bool.must.push({
      "match": {
        "StoreID": getState().user.store.key
      }
    })
    let addMessage = firebase.functions().httpsCallable('getDenomSales');
    return addMessage({ text: searchParameters }).then(function (result) {

      const productSubCategories = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.my_buckets.buckets.map((data, index) => {
        let productSubCategory = {
          store: data.key.store,
          category: data.key.category,
          sub_category: data.key.sub_category,
          total_quantity: data.total_qty.value,
          total_sales: data.total_sales.value,
          total_discount: data.total_discount.value,
          total_taxes: data.total_taxes.value,
          total_taxable: data.total_taxable.value
        }
        productSubCategories.push(productSubCategory);
      })

      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: productSubCategories }
    });
  }
};

export function getDashboardHeaders(registrationID, searchStores, searchCashRegister, searchTransactionType, searchTransactionFromDate,
  searchTransactionToDate) {
  let searchParameters =
  {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          {
            "term": {
              "RegistrationID": registrationID
            }
          },
          {
            "term": {
              "TransactionType.SalesImpactChecked": true
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": searchTransactionToDate,
                "gte": searchTransactionFromDate,
                "format": "yyyy-MM-dd"
              }
            }
          }
        ],
        "must_not": [
          { "exists": { "field": "CancellationReason" } }
        ], "should": [], "minimum_should_match": 1,
      }
    },
    "aggs": {
      "total_sales": {
        "sum": {
          "field": "BillPaidAmount"
        }
      },
      "total_qty": {
        "sum": {
          "field": "BillQty"
        }
      },
      "total_transactions": {
        "cardinality": {
          "field": "_id"
        }
      },
      "total_customers": {
        "cardinality": {
          "field": "Customer.FirstName.keyword"
        }
      }
    }
  }
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      "term": {
        "Store.key.keyword": store.key
      }
    })
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable('getSales');
    return addMessage({ text: searchParameters }).then(function (result) {
      let sanitizedMessage = result.data.text;
      const dashBoardHeader = {
        total_transactions: sanitizedMessage.aggregations.total_transactions.value,
        total_qty: sanitizedMessage.aggregations.total_qty.value,
        total_customers: sanitizedMessage.aggregations.total_customers.value,
        total_sales: sanitizedMessage.aggregations.total_sales.value
      }
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: dashBoardHeader }
    });
  }
};

export function getCategoryWiseSales(registrationID, searchStores, searchCashRegister, searchTransactionType, searchTransactionFromDate,
  searchTransactionToDate) {
  let searchParameters =
  {
    "query": {
      "bool": {
        "must": [
          {
            "term": {
              "RegistrationID": registrationID
            }
          },
          {
            "term": {
              "TransactionType.SalesImpactChecked": true
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": searchTransactionToDate,
                "gte": searchTransactionFromDate,
                "format": "yyyy-MM-dd"
              }
            }
          }
        ],
        "must_not": [
          { "exists": { "field": "CancellationReason" } }
        ], "should": [], "minimum_should_match": 1,
      }
    },
    "size": 0,
    "aggs": {
      "line_items": {
        "nested": {
          "path": "LineItems"
        },
        "aggs": {
          "group_by_category": {
            "terms": {
              "field": "LineItems.Product.Category.keyword"
            },
            "aggs": {
              "total_sales": {
                "sum": {
                  "field": "LineItems.Amount"
                }
              }
            }
          }
        }
      }
    }
  }
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      "term": {
        "Store.key.keyword": store.key
      }
    })
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable('getSales');
    return addMessage({ text: searchParameters }).then(function (result) {
      const categories = [];
      let sanitizedMessage = result.data.text;

      sanitizedMessage.aggregations.line_items.group_by_category.buckets.map((data, index) => {
        let category = {
          name: data.key,
          total_sales: data.total_sales.value,
        }
        categories.push(category);
      });
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: categories }
    });
  }
};

export function getProductWiseSales(registrationID, searchStores, searchCashRegister, searchTransactionType, searchTransactionFromDate,
  searchTransactionToDate) {
  let searchParameters =
  {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          {
            "term": {
              "RegistrationID": registrationID
            }
          },
          {
            "term": {
              "TransactionType.SalesImpactChecked": true
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": searchTransactionToDate,
                "gte": searchTransactionFromDate,
                "format": "yyyy-MM-dd"
              }
            }
          }
        ],
        "must_not": [
          { "exists": { "field": "CancellationReason" } }
        ],
        "should": [], "minimum_should_match": 1,
      }
    },
    "aggs": {
      "line_items": {
        "nested": {
          "path": "LineItems"
        },
        "aggs": {
          "group_by_product": {
            "terms": {
              "field": "LineItems.Product.Name.keyword"
            },
            "aggs": {
              "total_sales": {
                "sum": {
                  "field": "LineItems.Amount"
                }
              }
            }
          }
        }
      }
    }
  }
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      "term": {
        "Store.key.keyword": store.key
      }
    })
  }
  if (searchTransactionType.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "TransactionType.key": searchTransactionType.trim().toLowerCase() } })
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable('getSales');
    return addMessage({ text: searchParameters }).then(function (result) {
      const products = [];
      let sanitizedMessage = result.data.text;

      sanitizedMessage.aggregations.line_items.group_by_product.buckets.map((data, index) => {
        let product = {
          name: data.key,
          total_sales: data.total_sales.value,
        }
        products.push(product);
      });
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: products }
    });
  }
};

export function getEmployeeWiseSales(registrationID, searchStores, searchCashRegister, searchTransactionType, searchTransactionFromDate,
  searchTransactionToDate) {
  let searchParameters =
  {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          {
            "term": {
              "RegistrationID": registrationID
            }
          },
          {
            "term": {
              "TransactionType.SalesImpactChecked": true
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": searchTransactionToDate,
                "gte": searchTransactionFromDate,
                "format": "yyyy-MM-dd"
              }
            }
          }
        ],
        "must_not": [
          { "exists": { "field": "CancellationReason" } }
        ],
        "should": [], "minimum_should_match": 1,
      }
    },
    "aggs": {
      "line_items": {
        "nested": {
          "path": "LineItems"
        },
        "aggs": {
          "group_by_employee": {
            "terms": {
              "field": "LineItems.Employee.EmployeeName.keyword",
              "size": 10000
            },
            "aggs": {
              "total_sales": {
                "sum": {
                  "field": "LineItems.Amount"
                }
              },
              "total_taxes": {
                "sum": {
                  "field": "LineItems.TotalTax"
                }
              }
            }
          }
        }
      }
    }
  }
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      "term": {
        "Store.key.keyword": store.key
      }
    })
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable('getSales');
    return addMessage({ text: searchParameters }).then(function (result) {
      const employees = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.line_items.group_by_employee.buckets.map((data, index) => {
        let employee = {
          name: data.key,
          total_sales: data.total_sales.value - data.total_taxes.value,
        }
        employees.push(employee);
      });
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: employees }
    });
  }
};

export function getPaymentTypeWiseSales(registrationID, searchStore, searchCashRegister, searchTransactionType, searchTransactionFromDate,
  searchTransactionToDate) {
  let searchParameters =
  {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          {
            "term": {
              "RegistrationID": registrationID
            }
          },
          {
            "match": {
              "StoreID": searchStore
            }
          },
          {
            "term": {
              "SalesImpacted": true
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": searchTransactionToDate,
                "gte": searchTransactionFromDate,
                "format": "yyyy-MM-dd"
              }
            }
          }
        ],
        "must_not": [
          { "exists": { "field": "CancellationReason" } }
        ]
      }
    },
    "aggs": {
      "group_by_payment_type": {
        "terms": {
          "field": "PaymentMode.keyword"
        },
        "aggs": {
          "total_amount": {
            "sum": {
              "field": "PaymentAmount"
            }
          }
        }
      }
    }
  };
  if (searchCashRegister.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CashRegisterName.keyword": "@" + searchCashRegister.trim().toLowerCase() + ".*" } })
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable('getDenomPayments');
    return addMessage({ text: searchParameters }).then(function (result) {
      const paymentTypes = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.group_by_payment_type.buckets.map((data, index) => {
        let paymentType = {
          name: data.key,
          total_amount: data.total_amount.value
        };
        paymentTypes.push(paymentType);
      });
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: paymentTypes };
    });
  };
};


export function getSalesTransaction(registrationID, searchStore, searchCashRegister, searchTransactionType, searchTransactionFromDate,
  searchTransactionToDate) {
  let searchParameters =
  {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          {
            "term": {
              "RegistrationID": registrationID
            }
          },
          {
            "term": {
              "StoreID.keyword": searchStore
            }
          },
          {
            "term": {
              "SalesImpacted": true
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": searchTransactionFromDate,
                "gte": searchTransactionFromDate,
                "format": "yyyy-MM-dd"
              }
            }
          }
        ],
        "must_not": [
          {
            "exists": {
              "field": "CancellationReason"
            }
          }
        ]
      }
    },
    "aggs": {
      "total_sales": {
        "sum": {
          "field": "Amount"
        }
      },
      "total_discount": {
        "sum": {
          "field": "DiscountAmount"
        }
      },
      "total_transactions": {
        "cardinality": {
          "field": "TransactionID"
        }
      },
      "total_qty": {
        "sum": {
          "field": "Qty"
        }
      }
    }
  };
  return (dispatch, getState) => {
    searchParameters.query.bool.must.push({
      "match": {
        "StoreID": getState().user.store.key
      }
    })
    let addMessage = firebase.functions().httpsCallable('getDenomSales');
    return addMessage({ text: searchParameters }).then(function (result) {
      let sanitizedMessage = result.data.text;
      const salesTransaction = {
        total_transactions: sanitizedMessage.aggregations.total_transactions.value,
        total_amount: sanitizedMessage.aggregations.total_sales.value,
        total_qty: sanitizedMessage.aggregations.total_qty.value,
        total_discount_amount: sanitizedMessage.aggregations.total_discount.value
      };
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: salesTransaction }
    });
  }
};

export function getPaymentWiseTransaction(registrationID, searchStore, searchCashRegister, searchTransactionType, searchTransactionFromDate,
  searchTransactionToDate) {
  let searchParameters =
  {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          {
            "term": {
              "RegistrationID": registrationID
            }
          },
          {
            "term": {
              "StoreID.keyword": searchStore
            }
          },
          {
            "term": {
              "SalesImpacted": true
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": searchTransactionFromDate,
                "gte": searchTransactionFromDate,
                "format": "yyyy-MM-dd"
              }
            }
          }
        ],
        "must_not": [
          {
            "exists": {
              "field": "CancellationReason"
            }
          }
        ]
      }
    },
    "aggs": {
      "my_buckets": {
        "composite": {
          "size": 10,
          "sources": [
            {
              "paymentMode": {
                "terms": {
                  "field": "PaymentMode.keyword"
                }
              }
            },
            {
              "transactionType": {
                "terms": {
                  "field": "TransactionType.keyword"
                }
              }
            }
          ]
        },
        "aggs": {
          "total_amount": {
            "sum": {
              "field": "PaymentAmount"
            }
          }
        }
      }
    }
  };
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable('getDenomPayments');
    return addMessage({ text: searchParameters }).then(function (result) {
      let sanitizedMessage = result.data.text;

      let payments = []
      sanitizedMessage.aggregations.my_buckets.buckets.map((bucket) => {
        const payment = {
          paymentMode: bucket.key.paymentMode,
          transactionType: bucket.key.transactionType,
          total_amount: bucket.total_amount.value
        };
        payments.push(payment)
      })
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: payments }
    });
  }
};

export function getWalletWiseTransaction(registrationID, searchStore, searchCashRegister, searchTransactionType, searchTransactionFromDate,
  searchTransactionToDate) {
  let searchParameters =
  {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          {
            "term": {
              "RegistrationID": registrationID
            }
          },
          {
            "term": {
              "StoreID.keyword": searchStore
            }
          },
          {
            "term": {
              "SalesImpacted": true
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": searchTransactionFromDate,
                "gte": searchTransactionFromDate,
                "format": "yyyy-MM-dd"
              }
            }
          },
          {
            "term": {
              "ProductType.keyword": "Wallet Recharge"
            }
          }
        ],
        "must_not": [
          {
            "exists": {
              "field": "CancellationReason"
            }
          }
        ]
      }
    },
    "aggs": {
      "total_sales": {
        "sum": {
          "field": "Amount"
        }
      },
      "total_transactions": {
        "cardinality": {
          "field": "TransactionID"
        }
      }
    }
  };
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable('getDenomSales');
    return addMessage({ text: searchParameters }).then(function (result) {
      let sanitizedMessage = result.data.text;
      const wallet = {
        total_transactions: sanitizedMessage.aggregations.total_transactions.value,
        total_sales: sanitizedMessage.aggregations.total_sales.value
      };
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: wallet }
    });
  }
};

export function getSalesDashboard(registrationID, searchStores, searchCashRegister, searchTransactionType, searchTransactionFromDate,
  searchTransactionToDate) {
  let searchParameters =
  {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          {
            "term": {
              "RegistrationID": registrationID
            }
          },
          {
            "term": {
              "TransactionType.SalesImpactChecked": true
            }
          }
        ],
        "must_not": [
          { "exists": { "field": "CancellationReason" } }
        ],
        "should": [], "minimum_should_match": 1,
      }
    },
    "aggs": {
      "sales_over_time": {
        "date_histogram": {
          "field": "TransactionDate",
          "interval": "month",
          "format": "MMMM",
          "keyed": true
        },
        "aggs": {
          "total_sales": {
            "sum": {
              "field": "BillAmount"
            }
          }
        }
      }
    }
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      "term": {
        "Store.key.keyword": store.key
      }
    })
  }
  if (searchTransactionType.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "TransactionType.TransactionType": "@" + searchTransactionType.trim().toLowerCase() + ".*" } })
  }
  if (searchCashRegister.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CashRegister.CashRegisterName.keyword": "@" + searchCashRegister.trim().toLowerCase() + ".*" } })
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable('getSales');
    return addMessage({ text: searchParameters }).then(function (result) {
      let sanitizedMessage = result.data.text;
      const salesTransactions = sanitizedMessage.aggregations.sales_over_time;
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: salesTransactions }
    });
  }
};



export function getEmployeeDashboard(registrationID, searchStores, searchTransactionFromDate,
  searchTransactionToDate, size, searchTransactionType) {
  let searchParameters =
  {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          {
            "term": {
              "RegistrationID": registrationID
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": searchTransactionToDate,
                "gte": searchTransactionFromDate,
                "format": "yyyy-MM-dd"
              }
            }
          }
        ], "must_not": [
          { "exists": { "field": "CancellationReason" } }
        ], "should": [], "minimum_should_match": 1,
        "filter": {
          "bool": {
            "must": [
            ]
          }
        }
      }
    },
    "aggs": {
      "group_by_employee": {
        "terms": {
          "field": "EmployeeName.keyword"
        },
        "aggs": {
          "total_sales": {
            "sum": {
              "field": "Amount"
            }
          },
          "sales_bucket_sort": {
            "bucket_sort": {
              "sort": [
                {
                  "total_sales": {
                    "order": "desc"
                  }
                }
              ]
            }
          },
          "total_taxes": {
            "sum": {
              "field": "TotalTax"
            }
          }
        }
      }
    }
  }
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      "term": {
        "StoreID.keyword": store.key
      }
    })
  }
  if (size.trim() != "") {
    searchParameters.aggs.group_by_employee.terms.size = size
  }
  else {
    searchParameters.aggs.group_by_employee.terms.size = 10000
  }
  if (searchTransactionType.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "TransactionTypeID": searchTransactionType.trim().toLowerCase() } })
  }
  return (dispatch, getState) => {
    let addMessage = firebase.functions().httpsCallable('getDenomSales');
    return addMessage({ text: searchParameters }).then(function (result) {
      const employees = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.group_by_employee.buckets.map((data, index) => {
        let employee = {
          name: data.key,
          total_sales: Number(data.total_sales.value) - Number(data.total_taxes.value),
        }
        employees.push(employee);
      });
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: employees }
    });
  }
};

export function getEmployeeProductTypeWise(registrationID, searchStores, searchTransactionFromDate,
  searchTransactionToDate, searchTransactionType) {
  let searchParameters =
  {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          {
            "term": {
              "RegistrationID": registrationID
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": searchTransactionToDate,
                "gte": searchTransactionFromDate,
                "format": "yyyy-MM-dd"
              }
            }
          }
        ], "must_not": [
          { "exists": { "field": "CancellationReason" } }
        ], "should": [], "minimum_should_match": 1,
        "filter": {
          "bool": {
            "must": [
            ]
          }
        }
      }
    },
    "aggs": {
      "employeeid": {
        "terms": {
          "field": "EmployeeID.keyword",
          "size": 10000
        },
        "aggs": {
          "employee": {
            "terms": {
              "field": "EmployeeName.keyword"
            },
            "aggs": {
              "total_amount": {
                "sum": {
                  "field": "Amount"
                }
              },
              "total_transactions": {
                "cardinality": {
                  "field": "TransactionID"
                }
              },
              "sales_bucket_sort": {
                "bucket_sort": {
                  "sort": [
                    {
                      "total_amount": {
                        "order": "desc"
                      }
                    }
                  ]
                }
              },
              "total_taxes": {
                "sum": {
                  "field": "TotalTax"
                }
              }, "total_discount": {
                "sum": {
                  "field": "DiscountAmount"
                }
              },
              "product_type": {
                "terms": {
                  "field": "ProductType.keyword"
                },
                "aggs": {
                  "total_amount": {
                    "sum": {
                      "field": "Amount"
                    }
                  },
                  "total_taxes": {
                    "sum": {
                      "field": "TotalTax"
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      "term": {
        "StoreID.keyword": store.key
      }
    })
  }
  return (dispatch, getState) => {
    if (searchTransactionType.trim() !== "") {
      searchParameters.query.bool.filter.bool.must.push({ "regexp": { "TransactionTypeID": searchTransactionType.trim().toLowerCase() } })
    }

    console.log("searchParameters", JSON.stringify(searchParameters))
    let addMessage = firebase.functions().httpsCallable('getDenomSales');
    return addMessage({ text: searchParameters }).then(function (result) {
      const employees = [];
      let sanitizedMessage = result.data.text;

      let empid = sanitizedMessage.aggregations.employeeid

      console.log(JSON.stringify(empid))

      empid.buckets.map((data) => {

        let employeeID = data.key

        data.employee.buckets.map((data, index) => {

          let employee = {
            employeeID: employeeID,
            name: data.key,
            total_transactions: data.total_transactions.value,
            total_sales: Number(data.total_amount.value) - Number(data.total_taxes.value),
            total_gross_sales: Number(data.total_amount.value) + Number(data.total_discount.value),
            product_types: []
          }

          data.product_type.buckets.map((data, index) => {
            let productType = {};
            productType.name = data.key === "Goods for sale" ? "Products" : data.key
            productType.total_sales = Number(data.total_amount.value) - Number(data.total_taxes.value)
            employee.product_types.push(productType);
          })
          employees.push(employee);
        });
      })
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: employees }
    });
  }
};

export function getCustomerReferenceReport(from, size, registrationID, storeID, month, dobFrom, dobTo) {
  return (dispatch) => {
    let searchParameters = {
      "from": from,
      "size": size,
      "query": {
        "bool": {
          "must": [
            { "match": { "RegistrationID": registrationID } },
            { "match": { "StoreID": storeID } },
            { "match": { "DOBNew.Month": month } }
          ]
        }
      }
    }
    let addMessage = firebase.functions().httpsCallable('getCustomers');
    return addMessage({ text: searchParameters }).then(function (result) {
      const customers = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        customers.push({ key: data._id, ...data._source })
      })
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: customers }
    });
  }
}


export function getProductTypeWiseSales(registrationID, searchStores, searchCashRegister, searchTransactionType, searchTransactionFromDate,
  searchTransactionToDate) {
  let searchParameters =
  {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          {
            "term": {
              "RegistrationID": registrationID
            }
          },
          {
            "term": {
              "SalesImpacted": true
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": searchTransactionToDate,
                "gte": searchTransactionFromDate,
                "format": "yyyy-MM-dd"
              }
            }
          }
        ],
        "must_not": [
          { "exists": { "field": "CancellationReason" } }
        ],
        "should": [], "minimum_should_match": 1,
      }
    },
    "aggs": {
      "group_by_product_types": {
        "terms": {
          "field": "ProductType.keyword"
        },
        "aggs": {
          "total_sales": {
            "sum": {
              "field": "Amount"
            }
          }
        }
      }
    }
  }
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      "term": {
        "StoreID.keyword": store.key
      }
    })
  }
  return (dispatch, getState) => {
    searchParameters.query.bool.must.push({
      "match": {
        "StoreID": getState().user.store.key
      }
    })
    let addMessage = firebase.functions().httpsCallable('getDenomSales');
    return addMessage({ text: searchParameters }).then(function (result) {
      const productTypes = [];
      let sanitizedMessage = result.data.text;

      sanitizedMessage.aggregations.group_by_product_types.buckets.map((data, index) => {
        let productType = {
          name: data.key,
          total_sales: data.total_sales.value,
        }
        productTypes.push(productType);
      });
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: productTypes }
    });
  }
};

export function getExpensesforSalesDashboard(registrationID, searchStores, searchCashRegister, searchTransactionFromDate, searchTransactionToDate) {
  let searchParameters =
  {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          {
            "term": {
              "RegistrationID": registrationID
            }
          },
          {
            "range": {
              "ExpenseDate": {
                "lte": searchTransactionToDate,
                "gte": searchTransactionFromDate,
                "format": "yyyy-MM-dd"
              }
            }
          }
        ],
        "must_not": [
          {
            "match": {
              "Status": "cancelled"
            }
          }
        ], "should": [], "minimum_should_match": 1
      }
    },
    "aggs": {
      "total_expenses": {
        "sum": {
          "field": "Amount"
        }
      }
    }
  }
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      "match": {
        "StoreID": store.key
      }
    })
  }
  // console.log("searchParameters",JSON.stringify(searchParameters))
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable('getExpenses');
    return addMessage({ text: searchParameters }).then(function (result) {
      let sanitizedMessage = result.data.text;
      const expenseHeader = {
        total_expenses: sanitizedMessage.aggregations.total_expenses.value,
      }
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: expenseHeader }
    });
  }
};

export function getExpenseList(RegistrationID, StoreID, from, size, option, searchParty, searchExpenseDateFrom, searchExpenseDateTo, searchExpenseCategory,
  searchExpenseNumber, searchReferenceNumber) {
  let searchParameters = {
    "from": from,
    "size": size,
    "query": {
      "bool": {
        "must": [
          { "term": { "RegistrationID": RegistrationID } },
          { "term": { "StoreID": StoreID } },
          {
            "range": {
              "ExpenseDate": {
                "lte": searchExpenseDateTo,
                "gte": searchExpenseDateFrom,
                "format": "yyyy-MM-dd"
              }
            }
          }
        ],
        "should": [

        ],
        "minimum_should_match": 1,
        "filter": {
          "bool": {
            "must": [

            ]
          }
        }
      }
    },
    "sort": [
      {
        "ActionOn": {
          "order": "desc"
        }
      }
    ]
  }

  if (option != "") {
    searchParameters.query.bool.filter.bool.must.push({ "match": { "Status": option.trim().toLowerCase() } })
  }
  if (searchParty.trim() !== "") {
    searchParameters.query.bool.should.push({ "regexp": { "Party.PartyFirstName": "@" + searchParty.trim().toLowerCase() + ".*" } })
    searchParameters.query.bool.should.push({ "regexp": { "Party.PartyLastName": "@" + searchParty.trim().toLowerCase() + ".*" } })
  }
  else {
    searchParameters.query.bool.should.push({ "regexp": { "Party.PartyFirstName": "@.*" } })
  }
  if (searchExpenseCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "ExpenseCategory": "@" + searchExpenseCategory.trim().toLowerCase() + ".*" } })
  }
  if (searchExpenseNumber.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "match_phrase": { "ExpenseNo": searchExpenseNumber } })
  }
  if (searchReferenceNumber.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "RefNo": "@" + searchReferenceNumber.trim().toLowerCase() + ".*" } })
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable('getExpenses');
    return addMessage({ text: searchParameters }).then(function (result) {
      const expenses = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        expenses.push({ key: data._id, ...data._source })
      })
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: expenses }
    });
  }
}

export function getExpenseCategoryList(RegistrationID, StoreID, searchExpenseDateFrom, searchExpenseDateTo, searchExpenseCategory) {
  let searchParameters = {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          {
            "term": {
              "RegistrationID": RegistrationID
            }
          },
          {
            "match": {
              "StoreID": StoreID
            }
          },
          {
            "range": {
              "ExpenseDate": {
                "lte": searchExpenseDateTo,
                "gte": searchExpenseDateFrom,
                "format": "yyyy-MM-dd"
              }
            }
          }
        ],
        "must_not": [
          {
            "term": {
              "Status.keyword": "Cancelled"
            }
          }
        ],
        "filter": {
          "bool": {
            "must": [

            ]
          }
        }
      }
    },
    "aggs": {
      "expense_category": {
        "terms": {
          "field": "ExpenseCategory.keyword",
          "order": {
            "total_sales": "desc"
          }
        },
        "aggs": {
          "total_sales": {
            "sum": {
              "field": "Amount"
            }
          }
        }
      }
    }
  }
  if (searchExpenseCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "ExpenseCategory": "@" + searchExpenseCategory.trim().toLowerCase() + ".*" } })
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable('getExpenses');
    return addMessage({ text: searchParameters }).then(function (result) {
      const expenses = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.expense_category.buckets.map((data, index) => {
        expenses.push({ key: data.key, total_sales: data.total_sales.value })
      })
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: expenses }
    });
  }
}

export function getExpenseByParty(RegistrationID, StoreID, searchExpenseDateFrom, searchExpenseDateTo, searchParty) {
  let searchParameters = {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          {
            "term": {
              "RegistrationID": RegistrationID
            }
          },
          {
            "match": {
              "StoreID": StoreID
            }
          },
          {
            "range": {
              "ExpenseDate": {
                "lte": searchExpenseDateFrom,
                "gte": searchExpenseDateTo,
                "format": "yyyy-MM-dd"
              }
            }
          }
        ],
        "must_not": [
          {
            "term": {
              "Status.keyword": "Cancelled"
            }
          }
        ],
        "filter": {
          "bool": {
            "must": [

            ]
          }
        }
      }
    },
    "aggs": {
      "party": {
        "terms": {
          "field": "Party.PartyName.keyword"
        },
        "aggs": {
          "status": {
            "terms": {
              "field": "Status.keyword"
            },
            "aggs": {
              "total_amount": {
                "sum": {
                  "field": "Amount"
                }
              }
            }
          }
        }
      }
    }
  }
  if (searchParty.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Party.PartyName": "@" + searchParty.trim().toLowerCase() + ".*" } })
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable('getExpenses');
    return addMessage({ text: searchParameters }).then(function (result) {
      const expenses = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.party.buckets.map((data, index) => {
        let expense = { key: data.key, PaidAmount: 0, PendingAmount: 0 }

        data.status.buckets.map((data1) => {
          if (data1.key === "Paid") {
            expense.PaidAmount = data1.total_amount.value
          }
          if (data1.key === "Pending") {
            expense.PendingAmount = data1.total_amount.value
          }
        })
        expenses.push(expense);

      })
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: expenses }
    });
  }
}

export function getExpenseForClosing(registrationID, searchStore, searchCashRegister, searchTransactionType, searchTransactionFromDate,
  searchTransactionToDate) {
  let searchParameters =
  {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          {
            "term": {
              "RegistrationID": registrationID
            }
          },
          {
            "match": {
              "StoreID": searchStore
            }
          },
          {
            "range": {
              "ExpenseDate": {
                "lte": searchTransactionToDate,
                "gte": searchTransactionFromDate,
                "format": "yyyy-MM-dd"
              }
            }
          },
          {
            "match": {
              "PaymentType": "Cash From Counter"
            }
          }
        ],
        "must_not": [
          {
            "term": {
              "Status.keyword": "Cancelled"
            }
          }
        ]
      }
    },
    "aggs": {
      "total_expenses": {
        "sum": {
          "field": "Amount"
        }
      }
    }
  };

  if (searchCashRegister.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "CashRegister.CashRegisterName.keyword": "@" + searchCashRegister.trim().toLowerCase() + ".*" } })
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable('getExpenses');
    return addMessage({ text: searchParameters }).then(function (result) {
      let sanitizedMessage = result.data.text;
      let expenseAmount = { total_expenses: sanitizedMessage.aggregations.total_expenses.value };
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: expenseAmount }
    });
  }
};


export function getItemInStockData(searchStores, from, size, searchStoreID, searchStoreLocation, searchCategory, searchSubCategory,
  searchProductSKU, searchProductName, searchBrand) {

  let searchParameters =
  {
    "from": from,
    "size": size,
    "query": {
      "bool": {
        "must": [],
        "should": [], "minimum_should_match": 1,
        "filter": {
          "bool": {
            "must": []
          }
        }
      }
    }
  }
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      "term": {
        "StoreID.keyword": store.key
      }
    })
  }
  if (searchStoreLocation.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Location": "@" + searchStoreLocation.trim().toLowerCase() + ".*" } })
  }

  if (searchCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Category": "@" + searchCategory.trim().toLowerCase() + ".*" } })
  }
  if (searchSubCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "SubCategory": "@" + searchSubCategory.trim().toLowerCase() + ".*" } })
  }
  if (searchProductSKU.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "ProductSKU": "@" + searchProductSKU.trim().toLowerCase() + ".*" } })
  }
  if (searchProductName.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "ProductName": "@" + searchProductName.trim().toLowerCase() + ".*" } })
  }
  if (searchBrand.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "Brand": "@" + searchBrand.trim().toLowerCase() + ".*" } })
  }

  console.log("Parameters", JSON.stringify(searchParameters));
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable('getInventoryInStock');
    return addMessage({ text: searchParameters }).then(function (result) {

      const products = []
      let sanitizedMessage = result.data.text;

      sanitizedMessage.hits.hits.map((data, index) => {
        products.push({ ...data._source })
      })

      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: products }
    });
  }
};


export function getItemLedger(productSKU, locationID) {
  return (dispatch) => {
    return database.collection("InventoryInStockAuditTrail").where("LocationID", "==", locationID).where("ProductSKU", "==", productSKU).orderBy("ActionOn", "desc").get().then((querySnapshot) => {
      const itemLedgers = []
      querySnapshot.forEach((doc) => {
        itemLedgers.push(
          {
            ActionOn: doc.data().ActionOn,
            Brand: doc.data().Brand,
            ProductSKU: doc.data().ProductSKU,
            Location: doc.data().Location,
            Category: doc.data().Category,
            SubCategory: doc.data().SubCategory,
            BarCode: doc.data().BarCode,
            ProductName: doc.data().ProductName,
            RetailPrice: doc.data().RetailPrice,
            TransactionType: doc.data().TransactionType,
            TransactionQty: doc.data().TransactionQty,
            TransactionNo: doc.data().TransactionNo,
            Action: doc.data().Action,
            InStockQty: doc.data().InStockQty
          })
      })
      return itemLedgers
    })
  }
}

export function getPaymentModeWiseData(registrationID, searchStore, searchTransactionFromDate, searchTransactionToDate) {
  let searchParameters =
  {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          {
            "term": {
              "RegistrationID": registrationID
            }
          },
          {
            "term": {
              "StoreID.keyword": searchStore
            }
          },
          {
            "term": {
              "SalesImpacted": true
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": searchTransactionToDate,
                "gte": searchTransactionFromDate,
                "format": "yyyy-MM-dd"
              }
            }
          }
        ],
        "must_not": [
          {
            "exists": {
              "field": "CancellationReason"
            }
          }
        ]
      }
    },
    "sort": [
      {
        "TransactionDate": {
          "order": "desc"
        }
      }
    ],
    "aggs": {
      "orders_over_day": {
        "date_histogram": {
          "field": "TransactionDate",
          "interval": "day",
          "order": {
            "_key": "desc"
          }
        },
        "aggs": {
          "payment_mode": {
            "terms": {
              "field": "PaymentMode.keyword"
            },
            "aggs": {
              "total_sales": {
                "sum": {
                  "field": "PaymentAmount"
                }
              }
            }
          }
        }
      }
    }
  };
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable('getDenomPayments');
    return addMessage({ text: searchParameters }).then(function (result) {
      let sanitizedMessage = result.data.text;

      let paymentList = []
      let data = sanitizedMessage.aggregations.orders_over_day.buckets;
      data.map((bucket) => {
        bucket.payment_mode.buckets.map((pay) => {
          const payment = [
            (new Date(bucket.key_as_string).toISOString().split('T')[0]),
            pay.key,
            pay.total_sales.value]

          paymentList.push(payment)
        })
      })
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: paymentList }
    });
  }
};

export function getDailySales(registrationID, searchStores, searchTransactionToDate, searchTransactionFromDate, searchInterval) {
  let searchParameters =
  {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          {
            "match": {
              "RegistrationID": registrationID
            }
          },
          {
            "match": {
              "TransactionType.SalesImpactChecked": true
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": searchTransactionToDate,
                "gte": searchTransactionFromDate,
                "format": "yyyy-MM-dd"
              }
            }
          }
        ],
        "must_not": [
          {
            "exists": {
              "field": "CancellationReason"
            }
          }
        ], "should": [], "minimum_should_match": 1
      }
    },
    "aggs": {
      "orders_over": {
        "date_histogram": {
          "field": "TransactionDate",
          "interval": searchInterval,
          "format": searchInterval === 'month' ? "MMM-yyyy" : "dd-MMM-yyyy",
          "order": {
            "_key": "desc"
          }
        },
        "aggs": {
          "store": {
            "terms": {
              "field": "Store.StoreName.keyword",
              "size": 100
            },
            "aggs": {
              "total_qty": {
                "sum": {
                  "field": "BillQty"
                }
              },
              "total_discount": {
                "sum": {
                  "field": "BillDiscount"
                }
              },
              "total_transactions": {
                "cardinality": {
                  "field": "_id"
                }
              },
              "total_taxamount": {
                "sum": {
                  "field": "BillTaxAmount"
                }
              },
              "total_sales": {
                "sum": {
                  "field": "BillPaidAmount"
                }
              },
              "total_balance": {
                "sum": {
                  "field": "BalanceAmount"
                }
              }
            }
          }
        }
      }
    }
  }
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];
    searchParameters.query.bool.should.push({
      "match": {
        "Store.key": store.key
      }
    })
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable('getSales');
    return addMessage({ text: searchParameters }).then(function (result) {
      const dailySales = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.orders_over.buckets.map((data, index) => {
        data.store.buckets.map((data1, index) => {
          let dailySale = {}
          if (searchInterval === 'month') {
            dailySale["Transaction Month"] = data.key_as_string
          }
          else {
            dailySale["Transaction Date"] = data.key_as_string
          }
          dailySale["Store"] = data1.key
          dailySale["Tax Amount"] = data1.total_taxamount.value
          dailySale["Total Discount"] = data1.total_discount.value
          dailySale["Total Transactions"] = data1.total_transactions.value
          dailySale["Total Qty"] = data1.total_qty.value
          dailySale["Total Balance"] = data1.total_balance.value
          dailySale["Total Sales"] = data1.total_sales.value
          dailySale["Gross Sales"] = Number(data1.total_sales.value) + Number(data1.total_discount.value)
          if (data1.total_sales.value > 0)
            dailySales.push(dailySale);
        })
      })
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: dailySales }
    });
  }
};

export function getPaymentModeWiseDaily(registrationID, searchStores, searchTransactionToDate, searchTransactionFromDate, searchInterval) {
  let searchParameters =
  {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          {
            "term": {
              "RegistrationID": registrationID
            }
          },
          {
            "term": {
              "SalesImpacted": true
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": searchTransactionToDate,
                "gte": searchTransactionFromDate,
                "format": "yyyy-MM-dd"
              }
            }
          }
        ],
        "must_not": [
          {
            "exists": {
              "field": "CancellationReason"
            }
          }
        ]
      }
    },
    "aggs": {
      "orders_over_day": {
        "date_histogram": {
          "field": "TransactionDate",
          "interval": searchInterval,
          "format": searchInterval === 'month' ? "MMM-yyyy" : "dd-MMM-yyyy",
          "order": {
            "_key": "desc"
          }
        },
        "aggs": {
          "payment_mode": {
            "terms": {
              "field": "PaymentMode.keyword"
            },
            "aggs": {
              "total_sales": {
                "sum": {
                  "field": "PaymentAmount"
                }
              }
            }
          }
        }
      }
    }
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];
    searchParameters.query.bool.must.push({
      "term": {
        "StoreID.keyword": store.key
      }
    })
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable('getDenomPayments');
    return addMessage({ text: searchParameters }).then(function (result) {
      let sanitizedMessage = result.data.text;

      let paymentList = []
      let data = sanitizedMessage.aggregations.orders_over_day.buckets;
      data.map((bucket) => {
        bucket.payment_mode.buckets.map((pay) => {
          const payment = {
            PaymentMode: pay.key,
            Payment_total_sales: (pay.total_sales.value === undefined ? 0 : pay.total_sales.value)
          }
          if (searchInterval === 'month') {
            payment["Transaction Month"] = bucket.key_as_string
          }
          else {
            payment["Transaction Date"] = bucket.key_as_string
          }
          paymentList.push(payment)
        })
      })
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: paymentList }
    });
  }
};


export function getEmployeeWorksheet(employeeID, searchTransactionFromDate,
  searchTransactionToDate, searchTransactionType) {
  let searchParameters =
  {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          {
            "term": {
              "EmployeeID.keyword": employeeID
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": searchTransactionToDate,
                "gte": searchTransactionFromDate,
                "format": "yyyy-MM-dd"
              }
            }
          }
        ],
        "must_not": [
          {
            "exists": {
              "field": "CancellationReason"
            }
          }
        ],
        "filter": {
          "bool": {
            "must": [
            ]
          }
        }
      }
    },
    "aggs": {
      "sku": {
        "terms": {
          "field": "SKU.keyword",
          "size": 10000
        },
        "aggs": {
          "product": {
            "terms": {
              "field": "ProductName.keyword"
            },
            "aggs": {
              "total_amount": {
                "sum": {
                  "field": "Amount"
                }
              },
              "total_items": {
                "sum": {
                  "field": "Qty"
                }
              },
              "total_taxes": {
                "sum": {
                  "field": "TotalTax"
                }
              }
            }
          }
        }
      }
    }
  }
  if (searchTransactionType.trim() !== "") {
    searchParameters.query.bool.filter.bool.must.push({ "regexp": { "TransactionTypeID": searchTransactionType.trim().toLowerCase() } })
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable('getDenomSales');
    return addMessage({ text: searchParameters }).then(function (result) {
      const productDetails = [];
      let sanitizedMessage = result.data.text;
      let sku = sanitizedMessage.aggregations.sku

      sku.buckets.map((data, index) => {
        let productDetail = {
          SKU: data.key
        }
        data.product.buckets.map((data, index) => {
          productDetail.ProductName = data.key
          productDetail.Amount = data.total_amount.value - data.total_taxes.value
          productDetail.Qty = data.total_items.value
        })
        productDetails.push(productDetail);
      });
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: productDetails }
    });
  }
};

export function getPaymentModeTransactionTypeWiseSales(registrationID, searchStores, searchTransactionType, searchTransactionFromDate,
  searchTransactionToDate, salesImpacted) {
  let searchParameters =
  {
    "size": 0,
    "query": {
      "bool": {
        "must": [
          {
            "term": {
              "RegistrationID": registrationID
            }
          },
          {
            "range": {
              "TransactionDate": {
                "lte": searchTransactionToDate,
                "gte": searchTransactionFromDate,
                "format": "yyyy-MM-dd"
              }
            }
          }
        ],
        "must_not": [
          { "exists": { "field": "CancellationReason" } }
        ],
        "should": [], "minimum_should_match": 1,
        "filter": {
          "bool": {
            "must": [
            ]
          }
        }
      }
    },
    "aggs": {
      "group_by_payment_type": {
        "terms": {
          "field": "PaymentMode.keyword"
        },
        "aggs": {
          "transaction_type": {
            "terms": {
              "field": "TransactionType.keyword"
            },
            "aggs": {
              "total_amount": {
                "sum": {
                  "field": "PaymentAmount"
                }
              }
            }
          }
        }
      }
    }
  };
  for (let i = 0; i < searchStores.length; i++) {
    const store = searchStores[i];

    searchParameters.query.bool.should.push({
      "term": {
        "StoreID.keyword": store.key
      }
    })
  }
  if (salesImpacted) {
    searchParameters.query.bool.filter.bool.must.push({ "term": { "SalesImpacted": salesImpacted } })
  }
  return (dispatch) => {
    let addMessage = firebase.functions().httpsCallable('getDenomPayments');
    return addMessage({ text: searchParameters }).then(function (result) {
      const paymentTypes = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.aggregations.group_by_payment_type.buckets.map((data, index) => {
        let paymentType = {
          name: data.key,
          total_mode_amount: 0,
          transactionTypes: []
        };
        data.transaction_type.buckets.map((transactionData, index) => {
          let transactionType = {
            name: transactionData.key,
            total_tran_amount: transactionData.total_amount.value
          }
          paymentType.transactionTypes.push(transactionType);
          paymentType.total_mode_amount += transactionData.total_amount.value
        })
        paymentTypes.push(paymentType);
      });
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: paymentTypes };
    });
  };
};


export function getAllInvetoryDetailReport(from, size, searchTransactionNo, searchTransactionType,
  searchTransactionFromDate, searchTransactionToDate, searchVendorDetail, searchProductDetail) {
  let searchParameters = {
    "from": from,
    "size": size,
    "query": {
      "bool": {
        "must": [
          {
            "range": {
              "TransactionDate": {
                "lte": searchTransactionToDate,
                "gte": searchTransactionFromDate,
                "format": "yyyy-MM-dd"
              }
            }
          },
          { "regexp": { "TransactionTypeID": searchTransactionType.trim().toLowerCase() } }
        ],
        "filter": {
          "bool": {
            "should": [

            ],
            "minimum_should_match": 1
          }
        }
      }
    },
    "sort": [
      {
        "TransactionDate": {
          "order": "desc"
        }
      }
    ]
  }
  if (searchVendorDetail.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({ "regexp": { "VendorName": "@" + searchVendorDetail.trim().toLowerCase() + ".*" } })
    searchParameters.query.bool.filter.bool.should.push({ "regexp": { "VendorEmailID": "@" + searchVendorDetail.trim().toLowerCase() + ".*" } })
    searchParameters.query.bool.filter.bool.should.push({ "regexp": { "VendorPhoneNo": "@" + searchVendorDetail.trim().toLowerCase() + ".*" } })
    searchParameters.query.bool.filter.bool.should.push({ "regexp": { "VendorCode": "@" + searchVendorDetail.trim().toLowerCase() + ".*" } })
  }
  if (searchProductDetail.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "ProductName": searchProductDetail.trim().toLowerCase() } })
    searchParameters.query.bool.filter.bool.should.push({ "regexp": { "SKU": "@" + searchProductDetail.trim().toLowerCase() + ".*" } })
  }
  if (searchTransactionNo.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "TransactionNo": searchTransactionNo } })
  }
  return (dispatch, getState) => {
    searchParameters.query.bool.must.push({
      "match": {
        "StoreID": getState().user.store.key
      }
    })
    let addMessage = firebase.functions().httpsCallable('getDenomInventory');
    return addMessage({ text: searchParameters }).then(function (result) {
      const inventoryList = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        inventoryList.push({ ...data._source, key: data._id })
      })
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: inventoryList }
    });
  }
}

export function getCustomerWalletLedger(RegistrationID, StoreID, from, size, option, searchText, searchWalletFrom, searchWalletTo) {
  return (dispatch) => {
    let searchParameters = {
      "from": from,
      "size": size,
      "query": {
        "bool": {
          "must": [
            { "match": { "RegistrationID": RegistrationID } }
            , {
              "match": {
                "StoreID": StoreID
              }
            },
            {
              "range": {
                "WalletBalance": {
                  "gt": 0,
                }
              }
            }
          ],
          "must_not": [
          ],
          "should": [

          ],
          "minimum_should_match": 1,
          "filter": {
            "bool": {
              "must": [
              ]
            }
          }
        }
      },
      "sort": [
        {
          "ActionOn": {
            "order": "desc"
          }
        }
      ]
    }
    if (option === "active") {
      searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "true" } })
    }
    if (option === "inactive") {
      searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "false" } })
    }
    searchParameters.query.bool.should.push({ "regexp": { "CustomerDetails": "@" + searchText + ".*" } })
    searchParameters.query.bool.should.push({ "match": { "CustomerDetails": { "query": searchText, "operator": "and" } } })
    searchParameters.query.bool.should.push({ "regexp": { "EmailID": "@" + searchText.trim().toLowerCase() + ".*" } })
    searchParameters.query.bool.should.push({ "regexp": { "PhoneNo": "@" + searchText.trim().toLowerCase() + ".*" } })

    if (searchWalletFrom !== "" && searchWalletTo !== "") {
      searchParameters.query.bool.filter.bool.must.push({
        "range": {
          "WalletBalance": {
            "lte": searchWalletTo,
            "gte": searchWalletFrom
          }
        }
      })
    }
    else {
      if (searchWalletFrom !== "") {
        searchParameters.query.bool.filter.bool.must.push({
          "range": {
            "WalletBalance": {
              "gte": searchWalletFrom,
            }
          }
        })
      }
      if (searchWalletTo !== "") {
        searchParameters.query.bool.filter.bool.must.push({
          "range": {
            "WalletBalance": {
              "lte": searchWalletTo,
            }
          }
        })
      }
    }
    let addMessage = firebase.functions().httpsCallable('getCustomers');
    return addMessage({ text: searchParameters }).then(function (result) {
      const customers = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        customers.push({ key: data._id, ...data._source })
      })
      console.log("customers", customers)
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: customers }
    });
  }
}

export function getCustomerOutStandingLedger(RegistrationID, StoreID, from, size, option, searchText, searchOutstandingFrom, searchOutstandingTo) {
  return (dispatch) => {
    let searchParameters = {
      "from": from,
      "size": size,
      "query": {
        "bool": {
          "must": [
            { "match": { "RegistrationID": RegistrationID } }
            , {
              "match": {
                "StoreID": StoreID
              }
            },
            {
              "range": {
                "OutStandingAmount": {
                  "gt": 0,
                }
              }
            }
          ],
          "must_not": [
          ],
          "should": [

          ],
          "minimum_should_match": 1,
          "filter": {
            "bool": {
              "must": [
              ]
            }
          }
        }
      },
      "sort": [
        {
          "ActionOn": {
            "order": "desc"
          }
        }
      ]
    }
    if (option === "active") {
      searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "true" } })
    }
    if (option === "inactive") {
      searchParameters.query.bool.must_not.push({ "match": { "IsDeleted": "false" } })
    }
    searchParameters.query.bool.should.push({ "regexp": { "CustomerDetails": "@" + searchText + ".*" } })
    searchParameters.query.bool.should.push({ "match": { "CustomerDetails": { "query": searchText, "operator": "and" } } })
    searchParameters.query.bool.should.push({ "regexp": { "EmailID": "@" + searchText.trim().toLowerCase() + ".*" } })
    searchParameters.query.bool.should.push({ "regexp": { "PhoneNo": "@" + searchText.trim().toLowerCase() + ".*" } })

    if (searchOutstandingFrom !== "" && searchOutstandingTo !== "") {
      searchParameters.query.bool.filter.bool.must.push({
        "range": {
          "OutStandingAmount": {
            "gte": searchOutstandingFrom,
            "lte": searchOutstandingTo,
          }
        }
      })
    }
    else {
      if (searchOutstandingFrom !== "") {
        searchParameters.query.bool.filter.bool.must.push({
          "range": {
            "OutStandingAmount": {
              "gte": searchOutstandingFrom,
            }
          }
        })
      }
      if (searchOutstandingTo !== "") {
        searchParameters.query.bool.filter.bool.must.push({
          "range": {
            "OutStandingAmount": {
              "lte": searchOutstandingTo,
            }
          }
        })
      }
    }
    console.log("OutStanding - Query", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable('getCustomers');
    return addMessage({ text: searchParameters }).then(function (result) {
      const customers = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        customers.push({ key: data._id, ...data._source })
      })
      console.log("customers", customers)
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: customers }
    });
  }
}

export function getOutStandingLedgerByCustomerID(customerID, from, size, searchTransactionNo, searchTransactionFromDate, searchTransactionToDate) {
  let searchParameters = {
    "from": from,
    "size": size,
    "query": {
      "bool": {
        "must": [
          {
            "match": {
              "Customer.key.keyword": customerID
            }
          },
          {
            "range": {
              "BalanceAmount": {
                "gt": 0
              }
            }
          }
        ],
        "filter": {
          "bool": {
            "must": [

            ],
            "should": []
          }
        }
      }
    }, "sort": [
      {
        "TransactionDate": {
          "order": "desc"
        }
      }
    ]
  };
  if (searchTransactionNo.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "TransactionNo": searchTransactionNo } })
  }
  if (searchTransactionFromDate !== "" && searchTransactionToDate !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      "range": {
        "TransactionDate": {
          "lte": searchTransactionToDate,
          "gte": searchTransactionFromDate,
          "format": "yyyy-MM-dd"
        }
      }
    })
  }
  return (dispatch) => {
    // console.log("OutStanding - Query", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable('getSales');
    return addMessage({ text: searchParameters }).then(function (result) {
      const walletLedgers = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        walletLedgers.push({ key: data._id, ...data._source });
      });
      // console.log("Customer Dashboard Header Data", JSON.stringify(walletLedgers));
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: walletLedgers }
    });
  }
};

export function getWalletLedgersByCustomer(customerID, from, size, searchTransactionNo, searchTransactionFromDate, searchTransactionToDate) {
  let searchParameters = {
    "from": from,
    "size": size,
    "query": {
      "bool": {
        "must": [
          {
            "match": {
              "CustomerID": customerID
            }
          }
        ],
        "filter": {
          "bool": {
            "must": [],
            "should": []
          }
        }
      }
    }, "sort": [
      {
        "TransactionDate": {
          "order": "desc"
        }
      }
    ]
  };
  if (searchTransactionNo.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "TransactionNo": searchTransactionNo } })
  }
  if (searchTransactionFromDate !== "" && searchTransactionToDate !== "") {
    searchParameters.query.bool.filter.bool.must.push({
      "range": {
        "TransactionDate": {
          "lte": searchTransactionToDate,
          "gte": searchTransactionFromDate,
          "format": "yyyy-MM-dd"
        }
      }
    })
  }
  return (dispatch) => {
    // console.log("Get Wallet Details - Query", JSON.stringify(searchParameters));
    let addMessage = firebase.functions().httpsCallable('getWalletLedgers');
    return addMessage({ text: searchParameters }).then(function (result) {
      const walletLedgers = [];
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data, index) => {
        walletLedgers.push({ key: data._id, ...data._source });
      });
      // console.log("Customer Data", JSON.stringify(walletLedgers));
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: walletLedgers }
    });
  }
};

export function getUniqueNos(RegistrationID, StoreID, from, size, searchBrand, searchCategory, searchSubCategory,
  searchProductName, searchSKU, searchUniqueNo1, searchUniqueNo2, searchUniqueNo3, searchUniqueNo4) {
  let searchParameters = {
    "from": from,
    "size": size,
    "query": {
      "bool": {
        "must": [
          { "match": { "RegistrationID": RegistrationID } }
          , {
            "match": {
              "StoreID": StoreID
            }
          },
          {
            "match": {
              "IsDeleted": false
            }
          }
        ],
        "filter": {
          "bool": {
            "must": [],
            "should": []
          }
        }
      }
    }
  }
  if (searchBrand.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "ProductBrand": searchBrand } })
  }
  if (searchCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "ProductCategory": searchCategory } })
  }
  if (searchSubCategory.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "ProductSubCategory": searchSubCategory } })
  }
  if (searchProductName.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "ProductName": searchProductName } })
  }
  if (searchSKU.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "ProductSKU": searchSKU } })
  }
  if (searchUniqueNo1.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "UniqueNo1": searchUniqueNo1 } })
  }
  if (searchUniqueNo2.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "UniqueNo2": searchUniqueNo2 } })
  }
  if (searchUniqueNo3.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "UniqueNo3": searchUniqueNo3 } })
  }
  if (searchUniqueNo4.trim() !== "") {
    searchParameters.query.bool.filter.bool.should.push({ "match_phrase": { "UniqueNo4": searchUniqueNo4 } })
  }
  return (dispatch) => {
    // console.log('searchParameters', JSON.stringify(searchParameters))
    let addMessage = firebase.functions().httpsCallable('getUniqueNos');
    return addMessage({ text: searchParameters }).then(function (result) {
      let uniqueNo = []
      let sanitizedMessage = result.data.text;
      sanitizedMessage.hits.hits.map((data) => {
        uniqueNo.push({ key: data._id, ...data._source })
      })
      return { totalItemsCount: sanitizedMessage.hits.total, searchResult: uniqueNo }
    });
  }
}

export function getUniqueNoLedger(docKey) {
  return (dispatch) => {
    return database.collection("UniqueNosAuditTrail").where("DocKey", "==", docKey).get().then((querySnapshot) => {
      let uniqueNoLedgers = []
      querySnapshot.forEach((doc) => {
        uniqueNoLedgers.push({ key: doc.id, ...doc.data() })
      })
      console.log('uniqueNoLedgers', uniqueNoLedgers)
      return uniqueNoLedgers
    })
  }
}
