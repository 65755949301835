import React , {Component} from 'react'
import CustomerSearch from './CustomerSearch'
import CustomerAddress from './CustomerAddress'
import TimePicker from '../TimePicker'

class Delivery extends Component
{
    constructor () {
        super()
        this.state={
            selectedCustomer:null,
            selectedCustomerAddress:null,
            deliveryDate:'',
            deliveryTime:{HH:'', MM:'', AMPM:''},
            customerError:'',
            placeOfSupply:'',
            customerError:'',
            customerAddressError:'',
            deliveryDateError:'',
            deliveryNotes:''
        }
        this.onCustomerChange = this.onCustomerChange.bind(this)
        this.onAddCustomer = this.onAddCustomer.bind(this)
        this.onCustomerEdit = this.onCustomerEdit.bind(this)   
        this.onAMPMChange = this.onAMPMChange.bind(this)
        this.onMMChange = this.onMMChange.bind(this)
        this.onHHChange = this.onHHChange.bind(this)
        this.onSaveDelivery = this.onSaveDelivery.bind(this)
    }

    componentDidMount()
    {
        let deliveryDate= null
        // console.log('this.props.deliveryDate', this.props.deliveryDate)
        // console.log('this.props.deliveryCustomer', this.props.deliveryCustomer)
        if(!this.props.deliveryDate)
        {
            deliveryDate= new Date().toISOString().split('T')[0]
        }
        else
        {
            deliveryDate= this.props.deliveryDate
        }
        this.setState({selectedCustomer:this.props.deliveryCustomer,
        selectedCustomerAddress:this.props.deliveryCustomerAddress,
        deliveryDate:deliveryDate,
        deliveryTime:this.props.deliveryTime,
        deliveryNotes: this.props.deliveryNotes})
    }

    onCustomerChange(customer){
       this.setState({selectedCustomer:customer, selectedCustomerAddress:null})
    }
    
    onAddCustomer(newCustomer){
        this.props.onAddCustomer(newCustomer)
    }

    onCustomerEdit(){
        this.props.onCustomerEdit()
    }

    onAMPMChange(AMPM){

        this.setState({ deliveryTime:{ HH:this.state.deliveryTime.HH, MM:this.state.deliveryTime.MM, AMPM:AMPM }} )
    }

    onMMChange(MM){
        this.setState({ deliveryTime:{HH:this.state.deliveryTime.HH,   MM:MM , AMPM: this.state.deliveryTime.AMPPM}})
    }

    onHHChange(HH){
        this.setState({ deliveryTime:{ HH:HH ,  MM: this.state.deliveryTime.MM, AMPPM : this.state.deliveryTime.AMPM} })
    }

    onSaveDelivery(){
        let customerError =""
        let customerAddressError =""
        let deliveryDateError =""
        if(!this.state.selectedCustomer)
        {
            customerError= "Please select customer"
        }
        // if(!this.state.selectedCustomerAddress)
        // {
        //     customerAddressError = "Please specify customer address"
        // }
        if(!this.state.deliveryDate)
        {
            deliveryDateError = "Please select delivery date"
        }
        if(customerError.trim()!=="" || customerAddressError.trim() !=="" || deliveryDateError.trim() !=="")
        {
            this.setState({customerError:customerError,
                customerAddressError:customerAddressError,
                deliveryDateError:deliveryDateError
            })
            return 
        }
        // console.log('this.state.deliveryTime',this.state.deliveryTime)
        // console.log('this.state.deliveryDate', this.state.deliveryDate)
        this.props.onSave(this.state.selectedCustomer,
        this.state.selectedCustomerAddress, 
        this.state.deliveryDate,
        this.state.deliveryTime,
        this.state.deliveryNotes,
        this.state.placeOfSupply)
    }

    render(){
        const styleEditor = {
            width: "100%",
            height: "150px",
            font_size: "14px",
            line_height: "18px",
            border: "1px solid #dddddd",
            padding: "10px"
        }

        return <div style={{ width: "500px" }}>
            <h3 style={{ marginTop: "0px" }}>Delivery</h3>
            <div className="box-body">
            <div className="row" >
            {/* style={{ padding: "10px", paddingBottom: "0px" }}> */}
                    <div className="col-md-12">
                        <CustomerSearch
                        {...this.props}
                        // ref="customerSearch"
                        customer= {this.state.selectedCustomer}
                        error={this.state.customerError}
                        onCustomerChange= {
                            this.onCustomerChange}
                        onAddCustomer= {(newCustomer)=> this.props.onAddCustomer(newCustomer)}
                        onCustomerEdit= {()=>this.props.onCustomerEdit(this.state.selectedCustomer)}
                        ></CustomerSearch>
                    </div>
                </div>
                {this.state.selectedCustomer && <div className="row"  >
                {/* style={{padding:"0px 10px 0px 10px"}} */}
                        <div className="col-md-12" >
                            <CustomerAddress
                                {...this.props}
                            customer={this.state.selectedCustomer}
                            selectedAddress={this.state.selectedCustomerAddress}
                            onAddressChange ={(address)=> {
                                this.setState({selectedCustomerAddress:address, placeOfSupply:address.State })       
                            }}
                            />
                    </div>
                </div>}
                <div className="row"  style={{paddingTop:"10px"}}>
                    <div className="col-md-6">
                        <div className="form-group" >
                                <label >Date</label>
                                <input type="date" className="form-control"
                                onChange={(evt) => this.setState({ deliveryDate: evt.target.value })}
                                                value={this.state.deliveryDate}
                                                // onKeyPress={this.handleKeyPress} 
                                                />
                             </div>                   
                    </div>
                    <div className="col-md-6">
                    <div className="form-group" >
                                <label >Time</label>
                                    <TimePicker 
                                    time={this.state.deliveryTime}
                                    onChange={(time)=> this.setState({deliveryTime:time})}/>
                                    {/* <div className="row">
                                        <div className="col-md-4" style={{paddingRight:"0px"}}>
                                            <input type="number"
                                            onChange={(evt) => this.onHHChange(evt.target.value)}
                                            value={this.state.deliveryTime.HH} 
                                            placeholder="HH"
                                            className="form-control"></input>
                                        </div>
                                        <div style={{padding:"0px"}} className="col-md-4" >
                                            <input type="number" 
                                            className="form-control" 
                                            onChange={(evt) => this.onMMChange(evt.target.value)}
                                            value={this.state.deliveryTime.MM} 
                                            placeholder="MM"
                                            >
                                            </input>
                                        </div>
                                        <div className="col-md-4" style={{paddingLeft:"0px"}}>
                                            <select 
                                                className="form-control" 
                                                onChange={(evt) => this.onAMPMChange(evt.target.value)}
                                                value={this.state.deliveryTime.AMPM} >
                                                    <option>AM</option>
                                                    <option>PM</option>
                                            </select>
                                        </div> 
                                    </div>*/}
                                </div>
                    </div>
                </div>
                <div className="row"  style={{paddingTop:"10px"}}>
                    <div className="col-md-12">
                        <div className="form-group" >
                                    <label >Notes</label>
                                    <textarea className="text" style={styleEditor} 
                                        onChange={(evt) => this.setState({ deliveryNotes: evt.target.value })}
                                        value={this.state.deliveryNotes}></textarea>
                                </div>                  
                    </div>
                </div>
            </div>
            <div className="box-footer">
                <button type="submit" className="btn btn-flat btn-md btn-flat btn-default" onClick={this.props.onClose} >Cancel</button>
                <button type="submit" className="btn btn-flat btn-md btn-flat btn-success pull-right" onClick={this.onSaveDelivery} disabled={this.state.saving}>OK</button>
            </div>
    </div>
    }
}

export default Delivery