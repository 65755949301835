import React, { Component } from 'react';
import CSVReader from 'react-csv-reader';
import ReactExport from "react-data-export";
import { isNullOrUndefined } from 'util';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ImportMaster extends Component {
    constructor() {
        super();
        this.state = {
            salesImportTemplate: 'https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/templates%2FSalesImport.csv?alt=media&token=113cdc2d-225f-4540-87f5-e48d2183c969',
            membershipImportTemplate: 'https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/templates%2FMembershipImport.csv?alt=media&token=cb39c026-8438-4dd5-849c-03cd5ba3b6c6',
            membershipImportDiscountTemplate: 'https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/templates%2FMemberShipOnDiscountLevel.csv?alt=media&token=88dea171-7bd6-4b0d-8e0f-66ee3eb1190f',
            inventoryImportQtyTemplate: 'https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/templates%2FInventoryImportQty.csv?alt=media&token=be093a5f-c4ad-49b5-85e9-155e3ad58bb8',
            inventoryImportPriceTemplate: 'https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/templates%2FInventoryImportPrice.csv?alt=media&token=6f7d2ec6-9e97-4420-ab29-57d0a4c6e05a',
            tagImportTemplate: 'https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/templates%2FTagsImport.csv?alt=media&token=5dc7998a-1603-4923-b94a-30671fe125ad',
            inventoryUniqueNoTemplate: 'https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/templates%2FInventoryImportUniqueNo.csv?alt=media&token=5d2bcad8-46b5-4722-84a6-9e73a7a60705',
            vendorTemplate: 'https://firebasestorage.googleapis.com/v0/b/posible-app-prod.appspot.com/o/templates%2FInventoryImportUniqueNo.csv?alt=media&token=5d2bcad8-46b5-4722-84a6-9e73a7a60705',
            memberships: [],
            showFileUpload: true,
            importType: '',
            importedData: [],
            membershipColumns: ["SKU", "RetailPrice"],
            membershipColumnsDiscount: ["SKU", "Discount Perc", "Discount Amt"],
            tagColumns: ["SKU"],
            inventoryWithoutPriceColumns: ["SKU", "Qty", "Notes"],
            inventoryUniqueNo: ["SKU", "UniqueNo1", , "UniqueNo2", "UniqueNo3", "UniqueNo4"],
            inventoryWithPriceColumns: ["SKU", "Supply Price", "Qty", "Discount Amount", "HSNSACCode", "Tax Inclusive", "Tax Group", "Notes"],
            salesColumns: ["Barcode", "Description of Goods", "Batch", "MRP", "Qty", "Size", "Basic Rate", "Taxable Value", "Tax Inclusive", "SGST Rate", "SGST Amount", "CGST Rate", "CGST Amount", "IGST Rate", "IGST Amount", "Total Amount"],
            vendorColumns: ["VendorCode", "VendorFirstName", "VendorLastName", "Gender", "PhoneNo", "EmailID", "Address1", "Address2", "City", "State", "PostalCode", "Country", "CustomField1", "CustomField2", "CustomField3", "CustomField4", "CustomField5", "CustomField6"],
            errorSales: [],
            errorMemberships: [],
            totalCounter: 0,
            errorCounter: 0,
            successCounter: 0,
            inventoryError: [],
            importTemplate: '',
            inventoryImportTypeSelected: ''
        }
        this.onFileLoaded = this.onFileLoaded.bind(this);
        this.salesImport = this.salesImport.bind(this);
        this.inventoryImportWithQty = this.inventoryImportWithQty.bind(this)
        this.inventoryImportWithPrice = this.inventoryImportWithPrice.bind(this)
        this.inventoryImportUniqueNo = this.inventoryImportUniqueNo.bind(this)
        this.onInventoryImportChange = this.onInventoryImportChange.bind(this)
        this.vendorImport = this.vendorImport.bind(this);
    }

    componentDidMount() {
        let importTemplate = ""
        let inventoryImportTypeSelected = ""
        if (this.props.importType === "membership") {
            importTemplate = this.state.membershipImportTemplate
        }
        if (this.props.importType === "vendor") {
            importTemplate = this.state.vendorTemplate
        }
        else if (this.props.importType === "membershipDiscount") {
            importTemplate = this.state.membershipImportDiscountTemplate
        }
        else if (this.props.importType === "sales") {
            importTemplate = this.state.salesImportTemplate
        }
        else if (this.props.importType === "inventoryQty") {
            inventoryImportTypeSelected = this.props.importType
            importTemplate = this.state.inventoryImportQtyTemplate
        }
        else if (this.props.importType === "inventoryPrice") {
            inventoryImportTypeSelected = this.props.importType
            importTemplate = this.state.inventoryImportPriceTemplate
        }
        else if (this.props.importType === "producttags") {
            importTemplate = this.state.tagImportTemplate
        }
        this.setState({ importTemplate: importTemplate, inventoryImportTypeSelected: inventoryImportTypeSelected })
    }

    onInventoryImportChange(inventoryImportTypeSelected) {
        let importTemplate = ''
        if (inventoryImportTypeSelected === "inventoryUniqueNo") {
            importTemplate = this.state.inventoryUniqueNoTemplate
        }
        else if (inventoryImportTypeSelected === "inventoryQty") {
            importTemplate = this.state.inventoryImportQtyTemplate
        }
        else if (inventoryImportTypeSelected === "inventoryPrice") {
            importTemplate = this.state.inventoryImportPriceTemplate
        }
        console.log('inventoryImportTypeSelected', inventoryImportTypeSelected)
        console.log('importTemplate', importTemplate)
        this.setState({ importTemplate: importTemplate, inventoryImportTypeSelected: inventoryImportTypeSelected })
    }

    onFileLoaded(collection) {
        // console.log('this.props.importType',this.props.importType)
        this.setState({ showFileUpload: false });
        this.setState({ totalCounter: collection.length });

        if (this.props.importType === "membership") {
            this.membershipImport(collection);
        }
        if (this.props.importType === "membershipDiscount") {
            this.membershipImportOnDiscount(collection);
        }
        if (this.props.importType === "sales") {
            this.salesImport(collection);
        }
        if (this.props.importType === "inventoryQty") {
            if (this.state.inventoryImportTypeSelected === "inventoryUniqueNo") {
                this.inventoryImportUniqueNo(collection)
            }
            else {
                this.inventoryImportWithQty(collection)
            }
        }
        if (this.props.importType === "inventoryPrice") {
            if (this.state.inventoryImportTypeSelected === "inventoryUniqueNo") {
                this.inventoryImportUniqueNo(collection)
            }
            else {
                this.inventoryImportWithPrice(collection)
            }

        }
        if (this.props.importType === "producttags") {
            this.tagImport(collection)
        }
        if (this.props.importType === "vendor") {
            this.vendorImport(collection);
        }
    }

    tagImport(collection) {
        // console.log("collection: " + collection[0]);
        // console.log("state: "  + this.state.membershipColumns);

        if (this.state.tagColumns.length !== collection[0].length) {
            alert("Column missing:- File is not in desired format");
            return;
        }
        else {
            this.state.tagColumns.map((tagColumn, index) => {
                if (collection[0][index] !== tagColumn) {
                    alert("Column sequence missing:- File is not in desired format");
                    return;
                }
            })
            let products = [];
            collection.map((data, index) => {
                if (index > 0) {
                    if (!isNullOrUndefined(data) && data != "" && data[0] != "") // data[1] != null && data[1]!= undefined && data[1] !== "")
                    {
                        let product = {
                            SKU: data[0],
                            Name: ""
                        };
                        let productID = "";
                        this.props.config.Products.filter((e) => product.SKU.trim() === e.SKU.trim() && !e.IsDeleted).map((prod) => { productID = prod.key; product.Name = prod.Name })
                        if (!isNullOrUndefined(productID) && productID != "") {
                            product.ProductID = productID
                            products.push(product);
                        }
                    }
                }
            })
            this.props.onImportCompletion(products);
        }
    }

    membershipImport(collection) {
        if (this.state.membershipColumns.length !== collection[0].length) {
            alert("Column missing:- File is not in desired format");
            return;
        }
        else {
            this.state.membershipColumns.map((membershipColumn, index) => {
                if (collection[0][index] !== membershipColumn) {
                    alert("Column sequence missing:- File is not in desired format");
                    return;
                }
            })
            let products = [];
            collection.map((data, index) => {
                if (index > 0) {
                    if (!isNullOrUndefined(data) && data != "" && data[0] != "") // data[1] != null && data[1]!= undefined && data[1] !== "")
                    {
                        let product = {
                            SKU: data[0],
                            Name: "",
                            RetailPrice: Number(data[1]),
                            DiscountPercentage: 0,
                            DiscountAmount: 0
                        };
                        let productID = "";
                        this.props.config.Products.filter((e) => product.SKU.trim() === e.SKU.trim() && !e.IsDeleted).map((prod) => { productID = prod.key; product.Name = prod.Name })
                        if (!isNullOrUndefined(productID) && productID != "") {
                            product.ProductID = productID
                            products.push(product);
                        }
                    }
                }
            })
            this.props.onImportCompletion(products);
        }
    }

    membershipImportOnDiscount(collection) {
        if (this.state.membershipColumnsDiscount.length !== collection[0].length) {
            alert("Column missing:- File is not in desired format");
            return;
        }
        else {
            this.state.membershipColumnsDiscount.map((membershipColumn, index) => {
                if (collection[0][index] !== membershipColumn) {
                    alert("Column sequence missing:- File is not in desired format");
                    return;
                }
            })
            let products = [];
            collection.map((data, index) => {
                let errorMessage = "";
                if (index > 0) {
                    if (!isNullOrUndefined(data) && data != "" && data[0] != "") // data[1] != null && data[1]!= undefined && data[1] !== "")
                    {
                        let product = {
                            SKU: data[0],
                            Name: "",
                            RetailPrice: 0,
                            DiscountPercentage: Number(data[1]),
                            DiscountAmount: Number(data[2])
                        };
                        let productID = "";
                        this.props.config.Products.filter((e) => product.SKU.trim() === e.SKU.trim() && !e.IsDeleted).map((prod) => { productID = prod.key; product.Name = prod.Name })
                        if (!isNullOrUndefined(productID) && productID != "") {
                            product.ProductID = productID
                        }
                        if (product.DiscountAmount > 0 && product.DiscountPercentage > 0) {
                            errorMessage += "Discount Amt. and Discount Percentage cannot be non-zero."

                        }

                        if (errorMessage != "") {
                            let errorObj = {
                                SKU: data[0],
                                DiscountPercentage: Number(data[1]),
                                DiscountAmount: Number(data[2])
                            }
                            this.state.errorMemberships.push({ ...errorObj, Message: errorMessage });
                            this.setState({ errorCounter: this.state.errorCounter + 1 });
                        }
                        else {
                            this.setState({ successCounter: this.state.successCounter + 1 })
                            products.push(product);
                        }
                    }
                }
            })
            let len = products.length
            len = Number(len) + Number(this.state.errorMemberships.length);
            this.setState({ totalCounter: len });
            if (this.state.errorMemberships.length === 0) {
                this.props.onImportCompletion(products);
            }
        }
    }

    salesImport(collection) {
        if (this.state.salesColumns.length !== collection[0].length) {
            alert("Column missing:- File is not in desired format");
            return;
        }
        else {
            this.state.salesColumns.map((saleColumn, index) => {
                if (collection[0][index] !== saleColumn) {
                    //console.log("Column sequence missing:- File is not in desired format", collection[0][index] + ' ' + saleColumn);
                    alert("Column sequence missing:- File is not in desired format", collection[0][index] + ' ' + saleColumn);
                    return;
                }
            })
            let lineItems = [];
            collection.map((data, index) => {
                let errorMessage = "";
                if (index > 0) {
                    if (!isNullOrUndefined(data) && data != "" && data[0] != "") // data[1] != null && data[1]!= undefined && data[1] !== "")
                    {
                        let lineItem = {

                            Notes: data[2],
                            RetailPrice: data[6],
                            Qty: data[4],
                            Product: null,
                            TaxableAmount: data[7],
                            Amount: data[14],
                            TaxComponentAmount: null
                        };
                        this.props.config.Products.filter((e) => data[0].trim() === e.SKU.trim() && !e.IsDeleted).map((prod) => { lineItem.Product = prod })
                        if (isNullOrUndefined(lineItem.Product)) {
                            errorMessage += "Product does not exists."
                        }

                        let taxComponent = [];
                        if (data[9].trim() != "" && data[11].trim() != "") {
                            lineItem.TotalTax = Number(data[10]) + Number(data[12]);
                            lineItem.TaxGroup = 'GST ' + (Number(data[9].trim()) + Number(data[11].trim())) + '%'
                            let tax1 = {
                                TaxAmount: Number(data[10]),
                                TaxName: "CGST",
                                TaxPercentage: Number(data[9]),
                                TaxType: "CGST"
                            }
                            taxComponent.push(tax1);
                            let tax2 = {
                                TaxAmount: Number(data[12]),
                                TaxName: "SGST",
                                TaxPercentage: Number(data[11]),
                                TaxType: "SGST"
                            }
                            taxComponent.push(tax2);
                        }
                        else if (data[13] != "") {
                            lineItem.TotalTax = Number(data[14]);
                            lineItem.TaxGroup = 'GST ' + data[13].trim() + '%'
                            let tax1 = {
                                TaxAmount: Number(data[14]),
                                TaxName: "IGST",
                                TaxPercentage: Number(data[13]),
                                TaxType: "IGST"
                            }
                            taxComponent.push(tax1);
                        }
                        this.props.config.ProductTaxes.filter((e) => lineItem.TaxGroup.trim() === e.TaxGroup.trim() && !e.IsDeleted).map((productTax) => lineItem.TaxID = productTax.key)
                        if (isNullOrUndefined(lineItem.TaxID) || lineItem.TaxID === "") {
                            errorMessage += "Please enter a valid Tax Group."
                        }
                        else {
                            lineItem.TaxComponentAmount = taxComponent;
                        }
                        let taxInclusive = false
                        if (data[8].toLowerCase() === "y" || data[8].toLowerCase() === "yes") {
                            taxInclusive = true
                        }
                        lineItem.TaxInclusive = taxInclusive
                        if (errorMessage != "") {
                            let errorObj = {
                                "Barcode": data[0],
                                "Description of Goods": data[1],
                                "Batch": data[2],
                                "MRP": data[3],
                                "Qty": data[4],
                                "Size": data[5],
                                "Basic Rate": data[6],
                                "Taxable Value": data[7],
                                "Taxable Inclusive": data[8],
                                "SGST Rate": data[9],
                                "SGST Amount": data[10],
                                "CGST Rate": data[11],
                                "CGST Amount": data[12],
                                "IGST Rate": data[13],
                                "IGST Amount": data[14],
                                "Total Amount": data[15]
                            }
                            this.state.errorSales.push({ ...errorObj, Message: errorMessage });
                            this.setState({ errorCounter: this.state.errorCounter + 1 });
                            console.log("Errored Line Items: " + JSON.stringify(this.state.errorSales));
                        }
                        else {
                            this.setState({ successCounter: this.state.successCounter + 1 })
                            lineItems.push(lineItem);
                        }
                    }
                }
            })
            let len = lineItems.length
            len = Number(len) + Number(this.state.errorSales.length);
            this.setState({ totalCounter: len });
            //this.props.onImportCompletion(lineItems);
        }
    }

    inventoryImportWithPrice(collection) {
        console.log('collection', collection)
        console.log('this.state.inventoryWithPriceColumns', this.state.inventoryWithPriceColumns)
        if (this.state.inventoryWithPriceColumns.length !== collection[0].length) {
            alert("Column missing:- File is not in desired format");
            return;
        }
        else {
            this.state.inventoryWithPriceColumns.map((column, index) => {
                if (collection[0][index] !== column) {
                    alert("Column sequence missing:- File is not in desired format", collection[0][index] + ' ' + column);
                    return;
                }
            })
            let lineItems = [];
            let errors = []
            let successCounter = 0
            let errorCounter = 0
            let totalCounter = collection.length - 1

            collection.map((data, index) => {
                let errorMessage = "";
                if (index > 0) {
                    if (!isNullOrUndefined(data) && data != "" && data[0] != "") // data[1] != null && data[1]!= undefined && data[1] !== "")
                    {
                        let lineItem = {
                            SKU: data[0],
                            SupplyPrice: Number(data[1]),
                            Qty: Number(data[2]),
                            DiscountAmount: Number(data[3]),
                            HSNSACCode: data[4],
                            TaxGroup: data[6],
                            Notes: data[7],
                        };
                        let chargeTaxOnProduct = false
                        let taxInclusive = false
                        let taxID = ""

                        this.props.config.Products.filter((e) => data[0].trim() === e.SKU.trim() && !e.IsDeleted).map((prod) => { lineItem.Product = prod })
                        if (isNullOrUndefined(lineItem.Product)) {
                            errorMessage += "Product does not exists."
                        }
                        else {

                            if (lineItem.Product.ProductTypeID < 3) {

                                if (lineItem.TaxGroup.trim() !== "") {
                                    this.props.config.ProductTaxes.filter((e) => lineItem.TaxGroup.trim() === e.TaxGroup.trim() && !e.IsDeleted).map((productTax) => taxID = productTax.key)
                                    if (isNullOrUndefined(taxID) || taxID === "") {
                                        errorMessage += "Please enter a valid Tax Group."
                                    }
                                    else {
                                        chargeTaxOnProduct = true
                                    }
                                    if (chargeTaxOnProduct) {
                                        if (data[5].toLowerCase() === "y" || data[5].toLowerCase() === "yes") {
                                            taxInclusive = true
                                        }
                                    }
                                }
                            }
                            else {
                                errorMessage += "Invalid product type. Should be 'Goods for sale' or 'Goods not for sale'"
                            }
                        }
                        if (errorMessage != "") {
                            let errorObj = {
                                "Excel Row No": index + 1,
                                "SKU": data[0],
                                "Supply Price": data[1],
                                "Qty": data[2],
                                "Discount Amount": data[3],
                                "HSNSACCode": data[4],
                                "Tax Inclusive": data[5],
                                "Tax Group": data[6],
                                "Notes": data[7],
                                "Message": errorMessage
                            }
                            errorCounter = errorCounter + 1
                            errors.push(errorObj)
                        }
                        else {
                            // this.setState({successCounter: this.state.successCounter+1})
                            lineItem.TaxID = taxID
                            lineItem.SubTotal = lineItem.SupplyPrice * lineItem.Qty
                            lineItem.ChargeTaxOnProduct = chargeTaxOnProduct
                            lineItem.TaxInclusive = taxInclusive
                            lineItem.PriceBasedTax = false
                            lineItem.DiscountPer = 0
                            lineItem.SubTotalWithDiscount = 0
                            lineItem.TaxableAmount = 0
                            lineItem.TotalTax = 0
                            lineItem.Amount = 0
                            successCounter = successCounter + 1
                            lineItems.push(lineItem);
                        }
                    }
                }
            })
            let len = lineItems.length
            len = Number(len) + Number(errors.length);
            this.setState({ totalCounter: len, successCounter: successCounter, errorCounter: errorCounter, inventoryError: errors })
            if (errors.length === 0) {
                this.props.onImportCompletion(lineItems);
            }
        }
    }

    async inventoryImportUniqueNo(collection) {
        console.log('inventoryImportUniqueNo')
        if (collection.length > 2001) {
            alert('Maximum rows reached')
        }
        if (this.state.inventoryUniqueNo.length !== collection[0].length) {
            alert("Column missing:- File is not in desired format");
            return;
        }

        this.state.inventoryUniqueNo.map((column, index) => {
            if (collection[0][index] !== column) {
                alert("Column sequence missing:- File is not in desired format");
                return;
            }
        })

        let lineItems = [];
        let errors = []
        let successCounter = 0
        let errorCounter = 0
        let totalCounter = collection.length

        // collection.map((data,index)=>
        for (let index = 1; index < collection.length; index++) {
            const data = collection[index];
            let product = null
            let productKey = ""

            let error = ""
            if (data[0].trim() === "") // data[1] != null && data[1]!= undefined && data[1] !== "")
            {
                error = "SKU missing."
            }
            if (data[1].trim() === ""
                && data[2].trim() === ""
                && data[3].trim() === ""
                && data[4].trim() === ""
                && error.trim() === ""
            ) {
                error = "UniqueNo missing."
            }
            if (this.props.config.Products.filter((e) => data[0].trim() === e.SKU.trim() && !e.IsDeleted).length === 0
                && error.trim() === "") {
                error = "Invalid SKU."
            }
            else {
                product = this.props.config.Products.filter((e) => data[0].trim() === e.SKU.trim() && !e.IsDeleted)[0]
                if (product.hasOwnProperty('InventoryType') && product.InventoryType.toLowerCase() !== "unique no") {
                    error = error + "Invalid inventory type."
                }
                if (this.props.inventoryInWardOrOutward.toLowerCase() === "inward") {
                    //check in lineitems collection if its is duplicate 
                    productKey = product.key
                    let ls = lineItems.filter(f => f.Product.key === productKey)
                    for (let l = 0; l < ls.length; l++) {
                        if (data[1].trim() !== "" && ls[l].UniqueNoList.filter(f => f.UniqueNo1.trim() === data[1].trim()
                            || f.UniqueNo2.trim() === data[1].trim()
                            || f.UniqueNo3.trim() === data[1].trim()
                            || f.UniqueNo4.trim() === data[1].trim()
                        ).length > 0) {
                            error = error + "Unique no already exists in file."
                            break
                        }
                        if (data[2].trim() !== "" && ls[l].UniqueNoList.filter(f => f.UniqueNo1.trim() === data[2].trim()
                            || f.UniqueNo2.trim() === data[2].trim()
                            || f.UniqueNo3.trim() === data[2].trim()
                            || f.UniqueNo4.trim() === data[2].trim()
                        ).length > 0) {
                            error = error + "Unique no already exists in file."
                            break
                        }
                        if (data[3].trim() !== "" && ls[l].UniqueNoList.filter(f => f.UniqueNo1.trim() === data[3].trim()
                            || f.UniqueNo2.trim() === data[3].trim()
                            || f.UniqueNo3.trim() === data[3].trim()
                            || f.UniqueNo4.trim() === data[3].trim()
                        ).length > 0) {
                            error = error + "Unique no already exists in file."
                            break
                        }
                        if (data[4].trim() !== "" && ls[l].UniqueNoList.filter(f => f.UniqueNo1.trim() === data[4].trim()
                            || f.UniqueNo2.trim() === data[4].trim()
                            || f.UniqueNo3.trim() === data[4].trim()
                            || f.UniqueNo4.trim() === data[4].trim()
                        ).length > 0) {
                            error = error + "Unique no already exists in file."
                            break
                        }
                    }
                    //check in db
                    if (data[1].trim() !== "") {
                        let uniqueNo = await this.props.checkIfUniqueNoIsUnique(this.props.user.store.key,
                            productKey,
                            data[1])
                        if (uniqueNo) {
                            error = data[1] + " already exists."
                        }
                    }
                    if (data[2].trim() !== "") {
                        let uniqueNo = await this.props.checkIfUniqueNoIsUnique(this.props.user.store.key,
                            productKey,
                            data[2])
                        if (uniqueNo) {
                            error = data[2] + " already exists."
                        }
                    }
                    if (data[3].trim() !== "") {
                        let uniqueNo = await this.props.checkIfUniqueNoIsUnique(this.props.user.store.key,
                            productKey,
                            data[3])
                        if (uniqueNo) {
                            error = data[3] + " already exists."
                        }
                    }
                    if (data[4].trim() !== "") {
                        let uniqueNo = await this.props.checkIfUniqueNoIsUnique(this.props.user.store.key,
                            productKey,
                            data[4])
                        if (uniqueNo) {
                            error = data[4] + " already exists."
                        }
                    }
                }
            }
            if (error.trim() !== "") {
                let errorObj = {
                    "Excel Row No": index + 1,
                    "SKU": data[0],
                    "UniqueNo1": data[1],
                    "UniqueNo1": data[2],
                    "UniqueNo1": data[3],
                    "UniqueNo1": data[4],
                    "Message": error
                }
                errorCounter = errorCounter + 1
                errors.push(errorObj)
                continue
            }

            if (product) {
                if (lineItems.filter(f => f.Product.key === productKey).length > 0) {
                    lineItems.filter(f => f.Product.key === productKey).map(m => {
                        m.UniqueNoList.push({
                            UniqueNo1: '',
                            UniqueNo2: '',
                            UniqueNo3: '',
                            UniqueNo4: '',
                            AllowEdit: true,
                            Error: '',
                            key: ''
                        })
                        m.Qty = m.UniqueNoList.length
                    }
                    )
                }
                else {
                    let lineItem = {}
                    lineItem.Product = product
                    lineItem.Qty = 1
                    lineItem.SupplyPrice = product.SupplyPrice
                    lineItem.SubTotal = 0
                    lineItem.ChargeTaxOnProduct = false
                    lineItem.PriceBasedTax = false
                    lineItem.HSNSACCode = ""
                    lineItem.TaxInclusive = false
                    lineItem.TaxGroup = ""
                    lineItem.TaxID = ""
                    lineItem.DiscountPer = 0
                    lineItem.DiscountAmount = 0
                    lineItem.SubTotalWithDiscount = 0
                    lineItem.TaxableAmount = 0
                    lineItem.TotalTax = 0
                    lineItem.Amount = 0
                    lineItem.UniqueNoList = []
                    lineItem.UniqueNoList.push({
                        UniqueNo1: '',
                        UniqueNo2: '',
                        UniqueNo3: '',
                        UniqueNo4: '',
                        AllowEdit: true,
                        Error: '',
                        key: ''
                    })
                    lineItems.push(lineItem)
                }
                successCounter = successCounter + 1
            }
        }
        this.setState({
            totalCounter: totalCounter,
            successCounter: successCounter,
            errorCounter: errorCounter,
            inventoryError: errors
        })
        if (errors.length === 0) {
            this.props.onImportCompletion(lineItems);
        }

    }

    inventoryImportWithQty(collection) {
        if (collection.length > 2001) {
            alert('Maximum rows reached')
        }
        if (this.state.inventoryWithoutPriceColumns.length !== collection[0].length) {
            alert("Column missing:- File is not in desired format");
            return;
        }
        else {
            this.state.inventoryWithoutPriceColumns.map((column, index) => {
                if (collection[0][index] !== column) {
                    alert("Column sequence missing:- File is not in desired format");
                    return;
                }
            })
            let lineItems = [];
            let errors = []
            let successCounter = 0
            let errorCounter = 0
            let totalCounter = collection.length
            collection.map((data, index) => {
                if (index > 0) {
                    if (!isNullOrUndefined(data) && data != "" && data[0] != "") // data[1] != null && data[1]!= undefined && data[1] !== "")
                    {
                        let lineItem = {
                            Qty: data[1],
                            Notes: data[2]
                        };
                        let product = null;
                        this.props.config.Products.filter((e) => data[0].trim() === e.SKU.trim() && !e.IsDeleted).map((prod) => product = prod)
                        if (!isNullOrUndefined(product) && !isNullOrUndefined(product.key) && product.key != "") {
                            if (product.ProductTypeID < 3) {
                                lineItem.Product = product
                                lineItem.SupplyPrice = 0
                                lineItem.SubTotal = 0
                                lineItem.ChargeTaxOnProduct = false
                                lineItem.PriceBasedTax = false
                                lineItem.HSNSACCode = ""
                                lineItem.TaxInclusive = false
                                lineItem.TaxGroup = ""
                                lineItem.TaxID = ""
                                lineItem.DiscountPer = 0
                                lineItem.DiscountAmount = 0
                                lineItem.SubTotalWithDiscount = 0
                                lineItem.TaxableAmount = 0
                                lineItem.TotalTax = 0
                                lineItem.Amount = 0
                                lineItems.push(lineItem)
                                successCounter = successCounter + 1
                            }
                            else {
                                let errorObj = {
                                    "Excel Row No": index + 1,
                                    "SKU": data[0],
                                    "Qty": data[1],
                                    "Notes": data[2],
                                    "Message": "Invalid product type. Should be 'Goods for sale' or 'Goods not for sale'"
                                }
                                errorCounter = errorCounter + 1
                                errors.push(errorObj)
                            }
                            // product.ProductID = productID
                            // products.push(product);
                        }
                        else {
                            //product not found 
                            let errorObj = {
                                "Excel Row No": index + 1,
                                "SKU": data[0],
                                "Qty": data[1],
                                "Notes": data[2],
                                "Message": "Product not found"
                            }
                            errorCounter = errorCounter + 1
                            errors.push(errorObj)
                        }
                    }
                }
            })
            this.setState({ totalCounter: totalCounter, successCounter: successCounter, errorCounter: errorCounter, inventoryError: errors })
            if (errors.length === 0) {
                this.props.onImportCompletion(lineItems);
            }
        }
    }

    vendorImport(collection) {
        if (this.state.vendorColumns.length !== collection[0].length) {
            alert("Column missing:- File is not in desired format");
            return;
        }
        else {
            this.state.vendorColumns.map((vendorColumn, index) => {
                if (collection[0][index] !== vendorColumn) {
                    alert("Column sequence missing:- File is not in desired format");
                    return;
                }
            })
            collection.map((data, index) => {
                if (index > 0) {
                    if (!isNullOrUndefined(data) && data != "" && data[0] != "") // data[1] != null && data[1]!= undefined && data[1] !== "")
                    {
                        let vendor = {
                            VendorCode : data[0]
                            ,VendorFirstName : data[1]
                            ,VendorLastName : data[2]
                            ,Gender : data[3]
                            ,PhoneNo: data[4]
                            ,EmailID: data[5]
                            ,Address1: data[6]
                            ,Address2 : data[7]
                            ,City : data[8]
                            ,State : data[9]
                            ,PostalCode: data[10]
                            ,Country : data[11]
                            ,CustomField1: data[12]
                            ,CustomField2 : data[13]
                            ,CustomField3 : data[14]
                            ,CustomField4 : data[15]
                            ,CustomField5 : data[16]
                            ,CustomField6 : data[17]
                            ,StoreID : this.props.user.store.key
                            ,RegistrationID: this.props.user.user.RegistrationID
                            ,IsDeleted : false
                        };

                        this.props.addNewVendor(vendor);
                    }
                }
            })
            //this.props.onClose();
        }
    }

    render() {
        return <div>
            {this.props.importType == "membership" && <h3 style={{ marginTop: "0px" }}>Import Product(s)</h3>}
            {this.props.importType == "sales" && <h3 style={{ marginTop: "0px" }}>Import Product(s)</h3>}
            {(this.props.importType.trim() === "inventoryPrice"
                || this.props.importType.trim() === "inventoryQty") && <h3 style={{ marginTop: "0px" }}>Import Product(s)</h3>}
            {this.state.showFileUpload &&
                <div className="box-body">
                    <div className="row">
                        <div class="callout callout-info">
                            <h4>Info</h4>
                            <p>- Maximum of 2000 rows allowed per file upload.</p>
                            <p>- Click here to download import template. <a href={this.state.importTemplate}>Import Template</a></p>
                        </div>
                    </div>
                    {(this.props.importType.trim() === "inventoryPrice" || this.props.importType.trim() === "inventoryQty") && <div className="row">
                        <div className="col-md-4">
                            <select className="form-control"
                                value={this.state.categoryID}
                                onChange={this.onInventoryImportChange}>
                                <option value={this.props.importType}>Inventory Import</option>
                                {(this.props.inventoryInWardOrOutward && this.props.inventoryInWardOrOutward.toLowerCase() === "inward") && <option value="inventoryUniqueNo">Unique No Import</option>}
                            </select>
                        </div>
                    </div>}
                    <div className="row">
                        <div className="col-md-4">
                            <CSVReader
                                cssClass="csv-input"
                                label="Select .csv file"
                                onFileLoaded={this.onFileLoaded}
                                inputId="ObiWan"
                            />
                        </div>
                    </div>
                </div>}
            {this.state.totalCounter > 0 && <div className="box-body">
                <b> Total Count : {this.state.totalCounter}</b>
                <b> Success Count : {this.state.successCounter}</b>
                <b> Error Count : {this.state.errorCounter}</b>
            </div>}
            {this.props.importType === "sales" && this.state.errorSales.length > 0 &&
                <ExcelFile element={<button className="btn btn-flat btn-md btn-flat btn-default">Download Error File</button>}>
                    <ExcelSheet data={this.state.errorSales} name="LineItems">
                        <ExcelColumn label="Barcode" value="Barcode" />
                        <ExcelColumn label="Description of Goods" value="Description of Goods" />
                        <ExcelColumn label="Batch" value="Batch" />
                        <ExcelColumn label="MRP" value="MRP" />
                        <ExcelColumn label="Qty" value="Qty" />
                        <ExcelColumn label="Size" value="Size" />
                        <ExcelColumn label="Basic Rate" value="Basic Rate" />
                        <ExcelColumn label="Taxable Value" value="Taxable Value" />
                        <ExcelColumn label="TaxInclusive" value="TaxInclusive" />
                        <ExcelColumn label="SGST Rate" value="SGST Rate" />
                        <ExcelColumn label="SGST Amount" value="SGST Amount" />
                        <ExcelColumn label="CGST Rate" value="CGST Rate" />
                        <ExcelColumn label="CGST Amount" value="CGST Rate" />
                        <ExcelColumn label="IGST Rate" value="IGST Rate" />
                        <ExcelColumn label="IGST Amount" value="IGST Rate" />
                        <ExcelColumn label="Total Amount" value="Total Amount" />
                        <ExcelColumn label="Message" value="Message" />
                    </ExcelSheet>
                </ExcelFile>}
            {this.props.importType === "inventoryQty" && this.state.inventoryError.length > 0 && <React.Fragment>
                <ExcelFile element={<button className="btn btn-flat btn-md btn-flat btn-default">Download Error File</button>}>
                    <ExcelSheet data={this.state.inventoryError} name="LineItems">
                        <ExcelColumn label="Excel Row No" value="Excel Row No" />
                        <ExcelColumn label="SKU" value="SKU" />
                        <ExcelColumn label="Qty" value="Qty" />
                        <ExcelColumn label="Notes" value="Notes" />
                        <ExcelColumn label="Message" value="Message" />
                    </ExcelSheet>
                </ExcelFile>
            </React.Fragment>}
            {this.props.importType === "inventoryUniqueNo" && this.state.inventoryError.length > 0 && <React.Fragment>
                <ExcelFile element={<button className="btn btn-flat btn-md btn-flat btn-default">Download Error File</button>}>
                    <ExcelSheet data={this.state.inventoryError} name="LineItems">
                        <ExcelColumn label="Excel Row No" value="Excel Row No" />
                        <ExcelColumn label="SKU" value="SKU" />
                        <ExcelColumn label="UniqueNo1" value="UniqueNo1" />
                        <ExcelColumn label="UniqueNo2" value="UniqueNo2" />
                        <ExcelColumn label="UniqueNo3" value="UniqueNo3" />
                        <ExcelColumn label="UniqueNo4" value="UniqueNo4" />
                        <ExcelColumn label="Message" value="Message" />
                    </ExcelSheet>
                </ExcelFile>
            </React.Fragment>}
            {this.props.importType === "inventoryPrice" && this.state.inventoryError.length > 0 && <React.Fragment>
                <ExcelFile element={<button className="btn btn-flat btn-md btn-flat btn-default">Download Error File</button>}>
                    <ExcelSheet data={this.state.inventoryError} name="LineItems">
                        <ExcelColumn label="Excel Row No" value="Excel Row No" />
                        <ExcelColumn label="SKU" value="SKU" />
                        <ExcelColumn label="Supply Price" value="Supply Price" />
                        <ExcelColumn label="Qty" value="Qty" />
                        <ExcelColumn label="Discount Amount" value="Discount Amount" />
                        <ExcelColumn label="HSNSACCode" value="HSNSACCode" />
                        <ExcelColumn label="Tax Inclusive" value="Tax Inclusive" />
                        <ExcelColumn label="Tax Group" value="Tax Group" />
                        <ExcelColumn label="Notes" value="Notes" />
                        <ExcelColumn label="Message" value="Message" />
                    </ExcelSheet>
                </ExcelFile>
            </React.Fragment>}
            {this.props.importType === "membershipDiscount" && this.state.errorMemberships.length > 0 &&
                <ExcelFile element={<button className="btn btn-flat btn-md btn-flat btn-default">Download Error File</button>}>
                    <ExcelSheet data={this.state.errorMemberships} name="Products">
                        <ExcelColumn label="SKU" value="SKU" />
                        <ExcelColumn label="Discount Perc" value="DiscountPercentage" />
                        <ExcelColumn label="Discount Amt" value="DiscountAmount" />
                        <ExcelColumn label="Message" value="Message" />
                    </ExcelSheet>
                </ExcelFile>}
            <br />
            <div className="box-footer">
                <button type="submit" className="btn btn-flat btn-md btn-flat btn-default pull-left" onClick={this.props.onClose} >Cancel</button>
            </div>
        </div>
    }
}
export default ImportMaster