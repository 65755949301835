import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import * as constatnts from '../../Constatnts'
import { isNullOrUndefined } from 'util';
import Loader from '../Loader'
import Pagination from 'react-js-pagination'
import { Link } from 'react-router-dom';


class Instock extends Component {
    constructor() {
        super()
        this.state = {
            products: [],
            stores: [],
            storeList: [],
            searchText: '',
            searchStoreID: '',
            searchStoreLocation: '',
            searchCategory: '',
            searchSubCategory: '',
            searchProductSKU: '',
            searchProductName: '',
            searchBrand: '',
            exportCalled: false,
            activePage: 1,
            itemsCountPerPage: 20,
            totalItemsCount: 0,
            recordFrom: 0,
            paginationStatus: 'No records to display',
            loading: true,
        }
        this.loadproductsReportData = this.loadproductsReportData.bind(this)
        this.onClearSearch = this.onClearSearch.bind(this)
        this.onSearchClick = this.onSearchClick.bind(this)
        this.handleKeyPress = this.handleKeyPress.bind(this)
        this.onExportClick = this.onExportClick.bind(this)
        this.showPaginationStatus = this.showPaginationStatus.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.onStoreChange = this.onStoreChange.bind(this)
    }

    componentDidMount() {
        this.setState({ storeID: this.props.user.store.key })
        let storeList = []
        storeList.push({ key: this.props.user.store.key })

        this.props.getUserStores(this.props.user.user.RegistrationID, "").then((stores) => {                
            this.setState({ stores: stores })
        });
    this.setState({ storeList: storeList, storeID: this.props.user.store.key });
        this.loadproductsReportData(storeList, this.state.recordFrom,
            this.state.searchStoreLocation,
            this.state.searchCategory,
            this.state.searchSubCategory, this.state.searchProductSKU,
            this.state.searchProductName, this.state.searchBrand);
        
    }

    loadproductsReportData(storeList, from, searchStoreLocation, searchCategory, searchSubCategory,
        searchProductSKU, searchProductName, searchBrand) {
        this.setState({ loading: true })
        this.props.getItemInStockData(storeList, from, this.state.itemsCountPerPage, this.props.user.store.key, searchStoreLocation, searchCategory, searchSubCategory, searchProductSKU, searchProductName, searchBrand)
            .then((result) => {
                // console.log(JSON.stringify(result.searchResult))
                this.setState({ products: result.searchResult, totalItemsCount: result.totalItemsCount, loading: false })
                this.showPaginationStatus()
            })
    }

    onClearSearch() {
        let storeList = []
        storeList.push({ key: this.props.user.store.key});

        this.setState({
            storeList : storeList, from: 0,
            searchStoreLocation: '', searchCategory: '', searchSubCategory: '',
            searchProductSKU: '', searchProductName: '', searchBrand: ''
        })
        this.loadproductsReportData(storeList, 0, "", "", "", "", "", "")
    }

    onExportClick() {
        this.setState({ loading: false, exportCalled: true })
        this.props.getItemInStockData(
            this.state.storeList,
            this.state.recordFrom, 10000,
            this.props.user.store.key,
            this.state.searchStoreLocation,
            this.state.searchCategory,
            this.state.searchSubCategory,
            this.state.searchProductSKU,
            this.state.searchProductName,
            this.state.searchBrand
        )
            .then((result) => {
                console.log(result.searchResult);
                if (isNullOrUndefined(result.searchResult) || result.searchResult.length === 0) {
                    this.props.getToastr("No Record Found")
                    return
                }
                let products = []
                result.searchResult.map((data) => {

                    console.log(JSON.stringify(data))
                    let product = {

                        "Store Name": data.StoreName,
                        "Store Location": data.Location,
                        "Brand": data.Brand,
                        "Category": data.Category,
                        "Sub Category": data.SubCategory,
                        "SKU": data.ProductSKU,
                        "Barcode": data.BarCode,
                        "Product Name": data.ProductName,
                        "Retail Price": data.RetailPrice,
                        "In Stock Qty": data.InStockQty

                    }
                    products.push(product);
                })
                const csvData = constatnts.objToCSV(products);
                this.setState({ loading: false, exportCalled: false })
                const csvBlob = new Blob([csvData], { type: "text/csv" });
                const url = window.URL.createObjectURL(csvBlob);
                const a = document.createElement("a");
                a.setAttribute("hidden", "");
                a.setAttribute("href", url);
                a.setAttribute("download", "InStock.csv");
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
    }

    onSearchClick() {
        this.setState({ loading: true })
        this.loadproductsReportData(
            this.state.storeList, 0,
            this.state.searchStoreLocation,
            this.state.searchCategory,
            this.state.searchSubCategory,
            this.state.searchProductSKU,
            this.state.searchProductName,
            this.state.searchBrand
        )
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.onSearchClick()
        }
    }

    onStoreChange(e){
        let storeList = []
        console.log("key", e.target.value);
        if (e.target.value != "") {
            storeList.push({key: e.target.value})
        }
        else
        {
            for (let i = 0; i < this.state.stores.length; i++) {
                const store = this.state.stores[i];
                storeList.push({ key: store.key })
            }
        }
        console.log("storeList", JSON.stringify(storeList));
        this.setState({ storeList: storeList, storeID: e.target.value })
    }

    handlePageChange(pageNumber) {
        console.log(`active page is ${pageNumber}`);
        let recordFrom = ((pageNumber - 1) * this.state.itemsCountPerPage)
        this.setState({ activePage: pageNumber, recordFrom: recordFrom });
        this.loadproductsReportData(
            this.state.storeList,
            0,
            this.state.searchStoreLocation,
            this.state.searchCategory,
            this.state.searchSubCategory,
            this.state.searchProductSKU,
            this.state.searchProductName,
            this.state.searchBrand)
    }


    showPaginationStatus() {
        console.log('this.state.recordFrom', this.state.recordFrom)
        let paginationStatus
        if ((this.state.recordFrom + 1) === this.state.totalItemsCount) {
            paginationStatus = "Showing " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else if ((this.state.recordFrom + this.state.itemsCountPerPage + 1) > this.state.totalItemsCount) {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + this.state.totalItemsCount + " of " + this.state.totalItemsCount
        }
        else {
            paginationStatus = "Showing " + (this.state.recordFrom + 1) + " to  " + (this.state.recordFrom + this.state.itemsCountPerPage) + " of " + this.state.totalItemsCount

        }
        this.setState({ paginationStatus: paginationStatus })
    }


    render() {
        return <div className="content-wrapper" >
            <section className="content" >
                <div className="row">
                    <div className="col-md-12">
                        <div className="box ">
                            <div className="box-header with-border">
                                <div className="row">
                                    <div className="col-md-6">
                                        <font style={{ fontSize: "30px" }}>In Stock Report</font>
                                    </div>
                                    <div className="col-md-6">
                                        <button type="cancel" className="btn btn-default pull-right btn-flat"
                                            style={{ marginTop: "5px", marginLeft: "10px" }}
                                            onClick={() => this.props.history.push({ pathname: "/home/reports/allreport" })}
                                        ><i className="fa fa-arrow-left"></i> Back</button>
                                    </div>
                                </div>
                            </div>
                            <div className="box-body">
                                <div className="row">
                                <div className="col-md-3">
                                        <label>Store Name</label>
                                        <select className="form-control"
                                            value={this.state.storeID}
                                            onChange={this.onStoreChange}>
                                            <option value="">All Stores</option>
                                            {this.state.stores.filter((e) => !e.IsDeleted).map((store) =>
                                                <option value={store.key}>{store.StoreCode}-{store.StoreName}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>Store Location</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchStoreLocation}
                                                onChange={(evt) => this.setState({ searchStoreLocation: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>

                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>Brand</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchBrand}
                                                onChange={(evt) => this.setState({ searchBrand: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>Category</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchCategory}
                                                onChange={(evt) => this.setState({ searchCategory: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>Sub Category</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchSubCategory}
                                                onChange={(evt) => this.setState({ searchSubCategory: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3" style1={{ padding: "0px" }}>
                                        <div className="form-group">
                                            <label>SKU</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchProductSKU}
                                                onChange={(evt) => this.setState({ searchProductSKU: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>
                                    <div className="col-md-3" >
                                        <div className="form-group">
                                            <label>Product Name</label>
                                            <input type="text" className="form-control input-md"
                                                value={this.state.searchProductName}
                                                onChange={(evt) => this.setState({ searchProductName: evt.target.value })}
                                                onKeyPress={this.handleKeyPress} />
                                        </div>
                                    </div>

                                    <div className="col-md-3" >
                                        <button type="button" className="btn btn-primary btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginLeft: "5px" }}
                                            onClick={this.onSearchClick}
                                        >Search</button>
                                        <button type="button" className="btn btn-default btn-flat btn-md pull-right" style={{ marginTop: "25px" }}
                                            onClick={this.onClearSearch} >Clear</button>
                                        <button class="btn btn-default btn-flat btn-md pull-right"
                                            style={{ marginTop: "25px", marginRight: "5px" }}
                                            onClick={this.onExportClick}
                                        > Export <i class="glyphicon glyphicon-download-alt " ></i></button>

                                        {/* <a type="button" className="btn btn-md pull-right" style={{marginTop:"25px"}} onClick={this.showHideFilters}>{this.state.showFilters ? "Less Filter" : "More Filters"}</a> */}
                                    </div>
                                </div>
                                <br></br>
                                {this.state.loading && <Loader show={this.state.loading} />}
                                {!this.state.loading &&
                                    <table className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>Store Name</th>
                                                <th>Store Location</th>
                                                <th>Brand</th>
                                                <th>Category</th>
                                                <th>Sub Category</th>
                                                <th>SKU</th>
                                                <th>Barcode</th>
                                                <th>Product Name</th>
                                                <th>Retail Price</th>
                                                <th> In Stock Qty</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.products.map((item, index) =>
                                                <tr>
                                                    <td>{item.StoreName}</td>
                                                    <td>{item.Location}</td>
                                                    <td>{item.Brand}</td>
                                                    <td>{item.Category}</td>
                                                    <td>{item.SubCategory}</td>
                                                    <td>{item.ProductSKU}</td>
                                                    <td>{item.BarCode}</td>

                                                    <td>{item.ProductName}</td>
                                                    <td>{item.RetailPrice}</td>
                                                    <td>
                                                        <Link to={{
                                                            pathname: "/home/reports/ItemLedger",
                                                            state: {
                                                                ProductSKU: item.ProductSKU,
                                                                LocationID: item.LocationID
                                                            }
                                                        }}>{item.InStockQty}</Link>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>}
                                {this.state.totalItemsCount > 0 && <div className="pull-left" style={{ marginTop: "25px" }}>
                                    {this.state.paginationStatus}
                                </div>}
                                <div className="pull-right">
                                    <Pagination
                                        activePage={this.state.activePage} //this.state.activePage
                                        itemsCountPerPage={this.state.itemsCountPerPage}
                                        totalItemsCount={this.state.totalItemsCount}
                                        pageRangeDisplayed={2}
                                        onChange={this.handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default withRouter(Instock)